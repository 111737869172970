import templatesListController from "./templatesList.controller";

/* templatesList.component.js */

/**
 * @desc component for viewing a list of audit trail templates
 * @example <templates-list</templates-list>
 */

const templatesList = {
    bindings: {
        items: '<',
        onUpdate: '&'

    },
    controller: templatesListController,
    controllerAs: 'tlvm',
    templateUrl: '/App/Components/AuditTrail/MaintainTemplates/templatesList.tmpl.html'
};

export default templatesList;