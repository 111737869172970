export default /*@ngInject*/ function ($scope, AuditTrailEntityDataService, $location, helperService) {
    $scope.pageParams = $location.search();
    const vm = this;

    vm.stopTemplateDetailsSpinner = false;

    function activate() {
        getTemplate();
    }

    activate();

    async function getTemplate() {
        try {
            let response = await AuditTrailEntityDataService.getTemplate($scope.pageParams.TemplateId);
            response = await AuditTrailEntityDataService.mapTemplate(response.data, false);
            vm.stopTemplateDetailsSpinner = true;
            vm.selectedEntity = response;
            $scope.$evalAsync();
        } catch (response) {
            helperService.showErrorMessage(response.data);
        }

    }
}