import reportToolbarController from "./reportToolbar.controller";

/* reportToolbar.component.js */

/**
 * @desc component for displaying the report toolbar
 * @example <report-toolbar></report-toolbar>
 */

const reportToolbar = {
    bindings: {
        grid: '<',
        enableGrid: '<',
        dateCriteria: '<',
        gridDataSouce: '<',
        reportId: '<',
        selectedProduct: '<',
        reportDetailsModel: '<',
        onApplyFilter: '&',
        onReadGrid: '&',
        onClearFilters: '&',
        filterCriteria: '<',
        sortsAndFilters: '<',
        populateCriteriaModel: '&'
    },
    controller: reportToolbarController,
    controllerAs: 'rtvm',
    templateUrl: '/App/Shared/Components/ReportToolbar/reportToolbar.tmpl.html'
};

export default reportToolbar;