import createSpinner from "../../../Shared/Functions/createSpinner";


export default /*@ngInject*/ function (helperService, manageDataDataService, configurationDataService) {
    const vm = this;
    vm.helperService = helperService;
    vm.riskEligibleToggleChange = riskEligibleToggleChange;
    vm.submitIndirectAccessRiskEligible = submitIndirectAccessRiskEligible;
    vm.$onInit = activate;

    async function activate() {
        getConfiguration();
    }

    async function getConfiguration() {
        let { data } = await manageDataDataService.getConfigurationByProductId(vm.product.ID);

        vm.isEligible = data.find(config => config.Name === 'IndirectAccessRiskEligible').Value

        vm.riskEligible = vm.isEligible;
    }

    function riskEligibleToggleChange() {
        vm.disableSave = vm.isEligible === vm.riskEligible;
    }

    async function submitIndirectAccessRiskEligible() {
        let riskEligible = vm.riskEligible === '1';

        vm.updateRiskEligiblePending = new createSpinner();

        try {
            await configurationDataService.updateProductRiskEligibility(vm.product.Type, riskEligible);
            helperService.successfulSaveButton(vm.updateRiskEligiblePending);
            vm.disableSave = true;
            vm.isEligible = vm.riskEligible;
        }
        catch {
            vm.updateRiskEligiblePending.loadingValue = false;
        }

    }

}