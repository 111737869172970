import angular from 'angular';
import allSupportedProducts from '../GlobalVariables/ProductLookUp';

export function removeSpaces() {
    return function (string) {
        if (!angular.isString(string)) {
            return string;
        }
        return string.replace(/[\s]/g, '');
    };
}

export function startFrom() {
    return function (input, start) {
        start = +start; //parse to int
        return input.slice(start);
    };
}

export function removeBlankDate() {
    return function (string) {
        if (!angular.isString(string)) {
            return string;
        }
        return string.replace('0001-01-01T00:00:00Z', '');
    };
}

export function removeProductFromReportName() {
    return function (string, productID) {
        if (!angular.isString(string)) {
            return string;
        }

        if (allSupportedProducts) {
            var selectedProduct = allSupportedProducts.filter(e => e.ID === productID)[0];
            if (selectedProduct) {

                //loop thru string. Remove any part of the product name from the name shown in the left hand navigation
                var array = string.split(' ');
                array.forEach(function (item) {
                    if (selectedProduct.Name.includes(item)) {
                        string = string.replace(item, "");
                    }
                });
            }
        }

        return string;
    };
}

export function removeStringFromString() {
    return function (string, string2) {
        if (!angular.isString(string)) {
            return string;
        }

        if (string.includes(string2)) {
            string = string.replace(string2, "");
        }

        return string;
    };
}

export function removeDetailedFromString() {
    return function (string) {
        if (!angular.isString(string)) {
            return string;
        }
        if (string.includes("Detailed")) {
            string = string.replace("Detailed", "");
        }

        return string;
    };
}

export function removeRoleDetailFromString() {
    return function (string) {
        if (!angular.isString(string)) {
            return string;
        }
        if (string.includes("Role Detail")) {
            string = string.replace("Role Detail", "");
        }

        return string;
    };
}

export /*@ngInject*/  function addLockNextToTextInOISelect_dropdownFilter($sce) {
    return function (label, query, item, options, element) {

        var html = label;

        if (item.Status === "Analyzing" || item.Status === "Locked") {
            html = '<span class="fa fa-lock"></span>&nbsp;&nbsp;' + label;
        }

        return $sce.trustAsHtml(html);
    };
}

export function readableUrlRoute() {

    return function (urlRoute) {
        if (!angular.isString(urlRoute)) {
            return urlRoute;
        }
        var splitItUp = urlRoute.split("/");
        return urlRoute = splitItUp[1].replace('of', ' of').replace(/([A-Z])/g, ' $1').trim() + ' | ' + splitItUp[2].replace(/([A-Z])/g, ' $1').trim();
    };
}

export function booleanToYesNo() {

    return function (value) {
        if (value) {
            return 'Yes';
        } else {
            return 'No';
        }
    };
}

export function insertSpaceBetweenCapitalWords() {

    return function (string) {
        if (!angular.isString(string)) {
            return string;
        } else {
            string = string.replace(/([A-Z])/g, ' $1').trim();
            string = string.replace('I D', 'ID');

            return string;
        }
    };
}

export function insertSpaceBetweenCapitalWordsAndAcronyms() {

    return (string) => {
        if (!angular.isString(string)) {
            return string;
        } else {
            string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
            string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
            return string;

        }
    };
}

export function titleCase() {

    return function (input) {
        input = input || '';
        return input.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };
}

export /*@ngInject*/ function readableUrlItem(helperService) {

    return function (urlRoute) {
        if (!angular.isString(urlRoute)) {
            return urlRoute;
        }
        var splitItUp = urlRoute.split("/");
        urlRoute = splitItUp[3];

        return helperService.camelCaseToSentence(urlRoute);

    };
}

export /*@ngInject*/  function convertProductIdToProductName(helperService) {

    return function (productId) {
        return helperService.productNameLookUp(productId);
    };
}


export /*@ngInject*/  function translateObjectIdToObjectName(helperService) {
    return function (x) {
        var object = helperService.getSecurityObjectById(x);
        if (object) {
            return object.Name;
        } else {
            return "";
        }
    };
}

export function stripFirstName() {
    return function (x) {
        return x.substr(x.indexOf(' ') + 1);
    };
}

export function showIfNotDuplicate() {
    return function (word, word2) {
        if (word === word2) {
            return '';
        } else {
            return word;
        }
    };
}

export function addLockIconNextToText() {
    return function (word, word2) {
        if (word2 === "Analyzing" || word2 === "Locked") {
            return "&#xf083; " + word;
        } else {
            return word;
        }
    };
}

export function addPipeBeforeWord() {
    return function (word, word2) {
        if (word !== word2) {
            if (word) {
                return word2 + " | " + word;
            } else {
                return word2;
            }
        } else {
            return word;
        }
    };
}

export function dateDiff() {
    var magicNumber = 1000 * 60 * 60 * 24;

    return function (toDate, fromDate) {
        if (toDate && fromDate) {
            var dayDiff = Math.floor((toDate - fromDate) / magicNumber);
            dayDiff = Math.max(0, dayDiff);
            if (dayDiff === 0 && toDate.getDay() !== fromDate.getDay()) {
                dayDiff = 1;
            }
            return dayDiff;
        }
    };
}

export function filterResults() {
    //Get a value nested inside an object
    Object.byString = function (o, s) {
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return;
            }
        }
        return o;
    };

    //dataArray: The total list of objects you're searching against
    //searchTerm: Value typed into the filter box
    //searchProperties: Properties in the object the searchTerm will be applied to
    return function (dataArray, searchTerm, searchProperties) {
        // If no array is given, exit.
        if (!dataArray) {
            return;
        }
        // If no search term exists, return the array unfiltered.
        else if (!searchTerm) {
            return dataArray;
        }

        else {
            // Convert filter text to lower case.
            var term = searchTerm.toLowerCase();
            // Return the array and filter it by looking for any occurrences of the search term in the searchProperties array.
            return dataArray.filter(function (item) {

                for (var i = 0; i < searchProperties.length; i++) {
                    var foundValue = Object.byString(item, searchProperties[i]);

                    if (!foundValue) {
                        continue;
                    }

                    foundValue = foundValue.toString();

                    //Return true if the property has the search term
                    if (foundValue.toLowerCase().indexOf(term) > -1) {
                        return true;
                    }
                }
                return false;
            });
        }
    };
}

export function makeStringPlural() {
    return function (string) {
        if (string.slice(-2) === 'ss') {
            return string + 'es';
        } else if (string.slice(-1) === 'y') {
            return string.slice(0, -1) + 'ies';
        }
        else {
            return string + 's';
        }
    };
}

export function makeStringPluralWithCount() {
    return function (string, count) {
        if (count > 1 || count === 0) {
            if (string.slice(-2) === 'ss') {
                return string + 'es';
            } else {
                return string + 's';
            }
        } else {
            return string;
        }
    };
}

export function unique() {
    return function (collection, keyname) {
        var output = [],
            keys = [];

        angular.forEach(collection, function (item) {
            var key = item[keyname];
            if (keys.indexOf(key) === -1) {
                keys.push(key);
                output.push(item);
            }
        });

        return output;
    };
}