import ReportSchedulerModalController from "../../Controllers/ReportSchedulerModalController";
import createSpinner from "../../Functions/createSpinner";

export default /*@ngInject*/ function ($uibModal, $rootScope, dataService, $http, helperService) {

    const vm = this;

    vm.newSavedReport = {};
    vm.isScheduleEnabled = false;

    vm.$onInit = onInit;

    vm.scheduleReport = scheduleReport;
    vm.saveReportToSchedule = saveReportToSchedule;
    vm.cancel = cancel;

    function onInit() {
        if (vm.resolve.currentSavedReports.length > 0) {
            vm.showScheduleReport = true;
        } else {
            vm.showScheduleReport = false;
        }
    }

    //Schedule Report
    function scheduleReport(scheduleReportId) {
        vm.modalInstance.close();

        if (scheduleReportId) {
            $rootScope.savedReportToSchedule = scheduleReportId;
            $uibModal.open({
                templateUrl: '/App/Shared/Controllers/ReportSchedulerModal.html',
                controller: ReportSchedulerModalController,
                backdrop: 'static',
                size: 'md'
            });
        }
    }

    function saveReportToSchedule() {
        vm.saving = new createSpinner();
        const reportDefinition = vm.resolve.bundleReportDefinition(vm.resolve.grid.getOptions(), vm.resolve.reportDetailsModel);

        const postData = {
            "Description": vm.newSavedReport.description,
            "Name": vm.newSavedReport.name,
            "ReportDefinition": {
                "ReportId": vm.resolve.report.ReportID,
                "Columns": reportDefinition.Columns,
                "Sortings": reportDefinition.Sortings,
                "Criteria": reportDefinition.Criteria = vm.resolve.sortsAndFilters && vm.resolve.sortsAndFilters.Criteria ? reportDefinition.Criteria.concat(vm.resolve.sortsAndFilters.Criteria) : reportDefinition.Criteria,
                "Filters": reportDefinition.Filters,
                "DateFilters": reportDefinition.DateFilters
            }
        };

        $http.post(apiUrl + 'api/core/savedreports', postData).then(async function (response) {
            await helperService.successfulSaveButton(vm.saving);
            vm.modalInstance.close();

            dataService.getSavedReportsByID(response.data).then(function (response) {
                $rootScope.savedReportToSchedule = response.data.Id;
                $uibModal.open({
                    templateUrl: '/App/Shared/Controllers/ReportSchedulerModal.html',
                    controller: ReportSchedulerModalController,
                    backdrop: 'static',
                    size: 'md'
                });
            });

        }, function (response) {
            vm.saving.loadingValue = false;
            helperService.showErrorMessage(response.data);
        });

    }

    function cancel() {
        vm.modalInstance.close();
    }
}