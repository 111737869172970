import swal from "sweetalert2";
import roleAuthObjectModalController from "./roleAuthObjectModal.controller";

export default /*@ngInject*/ function (sapSdSimRoleDataService, helperService, $uibModal, $filter) {

    const vm = this;

    vm.changeSelectedAuthObject = changeSelectedAuthObject;
    vm.openAuthObjectModal = openAuthObjectModal;
    vm.removeAuthObjectFromRole = removeAuthObjectFromRole;

    vm.$onChanges = activate;

    async function activate() {
        getRoleAuthObjects();
    }

    async function getRoleAuthObjects() {
        vm.authObjectsLoading = true;
        let response = await sapSdSimRoleDataService.getRoleAuthObjects(vm.simulation.Id, vm.selectedRole.Id);
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.authObjects = response.data;
        vm.authObjectsLoading = false;
    }

    async function changeSelectedAuthObject(authObject) {
        vm.selectedAuthObject = authObject;
        vm.onSelectedAuthObjectChange({ authObject });
    }

    async function openAuthObjectModal() {
        let authObjectModal = $uibModal.open({
            templateUrl: '/App/Components/SAPSecurityDesigner/simRoleAuthObjectsList/roleAuthObjectModal.tmpl.html',
            controller: roleAuthObjectModalController,
            controllerAs: 'raomvm',
            backdrop: 'static',
            resolve: {
                simulation: () => vm.simulation,
                role: () => vm.selectedRole,
                selectedAuthObjects: () => vm.authObjects
            }
        });

        try {
            await authObjectModal.result;
            await getRoleAuthObjects();
        } catch { return; }
    }

    async function removeAuthObjectFromRole(authObject) {
        try {
            await swal(helperService.areYouSureParams(`Remove Authorization Object`, `Are you sure you want to remove authorization object ${authObject.Name} from role ${vm.selectedRole.Name}?`, `Yes`, 'No'))
            await sapSdSimRoleDataService.removeAuthObjectFromRole(vm.simulation.Id, vm.selectedRole.Id, authObject);
            getRoleAuthObjects();
        } catch {
            return;
        }
    }
}