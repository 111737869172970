import swal from "sweetalert2";
import addEditTemplatesModalController from "./addEditTemplatesModal.controller";

export default /*@ngInject*/ function ($filter, helperService, workdayMaintainTemplatesDataService, $uibModal) {

    const vm = this;

    vm.openAddEditTemplateModal = openAddEditTemplateModal;
    vm.selectedTemplateChanged = selectedTemplateChanged;


    activate();

    async function activate() {
        await setTemplates();

        vm.menuOptions = [
            {
                text: 'Clone',
                click: item => {
                    openAddEditTemplateModal(item.template, true);
                }
            },
            {
                text: 'Edit',
                click: item => {
                    openAddEditTemplateModal(item.template, false);
                }
            },
            {
                text: 'Delete',
                click: item => {
                    deleteTemplate(item.template);
                },
                displayed: item => {
                    return item.template.IsCustom;
                }
            }
        ];
    }

    async function setTemplates(newTemplateId, template, isClone) {
        vm.templatesLoading = true;
        const { data } = await workdayMaintainTemplatesDataService.getTemplates();
        vm.templates = $filter('orderBy')(data, 'Name');
        if (newTemplateId && (!template?.Id || isClone)) {
            vm.templates.sort((a, b) => a.Id === newTemplateId ? -1 : b.Id === newTemplateId ? 1 : 0);
        }
        vm.templatesLoading = false;
    }

    async function openAddEditTemplateModal(template, isClone) {
        let templateModal = $uibModal.open({
            templateUrl: '/App/Components/workdayMaintainTemplates/templatesList/addEditTemplatesModal.tmpl.html',
            controller: addEditTemplatesModalController,
            controllerAs: 'tmvm',
            backdrop: 'static',
            resolve: {
                template: () => template,
                isClone: () => isClone
            }
        });

        try {
            let newTemplateId = await templateModal.result;
            await setTemplates(newTemplateId, template, isClone);
            if (newTemplateId) {
                selectedTemplateChanged(vm.templates.find(template => template.Id === newTemplateId));
            }
        } catch {
            return;
        }
    }

    function selectedTemplateChanged(template) {
        vm.selectedTemplate = template;
        vm.onSelectedTemplateChanged({ template });
    }

    async function deleteTemplate(template) {
        await swal(helperService.areYouSureParamsWithHTML('Delete Template', `<div>Are you sure you want to delete template <strong>${template.Name}</strong>?</div>`, 'Delete'));
        await workdayMaintainTemplatesDataService.deleteTemplate(template.Id);
        selectedTemplateChanged(null);
        setTemplates();
    }
}