export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getMasterUsers,
        exportSystemUsers,
        updateUserIdentity,
        importSystemUsers,
        createUserMap,
        generateAssignments,
        deleteUserMap
    }

    return service;

    //GET
    async function getMasterUsers() {
        try {
            return await $http.get(`${apiUrl}api/universal/usermaster`);
        } catch (err) {
            showError(err);
        }
    }

    async function exportSystemUsers() {
        try {
            return await $http.get(`${apiUrl}api/universal/usermaster/identity/export`, { responseType: "blob" });
        } catch (err) {
            showError(err);
        }
    }

    //PUT
    async function updateUserIdentity(userMasterId, putData) {
        try {
            return await $http.put(`${apiUrl}api/universal/usermaster/${userMasterId}`, putData);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function importSystemUsers(data) {
        try {
            return await $http.put(`${apiUrl}api/universal/usermaster/identity/import`, data, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    // POST
    async function createUserMap(mapObject) {
        try {
            return await $http.post(`${apiUrl}api/universal/fastpathusermappings`, mapObject);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function generateAssignments() {
        try {
            return await $http.post(`${apiUrl}api/universal/usermaster/identity/automap`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    // DELETE
    async function deleteUserMap(userId, userMasterId) {
        try {
            return await $http.delete(`${apiUrl}api/universal/fastpathusermappings/fpuser/${userId}/usermaster/${userMasterId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}