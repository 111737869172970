/* maintainProfilesGrid.component.js */

import maintainProfilesGridController from "./maintainProfilesGrid.controller";

/**
 * @desc component to display, add, delete, import and export SAP users that are assigned to powerful profiles
 * @example <maintain-profiles-grid></maintain-profiles-grid>
 */

const maintainProfilesGrid = {
    controller: maintainProfilesGridController,
    controllerAs: 'mpgvm',
    templateUrl: '/App/Components/maintainProfiles/maintainProfilesGrid/maintainProfilesGrid.tmpl.html'
};

export default maintainProfilesGrid;