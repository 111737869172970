/* approvalRules.component.js */

import approvalRulesController from "./approvalRules.controller";

/**
 * @desc component for creating, updating, and deleting Approval Rules
 * @example <approval-rules></<approval-rules>
 */

const approvalRules = {
    controller: approvalRulesController,
    controllerAs: 'arvm',
    templateUrl: '/App/Components/ApprovalRules/approvalRules.tmpl.html'
};

export default approvalRules;