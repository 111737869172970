import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";


export default /*@ngInject*/ function ($scope, $uibModalInstance, helperService, existingClasses, workdayMaintainTemplatesDataService, manageDataDataService, selectedTemplate) {

    const vm = this;

    vm.cancel = cancel;
    vm.addClassToSelectedTemplate = addClassToSelectedTemplate;
    vm.collectMetadata = collectMetadata;
    vm.selectedTemplate = selectedTemplate;

    activate();

    $scope.$on('CollectDataChanges', (event, args) => vm.classesGridDataSource.read());

    function activate() {
        vm.metadataCollectionRequired = false;
        createObjectsGrid();
    }


    function createObjectsGrid() {
        const classesGridColumns = [
            { field: "Class", title: "Class", width: 120, filterable: customAutoCompleteFilter },
            { field: "BusinessObject", title: "Business Object", width: 120, filterable: customAutoCompleteFilter },
            { template: "<a ng-click='acmvm.addClassToSelectedTemplate(dataItem)' ng-disabled='acmvm.addingClass'><i class='fa fa-plus pull-right text-success'></i></a>", title: " ", width: 20 }
        ];

        vm.classesGridOptions = helperService.setMainGridOptions(classesGridColumns, null);
        vm.classesGridOptions.selectable = false;

        vm.classesGridDataSource = new kendo.data.DataSource({
            schema: {
                data: "_embedded.reportData",
                total: "TotalItems"
            },
            pageSize: standardGridPageSize,
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            noRecords: {
                template: "<div class='no-data-to-display-message'>No items to display</div>"
            },
            transport: {
                read: async options => {
                    let sortsAndFilters = helperService.prepareSortsAndFilters(options);

                    if (sortsAndFilters.Filters.length) {
                        if (sortsAndFilters.Filters[0].FieldName === 'Class') {
                            sortsAndFilters.Filters[0].FieldName = 'Name';
                        } else {
                            sortsAndFilters.Filters[0].FieldName = 'BusinessObjectName';
                        }
                    }

                    if (sortsAndFilters.Sorts.length) {
                        if (sortsAndFilters.Sorts[0].FieldName === 'Class') {
                            sortsAndFilters.Sorts[0].FieldName = 'Name'
                        } else {
                            sortsAndFilters.Sorts[0].FieldName = 'BusinessObjectName'
                        }
                    }

                    const postData = sortsAndFilters,
                        params = { 'pagesize': options.data.pageSize, 'page': options.data.page },
                        { data } = await workdayMaintainTemplatesDataService.getAllClasses(postData, params);

                    if (!data.TotalItems && !sortsAndFilters.Filters.length) {
                        vm.metadataCollectionRequired = true;
                    } else {
                        vm.metadataCollectionRequired = false;
                        vm.collectMetadataPending = false;

                        data._embedded.reportData = data._embedded.reportData.filter(dataItem => !existingClasses.find(existingClass => dataItem.Id === existingClass.ClassId));

                        // If all classes on page are selected, load the next page
                        if (!data._embedded.reportData.length && data.TotalItems && !sortsAndFilters.Filters.length) {
                            vm.classesGridDataSource.page(data.PageNumber + 1);
                        }
                    }

                    options.success(data);
                }
            }
        });
    }

    async function addClassToSelectedTemplate(item) {
        vm.addingClass = true;

        const data = {
            TemplateId: vm.selectedTemplate.Id,
            ClassId: item.Id
        };

        try {
            await workdayMaintainTemplatesDataService.addClassToTemplate(data);
            existingClasses.push({ ClassName: item.Class, ClassId: item.Id });
            vm.classesGridDataSource.read();
            vm.addingClass = false;
        } catch {
            vm.addingClass = false;
        }

    }

    async function collectMetadata() {     
        const data = {
            ImportType: "AuditTrailImport_WorkdayMetadata",
            ProductId: "WD"
        };

        try {
            vm.collectMetadataPending = true;
            await manageDataDataService.dataActionCall('collect', data);
        } catch {
            vm.collectMetadataPending = false;
        }
    }

    function cancel() { $uibModalInstance.close(); }

}