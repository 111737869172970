export default /*@ngInject*/ function (errorsDataService, $http) {

    const service = {
        availableTenants: availableTenants
    };

    return service;

    function availableTenants(userAuth) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/auth/tenants/user/' + userAuth
        }).then(workComplete)
            .catch(response => {
                errorsDataService.catch(response);
            });
    }

    const workComplete = function (response) {
        return response;
    };

}