import createSpinner from "../../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $filter, dataService, helperService, maintainSystemRolesDataService, ownershipGroupsDataService, ownershipGroupItemsDataService, role) {
    const vm = this;

    vm.editSystemRole = editSystemRole;
    vm.checkIfApproversNeeded = checkIfApproversNeeded;
    vm.approversSelectionChanged = approversSelectionChanged;
    vm.cancel = cancel;
    vm.role = { ...role };
    vm.approversNeeded = false;

    if (vm.role.Reviewed) {
        vm.role.previouslyReviewed = true;
    }

    activate();

    async function activate() {
        if (role.ProductType === 'SAP') {
            vm.eaDisabled = await canSetEmergencyAccess();
            if (vm.role.IsEmergencyAccess) {
                vm.approversExist = true;
            }
        }
    }

    async function canSetEmergencyAccess() {
        const { data: ownershipGroups } = await ownershipGroupsDataService.getOwnershipGroups(),
            defaultEAOGId = await helperService.getConfigurationValue('EmergencyAccessApproversGroup');
        if (!ownershipGroups.length || !defaultEAOGId) {
            return true;
        } else {
            vm.ownershipGroupsList = ownershipGroups;
            vm.defaultEAOwnershipGroupId = Number(defaultEAOGId);
            return false;
        }
        
    }

    async function checkIfApproversNeeded() {
        if (!vm.role.IsEmergencyAccess) {
            return;
        }
        vm.dataLoading = true;
        if (!vm.approversExist) {
            const { data: defaultEAOGRoles } = await ownershipGroupItemsDataService.getEmergencyAccessGroupRoles(vm.defaultEAOwnershipGroupId);
            if (!defaultEAOGRoles.some(role => role.Identifier === vm.role.Identifier)) {
                vm.approversNeeded = true;
                setupAvailableUsersGrid();
            } else {
                vm.dataLoading = false;
            }
        } else {
            vm.dataLoading = false;
        }
    }

    function setupAvailableUsersGrid() {
        const availableUsersGridColumns = [
            { selectable: true, width: 50 },
            { field: "UserLogonName", title: "Name", filterable: customAutoCompleteFilter }
        ];

        vm.availableUsersGridOptions = helperService.setMainGridOptions(availableUsersGridColumns, 300);
        vm.availableUsersGridOptions.selectable = false;
        vm.availableUsersGridOptions.persistSelection = true;

        vm.availableUsersGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: {
                model: {
                    id: "UserID"
                }
            },
            transport: {
                read: async options => {
                    let { data } = await dataService.getUsers();
                    data = data.filter(user => user.Active);
                    data = $filter('orderBy')(data, 'UserLogonName');
                    options.success(data);
                    vm.dataLoading = false;
                }
            }
        });
    }

    function approversSelectionChanged() {
        vm.selectedApproverIds = vm.availableUsersGrid.selectedKeyNames();
    }

    async function editSystemRole() {
        vm.savePending = new createSpinner();
        const data = {
            SecurityRoleId: vm.role.ID,
            SecurityRoleTypeId: vm.role.TypeID,
            ProductId: vm.role.ProductID,
            IsAssignable: vm.role.IsAssignable,
            IsAutoApproved: vm.role.IsAutoApproved,
            IgnoreCertification: vm.role.IgnoreCertification,
            Reviewed: vm.role.Reviewed,
            IsEmergencyAccess: vm.role.IsEmergencyAccess,
            EmergencyAccessRequest: vm.role.IsEmergencyAccess ? { GroupId: vm.defaultEAOwnershipGroupId, SecurityRoleIdentifier: vm.role.Identifier, UserIds: vm.selectedApproverIds } : null
        }

        try {
            await maintainSystemRolesDataService.updateSystemRole(data);
            await helperService.successfulSaveButton(vm.savePending);
            close();
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function close() {
        $uibModalInstance.close();
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}