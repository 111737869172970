import angular from 'angular';
import * as filters from './Filters/customFilters';
import ErpLabelFilter from './Filters/ErpLabelFilter';


export default angular
    .module('app.shared.filters', [])
    .filter('removeSpaces', filters.removeSpaces)
    .filter('startFrom', filters.startFrom)
    .filter('removeBlankDate', filters.removeBlankDate)
    .filter('removeProductFromReportName', filters.removeProductFromReportName)
    .filter('removeStringFromString', filters.removeStringFromString)
    .filter('removeDetailedFromString', filters.removeDetailedFromString)
    .filter('removeRoleDetailFromString', filters.removeRoleDetailFromString)
    .filter('addLockNextToTextInOISelect_dropdownFilter', filters.addLockNextToTextInOISelect_dropdownFilter)
    .filter('readableUrlRoute', filters.readableUrlRoute)
    .filter('booleanToYesNo', filters.booleanToYesNo)
    .filter('insertSpaceBetweenCapitalWords', filters.insertSpaceBetweenCapitalWords)
    .filter('insertSpaceBetweenCapitalWordsAndAcronyms', filters.insertSpaceBetweenCapitalWordsAndAcronyms)
    .filter('titleCase', filters.titleCase)
    .filter('readableUrlItem', filters.readableUrlItem)
    .filter('convertProductIdToProductName', filters.convertProductIdToProductName)
    .filter('translateObjectIdToObjectName', filters.translateObjectIdToObjectName)
    .filter('stripFirstName', filters.stripFirstName)
    .filter('showIfNotDuplicate', filters.showIfNotDuplicate)
    .filter('addLockIconNextToText', filters.addLockIconNextToText)
    .filter('addPipeBeforeWord', filters.addPipeBeforeWord)
    .filter('dateDiff', filters.dateDiff)
    .filter('filterResults', filters.filterResults)
    .filter('makeStringPlural', filters.makeStringPlural)
    .filter('ERPTranslate', ErpLabelFilter)
    .filter('unique', filters.unique)
    .filter('makeStringPluralWithCount', filters.makeStringPluralWithCount)
    .name;