/* controlLibrary.component.js */

import controlLibraryController from "./controlLibrary.controller";

/**
 * @desc component for Control Library
 * @example <control-library></control-library>
 */

const controlLibrary = {
    controller: controlLibraryController,
    controllerAs: 'clvm',
    templateUrl: '/App/Components/controlLibrary/controlLibrary.html'
};

export default controlLibrary;