import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, FileSaver, riskLevelsData, businessCyclesDataService, mitigationsDataService, statusDataService, controlLibraryDataService, configurationDataService, riskRulesetService, risk, rulesetId) {

    const vm = this;
    vm.attachments = {};
    vm.risk = Object.assign({}, risk);

    // Set up form fields for create or edit
    if (!vm.risk.Id) {
        vm.risk.IsDefaultMitigated = false;
        vm.risk.Enabled = true;
        vm.risk.BusinessCycle = null;
    } else {
        vm.risk.RiskLevel = vm.risk.RiskLevel ? vm.risk.RiskLevel.Id : vm.risk.RiskLevel;
        vm.risk.Status = vm.risk.Status ? vm.risk.Status.ID : vm.risk.Status;
        vm.risk.Mitigation = vm.risk.Mitigation ? vm.risk.Mitigation.ID : vm.risk.Mitigation;
        vm.risk.BusinessCycle = vm.risk.BusinessCycle ? vm.risk.BusinessCycle.ID : vm.risk.BusinessCycle;
    }

    vm.riskFormSubmit = riskFormSubmit;
    vm.removeFile = removeFile;
    vm.downloadFile = downloadFile;
    vm.cancel = cancel;
    vm.clearDefaultMitigationFields = clearDefaultMitigationFields;

    vm.riskTypesDataSource = [
        {
            Name: 'Segregation of Duties',
            Id: 1
        },
        {
            Name: 'Sensitive Access',
            Id: 2
        }
    ];

    activate();

    function activate() {
        getStandardMitigations();
        getRiskLevels();
        getBusinessCycles();
        getMitigations();
        getStatuses();
        getControls();
    }

    function riskFormSubmit() {
        vm.savingRisk = new createSpinner();
        vm.disableSubmit = true;
        if (vm.risk.Id) {
            updateRisk();
        } else {
            addNewRisk();
        }
    }

    async function getStandardMitigations() {
        const response = await configurationDataService.getStandardMitigations();
        vm.standardMitigations = response.data;
    }

    async function getRiskLevels() {
        const response = await riskLevelsData.getRiskLevels();
        vm.riskLevelDataSource = response.data;
    }

    async function getBusinessCycles() {
        const response = await businessCyclesDataService.getBusinessCycles();
        vm.businessCycleDataSource = response.data;
    }

    async function getMitigations() {
        const response = await mitigationsDataService.getMitigations();
        vm.mitigationDataSource = response.data;
    }

    async function getStatuses() {
        const response = await statusDataService.getStatuses();
        vm.statusDataSource = response.data;

        vm.canCurrentUserAccess = response.data.some(data => data.CanCurrentUserAccess === true);
    }

    async function getControls() {
        const response = await controlLibraryDataService.getControls();
        vm.controlLibrary = response.data;
    }

    async function addNewRisk() {
        const postBody = {
            "AssignedUserId": null,
            "Name": vm.risk.Name,
            "Description": vm.risk.Description,
            "IsDefaultMitigated": vm.risk.IsDefaultMitigated,
            "MitigationId": vm.risk.Mitigation,
            "MitigationNotes": vm.risk.MitigationNotes,
            "RiskLevelId": vm.risk.RiskLevel,
            "StatusId": vm.risk.Status,
            "BusinessCycle": vm.risk.BusinessCycle,
            "RiskObjects": vm.risk.Objects,
            "Policy": vm.risk.Policy === "" ? null : vm.risk.Policy,
            'Enabled': vm.risk.Enabled,
            'RulesetId': rulesetId,
            'Controls': vm.risk.Controls ? vm.risk.Controls.map(control => control.Identifier) : null,
            'RiskType': vm.risk.RiskType
        };

        try {
            const response = await riskRulesetService.addNewRisk(postBody);
            if (vm.attachments.flow.files.length) {
                uploadNewFiles(vm.attachments.flow.files, response.data, true);
            } else {
                vm.successText = 'created';
                await helperService.successfulSaveButton(vm.savingRisk);
                vm.disableSubmit = false;
                close({ riskId: response.data, isNewRecord: true });
            }
        } catch (_) {
            vm.savingRisk.loadingValue = false;
            vm.disableSubmit = false;
        }
    }

    async function updateRisk() {

        const putBody = {
            "Id": vm.risk.Id,
            "Name": vm.risk.Name,
            "Description": vm.risk.Description,
            "IsDefaultMitigated": vm.risk.IsDefaultMitigated,
            "MitigationId": vm.risk.Mitigation,
            "MitigationNotes": vm.risk.MitigationNotes,
            "RiskLevelId": vm.risk.RiskLevel,
            "StatusId": vm.risk.Status,
            "BusinessCycle": vm.risk.BusinessCycle,
            "Policy": vm.risk.Policy === "" ? null : vm.risk.Policy,
            'Enabled': vm.risk.Enabled,
            'Controls': vm.risk.Controls ? vm.risk.Controls.map(control => control.Identifier) : null,
            'RiskType': vm.risk.RiskType
        };

        try {
            await riskRulesetService.updateRisk(vm.risk.Id, putBody);
            if (vm.attachments.flow.files.length) {
                uploadNewFiles(vm.attachments.flow.files, vm.risk.Id, false);
            } else {
                vm.successText = 'updated';
                await helperService.successfulSaveButton(vm.savingRisk);
                vm.disableSubmit = false;
                close({ riskId: vm.risk.Id, isNewRecord: false });
            }
        } catch (_) {
            vm.savingRisk.loadingValue = false;
            vm.disableSubmit = false;
        }
    }

    function clearDefaultMitigationFields() {
        vm.risk.Mitigation = null;
        vm.risk.Status = null;
        vm.risk.MitigationNotes = null;
    }

    function close(risk) {
        $uibModalInstance.close(risk);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }

    async function removeFile(file) {
        vm.fileActionPending = true;

        try {
            await controlLibraryDataService.deleteAttachment(file.Identifier);
            const index = vm.risk.Attachments.findIndex(remainingFile => remainingFile.Identifier === file.Identifier);
            vm.risk.Attachments.splice(index, 1);
            vm.fileActionPending = false;
        } catch (_) {
            vm.fileActionPending = false;
        }
    }

    async function downloadFile(file) {
        vm.fileActionPending = true;

        try {
            let response = await controlLibraryDataService.downloadAttachment(file.Identifier);
            FileSaver.saveAs(response.data, file.FileName);
            vm.fileActionPending = false;
        } catch (_) {
            vm.fileActionPending = false;
        }
    }

    async function uploadNewFiles(files, riskId, isNewRecord) {
        let fd = new FormData();
        files.forEach((file, i) => {
            fd.append(files[i].file.name, files[i].file);
        });

        fd.append("DataType", "Risk");
        fd.append("DataTypeId", riskId);

        try {
            await controlLibraryDataService.addAttachments(fd);
            vm.successText = isNewRecord ? 'created' : 'updated';
            await helperService.successfulSaveButton(vm.savingRisk);
            vm.disableSubmit = false;
            close({ riskId: riskId, isNewRecord: isNewRecord });
        } catch (_) {
            vm.savingRisk.loadingValue = false;
            vm.disableSubmit = false;
            return;
        }
    }

}