import swal from "sweetalert2";
import companyTemplateModalController from "../CompanyTemplateModal/companyTemplateModal.controller";

export default /*@ngInject*/ function ($filter, $uibModal, companyTemplatesData, helperService) {

    const vm = this;

    vm.$onInit = getCompanyTemplates;

    vm.openCompanyTemplateModal = openCompanyTemplateModal;
    vm.changeSelectedCompanyTemplate = changeSelectedCompanyTemplate;


    async function getCompanyTemplates(preselectedId) {
        let response = await companyTemplatesData.getCompanyTemplates();
        if (response.data.length > 0) {
            response.data.forEach(template => {
                template.Companies = template.Companies.filter(company => company.CompanyMaster.UserHasAccess);
                template.Companies.forEach(templateCompany => templateCompany.CompanyIds = templateCompany.Companies.map(object => object.ID));
            });

            response.data = $filter('orderBy')(response.data, 'Name');
            if (preselectedId) {
                const companyTemplate = response.data.find(t => t.Id === preselectedId);
                response.data = response.data.filter(t => t.Id !== preselectedId);
                response.data.unshift(companyTemplate);
                changeSelectedCompanyTemplate(companyTemplate);
            }
        }
        vm.companyTemplates = response.data;
    }

    function changeSelectedCompanyTemplate(template) {
        vm.template = template;
        vm.onSelectTemplate({ template });
    }

    async function deleteCompanyTemplate(template) {
        try {
            await swal(helperService.areYouSureParamsWithHTML('Delete Company Template',
                `<div>Are you sure you want to delete
                <div><strong>${template.Name}</strong>?</div>
            </div>`, 'Delete'));
            await companyTemplatesData.deleteCompanyTemplate(template.Id);
            if (vm.template && vm.template.Id === template.Id) {
                vm.onSelectTemplate(null);
            }
            getCompanyTemplates();
        } catch {
            return;
        }
    }

    vm.menuOptions = [
        {
            text: 'Edit',
            click: item => {
                openCompanyTemplateModal('Edit', vm.companyTemplates, item.template);
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: item => {
                deleteCompanyTemplate(item.template);
            }
        }
    ];

    async function openCompanyTemplateModal(actionString, companyTemplates, selectedTemplate) {
        const companyTemplateModal = $uibModal.open({
            templateUrl: '/App/Components/CompanyTemplates/CompanyTemplateModal/companyTemplateModal.html',
            controller: companyTemplateModalController,
            controllerAs: 'ctmvm',
            backdrop: 'static',
            resolve: {
                actionString: () => actionString,
                companyTemplates: () => companyTemplates,
                selectedTemplate: () => selectedTemplate
            }
        });

        try {
            const newTemplateId = await companyTemplateModal.result;
            getCompanyTemplates(newTemplateId);
        } catch {
            return;
        }
    }

}
