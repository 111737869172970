export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getRoutingCriteria,
        postRoutingCriteria,
        deleteRoutingCriteria,
        getSchedules,
        getInstances,
        getInstanceAssignments,
        createSchedule,
        createInstance,
        cloneInstance,
        updateSchedule,
        updateInstance,
        deleteInstance,
        deleteInstanceSchedule,
        deleteReviewInstanceAssignment,
        runSchedule
    };

    return service;

    async function getRoutingCriteria(reviewAssignmentId) {
        try {
            return await $http.get(`${apiUrl}api/reviewassignments/${reviewAssignmentId}/routing-criteria`);
        }
        catch (err) {
            showError(err);
        }
    }

    async function postRoutingCriteria(reviewAssignmentId, data) {
        try {
            return await $http.post(`${apiUrl}api/reviewassignments/${reviewAssignmentId}/routing-criteria`, data);
        }
        catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteRoutingCriteria(reviewAssignmentId, id) {
        try {
            return await $http.delete(`${apiUrl}api/reviewassignments/${reviewAssignmentId}/routing-criteria/${id}`);
        }
        catch (err) {
            showError(err);
        }
    }

    async function getSchedules() {
        try {
            return await $http.get(`${apiUrl}api/certifications/reviewschedules`);
        }
        catch (err) {
            showError(err);
        }
    }

    async function getInstances() {
        try {
            return await $http.get(`${apiUrl}api/certifications/reviewinstances`);
        }
        catch (err) {
            showError(err);
        }
    }

    async function getInstanceAssignments(instanceId) {
        try {
            return await $http.get(`${apiUrl}api/certifications/reviewassignments/reviewinstance/${instanceId}`);
        }
        catch (err) {
            showError(err);
        }
    }

    async function createSchedule(data) {
        try {
            return await $http.post(`${apiUrl}api/certifications/reviewschedules`, data);
        }
        catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function createInstance(data) {
        try {
            return await $http.post(`${apiUrl}api/certifications/reviewinstances`, data);
        }
        catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function cloneInstance(data) {
        try {
            return await $http.post(`${apiUrl}api/certifications/reviewinstances/clone`, data);
        }
        catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateSchedule(scheduleId, data) {
        try {
            return await $http.put(`${apiUrl}api/certifications/reviewschedules/${scheduleId}`, data);
        }
        catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateInstance(data) {
        try {
            return await $http.put(`${apiUrl}api/certifications/reviewInstances/${data.Id}`, data);
        }
        catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteInstance(instanceId) {
        try {
            return await $http.delete(`${apiUrl}api/certifications/reviewInstances/${instanceId}`);
        }
        catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteInstanceSchedule(scheduleId) {
        try {
            return await $http.delete(`${apiUrl}api/certifications/reviewschedules/${scheduleId}`);
        }
        catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteReviewInstanceAssignment(assignmentId) {
        try {
            return await $http.delete(apiUrl + 'api/certifications/reviewassignments/' + assignmentId);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function runSchedule(scheduleId) {
        try {
            return await $http.post(`${apiUrl}api/certifications/reviewschedules/${scheduleId}/run`);
        }
        catch (err) {
            showError(err);
            sendError(err);
        }
    }



    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }

}