export default /*@ngInject*/ function ($scope) {

    //If $scope variable doesnt exist in child it looks in parent
    $scope.addPermission = function (permission) {
        $scope.vm.ListOfAvailableSalesforcePermissionSets.forEach((row, i) => {
            if (row.Id === permission.Id) {
                $scope.vm.ListOfAvailableSalesforcePermissionSets.splice(i, 1);
                row.new = true;
                $scope.vm.ListOfSelectedSalesforcePermissionSets.push(row);
            }
        });
        $scope.vm.IsConflictsReviewed = null;
    };

    $scope.removePermission = function (selectedPermission) {
        $scope.vm.ListOfSelectedSalesforcePermissionSets.forEach((row, i) => {
            if (row.Id === selectedPermission.Id) {
                $scope.vm.ListOfSelectedSalesforcePermissionSets.splice(i, 1);
                row.new = false;
                $scope.vm.ListOfAvailableSalesforcePermissionSets.push(row);
            }
        });
    };

    $scope.filterSalesforcePermissions = function (permission) {
        //Only showing newly added and pending permissions when license has changed
        return !$scope.vm.salesforceHideAssignedPermissionSets || permission.new || permission.Pending;
    }
}