/* controlManagement.component.js */

import controlManagementController from "./controlManagement.controller";

/**
 * @desc component for displaying the report toolbar
 * @example <control-management></control-management>
 */

const controlManagement = {
    controller: controlManagementController,
    controllerAs: 'cmvm',
    templateUrl: '/App/Components/TransactionMonitoring/ControlManagement/controlManagement.tmpl.html'

};

export default controlManagement;