import newRoleModalController from "./newRoleModal.controller";
import swal from "sweetalert2";
import { customAutoCompleteFilter } from "../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($rootScope, $uibModal, $filter, helperService, rolesDataService) {

    const vm = this;

    vm.openNewRoleModal = openNewRoleModal;
    vm.deleteRole = deleteRole;
    vm.selectedRoleChanged = selectedRoleChanged;
    vm.selectedModuleChanged = selectedModuleChanged;
    vm.search;
    vm.searchChanged = searchChanged;
    vm.changeFilter = changeFilter;
    vm.selectRoles = selectRoles;
    vm.selectAllRoles = selectAllRoles;
    vm.selectedType = {};
    vm.dropdownFilters = [
        { name: "All Types", id: null },
        { name: "Place", id: 2 },
        { name: "Report", id: 3 },
        { name: "Dialog", id: 4 },
        { name: "Action", id: 5 },
        { name: "Data", id: 6 }
    ]

    activate();

    async function activate() {
        vm.selectedType = {};
        vm.selectedRole = null;

        let response = await rolesDataService.getModules();
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.modulesDataSource = response.data;
        getRoles(null);
    }

    async function openNewRoleModal() {
        let newRoleModal = $uibModal.open({
            templateUrl: '/App/Components/Roles/newRoleModal.html',
            controller: newRoleModalController,
            controllerAs: 'nrmvm',
            backdrop: 'static'
        });

        try {
            let newRoleId = await newRoleModal.result;
            getRoles(newRoleId);
        } catch (_) {
            return;
        }
    }

    async function getRoles(preselect) {
        vm.selectedModule = null;

        let response = await rolesDataService.getRoles();
        if (preselect) {
            let mostRecentRole = response.data.find(r => r.ID === preselect);
            response.data = response.data.filter(i => i.ID !== preselect);
            response.data.unshift(mostRecentRole);
            vm.rolesDataSource = response.data;
            selectedRoleChanged(mostRecentRole);
        } else {
            response.data = $filter('orderBy')(response.data, 'Name');
            vm.selectedRole = null;
            vm.rolesDataSource = response.data;
        }
    }

    async function deleteRole(role) {
        try {
            await swal(helperService.areYouSureParams('Delete Role', 'Are you sure you want to delete role ' + role.Name + '?', 'Delete'));
            await rolesDataService.removeRole(role.ID);
            getRoles(null);
        } catch (_) {
            return;
        }
    }

    function selectedRoleChanged(event, selectedRole) {
        var isRemoval = event.target.classList.contains("fa-trash-o");

        if (selectedRole !== vm.selectedRole && !isRemoval) {
            vm.selectedRole = selectedRole;
            vm.selectedModule = null;
            vm.selectedType = {};
        }
    }

    function selectedModuleChanged(selectedModule) {
        vm.selectedModule = selectedModule;
        if (!vm.homeRolesGridDataSource) {
            createHomeRolesGrid();
        } else {
            vm.homeRolesGridDataSource.read();
            vm.selectedType = {};
            changeFilter(null);
        }
    }

    async function selectRoles(roles) {
        vm.selectionProcessing = true;
        //The Value of the checkbox determines which event to fire
        let selectedCheckboxValue = roles[0]?.SelectedCheckbox;
        let data = {
            "RoleAccesses": []
        };
        roles.forEach(role => {
            data.RoleAccesses.push({
                "RoleId": vm.selectedRole.ID,
                "ObjectId": role.ObjectID
            });
        });
        if (selectedCheckboxValue === true) {
            await rolesDataService.addRoleAccess(data);
        } else {
            await rolesDataService.removeRoleAccess(data);
        }
        setColumnHeaderToggle(vm.homeRolesGridDataSource.view());
        vm.selectionProcessing = false;
        vm.allSelectionProcessing = false;
        $rootScope.$broadcast('navigation-or-security-change');
    }

    function changeFilter(dropdownFilter) {
        let dynamicFilter = {};

        if (!dropdownFilter?.id) {
            dynamicFilter = {};
        } else {
            dynamicFilter = { "filters": [{ "operator": "eq", "value": dropdownFilter.id, "field": "TypeID" }], "logic": "and" };
        }

        vm.homeRolesGridDataSource.filter(angular.fromJson(dynamicFilter));
        setColumnHeaderToggle(vm.homeRolesGridDataSource._data);
        searchChanged();
    }

    function createHomeRolesGrid() {
        function createObjectModel(TypeID, TypeName, Name, ObjectID, SelectedCheckbox) {
            let objectModel = {
                "TypeName": TypeName,
                "TypeID": TypeID,
                "Name": Name,
                "ObjectID": ObjectID,
                "SelectedCheckbox": SelectedCheckbox
            };

            return objectModel;
        }

        const homeRolesGridColumns = [
            { title: " ", headerTemplate: `<label class="switch"><input type="checkbox" ng-disabled="rvm.allSelectionProcessing" ng-model="rvm.allSelectedCheckbox" ng-change="rvm.selectAllRoles()"><i></i></label>`, template: `<label class="switch"><input type="checkbox" ng-model="dataItem.SelectedCheckbox" ng-disabled="rvm.allSelectionProcessing || rvm.selectionProcessing" ng-change="rvm.selectRoles([dataItem])"><i></i></label>`, width: 55, filterable: false },
            { field: "Name", filterable: customAutoCompleteFilter },
            { field: "TypeName", title: "Type", filterable: customAutoCompleteFilter }
        ];

        vm.homeRolesGridOptions = helperService.setNonPageableGridOptions(homeRolesGridColumns);

        vm.homeRolesGridOptions.noRecords = {
            template: "<div class='no-data-to-display-message'>No Securable Objects</div>"
        }

        vm.homeRolesGridDataSource = new kendo.data.DataSource({
            transport: {
                read: async options => {

                    let modules = await rolesDataService.getModulesByObjectId(vm.selectedModule.ObjectID);

                    //removes modules from grid
                    modules.data.forEach(function (row, i) {
                        if (row.TypeID === 1) {
                            modules.data.splice(i, 1);
                        }
                    });

                    modules.data = $filter('orderBy')(modules.data, 'Name');

                    let objects = [];
                    for (let i = 0; i < modules.data.length; i++) {
                        let item = modules.data[i];
                        objects.push(createObjectModel(item.TypeID, item.TypeName, item.Name, item.ObjectID, false));
                    }

                    let securableobjects = await rolesDataService.getSecurableObjects(vm.selectedRole.ID, vm.selectedModule.ObjectID);

                    for (let i = 0; i < securableobjects.data.length; i++) {
                        let item = securableobjects.data[i];

                        for (let j = 0; j < objects.length; j++) {
                            if (objects[j].ObjectID === item.ObjectID) {
                                objects[j].SelectedCheckbox = true;
                                break;
                            }
                        }

                    }
                    setColumnHeaderToggle(objects);
                    options.success(objects);
                }
            }
        });
    }

    async function selectAllRoles() {
        vm.allSelectionProcessing = true;
        kendo.ui.progress(vm.homeRolesGrid.element, true);
        let roles = vm.homeRolesGridDataSource._data.filter(role => role.SelectedCheckbox !== vm.allSelectedCheckbox);
        if (vm.selectedType.id) {
            roles = roles.filter(role => role.TypeID === vm.selectedType.id);
            roles.forEach(async role => {
                role.SelectedCheckbox = vm.allSelectedCheckbox;
            });
        } else {
            roles.forEach(async role => {
                if (role.SelectedCheckbox !== vm.allSelectedCheckbox) {
                    role.SelectedCheckbox = vm.allSelectedCheckbox;
                }
            });
        }

        roles = roles.filter(role => !vm.search || role.Name.toLowerCase().includes(vm.search));

        if (roles.length) {
            await selectRoles(roles);
        } else {
            vm.allSelectedCheckbox = false;
        }

        vm.allSelectionProcessing = false;
        kendo.ui.progress(vm.homeRolesGrid.element, false);
    }

    function setColumnHeaderToggle(objects) {
        if (vm.selectedType.id) {
            objects = objects.filter(object => object.TypeID === vm.selectedType.id);
        }

        vm.allSelectedCheckbox = objects.length && objects.every(object => object.SelectedCheckbox);
    }

    function searchChanged() {
        let currentFilter = vm.homeRolesGridDataSource.filter(),
            dropdownFilter = currentFilter?.filters.length && currentFilter.filters.find(filter => filter.field === "TypeID") || {},
            filter = {
                filters: [
                    { field: "Name", operator: "contains", value: `${vm.search}` }
                ]
            };

        if (dropdownFilter.field && vm.search) {
            filter.logic = "and";
            filter.filters.push(dropdownFilter);
        } else if (!dropdownFilter.field && !vm.search) {
            filter = {};
        } else if (dropdownFilter.field && !vm.search) {
            filter.filters = [dropdownFilter];
        }

        if (filter?.filters?.length) {
            vm.homeRolesGridDataSource.filter(filter);
        }

        setColumnHeaderToggle(vm.homeRolesGridDataSource.view());
    }
}