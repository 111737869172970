import angular from 'angular';
import RequestManagementAXLoadDataService from '../Components/RequestManagement/RequestManagementAXLoadDataService';
import RequestManagementAXService from '../Components/RequestManagement/RequestManagementAXService';
import RequestManagementBuildRequestService from '../Components/RequestManagement/RequestManagementBuildRequestService';
import RequestManagementD365SLoadDataService from '../Components/RequestManagement/RequestManagementD365SLoadDataService';
import RequestManagementD365SService from '../Components/RequestManagement/RequestManagementD365SService';
import RequestManagementEffectiveDateService from '../Components/RequestManagement/RequestManagementEffectiveDateService';
import RequestManagementGPLoadDataService from '../Components/RequestManagement/RequestManagementGPLoadDataService';
import RequestManagementGPService from '../Components/RequestManagement/RequestManagementGPService';
import RequestManagementLoadDataService from '../Components/RequestManagement/RequestManagementLoadDataService';
import RequestManagementNAVLoadDataService from '../Components/RequestManagement/RequestManagementNAVLoadDataService';
import RequestManagementNAVService from '../Components/RequestManagement/RequestManagementNAVService';
import RequestManagementNetSuiteLoadDataService from '../Components/RequestManagement/RequestManagementNetSuiteLoadDataService';
import RequestManagementNetSuiteService from '../Components/RequestManagement/RequestManagementNetSuiteService';
import RequestManagementOracleCloudLoadDataService from '../Components/RequestManagement/RequestManagementOracleCloudLoadDataService';
import RequestManagementOracleCloudService from '../Components/RequestManagement/RequestManagementOracleCloudService';
import RequestManagementOracleLoadDataService from '../Components/RequestManagement/RequestManagementOracleLoadDataService';
import RequestManagementOracleService from '../Components/RequestManagement/RequestManagementOracleService';
import RequestManagementSAPLoadDataService from '../Components/RequestManagement/RequestManagementSAPLoadDataService';
import RequestManagementSAPService from '../Components/RequestManagement/RequestManagementSAPService';
import RequestManagementShowStepsService from '../Components/RequestManagement/RequestManagementShowStepsService';
import RequestManagementStepDefinitions from '../Components/RequestManagement/RequestManagementStepDefinitions';
import RequestManagementWizardController from '../Components/RequestManagement/RequestManagementWizardController';
import RequestManagementUPLoadDataService from '../Components/RequestManagement/RequestManagementUPLoadDataService';
import RequestManagementUPService from '../Components/RequestManagement/RequestManagementUPService';
import salesforceLoadData from '../Components/RequestManagement/salesforceLoadData';
import salesforceService from '../Components/RequestManagement/salesforceDataService.factory';
import RequestManagementAADService from '../Components/RequestManagement/RequestManagementAADService';
import RequestManagementAADLoadDataService from '../Components/RequestManagement/RequestManagementAADLoadDataService';
import RequestManagementPSService from '../Components/RequestManagement/RequestManagementPSService';
import RequestManagementPSLoadDataService from '../Components/RequestManagement/RequestManagementPSLoadDataService';

export default angular
    .module('app.identityManager.requestManagement.services', [])
    .service('RequestManagementUPLoadDataService', RequestManagementUPLoadDataService)
    .service('RequestManagementUPService', RequestManagementUPService)
    .service('RequestManagementAADService', RequestManagementAADService)
    .service('salesforceLoadData', salesforceLoadData)
    .service('salesforceService', salesforceService)
    .service('RequestManagementAADLoadDataService', RequestManagementAADLoadDataService)
    .service('RequestManagementAXLoadDataService', RequestManagementAXLoadDataService)
    .service('RequestManagementAXService', RequestManagementAXService)
    .service('RequestManagementBuildRequestService', RequestManagementBuildRequestService)
    .service('RequestManagementD365SLoadDataService', RequestManagementD365SLoadDataService)
    .service('RequestManagementD365SService', RequestManagementD365SService)
    .service('RequestManagementEffectiveDateService', RequestManagementEffectiveDateService)
    .service('RequestManagementGPLoadDataService', RequestManagementGPLoadDataService)
    .service('RequestManagementGPService', RequestManagementGPService)
    .service('RequestManagementLoadDataService', RequestManagementLoadDataService)
    .service('RequestManagementNAVLoadDataService', RequestManagementNAVLoadDataService)
    .service('RequestManagementNAVService', RequestManagementNAVService)
    .service('RequestManagementNetSuiteLoadDataService', RequestManagementNetSuiteLoadDataService)
    .service('RequestManagementNetSuiteService', RequestManagementNetSuiteService)
    .service('RequestManagementOracleCloudLoadDataService', RequestManagementOracleCloudLoadDataService)
    .service('RequestManagementOracleCloudService', RequestManagementOracleCloudService)
    .service('RequestManagementOracleLoadDataService', RequestManagementOracleLoadDataService)
    .service('RequestManagementOracleService', RequestManagementOracleService)
    .service('RequestManagementPSLoadDataService', RequestManagementPSLoadDataService)
    .service('RequestManagementPSService', RequestManagementPSService)
    .service('RequestManagementSAPLoadDataService', RequestManagementSAPLoadDataService)
    .service('RequestManagementSAPService', RequestManagementSAPService)
    .service('RequestManagementShowStepsService', RequestManagementShowStepsService)
    .service('RequestManagementStepDefinitions', RequestManagementStepDefinitions)
    .service('RequestManagementWizardController', RequestManagementWizardController)
    .name;