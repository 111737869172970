import maintainOptionSetsController from "./maintainOptionSets.controller";

/* maintainOptionSets.component.js */

/**
 * @desc component for displaying and modifying option sets
 * @example <maintain-option-sets></maintain-option-sets>
 */

const maintainOptionSets = {
    controller: maintainOptionSetsController,
    controllerAs: 'osvm',
    templateUrl: '/App/Components/NetSuiteAuditTrail/MaintainOptionSets/maintainOptionSets.tmpl.html'
};

export default maintainOptionSets;