export default /*@ngInject*/ function ($http, errorsDataService) {
    var service = {
        getReportSchedules,
        getReportScheduleExecutions,
        deleteSchedule,
        deleteScheduleOutput,
        downloadExecutionFile,
        runReportSchedule
    };

    return service;

    async function getReportSchedules() {
        try {
            return await $http.get(`${apiUrl}api/core/reportschedules`)
        } catch (err) {
            showError(err);
        }
    }

    async function getReportScheduleExecutions(id) {
        try {
            return await $http.get(`${apiUrl}api/core/reportschedules/${id}/executions`)
        } catch (err) {
            showError(err);
        }
    }

    async function deleteSchedule(id) {
        try {
            return await $http.delete(`${apiUrl}api/core/reportschedules/${id}`)
        } catch (err) {
            showError(err);
        }
    }

    async function deleteScheduleOutput(id, ScheduleID) {
        try {
            return await $http.delete(`${apiUrl}api/core/reportschedules/${ScheduleID}/outputs/${id}`)
        } catch (err) {
            showError(err);
        }
    }

    async function downloadExecutionFile(fileType, executionId) {
        try {
            return await $http.get(`${apiUrl}api/core/reportschedules/executions/${executionId}/download?fileFormat=${fileType}`, { responseType: "blob" })
        } catch (err) {
            showError(err);
            sendError(err)
        }
    }

    async function runReportSchedule(scheduleId) {
        try {
            return await $http.post(`${apiUrl}api/core/reportschedules/${scheduleId}/run`)
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}