/* userMultiselectDropdown.component.js */

import userMultiselectDropdownController from "./userMultiselectDropdown.controller";

/**
 * @desc component for selecting multiple users from a dropdown
 * @example <user-multiselect-dropdown></user-multiselect-dropdown>
 */

const userMultiselectDropdown = {
    bindings: {
        selectedProduct: '<',
        runSavedReport: '&',
        onUpdateUsers: '&'
    },
    controller: userMultiselectDropdownController,
    controllerAs: 'umdvm',
    templateUrl: '/App/Shared/Components/userMultiselectDropdown/userMultiselectDropdown.tmpl.html'
};

export default userMultiselectDropdown;