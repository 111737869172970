export default /*@ngInject*/ function ($http, errorsDataService) {
    var factory = {
        getBusinessCycles,
        addBusinessCycle,
        modifyBusinessCycle,
        removeBusinessCycle
    };

    return factory;

    async function getBusinessCycles() {
        try {
            return await $http.get(`${apiUrl}api/arm/businesscycles`);
        } catch (error) {
            showError(error);
        }
    }

    async function addBusinessCycle(data) {
        try {
            return await $http.post(`${apiUrl}api/arm/businesscycles`, data);
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    async function modifyBusinessCycle(businessCycleId, data) {
        try {
            return await $http.put(`${apiUrl}api/arm/businesscycles/${businessCycleId}`, data);
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    async function removeBusinessCycle(businessCycleId) {
        try {
            return await $http.delete(`${apiUrl}api/arm/businesscycles/${businessCycleId}`);
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}