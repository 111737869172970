import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $filter, helperService, businessProcessDataService, selectedBusinessProcess) {

    const vm = this;

    vm.cancel = cancel;

    activate();

    function activate() {
        createObjectsGrid();
    }


    function createObjectsGrid() {
        const objectsDataGridColumns = [
            { field: "GroupName", title: "Group", width: 120, filterable: customAutoCompleteFilter },
            { field: "SecurityObject.AltName", title: "Object Label", width: 120, filterable: customAutoCompleteFilter },
            { field: "SecurityObject.Name", title: "Object Name", width: 120, filterable: customAutoCompleteFilter },
            { field: "SecurityObject.SecurityObjectTypeName", title: "Object Type", width: 120, filterable: customAutoCompleteFilter },
            { field: "SecurityObject.ProductName", title: "Product", width: 120, filterable: customAutoCompleteFilter }
        ];

        vm.objectsDataGridOptions = helperService.setMainGridOptions(objectsDataGridColumns, 250);
        vm.objectsDataGridOptions.selectable = false;

        vm.objectsGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let response = await businessProcessDataService.getBusinessProcessObjects(selectedBusinessProcess.BusinessProcessId);
                    vm.businessProcess = await response.data;
                    vm.objects = await response.data.Objects;
                    vm.objects = $filter('orderBy')(vm.objects, 'Group');
                    vm.objects = $filter('orderBy')(vm.objects, 'SecurityObject.AltName');
                    options.success(vm.objects);
                }
            }
        });
    }

    function cancel() { $uibModalInstance.close(); }

}