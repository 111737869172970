/* riskProductProfile.component.js */

import riskProductProfileController from "./riskProductProfile.controller";

/**
 * @desc component for viewing Risk details profile information for a specific product
 * @example <risk-product-profile></risk-product-profile>
 */

const riskProductProfile = {
    bindings: {
        product: '<',
        risk: '<'
    },
    controller: riskProductProfileController,
    controllerAs: 'cppvm',
    templateUrl: '/App/Shared/Components/accessDetailsProfile/riskDetailsProductProfile/riskProductProfile.tmpl.html'
};

export default riskProductProfile;