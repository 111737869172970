export default /*@ngInject*/ function () {

    this.getListOfSteps = function (wizardMode, alternatePath, requestToEdit) {
        var steps = [];
        if (wizardMode === 'New' || wizardMode === 'Modify' && requestToEdit) {
            steps = [
                SelectApplicationsStep,
                UniversalProductUserInformationStep,
                UniversalProductRolesStep,
                AX2012UserInformationStep,
                AX2012RolesStep,
                AX2012UserGroupsStep,
                D365SUserInformationStep,
                D365SRolesStep,
                D365STeamsStep,
                AX7UserInformationStep,
                AX7RolesStep,
                AX7UserGroupsStep,
                AADUserInformationStep,
                AADGroupsStep,
                AADLicensesStep,
                AADRolesStep,
                SalesforceUserInformationStep,
                SalesforceUserProfilesStep,
                SalesforceUserPermissionsStep,
                GPUserInformationStep,
                GPCompaniesStep,
                GPRolesStep,
                NAVUserInformationStep,
                NAVPermissionSetsStep,
                NAVUserGroupsStep,
                NetSuiteUserInformationStep,
                NetSuiteRolesStep,
                NetSuiteGlobalPermissionsStep,
                OracleUserInformationStep,
                OracleRolesStep,
                OracleResponsibilitiesStep,
                OracleCloudUserInformationStep,
                OracleCloudRolesStep,
                PeopleSoftUserInformationStep,
                PeopleSoftRolesStep,
                SAPUserInformationStep,
                SAPSingleRolesStep,
                SAPCompositeRolesStep,
                EffectiveDatesStep,
                RiskAnalysisStep,
                RequestNotesStep,
                SummaryStep
            ];
        } else if (wizardMode === 'Modify' && alternatePath === 'SelfService' && !requestToEdit) {
            steps = [
                SelectApplicationsStep,
                UniversalProductUserInformationStep,
                UniversalProductRolesStep,
                AX2012UserInformationStep,
                AX2012RolesStep,
                AX2012UserGroupsStep,
                D365SUserInformationStep,
                D365SRolesStep,
                D365STeamsStep,
                AX7UserInformationStep,
                AX7RolesStep,
                AX7UserGroupsStep,
                AADUserInformationStep,
                AADGroupsStep,
                AADLicensesStep,
                AADRolesStep,
                SalesforceUserInformationStep,
                SalesforceUserProfilesStep,
                SalesforceUserPermissionsStep,
                GPUserInformationStep,
                GPCompaniesStep,
                GPRolesStep,
                NAVUserInformationStep,
                NAVPermissionSetsStep,
                NAVUserGroupsStep,
                NetSuiteUserInformationStep,
                NetSuiteRolesStep,
                NetSuiteGlobalPermissionsStep,
                OracleUserInformationStep,
                OracleRolesStep,
                OracleResponsibilitiesStep,
                OracleCloudUserInformationStep,
                OracleCloudRolesStep,
                PeopleSoftUserInformationStep,
                PeopleSoftRolesStep,
                SAPUserInformationStep,
                SAPSingleRolesStep,
                SAPCompositeRolesStep,
                EffectiveDatesStep,
                RiskAnalysisStep,
                RequestNotesStep,
                SummaryStep
            ];
        } else if (wizardMode === 'Modify' && alternatePath === 'EmergencyAccess' && !requestToEdit) {
            steps = [
                SelectApplicationsStep,
                SAPSingleRolesStep,
                SAPCompositeRolesStep,
                EmergencyAccessUsageStep,
                EffectiveDatesStep,
                RequestNotesStep,
                SummaryStep
            ];
        } else if (wizardMode === 'Modify' && !requestToEdit) {
            steps = [
                SearchExistingUsersStep,
                SelectApplicationsStep,
                UniversalProductUserInformationStep,
                UniversalProductRolesStep,
                AX2012UserInformationStep,
                AX2012RolesStep,
                AX2012UserGroupsStep,
                D365SUserInformationStep,
                D365SRolesStep,
                D365STeamsStep,
                AX7UserInformationStep,
                AX7RolesStep,
                AX7UserGroupsStep,
                AADUserInformationStep,
                AADGroupsStep,
                AADLicensesStep,
                AADRolesStep,
                SalesforceUserInformationStep,
                SalesforceUserProfilesStep,
                SalesforceUserPermissionsStep,
                GPUserInformationStep,
                GPCompaniesStep,
                GPRolesStep,
                NAVUserInformationStep,
                NAVPermissionSetsStep,
                NAVUserGroupsStep,
                NetSuiteUserInformationStep,
                NetSuiteRolesStep,
                NetSuiteGlobalPermissionsStep,
                OracleUserInformationStep,
                OracleRolesStep,
                OracleResponsibilitiesStep,
                OracleCloudUserInformationStep,
                OracleCloudRolesStep,
                PeopleSoftUserInformationStep,
                PeopleSoftRolesStep,
                SAPUserInformationStep,
                SAPSingleRolesStep,
                SAPCompositeRolesStep,
                EffectiveDatesStep,
                RiskAnalysisStep,
                RequestNotesStep,
                SummaryStep
            ];
        } else if (wizardMode === 'Terminate' && !requestToEdit) {
            steps = [
                SearchExistingUsersStep,
                SelectApplicationsStep,
                RequestNotesStep,
                SummaryStep
            ];
        } else if (wizardMode === 'Terminate' && requestToEdit) {
            steps = [
                SelectApplicationsStep,
                RequestNotesStep,
                SummaryStep
            ];
        }
        //removes reference from array
        var copySteps = angular.copy(steps);
        return copySteps;
    };

    var SearchExistingUsersStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/Universal/SearchExistingUsers.html',
        title: 'Search Existing Users',
        visible: true,
        parent: null
    };
    var SelectApplicationsStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/Universal/SelectApplications.html',
        title: 'Select Applications',
        visible: true,
        parent: null
    };
    var UniversalProductUserInformationStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/UniversalProduct/UPUserInformation.html',
        title: 'User Information',
        visible: false,
        parent: 'Universal'
    }
    var UniversalProductRolesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/UniversalProduct/UPRoles.html',
        title: 'Roles',
        visible: false,
        parent: 'Universal'
    }
    var AX2012UserInformationStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/AX2012/AXUserInformation.html',
        title: 'Dynamics AX User Information',
        visible: false,
        parent: 'Dynamics AX'
    };
    var AX2012RolesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/AX2012/AXRoles.html',
        title: 'Dynamics AX Roles',
        visible: false,
        parent: 'Dynamics AX'
    };
    var AX2012UserGroupsStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/AX2012/AXUserGroups.html',
        title: 'Dynamics AX User Groups',
        visible: false,
        parent: 'Dynamics AX'
    };
    var D365SUserInformationStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/D365S/D365SUserInformation.html',
        title: 'Dynamics 365 Customer Engagement User Information',
        visible: false,
        parent: 'Dynamics 365 Customer Engagement'
    };
    var D365SRolesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/D365S/D365SRoles.html',
        title: 'Dynamics 365 Customer Engagement Roles',
        visible: false,
        parent: 'Dynamics 365 Customer Engagement'
    };
    var D365STeamsStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/D365S/D365STeams.html',
        title: 'Dynamics 365 Customer Engagement Teams',
        visible: false,
        parent: 'Dynamics 365 Customer Engagement'
    };
    var AX7UserInformationStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/AX7/AXUserInformation.html',
        title: 'Dynamics 365 for Finance and Operations User Information',
        visible: false,
        parent: 'Dynamics 365 for Finance and Operations'
    };
    var AX7RolesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/AX7/AXRoles.html',
        title: 'Dynamics 365 for Finance and Operations Roles',
        visible: false,
        parent: 'Dynamics 365 for Finance and Operations'
    };
    var AX7UserGroupsStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/AX7/AXUserGroups.html',
        title: 'Dynamics 365 for Finance and Operations User Groups',
        visible: false,
        parent: 'Dynamics 365 for Finance and Operations'
    };
    var AADUserInformationStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/AAD/AADUserInformation.html',
        title: 'Entra ID User Information',
        visible: false,
        parent: 'Entra ID'
    };
    var AADRolesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/AAD/AADRoles.html',
        title: 'Entra ID Roles',
        visible: false,
        parent: 'Entra ID'
    };
    var AADGroupsStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/AAD/AADGroups.html',
        title: 'Entra ID Groups',
        visible: false,
        parent: 'Entra ID'
    };
    var AADLicensesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/AAD/AADLicenses.html',
        title: 'Entra ID Licenses',
        visible: false,
        parent: 'Entra ID'
    };
    var SalesforceUserInformationStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/Salesforce/salesforceUserForm.tmpl.html',
        title: 'Salesforce User Information',
        visible: false,
        parent: 'Salesforce'
    };
    var SalesforceUserProfilesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/Salesforce/salesforceProfileForm.tmpl.html',
        title: 'Salesforce Profiles',
        visible: false,
        parent: 'Salesforce'
    };
    var SalesforceUserPermissionsStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/Salesforce/salesforcePermissionSets.tmpl.html',
        title: 'Salesforce Permission Sets',
        visible: false,
        parent: 'Salesforce'
    };
    var GPUserInformationStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/GP/GPUserInformation.html',
        title: 'Dynamics GP User Information',
        visible: false,
        parent: 'Dynamics GP'
    };
    var GPRolesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/GP/GPRoles.html',
        title: 'Dynamics GP Roles',
        visible: false,
        parent: 'Dynamics GP'
    };
    var GPCompaniesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/GP/GPCompanies.html',
        title: 'Dynamics GP Companies',
        visible: false,
        parent: 'Dynamics GP'
    };
    var NAVUserInformationStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/NAV/NAVUserInformation.html',
        title: 'Dynamics NAV User Information',
        visible: false,
        parent: 'Dynamics NAV'
    };
    var NAVPermissionSetsStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/NAV/NAVPermissionSets.html',
        title: 'Dynamics NAV Permission Sets',
        visible: false,
        parent: 'Dynamics NAV'
    };
    var NAVUserGroupsStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/NAV/NAVUserGroups.html',
        title: 'Dynamics NAV User Groups',
        visible: false,
        parent: 'Dynamics NAV'
    };
    var NetSuiteUserInformationStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/NetSuite/NSUserInformation.html',
        title: 'NetSuite User Information',
        visible: false,
        parent: 'NetSuite'
    };
    var NetSuiteRolesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/NetSuite/NSRoles.html',
        title: 'NetSuite Roles',
        visible: false,
        parent: 'NetSuite'
    };
    var NetSuiteGlobalPermissionsStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/NetSuite/NSGlobalPermissions.html',
        title: 'NetSuite Global Permissions',
        visible: false,
        parent: 'NetSuite'
    };
    var OracleUserInformationStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/Oracle/ORUserInformation.html',
        title: 'Oracle EBS User Information',
        visible: false,
        parent: 'Oracle EBS'
    };
    var OracleRolesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/Oracle/ORRoles.html',
        title: 'Oracle EBS Roles',
        visible: false,
        parent: 'Oracle EBS'
    };
    var OracleResponsibilitiesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/Oracle/ORResponsibilities.html',
        title: 'Oracle EBS Responsibilities',
        visible: false,
        parent: 'Oracle EBS'
    };
    var OracleCloudUserInformationStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/OracleCloud/ORCloudUserInformation.html',
        title: 'Oracle Cloud User Information',
        visible: false,
        parent: 'Oracle Cloud'
    };
    var OracleCloudRolesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/OracleCloud/ORCloudRoles.html',
        title: 'Oracle Cloud Roles',
        visible: false,
        parent: 'Oracle Cloud'
    };
    var PeopleSoftUserInformationStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/PeopleSoft/PSUserInformation.html',
        title: 'PeopleSoft User Information',
        visible: false,
        parent: 'PeopleSoft'
    };
    var PeopleSoftRolesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/PeopleSoft/PSRoles.html',
        title: 'PeopleSoft Roles',
        visible: false,
        parent: 'PeopleSoft'
    };
    var SAPUserInformationStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/SAP/SAPUserInformation.html',
        title: 'SAP User Information',
        visible: false,
        parent: 'SAP'
    };
    var SAPSingleRolesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/SAP/SAPRoles.html',
        title: 'SAP Single Roles',
        visible: false,
        parent: 'SAP'
    };
    var SAPCompositeRolesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/SAP/SAPCompositeRoles.html',
        title: 'SAP Composite Roles',
        visible: false,
        parent: 'SAP'
    };
    var EmergencyAccessUsageStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/SAP/emergencyAccess.html',
        title: 'SAP Emergency Access Usage',
        visible: false,
        parent: 'SAP'
    };
    var EffectiveDatesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/Universal/EffectiveDates.html',
        title: 'Access Effective Dates',
        visible: true,
        parent: 'Summary'
    };
    var RiskAnalysisStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/Universal/RiskAnalysis.html',
        title: 'Risk Analysis',
        visible: true,
        parent: 'Summary'
    };
    var RequestNotesStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/Universal/RequestNotes.html',
        title: 'Request Notes',
        visible: true,
        parent: 'Summary'
    };
    var SummaryStep = {
        templateUrl: '/App/Components/RequestManagement/WizardSteps/Universal/Summary.html',
        title: 'Summary',
        visible: true,
        parent: 'Summary'
    };

}