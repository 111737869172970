import saveReportController from "./saveReport.controller";

/* saveReport.component.js */

/**
 * @desc component for saving reports
 * @example <save-report></save-report>
 */

const saveReport = {
   bindings: {
      resolve: '<',
      modalInstance: '<'
   },
    controller: saveReportController,
    controllerAs: 'srvm',
   templateUrl: '/App/Shared/Components/SaveReport/saveReport.tmpl.html'
};

export default saveReport;