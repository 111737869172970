export default /*@ngInject*/ function ($scope, $uibModal, RequestManagementNAVService) {
    $scope.testing = {};

    function getNAVLicenseTypes() {
        $scope.vm.NAVLicenseTypeDataSource = RequestManagementNAVService.getNAVLicenseTypes();
    }

    if (!$scope.vm.User.NAVUser) {
        $scope.vm.User.NAVUser = {};
    }
    $scope.vm.User.NAVUser.ProductType = 'NAV';
    $scope.vm.User.NAVUser.State = '0';

    getNAVLicenseTypes();
    
    //General User
    $scope.vm.nsUserFromDateObject = null;
    $scope.vm.nsUserToDateObject = null;
    $scope.vm.nsUserMnsDate = new Date();
    var nsUserMinDate = new Date();
    nsUserMinDate.setHours(0, 0, 0, 0);

    $scope.vm.nsUserMinDate = nsUserMinDate;

    $scope.nsUserFromDateChanged = function () {
        var date;
        if ($scope.vm.User.NAVUser.StartDate == '') {
            date = new Date();
        } else {
            date = new Date($scope.vm.User.NAVUser.StartDate);
        }

        $scope.vm.nsUserMinDate = date;
        $scope.vm.nsMinDate = date;
        $scope.vm.fromMinDate = date;
        if ($scope.vm.User.NAVUser == null) {
            $scope.vm.User.NAVUser = {};
            $scope.vm.User.NAVUser.State = 0;
        }
    };

    $scope.nsUserToDateChanged = function () {
        $scope.vm.nsUserMnsDate = new Date($scope.vm.User.NAVUser.EndDate);
        $scope.vm.nsMnsDate = new Date($scope.vm.User.NAVUser.EndDate);
        $scope.vm.fromMnsDate = new Date($scope.vm.User.NAVUser.StartDate);
        if ($scope.vm.User.NAVUser == null) {
            $scope.vm.User.NAVUser = {};
            $scope.vm.User.NAVUser.State = 0;
        }
    };

}

