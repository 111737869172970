import { standardGridPageSize, customAutoCompleteFilter, customDatePickerFilter } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";

export default /*@ngInject*/ function ($scope, $rootScope, SavedReportSelectorService, objectIdDataService, netsuiteDataChangesDataService, CustomReportServices, reportsDataService, reportsService, $filter, helperService) {

    const vm = this;

    vm.selectedRecordType = [];
    vm.selectedUsers = [];

    // --- FOR ADVANCED FILTERING ---       
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    // ---

    vm.helperService = helperService;
    vm.populateCriteriaModel = populateCriteriaModel;
    vm.deselectSelectedRecordType = deselectSelectedRecordType;
    vm.userDropdownChange = userDropdownChange;
    vm.generateGridWithSelectedValues = generateGridWithSelectedValues;

    vm.onReadGrid = onReadGrid;
    vm.onApplyFilter = onApplyFilter;
    vm.onClearFilters = onClearFilters;

    $scope.$on('kendoWidgetCreated', function () {
        if (vm.grid) {
            vm.gridReady = true;
            runSavedReport();
        }
    });

    activate();

    async function activate() {
        setUsers();
        setRecordTypes();
        await setObjectId();

        //Grid initialization
        setupDataChangesNSGrid();
        setColumns();
        runSavedReport();

        await setReportMetadata();
        if (!$rootScope.loadedSavedReport || !checkForSavedReportDateFilters()) {
            setupDefaultDateFilter();
            vm.filterCriteria.needsUpdate = true;
        }

        if ($rootScope.loadedSavedReport) {
            runSavedReport();
        }
    }

    async function setObjectId() {
        vm.ObjectId = await objectIdDataService.getObjectID();

        let securityObject = await helperService.getSecurityObjectDetailsFromObjectId(vm.ObjectId);
        vm.reportName = securityObject.Name;
        vm.reportId = securityObject.ReportID;

        vm.filtersDataSource = CustomReportServices.setfiltersDataSource(vm.ObjectId);
    }

    function setupDataChangesNSGrid() {
        vm.mainGridOptions = helperService.setMainGridOptions(null, 600);
        vm.mainGridOptions.autoBind = false;

        vm.filterManagerGridOptions = CustomReportServices.setfilterManagerGridOptions();
        vm.filterDropdownOptions = helperService.getFilterDropdownOptions();

        vm.gridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: angular.extend(halSchemaKendoGrid, {
                model: {
                    fields: {
                        Date: { type: "date" }
                    }
                }
            }),
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: async (options) => {
                    delete $rootScope.loadedSavedReport;

                    let selectedUserIds = [];
                    vm.selectedUsers.forEach(user => {
                        if (user?.InternalId !== 'AllObjectsInMultiSelect') {
                            selectedUserIds.push(user.InternalId);
                        }
                    });

                    let data = {
                        'RecordTypes': vm.recordTypes,
                        'UserInternalIds': selectedUserIds
                    };

                    const params = { 'pagesize': options.data.pageSize, 'page': options.data.page };

                    data['QueryInput'] = helperService.prepareSortsAndFilters(options);

                    // --- FOR ADVANCED FILTERING ---
                    if (vm.filterCriteria.query) {
                        data['QueryInput'].Filters = data['QueryInput'].Filters.concat(vm.filterCriteria.query);
                        data['QueryInput'].DateFilters = vm.filterCriteria.dateQuery;
                    }
                    // ---

                    // if tabs are on, save report info for the tab
                    if ($rootScope.tabs.length > 0) {
                        reportsService.saveTabReportInfo(vm);
                    }

                    try {
                        let response = await netsuiteDataChangesDataService.nsDataChangesReport(data, params);
                        options.success(response.data);
                        vm.enableGrid = true;
                    } catch (err) {
                        options.error(err);
                    }
                }
            }
        });

    }

    async function setUsers() {
        try {
            let response = await netsuiteDataChangesDataService.getUsers();
            response.data = $filter('orderBy')(response.data, 'UserName');
            vm.allUsersDropdownObject = { "UserName": "All Users", "InternalId": "AllObjectsInMultiSelect" };
            await response.data.unshift(vm.allUsersDropdownObject);
            vm.userDataSource = response.data;
            vm.selectedUsers = [vm.allUsersDropdownObject];
            vm.selectedUsers = SavedReportSelectorService.checkSavedReportForUsers($rootScope.loadedSavedReport, response.data, vm.selectedUsers);
            userDropdownChange();
            runSavedReport();
        } catch {
            return;
        }
    }

    function userDropdownChange() {
        vm.selectedUsers = helperService.deselectAllObjectsIfOtherIsClicked(vm.selectedUsers, vm.allUsersDropdownObject);
        if (!vm.selectedUsers?.length) {
            vm.selectedUsers.push(vm.allUsersDropdownObject);
        }
        populateCriteriaModel();
    }

    function populateCriteriaModel() {

        let criteria = [];

        //Used in TicketModal
        vm.product = {
            ID: 'NS',
            Type: 'NS'
        };

        vm.recordTypes = helperService.returnNullIfAllIsFound(vm.selectedRecordType.map(el => el.Id));

        vm.selectedUsers.forEach(function (row) {
            criteria.push(CustomReportServices.CreateCriteria("InternalId", 'User', row.UserName, row.InternalId));
        });

        vm.selectedRecordType.forEach(function (row) {
            criteria.push(CustomReportServices.CreateCriteria("RecordTypeId", 'RecordType', row.Name, row.Id));
        });

        vm.reportDetailsModel = criteria;

        // if tabs are on, save report info for the tab
        if ($rootScope.tabs.length > 0) {
            reportsService.saveTabReportInfo(vm);
        }
    }

    function generateGridWithSelectedValues() {

        if (!$rootScope.loadedSavedReport) {
            vm.grid.dataSource._filter = null;
            vm.grid.dataSource._sort = null;
        }

        populateCriteriaModel();
        vm.hideExportOptionsFromSubmit = true;

        // Add back the sorting and filtering options if we are running the report for the first time
        if (!$rootScope.loadedSavedReport) {
            vm.grid.setOptions({
                filterable: {
                    mode: "row"
                },
                sortable: true
            });
        }
        SavedReportSelectorService.queryGridWithOptions($rootScope.loadedSavedReport, vm.grid, true);
    }


    async function runSavedReport() {
        if (vm.gridReady && $rootScope.loadedSavedReport && vm.recordTypesDataSource && vm.userDataSource) {
            await setReportMetadata();
            // --- FOR ADVANCED FILTERING ---
            vm.filterCriteria.query = reportsService.getSavedReportFilters();
            vm.filterCriteria.dateQuery = reportsService.getSavedReportDateFilters();
            vm.filterCriteria.needsUpdate = true;
            // ---
            generateGridWithSelectedValues();
        } else if (vm.gridReady && !$rootScope.loadedSavedReport && vm.recordTypesDataSource && vm.userDataSource && vm.filterCriteria.fields) {
            // We want the necessary report details available to be able to use the report toolbar without running the report
            populateCriteriaModel();
        }
    }

    function deselectSelectedRecordType() {

        if (vm.selectedRecordType && vm.selectedRecordType[vm.selectedRecordType.length - 1].Id !== 'AllObjectsInMultiSelect') {
            vm.selectedRecordType.forEach(function (row, i) {
                if (row.Id === 'AllObjectsInMultiSelect') {
                    vm.selectedRecordType.splice(i, 1);
                }
            });
        } else {
            vm.selectedRecordType = [{ "DisplayName": "All Record Types", "Id": 'AllObjectsInMultiSelect' }];
        }
        populateCriteriaModel();
    }


    async function setRecordTypes() {
        let response = await netsuiteDataChangesDataService.getRecordTypes();

        response.data = $filter('orderBy')(response.data, 'DisplayName');
        //removes record Type if Insert, Update, Delete are all false
        response.data.remove(el => !el.Insert && !el.Update && !el.Delete);

        const all = { "DisplayName": "All Record Types", "Id": 'AllObjectsInMultiSelect' };

        response.data.unshift(all);
        vm.recordTypesDataSource = response.data;
        vm.selectedRecordType = [all];
        vm.selectedRecordType = SavedReportSelectorService.checkSavedReportForRecordTypeMultiSelect($rootScope.loadedSavedReport, response.data, vm.selectedRecordType);
        deselectSelectedRecordType();
        runSavedReport();
    }

    function onApplyFilter(selectedFilter) {
        const currentOptions = vm.grid.getOptions();

        if (selectedFilter && selectedFilter.Filters && selectedFilter.Filters.length > 0) {
            currentOptions.dataSource.filter = helperService.convertFiltersForGrid(_, selectedFilter.Filters);
            try {
                vm.grid.dataSource._filter = currentOptions.dataSource.filter;
                vm.grid.dataSource.read();
            }
            catch (err) {
                vm.gridDataSource.filter({});
            }
        }
    }

    function setColumns() {
        const rowSettingsTemplate = `<row-settings-template params="dataItem" selected-product="nsdcvm.product" on-delete="nsdcvm.onReadGrid()"></row-settings-template>`;

        const ticketTemplate = "<span ng-if='dataItem.TicketProvider'>{{ dataItem.TicketProvider }} &nbsp; <a href='{{dataItem.TicketUrl}}' target='_blank'>{{dataItem.TicketId}}</a></span>";

        vm.mainGridColumns = [
            { title: "", template: rowSettingsTemplate, attributes: { "class": "no-padding overflow-visible" }, minResizableWidth: 22, width: 22 },
            { field: "TicketDisplayName", title: "Ticket", template: ticketTemplate, filterable: customAutoCompleteFilter },
            { field: "Id", title: "Change Id", filterable: customAutoCompleteFilter },
            { field: "Date", title: "Date", template: "{{ dataItem.Date | date:'short'}}", filterable: customDatePickerFilter },
            { field: "RecordLink", title: "Record Link", template: "<a ng-if='dataItem.RecordLink != null' href='{{dataItem.RecordLink}}' target='_blank'>View</a>", filterable: customAutoCompleteFilter },
            { field: "User", title: "User", filterable: customAutoCompleteFilter },
            { field: "UserRoleId", title: "User Role Id", filterable: customAutoCompleteFilter },
            { field: "UserRoleName", title: "User Role Name", filterable: customAutoCompleteFilter },
            { field: "Event", title: "Event", filterable: customAutoCompleteFilter },
            { field: "RecordType", title: "Record Type", filterable: customAutoCompleteFilter },
            { field: "RecordName", title: "Record Name", filterable: customAutoCompleteFilter },
            { field: "DetailsLink", title: "Details Link", template: "<a ng-if='dataItem.DetailsLink != null' href='{{dataItem.DetailsLink}}' target='_blank'>View</a>", filterable: customAutoCompleteFilter },
            { field: "Context", title: "Context", filterable: customAutoCompleteFilter },
            { field: "Company", title: "Subsidiary", filterable: customAutoCompleteFilter },
            { field: "RecordId", title: "Record Id", filterable: customAutoCompleteFilter }
        ];

        // Don't allow filtering and sorting yet because they will force the report to run
        if (!$rootScope.loadedSavedReport) {
            vm.mainGridOptions.sortable = false;

            vm.mainGridOptions.filterable = false;
        }

        setLayout();
    }

    function onReadGrid() {
        vm.gridDataSource.read();
    }

    function onClearFilters() {
        vm.gridDataSource.filter({});
    }

    function setLayout() {
        if (!$rootScope.loadedSavedReport) {
            CustomReportServices.loadSavedGridLayout(vm, null);
        }
    }

    function setupDefaultDateFilter() {
        vm.filterCriteria.query = vm.filterCriteria.query || [];
        const today = new Date(),
            thirtyDaysPrior = new Date().setDate(today.getDate() - 30),
            startDate = new Date(thirtyDaysPrior).toISOString(),
            endDate = today.toISOString(),
            defaultDateFilter = [
                {
                    "FieldName": "Date",
                    "Operator": "GreaterThanOrEqual",
                    "Value": startDate
                },
                {
                    "FieldName": "Date",
                    "Operator": "LessThanOrEqual",
                    "Value": endDate
                }
            ];

        vm.filterCriteria.query.push(...defaultDateFilter);
    }

    function checkForSavedReportDateFilters() {
        if ($rootScope.loadedSavedReport) {
            return $rootScope.loadedSavedReport.ReportDefinition.Filters.some(filter => filter.FieldName === 'Date') || $rootScope.loadedSavedReport.ReportDefinition.DateFilters;
        } else {
            return false;
        }
    }

    // --- FOR ADVANCED FILTERING ---
    async function setReportMetadata() {
        let response = await reportsDataService.getReportMetadata(vm.reportId);

        vm.reportMetadata = response.data._embedded.reportData;
        vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');
        vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
    }
    // ---

}