export default /*@ngInject*/ function ($q, RequestManagementNetSuiteService) {

    var self = this;

    this.loadNetSuite = function ($scope, subsidiaryId, openRequest) {
        //Load Data for Products after search row is selected
        var netSuiteDfrd = $.Deferred();

        if (subsidiaryId != null) {
            self.loadNetSuiteData($scope, subsidiaryId);
        }

        try {
            self.loadPermissions($scope, openRequest).then(function () {
                netSuiteDfrd.resolve();
            }, function errorCallback(response) {
                netSuiteDfrd.reject(response);
            });
        }
        catch (e) {
            netSuiteDfrd.reject(e);
        }

        return $.when(netSuiteDfrd).done(function () {
        }).promise();

    };

    this.loadPermissions = function ($scope, openRequest) {
        let permissionsDfrd = $.Deferred(),
            promises = [],
            nsRoles = [],
            nsPermissions = [],
            pendingRoles = $scope.vm.User?.NetSuiteUser?.PendingRoles || [],
            pendingPermissions = $scope.vm.User?.NetSuiteUser?.PendingPermissions || [];

        $scope.vm.ListOfSelectedNetSuiteRoles = [];
        $scope.vm.ListOfSelectedNetSuitePermissions = [];

        if ($scope.vm.User.NetSuiteUser != null) {

            //If any nsRoles are selected in the request set the nsRoles valid property to true below
            $scope.vm.numberOfNetSuiteRolesValid = false;

            nsRoles = $scope.vm.User.NetSuiteUser.Roles;
            nsPermissions = $scope.vm.User.NetSuiteUser.Permissions;

            if (nsRoles.length > 0) {
                $scope.vm.numberOfNetSuiteRolesValid = true;
            }
        }

        //Load Available nsRoles then process existing security
        promises.push(
            RequestManagementNetSuiteService.getAllRoles().then(response => {
                $scope.vm.ListOfAvailableNetSuiteRoles = response.data.map(applyPending.bind(null, pendingRoles));
            })
        );

        //Load Levels list for existing users global nsPermissions. also pre selecting level in the dropdown
        promises.push(RequestManagementNetSuiteService.getAllPermissions().then(response => {
            $scope.vm.ListOfAvailableNetSuitePermissions = response.data.map(applyPending.bind(null, pendingPermissions));
        }));

        promises.push(RequestManagementNetSuiteService.getAllSubsidiaries().then(function (response) {
            $scope.vm.NSSubsidaryDataSource = response.data;
        }));

        $q.all(promises).then(function () {
            if ($scope.defaultRoleTemplate && $scope.vm.wizardMode === "New" && !$scope.vm.requestToEdit) {
                let defaultRoles = $scope.defaultRoleTemplate.Roles.filter(role => role.TypeId === "Role" && role.ProductType === "NS");

                if (defaultRoles.length) {
                    $scope.vm.numberOfNetSuiteRolesValid = true;
                }

                $scope.vm.ListOfSelectedNetSuiteRoles = [...defaultRoles];
                $scope.vm.ListOfAvailableNetSuiteRoles = $scope.vm.ListOfAvailableNetSuiteRoles.filter(availableRole => {
                    let selectedRole = $scope.vm.ListOfSelectedNetSuiteRoles.find(selectedRole => selectedRole.Identifier === availableRole.Identifier);

                    if (selectedRole) {
                        selectedRole = Object.merge(selectedRole, availableRole);
                    }

                    return !selectedRole;
                });
            } else if (nsRoles != null && $scope.vm.ListOfSelectedNetSuiteRoles != null) {
                nsRoles.forEach(function (role) {
                    if ($scope.vm.ListOfSelectedNetSuiteRoles.none(selectedRole => selectedRole.Id === role.Id)) {

                        $scope.vm.ListOfSelectedNetSuiteRoles.unshift({
                            Id: role.Id,
                            Name: role.Name,
                            IsAssignable: role.IsAssignable,
                            Identifier: role.Identifier,
                            Pending: pendingRoles.find(pendingRole => pendingRole.Id === role.Id)
                        });
                    }

                    if ($scope.vm.ListOfAvailableNetSuiteRoles.some(availableRole => {
                        return availableRole.Id === role.Id;
                    })) {
                        $scope.vm.ListOfAvailableNetSuiteRoles.forEach(function (row, j) {
                            if (row.Id === role.Id) {
                                $scope.vm.ListOfAvailableNetSuiteRoles.splice(j, 1);
                            }
                        });
                    }
                });
            }

            //Global Permissions
            var foundItemArray = [];
            foundItemArray = [];

            var availPermission = null;
            var level = null;
            var globalPermissionAccess = null;

            //Load user's existing permissions
            if (nsPermissions != null && $scope.vm.ListOfAvailableNetSuitePermissions != null) {
                nsPermissions.forEach(function (globalPermission) {

                    availPermission = $scope.vm.ListOfAvailableNetSuitePermissions.filter(e => e.Id == globalPermission.Id)[0];

                    if (globalPermission.EventType == "Revoke") {
                        //Dont add the item to the selected list
                    }
                    else {
                        if (availPermission != null && availPermission.Levels != null) {
                            level = availPermission.Levels.filter(e => e.Id == globalPermission.Levels[0].Id)[0];

                            //Only add it to the list if it's not already there
                            if ($scope.vm.ListOfSelectedNetSuitePermissions != null && $scope.vm.ListOfSelectedNetSuitePermissions.none(role => role.Id == globalPermission.Id)) {
                                //Do not modify the lists if the global permission is in the request access list
                                if ($scope.vm.reqAccesses == null || $scope.vm.reqAccesses != null && $scope.vm.reqAccesses.none(raGlobalPermission => JSON.parse(raGlobalPermission.Access.Id) == globalPermission.Id)) {
                                    $scope.vm.ListOfSelectedNetSuitePermissions.unshift({
                                        Id: globalPermission.Id,
                                        Name: globalPermission.Name,
                                        Levels: availPermission.Levels,
                                        selectedLevel: level,
                                        Pending: pendingPermissions.find(pendingPermission => pendingPermission.Id === globalPermission.Id)
                                    });

                                    $scope.vm.existingGlobalPermissions.unshift({
                                        Id: globalPermission.Id,
                                        Name: globalPermission.Name,
                                        Levels: availPermission.Levels,
                                        selectedLevel: level,
                                        Pending: pendingPermissions.find(pendingPermission => pendingPermission.Id === globalPermission.Id)
                                    });

                                    $scope.vm.ListOfAvailableNetSuitePermissions.forEach(function (row, j) {
                                        if (row.Id == globalPermission.Id) {
                                            $scope.vm.ListOfAvailableNetSuitePermissions.splice(j, 1);
                                        }
                                    });
                                }
                            }
                        }
                    }
                });
            }

            $scope.vm.ListOfAvailableNetSuiteRoles = $scope.vm.ListOfAvailableNetSuiteRoles.filter(role => role.IsAssignable);
            $scope.netSuiteUserLoaded = true;
            permissionsDfrd.resolve();
        });

        return $.when(permissionsDfrd).done(function () {
        }).promise();

    };

    this.loadNetSuiteData = function ($scope, subsidiaryId) {
        RequestManagementNetSuiteService.getDepartmentsBySubsidiary(subsidiaryId).then(function (response) {
            $scope.vm.NSDepartmentDataSource = response.data;
        });

        RequestManagementNetSuiteService.getClassesBySubsidiary(subsidiaryId).then(function (response) {
            $scope.vm.NSClassDataSource = response.data;
        });

        RequestManagementNetSuiteService.getLocationsBySubsidiary(subsidiaryId).then(function (response) {
            $scope.vm.NSLocationDataSource = response.data;
        });
    };
}

function applyPending(pendingItems, item) {
    if (pendingItems.length) {
        item.Pending = pendingItems.some(pendingItem => pendingItem.Id === item.Id);
    }

    return item;
}