import angular from 'angular';
import schedules from '../Components/Schedules/schedules.component';
import savedReports from '../Components/SavedReports/savedReports.component';
import savedReportsDataServiceFactory from '../Components/SavedReports/savedReportsDataService.factory';
import schedulesService from '../Components/Schedules/schedules.service';

export default angular
    .module('app.reportManagement', [])
    .component("schedules", schedules)
    .component("savedReports", savedReports)
    .factory('savedReportsDataService', savedReportsDataServiceFactory)
    .factory('schedulesDataService', schedulesService)
    .name;