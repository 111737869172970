import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (dataService, netsuiteDataChangesDataService, $http, helperService) {

    const vm = this;

    vm.$onInit = activate;

    vm.objectsAreEqual = objectsAreEqual;
    vm.changeAllValues = changeAllValues;
    vm.recordTypeChanged = recordTypeChanged;
    vm.submit = submit;

    function activate() {
        getProducts();
        getRecordTypes();
    }

    async function getProducts() {
        const response = await dataService.getProducts();
        vm.products = response.data;
    }

    async function getRecordTypes() {
        const response = await netsuiteDataChangesDataService.getRecordTypes();
        vm.recordTypes = response.data;
        vm.recordTypes.forEach(recordType => {
            recordType.value = recordType.Insert || recordType.Update || recordType.Delete;
        });
        vm.originalRecordTypes = angular.copy(vm.recordTypes);
    }

    function objectsAreEqual() {
        return angular.equals(vm.recordTypes, vm.originalRecordTypes);
    }

    function changeAllValues(recordType) {
        recordType.Insert = recordType.value;
        recordType.Update = recordType.value;
        recordType.Delete = recordType.value;
    }

    function recordTypeChanged(recordType) {
        recordType.value = recordType.Insert || recordType.Update || recordType.Delete;

    }

    function submit() {

        vm.pleaseWait = new createSpinner();

        vm.recordTypes.forEach(async (row, i) => {
            if (row.Insert !== vm.originalRecordTypes[i].Insert || row.Update !== vm.originalRecordTypes[i].Update || row.Delete !== vm.originalRecordTypes[i].Delete) {
                let data = {
                    "Id": row.Id,
                    "Insert": row.Insert,
                    "Update": row.Update,
                    "Delete": row.Delete
                };
                await $http.put(`${apiUrl}api/audittrail/recordtypes/${row.Id}`, data);
                vm.originalRecordTypes[i].Insert = row.Insert;
                vm.originalRecordTypes[i].Update = row.Update;
                vm.originalRecordTypes[i].Delete = row.Delete;
            }
        });

        helperService.successfulSaveButton(vm.pleaseWait);
    }

}