import addClassesModalController from "./addClassesModal.controller";

export default /*@ngInject*/ function ($filter, workdayMaintainTemplatesDataService, $uibModal) {

    const vm = this;

    vm.deleteClass = deleteClass;

    vm.$onChanges = activate;

    vm.openAddClassesModal = openAddClassesModal;

    async function activate() {
        await setClasses();
    }

    async function setClasses() {
        vm.classesLoading = true;
        const { data } = await workdayMaintainTemplatesDataService.getSelectedClasses(vm.selectedTemplate.Id);
        vm.classes = $filter('orderBy')(data, 'ClassName')
        vm.classesLoading = false;
    }

    async function deleteClass(templateClassId) {
        vm.deleting = true;
        await workdayMaintainTemplatesDataService.deleteClass(templateClassId);
        setClasses();
        vm.deleting = false;
    }

    async function openAddClassesModal() {
        let classModal = $uibModal.open({
            templateUrl: '/App/Components/workdayMaintainTemplates/classesList/addClassesModal.tmpl.html',
            controller: addClassesModalController,
            controllerAs: 'acmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                selectedTemplate: () => vm.selectedTemplate,
                existingClasses: () => vm.classes
            }
        });

        try {
            await classModal.result;
            setClasses();
        } catch {
            return;
        }
    }
}