export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getTeamMembers,
        getTeamMembersByProduct,
        addTeamMember,
        removeTeamMember
    };

    return service;

    async function getTeamMembers(teamTypeUrl) {
        try {
            return await $http.get(`${apiUrl}api/certifications/teammembers/${teamTypeUrl}`);
        } catch (err) {
            showError(err);
        }
    }

    async function getTeamMembersByProduct(productId) {
        try {
            return await $http.get(`${apiUrl}api/certifications/teammembers/security/product/${productId}`);
        } catch (err) {
            showError(err);
        }
    }

    async function addTeamMember(newTeamMemberInfo) {
        try {
            return await $http.post(`${apiUrl}api/certifications/teammembers`, newTeamMemberInfo);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function removeTeamMember(memberId) {
        try {
            return await $http.delete(`${apiUrl}api/certifications/teammembers/${memberId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }

}