export default /*@ngInject*/ function ($q, RequestManagementGPService) {

    var self = this;

    this.loadGP = function ($scope, openRequest) {
        //Load Data for Products after search row is selected
        var gpDfrd = $.Deferred();

        self.loadGPData($scope);

        try {
            self.loadPermissions($scope, openRequest).then(function () {
                gpDfrd.resolve();
            }, function errorCallback(response) {
                gpDfrd.reject(response);
            });
        }
        catch (e) {
            gpDfrd.reject(e);
        }

        return $.when(gpDfrd).done(function () {
        }).promise();
    };

    this.loadPermissions = function ($scope) {
        let permissionsDfrd = $.Deferred(),
            promises = [],
            gpCompanies = [],
            pendingCompanies = $scope.vm.User?.GPUser?.PendingCompanies || [];

        $scope.vm.ListOfSelectedGPRoles = [];
        $scope.vm.ListOfAvailableGPRoles = [];
        $scope.vm.ListOfSelectedGPCompanies = [];
        $scope.vm.ListOfAvailableGPCompanies = [];

        if ($scope.vm.User.GPUser != null) {
            $scope.vm.ListOfStoredGPRoles = $scope.vm.User.GPUser.RoleCompanies;
            gpCompanies = $scope.vm.User.GPUser.Companies;
        }

        $scope.vm.PendingGpRoleCompanies = $scope.vm.User?.GPUser?.PendingRoleCompanies || [];
        //Load Available gpRoles then process existing security
        promises.push(
            RequestManagementGPService.getAllRoles().then(function (response) {
                // Not filtering pending roles here, they are filtered after choosing a company
                $scope.vm.ListOfAllAvailableGPRoles = response.data;
            })
        );

        promises.push(
            RequestManagementGPService.getAllCompanies().then(function (response) {
                $scope.vm.ListOfAvailableGPCompanies = response.data.map(applyPending.bind(null, pendingCompanies));
            })
        );

        promises.push(RequestManagementGPService.getAllClasses().then(function (response) {
            $scope.vm.GPClassesDataSource = response.data;
        }));

        promises.push(RequestManagementGPService.getAltModProfiles().then(function (response) {
            $scope.vm.GPAltModProfileDataSource = response.data;
            if ($scope.vm.User != null && $scope.vm.User.GPUser != null && $scope.vm.User.GPUser.Companies != null) {
                $scope.vm.User.GPUser.Companies.forEach(function (comp) {
                    if (comp.AltModProfile != null && comp.AltModProfile !== "") {
                        var altModProfileId = comp.AltModProfile.Id != null ? comp.AltModProfile.Id : comp.AltModProfile;
                        var dbAltModProfile = $scope.vm.GPAltModProfileDataSource.find(function (profile) {
                            return profile.Id === altModProfileId;
                        });
                        comp.AltModProfile = dbAltModProfile;
                    }
                });
            }
        }));


        $q.all(promises).then(function () {
            if ($scope.vm.User == null) {
                $scope.vm.User = {};
            }
            if ($scope.vm.User.GPUser == null) {
                $scope.vm.User.GPUser = {};
                $scope.vm.User.GPUser.LoginType = 1;
                $scope.vm.User.GPUser.UserType = 1;
                $scope.vm.User.GPUser.UserStatus = 1;
            }

            //Modify Available and Selected company lists to reflect users current security
            if (gpCompanies != null && $scope.vm.ListOfSelectedGPCompanies != null) {
                gpCompanies.forEach(company => {
                    if ($scope.vm.ListOfSelectedGPCompanies.none(selectedRole => selectedRole.Id == company.Company.Id)) {

                        $scope.vm.ListOfSelectedGPCompanies.unshift({
                            Id: company.Company.Id,
                            Name: company.Company.Name,
                            Pending: pendingCompanies.some(pendingCompany => pendingCompany.Company.Id === company.Company.Id)
                        });

                        $scope.vm.ListOfAvailableGPCompanies.forEach(function (row, j) {
                            if (row.Id == company.Company.Id) {
                                $scope.vm.ListOfAvailableGPCompanies.splice(j, 1);
                            }
                        });
                    }
                });
            }

            $scope.vm.ListOfAllAvailableGPRoles = $scope.vm.ListOfAllAvailableGPRoles.filter(role => role.IsAssignable);

            $scope.gpUserLoaded = true;
            permissionsDfrd.resolve();
        });

        return $.when(permissionsDfrd).done(function () {
        }).promise();
    };

    this.loadGPData = function ($scope) {
        //Populate the GP Roles with the AltModProfile from the UserCompanies
        if ($scope.vm.User != null && $scope.vm.User.GPUser != null && $scope.vm.User.GPUser.RoleCompanies != null) {
            $scope.vm.User.GPUser.RoleCompanies.forEach(function (role) {
                if (role.AltModProfile == null) {
                    role.AltModProfile = $scope.vm.User.GPUser.Companies.find(company => company.Company.Id === role.Company.Id).AltModProfile;
                }
            });
        }

        if ($scope.vm.User.GPUser != null) {
            $scope.vm.User.GPUser.DisplayClass = { RowId: $scope.vm.User.GPUser.Class, Name: $scope.vm.User.GPUser.ClassName };
        }
    };
}

function applyPending(pendingItems, item) {
    if (pendingItems.length) {
        item.Pending = pendingItems.some(pendingItem => pendingItem.Company.Id === item.Id);
    } else {
        item.Pending = false;
    }

    return item;
}