import { ax7_application_id, ax_application_id, netsuite_application_id, oracle_application_id, oracleCloud_application_id, gp_application_id, nav_application_id, d365s_application_id, sap_application_id, universalProduct_application_id, aad_application_id, ps_application_id, salesforce_application_id } from "../../Shared/GlobalVariables/applicationIdVariables";

export default /*@ngInject*/ function ($http, $q, $rootScope, RequestManagementBuildRequestService, RequestManagementGPLoadDataService, RequestManagementEffectiveDateService, RequestManagementNetSuiteLoadDataService, RequestManagementUPLoadDataService, RequestManagementSAPLoadDataService, RequestManagementAXLoadDataService, RequestManagementAADLoadDataService, RequestManagementNAVLoadDataService, RequestManagementOracleLoadDataService, RequestManagementD365SLoadDataService, RequestManagementOracleCloudLoadDataService, RequestManagementPSLoadDataService, salesforceLoadData, helperService) {

    var self = this;

    //Populate user data from an Open Request for editing
    this.loadOpenRequestData = function ($scope) {

        if ($rootScope.requestToEdit) {

            var vm = $scope.vm;
            if ($scope.vm.User == null) {
                $scope.vm.User = {};
            }

            $scope.requestToEdit = $rootScope.requestToEdit;
            vm.requestToEdit = $rootScope.requestToEdit;

            delete $rootScope.requestToEdit;
            $scope.reportDetailsComplete = false;

            //Get Request data based on request Id and version from the database
            $http.get(apiUrl + 'api/identitymanager/requests/edit/' + $scope.requestToEdit.Id + '/version/' + $scope.requestToEdit.Version).then(({ data }) => {

                $scope.linkedTickets = data.Tickets;

                $scope.jiraTicketLinked = $scope.linkedTickets.some(ticket => ticket.TicketProvider === 'Jira');
                $scope.zendeskTicketLinked = $scope.linkedTickets.some(ticket => ticket.TicketProvider === 'Zendesk');
                $scope.serviceNowTicketLinked = $scope.linkedTickets.some(ticket => ticket.TicketProvider === 'ServiceNow');
                $scope.customTicketLinked = $scope.linkedTickets.some(ticket => ticket.TicketProvider === 'Custom');

                //data.AffectedObject = JSON.parse(data.AffectedObject);
                $scope.vm.User.UserMaster = data.GeneralUser;

                //Add applications array to store the applications the user has access to
                if ($scope.vm.User.Applications == null) {
                    $scope.vm.User.Applications = [];
                }

                $scope.vm.availableApplications = $scope.vm.availableApplications.map(setVisibleAndSelectedApps.bind(null, data));

                if (data.AadUser != null) {
                    $scope.vm.User.AadUser = data.AadUser;
                    if ($scope.vm.wizardMode === 'New') {
                        const splitPrincipalName = $scope.vm.User.AadUser.UserPrincipalName.split('@');
                        $scope.vm.User.AadUser.UserPrincipalName = splitPrincipalName[0];
                        $scope.vm.User.AadUser.Domain = splitPrincipalName[1];
                    }
                    if ($scope.vm.User.AadUser.StartDate != null) {
                        $scope.vm.User.AadUser.StartDate = new Date($scope.vm.User.AadUser.StartDate);
                    }
                    if ($scope.vm.User.AadUser.EndDate != null) {
                        $scope.vm.User.AadUser.EndDate = new Date($scope.vm.User.AadUser.EndDate);
                    }
                    if ($scope.vm.User.AadUser.PasswordProfile) {
                        $scope.vm.User.AadUser.ConfirmPassword = $scope.vm.User.AadUser.PasswordProfile.Password;
                    }
                    if ($scope.vm.User.AadUser.BusinessPhones.length > 0) {
                        $scope.vm.User.AadUser.BusinessPhones = $scope.vm.User.AadUser.BusinessPhones.toString();
                    }
                }
                if (data.Ax7User != null) {
                    $scope.vm.User.AX7User = data.Ax7User;
                    if ($scope.vm.User.AX7User.StartDate != null) {
                        $scope.vm.User.AX7User.StartDate = new Date($scope.vm.User.AX7User.StartDate);
                    }
                    if ($scope.vm.User.AX7User.EndDate != null) {
                        $scope.vm.User.AX7User.EndDate = new Date($scope.vm.User.AX7User.EndDate);
                    }
                    $scope.vm.User.AX7User.ConfirmPassword = $scope.vm.User.AX7User.Password;
                }
                if (data.AxUser != null) {
                    $scope.vm.User.AX2012User = data.AxUser;
                    $scope.vm.User.AX2012User.Partition = { Id: data.AxUser.Partition, Key: data.AxUser.PartitionKey };

                    if ($scope.vm.User.AX2012User.StartDate != null) {
                        $scope.vm.User.AX2012User.StartDate = new Date($scope.vm.User.AX2012User.StartDate);
                    }
                    if ($scope.vm.User.AX2012User.EndDate != null) {
                        $scope.vm.User.AX2012User.EndDate = new Date($scope.vm.User.AX2012User.EndDate);
                    }
                    $scope.vm.User.AX2012User.ConfirmPassword = $scope.vm.User.AX2012User.Password;
                }
                if (data.NetSuiteUser != null) {
                    $scope.vm.User.NetSuiteUser = data.NetSuiteUser;
                    if ($scope.vm.User.NetSuiteUser.StartDate != null) {
                        $scope.vm.User.NetSuiteUser.StartDate = new Date($scope.vm.User.NetSuiteUser.StartDate);
                    }
                    if ($scope.vm.User.NetSuiteUser.EndDate != null) {
                        $scope.vm.User.NetSuiteUser.EndDate = new Date($scope.vm.User.NetSuiteUser.EndDate);
                    }
                    $scope.vm.User.NetSuiteUser.ConfirmPassword = $scope.vm.User.NetSuiteUser.Password;
                }
                if (data.UvUser != null) {
                    // 'UvUser' gets set to property 'UPUser'
                    if (!$scope.vm.User.UPUser) { $scope.vm.User.UPUser = []; }
                    data.UvUser.forEach(user => {
                        var productName = helperService.getProductName(user.ProductId, $scope.vm.availableApplications);
                        $scope.vm.User.UPUser[productName] = user;
                        if ($scope.vm.User.UPUser[productName].StartDate) {
                            $scope.vm.User.UPUser[productName].StartDate = new Date($scope.vm.User.UPUser[productName].StartDate);
                        }
                        if ($scope.vm.User.UPUser[productName].EndDate) {
                            $scope.vm.User.UPUser[productName].EndDate = new Date($scope.vm.User.UPUser[productName].EndDate);
                        }
                        $scope.vm.User.UPUser[productName].ProductName = productName;
                    });
                }
                if (data.SapUser != null) {
                    if (!$scope.vm.User.SAPUser) { $scope.vm.User.SAPUser = []; }
                    data.SapUser.forEach(user => {
                        var productName = helperService.getProductName(user.ProductId, $scope.vm.availableApplications);
                        $scope.vm.User.SAPUser[productName] = user;
                        if ($scope.vm.User.SAPUser[productName].StartDate) {
                            $scope.vm.User.SAPUser[productName].StartDate = new Date($scope.vm.User.SAPUser[productName].StartDate);
                        }
                        if ($scope.vm.User.SAPUser[productName].EndDate) {
                            $scope.vm.User.SAPUser[productName].EndDate = new Date($scope.vm.User.SAPUser[productName].EndDate);
                        }
                        $scope.vm.User.SAPUser[productName].ConfirmPassword = user.Password;
                        $scope.vm.User.SAPUser[productName].ProductName = productName;
                    });
                }
                if (data.EbsUser != null) {
                    $scope.vm.User.OracleUser = data.EbsUser;
                    if ($scope.vm.User.OracleUser.StartDate != null) {
                        $scope.vm.User.OracleUser.StartDate = new Date($scope.vm.User.OracleUser.StartDate);
                    }
                    if ($scope.vm.User.OracleUser.EndDate != null) {
                        $scope.vm.User.OracleUser.EndDate = new Date($scope.vm.User.OracleUser.EndDate);
                    }
                    $scope.vm.User.OracleUser.ConfirmPassword = $scope.vm.User.OracleUser.Password;
                    $scope.vm.User.OracleUser.Expiration = data.EbsUser.PasswordDays === null && data.EbsUser.PasswordAccesses === null ? 'None' : data.EbsUser.PasswordDays !== null ? 'Days' : 'Accesses';
                    $scope.vm.User.OracleUser.ExpirationValue = data.EbsUser.PasswordDays !== null ? data.EbsUser.PasswordDays : data.EbsUser.PasswordAccesses;
                }

                // Oracle Cloud
                if (data.OrfcUser != null) {
                    $scope.vm.User.OracleCloudUser = data.OrfcUser;
                    if ($scope.vm.User.OracleCloudUser.StartDate != null) {
                        $scope.vm.User.OracleCloudUser.StartDate = new Date($scope.vm.User.OracleCloudUser.StartDate);
                    }
                    if ($scope.vm.User.OracleCloudUser.EndDate != null) {
                        $scope.vm.User.OracleCloudUser.EndDate = new Date($scope.vm.User.OracleCloudUser.EndDate);
                    }
                    $scope.vm.User.OracleCloudUser.PasswordConfirm = $scope.vm.User.OracleCloudUser.Password;
                }
                if (data.GPUser != null) {
                    $scope.vm.User.GPUser = data.GPUser;
                    $scope.vm.User.GPUser.DisplayClass = { RowId: data.GPUser.Class, Name: data.GPUser.ClassName };

                    if ($scope.vm.User.GPUser.StartDate != null) {
                        $scope.vm.User.GPUser.StartDate = new Date($scope.vm.User.GPUser.StartDate);
                    }
                    if ($scope.vm.User.GPUser.EndDate != null) {
                        $scope.vm.User.GPUser.EndDate = new Date($scope.vm.User.GPUser.EndDate);
                    }
                    $scope.vm.User.GPUser.ConfirmPassword = $scope.vm.User.GPUser.Password;
                }
                if (data.NavUser != null) {
                    $scope.vm.User.NAVUser = data.NavUser;
                    if ($scope.vm.User.NAVUser.StartDate != null) {
                        $scope.vm.User.NAVUser.StartDate = new Date($scope.vm.User.NAVUser.StartDate);
                    }
                    if ($scope.vm.User.NAVUser.EndDate != null) {
                        $scope.vm.User.NAVUser.EndDate = new Date($scope.vm.User.NAVUser.EndDate);
                    }
                }
                if (data.D365SUser != null) {
                    $scope.vm.User.D365SUser = data.D365SUser;
                    if ($scope.vm.User.D365SUser.StartDate != null) {
                        $scope.vm.User.D365SUser.StartDate = new Date($scope.vm.User.D365SUser.StartDate);
                    }
                    if ($scope.vm.User.D365SUser.EndDate != null) {
                        $scope.vm.User.D365SUser.EndDate = new Date($scope.vm.User.D365SUser.EndDate);
                    }
                }
                if (data.PsUserRequest != null) {
                    $scope.vm.User.PSUser = data.PsUserRequest;
                    if ($scope.vm.User.PSUser.StartDate != null) {
                        $scope.vm.User.PSUser.StartDate = new Date($scope.vm.User.PSUser.StartDate);
                    }
                    if ($scope.vm.User.PSUser.EndDate != null) {
                        $scope.vm.User.PSUser.EndDate = new Date($scope.vm.User.PSUser.EndDate);
                    }
                    $scope.vm.User.PSUser.ConfirmPassword = $scope.vm.User.PSUser.Password;
                }

                if (data.SfUserRequest != null) {
                    $scope.vm.User.SalesforceUser = data.SfUserRequest;
                    if ($scope.vm.User.SalesforceUser.StartDate != null) {
                        $scope.vm.User.SalesforceUser.StartDate = new Date($scope.vm.User.SalesforceUser.StartDate);
                    }
                    if ($scope.vm.User.SalesforceUser.EndDate != null) {
                        $scope.vm.User.SalesforceUser.EndDate = new Date($scope.vm.User.SalesforceUser.EndDate);
                    }
                }

                //Load application data, this includes security data and any data that is used in dropdown lists
                self.loadApplicationData($scope, data).then(function () {
                    //Set the effective dates from the build request on the initial load of an open request
                    self.loadOpenRequestEffectiveDates($scope, data).then(function () {
                        //Check if an application is selected and load ERP steps
                        $scope.atLeastOneAppSelected();


                        $scope.vm.availableApplications.forEach(application => $scope.loadERPSpecificSteps(application));
                        $scope.loadingIcon = false;
                        $scope.stopRequestManagementSpinner = true;
                        $scope.reportDetailsComplete = true;
                        $scope.$evalAsync();
                    });


                }, function errorCallback(response) {
                    lockdownOnError($scope, response);
                });
            }, function (response) {
                lockdownOnError($scope, response);
            });
        }
    };

    //Add Start/End dates and Actions to any permissions set in an Open Request
    this.loadOpenRequestEffectiveDates = function ($scope, request) {
        //Set effective dates on any existing or granted roles in the selected list
        //Set effective dates on any revoked roles that will be in the available list
        var loadRequestDfrd = $.Deferred();
        var returnedRequest = RequestManagementBuildRequestService.buildRequest($scope.vm, false);
        $http.post(apiUrl + 'api/identitymanager/requests/build', returnedRequest).then(function (buildResponse) {
            var builtRequest = buildResponse.data;
            //Find all effective dates and convert them to local time
            RequestManagementEffectiveDateService.convertDatesToLocal(builtRequest);

            if (builtRequest.NetSuiteRequest != null) {

                if ($scope.vm.ListOfSelectedNetSuiteRoles != null) {
                    $scope.vm.ListOfSelectedNetSuiteRoles.forEach(function (role) {
                        prepareNSAccessItem(role);

                        var foundRole = builtRequest.NetSuiteRequest.Roles.find(function (nsRole) {
                            return nsRole.Id == role.Id;
                        });
                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.StartDate = foundRole.StartDate;
                            role.EndDate = foundRole.EndDate;
                            role.AccessType = foundRole.AccessType;
                        }
                    });
                }

                if ($scope.vm.ListOfAvailableNetSuiteRoles != null) {
                    $scope.vm.ListOfAvailableNetSuiteRoles.forEach(function (role) {
                        prepareNSAccessItem(role);

                        var foundRole = builtRequest.NetSuiteRequest.Roles.find(function (nsRole) {
                            return nsRole.Id == role.Id;
                        });
                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.StartDate = foundRole.StartDate;
                            role.EndDate = foundRole.EndDate;
                            role.AccessType = foundRole.AccessType;
                        }
                    });
                }

                if ($scope.vm.ListOfSelectedNetSuitePermissions != null) {
                    $scope.vm.ListOfSelectedNetSuitePermissions.forEach(function (perm) {
                        prepareNSAccessItem(perm);

                        var foundPermission = builtRequest.NetSuiteRequest.GlobalPermissions.find(function (selPerm) {
                            return selPerm.Id == perm.Id;
                        });
                        if (foundPermission != null) {
                            perm.Action = foundPermission.Action;
                            perm.StartDate = foundPermission.StartDate;
                            perm.EndDate = foundPermission.EndDate;
                            perm.AccessType = foundPermission.AccessType;
                        }
                    });
                }

                if ($scope.vm.ListOfAvailableNetSuitePermissions != null) {
                    $scope.vm.ListOfAvailableNetSuitePermissions.forEach(function (perm) {
                        prepareNSAccessItem(perm);

                        var foundPermission = builtRequest.NetSuiteRequest.GlobalPermissions.find(function (selPerm) {
                            return selPerm.Id == perm.Id;
                        });
                        if (foundPermission != null) {
                            perm.Action = foundPermission.Action;
                            perm.StartDate = perm.StartDate;
                            perm.EndDate = perm.EndDate;
                            perm.AccessType = perm.AccessType;
                        }
                    });
                }
            }
            if (builtRequest.AadRequest != null) {
                if ($scope.vm.ListOfSelectedAADRoles != null) {
                    $scope.vm.ListOfSelectedAADRoles.forEach(role => {
                        var foundRole = builtRequest.AadRequest.Roles.find(adRole => {
                            return adRole.Id == role.Id;
                        });
                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.StartDate = foundRole.StartDate;
                            role.EndDate = foundRole.EndDate;
                            role.AccessType = foundRole.AccessType;
                        }

                        prepareAADAccessItem(role);
                    });
                }

                if ($scope.vm.ListOfAvailableAADRoles != null) {
                    $scope.vm.ListOfAvailableAADRoles.forEach(role => {

                        var foundRole = builtRequest.AadRequest.Roles.find(adRole => {
                            return adRole.Id == role.Id;
                        });
                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.StartDate = foundRole.StartDate;
                            role.EndDate = foundRole.EndDate;
                            role.AccessType = foundRole.AccessType;
                        }

                        prepareAADAccessItem(role);
                    });
                }

                if ($scope.vm.ListOfSelectedAADGroups != null) {
                    $scope.vm.ListOfSelectedAADGroups.forEach(group => {
                        var foundGroup = builtRequest.AadRequest.Groups.find(adGroup => {
                            return adGroup.Id == group.Id;
                        });
                        if (foundGroup != null) {
                            group.Action = foundGroup.Action;
                            group.StartDate = foundGroup.StartDate;
                            group.EndDate = foundGroup.EndDate;
                            group.AccessType = foundGroup.AccessType;
                        }

                        prepareAADAccessItem(group);
                    });
                }

                if ($scope.vm.ListOfAvailableAADGroups != null) {
                    $scope.vm.ListOfAvailableAADGroups.forEach(group => {

                        var foundGroup = builtRequest.AadRequest.Groups.find(adGroup => {
                            return adGroup.Id == group.Id;
                        });
                        if (foundGroup != null) {
                            group.Action = foundGroup.Action;
                            group.StartDate = foundGroup.StartDate;
                            group.EndDate = foundGroup.EndDate;
                            group.AccessType = foundGroup.AccessType;
                        }

                        prepareAADAccessItem(group);
                    });
                }

                if ($scope.vm.ListOfSelectedAADLicenses != null) {
                    $scope.vm.ListOfSelectedAADLicenses.forEach(license => {
                        var foundLicense = builtRequest.AadRequest.Licenses.find(adLicense => {
                            return adLicense.Id == license.Id;
                        });
                        if (foundLicense != null) {
                            license.Action = foundLicense.Action;
                            license.StartDate = foundLicense.StartDate;
                            license.EndDate = foundLicense.EndDate;
                            license.AccessType = foundLicense.AccessType;
                        }

                        prepareAADAccessItem(license);
                    });
                }

                if ($scope.vm.ListOfAvailableAADLicenses != null) {
                    $scope.vm.ListOfAvailableAADLicenses.forEach(license => {

                        var foundLicense = builtRequest.AadRequest.Licenses.find(adLicense => {
                            return adLicense.Id == license.Id;
                        });
                        if (foundLicense != null) {
                            license.Action = foundLicense.Action;
                            license.StartDate = foundLicense.StartDate;
                            license.EndDate = foundLicense.EndDate;
                            license.AccessType = foundLicense.AccessType;
                        }

                        prepareAADAccessItem(license);
                    });
                }
            }
            if (builtRequest.Ax7Request != null) {
                if ($scope.vm.ListOfSelectedAX7Roles != null) {
                    $scope.vm.ListOfSelectedAX7Roles.forEach(function (role) {

                        if (role.Companies != null && role.Companies) {
                            role.Companies.forEach(function (company) {
                                var foundRole = builtRequest.Ax7Request.RoleCompanies.find(function (axRole) {
                                    return axRole.Role.Id == role.Id && axRole.Company.CompanyId == company.CompanyId;
                                });

                                if (foundRole != null) {
                                    company.Action = foundRole.Action;
                                    company.StartDate = foundRole.StartDate;
                                    company.EndDate = foundRole.EndDate;
                                    company.AccessType = foundRole.AccessType;
                                }
                            });
                        } else {
                            var foundRole = builtRequest.Ax7Request.RoleCompanies.find(function (axRole) {
                                return axRole.Role.Id == role.Id;
                            });
                            if (foundRole != null) {
                                role.Action = foundRole.Action;
                                role.StartDate = foundRole.StartDate;
                                role.EndDate = foundRole.EndDate;
                                role.AccessType = foundRole.AccessType;
                            }
                        }

                        prepareAX7AccessItem(role);
                    });
                }

                if ($scope.vm.ListOfAvailableAX7Roles != null) {
                    $scope.vm.ListOfAvailableAX7Roles.forEach(function (role) {

                        var foundRole = builtRequest.Ax7Request.RoleCompanies.find(function (axRole) {
                            return axRole.Role.Id == role.Id;
                        });
                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.StartDate = foundRole.StartDate;
                            role.EndDate = foundRole.EndDate;
                            role.AccessType = foundRole.AccessType;
                        }

                        prepareAX7AccessItem(role);
                    });
                }

                if ($scope.vm.ListOfSelectedAX7UserGroups != null) {
                    $scope.vm.ListOfSelectedAX7UserGroups.forEach(function (userGroup) {
                        var foundUserGroup = builtRequest.Ax7Request.UserGroups.find(function (axUserGroup) {
                            return axUserGroup.Id == userGroup.Id;
                        });
                        if (foundUserGroup != null) {
                            userGroup.Action = foundUserGroup.Action;
                            userGroup.StartDate = foundUserGroup.StartDate;
                            userGroup.EndDate = foundUserGroup.EndDate;
                            userGroup.AccessType = foundUserGroup.AccessType;
                        }

                        prepareAX7AccessItem(userGroup);
                    });
                }

                if ($scope.vm.ListOfAvailableAX7UserGroups != null) {
                    $scope.vm.ListOfAvailableAX7UserGroups.forEach(function (userGroup) {

                        var foundUserGroup = builtRequest.Ax7Request.UserGroups.find(function (axUserGroup) {
                            return axUserGroup.Id == userGroup.Id;
                        });
                        if (foundUserGroup != null) {
                            userGroup.Action = foundUserGroup.Action;
                            userGroup.StartDate = foundUserGroup.StartDate;
                            userGroup.EndDate = foundUserGroup.EndDate;
                            userGroup.AccessType = foundUserGroup.AccessType;
                        }

                        prepareAX7AccessItem(userGroup);
                    });
                }
            }
            if (builtRequest.AxRequest != null) {
                if ($scope.vm.ListOfSelectedAX2012Roles != null) {
                    $scope.vm.ListOfSelectedAX2012Roles.forEach(function (role) {

                        if (role.Companies != null && role.Companies) {
                            role.Companies.forEach(function (company) {
                                var foundRole = builtRequest.AxRequest.RoleCompanies.find(function (axRole) {
                                    return axRole.Role.Id == role.Id && axRole?.Company?.CompanyId == company.CompanyId;
                                });

                                if (foundRole != null) {
                                    company.Action = foundRole.Action;
                                    company.StartDate = foundRole.StartDate;
                                    company.EndDate = foundRole.EndDate;
                                    company.AccessType = foundRole.AccessType;
                                }
                            });
                        } else {
                            var foundRole = builtRequest.AxRequest.RoleCompanies.find(function (axRole) {
                                return axRole.Role.Id == role.Id;
                            });
                            if (foundRole != null) {
                                role.Action = foundRole.Action;
                                role.StartDate = foundRole.StartDate;
                                role.EndDate = foundRole.EndDate;
                                role.AccessType = foundRole.AccessType;
                            }
                        }

                        prepareAX2012AccessItem(role);
                    });
                }

                if ($scope.vm.ListOfAvailableAX2012Roles != null) {
                    $scope.vm.ListOfAvailableAX2012Roles.forEach(function (role) {

                        var foundRole = builtRequest.AxRequest.RoleCompanies.find(function (axRole) {
                            return axRole.Role.Id == role.Id;
                        });
                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.StartDate = foundRole.StartDate;
                            role.EndDate = foundRole.EndDate;
                            role.AccessType = foundRole.AccessType;
                        }

                        prepareAX2012AccessItem(role);
                    });
                }

                if ($scope.vm.ListOfSelectedAX2012UserGroups != null) {
                    $scope.vm.ListOfSelectedAX2012UserGroups.forEach(function (userGroup) {
                        var foundUserGroup = builtRequest.AxRequest.UserGroups.find(function (axUserGroup) {
                            return axUserGroup.Id == userGroup.Id;
                        });
                        if (foundUserGroup != null) {
                            userGroup.Action = foundUserGroup.Action;
                            userGroup.StartDate = foundUserGroup.StartDate;
                            userGroup.EndDate = foundUserGroup.EndDate;
                            userGroup.AccessType = foundUserGroup.AccessType;
                        }

                        prepareAX2012AccessItem(userGroup);
                    });
                }

                if ($scope.vm.ListOfAvailableAX2012UserGroups != null) {
                    $scope.vm.ListOfAvailableAX2012UserGroups.forEach(function (userGroup) {

                        var foundUserGroup = builtRequest.AxRequest.UserGroups.find(function (axUserGroup) {
                            return axUserGroup.Id == userGroup.Id;
                        });
                        if (foundUserGroup != null) {
                            userGroup.Action = foundUserGroup.Action;
                            userGroup.StartDate = foundUserGroup.StartDate;
                            userGroup.EndDate = foundUserGroup.EndDate;
                            userGroup.AccessType = foundUserGroup.AccessType;
                        }

                        prepareAX2012AccessItem(userGroup);
                    });
                }
            }
            if (builtRequest.SAPRequest != null) {
                // for Single Roles
                if ($scope.vm.ListOfSelectedSAPSingleRoles != null) {
                    $scope.vm.ListOfSelectedSAPSingleRoles.forEach(function (role) {
                        prepareSAPAccessItem(role);

                        var foundRole = builtRequest.SAPRequest.Roles.find(function (sapRole) {
                            return sapRole.Id == role.Id;
                        });
                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.StartDate = foundRole.StartDate;
                            role.EndDate = foundRole.EndDate;
                            role.AccessType = foundRole.AccessType;
                        }
                    });
                }

                if ($scope.vm.ListOfAvailableSAPSingleRoles != null) {
                    $scope.vm.ListOfAvailableSAPSingleRoles.forEach(function (role) {
                        prepareSAPAccessItem(role);

                        var foundRole = builtRequest.SAPRequest.Roles.find(function (sapRole) {
                            return sapRole.Id == role.Id;
                        });
                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.StartDate = foundRole.StartDate;
                            role.EndDate = foundRole.EndDate;
                            role.AccessType = foundRole.AccessType;
                        }
                    });
                }

                // for Composite Roles
                if ($scope.vm.ListOfSelectedSAPCompositeRoles != null) {
                    $scope.vm.ListOfSelectedSAPCompositeRoles.forEach(function (compositeRole) {
                        prepareSAPAccessItem(compositeRole);

                        var foundCompositeRole = builtRequest.SAPRequest.Roles.find(function (sapCompositeRole) {
                            return sapCompositeRole.Id == compositeRole.Id;
                        });
                        if (foundCompositeRole != null) {
                            compositeRole.Action = foundCompositeRole.Action;
                            compositeRole.StartDate = foundCompositeRole.StartDate;
                            compositeRole.EndDate = foundCompositeRole.EndDate;
                            compositeRole.AccessType = foundCompositeRole.AccessType;
                        }
                    });
                }

                if ($scope.vm.ListOfAvailableSAPCompositeRoles != null) {
                    $scope.vm.ListOfAvailableSAPCompositeRoles.forEach(function (compositeRole) {
                        prepareSAPAccessItem(compositeRole);

                        var foundCompositeRole = builtRequest.SAPRequest.Roles.find(function (sapCompositeRole) {
                            return sapCompositeRole.Id == compositeRole.Id;
                        });
                        if (foundCompositeRole != null) {
                            compositeRole.Action = foundCompositeRole.Action;
                            compositeRole.StartDate = foundCompositeRole.StartDate;
                            compositeRole.EndDate = foundCompositeRole.EndDate;
                            compositeRole.AccessType = foundCompositeRole.AccessType;
                        }
                    });
                }
            }
            if (builtRequest.EbsRequest != null) {
                if ($scope.vm.ListOfSelectedOracleResponsibilities != null) {
                    $scope.vm.ListOfSelectedOracleResponsibilities.forEach(function (responsibility) {
                        prepareORAccessItem(responsibility);

                        var foundResponsibility = builtRequest.EbsRequest.Responsibilities.find(function (oracle) {
                            return oracle.Id == responsibility.Id;
                        });

                        if (foundResponsibility != null) {
                            responsibility.Action = foundResponsibility.Action;
                            responsibility.StartDate = foundResponsibility.StartDate;
                            responsibility.EndDate = foundResponsibility.EndDate;
                            responsibility.AccessType = foundResponsibility.AccessType;
                        }
                    });
                }

                if ($scope.vm.ListOfAvailableOracleResponsibilities != null) {
                    $scope.vm.ListOfAvailableOracleResponsibilities.forEach(function (responsibility) {
                        prepareORAccessItem(responsibility);

                        var foundResponsibility = builtRequest.EbsRequest.Responsibilities.find(function (oracle) {
                            return oracle.Id == responsibility.Id;
                        });

                        if (foundResponsibility != null) {
                            responsibility.Action = foundResponsibility.Action;
                            responsibility.StartDate = foundResponsibility.StartDate;
                            responsibility.EndDate = foundResponsibility.EndDate;
                            responsibility.AccessType = foundResponsibility.AccessType;
                        }
                    });
                }

                if ($scope.vm.ListOfSelectedOracleRoles != null) {
                    $scope.vm.ListOfSelectedOracleRoles.forEach(function (role) {
                        prepareORAccessItem(role);

                        var foundRole = builtRequest.EbsRequest.Roles.find(function (oracle) {
                            return oracle.Id == role.Id;
                        });

                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.StartDate = foundRole.StartDate;
                            role.EndDate = foundRole.EndDate;
                            role.AccessType = foundRole.AccessType;
                        }
                    });
                }

                if ($scope.vm.ListOfAvailableOracleRoles != null) {
                    $scope.vm.ListOfAvailableOracleRoles.forEach(function (role) {
                        prepareORAccessItem(role);

                        var foundRole = builtRequest.EbsRequest.Roles.find(function (oracle) {
                            return oracle.Id == role.Id;
                        });

                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.StartDate = foundRole.StartDate;
                            role.EndDate = foundRole.EndDate;
                            role.AccessType = foundRole.AccessType;
                        }
                    });
                }
            }
            // Oracle Cloud
            if (builtRequest.OrfcRequest != null) {
                if ($scope.vm.ListOfSelectedOracleCloudRoles != null) {
                    $scope.vm.ListOfSelectedOracleCloudRoles.forEach(function (role) {
                        prepareORFCAccessItem(role);

                        var foundRole = builtRequest.OrfcRequest.Roles.find(function (OrfcRole) {
                            return OrfcRole.RoleID == role.RoleID;
                        });
                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.StartDate = foundRole.StartDate;
                            role.EndDate = foundRole.EndDate;
                            role.AccessType = foundRole.AccessType;
                        }
                    });
                }

                if ($scope.vm.ListOfAvailableOracleCloudRoles != null) {
                    $scope.vm.ListOfAvailableOracleCloudRoles.forEach(function (role) {
                        prepareORFCAccessItem(role);

                        var foundRole = builtRequest.OrfcRequest.Roles.find(function (OrfcRole) {
                            return OrfcRole.RoleID == role.RoleID;
                        });
                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.StartDate = foundRole.StartDate;
                            role.EndDate = foundRole.EndDate;
                            role.AccessType = foundRole.AccessType;
                        }
                    });
                }
            }
            if (builtRequest.NavRequest != null) {
                if ($scope.vm.ListOfSelectedNAVPermissionSets != null) {

                    $scope.vm.ListOfSelectedNAVPermissionSets.forEach(permissionSet => {
                        if (permissionSet.Companies != null && permissionSet.Companies.length > 0) {
                            permissionSet.Companies.forEach(company => {
                                var foundPermission = builtRequest.NavRequest.PermissionSets.find(permission => permission.Id == permissionSet.Id && permission.Company == company);

                                if (foundPermission != null) {
                                    company.Action = foundPermission.Action;
                                    company.StartDate = foundPermission.StartDate;
                                    company.EndDate = foundPermission.EndDate;
                                    company.AccessType = foundPermission.AccessType;
                                }
                            });
                        } else {
                            var foundPermission = builtRequest.NavRequest.PermissionSets.find(permission => permission.Id == permissionSet.Id);

                            if (foundPermission != null) {
                                permissionSet.Action = foundPermission.Action;
                                permissionSet.StartDate = foundPermission.StartDate;
                                permissionSet.EndDate = foundPermission.EndDate;
                                permissionSet.AccessType = foundPermission.AccessType;
                            }
                        }

                        prepareNAVAccessItem(permissionSet);
                    });
                }
            }
            if (builtRequest.D365Request != null) {

                if ($scope.vm.ListOfSelectedD365SRoles != null) {
                    $scope.vm.ListOfSelectedD365SRoles.forEach(function (role) {

                        var foundRole = builtRequest.D365Request.Roles.find(function (d365sRole) {
                            return d365sRole.Role.Id === role.Id;
                        });
                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.StartDate = foundRole.StartDate;
                            role.EndDate = foundRole.EndDate;
                            role.AccessType = foundRole.AccessType;
                        }

                        prepareD365SAccessItem(role);
                    });
                }

                if ($scope.vm.ListOfAvailableD365SRoles != null) {
                    $scope.vm.ListOfAvailableD365SRoles.forEach(function (role) {

                        var foundRole = builtRequest.D365Request.Roles.find(function (d365sRole) {
                            return d365sRole.Role.Id == role.Id;
                        });
                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.StartDate = foundRole.StartDate;
                            role.EndDate = foundRole.EndDate;
                            role.AccessType = foundRole.AccessType;
                        }

                        prepareD365SAccessItem(role);
                    });
                }

                if ($scope.vm.ListOfSelectedD365STeams != null) {
                    $scope.vm.ListOfSelectedD365STeams.forEach(function (team) {

                        var foundTeam = builtRequest.D365Request.UserTeams.find(function (d365sTeam) {
                            return d365sTeam.Team.Id === team.Id;
                        });
                        if (foundTeam != null) {
                            team.Action = foundTeam.Action;
                            team.StartDate = foundTeam.StartDate;
                            team.EndDate = foundTeam.EndDate;
                            team.AccessType = foundTeam.AccessType;
                        }

                        prepareD365SAccessItem(team);
                    });
                }

                if ($scope.vm.ListOfAvailableD365STeams != null) {
                    $scope.vm.ListOfAvailableD365STeams.forEach(function (team) {

                        var foundTeam = builtRequest.D365Request.UserTeams.find(function (d365sTeam) {
                            return d365sTeam.Team.Id == team.Id;
                        });
                        if (foundTeam != null) {
                            team.Action = foundTeam.Action;
                            team.StartDate = foundTeam.StartDate;
                            team.EndDate = foundTeam.EndDate;
                            team.AccessType = foundTeam.AccessType;
                        }

                        prepareD365SAccessItem(team);
                    });
                }
            }

            // PeopleSoft
            if (builtRequest.PsRequest != null) {
                if ($scope.vm.ListOfSelectedPSRoles != null) {
                    $scope.vm.ListOfSelectedPSRoles.forEach(function (role) {
                        preparePSAccessItem(role);

                        var foundRole = builtRequest.PsRequest.Roles.find(function (PsRole) {
                            return PsRole.Name == role.Name;
                        });
                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.StartDate = foundRole.StartDate;
                            role.EndDate = foundRole.EndDate;
                            role.AccessType = foundRole.AccessType;
                        }
                    });
                }

                if ($scope.vm.ListOfAvailablePSRoles != null) {
                    $scope.vm.ListOfAvailablePSRoles.forEach(function (role) {
                        preparePSAccessItem(role);

                        var foundRole = builtRequest.PsRequest.Roles.find(function (PsRole) {
                            return PsRole.Name == role.Name;
                        });
                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.StartDate = foundRole.StartDate;
                            role.EndDate = foundRole.EndDate;
                            role.AccessType = foundRole.AccessType;
                        }
                    });
                }
            }

            // Salesforce
            if (builtRequest.SalesForceRequest != null) {
                if ($scope.vm.ListOfSelectedSalesforcePermissionSets != null) {
                    $scope.vm.ListOfSelectedSalesforcePermissionSets.forEach(permission => {
                        prepareSalesforceAccessItem(permission);

                        var foundPermission = builtRequest.SalesForceRequest.Permissions.find(requestPermission => requestPermission.Name == permission.Name);

                        if (foundPermission != null) {
                            permission.Action = foundPermission.Action;
                            permission.StartDate = foundPermission.StartDate;
                            permission.EndDate = foundPermission.EndDate;
                            permission.AccessType = foundPermission.AccessType;
                        }
                    });
                }

                if ($scope.vm.ListOfAvailableSalesforcePermissionSets != null) {
                    $scope.vm.ListOfAvailableSalesforcePermissionSets.forEach(permission => {
                        prepareSalesForceAccessItem(permission);

                        var foundPermission = builtRequest.SalesForceRequest.Permissions.find(requestPermission => requestPermission.Name === permission.Name);
                        if (foundPermission != null) {
                            permission.Action = foundPermission.Action;
                            permission.StartDate = foundPermission.StartDate;
                            permission.EndDate = foundPermission.EndDate;
                            permission.AccessType = foundPermission.AccessType;
                        }
                    });
                }

                if ($scope.vm.User.SalesforceUser.Profile) {
                    let profile = $scope.vm.User.SalesforceUser.Profile,
                        foundProfile = builtRequest.SalesForceRequest.Profiles.find(requestProfile => requestProfile.Id === profile.Id);

                    prepareSalesforceAccessItem(profile);

                    if (foundProfile) {
                        profile.Action = foundProfile.Action;
                        profile.StartDate = foundProfile.StartDate;
                        profile.EndDate = foundProfile.EndDate;
                        profile.AccessType = foundProfile.AccessType;
                    }
                }

                if ($scope.vm.salesforceProfiles) {
                    $scope.vm.salesforceProfiles.forEach(profile => {
                        prepareSalesForceAccessItem(profile);

                        var foundProfile = builtRequest.SalesForceRequest.Profiles.find(requestProfile => requestProfile.Id === profile.Id);
                        if (foundProfile != null) {
                            profile.Action = foundProfile.Action;
                            profile.StartDate = foundProfile.StartDate;
                            profile.EndDate = foundProfile.EndDate;
                            profile.AccessType = foundProfile.AccessType;
                        }
                    });
                }
            }

            loadRequestDfrd.resolve();
        });


        return $.when(loadRequestDfrd).done(function () {
        }).promise();

    };

    this.loadUserMasterERPData = function ($scope, userMaster, applications) {
        var User = {};
        var vm = $scope.vm;
        $scope.vm.helperService = helperService;
        $scope.vm.typeOf = typeOf;

        $scope.vm.userProperties = [];
        $scope.vm.userPropertyInfo = [];
        $scope.vm.terminationDisplay = [];

        if (vm == null) {
            vm = {};
            if ($scope.vm == null) {
                $scope.vm = vm;
            }
        }
        if ($scope.vm.User == null) {
            $scope.vm.User = User;
        }

        var userMasterDfrd = $.Deferred();

        $scope.vm.User.UserMaster = userMaster;
        var userMasterId = '';
        if (userMaster.Id) {
            userMasterId = userMaster.Id;
        } else if (userMaster.ID) {
            userMasterId = userMaster.ID;
        }
        $scope.netSuiteUserLoaded = false;
        $scope.upUserLoaded = false;
        $scope.sapUserLoaded = false;
        $scope.aadUserLoaded = false;
        $scope.axUserLoaded = false;
        $scope.gpUserLoaded = false;
        $scope.navUserLoaded = false;
        $scope.d365sUserLoaded = false;
        $scope.psUserLoaded = false;
        $scope.salesforceUserLoaded = false;

        //Reset all applications selected to false
        applications.forEach(function (app) {
            app.selected = false;
            app.Visible = false;
        });

        let isEmergencyAccess = vm.alternatePath === "EmergencyAccess" ? true : false;

        //Load data for existing users
        $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/requests/initiate/' + userMasterId

        }).then(({ data }) => {
            //Loop through Products and load ERP user data

            //Add list of errors, if any
            if (data.Errors != null) {
                $scope.vm.Errors = data.Errors;
            }

            $scope.vm.availableApplications = $scope.vm.availableApplications.map(setVisibleAndSelectedApps.bind(null, data));
            let dataCopy = { ...data };

            $scope.vm.availableUsers = Object.values(terminationSummaryFilter(dataCopy));
            createUserDetailsInstances($scope.vm.availableUsers);

            for (let i = 0; i < $scope.vm.availableUsers.length; i++) {
                $scope.vm.userProperties.push(Object.keys($scope.vm.availableUsers[i]));
                $scope.vm.userPropertyInfo.push(Object.values($scope.vm.availableUsers[i]));
            }

            $scope.vm.terminationDisplay = $scope.vm.availableUsers.reduce((acc, user, j) => {
                let tempArray = [];
                Object.keys(user).forEach((key, k) => {
                    let tempObject = {
                        UserProperty: $scope.vm.userProperties[j][k],
                        UserPropertyInfo: $scope.vm.userPropertyInfo[j][k]
                    }
                    tempArray.push(tempObject);
                });
                tempArray.push(user.ProductId);
                acc.push(tempArray);
                return acc;
            }, []);

            if (data.AadUser != null && !isEmergencyAccess) {
                $scope.vm.User.AadUser = data.AadUser;
                if ($scope.vm.User.AadUser.PasswordProfile) {
                    $scope.vm.User.AadUser.ConfirmPassword = $scope.vm.User.AadUser.PasswordProfile.Password;
                }
                if ($scope.vm.User.AadUser.BusinessPhones.length > 0) {
                    $scope.vm.User.AadUser.BusinessPhones = $scope.vm.User.AadUser.BusinessPhones.toString();
                }
            }
            if (data.AxUser != null && !isEmergencyAccess) {
                $scope.vm.User.AX2012User = data.AxUser;
                $scope.vm.User.AX2012User.CurrentAX2012User = { ...data.AxUser };
                $scope.vm.User.AX2012User.ConfirmPassword = $scope.vm.User.AX2012User.Password;
                $scope.vm.User.AX2012User.Partition = { Id: data.AxUser.Partition, Key: data.AxUser.PartitionKey };
            }
            if (data.Ax7User != null && !isEmergencyAccess) {
                $scope.vm.User.AX7User = data.Ax7User;
                $scope.vm.User.AX7User.CurrentAx7User = { ...data.Ax7User };
                $scope.vm.User.AX7User.ConfirmPassword = $scope.vm.User.AX7User.Password;
            }
            if (data.NetSuiteUser != null && !isEmergencyAccess) {
                $scope.vm.User.NetSuiteUser = data.NetSuiteUser;
                $scope.vm.User.NetSuiteUser.ConfirmPassword = $scope.vm.User.NetSuiteUser.Password;
            }
            if (data.EbsUser != null && !isEmergencyAccess) {
                $scope.vm.User.OracleUser = data.EbsUser;
                $scope.vm.User.OracleUser.ConfirmPassword = $scope.vm.User.OracleUser.Password;

                $scope.vm.User.OracleUser.Expiration = data.EbsUser.PasswordAccesses === null && data.EbsUser.PasswordDays === null ? 'None' : data.EbsUser.PasswordAccesses !== null ? 'Accesses' : 'Days';
                $scope.vm.User.OracleUser.ExpirationValue = data.EbsUser.Expiration === 'Days' ? data.EbsUser.PasswordDays : data.EbsUser.PasswordAccesses;
            }

            if (data.SapUser != null && data.SapUser.length > 0) {
                data.SapUser.forEach((user, i) => {
                    var productName = helperService.getProductName(user.ProductId, applications);
                    if (!$scope.vm.User.SAPUser) {
                        $scope.vm.User.SAPUser = [];
                    }
                    $scope.vm.User.SAPUser[productName] = user;
                    $scope.vm.User.SAPUser[productName].ConfirmPassword = $scope.vm.User.SAPUser[productName].Password;
                    $scope.vm.User.SAPUser[productName].ProductName = productName;
                });

                let userApplications = [];
                // get a list of applications the user has access to
                for (const userProduct in data) {
                    if (Array.isArray(data[userProduct])) {
                        data[userProduct].forEach(item => userApplications.push(item.ProductId));
                    } else if (data[userProduct] && data[userProduct].ProductId) {
                        userApplications.push(data[userProduct].ProductId);
                    }
                }

                // filter out applications that the user does not have access to
                $scope.vm.availableApplications.forEach((application, index, applications) => {
                    if (!userApplications.some(app => app === application.ProductID)) {
                        applications.splice(index, 1);
                    }
                });
            }
            if (data.UvUser != null && data.UvUser.length > 0 && !isEmergencyAccess) {
                data.UvUser.forEach((user, i) => {
                    var productName = helperService.getProductName(user.ProductId, applications);
                    if (!$scope.vm.User.UPUser) {
                        $scope.vm.User.UPUser = [];
                    }
                    $scope.vm.User.UPUser[productName] = user;
                    $scope.vm.User.UPUser[productName].ProductName = productName;
                });

                let userApplications = [];
                // get a list of applications the user has access to
                for (const userProduct in data) {
                    if (Array.isArray(data[userProduct])) {
                        data[userProduct].forEach(item => userApplications.push(item.ProductId));
                    } else if (data[userProduct] && data[userProduct].ProductId) {
                        userApplications.push(data[userProduct].ProductId);
                    }
                }

                // filter out applications that the user does not have access to
                $scope.vm.availableApplications.forEach((application, index, applications) => {
                    if (!userApplications.some(app => app === application.ProductID)) {
                        applications.splice(index, 1);
                    }
                });
            }

            // Oracle Cloud User
            if (data.OrfcUser != null && !isEmergencyAccess) {
                $scope.vm.User.OracleCloudUser = data.OrfcUser;
                $scope.vm.User.OracleCloudUser.PasswordConfirm = $scope.vm.User.OracleCloudUser.Password;
            }

            if (data.GPUser != null && !isEmergencyAccess) {
                $scope.vm.User.GPUser = data.GPUser;
                $scope.vm.User.GPUser.SamAccountName = data.GPUser.WindowsUserId;
            }

            if (data.NavUser != null && !isEmergencyAccess) {
                $scope.vm.User.NAVUser = data.NavUser;
            }
            if (data.D365SUser != null && !isEmergencyAccess) {
                $scope.vm.User.D365SUser = data.D365SUser;

                if ($scope.vm.User.D365SUser.AccessMode !== null) {
                    $rootScope.$broadcast('im-request-d365s-getlicenseTypes');
                }
            }
            if (data.PsUserRequest != null && !isEmergencyAccess) {
                $scope.vm.User.PSUser = data.PsUserRequest;
                if (data.PsUserRequest.PrimaryEmail) {
                    $scope.vm.originalPSEmail = data.PsUserRequest.PrimaryEmail.slice();
                } else {
                    $scope.vm.originalPSEmail = null;
                }
            }

            if (data.SfUserRequest != null && !isEmergencyAccess) {
                $scope.vm.User.SalesforceUser = data.SfUserRequest;
            }

            userMasterDfrd.resolve(data);

        }, function errorCallback(response) {
            userMasterDfrd.reject(response.data);
        });

        return $.when(userMasterDfrd).done(function () {
        }).promise();
    };
    //Load any user information lists displayed on the User Information pages
    this.loadApplicationData = function ($scope, openRequest) {
        var loadAppsDfrd = $.Deferred();
        var promises = [];

        try {
            if (openRequest.NetSuiteUser != null) {
                promises.push(RequestManagementNetSuiteLoadDataService.loadNetSuite($scope, openRequest.NetSuiteUser.Subsidiary.Id, openRequest));
            }

            if (openRequest.SapUser != null) {
                var selectedSapApplications = $scope.vm.availableApplications.filter(application => application.selected === true);
                selectedSapApplications.forEach(application => {
                    if (application.ProductType === 'SAP') {
                        promises.push(RequestManagementSAPLoadDataService.loadSAP($scope, application, null));
                    }
                });
            }

            if (openRequest.UvUser != null) {
                var selectedUvApplications = $scope.vm.availableApplications.filter(application => application.selected === true);

                selectedUvApplications.forEach(application => {
                    if (application.Name === 'Universal') {
                        promises.push(RequestManagementUPLoadDataService.loadUP($scope, application, null));
                    }
                });
            }

            if (openRequest.AadUser != null) {
                promises.push(RequestManagementAADLoadDataService.loadAAD($scope, openRequest.AadUser))
            }

            if (openRequest.Ax7User != null) {
                promises.push(RequestManagementAXLoadDataService.loadAX7($scope, openRequest.Ax7User));
            }

            if (openRequest.AxUser != null) {
                promises.push(RequestManagementAXLoadDataService.loadAX2012($scope, openRequest.AxUser));
            }

            if (openRequest.EbsUser != null) {
                promises.push(RequestManagementOracleLoadDataService.loadOracle($scope));
            }
            // Oracle Cloud
            if (openRequest.OrfcUser != null) {
                promises.push(RequestManagementOracleCloudLoadDataService.loadOracleCloud($scope));
            }

            if (openRequest.GPUser != null) {
                promises.push(RequestManagementGPLoadDataService.loadGP($scope));
            }

            if (openRequest.NavUser != null) {
                promises.push(RequestManagementNAVLoadDataService.loadNAV($scope));
            }

            if (openRequest.D365SUser != null) {
                promises.push(RequestManagementD365SLoadDataService.loadD365S($scope, openRequest.D365SUser));
            }

            if (openRequest.PsUserRequest != null) {
                promises.push(RequestManagementPSLoadDataService.loadPS($scope, openRequest.PSUser));
            }

            if (openRequest.SfUserRequest != null) {
                promises.push(salesforceLoadData.loadSalesforce($scope, openRequest.SfUserRequest));
            }

            $q.all(promises).then(function () {
                loadAppsDfrd.resolve();
            }, function errorCallback(response) {
                loadAppsDfrd.reject(response);
            });

        }
        catch (e) {
            loadAppsDfrd.reject(e);
        }

        return $.when(loadAppsDfrd).done(function () {
        }).promise();
    };

    function lockdownOnError($scope, errorMessage) {
        $scope.reportDetailsComplete = false;
        $scope.stopRequestManagementSpinner = true;

        //helperService.showErrorMessage(errorMessage);
        $rootScope.$emit('RequestManagementStopGridLoadingEvent', "Error");
    }
    //Find any permission changes for display, currently on Access Effective Dates and Summary
    this.findAccessChanges = function (request, accessChanges) {
        var changeList = [];

        if (request.GPRequest != null) {
            request.GPRequest.RoleCompanies.forEach(function (item) {
                changeList.push(prepareGPAccessItem(item));
            });

            request.GPRequest.UserCompanies.forEach(function (item) {
                changeList.push(prepareGPAccessItem(item));
            });
        }

        if (request.NetSuiteRequest != null) {

            request.NetSuiteRequest.Roles.forEach(function (item) {
                changeList.push(prepareNSAccessItem(item));
            });

            request.NetSuiteRequest.GlobalPermissions.forEach(function (item) {
                changeList.push(prepareNSAccessItem(item));
            });
        }

        if (request.UniversalRequest != null) {
            request.UniversalRequest.forEach(universalRequest => {
                universalRequest.UserRoles.forEach(function (item) {
                    changeList.push(prepareUniversalAccessItem(item, item.Role.ProductName));
                });
            });
        }

        if (request.SAPRequest != null) {
            request.SAPRequest.forEach(sapRequest => {
                sapRequest.Roles.forEach(function (item) {
                    changeList.push(prepareSAPAccessItem(item));
                });
            });
        }

        if (request.AadRequest != null) {
            request.AadRequest.Roles.forEach(function (item) {
                changeList.push(prepareAADAccessItem(item));
            });

            request.AadRequest.Groups.forEach(function (item) {
                changeList.push(prepareAADAccessItem(item));
            });

            request.AadRequest.Licenses.forEach(function (item) {
                changeList.push(prepareAADAccessItem(item));
            });
        }

        if (request.Ax7Request != null) {
            request.Ax7Request.RoleCompanies.forEach(function (item) {
                changeList.push(prepareAX7AccessItem(item));
            });

            request.Ax7Request.UserGroups.forEach(function (item) {
                changeList.push(prepareAX7AccessItem(item));
            });
        }

        if (request.AxRequest != null) {
            request.AxRequest.RoleCompanies.forEach(function (item) {
                changeList.push(prepareAX2012AccessItem(item));
            });

            request.AxRequest.UserGroups.forEach(function (item) {
                changeList.push(prepareAX2012AccessItem(item));
            });
        }

        if (request.NavRequest != null) {
            request.NavRequest.PermissionSets.forEach(function (item) {
                changeList.push(prepareNAVAccessItem(item));
            });

            request.NavRequest.UserGroups.forEach(function (item) {
                changeList.push(prepareNAVAccessItem(item));
            });
        }

        if (request.EbsRequest != null) {
            request.EbsRequest.Responsibilities.forEach(function (item) {
                changeList.push(prepareORAccessItem(item));
            });

            request.EbsRequest.Roles.forEach(function (item) {
                changeList.push(prepareORAccessItem(item));
            });
        }
        // Oracle Cloud
        if (request.OrfcRequest != null) {
            request.OrfcRequest.Roles.forEach(function (item) {
                changeList.push(prepareORFCAccessItem(item));
            });
        }

        if (request.D365SRequest != null) {
            request.D365SRequest.UserRoles.forEach(function (item) {
                changeList.push(prepareD365SAccessItem(item));
            });
            request.D365SRequest.UserTeams.forEach(function (item) {
                changeList.push(prepareD365SAccessItem(item));
            });
        }

        if (request.PsRequest != null) {
            request.PsRequest.Roles.forEach(function (item) {
                changeList.push(preparePSAccessItem(item));
            });
        }

        if (request.SfRequest != null) {
            request.SfRequest.Profiles.forEach(item => {
                changeList.push(prepareSalesforceAccessItem(item));
            });
            request.SfRequest.PermissionSets.forEach(item => {
                changeList.push(prepareSalesforceAccessItem(item));
            });
        }


        changeList.forEach(function (access) {

            self.addEffectiveDates(access, accessChanges);

            if (access.StartDate != null && access.MinDate == null) {
                access.MinDate = access.StartDate;
            }

            if (access.MinDate == null) {
                access.MinDate = new Date(2000, 0, 1, 0, 0, 0);
            }
        });

        return changeList;
    };
    //Find if a change is being made to a User, currently displayed on the Summary page
    this.findUserChanges = function (data) {
        var changeList = [];

        if (data.User.NetSuiteUser != null && data.User.NetSuiteUser.Action != null && data.availableApplications.some(availableApp => availableApp.ProductID === data.User.NetSuiteUser.ProductId && availableApp.selected)) {
            changeList.push(data.User.NetSuiteUser);
        }

        if (data.User.SAPUser != null) {
            Object.keys(data.User.SAPUser).forEach(key => {
                const currentObject = data.User.SAPUser[key];

                if (currentObject && currentObject.Action && data.availableApplications.some(availableApp => key === availableApp.ProductName && availableApp.selected)) {
                    changeList.push(currentObject);
                }
            });
        }

        if (data.User.AadUser != null && data.User.AadUser.Action != null && data.availableApplications.some(availableApp => availableApp.ProductID === data.User.AadUser.ProductId && availableApp.selected)) {
            changeList.push(data.User.AadUser);
        }

        if (data.User.AX7User != null && data.User.AX7User.Action != null && data.availableApplications.some(availableApp => availableApp.ProductID === data.User.AX7User.ProductId && availableApp.selected)) {
            changeList.push(data.User.AX7User);
        }

        if (data.User.AX2012User != null && data.User.AX2012User.Action != null && data.availableApplications.some(availableApp => availableApp.ProductID === data.User.AX2012User.ProductId && availableApp.selected)) {
            changeList.push(data.User.AX2012User);
        }

        if (data.User.OracleUser != null && data.User.OracleUser.Action != null && data.availableApplications.some(availableApp => availableApp.ProductID === data.User.OracleUser.ProductId && availableApp.selected)) {
            changeList.push(data.User.OracleUser);
        }

        if (data.User.OracleCloudUser != null && data.User.OracleCloudUser.Action != null && data.availableApplications.some(availableApp => availableApp.ProductID === data.User.OracleCloudUser.ProductId && availableApp.selected)) {
            changeList.push(data.User.OracleCloudUser);
        }

        if (data.User.GPUser != null && data.User.GPUser.Action != null && data.availableApplications.some(availableApp => availableApp.ProductID === data.User.GPUser.ProductId && availableApp.selected)) {
            changeList.push(data.User.GPUser);
        }

        if (data.User.NAVUser != null && data.User.NAVUser.Action != null && data.availableApplications.some(availableApp => availableApp.ProductID === data.User.NAVUser.ProductId && availableApp.selected)) {
            changeList.push(data.User.NAVUser);
        }

        if (data.User.D365SUser != null && data.User.D365SUser.Action != null && data.availableApplications.some(availableApp => availableApp.ProductID === data.User.D365SUser.ProductId && availableApp.selected)) {
            changeList.push(data.User.D365SUser);
        }

        if (data.User.PSUser != null && data.User.PSUser.Action != null && data.availableApplications.some(availableApp => availableApp.ProductID === data.User.PSUser.ProductId && availableApp.selected)) {
            changeList.push(data.User.PSUser);
        }

        if (data.User.SalesforceUser != null) {
            if (data.User.SalesforceUser != null && data.User.SalesforceUser.Action != null) {
                changeList.push(data.User.SalesforceUser);
            }
        }

        if (data.User.UPUser != null) {
            Object.keys(data.User.UPUser).forEach(key => {
                const currentObject = data.User.UPUser[key];
                if (currentObject && currentObject.Action && data.availableApplications.some(availableApp => key === availableApp.ProductName && availableApp.selected)) {
                    changeList.push(currentObject);
                }
            });
        }

        return changeList;
    };
    //Populate IM with data returned from the Build call
    this.loadRequestData = function (toStep, fromStep, vm, scope, request) {
        var loadRequestDfrd = $.Deferred();

        if (request.NetSuiteRequest != null) {
            if (request.NetSuiteRequest.User != null) {
                vm.User.NetSuiteUser.ConfirmPassword = request.NetSuiteRequest.User.Password;
                vm.User.NetSuiteUser.Action = request.NetSuiteRequest.User.Action;
                vm.User.NetSuiteUser.ProductId = request.NetSuiteRequest.User.ProductId;
                vm.User.NetSuiteUser.ApplicationType = request.NetSuiteRequest.User.ApplicationType;
                vm.User.NetSuiteUser.UserChanges = request.NetSuiteRequest.User.UserChanges;
            }
            vm.User.NetSuiteUser.ApplicationId = netsuite_application_id;

            if (vm.ListOfSelectedNetSuiteRoles != null) {
                //Remove all actions in between builds
                vm.ListOfSelectedNetSuiteRoles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfSelectedNetSuiteRoles.forEach(function (role) {
                    prepareNSAccessItem(role);

                    var foundRole = request.NetSuiteRequest.Roles.find(function (nsRole) {
                        return nsRole.Id == role.Id;
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }
                });
            }

            if (vm.ListOfAvailableNetSuiteRoles != null) {
                vm.ListOfAvailableNetSuiteRoles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfAvailableNetSuiteRoles.forEach(function (role) {
                    prepareNSAccessItem(role);

                    var foundRole = request.NetSuiteRequest.Roles.find(function (nsRole) {
                        return nsRole.Id == role.Id;
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }
                });
            }


            if (vm.ListOfSelectedNetSuitePermissions != null) {
                vm.ListOfSelectedNetSuitePermissions.forEach(function (perm) {
                    perm.Action = null;
                });
                vm.ListOfSelectedNetSuitePermissions.forEach(function (perm) {
                    prepareNSAccessItem(perm);


                    var foundPermission = request.NetSuiteRequest.GlobalPermissions.find(function (selPerm) {
                        return selPerm.Id == perm.Id;
                    });
                    if (foundPermission != null) {
                        perm.Action = foundPermission.Action;
                        perm.AccessType = foundPermission.AccessType;
                    }
                });
            }

            if (vm.ListOfAvailableNetSuitePermissions != null) {
                vm.ListOfAvailableNetSuitePermissions.forEach(function (perm) {
                    perm.Action = null;
                });
                vm.ListOfAvailableNetSuitePermissions.forEach(function (perm) {
                    prepareNSAccessItem(perm);

                    var foundPermission = request.NetSuiteRequest.GlobalPermissions.find(function (selPerm) {
                        return selPerm.Id == perm.Id;
                    });
                    if (foundPermission != null) {
                        perm.Action = foundPermission.Action;
                        perm.AccessType = foundPermission.AccessType;
                    }
                });
            }
        }
        if (request.AadRequest != null) {
            if (request.AadRequest.User != null) {
                if (request.AadRequest.User.PasswordProfile) {
                    vm.User.AadUser.ConfirmPassword = request.AadRequest.User.PasswordProfile.Password;
                }
                vm.User.AadUser.Action = request.AadRequest.User.Action;
                vm.User.AadUser.ProductId = request.AadRequest.User.ProductId;
                vm.User.AadUser.ApplicationType = request.AadRequest.User.ApplicationType;
                vm.User.AadUser.UserChanges = request.AadRequest.User.UserChanges;
            }
            vm.User.AadUser.ApplicationId = aad_application_id;

            if (vm.ListOfSelectedAADRoles != null) {
                vm.ListOfSelectedAADRoles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfSelectedAADRoles.forEach(function (role) {

                    var foundRole = request.AadRequest.Roles.find(function (adRole) {
                        return adRole.Id.toUpperCase() == role.Id.toUpperCase();
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }

                    prepareAADAccessItem(role);
                });
            }

            if (vm.ListOfAvailableAADRoles != null) {
                vm.ListOfAvailableAADRoles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfAvailableAADRoles.forEach(function (role) {

                    var foundRole = request.AadRequest.Roles.find(function (adRole) {
                        return adRole.Id.toUpperCase() == role.Id.toUpperCase();
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }

                    prepareAADAccessItem(role);
                });
            }

            if (vm.ListOfSelectedAADGroups != null) {
                vm.ListOfSelectedAADGroups.forEach(function (group) {
                    group.Action = null;
                });
                vm.ListOfSelectedAADGroups.forEach(function (group) {

                    var foundLicense = request.AadRequest.Groups.find(function (adGroup) {
                        return adGroup.Id.toUpperCase() == group.Id.toUpperCase();
                    });
                    if (foundLicense != null) {
                        group.Action = foundLicense.Action;
                        group.AccessType = foundLicense.AccessType;
                    }

                    prepareAADAccessItem(group);
                });
            }

            if (vm.ListOfAvailableAADGroups != null) {
                vm.ListOfAvailableAADGroups.forEach(function (group) {
                    group.Action = null;
                });
                vm.ListOfAvailableAADGroups.forEach(function (group) {

                    var foundLicense = request.AadRequest.Groups.find(function (adGroup) {
                        return adGroup.Id.toUpperCase() == group.Id.toUpperCase();
                    });
                    if (foundLicense != null) {
                        group.Action = foundLicense.Action;
                        group.AccessType = foundLicense.AccessType;
                    }

                    prepareAADAccessItem(group);
                });
            }

            if (vm.ListOfSelectedAADLicenses != null) {
                vm.ListOfSelectedAADLicenses.forEach(function (license) {
                    license.Action = null;
                });
                vm.ListOfSelectedAADLicenses.forEach(function (license) {

                    var foundLicense = request.AadRequest.Licenses.find(function (adLicense) {
                        return adLicense.SkuId.toUpperCase() == license.SkuId.toUpperCase();
                    });
                    if (foundLicense != null) {
                        license.Action = foundLicense.Action;
                        license.AccessType = foundLicense.AccessType;
                    }

                    prepareAADAccessItem(license);
                });
            }

            if (vm.ListOfAvailableAADLicenses != null) {
                vm.ListOfAvailableAADLicenses.forEach(function (license) {
                    license.Action = null;
                });
                vm.ListOfAvailableAADLicenses.forEach(function (license) {

                    var foundLicense = request.AadRequest.Licenses.find(function (adLicense) {
                        return adLicense.SkuId.toUpperCase() == license.SkuId.toUpperCase();
                    });
                    if (foundLicense != null) {
                        license.Action = foundLicense.Action;
                        license.AccessType = foundLicense.AccessType;
                    }

                    prepareAADAccessItem(license);
                });
            }
        }
        if (request.Ax7Request != null) {
            if (request.Ax7Request.User != null) {
                vm.User.AX7User.ConfirmPassword = request.Ax7Request.User.Password;
                vm.User.AX7User.Action = request.Ax7Request.User.Action;
                vm.User.AX7User.ProductId = request.Ax7Request.User.ProductId;
                vm.User.AX7User.ApplicationType = request.Ax7Request.User.ApplicationType;
                vm.User.AX7User.UserChanges = request.Ax7Request.User.UserChanges;
            }
            vm.User.AX7User.ApplicationId = ax7_application_id;

            if (vm.ListOfSelectedAX7Roles != null) {
                vm.ListOfSelectedAX7Roles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfSelectedAX7Roles.forEach(function (role) {

                    var foundRole = request.Ax7Request.RoleCompanies.find(function (axRole) {
                        return axRole.Role.Id.toUpperCase() == role.Id.toUpperCase();
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }

                    prepareAX7AccessItem(role);
                });
            }

            if (vm.ListOfAvailableAX7Roles != null) {
                vm.ListOfAvailableAX7Roles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfAvailableAX7Roles.forEach(function (role) {

                    var foundRole = request.Ax7Request.RoleCompanies.find(function (axRole) {
                        return axRole.Role.Id.toUpperCase() == role.Id.toUpperCase();
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }

                    prepareAX7AccessItem(role);
                });
            }

            if (vm.ListOfSelectedAX7UserGroups != null) {
                vm.ListOfSelectedAX7UserGroups.forEach(function (userGroup) {
                    userGroup.Action = null;
                });
                vm.ListOfSelectedAX7UserGroups.forEach(function (userGroup) {

                    var foundUserGroup = request.Ax7Request.UserGroups.find(function (axUserGroup) {
                        return axUserGroup.Id.toUpperCase() == userGroup.Id.toUpperCase();
                    });
                    if (foundUserGroup != null) {
                        userGroup.Action = foundUserGroup.Action;
                        userGroup.AccessType = foundUserGroup.AccessType;
                    }

                    prepareAX7AccessItem(userGroup);
                });
            }

            if (vm.ListOfAvailableAX7UserGroups != null) {
                vm.ListOfAvailableAX7UserGroups.forEach(function (userGroup) {
                    userGroup.Action = null;
                });
                vm.ListOfAvailableAX7UserGroups.forEach(function (userGroup) {

                    var foundUserGroup = request.Ax7Request.UserGroups.find(function (axUserGroup) {
                        return axUserGroup.Id.toUpperCase() == userGroup.Id.toUpperCase();
                    });
                    if (foundUserGroup != null) {
                        userGroup.Action = foundUserGroup.Action;
                        userGroup.AccessType = foundUserGroup.AccessType;
                    }

                    prepareAX7AccessItem(userGroup);
                });
            }
        }
        if (request.AxRequest != null) {
            if (request.AxRequest.User != null) {
                vm.User.AX2012User.ApplicationType = request.AxRequest.User.ApplicationType;
                vm.User.AX2012User.Action = request.AxRequest.User.Action;
                vm.User.AX2012User.ProductId = request.AxRequest.User.ProductId;
                vm.User.AX2012User.UserChanges = request.AxRequest.User.UserChanges;
            }
            vm.User.AX2012User.ApplicationId = ax_application_id;

            if (vm.ListOfSelectedAX2012Roles != null) {
                vm.ListOfSelectedAX2012Roles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfSelectedAX2012Roles.forEach(function (role) {

                    var foundRole = request.AxRequest.RoleCompanies.find(function (axRole) {
                        return axRole.Role.Id.toUpperCase() == role.Id.toUpperCase();
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }

                    prepareAX2012AccessItem(role);
                });
            }

            if (vm.ListOfAvailableAX2012Roles != null) {
                vm.ListOfAvailableAX2012Roles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfAvailableAX2012Roles.forEach(function (role) {

                    var foundRole = request.AxRequest.RoleCompanies.find(function (axRole) {
                        return axRole.Role.Id.toUpperCase() == role.Id.toUpperCase();
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }

                    prepareAX2012AccessItem(role);
                });
            }

            if (vm.ListOfSelectedAX2012UserGroups != null) {
                vm.ListOfSelectedAX2012UserGroups.forEach(function (userGroup) {
                    userGroup.Action = null;
                });
                vm.ListOfSelectedAX2012UserGroups.forEach(function (userGroup) {

                    var foundUserGroup = request.AxRequest.UserGroups.find(function (axUserGroup) {
                        return axUserGroup.Id.toUpperCase() == userGroup.Id.toUpperCase();
                    });
                    if (foundUserGroup != null) {
                        userGroup.Action = foundUserGroup.Action;
                        userGroup.AccessType = foundUserGroup.AccessType;
                    }

                    prepareAX2012AccessItem(userGroup);
                });
            }

            if (vm.ListOfAvailableAX2012UserGroups != null) {
                vm.ListOfAvailableAX2012UserGroups.forEach(function (userGroup) {
                    userGroup.Action = null;
                });
                vm.ListOfAvailableAX2012UserGroups.forEach(function (userGroup) {

                    var foundUserGroup = request.AxRequest.UserGroups.find(function (axUserGroup) {
                        return axUserGroup.Id.toUpperCase() == userGroup.Id.toUpperCase();
                    });
                    if (foundUserGroup != null) {
                        userGroup.Action = foundUserGroup.Action;
                        userGroup.AccessType = foundUserGroup.AccessType;
                    }

                    prepareAX2012AccessItem(userGroup);
                });
            }
        }
        if (request.UniversalRequest != null) {
            request.UniversalRequest.forEach(universalRequest => {
                var productName = vm.availableApplications.find(application => application.ProductID === universalRequest.ProductId).ProductName;

                if (universalRequest.User != null) {
                    vm.User.UPUser[productName].Action = universalRequest.User.Action;
                    vm.User.UPUser[productName].ApplicationType = universalRequest.User.ApplicationType;
                    vm.User.UPUser[productName].ProductId = universalRequest.User.ProductId;
                    vm.User.UPUser[productName].UserChanges = universalRequest.User.UserChanges;
                }
                vm.User.UPUser[productName].ApplicationId = universalProduct_application_id;

                if (vm.ListOfSelectedUPRoles[productName] != null) {
                    vm.ListOfSelectedUPRoles[productName].forEach(function (role) {
                        role.Action = null;
                    });

                    vm.ListOfSelectedUPRoles[productName].forEach(function (role) {
                        prepareUniversalAccessItem(role, productName);
                        var foundRole = universalRequest.UserRoles.find(function (universalRole) {
                            return universalRole.Id == role.Id;
                        });

                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.AccessType = foundRole.AccessType;
                        }
                    });
                }

                if (vm.ListOfAvailableUPRoles[productName] != null) {
                    vm.ListOfAvailableUPRoles[productName].forEach(function (role) {
                        role.Action = null;
                    });

                    vm.ListOfAvailableUPRoles[productName].forEach(function (role) {
                        prepareUniversalAccessItem(role, productName);
                        var foundRole = universalRequest.UserRoles.find(function (universalRole) {
                            return universalRole.Id == role.Id;
                        });

                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.AccessType = foundRole.AccessType;
                        }
                    });
                }
            });
        }
        if (request.SAPRequest != null) {
            request.SAPRequest.forEach(sapRequest => {
                var productName = vm.availableApplications.find(application => application.ProductID === sapRequest.ProductId).ProductName;

                if (sapRequest.User != null) {
                    vm.User.SAPUser[productName].ConfirmPassword = sapRequest.User.Password;
                    vm.User.SAPUser[productName].Action = sapRequest.User.Action;
                    vm.User.SAPUser[productName].ApplicationType = sapRequest.User.ApplicationType;
                    vm.User.SAPUser[productName].ProductId = sapRequest.User.ProductId;
                    vm.User.SAPUser[productName].UserChanges = sapRequest.User.UserChanges;
                }
                vm.User.SAPUser[productName].ApplicationId = sap_application_id;

                // for Single Roles
                if (vm.ListOfSelectedSAPSingleRoles[productName] != null) {
                    vm.ListOfSelectedSAPSingleRoles[productName].forEach(role => {
                        role.Action = null;
                    });

                    vm.ListOfSelectedSAPSingleRoles[productName].forEach(role => {
                        prepareSAPAccessItem(role);
                        var foundRole = sapRequest.Roles.find(sapRole => sapRole.Id == role.Id);

                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.AccessType = foundRole.AccessType;
                        }
                    });
                }

                if (vm.ListOfAvailableSAPSingleRoles[productName] != null) {
                    vm.ListOfAvailableSAPSingleRoles[productName].forEach(role => {
                        role.Action = null;
                    });

                    vm.ListOfAvailableSAPSingleRoles[productName].forEach(role => {
                        prepareSAPAccessItem(role);
                        var foundRole = sapRequest.Roles.find(sapRole => sapRole.Id == role.Id);

                        if (foundRole != null) {
                            role.Action = foundRole.Action;
                            role.AccessType = foundRole.AccessType;
                        }
                    });
                }

                // for Composite Roles
                if (vm.ListOfSelectedSAPCompositeRoles[productName] != null) {
                    vm.ListOfSelectedSAPCompositeRoles[productName].forEach(compositeRole => {
                        compositeRole.Action = null;
                    });

                    vm.ListOfSelectedSAPCompositeRoles[productName].forEach(compositeRole => {
                        prepareSAPAccessItem(compositeRole);
                        var foundCompositeRole = sapRequest.Roles.find(sapCompositeRole => sapCompositeRole.Id == compositeRole.Id);

                        if (foundCompositeRole != null) {
                            compositeRole.Action = foundCompositeRole.Action;
                            compositeRole.AccessType = foundCompositeRole.AccessType;
                        }
                    });
                }

                if (vm.ListOfAvailableSAPCompositeRoles[productName] != null) {
                    vm.ListOfAvailableSAPCompositeRoles[productName].forEach(compositeRole => {
                        compositeRole.Action = null;
                    });

                    vm.ListOfAvailableSAPCompositeRoles[productName].forEach(compositeRole => {
                        prepareSAPAccessItem(compositeRole);
                        var foundCompositeRole = sapRequest.Roles.find(sapCompositeRole => sapCompositeRole.Id == compositeRole.Id);

                        if (foundCompositeRole != null) {
                            compositeRole.Action = foundCompositeRole.Action;
                            compositeRole.AccessType = foundCompositeRole.AccessType;
                        }
                    });
                }
            });
        }
        if (request.EbsRequest != null) {
            if (request.EbsRequest.User != null) {
                vm.User.OracleUser.ConfirmPassword = request.EbsRequest.User.Password;
                vm.User.OracleUser.Action = request.EbsRequest.User.Action;
                vm.User.OracleUser.ProductId = request.EbsRequest.User.ProductId;
                vm.User.OracleUser.ApplicationType = request.EbsRequest.User.ApplicationType;
                vm.User.OracleUser.Expiration = request.EbsRequest.User.PasswordAccesses === null && request.EbsRequest.User.PasswordDays === null ? 'None' : request.EbsRequest.User.PasswordAccesses === null ? 'Days' : 'Accesses';
                vm.User.OracleUser.ExpirationValue = vm.User.OracleUser.Expiration === 'Days' ? request.EbsRequest.User.PasswordDays : request.EbsRequest.User.PasswordAccesses;
                vm.User.OracleUser.UserChanges = request.EbsRequest.User.UserChanges;
            }
            vm.User.OracleUser.ApplicationId = oracle_application_id;

            if (vm.ListOfSelectedOracleResponsibilities != null) {
                vm.ListOfSelectedOracleResponsibilities.forEach(function (responsibility) {
                    responsibility.Action = null;
                });
                vm.ListOfSelectedOracleResponsibilities.forEach(function (responsibility) {
                    prepareORAccessItem(responsibility);

                    var foundResponsibility = request.EbsRequest.Responsibilities.find(function (oracle) {
                        return oracle.Id == responsibility.Id;
                    });

                    if (foundResponsibility != null) {
                        responsibility.Action = foundResponsibility.Action;
                        responsibility.AccessType = foundResponsibility.AccessType;
                    }
                });
            }

            if (vm.ListOfAvailableOracleResponsibilities != null) {
                vm.ListOfAvailableOracleResponsibilities.forEach(function (responsibility) {
                    responsibility.Action = null;
                });
                vm.ListOfAvailableOracleResponsibilities.forEach(function (responsibility) {
                    prepareORAccessItem(responsibility);

                    var foundResponsibility = request.EbsRequest.Responsibilities.find(function (oracle) {
                        return oracle.Id == responsibility.Id;
                    });

                    if (foundResponsibility != null) {
                        responsibility.Action = foundResponsibility.Action;
                        responsibility.AccessType = foundResponsibility.AccessType;
                    }
                });
            }

            if (vm.ListOfSelectedOracleRoles != null) {
                vm.ListOfSelectedOracleRoles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfSelectedOracleRoles.forEach(function (role) {
                    prepareORAccessItem(role);

                    var foundRole = request.EbsRequest.Roles.find(function (oracle) {
                        return oracle.Id == role.Id;
                    });

                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }
                });
            }

            if (vm.ListOfAvailableOracleRoles != null) {
                vm.ListOfAvailableOracleRoles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfAvailableOracleRoles.forEach(function (role) {
                    prepareORAccessItem(role);

                    var foundRole = request.EbsRequest.Roles.find(function (oracle) {
                        return oracle.Id == role.Id;
                    });

                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }
                });
            }
        }

        // Oracle Cloud
        if (request.OrfcRequest != null) {
            if (request.OrfcRequest.User != null) {
                vm.User.OracleCloudUser = request.OrfcRequest.User;
                vm.User.OracleCloudUser.Action = request.OrfcRequest.User.Action;
                vm.User.OracleCloudUser.PasswordConfirm = request.OrfcRequest.User.Password;
                vm.User.OracleCloudUser.UserChanges = request.OrfcRequest.User.UserChanges;
            }
            vm.User.OracleCloudUser.ApplicationId = oracleCloud_application_id;

            if (vm.User.OrfcUser != null && vm.ListOfStoredOracleCloudRoles != null) {
                //Remove all actions in between builds
                vm.ListOfStoredOracleCloudRoles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfStoredOracleCloudRoles.forEach(function (role) {
                    prepareORFCAccessItem(role);

                    var foundRole = request.OrfcRequest.Roles.find(function (orfcRole) {
                        return orfcRole.RoleID == role.RoleID;
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }
                });
            }

            if (vm.ListOfAvailableOracleCloudRoles != null) {
                vm.ListOfAvailableOracleCloudRoles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfAvailableOracleCloudRoles.forEach(function (role) {
                    prepareORFCAccessItem(role);

                    var foundRole = request.OrfcRequest.Roles.find(function (orfcRole) {
                        return orfcRole.RoleID == role.RoleID;
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }
                });
            }
        }
        // END

        if (request.GPRequest != null) {
            if (request.GPRequest.User != null) {
                vm.User.GPUser.ConfirmPassword = request.GPRequest.User.Password;
                vm.User.GPUser.Action = request.GPRequest.User.Action;
                vm.User.GPUser.ProductId = request.GPRequest.User.ProductId;
                vm.User.GPUser.ApplicationType = request.GPRequest.User.ApplicationType;
                vm.User.GPUser.UserChanges = request.GPRequest.User.UserChanges;
            }
            vm.User.GPUser.ApplicationId = gp_application_id;

            if (vm.User.GPUser != null && vm.ListOfStoredGPRoles != null) {
                //Remove all actions in between builds
                vm.ListOfStoredGPRoles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfStoredGPRoles.forEach(function (role) {
                    prepareGPAccessItem(role);

                    var foundRole = request.GPRequest.RoleCompanies.find(function (nsRole) {
                        return nsRole.Role.Id == role.Role.Id;
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }
                });
            }

            if (vm.ListOfAvailableGPRoles != null) {
                vm.ListOfAvailableGPRoles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfAvailableGPRoles.forEach(function (role) {
                    prepareGPAccessItem(role);

                    var foundRole = request.GPRequest.RoleCompanies.find(function (nsRole) {
                        return nsRole.Role.Id == role.Id;
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }
                });
            }

            if (vm.ListOfSelectedGPCompanies != null) {
                vm.ListOfSelectedGPCompanies.forEach(function (perm) {
                    perm.Action = null;
                });
                vm.ListOfSelectedGPCompanies.forEach(function (perm) {
                    prepareGPAccessItem(perm);

                    var foundPermission = request.GPRequest.UserCompanies.find(function (selPerm) {
                        return selPerm.Company.Id == perm.Id;
                    });
                    if (foundPermission != null) {
                        perm.Action = foundPermission.Action;
                        perm.AccessType = foundPermission.AccessType;
                    }
                });
            }

            if (vm.ListOfAvailableGPCompanies != null) {
                vm.ListOfAvailableGPCompanies.forEach(function (perm) {
                    perm.Action = null;
                });
                vm.ListOfAvailableGPCompanies.forEach(function (perm) {
                    prepareGPAccessItem(perm);

                    var foundPermission = request.GPRequest.UserCompanies.find(function (selPerm) {
                        return selPerm.Company.Id == perm.Id;
                    });
                    if (foundPermission != null) {
                        perm.Action = foundPermission.Action;
                        perm.AccessType = foundPermission.AccessType;
                    }
                });
            }
        }
        if (request.NavRequest != null) {
            if (request.NavRequest.User != null) {
                vm.User.NAVUser.Action = request.NavRequest.User.Action;
                vm.User.NAVUser.ProductId = request.NavRequest.User.ProductId;
                vm.User.NAVUser.ApplicationType = request.NavRequest.User.ApplicationType;
                vm.User.NAVUser.UserChanges = request.NavRequest.User.UserChanges;
            }
            vm.User.NAVUser.ApplicationId = netsuite_application_id;

            if (vm.ListOfSelectedNAVPermissionSets != null) {
                //Remove all actions in between builds
                vm.ListOfSelectedNAVPermissionSets.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfSelectedNAVPermissionSets.forEach(function (role) {
                    prepareNAVAccessItem(role);

                    var foundRole = request.NavRequest.PermissionSets.find(function (nsRole) {
                        return nsRole.Id == role.Id;
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }
                });
            }

            if (vm.ListOfAvailableNAVPermissionSets != null) {
                vm.ListOfAvailableNAVPermissionSets.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfAvailableNAVPermissionSets.forEach(function (role) {
                    prepareNAVAccessItem(role);

                    var foundRole = request.NavRequest.PermissionSets.find(function (nsRole) {
                        return nsRole.Id == role.Id;
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }
                });
            }

            if (vm.ListOfSelectedNAVUserGroups != null) {
                //Remove all actions in between builds
                vm.ListOfSelectedNAVUserGroups.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfSelectedNAVUserGroups.forEach(function (role) {
                    prepareNAVAccessItem(role);

                    var foundRole = request.NavRequest.UserGroups.find(function (nsRole) {
                        return nsRole.Id == role.Id;
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }
                });
            }

            if (vm.ListOfAvailableNAVUserGroups != null) {
                vm.ListOfAvailableNAVUserGroups.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfAvailableNAVUserGroups.forEach(function (role) {
                    prepareNAVAccessItem(role);

                    var foundRole = request.NavRequest.UserGroups.find(function (nsRole) {
                        return nsRole.Id == role.Id;
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }
                });
            }
        }
        if (request.D365SRequest != null) {
            if (request.D365SRequest.User != null) {
                vm.User.D365SUser.Action = request.D365SRequest.User.Action;
                vm.User.D365SUser.ProductId = request.D365SRequest.User.ProductId;
                vm.User.D365SUser.ApplicationType = request.D365SRequest.User.ApplicationType;
                vm.User.D365SUser.UserChanges = request.D365SRequest.User.UserChanges;
            }
            vm.User.D365SUser.ApplicationId = d365s_application_id;

            if (vm.ListOfSelectedD365SRoles != null) {
                vm.ListOfSelectedD365SRoles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfSelectedD365SRoles.forEach(function (role) {

                    var foundRole = request.D365SRequest.UserRoles.find(function (d365sRole) {
                        return d365sRole.Role.Id.toUpperCase() === role.Id.toUpperCase();
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }

                    prepareD365SAccessItem(role);
                });
            }

            if (vm.ListOfAvailableD365SRoles != null) {
                vm.ListOfAvailableD365SRoles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfAvailableD365SRoles.forEach(function (role) {

                    var foundRole = request.D365SRequest.UserRoles.find(function (d365sRole) {
                        return d365sRole.Role.Id.toUpperCase() === role.Id.toUpperCase();
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }

                    prepareD365SAccessItem(role);
                });
            }

            if (vm.ListOfSelectedD365STeams != null) {
                vm.ListOfSelectedD365STeams.forEach(function (team) {

                    var foundTeam = request.D365SRequest.UserTeams.find(function (d365sTeam) {
                        return d365sTeam.Team.Id.toUpperCase() === team.Id;
                    });
                    if (foundTeam != null) {
                        team.Action = foundTeam.Action;
                        team.AccessType = foundTeam.AccessType;
                    }

                    prepareD365SAccessItem(team);
                });
            }

            if (vm.ListOfAvailableD365STeams != null) {
                vm.ListOfAvailableD365STeams.forEach(function (team) {

                    var foundTeam = request.D365SRequest.UserTeams.find(function (d365sTeam) {
                        return d365sTeam.Team.Id.toUpperCase() === team.Id;
                    });
                    if (foundTeam != null) {
                        team.Action = foundTeam.Action;
                        team.AccessType = foundTeam.AccessType;
                    }

                    prepareD365SAccessItem(team);
                });
            }
        }

        // PeopleSoft
        if (request.PsRequest != null) {
            if (request.PsRequest.User != null) {
                vm.User.PSUser.ConfirmPassword = request.PsRequest.User.Password;
                vm.User.PSUser.ApplicationType = request.PsRequest.User.ApplicationType;
                vm.User.PSUser.Action = request.PsRequest.User.Action;
                vm.User.PSUser.ProductId = request.PsRequest.User.ProductId;
                vm.User.PSUser.UserChanges = request.PsRequest.User.UserChanges;
            }
            vm.User.PSUser.ApplicationId = ps_application_id;

            if (vm.ListOfSelectedPSRoles != null) {
                vm.ListOfSelectedPSRoles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfSelectedPSRoles.forEach(function (role) {

                    var foundRole = request.PsRequest.Roles.find(function (psRole) {
                        return psRole.Name.toUpperCase() === role.Name.toUpperCase();
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }

                    preparePSAccessItem(role);
                });
            }

            if (vm.ListOfAvailablePSRoles != null) {
                vm.ListOfAvailablePSRoles.forEach(function (role) {
                    role.Action = null;
                });
                vm.ListOfAvailablePSRoles.forEach(function (role) {

                    var foundRole = request.PsRequest.Roles.find(function (psRole) {
                        return psRole.Name.toUpperCase() == role.Name.toUpperCase();
                    });
                    if (foundRole != null) {
                        role.Action = foundRole.Action;
                        role.AccessType = foundRole.AccessType;
                    }

                    preparePSAccessItem(role);
                });
            }
        }

        // Salesforce
        if (request.SfRequest != null) {
            if (request.SfRequest.User != null) {
                vm.User.SalesforceUser.ApplicationType = request.SfRequest.User.ApplicationType;
                vm.User.SalesforceUser.Action = request.SfRequest.User.Action;
                vm.User.SalesforceUser.ProductId = request.SfRequest.User.ProductId;
                vm.User.SalesforceUser.UserChanges = request.SfRequest.User.UserChanges;
            }

            vm.User.SalesforceUser.ApplicationId = salesforce_application_id;

            if (vm.ListOfSelectedSalesforcePermissionSets != null) {
                vm.ListOfSelectedSalesforcePermissionSets.forEach(permission => {
                    permission.Action = null;
                });
                vm.ListOfSelectedSalesforcePermissionSets.forEach(permission => {

                    var foundPermission = request.SfRequest.PermissionSets.find(salesforcePermission => salesforcePermission.Name.toUpperCase() === permission.Name.toUpperCase());
                    if (foundPermission != null) {
                        permission.Action = foundPermission.Action;
                        permission.AccessType = foundPermission.AccessType;
                    }

                    prepareSalesforceAccessItem(permission);
                });
            }

            if (vm.ListOfAvailableSalesforcePermissionSets != null) {
                vm.ListOfAvailableSalesforcePermissionSets.forEach(permission => {
                    permission.Action = null;
                });
                vm.ListOfAvailableSalesforcePermissionSets.forEach(permission => {
                    let foundPermission = request.SfRequest.PermissionSets.find(salesforcePermission => salesforcePermission.Name.toUpperCase() == permission.Name.toUpperCase());
                    if (foundPermission != null) {
                        permission.Action = foundPermission.Action;
                        permission.AccessType = foundPermission.AccessType;
                    }

                    prepareSalesforceAccessItem(permission);
                });
            }

            if (vm.User.SalesforceUser.Profile) {
                let profile = vm.User.SalesforceUser.Profile,
                    foundProfile = request.SfRequest.Profiles.find(requestProfile => requestProfile.Id === profile.Id);

                profile.Action = null;

                if (foundProfile) {
                    profile.Action = foundProfile.Action;
                    profile.AccessType = foundProfile.AccessType;
                }

                prepareSalesforceAccessItem(profile);
            }

            if (vm.salesforceProfiles) {
                let profile = vm.User.SalesforceUser.Profile,
                    foundProfile = request.SfRequest.Profiles.find(requestProfile => requestProfile.Id == profile.Id);

                profile.Action = null;

                if (foundProfile) {
                    profile.Action = foundProfile.Action;
                    profile.StartDate = foundProfile.StartDate;
                    profile.EndDate = foundProfile.EndDate;
                    profile.AccessType = foundProfile.AccessType;
                }

                prepareSalesforceAccessItem(profile);
            }
        }
        // END

        if (toStep == "Access Effective Dates") {
            //Find the access changes after the build request returns
            scope.loadEffectiveDateData(request);
            vm.hasCompany = vm.AccessChanges.find(function (access) { return access.Company != null; }) != null;
            if (vm.AccessChanges.some(access => access.Action === 'Grant') && vm.AccessChanges.some(access => access.Action === 'Revoke')) {
                vm.allowApplyAllDates = false;
            } else {
                vm.allowApplyAllDates = true;
                if (vm.AccessChanges.length > 0 && vm.AccessChanges.every(access => access.Action === 'Revoke')) {
                    vm.allDatesRevoke = true;
                } else {
                    vm.allDatesRevoke = false;
                }
            }
        }

        if (toStep == "Summary") {
            //Find access and user changes for summary page
            scope.loadEffectiveDateData(request);
            vm.AccessChanges = this.findAccessChanges(request, vm.AccessChanges);
            vm.hasCompany = vm.AccessChanges.find(function (access) { return access.Company != null; }) != null;
            vm.UserChanges = this.findUserChanges(vm);
        }

        loadRequestDfrd.resolve();

        return $.when(loadRequestDfrd).done(function () {
        }).promise();

    };

    this.addEffectiveDates = function (accessItem, accessChanges) {
        var foundAccessItem = null;
        var foundAccessItems = [];
        var companyId = null;

        if (accessItem.Company != null) {
            if (accessItem.Company.Id != null) {
                companyId = accessItem.Company.Id;
            } else if (accessItem.Company.CompanyId != null) {
                companyId = accessItem.Company.CompanyId;
            } else {
                companyId = accessItem.Company;
            }
        }

        //ternary operator for GP since GP can grant companies as a type
        if (accessItem.Company != null && (accessItem.ProductId == 'GP' && accessItem.AccessType == 'Company' ? false : true)) {
            //Find all access items that have the same Id and AppId
            foundAccessItems = accessChanges.filter(access => (access.Id != null && accessItem.Id != null && access.Id == accessItem.Id || access.Role != null && accessItem.Role != null && access.Role.Id != null && access.Role.Id == accessItem.Role.Id) && access.AppId == accessItem.AppId);
            for (let item of foundAccessItems) {
                //If there is more than one filter by company
                if (item.Company != null) {
                    if (item.Company.Id != null && item.Company.Id == companyId) {
                        foundAccessItem = item;
                    } else if (item.Company.CompanyId != null && item.Company.CompanyId == companyId) {
                        foundAccessItem = item;
                    } else if (item.Company == companyId) {
                        foundAccessItem = item;
                    }

                    if (foundAccessItem != null) {
                        break;
                    }
                }
            }
        } else if (accessItem.Company != null && (accessItem.ProductId == 'GP' && accessItem.AccessType == 'Company')) {
            foundAccessItem = accessChanges.find(function (item) {
                return item.Company.Id == companyId && item.AccessType == 'Company';
            });
        } else if (accessItem.RoleID) { // If RoleID exists, Oracle Cloud
            foundAccessItem = accessChanges.find(access => {
                return access.RoleID == accessItem.RoleID && access.AppId == accessItem.AppId;
            });
        }
        else {
            foundAccessItem = accessChanges.find(access => {
                return (access.Id != null && accessItem.Id != null && access.Id == accessItem.Id || access.Role != null && accessItem.Role != null && access.Role.Id != null && access.Role.Id == accessItem.Role.Id || access.SkuId != null && accessItem.SkuId != null & access.SkuId == accessItem.SkuId || access.Team != null && accessItem.Team != null && access.Team.Id != null && access.Team.Id == accessItem.Team.Id || access.Name != null && accessItem.Name != null && access.Name == accessItem.Name) && access.AppId == accessItem.AppId && access.Company == null;
            });
        }

        //Populate Start/End dates
        if (foundAccessItem != null) {
            accessItem.StartDate = foundAccessItem.StartDate;
            accessItem.EndDate = foundAccessItem.EndDate;
        }
    };

    this.getSelfServiceMapping = getSelfServiceMapping;

    async function getSelfServiceMapping() {
        try {
            let response = await $http.get(apiUrl + 'api/identitymanager/users/selfservice');
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    //Removes null instances and empty arrays from 'data'
    function terminationSummaryFilter(data) {

        Object.keys(data).forEach(key => {
            if (data[key] === null) {
                delete data[key];
            }
        });

        if (Array.isArray(data)) {
            const temp = data.reduce((r, v) => {
                v = terminationSummaryFilter(v);
                if (v !== '') r.push(v);
                return r;
            }, []);
            return temp.length
                ? temp
                : '';
        }
        if (data && typeof data === 'object') {
            const temp = Object.entries(data).reduce((r, [k, v]) => {
                v = terminationSummaryFilter(v);
                if (v !== '') r.push([k, v]);
                return r;
            }, []);
            return temp.length
                ? Object.fromEntries(temp)
                : '';
        }
        return data;
    }

    //Creates a new object with a subset of data properties based on the ProductId/ProductType of the data instance
    function createUserDetailsInstances(data) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].ProductId) {
                switch (data[i].ProductId) {
                    case 'AX7': //Dynamics 365FO
                        data[i] = {
                            UserID: data[i].UserId,
                            UserName: data[i].UserName,
                            Email: data[i].Email,
                            Company: data[i].Company,
                            ProductId: data[i].ProductId
                        };
                        break;
                    case 'NS': //Netsuite
                        data[i] = {
                            FirstName: data[i].FirstName,
                            LastName: data[i].LastName,
                            Email: data[i].Email,
                            Subsidiary: data[i].Subsidiary.Name,
                            ProductId: data[i].ProductId
                        };
                        break;
                    case 'ORFC': //Oracle Cloud
                        data[i] = {
                            UserName: data[i].UserName,
                            FirstName: data[i].FirstName,
                            LastName: data[i].LastName,
                            Email: data[i].Email,
                            ProductId: data[i].ProductId
                        };
                        break;
                    case 'OR': //Oracle EBS
                        data[i] = {
                            UserName: data[i].Name,
                            Description: data[i].Description,
                            Email: data[i].Email,
                            ProductId: data[i].ProductId
                        };
                        break;
                    case 'SF': //Salesforce
                        data[i] = {
                            FirstName: data[i].FirstName,
                            LastName: data[i].LastName,
                            Alias: data[i].Alias,
                            Email: data[i].Email,
                            UserName: data[i].Username,
                            NickName: data[i].Nickname,
                            ProductId: data[i].ProductId
                        };
                        break;
                    case 'AAD': //Azure Active Directory now Entra ID
                        data[i] = {
                            DisplayName: data[i].DisplayName,
                            UserName: data[i].UserPrincipalName,
                            FirstName: data[i].GivenName,
                            LastName: data[i].SurName,
                            ProductId: data[i].ProductId
                        };
                        break;
                    case 'AX': //Dynamics AX 2012
                        data[i] = {
                            UserID: data[i].UserId,
                            UserName: data[i].UserName,
                            Company: data[i].Company,
                            Email: data[i].Email,
                            ProductId: data[i].ProductId
                        };
                        break;
                    case 'D365S': //Dynamics 365 CE
                        data[i] = {
                            FirstName: data[i].FirstName,
                            LastName: data[i].LastName,
                            Email: data[i].InternalEmailAddress,
                            ProductId: data[i].ProductId
                        };
                        break;
                    case 'GP': //Dynamics GP
                        data[i] = {
                            UserID: data[i].Id,
                            UserName: data[i].UserName,
                            ProductId: data[i].ProductId
                        };
                        break;
                    case 'NAV': //Dynamics NAV
                        data[i] = {
                            UserName: data[i].UserName,
                            FullName: data[i].FullName,
                            ProductId: data[i].ProductId
                        };
                        break;
                    case 'PS': //Peoplesoft
                        data[i] = {
                            UserID: data[i].UserId,
                            Description: data[i].Description,
                            PrimaryEmail: data[i].PrimaryEmail,
                            ProductId: data[i].ProductId
                        };
                        break;
                    default:
                        data[i] = {
                            Name: data[i].Name,
                            Email: data[i].Email,
                            ProductId: data[i].ProductId
                        }
                        break;
                }
            } else {
                data[i] = { //SAP User case
                    FirstName: data[i][0].FirstName,
                    LastName: data[i][0].LastName,
                    Email: data[i][0].Email,
                    User: data[i][0].UserName,
                    ProductId: data[i][0].ProductId
                }
            }
        }
    }

    //Returns the type of value
    function typeOf(value) {
        return typeof value;    
    }

    //Functions to add AppId and ProductId onto each access item. Used for display purposes on UI side
    function prepareAADAccessItem(access) {
        access.AppId = aad_application_id;
        access.ProductId = 'AAD';

        return access;
    }
    function prepareAX7AccessItem(access) {
        access.AppId = ax7_application_id;
        access.ProductId = 'AX7';

        return access;
    }
    function prepareAX2012AccessItem(access) {
        access.AppId = ax_application_id;
        access.ProductId = 'AX';

        return access;
    }
    function prepareORAccessItem(access) {
        access.AppId = oracle_application_id;
        access.ProductId = 'OR';

        return access;
    }
    function prepareORFCAccessItem(access) {
        access.AppId = oracleCloud_application_id;
        access.ProductId = 'ORFC';

        return access;
    }
    function prepareNSAccessItem(access) {
        access.AppId = netsuite_application_id;
        access.ProductId = 'NS';

        return access;
    }
    function prepareUniversalAccessItem(access, productName) {
        access.AppId = universalProduct_application_id;
        access.ProductId = productName;
        return access;
    }
    function prepareSAPAccessItem(access) {
        access.AppId = sap_application_id;

        return access;
    }
    function prepareNAVAccessItem(access) {
        access.AppId = nav_application_id;
        access.ProductId = 'NAV';

        return access;
    }
    function prepareGPAccessItem(access) {
        access.AppId = gp_application_id;
        access.ProductId = 'GP';

        return access;
    }
    function prepareD365SAccessItem(access) {
        access.AppId = d365s_application_id;
        access.ProductId = 'D365S';

        return access;
    }
    function preparePSAccessItem(access) {
        access.AppId = ps_application_id;
        access.ProductId = 'PS';

        return access;
    }
    function prepareSalesforceAccessItem(access) {
        access.AppId = salesforce_application_id;
        access.ProductId = 'SF';

        return access;
    }
    this.searchByKey = function (obj, key) {
        for (var i in obj) {
            if (obj[i].Key == key) {
                return obj[i].Value;
            }
        }
        return 0;
    };
}

const appMap = [{
    key: "AadUser",
    id: aad_application_id
}, {
    key: "Ax7User",
    id: ax7_application_id
}, {
    key: "AxUser",
    id: ax_application_id
}, {
    key: "D365SUser",
    id: d365s_application_id
}, {
    key: "EbsUser",
    id: oracle_application_id
}, {
    key: "GPUser",
    id: gp_application_id
}, {
    key: "NavUser",
    id: nav_application_id
}, {
    key: "NetSuiteUser",
    id: netsuite_application_id
}, {
    key: "OrfcUser",
    id: oracleCloud_application_id
}, {
    key: "PsUserRequest",
    id: ps_application_id
}, {
    key: "SfUserRequest",
    id: salesforce_application_id
}, {
    key: "SapUser",
    id: sap_application_id
}, {
    key: "UvUser",
    id: universalProduct_application_id
}];

function setVisibleAndSelectedApps(data, app) {
    let map = appMap.find(item => item.id === app.Id),
        appData = data[map.key],
        isArray = Array.isArray(appData),
        errors = data.Errors,
        subApp;

    if (appData && !isArray) {
        app.selected = true;
        app.Visible = true;
    } else if (!isArray) {
        app.Visible = false;
    } else if (isArray && appData.length) {
        subApp = appData.find(application => application.ProductId === app.ProductID);

        if (subApp) {
            app.Visible = true;
            app.selected = true;
        } else {
            app.Visible = false;
        }
    } else {
        app.Visible = false;
    }

    // If app has errors, make sure it is visible
    if (!app.Visible && !isArray) {
        app.Visible = errors.some(error => error.ApplicationID === app.Id);
    } else if (!app.Visible && subApp) {
        app.Visible = errors.some(error => error.ApplicationID === subApp.Id);
    }

    return app;
}
