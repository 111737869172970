import compositeRolesListController from "./compositeRolesList.controller";

/* compositeRolesList.component.js */

/**
 * @desc component for viewing and maintaining list of sap security designer simulation composite roles
 * @example <sap-sd-simulation-composite-roles-list></sap-sd-simulation-composite-roles-list>
 */

const sapSdSimulationCompositeRolesList = {
    bindings: {
        simulation: '<',
        selectedRoleType: '<',
        onUpdateSimulationsList: '&',
        onSelectedCompositeRoleChange: '&',
        onRoleTypeChange: '&'
    },
    controller: compositeRolesListController,
    controllerAs: 'crlvm',
    templateUrl: '/App/Components/SAPSecurityDesigner/simulationCompositeRolesList/compositeRolesList.tmpl.html'
};

export default sapSdSimulationCompositeRolesList;