import angular from 'angular';
import authenticationService from './Services/authentication.service';
import SavedReportService from './Services/savedReportSelector';
import whiteListService from './Services/whiteListService';
import ViewProductImportPageService from './Services/viewProductImportPageService';
import KPIOverrideDefinitions from '../Components/Home/KPIOverrideDefinitions';
import KPIGeneratorService from '../Components/Home/KPIGeneratorService';
import CustomReportServices from './Services/reportService';
import ExportReportService from './Services/exportReportService';
import helperService from './Services/helper.service';
import ReportsService from '../Components/Reports/reports.service';

export default angular
    .module('app.shared.services', [])
    .service('authenticationService', authenticationService)
    .service('helperService', helperService)
    .service('whiteListService', whiteListService)
    .service('ViewProductImportPageService', ViewProductImportPageService)
    .service('KPIOverrideDefinitions', KPIOverrideDefinitions)
    .service('KPIGeneratorService', KPIGeneratorService)
    .service('CustomReportServices', CustomReportServices)
    .service('ExportReportService', ExportReportService)
    .service('SavedReportSelectorService', SavedReportService)
    .service('reportsService', ReportsService)
    .name;