export default /*@ngInject*/ function ($scope, $rootScope, $interval, authenticationService) {

    var vm = $scope.vm = {};
    vm.currentYear = new Date();

    $scope.logOut = function () {
        authenticationService.logOut();
    };

    $rootScope.applicationTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    function startClock() {
        var tick = function () {
            $rootScope.applicationClock = Date.now();
        };
        tick();
        $interval(tick, 1000);
    }

    startClock();

}