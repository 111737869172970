import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (helperService, connectionsDataService, manageDataDataService) {
    const vm = this;

    vm.$onInit = init;

    vm.updateCustomProduct = updateCustomProduct;

    function init() {
        vm.product.updatePassword = false;

        if (vm.product.HasAuditTrailModule) { 
            getProductConfiguration();
        }
    }

    function getProductConfiguration() {
        const fpAuditConfig = vm.allFpAuditConfigs.find(config => config.ProductId === vm.product.ID);

        vm.currentProductConfig = { ...fpAuditConfig };
        vm.originalProductConfig = { ...fpAuditConfig };
        vm.configName = fpAuditConfig.Name;
    }

    async function updateCustomProduct() {
        const product = vm.product;
        let sqlData = {
            ProductId: product.ID,
            DatabaseName: product.CustomProduct_DatabaseName,
            HostName: product.CustomProduct_HostName,
            Instance: product.CustomProduct_Instance,
            Port: product.CustomProduct_Port,
            Password: product.CustomProduct_Password,
            UserName: product.CustomProduct_User
        },
            productConfigData = {
                Configurations: [],
                ProductId: vm.product.ID
            };

        if (vm.product.HasAuditTrailModule) {
            if (vm.currentProductConfig.Value != vm.originalProductConfig.Value) {
                    productConfigData.Configurations.push({
                        "Name": vm.configName,
                        "Value": vm.currentProductConfig.Value
                    });
                }
        }

        vm.updatePending = true;
        vm.updatePending = new createSpinner();

        if (product.ConnectionExists === true) {
            if (!product.updatePassword) {
                sqlData["Password"] = null;
            }

            try {
                if (vm.product.HasAuditTrailModule) {
                    await manageDataDataService.setProductConfiguration(vm.product.ID, productConfigData);

                    productConfigData.Configurations.forEach(config => {
                        vm.originalProductConfig[config.Name] = config.Value;
                    });
                }

                await connectionsDataService.updateSqlConfig(product.ID, sqlData);
                helperService.successfulSaveButton(vm.updatePending);
            } catch (response) {
                vm.updatePending.loadingValue = false;
            }
        } else {
            try {
                await connectionsDataService.createSqlConfig(product.ID, sqlData);
                helperService.successfulSaveButton(vm.updatePending);
                product.ConnectionExists = true;
            } catch (response) {
                vm.updatePending.loadingValue = false;
            }
        }
    }
}
     
