import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (helperService, manageDataDataService) {
    const vm = this;
    vm.helperService = helperService;
    vm.provisioningChanged = provisioningChanged;
    vm.updateProductProvisioning = updateProductProvisioning

    vm.valueChanged = false;
    vm.$onInit = activate;

    function activate() {
        getProductProvisioning();
    }

    async function getProductProvisioning() {
        const response = await manageDataDataService.getConfigurationByProductId(vm.product.ProductID);
        let provisioning = {
            'IM_EnableProvisioning': response.data.find(prov => prov.Name === 'IM_EnableProvisioning').Value,
            'IM_ProvisioningAdmins': response.data.find(prov => prov.Name === 'IM_ProvisioningAdmins').Value,
        }
        vm.originalProvisioning = JSON.stringify(provisioning);
        vm.provisioning = provisioning;
    }

    async function updateProductProvisioning() {
        vm.updateProvisioningPending = new createSpinner();
        let data = {
            "Configurations": [
                {
                    "Name": "IM_EnableProvisioning",
                    "Value": vm.provisioning.IM_EnableProvisioning
                },
                {
                    "Name": "IM_ProvisioningAdmins",
                    "Value": vm.provisioning.IM_ProvisioningAdmins
                }
            ],
            "ProductId": vm.product.ProductID
        };

        try {
            await manageDataDataService.setProductConfiguration(vm.product.ProductID, data);
            helperService.successfulSaveButton(vm.updateProvisioningPending);
            vm.originalProvisioning = JSON.stringify(vm.provisioning);
            vm.valueChanged = false;
        } catch {
            vm.updateProvisioningPending.loadingValue = false;
        }
    }

    function provisioningChanged() {
        if (vm.originalProvisioning === JSON.stringify(vm.provisioning)) {
            vm.valueChanged = false;
        } else {
            vm.valueChanged = true;
        }
    }
}