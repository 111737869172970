import { customAutoCompleteFilter } from "../GlobalVariables/filterVariables";
import swal from "sweetalert2";

export default /*@ngInject*/ function ($http, $rootScope, $q, $timeout, $filter, helperService, $location, applicationInsightsService) {

    this.clearGridFilters = function (a) {
        a.filter({});
    };

    this.setfilterManagerGridOptions = function () {
        var filterManagerGridOptions = {

            height: 400,
            scrollable: { virtual: true },
            columns: [
                { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
                { field: "Description", filterable: customAutoCompleteFilter },
                { field: "ReportName", title: "Report Name", filterable: customAutoCompleteFilter },
                { template: `<a ng-click\="deleteFilter(#=Id#)\"><i class=\"glyphicon glyphicon-trash text-danger m-r-xs\"></i>Delete</a>`, "title": "Delete", width: 90 }
            ],
            sortable: true,
            resizable: true,
            //columnMenu: true,
            reorderable: true,
            selectable: true,
            allowCopy: true,
            pageable: { numeric: false, previousNext: false, messages: { display: "Total: {2}" } }
        };

        return filterManagerGridOptions;

    };

    this.setfiltersDataSource = function (objectId) {

        var dataSource = new kendo.data.DataSource({
            transport: {
                read: function (options) {
                    $http.get(apiUrl + 'api/core/userreportfilters/reports/' + objectId)
                        .then(function (response) {
                            response.data = $filter('orderBy')(response.data, 'Name');
                            options.success(response.data);
                        });
                }
            }
        });

        return dataSource;
    };

    this.getAccessDetailsForRowUserRisks = function (xscope, grid) {
        var selectedData = grid.dataItem(grid.select());

        if (selectedData) {

            return $http({
                method: 'GET',
                url: apiUrl + 'api/arm/risks/' + selectedData.RiskId + '/products'
            }).then(response => {
                return response;
            }).catch(response => {
                helperService.showErrorMessage(response.data);
            });
        }
    }



    this.getAccessDetailsForEachProduct = function (products, grid) {
        var selectedData = grid.dataItem(grid.select());

        var promises = [];
        var accessDetailsForEachProduct = [];
        var promise = $q.when();

        products.forEach(function (row, i) {


            var partialUrlString = '/places/accessdetails/risk/' + selectedData.RiskId + '/company/' + selectedData.CompanyMasterID + '/user/' + selectedData.UserMasterID;

            var urlString;
            var productType = row.Type

            if (productType === 'NS') {
                urlString = apiUrl + 'api/arm/ns' + partialUrlString;
            } else if (productType === 'INT') {
                urlString = apiUrl + 'api/arm/int' + partialUrlString;
            } else if (productType === 'OR') {
                urlString = apiUrl + 'api/arm/or' + partialUrlString;
            } else if (productType === 'SF') {
                urlString = apiUrl + 'api/arm/sf' + partialUrlString;
            } else if (productType === 'AC') {
                urlString = apiUrl + 'api/arm/ac' + partialUrlString;
            } else {
                urlString = apiUrl + 'api/arm/' + productType + partialUrlString;
            }

            if (urlString) {
                promise = promise.then(function () {
                    return $http({
                        method: 'GET',
                        url: urlString
                    }).then(response => {
                        accessDetailsForEachProduct.push({ 'Product': row, 'DataSource': response.data });
                        return accessDetailsForEachProduct;
                    }).catch(response => {
                        helperService.showErrorMessage(response.data);
                    });

                });
            };

        });
        return promise;
    };



    this.getAccessDetailsForRow = function (xscope, grid) {
        var selectedData = grid.dataItem(grid.select());

        if (selectedData) {
            var userId = selectedData.UserID;
            var companyId = selectedData.CompanyID;
            var accessTypeId = selectedData.AccessTypeID;
            var securityObjectId = selectedData.SecurityObjectID;
            var securityObjectTypeId = selectedData.SecurityObjectTypeID;
            var productId = selectedData.ProductID;

            var urlString;
            var accessDetailsParams = { userId: userId, companyId: companyId, securityObjectId: securityObjectId, securityObjectTypeId: securityObjectTypeId, productId: productId, accessTypeId: accessTypeId };

            urlString = apiUrl + 'api/arm/' + productId + '/places/accessdetails';

            if (urlString) {

                return $http({
                    method: 'GET',
                    url: urlString,
                    params: accessDetailsParams
                }).then(response => {
                    return response;
                }).catch(response => {
                    helperService.showErrorMessage(response.data);
                });
            }
        }
    };

    this.getATChangeCountForRow = function (xscope, grid) {
        var selectedData = grid.dataItem(grid.select());

        if (selectedData) {
            var userId = selectedData.UserID;
            var companyId = selectedData.CompanyID;
            var accessTypeId = selectedData.AccessTypeID;
            var securityObjectId = selectedData.SecurityObjectID;
            var securityObjectTypeId = selectedData.SecurityObjectTypeID;
            var productId = selectedData.ProductID;

            var urlString;
            var atDetailsParams = { userId: userId, securityObjectId: securityObjectId, securityObjectTypeId: securityObjectTypeId, accessTypeId: accessTypeId, companyId: companyId };

            if (productId === 'NS') {
                urlString = apiUrl + 'api/audittrail/ns/places/accessdetailscount';
            }


            if (urlString) {

                $http({
                    method: 'GET',
                    url: urlString,
                    params: atDetailsParams
                }).then(function (response) {
                    xscope.changeCount = response.data;

                }, function errorCallback(response) {
                    helperService.showErrorMessage(response.data);
                });
            } else {
                xscope.changeCount = 0;
            }
        }
    };

    this.deleteFilterFilterManager = function (a, id) {
        var rows = a.filtersDataSource.data();

        rows.forEach(function (row) {
            if (row.Id.toString() === id.toString()) {

                $http({
                    method: 'DELETE',
                    url: apiUrl + 'api/core/userreportfilters/' + row.Id
                }).then(function successCallback(response) {
                    rows.remove(row);

                }, function errorCallback(response) {
                    helperService.showErrorMessage(response.data);
                });
            }
        });
    };

    this.saveGridLayout = function (xscope, productId) {

        var gridOptions = xscope.grid.getOptions();

        var Columns = JSON.stringify(gridOptions.columns);
        var Groupings = JSON.stringify(gridOptions.dataSource.group);
        var Sortings = JSON.stringify(gridOptions.dataSource.sort);

        var dataItemForPost = { ObjectId: xscope.ObjectId, Columns: Columns, Sortings: Sortings, ProductId: productId };

        function postLayoutToDB(dataItemForPost) {
            //Get the report name before writing the custom event to insights
            $http({
                method: 'GET',
                url: apiUrl + 'api/core/objects/' + xscope.ObjectId
            }).then(function (response) {
                applicationInsightsService.trackEvent('SaveLayout', { ProductId: productId, Name: response.data.Name, ObjectId: xscope.ObjectId });
            });

            $http.post(apiUrl + 'api/core/reportlayouts/', dataItemForPost).
                then(function (response) {
                    helperService.showConfirmationMessage("Success", "Report Layout Saved");
                }, function (response) {
                    helperService.showErrorMessage(response.data);
                });
        }

        $http({
            method: 'GET',
            url: apiUrl + 'api/core/reportlayouts/object/' + xscope.ObjectId,
            params: { productId: productId }
        }).then(function (response) {
            //it exists - override by deleting and creating new
            var existingLayoutId = response.data.ID;

            function overWriteSavedLayout() {
                var data = {
                    "ID": existingLayoutId,
                    "Columns": Columns,
                    "Groupings": Groupings,
                    "Sortings": Sortings
                };

                //Get the report name before writing the custom event to insights
                $http({
                    method: 'GET',
                    url: apiUrl + 'api/core/objects/' + xscope.ObjectId
                }).then(function (response) {
                    applicationInsightsService.trackEvent('SaveLayout', { ProductId: productId, Name: response.data.Name, ObjectId: xscope.ObjectId });
                });

                $http.put(apiUrl + 'api/core/reportlayouts/' + existingLayoutId, data).then(function (response) {
                    helperService.showConfirmationMessage("Success", "Report Layout Saved");
                }, function (response) {
                    helperService.showErrorMessage(response.data);
                });
            }

            swal(helperService.areYouSureParams('Overwrite Layout', 'Are you sure you want to overwrite this Layout?', 'Overwrite')).then(function () {
                overWriteSavedLayout();
            }, function (dismiss) {
                return;
            });

        }, function (response) {
            //it doesnt exist - create
            postLayoutToDB(dataItemForPost);
        });
    };

    this.loadSavedDynamicGridLayout = function (xscope, productId) {

        var deferred = $q.defer();

        $http({
            method: 'GET',
            url: apiUrl + 'api/core/reportlayouts/object/' + xscope.ObjectId,
            params: { productId: productId }
        }).then(function (response) {
            deferred.resolve(JSON.parse(response.data.Columns));
        }, function (response) {
            deferred.reject();
        });

        return deferred.promise;
    };

    this.loadSavedGridLayout = function (xscope, productId) {
        $timeout(function () {

            if (xscope.grid) {
                $http({
                    method: 'GET',
                    url: apiUrl + 'api/core/reportlayouts/object/' + xscope.ObjectId,
                    params: { productId: productId }
                }).then(function (response) {

                    //xscope.mainGridColumns = JSON.parse(response.data.Columns);

                    var currentOptions = xscope.grid.getOptions();

                    var loadedColumns = JSON.parse(response.data.Columns);

                    xscope.grid.setOptions({ "columns": loadedColumns });


                }, function (response) {
                    return;
                });
            }

        }, 500);

    };

    this.loadSavedReportLayout = function (xscope, inLayout) {

        $timeout(function () {
            var layout = JSON.parse(inLayout);
            var loadedColumns = JSON.parse(layout.Columns);
            var loadedGroupings = JSON.parse(layout.Groupings);
            var loadedSortings = JSON.parse(layout.Sortings);

            if (xscope.grid) {
                var currentOptions = xscope.grid.getOptions();

                if (loadedColumns) {
                    currentOptions.columns = loadedColumns;
                }
                if (loadedGroupings) {
                    currentOptions.dataSource.group = loadedGroupings;
                }
                if (loadedSortings) {
                    currentOptions.dataSource.sort = loadedSortings;
                }
                xscope.grid.setOptions(currentOptions);
            }
        }, 500);
    };

    this.Criteria = function () {
        this.ParameterName = '',
            this.Value = '',
            this.DisplayName = '',
            this.DisplayValue = '';
    };

    this.CreateCriteria = function (ParameterName, DisplayName, DisplayValue, Value) {
        var criteria = {};

        //field Name
        criteria.ParameterName = ParameterName,

            //Must Match criteria type enum:
            //SecurityObject = 1, SecurityObjectType = 2, Product = 3, User = 4, Company = 5, Risk = 6, Role = 7, Responsibility = 8, StartDate = 9, EndDate = 10, GroupId = 11, GroupType = 12, RecordType = 13, Record = 14, Status = 15, IntraResponsibilityOnly = 16, Date = 17, TemplateId = 18
            criteria.DisplayName = DisplayName,

            criteria.DisplayValue = DisplayValue,

            criteria.Value = Value;

        return criteria;
    };

    var Layout = {
        Columns: '',
        Groupings: '',
        Sortings: ''
    };

    this.loadSavedReport = function (savedReport) {
        //Get detailed information about report
        var objectId = helperService.getSecurityObjectDetailsFromReportId(savedReport.ReportDefinition.ReportId).ObjectID;

        $http({
            method: 'GET',
            url: apiUrl + 'api/core/objects/' + objectId
        }).then(function (response) {
            
            var reportName = helperService.removeSpaces(response.data.Name);
            var moduleNameNoSpaces = helperService.removeSpaces(response.data.ParentObject.Name);
            $rootScope.loadedSavedReport = angular.copy(savedReport);

            if (response.data.TypeID === 2) {
                helperService.changeView(moduleNameNoSpaces, reportName, 'Place');
            } else {
                $location.path(moduleNameNoSpaces + '/Report/' + reportName + '/' + response.data.ObjectID);
            }

        }, function errorCallback(response) {
            helperService.showErrorMessage(response.data);
        });
    };

    this.getRelativeDateTypes = function () {

        return [
            {
                Name: 'Date Range',
                Id: 4
            },
            {
                Name: 'Previous',
                Id: 0
            },
            {
                Name: 'Current',
                Id: 1
            },
            {
                Name: 'Last',
                Id: 2
            }
        ];
    };
    this.getPeriodTypes = function () {

        return [
            {
                Name: 'Day',
                PluralName: 'Day(s)',
                Id: 0
            },
            {
                Name: 'Week',
                PluralName: 'Week(s)',
                Id: 1
            },
            {
                Name: 'Month',
                PluralName: 'Month(s)',
                Id: 2
            },
            {
                Name: 'Quarter',
                PluralName: 'Quarter(s)',
                Id: 3
            },
            {
                Name: 'Year',
                PluralName: 'Year(s)',
                Id: 4
            }
        ];
    };

    this.convertCriteriaValueToNull = function (object, propertyName) {
        if (object[propertyName] === "-1") {
            object[propertyName] = null;
        }
    }

}
