import angular from 'angular';
import identityManagerFactoriesModule from './identityManager.factories.module';
import IdentityManagerRequestDetailsViewModel from '../Components/IdentityManagerRequestDetails/IdentityManagerRequestDetailsViewModel';
import openRequests from '../Components/openRequests/openRequests.component';
import identityManagerRequestManagementModule from './identityManager.requestManagement.module';
import requestHistory from '../Components/requestHistory/requestHistory.component';
import approvalRules from '../Components/ApprovalRules/approvalRules.component';
import requestHistoryDataService from '../Components/requestHistory/requestHistoryDataService.factory';
import outstandingApprovals from '../Components/outstandingApprovals/outstandingApprovals.component'
import outstandingApprovalsGrid from '../Components/outstandingApprovals/outstandingApprovalsGrid.component';


export default angular
    .module('app.identityManager', [identityManagerFactoriesModule, identityManagerRequestManagementModule])
    .factory('requestHistoryDataService', requestHistoryDataService)
    .controller("identityManagerRequestDetailsViewModel", IdentityManagerRequestDetailsViewModel)
    .component('approvalRules', approvalRules)
    .component("openRequests", openRequests)
    .component('requestHistory', requestHistory)
    .component('outstandingApprovals', outstandingApprovals)
    .component('outstandingApprovalsGrid', outstandingApprovalsGrid)
    .name;