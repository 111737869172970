import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, riskLevelsData, helperService, riskLevel) {

    const vm = this;

    if (riskLevel) {
        vm.riskLevel = { ...riskLevel };
    }

    vm.cancel = cancel;
    vm.submitForm = submitForm;

    async function createRiskLevel() {
        vm.loading = new createSpinner();
        let postData = { Name: vm.riskLevel.Name };
        try {
            let response = await riskLevelsData.createRiskLevel(postData);
            let riskLevelId = response.data;
            await helperService.successfulSaveButton(vm.loading);
            $uibModalInstance.close(riskLevelId);
        } catch {
            vm.loading.loadingValue = false;
        }
    }

    async function renameRiskLevel() {
        vm.loading = new createSpinner();

        let putData = {
            Name: vm.riskLevel.Name,
            Id: vm.riskLevel.Id
        };

        try {
            await riskLevelsData.renameRiskLevel(putData);
            await helperService.successfulSaveButton(vm.loading);
            $uibModalInstance.close();
        } catch {
            vm.loading.loadingValue = false;
        }
    }

    function submitForm() {
        if (vm.riskLevel.Id) {
            renameRiskLevel();
        } else {
            createRiskLevel();
        }
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}