import angular from 'angular';
import templatesDataServiceFactory from '../Components/NetSuiteAuditTrail/MaintainTemplates/templatesDataService.factory';
import manageDataFactory from '../Components/ManageDataV2/Factories/manageData.factory';
import manageDataDataServiceFactory from '../Components/ManageDataV2/Factories/manageDataDataService.factory';
import generatedReportsDataServiceFactory from '../Components/ReportGenerator/generatedReportsDataService.factory';
import snapshotsDataServiceFactory from '../Components/AuditTrail/MaintainSnapshots/snapshotsDataService.factory';
import templateDataService from '../Components/TemplateTracking/TemplateTrackingDetails/templateDataService';
import workdayMaintainTemplatesDataServiceFactory from '../Components/workdayMaintainTemplates/workdayMaintainTemplatesDataService.factory';
import salesforceAtDataService from '../Components/salesForceAuditTrail/salesforceAtDataService.factory.js';

export default angular
    .module('app.auditTrail.factories', [])
    .service('AuditTrailEntityDataService', templateDataService)
    .factory('templatesDataService', templatesDataServiceFactory)
    .factory('manageDataFactory', manageDataFactory)
    .factory('manageDataDataService', manageDataDataServiceFactory)
    .factory('generatedReportsDataService', generatedReportsDataServiceFactory)
    .factory('snapshotsDataService', snapshotsDataServiceFactory)
    .factory('workdayMaintainTemplatesDataService', workdayMaintainTemplatesDataServiceFactory)
    .factory('salesforceAtDataService', salesforceAtDataService)
    .name;