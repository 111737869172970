import { customAutoCompleteFilter, standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, templatesDataService, helperService, $filter, template, objectsDataSource) {

    var vm = this;
    vm.cancel = cancel;
    vm.objectsDataSource = objectsDataSource;

    vm.addObjectToTemplate = addObjectToTemplate;

    var availableGridColumns = [
        { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
        { template: "<a ng-click='omvm.addObjectToTemplate(dataItem)'><i class='fa fa-plus pull-right text-success'></i></a>", title: " ", width: 20 }
    ];

    vm.availableObjectsGridOptions = helperService.setMainGridOptions(availableGridColumns, null);

    function activate() {
        configureObjectsGrid();
    }

    activate();

    function cancel() { $uibModalInstance.close(); }

    function configureObjectsGrid() {
        getAllObjects()
            .then(_ => {
                vm.objectsDataSource.forEach(function (object) {
                   vm.availableObjects = vm.availableObjects.filter(function (el) {
                        return el.Id !== object.ObjectId;
                    });
                });
                vm.availableObjectsGridDataSource = new kendo.data.DataSource({
                    pageSize: standardGridPageSize,
                    transport: {
                        read: function (options) {
                            vm.availableObjects = $filter('orderBy')(vm.availableObjects, 'Name');
                            options.success(vm.availableObjects);
                        }
                    }
                });
            });
    }

    function getAllObjects() {
        return templatesDataService.getObjects()
            .then(response => {
                vm.availableObjects = response;
                return vm.availableObjects;
            });
    }

    function addObjectToTemplate(object) {
        var data = {
            TemplateId: template.Id,
            ObjectId: object.Id
        };

        templatesDataService.addObjectToTemplate(template.Id, data)
            .then(_ => {
                vm.availableObjects = vm.availableObjects.filter(obj => obj.Id !== object.Id);
                vm.availableObjectsGridDataSource.read();
            });
    }

}