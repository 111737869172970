import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (helperService, manageDataFactory, manageDataDataService) {

    const vm = this,
        disabledProducts = ["D365Bc"];

    vm.$onInit = activate;

    vm.updateConfigValues = updateConfigValues;
    vm.isProductConfigUnChanged = isProductConfigUnChanged;
    vm.disabledProduct = false;

    function activate() {
        vm.helperService = helperService;
        vm.disabledProduct = disabledProducts.find(disabledProduct => vm.product.Type === disabledProduct);

        getProductConfigurations();
    }

    async function getProductConfigurations() {
        let response = await manageDataDataService.getConfigurationByProductId(vm.product.ID);
        vm.product.configuration = {};

        response.data.forEach(function (productConfig) {
            vm.product.configuration[productConfig.Name] = productConfig.Value;
        });

        vm.product.changedConfigurations = [];
        vm.product.oldConfig = angular.copy(vm.product.configuration);

        if (vm.product.Type === 'NS') {
            let response = await manageDataDataService.getNetSuiteAuditTrailVersion();
            vm.product.version = response.data;
            getProductActions(vm.product);
        } else {
            getProductActions(vm.product);
        }
    }

    function getProductActions(product) {
        vm.productActions = manageDataFactory.getActionsByProductId(product);

        if (vm.product.Type === 'ORFC') vm.productActions = vm.productActions.filter(a => a.name === 'Collect');
        if (vm.product.Type === 'AX7') vm.productActions = vm.productActions.filter(a => a.name !== 'Restore');

        vm.configurationNeeded = vm.productActions.some(action => action.configValue);
    }

    async function updateConfigValues() {
        vm.updateInProgress = new createSpinner();
        let eligibleValues = vm.product.changedConfigurations;
        let data = {
            'Configurations': [],
            'ProductId': vm.product.ID
        };

        eligibleValues.forEach(value => {
            data.Configurations.push({
                'Name': value,
                'Value': vm.product.configuration[value]
            });
        });
        await manageDataDataService.setProductConfiguration(vm.product.ID, data);
        helperService.successfulSaveButton(vm.updateInProgress);
        // update oldconfig so button gets disabled after saving
        vm.product.oldConfig = { ...vm.product.configuration };
    }

    function isProductConfigUnChanged() {
        if (vm.product.oldConfig && vm.product.configuration) {
            return angular.equals(vm.product.oldConfig, vm.product.configuration);
        }
    }
}