export default /*@ngInject*/ function ($http) {

    this.getPasswordExpirationTypes = function () {
        return [
            'Days',
            'Accesses',
            'None'
        ];
    };

    this.getOracleCloudRoles = function () {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/orfc/roles'
        });
    };

}