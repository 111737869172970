import { standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, userRestrictionsDataService, dataService, fastpathUser, $filter) {

    const vm = this;

    let inheritedProducts = [];

    vm.fastpathUser = fastpathUser;
    vm.toggleProduct = toggleProduct;
    vm.handleProductMap = handleProductMap;
    vm.selectedProducts = [];
    vm.cancel = cancel;

    activate();

    function activate() {
        // Add already mapped products to selected products array
        if (fastpathUser.ManualProductMappings.length > 0) vm.selectedProducts = fastpathUser.ManualProductMappings.map(m => m.ID);
        if (fastpathUser.ProductRestrictionType !== 'None') vm.enabled = true;

        // Auto-select user options and set up relevant grids
        if (fastpathUser.ProductRestrictionType === 'UserMasterMapping') {
            vm.option = 0;
            setupInheritedProductsGrid();
            setupManualProductsGrid();
        }
        else if (fastpathUser.SystemUserMapping && fastpathUser.ProductRestrictionType === 'ManualMapping') {
            vm.option = 1;
            setupInheritedProductsGrid();
            setupManualProductsGrid();
        }
        else if (fastpathUser.SystemUserMapping && fastpathUser.ProductRestrictionType === 'None') {
            vm.option = 0;
            setupInheritedProductsGrid();
            setupManualProductsGrid();
        }
        else if (!fastpathUser.SystemUserMapping && fastpathUser.ProductRestrictionType === 'ManualMapping') {
            vm.option = 1;
            setupManualProductsGrid();
        }
        else if (!fastpathUser.SystemUserMapping && fastpathUser.ProductRestrictionType === 'None') {
            setupManualProductsGrid();
        }

    }

    function setupInheritedProductsGrid() {
        const inheritedProductGridColumns = [{ field: "ProductName", title: "Product Name" }];

        vm.inheritedProductGridOptions = helperService.setMainGridOptions(inheritedProductGridColumns, 300);

        vm.inheritedProductGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    try {
                        let response = await userRestrictionsDataService.getUserMappings(vm.fastpathUser.SystemUserMapping.ID)
                        response.data = $filter('orderBy')(response.data, 'ProductName');
                        options.success(response.data);
                        inheritedProducts = response.data.map(p => { return p.ProductID; });
                    } catch {
                        options.error();
                    }
                }
            }
        });
    }

    function setupManualProductsGrid() {
        const manualProductGridColumns = [
            {
                title: "Access",
                template: `<label class='switch'>
                            <input type='checkbox' ng-model='dataItem.productAccess' ng-change='prmvm.toggleProduct(dataItem)'>
                            <i></i>
                        </label>`,
                width: 65, filterable: false
            },
            { field: "Name", title: "Product Name" }
        ];

        vm.manualProductGridOptions = helperService.setMainGridOptions(manualProductGridColumns, 300);

        vm.manualProductGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    try {
                        let res = await dataService.getProducts();

                        if (fastpathUser.ManualProductMappings.length > 0) {
                            fastpathUser.ManualProductMappings.forEach(m => {
                                let match = res.data.find(p => p.ID === m.ID);
                                match.productAccess = true;
                            });
                        }
                        res.data = $filter('orderBy')(res.data, 'Name');
                        options.success(res.data);
                    } catch {
                        options.error();
                    }
                }
            }
        });
    }

    function toggleProduct(dataItem) {

        switch (dataItem.productAccess) {

            case true:
                vm.selectedProducts.push(dataItem.ID);
                break;
            case false:
                vm.selectedProducts.remove(dataItem.ID);
                break;
        }
    }

    function handleProductMap() {

        let map = {};
        map.FpUserId = fastpathUser.UserID;

        if (!vm.enabled) {
            disableProductRestrictions(map.FpUserId);
        }
        else if (vm.enabled && vm.option === 0) {
            map.ProductIds = inheritedProducts;
            map.ProductRestrictionType = 2;
            createProductMap(map);
        }
        else if (vm.enabled && vm.option === 1) {
            map.ProductIds = vm.selectedProducts;
            map.ProductRestrictionType = 1;
            createProductMap(map);
        }
    }

    async function createProductMap(map) {

        vm.save = new createSpinner();

        try {
            await userRestrictionsDataService.createProductMap(map);
            await helperService.successfulSaveButton(vm.save);
            $uibModalInstance.close();
        } catch {
            vm.save = null;
        }
    }

    async function disableProductRestrictions(userId) {

        vm.save = new createSpinner();

        try {
            await userRestrictionsDataService.disableProductRestrictions(userId)
            await helperService.successfulSaveButton(vm.save)
            $uibModalInstance.close();
        } catch {
            vm.save = null;
        }
    }

    function cancel() { $uibModalInstance.dismiss(); }

}