import roleAuthObjectsListController from "./roleAuthObjectsList.controller";

/* roleAuthObjectsList.component.js */

/**
 * @desc component for viewing and maintaining list of sap security designer simulation role authorization objects
 * @example <sap-sd-simulation-role-auth-objects-list></sap-sd-simulation-role-auth-objects-list>
 */

const sapSdSimulationRoleAuthObjectsList = {
    bindings: {
        simulation: '<',
        selectedRole: '<',
        onSelectedAuthObjectChange: '&'
    },
    controller: roleAuthObjectsListController,
    controllerAs: 'raolvm',
    templateUrl: '/App/Components/SAPSecurityDesigner/simRoleAuthObjectsList/roleAuthObjectsList.tmpl.html'
};

export default sapSdSimulationRoleAuthObjectsList;