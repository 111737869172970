import swal from "sweetalert2";
import axSecurityDesignerAffectedObjectsModalController from "../axSecurityDesignerAffectedObjectsModal.controller";
import NewAXSecurityLayerSecurityDesignerController from "../NewAXSecurityLayerSecurityDesignerController";
import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($scope, $uibModal, $filter, objectIdDataService, AXSecurityDesignerService, $http, helperService) {

    const vm = $scope.vm = {};
    vm.disableFilter = true;
    $scope.currentPage = 1;
    $scope.itemsPerPage = 4;

    vm.openTableFieldsModal = openTableFieldsModal;

    activate();

    async function activate() {
        const res = await objectIdDataService.getObjectID();
        const securityObject = helperService.getSecurityObjectDetailsFromObjectId(res);
        $scope.ObjectId = res;
        $scope.productType = securityObject.ProductType;
    }

    $scope.searchChanged = function () {
        var filteredResults = $filter('filterResults')(vm.securityDesignerPrivilegesTree, vm.searchBox, ['Name', 'SystemName']);
        if (!vm.disableFilter) {
            filteredResults = $filter('filter')(filteredResults, { IsModified: true });
        }
        $scope.filteredCount = filteredResults.length;
    };

    vm.deletePrivilege = function (privilege) {
        if (privilege.IsCustom) {
            $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + vm.selectedSimulation.Id + '/securitylayer/privileges').then(function (response) {
                var securityLayer = response.data.filter(e => e.Name.toLowerCase() === privilege.Name.toLowerCase())[0];
                $http.delete(apiUrl + 'api/securitydesigner/ax7/simulation/securitylayer/' + securityLayer.Id).then(function (response) {
                    vm.simulationSelected();
                }, function errorCallback(response) {
                    return;
                });
            }, function errorCallback(response) {
                return;
            });
        } else {
            var message = "Are you sure you want to remove this privilege?";

            swal(helperService.areYouSureParams('Remove Privilege', message, 'Remove Privilege')).then(function () {
                var postBody = {
                    "SimulationId": vm.selectedSimulation.Id,
                    "SystemName": privilege.SystemName,
                    "Type": "Privilege"
                };

                $http.post(apiUrl + 'api/securitydesigner/ax7/simulation/securitylayer/removals', postBody).then(function (response) {
                    privilege.Action = "Remove";
                    privilege.IsModified = true;
                    $scope.determineModifiedArrays();
                }, function errorCallback(response) {
                    helperService.showErrorMessage(response.data);
                });
            }, function (dismiss) {
                return;
            });
        }
    };

    vm.openClonePrivilegeModal = function (privilege) {
        $scope.selectedSecurityObject = privilege;
        $scope.securityObjectType = 'Privilege';

        $uibModal.open({
            templateUrl: '/App/Shared/Partials/CloneSecurityModal.html',
            controller: clonePrivilegeModal,
            backdrop: 'static',
            scope: $scope
        });
    };

    vm.openAffectedObjectsModal = function (selectedPrivilege) {

        selectedPrivilege.securityLayerType = 3;

        $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/axSecurityDesignerAffectedObjectsModal.html',
            controller: axSecurityDesignerAffectedObjectsModalController,
            controllerAs: 'vm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                item: () => selectedPrivilege
            }
        });
    };

    vm.undoChangesToPrivilege = function (privilege) {
        swal(helperService.areYouSureParams('Undo Changes', 'Are you sure you want to undo this change?', 'Undo')).then(function () {

            var putBody = {
                "SimulationId": vm.selectedSimulation.Id,
                "ObjectId": privilege.SystemName,
                "ObjectType": "Privilege"
            };

            $http.put(apiUrl + 'api/securitydesigner/ax7/simulation/' + vm.selectedSimulation.Id + '/revert', putBody).then(function (response) {
                vm.simulationSelected();
            }, function errorCallback(response) {
                helperService.showErrorMessage(response.data);
            });

        }, function (dismiss) {
            return;
        });
    };

    vm.undoRemovePrivilege = function (privilege) {
        swal(helperService.areYouSureParams('Add Privilege Back', 'Are you sure you want to add this privilege back?', 'Add Privilege')).then(function () {

            $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + vm.selectedSimulation.Id + '/securitylayer/removals').then(function (response) {
                var securityLayer = response.data.filter(e => e.SystemName.toLowerCase() === privilege.SystemName.toLowerCase())[0];
                $http.delete(apiUrl + 'api/securitydesigner/ax7/simulation/securitylayer/removals/' + securityLayer.Id).then(function (response) {
                    vm.simulationSelected();
                }, function errorCallback(response) {
                    return;
                });
            }, function errorCallback(response) {
                return;
            });

        }, function (dismiss) {
            return;
        });
    };

    $scope.openAXNewSecurityLayerSecurityDesignerModal = function () {
        $scope.securityLayerType = 'Privilege';
        $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/NewAXSecurityLayerSecurityDesigner.html',
            controller: NewAXSecurityLayerSecurityDesignerController,
            backdrop: 'static',
            resolve: {
                productType: () => $scope.productType
            },
            scope: $scope
        });
    };

    $http.get(apiUrl + 'api/securitydesigner/simulation').then(function (response) {
        response.data.remove(function (n) { return n['Status'] === 'Deployed'; });
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.securityModels = response.data;

        var securityModelInLocalStorage = localStorage.getItem("LastSelectedAX7SecurityModel");
        var selected = vm.securityModels.find(e => e.Id.toString() === securityModelInLocalStorage);

        if (securityModelInLocalStorage && selected) {
            vm.selectedSimulation = selected;
        } else {
            vm.selectedSimulation = response.data[0];
        }
        vm.simulationSelected();
    }, function errorCallback(response) {
        return;
    });

    $scope.determineModifiedArrays = function () {
        vm.securityDesignerPrivilegesTree.forEach(function (row) {

            row.MenuItemsModified = row.MenuItems.filter(e => e.IsModified === true).length;
            row.TablesModified = row.Tables.filter(e => e.IsModified === true).length;
            row.DataEntitiesModified = row.DataEntities.filter(e => e.IsModified === true).length;
            row.ServiceOperationsModified = row.ServiceOperations.filter(e => e.IsModified === true).length;

            row.GrantedMenuItems = row.MenuItems.filter(e => e.Read !== "Unset" || e.Create !== "Unset" || e.Update !== "Unset" || e.Delete !== "Unset").length;
            row.GrantedTables = row.Tables.filter(e => e.Read !== "Unset" || e.Create !== "Unset" || e.Update !== "Unset" || e.Delete !== "Unset").length;
            row.GrantedDataEntities = row.DataEntities.filter(e => e.Read !== "Unset" || e.Create !== "Unset" || e.Update !== "Unset" || e.Delete !== "Unset").length;
            row.GrantedServiceOperations = row.ServiceOperations.filter(e => e.Invoke !== "Unset").length;

            if (row.MenuItemsModified > 0 || row.TablesModified > 0 || row.DataEntitiesModified > 0 || row.ServiceOperationsModified > 0 || row.IsCustom || row.Action === "Remove") {
                row.IsModified = true;
            } else {
                row.IsModified = false;
            }
        });
        vm.numberModified = vm.securityDesignerPrivilegesTree.filter(e => e.IsModified === true).length;
    };

    vm.simulationSelected = function () {
        vm.disableFilter = true;
        vm.searchBox = '';
        if (vm.selectedSimulation) {

            if (vm.selectedSimulation.Status === "Analyzing" || vm.selectedSimulation.Status === "Locked") {
                $scope.pageIsReadOnly = true;
            } else {
                $scope.pageIsReadOnly = false;
            }

            localStorage.setItem("LastSelectedAX7SecurityModel", vm.selectedSimulation.Id);
            vm.privilegesLoading = true;
            $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + vm.selectedSimulation.Id + '/privilegeaccess').then(function (response) {
                vm.privilegesLoading = false;
                AXSecurityDesignerService.populateSystemNameIfCustom(response.data);
                vm.securityDesignerPrivilegesTree = $filter('orderBy')(response.data, 'Name');

                $scope.determineModifiedArrays();

                $scope.filteredCount = response.data.length;

            }, function errorCallback(response) {
                vm.privilegesLoading = false;
            });

            $http.get(apiUrl + 'api/securitydesigner/ax7/menuitems').then(function (response) {
                vm.securityDesignerMenuItems = response.data;
            }, function errorCallback(response) {
                return;
            });

            $http.get(apiUrl + 'api/securitydesigner/ax7/tables').then(function (response) {
                vm.securityDesignerTables = response.data;
            }, function errorCallback(response) {
                return;
            });

            $http.get(apiUrl + 'api/securitydesigner/ax7/dataentities').then(function (response) {
                vm.securityDesignerDataEntities = response.data;
            }, function errorCallback(response) {
                return;
            });

            $http.get(apiUrl + 'api/securitydesigner/ax7/serviceoperations').then(function (response) {
                vm.securityDesignerServiceOperations = response.data;
            }, function errorCallback(response) {
                return;
            });
        }
    };

    vm.openMenuItemsModal = function (item) {
        $scope.objectOpenInModal = item;
        var currentItems = item.MenuItems;
        const menuItems = true;

        var columns = [
            { title: "Name", field: "Name", filterable: customAutoCompleteFilter, width: 160 },
            { title: "Label", field: "Label", filterable: customAutoCompleteFilter, width: 160 },
            { title: "Type", field: "Type", filterable: customAutoCompleteFilter, width: 110 },
            { title: "View License", field: "ViewLicense", filterable: customAutoCompleteFilter, width: 110 },
            { title: "Maintain License", field: "MaintainLicense", filterable: customAutoCompleteFilter, width: 110 },
            { title: "Read", field: "Read", template: `<select ng-disabled='pageIsReadOnly' class='inline-grid-dropdown' ng-change="actionDropdownChange('Read', dataItem)" ng-model='dataItem.Read'><option ng-repeat='action in tableActions'>{{action}}</option></select>`, filterable: customAutoCompleteFilter },
            { title: "Update", field: "Update", template: `<select ng-disabled='pageIsReadOnly' class='inline-grid-dropdown' ng-change="actionDropdownChange('Update', dataItem)" ng-model='dataItem.Update'><option ng-repeat='action in tableActions'>{{action}}</option></select>`, filterable: customAutoCompleteFilter },
            { title: "Create", field: "Create", template: `<select ng-disabled='pageIsReadOnly' class='inline-grid-dropdown' ng-change="actionDropdownChange('Create', dataItem)" ng-model='dataItem.Create'><option ng-repeat='action in tableActions'>{{action}}</option></select>`, filterable: customAutoCompleteFilter },
            { title: "Delete", field: "Delete", template: `<select ng-disabled='pageIsReadOnly' class='inline-grid-dropdown' ng-change="actionDropdownChange('Delete', dataItem)" ng-model='dataItem.Delete'><option ng-repeat='action in tableActions'>{{action}}</option></select>`, filterable: customAutoCompleteFilter },
            { title: "ContainsAssigningValue", field: "ContainsAssigningValue", hidden: true }
        ];

        if (!item.IsCustom) {
            columns.push({ title: " ", field: "IsModified", template: "<a ng-disabled='pageIsReadOnly' ng-click='deleteModificationToObject(dataItem)' ng-if='dataItem.IsModified' class='icons8-undo-2 pull-right'></a>", width: 50, filterable: false });
        }

        vm.manageSecurityLayerGridOptions = helperService.setMainGridOptions(columns, null);
        vm.manageSecurityLayerGridOptions.selectable = false;

        vm.manageSecurityLayerGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            sort: { field: "Name", dir: "asc" },
            transport: {
                read: function (options) {
                    vm.selectedSecurityDesignerMenuItems = vm.securityDesignerMenuItems.slice();
                    AXSecurityDesignerService.setDataSourceDefaultsAndMapProperties(vm.selectedSecurityDesignerMenuItems, currentItems, menuItems);

                    vm.selectedSecurityDesignerMenuItems.forEach(function (row) {
                        if (row.Create !== 'Unset' || row.Delete !== 'Unset' || row.Read !== 'Unset' || row.Update !== 'Unset') {
                            row.ContainsAssigningValue = true;
                        } else {
                            row.ContainsAssigningValue = false;
                        }
                    });

                    vm.selectedSecurityDesignerMenuItems = $filter('orderBy')(vm.selectedSecurityDesignerMenuItems, 'Name');
                    options.success(vm.selectedSecurityDesignerMenuItems);
                }
            }
        });

        $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/Roles/ManageRoleAXSecurityDesigner.html',
            controller: AddAXMenuItemsToPrivilegeSecurityDesigner,
            backdrop: 'static',
            size: 'xl',
            scope: $scope
        });
    };

    vm.openTablesModal = function (item) {
        $scope.objectOpenInModal = item;

        AXSecurityDesignerService.createTablesModalDataSource(item, vm);

        $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/Roles/ManageRoleAXSecurityDesigner.html',
            controller: AddAXTablesToPrivilegeSecurityDesigner,
            backdrop: 'static',
            size: 'lg',
            scope: $scope
        });
    };

    async function openTableFieldsModal(privilege) {
        $uibModal.open({
            component: 'tableFieldsModal',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                tables: () => vm.securityDesignerTables,
                simulationId: () => vm.selectedSimulation.Id,
                privilege: () => privilege
            }
        });
    }

    vm.openDataEntitiesModal = function (item) {
        $scope.objectOpenInModal = item;
        var currentItems = item.DataEntities;

        var columns = [
            { title: "Name", field: "Name", filterable: customAutoCompleteFilter, width: 200 },
            { title: "Label", field: "Label", filterable: customAutoCompleteFilter, width: 200 },
            { title: "Read", field: "Read", template: `<select ng-disabled='pageIsReadOnly' class='inline-grid-dropdown' ng-change="actionDropdownChange('Read', dataItem)" ng-model='dataItem.Read'><option ng-repeat='action in tableActions'>{{action}}</option></select>`, filterable: customAutoCompleteFilter },
            { title: "Update", field: "Update", template: `<select ng-disabled='pageIsReadOnly' class='inline-grid-dropdown' ng-change="actionDropdownChange('Update', dataItem)" ng-model='dataItem.Update'><option ng-repeat='action in tableActions'>{{action}}</option></select>`, filterable: customAutoCompleteFilter },
            { title: "Create", field: "Create", template: `<select ng-disabled='pageIsReadOnly' class='inline-grid-dropdown' ng-change="actionDropdownChange('Create', dataItem)" ng-model='dataItem.Create'><option ng-repeat='action in tableActions'>{{action}}</option></select>`, filterable: customAutoCompleteFilter },
            { title: "Delete", field: "Delete", template: `<select ng-disabled='pageIsReadOnly' class='inline-grid-dropdown' ng-change="actionDropdownChange('Delete', dataItem)" ng-model='dataItem.Delete'><option ng-repeat='action in tableActions'>{{action}}</option></select>`, filterable: customAutoCompleteFilter },
            { title: "ContainsAssigningValue", field: "ContainsAssigningValue", hidden: true }
        ];

        if (!item.IsCustom) {
            columns.push({ title: " ", field: "IsModified", template: "<a ng-disabled='pageIsReadOnly' ng-click='deleteModificationToObject(dataItem)' ng-if='dataItem.IsModified' class='icons8-undo-2 pull-right'></a>", width: 50, filterable: false });
        }

        vm.manageSecurityLayerGridOptions = helperService.setMainGridOptions(columns, null);
        vm.manageSecurityLayerGridOptions.selectable = false;

        vm.manageSecurityLayerGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            sort: { field: "Name", dir: "asc" },
            transport: {
                read: function (options) {
                    vm.selectedSecurityDesignerDataEntities = vm.securityDesignerDataEntities.slice();
                    AXSecurityDesignerService.setDataSourceDefaultsAndMapProperties(vm.selectedSecurityDesignerDataEntities, currentItems);

                    vm.selectedSecurityDesignerDataEntities.forEach(function (row) {
                        if (row.Create !== 'Unset' || row.Delete !== 'Unset' || row.Read !== 'Unset' || row.Update !== 'Unset') {
                            row.ContainsAssigningValue = true;
                        } else {
                            row.ContainsAssigningValue = false;
                        }
                    });

                    vm.selectedSecurityDesignerDataEntities = $filter('orderBy')(vm.selectedSecurityDesignerDataEntities, 'Name');
                    options.success(vm.selectedSecurityDesignerDataEntities);
                }
            }
        });

        $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/Roles/ManageRoleAXSecurityDesigner.html',
            controller: AddAXDataEntitiesToPrivilegeSecurityDesigner,
            backdrop: 'static',
            size: 'lg',
            scope: $scope
        });
    };

    vm.openServiceOperationsModal = function (item) {
        $scope.objectOpenInModal = item;
        var currentItems = item.ServiceOperations;

        var columns = [
            { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
            { title: "Invoke", field: "Invoke", template: `<select ng-disabled='pageIsReadOnly' class='inline-grid-dropdown' ng-change="actionDropdownChange('Invoke', dataItem)" ng-model='dataItem.Invoke'><option ng-repeat='action in tableActions'>{{action}}</option></select>`, filterable: customAutoCompleteFilter, width: 250 },
            { title: "ContainsAssigningValue", field: "ContainsAssigningValue", hidden: true }
        ];

        if (!item.IsCustom) {
            columns.push({ title: " ", field: "IsModified", template: "<a ng-disabled='pageIsReadOnly' ng-click='deleteModificationToObject(dataItem)' ng-if='dataItem.IsModified' class='icons8-undo-2 pull-right'></a>", width: 50, filterable: false });
        }

        vm.manageSecurityLayerGridOptions = helperService.setMainGridOptions(columns, null);
        vm.manageSecurityLayerGridOptions.selectable = false;

        vm.manageSecurityLayerGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            sort: { field: "Name", dir: "asc" },
            transport: {
                read: function (options) {
                    vm.selectedSecurityDesignerServiceOperations = vm.securityDesignerServiceOperations.slice();
                    AXSecurityDesignerService.setDataSourceDefaultsAndMapProperties(vm.selectedSecurityDesignerServiceOperations, currentItems);

                    vm.selectedSecurityDesignerServiceOperations.forEach(function (row) {
                        if (row.Invoke !== 'Unset') {
                            row.ContainsAssigningValue = true;
                        } else {
                            row.ContainsAssigningValue = false;
                        }
                    });

                    vm.selectedSecurityDesignerServiceOperations = $filter('orderBy')(vm.selectedSecurityDesignerServiceOperations, 'Name');
                    options.success(vm.selectedSecurityDesignerServiceOperations);
                }
            }
        });

        $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/Roles/ManageRoleAXSecurityDesigner.html',
            controller: AddAXServiceOperationsToPrivilegeSecurityDesigner,
            backdrop: 'static',
            size: 'lg',
            scope: $scope
        });
    };

}

function AddAXMenuItemsToPrivilegeSecurityDesigner($scope, $uibModalInstance, AXSecurityDesignerService) {

    $scope.selectedTableFilter = "All";
    $scope.modalHeader = "Menu Items";
    $scope.tableActions = ["Grant", "Deny", "Unset"];

    $scope.changeFilter = function () {
        AXSecurityDesignerService.changeFilter($scope.selectedTableFilter, $scope.vm.manageSecurityLayerGrid.dataSource, "ContainsAssigningValue");
    };

    $scope.actionDropdownChange = async function (action, dataItem) {
        kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, true);
        dataItem.IsModified = true;

        var parentItem = $scope.vm.securityDesignerPrivilegesTree.filter(e => e.SystemName.toLowerCase() === $scope.objectOpenInModal.SystemName.toLowerCase())[0];

        var childItem = {
            "Create": dataItem.Create,
            "Delete": dataItem.Delete,
            "Invoke": dataItem.Invoke,
            "IsModified": true,
            "OldCreate": dataItem.OldCreate,
            "OldDelete": dataItem.OldDelete,
            "OldInvoke": dataItem.OldInvoke,
            "OldRead": dataItem.OldRead,
            "OldUpdate": dataItem.OldUpdate,
            "Read": dataItem.Read,
            "SystemName": dataItem.Name,
            "Update": dataItem.Update,
            "Type": dataItem.Type
        };

        var parentItemMenuItem = parentItem.MenuItems.filter(e => e.SystemName.toLowerCase() === dataItem.Name.toLowerCase())[0];
        parentItem.MenuItems.remove(parentItemMenuItem);

        if (dataItem.Create !== 'Unset' || dataItem.Delete !== 'Unset' || dataItem.Read !== 'Unset' || dataItem.Update !== 'Unset') {
            dataItem.ContainsAssigningValue = true;
        } else {
            dataItem.ContainsAssigningValue = false;
        }

        parentItem.MenuItems.push(childItem);

        var childTypeId;
        if (childItem.Type === "Action") {
            childTypeId = 3;
        } else if (childItem.Type === "Display") {
            childTypeId = 1;
        } else if (childItem.Type === "Output") {
            childTypeId = 2;
        }

        let { data } = await AXSecurityDesignerService.AssignChildObjectToParent({ SimulationId: $scope.vm.selectedSimulation.Id, ChildItem: childItem, ChildType: childTypeId, ParentItem: parentItem, ParentType: 3 });
        // use the returned object to update the permissions dropdowns
        childItem.Create = data[0].Create;
        childItem.Update = data[0].Update;
        childItem.Read = data[0].Read;
        childItem.Delete = data[0].Delete
        $scope.vm.manageSecurityLayerGridDataSource.read();
        $scope.determineModifiedArrays();

        kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, false);
    };

    $scope.deleteModificationToObject = function (dataItem) {
        kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, true);
        AXSecurityDesignerService.DeleteModificationToObject(dataItem, $scope.vm.selectedSimulation, $scope.objectOpenInModal, "MenuItems").then(function () {
            $scope.determineModifiedArrays();
            kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, false);
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}

function AddAXTablesToPrivilegeSecurityDesigner($scope, $uibModalInstance, AXSecurityDesignerService) {

    $scope.modalvm = {};

    $scope.selectedTableFilter = "All";
    $scope.modalHeader = "Tables";
    $scope.tableActions = ["Grant", "Deny", "Unset"];

    $scope.changeFilter = function () {
        AXSecurityDesignerService.changeFilter($scope.selectedTableFilter, $scope.vm.manageSecurityLayerGrid.dataSource, "ContainsAssigningValue");
    };

    $scope.actionDropdownChange = async function (action, dataItem) {
        kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, true);
        dataItem.IsModified = true;

        var parentItem = $scope.vm.securityDesignerPrivilegesTree.filter(e => e.SystemName.toLowerCase() === $scope.objectOpenInModal.SystemName.toLowerCase())[0];

        var childItem = {
            "Create": dataItem.Create,
            "Delete": dataItem.Delete,
            "Invoke": dataItem.Invoke,
            "IsModified": true,
            "OldCreate": dataItem.OldCreate,
            "OldDelete": dataItem.OldDelete,
            "OldInvoke": dataItem.OldInvoke,
            "OldRead": dataItem.OldRead,
            "OldUpdate": dataItem.OldUpdate,
            "Read": dataItem.Read,
            "SystemName": dataItem.Name,
            "Update": dataItem.Update,
            "Type": dataItem.Type
        };

        var parentItemTable = parentItem.Tables.filter(e => e.SystemName.toLowerCase() === dataItem.Name.toLowerCase())[0];

        parentItem.Tables.remove(parentItemTable);

        if (dataItem.Create !== 'Unset' || dataItem.Delete !== 'Unset' || dataItem.Read !== 'Unset' || dataItem.Update !== 'Unset') {
            dataItem.ContainsAssigningValue = true;
        } else {
            dataItem.ContainsAssigningValue = false;
        }

        parentItem.Tables.push(childItem);

        let { data } = await AXSecurityDesignerService.AssignChildObjectToParent({ SimulationId: $scope.vm.selectedSimulation.Id, ChildItem: childItem, ChildType: 44, ParentItem: parentItem, ParentType: 3 });
        // use the returned object to update the permissions dropdowns
        childItem.Create = data[0].Create;
        childItem.Update = data[0].Update;
        childItem.Read = data[0].Read;
        childItem.Delete = data[0].Delete
        $scope.vm.manageSecurityLayerGridDataSource.read();
        $scope.determineModifiedArrays();

        kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, false);
    };

    $scope.deleteModificationToObject = function (dataItem) {
        kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, true);
        AXSecurityDesignerService.DeleteModificationToObject(dataItem, $scope.vm.selectedSimulation, $scope.objectOpenInModal, "Tables").then(function () {
            $scope.determineModifiedArrays();
            kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, false);
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}

function AddAXDataEntitiesToPrivilegeSecurityDesigner($scope, $uibModalInstance, AXSecurityDesignerService) {

    $scope.selectedTableFilter = "All";
    $scope.modalHeader = "Data Entities";
    $scope.tableActions = ["Grant", "Deny", "Unset"];

    $scope.changeFilter = function () {
        AXSecurityDesignerService.changeFilter($scope.selectedTableFilter, $scope.vm.manageSecurityLayerGrid.dataSource, "ContainsAssigningValue");
    };

    $scope.actionDropdownChange = async function (action, dataItem) {
        kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, true);
        dataItem.IsModified = true;

        var parentItem = $scope.vm.securityDesignerPrivilegesTree.filter(e => e.SystemName.toLowerCase() === $scope.objectOpenInModal.SystemName.toLowerCase())[0];

        var childItem = {
            "Create": dataItem.Create,
            "Delete": dataItem.Delete,
            "Invoke": dataItem.Invoke,
            "IsModified": true,
            "OldCreate": dataItem.OldCreate,
            "OldDelete": dataItem.OldDelete,
            "OldInvoke": dataItem.OldInvoke,
            "OldRead": dataItem.OldRead,
            "OldUpdate": dataItem.OldUpdate,
            "Read": dataItem.Read,
            "SystemName": dataItem.Name,
            "Update": dataItem.Update,
            "Type": dataItem.Type
        };

        var parentItemDataEntity = parentItem.DataEntities.filter(e => e.SystemName.toLowerCase() === dataItem.Name.toLowerCase())[0];
        parentItem.DataEntities.remove(parentItemDataEntity);

        if (dataItem.Create !== 'Unset' || dataItem.Delete !== 'Unset' || dataItem.Read !== 'Unset' || dataItem.Update !== 'Unset') {
            dataItem.ContainsAssigningValue = true;
        } else {
            dataItem.ContainsAssigningValue = false;
        }

        parentItem.DataEntities.push(childItem);

        let { data } = await AXSecurityDesignerService.AssignChildObjectToParent({ SimulationId: $scope.vm.selectedSimulation.Id, ChildItem: childItem, ChildType: 67, ParentItem: parentItem, ParentType: 3 });
        // use the returned object to update the permissions dropdowns
        childItem.Create = data[0].Create;
        childItem.Update = data[0].Update;
        childItem.Read = data[0].Read;
        childItem.Delete = data[0].Delete
        $scope.vm.manageSecurityLayerGridDataSource.read();
        $scope.determineModifiedArrays();

        kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, false);
    };

    $scope.deleteModificationToObject = function (dataItem) {
        kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, true);
        AXSecurityDesignerService.DeleteModificationToObject(dataItem, $scope.vm.selectedSimulation, $scope.objectOpenInModal, "DataEntities").then(function () {
            $scope.determineModifiedArrays();
            kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, false);
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}

function AddAXServiceOperationsToPrivilegeSecurityDesigner($scope, $uibModalInstance, AXSecurityDesignerService) {

    $scope.selectedTableFilter = "All";
    $scope.modalHeader = "Service Operations";
    $scope.tableActions = ["Grant", "Deny", "Unset"];

    $scope.changeFilter = function () {
        AXSecurityDesignerService.changeFilter($scope.selectedTableFilter, $scope.vm.manageSecurityLayerGrid.dataSource, "ContainsAssigningValue");
    };

    $scope.actionDropdownChange = function (action, dataItem) {
        kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, true);
        dataItem.IsModified = true;

        var parentItem = $scope.vm.securityDesignerPrivilegesTree.filter(e => e.SystemName.toLowerCase() === $scope.objectOpenInModal.SystemName.toLowerCase())[0];

        var childItem = {
            "Create": dataItem.Create,
            "Delete": dataItem.Delete,
            "Invoke": dataItem.Invoke,
            "IsModified": true,
            "OldCreate": dataItem.OldCreate,
            "OldDelete": dataItem.OldDelete,
            "OldInvoke": dataItem.OldInvoke,
            "OldRead": dataItem.OldRead,
            "OldUpdate": dataItem.OldUpdate,
            "Read": dataItem.Read,
            "SystemName": dataItem.Name,
            "Update": dataItem.Update,
            "Type": dataItem.Type
        };

        var parentItemServiceOperation = parentItem.ServiceOperations.filter(e => e.SystemName.toLowerCase() === dataItem.Name.toLowerCase())[0];
        parentItem.ServiceOperations.remove(parentItemServiceOperation);

        if (dataItem.Invoke !== 'Unset') {
            dataItem.ContainsAssigningValue = true;
        } else {
            dataItem.ContainsAssigningValue = false;
        }

        parentItem.ServiceOperations.push(childItem);

        AXSecurityDesignerService.AssignChildObjectToParent({ SimulationId: $scope.vm.selectedSimulation.Id, ChildItem: childItem, ChildType: 76, ParentItem: parentItem, ParentType: 3 });
        $scope.determineModifiedArrays();

        kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, false);
    };

    $scope.deleteModificationToObject = function (dataItem) {
        kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, true);
        AXSecurityDesignerService.DeleteModificationToObject(dataItem, $scope.vm.selectedSimulation, $scope.objectOpenInModal, "ServiceOperations").then(function () {
            $scope.determineModifiedArrays();
            kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, false);
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}

function clonePrivilegeModal($scope, helperService, $uibModalInstance, $http) {

    $scope.modalvm = {};
    $scope.modalvm.securityObjectType = $scope.securityObjectType;

    $scope.clone = function (name, description) {
        var obj = $scope.selectedSecurityObject;

        if (!obj.pleaseWait) {
            obj.pleaseWait = {};
        }
        obj.pleaseWait = new createSpinner();
        $scope.pleaseWait = new createSpinner();

        if (!description) {
            description = "";
        }

        var postData = {
            "SimulationId": $scope.vm.selectedSimulation.Id,
            "SystemName": obj.SystemName,
            "Name": name,
            "Description": description,
            "Type": "Privilege"
        };

        $http.post(apiUrl + 'api/securitydesigner/ax7/simulation/' + $scope.vm.selectedSimulation.Id + '/clone', postData).then(function (response) {
            $scope.vm.simulationSelected();
            $uibModalInstance.close();
        }, function errorCallback(response) {
            obj.pleaseWait.loadingValue = false;
            $scope.pleaseWait.loadingValue = false;
            helperService.showErrorMessage(response.data);
        });
    };


    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}
