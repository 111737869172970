export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getRolesByUserId,
        getUser,
        createUser,
        addSubstitute,
        setUserRole,
        changeSubstitute,
        updateUser,
        removeSubstitute,
        deleteUserRole,
        addOOFHours,
        removeOOFHours,
        importUsers,
        downloadUsersTemplate
    };

    return service;

    async function getRolesByUserId(userId) {
        try {
            return await $http.get(`${apiUrl}api/core/role/user/${userId}`)
        } catch (err) {
            showError(err);
        }
    }

    async function getUser(userId) {
        try {
            return await $http.get(`${apiUrl}api/core/users/${userId}`)
        } catch (err) {
            showError(err);
        }
    }

    async function downloadUsersTemplate() {
        try {
            return await $http.get(`${apiUrl}api/core/users/export/usertemplate`, { responseType: "blob" });
        } catch (err) {
            showError(err);
        }
    }


    async function createUser(data) {
        try {
            return await $http.post(`${apiUrl}api/core/users`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addSubstitute(userId, data) {
        try {
            return await $http.post(`${apiUrl}api/core/users/${userId}/substitutereviewer`, data);
        } catch (err) {
            showError(err);
        }
    }

    async function setUserRole(data) {
        try {
            return await $http.post(`${apiUrl}api/core/userroles`, data);
        } catch (err) {
            showError(err);
        }
    }

    async function changeSubstitute(userId, data) {
        try {
            return await $http.put(`${apiUrl}api/core/users/${userId}/substitutereviewer`, data);
        } catch (err) {
            showError(err);
        }
    }

    async function updateUser(userId, data) {
        try {
            return await $http.put(`${apiUrl}api/core/users/${userId}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addOOFHours(userId, data) {
        try {
            return await $http.put(`${apiUrl}api/core/users/${userId}/outofoffice`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function importUsers(fd) {
        try {
            return await $http.put(`${apiUrl}/api/core/users/import`, fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function removeSubstitute(userId) {
        try {
            return await $http.delete(`${apiUrl}api/core/users/${userId}/substitutereviewer`);
        } catch (err) {
            errorsDataService.catch(err);
        }
    }

    async function deleteUserRole(data) {
        try {
            return await $http.delete(`${apiUrl}api/core/userroles`, { data: data, headers: { 'Content-Type': 'application/json;charset=utf-8' } });
        } catch (err) {
            showError(err);
        }
    }

    async function removeOOFHours(userId) {
        try {
            return await $http.delete(`${apiUrl}api/core/users/${userId}/outofoffice`);
        } catch (err) {
            errorsDataService.catch(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

}