export default /*@ngInject*/ function ($http) {

    this.getExistingNetSuiteUserSecurity = function (userId) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/ns/employees/userid/' + userId + '/'
        });
    };

    this.getAllRoles = function () {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/ns/roles'
            });
        }
        catch (ex) {
            throw 'Error loading NetSuite Roles';
        }
    };

    this.getAllPermissions = function (userId) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/ns/permissions'
        });
    };

    this.getAllSubsidiaries = function (userId) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/ns/subsidiaries'
        });
    };

    this.getDepartmentsBySubsidiary = function (subsidiaryId) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/ns/subsidiaries/' + subsidiaryId + '/departments'
        });
    };

    this.getClassesBySubsidiary = function (subsidiaryId) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/ns/subsidiaries/' + subsidiaryId + '/classes'
        });
    };

    this.getLocationsBySubsidiary = function (subsidiaryId) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/ns/subsidiaries/' + subsidiaryId + '/locations'
        });
    };

}