export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getProducts,
        getUserId,
        updateGrid,
        generateCrossPlatformAutoMapping,
        deleteUserMaster,
        exportAutoMapping,
        importUserMapping,
        updateManualMapGrid,
        submitUserMapping
    };

    return service;


    async function getProducts() {
        try {
            return await $http.get(`${apiUrl}api/universal/products`);
        } catch (err) {
            showError(err);
        }
    }

    async function getUserId(userId) {
        try {
            return await $http.get(`${apiUrl}api/universal/usermaster/${userId}`);
        } catch (err) {
            showError(err);
        }
    }

    async function generateCrossPlatformAutoMapping() {
        try {
            return await $http.post(`${apiUrl}api/universal/usermaster/crossplatform/users/automap`);
        } catch {
            showError(err);
        }
    }

    async function updateGrid(pageSize, page, data) {
        try {
            return await $http({
                method: 'POST',
                url: apiUrl + 'api/universal/usermaster/crossplatform/users',
                data: data,
                params: { 'pagesize': pageSize, 'page': page }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateManualMapGrid(postData, pageSize, page) {
        try {
            return await $http({
                method: 'POST',
                url: apiUrl + 'api/universal/usermaster/crossplatform/users/available',
                data: postData,
                params: { 'pagesize': pageSize, 'page': page }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function submitUserMapping(postData) {
        try {
            return await $http.post(`${apiUrl}api/universal/usermaster/crossplatform`, postData);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteUserMaster(postData) {
        try {
            return await $http.post(`${apiUrl}api/universal/usermaster/crossplatform`, postData);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function exportAutoMapping() {
        try {
            return await $http.post(`${apiUrl}/api/universal/usermaster/export`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function importUserMapping(fd) {
        try {
            return await $http.put(`${apiUrl}api/universal/usermaster/import`, fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}