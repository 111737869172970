/* groupItemsList.component.js */

import groupItemsListController from "./groupItemsList.controller";

/**
 * @desc component for viewing and maintaining list of ownership group items
 * @example <ownership-group-items-list><ownership-group-items-list>
 */

const groupItemsList = {
    bindings: {
        selectedOwnershipGroup: '<',
        selectedGroupItemType: '<',
        onGroupItemTypeChange: '&',
        onSelectedItemChange: '&'
    },
    controller: groupItemsListController,
    controllerAs: 'gilvm',
    templateUrl: '/App/Components/OwnershipGroups/ownershipGroupItemsList/groupItemsList.tmpl.html'
};

export default groupItemsList;