import { customAutoCompleteFilter } from "../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($scope, $rootScope, objectIdDataService, $http, $location, helperService) {

    objectIdDataService.getObjectID().then(objectId => {
        $scope.ObjectId = objectId;

        var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;
        $scope.$evalAsync();
    });

    $scope.helperService = helperService;

    var vm = $scope.vm = {};

    $scope.showSetup = false;

    vm.selectDetailView = (viewBy, riskType) => {
        $rootScope.NSQuantDetailViewOptions = {
            viewBy: viewBy,
            riskType: riskType
        };
        $location.path('/AccessControl/Place/NetSuiteRiskQuantification/Details').search({});
    };

    // Setup

    $scope.helperService.getConfigurationValue('NS_QuantificationThreshold').then(data => vm.QuantificationThreshold = data);

    $scope.helperService.getConfigurationValue('NS_QuantificationLastDays').then(data => vm.QuantificationLastDays = data);

    var columnsForRiskRuleset = [
        { template: "<label class='switch'><input type='checkbox' ng-model='dataItem.RiskQuantifiable' ng-change='toggleValue(dataItem)'><i></i></label>", width: 55, filterable: false },
        { field: "RiskName", title: "Risk Name", filterable: customAutoCompleteFilter },
        { field: "RiskDescription", title: "Risk Description", filterable: customAutoCompleteFilter }
    ];

    $scope.riskGridOptions = helperService.setNonPageableGridOptions(columnsForRiskRuleset, 600);

    // Setup functions

    $scope.riskGridDataSource = new kendo.data.DataSource({
        sort: { field: "RiskName", dir: "asc" },
        transport: {

            read: options => {
                $http({
                    method: 'GET',
                    url: apiUrl + 'api/arm/ns/quantification/risks'
                }).then(response => options.success(response.data));
            }
        }
    });

    $scope.toggleValue = dataItem => {

        var selectedCheckboxValue = dataItem.RiskQuantifiable;

        if (selectedCheckboxValue === true) {
            $http({
                method: 'POST',
                url: apiUrl + 'api/arm/risks/' + dataItem.RiskId + '/quantification',
                data: {}
            }).then(response => { return; }).catch(response => helperService.showErrorMessage(response.data));
        } else {
            $http({
                method: 'DELETE',
                url: apiUrl + 'api/arm/risks/' + dataItem.RiskId + '/quantification'
            }).then(response => { return; }).catch(response => helperService.showErrorMessage(response.data));
        }
    };

}