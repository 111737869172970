import createSpinner from "../../../Shared/Functions/createSpinner";
import { customAutoCompleteFilterEqualToOnly, customAutoCompleteFilter, customDatePickerFilter } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, allChangesDataService, record, helperService) {

    const vm = this;
    vm.record = Object.assign({}, record.toJSON());
    vm.originalRecord = Object.assign({}, record.toJSON());

    vm.changeRowSelected = changeRowSelected;
    vm.selectedTicketProviderChanged = selectedTicketProviderChanged;
    vm.cancel = cancel;
    vm.removeTicketAssociation = removeTicketAssociation;
    vm.saveChangesRecord = saveChangesRecord;
    vm.recordValuesHaveChanged = recordValuesHaveChanged;

    function changeRowSelected(kendoEvent) {
        const grid = kendoEvent.sender;
        vm.record.TicketId = grid.dataItem(grid.select()).Id;
    }

    function selectedTicketProviderChanged() {
        if (vm.record.TicketProvider !== 'Custom') {
            setupTicketsGrid();
        } else {
            vm.record.TicketId = null;
        }
    }

    function setupTicketsGrid() {

        const ticketsGridColumns = [
            { field: "Status", title: "Status", filterable: false },
            { field: "Id", title: "Id", filterable: customAutoCompleteFilterEqualToOnly },
            { field: "Subject", title: "Subject", filterable: customAutoCompleteFilter },
            { field: "Description", title: "Description", template: "{{ dataItem.Description }}", filterable: customAutoCompleteFilter }, // use template to handle possible issues with characters in description field
            { field: "CreatedDate", title: "Date Created", template: "{{ dataItem.CreatedDate | date:'short'}}", filterable: customDatePickerFilter }
        ];

        vm.ticketsGridOptions = helperService.setMainGridOptions(ticketsGridColumns, 250);
        vm.ticketsGridOptions.pageable = false;
        vm.ticketsGridOptions.filterable = {
            mode: 'row',
            operators: {
                string: {
                    contains: "Contains"
                }
            }
        };

        vm.ticketsGridDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            schema: {
                model: {
                    fields: {
                        CreatedDate: { type: "date" }
                    }
                }
            },
            transport: {
                read: async function (options) {
                    const sortsAndFilters = helperService.prepareSortsAndFilters(options);

                    const ticketsPostBody = {
                        "Criteria": sortsAndFilters.Filters
                    };

                    try {
                        let response = await allChangesDataService.ticketsReport(vm.record.TicketProvider.toLowerCase(), ticketsPostBody);
                        options.success(response.data.Tickets);
                    } catch (err) {
                        options.error(err);
                    }
                }
            }
        });
    }

    function cancel() { $uibModalInstance.dismiss(); }

    function removeTicketAssociation() {
        vm.record.TicketId = null;
        vm.record.TicketProvider = null;
    }

    function recordValuesHaveChanged(array) {
        return helperService.returnTrueIfValuesDiffer(array, vm.originalRecord, vm.record);
    }

    async function saveChangesRecord() {
        vm.saving = new createSpinner();
        const record = {
            "ChangeId": vm.record.ChangeId,
            "IsSystemChange": vm.record.SystemChange,
            "Notes": vm.record.Notes,
            "TicketId": vm.record.TicketProvider === 'Custom' && vm.record.CustomTicketId ? vm.record.CustomTicketId : vm.record.TicketId,
            "TicketProvider": vm.record.TicketProvider
        };
        try {
            await allChangesDataService.updateChangeRecord(record);
            await helperService.successfulSaveButton(vm.saving);
            $uibModalInstance.close();
        } catch {
            vm.saving.loadingValue = false;
        }
    }
}