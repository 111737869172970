import controller from "./maintainSystemRoles.controller";

/* maintainSystemRoles.component.js */

/**
 * @desc component for maintaining system roles
 * @example <maintain-system-roles></maintain-system-roles>
 */

const maintainSystemRoles = {
    controller: controller,
    controllerAs: 'msrvm',
    template: `<div class="maintain-system-roles">
    <page-header></page-header>
    <div class="row wrapper-content">
        <div class="col-lg-12">
            <div class="custom-ibox">
                <div class="ibox-title flex-vcenter flex-between">
                    <h5>System Roles</h5>
                    <div class="pull-right">
                        <span ng-if="!msrvm.exportUpdatePending.loadingValue">
                            <button class="btn btn-secondary" ng-click="msrvm.openImportModal()">
                                Import
                            </button>
                            <button class="btn btn-secondary" ng-click="msrvm.exportSystemRoles()">
                                Export
                            </button>
                        </span>
                        <span>
                            <inline-saver successtext="Export Complete" customcolor="smallWhite" ng-model="msrvm.exportUpdatePending"></inline-saver>
                        </span>
                    </div>
                </div>
                <div class="ibox-content">
                    <div kendo-grid="msrvm.systemRolesGrid"
                         k-options="msrvm.systemRolesGridOptions"
                         k-data-source="msrvm.systemRolesGridDataSource">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>`
};

export default maintainSystemRoles;