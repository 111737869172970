export const aad_application_id = 'e636d4fc-dd34-4dca-9171-d61270179167';
export const ax7_application_id = 'c311c379-e109-4e68-8082-f9c2f2e843ff';
export const ax_application_id = 'e39d0c80-61ea-4f40-8179-a5def6869507';
export const netsuite_application_id = '919aa6b9-8083-4c08-ace7-9322aa69bc4e';
export const sap_application_id = 'a4066336-3e11-4856-a7cc-9726460448b0';
export const oracle_application_id = '34d25422-9dc2-4918-8d85-084373e4bbb3';
export const oracleCloud_application_id = '286e7a77-4450-4527-987c-c08680356b00';
export const gp_application_id = 'e0981441-d1f9-4d5a-af74-cbb7339991a0';
export const nav_application_id = '19c49353-308b-4485-babe-f4200dfd1bf0';
export const d365s_application_id = '79f1178d-d827-460e-84e5-758c0b799115';
export const ps_application_id = '52c24698-ed59-4734-a80f-c7bcde27b16f';
export const universalProduct_application_id = '23fa3370-b057-489e-b6bb-49aa2a2fa040';
export const salesforce_application_id = '98999322-a76f-4c6e-b025-e62134cda4d1';