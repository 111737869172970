import manageDataScheduleController from "./manageDataSchedule.controller";

/* manageDataSchedule.component.js */

/**
 * @desc component for manage data to schedule any actions for a given product
 * @example <manage-data-schedule></manage-data-schedule>
 */

const manageDataSchedule = {
    bindings: {
        product: '<',
        action: '<'
    },
    controller: manageDataScheduleController,
    controllerAs: 'savm',
    templateUrl: '/App/Components/ManageDataV2/Schedule/manageDataSchedule.tmpl.html'
};

export default manageDataSchedule;