/* maintainProfiles.component.js */

/**
 * @desc component to configure profiles in SAP
 * @example <maintain-profiles></maintain-profiles>
 */

const maintainProfiles = {
    template: `<div>
        <page-header></page-header>
            <div class="row wrapper-content">
                <div class="col-lg-12">
                     <div class="row">
                        <maintain-profiles-grid></maintain-profiles-grid>
                     </div>
                </div>
            </div>
        </div >`
};

export default maintainProfiles;