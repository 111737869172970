import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($filter, $uibModalInstance, helperService, notificationTeamsDataService, dataService, teamMembersList, teamType, selectedProduct) {

    const vm = this;

    teamMembersList = [...teamMembersList];

    vm.cancel = cancel;
    vm.addTeamMember = addTeamMember;

    function activate() {
        setupUsersGrid();
        createAvailableUsersDataSource();
    }

    activate();

    function setupUsersGrid() {
        let availableGridColumns = [
            { title: "Name", field: "UserLogonName", filterable: customAutoCompleteFilter },
            { title: "Email", field: "Email", filterable: customAutoCompleteFilter },
            { template: "<a ng-click='atmmvm.addTeamMember(dataItem)' ng-disabled='atmmvm.disableButtonWhileAdding'><i class='fa fa-plus pull-right text-success'></i></a>", title: "", width: 20, filterable: false }
        ];

        vm.availableUsersGridOptions = helperService.setMainGridOptions(availableGridColumns, null);
        vm.availableUsersGridOptions.selectable = false;
    }


    function createAvailableUsersDataSource() {
        vm.availableUsersGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    try {
                        let response = await dataService.getUsers();
                        vm.users = await response.data;
                        teamMembersList.forEach(member => {
                            vm.users = vm.users.filter(user => user.UserLogonName !== member.UserLogonName);
                        });
                        vm.users = vm.users.filter(user => user.Active === true);
                        vm.users = $filter('orderBy')(vm.users, 'UserLogonName');
                        options.success(vm.users);
                    } catch {
                        return;
                    }
                }
            }
        });
    }

    async function addTeamMember(user) {
        kendo.ui.progress(vm.availableUsersGrid.element, true);

        const newTeamMemberInfo = {
            AdmUserId: user.UserID,
            TeamType: teamType,
            ProductId: selectedProduct?.ID
        }

        try {
            await notificationTeamsDataService.addTeamMember(newTeamMemberInfo);
            teamMembersList.push(user);
            kendo.ui.progress(vm.availableUsersGrid.element, false);
            vm.availableUsersGridDataSource.read();
        } catch {
            kendo.ui.progress(vm.availableUsersGrid.element, false);
        }
    }

    function cancel() { $uibModalInstance.close(); }

}