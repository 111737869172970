export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getObjectsToTrack,
        saveFieldDataChanges,
        saveObjectDataChanges,
        exportObjectData,
        importObjectData,
        importObjectTrackingMetadata
    };

    return service;

    async function getObjectsToTrack() {
        try {
            return await $http.get(`${apiUrl}api/audittrail/sf/object-tracking`);
        } catch (err) {
            showError(err);
            sendError(err)
        }
    }

    async function saveFieldDataChanges(fieldData) {
        const data = { "Id": fieldData.Id, "Enabled": fieldData.Enabled };
        try {
            return await $http.put(`${apiUrl}api/audittrail/sf/object-tracking/field/${fieldData.Id}`, data);
        } catch (err) {
            showError(err);
            sendError(err)
        }
    }

    async function saveObjectDataChanges(fieldData) {
        const data = { "Id": fieldData.Id, "Enabled": fieldData.Enabled };
        try {
            return await $http.put(`${apiUrl}api/audittrail/sf/object-tracking/object/${fieldData.Id}`, data);
        } catch (err) {
            showError(err);
            sendError(err)
        }
    }

    async function importObjectTrackingMetadata() {
        try {
            return await $http.put(`${apiUrl}api/audittrail/sf/object-tracking/import-metadata`);
        } catch (err) {
            showError(err);
            sendError(err)
        }
    }

    async function exportObjectData() {
        try {
            return await $http.get(`${apiUrl}api/audittrail/sf/object-tracking/export`, {responseType: "blob"});
        } catch (err) {
            showError(err);
            sendError(err)
        }
    }

    async function importObjectData(formData) {
        try {
            return await $http.put(`${apiUrl}api/audittrail/sf/object-tracking/import`, formData, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err)
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }
}