import createSpinner from "../../Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, companyTemplatesData, role, availableCompanies, productId, helperService) {

    const vm = this;
    if (role) {
        vm.applyToAllRoles = false;
        vm.role = { ...role };
    } else {
        vm.applyToAllRoles = true;
        vm.role = {
            Name: 'All Roles'
        };
    }
    vm.availableCompanies = [...availableCompanies];
    vm.cancel = cancel;
    vm.close = close;
    vm.addCompanyToList = addCompanyToList;
    vm.deleteselectedCompany = deleteselectedCompany;
    vm.copyCompaniesFromTemplate = copyCompaniesFromTemplate;
    vm.selectAllCompaniesChanged = selectAllCompaniesChanged;

    activate();

    function activate() {
        getTemplates();
        if (vm.role.Companies) {
            filterAvailableCompanies();
            vm.selectAllCompanies = false;
        } else {
            vm.role.Companies = [];
            vm.selectAllCompanies = true;
            vm.selectAllCompaniesChanged()
        }
    }

    function filterAvailableCompanies() {
        vm.role.Companies.forEach(selectedCompany => {
            vm.availableCompanies = vm.availableCompanies.filter(company => company.CompanyId !== selectedCompany.CompanyId);
        });
    }

    function addCompanyToList(company) {
        vm.role.Companies.push(company);
        const selectedCompanyIndex = vm.availableCompanies.findIndex(item => item.CompanyId === company.CompanyId);
        vm.availableCompanies.splice(selectedCompanyIndex, 1);
    }

    function deleteselectedCompany(company) {
        vm.availableCompanies.push(company);
        const removedCompanyIndex = vm.role.Companies.findIndex(item => item.CompanyId === company.CompanyId);
        vm.role.Companies.splice(removedCompanyIndex, 1);
    }

    async function getTemplates() {
        let response = await companyTemplatesData.getCompanyTemplates();
        vm.templatesDataSource = response.data.map(({ Id, Name, Companies }) => {
            const companies = Companies.reduce((acc, curr) => {
                curr.Companies = curr.Companies.filter(co => co.ProductID === productId);
                return acc.concat(curr.Companies);
            }, []);

            return { Id, Name, Companies: companies };
        });
    }

    function copyCompaniesFromTemplate() {
        vm.selectedTemplate.Companies.forEach(company => {
            const availableCompany = findAvailableCompany(company);
            if (availableCompany) {
                const availableCompanyIndex = vm.availableCompanies.findIndex(ac => ac.CompanyId === company.ID);
                addCompany(availableCompany, availableCompanyIndex);
            } else {
                return;
            }
        });
    }

    function findAvailableCompany(company) {
        return vm.availableCompanies.find(selectedCompany => selectedCompany.CompanyId === company.ID);
    }

    function addCompany(companyObject, companyIndex) {
        vm.role.Companies.push(companyObject);
        vm.availableCompanies.splice(companyIndex, 1);
    }

    function selectAllCompaniesChanged() {
        if (vm.selectAllCompanies) {
            let roleCompanies = [...vm.role.Companies];
            roleCompanies.forEach(company => {
                deleteselectedCompany(company);
            });
            vm.companySelectionDisabled = true;
        } else {
            vm.companySelectionDisabled = false;
        }
    }

    function cancel() { $uibModalInstance.dismiss(); }

    async function close() {
        vm.saving = new createSpinner();
        let roleCompanyModalObject = {
            applyToAll: vm.applyToAllRoles,
            roleCompanies: vm.role.Companies
        };
        await helperService.successfulSaveButton(vm.saving);
        $uibModalInstance.close(roleCompanyModalObject);
    }
}