/* workdayMaintainTemplates.component.js */

/**
 * @desc component for displaying and maintaining Workday Templates
 * @example <workday-maintain-templates></workday-maintain-templates>
 */

const workdayMaintainTemplates = {
    controller: workdayMaintainTemplatesController,
    controllerAs: 'wmtvm',
    template: `<div>
                    <page-header></page-header>
                    <div class="row wrapper-content">
                        <div class="col-lg-12">
                            <div class="row">
                                <workday-templates-list  on-selected-template-changed="wmtvm.changeSelectedTemplate(template)"></workday-templates-list>
                                <workday-classes-list ng-if="wmtvm.selectedTemplate"
                                      selected-template="wmtvm.selectedTemplate">
                                </workday-classes-list>
                            </div>
                        </div>
                    </div>
                </div>`
};

/*@ngInject*/ function workdayMaintainTemplatesController() {

    const vm = this;

    vm.changeSelectedTemplate = changeSelectedTemplate;

    function changeSelectedTemplate(template) {
        vm.selectedTemplate = template;
    }
}

export default workdayMaintainTemplates;