import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($timeout, authenticationService, emailNavigationService, $http, $window, $location, helperService, controlLibraryDataService) {

    const vm = this;

    vm.signReport = signReport;
    vm.uploadFiles = uploadFiles;
    vm.cancel = cancel;

    activate();

    async function activate() {
        vm.pageParams = $location.search();
        vm.tenantInlocalStorage = angular.fromJson(localStorage.getItem("selectedTenant"));
        await redirectIfNeeded();
        getReportInfo();
    }

    async function redirectIfNeeded() {
        vm.currentUser = await authenticationService.getUser();

        if (vm.pageParams.tenantid) {
            if (vm.pageParams.tenantid === vm.tenantInlocalStorage.ID) {
                vm.sufficientSecurityAccess = true;
                return;
            }
            else {
                findTenantFromEmail();
            }
        }
        else {
            vm.sufficientSecurityAccess = true;
            return;
        }
    }

    async function findTenantFromEmail() {
        let validTenant, { data } = await emailNavigationService.availableTenants(vm.currentUser.userId)
        validTenant = data.find(tenant => tenant.ID === vm.pageParams.tenantid);

        if (validTenant) {
            vm.redirectToTenant = true;
            localStorage.setItem("selectedTenant", JSON.stringify(angular.copy(validTenant)));
            $window.location.reload();
        } else {
            vm.insufficientSecurityAccess = true;
        }
    }

    async function getReportInfo() {
        let { data } = await $http.get(`${apiUrl}api/core/savedReports/${vm.pageParams.reportid}`);
        vm.reportInfo = data;
    }

    async function signReport() {
        let signReportObject = {
            "SavedReportId": vm.pageParams.reportid,
            "Notes": vm.notes
        };

        //If executionid is included in the query string add it to the API call
        if (vm.pageParams.executionid) {
            signReportObject = $.extend({}, signReportObject, { "ExecutionId": vm.pageParams.executionid });
        }

        if (vm.tenantInlocalStorage.ID === vm.pageParams.tenantid) {
            try {
                let { data } = await $http.post(`${apiUrl}api/core/cases/report/${vm.pageParams.reportid}`, signReportObject)
                localStorage.removeItem('locationBeforeRedirect');
                if (vm.attachments.flow.files.length > 0) {
                    const requestId = data;
                    await uploadFiles(vm.attachments.flow.files, requestId);
                }
                await helperService.showConfirmationMessageNoButtons("Success", "Report has been signed. You will now be redirected to the homepage");
                $timeout(function () {
                    $window.open(appUrl, '_self');
                }, 3500);
            } catch (error) {
                helperService.showErrorMessage(error.data);
            }
        } else {
            helperService.showErrorMessage(`You are not logged into the correct tenant for this report. Please log into the ${vm.correctTenant} tenant.`);
        }

    }

    async function uploadFiles(files, requestId) {
        let fd = new FormData();
        vm.saving = new createSpinner();

        files.forEach(function (file, i) {
            fd.append(files[i].file.name, files[i].file);
        });

        fd.append("DataType", "Signature");
        fd.append("DataTypeId", requestId);

        await controlLibraryDataService.addAttachments(fd);
        await helperService.successfulSaveButton(vm.saving);
    }

    function cancel() { $uibModalInstance.close(); }

}
