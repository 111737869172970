import selectRolesByUserController from "./selectRolesByUser.controller";

/* selectRolesByUser.component.js */

/**
 * @desc component for selecting user from list of all users and getting roles associated with the selected user
 * @example <select-roles-by-user></select-roles-by-user>
 */

const selectRolesByUser = {
    bindings: {
        productId: '<',
        stringProductId: '@',
        roles: '=',
        availableRoles: '=',
        storedRoles: '=',
        altText: '@',
        companies: '<',
        selectedCompany: '<',
        businessUnitId: '<',
        validateRoles: '&',
        requestAction: '<',
        toggleIntraResponsibility: '&?',
        excludeRoles: '<'
    },
    controller: selectRolesByUserController,
    controllerAs: 'srbuvm',
    templateUrl: '/App/Shared/Components/selectRolesByUser/selectRolesByUser.tmpl.html'
};

export default selectRolesByUser;