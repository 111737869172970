import swal from "sweetalert2";
import { customAutoCompleteFilter } from "../../Shared/GlobalVariables/filterVariables";
import editUserModalController from "./EditModal/editUserModal.controller";
import importUsersModalController from "./ImportModal/importUsersModal.controller";
import newUsersModalController from "./newUsersModal.controller";
import substituteModalController from "./SubstituteModal/substituteModal.controller";

export default /*@ngInject*/ function ($scope, $uibModal, dataService, $filter, $rootScope, helperService, usersDataService) {
    const vm = this;

    vm.shouldBeOpen = true;
    vm.preselectUserId = null;
    vm.listOfUsers = null;
    vm.menuOptions = [
        {
            text: 'Edit',
            click: function ($itemScope, $event, modelValue) {
                const user = modelValue;
                vm.editUser(user);
            }
        },
        {
            text: 'Out of Office Manager',
            click: function ($itemScope, $event, modelValue) {
                const user = modelValue;
                vm.openSubstituteModal(user);
            },
            displayed: function (modelValue) {
                return $rootScope.UserSecurityList['2108'];
            }
        }
    ];

    vm.removeSubstitute = removeSubstitute;
    vm.removeOOFHours = removeOOFHours;
    vm.openSubstituteModal = openSubstituteModal;
    vm.changeRowSelected = changeRowSelected;
    vm.toggleUsersActiveState = toggleUsersActiveState;
    vm.toggleRoleState = toggleRoleState;
    vm.openNewModal = openNewModal;
    vm.editUser = editUser;
    vm.saveNameChange = saveNameChange;
    vm.openUserImportModal = openUserImportModal;

    $scope.$on('ImportUsers', function (event, data) {
        getUsers();
    });

    activate();

    function activate() {
        checkLocalAccounts();
        getUsers();
        setupAssignRolesGrid();
    }

    async function checkLocalAccounts() {
        let data = await helperService.getConfigurationValue('AllowLocalAccounts');
        vm.AllowLocalAccounts = data;
    }

    //Load List of users
    async function getUsers() {
        let response = await dataService.getUsers();
        vm.listOfUsers = $filter('orderBy')(response.data, 'UserLogonName');
        findSubstituteNames(vm.listOfUsers);
        if (vm.preselectUserId) {
            let user = response.data.find(e => vm.preselectUserId == e.UserID);
            vm.changeRowSelected(user);
        }
    }

    function changeRowSelected(dataItem) {

        let previousSelectedUser = { ...vm.selectedUser };
        vm.selectedUser = dataItem;

        if (previousSelectedUser && dataItem) {
            if (previousSelectedUser.UserID !== dataItem.UserID) {
                vm.assignRolesGridDataSource.read();
            }
        } else if (dataItem) {
            vm.assignRolesGridDataSource.read();
        }
    };

    function setupAssignRolesGrid() {
        vm.assignRolesGridOptions = {
            sortable: true,
            autoBind: false,
            height: 315,

            pageable: {
                numeric: false,
                previousNext: false,
                messages: {
                    display: "Total: {2}"
                }
            },
            columns: [{
                title: " ",
                template: "<label class='switch'><input type='checkbox' ng-model='dataItem.SelectedRoleCheckbox' ng-change='uvm.toggleRoleState(dataItem)'><i></i></label>",
                width: 55,
                filterable: false
            },
            {
                field: "Name",
                filterable: customAutoCompleteFilter
            }

            ]
        };

        vm.assignRolesGridDataSource = new kendo.data.DataSource({
            transport: {
                read: async options => {

                    let objectModel = function (ID, Name, ObjectID, SelectedRoleCheckbox) {
                        this.ID = ID,
                            this.Name = Name,
                            this.ObjectID = ObjectID,
                            this.SelectedRoleCheckbox = SelectedRoleCheckbox;
                    };

                    let allRoles = await dataService.getRoles();
                    allRoles.data = $filter('orderBy')(allRoles.data, 'Name');
                    let roles = [];
                    allRoles.data.forEach(function (item) {
                        roles.push(new objectModel(item.ID, item.Name, item.ObjectID, false));
                    });

                    let userRoles = await usersDataService.getRolesByUserId(vm.selectedUser.UserID);
                    userRoles.data.forEach(item => {
                        for (let j = 0; j < roles.length; j++) {
                            if (roles[j].ID === item.ID) {
                                roles[j].SelectedRoleCheckbox = true;
                                break;
                            }
                        }
                    });

                    options.success(roles);
                }
            }
        });
    }

    async function toggleUsersActiveState(dataItem) {
        let response = await usersDataService.getUser(dataItem.UserID);
        let data = {
            UserId: response.data.UserID,
            Name: response.data.UserLogonName,
            Email: response.data.Email,
            Active: dataItem.Active
        };
        try {
            await usersDataService.updateUser(dataItem.UserID, data);
        } catch {
            dataItem.Active = !dataItem.Active;
        }
    }

    async function toggleRoleState(dataItem) {
        let data = {
            UserRoles: [{
                roleId: dataItem.ID,
                userId: vm.selectedUser.UserID
            }]
        };

        if (dataItem.SelectedRoleCheckbox === true) {
            // set the role for the user
            await usersDataService.setUserRole(data);
        } else {
            // delete the role for the user
            await usersDataService.deleteUserRole(data);
        }
    };

    async function openNewModal() {
        let newUserModal = $uibModal.open({
            templateUrl: '/App/Shared/Partials/newUserModal.html',
            controller: newUsersModalController,
            controllerAs: 'numvm',
            backdrop: 'static',
            resolve: {
                allowLocalAccounts: () => vm.AllowLocalAccounts
            }
        });

        try {
            vm.preselectUserId = await newUserModal.result;
            getUsers();
        } catch {
            return;
        }
    };


    async function editUser(user) {
        let modal = $uibModal.open({
            templateUrl: '/App/Components/Users/EditModal/editUserModal.html',
            controller: editUserModalController,
            controllerAs: 'eumvm',
            backdrop: 'static',
            resolve: {
                user: () => user
            }
        });

        try {
            await modal.result;
            getUsers();
        } catch (_) {
            return;
        }
    }

    //Save the new name
    async function saveNameChange(item) {
        let postItem = {
            'UserId': item.UserID,
            'Name': item.changeNameTo,
            'Active': item.Active
        };
        await usersDataService.updateUser(item.UserID, postItem);
        item.editModeOn = false;
        item.UserLogonName = item.changeNameTo;
    };

    function findSubstituteNames(users) {
        users.forEach(user => {
            if (user.SubstituteReviewer) {
                user.SubstituteReviewer.UserLogonName = users.find(u => u.UserID === user.SubstituteReviewer.SubstituteUserId).UserLogonName;
            }
        });
    }

    async function openSubstituteModal(user) {
        let modal = $uibModal.open({
            templateUrl: '/App/Components/Users/SubstituteModal/substituteModal.html',
            controller: substituteModalController,
            controllerAs: 'smvm',
            backdrop: 'static',
            resolve: {
                user: () => user,
                source: () => 'usersPage'
            }
        });

        try {
            await modal.result;
            getUsers();
        } catch (_) {
            return;
        }
    }

    async function removeSubstitute(user) {

        const substituteName = user.SubstituteReviewer.UserLogonName;

        try {
            if (user.OutOfOfficeHours) {
                await swal(helperService.areYouSureParamsWithHTML('Remove Substitute', `<div>Are you sure you want to remove <strong>${substituteName}</strong> as a substitute reviewer?</div><div class="m-t-sm">This will also remove associated out of office hours.</div>`, 'Remove'));
            }
            await usersDataService.removeSubstitute(user.UserID);
            getUsers();
        } catch (_) {
            return;
        }
    }

    async function removeOOFHours(user) {
        await usersDataService.removeOOFHours(user.UserID);
        getUsers();
    }


    async function openUserImportModal() {
        $uibModal.open({
            templateUrl: 'App/Components/Users/ImportModal/importUsersModal.html',
            controller: importUsersModalController,
            controllerAs: 'iumvm',
            backdrop: 'static',
        });

    };

}

