import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, $filter, ownershipGroup, dataService, helperService, ownershipGroupItemsDataService, ownershipGroupOwnerDataService) {
    const vm = this;

    vm.saveProduct = saveProduct;
    vm.selectedOwnerTypeChanged = selectedOwnerTypeChanged;
    vm.cancel = cancel;

    vm.ownershipGroup = { ...ownershipGroup };

    activate();

    async function activate() {
        await getProducts();
        await getOwners();

        vm.selectedOwnerType = 'User';
        selectedOwnerTypeChanged();
    }

    async function getProducts() {
        let { data } = await ownershipGroupItemsDataService.getAvailableItems(ownershipGroup);
        vm.products = data;
    }

    async function getOwners() {
        vm.ownersDataSourceLoading = true;
        let response = await ownershipGroupOwnerDataService.getAllOwners(ownershipGroup.Id);
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.availableOwnersDataSource = response.data;
        vm.ownersDataSourceLoading = false;
        return vm.availableOwnersDataSource;
    }

    async function saveProduct() {
        vm.savePending = new createSpinner();
        let newProductId;
        let productIds = [vm.selectedProduct];
        let postData = {
            GroupId: ownershipGroup.Id,
            Owners: vm.selectedOwners,
            ProductIds: productIds
        };

        try {
            const { data } = await ownershipGroupItemsDataService.addItemToGroup(postData, ownershipGroup.Id, ownershipGroup.GroupType);
            newProductId = data;
            await helperService.successfulSaveButton(vm.savePending);
            close(newProductId);
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function selectedOwnerTypeChanged() {
        vm.selectedOwners = null;
        vm.filteredOwnersDataSource = vm.availableOwnersDataSource.filter(owner => owner.TypeName === vm.selectedOwnerType);
    }

    function close(productId) {
        $uibModalInstance.close(productId);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}