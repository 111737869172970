import { customAutoCompleteFilter, customDatePickerFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";


export default /*@ngInject*/ function (helperService, objectIdDataService, CustomReportServices, reportsDataService, $filter, reportsService, dataService, $rootScope) {
    const vm = this;
    const supportedProducts = ["SF"];
    const isDateRangeFilter = 4;
    const dateTypeLast = 2;
    const dateTypes = ["Previous", "ToDate", "LastNumberOf"];
    const periodNames = ['Day', 'Week', 'Month', 'Quarter', 'Year'];

    vm.relativeDateType = 2;
    vm.periodType = 0;
    vm.generateGridWithSelectedValues = generateGridWithSelectedValues;
    vm.clearCriteriaModel = clearCriteriaModel;
    vm.populateCriteriaModel = populateCriteriaModel;
    vm.setFilterValues = setFilterValues;
    vm.onApplyFilter = onApplyFilter;
    vm.onClearFilters = onClearFilters;
    vm.onReadGrid = onReadGrid;
    vm.reportDetailsModel = [];
    vm.openStartDate = openStartDate;
    vm.openEndDate = openEndDate;
    vm.startDatePopupOpened = false;
    vm.endDatePopupOpened = false;
    vm.enableGrid = false;



    const rowSettingsTemplate = `<row-settings-template params="dataItem" selected-product="scvm.selectedProduct" on-delete="scvm.onReadGrid()"></row-settings-template>`;

    var ticketTemplate = `<span ng-if='dataItem.TicketProvider && dataItem.TicketProvider !== "Custom"'>{{ dataItem.TicketProvider }} &nbsp; <a href='{{dataItem.TicketUrl}}' target='_blank'>{{dataItem.TicketId}}</a></span>
                          <span ng-if='dataItem.TicketProvider && dataItem.TicketProvider === "Custom"'>{{ dataItem.TicketProvider }} &nbsp; <span>{{dataItem.TicketId}}</span></span>`;

    vm.mainGridColumns = [{ title: "", template: rowSettingsTemplate, attributes: { "class": "no-padding overflow-visible" }, minResizableWidth: 22, width: 22 },
    { field: "TicketDisplayName", title: "Ticket", template: ticketTemplate, filterable: false, sortable: false }];

    vm.dateCriteria = [
        {
            "DataType": "DateTime",
            "FieldName": "Date",
            "IsNullable": false
        }
    ];


    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    // ---

    activate();

    async function activate() {
        await getObjectId()
        getProducts();
        vm.enableSetupTracking = helperService.getConfigurationValue('SF_AT_EnableSetupTracking');
        vm.relativeDateTypeDataSource = CustomReportServices.getRelativeDateTypes();
        vm.periodTypeDataSource = CustomReportServices.getPeriodTypes();
        setupDatePickers();
        setDates();
        await setReportMetadata();
        vm.mainGridOptions = helperService.setMainGridOptions(vm.mainGridColumns, 600);
        if ($rootScope.loadedSavedReport) {
            vm.loadedSavedReport = $rootScope.loadedSavedReport;
            loadSavedReport();
        }
        // We want the necessary report details available to be able to use the report toolbar without running the report
        populateCriteriaModel();
    }

    async function getObjectId() {
        let objectId = await objectIdDataService.getObjectID();
        // Needed for report toolbar controller functions
        vm.ObjectId = objectId;
    }

    function loadSavedReport() {
        vm.filterInfo = vm.loadedSavedReport.ReportDefinition;
        if (vm.filterInfo.DateFilters.length) {
            vm.relativeDateType = dateTypes.indexOf(vm.filterInfo.DateFilters[0].DateType);
            vm.periodType = periodNames.indexOf(vm.filterInfo.DateFilters[0].Period);
            vm.lastNumberOf = vm.filterInfo.DateFilters[0].Value ?? null;

        } else {
            vm.relativeDateType = isDateRangeFilter; // 4
            const startDateObject = vm.filterInfo.Filters.find(filter => filter.Operator === "GreaterThanOrEqual");
            vm.startDate = new Date(startDateObject.Value).getTime();
            const endDateObject = vm.filterInfo.Filters.find(filter => filter.Operator === "LessThanOrEqual");
            vm.endDate = new Date(endDateObject.Value).getTime();

        }

        vm.filterCriteria.query = vm.filterInfo.Filters.filter(filter => filter.FieldName !== "CreatedDate");
        vm.filterCriteria.dateQuery = reportsService.getSavedReportDateFilters();
        vm.filterCriteria.needsUpdate = true;

        generateGridWithSelectedValues();
    }

    function loadDateFilterAsCriteria(DateFilter) {
        if (DateFilter.Operator === 'GreaterThanOrEqual' || DateFilter.Operator === 'LessThanOrEqual') {
            if (!vm.loadedSavedReport.ReportDefinition.Criteria[0]) {
                vm.loadedSavedReport.ReportDefinition.Criteria = [{
                    "CriteriaType": "Date",
                    "CriteriaValue": DateFilter.FieldName,
                    Filters: []
                }];
                vm.loadedSavedReport.ReportDefinition.Criteria[0].Filters.push(DateFilter);
            } else {
                vm.loadedSavedReport.ReportDefinition.Criteria[0].Filters.push(DateFilter);
            }
        } else {
            vm.filterInfo.Criteria = [{
                "CriteriaType": "Date",
                "CriteriaValue": DateFilter.FieldName,
                Filters: []
            }];
            vm.filterInfo.DateFilters = [DateFilter];
        }
    }

    async function getProducts() {
        let { data: products } = await dataService.getProducts();
        const productDataSource = products.filter(product => supportedProducts.find(supportedProduct => supportedProduct === product.Type));
        vm.selectedProduct = productDataSource.find(product => product.Type === "SF");
    }

    function setFilterValues() {
        vm.loadedSavedReport = null;
        vm.dateFilter = [];
        if (vm.startDate <= vm.endDate) {

            if (vm.relativeDateType !== isDateRangeFilter) {
                vm.periodName = periodNames.find((type, index) => index === vm.periodType);
                vm.dateType = dateTypes.find((type, index) => index === vm.relativeDateType);

                vm.dateFilter = [
                    {
                        "DateType": vm.dateType,
                        "FieldName": vm.fieldName,
                        "Period": vm.periodName,
                        "Value": vm.relativeDateType === dateTypeLast ? vm.lastNumberOf : null
                    }
                ]
            }

            if (vm.relativeDateType === isDateRangeFilter) {
                vm.startDateRow = {
                    "FieldName": vm.fieldName,
                    "Operator": 'GreaterThanOrEqual',
                    "Value": helperService.setDateToBeginningOfDay(vm.startDate)
                };

                vm.endDateRow = {
                    "FieldName": vm.fieldName,
                    "Operator": 'LessThanOrEqual',
                    "Value": helperService.setDateToEndOfDay(vm.endDate)
                };
            }
            generateGridWithSelectedValues();

        } else {
            helperService.showErrorMessage("Start Date must be before or equal to End Date");
        }
    }

    function generateGridWithSelectedValues() {
        vm.gridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: angular.extend(halSchemaKendoGrid, {
                model: {
                    fields: {
                        CreatedDate: { type: "date" }
                    }
                }
            }),
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: async options => {
                    let filterData = {};
                    if (!vm.loadedSavedReport) {
                        const sortsAndFilters = helperService.prepareSortsAndFilters(options);
                        setupFilters();
                        // --- FOR ADVANCED FILTERING ---
                        if (vm.filterCriteria.query) {
                            sortsAndFilters.Filters = sortsAndFilters.Filters.concat(vm.filterCriteria.query);
                        }
                        // ---

                        if (vm.relativeDateType === isDateRangeFilter) {
                            sortsAndFilters.Filters = sortsAndFilters.Filters.concat(vm.startDateRow);
                            sortsAndFilters.Filters = sortsAndFilters.Filters.concat(vm.endDateRow);
                        }
                        
                        filterData = {
                            "QueryInput": {
                                "DateFilters": vm.dateFilter,
                                "Filters": sortsAndFilters.Filters,
                                "Sorts": sortsAndFilters.Sorts
                            }
                        };
                        vm.sortsAndFilters = filterData.QueryInput;
                    } else {
                        filterData = {
                            "QueryInput": {
                                "DateFilters": vm.filterInfo.DateFilters,
                                "Filters": vm.filterInfo.Filters,
                                "Sorts": vm.filterInfo.Sortings
                            }
                        }
                    }
                    const { data } = await reportsDataService.getSetupData(filterData, { 'pagesize': options.data.pageSize, 'page': options.data.page });
                    options.success(data);
                    vm.enableGrid = true;
                }
            }
        });
    }

    function clearCriteriaModel() {
        var newDate = new Date();
        vm.startDate = newDate.setDate(newDate.getDate() - 30);
        vm.endDate = new Date();
        vm.lastNumberOf = "";
    }

    async function setReportMetadata() {
        const { data: reportMetadata } = await reportsDataService.getSetupMetadata();
        vm.reportMetadata = reportMetadata._embedded.reportData;
        vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');
        setupColumns(vm.reportMetadata.Columns);
        vm.fieldName = vm.reportMetadata.Columns.find(c => c.DataType === "DateTime").FieldName;
        vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
        if (!$rootScope.loadedSavedReport) {
            CustomReportServices.loadSavedGridLayout(vm, null);
        }
    }

    function setupColumns(columns) {
        let columnDef = {};
        columns.forEach(column => {
            columnDef = {
                field: column.FieldName, title: column.DisplayName, filterable: customAutoCompleteFilter
            }
            if (column.DataType === "DateTime") {
                columnDef.filterable = customDatePickerFilter;
                columnDef.template = `{{dataItem.${column.FieldName} | date: 'short'}}`
            }
            vm.mainGridColumns.push(columnDef);
        });
    }

    function setupDatePickers() {

        var newDate = new Date();
        vm.startDate = newDate.setDate(newDate.getDate() - 30);

        vm.startDateOptions = {
            dateDisabled: disabled,
            showWeeks: false,
            formatYear: 'yy',
            startingDay: 1
        };

        vm.endDate = new Date();
        vm.endDateOptions = {
            showWeeks: false,
            formatYear: 'yy',
            maxDate: new Date(),
            startingDay: 1
        };

    }

    function setDates() {
        let newDate = new Date();
        newDate.setDate(newDate.getDate() - $rootScope.UserPreferences.DefaultDaysForAuditTrailReports);
        vm.lastNumberOf = $rootScope.UserPreferences.DefaultDaysForAuditTrailReports;

        vm.startDate = newDate;
        vm.endDate = new Date();
    }

    function openStartDate() {
        vm.startDatePopupOpened = true;
    }

    function openEndDate() {
        vm.endDatePopupOpened = true;
    }

    function disabled(data) {
        return data.mode === 'day' && data.date > vm.endDate;
    }

    function onApplyFilter(selectedFilter) {
        const currentOptions = vm.grid.getOptions();

        if (selectedFilter?.Filters?.length) {
            currentOptions.dataSource.filter = helperService.convertFiltersForGrid("", selectedFilter.Filters);
            try {
                vm.grid.dataSource._filter = currentOptions.dataSource.filter;
                vm.grid.dataSource.read();
            }
            catch (err) {
                vm.gridDataSource.filter({});
            }
        }
    }

    function onClearFilters() {
        vm.gridDataSource.filter({});
        onReadGrid();
    }

    function onReadGrid() {
        vm.gridDataSource.read();
    }

    function populateCriteriaModel() {
        var criteria = [];

        criteria.push(CustomReportServices.CreateCriteria("ProductId", 'Product', vm.selectedProduct.Name, vm.selectedProduct.ID));

        setupFilters();

        vm.reportDetailsModel = criteria;

        var dateCriteria = vm.dateCriteria[0];
        dateCriteria.FieldName = "CreatedDate";

        if (dateCriteria) {
            var dateTimeFieldName = dateCriteria.FieldName,
                criteria = { CriteriaType: 'Date', CriteriaValue: dateTimeFieldName, Filters: [], DateFilter: null },
                filterObject = {
                    Criteria: {},
                    Filters: [],
                    RelativeFilter: null
                },
                relativeDate = {
                    "DateType": vm.dateType,
                    "FieldName": dateTimeFieldName,
                    "Period": vm.periodName,
                    "Value": vm.relativeDateType === dateTypeLast ? vm.lastNumberOf : null
                },
                startDate = helperService.setDateToBeginningOfDay(vm.startDate),
                endDate = helperService.setDateToEndOfDay(vm.endDate);

            let endDateRow, startDateRow;

            if (vm.relativeDateType === isDateRangeFilter) {
                startDateRow = {
                    "FieldName": dateTimeFieldName,
                    "Operator": 'GreaterThanOrEqual',
                    "Value": startDate
                };
                endDateRow = {
                    "FieldName": dateTimeFieldName,
                    "Operator": 'LessThanOrEqual',
                    "Value": endDate
                };

                criteria.Filters.push(startDateRow);
                criteria.Filters.push(endDateRow);

                filterObject.Criteria = criteria;

            }
            if (vm.relativeDateType !== isDateRangeFilter) {
                filterObject.RelativeFilter = relativeDate;
                criteria.DateFilter = relativeDate;
                filterObject.Criteria = criteria;
            }
        }

        if (filterObject?.Filters?.length) {
            vm.sortsAndFilters.Filters = vm.sortsAndFilters.Filters.concat(filterObject.Filters);
        }
        if (filterObject.RelativeFilter) {
            vm.sortsAndFilters.DateFilters.push(filterObject.RelativeFilter);
        }
        vm.reportDetailsModel.DateFilters = filterObject.RelativeFilter;

        // if tabs are on, save report info for the tab
        if ($rootScope.tabs.length) {
            reportsService.saveTabReportInfo(vm);
        }
    }

    function setupFilters() {
        if (!vm.sortsAndFilters) {
            vm.sortsAndFilters = {};
        }
        if (!vm.sortsAndFilters.Filters) {
            vm.sortsAndFilters.Filters = [];
        }
        if (!vm.sortsAndFilters.DateFilter) {
            vm.sortsAndFilters.DateFilters = [];
        }
    }
}