import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, ownershipGroup, groupItem, helperService, ownershipGroupsDataService, ownershipGroupOwnerDataService) {
    const vm = this;

    vm.saveOwner = saveOwner;
    vm.selectedOwnerTypeChanged = selectedOwnerTypeChanged;
    vm.cancel = cancel;

    vm.ownershipGroup = { ...ownershipGroup };
    vm.groupItem = groupItem;

    activate();

    function activate() {
        vm.selectedOwnerType = 'Users';
        getOwners();
    }

    function selectedOwnerTypeChanged() {
        vm.selectedOwners = null;
        getOwners();
    }

    async function getOwners() {
        vm.ownersDataSourceLoading = true;
        const { data } = await ownershipGroupOwnerDataService.getAvailableOwnersForItem(ownershipGroup.Id, groupItem.Id, ownershipGroup.GroupType);
        vm.availableOwnersDataSource = data;
        vm.availableOwnersDataSource = vm.availableOwnersDataSource.filter(owner => owner.TypeName === (vm.selectedOwnerType === 'Users' ? 'User' : 'Approval Group'));
        vm.ownersDataSourceLoading = false;
        return vm.availableOwnersDataSource;
    }

    async function saveOwner() {
        vm.savePending = new createSpinner();

        let data = {
            Owners: vm.selectedOwners
        }

        try {
            await ownershipGroupsDataService.addOwnersToGroupObject(ownershipGroup.Id, ownershipGroup.GroupType, groupItem.Id, data);
            await helperService.successfulSaveButton(vm.savePending);
            close();
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function close() {
        $uibModalInstance.close();
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}