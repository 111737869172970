import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, maintainSystemUsersDataService, helperService, user, modalType) {

    const vm = this;

    vm.dismiss = $uibModalInstance.dismiss;
    vm.saveUser = saveUser;
    vm.modalType = modalType;
    vm.importUsers = importUsers;
    vm.user = { ...user };

    //Save User
    async function saveUser() {
        vm.saving = new createSpinner();
        const putData = {
            "Department": vm.user.Department,
            "BusinessUnit": vm.user.BusinessUnit,
            "Location": vm.user.Location,
            "JobTitle": vm.user.JobTitle
        }

        try {
            await maintainSystemUsersDataService.updateUserIdentity(vm.user.ID, putData);
            await helperService.successfulSaveButton(vm.saving);
            $uibModalInstance.close();
        } catch {
            vm.saving.loadingValue = false;
        }
    }

    // Import User
    async function importUsers(file) {
        vm.saving = new createSpinner();
        let fd = new FormData();
        fd.append(file[0].name, file[0].file);
        try {
            await maintainSystemUsersDataService.importSystemUsers(fd);
            await helperService.successfulSaveButton(vm.saving);
            $uibModalInstance.close();
        } catch {
            vm.saving.loadingValue = false;
        }
    }
}