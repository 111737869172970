import groupItemOwnerModalController from "./groupItemOwnerModal.controller";

export default /*@ngInject*/ function (ownershipGroupOwnerDataService, ownershipGroupItemsDataService, $uibModal) {

    const vm = this;

    vm.openGroupItemOwnerModal = openGroupItemOwnerModal;
    vm.removeOwnerFromGroupItem = removeOwnerFromGroupItem;

    vm.$onChanges = activate;

    async function activate() {
        await setOwners();
        vm.ownersRadioFilter = null;
    }

    async function setOwners() {
        vm.ownersLoading = true;
        const { data } = await ownershipGroupOwnerDataService.getGroupItemOwners(vm.selectedOwnershipGroup, vm.selectedGroupItem);
        vm.owners = data;
        vm.ownersLoading = false;
    }

    async function openGroupItemOwnerModal() {
        let ownerModal = $uibModal.open({
            templateUrl: '/App/Components/OwnershipGroups/groupItemOwnersList/groupItemOwnerModal.tmpl.html',
            controller: groupItemOwnerModalController,
            controllerAs: 'giomvm',
            backdrop: 'static',
            resolve: {
                ownershipGroup: () => vm.selectedOwnershipGroup,
                groupItem: () => vm.selectedGroupItem
            }
        });

        try {
            await ownerModal.result;
            setOwners();
        } catch { return; }
    }

    async function removeOwnerFromGroupItem(owner) {

        let putData = {
            Owners: [owner]
        }

        try {
            await ownershipGroupItemsDataService.removeOwnerFromOwnershipGroupItem(vm.selectedOwnershipGroup, vm.selectedGroupItem, putData);
            setOwners();
        } catch {
            return;
        }
    }
}