import RowSettingsTemplateController from "./rowSettingsTemplate.controller";
/* rowSettingsTemplate.component.js */

/**
 * @desc component for adding tickets in Data Changes reports
 * @example <row-settings-template></row-settings-template>
 */

const rowSettingsTemplate = {
    bindings: {
        params: '<',
        selectedProduct: '<',
        onDelete: '&'
    },
    controller: RowSettingsTemplateController,
    controllerAs: 'rstvm',
    templateUrl: '/App/Shared/Components/rowSettingsTemplate/rowSettingsTemplate.tmpl.html'
};

export default rowSettingsTemplate;