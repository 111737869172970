export default /*@ngInject*/ function (helperService) {
    const factory = {
        generateBody
    };

    return factory;

    function generateBody(objectId, productId, roles, businessProcesses, reportId, securityObject, rulesetId) {

        var selectedRoles;

        var result = helperService.returnNullIfAllIsFound(roles.map(role => role.ID));
        if (result) {
            selectedRoles = roles.map(role => role.Identifier);
        } else {
            selectedRoles = null;
        }
        var businessProcessesSelected = helperService.returnNullIfAllIsFound(businessProcesses.map(function (el) { return el.Id; }));

        if (businessProcessesSelected) {
            businessProcessesSelected = businessProcesses.map(bp => bp.Id);
        }

        var data = {
            "ObjectId": objectId,
            "ProductIds": productId,
            "Roles": selectedRoles,
            "BusinessProcessIds": businessProcessesSelected,
            "ReportId": reportId,
            "SecurityObject": securityObject,
            "RulesetId": rulesetId
        };

        return data;
    }

}