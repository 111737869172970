export default /*@ngInject*/ function ($http, $q, RequestManagementSAPService) {

    this.loadSAP = function ($scope, application) {
        //Load Data for Products after search row is selected
        var sapDfrd = $.Deferred();

        if (!$scope.vm.User.SAPUser) {
            // Need to set up the User Objects for each SAP instance
            $scope.vm.User.SAPUser = {};
        }

        if (!$scope.vm.User.SAPUser[application.ProductName]) {
            $scope.vm.User.SAPUser[application.ProductName] = {};
            $scope.vm.User.SAPUser[application.ProductName].ProductType = application.ProductType;
        }

        loadPermissions($scope, application).done(function () {
            sapDfrd.resolve();
        });

        return $.when(sapDfrd).done(function () {
        }).promise();
    };

    this.loadSAPRoleObjects = async function ($scope, application) {
        $scope.vm.loadingSAPRoleObjects = true;
        let data = [], compositeRoleData, compositeRoleIds, response;
        $scope.vm.ListOfSelectedSAPSingleRoles[application.ProductName].forEach(role => data.push(role.Identifier));
        // get role ids from composite roles because we can't get tCodes based on Composite Role Identifiers
        compositeRoleData = {
            ProductId: application.ProductID,
            CompositeRoleIds: $scope.vm.ListOfSelectedSAPCompositeRoles[application.ProductName].map(compositeRole => compositeRole.Identifier)
        };
        compositeRoleIds = await RequestManagementSAPService.getRolesByCompositeRole(compositeRoleData);
        // add the ids for the roles that come from the composite roles
        data = data.concat(compositeRoleIds.data.map(compRole => compRole.RoleIdentifier));

        response = await $http.post(`${apiUrl}api/universal/securityobjects/securityroles`, data);
        response.data = response.data.filter(role => role.SecurityObjectTypeId === "TCode");

        $scope.vm.ListOfAvailableSAPRoleObjects[application.ProductName] = response.data;
        $scope.vm.ListOfSelectedSAPRoleObjects[application.ProductName] = [];

        $scope.vm.loadingSAPRoleObjects = false;
    };

    var loadPermissions = function ($scope, application) {
        var permissionsDfrd = $.Deferred(),
            promises = [],
            sapRoles = [],
            sapCompositeRoles = [],
            pendingRoles = $scope.vm.User?.SAPUser?.[application.ProductName]?.PendingRoles || [];

        if ($scope.vm.User.SAPUser && $scope.vm.User.SAPUser[application.ProductName] && $scope.vm.User.SAPUser[application.ProductName].ProductName === application.ProductName) {
            if ($scope.vm.alternatePath === 'EmergencyAccess') {
                $scope.vm.User.SAPUser[application.ProductName].Roles = $scope.vm.User.SAPUser[application.ProductName].Roles.filter(role => role.IsEmergencyAccess);
            }
            sapRoles = $scope.vm.User.SAPUser[application.ProductName].Roles.filter(role => !role.IsComposite);
            sapCompositeRoles = $scope.vm.User.SAPUser[application.ProductName].Roles.filter(compositeRole => compositeRole.IsComposite);
        }

        //Load Available sapRoles then process existing security
        promises.push(RequestManagementSAPService.getAllRoles(application.ProductID).then(function (response) {
            if ($scope.vm.alternatePath === 'EmergencyAccess') {
                // filter out roles that aren't emergency access designated
                response.data = response.data.filter(role => role.IsEmergencyAccess);
                // remove any emergency access roles the user already has from available roles
                if (response.data.length > 0 && $scope.vm.User.SAPUser[application.ProductName].Roles.length > 0) {
                    response.data.forEach((role, index, allRolesArray) => {
                        if ($scope.vm.User.SAPUser[application.ProductName].Roles.some(r => r.Identifier === role.Identifier)) {
                            allRolesArray.splice(index, 1);
                        }
                    });
                }
            }
            // filter out composite roles to create a list of single roles
            $scope.vm.ListOfAvailableSAPSingleRoles[application.ProductName] = response.data.filter(item => !item.IsComposite).map(applyPending.bind(null, pendingRoles));
            $scope.vm.ListOfSelectedSAPSingleRoles[application.ProductName] = [];

            // filter out single roles to create a list of composite roles
            $scope.vm.ListOfAvailableSAPCompositeRoles[application.ProductName] = response.data.filter(item => item.IsComposite).map(applyPending.bind(null, pendingRoles));

            $scope.vm.ListOfSelectedSAPCompositeRoles[application.ProductName] = [];

        }, function errorCallback(response) {
            permissionsDfrd.reject();
        }));


        $q.all(promises).then(function () {
            if ($scope.defaultRoleTemplate && $scope.vm.wizardMode === "New" && !$scope.vm.requestToEdit) {
                let defaultRoles = $scope.defaultRoleTemplate.Roles.filter(role => role.TypeId === "Role" && role.ProductType === application.ProductType);

                sapRoles = [...defaultRoles];
                sapCompositeRoles = [...defaultRoles];

                $scope.vm.User.SAPUser = {
                    [application.ProductName]: {
                        Roles: sapRoles
                    }
                }
            }

            // Single Roles
            if (sapRoles.length > 0 && $scope.vm.alternatePath !== 'EmergencyAccess') {
                if ($scope.vm.User.SAPUser[application.ProductName] && $scope.vm.User.SAPUser[application.ProductName].Roles) {
                    $scope.vm.User.SAPUser[application.ProductName].Roles.forEach(role => {
                        var sapRole = null;

                        if ($scope.vm.ListOfAvailableSAPSingleRoles[application.ProductName]) {
                            //Get the role information from the available sapRoles list
                            sapRole = $scope.vm.ListOfAvailableSAPSingleRoles[application.ProductName].find(object => object.Id === role.Id);

                            if (sapRole) {
                                $scope.vm.ListOfSelectedSAPSingleRoles[application.ProductName].unshift({
                                    Id: sapRole.Id,
                                    Name: sapRole.Name,
                                    IsAssignable: sapRole.IsAssignable,
                                    Identifier: sapRole.Identifier,
                                    IsComposite: sapRole.IsComposite,
                                    Pending: pendingRoles.some(pendingRole => pendingRole.Id === sapRole.Id && !sapRole.IsComposite)
                                });

                                $scope.vm.ListOfAvailableSAPSingleRoles[application.ProductName].forEach(function (row, j) {
                                    if (row.Id === sapRole.Id) {
                                        $scope.vm.ListOfAvailableSAPSingleRoles[application.ProductName].splice(j, 1);
                                    }
                                });
                            }

                            sapRole = null;
                        }
                    });
                }
            }

            // Composite Roles
            if (sapCompositeRoles.length > 0 && $scope.vm.alternatePath !== 'EmergencyAccess') {
                if ($scope.vm.User.SAPUser[application.ProductName] && $scope.vm.User.SAPUser[application.ProductName].Roles) {
                    $scope.vm.User.SAPUser[application.ProductName].Roles.forEach(function (role) {

                        var sapCompositeRole = null;

                        if ($scope.vm.ListOfAvailableSAPCompositeRoles[application.ProductName]) {
                            //Get the role information from the available sapCompositeRoles list
                            sapCompositeRole = $scope.vm.ListOfAvailableSAPCompositeRoles[application.ProductName].find(object => object.Id === role.Id);

                            if (sapCompositeRole) {
                                $scope.vm.ListOfSelectedSAPCompositeRoles[application.ProductName].unshift({
                                    Id: sapCompositeRole.Id,
                                    Name: sapCompositeRole.Name,
                                    IsAssignable: sapCompositeRole.IsAssignable,
                                    IsComposite: sapCompositeRole.IsComposite,
                                    Pending: pendingRoles.some(pendingRole => pendingRole.Id === sapCompositeRole.Id && sapCompositeRole.IsComposite)
                                });


                                $scope.vm.ListOfAvailableSAPCompositeRoles[application.ProductName].forEach(function (row, j) {
                                    if (row.Id === sapCompositeRole.Id) {
                                        $scope.vm.ListOfAvailableSAPCompositeRoles[application.ProductName].splice(j, 1);
                                    }
                                });
                            }

                            sapCompositeRole = null;
                        }
                    });
                }
            }

            $scope.vm.ListOfAvailableSAPSingleRoles[application.ProductName] = $scope.vm.ListOfAvailableSAPSingleRoles?.[application.ProductName]?.filter(role => role.IsAssignable) || [];
            $scope.vm.ListOfAvailableSAPCompositeRoles[application.ProductName] = $scope.vm.ListOfAvailableSAPCompositeRoles?.[application.ProductName]?.filter(compositeRole => compositeRole.IsAssignable) || [];
            $scope.sapUserLoaded = true;
            permissionsDfrd.resolve();
        });

        return $.when(permissionsDfrd).done(function () {
        }).promise();
    };
}

function applyPending(pendingItems, item) {
    if (pendingItems.length) {
        item.Pending = pendingItems.some(pendingItem => pendingItem.Id === item.Id);
    }

    return item;
}