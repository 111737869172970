/* armProductConfiguration.component.js */

import armProductConfigurationController from "./armProductConfiguration.controller";

/**
 * @desc component for configuring product specific settings for Access Control
 * @example <arm-product-configuration></arm-product-configuration>
 */

const armProductConfiguration = {
    bindings: {
        product: '<',
    },
    controller: armProductConfigurationController,
    controllerAs: 'apcvm',
    templateUrl: '/App/Components/Configuration/armProductConfiguration/armProductConfiguration.tmpl.html'
};

export default armProductConfiguration;