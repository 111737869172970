/* excludedRoles.component.js */

import excludedRolesController from "./excludedRoles.controller";

/**
 * @desc component to display view and Excluded Roles
 * @example <excluded-roles></excluded-roles>
 */

const excludedRoles = {
    controller: excludedRolesController,
    controllerAs: 'ervm',
    templateUrl: '/App/Components/ExcludedRoles/excludedRoles.html'
};

export default excludedRoles;