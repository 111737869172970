/* maintainSystemUsers.component.js */

/**
 * @desc component to display Maintain System Users place
 * @example <maintain-system-users></maintain-system-users>
 */

const maintainSystemUsers = {
    template: `
        <div>
        <page-header></page-header>
            <div class="row wrapper-content">
                <div class="col-lg-12">
                     <div class="row">
                        <maintain-system-users-grid></maintain-system-users-grid>
                     </div>
                </div>
            </div>
        </div>`
};

export default maintainSystemUsers;