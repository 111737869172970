import selectCompaniesByTemplateController from "./selectCompaniesByTemplate.controller";

/* selectCompaniesByTemplate.component.js */

/**
 * @desc component for selecting template from list of company templates and getting companies from the template
 * @example <select-companies-by-template></select-companies-by-template>
 */

const selectCompaniesByTemplate = {
    bindings: {
        onSelectedTemplate: '&'
    },
    controller: selectCompaniesByTemplateController,
    controllerAs: 'scbtvm',
    templateUrl: '/App/Shared/Components/SelectCompaniesByTemplate/selectCompaniesByTemplate.tmpl.html'
};

export default selectCompaniesByTemplate;