export default /*@ngInject*/ function ($timeout, authenticationService) {

    this.stateChanged = function (state, hub, hubName) {
        switch (state.newState) {
            case $.signalR.connectionState.connecting:
                console.log('Connecting to ' + hubName + ' Hub...');
                break;
            case $.signalR.connectionState.connected:
                console.log('Connected to ' + hubName + ' Hub');
                break;
            case $.signalR.connectionState.reconnecting:
                console.log('Attempting to reconnect to ' + hubName + ' Hub...');
                break;
            case $.signalR.connectionState.disconnected:
                $timeout(function () {
                    hub.connection.start();
                }, 5000);

                break;
        }
    };

    this.populateQueryParams = async function () {

        var selectedTenantId = null;
        var selectedTenant = localStorage.getItem("selectedTenant");
        if (selectedTenant) {
            selectedTenant = JSON.parse(selectedTenant);
            selectedTenantId = selectedTenant.ID;
        }

        const currentUser = await authenticationService.getUser();
        if (currentUser) {
            return {
                'tenantId': selectedTenantId,
                'userId': currentUser.userId
            };
        }
    };

}
