import createSpinner from "../../Functions/createSpinner";

export default /*@ngInject*/ function (applicationInsightsService, $http, helperService, errorsDataService) {

    const vm = this;

    vm.form = {};
    vm.form.filterType = "0";

    vm.saveFilter = saveFilter;
    vm.cancel = cancel;

    async function saveFilter() {
        if (vm.userForm.$valid) {
            vm.pleaseWait = new createSpinner();
            const Name = vm.user.name;
            const Description = vm.user.description;
            const FilterType = vm.form.filterType;
            const ReportId = vm.resolve.reportId ? vm.resolve.reportId : vm.resolve.objectId;
            const FilterList = helperService.halPrepareFilterArray(vm.resolve.grid.getOptions().dataSource.filter);

            const filterSaveObject = {
                Name: Name,
                Description: Description,
                Filters: FilterList,
                Type: FilterType,
                ReportId: ReportId
            };

            applicationInsightsService.trackEvent('SaveFilter', { Name: vm.reportName, ObjectId: vm.objectId });

            try {
                await $http.post(apiUrl + 'api/core/userreportfilters', filterSaveObject);
                await helperService.successfulSaveButton(vm.pleaseWait);
                vm.modalInstance.close();
                vm.resolve.filtersDataSource.read();
            } catch (err) {
                vm.pleaseWait.loadingValue = false;
                errorsDataService.catch(err);
            }
        }
    }

    function cancel() {
        vm.modalInstance.close();
    }
}