import { standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, existingUserSearchService, $rootScope, $filter, helperService, userObject, aDUserObjectValueMapperService) {
    const vm = this;

    vm.title = 'Active Directory User Lookup';
    vm.filterUsersToggle = true;
    vm.selectedUser = null;

    vm.cancel = cancel;
    vm.doneSearchingUsers = doneSearchingUsers;
    vm.selectUser = selectUser;
    vm.filterUsers = filterUsers;
    vm.changeExistingUserRowSelected = changeExistingUserRowSelected;

    if (!userObject) {
        userObject = {};
    }

    const userGridColumns = [
        { field: "DisplayName", title: "User Name", filterable: false },
        { field: "Mail", title: "Email", filterable: false }
    ];

    function doneSearchingUsers() {

        $rootScope.IMSelectedExistingUser = null;

        if (vm.ExistingWindowsUserSearchString === '' || vm.ExistingWindowsUserSearchString === null) {
            vm.ExistingusersGridDataSource.data([]);
        } else {
            vm.ExistingusersGridDataSource.read();
        }
    }

    vm.ExistingUsersGridOptions = helperService.setMainGridOptions(userGridColumns, 350);
    vm.ExistingUsersGridOptions.autoBind = false;

    vm.ExistingusersGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: async (options) => {
                try {
                    let { data: existingUsers } = await existingUserSearchService.getFilteredADUsers(vm.ExistingWindowsUserSearchString, vm.filterUsersToggle);
                    existingUsers = $filter('orderBy')(existingUsers, 'DisplayName');
                    options.success(existingUsers);
                } catch (err) {
                    options.error();
                }
            }
        }
    });

    async function selectUser() {
        let emailMappingField, mappedObject, emailMapConfig = await helperService.getConfigurationValue('IM_ADFieldForEmailMapping');
        emailMappingField = emailMapConfig === '0' ? 'UserPrincipalName' : 'Mail';
        vm.selectedUser.ProductType = userObject.ProductType; // Add productType for value mapper to map properly
        mappedObject = aDUserObjectValueMapperService.valueMap(vm.selectedUser, emailMappingField); // convert selectedUser properties for form models
        userObject = Object.assign(userObject, mappedObject); // add mapped properties back into userObject

        vm.ExistingWindowsUserSearchString = null;
        $uibModalInstance.close(userObject);
    }

    function changeExistingUserRowSelected(kendoEvent) {
        let grid = kendoEvent.sender;
        vm.selectedUser = grid.dataItem(grid.select());
    }

    function filterUsers() {
        if (vm.ExistingusersGridDataSource._data.length > 0) {
            vm.ExistingusersGridDataSource.read();
        }
    }

    function cancel() { $uibModalInstance.dismiss(); }
}