import angular from 'angular';
import criteriaSubmitButtonDropdown from './Components/criteriaSubmitButtonDropdown/criteriaSubmitButtonDropdown.component';
import roleMultiselectDropdown from './Components/roleMultiselectDropdown/roleMultiselectDropdown.component';
import pageHeader from './Components/pageHeader/pageHeader.component';
import manageSavedLayouts from './Components/ManageSavedLayouts/manageSavedLayouts.component';
import selectRolesByUser from './Components/selectRolesByUser/selectRolesByUser.component';
import selectRolesByRoleTemplate from './Components/selectRolesByRoleTemplate/selectRolesByRoleTemplate.component';
import reportToolbar from './Components/ReportToolbar/reportToolbar.component';
import tableFieldsModal from './Components/TableFieldsModal/tableFieldsModal.component';
import rowSettingsTemplate from './Components/rowSettingsTemplate/rowSettingsTemplate.component';
import reportFilterManager from './Components/ReportFilterManager/reportFilterManager.component';
import signReport from './Components/SignReport/signReport.component';
import saveReport from './Components/SaveReport/saveReport.component';
import scheduleReport from './Components/ScheduleReport/scheduleReport.component';
import saveReportFilter from "./Components/SaveReportFilter/saveReportFilter.component";
import imRoleCompanies from './Components/identityManagerRoleCompanies/editRoleCompanies.component';
import selectCompaniesByTemplate from './Components/selectCompaniesByTemplate/selectCompaniesByTemplate.component';
import dataChangesImport from './Components/DataChangesImport/dataChangesImport.component';
import backButton from './Components/backButton/backButton.component';
import staticPageHeader from './Components/staticPageHeader/staticPageHeader.component'
import userMultiselectDropdown from './Components/userMultiselectDropdown/userMultiselectDropdown.component';
import myInbox from '../Components/MyInbox/myInbox.component';
import imRoleOrgHierarchies from './Components/imRoleOrgHierarchies/editRoleOrgHierarchies.component';
import userProfile from './Components/userProfile/userProfile.component';
import userDetailsChanges from './Components/userDetailsChanges/userDetailsChanges.component';
import userProductProfile from './Components/userProfile/userProductProfile/userProductProfile.component';
import accessDetailsProfile from './Components/accessDetailsProfile/accessDetailsProfile.component';
import attachmentsDownload from './components/Attachments/attachmentsDownload.component'
import riskProductProfile from './Components/accessDetailsProfile/riskDetailsProductProfile/riskProductProfile.component';
import businessProcessProductProfile from './Components/accessDetailsProfile/businessProcessDetailsProductProfile/businessProcessProductProfile.component';
import ownershipGroupAssignments from './Components/ownershipGroupAssignments/ownershipGroupAssignments.component';

export default angular
    .module('app.shared.components', [])
    .component('criteriaSubmitButtonDropdown', criteriaSubmitButtonDropdown)
    .component('roleMultiselectDropdown', roleMultiselectDropdown)
    .component('userMultiselectDropdown', userMultiselectDropdown)
    .component('pageHeader', pageHeader)
    .component('manageSavedLayouts', manageSavedLayouts)
    .component('selectRolesByRoleTemplate', selectRolesByRoleTemplate)
    .component('selectRolesByUser', selectRolesByUser)
    .component('reportToolbar', reportToolbar)
    .component('tableFieldsModal', tableFieldsModal)
    .component('rowSettingsTemplate', rowSettingsTemplate)
    .component('reportFilterManager', reportFilterManager)
    .component('signReport', signReport)
    .component('saveReport', saveReport)
    .component('scheduleReport', scheduleReport)
    .component('saveReportFilter', saveReportFilter)
    .component('imRoleCompanies', imRoleCompanies)
    .component('selectCompaniesByTemplate', selectCompaniesByTemplate)
    .component('netsuiteDataChangesImport', dataChangesImport)
    .component('backButton', backButton)
    .component('staticPageHeader', staticPageHeader)
    .component('myInbox', myInbox)
    .component('imRoleOrgHierarchies', imRoleOrgHierarchies)
    .component('userDetailsChanges', userDetailsChanges)
    .component('userProfile', userProfile)
    .component('userProductProfile', userProductProfile)
    .component('accessDetailsProfile', accessDetailsProfile)
    .component('attachmentsDownload', attachmentsDownload)
    .component('riskProductProfile', riskProductProfile)
    .component('businessProcessProductProfile', businessProcessProductProfile)
    .component('ownershipGroupAssignments', ownershipGroupAssignments)
    .name;