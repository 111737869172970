import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, dataService, supervisorAssignmentsDataService, helperService, $filter) {

    const vm = this;

    vm.createSupervisorAssignments = createSupervisorAssignments;
    vm.cancel = cancel;

    activate();

    function activate() {
        getUsers();
        getSupervisors();
    }

    async function getUsers() {
        vm.usersDropdownLoading = true;
        try {
            let response = await dataService.getUserMaster();
            vm.userDataSource = response.data;

            vm.userDataSource = $filter('orderBy')(vm.userDataSource, 'Name');
            vm.usersDropdownLoading = false;
        } catch {
            vm.usersDropdownLoading = false;
        }
    }

    async function getSupervisors() {
        vm.supervisorsDropdownLoading = true;
        try {
            let response = await dataService.getUsers();
            vm.supervisorsDataSource = response.data;
            vm.supervisorsDataSource = vm.supervisorsDataSource.filter(u => u.Active);
            vm.supervisorsDataSource = $filter('orderBy')(vm.supervisorsDataSource, 'UserLogonName');
            vm.supervisorsDropdownLoading = false;
        } catch {
            vm.supervisorsDropdownLoading = false;
        }
    }

    async function createSupervisorAssignments() {

        vm.createAssignmentPending = new createSpinner();
        let data = {
            'UserMasterId': vm.selectedUser.ID,
            'SupervisorId': vm.selectedSupervisor.UserID
        };

        try {
            await supervisorAssignmentsDataService.createSupervisorAssignment(data);
            await helperService.successfulSaveButton(vm.createAssignmentPending);
            close();
        } catch {
            vm.createAssignmentPending.loadingValue = false;
        }
    }

    function close() {
        $uibModalInstance.close(true);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }

}