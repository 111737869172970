/* reviewScheduleInstancesList.component.js */

import reviewScheduleInstancesListController from "./reviewScheduleInstancesList.controller";

/**
 * @desc component for viewing and managing access certifications review schedule and assignment items
 * @example <review-schedule-instances-list></review-schedule-instances-list>
 */

const reviewScheduleInstancesList = {
    bindings: {
        onSelectSchedule: '&'
    },
    controller: reviewScheduleInstancesListController,
    controllerAs: 'rsilvm',
    templateUrl: '/App/Components/Certifications/reviewSchedules/reviewScheduleInstancesList/reviewScheduleInstancesList.tmpl.html'
};

export default reviewScheduleInstancesList;