import ErrorsDataService from "../../../APICalls/ErrorsDataService";

export default /*@ngInject*/ function ($http, errorsDataService) {
    var service = {
        getUsers,
        getRolesByUserId,
        getRolesByUserMasterId
    };

    return service;

    async function getUsers(productId) {
        try {
            return await $http.get(`${apiUrl}api/universal/users/product/${productId}`);
        } catch (err) {
            showError(err);
        }
    }

    async function getRolesByUserId(productId, userId, excludeRoles) {
        try {
            let response = await $http.get(`${apiUrl}api/universal/usersecurityroles`, { params: { 'productId': productId, 'userId': userId } });
            if (excludeRoles) {
                response.data = response.data.filter(r => r.IsExcluded == false);
            }
            return response;
        } catch (err) {
            showError(err);
        }
    }

    async function getRolesByUserMasterId(userMasterId, excludeRoles) {
        try {
            let response = await $http.get(`${apiUrl}api/universal/usersecurityroles/usermaster/${userMasterId}`);
            if (excludeRoles) {
                response.data = response.data.filter(r => r.IsExcluded == false);
            }
            return response;
        } catch (err) {
            showError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

}