export default /*@ngInject*/ function(usSpinnerConfigProvider) {
    usSpinnerConfigProvider.setTheme('medBlack', {
        lines: 9, length: 10, width: 4, radius: 10, color: 'black', opacity: 0.25, speed: 1.2, trail: 60, position: 'absolute', top: '20%', left: '50%'
    });

    usSpinnerConfigProvider.setTheme('medWhite', {
        lines: 9, length: 10, width: 4, radius: 10, color: 'white', opacity: 0.25, speed: 1.2, trail: 60, position: 'absolute', top: '20%', left: '50%'
    });
    usSpinnerConfigProvider.setTheme('largeWhite', {
        lines: 9, length: 14, width: 7, radius: 16, color: 'white', opacity: 0.25, speed: 1.2, trail: 60, position: 'absolute', top: '20%', left: '50%'
    });

    usSpinnerConfigProvider.setTheme('largeBlack', {
        lines: 9, length: 14, width: 7, radius: 16, color: 'black', opacity: 0.25, speed: 1.2, trail: 60, position: 'absolute', top: '20%', left: '50%'
    });

    usSpinnerConfigProvider.setTheme('smallWhite', {
        lines: 9, length: 4, width: 2, radius: 4, scale: 1, color: 'white', opacity: 0.25, speed: 1.2, trail: 60, position: 'absolute'
    });

    usSpinnerConfigProvider.setTheme('smallBlack', {
        lines: 9, length: 4, width: 2, radius: 4, scale: 1, color: 'black', opacity: 0.25, speed: 1.2, trail: 60, position: 'absolute'
    });

    usSpinnerConfigProvider.setTheme('smallDarkGray', {
        lines: 9, length: 10, width: 8, radius: 14, scale: 0.25, color: '#2e3238', opacity: 0.25, speed: 1.2, trail: 60, position: 'absolute'
    });
}
