import { customAutoCompleteFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import createSupervisorModalController from "./Create/createSupervisorModal.controller";
import ImportSupervisorModalController from "./Import/importSupervisorModal.controller";
import swal from "sweetalert2";

export default /*@ngInject*/ function ($scope, $uibModal, $filter, helperService, supervisorAssignmentsDataService, $http) {

    const vm = this;

    vm.config = {};
    vm.origConfig = {};

    vm.activate = activate;
    vm.openImportSupervisorsModal = openImportSupervisorsModal;
    vm.openCreateSupervisorsModal = openCreateSupervisorsModal;
    vm.exportSupervisorReviews = exportSupervisorReviews;
    vm.deleteSupervisorAssignment = deleteSupervisorAssignment;

    vm.$onInit = activate;

    $scope.$on('AutoGenerateSupervisorAssignments', function (event, args) {
        vm.supervisorAssignmentsGridDataSource.read();
    });

    function activate() {
        checkIfHcmExists();
        createGrid();
    }

    async function checkIfHcmExists() {
        let response = await $http.get(`${apiUrl}/api/core/plugins`);
        vm.hcmExists = response.data.some(plugin => plugin.IsHcm);
    }

    function createGrid() {
        const supervisorAssignmentsGridColumns =
            [
                { field: "UserMasterName", title: "User", filterable: customAutoCompleteFilter },
                { field: "UserMasterEmail", title: "User Email", filterable: customAutoCompleteFilter },
                { field: "SupervisorName", title: "Supervisor", filterable: customAutoCompleteFilter },
                { field: "SupervisorEmail", title: "Supervisor Email", filterable: customAutoCompleteFilter },
                { template: "<div class=\"center-grid-icon\"><a ng-click=\"savm.deleteSupervisorAssignment(dataItem)\"><i class=\"glyphicon glyphicon-trash grid-trash-icon\"></i></a></div>", width: 70 }
            ];

        vm.supervisorAssignmentsGridOptions = helperService.setMainGridOptions(supervisorAssignmentsGridColumns, 400);
        vm.supervisorAssignmentsGridOptions.selectable = false;

        vm.supervisorAssignmentsGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async (options) => {
                    let response = await supervisorAssignmentsDataService.getSupervisorAssignments();
                    response.data = $filter('orderBy')(response.data, 'ManagerName');
                    vm.existingUserAssignments = response.data.map(user => user.UserMasterId);
                    options.success(response.data);
                }
            }

        });
    }

    //Update successful inline saver
    $scope.$on('ImportSupervisorAssignments', function (event, args) {
        vm.supervisorAssignmentsGridDataSource.read();
    });

    function openImportSupervisorsModal() {
        $uibModal.open({
            templateUrl: '/App/Components/SupervisorAssignments/Import/importSupervisorAssignmentModal.html',
            controller: ImportSupervisorModalController,
            controllerAs: 'isvm',
            backdrop: 'static'
        });
    }

    async function openCreateSupervisorsModal() {
        let createModal = $uibModal.open({
            templateUrl: '/App/Components/SupervisorAssignments/Create/createSupervisorAssignmentModal.html',
            controller: createSupervisorModalController,
            controllerAs: 'csvm',
            backdrop: 'static',
            resolve: {
                existingUserAssignments: () => vm.existingUserAssignments
            }
        });

        try {
            await createModal.result;
            vm.supervisorAssignmentsGridDataSource.read();
        } catch {
            return;
        }
    }

    async function exportSupervisorReviews() {
        let response = await supervisorAssignmentsDataService.exportSupervisorAssignments();
        response.data = { ExportId: response.data };
    }

    async function deleteSupervisorAssignment(dataItem) {
        try {
            await swal(helperService.areYouSureParams('Delete Assignment', 'Are you sure you want to this assignment?', 'Delete'));
            kendo.ui.progress(vm.supervisorAssignmentsGrid.element, true);
            await supervisorAssignmentsDataService.deleteSupervisorAssignment(dataItem.UserMasterId, dataItem.SupervisorId);
            kendo.ui.progress(vm.supervisorAssignmentsGrid.element, false);
            vm.supervisorAssignmentsGridDataSource.read();
        } catch (dismiss) {
            return;
        }
    }

}