import { customAutoCompleteFilter, customBooleanFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import swal from "sweetalert2";
import addMitigationRuleModalController from "./addMitigationRuleModal.controller";

export default /*@ngInject*/ function ($filter, $uibModal, helperService, peoplesoftAutoMitigationsDataService) {

    const vm = this;

    vm.openAutoMitigationModal = openAutoMitigationModal;
    vm.removeMitigation = removeMitigation;
    vm.applyAutoMitigationConfirmation = applyAutoMitigationConfirmation;

    activate();

    function activate() {
        var autoMitigationRulesGridColumns = [
                { field: "AutoMitigationObjectType", title: "Auto Mitigation Object Type", filterable: customAutoCompleteFilter },
                { field: "AutoMitigationObjectName", title: "Auto Mitigation Object Name", filterable: customAutoCompleteFilter },
                { field: "FromObjectType", title: "From Object Type", filterable: customAutoCompleteFilter },
                { field: "FromObjectName", title: "From Object Name", filterable: customAutoCompleteFilter },
                { field: "Description", title: "Description", filterable: customAutoCompleteFilter },
                { field: "Enabled", title: "Enabled", width: 125, filterable: customBooleanFilter },
                { template: `<a ng-click="amvm.openAutoMitigationModal(dataItem)"><i class="glyphicon glyphicon-edit text-warning m-r-xs"></i>Edit</a>`, "title": "Edit", width: 90 },
                { template: `<a ng-click="amvm.removeMitigation(dataItem)"><i class="glyphicon glyphicon-trash text-danger m-r-xs"></i>Remove</a>`, "title": "Remove", width: 90 }
            ];

        vm.autoMitigationRulesGridOptions = helperService.setMainGridOptions(autoMitigationRulesGridColumns, 275);
        vm.autoMitigationRulesGridOptions.selectable = false;

        vm.autoMitigationRulesGridDatasource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: {
                model: {
                    fields: {
                        Enabled: { type: "boolean" }
                    }
                }
            },
            transport: {
                read: async (options) => {
                    let { data } = await peoplesoftAutoMitigationsDataService.getAutoMitigations();

                    data = $filter('orderBy')(data, 'Name');
                    options.success(data);
                }
            }
        });
    }

    async function applyAutoMitigationConfirmation() {
        await swal(helperService.areYouSureParams('Apply Auto Mitigation', 'This process will apply the defined Auto Mitigation Rules to the user conflicts that have not already been mitigated.', 'Apply'));

        await peoplesoftAutoMitigationsDataService.applyAutoMitigations();
    }

    //Update AutoMitigation
    async function openAutoMitigationModal(autoMitigation) {
        let openModal = $uibModal.open({
            templateUrl: '/App/Components/Peoplesoft/autoMitigations/addMitigationRuleModal.html',
            controller: addMitigationRuleModalController,
            controllerAs: 'amrmvm',
            backdrop: 'static',
            resolve: {
                autoMitigation: () => autoMitigation || {}
            }
        });

        await openModal.result;

        vm.autoMitigationRulesGridDatasource.read();
    }

    async function removeMitigation(dataItem) {
        await peoplesoftAutoMitigationsDataService.removeAutoMitigation(dataItem.RuleId);
        
        vm.autoMitigationRulesGridDatasource.read();
    }
}