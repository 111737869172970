import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (helperService, $filter, incident, $uibModalInstance, incidentManagementDataService) {

    const vm = this;

    vm.saveIncident = saveIncident;
    vm.cancel = cancel;
    vm.incident = incident;


    async function activate() {
        await getStatuses();
    }

    activate();

    async function getStatuses() {
        vm.selectedStatus = { "Id": vm.incident.StatusId, "Name": vm.incident.StatusName };
        let response = await incidentManagementDataService.getStatuses();
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.statusDataSource = response.data.filter(status => status.Name !== 'Closed: Resolved' && status.Name !== 'Closed: Aged Out');
    }

    async function saveIncident() {
        vm.saving = new createSpinner();

        const putData = {
            "Id": vm.incident.IncidentId,
            "Status": vm.selectedStatus.Id,
            "StatusReason": vm.incident.StatusReason
        };

        try {
            await incidentManagementDataService.updateIncident(vm.incident.IncidentId, putData);
            await helperService.successfulSaveButton(vm.saving);
            cancel();
        } catch {
            vm.saving.loadingValue = false;
        }
    }

    function cancel() {
        $uibModalInstance.close();
    }

}