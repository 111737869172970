import hybridController from "./partialHybridConnectionsInformation.controller";

/* partialHybridConnectionsInformation.component.js */

/**
 * @desc component for displaying partial hybrid connection information
 * @example <partial-hybrid-connections-information></partial-hybrid-connections-information>
 */

const partialHybridConnectionsInformation = {
    bindings: {
        hybridConnectionsArray: '<'
    },
    controller: hybridController,
    controllerAs: 'phcivm',
    templateUrl: '/App/Components/Connections/PartialHybridInformation/partialHybridConnectionsInformation.tmpl.html'
};

export default partialHybridConnectionsInformation;