import requesterGroupsListController from "./requesterGroupsList.controller";

/* requesterGroupsList.component.js */

/**
 * @desc component for viewing and maintaining list of requester groups
 * @example <requester-groups-list><requester-groups-list>
 */

const requesterGroupsList = {
    bindings: {
        selectedRequesterGroup: '<',
        onSelectedRequesterGroupChanged: '&',
    },
    controller: requesterGroupsListController,
    controllerAs: 'rglvm',
    templateUrl: '/App/Components/RequesterGroups/requesterGroupsList/requesterGroupsList.tmpl.html'
};

export default requesterGroupsList;