export default /*@ngInject*/ function reportsDataService($http, errorsDataService) {
    const service = {
        getReportMetadata,
        getDataChangesReportMetadata,
        getSetupMetadata,
        getSetupData
    };

    return service;

    async function getReportMetadata(reportId) {
        try {
            return await $http.get(`${apiUrl}api/core/reports/${reportId}/metadata`);
        } catch (err) {
            showError(err);
        }
    }

    async function getDataChangesReportMetadata(productId, isCustom) {
        try {
            if (isCustom) {
                return await $http.get(`${apiUrl}api/audittrail/universal/${productId}/datachanges/metadata`);
            } else {
                return await $http.get(`${apiUrl}api/audittrail/${productId}/datachanges/metadata`);
            }
        } catch (err) {
            showError(err);
        }
    }

    async function getSetupMetadata() {
        try {
            return await $http.get(`${apiUrl}api/audittrail/sf/datachanges/setup-metadata`)
        } catch (err) {
            showError(err);
        }
    }

    async function getSetupData(filterCriteria, params) {
        try {
            return await $http.post(`${apiUrl}api/audittrail/sf/datachanges/setup`, filterCriteria, { params })
        } catch (err) {
            showError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

}