export default /*@ngInject*/ function ($http, errorsDataService) {
    var service = {
        getDocumentation,
        getDocumentationTypes,
        getDocumentationAuthorizedByOptions,
        createDocumentation,
        updateDocumentation,
        deleteDocumentation,
        getDocumentationLinkedChangesList,
        getDocumentationLinkedTicketsList,
        linkChangeTicketToDocumentation,
        deleteChangeTicketDocumentationLink,
        linkChangeToDocumentation,
        deleteChangeDocumentationLink
    };

    return service;

    function getDocumentation() {
        return $http.get(apiUrl + 'api/audittrail/ns/v2/documentation')
            .then(getDocumentationComplete)
            .catch(getDocumentationFailed);

        function getDocumentationComplete(response) {
            return response.data;
        }

        function getDocumentationFailed(error) {
            errorsDataService.catch(error);
        }
    }

    function getDocumentationTypes() {
        return $http.get(apiUrl + 'api/audittrail/ns/v2/optionsets/453D5A39-6F39-4CFE-9DDE-5F21180259FC/values')
            .then(getDocumentationTypesComplete)
            .catch(getDocumentationTypesFailed);

        function getDocumentationTypesComplete(response) {
            return response.data;
        }

        function getDocumentationTypesFailed(error) {
            errorsDataService.catch(error);
        }
    }

    function getDocumentationAuthorizedByOptions() {
        return $http.get(apiUrl + 'api/audittrail/ns/v2/optionsets/4ADF6D38-2716-4D60-B613-3ABED3AC662F/values')
            .then(getDocumentationAuthorizedByOptionsComplete)
            .catch(getDocumentationAuthorizedByOptionsFailed);

        function getDocumentationAuthorizedByOptionsComplete(response) {
            return response.data;
        }

        function getDocumentationAuthorizedByOptionsFailed(error) {
            errorsDataService.catch(error);
        }
    }

    function createDocumentation(data) {
        return $http.post(apiUrl + 'api/audittrail/ns/v2/documentation', data)
            .then(createDocumentationComplete)
            .catch(createDocumentationFailed);

        function createDocumentationComplete(response) {
            return response.data;
        }

        function createDocumentationFailed(error) {
            errorsDataService.catch(error);
        }
    }

    function updateDocumentation(data) {
        return $http.put(apiUrl + 'api/audittrail/ns/v2/documentation', data)
            .then(updateDocumentationComplete)
            .catch(updateDocumentationFailed);

        function updateDocumentationComplete(response) {
            return response.data;
        }

        function updateDocumentationFailed(error) {
            errorsDataService.catch(error);
        }
    }

    function deleteDocumentation(documentationId) {
        return $http.delete(apiUrl + 'api/audittrail/ns/v2/documentation/' + documentationId)
            .then(deleteDocumentationComplete)
            .catch(deleteDocumentationFailed);

        function deleteDocumentationComplete(response) {
            return response.data;
        }

        function deleteDocumentationFailed(error) {
            errorsDataService.catch(error);
        }
    }

    function getDocumentationLinkedChangesList(documentationId, data, params) {
        return $http({
            method: 'POST',
            url: apiUrl + 'api/audittrail/ns/v2/documentation/' + documentationId + '/details/list/paged',
            data: data,
            params: params
        }).then(getDocumentationLinkedChangesListComplete)
            .catch(getDocumentationLinkedChangesListFailed);

        function getDocumentationLinkedChangesListComplete(response) {
            return response.data;
        }

        function getDocumentationLinkedChangesListFailed(error) {
            errorsDataService.catch(error);
        }
    }

    function getDocumentationLinkedTicketsList(documentationId, data, params) {
        return $http({
            method: 'POST',
            url: apiUrl + 'api/audittrail/ns/v2/documentation/' + documentationId + '/tickets/list/paged',
            data: data,
            params: params
        }).then(getDocumentationLinkedTicketsListComplete)
            .catch(getDocumentationLinkedTicketsListFailed);

        function getDocumentationLinkedTicketsListComplete(response) {
            return response.data;
        }

        function getDocumentationLinkedTicketsListFailed(error) {
            errorsDataService.catch(error);
        }
    }

    function linkChangeTicketToDocumentation(documentationId, data) {
        return $http.post(apiUrl + 'api/audittrail/ns/v2/documentation/' + documentationId + '/tickets', data)
            .then(linkChangeTicketToDocumentationComplete)
            .catch(linkChangeTicketToDocumentationFailed);

        function linkChangeTicketToDocumentationComplete(response) {
            return response.data;
        }

        function linkChangeTicketToDocumentationFailed(error) {
            errorsDataService.catch(error);
            throw error;
        }
    }

    function deleteChangeTicketDocumentationLink(documentationId, documentationTicketId) {
        return $http.delete(apiUrl + 'api/audittrail/ns/v2/documentation/' + documentationId + '/tickets/' + documentationTicketId)
            .then(deleteChangeTicketDocumentationLinkComplete)
            .catch(deleteChangeTicketDocumentationLinkFailed);

        function deleteChangeTicketDocumentationLinkComplete(response) {
            return response.data;
        }

        function deleteChangeTicketDocumentationLinkFailed(error) {
            errorsDataService.catch(error);
            throw error;
        }
    }

    function linkChangeToDocumentation(documentationId, data) {
        return $http.post(apiUrl + 'api/audittrail/ns/v2/documentation/' + documentationId + '/details', data)
            .then(linkChangeToDocumentationComplete)
            .catch(linkChangeToDocumentationFailed);

        function linkChangeToDocumentationComplete(response) {
            return response.data;
        }

        function linkChangeToDocumentationFailed(error) {
            errorsDataService.catch(error);
            throw error;
        }
    }

    function deleteChangeDocumentationLink(documentationId, documentationDetailId) {
        return $http.delete(apiUrl + 'api/audittrail/ns/v2/documentation/' + documentationId + '/details/' + documentationDetailId)
            .then(deleteChangeDocumentationLinkComplete)
            .catch(deleteChangeDocumentationLinkFailed);

        function deleteChangeDocumentationLinkComplete(response) {
            return response.data;
        }

        function deleteChangeDocumentationLinkFailed(error) {
            errorsDataService.catch(error);
            throw error;
        }
    }
}