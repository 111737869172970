import { customAutoCompleteFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import mitigationModalController from "./mitigationModal.controller";

export default /*@ngInject*/ function ($scope, $filter, $uibModal, helperService, mitigationsDataService) {

    const vm = this;

    vm.shouldBeOpen = true;

    vm.deleteItem = deleteItem;
    vm.openModal = openModal;

    function activate() {
        const mitigationsGridColumns =
            [
                { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
                { template: `<a ng-if="dataItem.Name !== 'Auto Mitigation Rule'" ng-click="mvm.openModal(dataItem)"><i class="glyphicon glyphicon-edit text-warning m-r-xs"></i>Edit</a>`, "title": "Edit", width: 90 },
                { template: `<a ng-if="dataItem.Name !== 'Auto Mitigation Rule'" ng-click="mvm.deleteItem(dataItem.ID)"><i class="glyphicon glyphicon-trash text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 90 }

            ];

        vm.mitigationsGridOptions = helperService.setMainGridOptions(mitigationsGridColumns, 600);
        vm.mitigationsGridOptions.selectable = false;

        vm.mitigationsGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let { data } = await mitigationsDataService.getMitigations();
                    data = $filter('orderBy')(data, 'Name');
                    options.success(data);
                }
            }
        });
    }

    activate();

    async function deleteItem(id) {
        kendo.ui.progress(vm.mitigationsGrid.element, true);

        try {
            await mitigationsDataService.removeMitigation(id);
            kendo.ui.progress(vm.mitigationsGrid.element, false);
            vm.mitigationsGridDataSource.read();         
        } catch {
            kendo.ui.progress(vm.mitigationsGrid.element, false);
        }
    }

    async function openModal(mitigation) {
        let openModal = $uibModal.open({
            templateUrl: '/App/Components/maintainMitigations/mitigationModal.html',
            controller: mitigationModalController,
            controllerAs: 'mmvm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                mitigation: () => mitigation || {}
            }
        });

        try {
            await openModal.result;
            vm.mitigationsGridDataSource.read();
        } catch {
            return;
        }
    }

}