import groupOwnerItemsModalController from "./groupOwnerItemsModal.controller";

export default /*@ngInject*/ function (ownershipGroupItemsDataService, $uibModal) {

    const vm = this;

    vm.openGroupOwnerItemModal = openGroupOwnerItemModal;
    vm.removeOwnerItemFromGroupOwner = removeOwnerItemFromGroupOwner;

    vm.$onChanges = activate;

    async function activate() {
        setOwnerItems();
        vm.itemsRadioFilter = null;
    }

    async function setOwnerItems() {
        vm.ownerItemsLoading = true;

        const { data } = await ownershipGroupItemsDataService.getGroupOwnerItems(vm.selectedOwnershipGroup, vm.selectedGroupOwner);
        vm.ownerItems = data;
        vm.ownerItemsLoading = false;
    }

    async function openGroupOwnerItemModal() {
        let ownerItemModal = $uibModal.open({
            templateUrl: '/App/Components/OwnershipGroups/groupOwnerItemsList/groupOwnerItemsModal.tmpl.html',
            controller: groupOwnerItemsModalController,
            controllerAs: 'goimvm',
            backdrop: 'static',
            resolve: {
                ownershipGroup: () => vm.selectedOwnershipGroup,
                groupOwner: () => vm.selectedGroupOwner
            }
        });

        try {
            await ownerItemModal.result;
            await setOwnerItems();
        } catch { return; }
    }

    async function removeOwnerItemFromGroupOwner(ownerItem) {
        const putBody = {
            ItemIds: [ownerItem.Id]
        };

        try {
            await ownershipGroupItemsDataService.removeItemFromOwnershipGroupOwner(vm.selectedOwnershipGroup, vm.selectedGroupOwner, putBody);
            await setOwnerItems();
        } catch {
            return;
        }
    }
}