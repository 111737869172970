import { standardGridPageSize, customAutoCompleteFilter, customNotStringEmptyFilter, customBooleanFilter } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";

export default /*@ngInject*/ function ($scope, $rootScope, $filter, SavedReportSelectorService, buildResponsibilityRiskPostBody, objectIdDataService, CustomReportServices, $http, $location, helperService, reportsDataService, reportsService, errorsDataService) {

    $scope.$on('kendoWidgetCreated', function () {
        if ($scope.grid) {
            $scope.gridReady = true;
            $scope.runSavedReport();
        }
    });

    $scope.helperService = helperService;

    var vm = $scope.vm = {};

    vm.allRolesOption = true;

    vm.selectedResponsibilities = [];
    vm.updateRoleDataSource = updateRoleDataSource;
    vm.intraResponsibilityToggleClicked = intraResponsibilityToggleClicked;

    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    // ---

    $scope.intraResponsibilityOnly = false;
    $scope.intraResponsibilityOnly = SavedReportSelectorService.checkSavedReportForIntraResponsibility($rootScope.loadedSavedReport, $scope.intraResponsibilityOnly);

    $scope.hasDetailedVersion = true;
    objectIdDataService.getObjectID().then(function (objectId) {
        $scope.ObjectId = objectId;

        $scope.filtersDataSource = CustomReportServices.setfiltersDataSource($scope.ObjectId);

        var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;
        $scope.reportId = securityObject.ReportID;

        vm.selectedProduct = {};
        vm.selectedProduct.ID = $scope.pageInfo.ProductType;

        if (!$rootScope.loadedSavedReport) {
            CustomReportServices.loadSavedGridLayout($scope, null);
        }

        // --- FOR ADVANCED FILTERING ---
        getReportMetadata().then(_ => {
            vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
            if ($rootScope.loadedSavedReport) {
                $scope.runSavedReport();
            }
        });
        // ---

    });
    if ($location.path().has('Detailed')) {
        $scope.isDetailed = true;
    } else {
        $scope.isDetailed = false;
    }

    $scope.runSavedReport = function () {
        if ($scope.gridReady && $rootScope.loadedSavedReport && $scope.vm.responsibilityDataSource && $scope.vm.riskDataSource && vm.filterCriteria.fields) {
            // --- FOR ADVANCED FILTERING ---
            vm.filterCriteria.query = reportsService.getSavedReportFilters();
            vm.filterCriteria.needsUpdate = true;
            // ---
            $scope.generateGridWithSelectedValues();
        } else if ($scope.gridReady && !$rootScope.loadedSavedReport && $scope.vm.selectedResponsibilities && $scope.vm.riskDataSource && vm.filterCriteria.fields) {
            // We want the necessary report details available to be able to use the report toolbar without running the report
            $scope.populateCriteriaModel();
        }
    };

    $scope.changeView = function () {
        if ($scope.isDetailed === true) {
            $location.path('/AccessControl/Place/ResponsibilityRisk').search('');
        } else {
            $location.path('/AccessControl/Place/ResponsibilityRiskDetailed').search('');
        }
    };

    vm.deselectSelectedRisk = function () {
        vm.riskSelectedIds = helperService.deselectAllObjectsIfOtherIsClicked(vm.riskSelectedIds, vm.allRisksDropdownObject);
        $scope.populateCriteriaModel();
    };

    $scope.mainGridOptions = helperService.setMainGridOptions(null, 600);
    $scope.mainGridOptions.autoBind = false;


    $scope.filterManagerGridOptions = CustomReportServices.setfilterManagerGridOptions();

    $scope.filterDropdownOptions = helperService.getFilterDropdownOptions();

    $scope.applySelectedFilter = function () {
        helperService.applyFilterCatchError($scope);
    };

    $scope.gridDataSource = new kendo.data.DataSource({

        pageSize: standardGridPageSize,
        schema: angular.extend(halSchemaKendoGrid, {
            model: {
                fields: {
                    AssignedUsers: { type: "number" },
                    IsAssigned: { type: "boolean" }
                }
            }
        }),
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {

            read: function (options) {
                delete $rootScope.loadedSavedReport;
                var data = buildResponsibilityRiskPostBody.generateBody($scope.ObjectId, $scope.responsibilities, $scope.risks, $scope.savedIntraResponsibilityOnly, vm.selectedRuleset.RulesetId);
                data['QueryInput'] = helperService.prepareSortsAndFilters(options);

                // --- FOR ADVANCED FILTERING ---
                if (vm.filterCriteria.query) {
                    data['QueryInput'].Filters = data['QueryInput'].Filters.concat(vm.filterCriteria.query);
                }
                // ---

                // if tabs are on, save report info for the tab
                if ($rootScope.tabs.length > 0) {
                    reportsService.saveTabReportInfo($scope);
                }

                $http({
                    method: 'POST',
                    url: $scope.urlString,
                    data: data,
                    params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                }).then(function (response) {
                    options.success(response.data);
                    vm.enableGrid = true;

                }).catch(response => {
                    const timeout = 408;

                    if (response.status !== timeout) {
                        helperService.showErrorMessage(response.data);
                    }
                });
            }
        }
    });

    $scope.populateCriteriaModel = function () {
        // do not continue if the proper criteria are not loaded
        if (!vm.selectedResponsibilities || vm.riskSelectedIds === undefined || $scope.intraResponsibilityOnly === undefined || !vm.selectedRuleset) {
            return;
        }
        var criteria = [];

        $scope.responsibilities = vm.selectedResponsibilities;

        $scope.risks = helperService.returnNullIfAllIsFound(vm.riskSelectedIds.map(function (el) { return el.Id; }));

        $scope.savedIntraResponsibilityOnly = $scope.intraResponsibilityOnly;

        $scope.vm.selectedResponsibilities.forEach(function (row, i) {
            criteria.push(CustomReportServices.CreateCriteria("SecurityRoleIdentifier", 'Role', row.Name, row.Identifier));
        });

        vm.riskSelectedIds.forEach(function (row, i) {
            criteria.push(CustomReportServices.CreateCriteria("RiskId", 'Risk', row.Name, row.Id));
        });

        criteria.push(CustomReportServices.CreateCriteria("Individual", 'IntraResponsibilityOnly', $scope.intraResponsibilityOnly, $scope.intraResponsibilityOnly));
        criteria.push(CustomReportServices.CreateCriteria("RulesetId", 'Ruleset', vm.selectedRuleset.Name, vm.selectedRuleset.RulesetId));

        $scope.reportDetailsModel = criteria;

        if ($scope.isDetailed === true) {
            $scope.urlString = apiUrl + 'api/arm/or/places/responsibilityrisk/detailed';
        } else if ($scope.isDetailed === false) {
            $scope.urlString = apiUrl + 'api/arm/or/places/responsibilityrisk';
        }

        // if tabs are on, save report info for the tab
        if ($rootScope.tabs.length > 0) {
            reportsService.saveTabReportInfo($scope);
        }
    };

    $scope.generateGridWithSelectedValues = function () {

        if (!$rootScope.loadedSavedReport) {
            $scope.grid.dataSource._filter = null;
            $scope.grid.dataSource._sort = null;
        }

        $scope.populateCriteriaModel();
        vm.hideExportOptionsFromSubmit = true;

        // Add back the sorting and filtering options if we are running the report for the first time
        if (!$rootScope.loadedSavedReport) {
            $scope.grid.setOptions({
                filterable: {
                    mode: "row"
                },
                sortable: true
            });
        }

        SavedReportSelectorService.queryGridWithOptions($rootScope.loadedSavedReport, $scope.grid, true);
    };

    vm.setColumns = function () {

        if ($scope.isDetailed === false && $scope.intraResponsibilityOnly === false) {
            $scope.mainGridColumns = [
                { field: "RiskName", title: "Risk Name", width: 200, filterable: customAutoCompleteFilter },
                { field: "RiskDescription", title: "Risk Description", width: 200, filterable: customAutoCompleteFilter },
                { field: "RiskTypeName", title: "Risk Type", width: 200, filterable: customAutoCompleteFilter },
                { field: "BusinessCycle", title: "Business Cycle", width: 180, filterable: customAutoCompleteFilter },
                { field: "RiskLevel", title: "Risk Level", width: 120, filterable: customAutoCompleteFilter },
                { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
            ];

        } else if ($scope.isDetailed === false && $scope.intraResponsibilityOnly === true) {
            $scope.mainGridColumns = [
                { field: "ResponsibilityName", title: "Responsibility", width: 190, filterable: customAutoCompleteFilter },
                { field: "ResponsibilityID", title: "Responsibility ID", width: 190, filterable: customAutoCompleteFilter, hidden: true },
                { field: "ResponsibilityKey", title: "Responsibility Key", width: 190, filterable: customAutoCompleteFilter, hidden: true },
                { field: "BusinessCycle", title: "Business Cycle", width: 180, filterable: customAutoCompleteFilter },
                { field: "RiskName", title: "Risk Name", width: 190, filterable: customAutoCompleteFilter },
                { field: "RiskDescription", title: "Risk Description", width: 200, filterable: customAutoCompleteFilter },
                { field: "RiskLevel", title: "Risk Level", width: 120, filterable: customAutoCompleteFilter },
                { field: "RiskTypeName", title: "Risk Type", width: 120, filterable: customAutoCompleteFilter },
                { field: "AssignedUsers", title: "Assigned Users", width: 140, filterable: customNotStringEmptyFilter },
                { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
            ];

        } else if ($scope.isDetailed === true) {
            $scope.mainGridColumns = [
                { field: "ResponsibilityName", title: "Responsibility", width: 190, filterable: customAutoCompleteFilter },
                { field: "ResponsibilityID", title: "Responsibility ID", width: 190, filterable: customAutoCompleteFilter, hidden: true },
                { field: "ResponsibilityKey", title: "Responsibility Key", width: 190, filterable: customAutoCompleteFilter, hidden: true },
                { field: "AssignedUsers", title: "Assigned Users", width: 140, filterable: customNotStringEmptyFilter },
                { field: "BusinessCycle", title: "Business Cycle", width: 160, filterable: customAutoCompleteFilter },
                { field: "BusinessProcess", title: "Business Process", width: 140, filterable: customAutoCompleteFilter },
                { field: "UserMenuName", title: "User Menu Name", width: 200, filterable: customAutoCompleteFilter },
                { field: "MenuName", title: "Menu Name", width: 200, filterable: customAutoCompleteFilter, hidden: true },
                { field: "UserSubMenuName", title: "User Sub Menu Name", width: 200, filterable: customAutoCompleteFilter },
                { field: "SubMenuName", title: "Sub Menu Name", width: 200, filterable: customAutoCompleteFilter, hidden: true },
                { field: "SecurityObjectAltName", title: "User Object Name", width: 200, filterable: customAutoCompleteFilter },
                { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                { field: "Type", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                { field: "RiskName", title: "Risk Name", width: 190, filterable: customAutoCompleteFilter },
                { field: "RiskDescription", title: "Risk Description", width: 200, filterable: customAutoCompleteFilter },
                { field: "RiskLevel", title: "Risk Level", width: 120, filterable: customAutoCompleteFilter },
                { field: "RiskTypeName", title: "Risk Type", width: 120, filterable: customAutoCompleteFilter },
                { field: "Prompt", title: "Prompt", width: 200, filterable: customAutoCompleteFilter },
                { field: "Path", title: "Path", width: 200, filterable: customAutoCompleteFilter },
                { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
            ];
        }

        // Don't allow filtering and sorting yet because they will force the report to run
        if (!$rootScope.loadedSavedReport) {
            $scope.mainGridOptions.sortable = false;

            $scope.mainGridOptions.filterable = false;
        }
    };

    vm.setColumns();

    vm.EnableIntraResponsibility = function (selection) {
        if (!selection?.length) {
            $scope.intraResponsibilityOnly = true;
            vm.allResponsibilitiesSelected = true;
        } else {
            $scope.intraResponsibilityOnly = false;
            vm.allResponsibilitiesSelected = false;
        }
        vm.setColumns();
    };

    $scope.deleteFilter = function (id) {
        CustomReportServices.deleteFilterFilterManager($scope, id);
    };

    $scope.splitHeaderText = function () {
        helperService.splitHeaderText(angular);
    };

    $scope.clearAllFilters = function () {
        $scope.gridDataSource.filter({});
    };

    $scope.saveReportLayoutButton = function () {
        CustomReportServices.saveGridLayout($scope, null);
    };

    $http.get(`${apiUrl}api/arm/riskrulesets`)
        .then(function (response) {
            response.data = $filter('filter')(response.data, { IsEnabled: true });
            vm.rulesetDataSource = response.data;
            vm.rulesetDataSource = $filter('orderBy')(response.data, 'Name');
            vm.selectedRuleset = SavedReportSelectorService.checkSavedReportForRuleset($rootScope.loadedSavedReport, response.data, vm.rulesetDataSource);
            getRisks();
        });

    $scope.rulesetDropdownChange = function () {
        getRisks();
        $scope.populateCriteriaModel;
    };

    async function getRisks() {
        try {
            let response = await $http.get(apiUrl + 'api/arm/risks/ruleset/' + vm.selectedRuleset.RulesetId);
            response.data = $filter('filter')(response.data, { Enabled: true });
            response.data = $filter('orderBy')(response.data, 'Name');
            vm.allRisksDropdownObject = { "Name": "All Risks", "Id": 'AllObjectsInMultiSelect', 'Enabled': true };
            response.data.unshift(vm.allRisksDropdownObject);
            vm.riskDataSource = response.data;
            vm.riskSelectedIds = [vm.allRisksDropdownObject];
            vm.riskSelectedIds = await SavedReportSelectorService.checkSavedReportForRisks($rootScope.loadedSavedReport, response.data, vm.riskSelectedIds);
            $scope.runSavedReport();
        }
        catch (err) {
            errorsDataService.catch(err);
        }
    }

    function updateRoleDataSource(roleDataSource, roleSelectedIds) {
        $scope.vm.responsibilityDataSource = roleDataSource;
        vm.responsibilities = roleSelectedIds;
        $scope.populateCriteriaModel();
    }

    // --- FOR ADVANCED FILTERING ---
    function getReportMetadata() {
        return reportsDataService.getReportMetadata($scope.reportId)
            .then(data => {

                vm.reportMetadata = data.data._embedded.reportData; 
                vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');

                return vm.reportMetadata;
            });
    }
    // ---

    function intraResponsibilityToggleClicked() {
        vm.setColumns();
        $scope.populateCriteriaModel();
    }

}
