import { standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($rootScope, $filter, $uibModalInstance, helperService, aDUserObjectValueMapperService, existingUserSearchService, userObject) {

    const vm = this;

    vm.title = 'Active Directory User Search';
    vm.filterUsersToggle = true;
    vm.selectedUser = null;

    vm.doneSearchingUsers = doneSearchingUsers;
    vm.changeExistingUserRowSelected = changeExistingUserRowSelected;
    vm.selectUser = selectUser;
    vm.filterUsers = filterUsers;

    //Search Existing Users
    var getExistingUserGridColumns =
        [
            { field: "DisplayName", title: "User Name", filterable: false },
            { field: "Domain", title: "Domain", filterable: false },
            { field: "Mail", title: "Email", filterable: false }
        ];

    function doneSearchingUsers() {

        $rootScope.IMSelectedExistingUser = null;

        if (vm.ExistingWindowsUserSearchString === '' || vm.ExistingWindowsUserSearchString === null) {

            var raw = vm.ExistingusersGridDataSource.data();
            var length = raw.length;

            let item, i;
            for (i = length - 1; i >= 0; i--) {

                item = raw[i];
                vm.ExistingusersGridDataSource.remove(item);

            }
        } else {
            vm.ExistingusersGridDataSource.read();
        }
    }

    vm.ExistingUsersGridOptions = helperService.setMainGridOptions(getExistingUserGridColumns, 350);
    vm.ExistingUsersGridOptions.autoBind = false;

    vm.ExistingusersGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: async (options) => {
                try {
                    let response =  await existingUserSearchService.getFilteredADUsers(vm.ExistingWindowsUserSearchString, vm.filterUsersToggle);
                    response.data = $filter('orderBy')(response.data, 'DisplayName');
                    options.success(response.data);
                } catch(err) {
                    options.error();
                }
            }
        }
    });

    function changeExistingUserRowSelected(kendoEvent) {
        let grid = kendoEvent.sender;
        vm.selectedUser = grid.dataItem(grid.select());
    }

    function selectUser() {
        vm.selectedUser.ProductType = userObject.ProductType; // Add productType for value mapper to map properly
        let mappedObject = aDUserObjectValueMapperService.valueMap(vm.selectedUser); // convert selectedUser properties for form models
        userObject = Object.assign(userObject, mappedObject); // add mapped properties back into userObject

        vm.ExistingWindowsUserSearchString = null;
        $uibModalInstance.close(userObject);
    }

    function filterUsers() {
        if (vm.ExistingusersGridDataSource._data.length > 0) {
            vm.ExistingusersGridDataSource.read();
        }
    }

    vm.cancel = () => $uibModalInstance.dismiss();

}