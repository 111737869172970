import angular from 'angular';
import NetSuiteArmQuantificationViewModel from '../Components/NetSuiteArmQuantification/NetSuiteArmQuantificationViewModel';
import NSQuantificationSelection from '../Components/NetSuiteArmQuantification/NSQuantificationSelection';
import NetSuiteSecurityDesignerViewModel from '../Components/NetSuiteSecurityDesigner/NetSuiteSecurityDesignerViewModel';
import NetSuiteSecurityDesignerDeploymentController from '../Components/NetSuiteSecurityDesigner/Deployment/NetSuiteSecurityDesignerDeploymentController';
import SimulationNewController from '../Components/NetSuiteSecurityDesigner/SimulationNewController';

export default angular
    .module('app.accessRiskMonitor.netSuite', [])
    .controller("NetSuiteArmQuantificationViewModel", NetSuiteArmQuantificationViewModel)
    .controller("nsQuantificationSelection", NSQuantificationSelection)
    .controller("NetSuiteSecurityDesignerViewModel", NetSuiteSecurityDesignerViewModel)
    .controller("NetSuiteSecurityDesignerDeploymentController", NetSuiteSecurityDesignerDeploymentController)
    .controller("SimulationNewController", SimulationNewController)
    .name;