/* reviewSchedules.component.js */

import reviewSchedulesController from "./reviewSchedules.controller";

/**
 * @desc component for viewing and managing access certifications review schedules and assignments
 * @example <review-schedules></review-schedules>
 */

const reviewSchedules = {
    controller: reviewSchedulesController,
    controllerAs: 'rsvm',
    templateUrl: '/App/Components/Certifications/reviewSchedules/reviewSchedules.tmpl.html'
};

export default reviewSchedules;