export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getReviewTypes,
        exportReviews,
        addReviewAssignment,
        importReviews,
        deleteReview
    };

    return service;

    async function getReviewTypes() {
        try {
            return await $http.get(apiUrl + 'api/certifications/reviewtypes');
        }
        catch (err) {
            showError(err);
        }
    }

    async function exportReviews() {
        try {
            return await $http.get(`${apiUrl}api/certifications/reviewassignments/export`, { responseType: 'blob' });
        } catch (err) {
            showError(err);
        }
    }

    async function addReviewAssignment(data) {
        try {
            return await $http.post(apiUrl + 'api/certifications/reviewassignments', data);
        }
        catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function importReviews(data) {
        try {
            return await $http.put(`${apiUrl}api/certifications/reviewassignments/import`, data, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteReview(reviewId) {
        try {
            return await $http.delete(apiUrl + 'api/certifications/reviewassignments/' + reviewId);
        } catch(err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }

}