export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getReviews,
        getReviewDetails,
        updateItemStatus,
        delegateReviewItems,
        exportReview
    };

    return service;

    async function getReviews() {
        try {
            return await $http.get(apiUrl + 'api/certifications/reviews');
        } catch (err) {
            showError(err);
        }

    }

    async function getReviewDetails(reviewType, headerId, groupByType) {
        try {
            let response = await $http.get(apiUrl + `api/certifications/reviews/${headerId}/${reviewType}/items/${groupByType}`);
            return response.data;
        }
        catch (err) {
            showError(err);
        }
    }

    async function updateItemStatus(headerId, selectedTile, data) {
        try {
            return await $http.put(apiUrl + `api/certifications/reviews/${headerId}/${selectedTile}/items`, data);
        } catch (err) {
            showError(err);
        }
    }

    async function delegateReviewItems(reviewHeaderId, reviewType, data) {
        try {
            return await $http.put(`${apiUrl}api/certifications/reviews/${reviewHeaderId}/${reviewType}/items/delegate`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function exportReview(reviewId, data) {
        try {
            return await $http.post(`${apiUrl}api/certifications/reviews/${reviewId}/export`, data);
        } catch (err) {
            showError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }

}