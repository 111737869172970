import { customAutoCompleteFilter, standardGridPageSize } from "../../../GlobalVariables/filterVariables";

export default /*@ngInject*/ function (helperService, $uibModalInstance, configurationDataService, roleSecurityProfileDataService, role) {

    const vm = this,
        fioriReportId = 'eaefba7b-1d22-47d7-8618-ce3cf439a98d';

    vm.roleCollapsed = true;
    vm.catalogCollapsed = true;
    vm.isWorkDay = false;
    vm.helperService = helperService;

    vm.$onInit = onInit;

    vm.toggleRoleAccess = toggleRoleAccess;
    vm.toggleFioriCatalog = toggleFioriCatalog;
    vm.cancel = cancel;

    async function onInit() {
        vm.securityRoleIdentifier = role?.SecurityRoleIdentifier || role.Identifier;
        vm.profile = await roleSecurityProfileDataService.getProfile(vm.securityRoleIdentifier);

        if (role.ProductType === 'WD') {
            vm.isWorkDay = true;
            vm.workDayProfileInformation = await roleSecurityProfileDataService.getWorkdayProfile(role.SecurityRoleId);
        }
        setupRoleGrid();

        vm.s4Enabled = vm.profile?.ProductType === 'SAP' ? await getS4Config() : false;

        if (vm.s4Enabled) {
            setupCatalogGrid();
        }
    }

    async function getS4Config() {
        const { data } = await configurationDataService.getProductConfigurationValueByName('SAP', 'SAP_ErpType');

        return data.Value === 'S4';
    }

    async function setupRoleGrid() {
        vm.roleGridColumns = [
            { field: "SecurityObjectName", title: "Object Name", width: 120, filterable: customAutoCompleteFilter },
            { field: "SecurityObjectAltName", title: "Object Label", width: 120, filterable: customAutoCompleteFilter },
            { field: "SecurityObjectTypeName", title: "Object Type", width: 120, filterable: customAutoCompleteFilter },
            { field: "AccessTypeName", title: "Access Type", width: 120, filterable: customAutoCompleteFilter },
            { field: "AccessLevelName", title: "Access Level", width: 120, filterable: customAutoCompleteFilter }
        ];

        vm.roleGridOptions = helperService.setMainGridOptions(vm.roleGridColumns, 400);

        vm.roleGridDataSource = new kendo.data.DataSource({
            schema: {
                data: "_embedded.reportData",
                total: "TotalItems"
            },
            pageSize: standardGridPageSize,
            serverPaging: true,
            serverFiltering: true,
            transport: {
                read: async options => {
                    try {
                        const { page, pageSize } = options.data,
                            sortsAndFilters = helperService.prepareSortsAndFilters(options),
                            { data } = await roleSecurityProfileDataService.getAccess(vm.securityRoleIdentifier, sortsAndFilters, page, pageSize);

                        options.success(data);
                    } catch (err) {
                        options.error();
                    }
                }
            }
        });
    }

    async function setupCatalogGrid() {
        vm.catalogGridColumns = [
            { field: "Role", title: "Role", width: 120, filterable: customAutoCompleteFilter },
            { field: "RoleDescription", title: "Role Description", width: 120, filterable: customAutoCompleteFilter },
            { field: "Catalog", title: "Catalog", width: 120, filterable: customAutoCompleteFilter },
            { field: "CatalogDescription", title: "Catalog Description", width: 120, filterable: customAutoCompleteFilter },
            { field: "FioriID", title: "Fiori Id", width: 120, filterable: customAutoCompleteFilter },
            { field: "FioriDescription", title: "Fiori Description", width: 120, filterable: customAutoCompleteFilter },
            { field: "SemanticObject", title: "Semantic Object", width: 120, filterable: customAutoCompleteFilter },
            { field: "SemanticAction", title: "Semantic Action", width: 120, filterable: customAutoCompleteFilter }
        ];

        vm.catalogGridOptions = helperService.setMainGridOptions(vm.catalogGridColumns, 400);

        vm.catalogGridDataSource = new kendo.data.DataSource({
            schema: {
                data: "_embedded.reportData",
                total: "TotalItems"
            },
            pageSize: standardGridPageSize,
            serverPaging: true,
            serverFiltering: true,
            noRecords: {
                template: "<div class='no-data-to-display-message'>No items to display</div>"
            },
            transport: {
                read: async options => {
                    try {
                        const { page, pageSize } = options.data;
                        let sortsAndFilters = helperService.prepareSortsAndFilters(options);
                        //Only show data for role in profile
                        sortsAndFilters.Filters.push({ FieldName: "Role", Operator: "Equals", Value: vm.profile.Name })
                        const { data } = await roleSecurityProfileDataService.getCatalog(fioriReportId, sortsAndFilters, page, pageSize);

                        options.success(data);
                    } catch (err) {
                        options.error();
                    }
                }
            }
        });
    }

    function toggleRoleAccess() {
        vm.roleCollapsed = !vm.roleCollapsed;

        if (vm.roleCollapsed) {
            return;
        } else {
            vm.roleGridDataSource.read();
        }
    }

    function toggleFioriCatalog() {
        vm.catalogCollapsed = !vm.catalogCollapsed;

        if (vm.catalogCollapsed) {
            return;
        } else {
            vm.catalogGridDataSource.read();
        }
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}