import angular from 'angular';
import axSecurityDesignerSecurityMigration from '../Components/AXSecurityDesigner/SecurityMigration/securityMigration.component';
import axSecurityDesignerMigrationsList from '../Components/AXSecurityDesigner/SecurityMigration/MigrationsList/migrationsList.component';
import axSecurityDesignerMigrationDetailsGrid from '../Components/AXSecurityDesigner/SecurityMigration/MigrationDetails/migrationDetailsGrid.component';
import sapSdSimulations from '../Components/SAPSecurityDesigner/simulations.component';
import sapSdSimulationsList from '../Components/SAPSecurityDesigner/simulationsList/simulationsList.component';
import sapSdSimulationRolesList from '../Components/SAPSecurityDesigner/simulationRolesList/rolesList.component';
import sapSdSimulationCompositeRolesList from '../Components/SAPSecurityDesigner/simulationCompositeRolesList/compositeRolesList.component';
import sapSdSimulationRoleAuthObjectsList from '../Components/SAPSecurityDesigner/simRoleAuthObjectsList/roleAuthObjectsList.component';
import sapSdSimulationRoleOrgLevelsList from '../Components/SAPSecurityDesigner/simRoleOrgLevelsList/roleOrgLevelsList.component';
import sapSdSimulationsCompositeRoleRolesList from '../Components/SAPSecurityDesigner/compositeRoleRolesList/compositeRoleRolesList.component';
import sapSdSimulationRoleAuthObjectFieldsList from '../Components/SAPSecurityDesigner/simRoleAuthObjFieldsList/authObjectFieldsList.component';
import sapSdSimulationsRoleItemsList from '../Components/SAPSecurityDesigner/itemsList/items.component';
import sapSdSimulationFieldValuesList from '../Components/SAPSecurityDesigner/simFieldValuesList/fieldValuesList.component';
import sapSdSimulationRiskAnalysis from '../Components/SAPSecurityDesigner/simulationRiskAnalysis/simRiskAnalysis.component';
import sapSdSimulationRiskAnalysisReport from '../Components/SAPSecurityDesigner/simulationRiskAnalysis/simRiskAnalysisReport/simRiskAnalysisReport.component';

export default angular
    .module('app.securityDesigner.components', [])
    .component('axSecurityDesignerSecurityMigration', axSecurityDesignerSecurityMigration)
    .component('axSecurityDesignerMigrationsList', axSecurityDesignerMigrationsList)
    .component('axSecurityDesignerMigrationDetailsGrid', axSecurityDesignerMigrationDetailsGrid)
    .component('sapSdSimulations', sapSdSimulations)
    .component('sapSdSimulationsList', sapSdSimulationsList)
    .component('sapSdSimulationRolesList', sapSdSimulationRolesList)
    .component('sapSdSimulationCompositeRolesList', sapSdSimulationCompositeRolesList)
    .component('sapSdSimulationsRoleItemsList', sapSdSimulationsRoleItemsList)
    .component('sapSdSimulationCompositeRoleRolesList', sapSdSimulationsCompositeRoleRolesList)
    .component('sapSdAuthorizationObjectsList', sapSdSimulationRoleAuthObjectsList)
    .component('sapSdOrganizationalLevelsList', sapSdSimulationRoleOrgLevelsList)
    .component('sapSdAuthorizationObjectFieldsList', sapSdSimulationRoleAuthObjectFieldsList)
    .component('sapSdFieldValuesList', sapSdSimulationFieldValuesList)
    .component('sapSdSimulationRiskAnalysis', sapSdSimulationRiskAnalysis)
    .component('sapSdSimulationRiskAnalysisReport', sapSdSimulationRiskAnalysisReport)
    .name;