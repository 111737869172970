export default /*@ngInject*/ function ($scope, $uibModal, $http, RequestManagementAXService, existingUserCheckService, errorsDataService) {

    //General User
    $scope.vm.axUserFromDateObject = null;
    $scope.vm.axUserToDateObject = null;
    $scope.vm.axUserMaxDate = new Date();
    var axUserMinDate = new Date();
    axUserMinDate.setHours(0, 0, 0, 0);

    $scope.vm.axUserMinDate = axUserMinDate;
    if (!$scope.vm.User.AX2012User) {
        $scope.vm.User.AX2012User = {};
    }
    if ($scope.vm.wizardMode === 'New') {
        $scope.vm.User.AX2012User.Enabled = true;
    }
    $scope.vm.User.AX2012User.ProductType = 'AX';

    activate();

    async function activate() {
        $scope.showDepartmentControl = await getProductConfig();
        createCompanyDropdown();
    }

    async function getProductConfig() {
        // check for the tishman config value and use it to determine whether to display the department control when a person record has been selected
        try {
            const response = await $http.get(`${apiUrl}api/universal/products/ax/configuration/IM_TishmanDepartmentCustomization`);
            if (response.data.Value === '1') {
                await createDepartmentDropdown();
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }

    }

    async function createDepartmentDropdown() {
        const response = await $http.get(`${apiUrl}api/identitymanager/ax/departments`);
        $scope.vm.axDepartments = response.data;
    }

    function createCompanyDropdown() {
        $scope.vm.axCompanyDropdownOptions = {
            valuePrimitive: true,
            template: `
                <span>{{dataItem.CompanyName}}</span> | <span>{{dataItem.CompanyId}}</span>
            `,
            filter: "contains",
            autoBind: true,
            minLength: 3,
            optionLabel: '-- Select Company --'
        };

        $scope.vm.axCompanyDropdownDataSource = new kendo.data.DataSource({
            transport: {
                read: options => {

                    options.success($scope.vm.ListOfAX2012Companies);
                }
            }
        });
    }

    $scope.axUserFromDateChanged = function () {
        var date;
        if ($scope.vm.User.AX2012User.StartDate == '') {
            date = new Date();
        } else {
            date = new Date($scope.vm.User.AX2012User.StartDate);
        }

        $scope.vm.axUserMinDate = date;
        $scope.vm.nsMinDate = date;
        $scope.vm.fromMinDate = date;
        if ($scope.vm.User.AX2012User == null) {
            $scope.vm.User.AX2012User = {};
        }
    };

    $scope.axUserToDateChanged = function () {
        $scope.vm.axUserMaxDate = new Date($scope.vm.User.AX2012User.EndDate);
        $scope.vm.nsMaxDate = new Date($scope.vm.User.AX2012User.EndDate);
        $scope.vm.fromMaxDate = new Date($scope.vm.User.AX2012User.StartDate);
        if ($scope.vm.User.AX2012User == null) {
            $scope.vm.User.AX2012User = {};
        }
    };

    $scope.clearWindowsUser = function () {
        $scope.vm.User.AX2012User.SID = null;
        $scope.vm.User.AX2012User.NetworkDomain = null;
        $scope.vm.User.AX2012User.NetworkAlias = null;
        $scope.vm.User.AX2012User.UserName = null;
        $scope.vm.User.AX2012User.DisplayUserId = null;
    };

    //Watch for AX UserId updates the display value of the user Id by removing the partiion
    $scope.$watch('vm.User.AX2012User', function (newVal) {
        if ($scope.vm.User != null && $scope.vm.User.AX2012User != null && $scope.vm.User.AX2012User.UserId != null) {
            $scope.vm.User.AX2012User.DisplayUserId = $scope.vm.User.AX2012User.UserId.slice($scope.vm.User.AX2012User.UserId.indexOf('|') + 1, $scope.vm.User.AX2012User.UserId.length);
        }
    }, true);

    $scope.openModal = function () {
        $scope.vm.ExistingWindowsUserSearchString = null;

        $uibModal.open({
            templateUrl: '/App/Components/RequestManagement/WizardSteps/Universal/SearchWindowsUsersModal.html',
            controller: 'SearchAXWindowsUsersController',
            backdrop: 'static',
            size: 'lg',
            scope: $scope
        });
    };

    $scope.$watch('vm.User.AX2012User.Partition', function (newVal) {
        if ($scope.vm.User != null && $scope.vm.User.AX2012User != null && $scope.vm.User.AX2012User.Partition != null) {
            $scope.partitionChange();
        }
    }, true);

    $scope.partitionChange = function () {
        if ($scope.vm.User.AX2012User.Partition != null) {
            RequestManagementAXService.getAllAX2012Companies($scope.vm.User.AX2012User.Partition.Key).then(function (response) {
                $scope.vm.ListOfAX2012Companies = response.data;
                $scope.vm.axCompanyDropdownDataSource.read();
            }, function errorCallback(response) {
                //permissionsDfrd.reject();
            });
        }
    };

    $scope.existingUserCheck = function (userId, partitionId) {
        existingUserCheckService.checkAX(userId, partitionId)
            .then(response => {
                if (response.data === true) {
                    $scope.userExists = true;
                    $scope.wizardForm.AXuserId.$setValidity("AXuserId", false);
                }
                else {
                    $scope.userExists = false;
                    $scope.wizardForm.AXuserId.$setValidity("AXuserId", true);
                }

            })
            .catch(error => {
                errorsDataService.catch(error);
            });
    };

    $scope.$watchGroup(["vm.User.AX2012User.Partition", "vm.User.AX2012User.DisplayUserId"], () => {
        if ($scope.vm.User.AX2012User.DisplayUserId && $scope.vm.User.AX2012User.Partition && $scope.vm.wizardMode === 'New') {
            $scope.existingUserCheck($scope.vm.User.AX2012User.DisplayUserId, $scope.vm.User.AX2012User.Partition.Key);
        }
    });

    $scope.setPersonName = function (personObject) {
        $scope.vm.User.AX2012User.Person = {
            PartyNumber: personObject.PartyNumber,
            PersonNameAlias: personObject.PersonNameAlias,
            PersonName: personObject.PersonName,
            PersonPrimaryEmail: personObject.PersonPrimaryEmail
        };

        $scope.personSelected = true;
    }
}