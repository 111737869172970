import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($http, $filter, $uibModalInstance, helperService, generatedReportsDataService, authenticationService, usersDataService, dataService) {

    const vm = this;

    vm.productChanged = productChanged;
    vm.createGeneratedReport = createGeneratedReport;
    vm.deselectSelectedTemplateType = deselectSelectedTemplateType;
    vm.dismiss = dismiss;
    vm.roles = [];
    vm.selectedRoles = [];
    vm.selectedCompanies = [];
    vm.generatedReport = {};

    activate();

    async function activate() {
        await getProducts();
        await getRoles();

        if (vm.generatedReport.Product.ID !== 'AX7') {
            loadTemplates();
        }
    }

    async function getRoles() {
        vm.rolesDropdownLoading = true;

        let { userId } = await authenticationService.getUser(),
            { data: coreRoles } = await dataService.getRoles(),
            { data: userRoles } = await usersDataService.getRolesByUserId(userId);

        vm.roles = coreRoles;
        vm.selectedRoles = coreRoles.filter(coreRole => userRoles.find(userRole => userRole.ID === coreRole.ID) && coreRole.Name !== "Administrators");

        vm.rolesDropdownLoading = false;
    }

    async function getProducts() {
        let response = await $http.get(`${apiUrl}api/core/modules/3000/products`);

        response.data = response.data.filter(el => el.Type === 'GP' || el.Type === 'NAV' || el.Type === 'AX' || el.Type === "AX5" || el.Type === "SL" || el.Type === 'AX7' || el.IsCustom);
        vm.productsDataSource = response.data;
        vm.generatedReport.Product = response.data[0];
    }

    function productChanged() {
        if (vm.generatedReport.Product.ID !== 'AX7') {
            vm.showTablesControl = true;
            loadTemplates();
        } else {
            vm.showTablesControl = false;
        }
    }

    async function loadTemplates() {
        vm.templateDropdownIsLoading = true;
        vm.showTablesControl = true;
        let atUrl = 'audittrail';
        if (vm.generatedReport.Product.IsCustom) {
            atUrl = 'audittrail/universal';
        }
        const product = vm.generatedReport.Product.ID.toLowerCase();
        try {
            let url = `${apiUrl}api/${atUrl}/${product}/tables`;
            if (!vm.generatedReport.Product.IsCustom && vm.trackedTables === '1') {
                url += '?trackedTables=true';
            }

            let response = await $http.get(url);
            response.data = $filter('orderBy')(response.data, 'Name');
            const All = { "Name": "All Tables", "Id": 'AllObjectsInMultiSelect' };
            response.data.unshift(All);
            vm.templateTypesDataSource = response.data;
            vm.generatedReport.selectedTemplateType = [All];
            vm.templateDropdownIsLoading = false;
        } catch {
            return;
        }
    }

    async function createGeneratedReport() {
        vm.loading = new createSpinner();
        let tableList = new Array;

        if (vm.generatedReport.Product.ID === 'AX7') {
            let data = {
                Name: vm.generatedReport.Name,
                ViewName: vm.generatedReport.ViewName,
                Description: vm.generatedReport.Description
            }
            try {
                await generatedReportsDataService.createAx7GeneratedReport(data);
                await helperService.successfulSaveButton(vm.loading);
                close();
            } catch {
                vm.disableSubmit = false;
                vm.loading.loadingValue = false;
            }
        } else {
            if (vm.generatedReport.selectedTemplateType[vm.generatedReport.selectedTemplateType.length - 1].Id !== 'AllObjectsInMultiSelect') {
                vm.generatedReport.selectedTemplateType.forEach(template => {
                    tableList.push(template.Id);
                });
            }
            const postData = {
                ProductId: vm.generatedReport.Product.ID,
                Name: vm.generatedReport.Name,
                Description: vm.generatedReport.Description,
                Tables: tableList,
                RoleIds: vm.selectedRoles.map(role => role.ID),
                AllTrackedTables: vm.trackedTables === '1' ? true: false
            };
            try {
                await generatedReportsDataService.createGeneratedReport(postData);
                await helperService.successfulSaveButton(vm.loading);
                close();
            } catch {
                vm.disableSubmit = false;
                vm.loading.loadingValue = false;
            }
        }
    }

    function deselectSelectedTemplateType() {
        if (vm.generatedReport.selectedTemplateType !== null && vm.generatedReport.selectedTemplateType[vm.generatedReport.selectedTemplateType.length - 1].Id !== 'AllObjectsInMultiSelect') {
            vm.generatedReport.selectedTemplateType.forEach((row, i) => {
                if (row.Id === 'AllObjectsInMultiSelect') {
                    vm.generatedReport.selectedTemplateType.splice(i, 1);
                }
            });
        } else {
            vm.generatedReport.selectedTemplateType = [{ "Name": "All Tables", "Id": 'AllObjectsInMultiSelect' }];
        }
    }

    function close() {
        $uibModalInstance.close();
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }

}
