import allSupportedProducts from "../GlobalVariables/ProductLookUp";

export default /*@ngInject*/ function (helperService, $location) {
    var self = this;

    self.viewImportReport = function (productType) {
        var selectedProduct = allSupportedProducts.filter(e => e.Type === productType)[0];
        var productName = '';
        var objectName = "Import Details";

        if (selectedProduct) {
            productName = selectedProduct.Name;
            objectName = productName + " Import Details";
        }

        var userSecurityList = angular.fromJson(localStorage.getItem("UserSecurityObjects"));

        productName = helperService.removeSpaces(productName);

        var objectId = null;

        if (userSecurityList) {
            var userSecurityObject = userSecurityList.find(function (object) {
                return object.Name === objectName;
            });

            if (userSecurityObject !== null) {
                objectId = userSecurityObject.ObjectID;
                $location.path('/Integrations/Report/' + productName + 'ImportDetails/' + objectId).search('');
            }
        }

    };

    self.launchReportByObjectId = function (objectId) {

        var userSecurityObject = helperService.getSecurityObjectById(objectId);

        if (userSecurityObject) {

            var ModuleName = helperService.moduleLookUpById(userSecurityObject.ParentID);

            if (userSecurityObject.TypeID === 2) {
                helperService.changeView(ModuleName, userSecurityObject.Name, userSecurityObject.TypeName);
            } else {
                helperService.changeReportView(ModuleName, userSecurityObject.Name, userSecurityObject.ObjectID, userSecurityObject.TypeName);
            }
        }

    };

}