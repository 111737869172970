export default /*@ngInject*/ function (toastr, $rootScope) {

    var service = {
        notifyArchive: notifyArchive
    };

    return service;

    function notifyArchive({ correlationId, type, message, title, status, response, options = {} }) {

        // this will represent our message in the notifications sidebar
        var archiveObject = { correlationId: correlationId, type: type, message: message, title: title, status: status, response: response, options: options, date: new Date() };

        if (!$rootScope.rightNotificationsSidebar) {
            switch (status) {
                case 'Success':
                    options.closeButton = true;
                    if (!duplicateProductImport(type, title, archiveObject.date)) {
                        toastr.success(message, title, options);
                    }
                    break;
                case 'Warning':
                    options.closeButton = true;
                    if (!duplicateProductImport(type, title, archiveObject.date)) {
                        toastr.warning(message, title, options);
                    }
                    break;
                case 'Error':
                    options.closeButton = true;
                    if (!duplicateProductImport(type, title, archiveObject.date)) {
                        toastr.error(message, title, options);
                    }
                    break;
                case 'Info':
                    options.closeButton = true;
                    if (!duplicateProductImport(type, title, archiveObject.date)) {
                        toastr.info(message, title, options);
                    }
                    break;
                case 'InProgress':
                    options.iconClass = 'toast-loading';
                    if (!duplicateProductImport(type, title, archiveObject.date)) {
                        toastr.info(message, title, options);
                    }
                    break;
            }
        }

        function duplicateProductImport(type, title, date) {
            return type === "ProductImport" && $rootScope.archivedNotifications.find(notification => notification.title === title && notification.date.getDay() === date.getDay());
        }

        function removeDuplicates(correlationId) {
            $rootScope.archivedNotifications.forEach(function (row, i) {
                if (correlationId === row.correlationId) {
                    $rootScope.archivedNotifications.splice(i, 1);
                }
            });
        }

        removeDuplicates(correlationId);

        $rootScope.archivedNotifications.unshift(archiveObject);
        if (!$rootScope.$$phase) {
            $rootScope.$evalAsync();
        }
    }

}
