export default /*@ngInject*/ function ($scope, configurationDataService) {

    getRoleDescConfigValue();

    async function getRoleDescConfigValue() {
        let response = await configurationDataService.getProductConfigurationValueByName('sap', 'IM_ShowRoleDescription');
        $scope.roleDesc = response.data.Value;
    }

    $scope.addSingleRoleToList = function (role, productName) {
        $scope.vm.ListOfAvailableSAPSingleRoles[productName].forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfAvailableSAPSingleRoles[productName].splice(i, 1);
                $scope.vm.ListOfSelectedSAPSingleRoles[productName].push(row);
            }
        });
        $scope.vm.IsRisksReviewed = null;
    };

    $scope.deleteSelectedSingleRole = function (role, productName) {
        $scope.vm.ListOfSelectedSAPSingleRoles[productName].forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfSelectedSAPSingleRoles[productName].splice(i, 1);
                if (role.IsAssignable) {
                    $scope.vm.ListOfAvailableSAPSingleRoles[productName].push(row);
                }
                if (role.IsEmergencyAccess) {
                    // Clear out selected TCodes
                    $scope.vm.ListOfAvailableSAPRoleObjects[productName] = null;
                    $scope.vm.ListOfSelectedSAPRoleObjects[productName] = null;
                }
            }
        });
        $scope.vm.IsRisksReviewed = null;
    };

    $scope.setSelectedRoleTemplates = function (selectedRoleTemplates) {
        $scope.vm.selectedRoleTemplates = selectedRoleTemplates;
    };
}