import { standardGridPageSize, customAutoCompleteFilter } from "../../../Shared/GlobalVariables/filterVariables";
import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, $filter, maintainProfilesDataService, helperService, isImport, existingProfiles) {

    const vm = this;

    vm.addProfile = addProfile;
    vm.cancel = cancel;
    vm.importProfiles = importProfiles;
    vm.isImport = isImport;
    vm.existingProfiles = [...existingProfiles];

    activate();

    function activate() {
        createAvailableProfilesGrid();
    }

    async function addProfile({ ProfileName, ProductId }) {
        kendo.ui.progress(vm.availableProfilesGrid.element, true);
        let postData = {
            ProfileName: ProfileName,
            ProductId: ProductId
        };

        try {
            await maintainProfilesDataService.updateProfiles(postData);
            vm.existingProfiles.push(postData);
            kendo.ui.progress(vm.availableProfilesGrid.element, false);
            await vm.availableProfilesGridDataSource.read();
        } catch {
            kendo.ui.progress(vm.availableProfilesGrid.element, false);
        }
    }

    function createAvailableProfilesGrid() {
        var availableGridColumns = [
            { title: "Profile", field: "ProfileName", filterable: customAutoCompleteFilter },
            { title: "Product", field: "ProductName", filterable: customAutoCompleteFilter },
            { template: "<a ng-click='mpmvm.addProfile(dataItem)'><i class='fa fa-plus pull-right text-success'></i></a>", title: " ", width: 20 }
        ];

        vm.availableProfilesGridOptions = helperService.setMainGridOptions(availableGridColumns, null);
        vm.availableProfilesGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let { data } = await maintainProfilesDataService.getProfiles();
                    data = $filter("orderBy")(data, "ProfileName");
                    data = data.filter(profile => !vm.existingProfiles.some(existingProfile => existingProfile.ProfileName === profile.ProfileName && existingProfile.ProductId === profile.ProductId));
                    options.success(data);
                }
            }
        });
    }

    async function importProfiles(files) {
        vm.importPending = new createSpinner();
        let fd = new FormData();
        files.forEach(file => {
            fd.append(file.file.name, file.file);
        });
        if (files.length > 0) {
            vm.disableButton = true;
            try {
                await maintainProfilesDataService.importProfiles(fd);
                await helperService.successfulSaveButton(vm.importPending);
                $uibModalInstance.close();
            } catch {
                vm.importPending.loadingValue = false;
                vm.disableButton = false;
            }
        }
    }

    function cancel() {
        $uibModalInstance.close();
    }

}