import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, companyTemplatesData, helperService, actionString, companyTemplates, selectedTemplate) {

    const vm = this;

    vm.actionString = actionString;

    vm.cancel = cancel;

    if (selectedTemplate) {
        vm.selectedTemplate = selectedTemplate;
        vm.templateName = selectedTemplate.Name;
    }

    vm.templateNamesMatch = templateNamesMatch;
    vm.submitForm = submitForm;

    async function createCompanyTemplate() {
        vm.loading = new createSpinner();
        let postData = { Name: vm.templateName };
        try {
            let response = await companyTemplatesData.createCompanyTemplate(postData);
            let newTemplateId = response.data;
            vm.killModal = true;
            await helperService.successfulSaveButton(vm.loading);
            $uibModalInstance.close(newTemplateId);
        } catch {
            vm.loading.loadingValue = false;
        }
    }

    function templateNamesMatch() {
        let match = (t) => t.Name.toLowerCase() === vm.templateName.toLowerCase();
        return companyTemplates.some(match);
    }

    async function renameCompanyTemplate() {
        vm.loading = new createSpinner();

        let putData = {
            Name: vm.templateName,
            Id: vm.selectedTemplate.Id
        };

        try {
            await companyTemplatesData.renameCompanyTemplate(putData.Id, putData);
            vm.killModal = true;
            await helperService.successfulSaveButton(vm.loading);
            $uibModalInstance.close();
        } catch {
            vm.loading.loadingValue = false;
        }
    }

    function submitForm() {
        if (actionString === 'Create') {
            createCompanyTemplate();
        } else {
            renameCompanyTemplate();
        }
    }


    function cancel() {
        $uibModalInstance.dismiss();
    }
}