import { standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($filter, $uibModalInstance, partitionKey, axVersion, helperService, existingUserSearchService) {

    const vm = this;

    vm.title = 'Person Search';
    vm.filterUsersToggle = true;
    vm.selectedPerson = null;

    vm.doneSearchingPerson = doneSearchingPerson;
    vm.changePersonRowSelected = changePersonRowSelected;
    vm.selectPerson = selectPerson;
    vm.filterPerson = filterPerson;
    vm.cancel = cancel;

    vm.ax7PersonGridColumns = [
        { field: "PersonName", title: "Name", filterable: false },
        { field: "PersonNameAlias", title: "Alias", filterable: false },
        { field: "PartyNumber", title: "Party Number", filterable: false },
        { field: "PersonPrimaryEmail", title: "Email", filterable: false }
    ];

    function doneSearchingPerson() {
        if (vm.ax7PersonSearchString === '' || vm.ax7PersonSearchString === null) {

            var raw = vm.ax7PersonGridDataSource.data();
            var length = raw.length;

            let item, i;
            for (i = length - 1; i >= 0; i--) {

                item = raw[i];
                vm.ax7PersonGridDataSource.remove(item);

            }
        } else {
            vm.ax7PersonGridDataSource.read();
        }
    }

    vm.ax7PersonGridOptions = helperService.setMainGridOptions(vm.ax7PersonGridColumns, 350);
    vm.ax7PersonGridOptions.autoBind = false;

    vm.ax7PersonGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: async (options) => {
                let response;
                try {
                    if (axVersion) {
                        response = await existingUserSearchService.getFilteredAX2012Person(vm.ax7PersonSearchString, partitionKey);
                    } else {
                        response = await existingUserSearchService.getFilteredAX7Person(vm.ax7PersonSearchString);
                    }

                    response.data = $filter('orderBy')(response.data, 'PersonName');
                    options.success(response.data);
                } catch (err) {
                    options.error();
                }
            }
        }
    });

    function changePersonRowSelected(kendoEvent) {
        let grid = kendoEvent.sender;
        vm.selectedPerson = grid.dataItem(grid.select());
    }

    function selectPerson() {
        if (vm.selectedPerson) {
            vm.personObject = { ...vm.selectedPerson };
        }
        vm.ax7PersonSearchString = null;
        $uibModalInstance.close(vm.personObject);
    }

    function filterPerson() {
        if (vm.ax7PersonGridDataSource._data.length > 0) {
            vm.ax7PersonGridDataSource.read();
        }
    }

    function cancel() { $uibModalInstance.close(); }

}