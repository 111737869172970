import requesterGroupsController from "./requesterGroups.controller";

/* requesterGroups.component.js */

/**
 * @desc component for displaying and managing requester groups
 * @example <requester-groups></requester-groups>
 */

const requesterGroups = {
    controller: requesterGroupsController,
    controllerAs: 'rgvm',
    templateUrl: '/App/Components/RequesterGroups/requesterGroups.tmpl.html'
};

export default requesterGroups;