import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($http, $uibModalInstance, helperService, request, errorsDataService, $scope) {

    const vm = this;

    vm.request = { ...request };
    vm.saveRequestExpirationDate = saveRequestExpirationDate;
    vm.dismiss = dismiss;

    vm.minStartDate = new Date();


    async function saveRequestExpirationDate() {
        vm.saving = new createSpinner();

        let putData = {
            RequestId: request.Id,
            Version: request.Version,
            ExpirationDate: vm.request.ExpirationDate ? new Date(vm.request.ExpirationDate).toUTCString() : null
        };

        try {
            await $http.put(`${apiUrl}api/identitymanager/requests/${request.Id}/version/${request.Version}/expirationdate`, putData);

            await helperService.successfulSaveButton(vm.saving);
            $uibModalInstance.close();
        } catch (err) {
            vm.saving.loadingValue = false;
            errorsDataService.catch(err);
        }
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }

}