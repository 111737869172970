export default /*@ngInject*/ function ($http) {

    const vm = this,
        availableProducts = ["NS", "SAP", "GP", "NAV", "AX", "AX5", "AX7", "SL", "ORFC", "D365Bc", "WD", "SF"];

    activate();

    async function activate() {
        let { data: products } = await $http.get(apiUrl + '/api/core/modules/' + 3000 + '/products');

        vm.productsDataSource = products.filter(product => product.IsCustom || availableProducts.find(availableProduct => product.Type === availableProduct));
    }
}