export default /*@ngInject*/ function ($scope) {

    //If $scope variable doesnt exist in child it looks in parent

    $scope.addLicenseToList = function (license) {
        $scope.vm.ListOfAvailableAADLicenses.forEach(function (row, i) {
            if (row.SkuId === license.SkuId) {
                $scope.vm.ListOfAvailableAADLicenses.splice(i, 1);
                $scope.vm.ListOfSelectedAADLicenses.push(license);
            }
        });
        $scope.vm.IsRisksReviewed = null;
    };

    $scope.deleteSelectedLicense = function (license) {
        $scope.vm.ListOfSelectedAADLicenses.forEach(function (row, i) {
            if (row.SkuId === license.SkuId) {
                $scope.vm.ListOfSelectedAADLicenses.splice(i, 1);
                $scope.vm.ListOfAvailableAADLicenses.push(row);
            }
        });

        $scope.vm.IsRisksReviewed = null;
    };

}