export default /*@ngInject*/ function ($http, errorsDataService) {
    const factory = {
        getRequests,
        getStatuses
    };

    return factory;

    async function getRequests(url, data, config) {
        try {
            return await $http.post(url, data, config);
        } catch (err) {
            sendError(err);
        }
    }

    async function getStatuses() {
        try {
            return await $http.get(`${apiUrl}api/identitymanager/requests/statuses`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}