/* roleSecurityProfile.component.js */

import roleSecurityProfileController from "./roleSecurityProfile.controller";

/**
 * @desc component for opening and viewing Role Security Profile
 * @example <role-security-profile></role-security-profile>
 */

const roleSecurityProfile = {
    bindings: {
        role: '<'
    },
    controller: roleSecurityProfileController,
    controllerAs: 'rspvm',
    template:
        `<a ng-click=rspvm.openRoleSecurityProfile(rspvm.role)>
            <i class="fa fa-info-circle fa-lg m-l-xs m-t-xs text-info"
                title="View role profile">
            </i>
         </a>`
};

export default roleSecurityProfile;