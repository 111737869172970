import createSpinner from "../../../Shared/Functions/createSpinner";


export default /*@ngInject*/ function ($rootScope, $http, helperService, errorsDataService) {

    const vm = this,
        defaultRoleTemplate = {
            Id: null,
            Name: "-- Select A Template --"
        },
        emailMappingADProducts = ['NS', 'ORFC', 'OR', 'SAP', 'PS', 'SF'],
        emailMappingAADProducts = ['AX7', 'NS', 'ORFC', 'OR', 'SAP', 'PS', 'SF'];

    vm.hasNS = null;
    vm.updateReminderSchedule = updateReminderSchedule;
    vm.updateIdentityManagerSettingsConfigValues = updateIdentityManagerSettingsConfigValues;
    vm.getReminderSchedule = getReminderSchedule;
    vm.equalityCheck = equalityCheck;
    vm.toggleAutomaticDelegations = toggleAutomaticDelegations;
    vm.configValuesHaveChanged = configValuesHaveChanged;
    vm.refreshConfig = refreshConfig;
    vm.configNames = [
        'IM_AutoApproveRoleRevokes_AC',
        'IM_AutoApproveRoleRevokes_IM',
        'IM_AllowApproverEqualsRequesterOnAC',
        'IM_ReminderThreshold',
        'IM_DaysBeforeExpirationReminder',
        'IM_DisableObjectLevelRiskAnalysis',
        'IM_ExpirationReminderEnabled',
        'IM_ApprovalReminderEnabled',
        'IM_SODThresholdNewOnly',
        'NS_ImRequireExistingEmployee',
        'NS_ImTerminateOption',
        'NS_ImTerminateSetReleaseDate',
        'IM_AutomaticDelegationDaysThreshold',
        'IM_AutoApproveAPIAppRequests',
        'IM_ApplyApprovalRulesToRevokes',
        'IM_RequireTicketAssociation',
        'MaxFirefighterAccessPeriod',
        'AX_ImDisableOnTerminate',
        'AX7_ImDisableOnTerminate',
        'GP_ImDisableOnTerminate',
        'NAV_ImDisableOnTerminate',
        'ORFC_ImDisableOnTerminate',
        'SAP_ImDisableOnTerminate',
        'AAD_ImDisableOnTerminate',
        'IM_RequireEmergencyAccessPreApproval',
        'IM_DefaultRoleTemplateForNewUserRequestsEnabled',
        'IM_DefaultRoleTemplateForNewUserRequests',
        'IM_DefaultExpirationEnabled',
        'IM_DefaultExpirationDays',
        'IM_PreventSelfReview',
        'IM_RequireNoteOnCancelledRequests',
        'IM_ProvisionRequestItemsOnApproval',
        'IM_BypassRiskAnalysis',
        'IM_RoleTemplatesOnlyEnabled',
        'IM_NewRolesAssignableAfterImport',
        'EmergencyAccessApproversGroup',
        'IM_ADFieldForEmailMapping',
        'IM_AADFieldForEmailMapping',
        'IM_RestrictRoleRequestsEA',
        'IM_SendRequestNotificationsToAffectedUsers',
        'IM_SendRequestCreatedNotificationToRequester'
    ];
    vm.roleTemplatesDataSource = [];
    vm.defaultRoleTemplateInvalid = defaultRoleTemplateInvalid;

    vm.$onInit = activate;

    async function activate() {
        vm.productsDataSource = vm.products;
        vm.config = vm.currentConfig;
        vm.origConfig = vm.originalConfig;

        await checkForNS();
        await checkForSap();
        await getReminderSchedule();
        await getIMApplications();
        await getRoleTemplates();
        checkForSF();
        if (vm.config.IM_AutomaticDelegationDaysThreshold) {
            vm.automaticDelegations = true;
        }

        vm.config.IM_DefaultExpirationDays = Number(vm.config.IM_DefaultExpirationDays);
        vm.origConfig.IM_DefaultExpirationDays = Number(vm.origConfig.IM_DefaultExpirationDays);
        vm.showEmailMappingADFieldsConfiguration = () => vm.products.some(product => emailMappingADProducts.includes(product.ID)) && vm.currentConfig['AD_Active'] === "1";
        vm.showEmailMappingAADFieldsConfiguration = () => vm.products.some(product => emailMappingAADProducts.includes(product.ID)) && vm.currentConfig['AAD_Active'] === "1";
    }
    async function updateReminderSchedule() {
        vm.updateInProgress = new createSpinner();

        const postData = {
            "StartTime": vm.reminderStartTime.toUTCString(),
            "TimeZone": $rootScope.applicationTimeZone
        };

        if (vm.config.IM_ExpirationReminderEnabled === "1" || vm.config.IM_ApprovalReminderEnabled === "1") {
            try {
                await $http.post(`${apiUrl}api/identitymanager/notifications/schedule`, postData);
                await getReminderSchedule();
                helperService.successfulSaveButton(vm.updateInProgress);
            } catch (err) {
                errorsDataService.catch(err);
                vm.updateInProgress.loadingValue = false;
            }
        }

        if (vm.config.IM_ExpirationReminderEnabled === "0" && vm.config.IM_ApprovalReminderEnabled === "0") {
            if (vm.reminderSchedule) {
                try {
                    await $http.delete(`${apiUrl}api/identitymanager/notifications/schedule`);
                    await getReminderSchedule();
                    vm.updateInProgress.loadingValue = false;
                } catch (err) {
                    errorsDataService.catch(err);
                    vm.updateInProgress.loadingValue = false;
                }
            }
        }
    }

    //Check if NS exists for Terminate Request Processing IM section
    function checkForNS() {
        vm.hasNS = vm.productsDataSource.some(product => product.ID === 'NS');
    }

    //Check if SAP exists for Maximum Firefighter Access Period IM section
    function checkForSap() {
        vm.hasSap = vm.productsDataSource.some(product => product.Type === 'SAP');
    }

    // check for only SF as a product in order to hide Default Role Templates until it is ready for SF
    function checkForSF() {
        vm.salesforceOnly = vm.productsDataSource.every(product => product.Type === 'SF');
    }

    async function updateIdentityManagerSettingsConfigValues() {
        vm.updateSettingsPending = new createSpinner();

        let list = [
            { Name: "IM_AutoApproveRoleRevokes_AC", Encrypted: false },
            { Name: "IM_AutoApproveRoleRevokes_IM", Encrypted: false },
            { Name: "IM_AllowApproverEqualsRequesterOnAC", Encrypted: false },
            { Name: "IM_ReminderThreshold", Encrypted: false },
            { Name: "IM_DaysBeforeExpirationReminder", Encrypted: false },
            { Name: "IM_ExpirationReminderEnabled", Encrypted: false },
            { Name: "IM_ApprovalReminderEnabled", Encrypted: false },
            { Name: "IM_PreventSelfReview", Encrypted: false },
            { Name: "IM_DefaultExpirationDays", Encrypted: false },
            { Name: "IM_DefaultExpirationEnabled", Encrypted: false },
            { Name: "IM_DisableObjectLevelRiskAnalysis", Encrypted: false },
            { Name: "IM_SODThresholdNewOnly", Encrypted: false },
            { Name: "IM_AutomaticDelegationDaysThreshold", Encrypted: false },
            { Name: "IM_AutoApproveAPIAppRequests", Encrypted: false },
            { Name: "IM_ApplyApprovalRulesToRevokes", Encrypted: false },
            { Name: "IM_DefaultRoleTemplateForNewUserRequestsEnabled", Encrypted: false },
            { Name: "IM_DefaultRoleTemplateForNewUserRequests", Encrypted: false },
            { Name: "IM_RequireTicketAssociation", Encrypted: false },
            { Name: "IM_RequireEmergencyAccessPreApproval", Encrypted: false },
            { Name: "IM_ProvisionRequestItemsOnApproval", Encrypted: false },
            { Name: "IM_RequireNoteOnCancelledRequests", Encrypted: false },
            { Name: "IM_RoleTemplatesOnlyEnabled", Encrypted: false },
            { Name: "NS_ImRequireExistingEmployee", Encrypted: false },
            { Name: "NS_ImTerminateOption", Encrypted: false },
            { Name: "NS_ImTerminateSetReleaseDate", Encrypted: false },
            { Name: "MaxFirefighterAccessPeriod", Encrypted: false },
            { Name: "AX_ImDisableOnTerminate", Encrypted: false },
            { Name: "AX7_ImDisableOnTerminate", Encrypted: false },
            { Name: "NAV_ImDisableOnTerminate", Encrypted: false },
            { Name: "GP_ImDisableOnTerminate", Encrypted: false },
            { Name: "ORFC_ImDisableOnTerminate", Encrypted: false },
            { Name: "SAP_ImDisableOnTerminate", Encrypted: false },
            { Name: "IM_NewRolesAssignableAfterImport", Encrypted: false },
            { Name: "IM_BypassRiskAnalysis", Encrypted: false },
            { Name: "IM_ADFieldForEmailMapping", Encrypted: false },
            { Name: "IM_AADFieldForEmailMapping", Encrypted: false },
            { Name: "IM_RestrictRoleRequestsEA", Encrypted: false },
            { Name: "IM_SendRequestNotificationsToAffectedUsers", Encrypted: false },
            { Name: "IM_SendRequestCreatedNotificationToRequester", Encrypted: false }
        ];

        try {
            let value = vm.config;
            let origVal = vm.origConfig;
            await vm.updateConfiguration({ list, value, origVal });
            if (vm.config.IM_ExpirationReminderEnabled === "0" && vm.config.IM_ApprovalReminderEnabled === "0") {
                updateReminderSchedule();
            }
            await helperService.successfulSaveButton(vm.updateSettingsPending);
            activate();
        } catch {
            vm.updateSettingsPending.loadingValue = false;
        }

    }

    async function getReminderSchedule() {
        try {
            let { data } = await $http.get(apiUrl + 'api/identitymanager/notifications/schedule');
            vm.reminderSchedule = data;
            vm.savedReminderStartTime = new Date(data.StartTime);
            vm.reminderStartTime = vm.savedReminderStartTime;
            vm.equal = true;

        } catch (err) {
            vm.reminderSchedule = null;
            vm.reminderStartTime = new Date();

        }
    }

    async function getIMApplications() {
        let { data } = await $http.get(`${apiUrl}api/identitymanager/applications`);

        vm.imApplications = data;

        vm.universalOnly = vm.imApplications.every(product => product.Name === 'Universal');
    }

    async function getRoleTemplates() {
        let { data } = await $http.get(`${apiUrl}api/universal/roletemplates`);

        vm.roleTemplatesDataSource = [defaultRoleTemplate, ...data];
    }

    function equalityCheck() {
        vm.equal = vm.savedReminderStartTime && vm.reminderStartTime.getTime() === vm.savedReminderStartTime.getTime()
    }

    function toggleAutomaticDelegations() {
        if (!vm.automaticDelegations) {
            vm.origConfig.IM_AutomaticDelegationDaysThreshold = vm.config.IM_AutomaticDelegationDaysThreshold;
            vm.config.IM_AutomaticDelegationDaysThreshold = null;
        }
    }

    function configValuesHaveChanged(configArray) {
        return helperService.returnTrueIfValuesDiffer(configArray, vm.origConfig, vm.config);
    }

    function refreshConfig(updatedConfiguration) {
        vm.config = updatedConfiguration;
    }

    function defaultRoleTemplateInvalid() {
        return vm.config.IM_DefaultRoleTemplateForNewUserRequestsEnabled === "1" && !vm.config.IM_DefaultRoleTemplateForNewUserRequests;
    }
}