export default function () {
    return function (string, itemType) {

        function Netsuite() {
            switch (string) {
                case 'Product':
                    string = "Product";
                    break;
                case 'Company':
                    string = "Subsidiary";
                    break;
                case 'All Companies':
                    string = "All Subsidiaries";
                    break;
            }
        }

        function Intacct() {
            switch (string) {
                case 'Company':
                    string = "Company";
                    break;
                case 'All Companies':
                    string = "All Companies";
                    break;
            }
        }

        function Oracle() {
            switch (string) {
                case 'Company':
                    string = "Operating Unit";
                    break;
                case 'All Companies':
                    string = "All Operating Units";
                    break;
            }
        }

        function Salesforce() {
            switch (string) {
                case 'Company':
                    string = "Organizations";
                    break;
                case 'All Companies':
                    string = "All Organizations";
                    break;
            }
        }

        switch (itemType) {
            case 'NS':
                Netsuite();
                break;
            case 'INT':
                Intacct();
                break;
            case 'OR':
                Oracle();
                break;
            case 'SF':
                Salesforce();
                break;
        }

        return string;
    };
}
