import controller from "./sapConnectionSettings.controller";

/* sapConnectionSettings.component.js */

/**
 * @desc component for configuring sap product connection settings.
 * @example <sap-connection-settings></sap-connection-settings>
 */

const sapConnectionSettings = {
    bindings: {
        product: '<',
        currentConfig: '=',
        originalConfig: '<',
        configUpdate: '<'
    },
    controller: controller,
    controllerAs: 'scsvm',
    templateUrl: '/App/Components/Connections/SapConnection/Settings/sapConnectionSettings.tmpl.html'
};

export default sapConnectionSettings;