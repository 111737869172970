import usersController from "./users.controller";

/* users.component.js */

/**
 * @desc component for displaying and managing tenant users
 * @example <users></users>
 */

var users = {
    controller: usersController,
    controllerAs: 'uvm',
    templateUrl: '/App/Components/Users/users.html'
};

export default users;