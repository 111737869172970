export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getFilteredAzureADUsers,
        getFilteredADUsers,
        getFilteredAX7Person,
        getFilteredAX2012Person
    };

    return service;

    async function getFilteredAzureADUsers(searchString, filterBoolean) {
        try {
            return await $http({
                method: 'GET',
                params: {
                    "criteria": searchString,
                    "includeInactive": filterBoolean
                },
                url: apiUrl + 'api/identitymanager/aad/users/search'
            });
        } catch(err) {
            showError(err);
            sendError(err);
        }
    }

    async function getFilteredADUsers(searchString, filterBoolean) {
        try {
            return await $http({
                method: 'GET',
                params: {
                    "criteria": searchString,
                    "includeInactive": filterBoolean
                },
                url: apiUrl + 'api/identitymanager/activedirectory/users/search'
            });
        } catch(err) {
            showError(err);
            sendError(err);
        }
    }

    async function getFilteredAX7Person(searchString) {
        try {
            return await $http({
                method: 'GET',
                params: {
                    "criteria": searchString

                },
                url: apiUrl + 'api/identitymanager/ax7/persons/search'
            });
        } catch(err) {
            showError(err);
            sendError(err);
        }
    }

    async function getFilteredAX2012Person(searchString, partitionKey) {
        let params = { "criteria": searchString };
        if (partitionKey) {
            params["partitionKey"] = partitionKey;
        }
        try {
            return await $http({
                method: 'GET',
                params: params,
                url: apiUrl + 'api/identitymanager/ax/persons/search'
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }

}