export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getOptionSets,
        getOptionSetValuesByOptionSet,
        editOptionSetValue,
        addOptionSetValue
    };

    return service;

    async function getOptionSets() {
        try {
            return await $http.get(`${apiUrl}api/audittrail/ns/v2/optionsets`);
        } catch (err) {
            showError(err);
        }
    }

    async function getOptionSetValuesByOptionSet(optionSetId) {
        try {
            return await $http.get(`${apiUrl}api/audittrail/ns/v2/optionsets/${optionSetId}/values`)
        } catch (err) {
            showError(err);
        }
    }

    async function editOptionSetValue(optionSetId, data) {
        try {
            return await $http.put(`${apiUrl}api/audittrail/ns/v2/optionsets/${optionSetId}/values`, data)
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addOptionSetValue(optionSetId, data) {
        try {
            return await $http.post(`${apiUrl}api/audittrail/ns/v2/optionsets/${optionSetId}/values`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }


}