import createSpinner from "../../../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter, standardGridPageSize, customAutoCompleteFilterEqualToOnly, customDatePickerFilter } from "../../../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../../../Shared/kendo.module";

export default /*@ngInject*/ function ($uibModalInstance, helperService, nsAtDocumentationService, $http, documentation) {

    const vm = this;
    vm.documentation = documentation;
    vm.cancel = cancel;
    vm.isLinked = isLinked;
    vm.linkTicketToDocumenation = linkTicketToDocumenation;
    vm.linkCustomTicket = linkCustomTicket;
    vm.unlinkTicketFromDocumentation = unlinkTicketFromDocumentation;
    vm.gridView = "LinkedChangeTickets";

    let linkedJiraTickets = [];
    let linkedServiceNowTickets = [];
    let linkedZendeskTickets = [];

    function activate() {
        configureGrids();
    }

    activate();

    function cancel() { $uibModalInstance.close(); }

    function configureGrids() {

        const statusTemplate = "<span class='review-status-capitalized'>{{dataItem.Status}}</span>";

        //-----LINKED TICKETS-----

        const ticketTemplate = "<span ng-if='dataItem.TicketProvider'>{{ dataItem.TicketProvider }} &nbsp; <a href='{{dataItem.TicketUrl}}' target='_blank'>{{dataItem.TicketId}}</a></span>";

        const linkedChangeTicketsGridColumns = [
            { field: "TicketId", title: "Ticket", template: ticketTemplate, filterable: customAutoCompleteFilter },
            { field: "TicketSubject", title: "Subject", filterable: customAutoCompleteFilter },
            { field: "TicketDescription", title: "Description", template: "{{ dataItem.TicketDescription }}", filterable: customAutoCompleteFilter },
            { field: "TicketStatus", title: "Status", filterable: customAutoCompleteFilter },
            { field: "TicketRequester", title: "Requester", filterable: customAutoCompleteFilter },
            { field: "TicketSubmitter", title: "Submitter", filterable: customAutoCompleteFilter },
            { template: `<a ng-click="ctmvm.unlinkTicketFromDocumentation(dataItem)"><i class="glyphicon glyphicon-trash text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 100 }
            
        ];

        vm.linkedChangeTicketsGridOptions = helperService.setMainGridOptions(linkedChangeTicketsGridColumns, 300);
        vm.linkedChangeTicketsGridOptions.noRecords = {
            template: "<div class='no-data-to-display-message'>No Linked Change Tickets exist for this Documentation</div>"
        };

        vm.linkedChangeTicketsGridDataSource = new kendo.data.DataSource({
            schema: halSchemaKendoGrid,
            pageSize: standardGridPageSize,
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: async options => {
                    let linkedTicketsData = helperService.prepareSortsAndFilters(options);
                    const params = { 'pagesize': options.data.pageSize, 'page': options.data.page };
                    let response = await nsAtDocumentationService.getDocumentationLinkedTicketsList(documentation.Id, linkedTicketsData, params);
                    response._embedded.reportData.forEach(ticket => {
                        switch (ticket.TicketProvider) {
                            case "Jira":
                                linkedJiraTickets.push(ticket);
                                break;
                            case "ServiceNow":
                                linkedServiceNowTickets.push(ticket);
                                break;
                            case "Zendesk":
                                linkedZendeskTickets.push(ticket);
                                break;
                            default:
                                break;
                        }
                    });
                    options.success(response);
                }
            }
        });

        //-----END LINKED TICKETS-----

        //-----JIRA TICKETS-----     

        const jiraTicketsGridColumns = [
            { field: "Status", title: "Status", template: statusTemplate, filterable: false },
            { field: "Id", title: "Id", filterable: customAutoCompleteFilterEqualToOnly },
            { field: "Subject", title: "Subject", filterable: customAutoCompleteFilter },
            { field: "Description", title: "Description", template: "{{ dataItem.Description }}", filterable: customAutoCompleteFilter }, // use template to handle possible issues with characters in description field
            { field: "CreatedDate", title: "Date Created", template: "{{ dataItem.CreatedDate | date:'short'}}", filterable: customDatePickerFilter },
            { title: ' ', template: "<span ng-disabled='ctmvm.actionInProgress || ctmvm.isLinked(dataItem)' class='btn btn-sm btn-secondary fixed-btn-width' ng-click='ctmvm.linkTicketToDocumenation(dataItem)'>Link</span>", width: 90 }
        ];

        vm.jiraTicketsGridOptions = helperService.setMainGridOptions(jiraTicketsGridColumns, 400);
        vm.jiraTicketsGridOptions.selectable = false;
        vm.jiraTicketsGridOptions.pageable = false;
        vm.jiraTicketsGridOptions.filterable = {
            mode: 'row',
            operators: {
                string: {
                    contains: "Contains"
                }
            }
        };

        vm.jiraTicketsGridDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            schema: {
                model: {
                    fields: {
                        CreatedDate: { type: "date" }
                    }
                }
            },
            transport: {
                read: async options => {
                    const sortsAndFilters = helperService.prepareSortsAndFilters(options);

                    const jiraTicketsPostBody = {
                        "Criteria": sortsAndFilters.Filters
                    };

                    try {
                        let response = await $http.post(apiUrl + 'api/core/tickets/jira/search/', jiraTicketsPostBody);
                        vm.TotalTicketCount = response.data.Count;
                        options.success(response.data.Tickets);
                    } catch (err) {
                        options.error(err);
                    }
                }
            }
        });

        //-----END JIRA TICKETS-----

        //-----SERVICENOW TICKETS-----

        const serviceNowTicketsGridColumns = [
            { field: "Status", title: "Status", template: statusTemplate, filterable: false },
            { field: "Id", title: "Id", filterable: customAutoCompleteFilterEqualToOnly },
            { field: "Subject", title: "Subject", filterable: customAutoCompleteFilter },
            { field: "Description", title: "Description", template: "{{ dataItem.Description }}", filterable: customAutoCompleteFilter }, // use template to handle possible issues with characters in description field
            { field: "CreatedDate", title: "Date Created", template: "{{ dataItem.CreatedDate | date:'short'}}", filterable: customDatePickerFilter },
            { title: ' ', template: "<span ng-disabled='ctmvm.actionInProgress || ctmvm.isLinked(dataItem)' class='btn btn-sm btn-secondary fixed-btn-width' ng-click='ctmvm.linkTicketToDocumenation(dataItem)'>Link</span>", width: 90 }
        ];

        vm.serviceNowTicketsGridOptions = helperService.setMainGridOptions(serviceNowTicketsGridColumns, 400);
        vm.serviceNowTicketsGridOptions.selectable = false;
        vm.serviceNowTicketsGridOptions.pageable = false;
        vm.serviceNowTicketsGridOptions.filterable = {
            mode: 'row',
            operators: {
                string: {
                    contains: "Contains"
                }
            }
        };

        vm.serviceNowTicketsGridDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            schema: {
                model: {
                    fields: {
                        CreatedDate: { type: "date" }
                    }
                }
            },
            transport: {
                read: async options => {
                    const sortsAndFilters = helperService.prepareSortsAndFilters(options);

                    const serviceNowTicketsPostBody = {
                        "Criteria": sortsAndFilters.Filters
                    };

                    try {
                        let response = await $http.post(apiUrl + 'api/core/tickets/servicenow/search/', serviceNowTicketsPostBody);
                        vm.TotalTicketCount = response.data.Count;
                        options.success(response.data.Tickets);
                    } catch (err) {
                        options.error(err);
                    }
                }
            }
        });

        //-----END SERVICENOW TICKETS-----

        //-----ZENDESK TICKETS-----

        const zendeskTicketsGridColumns = [
            { field: "Status", title: "Status", template: statusTemplate, filterable: customAutoCompleteFilterEqualToOnly, width: 80 },
            { field: "Id", title: "Id", filterable: customAutoCompleteFilter, width: 100 },
            { field: "Subject", title: "Subject", filterable: customAutoCompleteFilter },
            { field: "Description", title: "Description", template: "{{ dataItem.Description }}", filterable: customAutoCompleteFilter }, // use template to handle possible issues with characters in description field
            { field: "CreatedDate", title: "Date Created", template: "{{ dataItem.CreatedDate | date:'short'}}", filterable: customDatePickerFilter },
            { title: ' ', template: "<span ng-disabled='ctmvm.actionInProgress || ctmvm.isLinked(dataItem)' class='btn btn-sm btn-secondary fixed-btn-width' ng-click='ctmvm.linkTicketToDocumenation(dataItem)'>Link</span>", width: 90 }
        ];

        vm.zendeskTicketsGridOptions = helperService.setMainGridOptions(zendeskTicketsGridColumns, 400);
        vm.zendeskTicketsGridOptions.selectable = false;
        vm.zendeskTicketsGridOptions.pageable = false;
        vm.zendeskTicketsGridOptions.filterable = {
            mode: 'row',
            operators: {
                string: {
                    contains: "Contains"
                }
            }
        };

        vm.zendeskTicketsGridDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            schema: {
                model: {
                    fields: {
                        CreatedDate: { type: "date" }
                    }
                }
            },
            transport: {
                read: async options => {
                    const sortsAndFilters = helperService.prepareSortsAndFilters(options);

                    const zendeskTicketsPostBody = {
                        "Criteria": sortsAndFilters.Filters
                    };

                    try {
                        let response = await $http.post(apiUrl + 'api/core/tickets/zendesk/search/', zendeskTicketsPostBody);
                        vm.TotalTicketCount = response.data.Count;
                        options.success(response.data.Tickets);
                    } catch (err) {
                        options.error(err);
                    }
                }
            }
        });
    }

    async function linkTicketToDocumenation(dataItem) {
        vm.actionInProgress = new createSpinner();

        const postData = {
            "DocumentationId": documentation.Id,
            "TicketId": dataItem.Id,
            "Provider": dataItem.Provider
        };

        try {
            await nsAtDocumentationService.linkChangeTicketToDocumentation(documentation.Id, postData);
            await helperService(vm.actionInProgress);
            vm.linkedChangeTicketsGridDataSource.read();
        } catch {
            vm.actionInProgress.loadingValue = false;
        }
    }

    function linkCustomTicket() {
        let data = {
            Id: vm.customTicketId,
            Provider: "Custom"
        };
        vm.customTicketId = "";
        linkTicketToDocumenation(data);
    }

    async function unlinkTicketFromDocumentation(dataItem) {
        vm.actionInProgress = true;

        try {
            await nsAtDocumentationService.deleteChangeTicketDocumentationLink(documentation.Id, dataItem.NsAtDocumentationTicketId);
            linkedJiraTickets = [];
            linkedServiceNowTickets = [];
            linkedZendeskTickets = [];
            vm.linkedChangeTicketsGridDataSource.read();
            vm.actionInProgress = false;
        } catch {
            vm.actionInProgress = false;
        }
    }

    function isLinked(ticket) {
        switch (ticket.Provider) {
            case "Jira":
                return linkedJiraTickets.some(t => t.TicketId === ticket.Id);
                break;
            case "ServiceNow":
                return linkedServiceNowTickets.some(t => t.TicketId === ticket.Id);
                break;
            case "Zendesk":
                return linkedZendeskTickets.some(t => t.TicketId === ticket.Id);
                break;
        }
    }


}