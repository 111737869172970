import { customAutoCompleteFilterEqualToOnly, customAutoCompleteFilter, customDatePickerFilter } from "../../../Shared/GlobalVariables/filterVariables";
import createSpinner from "../../../Shared/Functions/createSpinner";
import allSupportedProducts from '../../../Shared/GlobalVariables/ProductLookUp';

export default /*@ngInject*/ function ($scope, $uibModalInstance, helperService, $http, lastClickedDataItemRecord) {
    const vm = this;

    vm.lastClickedDataItemRecord = lastClickedDataItemRecord;

    vm.linkRecordToFreshService = linkRecordToFreshService;
    vm.unLinkRecord = unLinkRecord;
    vm.dismiss = dismiss;
    vm.daysToSearch = "30";

    activate();

    function activate() {
        vm.gridDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            schema: {
                model: {
                    fields: {
                        CreatedDate: { type: "date" }
                    }
                }
            },
            transport: {
                read: function (options) {
                    const sortsAndFilters = helperService.prepareSortsAndFilters(options);
                    const postBody = {
                        "Criteria": sortsAndFilters.Filters
                    };
                    const params = { 'days': Number(vm.daysToSearch) };
                    $http.post(apiUrl + 'api/core/tickets/freshservice/search', postBody, { params: params }).then(function (response) {
                        vm.TotalTicketCount = response.data.Count;
                        options.success(response.data.Tickets);
                    }, function (response) {
                        options.error();
                    });
                }
            }
        });

        let isCustomProduct = false;

        if (!vm.lastClickedDataItemRecord.auditLogTicket) {
            if (!allSupportedProducts.some(p => p.Type === vm.lastClickedDataItemRecord.ProductType)) {
                isCustomProduct = true;
            }

            if (vm.lastClickedDataItemRecord.ProductType === 'NS' || vm.lastClickedDataItemRecord.ProductType === 'ORFC' || vm.lastClickedDataItemRecord.ProductType === 'SF') {
                vm.AtHeaderId = vm.lastClickedDataItemRecord.Id;
            } else if (vm.lastClickedDataItemRecord.ProductType === 'GP' || vm.lastClickedDataItemRecord.ProductType === 'AX' || vm.lastClickedDataItemRecord.ProductType === 'NAV' || vm.lastClickedDataItemRecord.ProductType === 'SL' || vm.lastClickedDataItemRecord.ProductType === 'AX5' || isCustomProduct) {
                vm.AtHeaderId = vm.lastClickedDataItemRecord.TableLogID;
            } else if (vm.lastClickedDataItemRecord.ProductType === 'SAP') {
                vm.AtHeaderId = vm.lastClickedDataItemRecord.ChangeNumber;
            } else if (vm.lastClickedDataItemRecord.ProductType === 'D365Bc') {
                vm.AtHeaderId = vm.lastClickedDataItemRecord.entryNo;
            } else {
                vm.AtHeaderId = vm.lastClickedDataItemRecord.RecId;
            }
        }

        if (vm.lastClickedDataItemRecord.TicketProvider == 'Freshservice') {
            $http.get(apiUrl + 'api/core/tickets/' + 'FreshService' + '/' + vm.lastClickedDataItemRecord.TicketId).then(function (response) {
                vm.populatedTicket = response.data;
            }, function (response) {
                helperService.showErrorMessage(response.data);
            });
        }

        const statusTemplate = "<span class='review-status-capitalized'>{{dataItem.Status}}</span>";

        const columnsFromVM = [
            { field: "Status", title: "Status", template: statusTemplate, filterable: false },
            { field: "Id", title: "Id", filterable: customAutoCompleteFilterEqualToOnly },
            { field: "Subject", title: "Subject", filterable: customAutoCompleteFilter },
            { field: "Description", title: "Description", template: "{{ dataItem.Description }}", filterable: customAutoCompleteFilter }, // use template to handle possible issues with characters in description field
            { field: "CreatedDate", title: "Date Created", template: "{{ dataItem.CreatedDate | date:'short'}}", filterable: customDatePickerFilter },
            { title: ' ', template: "<span ng-disabled='fstmvm.actionInProgress' class='btn btn-sm btn-secondary fixed-btn-width' ng-click='fstmvm.linkRecordToFreshService(dataItem)'>Link</span>", width: 90 }
        ];


        vm.gridOptions = helperService.setMainGridOptions(columnsFromVM, 400);
        vm.gridOptions.selectable = false;
        vm.gridOptions.pageable = false;
        vm.gridOptions.filterable = {
            mode: 'row',
            operators: {
                string: {
                    contains: "Contains"
                }
            }
        };
    }

    async function linkRecordToFreshService(dataItem) {
        vm.actionInProgress = new createSpinner();

        const productType = vm.lastClickedDataItemRecord.ProductType;

        let postData = {
            "TicketId": dataItem.Id,
            "Provider": 'FreshService'
        };
           
        if (vm.lastClickedDataItemRecord.auditLogTicket) {
            postData.AuditId = vm.lastClickedDataItemRecord.AuditId;
            postData.UserId = vm.lastClickedDataItemRecord.UserId;
            postData.ObjectName = vm.lastClickedDataItemRecord.ObjectName;
        } else {
            postData.AtHeaderId = vm.AtHeaderId;
            postData.ProductId = vm.lastClickedDataItemRecord.ProductId;
            postData.ProductType = productType;
        }

        try { 
            if (vm.lastClickedDataItemRecord.auditLogTicket) {
                await $http.post(apiUrl + 'api/core/auditlogs/associateticket', postData);
            } else {
                await $http.post(apiUrl + 'api/audittrail/tickets', postData);

                if (productType === 'OR' || productType === 'AX7' || productType === 'SAP') {
                    await buildHeaderPost(productType);
                }
            }

            await helperService.successfulSaveButton(vm.actionInProgress);
            $uibModalInstance.close();
        } catch (response) {
            helperService.showErrorMessage(response.data);
            vm.actionInProgress.loadingValue = false;
        }
    }

    function buildHeaderPost(productType) {
        let headerPostData;
        let headerEndpoint = '';

        if (productType === 'OR') {
            headerPostData = {
                "RecId": vm.lastClickedDataItemRecord.RecId,
                "ChangeDate": vm.lastClickedDataItemRecord.ChangeDate,
                "Template": vm.lastClickedDataItemRecord.Template,
                "TableName": vm.lastClickedDataItemRecord.TableName,
                "Event": vm.lastClickedDataItemRecord.Event,
                "UserName": vm.lastClickedDataItemRecord.User,
                "Responsibility": vm.lastClickedDataItemRecord.Responsibility,
                "Application": vm.lastClickedDataItemRecord.Application
            };

            headerEndpoint = 'api/audittrail/or/tickets';
        }
        else if (productType === 'AX7') {
            headerPostData = {
                "RecId": vm.lastClickedDataItemRecord.RecId,
                "ObjRecId": vm.lastClickedDataItemRecord.ObjRecId,
                "ModifiedOn": vm.lastClickedDataItemRecord.ModifiedOn,
                "TableName": vm.lastClickedDataItemRecord.TableName,
                "Event": vm.lastClickedDataItemRecord.Event,
                "Description": vm.lastClickedDataItemRecord.Description,
                "ModifiedBy": vm.lastClickedDataItemRecord.ModifiedBy
            };

            headerEndpoint = 'api/audittrail/ax7/tickets';
        }
        else if (productType === 'SAP') {
            headerPostData = {
                "ChangeNumber": vm.lastClickedDataItemRecord.ChangeNumber,
                "Template": vm.lastClickedDataItemRecord.Template,
                "ChangeDate": vm.lastClickedDataItemRecord.ChangeDate,
                "TableName": vm.lastClickedDataItemRecord.TableName,
                "Event": vm.lastClickedDataItemRecord.Event,
                "UserName": vm.lastClickedDataItemRecord.User
            };

            headerEndpoint = 'api/audittrail/sap/tickets';
        }

        $http.post(apiUrl + headerEndpoint, headerPostData).then(_ => {
            return;
        }, function (response) {
            helperService.showErrorMessage(response.data);
            vm.actionInProgress = false;
        });
    }

    async function unLinkRecord () {
        vm.actionInProgress = true;

        try {
            if (vm.lastClickedDataItemRecord.auditLogTicket) {
                const putData = {
                    "AuditId": vm.lastClickedDataItemRecord.AuditId,
                    "UserId": vm.lastClickedDataItemRecord.UserId,
                    "ObjectName": vm.lastClickedDataItemRecord.ObjectName
                }

                await $http.put(`${apiUrl}api/core/auditlogs/disassociateticket`, putData);
            } else {
                await $http.delete(apiUrl + "api/audittrail/tickets/" + vm.lastClickedDataItemRecord.AtHeaderTicketId);
            }

            vm.actionInProgress = false;
            $uibModalInstance.close();
        } catch (response) {
            helperService.showErrorMessage(response.data);
            vm.actionInProgress = false;
        }
    }

    function dismiss () {
        $uibModalInstance.dismiss();
    }
}