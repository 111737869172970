import { customAutoCompleteFilter } from "../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($scope, $rootScope, KPIGeneratorService, ViewProductImportPageService, objectIdDataService, CustomReportServices, $http, helperService) {

    objectIdDataService.getObjectID().then(function (objectId) {
        $scope.ObjectId = objectId;

        $scope.filtersDataSource = CustomReportServices.setfiltersDataSource($scope.ObjectId);

        var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;

    });

    var vm = $scope.vm = {};

    $scope.showSetup = false;

    $scope.helperService.getConfigurationValue('QuantificationThreshold').then(function (data) {
        vm.QuantificationThreshold = data;
    });

    $scope.helperService.getConfigurationValue('QuantificationLastDays').then(function (data) {
        vm.QuantificationLastDays = data;
    });

    var columnsForRiskRuleset = [
        { template: "<label class='switch'><input type='checkbox' ng-model='dataItem.Quantifiable' ng-change='toggleValue(dataItem)'><i></i></label>", width: 55, filterable: false },
        { field: "Name", title: "Risk Name", filterable: customAutoCompleteFilter },
        { field: "Description", title: "Risk Description", filterable: customAutoCompleteFilter },
        { field: "RiskTypeName", title: "Risk Type", filterable: customAutoCompleteFilter },
        { field: "RulesetName", title: "Ruleset", filterable: customAutoCompleteFilter }
    ];

    $scope.gridOptions = helperService.setNonPageableGridOptions(columnsForRiskRuleset, 600);

    $scope.gridDataSource = new kendo.data.DataSource({
        sort: { field: "Name", dir: "asc" },
        transport: {

            read: function (options) {
                $http({
                    method: 'GET',
                    url: apiUrl + 'api/arm/risks'
                }).then(function (response) {
                    options.success(response.data);
                });
            }
        }
    });

    $scope.toggleValue = function (dataItem) {

        var selectedCheckboxValue = dataItem.Quantifiable;

        if (selectedCheckboxValue === true) {
            $http({
                method: 'POST',
                url: apiUrl + 'api/arm/risks/' + dataItem.Id + '/quantification',
                data: {}
            }).then(function successCallback(response) {
                return;
            }, function errorCallback(response) {
                helperService.showErrorMessage(response.data);
            });
        } else {
            $http({
                method: 'DELETE',
                url: apiUrl + 'api/arm/risks/' + dataItem.Id + '/quantification'
            }).then(function successCallback(response) {
                return;
            }, function errorCallback(response) {
                helperService.showErrorMessage(response.data);

            });
        }
    };

    function highlightSelectedItem(ev, props) {
        var dataSource = ev.sender.args.dataSource.data;
        for (var i = 0; i < dataSource.length; i++) {
            if (i === props.index) {
                dataSource[i].color = "#4F7E98";
            } else {
                delete dataSource[i].color;
            }
        }
        ev.sender.args.dataSource.data = dataSource;
        ev.sender.setJSONData(ev.sender.args.dataSource);
    }

    $scope.fusionChartByRiskEvents = {
        dataPlotClick: function (ev, props) {
            var row = ev.sender.args.dataSource.data[props.index];
            $scope.selectItem(row, 'risk');
            highlightSelectedItem(ev, props);
        }
    };

    $scope.fusionChartByUserEvents = {
        dataPlotClick: function (ev, props) {
            var row = ev.sender.args.dataSource.data[props.index];
            $scope.selectItem(row, 'user');
            highlightSelectedItem(ev, props);
        }
    };

    $scope.createPartialSavedReportAndNavigate = function (filters, objectId) {
        $rootScope.loadedSavedReport = KPIGeneratorService.createSavedReportStub(filters, objectId);
        ViewProductImportPageService.launchReportByObjectId(objectId);
    };

    $scope.selectedRiskData = null;

    $scope.selectItem = function (row, type) {
        if (type === 'risk') {
            $scope.selectedRisk = row;
            $scope.selectedRiskLoading = true;

            $http.get(apiUrl + 'api/core/charts/12350565-69B1-4424-810A-BDD3B7CCFDD7/data?filterField=RiskName&filterValue=' + row.Label).then(function (response) {
                $scope.selectedRiskData = response.data;
                $scope.selectedRiskLoading = false;
            });

        } else if (type === 'user') {
            $scope.selectedUser = row;
            $scope.selectedUserLoading = true;

            $http.get(apiUrl + 'api/core/charts/B7BB0BE0-94EA-46E9-B8A1-09BA6CBEB933/data?filterField=UserName&filterValue=' + row.Label).then(function (response) {
                $scope.selectedUserData = response.data;

                $scope.selectedUserLoading = false;

            });

        }
    };

    $scope.myDataSourceByRisk = {
        chart: {
            caption: "By Risk",
            numberPrefix: "$",
            theme: "ocean"
        },
        data: []
    };

    $scope.myDataSourceByUser = {
        chart: {
            caption: "By User",
            numberPrefix: "$",
            theme: "ocean",
            enableSlicing: 0
        },
        data: []
    };

    $http({
        method: 'GET',
        url: apiUrl + 'api/core/charts/' + 'B7BB0BE0-94EA-46E9-B8A1-09BA6CBEB933' + '/data'
    }).then(function (response) {
        $scope.myDataSourceByRisk.data = response.data;
    });

    $http({
        method: 'GET',
        url: apiUrl + 'api/core/charts/' + '12350565-69B1-4424-810A-BDD3B7CCFDD7' + '/data'
    }).then(function (response) {
        $scope.myDataSourceByUser.data = response.data;

    });


}