import createSpinner from "../../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, templatesDataService, helperService, $filter, template, isClone) {

    var vm = this;
    vm.cancel = cancel;
    vm.submitTemplateForm = submitTemplateForm;
    vm.hasExistingValueChanged = hasExistingValueChanged;
    vm.updateIneligibleObjectIds = updateIneligibleObjectIds;
    vm.isIneligibleObjectId = isIneligibleObjectId;
    vm.gridView = 'Objects';

    vm.ineligibileObjectIds = [];
    vm.template = angular.copy(template);
    vm.isClone = isClone;

    function activate() {
        if (template.Id) {
            vm.origValue = angular.copy(vm.template);
        } else {
            vm.template.Active = true;
            vm.template.Custom = true;
        }

        if (isClone) {
            setupCloneTemplateGrids();
            getTemplateData();
        }
    }
    activate();

    function cancel() { $uibModalInstance.dismiss(); }

    function getTemplateData() {
        templatesDataService.getObjectsByTemplateId(template.Id)
            .then(response => {
                vm.objectsDataSource = response.TemplateObjects;
            });
    }

    function setupCloneTemplateGrids() {
        var objectsGridOptions = [
            { template: "<label class='switch'><input type='checkbox' ng-model='dataItem.isSelected' ng-change='tmvm.updateIneligibleObjectIds(dataItem)'><i></i></label>", width: 55, filterable: false },
            { field: "ObjectName", title: "Name", filterable: customAutoCompleteFilter }
        ];

        var fieldsGridOptions = [
            { template: "<label class='switch'><input type='checkbox' ng-model='dataItem.isSelected' ng-disabled='tmvm.isIneligibleObjectId(dataItem)'><i></i></label>", width: 55, filterable: false },
            { field: "FieldName", title: "Name", filterable: customAutoCompleteFilter }
        ];

        vm.cloneTemplateObjectsGridOptions = helperService.setNonPageableGridOptions(objectsGridOptions, 300);
        vm.cloneTemplateObjectsGridOptions.noRecords = {
            template: "<div class='no-data-to-display-message'>No Objects exist for this template</div>"
        };

        vm.cloneTemplateFieldGridOptions = helperService.setNonPageableGridOptions(fieldsGridOptions, 300);
        vm.cloneTemplateFieldGridOptions.noRecords = {
            template: "<div class='no-data-to-display-message'>No Business Processes exist for this ruleset</div>"
        };

        templatesDataService.getObjectsByTemplateId(template.Id).then(response => {
            response.TemplateObjects = $filter('orderBy')(response.TemplateObjects, 'ObjectName');
            response.TemplateObjects.forEach(obj => {
                obj.isSelected = true;
            });

            vm.templateObjects = response.TemplateObjects;
            vm.cloneTemplateObjectsGridDataSource = new kendo.data.DataSource({
                transport: {
                    read: options => {
                        options.success(vm.templateObjects);
                    }
                }
            });

            vm.templateFields = [];
            vm.templateObjects.forEach(object => {
                vm.templateFields = vm.templateFields.concat(object.TemplateFields);
            });
            vm.templateFields.forEach(field => {
                field.isSelected = true;
            });
            vm.templateFields = $filter('orderBy')(vm.templateFields, 'FieldName');

            vm.cloneTemplateFieldGridDataSource = new kendo.data.DataSource({
                transport: {
                    read: options => {
                        options.success(vm.templateFields);
                    }
                }
            });
        });


    }

    function updateIneligibleObjectIds(object) {
        if (object.isSelected) {
            var index = vm.ineligibileObjectIds.indexOf(object.TemplateObjectId);
            if (index > -1) {
                vm.ineligibileObjectIds.splice(index, 1);
            }
        } else {
            vm.ineligibileObjectIds.push(object.TemplateObjectId);
        }
    }

    function isIneligibleObjectId(field) {
        var hasineligibleObjectId = false;

        hasineligibleObjectId = vm.ineligibileObjectIds.some(id => id === field.TemplateObjectId);
        if (hasineligibleObjectId) {
            field.isSelected = false;
        }
        return hasineligibleObjectId;
    }

    function submitTemplateForm(template) {
        vm.saving = new createSpinner();
        if (isClone) {
            cloneTemplate(template);
        } else {
            if (template.Id) {
                editTemplate();
            } else {
                addNewtemplate();
            }
        }
    }

    async function addNewtemplate() {

        let newTemplate = {
            'Name': vm.template.Name,
            'Active': vm.template.Active
        };

        try {
            await templatesDataService.createTemplate(newTemplate);
            await helperService.successfulSaveButton(vm.saving);
            $uibModalInstance.close(response);
        } catch {
            vm.saving.loadingValue = false;
        }
    }

    async function editTemplate() {

        var editedTemplate = {
            'Id': vm.template.Id,
            'Name': vm.template.Name,
            'Active': vm.template.Active
        };

        await templatesDataService.editTemplate(template.Id, editedTemplate).then(successCallback);
        await helperService.successfulSaveButton(vm.saving);
        $uibModalInstance.close();
    }

    function cloneTemplate(template) {
        vm.disableSubmit = true;

        if (vm.cloneTemplateFieldGridDataSource._data.length === 0) {
            vm.cloneTemplateFieldGridDataSource.read().then(_ => cloneTemplate());
        } else {
            cloneTemplate();
        }

        async function cloneTemplate() {

            var selectedObjects = vm.cloneTemplateObjectsGridDataSource._data.filter(object => object.isSelected);

            var selectedFields = vm.cloneTemplateFieldGridDataSource._data.filter(field => field.isSelected);
            selectedObjects.forEach(object => {
                object.FieldIds = selectedFields.filter(field => field.TemplateObjectId === object.TemplateObjectId).map(field => field.FieldId);
            });

            selectedObjects = selectedObjects.map(object => {
                var newObject = {};
                newObject.Id = object.ObjectId;
                newObject.FieldIds = object.FieldIds;
                return newObject;
            });

            var data = {
                "Name": template.Name,
                "Active": template.Active,
                "Objects": selectedObjects
            };

            try {
                await templatesDataService.cloneTemplate(template.Id, data);
                await helperService.successfulSaveButton(vm.saving);
                $uibModalInstance.close();
            } catch {
                vm.disableSubmit = false;
                vm.saving.loadingValue = false;
            }
        }
    }

    function successCallback(response) {
        $uibModalInstance.close();
    }

    function hasExistingValueChanged() {
        if (vm.origValue && angular.equals(vm.origValue, vm.value)) {
            return false;
        } else {
            // Either creating a new value or user has modified one of the fields
            return true;
        }
    }
}