import { customAutoCompleteFilter } from "../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($scope, $rootScope, KPIGeneratorService, ViewProductImportPageService, objectIdDataService, CustomReportServices, $http, helperService) {

    var vm = this;
    vm.showSetup = false;

    vm.helperService = helperService;
    vm.createPartialSavedReportAndNavigate = createPartialSavedReportAndNavigate;
    vm.toggleValue = toggleValue;

    function activate() {

        objectIdDataService.getObjectID().then(function (objectId) {
            $scope.ObjectId = objectId;

            $scope.filtersDataSource = CustomReportServices.setfiltersDataSource($scope.ObjectId);

            var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
            $scope.pageInfo = securityObject;
            $scope.pageDescription = securityObject.HelpLink;
            $scope.reportName = securityObject.Name;

        });

        helperService.getConfigurationValue('AX7_QuantificationThreshold').then(function (data) {
            vm.QuantificationThreshold = data;
        });

        helperService.getConfigurationValue('AX7_QuantificationLastDays').then(function (data) {
            vm.QuantificationLastDays = data;
        });

        $http({
            method: 'GET',
            url: apiUrl + 'api/core/charts/' + 'B7E53CC5-2EE6-4F19-8691-D161CAAAC6CA' + '/data'
        }).then(function (response) {
            //response.data.forEach(function (row) { row.link = "j-" })
            vm.myDataSourceByRisk.data = response.data;
        });

        $http({
            method: 'GET',
            url: apiUrl + 'api/core/charts/' + 'CB14500A-085D-4F52-9A6E-F344E900331C' + '/data'
        }).then(function (response) {
            //response.data.forEach(function (row) { row.link = "j-" })
            vm.myDataSourceByUser.data = response.data;

        });

    }
    activate();

    var columnsForRiskRuleset = [
        { template: "<label class='switch'><input type='checkbox' ng-model='dataItem.Enabled' ng-change='qvm.toggleValue(dataItem)'><i></i></label>", width: 55, filterable: false },
        { field: "RiskName", title: "Risk Name", filterable: customAutoCompleteFilter },
        { field: "RiskDescription", title: "Risk Description", filterable: customAutoCompleteFilter }
    ];

    vm.gridOptions = helperService.setNonPageableGridOptions(columnsForRiskRuleset, 600);

    vm.gridDataSource = new kendo.data.DataSource({
        sort: { field: "RiskName", dir: "asc" },
        transport: {

            read: function (options) {
                $http({
                    method: 'GET',
                    url: apiUrl + '/api/arm/ax7/quantification/risks'
                }).then(function (response) {
                    options.success(response.data);
                });
            }
        }
    });

    function toggleValue(dataItem) {

        var selectedCheckboxValue = dataItem.Enabled;

        if (selectedCheckboxValue === true) {
            $http({
                method: 'POST',
                url: apiUrl + 'api/arm/risks/' + dataItem.RiskId + '/quantification',
                data: {}
            }).then()
                .catch(response => {
                    helperService.showErrorMessage(response.data);
                    selectedCheckboxValue = false;
                });
        } else {
            $http({
                method: 'DELETE',
                url: apiUrl + 'api/arm/risks/' + dataItem.RiskId + '/quantification'
            }).then()
                .catch(response => {
                    helperService.showErrorMessage(response.data);
                    selectedCheckboxValue = true;
                });
        }
    }

    function highlightSelectedItem(ev, props) {
        var dataSource = ev.sender.args.dataSource.data;
        for (var i = 0; i < dataSource.length; i++) {
            if (i === props.index) {
                dataSource[i].color = "#4F7E98";
            } else {
                delete dataSource[i].color;
            }
        }
        ev.sender.args.dataSource.data = dataSource;
        ev.sender.setJSONData(ev.sender.args.dataSource);
    }

    vm.fusionChartByRiskEvents = {
        dataPlotClick: function (ev, props) {
            var row = ev.sender.args.dataSource.data[props.index];
            selectItem(row, 'risk');
            highlightSelectedItem(ev, props);
        }
    };

    vm.fusionChartByUserEvents = {
        dataPlotClick: function (ev, props) {
            var row = ev.sender.args.dataSource.data[props.index];
            selectItem(row, 'user');
            highlightSelectedItem(ev, props);
        }
    };

    function createPartialSavedReportAndNavigate(filters, objectId) {
        $rootScope.loadedSavedReport = KPIGeneratorService.createSavedReportStub(filters, objectId);
        ViewProductImportPageService.launchReportByObjectId(objectId);
    }

    vm.selectedRiskData = null;

    function selectItem(row, type) {
        if (type === 'risk') {
            vm.selectedRisk = row;
            vm.selectedRiskLoading = true;

            $http({
                method: 'GET',
                url: apiUrl + 'api/core/charts/CB14500A-085D-4F52-9A6E-F344E900331C/data',
                params: { 'filterField': 'RiskName', 'filterValue': row.Label }
            }).then(function (response) {
                vm.selectedRiskData = response.data;
                vm.selectedRiskLoading = false;
            });

        } else if (type === 'user') {
            vm.selectedUser = row;
            vm.selectedUserLoading = true;

            $http.get(apiUrl + 'api/core/charts/B7E53CC5-2EE6-4F19-8691-D161CAAAC6CA/data?filterField=UserName&filterValue=' + row.Label).then(function (response) {
                vm.selectedUserData = response.data;
                vm.selectedUserLoading = false;

            });

        }
    }

    vm.myDataSourceByRisk = {
        chart: {
            caption: "By Risk",
            //subCaption: "Top 5 stores in last month by revenue",
            numberPrefix: "$",
            theme: "ocean"
        },
        data: []
    };

    vm.myDataSourceByUser = {
        chart: {
            caption: "By User",
            //subCaption: "Top 5 stores in last month by revenue",
            numberPrefix: "$",
            theme: "ocean",
            enableSlicing: 0
        },
        data: []
    };

}