import createSpinner from "../../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, ownershipGroupsDataService, reviewAssignmentId, reviewSchedulesDataService) {

    const vm = this;
    vm.dismiss = dismiss;
    vm.addRoutingCriteria = addRoutingCriteria;
    
    activate();

    async function activate() {        
        vm.helperService = helperService;
        vm.owners = [];      
        vm.ownershipGroupsDataSource = await getOwnershipGroups();              
    }

    async function getOwnershipGroups() {
        vm.ownershipGroupDropdownLoading = true;
        let { data } = await ownershipGroupsDataService.getOwnershipGroups();    
        vm.ownershipGroupDropdownLoading = false;
        return data = data.filter(group => group.TypeName === 'Company');
    }

    async function addRoutingCriteria() {
        vm.pleaseWait = new createSpinner();

        let routingCriteria = {
            RoutingCriteriaOwnershipGroupId: vm.selectedItem.Id,
            ReviewAssignmentId: reviewAssignmentId
        };

        try {
            await reviewSchedulesDataService.postRoutingCriteria(reviewAssignmentId, routingCriteria);
            await helperService.successfulSaveButton(vm.pleaseWait);
            $uibModalInstance.close(reviewAssignmentId);
        } catch {
            vm.pleaseWait.loadingValue = false;
        }
    }

    function dismiss() {
        $uibModalInstance.close();
    }
}