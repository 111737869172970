export default /*@ngInject*/ function ($http, errorsDataService) {
    const factory = {
        getSimulationCompositeRoles,
        getCompositeRoleRoles,
        addCompositeRoleToSimulation,
        addRoleToCompositeRole,
        updateCompositeRole,
        copyCompositeRoleToSimulation,
        editCompositeRoleToSimulation,
        deleteCompositeRole,
        removeRoleFromCompositeRole
    };

    return factory;

    async function getSimulationCompositeRoles(simulationId, params) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/compositeroles`, {
                params: params
            });
        } catch (err) {
            showError(err);
        }
    }

    async function getCompositeRoleRoles(simulationId, compositeRoleId) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/compositeroles/${compositeRoleId}/roles`);
        } catch (err) {
            showError(err);
        }
    }

    async function addCompositeRoleToSimulation(data) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/sap/simulations/${data.SimulationId}/compositeroles`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addRoleToCompositeRole(data) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/sap/simulations/${data.SimulationId}/compositeroles/${data.CompositeRoleId}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateCompositeRole(data) {
        try {
            return await $http.put(`${apiUrl}api/securitydesigner/sap/simulations/${data.SimulationId}/compositeroles/${data.CompositeRoleId}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function copyCompositeRoleToSimulation(data) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/sap/simulations/${data.SimulationId}/compositeroles/copy`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function editCompositeRoleToSimulation(data) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/sap/simulations/${data.SimulationId}/compositeroles/edit`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteCompositeRole(simulationId, compositeRoleId) {
        try {
            return await $http.delete(`${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/compositeroles/${compositeRoleId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function removeRoleFromCompositeRole(simulationId, compositeRoleId, roleId) {
        try {
            return await $http.delete(`${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/compositeroles/${compositeRoleId}/roles/${roleId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(error) {
        errorsDataService.catch(error);
    }

    function sendError(error) {
        throw error;
    }

}