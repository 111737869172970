import { standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($scope, $filter, RequestManagementPSService, manageDataDataService, $http) {

    //General User
    $scope.vm.selectUser = selectUser;
    $scope.vm.psUserFromDateObject = null;
    $scope.vm.psUserToDateObject = null;
    $scope.vm.psUserMaxDate = new Date();
    var psUserMinDate = new Date();
    psUserMinDate.setHours(0, 0, 0, 0);

    $scope.vm.psUserMinDate = psUserMinDate;

    activate();

    async function activate() {
        await getPermissionlists();
        await setFieldConfigurationValues();
        createPermissionListDropdowns();
        $scope.vm.ListOfPSEmailTypes = RequestManagementPSService.getPSEmailTypes();
    }

    if (!$scope.vm.User.PSUser) {
        $scope.vm.User.PSUser = {};
    }
    $scope.vm.User.PSUser.ProductType = "PS";

    async function getPermissionlists() {
        let response = await RequestManagementPSService.getPSPermissionLists()
        response.data = $filter('orderBy')(response.data, 'Name');
        $scope.vm.psPermissionLists = response.data;
    }

    function createPermissionListDropdowns() {
        $scope.vm.permissionListsDropdownOptions = {
            valuePrimitive: true,
            template: `
                <span>{{dataItem.Id}} | {{dataItem.Name}}</span>
            `,
            filter: "contains",
            autoBind: true,
            minLength: 3,
            optionLabel: '-- Select a Permission List --',
            filtering: ev => {
                var filterValue = ev.filter != undefined ? ev.filter.value : "";
                ev.preventDefault();

                ev.sender.dataSource.filter({
                    logic: "or",
                    filters: [
                        {
                            field: "Name",
                            operator: "contains",
                            value: filterValue
                        },
                        {
                            field: "Id",
                            operator: "contains",
                            value: filterValue
                        }
                    ]
                });
            }
        };



        $scope.vm.psNavPermissionsListsDataSource = new kendo.data.DataSource({
            transport: {
                read: options => {
                    options.success($scope.vm.psPermissionLists);
                }
            }
        });

        $scope.vm.psPrimaryPermissionsListsDataSource = new kendo.data.DataSource({
            transport: {
                read: options => {
                    options.success($scope.vm.psPermissionLists);
                }
            }
        });

        $scope.vm.psProcessPermissionsListsDataSource = new kendo.data.DataSource({
            transport: {
                read: options => {
                    options.success($scope.vm.psPermissionLists);
                }
            }
        });

        $scope.vm.psRowSecurityPermissionsListsDataSource = new kendo.data.DataSource({
            transport: {
                read: options => {
                    options.success($scope.vm.psPermissionLists);
                }
            }
        });
    }

    $scope.psUserFromDateChanged = function () {
        var date;
        if ($scope.vm.User.PSUser.StartDate === '') {
            date = new Date();
        } else {
            date = new Date($scope.vm.User.PSUser.StartDate);
        }

        $scope.vm.psUserMinDate = date;
        $scope.vm.nsMinDate = date;
        $scope.vm.fromMinDate = date;
        if ($scope.vm.User.PSUser == null) {
            $scope.vm.User.PSUser = {};
        }
    };

    $scope.psUserToDateChanged = function () {
        $scope.vm.psUserMaxDate = new Date($scope.vm.User.PSUser.EndDate);
        $scope.vm.nsMaxDate = new Date($scope.vm.User.PSUser.EndDate);
        $scope.vm.fromMaxDate = new Date($scope.vm.User.PSUser.StartDate);
        if ($scope.vm.User.PSUser == null) {
            $scope.vm.User.PSUser = {};
        }
    };

    async function setFieldConfigurationValues() {
        $scope.vm.showADUserLookup = $scope.vm.config.find(config => config.Name === 'AD_Active').Value === '1';
        $scope.vm.showAADUserLookup = $scope.vm.config.find(config => config.Name === 'AAD_Active').Value === '1';
        let response = await manageDataDataService.getConfigurationByProductId('PS');
        $scope.vm.disableADUserFields = response.data.find(config => config.Name === 'IM_DisableADUserFields').Value === '1' && $scope.vm.showADUserLookup;
        $scope.vm.disableAADUserFields = response.data.find(config => config.Name === 'IM_DisableAADUserFields').Value === '1' && $scope.vm.showAADUserLookup;
    }

    function selectUser(user) {
        $scope.vm.User.PSUser = user;
        if (user.UserType !== 'Guest') {
            setProvider();
        }
    }

    async function setProvider() {
        const { data } = await $http.get(`${apiUrl}api/universal/products/ORFC/configuration/im_provider`);
        $scope.vm.User.PSUser.NetworkDomain = data.Value;
    }
}