import dataChangesImportController from "./dataChangesImport.controller";



/* dataChangesImport.component.js */

/**
 * @desc component for importing data changes .csv file
 * @example <data-changes-import></data-changes-import>
 */

const dataChangesImport = {
    bindings: {
        reportDetails: '<',
        disableButton: '<',
        reportId: '@'
    },
    controller: dataChangesImportController,
    controllerAs: 'dcivm',
    templateUrl: '/App/Shared/Components/DataChangesImport/dataChangesImport.tmpl.html'
};

export default dataChangesImport;