import snapshotComparisonController from "./snapshotComparison.controller";


/* snapshotComparison.component.js */

/**
 * @desc component for Snapshot Comparison in NetSuite
 * @example <snapshot-comparison></snapshot-comparison>
 */

const snapshotComparison = {
    controller: snapshotComparisonController,
    controllerAs: 'scvm',
    templateUrl: '/App/Components/SnapshotComparison/snapshotComparison.tmpl.html'
};

export default snapshotComparison;