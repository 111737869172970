export default /*@ngInject*/ function (sapSdSimRoleDataService, $filter) {

    const vm = this;

    vm.changeSelectedField = changeSelectedField;

    vm.menuOptions = [
        {
            text: 'Grant full authorization',
            click: item => {
                grantFullAuthorization(item.field);
            }
        }
    ];

    vm.$onChanges = activate;

    async function activate() {
        getAuthObjectFields();
    }

    async function getAuthObjectFields() {
        vm.fieldsLoading = true;
        let response = await sapSdSimRoleDataService.getAuthObjectFields(vm.simulation.Id, vm.selectedRole.Id, vm.authObject.Id);
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.fields = response.data;
        vm.fieldsLoading = false;
    }

    async function changeSelectedField(field) {
        vm.selectedField = field;
        vm.onSelectedFieldChange({ field });
    }

    async function grantFullAuthorization(field) {
        vm.fieldsLoading = true;
        let data = {
            SimulationId: vm.simulation.Id,
            RoleId: vm.selectedRole.Id,
            AuthorizationObjectId: vm.authObject.Id,
            FieldId: field.Id,
            Low: "*",
            High: null
        };

        try {
            await sapSdSimRoleDataService.addFieldValue(data);
            vm.fieldsLoading = false;
            // we have to clone the field object to ensure the field values component updates via $onChanges
            const updatedField = { ...field };
            changeSelectedField(updatedField);
            await getAuthObjectFields();
            if (vm.fields.every(field => field.AllMaintained)) {
                // All Maintained status means we need to update the auth objects list
                vm.simulationsCtrl.updateList('authObjects');
            }
        } catch {
            vm.fieldsLoading = false;
        }
    }
}