import rolesListController from "./rolesList.controller";

/* rolesList.component.js */

/**
 * @desc component for viewing and maintaining list of sap security designer simulation roles
 * @example <sap-sd-simulation-roles-list></sap-sd-simulation-roles-list>
 */

const sapSdSimulationRolesList = {
    bindings: {
        simulation: '<',
        selectedRoleType: '<',
        onUpdateSimulationsList: '&',
        onSelectedCompositeRoleChange: '&',
        onSelectedRoleChange: '&',
        onRoleTypeChange: '&',
        onManageAuthClicked: '&',
        onDefineOrgLevelsClicked: '&'
    },
    controller: rolesListController,
    controllerAs: 'rlvm',
    templateUrl: '/App/Components/SAPSecurityDesigner/simulationRolesList/rolesList.tmpl.html'
};

export default sapSdSimulationRolesList;