export default function () {
    var service = {
        getActionsByProductId
    };

    return service;

    function getActionsByProductId(product) {
        var actionsArray = [
            {
                name: 'Collect',
                displayName: 'Collect Data Changes',
                buttonText: 'Collect',
                description: 'Moves audit trail data from the selected product to the Fastpath Assure system for audit trail reporting.',
                type: 'AuditTrailImport',
                configValue: null,
                canSchedule: true
            },
            {
                name: 'Archive',
                displayName: 'Archive Data Changes',
                buttonText: 'Archive',
                description: 'Archives audit trail data older than the specified date. This will remove this data from your audit trail reports but is available for restore, if needed.',
                type: null,
                configValue: 'AT_ArchiveDays',
                canSchedule: true
            },
            {
                name: 'Purge',
                displayName: 'Purge Data Changes',
                buttonText: 'Purge',
                description: 'Purges archived audit trail data older than the specified date. This will permanently remove the audit trail data from the Fastpath Assure system. The purge process will only delete data from the archive tables.',
                type: null,
                configValue: 'AT_PurgeDays',
                canSchedule: true
            },
            {
                name: 'Restore',
                displayName: 'Restore Data Changes',
                buttonText: 'Restore',
                description: 'Restores archived audit trail data between the specified dates. This will move the data back into the reporting tables and the data will be available on your Audit Trail reports.',
                type: null,
                configValue: null,
                canSchedule: false
            }
        ];
        if (product.Type === 'NS') {
            if (!product.version || product.version === 1) {
                var action = {
                    name: 'Collect',
                    displayName: 'Collect Snapshot',
                    buttonText: 'Collect',
                    description: 'The Collect Snapshot process will move selected metadata from the selected product to the Fastpath Assure system for reporting and historical comparisons.',
                    type: 'AuditTrailImport_NetSuiteSnapshot',
                    configValue: null,
                    canSchedule: true
                };
                actionsArray.unshift(action);
            } else if (product.version === 2) {
                actionsArray  = [
                    {
                        name: 'Collect',
                        displayName: 'Import Data',
                        buttonText: 'Import Data',
                        description: 'Import audit trail data from NetSuite.',
                        type: 'AuditTrailImport',
                        configValue: null,
                        canSchedule: true
                    },
                    {
                        name: 'Collect',
                        displayName: 'Refresh Metadata',
                        buttonText: 'Refresh Metadata',
                        description: 'Refreshes available record types and fields based on NetSuite system notes.',
                        type: 'AuditTrailImport_NetSuiteMetadata',
                        configValue: null,
                        canSchedule: false
                    }
                ];
            }
        }

        if (product.Type === 'WD') {
            actionsArray = actionsArray.concat([
                {
                    name: 'Collect',
                    displayName: 'Collect Metadata',
                    buttonText: 'Collect Metadata',
                    description: 'Collect Metadata from Workday.',
                    type: 'AuditTrailImport_WorkdayMetadata',
                    configValue: null,
                    canSchedule: true
                }
            ]);
        }

        if (product.Type === 'SF') {
            actionsArray = actionsArray.concat([
                {
                    name: 'Import',
                    displayName: 'Import Metadata',
                    buttonText: 'Import Metadata',
                    description: 'Import Metadata from Salesforce.',
                    type: 'AuditTrailImport',
                    configValue: null,
                    canSchedule: true
                },
                {
                    name: 'Setup',
                    displayName: 'Track Setup Data Changes',
                    buttonText: 'Track Setup Data Changes',
                    description: 'Enable Setup Changes Data Collection',
                    type: null,
                    configValue: 'SF_AT_EnableSetupTracking',
                    canSchedule: false
                }                
            ]);
        }

        return actionsArray;
    }
}