import selectTemplateModalController from "./selectTemplateModal.controller";

export default /*@ngInject*/ function ($uibModal, companyTemplatesData) {

    var vm = this;

    vm.$onInit = checkForCompanyTemplates;

    vm.openTemplateSelectionModal = openTemplateSelectionModal;

    async function checkForCompanyTemplates() {
        let response = await companyTemplatesData.getCompanyTemplates();
        vm.companyTemplates = response.data;
    }


    async function openTemplateSelectionModal() {
        let templateSelectionModal = $uibModal.open({
            templateUrl: '/App/Shared/Components/SelectCompaniesByTemplate/selectTemplateModal.html',
            controller: selectTemplateModalController,
            controllerAs: 'stmvm',
            backdrop: 'static'
        });

        try {
            let companies = await templateSelectionModal.result;
            companies = companies.filter(company => company.CompanyMaster.UserHasAccess);
            await getCompanies(companies);
        } catch (err) {
            return;
        }
    }

    function getCompanies(companies) {
        // modify companies array to match company master returned array
        companies = companies.map(company => company.CompanyMaster);

        // call bound function to update companies on the parent side
        vm.onSelectedTemplate({ companies });
    }

}
