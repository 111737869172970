import companyRestrictionsModalController from "../CompanyRestrictionsModal/companyRestrictionsModal.controller";
import productRestrictionsModalController from "../ProductRestrictionsModal/productRestrictionsModal.controller";


export default /*@ngInject*/ function FastpathUserListController($scope, $filter, $uibModal, userRestrictionsDataService) {

    const vm = this;

    vm.changeSelectedUser = changeSelectedUser;
    vm.availableProducts = $scope.$parent.availableProductsForUser;

    activate();

    function activate() {

        getFastpathUsers();

        vm.menuOptions = [
            {
                text: 'Company Restrictions',
                click: item => {
                    openCompanyRestrictionsModal(item.user);
                }
            },
            {
                text: 'Product Restrictions',
                click: item => {
                    openProductRestrictionsModal(item.user);
                }, 
                displayed: item => {
                    return  vm.availableProducts.length > 1;
                }
            }
        ];
    }

    async function getFastpathUsers() {
        try {
            let { data } = await userRestrictionsDataService.getFastpathUsers();
            if (data.length > 0) {
                data = data.filter(user => user.Active === true);
                data = $filter('orderBy')(data, 'UserLogonName');
            }
            vm.users = data;
        } catch {
            return;
        }
    }

    function changeSelectedUser(user) {
        vm.user = user;
    }

    async function openCompanyRestrictionsModal(user) {

        let companyModal = $uibModal.open({
            templateUrl: '/App/Components/userRestrictions/companyRestrictionsModal/companyRestrictionsModal.html',
            controller: companyRestrictionsModalController,
            controllerAs: 'crmvm',
            backdrop: 'static',
            resolve: { fastpathUser: () => user }
        });

        try {
            await companyModal.result;
            getFastpathUsers();
        } catch {
            return;
        }
    }

    async function openProductRestrictionsModal(user) {

        let productModal = $uibModal.open({
            templateUrl: '/App/Components/userRestrictions/productRestrictionsModal/productRestrictionsModal.html',
            controller: productRestrictionsModalController,
            controllerAs: 'prmvm',
            backdrop: 'static',
            resolve: { fastpathUser: () => user }
        });

        try {
            await productModal.result;
            getFastpathUsers();
        } catch {
            return;
        }
    }
}
