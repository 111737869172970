import createSpinner from "../../Functions/createSpinner";

export default /*@ngInject*/ function (applicationInsightsService, $http, helperService) {

   const vm = this;

   vm.newSavedReport = {};

   vm.$onInit = onInit;

   vm.cancel = cancel;
   vm.saveReport = saveReport;
   vm.overwriteReport = overwriteReport;

   function onInit() {
       if (vm.resolve.currentSavedReports.length > 0) {
            vm.showOverwrite = true;
        } else {
            vm.showOverwrite = false;
       }
    }

   //Save Report
    function saveReport() {
       vm.pleaseWait = new createSpinner();

       applicationInsightsService.trackEvent('SaveReport', { Name: vm.resolve.report.Name, ObjectId: vm.resolve.objectId });
        const reportDefinition = vm.resolve.bundleReportDefinition(vm.resolve.grid.getOptions(), vm.resolve.reportDetailsModel);

       const postData = {
           "Description": vm.newSavedReport.Description,
           "Name": vm.newSavedReport.Name,
           "ReportDefinition": {
               "ReportId": vm.resolve.report.ReportID,
               "Columns": reportDefinition.Columns,
               "Sortings": reportDefinition.Sortings,
               "Criteria": vm.resolve.sortsAndFilters && vm.resolve.sortsAndFilters.Criteria ? reportDefinition.Criteria.concat(vm.resolve.sortsAndFilters.Criteria) : reportDefinition.Criteria,
               "Filters": reportDefinition.Filters,
               "DateFilters": reportDefinition.DateFilters
           }
       };

       $http.post(apiUrl + 'api/core/savedreports', postData).then(async function (response) {
           vm.killModal = true;
           await helperService.successfulSaveButton(vm.pleaseWait).then(function (result) {
               vm.modalInstance.close();
           });

       }, function (response) {
           helperService.showErrorMessage(response.data);
           vm.pleaseWait.loadingValue = false;
       });

   }

   function overwriteReport(selectedReport) {

       vm.pleaseWait = new createSpinner();

       var reportDefinition = vm.resolve.bundleReportDefinition(vm.resolve.grid.getOptions(), vm.resolve.reportDetailsModel);

       var postData = {
           "Description": selectedReport.Description,
           "Name": selectedReport.Name,
           "ReportDefinition": {
               "ReportId": vm.resolve.report.ReportID,
               "Columns": reportDefinition.Columns,
               "Sortings": reportDefinition.Sortings,
               "Criteria": vm.resolve.sortsAndFilters && vm.resolve.sortsAndFilters.Criteria ? reportDefinition.Criteria.concat(vm.resolve.sortsAndFilters.Criteria) : reportDefinition.Criteria,
               "Filters": reportDefinition.Filters,
               "DateFilters": reportDefinition.DateFilters
           }
       };

       postData.Id = selectedReport.Id;
       $http.put(apiUrl + 'api/core/savedreports/' + selectedReport.Id, postData).then(async function (response) {
           vm.killModal = true;
           await helperService.successfulSaveButton(vm.pleaseWait).then(function (result) {
               vm.modalInstance.close();
           });

       }, function (response) {
           helperService.showErrorMessage(response.data);
           vm.pleaseWait.loadingValue = false;
       });
   }

   function cancel() {
       vm.modalInstance.close();
   }
}