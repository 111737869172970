export default /*@ngInject*/ function ($http) {

    const service = {
        checkAX7,
        checkAX,
        checkGP,
        checkOR,
        checkAAD
    };

    return service;

    function checkAX7(userId) {
        // AX7
        return $http.get(apiUrl + `api/identitymanager/ax7/users/search?userId=${userId}`);
    }

    function checkAX(userId, partitionId) {
        // AX R1, R2+
        return $http.get(apiUrl + `api/identitymanager/ax/users/search?userId=${partitionId}|${userId}`);
    }

    function checkGP(userId) {
        // GP
        return $http.get(apiUrl + `api/identitymanager/gp/users/search?userId=${userId}`);
    }

    function checkOR(username) {
        return $http.get(`${apiUrl}api/identitymanager/or/users/search?userName=${username}`);
    }

    function checkAAD(username) {
        return $http.get(`${apiUrl}api/identitymanager/aad/users/${username}`);
    }

}