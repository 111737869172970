export default /*@ngInject*/ function ($http, errorsDataService) {
    const factory = {
        getRequesterGroups,
        getRequesterGroupUsers,
        createGroup,
        addUserToGroup,
        updateGroup,
        deleteGroup,
        removeUserFromGroup,
        exportRequesterGroups,
        importRequesterGroups
    };

    return factory;

    async function getRequesterGroups() {
        try {
            return await $http.get(`${apiUrl}api/universal/requestergroups`);
        } catch (err) {
            showError(err);
        }
    }

    async function getRequesterGroupUsers(groupId) {
        try {
            return await $http.get(`${apiUrl}api/universal/requestergroups/group/${groupId}/users`);
        } catch (err) {
            showError(err);
        }
    }

    async function createGroup(data) {
        try {
            return await $http.post(`${apiUrl}api/universal/requestergroups`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addUserToGroup(data) {
        try {
            return await $http.post(`${apiUrl}api/universal/requestergroups/group/${data.RequesterGroupId}/users`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateGroup(data) {
        try {
            return await $http.put(`${apiUrl}api/universal/requestergroups/${data.Id}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteGroup(groupId) {
        try {
            return await $http.delete(`${apiUrl}api/universal/requestergroups/${groupId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function removeUserFromGroup(groupId, groupUserId) {
        try {
            return await $http.delete(`${apiUrl}api/universal/requestergroups/group/${groupId}/users/${groupUserId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function exportRequesterGroups() {
        try {
            return await $http.get(`${apiUrl}api/universal/requestergroups/export`, { responseType: "blob" });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function importRequesterGroups(fd) {
        try {
            return await $http.put(`${apiUrl}api/universal/requestergroups/import`, fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(error) {
        errorsDataService.catch(error);
    }

    function sendError(error) {
        throw error;
    }

}