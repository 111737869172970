import applyTrackingTemplatesController from "./applyTrackingTemplates.controller";

/* applyTrackingTemplates.component.js */

/**
 * @desc component for managing which Audit Trail templates are currently active and applied
 * @example <apply-tracking-templates></apply-tracking-templates>
 */

const applyTrackingTemplates = {
    controller: applyTrackingTemplatesController,
    controllerAs: 'attvm',
    templateUrl: '/App/Components/ApplyTrackingTemplates/applyTrackingTemplates.tmpl.html'
};

export default applyTrackingTemplates;