export default /*@ngInject*/ function ($http, errorsDataService) {
    var service = {
        getSavedReports,
        addSavedReport,
        getSavedReportSchedules,
        editSavedReport,
        removeSavedReport
    };

    return service;

    function getSavedReports() {
        return $http.get(apiUrl + 'api/core/savedreports/')
            .then(workComplete)
            .catch(workFailed);
    }

    function addSavedReport(data) {
        return $http.post(apiUrl + 'api/core/savedreports/', data)
            .then(workComplete)
            .catch(workFailed);
    }


    function getSavedReportSchedules(savedReportId) {
        return $http.get(apiUrl + 'api/core/reportschedules/savedreports/' + savedReportId)
            .then(workComplete)
            .catch(workFailed);
    }

    function editSavedReport(savedReportId, putData) {
        return $http.put(`${apiUrl}api/core/savedreports/${savedReportId}`, putData)
            .then(workComplete)
            .catch(workFailed);
    }


    function removeSavedReport(savedReportId) {
        return $http.delete(apiUrl + 'api/core/savedreports/' + savedReportId)
            .then(workComplete)
            .catch(workFailed);
    }

    function workComplete(response) {
        return response;
    }

    function workFailed(response) {
        errorsDataService.catch(response);
    }
}