import { customAutoCompleteFilter, customBooleanYesNoFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import createSpinner from "../../Shared/Functions/createSpinner";
import importExcludedUsersController from "./importExcludedUsers.controller";

export default /*@ngInject*/ function ($scope, $filter, helperService, $uibModal, FileSaver, excludedUsersDataService, maintainSystemUsersDataService) {

    const vm = this;

    vm.helperService = helperService;
    vm.exportExcludedUsers = exportExcludedUsers;
    vm.openImportModal = openImportModal;
    vm.excludeUser = excludeUser;

    activate();

    function activate() {
        createExcludedUsersGrid();
    }

    $scope.$on('ImportExcludedUsers', (event, args) => vm.gridDataSource.read());

    function createExcludedUsersGrid() {
        let columnsFromVM = [
            { title: "Excluded", field: "Exclude", template: "<label class='switch'><input type='checkbox' ng-model='dataItem.Exclude' ng-change='euvm.excludeUser(dataItem)'><i></i></label>", width: 130, filterable: customBooleanYesNoFilter },
            { field: "Name", title: "User Name", filterable: customAutoCompleteFilter }
        ];

        vm.mainGridOptions = helperService.setMainGridOptions(columnsFromVM, 600);
        vm.mainGridOptions.autoBind = true;

        vm.gridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: {
                model: {
                    fields: {
                        Exclude: { type: "boolean" }
                    }
                }
            },
            transport: {
                read: async options => {
                    let { data } = await maintainSystemUsersDataService.getMasterUsers();
                    data = $filter('orderBy')(data, 'Name');
                    options.success(data);
                }
            }
        });
    }

    async function excludeUser(dataItem) {
        let userId = dataItem.ID;
        let exclude = dataItem.Exclude;
        let data = { UserMasterID: userId, ShouldExclude: exclude };
        vm.pleaseWait = new createSpinner();
        try {
            await excludedUsersDataService.updateExcludedUser(data);
            await helperService.successfulSaveButton(vm.pleaseWait);
        } catch {
            dataItem.Exclude = !dataItem.Exclude;
            vm.pleaseWait.loadingValue = false;
        }
    }

    async function exportExcludedUsers() {
        vm.exportUpdatePending = new createSpinner();
        try {
            let { data } = await excludedUsersDataService.exportExcludedUsers();
            await helperService.successfulSaveButton(vm.exportUpdatePending);
            FileSaver.saveAs(data, 'ExcludedUsers.xlsx');
        } catch {
            vm.exportUpdatePending.loadingValue = false;
        }
    }

    async function openImportModal() {
        let importModal = $uibModal.open({
            templateUrl: '/App/Components/ExcludedUsers/importExcludedUsers.html',
            controller: importExcludedUsersController,
            controllerAs: 'ieuvm',
            backdrop: 'static',
        });
        try {
            await importModal.result
            vm.gridDatasource.read();
        } catch {
            return;
        }
    }

}
