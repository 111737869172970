import staticPageHeaderController from "./staticPageHeader.controller";

/* staticPageHeader.component.js */

/**
 * @desc component for displaying a static page header in place of the standard security object dependent page header
 * @example <static-page-header></static-page-header>
 */

const staticPageHeader = {
    bindings: {
        title: '<',
        description: '<'
    },
    controller: staticPageHeaderController,
    controllerAs: 'sphvm',
    templateUrl: '/App/Shared/Components/staticPageHeader/staticPageHeader.tmpl.html'
};

export default staticPageHeader;