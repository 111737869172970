 import signReportFromEmailController from "./signReportFromEmail.controller";

/* signReportFromEmail.component.js */

/**
 * @desc component for Signing Reports via Email link
 * @example <sign-report-from-email></sign-report-from-email>
 */

const signReportFromEmail = {
    controller: signReportFromEmailController,
    controllerAs: 'srfevm',
    templateUrl: '/App/Components/signReportFromEmail/signReportFromEmail.tmpl.html'
};

export default signReportFromEmail;