import riskRulesetModalController from "./riskRulesetModal.controller";
import swal from "sweetalert2";
import createSpinner from "../../Shared/Functions/createSpinner";
import riskModalController from "./risksModal/riskModal.controller";
import availableObjectsForRiskModalController from "./Objects/availableObjectsForRiskModal.controller";
import businessProcessObjectsModalController from "./Objects/businessProcessObjectsModal.controller";
import rulesetObjectCopyModalController from "../../Shared/Components/rulesetObjectCopyModal/rulesetObjectCopyModal.controller";

export default /*@ngInject*/ function ($scope, $filter, FileSaver, $http, $uibModal, helperService, riskRulesetService) {

    const vm = this;

    vm.helperService = helperService;
    vm.updatePending = false;

    vm.changeRulesetRowSelectedFromRiskRulesetTable = changeRulesetRowSelectedFromRiskRulesetTable;
    vm.changeRiskRowSelectedFromRiskTable = changeRiskRowSelectedFromRiskTable;
    vm.openManageConditionsModal = openManageConditionsModal;
    vm.openRiskRulesetModal = openRiskRulesetModal;
    vm.openRulesetObjectCopyModal = openRulesetObjectCopyModal;
    vm.deleteRiskRuleset = deleteRiskRuleset;
    vm.exportRiskRuleset = exportRiskRuleset;

    vm.openRiskModal = openRiskModal;
    vm.changeRiskSelected = changeRiskSelected;
    vm.deleteRisk = deleteRisk;

    vm.openBusinessProcessObjectsModal = openBusinessProcessObjectsModal;
    vm.removeFromSelectedRiskObjects = removeFromSelectedRiskObjects;
    vm.openAvailableObjectsModal = openAvailableObjectsModal;
    vm.selectObject = selectObject;
    vm.selectAllObjects = selectAllObjects;

    $scope.$on('ImportRiskRuleset', (event, args) => getRiskRulesetsList());
    $scope.$on('CloneRiskRuleset', (event, args) => getRiskRulesetsList());

    vm.riskRulesetMenuOptions = [
        {
            text: 'Clone',
            click: function ($itemScope, $event, modelValue) {
                const riskRuleset = modelValue;
                vm.openRiskRulesetModal(riskRuleset, true);
            }
        },
        {
            text: 'Edit',
            click: function ($itemScope, $event, modelValue) {
                const riskRuleset = modelValue;
                vm.openRiskRulesetModal(riskRuleset, false);
            },
            displayed: function (modelValue) {
                return true;
            }
        },
        {
            text: 'Export',
            click: function ($itemScope, $event, modelValue) {
                const riskRuleset = modelValue;
                vm.exportRiskRuleset(riskRuleset);
            }
        },
        {
            text: 'Set as Default',
            click: async function ($itemScope, $event, modelValue) {
                const riskRulesetId = modelValue.RulesetId;
                const data = {
                    "RulesetId": riskRulesetId
                };
                await riskRulesetService.setDefaultRiskRuleset(riskRulesetId, data);
                getRiskRulesetsList();
            },
            displayed: function (modelValue) {
                return !modelValue.riskRuleset.IsDefault && modelValue.riskRuleset.IsEnabled;
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: function ($itemScope, $event, modelValue) {
                const riskRuleset = modelValue;
                vm.deleteRiskRuleset(riskRuleset);
            },
            displayed: function (modelValue) {
                return modelValue.riskRuleset.IsCustom;
            }
        }
    ];

    vm.riskMenuOptions = [
        {
            text: 'Edit',
            click: function ($itemScope, $event, modelValue) {
                const risk = modelValue;
                vm.openRiskModal(risk);
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: function ($itemScope, $event, modelValue) {
                const risk = modelValue;
                vm.deleteRisk(risk);
            }
        }
    ];

    vm.selectedRisks = [];
    vm.selectedBPs = [];

    activate();

    function activate() {
        getRiskRulesetsList();
    }

    // ----- Risk Ruleset -----
    async function getRiskRulesetsList(mostRecentRulesetId) {
        if (vm.riskRulesetsDataSource) { vm.riskRulesetsDataSource = null; }
        vm.loadingRiskRulesetList = true;
        try {
            let response = await riskRulesetService.getRiskRulesetList();
            if (mostRecentRulesetId) {
                let mostRecentRuleset = response.data.find(r => r.RulesetId === mostRecentRulesetId);
                response.data = response.data.filter(i => i.RulesetId !== mostRecentRuleset.RulesetId);
                response.data.unshift(mostRecentRuleset);
                vm.changeRulesetRowSelectedFromRiskRulesetTable(mostRecentRuleset);
            }
            vm.riskRulesetsDataSource = response.data;
            vm.loadingRiskRulesetList = false;
        } catch (error) {
            vm.loadingRiskRulesetList = false;
        }
    }

    function changeRulesetRowSelectedFromRiskRulesetTable(riskRuleset) {
        // only do something if a new ruleset is selected
        if (!vm.selectedRiskRuleset || vm.selectedRiskRuleset.RulesetId !== riskRuleset.RulesetId) {
            vm.selectedRisk = null;
            vm.selectedRiskRuleset = riskRuleset;
            vm.riskSearchBox = "";
            clearObjectSelection();
            changeRiskRulesetSelected(riskRuleset);
        }
    }

    function clearObjectSelection(showRiskSelection) {
        // clear selected property for bps because new data isnt being fetched
        if (vm.selectedBPs.length) {
            vm.selectedRisk.Objects.forEach(object => object.copy = false);
        }

        if (!showRiskSelection) {
            vm.selectedRisks = vm.selectedRisks.map(risk => {
                risk.copy = false;

                return risk;
            });

            vm.selectedRisks = [];
            vm.selectRisksMode = false;
            vm.allRisksSelected = false;
        }
        vm.selectBPsMode = false;
        vm.selectedBPs = [];
        vm.allBPsSelected = false;
    }

    function changeRiskRulesetSelected() {
        getRisksList(null, true, true);
    }

    async function openRiskRulesetModal(riskRuleset, isCloning) {
        let riskRulesetModal = $uibModal.open({
            templateUrl: '/App/Components/maintainRiskRuleset/riskRulesetModal.html',
            controller: riskRulesetModalController,
            controllerAs: 'rrmvm',
            backdrop: 'static',
            resolve: {
                riskRuleset: () => riskRuleset || null,
                isCloning: () => isCloning || null
            }
        });

        try {
            let result = await riskRulesetModal.result;
            getRiskRulesetsList(result);
        } catch (_) {
            return;
        }
    }

    async function deleteRiskRuleset(riskRuleset) {
        try {
            await swal(helperService.areYouSureParams('Delete Risk Ruleset', 'Are you sure you want to delete ' + riskRuleset.Name + '?', 'Delete'));
            await riskRulesetService.deleteRiskRuleset(riskRuleset.RulesetId);
            vm.selectedRiskRuleset = null;
            vm.selectedRisk = null;
            getRiskRulesetsList();
        } catch (_) {
            return;
        }
    }

    async function exportRiskRuleset(riskRuleset) {
        riskRuleset.exportUpdatePending = new createSpinner();
        try {
            let response = await $http.get(`${apiUrl}api/arm/risks/export/ruleset/${riskRuleset.RulesetId}`, { responseType: "blob" });
            FileSaver.saveAs(response.data, 'RiskRuleset.xlsx');
            riskRuleset.exportUpdatePending.loadingValue = false;
        } catch (error) {
            helperService.showErrorMessage(error.data);
            riskRuleset.exportUpdatePending.loadingValue = false;
        }
    }
    // ----- END Risk Ruleset -----


    // ----- Risk -----
    async function getRisksList(preselect, isNewRecord, isRulesetChanged) {
        vm.selectedRisk = null;
        if (vm.risksDataSource && isNewRecord && isRulesetChanged) { vm.risksDataSource = null; }
        vm.loadingRiskList = true;
        try {
            let response = await riskRulesetService.getRisks(vm.selectedRiskRuleset.RulesetId);
            if (preselect) {
                response.data.forEach(function (row, i) {
                    if (row.Id === preselect) {
                        if (isNewRecord) {
                            row['NewRecord'] = true;
                        } else {
                            row['NewRecord'] = false;
                        }
                        vm.selectedRisk = row;
                        vm.changeRiskSelected();
                    } else {
                        row['NewRecord'] = false;
                    }
                });

            }
            //Bubble New Record to top if possible
            response.data = $filter('orderBy')(response.data, ['-NewRecord', 'Name']);
            vm.risksDataSource = response.data;
            vm.loadingRiskList = false;
        } catch (error) {
            vm.loadingRiskList = false;
        }
    }

    function changeRiskRowSelectedFromRiskTable(risk) {
        vm.selectedRisk = risk;
        vm.searchBoxSelectedObjects = "";
        vm.changeRiskSelected();
    }

    function changeRiskSelected() {
        if (vm.selectedRisk) {
            clearObjectSelection(true);
            vm.selectedRisk.Objects.forEach(function (row, i) {
                $http.get(`${apiUrl}api/arm/defaultmitigationconditions/riskobjectId/${row.Id}`).then(function (response) {
                    row.ConditionList = response.data;
                });
            });
        }
    }

    async function openRiskModal(risk) {
        let riskModal = $uibModal.open({
            templateUrl: '/App/Components/maintainRiskRuleset/risksModal/riskModal.html',
            controller: riskModalController,
            controllerAs: 'rmvm',
            backdrop: 'static',
            resolve: {
                risk: () => risk || null,
                rulesetId: () => vm.selectedRiskRuleset.RulesetId
            }
        });

        try {
            const result = await riskModal.result;
            getRisksList(result.riskId, result.isNewRecord, result.isRulesetChanged);
        } catch (_) {
            return;
        }
    }

    async function openRulesetObjectCopyModal(objects) {
        let objectCopyModal = $uibModal.open({
            templateUrl: '/App/Shared/Components/rulesetObjectCopyModal/rulesetObjectCopyModal.html',
            controller: rulesetObjectCopyModalController,
            controllerAs: 'rocmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                objects: () => objects,
                ruleset: () => vm.selectedRiskRuleset
            }
        });
        try {
            await objectCopyModal.result;
            clearObjectSelection();
        } catch (_) {
            return;
        }
    }

    async function deleteRisk(risk) {
        try {
            await swal(helperService.areYouSureParams('Delete Risk', 'Are you sure you want to delete ' + risk.Name + '?', 'Delete'));
            try {
                vm.updatePending = true; 
                await $http.delete(`${apiUrl}api/arm/risks/${risk.Id}`);
                vm.selectedRisk = null;
                getRisksList(null, false, false);
                vm.updatePending = false;
            } catch (error) {
                helperService.showErrorMessage(error.data);
                vm.updatePending = false;
            }
        } catch (_) {
            return;
        }
    }
    // ----- END Risk -----


    // ----- Objects -----
    async function openManageConditionsModal(object) {
        const manageConditionsModal = $uibModal.open({
            component: 'manageConditions',
            backdrop: 'static',
            size: 'xl',
            resolve: {
                object: () => object
            }
        });

        try {
            await manageConditionsModal.result;
            getRisksList(vm.selectedRisk.Id, false, false);
        } catch (_) {
            return;
        }
    }

    async function removeFromSelectedRiskObjects(businessProcess) {
        try {
            await swal(helperService.areYouSureParamsWithHTML('Remove Object', `<div>Are you sure you want to remove <strong>${businessProcess.BusinessProcessName}</strong> from <strong>${vm.selectedRisk.Name}?</strong></div><div class="m-t-sm">This will also remove any associated conditions.</div>`, 'Remove'));
            await $http.delete(`${apiUrl}api/arm/risks/${vm.selectedRisk.Id}/objects/${businessProcess.Id}`);
            getRisksList(vm.selectedRisk.Id, false, false);
        } catch (_) {
            return;
        }


    }

    async function openAvailableObjectsModal() {
        let availableObjectsModal = $uibModal.open({
            templateUrl: '/App/Components/maintainRiskRuleset/objects/availableObjectsForRiskModal.html',
            controller: availableObjectsForRiskModalController,
            controllerAs: 'aocvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                riskRuleset: () => vm.selectedRiskRuleset,
                risk: () => vm.selectedRisk
            }
        });

        try {
            await availableObjectsModal.result;
            getRisksList(vm.selectedRisk.Id, false, false);
        } catch (_) {
            return;
        }
    }

    function openBusinessProcessObjectsModal(businessProcess) {
        $uibModal.open({
            templateUrl: '/App/Components/maintainRiskRuleset/objects/businessProcessObjectsModal.html',
            controller: businessProcessObjectsModalController,
            controllerAs: 'bpovm',
            backdrop: 'static',
            resolve: {
                selectedBusinessProcess: () => businessProcess
            },
            size: 'lg'
        });
    }

    function selectObject(object, selectedObjects) {

        if (object.copy) {
            selectedObjects.push(object);
        }
        else {
            selectedObjects.splice(selectedObjects.indexOf(object), 1);
        }
        vm.allRisksSelected = vm.risksDataSource.every(object => object.copy);
        vm.allBPsSelected = vm.selectedRisk?.Objects.every(object => object.copy);
    }

    function selectAllObjects(objectType) {
        if (objectType === 'risk') {
            vm.risksDataSource.forEach(risk => risk.copy = vm.allRisksSelected);
            vm.selectedRisks = vm.risksDataSource.filter(object => object.copy);
        }
        else {
            vm.selectedRisk.Objects.forEach(object => object.copy = vm.allBPsSelected);
            vm.selectedBPs = vm.selectedRisk.Objects.filter(object => object.copy);
        }
    }
    // ----- END Objects -----

}