import { customAutoCompleteFilter, standardGridPageSize } from "../../../GlobalVariables/filterVariables";

export default /*@ngInject*/ function (helperService, userProfileDataService) {
    const vm = this;
    vm.helperService = helperService;

    vm.accessCollapsed = true;
    vm.userProductCollapsed = true;

    vm.$onInit = activate;

    function activate() {
        setupGrid();
    }

    function setupGrid() {
        const productProfileAccessGridColumns = [
            { field: "SecurityObjectName", title: "Object Name", width: 120, filterable: customAutoCompleteFilter },
            { field: "SecurityObjectAltName", title: "Object Label", width: 120, filterable: customAutoCompleteFilter },
            { field: "SecurityObjectTypeName", title: "Object Type", width: 120, filterable: customAutoCompleteFilter },
            { field: "AccessTypeName", title: "Access Type", width: 120, filterable: customAutoCompleteFilter },
            { field: "AccessLevelName", title: "Access Level", width: 120, filterable: customAutoCompleteFilter }
        ];

        vm.productProfileAccessGridOptions = helperService.setMainGridOptions(productProfileAccessGridColumns, 400);
        vm.productProfileAccessGridOptions.autoBind = true;

        vm.productProfileAccessGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    try {
                        const { data } = await userProfileDataService.getUserProfileAccess(vm.product.User.UserId, vm.product.User.ProductId);
                        options.success(data);
                    } catch (err) {
                        return;
                    }
                }
            }
        });
    }
}