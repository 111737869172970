/* groupOwnerItemsList.component.js */

import groupOwnerItemsListController from "./groupOwnerItemsList.controller";

/**
 * @desc component for viewing and maintaining list of ownership group owner items
 * @example <ownership-group-owner-items-list><ownership-group-owner-items-list>
 */

const groupOwnerItemsList = {
    bindings: {
        selectedOwnershipGroup: '<',
        selectedGroupOwner: '<',
    },
    controller: groupOwnerItemsListController,
    controllerAs: 'goilvm',
    templateUrl: '/App/Components/OwnershipGroups/groupOwnerItemsList/groupOwnerItemsList.tmpl.html'
};

export default groupOwnerItemsList;