import existingUserExpansionController from "./existingUserExpansion.controller"

/* existingUserExpansion.component.js */

/**
 * @desc component for displaying existing user table and linked user info when selected
 * @example <existing-user-expansion></existing-user-expansion>
 */


const existingUserExpansion = {
    bindings: {
        linkedUserChange: '&'
    },
    controller: existingUserExpansionController,
    controllerAs: 'euevm',
    template: `<div class="row marginBottom">
    <strong>Select Existing User Account</strong>
</div>
<div class="row">
    <div kendo-grid="ExistingUsersGrid"
         id="ExistingUsersGrid"
         k-options="euevm.ExistingUsersGridOptions"
         k-data-source="euevm.ExistingUsersGridDataSource"
         k-on-change="euevm.selectUser(kendoEvent)"></div>
</div>`
};

export default existingUserExpansion;