import angular from 'angular';
import dataService from '../APICalls/CommonDataService';
import objectIdDataService from '../APICalls/ObjectIdDataService';
import exceptionHandlerFactory from './Factories/exceptionHandler.factory';
import ReportDataService from '../APICalls/ReportDataService';
import reportsDataService from '../Components/Reports/reportsDataService.factory';
import errorsDataService from '../APICalls/ErrorsDataService';
import viewModelHelperFactory from './Factories/viewModelHelper.factory';
import configurationDataServiceFactory from '../APICalls/configurationDataService.factory';
import emailNavigationServiceFactory from './Services/emailNavigationService.factory';
import dateFormatterFactory from './Factories/dateFormatter.factory';
import userRolesDataServiceFactory from './Components/selectRolesByUser/userRolesDataService.factory';
import approvalGroupsDataServiceFactory from '../APICalls/approvalGroupsDataService.factory';
import myDownloadsDataServiceFactory from '../Components/MyDownloads/myDownloadsDataService.factory';
import userProfileDataServiceFactory from './Components/userProfile/userProfileDataService.factory';
import accessDetailsDataServiceFactory from './Components/accessDetailsProfile/accessDetailsDataService.factory';
import homeDataServiceFactory from '../Components/home/homeDataService.factory';

export default angular
    .module('app.shared.factories', [])
    .factory('reportDataService', ReportDataService)
    .factory('dataService', dataService)
    .factory('objectIdDataService', objectIdDataService)
    .factory('$exceptionHandler', exceptionHandlerFactory)
    .factory('reportsDataService', reportsDataService)
    .factory('errorsDataService', errorsDataService)
    .factory('viewModelHelper', viewModelHelperFactory)
    .factory('configurationDataService', configurationDataServiceFactory)
    .factory('emailNavigationService', emailNavigationServiceFactory)
    .factory('dateFormatterFactory', dateFormatterFactory)
    .factory('userRolesDataService', userRolesDataServiceFactory)
    .factory('approvalGroupsDataService', approvalGroupsDataServiceFactory)
    .factory('myDownloadsDataService', myDownloadsDataServiceFactory)
    .factory('userProfileDataService', userProfileDataServiceFactory)
    .factory('accessDetailsProfileDataService', accessDetailsDataServiceFactory)
    .factory('homeDataService', homeDataServiceFactory)
    .name;