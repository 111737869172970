import { standardGridPageSize, customAutoCompleteFilter } from "../../Shared/GlobalVariables/filterVariables";
import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($scope, $http, helperService, AXSecurityDesignerService, errorsDataService, $uibModalInstance, sim) {

    var vm = $scope.vm = {};

    vm.sim = sim;
    vm.cancel = cancel;
    vm.exportSimulationChanges = exportSimulationChanges;
    vm.setGridOptions = setGridOptions;

    function activate() {
        getSimulationChanges();
    }

    activate();

    function getSimulationChanges() {
        vm.loadingChanges = true;
        $http.get(apiUrl + 'api/securitydesigner/simulation/' + sim.Id + '/changes').then(response => {
            vm.loadingChanges = false;
            sim.modifications = response.data;
        }).catch(response => {
            vm.loadingChanges = false;
            errorsDataService.catch(response);
        });
    }

    function setGridOptions(columns) {
        return helperService.setMainGridOptions(columns, 250);
    }

    vm.newSecurityLayersGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: options => {
                options.success(sim.modifications.CreatedSecurityLayers);
            }
        }
    });

    vm.parentToChildGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: options => {
                options.success(sim.modifications.ParentToChild);
            }
        }
    });

    vm.parentToObjectGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: options => {
                options.success(sim.modifications.ParentToObject);
            }
        }
    });

    vm.removedSecurityLayersGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: options => {
                options.success(sim.modifications.RemovedSecurityLayers);
            }
        }
    });

    vm.newSecurityLayersDataGridColumns = [
        { field: "Name", title: "Name", width: 120, filterable: customAutoCompleteFilter },
        { field: "Description", title: "Description", width: 120, filterable: customAutoCompleteFilter },
        { field: "Type", title: "Security Layer Type", width: 120, filterable: customAutoCompleteFilter },
        { field: "ModifiedBy", title: "Modified By", width: 120, filterable: customAutoCompleteFilter }
    ];

    vm.parentToChildDataGridColumns = [
        { field: "ParentObjectName", title: "Parent Object Name", width: 120, filterable: customAutoCompleteFilter },
        { field: "ParentObjectId", title: "Parent Object Id", width: 120, filterable: customAutoCompleteFilter },
        { field: "ParentObjectType", title: "Parent Object Type", width: 120, filterable: customAutoCompleteFilter },
        { field: "ChildObjectName", title: "Child Object Name", width: 120, filterable: customAutoCompleteFilter },
        { field: "ChildObjectId", title: "Child Object Id", width: 120, filterable: customAutoCompleteFilter },
        { field: "ChildObjectType", title: "Child Object Type", width: 120, filterable: customAutoCompleteFilter },
        { field: "Action", title: "Action", width: 120, filterable: customAutoCompleteFilter },
        { field: "ModifiedBy", title: "Modified By", width: 120, filterable: customAutoCompleteFilter }
    ];

    vm.parentToObjectDataGridColumns = [
        { field: "ParentObjectName", title: "Parent Object Name", width: 120, filterable: customAutoCompleteFilter },
        { field: "ParentObjectId", title: "Parent Object Id", width: 120, filterable: customAutoCompleteFilter },
        { field: "ParentObjectType", title: "Parent Object Type", width: 120, filterable: customAutoCompleteFilter },
        { field: "ChildObjectName", title: "Child Object Name", width: 120, filterable: customAutoCompleteFilter },
        { field: "ChildObjectId", title: "Child Object Id", width: 120, filterable: customAutoCompleteFilter },
        { field: "ChildObjectType", title: "Child Object Type", width: 120, filterable: customAutoCompleteFilter },
        { field: "Read", title: "Read", width: 120, filterable: customAutoCompleteFilter },
        { field: "Update", title: "Update", width: 120, filterable: customAutoCompleteFilter },
        { field: "Create", title: "Create", width: 120, filterable: customAutoCompleteFilter },
        { field: "Delete", title: "Delete", width: 120, filterable: customAutoCompleteFilter },
        { field: "Invoke", title: "Invoke", width: 120, filterable: customAutoCompleteFilter },
        { field: "ModifiedBy", title: "Modified By", width: 120, filterable: customAutoCompleteFilter }
    ];

    vm.removedSecurityLayersDataGridColumns = [
        { field: "Name", title: "Name", width: 120, filterable: customAutoCompleteFilter },
        { field: "SystemName", title: "System Name", width: 120, filterable: customAutoCompleteFilter },
        { field: "Type", title: "Security Layer Type", width: 120, filterable: customAutoCompleteFilter },
        { field: "ModifiedBy", title: "Modified By", width: 120, filterable: customAutoCompleteFilter }
    ];

    function exportSimulationChanges() {
        vm.pleaseWait = new createSpinner();
        AXSecurityDesignerService.exportSimulationChanges(sim).then(response => {
            helperService.successfulSaveButton(vm.pleaseWait);
        }).catch(response => {
            vm.pleaseWait.loadingValue = false;
            errorsDataService.catch(response);
        });
    }

    function cancel() { $uibModalInstance.close(); }

}