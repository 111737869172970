import manageDataProductController from "./manageDataProduct.controller";

/* manageDataProduct.component.js */

/**
 * @desc component for each product to handle any manage data functions
 * @example <manage-data-product></manage-data-product>
 */

const manageDataProduct = {
    bindings: {
        product: '<'
    },
    controller: manageDataProductController,
    controllerAs: 'mdpvm',
    templateUrl: '/App/Components/ManageDataV2/Product/manageDataProduct.tmpl.html'
};

export default manageDataProduct;