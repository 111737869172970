import autoMappingAssignmentsController from "./autoMappingAssignments.controller";

/* autoMappingAssignments.component.js */

/**
 * @desc component for viewing, creating, editing, and removing mappings for auto generation of supervisor assignments
 * @example <auto-mapping-assignments></auto-mapping-assignments>
 */

const autoMappingAssignments = {
    controller: autoMappingAssignmentsController,
    controllerAs: 'amavm',
    templateUrl: '/App/Components/SupervisorAssignments/AutoMappingAssignments/autoMappingAssignments.tmpl.html'
};

export default autoMappingAssignments;