/* systemUserProductMappings.component.js */

import editSystemUserMappingModalController from "./editSystemUserMappingModal.controller";

/**
 * @desc component for viewing, creating, editing, and removing mappings for auto generation of Cross Platform Users
 * @example <system-user-product-mappings></system-user-product-mappings>
 */

const systemUserProductMappings = {
    controller: systemUserProductMappingsController,
    controllerAs: 'supmvm',
    bindings: {
        product: '<'
    },
    template: `<div list-view>
    <list-item class="list-item">
        <div class="flex-column">
            <div class="k-flex-auto flex-vcenter">
                <div title="{{ supmvm.product.Name }}" class="contain-background-icon pull-left" ng-class="supmvm.productImageClass"></div>
                <h4 class="m-l-md">{{ supmvm.product.Name }}</h4>
                <a class="m-l-md m-b-xs" ng-click="supmvm.openEditMappingModal()"><i class="glyphicon glyphicon-edit text-warning"></i></a>
            </div>
        </div>
        <div class="flex-column">
            <div class="k-flex-auto flex-vcenter">
                <h4>System User Mapping Field: </h4>
                <p class="m-l-lg m-t-xs">{{supmvm.selectedField ?  supmvm.selectedField.DisplayName : 'No System Mapping Field'}}</p>
            </div>
        </div>
    </list-item>
</div>`
};

/*@ngInject*/ function systemUserProductMappingsController(supervisorAssignmentsDataService, manageDataDataService, helperService, $filter, $uibModal) {

    const vm = this;

    vm.$onInit = activate;

    vm.openEditMappingModal = openEditMappingModal;

    async function activate() {
        vm.selectedFieldId = await getSelectedFieldId('AutoMappingSystemFieldId');
        await setSystemFields();
        vm.productImageClass = helperService.setProductImageClass(vm.product.Type);
    }

    async function getSelectedFieldId(name) {
        let { data } = await manageDataDataService.getConfigurationByProductId(vm.product.ID);
         return data.find(item => item.Name === name)?.Value;
    }

    async function setSystemFields() {
        const { data: universalFields } = await supervisorAssignmentsDataService.getUniversalMappingFields(),
            { data: productSpecificFields } = await supervisorAssignmentsDataService.getProductMappingFields(vm.product.Type);

        vm.systemFields = universalFields.concat(productSpecificFields);
        vm.systemFields = $filter('orderBy')(vm.systemFields, 'DisplayName');

        vm.selectedField = vm.systemFields.find(fieldId => fieldId.Id === vm.selectedFieldId);
    }

    async function openEditMappingModal() {
        let mappingModal = $uibModal.open({
            templateUrl: '/App/Components/CrossPlatformUsers/userFieldMappings/editSystemUserMappingModal.tmpl.html',
            controller: editSystemUserMappingModalController,
            controllerAs: 'esummvm',
            backdrop: 'static',
            resolve: {
                product: () => vm.product,
                systemFields: () => vm.systemFields,
                selectedFieldId: () => vm.selectedFieldId
            }
        });

        try {
            vm.selectedFieldId = await mappingModal.result;
            vm.selectedField = vm.systemFields.find(fieldId => fieldId.Id === vm.selectedFieldId);
        } catch {}
    }

}

export default systemUserProductMappings;