import '../../ExternalScripts/RaygunSetup';
import 'jquery';
import angular from 'angular';
import 'angular-applicationinsights';
import 'angular-animate';
import '../../ExternalScripts/angular-elastic-builder/dist/angular-elastic-builder';
import 'angular-bootstrap-contextmenu';
import 'angular-file-saver';
import 'angular-listview/angular-listview.min';
import 'angular-recursion';
import 'angular-resizable';
import 'angular-route';
import 'angular-sanitize';
import 'angular-spinner';
import 'angular-translate';
import 'angular-ui-bootstrap';
import 'angular-wizard';
import 'bootstrap';
import FusionCharts from 'fusioncharts';
import 'angularjs-fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import OceanTheme from 'fusioncharts/themes/fusioncharts.theme.ocean';
import 'bootstrap';
import 'metismenu';
import 'ng-Packery';
import 'oi.select';
import '../../ExternalScripts/fastpath-select-tpls';
import 'v-accordion';
import '@flowjs/ng-flow/dist/ng-flow-standalone.min';
import swal from 'sweetalert2';
import '../../ExternalScripts/Inspina/inspinia';
import 'sugar/sugar.min';

//controller imports
import navCtrl from './Controllers/navController';
import pageWrapperCtrl from './Controllers/pageWrapper.controller';
import loginCtrl from './Controllers/login.controller';
import redirectController from './Controllers/redirectController';
import callbackController from './Controllers/callback.controller';
import rootController from '../app.report.rootViewModel';
import sideNavController from './Controllers/rightSideNavController';
import { reportToolbarController } from './Controllers/ReportToolbarViewModel';
import BackNavigationBarController from '../Components/NavigationBar/BackNavigationBarController';
import dynamicTabController from '../Components/DynamicReports/DynamicTabViewModel';

//component imports
import home from '../Components/home/home.component';
import myDownloads from '../Components/myDownloads/myDownloads.component';
import signReportFromEmail from '../Components/signReportFromEmail/signReportFromEmail.component';

//css imports
import '../../css/site.scss';

//config imports
import routeConfig from '../app.route';
import raygunConfig from './Config/raygun.config';
import churnZero from './Config/churnZero.config';
import helpObjectConfig from './Config/helpObject.config';
import appInsightsConfig from './Config/applicationInsights.config';
import spinnerConfig from './Config/spinner.config';
import translationConfig from './Config/translation.config';
import tenantInterceptorConfig from './Interceptors/tenantInterceptor';
import authInterceptor from './Interceptors/authInterceptor';
import responseErrorInterceptorConfig from './Interceptors/responseErrorInterceptor';
import flowJsConfig from './Config/flowJs.config';
import locationConfig from './Config/location.config';

//provider imports
import dialogsProvider from './Functions/dialogs';
import modal from 'angular-ui-bootstrap/src/modal';

//module imports
import kendoModule from './kendo.module';
import sharedServicesModule from './sharedServices.module';
import sharedFactoriesModule from './sharedFactories.module';
import signalRModule from './signalr.module';
import sharedComponentsModule from './sharedComponents.module';
import dashboardReportsModule from './dashboardReports.module';
import sharedFiltersModule from './sharedFilters.module ';
import sharedDirectivesModule from './sharedDirectives.module';
import npsQuestionModalController from './Controllers/npsQuestionModal.controller';

Charts(FusionCharts);
OceanTheme(FusionCharts);
FusionCharts.options.license({
    key: 'KqA8ydjB3B3E2A3B15B10A7E6E6F4C1H2C1nqsB1F2F5cE4F3B9vulC4E3A3jnA-21A5A1B3E-11fbI2J3A4A6A2B4A2A3G1D2G1emoE6E1e1D4akzD7E6F4kzE3MG1B6vpaB2E3C1F3H3A8A6D4A3D3E3B2A12A32y==',
    creditLabel: false,
});

swal.setDefaults({
    allowOutsideClick: false,
    reverseButtons: true,
    confirmButtonClass: 'swal-confirm-button',
    cancelButtonClass: 'swal-cancel-button'

});


export default angular
    .module('app.shared', [
        'ngRoute',
        'ngAnimate',
        'flow',
        'kendo.directives',
        'ui.bootstrap',
        'ui.bootstrap.contextMenu',
        'ngFileSaver',
        'oi.select',
        'ngSanitize',
        'mgo-angular-wizard',
        'angularSpinner',
        'angularResizable',
        'ApplicationInsightsModule',
        'listview',
        'vAccordion',
        'ng-fusioncharts',
        'RecursionHelper',
        'ngPackery',
        'angular-elastic-builder',
        'pascalprecht.translate',
        modal,
        kendoModule,
        sharedServicesModule,
        sharedFactoriesModule,
        sharedComponentsModule,
        sharedFiltersModule,
        sharedDirectivesModule,
        signalRModule,
        dashboardReportsModule
    ])

    // components
    .component("home", home)
    .component("myDownloads", myDownloads)
    .component("signReportFromEmail", signReportFromEmail)

    // controllers
    .controller('navCtrl', navCtrl)
    .controller('pageWrapperCtrl', pageWrapperCtrl)
    .controller('loginCtrl', loginCtrl)
    .controller('redirectCtrl', redirectController)
    .controller('callbackCtrl', callbackController)
    .controller('rootViewModel', rootController)
    .controller('sideNavCtrl', sideNavController)
    .controller("dynamicTabViewModel", dynamicTabController)
    .controller("reportToolbarViewModel", reportToolbarController)
    .controller("BackNavigationBarController", BackNavigationBarController)
    .controller("npsQuestionModalController", npsQuestionModalController)

    // configs
    .config(routeConfig)
    .config(appInsightsConfig)
    .config(spinnerConfig)
    .config(translationConfig)
    .config(tenantInterceptorConfig)
    .config(responseErrorInterceptorConfig)
    .config(authInterceptor)
    .config(flowJsConfig)
    //providers
    .provider('dialogs', [dialogsProvider])
    // runs
    .run(raygunConfig)
    .run(churnZero)
    .run(helpObjectConfig)
    .run(locationConfig)
    .name;