import angular from 'angular';
import DataChangesViewModel from '../Components/DataChanges/DataChangesViewModel';
import auditTrailFactoriesModule from './auditTrail.factories.module';
import auditTrailNetSuiteModule from './auditTrailNetSuite.module';
import manageDataAction from '../Components/ManageDataV2/Action/manageDataAction.component';
import manageDataConfiguration from '../Components/ManageDataV2/Configuration/manageDataConfiguration.component';
import manageDataSchedule from '../Components/ManageDataV2/Schedule/manageDataSchedule.component';
import manageDataProduct from '../Components/ManageDataV2/Product/manageDataProduct.component';
import maintainTemplates from '../Components/AuditTrail/MaintainTemplates/maintainTemplates.component';
import templatesList from '../Components/AuditTrail/MaintainTemplates/templatesList.component';
import ManageDataViewModel from '../Components/ManageData/ManageDataViewModel';
import ArchiveDataChangesController from '../Components/ManageData/ArchiveDataChanges/ArchiveDataChangesController';
import CollectDataChangesController from '../Components/ManageData/CollectDataChanges/CollectDataChangesController';
import CollectSnapshotController from '../Components/ManageData/CollectSnapshot/CollectSnapshotController';
import PurgeDataChangesController from '../Components/ManageData/PurgeDataChanges/PurgeDataChangesController';
import RestoreDataChangesController from '../Components/ManageData/RestoreDataChanges/RestoreDataChangesController';
import GenericManageDataScheduleController from '../Components/ManageData/GenericManageDataScheduleController';
import oracleCloudDataChanges from '../Components/DataChangesOracleCloud/oracleCloudDataChanges.component';
import maintainSnapshots from '../Components/AuditTrail/MaintainSnapshots/maintainSnapshots.component';
import snapshotsList from '../Components/AuditTrail/MaintainSnapshots/SnapshotsList/snapshotsList.component';
import reportGenerator from '../Components/ReportGenerator/reportGenerator.component';
import currentSnapshot from '../Components/CurrentSnapshot/currentSnapshot.component';
import applyTrackingTemplates from '../Components/ApplyTrackingTemplates/applyTrackingTemplates.component';
import maintainTemplate from '../Components/TemplateTracking/maintainTemplate.component';
import manageData from '../Components/ManageDataV2/manageData.component';
import templateDetails from '../Components/TemplateTracking/TemplateTrackingDetails/templateDetails.component';
import workdayMaintainTemplates from '../Components/workdayMaintainTemplates/workdayMaintainTemplates.component';
import workdayTemplatesList from '../Components/workdayMaintainTemplates/templatesList/templatesList.component';
import classesList from '../Components/workdayMaintainTemplates/classesList/classesList.component';
import maintainObjectTracking from '../Components/salesForceAuditTrail/maintainObjectTracking/maintainObjectTracking.component';
import maintainObjectTrackingGrid from '../Components/salesForceAuditTrail/maintainObjectTracking/maintainObjectTrackingGrid/maintainObjectTrackingGrid.component';
import maintainObjectTrackingGridController from '../Components/salesForceAuditTrail/maintainObjectTracking/maintainObjectTrackingGrid/maintainObjectTrackingGrid.controller';
import setupChanges from '../Components/setupChanges/setupChanges.component';



export default angular
    .module('app.auditTrail', [auditTrailFactoriesModule, auditTrailNetSuiteModule])
    .controller("dataChangesViewModel", DataChangesViewModel)
    .component('templateDetails', templateDetails)
    .component("maintainTemplate", maintainTemplate)
    .component('manageDataAction', manageDataAction)
    .component('manageDataConfiguration', manageDataConfiguration)
    .component('manageDataSchedule', manageDataSchedule)
    .component('manageData', manageData)
    .component('manageDataProduct', manageDataProduct)
    .component('maintainTemplates', maintainTemplates)
    .component('templatesList', templatesList)
    .controller("manageDataViewModel", ManageDataViewModel)
    .controller("ArchiveDataChangesController", ArchiveDataChangesController)
    .controller("CollectDataChangesController", CollectDataChangesController)
    .controller("CollectSnapshotController", CollectSnapshotController)
    .controller("PurgeDataChangesController", PurgeDataChangesController)
    .controller("RestoreDataChangesController", RestoreDataChangesController)
    .controller("GenericManageDataScheduleController", GenericManageDataScheduleController)
    .component("reportGenerator", reportGenerator)
    .component('oracleCloudDataChanges', oracleCloudDataChanges)
    .component('maintainSnapshots', maintainSnapshots)
    .component('snapshotsList', snapshotsList)
    .component('currentSnapshot', currentSnapshot)
    .component('applyTrackingTemplates', applyTrackingTemplates)
    .component('workdayMaintainTemplates', workdayMaintainTemplates)
    .component('workdayTemplatesList', workdayTemplatesList)
    .component('workdayClassesList', classesList)
    .component('maintainObjectTracking', maintainObjectTracking)
    .component('maintainObjectTrackingGrid', maintainObjectTrackingGrid)
    .controller('maintainObjectTrackingGridController', maintainObjectTrackingGridController)
    .component('setupChanges', setupChanges)
    .name;