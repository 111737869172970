/* businessProcessProductProfile.component.js */

import businessProcessProductProfileController from "./businessProcessProductProfile.controller";

/**
 * @desc component for viewing Business Process details profile information for a specific product
 * @example <risk-product-profile></risk-product-profile>
 */

const businessProcessProductProfile = {
    bindings: {
        product: '<',
        businessProcess: '<'
    },
    controller: businessProcessProductProfileController,
    controllerAs: 'bpppvm',
    templateUrl: '/App/Shared/Components/accessDetailsProfile/businessProcessDetailsProductProfile/businessProcessProductProfile.tmpl.html'
};

export default businessProcessProductProfile;