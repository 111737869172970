import { standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($scope, $uibModal, RequestManagementNetSuiteService, manageDataDataService, $http) {

    if (!$scope.vm.User.NetSuiteUser) {
        $scope.vm.User.NetSuiteUser = {};
    }

    $scope.vm.selectUser = selectUser;
    $scope.vm.User.NetSuiteUser.ProductType = "NS";
    setFieldConfigurationValues();

    $scope.vm.subsidiaryDropdownChange = function (netSuiteUser) {
        getDepartments(netSuiteUser);
        getClasses(netSuiteUser);
        getLocations(netSuiteUser);
    };

    function getDepartments(netSuiteUser) {
        //If the user is not passed in try the $scope to get the data
        if (netSuiteUser == null) {
            netSuiteUser = $scope.vm.User.NetSuiteUser;
        }

        if (netSuiteUser.Subsidiary) {
            RequestManagementNetSuiteService.getDepartmentsBySubsidiary(netSuiteUser.Subsidiary.Id).then(function (response) {
                $scope.vm.NSDepartmentDataSource = response.data;
            });
        }
    }

    function getClasses(netSuiteUser) {
        //If the user is not passed in try the $scope to get the data
        if (netSuiteUser == null) {
            netSuiteUser = $scope.vm.User.NetSuiteUser;
        }

        if (netSuiteUser.Subsidiary) {
            RequestManagementNetSuiteService.getClassesBySubsidiary(netSuiteUser.Subsidiary.Id).then(function (response) {
                $scope.vm.NSClassDataSource = response.data;
            });
        }
    }

    function getLocations(netSuiteUser) {
        //If the user is not passed in try the $scope to get the data
        if (netSuiteUser == null) {
            netSuiteUser = $scope.vm.User.NetSuiteUser;
        }

        if (netSuiteUser.Subsidiary) {
            RequestManagementNetSuiteService.getLocationsBySubsidiary(netSuiteUser.Subsidiary.Id).then(function (response) {
                $scope.vm.NSLocationDataSource = response.data;
            });
        }
    }

    $scope.vm.clearNSUser = function () {
        $scope.vm.User.NetSuiteUser = null;
        $scope.vm.NSDepartmentDataSource = null;
        $scope.vm.NSLocationDataSource = null;
        $scope.vm.NSClassDataSource = null;
    };

    //General User
    $scope.vm.nsUserFromDateObject = null;
    $scope.vm.nsUserToDateObject = null;
    $scope.vm.nsUserMnsDate = new Date();
    var nsUserMinDate = new Date();
    nsUserMinDate.setHours(0, 0, 0, 0);

    $scope.vm.nsUserMinDate = nsUserMinDate;

    $scope.nsUserFromDateChanged = function () {
        var date;
        if ($scope.vm.User.NetSuiteUser.StartDate === '') {
            date = new Date();
        } else {
            date = new Date($scope.vm.User.NetSuiteUser.StartDate);
        }

        $scope.vm.nsUserMinDate = date;
        $scope.vm.nsMinDate = date;
        $scope.vm.fromMinDate = date;
        if ($scope.vm.User.NetSuiteUser == null) {
            $scope.vm.User.NetSuiteUser = {};
        }
    };

    $scope.nsUserToDateChanged = function () {
        $scope.vm.nsUserMnsDate = new Date($scope.vm.User.NetSuiteUser.EndDate);
        $scope.vm.nsMnsDate = new Date($scope.vm.User.NetSuiteUser.EndDate);
        $scope.vm.fromMnsDate = new Date($scope.vm.User.NetSuiteUser.StartDate);
        if ($scope.vm.User.NetSuiteUser == null) {
            $scope.vm.User.NetSuiteUser = {};
        }
    };

    $scope.openModal = function () {
        $scope.vm.ExistingWindowsUserSearchString = null;

        $uibModal.open({
            templateUrl: '/App/Components/RequestManagement/WizardSteps/Universal/SearchWindowsUsersModal.html',
            controller: SearchNonNSUsersController,
            backdrop: 'static',
            size: 'lg',
            scope: $scope
        });
    };

    async function setFieldConfigurationValues() {
        $scope.vm.showADUserLookup = $scope.vm.config.find(config => config.Name === 'AD_Active').Value === '1';
        $scope.vm.showAADUserLookup = $scope.vm.config.find(config => config.Name === 'AAD_Active').Value === '1';
        let response = await manageDataDataService.getConfigurationByProductId('NS');
        $scope.vm.disableADUserFields = response.data.find(config => config.Name === 'IM_DisableADUserFields').Value === '1' && $scope.vm.showADUserLookup;
        $scope.vm.disableAADUserFields = response.data.find(config => config.Name === 'IM_DisableAADUserFields').Value === '1' && $scope.vm.showAADUserLookup;
    }

    function selectUser(user) {
        $scope.vm.User.NetSuiteUser = user;
        if (user.UserType !== 'Guest') {
            setProvider();
        }
    }

    async function setProvider() {
        const { data } = await $http.get(`${apiUrl}api/universal/products/NS/configuration/im_provider`);
        $scope.vm.User.NetSuiteUser.NetworkDomain = data.Value;
    }

}

function SearchNonNSUsersController($scope, $rootScope, $filter, $http, $uibModalInstance, helperService) {

    var vm = $scope.vm;
    $scope.selectedUser = null;
    $scope.modalTitle = 'Search for Existing NetSuite Employee';
    vm.ExistingWindowsUserSearchString = '';

    //Search Existing Users
    var getExistingUserGridColumns = function () {
        return [
            { field: "FirstName", title: "First Name", filterable: false },
            { field: "LastName", title: "Last Name", filterable: false },
            { field: "Email", title: "Email", filterable: false }
        ];
    };

    $scope.doneSearchingUsers = function (vm) {
        $rootScope.IMSelectedExistingUser = null;

        if (vm.ExistingWindowsUserSearchString === '' || vm.ExistingWindowsUserSearchString == null) {

            var raw = vm.ExistingusersGridDataSource.data();
            var length = raw.length;

            var item, i;
            for (i = length - 1; i >= 0; i--) {

                item = raw[i];
                vm.ExistingusersGridDataSource.remove(item);

            }
        } else {
            if (vm.wizardMode === 'Terminate') {
                vm.ExistingusersGridDataSource.read();
            } else {
                vm.ExistingusersGridDataSource.read();
            }
        }
    };

    vm.ExistingUsersGridOptions = helperService.setMainGridOptions(getExistingUserGridColumns(), 350);
    vm.ExistingUsersGridOptions.autoBind = false;
    vm.ExistingUsersGridOptions.pageable = false;

    vm.ExistingusersGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: function (options) {
                $http({
                    method: 'GET',
                    url: `${apiUrl}api/identitymanager/ns/employees/search`,
                    params: { searchStr: vm.ExistingWindowsUserSearchString }
                }).then(function (response) {
                    response.data = $filter('orderBy')(response.data, 'UserName');
                    options.success(response.data);
                }, function errorCallback(response) {
                    options.error();
                    helperService.showErrorMessage(response.data);
                });
            }
        }
    });

    vm.searchKeyDown = function () {
        vm.SearchExistingUserSelected = null;
    };

    vm.changeExistingUserRowSelected = function (kendoEvent) {
        var grid = kendoEvent.sender;
        $scope.selectedUser = grid.dataItem(grid.select());
    };

    $scope.selectUser = function () {
        if ($scope.selectedUser != null) {
            if (vm.User.NetSuiteUserUser == null) {
                vm.User.NetSuiteUserUser = {};
            }

            $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/ns/employees/' + $scope.selectedUser.Id

            }).then(function (response) {

                vm.User.NetSuiteUser = response.data;
                vm.ListOfSelectedNetSuiteRoles = response.data.Roles;
                updateListOfAvailableRoles();
                vm.subsidiaryDropdownChange();
            }, function errorCallback(response) {
                helperService.showErrorMessage(response.data);
            });

        }
        vm.ExistingWindowsUserSearchString = null;

        $uibModalInstance.close();
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

    function updateListOfAvailableRoles() {
        vm.ListOfSelectedNetSuiteRoles.forEach(function (role) {
            vm.ListOfAvailableNetSuiteRoles.forEach(function (availableRole, j) {
                if (availableRole.Id === role.Id) {
                    vm.ListOfAvailableNetSuiteRoles.splice(j, 1);
                }
            });
        });
    }
}
