export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getRoles,
        getModules,
        getModulesByObjectId,
        getSecurableObjects,
        removeRole,
        removeRoleAccess,
        addRole,
        addRoleAccess
    };

    return service;

    // GET Calls
    async function getRoles() {
        try {
            return await $http.get(`${apiUrl}api/core/Role`);
        } catch (err) {
            showError(err);
        }
    }

    async function getModules() {
        try {
            return await $http.get(`${apiUrl}api/core/modules`);
        } catch (err) {
            showError(err);
        }
    }

    async function getModulesByObjectId(objectId) {
        try {
            return await $http.get(`${apiUrl}api/core/objects/module/${objectId}`);
        } catch (err) {
            showError(err);
        }
    }

    async function getSecurableObjects(roleId, objectId) {
        try {
            return await $http.get(`${apiUrl}api/core/roleaccesses/role/${roleId}/module/${objectId}`);
        } catch (err) {
            showError(err);
        }
    }

    // DELETE calls
    async function removeRole(roleId) {
        try {
            return await $http.delete(`${apiUrl}api/core/Role/${roleId}`);
        } catch (err) {
            showError(err);
        }
    }

    async function removeRoleAccess(data) {
        try {
            return await $http.delete(`${apiUrl}api/core/roleaccesses`, { data: data, headers: { 'Content-Type': 'application/json;charset=utf-8' } });
        } catch (err) {
            showError(err);
        }
    }

    // POST calls
    async function addRoleAccess(data) {
        try {
            return await $http.post(`${apiUrl}api/core/roleaccesses`, data);
        } catch (err) {
            showError(err);
        }
    }

    async function addRole(data) {
        try {
            return await $http.post(`${apiUrl}api/core/Role`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }
}