export default /*@ngInject*/ function ($q, RequestManagementPSService, $filter) {

    this.loadPS = function ($scope) {
        var psDfrd = $.Deferred();

        loadPSPermissions($scope).done(function () {
            psDfrd.resolve();
        });

        return $.when(psDfrd).done(function () {
        }).promise();
    };

    this.loadPSLoadPermisssions = function ($scope) {
        return loadPSPermissions($scope);
    };
    //Load the PS permissions, after that completes and if there is an existing PS user load their permissions
    function loadPSPermissions($scope) {
        let permissionsDfrd = $.Deferred(),
            promises = [],
            pendingRoles = $scope.vm.User?.PSUser?.PendingRoles || [];

        $scope.vm.ListOfSelectedPSRoles = [];
        $scope.vm.ListOfAvailablePSRoles = [];

        //Load Available psRoles then process existing security
        promises.push(RequestManagementPSService.getAllPSRoles().then(function (response) {
            $scope.vm.ListOfAvailablePSRoles = response.data.filter(applyPending.bind(null, pendingRoles));
        }, function errorCallback(response) {
            permissionsDfrd.reject();
        }));

        $q.all(promises).then(function () {
            if ($scope.defaultRoleTemplate && $scope.vm.wizardMode === "New" && !$scope.vm.requestToEdit) {
                let defaultRoles = $scope.defaultRoleTemplate.Roles.filter(role => role.TypeId === "Role" && role.ProductType === "PS");

                $scope.vm.User.PSUser.Roles = [...defaultRoles];
            }
            //Load PS user roles
            if ($scope.vm.User.PSUser.Roles != null) {
                $scope.vm.User.PSUser.Roles.forEach(Role => {

                    var psRole = null;

                    //Get the role information from the available psRoles list
                    psRole = $scope.vm.ListOfAvailablePSRoles.find(object => object.Name === Role.Name);

                    if (psRole != null) {
                        $scope.vm.ListOfSelectedPSRoles.unshift({
                            Name: psRole.Name,
                            IsAssignable: psRole.IsAssignable,
                            Pending: pendingRoles.find(pendingRole => pendingRole.Name === psRole.Name)
                        });

                        $scope.vm.ListOfAvailablePSRoles.forEach((row, j) => {
                            if (row.Name === psRole.Name) {
                                $scope.vm.ListOfAvailablePSRoles.splice(j, 1);
                            }
                        });
                    }

                    psRole = null;

                    $scope.vm.numberOfPSRolesValid = true;
                });
            }
            $scope.vm.ListOfAvailablePSRoles = $scope.vm.ListOfAvailablePSRoles.filter(role => role.IsAssignable);

            $scope.psUserLoaded = true;
            permissionsDfrd.resolve();
        });

        return $.when(permissionsDfrd).done(function () {
        }).promise();
    }
}

function applyPending(pendingItems, item) {
    if (pendingItems.length) {
        item.Pending = pendingItems.some(pendingItem => pendingItem.Name === item.Name);
    }

    return item;
}