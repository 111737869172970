import controller from "./applyDefaultMitigations.controller";

/* applyDefaultMitigations.component.js */

/**
 * @desc component for applying default mitigations
 * @example <apply-default-mitigations></apply-default-mitigations>
 */

const applyDefaultMitigations = {
    controller: controller,
    controllerAs: 'admvm',
    templateUrl: '/App/Components/applyDefaultMitigations/applyDefaultMitigations.tmpl.html'
};

export default applyDefaultMitigations;