import rolesController from "./roles.controller";

/* roles.component.js */

/**
 * @desc component for viewing and maintaining system roles
 * @example <roles></roles>
 */

const roles = {
    controller: rolesController,
    controllerAs: 'rvm',
    templateUrl: '/App/Components/Roles/roles.html'
};

export default roles;