import authObjectFieldsListController from "./authObjectFieldsList.controller";

/* authObjectFieldsList.component.js */

/**
 * @desc component for viewing and maintaining list of sap security designer authorization object fields
 * @example <sap-sd-simulation-role-auth-object-fields-list></sap-sd-simulation-role-auth-object-fields-list>
 */

const sapSdSimulationRoleAuthObjectFieldsList = {
    require: {
        simulationsCtrl: '^sapSdSimulations'
    },
    bindings: {
        simulation: '<',
        selectedRole: '<',
        authObject: '<',
        onSelectedFieldChange: '&'
    },
    controller: authObjectFieldsListController,
    controllerAs: 'aoflvm',
    templateUrl: '/App/Components/SAPSecurityDesigner/simRoleAuthObjFieldsList/authObjectFieldsList.tmpl.html'
};

export default sapSdSimulationRoleAuthObjectFieldsList;