import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, existingSchedules, ownershipGroupTypeName, reviewInstance, reviewAssignmentsDataService, ownershipGroupsDataService) {

    const vm = this;
    vm.helperService = helperService;
    vm.existingSchedules = existingSchedules;
    vm.reviewInstance = reviewInstance;
    vm.ownershipGroupTypeName = ownershipGroupTypeName;

    vm.cancel = cancel;
    vm.addAssignment = addAssignment;

    activate();

    async function activate() {
        vm.ownershipGroupDropdownLoading = true;
        vm.ownershipGroupsDataSource = await getOwnershipGroups();
        vm.ownershipGroupDropdownLoading = false;
    }

    function cancel() { $uibModalInstance.dismiss(); }

    async function getOwnershipGroups() {
        let { data } = await ownershipGroupsDataService.getOwnershipGroups();
        data = removeExistingObjects(data);
        data = data.filter(group => group.TypeName === ownershipGroupTypeName);
        return data;
    }

    async function addAssignment() {
        vm.pleaseWait = new createSpinner();

        let assignment = {
            "ReviewInstanceId": reviewInstance.Id,
            "OwnershipGroupIds": [vm.selectedItem.Id],
        };

        try {
            await reviewAssignmentsDataService.addReviewAssignment(assignment);
            await helperService.successfulSaveButton(vm.pleaseWait);
            $uibModalInstance.close();
        } catch {
            vm.pleaseWait.loadingValue = false;
            return;
        }
    }

    function removeExistingObjects(array) {
        return array.filter(ownershipGroup => !existingSchedules?.some(schedule => schedule.OwnershipGroupId === ownershipGroup.Id));
    }
}