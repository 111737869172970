import createSpinner from "../../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($rootScope, $uibModalInstance, helperService, reviewSchedulesDataService, scheduleData) {

    const vm = this;
    vm.ReviewInstance = scheduleData.selectedReviewInstance;
    vm.scheduleType = scheduleData.scheduleType;

    vm.daysInMonth = helperService.getDayOfMonthDropdownOptions();
    vm.months = helperService.getMonthsDropdownOptions();
    vm.frequencyTypesDataSource = [{ Name: 'Full', Id: 'Full' }, { Name: 'Rolling', Id: 'Rolling' }];
    vm.saveSchedule = saveSchedule;
    vm.cancel = cancel;

    vm.frequencyDataSource = [
        { Name: 'Monthly', Id: 'Monthly' },
        { Name: 'Quarterly', Id: 'Quarterly' },
        { Name: 'Semi Annually', Id: 'SemiAnnually' },
        { Name: 'Yearly', Id: 'Yearly' }];

    activate();

    function activate() {
        setUpForm();
    }


    function setUpForm() {
        //Load data for existing schedule
        if (scheduleData.selectedSchedule) {
            vm.ReviewInstanceId = scheduleData.selectedReviewInstance.Id
            vm.Frequency = vm.frequencyDataSource.find(function (object) { return object.Name === scheduleData.selectedSchedule.Frequency.replace(/([a-z])([A-Z])/g, '$1 $2'); });
            vm.FrequencyType = vm.frequencyTypesDataSource.find(function (object) { return object.Id === scheduleData.selectedSchedule.FrequencyType; });
            vm.Month = scheduleData.selectedSchedule.Month;
            vm.DayOfMonth = scheduleData.selectedSchedule.DayOfMonth;
        } else {
            //Load Data for new schedule
            vm.ReviewInstanceId = scheduleData.selectedReviewInstance.Id
            vm.FrequencyType = vm.frequencyTypesDataSource.find(function (object) { return object.Id === scheduleData.selectedFrequencyType; });
        }
    }

    function saveSchedule() {
        vm.pleaseWait = new createSpinner();
        if (scheduleData.selectedSchedule) {
            updateSchedule();
        } else {
            createSchedule();
        }
    }

    async function createSchedule() {
        const postData = {
            "ReviewInstanceId": vm.ReviewInstanceId,
            "FrequencyType": vm.FrequencyType.Id,
            "Frequency": vm.Frequency.Id,
            "TimeZone": $rootScope.applicationTimeZone,
            "DayOfMonth": vm.DayOfMonth,
            "Month": vm.Month
        };

        try {
            await reviewSchedulesDataService.createSchedule(postData);
            await helperService.successfulSaveButton(vm.pleaseWait);
            $uibModalInstance.close();
        } catch {
            vm.pleaseWait.loadingValue = false;
            vm.disableSubmit = false;
        }
    };

    async function updateSchedule() {
        const putData = {
            "Id": scheduleData.selectedSchedule.Id,
            "FrequencyType": vm.FrequencyType.Id,
            "Frequency": vm.Frequency.Id,
            "TimeZone": $rootScope.applicationTimeZone,
            "DayOfMonth": vm.DayOfMonth,
            "Month": vm.Month
        };

        try {
            await reviewSchedulesDataService.updateSchedule(scheduleData.selectedSchedule.Id, putData);
            await helperService.successfulSaveButton(vm.pleaseWait);
            $uibModalInstance.close();
        } catch {
            vm.pleaseWait.loadingValue = false;
            vm.disableSubmit = false;
        }
    }

    function cancel() {
        $uibModalInstance.dismiss();
    };
}
