import angular from 'angular';
import certificationsAllReviewDataServiceFactory from '../Components/Certifications/AllReviews/certificationsAllReviewDataService.factory';
import accessCertificationsService from '../Shared/Services/accessCertifications.service';
import notificationTeamsServiceFactory from '../Components/Certifications/notificationTeams/notificationTeamsService.factory';
import certificationsReviewAssignmentsDataServiceFactory from '../Components/Certifications/reviewSchedules/certificationsReviewAssignmentsDataService.factory';
import roleSecurityProfileDataServiceFactory from '../Shared/Components/roleSecurityProfile/roleSecurityProfileDataService.factory';
import myReviewsDataServiceFactory from '../Components/Certifications/MyReviews/myReviewsDataService.factory';
import reviewSchedulesDataServiceFactory from '../Components/Certifications/reviewSchedules/reviewSchedulesDataService.factory';


export default angular
    .module('app.accessCertifications.factories', [])
    .factory('certificationsAllReviewDataService', certificationsAllReviewDataServiceFactory)
    .factory('accessCertificationsService', accessCertificationsService)
    .factory('notificationTeamsDataService', notificationTeamsServiceFactory)
    .factory('reviewAssignmentsDataService', certificationsReviewAssignmentsDataServiceFactory)
    .factory('roleSecurityProfileDataService', roleSecurityProfileDataServiceFactory)
    .factory('myReviewsDataService', myReviewsDataServiceFactory)
    .factory('reviewSchedulesDataService', reviewSchedulesDataServiceFactory)
    .name;