import editProductNameModalController from "../editProductNameModal.controller";

export default /*@ngInject*/ function ($uibModal, ViewProductImportPageService) {
    var vm = this;

    vm.ViewProductImportPageService = ViewProductImportPageService;
    vm.getImportStatusClass = getImportStatusClass;
    vm.openEditProductName = openEditProductName;

    function getImportStatusClass(status) {
        const classes = {
            "Completed": "toast-success",
            "InProgress": "toast-loading",
            "Error": "toast-error"
        }

        return classes[status];
    }

    function openEditProductName() {
        $uibModal.open({
            templateUrl: '/App/Shared/Partials/EditProductNameModal.html',
            controller: editProductNameModalController,
            controllerAs: 'epnmcvm',
            backdrop: 'static',
            resolve: {
                product: () => vm.product
            }
        });
    };
}
