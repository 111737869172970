import startNewAuditController from "./startNewAudit.controller";

/* startNewAudit.component.js */

/**
 * @desc component for start new audit
 * @example <start-new-audit></start-new-audit>
 */

const startNewAudit = {
    controller: startNewAuditController,
    controllerAs: 'snavm',
    templateUrl: '/App/Components/startNewAudit/startNewAudit.tmpl.html'
};

export default startNewAudit;