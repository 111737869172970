import autoMappingAssignmentModalController from "./autoMappingAssignmentModal.controller";

export default /*@ngInject*/ function ($uibModal, dataService, supervisorAssignmentsDataService) {

    const vm = this;

    vm.openAutoMappingModal = openAutoMappingModal;
    vm.deleteMapping = deleteMapping;
    vm.generateAssignments = generateAssignments;

    vm.$onInit = activate;

    async function activate() {
        await getCurrentMappings();
    }

    vm.mappingMenuOptions = [
        {
            text: 'Edit',
            click: function ($itemScope, $event, modelValue) {
                var item = modelValue;
                let mappingType;
                if (item.ProductId === null) {
                    mappingType = 'Fastpath Supervisor';
                } else {
                    mappingType = 'System User';
                }
                vm.openAutoMappingModal(item, mappingType);
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: function ($itemScope, $event, modelValue) {
                var item = modelValue;
                vm.deleteMapping(item);
            }
        }
    ];

    async function getCurrentMappings() {
        let response = await supervisorAssignmentsDataService.getSupervisorAssignmentMappings();
        vm.supervisorMappings = response.data.filter(mapping => mapping.ProductId === null);
        vm.userMappings = response.data.filter(mapping => mapping.ProductId !== null);
        if (!vm.allProducts) {
            await getProducts();
        }
        vm.availableProducts = vm.allProducts.filter(product => !vm.userMappings.some(mapping => mapping.ProductId === product.ID));
    }

    async function getProducts() {
        let response = await dataService.getProducts();
        vm.allProducts = response.data;
    }

    async function openAutoMappingModal(mapping, mappingType) {
        const autoMappingModal = $uibModal.open({
            templateUrl: '/App/Components/SupervisorAssignments/AutoMappingAssignments/autoMappingAssignmentModal.html',
            controller: autoMappingAssignmentModalController,
            controllerAs: 'amamvm',
            backdrop: 'static',
            resolve: {
                mapping: () => mapping || null,
                availableProducts: () => vm.availableProducts || null,
                mappingType: () => mappingType || null,
                allProducts: () => vm.allProducts || null
            }
        });

        try {
            await autoMappingModal.result;
            getCurrentMappings();
        } catch {
            return;
        }

    }

    async function deleteMapping(mapping) {
        await supervisorAssignmentsDataService.deleteAutoSupervisorAssignmentMapping(mapping.Id);
        getCurrentMappings();
    }

    async function generateAssignments() {
        vm.generatingAssignments = true; 
        try {
            await supervisorAssignmentsDataService.autoGenerateMappingsSupervisorAssignments();
            vm.generatingAssignments = false;  
        } catch {
            vm.generatingAssignments = false;
            return;
        }
    }
}