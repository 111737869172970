export default /*@ngInject*/ function ($scope, $rootScope, $filter, $uibModal, RequestManagementGPService, errorsDataService, existingUserCheckService) {

    var vm = $scope.vm;
    vm.GPUserExists = null;

    if (!$scope.vm.User.GPUser) {
        $scope.vm.User.GPUser = {};
    }
    $scope.vm.User.GPUser.ProductType = 'GP';

    function getLoginTypes() {
        $scope.vm.GPLoginTypesDataSource = RequestManagementGPService.getGPLoginTypes();
    }
    function getUserTypes() {

        $scope.vm.GPUserTypesDataSource = RequestManagementGPService.getGPUserTypes();

        //If version is less than GP 2015, remove Self Service user type
        if ($rootScope.ConfigurationValues.GP_Version < 5) {
            $scope.vm.GPUserTypesDataSource = $filter('filter')($scope.vm.GPUserTypesDataSource, { Id: '!' + 3 });
        }
    }
    function getStatuses() {
        $scope.vm.GPStatusesDataSource = RequestManagementGPService.getGPStatuses();
    }

    getLoginTypes();
    getUserTypes();
    getStatuses();

    //General User
    vm.gpUserFromDateObject = null;
    vm.gpUserToDateObject = null;
    vm.gpUserMnsDate = new Date();
    var gpUserMinDate = new Date();
    gpUserMinDate.setHours(0, 0, 0, 0);

    vm.gpUserMinDate = gpUserMinDate;
    vm.gpUserFromDateChanged = function () {
        var date;
        if ($scope.vm.User.GPUser.StartDate === '') {
            date = new Date();
        } else {
            date = new Date($scope.vm.User.GPUser.StartDate);
        }

        $scope.vm.gpUserMinDate = date;
        $scope.vm.gpMinDate = date;
        $scope.vm.fromMinDate = date;
        if ($scope.vm.User.GPUser == null) {
            $scope.vm.User.GPUser = {};
        }
    };
    vm.gpUserToDateChanged = function () {
        $scope.vm.gpUserMnsDate = new Date($scope.vm.User.GPUser.EndDate);
        $scope.vm.gpMnsDate = new Date($scope.vm.User.GPUser.EndDate);
        $scope.vm.fromMnsDate = new Date($scope.vm.User.GPUser.StartDate);
        if ($scope.vm.User.GPUser == null) {
            $scope.vm.User.GPUser = {};
        }
    };

    vm.clearWindowsUser = function () {
        if (vm.User != null && vm.User.GPUser != null) {
            vm.User.GPUser.WindowsUserId = null;
            vm.User.GPUser.NetBiosName = null;
            vm.User.GPUser.ADUserId = null;

            vm.User.GPUser.Id = null;
            vm.User.GPUser.UserName = null;

            vm.GPUserExists = null;
        }
    };

    $scope.existingUserCheck = function (userId) {
        existingUserCheckService.checkGP(userId)
            .then(response => {
                $scope.vm.GPUserExists = response.data;
                if (response.data === true) {
                    $scope.wizardForm.GPuserId.$setValidity("GPuserId", false);
                } else {
                    $scope.wizardForm.GPuserId.$setValidity("GPuserId", true);
                }
            }).catch(error => {
                errorsDataService.catch(error);
            });
    };

    $scope.$watch("vm.User.GPUser.Id", () => {
        if (vm.User.GPUser.Id && vm.wizardMode === 'New') {
            $scope.existingUserCheck(vm.User.GPUser.Id);
        }
    });

}
