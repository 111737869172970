import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($http, $uibModalInstance, helperService, selectedAccessItemsForAction, request, errorsDataService) {

    const vm = this;

    vm.helperService = helperService;
    vm.cancel = cancel;

    vm.accessItems = selectedAccessItemsForAction;
    vm.requestType = request.Action;
    vm.requestId = request.Id;

    vm.fromDateChanged = fromDateChanged;
    vm.toDateChanged = toDateChanged;
    vm.saveEffectiveDates = saveEffectiveDates;

    activate();

    function activate() {
        // Set up Min Dates for each item
        vm.accessItems.forEach(access => {
            access.minStartDate = new Date();
            access.minEndDate = new Date();
            access.maxEndDate = new Date(2900, 0, 1, 0, 0, 0);

            if (access.StartDate) {
                access.StartDate = new Date(access.StartDate);
            }

            if (access.EndDate) {
                access.EndDate = new Date(access.EndDate);
            }

            if (access.originalStartDate > new Date()) {
                access.minEndDate = access.StartDate;
                access.readOnly = false;
            } else {
                access.minEndDate = new Date();
                access.minStartDate = new Date(null);
                access.readOnly = true;
            }

            access.invalidEmergencyAccessEndDate = false;

            if (vm.requestType === 'EmergencyAccess') {
                access.maxEndDate = new Date();
                access.maxEndDate.setTime(vm.maxEndDate.getTime() + (vm.emergencyAccessPeriod * 60 * 60 * 1000));
            }

        });

        vm.changeMade = false;
        vm.emergencyAccessPeriod = Number(JSON.parse(localStorage.getItem("ConfigurationValues")).MaxFirefighterAccessPeriod);
    }


    function cancel() {
        $uibModalInstance.dismiss();
    }

    function fromDateChanged(access) {
        vm.changeMade = true;
        if (!access.StartDate) {
            access.minEndDate = new Date();
        } else {
            access.minEndDate = access.StartDate;
        }
        if (vm.requestType === 'EmergencyAccess') {
            access.maxEndDate = new Date(access.StartDate);
            access.maxEndDate.setTime(access.maxEndDate.getTime() + (vm.emergencyAccessPeriod * 60 * 60 * 1000));
        }
        vm.rebindEffectiveDates = vm.rebindEffectiveDates === 0 ? 1 : 0;
    }

    function toDateChanged(access) {
        vm.changeMade = true;

        if (!isDate(access.EndDate)) {
            access.EndDate = null;
        }

        if (vm.requestType === 'EmergencyAccess') {
            if (access.EndDate > access.maxEndDate) {
                access.invalidEmergencyAccessEndDate = true;
            } else {
                access.invalidEmergencyAccessEndDate = false;
            }
        }

        vm.rebindEffectiveDates = vm.rebindEffectiveDates === 0 ? 1 : 0;
    }

    function isDate(x) {
        return x instanceof Date;
    }

    async function saveEffectiveDates() {
        vm.saving = new createSpinner();
        let effectiveDates = [];
        vm.accessItems.forEach(access => {
            effectiveDates.push({
                "RequestAccessId": access.Id,
                "StartDate": access.StartDate,
                "EndDate": access.EndDate
            });
        });

        let putData = {
            "RequestId": request.Id,
            "Version": request.Version,
            "RequestEffectiveDates": effectiveDates
        };

        try {
            await $http.put(apiUrl + 'api/identitymanager/requests/' + request.Id + '/version/' + request.Version + '/effectivedates', putData);
            await helperService.successfulSaveButton(vm.saving);
            $uibModalInstance.close();
        } catch (err) {
            vm.saving.loadingValue = false;
            showError(err);
            sendError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

}