export default function () {

    this.stepDisabled = function (title, wizardInstance, $scope) {

        var enabledSteps = wizardInstance.getEnabledSteps(),
            positionInArray = null,
            steps = $scope.steps;

        enabledSteps.forEach(function (row, i) {
            if (title === row.title) {
                positionInArray = i;
            }
        });

        if ($scope.vm?.salesforceLicensePending && (title === "Salesforce Profiles" || title === "Salesforce Permission Sets")) {
            return true;
        }

        if (positionInArray === 0) {
            return false;
        }

        var firstVisibleNotValidStep = null;

        for (var i = 0; i < enabledSteps.length; i++) {
            if (enabledSteps[i].canexit === false && steps[i].visible === true) {
                firstVisibleNotValidStep = i;
                break;
            } if (steps[i].visible === true) {
                firstVisibleNotValidStep = positionInArray + 1;
            }
        }

        if (positionInArray > firstVisibleNotValidStep) {
            return true;
        } else {
            return false;
        }
    };

    this.setParentStepToActive = function (parent, wizardInstance, steps) {
        var stepIndex = wizardInstance.currentStepNumber() - 1;

        if (stepIndex != -1) {
            if (steps[stepIndex].parent === parent) {
                return true;
            } else { return false; }
        } else { return false; }
    };

    this.atLeastOneAppSelected = function (availableApplications, wizardMode) {
        var validAppsSelected = null;
        for (var i = 0; i < availableApplications.length; i++) {

            if (wizardMode != null && wizardMode == 'Terminate' && availableApplications[i].selected == true) {
                validAppsSelected = false;
            } else if (availableApplications[i].selected == true) {
                validAppsSelected = true;
            }
        }
        return validAppsSelected;
    };

    this.goToNextVisiblePage = function (steps, wizardInstance) {
        for (var i = wizardInstance.currentStepNumber(); i < steps.length; i++) {
            var step = steps[i];
            if (step.visible == true) {
                return step;
            }
        }
    };

    this.goToPreviousVisiblePage = function (steps, wizardInstance) {
        for (var i = wizardInstance.currentStepNumber() - 2; i > -1; i--) {
            var step = steps[i];
            if (step.visible == true) {
                return step;
            }
        }
    };

    this.getCurrentStepsTitle = function (wizardInstance) {
        if (wizardInstance.currentStep()) {
            return wizardInstance.currentStep().title;
        }
    };

}