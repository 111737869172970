import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, crossPlatformUsersDataService, helperService) {

    const vm = this;

    vm.importUserMapping = importUserMapping;
    vm.cancel = cancel;

    async function importUserMapping(files) {
        vm.disableButton = true;
        let fd = new FormData();
        files.forEach(function (file, i) {
            fd.append(files[i].file.name, files[i].file);
        });

        if (files.length > 0) {
            vm.disableButton = false;
            vm.importUpdatePending = new createSpinner();
            try {
                await crossPlatformUsersDataService.importUserMapping(fd);
                await helperService.successfulSaveButton(vm.importUpdatePending);
                cancel();
            } catch {
                vm.importUpdatePending.loadingValue = false;
                vm.disableButton = true;
            }
            cancel();
        }
    }

    function cancel() {
        $uibModalInstance.close();
    }
}