import swal from "sweetalert2";
import simRiskAnalysisReportDetailModalController from "./simRiskAnalysisReport/simRiskAnalysisReportDetailModal.controller";

export default /*@ngInject*/ function ($scope, $uibModal, sapSdSimAnalysisDataService, helperService) {
    const vm = this;

    vm.analyzeSimulation = analyzeSimulation;
    vm.openDetailedReportModal = openDetailedReportModal;

    vm.showReport = false;

    vm.$onInit = activate;

    $scope.$on('RiskAnalysis', () => getAnalysisStatistics());

    function activate() {
        getAnalysisStatistics();
        vm.headerTitle = `SAP Security Designer Simulation Risk Analysis`;
        vm.headerDescription = `Risk Analysis for Simulation ${vm.simulation.Name}`;
        if (vm.simulation.Status === 'NeedsAnalysis') vm.showNeedsReAnalyzeMessage = true;
    }

    async function getAnalysisStatistics() {
        vm.statisticsLoading = true;
        try {
            let response = await sapSdSimAnalysisDataService.getSimulationAnalysisStats(vm.simulation.Id);
            vm.selectedSimulationStatistics = response.data;
            vm.statisticsNotFound = false;
            vm.statisticsLoading = false;
        } catch (error) {
            if (error.status === 404) {
                vm.statisticsNotFound = true;
                vm.statisticsLoading = false;
            } else {
                return;
            }
        }
    }

    async function analyzeSimulation() {
        let data = {
            SimulationId: vm.simulation.Id
        }
        try {
            await swal(helperService.areYouSureParams(`Run Analysis`, `Are you sure you want to run the Simulation Analysis for Simulation ${vm.simulation.Name}?`, `Yes`, 'No'))
            await sapSdSimAnalysisDataService.analyzeSimulation(data);
            showNeedsReAnalyzeMessage = false;
        } catch {
            return;
        }
    }

    async function openDetailedReportModal(detailedReportType, roleType) {
        let detailedReportModal = $uibModal.open({
            templateUrl: '/App/Components/SAPSecurityDesigner/simulationRiskAnalysis/simRiskAnalysisReport/simRiskAnalysisReportDetailModal.tmpl.html',
            controller: simRiskAnalysisReportDetailModalController,
            controllerAs: 'drmvm',
            backdrop: 'static',
            size: 'xl',
            resolve: {
                simulationId: () => vm.simulation.Id,
                detailedReportType: () => detailedReportType,
                roleType: () => roleType
            }
        });

        // prevent unhandled rejection
        try {
            await detailedReportModal.result
        } catch {
            return;
        }
    }
}