import { customAutoCompleteFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import swal from "sweetalert2";

export default /*@ngInject*/ function ($scope, $rootScope, $filter, $http, helperService) {

    var vm = $scope.vm;
    $scope.vm.wizardClass = "wizard-nav-container";

    //Search Existing Users
    var getExistingUserGridColumns = function () {
        return [
            { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
            { field: "PrimarySystemUserId", title: "User ID", filterable: customAutoCompleteFilter },
            { field: "Email", title: "Email", filterable: customAutoCompleteFilter },
            { field: "PendingRequest", title: "Pending Request", filterable: customAutoCompleteFilter },
            { field: "Products", title: "Products", filterable: customAutoCompleteFilter }
        ];
    };

    $scope.doneSearchingUsers = function (vm) {
        $rootScope.IMSelectedExistingUser = null;

        if (vm.ExistingUserSearchString === '' || !vm.ExistingUserSearchString) {

            var raw = vm.ExistingusersGridDataSource.data();
            var length = raw.length;

            var item, i;
            for (i = length - 1; i >= 0; i--) {

                item = raw[i];
                vm.ExistingusersGridDataSource.remove(item);

            }
        } else {
            if (vm.wizardMode === 'Terminate') {
                vm.ExistingusersGridDataSource.read();
            } else {
                vm.ExistingusersGridDataSource.read();
            }
        }
    };

    vm.ExistingUsersGridOptions = helperService.setMainGridOptions(getExistingUserGridColumns(), 350);
    vm.ExistingUsersGridOptions.autoBind = false;

    vm.ExistingusersGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: function (options) {
                $http({
                    method: 'GET',
                    url: apiUrl + 'api/identitymanager/users',
                    params: {
                        searchString: vm.ExistingUserSearchString
                    }

                }).then(function (response) {

                    response.data.forEach(function (row, i) {
                        if (response.data[i].PendingRequest !== null) {
                            response.data[i].PendingRequest = response.data[i].PendingRequest.toString();
                        }
                    });

                    response.data = $filter('orderBy')(response.data, 'Name');
                    options.success(response.data);
                    }, function errorCallback(response) {
                        options.error();
                        helperService.showErrorMessage(response.data);
                });
            }
        }
    });

    vm.searchKeyDown = function () {
        vm.SearchExistingUserSelected = null;
        $scope.vm.wizardClass = "wizard-nav-container disabledDiv";
    };

    vm.changeExistingUserRowSelected = function (kendoEvent) {
        var grid = kendoEvent.sender,
            isTerminate = vm.wizardMode === "Terminate",
            canModify = false,
            canTerminate = false,
            selectedUser;

        $rootScope.IMSelectedExistingUser = grid.dataItem(grid.select());
        $rootScope.IMSelectedExistingUser.HasERPDataLoaded = false;

        $scope.vm.wizardClass = "wizard-nav-container";
        selectedUser = grid.dataItem(grid.select());

        canModify = !selectedUser?.PendingTerminateRequest && !isTerminate;
        // selectedUser.PendingRequest gets converted to a string for display in grid
        canTerminate = selectedUser?.PendingRequest === "false" && isTerminate;

        if (canModify || canTerminate) {
            if (vm.User.SalesforceUser) {
                // deselect SF app when switching between users - logic in Profiles step needs to be run again
                const salesforceApp = vm.availableApplications.find(app => app.ProductType === 'SF');
                salesforceApp.selected = false;
                vm.appSelectedChange(salesforceApp);
            }
            vm.SearchExistingUserSelected = true;
            $scope.vm.User = {};
            $scope.vm.validAppsSelected = null;
        } else if (!canModify || !canTerminate) {
            alertUser(isTerminate);
        }
        else {
            vm.SearchExistingUserSelected = null;
        }
    };

    function alertUser(isTerminate) {
        let message;

        if (isTerminate) {
            message = "Users with pending requests may not be terminated"
        } else {
            message = "Users with pending termination requests may not be modified"
        }

        swal("Alert", message, "warning");
    }

    function stopGridLoading(data) {
        //Reset grid after search completes
        var unlockGrid = true;
        if (data) {
            if (data === "Error") {
                unlockGrid = false;
            }
        }
        var usersGrid = $("#ExistingusersGrid");
        kendo.ui.progress(usersGrid, false);
        if (unlockGrid) {
            $scope.vm.wizardClass = "wizard-nav-container";
        }
    }
}