import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (control, $rootScope, $uibModalInstance, controlManagementDataService, helperService) {

    const vm = this;

    vm.control = control;
    vm.cancel = cancel;
    vm.saveEditModal = saveEditModal;
    vm.conditions = [];

    async function activate() {
        vm.conditions = vm.control.Conditions;
        vm.frequencies = ['Weekly', 'Monthly', 'Quarterly', 'Yearly'];
        vm.daysInWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        vm.daysInMonth = helperService.getDayOfMonthDropdownOptions();
        vm.months = helperService.getMonthsDropdownOptions();
        vm.startTimeForControlSchedule = new Date();
        setupControlSchedule();
    }

    activate();

    function cancel() {
        $uibModalInstance.close();
    }

    async function saveEditModal() {
        vm.saving = new createSpinner();

        try {
            await updateControls();
            if (vm.conditions.length > 0) {
                await updateConditions();
            }

            if (vm.controlScheduleIsOn == false && vm.control.Schedule) {
                await setUpdateControlSchedule(false);
            } else {
                await setUpdateControlSchedule(true);
            }

            await helperService.successfulSaveButton(vm.saving);
            cancel();
        } catch {
            vm.saving.loadingValue = false;
        }
    }

    async function updateControls() {
        let putData = {
            "Id": vm.control.Id,
            "Name": vm.control.Name,
            "Description": vm.control.Description,
            "Enabled": vm.control.Enabled,
            "ReferenceNumber": vm.control.ReferenceNumber,
            "Notes": vm.control.Notes,
            "AutoClose": vm.control.AutoClose
        }

        try {
            await controlManagementDataService.updateControl(vm.control.Id, putData);
        } catch {
            return
        }
    }

    function updateConditions() {

        vm.conditions.forEach(async condition => {
            let putData = {
                "ConditionId": condition.Id,
                "Value": !condition.Value ? null : condition.Value
            };

            await controlManagementDataService.updateControlConditions(vm.control.Id, condition.Id, putData);
        });
    }

    // Schedule Control Code
    function setupControlSchedule() {
        if (vm.control.Schedule && vm.control.Schedule.NextExecutionTime) {
            vm.selectedFrequency = vm.control.Schedule.Frequency;
            vm.selectedDayOfWeek = vm.control.Schedule.DayOfWeek;
            vm.selectedDayOfMonth = vm.control.Schedule.DayOfMonth ? { Value: vm.control.Schedule.DayOfMonth } : null;
            vm.selectedMonth = vm.control.Schedule.Month
            vm.startTime = new Date(vm.control.Schedule.StartTime);
            vm.controlScheduleIsOn = true;
        } else {
            vm.controlScheduleIsOn = false;
        }
    }

    function setUpdateControlSchedule(isOn) {
        updateControlSchedule(isOn, vm.selectedFrequency, vm.selectedDayOfWeek, vm.selectedDayOfMonth, vm.selectedMonth, vm.startTime);
    }

    async function updateControlSchedule(scheduleIsOn, frequency, dayOfWeek, dayOfMonth, month, startTime) {
        vm.updateInProgress = new createSpinner();

        if (scheduleIsOn) {

            let dayOfMonthRequest;
            let dayOfWeekRequest;
            let monthRequest;


            if (frequency === 'Weekly') {
                dayOfMonthRequest = [];
                monthRequest = [];
                dayOfWeekRequest = [dayOfWeek];
            } if (frequency === 'Monthly') {
                dayOfMonthRequest = [dayOfMonth.Value];
                dayOfWeekRequest = [];
                monthRequest = [];
            } if (frequency === 'Quarterly') {
                dayOfMonthRequest = [dayOfMonth.Value];
                monthRequest = [month];
                dayOfWeekRequest = [];
            } if (frequency === 'Yearly') {
                dayOfMonthRequest = [dayOfMonth.Value];
                monthRequest = [month];
                dayOfWeekRequest = [];
            }

            let adjustedStartTime = new Date();
            adjustedStartTime.setHours(startTime.getHours());
            adjustedStartTime.setMinutes(startTime.getMinutes());
            adjustedStartTime = new Date(adjustedStartTime.toUTCString());
            adjustedStartTime = adjustedStartTime.toISOString();

            const putData = {
                "ControlId": control.Id,
                "Schedule": {
                    "DayOfMonth": dayOfMonthRequest[0],
                    "DayOfWeek": dayOfWeekRequest[0],
                    "Month": monthRequest[0],
                    "Frequency": frequency,
                    "StartTime": adjustedStartTime,
                    "TimeZone": $rootScope.applicationTimeZone
                }
            };

            try {
                await controlManagementDataService.scheduleControl(vm.control.Id, putData);
                await setupControlSchedule();
                await helperService.successfulSaveButton(vm.updateInProgress);
            } catch {
                vm.updateInProgress.loadingValue = false;
            }
        }

        if (!scheduleIsOn && control.Schedule) {
            let disablePutData = {
                ControlId: vm.control.Id
            }

            try {
                await controlManagementDataService.disableControlSchedule(control.Id, disablePutData);
            } catch {
                vm.updateInProgress.loadingValue = false;
            }
        }
    }

}