import swal from "sweetalert2";
import createSpinner from "../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($scope, $uibModalInstance, $filter, $timeout, helperService, $http, statusDataService, controlLibraryDataService, FileSaver) {

    var vm = $scope.vm = {};
    const riskToMitigate = $scope.riskToMitigate;

    $scope.originalMitigation = riskToMitigate.MitigationID;
    $scope.originalStatus = riskToMitigate.StatusID;
    $scope.originalMitigationNotes = riskToMitigate.MitigationNotes;
    $scope.originalExpirationDate = riskToMitigate.ExpirationDate;
    $scope.deleteRiskMitigationRecord = deleteRiskMitigationRecord;
    $scope.okMitigateRisk = okMitigateRisk;
    $scope.deselectSelectedCompany = deselectSelectedCompany;
    $scope.saveAttachments = saveAttachments;
    $scope.removeFile = removeFile;
    $scope.downloadFile = downloadFile;
    $scope.minimumExpiryDate = new Date();

    $http.get(apiUrl + 'api/core/configuration/ARM_StandardMitigations').then(function (response) {
        $scope.standardMitigations = response.data;
    });

    $scope.controlsLoading = true;
    $http.get(apiUrl + 'api/arm/controls').then(function (response) {
        $scope.controlLibrary = response.data;
        if (riskToMitigate.Status != 'New') {
            $http.get(apiUrl + 'api/arm/usermitigations/risk/' + riskToMitigate.RiskId + '/user/' + riskToMitigate.UserMasterID + '/company/' + riskToMitigate.CompanyMasterID).then(function (response) {
                $scope.selectedControls = new Array();
                $scope.originalControls = new Array();
                if (response.data.Controls != null && response.data.Controls != undefined && response.data.Controls.length > 0) {
                    for (var i = 0; i < response.data.Controls.length; ++i) {
                        var matchingControl = $scope.controlLibrary.find(x => x.Identifier === response.data.Controls[i].ControlId);
                        if (matchingControl != null && matchingControl != undefined) {
                            $scope.selectedControls.push(matchingControl);
                            $scope.originalControls.push(matchingControl);
                        }
                    }
                }
                if (response.data.Attachments.length > 0) {
                    $scope.riskToMitigate.attachments = [...response.data.Attachments];
                }
                if (response.data.ExpirationDate) {
                    $scope.originalExpirationDate = response.data.ExpirationDate.slice();
                    $scope.riskToMitigate.ExpirationDate = response.data.ExpirationDate.slice();
                }
                $scope.controlsLoading = false;
            });
        }
        else {
            $scope.riskToMitigate.StatusID = "";
            $scope.selectedControls = new Array();
            $scope.originalControls = new Array();
            $scope.controlsLoading = false;
        }
    });

    $http.get(apiUrl + 'api/universal/companies/user/' + riskToMitigate.UserMasterID + '/risk/' + riskToMitigate.RiskId).then(function (response) {
        var index = -1;
        for (var i = 0, len = response.data.length; i < len; i++) {
            if (response.data[i].ID === riskToMitigate.CompanyMasterID) {
                index = i;
                response.data.splice(index, 1);
                break;
            }
        }
        response.data = $filter('orderBy')(response.data, 'Name');
        $scope.mitigateAddtionalDataSource = response.data;

        if ($scope.mitigateAddtionalDataSource.length > 1) {

            $scope.allCompaniesDropdownObject = {
                Name: 'All Companies',
                ID: 'AllObjectsInMultiSelect'
            };
            $scope.mitigateAddtionalDataSource.unshift($scope.allCompaniesDropdownObject);
        }
    });

    $http.get(apiUrl + 'api/arm/risks/' + riskToMitigate.RiskId).then(function (response) {
        $scope.riskObjects = response.data;
    });

    if ($scope.riskToMitigate.Status === 'New') {
        $http.get(`${apiUrl}api/arm/usermitigations/user/${$scope.riskToMitigate.UserMasterID}/company/${$scope.riskToMitigate.CompanyMasterID}/risk/${$scope.riskToMitigate.RiskId}`).then(response => {
            $scope.expiredMitigationsDataSource = response.data;
        });
    }

    $scope.similarMitigationsDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: function (options) {
                $http.get(apiUrl + 'api/arm/usermitigations/risk/' + riskToMitigate.RiskId + '/user/' + riskToMitigate.UserMasterID + '/similar').then(function (response) {
                    options.success(response.data);
                    $scope.similarMitigations = response.data;
                }, function (response) {
                    options.error();
                });
            }
        }
    });

    if (riskToMitigate.UserMitigationID) {
        $http.get(apiUrl + 'api/arm/usermitigations/' + riskToMitigate.UserMitigationID + '/history').then(function (response) {
            $scope.mitigationHistoryDataSource = response.data;
        });
    }

    $scope.mitigatedRisk = {
        selectedMitigateAddtional: '',
        companyIds: ''
    };

    var similarMitigationColumns = [
        { field: "UseMitigation", title: " ", template: "<span class='k-button btn-xs' ng-click='useMitigation(dataItem)'>Apply</span>", width: 80, filterable: false },
        { field: "UserMasterName", title: "User Name", width: 105, filterable: customAutoCompleteFilter },
        { field: "CompanyMasterName", title: "Company Name", width: 175, filterable: customAutoCompleteFilter },
        { field: "StatusName", title: "Status", width: 125, filterable: customAutoCompleteFilter },
        { field: "MitigationName", title: "Mitigation", width: 105, filterable: customAutoCompleteFilter },
        { field: "MitigationNotes", title: "Mitigation Notes", width: 155, filterable: customAutoCompleteFilter },
        { field: "ControlsString", title: "Controls", width: 165, filterable: customAutoCompleteFilter }
    ];

    $scope.similarMitigationsGridOptions = helperService.setMainGridOptions(similarMitigationColumns);


    $http.get(apiUrl + 'api/arm/mitigations').then(function (response) {
        response.data = $filter('orderBy')(response.data, 'Name');
        $scope.mitigations = response.data;
    });

    statusDataService.getStatuses().then(function (response) {
        response.data = $filter('filter')(response.data, { CanCurrentUserAccess: true });
        response.data = $filter('orderBy')(response.data, 'Name');
        $scope.statuses = response.data;

        $scope.statuses.unshift({
            ID: "",
            Name: "-- Select a Status --"
        })

    });

    $scope.companyIds = {};

    $scope.useMitigation = function (risk) {
        $scope.similarIsCollapsed = false;
        $timeout(function () {
            riskToMitigate.StatusID = risk.StatusID;
            riskToMitigate.MitigationID = risk.MitigationID;
            riskToMitigate.MitigationNotes = risk.MitigationNotes;
            var newCons = new Array();
            if (risk.Controls != null && risk.Controls != undefined && risk.Controls.length > 0) {
                riskToMitigate.MitigationID = null;
                for (var i = 0; i < risk.Controls.length; ++i) {
                    var matchingControl = $scope.controlLibrary.find(x => x.Identifier === risk.Controls[i].ControlId);
                    if (matchingControl != null && matchingControl != undefined) {
                        newCons.push(matchingControl);
                    }
                }
            }
            $scope.selectedControls = newCons;
        }, 500);
    };

    $scope.controlsEqual = function () {
        if ($scope.originalControls == undefined || $scope.selectedControls == undefined) return true;
        if ($scope.originalControls.length != $scope.selectedControls.length) return false;
        if ($scope.originalControls.map(x => x.Identifier).sort().join(',') === $scope.selectedControls.map(x => x.Identifier).sort().join(',')) {
            return true;
        }
        return false;
    };

    function okMitigateRisk() {

        var methodType = '';
        var data = '';
        var url = '';

        if (riskToMitigate.Status != 'New') {

            methodType = 'PUT';
            url = apiUrl + 'api/arm/usermitigations/' + riskToMitigate.UserMitigationID;

            data = {
                "ID": riskToMitigate.UserMitigationID,
                "CompanyMasterIds": [riskToMitigate.CompanyMasterID],
                "RiskId": riskToMitigate.RiskId,
                "IsAutoMitigated": riskToMitigate.AutoMitigated,
                "MitigationID": riskToMitigate.MitigationID,
                "MitigationNotes": riskToMitigate.MitigationNotes,
                "StatusID": riskToMitigate.StatusID,
                "UserMasterID": riskToMitigate.UserMasterID,
                "Controls": $scope.selectedControls.map(x => x.Identifier),
                "ExpirationDate": riskToMitigate.ExpirationDate
            };
        } else {

            methodType = 'POST';
            url = apiUrl + 'api/arm/usermitigations';

            data = {
                "CompanyMasterIds": [riskToMitigate.CompanyMasterID],
                "RiskId": riskToMitigate.RiskId,
                "IsAutoMitigated": riskToMitigate.AutoMitigated,
                "MitigationID": riskToMitigate.MitigationID,
                "MitigationNotes": riskToMitigate.MitigationNotes,
                "StatusID": riskToMitigate.StatusID,
                "UserMasterID": riskToMitigate.UserMasterID,
                "Controls": $scope.selectedControls.map(x => x.Identifier),
                "ExpirationDate": riskToMitigate.ExpirationDate
            };
        }

        $scope.updateInProgress = new createSpinner();
        $http({
            method: methodType,
            url: url,
            data: data
        }).then(function successCallback(response) {

            switch (methodType) {
                case 'PUT':
                    saveAttachments($scope.vm.attachments.flow.files, riskToMitigate.UserMitigationID);
                    mitigateInAdditionalCompanies(riskToMitigate.UserMitigationID);
                    break;
                case 'POST':
                    saveAttachments($scope.vm.attachments.flow.files, response.data);
                    mitigateInAdditionalCompanies(response.data[0]);
                    break;

            }


            $timeout(function () {
                $scope.killModal = true;

                helperService.successfulSaveButton($scope.updateInProgress).then(function (result) {
                    $scope.$parent.gridDataSource.read();
                    $uibModalInstance.close();
                });

            }, 2000);

        }, function errorCallback(response) {
            $scope.updateInProgress.loadingValue = false;
            helperService.showErrorMessage(response.data);
        });
    }

    async function saveAttachments(files, userMitigationId, companies) {
        if (files.length === 0) {
            return;
        }
        let fd = new FormData();
        files.forEach(function (file, i) {
            fd.append(files[i].file.name, files[i].file);
        });

        fd.append("DataType", "UserMitigation");

        if (companies) {
            fd.append('DataTypeId', companies);
            await controlLibraryDataService.addAttachments(fd);
        }
        else {
            fd.append('DataTypeId', [userMitigationId]);
            await controlLibraryDataService.addAttachments(fd);
        }
    }

    async function removeFile(file) {
        $scope.fileActionPending = true;

        try {
            await controlLibraryDataService.deleteAttachment(file.Identifier);
            const index = $scope.riskToMitigate.attachments.findIndex(remainingFile => remainingFile.Identifier === file.Identifier);
            $scope.riskToMitigate.attachments.splice(index, 1);
            $scope.fileActionPending = false;
        } catch (_) {
            $scope.fileActionPending = false;
        }
    }

    async function downloadFile(file) {
        $scope.fileActionPending = true;

        try {
            let response = await controlLibraryDataService.downloadAttachment(file.Identifier);
            FileSaver.saveAs(response.data, file.FileName);
            $scope.fileActionPending = false;
        } catch (_) {
            $scope.fileActionPending = false;
        }
    }

    function mitigateInAdditionalCompanies(mitigationId) {
        if ($scope.mitigatedRisk !== '' && $scope.mitigatedRisk.companyIds !== '') {
            let id = mitigationId;

            $scope.additionalCompanies = [];

            if ($scope.mitigatedRisk.companyIds[0].Name === 'All Companies') {
                $scope.mitigateAddtionalDataSource.forEach(company => $scope.additionalCompanies.push(company.ID));
                $scope.additionalCompanies.shift();
            } else {
                $scope.mitigatedRisk.companyIds.forEach(company => { $scope.additionalCompanies.push(company.ID); });
            }

            let data = {
                "ID": id,
                "CompanyMasterIds": $scope.additionalCompanies,
                "RiskId": riskToMitigate.RiskId,
                "IsAutoMitigated": riskToMitigate.AutoMitigated,
                "MitigationID": riskToMitigate.MitigationID,
                "MitigationNotes": riskToMitigate.MitigationNotes,
                "StatusID": riskToMitigate.StatusID,
                "UserMasterID": riskToMitigate.UserMasterID,
                "Controls": $scope.selectedControls.map(x => x.Identifier),
                "ExpirationDate": riskToMitigate.ExpirationDate
            };

            $http({ method: 'PUT', url: apiUrl + 'api/arm/usermitigations/' + id, data: data })
                .then(response => {
                    return;
                           
                }).catch(response => {
                    helperService.showErrorMessage(response.data);
                });
        }
    }

    function deselectSelectedCompany(companies) {
        if (companies) {
            $scope.mitigatedRisk.companyIds = helperService.deselectAllObjectsIfOtherIsClicked(companies, $scope.allCompaniesDropdownObject);
        } else {
            $scope.mitigatedRisk.companyIds = helperService.deselectAllObjectsIfOtherIsClicked($scope.mitigatedRisk.companyIds, $scope.allCompaniesDropdownObject);
        }
    }

    function deleteRiskMitigationRecord() {
        swal(helperService.areYouSureParams('Delete Risk Mitigation', 'Are you sure you want to delete Default this risk mitigation record?', 'Delete'))
            .then(_ => {
                $scope.deleteInProgress = new createSpinner();
                $http.delete(apiUrl + 'api/arm/usermitigations/' + riskToMitigate.UserMitigationID);
                $timeout(_ => {
                    $scope.killModal = true;
                    helperService.successfulSaveButton($scope.deleteInProgress)
                        .then(result => {
                            $scope.$parent.gridDataSource.read();
                            $uibModalInstance.close();
                        });
                }, 2000);
            })
            .catch(dismiss => {
                return;
            });
    }

    $scope.cancel = function () { $uibModalInstance.close(); };

}
