import aDUserSearchController from "./aDUserSearch.controller";

export default /*@ngInject*/ function ($scope, $uibModal) {

    const vm = this;

    vm.onClick = onClick;

    function onClick() {

        let modal = $uibModal.open({
            templateUrl: '/App/Components/RequestManagement/ADUserLink/aDUserSearch.html',
            controller: aDUserSearchController,
            controllerAs: 'adusvm',
            backdrop: 'static',
            size: 'lg',
            scope: $scope,
            resolve: {
                userObject: () => vm.userObject // bound to parent controller
            }
        });
        modal.result
            .then(userObject => vm.userObject = userObject)
            .catch(_ => { return });
    }

}