import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($scope, helperService, $uibModalInstance, $http) {

    $scope.modalvm = {};

    $scope.createSimulation = function () {
        $scope.pleaseWait = new createSpinner();
        var postData = {
            "Name": $scope.simulation.name,
            "ProductId": "AX7"
        };

        $http.post(apiUrl + 'api/securitydesigner/simulation', postData).then(async function successCallback(response) {

            $scope.killModal = true;
            await helperService.successfulSaveButton($scope.pleaseWait).then(function (result) {
                $uibModalInstance.close();
            });

        }, function errorCallback(response) {
            $scope.pleaseWait.loadingValue = false;
            helperService.showErrorMessage(response.data);
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}