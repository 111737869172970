import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, statusDataService, status) {

    const vm = this;

    vm.dismiss = dismiss;
    vm.saveStatus = saveStatus;

    function activate() {
        vm.shouldBeOpen = true;

        if (status.ID) {
            vm.status = Object.assign({}, status.toJSON());
        }
    }

    activate();

    function dismiss() {
        $uibModalInstance.dismiss();
    }

    function close() {
        $uibModalInstance.close();
    }

    //Save status
    function saveStatus() {
        vm.saving = new createSpinner();
            if (vm.status.ID) {
                modifyStatus(vm.status.ID, vm.status);
            } else {
                addStatus(vm.status);
            }
    }

    async function modifyStatus(statusId, status) {
        try {
            await statusDataService.modifyStatus(statusId, status);
            await helperService.successfulSaveButton(vm.saving);
            close();
        } catch {
            vm.saving.loadingValue = false;
        }
    }

    async function addStatus(status) {
        try {
            status.ID = '';
            await statusDataService.addStatus(status);
            await helperService.successfulSaveButton(vm.saving);
            close();
        } catch {
            vm.saving.loadingValue = false;
        }
    }
}