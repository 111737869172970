export default /*@ngInject*/ function reportsService($rootScope) {

    let service = {
        getFields,
        getSavedReportFilters,
        getSavedReportDateFilters,
        fixFieldNamesForApi,
        saveTabReportInfo
    };

    return service;

    function getFields(columns) {
        return columns.map(column => {
            let newColumn = {};
            if (column.Criteria) {
                newColumn = {
                    type: 'Multi',
                    link: column.Criteria._links.query.href,
                    fieldName: column.Criteria.FilterField,
                    textField: column.FieldName,
                    identifier: column.Criteria.Value,
                    label: column.Criteria.Label
                };
            } else {
                newColumn.type = column.DataType;
                newColumn.fieldName = column.FieldName;
            }
            newColumn.displayName = column.DisplayName;
            newColumn.isNullable = column.IsNullable;
            return newColumn;
        });
    }

    function getSavedReportFilters() {
        let query = [];
        let criteria = $rootScope.loadedSavedReport.ReportDefinition.Criteria;
        let filters = $rootScope.loadedSavedReport.ReportDefinition.Filters;

        if (criteria.some(c => !c.CriteriaType)) {
            let multiFilters = criteria.filter(c => !c.CriteriaType);
            multiFilters.forEach(f => {
                f.Filters[0].ValueName = f.CriteriaValue;
                f.Filters[0].DisplayName = f.CriteriaDisplayName;
            });
            multiFilters = multiFilters.map(filter => filter.Filters);
            multiFilters.forEach(filter => {
                query = query.concat(filter);
            });
        }

        if (filters.length > 0) {
           query = query.concat(filters);
        }

        return query;

    }

    function getSavedReportDateFilters() {
        let dateQuery = [];
        let dateFilters = $rootScope.loadedSavedReport.ReportDefinition.DateFilters;

        if (dateFilters && dateFilters.length > 0) {
            dateQuery = dateFilters;
        }

        return dateQuery;

    }

    function fixFieldNamesForApi(data, itemType) {
        if (data.Filters.length > 0) {
            const altNameFilterIndex = data.Filters.findIndex(filter => filter.FieldName === "AltName");
            const nameFilterIndex = data.Filters.findIndex(filter => filter.FieldName === "Name");
            const idFilterIndex = data.Filters.findIndex(filter => filter.FieldName === "ID");
            const descFilterIndex = data.Filters.findIndex(filter => filter.FieldName === "Description");
            if (altNameFilterIndex > -1) {
                data.Filters[altNameFilterIndex].FieldName = itemType + "AltName";
            }
            if (nameFilterIndex > -1) {
                data.Filters[nameFilterIndex].FieldName = itemType + "Name";
            }
            if (idFilterIndex > -1) {
                data.Filters[idFilterIndex].FieldName = itemType + "ID";
            }
            if (descFilterIndex > -1) {
                data.Filters[descFilterIndex].FieldName = itemType + "Description";
            }
        }
        if (data.Sorts.length > 0) {
            const altNameSortIndex = data.Sorts.findIndex(sort => sort.FieldName === "AltName");
            const nameSortIndex = data.Sorts.findIndex(sort => sort.FieldName === "Name");
            const idSortIndex = data.Sorts.findIndex(filter => filter.FieldName === "ID");
            const descSortIndex = data.Sorts.findIndex(filter => filter.FieldName === "Description");
            if (altNameSortIndex > -1) {
                data.Sorts[altNameSortIndex].FieldName = itemType + "AltName";
            }
            if (nameSortIndex > -1) {
                data.Sorts[nameSortIndex].FieldName = itemType + "Name";
            }
            if (idSortIndex > -1) {
                data.Sorts[idSortIndex].FieldName = itemType + "ID";
            }
            if (descSortIndex > -1) {
                data.Sorts[descSortIndex].FieldName = itemType + "Description";
            }
        }

        return data;
    }

    function saveTabReportInfo(xscope) {
        let currentTab = $rootScope.tabs.find(tab => tab.title === xscope.reportName);
        let exportDetails = {
            reportId: xscope.reportId,
            reportName: xscope.reportName,
            ObjectId: xscope.ObjectId,
            grid: xscope.grid,
            reportDetailsModel: xscope.reportDetailsModel,
            sortsAndFilters: xscope.sortsAndFilters
        };
        currentTab.reportExportDetails = exportDetails;
    }

}