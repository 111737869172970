export default /*@ngInject*/ function ($http) {

    this.getGPLoginTypes = function () {

        return [
            {
                Name: 'Single Sign-On',
                Id: 1
            },
            {
                Name: 'Single Sign-On (Password Required)',
                Id: 0
            },
            {
                Name: 'Standard',
                Id: 2
            }
        ];
    };

    this.getGPUserTypes = function () {

        return [
            {
                Name: 'Full',
                Id: 1
            },
            {
                Name: 'Limited',
                Id: 2
            },
            {
                Name: 'Self-Service',
                Id: 3
            }
        ];
    };

    this.getGPStatuses = function () {

        return [
            {
                Name: 'Active',
                Id: 1
            },
            {
                Name: 'Inactive',
                Id: 2
            },
            {
                Name: 'Lesson User',
                Id: 3
            }
        ];
    };


    this.getExistingGPUserSecurity = function (userId) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/gp/userroles/' + userId + '/'
        });
    };

    this.getGPIMUserSecurity = function (userId, version, useConfigAd) {
        return $http({
            method: 'GET',
            url: apiUrl + `identitymanager/gp/users/user/${userId}/version/${version}/useConfigAd/${useConfigAd}`
        });
    };

    this.getAllRoles = function () {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/gp/roles'
            });
        }
        catch (ex) {
            throw 'Error loading GP Roles';
        }
    };

    this.getAllCompanies = function () {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/gp/companies'
            });
        }
        catch (ex) {
            throw 'Error loading GP Companies';
        }
    };

    this.getAllClasses = function () {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/gp/classes'
            });
        }
        catch (ex) {
            throw 'Error loading GP Classes';
        }
    };

    this.getAltModProfiles = function () {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/gp/altmodprofiles'
            });
        }
        catch (ex) {
            throw 'Error loading GP Alt Mod Profiles';
        }
    };

    this.getClassesBySubsidiary = function (subsidiaryId) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/gp/subsidiaries/' + subsidiaryId + '/classes'
        });
    };

}