export default /*@ngInject*/ function ($http) {

    this.getRolesByBusinessUnitId = function (businessUnitId) {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/d365s/roles/businessunit/' + businessUnitId
            });
        }
        catch (ex) {
            throw 'Error loading D365S Roles';
        }
    };

    this.getTeamsByBusinessUnitId = function (businessUnitId) {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/d365s/teams/businessunit/' + businessUnitId
            });
        }
        catch (ex) {
            throw 'Error loading D365S Teams';
        }
    };

    this.getAllSites = function () {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/d365s/sites'
            });
        }
        catch (ex) {
            throw 'Error loading D365S Sites';
        }
    };

    this.getAllTerritories = function () {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/d365s/territories'
            });
        }
        catch (ex) {
            throw 'Error loading D365S Territories';
        }
    };

    this.getAllUsers = function () {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/d365s/users'
            });
        }
        catch (ex) {
            throw 'Error loading D365S Users';
        }
    };

    this.getAllBusinessUnits = function () {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/d365s/businessunits'
            });
        }
        catch (ex) {
            throw 'Error loading D365S Business Units';
        }
    };

    this.getAllIncomingMailMethods = function () {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/d365s/picklists/incomingmailmethods'
            });
        }
        catch (ex) {
            throw 'Error loading D365S Business Units';
        }
    };

    this.getAllOutgoingMailMethods = function () {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/d365s/picklists/outgoingmailmethods'
            });
        }
        catch (ex) {
            throw 'Error loading D365S Business Units';
        }
    };

    this.getAllAccessModes = function () {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/d365s/picklists/accessModes'
            });
        }
        catch (ex) {
            throw 'Error loading D365S Business Units';
        }
    };

    this.getLicenseTypesByAccessModeId = function (accessModeId) {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/d365s/picklists/licensetypes/accessmode/' + accessModeId
            });
        }
        catch (ex) {
            throw 'Error loading D365S Business Units';
        }
    };

}