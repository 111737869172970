import controller from "./openRequests.controller";

/* openRequests.component.js */

/**
 * @desc component for open IM requests
 * @example <open-requests></open-requests>
 */

const openRequests = {
    controller: controller,
    controllerAs: 'orvm',
    templateUrl: '/App/Components/openRequests/openRequests.tmpl.html'
};

export default openRequests;