import selectRolesByRoleTemplateController from "./selectRolesByRoleTemplate.controller";

/* selectRolesByRoleTemplate.component.js */

/**
 * @desc component for selecting role template from list of all role templates and getting roles associated with the selected role template
 * @example <select-roles-by-role-template></select-roles-by-role-template>
 */

const selectRolesByRoleTemplate = {
    bindings: {
        productId: '<',
        stringProductId: '@',
        roles: '=',
        availableRoles: '=',
        storedRoles: '=',
        altText: '@',
        companies: '<',
        selectedCompany: '<',
        businessUnitId: '<',
        validateRoles: '&',
        requestAction: '<',
        setSelectedRoleTemplates: '&',
        toggleIntraResponsibility: '&?'

    },
    controller: selectRolesByRoleTemplateController,
    controllerAs: 'srbrtvm',
    templateUrl: '/App/Shared/Components/selectRolesByRoleTemplate/selectRolesByRoleTemplate.tmpl.html'
};

export default selectRolesByRoleTemplate;