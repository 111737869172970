import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, $filter, simulation, role, field, object, helperService, sapSdSimRoleDataService) {
    const vm = this;

    vm.saveFieldValue = saveFieldValue;
    vm.cancel = cancel;

    activate();

    function activate() {
        setupFieldValueDropdowns();
    }

    async function setupFieldValueDropdowns() {
        vm.fieldValueOptionsDataSource = new kendo.data.DataSource({
            serverFiltering: false,
            transport: {
                read: async options => {
                    let response = await sapSdSimRoleDataService.getFieldValueOptions(simulation.Id, role.Id, object.Id, field ? field.Id : null);
                    response.data = $filter('orderBy')(response.data, 'Value');
                    options.success(response.data);
                }
            }

        });
    }

    async function saveFieldValue() {
        vm.savePending = new createSpinner();
        let data = {
            SimulationId: simulation.Id,
            RoleId: role.Id,
            FieldId: field ? field.Id : object.Id,
            Low: vm.fieldValue.low,
            High: vm.fieldValue.high
        };

        if (field) {
            data.AuthorizationObjectId = object.Id;
        }

        try {
            await sapSdSimRoleDataService.addFieldValue(data);
            await helperService.successfulSaveButton(vm.savePending);
            close();
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function close() {
        $uibModalInstance.close();
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}