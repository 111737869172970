import activeDirectoryDomainsController from "./activeDirectoryDomains.controller";

/* activeDirectoryDomains.component.js */

/**
 * @desc component for displaying, adding, editing, and deleting active directory domains
 * @example <active-directory-domains></active-directory-domains>
 */

const activeDirectoryDomains = {
    controller: activeDirectoryDomainsController,
    controllerAs: 'addvm',
    templateUrl: '/App/Components/Connections/activeDirectoryDomains/activeDirectoryDomains.tmpl.html'
};

export default activeDirectoryDomains;