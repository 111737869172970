export default /*@ngInject*/ function ($http) {

    this.getPasswordExpirationTypes = function () {
        return [
            'Days',
            'Accesses',
            'None'
        ];
    };

    this.getOracleResponsibilities = function () {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/or/responsibilities'
        });
    };

    this.getOracleRoles = function () {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/or/roles'
        });
    };

    this.getOracleProductConfig = function () {
        return $http({
            method: 'GET',
            url: `${apiUrl}api/universal/products/or/configuration`
        });
    };
}