import editRoleOrgHierarchiesModalController from "./editRoleOrgHierarchiesModal.controller";

export default /*@ngInject*/ function ($uibModal) {

    const vm = this;

    vm.openEditRoleOrgHierarchiesModal = openEditRoleOrgHierarchiesModal;

    async function openEditRoleOrgHierarchiesModal() {
        const editRoleOrgHierarchiesModal = $uibModal.open({
            templateUrl: '/App/Shared/Components/imRoleOrgHierarchies/editRoleOrgHierarchiesModal.html',
            controller: editRoleOrgHierarchiesModalController,
            controllerAs: 'erohmvm',
            backdrop: 'static',
            resolve: {
                role: () => vm.selectedRole ? JSON.parse(JSON.stringify(vm.selectedRole)) : null
            },
            size: 'lg'
        });

        try {
            const roleOrgHierarchiesObject = await editRoleOrgHierarchiesModal.result;
            updateRoleOrgHierarchies(vm.selectedRole, roleOrgHierarchiesObject);
        } catch (err) {
            return;
        }
    }

    function updateRoleOrgHierarchies(selectedRole, roleOrgHierarchiesObject) {
        // overwrite companies for role based on user input from modal
        if (!roleOrgHierarchiesObject.roleCompanies.length) {
            roleOrgHierarchiesObject.roleCompanies = null;
        }
        vm.onUpdateOrgHierarchies({ selectedRole: selectedRole, prop: 'Companies', value: roleOrgHierarchiesObject.roleCompanies});
    }

}
