import fieldValueModalController from "./fieldValueModal.controller";

export default /*@ngInject*/ function (sapSdSimRoleDataService, $filter, $uibModal) {

    const vm = this;

    vm.openFieldValueModal = openFieldValueModal;
    vm.deleteFieldValue = deleteFieldValue;

    vm.$onChanges = activate;

    async function activate() {
        getFieldValues();
    }

    async function getFieldValues() {
        vm.fieldValuesLoading = true;
        let response = await sapSdSimRoleDataService.getFieldValues(vm.simulation.Id, vm.selectedRole.Id, vm.selectedObject.Id, vm.field ? vm.field.Id : null);
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.fieldValues = response.data;
        vm.fieldValuesLoading = false;
    }

    async function openFieldValueModal() {
        let fieldValueModal = $uibModal.open({
            templateUrl: '/App/Components/SAPSecurityDesigner/simFieldValuesList/fieldValueModal.tmpl.html',
            controller: fieldValueModalController,
            controllerAs: 'fvmvm',
            backdrop: 'static',
            resolve: {
                simulation: () => vm.simulation,
                role: () => vm.selectedRole,
                field: () => vm.field,
                object: () => vm.selectedObject
            }
        });

        try {
            await fieldValueModal.result;
            await getFieldValues();
            updateLists();
        } catch { return; }
    }

    async function deleteFieldValue(fieldValue) {
        vm.fieldValuesLoading = true;
        await sapSdSimRoleDataService.deleteFieldValue(vm.simulation.Id, vm.selectedRole.Id, vm.selectedObject.Id, vm.field ? vm.field.Id : null, fieldValue.Id);
        await getFieldValues();
        updateLists();
    }

    function updateLists() {
        if (vm.field) {
            // need to refresh auth object fields list
            vm.simulationsCtrl.updateList('objectFields');
            vm.simulationsCtrl.updateList('authObjects');
        } else {
            // need to refresh org levels list
            vm.simulationsCtrl.updateList('orgLevels');
        }
    }
}