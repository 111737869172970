import controller from "./sapConnection.controller";

/* sapConnection.component.js */

/**
 * @desc component for sap product connections
 * @example <sap-connection></sap-connection>
 */

const sapConnection = {
    bindings: {
        product: '<',
        latestImportArray: '<',
        radioSelected: '<',
        setRadioSelected: '<',
        currentConfig: '<',
        originalConfig: '<',
        configUpdate: '<',
        updateConfig: '<',
        userSecurityList: '<'
    },
    controller: controller,
    controllerAs: 'scvm',
    templateUrl: '/App/Components/Connections/SapConnection/sapConnection.tmpl.html'
};

export default sapConnection;