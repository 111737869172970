export default /*@ngInject*/ function ($scope, configurationDataService) {

    getRoleDescConfigValue();

    async function getRoleDescConfigValue() {
        let response = await configurationDataService.getProductConfigurationValueByName('nav', 'IM_ShowRoleDescription');
        $scope.roleDesc = response.data.Value;
    }

    //If $scope variable doesnt exist in child it looks in parent

    var RoleCompany = function (params) {
        this.RoleId = params.RoleId;
        this.CompanyId = params.CompanyId;
        this.RoleName = params.RoleName;
        this.CompanyName = params.CompanyName;
        this.AllCompanies = params.AllCompanies;
    };

    $scope.AllCompanies = false;

    $scope.handleNavPermissionCompany = function (permissionSet) {
        permissionSet.Companies = permissionSet.CompanyNames.map(name => {
            return { Name: name };
        });
    };

    $scope.addRoleToList = function (role) {
        $scope.vm.ListOfAvailableNAVPermissionSets.forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfAvailableNAVPermissionSets.splice(i, 1);
                $scope.vm.ListOfSelectedNAVPermissionSets.push(role);
            }
        });
        $scope.vm.IsRisksReviewed = null;
    };

    $scope.deleteSelectedRole = function (role) {
        $scope.vm.ListOfSelectedNAVPermissionSets.forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfSelectedNAVPermissionSets.splice(i, 1);
                if (role.IsAssignable) {
                    $scope.vm.ListOfAvailableNAVPermissionSets.push(row);
                }
            }
        });

        $scope.vm.IsRisksReviewed = null;
    };

    $scope.addRoleCompany = function (company, selected) {
        $scope.vm.ListOfSelectedNAVPermissionSets.forEach(function (row, i) {
            if (row.Id === $scope.selectedRole.Id) {

                if (!row.CompanyId || selected === false) {
                    $scope.vm.NAVRoleCompanyList.splice(i, 1);
                }
                if (selected === true) {
                    $scope.vm.NAVRoleCompanyList.push(
                        new RoleCompany({
                            RoleId: row.RoleId,
                            CompanyId: company.CompanyId,
                            RoleName: row.RoleName,
                            CompanyName: company.CompanyName,
                            AllCompanies: false
                        }));
                }
            }
        });
    };

    $scope.selectedRole = null;

    $scope.setSelectedRoleTemplates = function (selectedRoleTemplates) {
        $scope.vm.selectedRoleTemplates = selectedRoleTemplates;
    };

}