import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($scope, $filter, helperService, taskRecordingDataService, $uibModalInstance, $http, productType) {

    $scope.modalvm = {};
    $scope.modalvm.fileUploaded = null;
    $scope.modalvm.file = null;
    $scope.showUploadSection = false;

    function activate() {
        if (productType) getTaskRecordings(); // if adding a new privilege
    }

    activate();

    function getTaskRecordings() {
        $scope.modalvm.loadingTaskRecordings = true;
        taskRecordingDataService.getTaskRecordings(productType)
            .then(response => {
                response.data = $filter('orderBy')(response.data, 'Name');
                $scope.modalvm.taskRecordings = response.data;
                $scope.modalvm.loadingTaskRecordings = false;
            })
            .catch(error => {
                $scope.modalvm.loadingTaskRecordings = false;
                return;
            });
    }

    function submitExistingTaskRecording() {
        $scope.pleaseWait = new createSpinner();

        let taskRecordingId = $scope.modalvm.selectedTaskRecording.Id;

        let postData = {
            SimulationId: $scope.vm.selectedSimulation.Id,
            Name: $scope.modalvm.name,
            Description: $scope.modalvm.desc || '',
            TaskRecordingId: taskRecordingId
        };

        $http.post(apiUrl + `api/securitydesigner/ax7/simulation/${taskRecordingId}/importedtaskrecording/privilege`, postData)
            .then(response => {
                $scope.killModal = true;
                helperService.successfulSaveButton($scope.pleaseWait)
                    .then(result => {
                        $uibModalInstance.close();
                        $scope.vm.simulationSelected();
                    });
            })
            .catch(error => {
                $scope.pleaseWait.loadingValue = false;
                helperService.showErrorMessage(error.data);
            });
    }

    $scope.create = function () {
        $scope.pleaseWait = new createSpinner();

        var desc = $scope.modalvm.desc;
        if (!desc) {
            desc = "";
        }

        var postData = {
            "SimulationId": $scope.vm.selectedSimulation.Id,
            "Name": $scope.modalvm.name,
            "Description": desc,
            "Type": $scope.securityLayerType
        };

        $http.post(apiUrl + 'api/securitydesigner/ax7/simulation/securitylayer', postData).then(function successCallback(response) {

            $scope.killModal = true;
            helperService.successfulSaveButton($scope.pleaseWait).then(function (result) {
                $uibModalInstance.close();
                $scope.vm.simulationSelected();
            });

        }, function errorCallback(response) {
            $scope.pleaseWait.loadingValue = false;
            helperService.showErrorMessage(response.data);
        });
    };

    $scope.cancelUpload = function (flow) {
        flow.cancel();
        $scope.modalvm.fileUploaded = null;
    };

    $scope.uploadedFile = null;

    $scope.fileAdded = function (file, event, flow) {

        var fileType = file[0].file.type;

        if (fileType === "text/xml") {
            $scope.modalvm.file = file[0].file;
            $scope.modalvm.fileUploaded = true;
        } else {
            $scope.taskRecorderResponse = null;
            $scope.modalvm.fileUploaded = null;
            helperService.showErrorMessage('XML files only');
        }
    };

    $scope.uploadTaskRecording = function () {
        $scope.pleaseWait = new createSpinner();

        var desc = $scope.modalvm.desc;
        if (!desc) {
            desc = "";
        }

        var fd = new FormData();
        fd.append("SimulationId", $scope.vm.selectedSimulation.Id);
        fd.append("TaskRecording", $scope.modalvm.file);
        fd.append("Name", $scope.modalvm.name);
        fd.append("Description", desc);

        $http.post(apiUrl + 'api/securitydesigner/ax7/simulation/' + $scope.vm.selectedSimulation.Id + '/taskrecording/privilege', fd, {
            transformRequest: angular.identity,
            headers: { 'Content-Type': undefined }
        }).then(function successCallback(response) {
            $scope.killModal = true;
            helperService.successfulSaveButton($scope.pleaseWait).then(function (result) {
                $uibModalInstance.close();
                $scope.vm.simulationSelected();
            });

        }, function errorCallback(response) {
            $scope.pleaseWait.loadingValue = false;
            helperService.showErrorMessage(response.data);
        });
    };

    $scope.submitPrivilege = function () {
        if ($scope.modalvm.showUploadSection) $scope.uploadTaskRecording();
        else if ($scope.modalvm.showInputSection) submitExistingTaskRecording();
        else $scope.create();
    };


    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}