import generatedReportsController from "./generatedReports.controller";

/* reportGenerator.component.js */
/**
 * @desc component for displaying and creating generated reports
 * @example <report-generator></report-generator>
 */
const reportGenerator = {
    controller: generatedReportsController,
    controllerAs: 'grvm',
    templateUrl: '/App/Components/ReportGenerator/generatedReports.html'
};
export default reportGenerator;