import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($scope, $rootScope, $filter, $route, $http, helperService) {

    var newVm = $scope.newVm = {};

    newVm.newSimulationName = $rootScope.simulationToDeploy.Name;
    newVm.simulationId = $rootScope.simulationToDeploy.Id;
    delete $rootScope.simulationToDeploy;

    newVm.loadingValidation = true;

    $http.get(apiUrl + '/api/arm/ns/risks/deployment/' + $scope.newVm.simulationId + '/steps').then(function successCallback(response) {
        $scope.simulationRoleDeploymentSteps = response.data;
        $scope.validateResponse();
    }, function errorCallback(response) {
        newVm.loadingValidation = false;
        helperService.showErrorMessage(response.data);
    });

    $scope.markAsDeployed = function () {
        $scope.postSimulationInProgress = new createSpinner();
        var postData = {
            "NsRsHeaderId": newVm.simulationId,
            "StatusId": 4
        };
        $http.put(apiUrl + '/api/arm/ns/risks/status', postData).then(function successCallback(response) {
            $scope.killModal = true;
            helperService.successfulSaveButton($scope.postSimulationInProgress).then(function (result) {
                $route.reload();
            });
        }, function errorCallback(response) {
            $scope.postSimulationInProgress.loadingValue = false;
            helperService.showErrorMessage(response.data);
        });
    };

    function addPassedValidationProperty(array) {
        array.forEach(function (row) {
            var element = $scope.validationResponse.filter(e => e.StepId === row.StepId)[0];

            if (element) {
                row['PassedValidation'] = element.PassedValidation;
            }
        });
    }

    function calculateSteps(steps) {
        var array = [];
        steps.forEach(function (row) {
            if (row.ActionName === "Modify" || row.ActionName === "Add") {
                row.PermissionDeploymentSteps.forEach(function (item) {
                    array.push({ StepId: item.StepId, PassedValidation: item.PassedValidation });
                });
                row.SubsidiaryDeploymentSteps.forEach(function (item) {
                    array.push({ StepId: item.StepId, PassedValidation: item.PassedValidation });
                });
                row.UserDeploymentSteps.forEach(function (item) {
                    array.push({ StepId: item.StepId, PassedValidation: item.PassedValidation });
                });
            } else if (row.ActionName === "Remove") {
                array.push({ StepId: row.StepId, PassedValidation: row.PassedValidation });
            }
        });

        $scope.maxStepsComplete = array.length;
        $scope.totalStepsComplete = $filter('filter')(array, { PassedValidation: true }).length;
    }

    $scope.validateResponse = function () {

        newVm.loadingValidation = true;
        var postData = angular.copy($scope.simulationRoleDeploymentSteps.RoleDeploymentSteps);

        $http.post(apiUrl + '/api/arm/ns/risks/deployment/validate', postData).then(function successCallback(response) {
            $scope.validationResponse = response.data;

            $scope.simulationRoleDeploymentSteps.RoleDeploymentSteps.forEach(function (row) {
                var element = $scope.validationResponse.filter(e => e.StepId === row.StepId)[0];

                if (element) {
                    row['PassedValidation'] = element.PassedValidation;
                }

                addPassedValidationProperty(row.PermissionDeploymentSteps);
                addPassedValidationProperty(row.SubsidiaryDeploymentSteps);
                addPassedValidationProperty(row.UserDeploymentSteps);

            });
            calculateSteps($scope.simulationRoleDeploymentSteps.RoleDeploymentSteps);
            newVm.loadingValidation = false;

        }, function errorCallback(response) {
            newVm.loadingValidation = false;
            helperService.showErrorMessage(response.data);
        });
    };
}
