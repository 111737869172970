import { halSchemaKendoGrid } from "../../../Shared/kendo.module";
import { standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, peoplesoftAutoMitigationsDataService, autoMitigation) {

    const vm = this;

    vm.needsSelectedExcludedObjectType = true;
    vm.needsSelectedFromObjectType = true;
    vm.updateAutoMitigation = updateAutoMitigation;
    vm.saveAutoMitigation = saveAutoMitigation;
    vm.dismiss = dismiss;
    vm.excludeTypeDropdownChange = excludeTypeDropdownChange;
    vm.fromTypeDropdownChange = fromTypeDropdownChange;
    vm.excludeDropdownOptions = {
        dataSource: [{
            id: 1, name: "Page"
        }],
        dataTextField: "name",
        dataValueField: "id",
        optionLabel: "--Select an item--"
    };
    vm.fromDropdownOptions = {
        dataSource: [
            { id: 5, name: "Bar Name" },
            { id: 4, name: "Component" },
            { id: 2, name: "Menu" },
            { id: 3, name: "Permission List" }
        ],
        dataTextField: "name",
        dataValueField: "id",
        optionLabel: "--Select an item--"
    };
    vm.excludeObjectDropdownOptions = {
        template: '<strong>{{dataItem.AltName}}</strong><span ng-if="dataItem.Name"> | {{dataItem.Name}}</span><span ng-if="dataItem.SecurityObjectTypeName"> | {{dataItem.SecurityObjectTypeName}} </span>',
        filter: "contains",
        autoBind: false,
        minLength: 3,
        virtual: {
            itemHeight: 26
        },
        height: 325
    };
    vm.fromObjectDropdownOptions = {
        template: `
            <span ng-if="amrmvm.autoMitigation.selectedFromType.id == 5">
                <span ng-if="dataItem.BarName">{{dataItem.BarName}}</span>
            </span>
            <span ng-if="amrmvm.autoMitigation.selectedFromType.id == 4">
                <strong ng-if="dataItem.ComponentDescription">{{ dataItem.ComponentDescription }}</strong>
                <span ng-if="dataItem.ComponentName"> | {{ dataItem.ComponentName }}</span>
                <span ng-if="dataItem.MarketId"> | {{ dataItem.MarketId }}</span>
            </span>
            <span ng-if="amrmvm.autoMitigation.selectedFromType.id == 2">
                <strong ng-if="dataItem.MenuLabel">{{ dataItem.MenuLabel }}</strong>
                <span ng-if="dataItem.MenuName"> | {{ dataItem.MenuName }}</span>
            </span>
            <span ng-if="amrmvm.autoMitigation.selectedFromType.id == 3">
                <strong ng-if="dataItem.Id">{{ dataItem.Id }}</strong>
                <span ng-if="dataItem.Name"> | {{ dataItem.Name }}</span>
            </span>
        `,
        filter: "contains",
        autoBind: false
    };
    vm.fromFunctionDropdownOptions = {
        dataTextField: "name",
        dataValueField: "id"
    };


    activate();

    function activate() {
        if (autoMitigation.uid) {
            vm.isCreate = false;
            vm.moduleType = 'Edit';
            vm.autoMitigation = {
                description: autoMitigation.Description,
                ruleId: autoMitigation.RuleId,
                Enabled: autoMitigation.Enabled,
                selectedExcludeType: {
                    name: autoMitigation.AutoMitigationObjectType
                },
                excludeObject: {
                    Name: autoMitigation.AutoMitigationObjectName
                },
                selectedFromType: {
                    name: autoMitigation.FromObjectType
                },
                fromObject: {
                    Name: autoMitigation.FromObjectName
                }
            };
        } else {
            vm.isCreate = true;
            vm.moduleType = 'Create';
            vm.autoMitigation = autoMitigation;
            vm.autoMitigation.Enabled = true;
        }
    }

    function cancel() {
        $uibModalInstance.close();
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }

    function getFromObjectId(autoMitigation) {
        let id;

        switch (autoMitigation.selectedFromType.id) {
            case 5:
                id = autoMitigation.fromObject.Id;
                break;
            case 4:
                id = autoMitigation.fromObject.ComponentName;
                break;
            case 3:
                id = autoMitigation.fromObject.Id;
                break;
            case 2:
                id = autoMitigation.fromObject.MenuName;
                break;
            default:
                id = undefined;
                break;
        }

        return id;
    }

    //Save Auto Mitigation
    async function saveAutoMitigation() {
        let data = {
            AutoMitigationObjectId: vm.autoMitigation.excludeObject.Name,
            AutoMitigationObjectType: vm.autoMitigation.selectedExcludeType.id,
            FromObjectId: getFromObjectId(vm.autoMitigation),
            FromObjectType: Number(vm.autoMitigation.selectedFromType.id),
            Enabled: vm.autoMitigation.Enabled,
            Description: vm.autoMitigation.description
        };

        vm.savingAutoMitigation = new createSpinner();
 
        try {
            await peoplesoftAutoMitigationsDataService.addAutoMitigation(data);
            await helperService.successfulSaveButton(vm.savingAutoMitigation);

            cancel();
        } catch {
            vm.savingAutoMitigation.loadingValue = false;
        }
    }

    //Update Auto Mitigation
    async function updateAutoMitigation() {
        let data = {
            RuleId: vm.autoMitigation.ruleId,
            Enabled: vm.autoMitigation.Enabled,
            Description: vm.autoMitigation.description
        };

        vm.savingAutoMitigation = new createSpinner();

        try {
            await peoplesoftAutoMitigationsDataService.modifyMitigation(vm.autoMitigation.ruleId, data);
            await helperService.successfulSaveButton(vm.savingAutoMitigation);

            cancel();
        } catch {
            vm.savingAutoMitigation.loadingValue = false;
        }
    }

    function excludeTypeDropdownChange() {
        if (!vm.autoMitigation.Id) {
            vm.autoMitigation.excludeObject = null;
        }

        if (vm.autoMitigation?.selectedExcludeType?.id === 1) {
            vm.autoMitigation.excludeObjectDataSource = new kendo.data.DataSource({
                schema: halSchemaKendoGrid,
                pageSize: standardGridPageSize,
                serverPaging: true,
                serverFiltering: true,
                transport: {
                    read: async (options) => {
                        let body = helperService.prepareSortsAndFilters(options),
                            params = {
                                "pagesize": options.data.pageSize,
                                "page": options.data.page
                            };

                        if (body.Filters.length > 0) {
                            body.Filters[0].FieldName = "SecurityObjectAltName";
                        }
                        let { data } = await peoplesoftAutoMitigationsDataService.getObjects(body, params);

                        if (vm.autoMitigation.excludeObjectDataSource._data.length === 0) {
                            vm.needsSelectedExcludedObjectType = false;
                            vm.excludedObjectsDropdownList.setOptions({ optionLabel: '--Select an item--' });
                        }
                        options.success(data);
                    }
                }
            });

            vm.autoMitigation.excludeObjectDataSource.read();

        } else if (!vm.autoMitigation.selectedExcludeType.id) {
            vm.needsSelectedExcludedObjectType = true;
            vm.autoMitigation.excludeObjectDataSource = [];
        }
    }

    function fromTypeDropdownChange() {
        if (!vm.autoMitigation.Id) {
            vm.autoMitigation.fromObject = null;
        }

        if (vm.autoMitigation?.selectedFromType?.id) {
            // String == number
            if (vm.autoMitigation.selectedFromType.id === 5) {
                vm.selectedFromObjectTypeRoute = apiUrl + 'api/assure/ps/barnames';
                vm.fromObjectDropdownList.setOptions({ dataTextField: 'BarName' });
            } else if (vm.autoMitigation.selectedFromType.id === 4) {
                vm.selectedFromObjectTypeRoute = apiUrl + 'api/assure/ps/components';
                vm.fromObjectDropdownList.setOptions({ dataTextField: 'ComponentName' });
            } else if (vm.autoMitigation.selectedFromType.id === 2) {
                vm.selectedFromObjectTypeRoute = apiUrl + 'api/assure/ps/menus';
                vm.fromObjectDropdownList.setOptions({ dataTextField: 'MenuName' });
            }  else if (vm.autoMitigation.selectedFromType.id === 3) {
                vm.selectedFromObjectTypeRoute = apiUrl + 'api/assure/ps/permissionlists';
                vm.fromObjectDropdownList.setOptions({ dataTextField: 'Id' });
            }

            vm.autoMitigation.fromObjectDataSource = new kendo.data.DataSource({
                transport: {
                    read: async (options) => {
                        let { data } = await peoplesoftAutoMitigationsDataService.getObjectsByUrl(vm.selectedFromObjectTypeRoute);

                        vm.needsSelectedFromObjectType = false;
                        vm.fromObjectDropdownList.setOptions({ optionLabel: '--Select an item--' });
                        options.success(helperService.generateRandomUuid(data));
                    }
                }
            });

            vm.autoMitigation.fromObjectDataSource.read();

        } else if (!vm.autoMitigation.selectedFromType.id) {
            vm.needsSelectedFromObjectType = true;
            vm.autoMitigation.fromObjectDataSource = [];
        }
    }
}