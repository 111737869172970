import createSpinner from "../../../../Shared/Functions/createSpinner";

const frequencies = ['Daily', 'Weekly', 'Monthly'],
    daysInWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export default /*@ngInject*/ function (helperService, errorsDataService, dateFormatterFactory, connectionsDataService) {

    const vm = this;

    vm.$onInit = init;

    function init() {
        getImportSchedule();
    }

    vm.frequencies = frequencies;
    vm.daysInWeek = daysInWeek;
    vm.daysInMonth = helperService.getDayOfMonthDropdownOptions();
    vm.startTimeForScheduleImport = new Date();

    vm.scheduleImportStatusChanged = scheduleImportStatusChanged;
    vm.updateScheduleImport = updateScheduleImport;
    vm.createNewSchedule = createNewSchedule;
    vm.disableSchedule = disableSchedule;
    vm.scheduleChanged = scheduleChanged;
    vm.formatDate = formatDate;
    vm.formatDay = formatDay;
    vm.updateInProgress = false;

    async function getImportSchedule() {
        let response;

        try {
            response = await connectionsDataService.getProductSchedule(vm.product.ID);

            vm.importSchedule = Object.assign({}, response.data);
            vm.DayOfMonth = { Value: response.data.DayOfMonth };
            vm.StartTime = new Date(response.data.NextExecutionTime);
            vm.updatedSchedule = Object.assign({}, response.data);
            vm.scheduleImportIsOn = true;
        } catch {
            vm.importSchedule = null;
            vm.scheduleImportIsOn = false;
        }
    }

    function formatDate(dateObject) {
        vm.updatedSchedule.StartTime = dateFormatterFactory.formatDate(dateObject);
    }

    function formatDay(dayObject) {
        vm.updatedSchedule.DayOfMonth = dayObject.Value;
    }

    function scheduleChanged() {
        let initial = vm.importSchedule || {},
            updated = vm.updatedSchedule || {},
            changed = true;

        if (vm.importSchedule && angular.equals(initial, updated)) {
            changed = false;
        }

        return changed;
    }

    function scheduleImportStatusChanged() {
        if (vm.scheduleImportIsOn === false && vm.importSchedule) {
            updateScheduleImport(false, vm.product);
        }
    }

    function updateScheduleImport(isOn) {
        updateSchedule(isOn);
    }

    async function updateSchedule(scheduleIsOn) {
        vm.updateInProgress = new createSpinner();

        if (scheduleIsOn) {
            await createNewSchedule();
            await getImportSchedule();
        } else {
            await disableSchedule();
        }
    }

    async function createNewSchedule() {
        const frequency = vm.updatedSchedule.Frequency,
            dayOfWeek = vm.updatedSchedule.DayOfWeek;
        let data = {},
            adjustedTime = new Date(),
            enteredHours = vm.StartTime.getHours(),
            enteredMinutes = vm.StartTime.getMinutes(),
            dayOfMonthRequest,
            dayOfWeekRequest;

        vm.updateInProgress = new createSpinner();

        if (frequency === 'Daily') {
            dayOfMonthRequest = [];
            dayOfWeekRequest = [];
        } if (frequency === 'Weekly') {
            dayOfMonthRequest = [];
            dayOfWeekRequest = [dayOfWeek];
        } if (frequency === 'Monthly') {
            dayOfMonthRequest = [vm.DayOfMonth.Value];
            dayOfWeekRequest = [];
        }

        // Adjusting for DST
        adjustedTime.setHours(enteredHours);
        adjustedTime.setMinutes(enteredMinutes);

        try {
            data = {
                "ProductId": vm.product.ID,
                "Schedule": {
                    "DayOfMonth": dayOfMonthRequest[0],
                    "DayOfWeek": dayOfWeekRequest[0],
                    "Frequency": frequency,
                    "StartTime": adjustedTime.toUTCString()
                }
            };

            await connectionsDataService.updateProductSchedule(vm.product.ID, data);
            helperService.successfulSaveButton(vm.updateInProgress);
            vm.updateInProgress.loadingValue = false;
        } catch {
            vm.updateInProgress.loadingValue = false;
        }
    }

    async function disableSchedule() {
        const data = { "ProductId": vm.product.ID };
        try {
            await connectionsDataService.disableProductSchedule(vm.product.ID, data);
            await getImportSchedule();
            vm.updateInProgress.loadingValue = false;
        } catch {
            vm.updateInProgress.loadingValue = false;
        }
    }
}