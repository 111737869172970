import aDUserLookupController from "./aDUserLookup.controller";

/* aDUserLookup.component.js */

/**
 * @desc component for displaying AD User Lookup
 * @example <ad-user-lookup></ad-user-lookup>
 */

const aDUserLookup = {
    bindings: {
        userObject: '<',
        onUserUpdated: '&'
    },
    controller: aDUserLookupController,
    controllerAs: 'adulm',
    templateUrl: '/App/Components/RequestManagement/ADUserLookup/aDUserLookup.tmpl.html'
};

export default aDUserLookup;