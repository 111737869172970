import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($scope, $filter, $uibModalInstance, selectedCompanyProduct, helperService, $http) {
    const vm = this;

    vm.cancel = cancel;

    activate();

    function activate() {
        let columnsFromVM = [
            { field: "CompanyName", title: "Company", filterable: customAutoCompleteFilter }
        ];

        vm.manualMapGridOptions = helperService.setMainGridOptions(columnsFromVM, 400);
        vm.manualMapGridOptions.selectable = true;

        vm.manualMapDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: function (options) {
                    $http.get(apiUrl + 'api/universal/companymapping/unmapped/product/' + selectedCompanyProduct.ProductID).then(function (response) {
                        response.data = $filter('orderBy')(response.data, 'Name');
                        options.success(response.data);
                    });
                }
            }
        });
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }

}