import swal from "sweetalert2";
import generatedReportModalController from "./generatedReportModal.controller";

export default /*@ngInject*/ function ($uibModal, $rootScope, $filter, helperService, generatedReportsDataService) {

    const vm = this;

    vm.openNewReportModal = openNewReportModal;

    activate();

    function activate() {
        getGeneratedReports();
    }

    vm.getGeneratedReports = getGeneratedReports;
    vm.deleteReport = deleteReport;

    async function getGeneratedReports() {
        vm.loadingGeneratedReports = true;
        try {
            const res = await generatedReportsDataService.getGeneratedReports();
            res.data = $filter('orderBy')(res.data, 'Name');
            vm.generatedReports = res.data;
            vm.loadingGeneratedReports = false;
        } catch {
            vm.loadingGeneratedReports = false;
        }
    }

    async function deleteReport(report) {
        try {
            await swal(helperService.areYouSureParams('Delete Report', `Are you sure you want to delete ${report.Name}?`, 'Delete'));
            await generatedReportsDataService.deleteReport(report.ReportId);
            getGeneratedReports();
            $rootScope.$broadcast('navigation-or-security-change');
        } catch {
            return;
        }
    }

    async function openNewReportModal() {
        let newReportModal = $uibModal.open({
            templateUrl: '/App/Components/ReportGenerator/generatedReportModal.html',
            controller: generatedReportModalController,
            controllerAs: 'grmvm',
            backdrop: 'static'
        });

        try {
            await newReportModal.result;
            getGeneratedReports();
            $rootScope.$broadcast('navigation-or-security-change');
        } catch {
            return;
        }
    }
}