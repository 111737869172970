export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getRoleTemplates,
        createRoleTemplate,
        renameRoleTemplate,
        deleteRoleTemplate,
        getRolesForProduct,
        addRole,
        deleteRole,
        exportRoleTemplates,
        importRoleTemplates,
    };

    return service;

    async function getRoleTemplates() {
        try {
            return await $http.get(apiUrl + 'api/universal/roletemplates');
        } catch (err) {
            showError(err);
        }
    }

    async function createRoleTemplate(postData) {
        try {
            return await $http.post(apiUrl + 'api/universal/roletemplates', postData);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function renameRoleTemplate(templateId, putData) {
        try {
            return await $http.put(apiUrl + 'api/universal/roletemplates/' + templateId, putData);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteRoleTemplate(templateId) {
        try {
            return await $http.delete(apiUrl + 'api/universal/roletemplates/' + templateId);
        } catch (err) {
            showError(err);
        }
    }

    async function getRolesForProduct(productId) {
        try {
            return await $http.get(apiUrl + '/api/universal/securityroles/product/' + productId);
        } catch (err) {
            showError(err);
        }
    }

    async function addRole(templateId, postData) {
        try {
            return await $http.post(apiUrl + 'api/universal/roletemplates/' + templateId + '/associate', postData);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteRole(templateId, securityRoleId) {
        try {
            return await $http.delete(`${apiUrl}api/universal/roletemplates/${templateId}/securityrole/${securityRoleId}/disassociate`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function exportRoleTemplates() {
        try {
            return await $http.get(`${apiUrl}/api/universal/roletemplates/export`, { responseType: "blob" });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function importRoleTemplates(fd) {
        try {
            return await $http.put(`${apiUrl}/api/universal/roletemplates/import`, fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }
}