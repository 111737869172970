/* attachmentsDownload.component.js */
import attachmentsDownloadModalController from "./attachmentsDownloadModal.controller"
/**
 * @desc component for viewing user profile information
 * @example <attachments-download></attachments-download>
 */

const attachmentsDownload = {
    bindings: {
        caseId: '<'
    },
    controller: attachmentsDownloadController,
    controllerAs: 'advm',
    template: `<div class="attachments-download">
                    <a ng-click="advm.openAttachmentsDownloadModal(advm.caseId)">
                        <strong>Download</strong>
                    </a>
                </div>`

};

/*@ngInject*/ function attachmentsDownloadController($uibModal) {

    const vm = this;

    vm.openAttachmentsDownloadModal = openAttachmentsDownloadModal;

    async function openAttachmentsDownloadModal(caseId) {
        $uibModal.open({
            templateUrl: '/App/Shared/Components/Attachments/attachmentsDownloadModal.tmpl.html',
            controller: attachmentsDownloadModalController,
            controllerAs: 'admvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                caseId: () => caseId
            }
        });
    }
}

export default attachmentsDownload;