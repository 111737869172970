import controller from "./connection.controller";

/* connection.component.js */

/**
 * @desc component for product connections
 * @example <connection></connection>
 */

const connection = {
    bindings: {
        product: '<',
        setRadioSelected: '<',
        radioSelected: '<',
        latestImportArray: '<',
        productImportTiles: '<',
        productTiles: '<',
        currentConfig: '<',
        originalConfig: '<',
        userSecurityList: '<',
        hybridConnectionsArray: '<',
        nsRolesChanged: '<',
        modules: '<'
    },
    controller: controller,
    controllerAs: 'cvm',
    templateUrl: '/App/Components/Connections/Connection/connection.tmpl.html'
};

export default connection;