import controller from "./plugins.controller";

/* plugins.component.js */

/**
 * @desc component for plugins
 * @example <plugins></plugins>
 */

const plugins = {
    controller: controller,
    controllerAs: 'pvm',
    templateUrl: '/App/Components/Plugins/plugins.tmpl.html'
};

export default plugins;