export default /*@ngInject*/ function ($http, errorsDataService) {
    var service = {
        getUserGroups,
        getUsers,
        getUsersByUserGroupId,
        createUserGroup,
        editUserGroup,
        deleteUserGroup,
        addUserToUserGroup,
        removeUserGroupUser
    };

    return service;

    async function getUserGroups() {
        try {
            return await $http.get(`${apiUrl}api/audittrail/ns/v2/usergroups`);
        } catch (err) {
            showError(err);
        }
    }

    async function getUsers() {
        try {
            return await $http.get(`${apiUrl}api/audittrail/ns/v2/users`);
        } catch (err) {
            showError(err);
        }
    }

    async function getUsersByUserGroupId(userGroupId) {
        try {
            return await $http.get(`${apiUrl}api/audittrail/ns/v2/usergroups/${userGroupId}/users`);
        } catch (err) {
            showError(err);
        }
    }

    async function createUserGroup(data) {
        try {
            return await $http.post(`${apiUrl}api/audittrail/ns/v2/usergroups`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function editUserGroup(userGroupId, data) {
        try {
            return await $http.put(`${apiUrl}api/audittrail/ns/v2/usergroups/${userGroupId}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteUserGroup(userGroupId) {
        try {
            return await $http.delete(`${apiUrl}api/audittrail/ns/v2/usergroups/${userGroupId}`);
        } catch (err) {
            showError(err);
        }
    }

    async function addUserToUserGroup(userGroupId, data) {
        try {
            return await $http.post(`${apiUrl}api/audittrail/ns/v2/usergroups/${userGroupId}/users`, data);
        } catch (err) {
            showError(err);
        }
    }

    async function removeUserGroupUser(userGroupId, userGroupUserId) {
        try {
            return await $http.delete(`${apiUrl}api/audittrail/ns/v2/usergroups/${userGroupId}/users/${userGroupUserId}`);
        } catch (err) {
            showError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

}