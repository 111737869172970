import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (dataService, modelValue, $uibModalInstance, helperService, myDownloadsDataService) {

    const vm = this;

    vm.addUserToList = addUserToList;
    vm.deleteSelectedUser = deleteSelectedUser;
    vm.dismiss = dismiss;
    vm.submitSelectedUsers = submitSelectedUsers;

    vm.exportId = modelValue.Id;
    vm.ownerId = modelValue.OwnerId;
    vm.selectedUserIds = [];

    async function activate() {
        await getUsers();
        await getCurrentSharedUsers();

        vm.disableSave = true;

        vm.selectedUsersList.forEach(function (selectedUser) {
            vm.availableUsersList = vm.availableUsersList.filter(user => user.UserID !== selectedUser.SharedUserId);
        }); 

        vm.availableUsersCopy = [...vm.availableUsersList];

    }

    activate();

    async function getUsers() {
        let { data } = await dataService.getUsers();

        vm.availableUsersList = data.filter(user => user.Active && user.UserID !== vm.ownerId);

    }

    async function getCurrentSharedUsers() {
        vm.pleaseWait = new createSpinner();
        vm.pleaseWait.loadingValue = true;
        try {
            let { data } = await myDownloadsDataService.getCurrentSharedUsers(vm.exportId);
            vm.selectedUsersList = data.length > 0 ? data : [];
            vm.loadedUsers = [...vm.selectedUsersList];
            vm.pleaseWait.loadingValue = false;
        } catch {
            vm.pleaseWait.loadingValue = false;
        }
    }

    function addUserToList(user) {
        vm.disableSave = false;
        vm.availableUsersList.forEach(function (row, i) {
            if (row.UserID === user.UserID) {
                vm.availableUsersList.splice(i, 1);
                vm.selectedUsersList.push(
                    {
                        ExportId: vm.exportId,
                        SharedUserId: user.UserID,
                        SharedUserName: user.UserLogonName
                    }
                );             
            }         
        });
    }

    function deleteSelectedUser(selectedUser) {
        vm.disableSave = false;
        vm.selectedUsersList.forEach(function (row, i) {
            if (row.SharedUserId === selectedUser.SharedUserId) {
                vm.selectedUsersList.splice(i, 1);
                vm.current = row;
                vm.availableUsersList.push(
                    {
                        UserID: row.SharedUserId,
                        UserLogonName: row.SharedUserName
                    }
                );
            }
        });
    }

    async function submitSelectedUsers() {
        vm.updateInProgress = new createSpinner();
        vm.selectedUsersList.forEach(function (user) {
            vm.selectedUserIds.push(user.SharedUserId);
        });

        let postData = {
            ExportId: vm.exportId,
            UserIds: vm.selectedUserIds
        };

        try {
            await myDownloadsDataService.updateSharedUsers(postData, vm.exportId);
            await helperService.successfulSaveButton(vm.updateInProgress);
            cancel();
        } catch {
            vm.updateInProgress.loadingValue = false;
        }
    }

    function cancel() {
        $uibModalInstance.close();
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }
}