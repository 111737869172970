import routingCriteriaInstanceModalController from "./routingCriteriaInstanceModal.controller";

export default /*@ngInject*/ function (reviewSchedulesDataService, ownershipGroupsDataService, $uibModal) {
    const vm = this;

    vm.openRoutingCriteriaInstanceModal = openRoutingCriteriaInstanceModal;
    vm.removeRoutingCriteria = removeRoutingCriteria;
    vm.$onChanges = activate;
    vm.$onInit = activate;
    
    async function activate() {
        vm.ownershipGroupsList = await getOwnershipGroups();
        setRoutingCriteriaInstances(vm.ownershipGroup?.Id); 
    }

    async function setRoutingCriteriaInstances(id) {
        vm.loadingAssignments = true;

        let response = await reviewSchedulesDataService.getRoutingCriteria(id);
        vm.routingCriteriaList = response.data.reduce((routingCriteriaArray, currentCriteria) => {
            const routingCriteria = {
                Id: currentCriteria.Id,
                ReviewAssignmentId: currentCriteria.ReviewAssignmentId,
                RoutingCriteriaOwnershipGroupId: currentCriteria.RoutingCriteriaOwnershipGroupId,
                RoutingCriteriaOwnershipGroupName: vm.ownershipGroupsList
                                                   .find(ownershipGroup => ownershipGroup.Id === currentCriteria.RoutingCriteriaOwnershipGroupId)
                                                   .Name
            }
            routingCriteriaArray.push(routingCriteria);
            return routingCriteriaArray;
        }, []);

        vm.loadingAssignments = false;
    }

    async function openRoutingCriteriaInstanceModal(routingCriteriaInstance) {

        let routingCriteriaInstanceModal = $uibModal.open({
            templateUrl: '/App/Components/Certifications/reviewSchedules/routingCriteriaInstancesList/routingCriteriaInstanceModal.tmpl.html',
            controller: routingCriteriaInstanceModalController,
            controllerAs: 'rcimvm',
            backdrop: 'static',
            resolve: {
                ownershipGroupName: () => routingCriteriaInstance.RoutingCriteriaOwnershipGroupName,
                routingCriteriaData: () => routingCriteriaInstance
            }
        });

        try {
            await routingCriteriaInstanceModal.result;
        } catch (dismiss) { return; }
    }

    async function getOwnershipGroups() {
        let { data } = await ownershipGroupsDataService.getOwnershipGroups();
        return data.filter(group => group.TypeName === 'Company');
    }

    async function removeRoutingCriteria(item) {
        await reviewSchedulesDataService.deleteRoutingCriteria(item.reviewAssignmentId, item.Id);
        await setRoutingCriteriaInstances(vm.ownershipGroup.Id);
    }

}