import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($filter, $uibModalInstance, dataService, roleTemplatesData, maintainSystemRolesDataService, helperService, errorsDataService, template) {

    const vm = this;

    vm.$onInit = getProducts;

    vm.addRole = addRole;
    vm.productDropdownChanged = productDropdownChanged;
    vm.cancel = cancel;

    const availableRolesGridColumns = [
        { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
        { title: "Id", field: "ID", filterable: customAutoCompleteFilter },
        { title: "Description", field: "Description", filterable: customAutoCompleteFilter },
        { template: "<a ng-disabled='rmvm.addingRole' ng-click='rmvm.addRole(dataItem)'><i class='fa fa-plus pull-right text-success'></i></a>", title: "", width: 20, filterable: false }
    ];

    vm.availableRolesGridOptions = helperService.setMainGridOptions(availableRolesGridColumns, null);
    vm.availableRolesGridOptions.selectable = false;

    async function getProducts() {
        try {
            let response = await dataService.getProducts();
            vm.availableProducts = response.data;
            if (response.data.length === 1) autoSelectSingleProduct();
        } catch (error) {
            errorsDataService.catch(error);
        }
    }

    function autoSelectSingleProduct() {
        vm.selectedProduct = vm.availableProducts[0];
        productDropdownChanged();
    }

    function createAvailableRolesDataSource() {
        vm.availableRolesGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    try {
                        let response = await maintainSystemRolesDataService.getSystemRolesNonPaged();
                        response.data = response.data.filter(role => role.ProductID === vm.selectedProduct.ID);
                        response.data = $filter('orderBy')(response.data, 'Name');
                        if (template.Roles && template.Roles.length > 0) {
                            template.Roles.forEach(tr => {
                                response.data.forEach(r => {
                                    if (tr.Identifier === r.Identifier && tr.ProductName === r.ProductName && tr.TypeId === r.TypeID) {
                                        response.data.remove(r);
                                    }
                                });
                            });
                        }
                        vm.availableRoles = response.data;
                        options.success(vm.availableRoles);
                    } catch (error) {
                        options.error(error);
                    }
                }
            }
        });
    }

    function productDropdownChanged() {
        if (vm.availableRolesGridDataSource) {
            vm.availableRolesGridDataSource.read();
        } else {
            createAvailableRolesDataSource();
        }
    }

    async function addRole(role) {
        vm.addingRole = true;

        let postData = {
            RoleTemplateId: template.Id,
            SecurityRoleIdentifier: role.Identifier,
        };

        try {
            await roleTemplatesData.addRole(template.Id, postData);
            let addedRole = {
                    Identifier: role.Identifier,
                    Name: role.Name,
                    ProductName: role.ProductName,
                    TypeId: role.TypeID
            };
            template.Roles.push(addedRole);
            template.Roles = $filter('orderBy')(template.Roles, 'Name');
            await removeRoleFromGrid(role);
            vm.addingRole = false;
        } catch (error) {
            vm.addingRole = false;
            return;
        }

    }

    function removeRoleFromGrid(role) {
        let addedRole = vm.availableRolesGridDataSource._data.find(r => r.ID === role.ID && r.ProductID === role.ProductID && r.TypeID === role.TypeID);
        vm.availableRolesGridDataSource._data.remove(addedRole);
    }

    function cancel() {
        $uibModalInstance.close();
    }

}