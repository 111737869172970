export default /*@ngInject*/ function ($http, errorsDataService) {
    const factory = {
        getGroupOwners,
        getAllOwners,
        getAvailableOwners,
        getAvailableOwnersForItem,
        getGroupItemOwners,
        addOwnerToGroup,
        removeOwnerFromOwnershipGroup   
    };

    return factory;

    async function getGroupOwners(groupId, type) {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/${groupId}/type/${type}/owners`);
        } catch (err) {
            showError(err);
        }
    }

    async function getAllOwners() {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/allowners`);
        } catch (err) {
            showError(err);
        }
    }

    async function getAvailableOwners(groupId, type) {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/${groupId}/type/${type}/availableowners`);
        } catch (err) {
            showError(err);
        }
    }

    async function getAvailableOwnersForItem(groupId, groupItemId, type) {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/${groupId}/type/${type}/items/${groupItemId}/availableowners`);
        } catch (err) {
            showError(err);
        }
    }

    async function getGroupItemOwners(ownershipGroup, groupItem) {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/${ownershipGroup.Id}/type/${ownershipGroup.GroupType}/items/${groupItem.Id}/owners`);
        } catch (err) {
            showError(err);
        }
    }

    async function addOwnerToGroup(groupId, type, data) {
        try {
            return await $http.post(`${apiUrl}api/universal/ownershipgroups/${groupId}/type/${type}/owner`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function removeOwnerFromOwnershipGroup(groupId, type, ownerId) {
        try {
            return await $http.delete(`${apiUrl}api/universal/ownershipgroups/${groupId}/type/${type}/owners/${ownerId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(error) {
        errorsDataService.catch(error);
    }

    function sendError(error) {
        throw error;
    }

}