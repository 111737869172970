import approvalGroupsController from "./approvalGroups.controller";

/* approvalGroups.component.js */

/**
 * @desc component for viewing and manage approval groups
 * @example <approval-groups></approval-groups>
 */

const approvalGroups = {
    controller: approvalGroupsController,
    controllerAs: 'agvm',
    templateUrl: '/App/Components/ApprovalGroups/approvalGroups.tmpl.html'
};

export default approvalGroups;