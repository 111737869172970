// Intercepting HTTP calls with AngularJS.

export default /*@ngInject*/ function ($provide, $httpProvider) {

    // Intercept http calls.
    $provide.factory('APIInterceptor', function ($q) {

        var tenantID = null;
        var value = localStorage.getItem("selectedTenant");
        if (value != null) {

            var selectedTenantAsObject = angular.fromJson(localStorage.getItem("selectedTenant"));
            tenantID = selectedTenantAsObject.ID;

        }

        //var tenantID = localStorageService.get('localStorageTenant')
        return {
            // On request success
            request: function (config) {
                // console.log(config); // Contains the data about the request before it is sent.
                if (tenantID != null) {
                    config.headers['TenantId'] = tenantID;

                }
                // Return the config or wrap it in a promise if blank.

                return config || $q.when(config);
            },

            // On request failure
            requestError: function (rejection) {
                // console.log(rejection); // Contains the data about the error on the request.

                // Return the promise rejection.
                return $q.reject(rejection);
            }
        };
    });

    // Add the interceptor to the $httpProvider.
    $httpProvider.interceptors.push('APIInterceptor');

}