export default /*@ngInject*/ function ($scope) {

    $scope.addRoleToList = addRoleToList;
    $scope.deleteSelectedRole = deleteSelectedRole;
    $scope.setSelectedRoleTemplates = setSelectedRoleTemplates;

    function addRoleToList(role) {
        $scope.vm.ListOfAvailableD365SRoles.forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfAvailableD365SRoles.splice(i, 1);
                $scope.vm.ListOfSelectedD365SRoles.push(row);
            }
        });
        $scope.vm.IsRisksReviewed = null;
        validateNumberOfD365SRoles();
    }

    function deleteSelectedRole(role) {
        $scope.vm.ListOfSelectedD365SRoles.forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfSelectedD365SRoles.splice(i, 1);
                if (role.IsAssignable) {
                    $scope.vm.ListOfAvailableD365SRoles.push(row);
                }
            }
        });
        $scope.vm.IsRisksReviewed = null;
        validateNumberOfD365SRoles();
    }

    function validateNumberOfD365SRoles() {
        $scope.vm.numberOfD365SRolesValid = null;
        if ($scope.vm.ListOfSelectedD365SRoles.length > 0) {
            $scope.vm.numberOfD365SRolesValid = true;
        }
    }

    function setSelectedRoleTemplates(selectedRoleTemplates) {
        $scope.vm.selectedRoleTemplates = selectedRoleTemplates;
    };
}