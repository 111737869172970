import groupModalController from "./groupModal.controller";

export default /*@ngInject*/ function ($uibModal, sapAuthFieldGroupsDataService) {
    const vm = this;

    vm.openManageGroupModal = openManageGroupModal;
    vm.deleteGroup = deleteGroup;

    vm.groupMenuOptions = [
        {
            text: 'Edit Group Info',
            click: function (modelValue) {
                vm.openManageGroupModal(modelValue.group, false);
            }
        },
        {
            text: 'Edit Field Values',
            click: function (modelValue) {
                vm.openManageGroupModal(modelValue.group, true);
            }
        },
        {
            text: '<span class="text-danger">Delete Group</span>',
            click: function (modelValue) {
                vm.deleteGroup(modelValue.group.Id);
            }
        }
    ];

    async function openManageGroupModal(group, isEditingValues) {
        let groupModal = $uibModal.open({
            templateUrl: '/App/Components/MaintainBusinessProcess/SAPAuthFieldGroups/groupModal.tmpl.html',
            controller: groupModalController,
            controllerAs: 'gmvm',
            backdrop: 'static',
            resolve: {
                group: () => group,
                isEditingValues: () => isEditingValues,
                objectId: () => vm.object.BusinessProcessObjectId
            }
        });

        try {
            await groupModal.result;
            vm.onGroupUpdated();
        } catch {
            return;
        }
    }

    async function deleteGroup(groupId) {
        try {
            await sapAuthFieldGroupsDataService.deleteGroup(groupId);
            vm.onGroupUpdated();
        } catch {
            return;
        }
    }
}