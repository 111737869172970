import angular from 'angular';
import identityManagerRequestManagementFactoriesModule from './identityManager.requestManagement.factories.module';
import adUserSearchLink from '../Components/RequestManagement/ADUserLink/aDUser.component';
import adUserLookup from '../Components/RequestManagement/ADUserLookup/aDUserLookup.component';
import azureAdUserSearchLink from '../Components/RequestManagement/AzureADUserLink/azureADUser.component';
import azureAdUserLookup from '../Components/RequestManagement/AzureADUserLookup/azureADUserLookup.component';
import existingUserExpansion from '../Components/RequestManagement/ExistingUserExpansion/existingUserExpansion.component'
import identityManagerRequestManagementServicesModule from './identityManager.requestManagement.services.module';
import AXRolesController from '../Components/RequestManagement/WizardSteps/AX2012/AXRolesController';
import AXUserInformationController from '../Components/RequestManagement/WizardSteps/AX2012/AXUserInformationController';
import AX7RolesController from '../Components/RequestManagement/WizardSteps/AX7/AXRolesController';
import AX7UserInformationController from '../Components/RequestManagement/WizardSteps/AX7/AXUserInformationController';
import D365SRolesController from '../Components/RequestManagement/WizardSteps/D365S/D365SRolesController';
import D365STeamsController from '../Components/RequestManagement/WizardSteps/D365S/D365STeamsController';
import D365SUserInformationController from '../Components/RequestManagement/WizardSteps/D365S/D365SUserInformationController';
import GPCompaniesController from '../Components/RequestManagement/WizardSteps/GP/GPCompaniesController';
import GPRolesController from '../Components/RequestManagement/WizardSteps/GP/GPRolesController';
import GPUserInformationController from '../Components/RequestManagement/WizardSteps/GP/GPUserInformationController';
import NAVPermissionSetsController from '../Components/RequestManagement/WizardSteps/NAV/NAVPermissionSetsController';
import NAVUserGroupsController from '../Components/RequestManagement/WizardSteps/NAV/NAVUserGroupsController';
import NAVUserInformationController from '../Components/RequestManagement/WizardSteps/NAV/NAVUserInformationController';
import NSDuplicateUserController from '../Components/RequestManagement/WizardSteps/NetSuite/NSDuplicateUserController';
import NSPermissionsController from '../Components/RequestManagement/WizardSteps/NetSuite/NSPermissionsController';
import NSRolesController from '../Components/RequestManagement/WizardSteps/NetSuite/NSRolesController';
import NSUserInformationController from '../Components/RequestManagement/WizardSteps/NetSuite/NSUserInformationController';
import ORResponsibilitiesController from '../Components/RequestManagement/WizardSteps/Oracle/ORResponsibilitiesController';
import ORRolesController from '../Components/RequestManagement/WizardSteps/Oracle/ORRoles.controller';
import ORUserInformationController from '../Components/RequestManagement/WizardSteps/Oracle/ORUserInformationController';
import ORCloudRolesController from '../Components/RequestManagement/WizardSteps/OracleCloud/ORCloudRolesController';
import ORCloudUserInformationController from '../Components/RequestManagement/WizardSteps/OracleCloud/ORCloudUserInformationController';
import emergencyAccessController from '../Components/RequestManagement/WizardSteps/SAP/emergencyAccess.controller';
import SAPRolesController from '../Components/RequestManagement/WizardSteps/SAP/SAPRolesController';
import SAPUserInformationController from '../Components/RequestManagement/WizardSteps/SAP/SAPUserInformationController';
import EffectiveDatesController from '../Components/RequestManagement/WizardSteps/Universal/EffectiveDatesController';
import RiskAnalysisController from '../Components/RequestManagement/WizardSteps/Universal/RiskAnalysisController';
import RequestManagementSearchExistingUsers from '../Components/RequestManagement/RequestManagementSearchExistingUsers';
import RequestManagementSelection from '../Components/RequestManagement/RequestManagementSelection';
import RequestManagementViewModelModify from '../Components/RequestManagement/RequestManagementViewModelModify';
import RequestManagementViewModelNew from '../Components/RequestManagement/RequestManagementViewModelNew';
import RequestManagementViewModelTerminate from '../Components/RequestManagement/RequestManagementViewModelTerminate';
import ax7PersonSearchLink from '../Components/RequestManagement/AX7Person/AX7Person.component';
import UPRolesController from '../Components/RequestManagement/WizardSteps/UniversalProduct/UPRolesController';
import UPUserInformationController from '../Components/RequestManagement/WizardSteps/UniversalProduct/UPUserInformationController';
import AADUserInformationController from '../Components/RequestManagement/WizardSteps/AAD/AADUserInformationController';
import AADRolesController from '../Components/RequestManagement/WizardSteps/AAD/AADRolesController';
import AADGroupsController from '../Components/RequestManagement/WizardSteps/AAD/AADGroupsController';
import AADLicensesController from '../Components/RequestManagement/WizardSteps/AAD/AADLicensesController';
import PSUserInformationController from '../Components/RequestManagement/WizardSteps/PeopleSoft/PSUserInformationController';
import PSRolesController from '../Components/RequestManagement/WizardSteps/PeopleSoft/PSRolesController';
import AX7UserGroupsController from '../Components/RequestManagement/WizardSteps/AX7/AXUserGroupsController';
import AXUserGroupsController from '../Components/RequestManagement/WizardSteps/AX2012/AXUserGroupsController';
import SAPCompositeRolesController from '../Components/RequestManagement/WizardSteps/SAP/SAPCompositeRolesController';
import salesforceUserFormController from '../Components/RequestManagement/WizardSteps/Salesforce/salesforceUserForm.controller';
import salesforceProfileFormController from '../Components/RequestManagement/WizardSteps/Salesforce/salesforceProfileForm.controller';
import salesforcePermissionSetsController from '../Components/RequestManagement/WizardSteps/Salesforce/salesforcePermissionSets.controller';

export default angular
    .module('app.identityManager.requestManagement', [identityManagerRequestManagementFactoriesModule, identityManagerRequestManagementServicesModule])
    .component('adUserSearchLink', adUserSearchLink)
    .component('adUserLookup', adUserLookup)
    .component('azureAdUserSearchLink', azureAdUserSearchLink)
    .component('azureAdUserLookup', azureAdUserLookup)
    .component('ax7PersonSearchLink', ax7PersonSearchLink)
    .component('existingUserExpansion', existingUserExpansion)
    .controller('AADUserInformationController', AADUserInformationController)
    .controller('AADRolesController', AADRolesController)
    .controller('AADGroupsController', AADGroupsController)
    .controller('AADLicensesController', AADLicensesController)
    .controller('UPRolesController', UPRolesController)
    .controller('UPUserInformationController', UPUserInformationController)
    .controller('AX2012RolesController', AXRolesController)
    .controller('AX2012UserInformationController', AXUserInformationController)
    .controller('AX2012UserGroupsController', AXUserGroupsController)
    .controller('AX7RolesController', AX7RolesController)
    .controller('AX7UserGroupsController', AX7UserGroupsController)
    .controller('AX7UserInformationController', AX7UserInformationController)
    .controller('D365SRolesController', D365SRolesController)
    .controller('D365STeamsController', D365STeamsController)
    .controller('D365SUserInformationController', D365SUserInformationController)
    .controller('GPCompaniesController', GPCompaniesController)
    .controller('GPRolesController', GPRolesController)
    .controller('GPUserInformationController', GPUserInformationController)
    .controller('NAVPermissionSetsController', NAVPermissionSetsController)
    .controller('NAVUserGroupsController', NAVUserGroupsController)
    .controller('NAVUserInformationController', NAVUserInformationController)
    .controller('NSDuplicateUserController', NSDuplicateUserController)
    .controller('NSPermissionsController', NSPermissionsController)
    .controller('NSRolesController', NSRolesController)
    .controller('NSUserInformationController', NSUserInformationController)
    .controller('ORResponsibilitiesController', ORResponsibilitiesController)
    .controller('ORRolesController', ORRolesController)
    .controller('ORUserInformationController', ORUserInformationController)
    .controller('ORCloudRolesController', ORCloudRolesController)
    .controller('ORCloudUserInformationController', ORCloudUserInformationController)
    .controller('EmergencyAccessUsageController', emergencyAccessController)
    .controller('SAPRolesController', SAPRolesController)
    .controller('SAPCompositeRolesController', SAPCompositeRolesController)
    .controller('SAPUserInformationController', SAPUserInformationController)
    .controller('salesforceUserFormController', salesforceUserFormController)
    .controller('salesforceProfileFormController', salesforceProfileFormController)
    .controller('salesforcePermissionSetsController', salesforcePermissionSetsController)
    .controller('EffectiveDatesController', EffectiveDatesController)
    .controller('RiskAnalysisController', RiskAnalysisController)
    .controller("RequestManagementSearchExistingUsers", RequestManagementSearchExistingUsers)
    .controller("requestManagementSelection", RequestManagementSelection)
    .controller("requestManagementModifyViewModel", RequestManagementViewModelModify)
    .controller("requestManagementNewViewModel", RequestManagementViewModelNew)
    .controller("requestManagementTerminateViewModel", RequestManagementViewModelTerminate)
    .controller('PSUserInformationController', PSUserInformationController)
    .controller('PSRolesController', PSRolesController)
    .name;