import swal from "sweetalert2";
import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($rootScope, $filter, helperService, connectionsDataService) {
    const vm = this,
        navObjects = JSON.parse(localStorage.getItem("NavigationObjects"));
    let baseUrl = "api/universal/";

    vm.$onInit = init;
    vm.$onChanges = onChanges;

    vm.importProduct = importProduct;
    vm.importOracle = importOracle;
    vm.importType = "Metadata";
    vm.showSapImportType = navObjects.some(navObject => navObject.ModuleID === 14000);
    vm.sapImportType = "SecurityOnly";
    vm.getNsRoles = getNsRoles;
    vm.nsImportAllRoles = true;

    function init() {
        setProductSpecificDropdowns(vm.product.ID);
    }

    function onChanges(changes) {
        if (changes['nsRolesNeedsUpdate']?.currentValue && !nsImportAllRoles) {
            getNsRoles();
            const configChanged = false;
            vm.onNsRolesUpdate({ configChanged });
        }
    }

    async function setProductSpecificDropdowns(productId) {
        let response;

        if (productId === "OREPM") {
            try {
                response = await connectionsDataService.getOracleEpmServiceTypes();
                vm.serviceTypeDropdownDataSource = $filter("orderBy")(response.data, "displayName");
                vm.serviceTypeDropdownDataSource.unshift({ "_displayName": "-- Select a Service Type --", "_value": -1 });
                vm.selectedServiceType = vm.serviceTypeDropdownDataSource[0];
            } catch {
                // Error handled in data service.
            }
        }
    }

    async function getNsRoles() {
        const productId = vm.product.ID;
        let response;

        if ($rootScope.UserSecurityList["16300"]) {
            try {
                vm.rolesDropdownIsLoading = true;
                response = await connectionsDataService.getImportRoles(productId);
                vm.nsRoleDataSource = response.data;
                vm.nsSelectedItems = [];
                vm.rolesDropdownIsLoading = false;
            } catch {
                vm.rolesDropdownIsLoading = false;
            }
        }
    }

    async function importProduct(importType, files) {
        const securityImportTypes = ["api", "sql", "permission", "security"],
            isFileImport = checkFileImport(importType, files),
            isSecurityImport = securityImportTypes.includes(importType);

        try {
            var message = 'Are you sure you want to proceed? This will replace all currently imported security data in Fastpath Assure.';

            if (vm.product.ID === 'CPA') {
                message = 'Are you sure you want to proceed? This will replace all currently imported user-role security data in Fastpath Assure, but it will not affect permissions data.';
            }

            await swal(helperService.areYouSureParams(
                `Import ${vm.product.Name} Security`,
                `${message}`,
                `Import`
            ));

            if (isSecurityImport) {
                await importSecurity(importType);
            } else if (isFileImport) {
                vm.importType = importType;
                await importFromFileUpload(getFormData(files));
            }
        } catch {
            // Error handled in data service.
        }
    }

    async function importOracle(transactionMonitoring) {
        let url = apiUrl,
            type = "Security";

        if (transactionMonitoring) {
            type = "Transaction Monitoring";
            url = url + "api/transactionmonitoring/or/import";
        } else {
            url = url + "api/universal/or/import/sql";
        }

        try {
            await swal(helperService.areYouSureParams(
                `Import ${vm.product.Name} ${type}`,
                `Are you sure you want to proceed? This will replace all currently imported security data in Fastpath Assure.`,
                `Import`
            ));

            vm.showLoading = new createSpinner();

            await connectionsDataService.importSecurity(url, {});
            vm.showLoading.loadingValue = false;

            vm.setRadioSelected();
            vm.importFlow.cancel();
        } catch (err) {
            vm.showLoading.loadingValue = false;
        }
    }

    async function importSecurity(type) {
        let body = getRequestBodyByProductId(vm.product.ID),
            url = apiUrl + baseUrl + vm.product.ID + "/import/";

        vm.showLoading = new createSpinner();
        if (vm.product.ID === "NS") {
            url = url + (vm.nsImportAllRoles ? type : "/api/role");

            body = vm.nsImportAllRoles ? {} : { RoleIds: vm.nsSelectedItems.map(item => item.ID) };
        } else if (vm.product.Type === "SAP") {
            url = apiUrl + baseUrl + vm.product.Type + "/import/" + type;
        } else {
            url = url + type;
        }

        try {
            await connectionsDataService.importSecurity(url, body);
            vm.showLoading.loadingValue = false;

            vm.setRadioSelected();
            vm.importFlow.cancel();

            if (vm.product.ID === "NS") {
                vm.permissionsFlow.cancel();
            } else if (vm.product.Type === "SAP") {
                vm.rawFlow.cancel();
            }
        } catch (err) {
            vm.showLoading.loadingValue = false;
        }
    }

    async function importFromFileUpload(formData) {
        let urlId = vm.product.ID;

        vm.showLoading = new createSpinner();

        if (vm.product.Type === "SAP") {
            urlId = vm.product.Type;
            formData.append("ProductId", vm.product.ID);
        }

        try {
            await connectionsDataService.importFromFileUpload(apiUrl + baseUrl + urlId + "/import/" + vm.importType, formData);
            vm.showLoading.loadingValue = false;

            vm.setRadioSelected();
            vm.importFlow.cancel();

            if (vm.product.ID === "NS") {
                vm.permissionsFlow.cancel();
            } else if (vm.product.Type === "SAP") {
                vm.rawFlow.cancel();
            }
        } catch (err) {
            vm.showLoading.loadingValue = false;
        }
    }

    function getRequestBodyByProductId() {
        let data = {};

        if (vm.product.ID === "AX7" || vm.product.ID === "D365S") {
            data = {
                "ImportType": vm.importType,
                "RoleList": []
            };
        } else if (vm.product.Type === "SAP") {
            data = {
                ProductId: vm.product.ID,
                SdImportType: vm.sapImportType
            }
        }

        return data;
    }

    function getFormData(files) {
        let formData = new FormData(),
            file = files.first().file,
            oracleServiceTypeId;

        if (vm.product.ID === "OREPM") {
            oracleServiceTypeId = vm.selectedServiceType._value;
            formData.append("OrEpmServiceId", oracleServiceTypeId);
        } else if (vm.product.isCustom) {
            formData.append("ProductId", vm.product.ID);
        }

        if (vm.product.isCustom && file.type === "application/x-zip-compressed") {
            vm.importType = "csv"
            formData.append("File", file);
        } else if (vm.product.isCustom) {
            vm.importType = "excel"
            formData.append("ExcelImport", file);
        } else {
            formData.append(file.name, file);
        }

        return formData;
    }
}

function checkFileImport(importType, files) {
    const types = ["", "file", "files", "raw-files", "metadata", "permissionusage"];

    return types.includes(importType) && files && files.length;
}