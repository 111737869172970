/* userProductProfile.component.js */

import userProductProfileController from "./userProductProfile.controller";

/**
 * @desc component for viewing user profile information for a specific product
 * @example <user-product-profile></user-product-profile>
 */

const userProductProfile = {
    bindings: {
        product: '<',
        workDayProfile: '<'
    },
    controller: userProductProfileController,
    controllerAs: 'uppvm',
    templateUrl: '/App/Shared/Components/userProfile/userProductProfile/userProductProfile.tmpl.html'
};

export default userProductProfile;