export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getProducts,
        getLatestProductImports,
        getHybridConnections,
        getConnectionSql,
        getProductSchedule,
        getProductSql,
        getSapProduct,
        getImportRoles,
        getOracleEpmServiceTypes,
        getSfPermissionConfig,
        getSfPermissionConfigByObject,
        importSecurity,
        importFromFileUpload,
        createNewProduct,
        updateProductName,
        updateProductSchedule,
        disableProductSchedule,
        updateConfig,
        updateSqlConfig,
        createSqlConfig,
        validateNsConfig,
        updateSapProduct,
        createSapProduct,
        createSfFieldPermissionConfig,
        removeSfFieldPermissionConfig
    };

    return service;

    //GET Requests
    async function getProducts() {
        try {
            return $http.get(apiUrl + 'api/universal/products');
        } catch (err) {
            showError(err);
        }
    }

    async function getLatestProductImports() {
        return $http.get(apiUrl + 'api/universal/imports/products/latest');
    }

    async function getHybridConnections() {
        return $http.get(apiUrl + 'api/core/hybridconnections');
    }

    async function getConnectionSql(productId) {
        return $http.get(apiUrl + 'api/universal/products/' + productId + '/connections/sql');
    }

    async function getProductSchedule(productId) {
        return $http.get(apiUrl + 'api/universal/imports/schedule/products/' + productId);
    }

    async function getProductSql(productId) {
        return $http.get(apiUrl + 'api/universal/products/' + productId + '/connections/sql');
    }

    async function getSapProduct(productId) {
        return $http.get(apiUrl + 'api/universal/products/' + productId + '/connections/saprfc');
    }

    async function getImportRoles(productId) {
        let response;

        try {
            response = await $http.get(apiUrl + 'api/universal/' + productId + '/import/api/role');
        } catch (err) {
            showError({
                data: "Unable to get import roles."
            });
            sendError(err);
        }

        return response;
    }

    async function getOracleEpmServiceTypes() {
        let response;

        try {
            response = await await $http.get(apiUrl + 'api/assure/orepm/services');
        } catch (err) {
            showError({
                data: "Unable to get service types"
            });
            sendError(err);
        }

        return response;
    }

    async function getSfPermissionConfig() {
        return $http.get(apiUrl + 'api/universal/sf/import/fieldpermissionconfig');
    }

    async function getSfPermissionConfigByObject(name) {
        return $http.get(apiUrl + 'api/universal/sf/import/fieldpermissionconfig/' + name + '/fields');
    }

    async function importSecurity(url, data) {
        try {
            await $http.put(url, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function importFromFileUpload(url, data) {
        try {
            await $http.put(url, data, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function createNewProduct(data) {
        let response;

        try {
            response = await $http({
                method: 'POST',
                url: apiUrl + 'api/universal/products',
                data: data
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }

        return response;
    }

    async function updateProductName(product) {
        let response;

        try {
            response = await $http({
                method: 'PUT',
                url: apiUrl + 'api/universal/products/' + product.ID,
                data: {
                    ProductId: product.ID,
                    Name: product.Name
                }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }

        return response;
    }

    async function updateProductSchedule(productId, data) {
        let response;

        try {
            response = await $http.put(apiUrl + 'api/universal/imports/schedule/products/' + productId, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }

        return response;
    }

    async function createSapProduct(productId, data) {
        try {
            await $http.post(apiUrl + 'api/universal/products/' + productId + '/connections/saprfc', data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function validateNsConfig(data) {
        try {
            await $http.put(apiUrl + 'api/core/ns/configuration/validate', data)
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateSapProduct(productId, data) {
        try {
            await $http.put(apiUrl + 'api/universal/products/' + productId + '/connections/saprfc', data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function disableProductSchedule(productId, data) {
        let response;

        try {
            response = await $http.put(apiUrl + 'api/universal/imports/schedule/products/' + productId + "/disable", data);
        } catch (err) {
            showError(err);
            sendError(err);
        }

        return response;
    }

    async function updateConfig(data) {
        let response;
        try {
            response = $http.put(apiUrl + 'api/core/configuration', data);
        } catch (err) {
            showError(err);
            sendError(err);
        }

        return response;
    }

    async function updateSqlConfig(productId, data) {
        let response;

        try {
            response = await $http.put(apiUrl + 'api/universal/products/' + productId + '/connections/sql', data);
        } catch (err) {
            showError(err);
            sendError(err);
        }

        return response;
    }

    async function createSqlConfig(productId, data) {
        let response;

        try {
            response = await $http.post(apiUrl + 'api/universal/products/' + productId + '/connections/sql', data);
        } catch (err) {
            showError(err);
            sendError(err);
        }

        return response;
    }

    async function createSfFieldPermissionConfig(data) {
        let response;

        try {
            response = await $http.post(apiUrl + 'api/universal/sf/import/fieldpermissionconfig', data);
        } catch (err) {
            showError(err);
        }

        return response;
    }

    async function removeSfFieldPermissionConfig(fieldId) {
        try {
            await $http.delete(apiUrl + 'api/universal/sf/import/fieldpermissionconfig/' + fieldId);
        } catch (err) {
            showError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
       errorsDataService.catch(err);
    }
}