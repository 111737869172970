export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getSystemRoles,
        getSystemRolesNonPaged,
        exportSystemRoles,
        getEmergencyAccessApproversByRole,
        addUserAsApprover,
        addEmergencyAccessSystemRole,
        updateSystemRole,
        addEmergencyAccessApprover,
        importSystemRoles,
        removeEmergencyAccessApprover
    };

    return service;

    // GET REQUESTS
    async function getEmergencyAccessApproversByRole(roleIdentifier) {
        try {
            return await $http.get(`${apiUrl}api/universal/emergencyaccessapprovers/role/${roleIdentifier}`);
        } catch (err) {
            showError(err);
        }
    }

    async function getSystemRolesNonPaged() {
        try {
            return await $http.get(`${apiUrl}api/universal/securityroles`);
        } catch (err) {
            showError(err);
        }
    }

    async function exportSystemRoles() {
        try {
            return await $http.get(`${apiUrl}api/universal/securityroles/properties/export`, { responseType: "blob" });
        } catch (err) {
            showError(err);
        }
    }

    //POST REQUESTS
    async function getSystemRoles(data, params) {
        try {
            return await $http.post(`${apiUrl}api/universal/securityroles`, data, { params: params });
        } catch (err) {
            showError(err);
        }
    }

    async function addUserAsApprover(data) {
        try {
            return await $http.post(`${apiUrl}api/universal/emergencyaccessapprovers`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addEmergencyAccessSystemRole(data) {
        try {
            return await $http.post(`${apiUrl}api/universal/securityroles/emergencyaccess`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    // PUT REQUESTS
    async function updateSystemRole(data) {
        try {
            return await $http.put(`${apiUrl}api/universal/securityroles/properties`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addEmergencyAccessApprover(data) {
        try {
            return await $http.put(`${apiUrl}api/universal/emergencyaccessapprovers`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function importSystemRoles(fd) {
        try {
            return await $http.put(`${apiUrl}api/universal/securityroles/properties/import`, fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    // DELETE REQUESTS
    async function removeEmergencyAccessApprover(emergencyAccessApproverId) {
        try {
            return await $http.delete(`${apiUrl}api/universal/emergencyaccessapprovers/${emergencyAccessApproverId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(error) {
        errorsDataService.catch(error);
    }

    function sendError(error) {
        throw error;
    }
}