import swal from "sweetalert2";
import createSpinner from "../../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import maintainProfilesModalController from "../maintainProfilesModal/maintainProfilesModal.controller";

export default /*@ngInject*/ function ($filter, $uibModal, maintainProfilesDataService, helperService, FileSaver) {

    const vm = this;

    vm.$onInit = createMaintainProfilesGrid();

    vm.openMaintainProfilesModal = openMaintainProfilesModal;
    vm.deleteProfile = deleteProfile;
    vm.exportProfiles = exportProfiles;

    function createMaintainProfilesGrid() {
        let maintainProfilesGridColumns =
            [
                { field: "ProfileName", title: "Name", filterable: customAutoCompleteFilter },
                { field: "ProductName", title: "Product", filterable: customAutoCompleteFilter },
                { template: `<a ng-click="mpgvm.deleteProfile(dataItem)"><i class="glyphicon glyphicon-trash text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 90 }

            ];

        vm.maintainProfilesGridOptions = helperService.setMainGridOptions(maintainProfilesGridColumns, 600);
        vm.maintainProfilesGridOptions.selectable = false;

        vm.maintainProfilesGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let { data } = await maintainProfilesDataService.getSensitiveProfiles();
                    data = $filter('orderBy')(data, 'ProfileName');
                    vm.existingProfiles = data;
                    options.success(data);
                }
            }
        });
    }

    async function exportProfiles() {
        vm.exportUpdatePending = new createSpinner();

        try {
            let { data } = await maintainProfilesDataService.exportProfiles();
            await helperService.successfulSaveButton(vm.exportUpdatePending);
            FileSaver.saveAs(data, 'Profiles.xlsx');
        } catch {
            vm.exportUpdatePending.loadingValue = false;
        }
    }

    async function deleteProfile({ ProfileName, Id }) {
        try {
            await swal(helperService.areYouSureParamsWithHTML('Delete Profile',
                `<div>Are you sure you want to delete profile
                <div><strong>${ProfileName}</strong>?</div>
            </div>`, 'Delete'));
            await maintainProfilesDataService.deleteProfile(Id);
            vm.profile = null;
            vm.maintainProfilesGridDataSource.read();
        } catch {
            return;
        }

    }

    async function openMaintainProfilesModal(isImport) {
        let profileModal = $uibModal.open({
            templateUrl: '/App/Components/maintainProfiles/maintainProfilesModal/maintainProfilesModal.tmpl.html',
            controller: maintainProfilesModalController,
            controllerAs: 'mpmvm',
            backdrop: 'static',
            resolve: {
                isImport: () => isImport,
                existingProfiles: () => vm.existingProfiles
            }
        });

        try {
            await profileModal.result;
            vm.maintainProfilesGridDataSource.read();
        } catch {
            return;
        }
    }

}