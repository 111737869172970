/* excludedUsers.component.js */

import excludedUsersController from "./excludedUsers.controller";

/**
 * @desc component to display view and Excluded Users
 * @example <excluded-users></excluded-users>
 */

const excludedUsers = {
    controller: excludedUsersController,
    controllerAs: 'euvm',
    templateUrl: '/App/Components/ExcludedUsers/excludedUsers.html'
};

export default excludedUsers;