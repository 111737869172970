export default /*@ngInject*/ function ($scope, existingUserCheckService, errorsDataService, configurationDataService, $http) {

    //General User
    $scope.vm.axUserFromDateObject = null;
    $scope.vm.axUserToDateObject = null;
    $scope.vm.axUserMaxDate = new Date();
    var axUserMinDate = new Date();
    axUserMinDate.setHours(0, 0, 0, 0);

    $scope.vm.selectADUser = selectADUser;

    $scope.vm.axUserMinDate = axUserMinDate;

    if (!$scope.vm.User.AX7User) {
        $scope.vm.User.AX7User = {};
    }
    $scope.vm.User.AX7User.ProductType = "AX7";

    activate();

    function activate() {
        if ($scope.vm.ListOfAX7Companies && $scope.vm.ListOfAX7Companies.length > 0) { 
            setAADFieldRestrictionConfig();
            if (!$scope.vm.User.AX7User.NetworkDomain) {
                getProvider();
            }

            createCompanyDropdown();
        }
    }

    async function setAADFieldRestrictionConfig() {
        try {
            const response = await configurationDataService.getProductConfigurationValueByName('ax7', 'IM_DisableAADUserFields');
            $scope.disableAADFields = response.data.Value === '1';
        } catch {
            return false;
        }
    }

    async function getProvider() {
        let response = await $http.get(`${apiUrl}api/universal/products/AX7/configuration/im_provider`);
        $scope.vm.User.AX7User.NetworkDomain = response.data.Value;
    }

    function createCompanyDropdown() {
        $scope.vm.axCompanyDropdownOptions = {
            valuePrimitive: true,
            template: `
                <span>{{dataItem.CompanyName}}</span> | <span>{{dataItem.CompanyId}}</span>
            `,
            filter: "contains",
            autoBind: true,
            minLength: 3,
            optionLabel: '-- Select Company --',
            filtering: item => {
                var filterValue = item.filter != undefined ? item.filter.value : "";
                item.preventDefault();

                item.sender.dataSource.filter({
                    logic: "or",
                    filters: [
                        {
                            field: "CompanyName",
                            operator: "contains",
                            value: filterValue
                        },
                        {
                            field: "CompanyId",
                            operator: "contains",
                            value: filterValue
                        }
                    ]
                });
            }
        };

        $scope.vm.axCompanyDropdownDataSource = new kendo.data.DataSource({
            transport: {
                read: options => {
                    // Only allow Legal Entity Companies to be assigned as Default Company
                    options.success($scope.vm.ListOfAX7Companies.filter(company => company.CompanyType === 'LegalEntity'));
                }
            }
        });
    }
    if ($scope.vm.wizardMode === 'New') {
        $scope.vm.User.AX7User.Enabled = true;
    }
    if (!$scope.vm.User.AX7User.PersonUser) {
        $scope.vm.User.AX7User.PersonUser = {};
    }

    $scope.axUserFromDateChanged = function () {
        var date;
        if ($scope.vm.User.AX7User.StartDate === '') {
            date = new Date();
        } else {
            date = new Date($scope.vm.User.AX7User.StartDate);
        }

        $scope.vm.axUserMinDate = date;
        $scope.vm.nsMinDate = date;
        $scope.vm.fromMinDate = date;
        if ($scope.vm.User.AX7User == null) {
            $scope.vm.User.AX7User = {};
        }
    };

    $scope.axUserToDateChanged = function () {
        $scope.vm.axUserMaxDate = new Date($scope.vm.User.AX7User.EndDate);
        $scope.vm.nsMaxDate = new Date($scope.vm.User.AX7User.EndDate);
        $scope.vm.fromMaxDate = new Date($scope.vm.User.AX7User.StartDate);
        if ($scope.vm.User.AX7User == null) {
            $scope.vm.User.AX7User = {};
        }
    };

    $scope.existingUserCheck = function (userId) {
        existingUserCheckService.checkAX7(userId)
            .then(response => {
                if (response.data === true) {
                    $scope.userExists = true;
                    $scope.wizardForm.AX7userId.$setValidity("AX7userId", false);
                }
                else {
                    $scope.userExists = false;
                    $scope.wizardForm.AX7userId.$setValidity("AX7userId", true);
                }
            })
            .catch(error => {
                errorsDataService.catch(error);
            });
    };

    $scope.$watch("vm.User.AX7User.UserId", () => {
        if ($scope.vm.User?.AX7User?.UserId && $scope.vm.wizardMode === 'New') {
            $scope.existingUserCheck($scope.vm.User.AX7User.UserId);
        }
    });

    $scope.$watch("vm.ListOfAX7Companies", () => {
        activate();
    });

    $scope.setPersonName = function (personObject) {
        $scope.vm.User.AX7User.PersonUser = {
            PartyNumber: personObject.PartyNumber,
            PersonNameAlias: personObject.PersonNameAlias,
            PersonName: personObject.PersonName,
            PersonPrimaryEmail: personObject.PersonPrimaryEmail
        };

        $scope.personSelected = true;
    }

    function selectADUser(user) {
        $scope.vm.User.AX7User = user;
        if (user.UserType !== 'Guest') {
            getProvider();
        }
    }

}