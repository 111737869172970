export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getSecurityMigrations,
        getSecurityMigrationDetails,
        exportMigration,
        addSecurityMigration,
        updateSecurityMigration,
        updateSecurityMigrationDetails,
        updateSecurityMigrationDetailEnabled,
        deleteSecurityMigration
    };

    return service;

    // GET Requests

    async function getSecurityMigrations() {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/ax7/securitymigration`);
        }
        catch (err) {
            showError(err);
        }
    }

    async function getSecurityMigrationDetails(migrationId) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/ax7/securitymigration/header/${migrationId}/details`);
        }
        catch (err) {
            showError(err);
        }
    }

    async function exportMigration(migrationId, exportType) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/ax7/securitymigration/${migrationId}/type/${exportType}/export`, { responseType: "blob" });
        }
        catch (err) {
            showError(err);
        }
    }


    // POST Requests

    async function addSecurityMigration(data) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/ax7/securitymigration`, data, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        }
        catch (err) {
            showError(err);
            sendError(err);
        }
    }

    // PUT Requests

    async function updateSecurityMigration(data) {
        try {
            return await $http.put(`${apiUrl}api/securitydesigner/ax7/securitymigration/${data.Id}`, data);
        }
        catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateSecurityMigrationDetails(headerId, data) {
        try {
            return await $http.put(`${apiUrl}api/securitydesigner/ax7/securitymigration/header/${headerId}/detail/${data.Id}`, data);
        }
        catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateSecurityMigrationDetailEnabled(data) {
        try {
            return await $http.put(`${apiUrl}api/securitydesigner/ax7/securitymigration/header/${data.HeaderId}/details`, data);
        }
        catch (err) {
            showError(err);
            sendError(err);
        }
    }

    // DELETE Requests

    async function deleteSecurityMigration(migrationId) {
        try {
            return await $http.delete(`${apiUrl}api/securitydesigner/ax7/securitymigration/${migrationId}`);
        } catch(err) {
            showError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }

}