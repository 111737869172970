export default /*@ngInject*/ function ($scope) {

    $scope.invalidEmergencyAccessEndDate = false;

    if ($scope.vm.alternatePath === "EmergencyAccess") {
        $scope.emergencyAccessPeriod = Number(JSON.parse(localStorage.getItem("ConfigurationValues")).MaxFirefighterAccessPeriod);
        $scope.maxEndDate = new Date();
        $scope.maxEndDate.setTime($scope.maxEndDate.getTime() + ($scope.emergencyAccessPeriod * 60 * 60 * 1000));
    }

    $scope.clearEffectiveDates = function (dataItem) {
        dataItem.StartDate = null;
        dataItem.EndDate = null;

        $scope.vm.fromDateString = '';
        $scope.vm.toDateString = '';

        $scope.setSelectedEffectiveDates(null, null, dataItem);
    };

    $scope.vm.fromDateString = '';
    $scope.vm.fromDateObject;
    $scope.vm.toDateString = '';
    $scope.vm.toDateObject;
    $scope.vm.maxDate = new Date();
    $scope.vm.minDate = new Date(2000, 0, 1, 0, 0, 0);
    $scope.vm.fromMinDate = Date();

    $scope.vm.nsMaxDate = new Date(2900, 0, 1, 0, 0, 0);
    $scope.vm.nsMinDate = new Date();

    $scope.fromDateChanged = function (access) {
        access.MinDate = access.StartDate;
        if ($scope.vm.alternatePath === 'EmergencyAccess') {
            $scope.maxEndDate = new Date(access.StartDate);
            $scope.maxEndDate.setTime($scope.maxEndDate.getTime() + ($scope.emergencyAccessPeriod * 60 * 60 * 1000));
        }
        $scope.rebindEffectiveDates = $scope.rebindEffectiveDates === 0 ? 1 : 0;
    };

    $scope.toDateChanged = function (access) {
        var date;

        if ($scope.isDate(access.EndDate)) {
            date = new Date(access.EndDate);
        } else {
            date = new Date();
            access.EndDate = null;
        }

        $scope.vm.fromMinDate = date;

        if ($scope.vm.alternatePath === 'EmergencyAccess') {
            if (access.EndDate > $scope.maxEndDate) {
                access.invalidEmergencyAccessEndDate = true;
            } else {
                access.invalidEmergencyAccessEndDate = false;
            }
        }
        $scope.rebindEffectiveDates = $scope.rebindEffectiveDates === 0 ? 1 : 0;
    };

    $scope.generalStartDateChanged = function (accessChanges, startDate) {
        accessChanges.forEach(access => access.StartDate = startDate);
    };

    $scope.generalEndDateChanged = function (accessChanges, endDate) {
        if ($scope.vm.alternatePath === 'EmergencyAccess') {
            if (endDate > $scope.maxEndDate) {
                $scope.invalidEmergencyAccessEndDate = true;
            } else {
                $scope.invalidEmergencyAccessEndDate = false;
            }
        }
        accessChanges.forEach(access => access.EndDate = endDate);
    };

    $scope.isDate = function (x) {
        return x instanceof Date;
    };

    $scope.validate = function (event) {
        event.preventDefault();

        if ($scope.vm.toDateObject < $scope.vm.fromDateObject) {
            $scope.validationMessage = 'End Date cannot come before Start Date';
        }
    };

    $scope.getUserStartDate = function (access) {
        var startDate;

        if (access && $scope.vm.wizardMode === 'New') {
            if (access.ProductId === 'NS') {
                startDate = $scope.vm.User.NetSuiteUser.StartDate;
            }
            if (access.ProductId === 'OR') {
                startDate = $scope.vm.User.OracleUser.StartDate;
            }
            if (access.ProductId === 'ORFC') {
                startDate = $scope.vm.User.OracleCloudUser.StartDate;
            }
            if (access.ProductId === 'AX7') {
                startDate = $scope.vm.User.AX7User.StartDate;
            }
            if (access.ProductId === 'AX') {
                startDate = $scope.vm.User.AX2012User.StartDate;
            }
            if (access.ProductId === 'PS') {
                startDate = $scope.vm.User.PSUser.StartDate;
            }
            if (access.ProductType === 'SAP') {
                const availableSAPApps = $scope.vm.availableApplications.filter(application => application.ProductType === 'SAP');
                availableSAPApps.forEach(app => startDate = $scope.vm.User.SAPUser[app.ProductName].StartDate);
            }
            if (access.ProductId === 'SF') {
                startDate = $scope.vm.User.SalesforceUser.StartDate;
            }
        }

        if (!startDate) {
            startDate = new Date();
        }

        if (access) {
            if (new Date(access.StartDate) > new Date(startDate)) {
                access.MinDate = access.StartDate;
            }
            else {
                access.MinDate = startDate;
            }
        }

        return startDate;
    };

    $scope.getUserEndDate = function (access) {

        if ($scope.invalidEmergencyAccessEndDate) {
            $scope.invalidEmergencyAccessEndDate = false;
        }
        var endDate;

        if ($scope.vm.alternatePath === 'EmergencyAccess' && !access.EndDate) {
            endDate = $scope.maxEndDate;
            access.EndDate = endDate;
        }

        if ($scope.vm.wizardMode === 'New') {
            if (access.ProductId === 'NS') {
                endDate = $scope.vm.User.NetSuiteUser.EndDate;
            }
            if (access.ProductId === 'OR') {
                endDate = $scope.vm.User.OracleUser.EndDate;
            }
            if (access.ProductId === 'ORFC') {
                endDate = $scope.vm.User.OracleCloudUser.EndDate;
            }
            if (access.ProductId === 'AX7') {
                endDate = $scope.vm.User.AX7User.EndDate;
            }
            if (access.ProductId === 'AX') {
                endDate = $scope.vm.User.AX2012User.EndDate;
            }
            if (access.ProductType === 'SAP') {
                $scope.vm.availableApplications.forEach(app => endDate = $scope.vm.User.SAPUser[app.ProductName].EndDate);
            }
            if (access.ProductId === 'SF') {
                endDate = $scope.vm.User.SalesforceUser.EndDate;
            }
        }

        if (!endDate) {
            endDate = new Date(2900, 0, 1, 0, 0, 0);
        }

        access.MaxDate = endDate;

        return endDate;
    };

    $scope.applyAllDatesChanged = function (accessChanges) {
        if ($scope.invalidEmergencyAccessEndDate) {
            $scope.invalidEmergencyAccessEndDate = false;
        }
        let endDate;

        if ($scope.vm.alternatePath === 'EmergencyAccess' && !$scope.generalEndDate && $scope.vm.applyAllDates) {
            endDate = $scope.maxEndDate;
            $scope.generalEndDate = endDate;
            accessChanges.forEach(access => access.EndDate = endDate);
        }
    };

    $scope.clearAllEffectiveDates = function (accessChanges) {
        accessChanges.forEach(access => access.StartDate = null);
        accessChanges.forEach(access => access.EndDate = null);
        $scope.generalStartDate = null;
        $scope.generalEndDate = null;
    };
}