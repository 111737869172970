import migrationsListController from "./migrationsList.controller";

/* axSecurityDesignerMigrationsList.component.js */

/**
 * @desc component for viewing review assignments
 * @example <ax-security-designer-migrations-list></ax-security-designer-migrations-list>
 */

const axSecurityDesignerMigrationsList = {
    bindings: {
        selectedMigrationChanged: '&'

    },
    controller: migrationsListController,
    controllerAs: 'mlvm',
    templateUrl: '/App/Components/AXSecurityDesigner/SecurityMigration/MigrationsList/migrationsList.html'
};

export default axSecurityDesignerMigrationsList;