import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, $filter, FileSaver, helperService, controlLibraryDataService, item, dataService) {
    const vm = this;
    vm.saveControl = saveControl;
    vm.cancel = cancel;
    vm.removeFile = removeFile;
    vm.downloadFile = downloadFile;

    activate();

    function activate() {
        setMitigations();
        setProducts();
        setUsers();
        setBusinessProcesses();

        vm.shouldBeOpen = true;

        if (item.Id) {
            vm.control = { ...item.toJSON() };
            // get any attachments that our control already has
            if (vm.control.Attachments.length) {
                vm.existingFilesDataSource = [...vm.control.Attachments];
            }
        }
    }

    // Functions for loading dropdown data
    async function setMitigations() {
        vm.mitigationDropdownLoading = true;
        let response = await controlLibraryDataService.getMitigations();
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.mitigationDataSource = response.data;
        vm.mitigationDropdownLoading = false;
    }

    async function setProducts() {
        vm.productDropdownLoading = true;
        let response = await controlLibraryDataService.getProducts();
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.productDataSource = response.data;
        vm.productDropdownLoading = false;
    }

    async function setUsers() {
        vm.userDropdownLoading = true;
        let response = await dataService.getUsers()
        response.data = response.data.filter(user => user.Active);
        response.data = $filter('orderBy')(response.data, 'UserLogonName');
        vm.usersDataSource = response.data;
        vm.userDropdownLoading = false;
    }

    async function setBusinessProcesses() {
        vm.businessProcessDropdownLoading = true;
        let response = await controlLibraryDataService.getBusinessProcesses();
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.businessProcessDataSource = response.data.map(bp => {
            let noObjectsBp = {
                "Id": bp.Id,
                "Name": bp.Name,
                "Description": bp.Description,
                "Type": bp.Type,
                "Objects": [],
                "RulesetId": bp.RulesetId,
                "RulesetName": bp.RulesetName
            };
            return noObjectsBp;
        });
        vm.businessProcessDropdownLoading = false;
    }

    // Common modal functions
    function cancel() { $uibModalInstance.dismiss(); }
    function close() { $uibModalInstance.close(); }

    async function saveControl(control) {
        vm.savePending = new createSpinner();
        if (control.BusinessProcesses) {
            control.selectedBusinessProcesses = control.BusinessProcesses.map(bp => bp.Id);
        }

        let controlToSave = {
            'Id': control.Id,
            'Name': control.Name,
            'Description': control.Description,
            'OwnerId': control.OwnerId,
            'PerformerId': control.PerformerId,
            'MitigationId': control.MitigationId,
            'ProductType': control.ProductType,
            'BusinessProcessIds': control.selectedBusinessProcesses
        };

        if (vm.control.Identifier) {
            controlToSave.Identifier = control.Identifier;
        }

        try {
            var controlData;
            if (vm.control.Identifier) {
                await controlLibraryDataService.editControl(controlToSave);
                controlData = item.Identifier;
            } else {
                var { data: controlData } = await controlLibraryDataService.addNewControl(controlToSave);
            }

            if (vm.attachments.flow.files.length) {
                uploadNewFiles(vm.attachments.flow.files, controlData);
            } else {
                await helperService.successfulSaveButton(vm.savePending)
                close();
            }
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    async function removeFile(file) {
        vm.fileActionPending = true;

        try {
            await controlLibraryDataService.deleteAttachment(file.Identifier);
            const index = vm.existingFilesDataSource.findIndex(remainingFile => remainingFile.Identifier === file.Identifier);
            vm.existingFilesDataSource.splice(index, 1);
            vm.fileActionPending = false;
        } catch {
            vm.fileActionPending = false;
        }
    }

    async function downloadFile(file) {
        vm.fileActionPending = true;

        try {
            const response = await controlLibraryDataService.downloadAttachment(file.Identifier);
            FileSaver.saveAs(response.data, file.FileName);
            vm.fileActionPending = false;
        } catch {
            vm.fileActionPending = false;
        }
    }

    async function uploadNewFiles(files, controlId) {
        let fd = new FormData();
        files.forEach(function (file, i) {
            fd.append(files[i].file.name, files[i].file);
        });

        fd.append("DataType", "Control");
        fd.append("DataTypeId", controlId);

        try {
            await controlLibraryDataService.addAttachments(fd);
            await helperService.successfulSaveButton(vm.savePending);
            close();
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

}