import accessDetailsProfileModalController from "./accessDetailsProfileModal.controller";

export default /*@ngInject*/ function ($uibModal) {

    const vm = this;

    vm.openAccessDetailsProfileModal = openAccessDetailsProfileModal;

    function openAccessDetailsProfileModal() {
        $uibModal.open({
            templateUrl: '/App/Shared/Components/accessDetailsProfile/accessDetailsProfileModal.tmpl.html',
            controller: accessDetailsProfileModalController,
            controllerAs: 'adpmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                risk: () => vm.risk,
                businessProcess: () => vm.businessProcess
            }
        });
    }
}