import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../../Shared/kendo.module";
import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, lastSelectedUser, modalName, availableProducts, crossPlatformUsersDataService) {

    const vm = this;

    vm.helperService = helperService;
    vm.lastSelectedUser = lastSelectedUser;
    vm.modalName = modalName;
    vm.availableProducts = availableProducts;
    vm.submitUserMapping = submitUserMapping;
    vm.nullOutMapping = nullOutMapping;
    vm.checkUserMapping = checkUserMapping;
    vm.selectProduct = selectProduct;
    vm.cancel = cancel;

    vm.productsInModal = angular.copy(vm.availableProducts);

    vm.selectedProduct = null;

    async function activate() {
        if (vm.modalName === "Edit") {
            await getLastSelectedUserId();
        } else {
            vm.selectedUserModal = {
                "ID": null,
                "Name": "",
                "Email": "",
                "UserMaps": []
            };

            vm.productsInModal.forEach(function (product, i) {
                let mapModel = {
                    "UserID": null,
                    "ProductID": product.ID,
                    "ProductName": product.Name,
                    "ProductType": product.Type,
                    "UserName": null,
                    "Email": null
                };
                vm.selectedUserModal.UserMaps.push(mapModel);
            });
        }
    }

    activate();

    async function getLastSelectedUserId() {
        try {
            let response = await crossPlatformUsersDataService.getUserId(vm.lastSelectedUser.ID);
            vm.selectedUserModal = response.data;
            await populateData();
        } catch {
            return
        }
    }


    function populateData() {

        vm.selectedUserModal.UserMaps = [];


        vm.productsInModal.forEach(function (product, i) {
            var mapModel = {
                "UserID": null,
                "ProductID": product.ID,
                "ProductName": product.Name,
                "ProductType": product.Type,
                "UserName": null,
                "Email": null
            };

            vm.selectedUserModal.UserMaps.push(mapModel);

        });

        vm.selectedUserModal.UserMappings.forEach(function (mapping, i) {
            vm.selectedUserModal.UserMaps.forEach(function (userMap, i) {
                if (userMap.ProductID === mapping.ProductID) {
                    userMap['UserID'] = mapping.UserID;
                    userMap['UserName'] = mapping.UserName;
                    userMap['Email'] = mapping.Email;
                }
            });
        });

        checkIfEnoughProductsAreSelected();

    }

    function nullOutMapping(dataItem) {

        let selectedMapping = vm.selectedUserModal.UserMaps.filter(e => e.ProductID === dataItem.ProductID)[0];
        selectedMapping['UserID'] = null;
        selectedMapping['UserName'] = null;
        selectedMapping['Email'] = null;

        //$scope.selectProduct(null);
        checkIfEnoughProductsAreSelected();
    }

    function checkIfEnoughProductsAreSelected() {
        let productsValid = 0;
        vm.selectedUserModal.UserMaps.forEach(function (dataItem, i) {
            if (dataItem.UserID) {
                productsValid = productsValid + 1;
            }
        });
        if (productsValid > 1) {
            vm.productsValid = true;
        } else {
            vm.productsValid = null;
        }
    }

    function checkUserMapping(dataItem) {

        if (vm.modalName === "Create") {
            vm.selectedUserModal.Name = dataItem.Name;
            vm.selectedUserModal.Email = dataItem.Email;
        }

        let selectedMapping = vm.selectedUserModal.UserMaps.filter(e => e.ProductID.toUpperCase() === dataItem.ProductID.toUpperCase())[0];
        selectedMapping['UserID'] = dataItem.UserID;
        selectedMapping['UserName'] = dataItem.Name;
        selectedMapping['Email'] = dataItem.Email;

        checkIfEnoughProductsAreSelected();
        vm.selectedProduct = null;
    }

    const columnsFromVM = [
        { title: " ", template: "<button class='btn btn-sm btn-secondary' ng-click='ncpumvm.checkUserMapping(dataItem)'>Select</button>", width: 65, filterable: false },
        { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
        { field: "Email", title: "Email", filterable: customAutoCompleteFilter }
    ];

    vm.manualMapGridOptions = helperService.setMainGridOptions(columnsFromVM, 400);
    vm.manualMapGridOptions.selectable = false;
    vm.manualMapGridOptions.pageable = {
        buttonCount: 3
    };
    vm.manualMapGridOptions.autoBind = false;

    vm.manualMapDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        schema: halSchemaKendoGrid,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
            read: async options => {

                let postData = {
                    "ProductId": vm.selectedProduct,
                    "QueryInput": helperService.prepareSortsAndFilters(options)
                };

                try {
                    let response = await crossPlatformUsersDataService.updateManualMapGrid(postData, options.data.pageSize, options.data.page);
                    options.success(response.data);
                } catch (err) {
                    options.error(err);
                }
            }
        }
    });

    function selectProduct(productId) {
        if (vm.selectedProduct) {
            vm.manualMapGrid.dataSource.read();
        }

    };

    async function submitUserMapping() {
        vm.submit = new createSpinner();

        let userMappings = [];

        vm.selectedUserModal.UserMaps.forEach(function (dataItem, i) {
            if (dataItem.UserID) {
                userMappings.push({
                    "ProductId": dataItem.ProductID,
                    "ProductName": dataItem.ProductName,
                    "UserId": dataItem.UserID
                });
            }
        });


        let postData = {
            "Input": {
                "ID": vm.selectedUserModal.ID,
                "Name": vm.selectedUserModal.Name,
                "Email": vm.selectedUserModal.Email,
                "UserMappings": userMappings
            }
        };

        try {
            await crossPlatformUsersDataService.submitUserMapping(postData);
            let dataItem = vm.manualMapGrid.dataSource.data().find(function (row) {
                return row.ID === vm.selectedUserModal.ID;
            });

            if (dataItem) {
                var productsAsString = "";
                userMappings.forEach(function (row, i) {
                    productsAsString = productsAsString + row.ProductName + ", ";
                });
                productsAsString = productsAsString.replace(/,\s*$/, "");

                dataItem.Name = vm.selectedUserModal.Name;
                dataItem.Email = vm.selectedUserModal.Email;
                dataItem.Products = productsAsString;
            } else {
                vm.manualMapGrid.dataSource.read();
            }
            await helperService.successfulSaveButton(vm.submit);
            cancel();
        } catch {
            vm.submit.loadingValue = false;
        }
    }

    function cancel() {
        $uibModalInstance.close();
    }

}