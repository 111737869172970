import ownershipGroupsController from "./ownershipGroups.controller";

/* ownershipGroups.component.js */

/**
 * @desc component for displaying and managing ownership groups
 * @example <ownership-groups></ownership-groups>
 */

const ownershipGroups = {
    controller: ownershipGroupsController,
    controllerAs: 'ogvm',
    templateUrl: '/App/Components/OwnershipGroups/ownershipGroups.tmpl.html'
};

export default ownershipGroups;