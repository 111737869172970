import { standardGridPageSize, customAutoCompleteFilter, customBooleanFilter } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";

export default /*@ngInject*/ function ($scope, $rootScope, SavedReportSelectorService, objectIdDataService, reportsDataService, reportsService, CustomReportServices, $filter, $http, helperService, snapshotsDataService) {

    const vm = this;

    $scope.$on('kendoWidgetCreated', function () {
        if (vm.grid) {
            vm.gridReady = true;
            runSavedReport();
        }
    });

    vm.helperService = helperService;

    vm.clearAllFilters = clearAllFilters;
    vm.openStartDate = openStartDate;
    vm.openEndDate = openEndDate;
    vm.populateCriteriaModel = populateCriteriaModel;
    vm.generateGridWithSelectedValues = generateGridWithSelectedValues;
    vm.applySelectedFilter = applySelectedFilter;
    vm.deleteFilter = deleteFilter;
    vm.onReadGrid = onReadGrid;
    vm.recordTypeDropdownChange = recordTypeDropdownChange;

    async function activate() {
        createSnapshotComparisonGrid();
        getRecordTypes();
        recordTypeDropdownChange();
        await getObjectId();

        vm.dateCriteria = [
            {
                "DataType": "DateTime",
                "FieldName": "Date",
                "IsNullable": false
            }
        ];

        getReportMetadata().then(_ => {
            vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
            if ($rootScope.loadedSavedReport) {
                runSavedReport();
            }
        });

    }

    activate();

     function runSavedReport() {
        if (vm.gridReady && $rootScope.loadedSavedReport && vm.recordTypeDataSource && vm.recordDataSource) {
            // --- FOR ADVANCED FILTERING ---
            // Convert date filters to criteria

            if ($rootScope.loadedSavedReport && $rootScope.loadedSavedReport.ReportDefinition.DateFilters.length > 0) {
                const criteriaDateFilterIndex = $rootScope.loadedSavedReport.ReportDefinition.DateFilters.findIndex(filter => filter.FieldName === 'Date');
                if (criteriaDateFilterIndex > -1) {
                    loadDateFilterAsCriteria($rootScope.loadedSavedReport.ReportDefinition.DateFilters[criteriaDateFilterIndex]);
                    $rootScope.loadedSavedReport.ReportDefinition.DateFilters.splice(criteriaDateFilterIndex, 1);
                }
            } else if ($rootScope.loadedSavedReport && $rootScope.loadedSavedReport.ReportDefinition.Filters.length > 0) {
                const dateRangeFilters = $rootScope.loadedSavedReport.ReportDefinition.Filters.filter(filter => filter.FieldName === 'Date');
                if (dateRangeFilters.length > 0) {
                    $rootScope.loadedSavedReport.ReportDefinition.Filters = $rootScope.loadedSavedReport.ReportDefinition.Filters.filter(filter => filter.FieldName !== 'Date');
                    dateRangeFilters.forEach(filter => {
                        loadDateFilterAsCriteria(filter);
                    });
                }
            }

            vm.filterCriteria.query = reportsService.getSavedReportFilters();
            vm.filterCriteria.dateQuery = reportsService.getSavedReportDateFilters();
            vm.filterCriteria.needsUpdate = true;
            // ---
            vm.generateGridWithSelectedValues();
        } else if (vm.gridReady && !$rootScope.loadedSavedReport && vm.recordTypeDataSource && vm.recordDataSource && vm.filterCriteria.fields) {
            // We want the necessary report details available to be able to use the report toolbar without running the report
            vm.populateCriteriaModel();
        }
    };
 
    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    // ---

    async function getObjectId() {
        const objectId = await objectIdDataService.getObjectID();
        vm.ObjectId = objectId;
        let securityObject = helperService.getSecurityObjectDetailsFromObjectId(objectId);
        vm.reportName = securityObject.Name;
        vm.reportId = securityObject.ReportID;
        vm.filtersDataSource = CustomReportServices.setfiltersDataSource(vm.ObjectId);
    }

    function createSnapshotComparisonGrid() {

        vm.mainGridOptions = helperService.setMainGridOptions(null, 600);
        vm.mainGridOptions.autoBind = false;

        vm.mainGridColumns = [
            { field: "RecordId", title: "Record Id", filterable: customAutoCompleteFilter },
            { field: "RecordName", title: "Record Name", filterable: customAutoCompleteFilter },
            { field: "Field", title: "Field", filterable: customAutoCompleteFilter },
            { field: "StartValue", title: "Start Value", filterable: customAutoCompleteFilter },
            { field: "EndValue", title: "End Value", filterable: customAutoCompleteFilter },
            { field: "Diff", title: "Difference", width: 140, filterable: customBooleanFilter }
        ]; 

        // Don't allow filtering and sorting yet because they will force the report to run
        if (!$rootScope.loadedSavedReport) {
            vm.mainGridOptions.sortable = false;

            vm.mainGridOptions.filterable = false;
        }

        vm.gridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: angular.extend(halSchemaKendoGrid, {
                model: {
                    fields: {
                        Diff: { type: "boolean" }
                    }
                }
            }),
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: function (options) {

                    delete $rootScope.loadedSavedReport;

                    let data = {
                        'RecordTypeId': vm.recordType,
                        'RecordId': vm.record,
                        'StartDate': vm.savedStartDate,
                        'EndDate': vm.savedEndDate
                    };

                    vm.sortsAndFilters = helperService.prepareSortsAndFilters(options);
                    data['QueryInput'] = vm.sortsAndFilters;

                    // --- FOR ADVANCED FILTERING ---
                    if (vm.filterCriteria.query) {
                        data['QueryInput'].Filters = data['QueryInput'].Filters.concat(vm.filterCriteria.query);
                    }
                    // ---

                    // if tabs are on, save report info for the tab
                    if ($rootScope.tabs.length > 0) {
                        reportsService.saveTabReportInfo(vm);
                    }

                    $http({
                        method: 'POST',
                        url: apiUrl + 'api/audittrail/ns/snapshot/report/comparison',
                        data: data,
                        params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                    }).then(function (response) {

                        let data = response.data._embedded.reportData;

                        options.success(response.data);
                        vm.enableGrid = true;

                    }, function errorCallback(response) {
                        options.error();
                    });
                }
            }
        });

        setLayout();

    }

    async function getRecordTypes() {
        let response = await snapshotsDataService.getRecordType();
        response.data = $filter('filter')(response.data, { Enabled: true });
        response.data = $filter('orderBy')(response.data, 'DisplayName');
        vm.recordTypeDataSource = response.data;

        vm.selectedRecordType = SavedReportSelectorService.checkSavedReportForRecordType($rootScope.loadedSavedReport, response.data, vm.selectedRecordType);
        recordTypeDropdownChange();
        runSavedReport();
    } 

    async function recordTypeDropdownChange() {
        try {
            if (vm.selectedRecordType) {
                let response = await snapshotsDataService.getDistinctRecordType(vm.selectedRecordType.Id);
                let allRecords = { "RecordName": "All Records", "RecordId": 'AllObjectsInMultiSelect' };
                response.data.unshift(allRecords);

                vm.recordDataSource = response.data;
                vm.selectedRecord = response.data[0];

                vm.selectedRecord = SavedReportSelectorService.checkSavedReportForRecord($rootScope.loadedSavedReport, response.data, vm.selectedRecord);
                populateCriteriaModel();
                runSavedReport();
            }
        } catch {
            return;
        }
    }

    //Start Date picker settings
    function thirtyDaysAgo() {
        let newDate = new Date();
        newDate.setDate(newDate.getDate() - 30);
        vm.startDate = newDate;
        vm.startDate = SavedReportSelectorService.checkSavedReportForDate($rootScope.loadedSavedReport, "StartDate", vm.startDate);
    }

    thirtyDaysAgo();

    vm.startDateOptions = {
        dateDisabled: disabled,
        showWeeks: false,
        formatYear: 'yy',
        startingDay: 1
    };

    function disabled(data) {
        let date = data.date,
            mode = data.mode;
        return mode === 'day' && date > vm.endDate;
    }

    function openStartDate() {
        vm.startDatePopup.opened = true;
    }

    vm.startDatePopup = {
        opened: false
    };

    //End Date picker settings
    function today() {
        vm.endDate = new Date();
        vm.endDate = SavedReportSelectorService.checkSavedReportForDate($rootScope.loadedSavedReport, "EndDate", vm.endDate);

    }

    today();

    vm.endDateOptions = {
        showWeeks: false,
        formatYear: 'yy',
        maxDate: new Date(),
        startingDay: 1
    };

    function openEndDate() {
        vm.endDatePopup.opened = true;
    }

    vm.endDatePopup = {
        opened: false
    };

    function populateCriteriaModel() {
        // do not continue if the proper criteria are not loaded
        if (!vm.selectedRecordType || vm.selectedRecord === undefined || !vm.startDate || !vm.endDate) {
            return;
        }
        let criteria = [];

        vm.recordType = vm.selectedRecordType.Id;
        vm.savedStartDate = vm.startDate;
        vm.savedEndDate = vm.endDate;

        if (vm.selectedRecord.RecordId === 'AllObjectsInMultiSelect') {
            vm.record = null;
        } else {
            vm.record = vm.selectedRecord.RecordId;
        }

        criteria.push(CustomReportServices.CreateCriteria("RecordTypeId", 'RecordType', vm.selectedRecordType.DisplayName, vm.selectedRecordType.Id));
        criteria.push(CustomReportServices.CreateCriteria("RecordId", 'Record', vm.selectedRecord.RecordName, vm.selectedRecord.RecordId));
        criteria.push(CustomReportServices.CreateCriteria("StartDate", 'StartDate', vm.startDate, vm.startDate));
        criteria.push(CustomReportServices.CreateCriteria("EndDate", 'EndDate', vm.endDate, vm.endDate));
        vm.reportDetailsModel = criteria;

        // if tabs are on, save report info for the tab
        if ($rootScope.tabs.length > 0) {
            reportsService.saveTabReportInfo(vm);
        }
    }

    function generateGridWithSelectedValues() {

        if (!$rootScope.loadedSavedReport) {
            vm.grid.dataSource._filter = null;
            vm.grid.dataSource._sort = null;
        }

        vm.populateCriteriaModel();
        vm.hideExportOptionsFromSubmit = true;

        // Add back the sorting and filtering options if we are running the report for the first time
        if (!$rootScope.loadedSavedReport) {
            vm.grid.setOptions({
                filterable: {
                    mode: "row"
                },
                sortable: true
            });
        }

        SavedReportSelectorService.queryGridWithOptions($rootScope.loadedSavedReport, vm.grid, true);
    } 

    vm.filterManagerGridOptions = CustomReportServices.setfilterManagerGridOptions();

    vm.filterDropdownOptions = helperService.getFilterDropdownOptions();

    function applySelectedFilter(selectedFilter) {
        const currentOptions = vm.grid.getOptions();

        if (selectedFilter && selectedFilter.Filters && selectedFilter.Filters.length > 0) {
            currentOptions.dataSource.filter = helperService.convertFiltersForGrid(_, selectedFilter.Filters);
            try {
                vm.grid.dataSource._filter = currentOptions.dataSource.filter;
                vm.grid.dataSource.read();
            }
            catch (err) {
                vm.gridDataSource.filter({});
            }
        }
    }

    function deleteFilter(id) {
        CustomReportServices.deleteFilterFilterManager(vm, id);
    }

    function clearAllFilters() {
        vm.gridDataSource.filter({});
    }

    function onReadGrid() {
        vm.gridDataSource.read();
    }

    function setLayout() {
        if (!$rootScope.loadedSavedReport) {
            CustomReportServices.loadSavedGridLayout(vm, null);
        }
    }

    // --- FOR ADVANCED FILTERING ---
    function getReportMetadata() {
        return reportsDataService.getReportMetadata(vm.reportId)
            .then(data => {
                vm.reportMetadata = data.data._embedded.reportData;
                vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');
                vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
                return vm.reportMetadata;
            });
    }
    // ---
}