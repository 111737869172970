import createSpinner from "../../../Functions/createSpinner";
import { customAutoCompleteFilter, standardGridPageSize } from "../../../GlobalVariables/filterVariables";

export default /*@ngInject*/ function (helperService, accessDetailsProfileDataService) {

    const vm = this;
    vm.helperService = helperService;

    vm.accessCollapsed = true;
    vm.riskProductCollapsed = true;

    vm.$onInit = activate;

    async function activate() {
        vm.data = await getGridData();
        setupGrid();
    }

    async function getGridData() {
        vm.loading = new createSpinner();
        vm.loading.loadingValue = true;
        try {
            const { data } = await accessDetailsProfileDataService.getRiskProductProfile(vm.product.Type, vm.risk);
            vm.loading.loadingValue = false;
            return data;
        } catch {
            vm.loading.loadingValue = false;
        }
    }

    function setupGrid() {
        let riskProductProfileGridColumns;
        if (vm.data.length) {
            let accessDetailFields = Object.keys(vm.data[0]);
            riskProductProfileGridColumns = accessDetailFields.map(field => {
                return {
                    field: field,
                    title: helperService.headerStringTransformer(field),
                    width: 120,
                    filterable: customAutoCompleteFilter
                }
            });
        } else {
            riskProductProfileGridColumns = null;
        }

        vm.riskProductProfileGridOptions = helperService.setMainGridOptions(riskProductProfileGridColumns, 400);
        vm.riskProductProfileGridOptions.autoBind = true;

        vm.riskProductProfileGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            noRecords: {
                template: "<div class='no-data-to-display-message'>No items to display</div>"
            },
            transport: {
                read: async options => {
                    try {
                        await options.success(vm.data);
                    } catch (err) {
                        return;
                    }
                }
            }
        });
    }
}