import pageHeaderController from "./pageHeader.controller";

/* pageHeader.component.js */

/**
 * @desc component for displaying page header
 * @example <page-header></page-header>
 */

const pageHeader = {
    controller: pageHeaderController,
    controllerAs: 'phvm',
    templateUrl: '/App/Shared/Components/pageHeader/pageHeader.tmpl.html'
};

export default pageHeader;