/* allChanges.component.js */

import allChangesController from "./allChanges.controller";

/**
 * @desc component for viewing all NetSuite audit trail changes
 * @example <all-changes></all-changes>
 */

const allChanges = {
    controller: allChangesController,
    controllerAs: 'acvm',
    templateUrl: '/App/Components/NetSuiteAuditTrail/AllChanges/allChanges.tmpl.html'
};

export default allChanges;