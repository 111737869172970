import { standardGridPageSize, customAutoCompleteFilter, customBooleanFilter } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";

export default /*@ngInject*/ function ($scope, $rootScope, $filter, SavedReportSelectorService, dataService, buildRoleBPPostBody, objectIdDataService, CustomReportServices, $http, $location, helperService, reportsDataService, reportsService, risksDataService, businessProcessDataService) {

    var vm = this;

    vm.helperService = helperService;
    vm.roleSelectedIds = [];
    vm.allRolesOption = true;
    vm.updateRoleDataSource = updateRoleDataSource;
    vm.urlString = "";

    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    // ---
    vm.runSavedReport = runSavedReport;
    vm.populateCriteriaModel = populateCriteriaModel;
    vm.generateGridWithSelectedValues = generateGridWithSelectedValues;

    vm.businessProcessDropdownChange = businessProcessDropdownChange;
    vm.rulesetDropdownChange = rulesetDropdownChange;
    vm.productDropdownChange = productDropdownChange;

    $scope.deleteFilter = deleteFilter;
    $scope.clearAllFilters = clearAllFilters;
    $scope.saveReportLayoutButton = saveReportLayoutButton;
    $scope.applySelectedFilter = applySelectedFilter;
    $scope.populateCriteriaModel = populateCriteriaModel;

    $scope.$on('kendoWidgetCreated', function () {
        if ($scope.grid) {
            $scope.gridReady = true;
            runSavedReport();
        }
    });

    if ($location.path().includes('Detailed')) {
        vm.isDetailed = true;
    } else {
        vm.isDetailed = false;
    }

    function activate() {
        objectIdDataService.getObjectID().then(function (objectId) {
            $scope.ObjectId = objectId;

            $scope.filtersDataSource = CustomReportServices.setfiltersDataSource($scope.ObjectId);

            var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
            $scope.pageInfo = securityObject;
            $scope.pageDescription = securityObject.HelpLink;
            $scope.reportName = securityObject.Name;
            $scope.reportId = securityObject.ReportID;

            // --- Grid initialization ---
            vm.mainGridOptions = helperService.setMainGridOptions(null, 600);
            vm.mainGridOptions.autoBind = false;
            $scope.filterManagerGridOptions = CustomReportServices.setfilterManagerGridOptions();
            $scope.filterDropdownOptions = helperService.getFilterDropdownOptions();

            // --- FOR ADVANCED FILTERING ---
            getReportMetadata().then(_ => {
                vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
                if ($rootScope.loadedSavedReport) {
                    runSavedReport();
                }
            });
            // ---

            getRulesets()
                .then(_ => {
                    return getBusinessProcesses();
                });
        });
    }

    activate();


    function runSavedReport() {
        if ($scope.gridReady && $rootScope.loadedSavedReport && vm.productsDataSource && vm.roleDataSource && vm.objectDataSource && vm.filterCriteria.fields) {
            vm.filterCriteria.query = reportsService.getSavedReportFilters();
            vm.filterCriteria.needsUpdate = true;
            generateGridWithSelectedValues();
        } else if ($scope.gridReady && !$rootScope.loadedSavedReport && vm.productsDataSource && vm.roleDataSource && vm.objectDataSource && vm.filterCriteria.fields) {
            // We want the necessary report details available to be able to use the report toolbar without running the report
            $scope.populateCriteriaModel();
        }
    }

    function applySelectedFilter() {
        helperService.applyFilterCatchError($scope);
    }

    $scope.gridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        schema: angular.extend(halSchemaKendoGrid, {
            model: {
                fields: {
                    IsAssigned: { type: "boolean" }
                }
            }
        }),
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
            read: function (options) {
                delete $rootScope.loadedSavedReport;

                var data = buildRoleBPPostBody.generateBody($scope.ObjectId, vm.selectedProductId, vm.roles, vm.selectedBusinessProcesses, $scope.reportId, vm.selectedObject.Identifier, vm.selectedRuleset.RulesetId);

                data['QueryInput'] = helperService.prepareSortsAndFilters(options);

                // --- FOR ADVANCED FILTERING ---
                if (vm.filterCriteria.query) {
                    data['QueryInput'].Filters = data['QueryInput'].Filters.concat(vm.filterCriteria.query);
                }
                // ---

                // if tabs are on, save report info for the tab
                if ($rootScope.tabs.length > 0) {
                    reportsService.saveTabReportInfo($scope);
                }

                $http({
                    method: 'POST',
                    url: vm.urlString,
                    data: data,
                    params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                }).then(response => {
                    options.success(response.data);
                    vm.enableGrid = true;

                    let securityObject = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProduct.Type);

                    if (securityObject) {
                        $scope.reportId = securityObject.ReportID;
                    }

                }).catch(response => {
                    const timeout = 408;

                    if (response.status !== timeout) {
                        helperService.showErrorMessage(response.data);
                    }
                });
            }
        }
    });

    function populateCriteriaModel() {
        // do not continue if the proper criteria are not loaded
        if (!vm.selectedProduct || !vm.selectedBusinessProcesses || !vm.roleSelectedIds || !vm.selectedRuleset || !vm.selectedObject) {
            return;
        }
        vm.product = vm.selectedProduct;
        vm.roles = vm.roleSelectedIds;//.map(function (el) { return el.ID; });
        vm.ruleset = vm.selectedRuleset;
        vm.selectedProductId = [];
        vm.businessProcesses = vm.selectedBusinessProcesses;
        vm.securityObject = vm.selectedObject;

        var criteria = [];
        criteria.push(CustomReportServices.CreateCriteria("SecurityObject", 'SecurityObject', vm.securityObject.AltName, { "Id": vm.selectedObject.Identifier }));

        vm.roleSelectedIds.forEach(function (role, i) {
            criteria.push(CustomReportServices.CreateCriteria("SecurityRoleIdentifier", 'Role', role.Name, role.Identifier));
        });

        vm.selectedBusinessProcesses.forEach(bp => {
            criteria.push(CustomReportServices.CreateCriteria("ProcessId", 'BusinessProcessId', bp.Name, bp.Id));
        });

        criteria.push(CustomReportServices.CreateCriteria("RulesetId", 'Ruleset', vm.ruleset.Name, vm.ruleset.RulesetId));
        criteria.push(CustomReportServices.CreateCriteria("ProductId", 'Product', vm.product.Name, vm.product.ID));
        vm.selectedProductId.push(vm.product.ID);
        $scope.reportDetailsModel = criteria;

        vm.urlString = vm.isDetailed ? apiUrl + "api/arm/sap/places/rolebusinessprocessaccessdetailed" : apiUrl + "api/arm/places/rolebusinessprocessaccess";

        // if tabs are on, save report info for the tab
        if ($rootScope.tabs.length > 0) {
            reportsService.saveTabReportInfo($scope);
        }
    }

    function generateGridWithSelectedValues() {
        if (!$rootScope.loadedSavedReport) {
            $scope.grid.dataSource._filter = null;
            $scope.grid.dataSource._sort = null;
        }

        populateCriteriaModel();
        vm.hideExportOptionsFromSubmit = true;

        // Add back the sorting and filtering options if we are running the report for the first time
        if (!$rootScope.loadedSavedReport) {
            $scope.grid.setOptions({
                filterable: {
                    mode: "row"
                },
                sortable: true
            });
        }

        SavedReportSelectorService.queryGridWithOptions($rootScope.loadedSavedReport, $scope.grid, true);
    }

    function setColumns() {
        if (vm.isDetailed && vm.selectedProduct.ID === "SAP") {
            vm.mainGridColumns = [
                { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                { field: "GroupName", title: "Group Name", width: 120, filterable: customAutoCompleteFilter },
                { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                { field: "RoleId", title: "Role ID", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                { field: "RoleType", title: "Role Type", width: 180, filterable: customAutoCompleteFilter },
                { field: "SecurityObjectId", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                { field: "ProductName", title: "Product", width: 180, filterable: customAutoCompleteFilter },
                { field: "Authorization", title: "Authorization", width: 180, filterable: customAutoCompleteFilter },
                { field: "Field", title: "Field", width: 180, filterable: customAutoCompleteFilter },
                { field: "RulesetLow", title: "Ruleset Low", width: 180, filterable: customAutoCompleteFilter },
                { field: "RulesetHigh", title: "RulesetHigh", width: 180, filterable: customAutoCompleteFilter },
                { field: "TechnicalLow", title: "Technical Low", width: 180, filterable: customAutoCompleteFilter },
                { field: "Low", title: "Low", width: 180, filterable: customAutoCompleteFilter },
                { field: "TechnicalHigh", title: "Technical High", width: 180, filterable: customAutoCompleteFilter },
                { field: "High", title: "High", width: 180, filterable: customAutoCompleteFilter },
                { field: "IsAssigned", title: "IsAssigned", width: 180, filterable: customBooleanFilter }
            ];
        } else {
            vm.mainGridColumns = [
                { field: "ProcessName", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                { field: "GroupName", title: "Group Name", width: 120, filterable: customAutoCompleteFilter },
                { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                { field: "RoleId", title: "Role ID", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                { field: "RoleType", title: "Role Type", width: 180, filterable: customAutoCompleteFilter },
                { field: "SecurityObjectId", title: "Object ID", width: 180, filterable: customAutoCompleteFilter },
                { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                { field: "AccessLevelName", title: "Access Level", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                { field: "ProductName", title: "Product", width: 180, filterable: customAutoCompleteFilter },
                { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
            ];
        }

        // Don't allow filtering and sorting yet because they will force the report to run
        if (!$rootScope.loadedSavedReport) {
            vm.mainGridOptions.sortable = false;

            vm.mainGridOptions.filterable = false;
        }

        setLayout();
    }

    function setLayout() {
        if (!$rootScope.loadedSavedReport) {
            CustomReportServices.loadSavedGridLayout($scope, null);
        }
    }

    function deleteFilter(id) {
        CustomReportServices.deleteFilterFilterManager($scope, id);
    }

    function clearAllFilters() {
        $scope.gridDataSource.filter({});
    }

    function saveReportLayoutButton() {
        CustomReportServices.saveGridLayout($scope, null);
    }

    function productDropdownChange() {
        setColumns();
        $scope.populateCriteriaModel();
    }

    dataService.getProducts().then(function (response) {
        response.data = response.data.filter(p => p.UserHasAccess === true);
        vm.productsDataSource = response.data;
        let defaultProduct = vm.productsDataSource.find(obj => obj.Name === $rootScope.UserPreferences.PreferredProduct);
        let defaultProductSelected = vm.productsDataSource.indexOf(defaultProduct);
        if ($rootScope.UserPreferences.PreferredProduct !== null) {
            vm.selectedProduct = vm.productsDataSource[defaultProductSelected];
        } else {
            vm.selectedProduct = response.data[0];
        }

        vm.selectedProduct = SavedReportSelectorService.checkSavedReportForSingleProduct($rootScope.loadedSavedReport, response.data, vm.selectedProduct);
        productDropdownChange();
        runSavedReport();
    });

    function getRulesets() {
        return risksDataService.getRulesets()
            .then(response => {
                response.data = $filter('filter')(response.data, { IsEnabled: true });
                vm.rulesetDataSource = response.data;
                vm.rulesetDataSource = $filter('orderBy')(response.data, 'Name');
                vm.selectedRuleset = SavedReportSelectorService.checkSavedReportForRuleset($rootScope.loadedSavedReport, response.data, vm.rulesetDataSource);
                runSavedReport();
            });
    }

    function getBusinessProcesses() {
        businessProcessDataService.getBusinessProcessesByRiskRuleset(vm.selectedRuleset.RulesetId).then(response => {
            var businessProcesses = response.data;
            businessProcesses = $filter('orderBy')(response.data, 'Name');

            if (businessProcesses.length > 0) {
                vm.allBusinessProcessesDropdownObject = { "Name": "All Business Processes", "Id": "AllObjectsInMultiSelect", "Type": "BP", "Objects": [], "DisplayName": "All Business Processes" };
                businessProcesses.unshift(vm.allBusinessProcessesDropdownObject);
            }

            vm.businessProcessDataSource = businessProcesses;

            vm.selectedBusinessProcesses = [vm.allBusinessProcessesDropdownObject];
            vm.selectedBusinessProcesses = SavedReportSelectorService.checkSavedReportForBusinessProcesses($rootScope.loadedSavedReport, response.data, vm.selectedBusinessProcesses);
            businessProcessDropdownChange();
            runSavedReport();
        });
    }

    function businessProcessDropdownChange() {
        vm.selectedBusinessProcesses = helperService.deselectAllObjectsIfOtherIsClicked(vm.selectedBusinessProcesses, vm.allBusinessProcessesDropdownObject);

        if (vm.selectedBusinessProcesses) {

            vm.objectDataSource = [];

            if (vm.selectedBusinessProcesses.some(bp => bp.Id === "AllObjectsInMultiSelect")) {
                vm.businessProcessDataSource.forEach(function (row) {
                    row.Objects.forEach(function (row) {
                        vm.objectDataSource.push(row.SecurityObject);
                    });
                });

                vm.objectDataSource = vm.objectDataSource.unique();
            } else {
                vm.selectedBusinessProcesses.forEach(bp => {
                    bp.Objects.forEach(object => vm.objectDataSource.push(object.SecurityObject));
                });
            }

            vm.objectDataSource = $filter('orderBy')(vm.objectDataSource, 'AltName');
            vm.objectDataSource.unshift({ "Id": null, "AltName": "All Objects" });
            vm.selectedObject = vm.objectDataSource[0];
            vm.selectedObject = SavedReportSelectorService.checkSavedReportForObjects($rootScope.loadedSavedReport, vm.objectDataSource, vm.selectedObject);
            runSavedReport();
        }
        $scope.populateCriteriaModel();
    }

    async function rulesetDropdownChange() {
        await getBusinessProcesses();
        if (vm.filterCriteria.query && vm.filterCriteria.query.some(criteria => criteria.CriteriaDisplayName.includes("Business Process"))) {
            vm.filterCriteria.query = vm.filterCriteria.query.filter(criteria => {
                if (criteria.CriteriaDisplayName) {
                    return !criteria.CriteriaDisplayName.includes("Business Process");
                } else {
                    return criteria;
                }
            });
            vm.filterCriteria.needsUpdate = true;
        }
        $scope.populateCriteriaModel();
    }

    function updateRoleDataSource(roleDataSource, roleSelectedIds) {
        vm.roleDataSource = roleDataSource;
        vm.roleSelectedIds = roleSelectedIds;
        $scope.populateCriteriaModel();
    }

    // --- FOR ADVANCED FILTERING ---
    function getReportMetadata() {
        return reportsDataService.getReportMetadata($scope.reportId)
            .then(data => {

                vm.reportMetadata = data.data._embedded.reportData;
                vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');

                return vm.reportMetadata;
            });
    }
    // ---
}