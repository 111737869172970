import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($http, $rootScope, helperService) {

    var vm = this;
    vm.$onInit = activate;
    vm.schedule = {};

    function activate() {

        //Only allow 'Daily' schedule frequency for Workday Collect Data Changes
        if (vm.action.displayName === 'Collect Data Changes' && vm.product.ID === 'WD') {
            vm.frequencies = ['Daily'];
        } else {
            vm.frequencies = ['Daily', 'Weekly', 'Monthly'];
        }

        vm.daysInWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        vm.daysInMonth = helperService.getDayOfMonthDropdownOptions();
        vm.schedule.startTimeForScheduleImport = new Date();

        vm.hasExistingValueChanged = hasExistingValueChanged;
        vm.scheduleImportStatusChanged = scheduleImportStatusChanged;
        vm.updateScheduleImport = updateScheduleImport;

        setupLinks();
    }

    function setupLinks() {
        var productId = vm.product.ID;
        var action = vm.action.name.toLowerCase();

        if (vm.action.type) {
            vm.getScheduleUrl = apiUrl + 'api/audittrail/auditdata/' + action + '/schedule/' + productId + '/' + vm.action.type;
        } else {
            vm.getScheduleUrl = apiUrl + 'api/audittrail/auditdata/' + action + '/schedule/' + productId;
        }
        vm.putScheduleUrl = apiUrl + 'api/audittrail/auditdata/' + action + '/schedule';

        if (vm.action.name === 'Collect') {
            vm.deleteScheduleUrl = apiUrl + 'api/audittrail/auditdata/' + action + '/schedule/' + productId + '/' + vm.action.type;
        } else {
            vm.deleteScheduleUrl = apiUrl + 'api/audittrail/auditdata/' + action + '/schedule/' + productId;
        }

        getImportSchedule();
    }

    function getImportSchedule() {
        $http.get(vm.getScheduleUrl).then(response => {
            vm.importSchedule = response.data;
            vm.schedule.selectedFreqForScheduleImport = response.data.Frequency;
            vm.schedule.selectedDayOfWeekForScheduleImport = response.data.DayOfWeek;
            vm.schedule.selectedDayOfMonthForScheduleImport = { Name: response.data.DayOfMonth, Value: response.data.DayOfMonth };
            vm.schedule.startTimeForScheduleImport = new Date(response.data.StartTime);
            vm.originalSchedule = angular.copy(vm.schedule);
            vm.ScheduleImportIsOn = true;
        }).catch(_ => {
            vm.importSchedule = null;
            vm.ScheduleImportIsOn = false;
        });
    }

    function scheduleImportStatusChanged() {
        if (!vm.ScheduleImportIsOn && vm.importSchedule) {
            updateScheduleImport(false);
        }
    }

    function updateScheduleImport(isOn) {
        updateSchedule(isOn, vm.schedule.selectedFreqForScheduleImport, vm.schedule.selectedDayOfWeekForScheduleImport, vm.schedule.selectedDayOfMonthForScheduleImport, vm.schedule.startTimeForScheduleImport, vm.putScheduleUrl, vm.deleteScheduleUrl, vm.importSchedule);
    }

    function updateSchedule(scheduleIsOn, frequency, dayOfWeek, dayOfMonth, startTime, scheduleUrl, disableUrl, downloadedSchedule) {

        vm.updateInProgress = new createSpinner();

        if (scheduleIsOn) {

            var dayOfMonthRequest;
            var dayOfWeekRequest;

            if (frequency === 'Daily') {
                dayOfMonthRequest = [];
                dayOfWeekRequest = [];
            } if (frequency === 'Weekly') {
                dayOfMonthRequest = [];
                dayOfWeekRequest = [dayOfWeek];
            } if (frequency === 'Monthly') {
                dayOfMonthRequest = [dayOfMonth.Value];
                dayOfWeekRequest = [];
            }

            let putData = {
                "Schedule": {
                    "DayOfMonth": dayOfMonthRequest[0],
                    "DayOfWeek": dayOfWeekRequest[0],
                    "Frequency": frequency,
                    "StartTime": startTime.toUTCString(),
                    "TimeZone": $rootScope.applicationTimeZone
                },
                "ProductId": vm.product.ID
            };

            if (vm.action.name === "Collect") {
                putData.ScheduleType = vm.action.type;
            }

            $http.put(scheduleUrl, putData).then(function (response) {
                getImportSchedule();
                helperService.successfulSaveButton(vm.updateInProgress);
            }, function (response) {
                helperService.showErrorMessage(response.data);
                vm.updateInProgress.loadingValue = false;
            });
        } else if (scheduleIsOn === false) {
            if (downloadedSchedule) {
                $http.delete(disableUrl, {}).then(function (response) {
                    vm.schedule = null;
                    vm.originalSchedule = null;
                    getImportSchedule();
                    vm.updateInProgress.loadingValue = false;
                }, function (response) {
                    helperService.showErrorMessage(response.data);
                    vm.updateInProgress.loadingValue = false;
                });
            }
        }
    }

    function hasExistingValueChanged() {
        if (vm.originalSchedule && angular.equals(vm.originalSchedule, vm.schedule)) {
            return false;
        } else {
            // Either creating a new value or user has modified one of the fields
            return true;
        }
    }


}
