export default /*@ngInject*/ function ($rootScope) {
    const service = {
        valueMap
    };

    return service;

    function valueMap(userObject, mailMappingField) {
        let mappedObject = {};

        switch (userObject.ProductType) {
            case 'AX7':
                // AX7_UseAzureAD === 0 ? On-prem Active Directory : Azure AD
                mappedObject = {
                    UserId: null,
                    UserName: userObject.DisplayName,
                    Email: $rootScope.ConfigurationValues.AX7_UseAzureAD === '0' ? userObject.Mail : userObject.UserPrincipalName,
                    NetworkDomain: $rootScope.ConfigurationValues.AX7_UseAzureAD === '0' ? null : userObject.NetworkDomain,
                    Company: null,
                    Enabled: userObject.Enabled,
                    ProductType: userObject.ProductType,
                    UserType: userObject.UserType
                }
                break;

            case 'D365S':
                mappedObject = {
                    AzureADObjectId: userObject.Id,
                    FirstName: userObject.GivenName,
                    LastName: userObject.SurName,
                    Title: userObject.JobTitle,
                    HomePhone: userObject.BusinessPhone,
                    MobilePhone: userObject.MobilePhone,
                    Address1_Street1: userObject.StreetAddress,
                    Address1_City: userObject.City,
                    Address1_StateOrProvince: userObject.State,
                    Address1_Country: userObject.Country,
                    InternalEmailAddress: userObject.Mail,
                    DomainName: userObject.UserPrincipalName,
                    ProductType: userObject.ProductType,
                    UserType: userObject.UserType
                };
                break;

            case 'ORFC':
                mappedObject = {
                    DisplayName: userObject.DisplayName,
                    FirstName: userObject.GivenName,
                    LastName: userObject.SurName,
                    Email: userObject[mailMappingField],
                    ProductType: userObject.ProductType,
                    EndDate: ''
                };
                break;

            case 'SF':
                mappedObject = {
                    CompanyName: userObject.CompanyName,
                    Department: userObject.Department,
                    Email: userObject[mailMappingField],
                    FirstName: userObject.GivenName,
                    LastName: userObject.SurName,
                    ManagerId: '',
                    MobilePhone: userObject.MobilePhone,
                    Phone: userObject.BusinessPhones[0],
                    ProductType: userObject.ProductType,
                    Title: userObject.JobTitle,
                    UserEffectiveEndDate: '',
                    Country: userObject.Country,
                    Street: userObject.StreetAddress,
                    City: userObject.City
                };
                break;

            case 'PS':
                mappedObject = {
                    PrimaryEmail: userObject[mailMappingField],
                    ProductType: userObject.ProductType,
                    UserEffectiveEndDate: ''
                };
                break;

            case 'NS':
                mappedObject = {
                    Email: userObject[mailMappingField],
                    FirstName: userObject.GivenName,
                    LastName: userObject.SurName,
                    Phone: userObject.BusinessPhones[0],
                    MobilePhone: userObject.MobilePhone,
                    JobTitle: userObject.JobTitle,
                    ProductType: userObject.ProductType,
                    EndDate: ''
                };
                break;

            case 'OR':
                mappedObject = {
                    ProductType: userObject.ProductType,
                    Email: userObject[mailMappingField],
                    EndDate: null
                }
                break;

            case 'SAP':
                mappedObject = {
                    Email: userObject[mailMappingField],
                    FirstName: userObject.GivenName,
                    LastName: userObject.SurName,
                    Street1: userObject.StreetAddress,
                    City: userObject.City,
                    State: userObject.State
                };
                break;

            default:
                mappedObject = userObject;
        }
        return mappedObject;
    }

}