import createSpinner from "../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter } from "../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, helperService, riskRulesetService, $filter, riskRuleset, isCloning, dataService, FileSaver) {

    const vm = this;

    vm.newRiskRulesetIsDefault = false;
    vm.isImport = false;
    vm.loadingImport = false;
    vm.importType = "New";
    vm.gridView = 'BusinessProcesses';
    vm.ineligibileBusinessProcessIds = [];

    vm.riskRuleset = Object.assign({}, riskRuleset);
    vm.riskRuleset.IsDefault = vm.riskRuleset.IsDefault !== undefined ? vm.riskRuleset.IsDefault : false;
    vm.riskRuleset.IsEnabled = vm.riskRuleset.IsEnabled !== undefined ? vm.riskRuleset.IsEnabled : true;
    vm.riskRuleset.BypassSecurityObjectValidation = vm.riskRuleset.BypassSecurityObjectValidation !== undefined ? vm.riskRuleset.BypassSecurityObjectValidation : false;
    vm.isCloning = isCloning;
    vm.downloadTemplate = downloadTemplate;


    vm.importChecked = importChecked;
    vm.getCustomRulesets = getCustomRulesets;
    vm.rulesetFormSubmit = rulesetFormSubmit;
    vm.addNewRiskRuleset = addNewRiskRuleset;
    vm.updateIneligibleBusinessProcessIds = updateIneligibleBusinessProcessIds;
    vm.hasIneligibleBusinessProcessId = hasIneligibleBusinessProcessId;
    vm.cancel = cancel;
    vm.checkDefaultEnabled = checkDefaultEnabled;

    activate();

    async function activate() {
        await hasSAP();
        if (vm.isCloning) {
            vm.actionTitle = 'Clone';
            setupCloningGrids();
        } else if (vm.riskRuleset.RulesetId) {
            vm.actionTitle = 'Edit';
        } else {
            vm.actionTitle = 'Create';
        }
    }

    function rulesetFormSubmit() {
        vm.disableSubmit = true;
        if (vm.isCloning) {
            cloneRiskRuleset();
        } else if (vm.riskRuleset.RulesetId) {
            updateRiskRuleset();
        } else if (vm.isImport) {
            importRiskRuleset(vm.files.files);
        } else {
            addNewRiskRuleset();
        }
    }

    // ----- CREATE RULESET -----
    function importChecked() {
        if (vm.isImport && !vm.customRiskRulesetDataSource) {
            getCustomRulesets();
        }
    }

    async function getCustomRulesets() {
        vm.loadingRiskRulesets = true;
        let response = await riskRulesetService.getRiskRulesetList();
        vm.loadingRiskRulesets = false;
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.customRiskRulesetDataSource = response.data.filter(ruleset => ruleset.IsCustom);
    }


    async function updateRiskRuleset() {
        vm.savingRiskRuleset = new createSpinner();
        const putBody = {
            "RulesetId": vm.riskRuleset.RulesetId,
            "Name": vm.riskRuleset.Name,
            "Description": vm.riskRuleset.Description,
            "IsDefault": vm.riskRuleset.IsDefault,
            "IsEnabled": vm.riskRuleset.IsEnabled
        };

        try {
            await riskRulesetService.updateRiskRuleset(vm.riskRuleset.RulesetId, putBody);
            await helperService.successfulSaveButton(vm.savingRiskRuleset);
            close();
            vm.disableSubmit = false;
        } catch (_) {
            vm.savingRiskRuleset.loadingValue = false;
            vm.disableSubmit = false;
        }
    }

    async function hasSAP() {
        let response = await dataService.getProducts();
        vm.hasSAP = response.data.some(product => product.Type === 'SAP');
    }

    async function downloadTemplate() {
        try {
            if (vm.hasSAP) {
                let response = await riskRulesetService.downloadRulesetTemplate('SAP');
                saveFile(response.data);
            } else {
                let response = await riskRulesetService.downloadRulesetTemplate();
                saveFile(response.data);
            }
        } catch {
            return;
        }
    }

    function saveFile(file) {
        FileSaver.saveAs(file, 'RiskRuleset.xlsx');
    }

    async function importRiskRuleset(file) {
        vm.savingRiskRuleset = new createSpinner();
        let fd = new FormData();
        const fileName = file[0].name;
        file = file[0].file;
        fd.append(fileName, file);

        if (vm.importType === 'New') {
            fd.append("RulesetName", vm.riskRuleset.Name);
            fd.append("RulesetDescription", vm.riskRuleset.Description);
            fd.append("IsDefaultRuleset", vm.riskRuleset.IsDefault);
            fd.append("BypassSecurityObjectValidation", vm.riskRuleset.BypassSecurityObjectValidation);
            fd.append("IsEnabled", vm.riskRuleset.IsEnabled);
        } else {
            fd.append("RulesetId", vm.selectedRiskRulesetToOverwrite.RulesetId);
        }

        try {
            let response = await riskRulesetService.importRiskRuleset(fd);
            await helperService.successfulSaveButton(vm.savingRiskRuleset);
            close(response.data);
        } catch (_) {
            vm.savingRiskRuleset.loadingValue = false;
            vm.disableSubmit = false;
        }
    }

    async function addNewRiskRuleset() {
        vm.savingRiskRuleset = new createSpinner();
        const postBody = {
            "Name": vm.riskRuleset.Name,
            "Description": vm.riskRuleset.Description,
            "IsDefault": vm.riskRuleset.IsDefault,
            "IsEnabled": vm.riskRuleset.IsEnabled
        };

        try {
            let response = await riskRulesetService.addNewRiskRuleset(postBody);
            await helperService.successfulSaveButton(vm.savingRiskRuleset);
            close(response.data);
            vm.disableSubmit = false;
        } catch (_) {
            vm.savingRiskRuleset.loadingValue = false;
            vm.disableSubmit = false;
        }
    }


    // ----- CLONE RULESET -----
    function setupCloningGrids() {
        vm.cloneRiskRulesetRisksGridDataSource = new kendo.data.DataSource({
            transport: {
                read: async options => {
                    let response = await riskRulesetService.getRisks(vm.riskRuleset.RulesetId);
                    response.data = $filter('orderBy')(response.data, 'Name');
                    response.data.forEach(obj => {
                        obj.isSelected = true;
                    });
                    options.success(response.data);
                }
            }
        });

        vm.cloneRiskRulesetBusinessProcessesGridDataSource = new kendo.data.DataSource({
            transport: {
                read: async options => {
                    let response = await riskRulesetService.getBusinessProcesses(vm.riskRuleset.RulesetId);
                    response.data = $filter('orderBy')(response.data, 'Name');
                    response.data.forEach(obj => {
                        obj.isSelected = true;
                    });
                    options.success(response.data);
                }
            }
        });

        let businessProcessesGridOptions = [
            { template: "<label class='switch'><input type='checkbox' ng-model='dataItem.isSelected' ng-change='rrmvm.updateIneligibleBusinessProcessIds(dataItem)'><i></i></label>", width: 55, filterable: false },
            { field: "Name", title: "Name", filterable: customAutoCompleteFilter }
        ];

        let risksGridOptions = [
            { template: "<label class='switch'><input type='checkbox' ng-model='dataItem.isSelected' ng-disabled='rrmvm.hasIneligibleBusinessProcessId(dataItem)'><i></i></label>", width: 55, filterable: false },
            { field: "Name", title: "Name", filterable: customAutoCompleteFilter }
        ];

        vm.cloneRiskRulesetBusinessProcessGridOptions = helperService.setNonPageableGridOptions(businessProcessesGridOptions, 300);
        vm.cloneRiskRulesetBusinessProcessGridOptions.noRecords = {
            template: "<div class='no-data-to-display-message'>No Business Processes exist for this ruleset</div>"
        };

        vm.cloneRiskRulesetRisksGridOptions = helperService.setNonPageableGridOptions(risksGridOptions, 300);
        vm.cloneRiskRulesetRisksGridOptions.noRecords = {
            template: "<div class='no-data-to-display-message'>No Risks exist for this ruleset</div>"
        };
    }

    async function cloneRiskRuleset() {
        vm.disableSubmit = true;

        if (vm.cloneRiskRulesetRisksGridDataSource._data.length === 0) {
            await vm.cloneRiskRulesetRisksGridDataSource.read();
        }

        vm.selectedBusinessProcessIds = vm.cloneRiskRulesetBusinessProcessesGridDataSource._data.filter(businessProcess => businessProcess.isSelected).map(businessProcess => businessProcess.Id);
        vm.selectedRiskIds = vm.cloneRiskRulesetRisksGridDataSource._data.filter(risk => risk.isSelected).map(risk => risk.Id);

        const data = {
            "Name": vm.riskRuleset.Name,
            "Description": vm.riskRuleset.Description,
            "IsDefault": vm.riskRuleset.IsDefault,
            "RiskIds": vm.selectedRiskIds,
            "BusinessProcessIds": vm.selectedBusinessProcessIds,
            "IsEnabled": vm.riskRuleset.IsEnabled
        };

        try {
            await riskRulesetService.cloneRiskRuleset(data);
            vm.disableSubmit = false;
            cancel();
        } catch (_) {
            vm.disableSubmit = false;
        }
    }

    function updateIneligibleBusinessProcessIds(businessProcess) {
        const businessProcessId = businessProcess.Id;
        if (businessProcess.isSelected) {
            const index = vm.ineligibileBusinessProcessIds.indexOf(businessProcessId);
            if (index > -1) {
                vm.ineligibileBusinessProcessIds.splice(index, 1);
            }
        } else {
            vm.ineligibileBusinessProcessIds.push(businessProcessId);
        }
    }

    function hasIneligibleBusinessProcessId(risk) {
        let hasIneligibleIds = false;

        risk.Objects.forEach(object => {
            if (vm.ineligibileBusinessProcessIds.includes(object.BusinessProcessId)) {
                hasIneligibleIds = true;
            }
        });
        if (hasIneligibleIds) {
            risk.isSelected = false;
        }
        return hasIneligibleIds;
    }

    function checkDefaultEnabled() {
        if (!vm.riskRuleset.IsEnabled) {
            vm.riskRuleset.IsDefault = false;
        }
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }

    function close() {
        $uibModalInstance.close();
    }

}