export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getTaskRecordings,
        getSelectedTaskRecordingOverview,
        importTaskRecording,
        deleteTaskRecording,
        bulkDeleteTaskRecordings,
        getMenuItems,
        renameTaskRecording,
        getSelectedSecurityLayerTypeItemDetails,
        addMissingMenuItems,
        createNewSecurityModel,
        exportMenuItems
    };

    return service;

    async function getTaskRecordings(productType) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/${productType}/taskrecording/`);
        } catch (error) {
            showError(error);
        }
    }

    async function getSelectedTaskRecordingOverview(productType, Id) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/${productType}/taskrecording/${Id}/securitylayer/overview`);
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    async function getSelectedSecurityLayerTypeItemDetails(productType, Id, securityLayerName, securityLayerType) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/${productType}/taskrecording/${Id}/securitylayer/${securityLayerName}/securitylayertype/${securityLayerType}/details`)
        } catch (error) {
            showError(error);
        }
    }

    async function getMenuItems(productType, Id) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/${productType}/taskrecording/${Id}/details`)
        } catch (error) {
            showError(error);
        }
    }

    async function importTaskRecording(productType, formData) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/${productType}/taskrecording`, formData, { transformRequest: angular.identity, headers: { 'Content-Type': undefined } });
        } catch (error) {
            showError(error);
        }
    }

    async function exportMenuItems(productType, Id) {
        let postData = {};
        postData.taskRecordingId = Id;
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/${productType}/taskrecording/${Id}/menuitems/export`, postData)
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    async function renameTaskRecording(productType, Id, formData) {
        try {
            return await $http.put(`${apiUrl}api/securitydesigner/${productType}/taskrecording/${Id}`, formData)
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    async function deleteTaskRecording(productType, Id) {
        try {
            return await $http.delete(`${apiUrl}api/securitydesigner/${productType}/taskrecording/${Id}`)
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    async function bulkDeleteTaskRecordings(productType, data) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/${productType}/taskrecording/bulkdelete`, data)
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    async function addMissingMenuItems(productType, Id, formData) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/${productType}/simulation/${Id}/addmissingobjects`, formData)
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    async function createNewSecurityModel(formData) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/simulation`, formData)
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

}