import swal from "sweetalert2";
import importTaskRecordingModalController from "./importTaskRecordingModal.controller";
import viewMenuItemsModalController from "./viewMenuItemsModal.controller";
import renameTaskRecordingModalController from "./renameTaskRecordingModal.controller";
import addMissingMenuItemsModalController from "./addMissingMenuItemsModal.controller";
import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($filter, $route, objectIdDataService, $uibModal, helperService, taskRecordingDataService) {

    const vm = this;

    vm.openImportTaskRecordingModal = openImportTaskRecordingModal;
    vm.getSelectedTaskRecordingOverview = getSelectedTaskRecordingOverview;
    vm.selectedSecurityTypeDropdownChange = selectedSecurityTypeDropdownChange;
    vm.getSelectedSecurityLayerTypeItemDetails = getSelectedSecurityLayerTypeItemDetails;
    vm.addMissingMenuItems = addMissingMenuItems;
    vm.openAddMissingMenuItemsModal = openAddMissingMenuItemsModal;
    vm.accessTypeForAddingFirst = accessTypeForAddingFirst;
    vm.selectAllTaskRecordings = selectAllTaskRecordings;
    vm.selectTaskRecording = selectTaskRecording;
    vm.deleteSelectedTaskRecordings = deleteSelectedTaskRecordings;

    vm.selectedTaskRecordings = [];

    vm.menuOptions = [
        {
            text: 'View Menu Items',
            click: item => {
                openViewMenuItemsModal(item.taskRecording);
            }
        },
        {
            text: 'Change Name',
            click: item => {
                openRenameTaskRecordingModal(item.taskRecording);
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: item => {
                deleteTaskRecording(item.taskRecording);
            }
        }
    ];

    activate();

    async function activate() {
        vm.productType = await getProductType();
        getTaskRecordings();
    }

    async function getProductType() {
        let objectId = await objectIdDataService.getObjectID();
        return helperService.getSecurityObjectDetailsFromObjectId(objectId).ProductType;
    }

    async function getTaskRecordings(newTaskRecordingId) {
        let response = await taskRecordingDataService.getTaskRecordings(vm.productType);
        response.data = $filter('orderBy')(response.data, 'Name');
        if (newTaskRecordingId) {
            let newTaskRecording = response.data.find(tr => tr.Id === newTaskRecordingId);
            response.data = response.data.filter(i => i.Id !== newTaskRecording.Id);
            response.data.unshift(newTaskRecording);
            vm.taskRecordingsDataSource = response.data;
            vm.selectedTaskRecording = newTaskRecording;
            getSelectedTaskRecordingOverview(newTaskRecording);
        } else {
            vm.taskRecordingsDataSource = response.data;
        }
    }

    async function getSelectedTaskRecordingOverview(taskRecording) {
        vm.selectedSecurityType = null;
        vm.securityLayerItem = null;
        vm.selectedTaskRecording = taskRecording;

        if (taskRecording) {
            vm.taskRecordingInProgress = true;

            getMenuItems();

            try {
                let response = await taskRecordingDataService.getSelectedTaskRecordingOverview(vm.productType, taskRecording.Id);
                vm.selectedSecurityObjects = response.data;
                vm.selectedSecurityObjects = $filter('orderBy')(vm.selectedSecurityObjects, ['-Count', 'SecurityLayerLabel']);
                vm.selectedSecurityObjectsCopy = [...vm.selectedSecurityObjects];
                vm.taskRecordingInProgress = false;
                vm.availableSecurityTypes = [...new Set(vm.selectedSecurityObjects.map(item => item.SecurityLayerType))];
            } catch {
                vm.taskRecordingInProgress = false;
            }
        }
    }

    async function getMenuItems() {
        let response = await taskRecordingDataService.getMenuItems(vm.productType, vm.selectedTaskRecording.Id);
        vm.menuItemsList = response.data;
        vm.menuItemsList = $filter('orderBy')(vm.menuItemsList, 'ObjectLabel');
    }

    function selectedSecurityTypeDropdownChange() {
        vm.securityLayerItem = null;
        vm.selectedSecurityObjects = [];
        if (vm.selectedSecurityType) {
            vm.selectedSecurityObjects = vm.selectedSecurityObjectsCopy.filter(item => item.SecurityLayerType === vm.selectedSecurityType);
        } else {
            vm.selectedSecurityObjects = vm.selectedSecurityObjectsCopy;
        }
    }

    async function getSelectedSecurityLayerTypeItemDetails(securityLayerItem) {
        vm.securityLayerItemDetailsLoading = true;
        vm.securityLayerItem = securityLayerItem;
        let response = await taskRecordingDataService.getSelectedSecurityLayerTypeItemDetails(vm.productType, securityLayerItem.TaskRecordingId, securityLayerItem.SecurityLayerName, vm.selectedSecurityType);
        vm.securityLayerItemObjects = response.data;
        vm.securityLayerItemObjects = $filter('orderBy')(vm.securityLayerItemObjects, 'SecurityObjectLabel');
        vm.securityLayerItemObjects.forEach(object => {
            if (object.AccessType === 'None') {
                let matchingMenuItem = vm.menuItemsList.find(menuItem => getAccessTypeForAdding(menuItem, object));
                object.AccessTypeForAdding = matchingMenuItem.AccessType;
            }
        });
        vm.objectsToAdd = vm.securityLayerItemObjects.filter(object => object.AccessTypeForAdding);
        vm.securityLayerItemDetailsLoading = false;
    }

    function getAccessTypeForAdding(menuItem, object) {
        return menuItem.ObjectName.toLowerCase() === object.SecurityObjectName.toLowerCase() && menuItem.ObjectType.toLowerCase() === object.SecurityObjectType.toLowerCase();
    }

    function accessTypeForAddingFirst(arr) {
        return !arr.AccessTypeForAdding;
    }

    async function deleteTaskRecording(taskRecording) {
        try {
            await swal(helperService.areYouSureParams('Delete Task Recording', `Are you sure you want to delete the ${taskRecording.Name} task recording?`, 'Delete'));
            await taskRecordingDataService.deleteTaskRecording(vm.productType, taskRecording.Id);
            if (taskRecording.Id === vm.selectedTaskRecording.Id) {
                vm.selectedTaskRecording = null;
                vm.selectedSecurityType = null;
                vm.securityLayerItem = null;
            }
            getTaskRecordings();
        } catch {
            return;
        }
    }

    function addMissingMenuItems() {
        vm.missingMenuItems = vm.objectsToAdd.map(object => {
            return {
                AccessType: object.AccessTypeForAdding,
                SecurityObjectId: object.SecurityObjectName,
                SecurityObjectType: object.SecurityObjectType.replace(/\s+/g, '') // Removes spaces from string.
            }
        });

        const data = {
            TaskRecordingId: vm.selectedTaskRecording.Id,
            SecurityLayerName: vm.securityLayerItem.SecurityLayerName,
            LayerType: vm.securityLayerItem.SecurityLayerType,
            ObjectsToAdd: vm.missingMenuItems
        };

        vm.partialDataObject = data;

    }

    async function openImportTaskRecordingModal() {
        let openModal = $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/taskRecordingManager/importTaskRecordingModal.html',
            controller: importTaskRecordingModalController,
            controllerAs: 'itrmvm',
            backdrop: 'static',
            resolve: {
                productType: () => vm.productType
            }
        });

        try {
            let newTaskRecordingId = await openModal.result;
            getTaskRecordings(newTaskRecordingId);
        } catch {
            openModal.close();
        }
    }

    function openViewMenuItemsModal(item) {
        $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/taskRecordingManager/viewMenuItemsModal.html',
            controller: viewMenuItemsModalController,
            controllerAs: 'vmivm',
            backdrop: 'static',
            resolve: {
                taskRecording: () => item,
                productType: () => vm.productType
            }
        });
    }

    async function openRenameTaskRecordingModal(item) {
        let openModal = $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/taskRecordingManager/renameTaskRecordingModal.html',
            controller: renameTaskRecordingModalController,
            controllerAs: 'rtrmvm',
            backdrop: 'static',
            resolve: {
                taskRecording: () => item,
                productType: () => vm.productType
            }
        });

        try {
            await openModal.result;
            getTaskRecordings();
        } catch {
            openModal.close();
        }
    }

    async function openAddMissingMenuItemsModal() {
        addMissingMenuItems();

        let openModal = $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/taskRecordingManager/addMissingMenuItemsModal.html',
            controller: addMissingMenuItemsModalController,
            controllerAs: 'ammivm',
            backdrop: 'static',
            resolve: {
                dataObject: () => vm.partialDataObject,
                missingItemsArray: () => vm.objectsToAdd
            }
        });

        try {
            await openModal.result;
            $route.reload();
        } catch {
            openModal.close();
        }
    }

    function selectTaskRecording(recording) {
        // either remove or add the recording to the list of selected recordings
        if (recording.Selected) {
            vm.selectedTaskRecordings.push(recording);
        } else {
            vm.selectedTaskRecordings = vm.selectedTaskRecordings.filter(item => item.Id !== recording.Id);
        }
        vm.allRecordingsSelected = vm.selectedTaskRecordings.length === vm.taskRecordingsDataSource.length;
    }

    function selectAllTaskRecordings() {
        vm.taskRecordingsDataSource.forEach(taskRecording => {
            taskRecording.Selected = vm.allRecordingsSelected;
        });
        vm.selectedTaskRecordings = vm.allRecordingsSelected ? [...vm.taskRecordingsDataSource] : [];
    }

    async function deleteSelectedTaskRecordings() {
        vm.bulkDeletePending = new createSpinner();
        let selectedTaskRecordingIds, postBody;
        selectedTaskRecordingIds = vm.selectedTaskRecordings.map(taskRecording => taskRecording.Id);
        postBody = { "TaskRecordingIds": selectedTaskRecordingIds };
        try {
            await taskRecordingDataService.bulkDeleteTaskRecordings(vm.productType, postBody);
            vm.bulkDeletePending.loadingValue = false;
            await getTaskRecordings();
            vm.allRecordingsSelected = false;
            vm.selectAllTaskRecordings();
            vm.selectRecordingsMode = false;
            vm.selectedTaskRecording = null;
            vm.selectedSecurityType = null;
        } catch {
            vm.bulkDeletePending.loadingValue = false;
        }
    }
}