import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, userMappingOptions) {

    const vm = this;

    vm.saveUserMapping = saveUserMapping;
    vm.dismiss = dismiss;

    activate();

    function activate() {
        vm.userMappingOptions = userMappingOptions;
    }

    function saveUserMapping() {
        $uibModalInstance.close(vm.selectedItem);
    }

    function dismiss() {
        $uibModalInstance.dismiss();

    }
}