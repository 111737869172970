export default /*@ngInject*/ function ($scope) {

    //If $scope variable doesnt exist in child it looks in parent

    $scope.addGroupToList = function (group) {
        $scope.vm.ListOfAvailableAADGroups.forEach(function (row, i) {
            if (row.Id === group.Id) {
                $scope.vm.ListOfAvailableAADGroups.splice(i, 1);
                $scope.vm.ListOfSelectedAADGroups.push(group);
            }
        });
        $scope.vm.IsRisksReviewed = null;
    };

    $scope.deleteSelectedGroup = function (group) {
        $scope.vm.ListOfSelectedAADGroups.forEach(function (row, i) {
            if (row.Id === group.Id) {
                $scope.vm.ListOfSelectedAADGroups.splice(i, 1);
                $scope.vm.ListOfAvailableAADGroups.push(row);
            }
        });

        $scope.vm.IsRisksReviewed = null;
    };

    $scope.setSelectedRoleTemplates = function (selectedRoleTemplates) {
        $scope.vm.selectedRoleTemplates = selectedRoleTemplates;
    };
}