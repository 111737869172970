import { customDatePickerFilter, customAutoCompleteFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";

export default /*@ngInject*/ function ($scope, $rootScope, SavedReportSelectorService, objectIdDataService, CustomReportServices, $filter, $http, reportsService, reportsDataService, helperService, snapshotsDataService, dataService) {

    const vm = this;

    $scope.$on('kendoWidgetCreated', function () {
        if (vm.grid) {
            vm.gridReady = true;
            runSavedReport();
        }
    });

    vm.recordTypeDropdownChange = recordTypeDropdownChange;
    vm.populateCriteriaModel = populateCriteriaModel;
    vm.generateGridWithSelectedValues = generateGridWithSelectedValues;
    vm.applySelectedFilter = applySelectedFilter;
    vm.deleteFilter = deleteFilter;
    vm.clearAllFilters = clearAllFilters;
    vm.onReadGrid = onReadGrid;

    vm.helperService = helperService;

   async function activate() {
        createSnapshotChangesGrid();
        getRecordTypes();
        recordTypeDropdownChange();
        await getObjectId();


        vm.dateCriteria = [
            {
                "DataType": "DateTime",
                "FieldName": "Date",
                "IsNullable": false
            }
        ];

        getReportMetadata().then(_ => {
            vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
            if ($rootScope.loadedSavedReport) {
                runSavedReport();
            }
        });
    }

    activate();



    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    // ---

    async function getObjectId() {
        const objectId = await objectIdDataService.getObjectID();
        vm.ObjectId = objectId;
        let securityObject = helperService.getSecurityObjectDetailsFromObjectId(objectId);
        vm.reportName = securityObject.Name;
        vm.reportId = securityObject.ReportID;
        vm.filtersDataSource = CustomReportServices.setfiltersDataSource(vm.ObjectId);
    }

    function runSavedReport() {
        if (vm.gridReady && $rootScope.loadedSavedReport && vm.recordTypeDataSource && vm.recordDataSource && vm.selectedRecord && vm.filterCriteria.fields) {
            // --- FOR ADVANCED FILTERING ---
            vm.filterCriteria.query = reportsService.getSavedReportFilters();
            vm.filterCriteria.dateQuery = reportsService.getSavedReportDateFilters();
            vm.filterCriteria.needsUpdate = true;
            // ---
            generateGridWithSelectedValues();
        } else if (vm.gridReady && !$rootScope.loadedSavedReport && vm.recordTypeDataSource && vm.recordDataSource && vm.selectedRecord && vm.filterCriteria.fields) {
            // We want the necessary report details available to be able to use the report toolbar without running the report
            populateCriteriaModel();
        }
    }

    function createSnapshotChangesGrid() {

        vm.mainGridOptions = helperService.setMainGridOptions(null, 600);
        vm.mainGridOptions.autoBind = false;

        vm.mainGridColumns = [
            { field: "Date", title: "Date", template: "{{ dataItem.Date | date:'short'}}", filterable: customDatePickerFilter },
            { field: "RecordId", title: "Record Id", filterable: customAutoCompleteFilter },
            { field: "RecordName", title: "Record Name", filterable: customAutoCompleteFilter },
            { field: "Field", title: "Field", filterable: customAutoCompleteFilter },
            { field: "Value", title: "Value", filterable: customAutoCompleteFilter }
        ];

        // Don't allow filtering and sorting yet because they will force the report to run
        if (!$rootScope.loadedSavedReport) {
            vm.mainGridOptions.sortable = false;

            vm.mainGridOptions.filterable = false;
        }

        vm.gridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: angular.extend(halSchemaKendoGrid, {
                fields: {
                    Date: { type: "date" }
                }
            }),
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: async function (options) {
                    delete $rootScope.loadedSavedReport;

                    let data = {
                        'RecordTypeId': vm.recordType,
                        'RecordId': vm.record
                    };

                    vm.sortsAndFilters = helperService.prepareSortsAndFilters(options);
                    data['QueryInput'] = vm.sortsAndFilters;
                    // --- FOR ADVANCED FILTERING ---
                    if (vm.filterCriteria.query) {
                        data['QueryInput'].Filters = data['QueryInput'].Filters.concat(vm.filterCriteria.query);
                        data['QueryInput'].DateFilters = vm.filterCriteria.dateQuery;
                    }
                    // ---

                    // if tabs are on, save report info for the tab
                    if ($rootScope.tabs.length > 0) {
                        reportsService.saveTabReportInfo(vm);
                    }
                    $http({
                        method: 'POST',
                        url: apiUrl + 'api/audittrail/ns/snapshot/report/changes',
                        data: data,
                        params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                    }).then(function (response) {

                        data = response.data._embedded.reportData;

                        options.success(response.data);
                        vm.enableGrid = true;

                    }).catch(response => {
                        const timeout = 408;

                        if (response.status !== timeout) {
                            helperService.showErrorMessage(response.data);
                        }
                    });
                }
            }
        });

        setLayout();

    }

    async function getRecordTypes() {
        let response = await snapshotsDataService.getRecordType();
        response.data = $filter('filter')(response.data, { Enabled: true });
        response.data = $filter('orderBy')(response.data, 'DisplayName');
        vm.recordTypeDataSource = response.data;

        vm.selectedRecordType = SavedReportSelectorService.checkSavedReportForRecordType($rootScope.loadedSavedReport, response.data, vm.selectedRecordType);
        vm.recordTypeDropdownChange();
        recordTypeDropdownChange();
        runSavedReport();
    }

    async function recordTypeDropdownChange() {
        try {
            if (vm.selectedRecordType) {
                let response = await snapshotsDataService.getDistinctRecordType(vm.selectedRecordType.Id);
                let allRecords = { "RecordName": "All Records", "RecordId": 'AllObjectsInMultiSelect' };
                response.data.unshift(allRecords);

                vm.recordDataSource = response.data;
                vm.selectedRecord = response.data[0];

                vm.selectedRecord = SavedReportSelectorService.checkSavedReportForRecord($rootScope.loadedSavedReport, response.data, vm.selectedRecord);
                populateCriteriaModel();
                runSavedReport();
            }
        } catch {
            return;
        }
    }

    function populateCriteriaModel() {
        // do not continue if the proper criteria are not loaded
        if (!vm.selectedRecordType || vm.selectedRecord === undefined) {
            return;
        }
        let criteria = [];

        vm.recordType = vm.selectedRecordType.Id;

        if (vm.selectedRecord.RecordId === 'AllObjectsInMultiSelect') {
            vm.record = null;
        } else {
            vm.record = vm.selectedRecord.RecordId;
        }

        criteria.push(CustomReportServices.CreateCriteria("RecordTypeId", 'RecordType', vm.selectedRecordType.DisplayName, vm.selectedRecordType.Id));
        criteria.push(CustomReportServices.CreateCriteria("RecordId", 'Record', vm.selectedRecord.RecordName, vm.selectedRecord.RecordId));
        vm.reportDetailsModel = criteria;

        // if tabs are on, save report info for the tab
        if ($rootScope.tabs.length > 0) {
            reportsService.saveTabReportInfo(vm);
        }
    }

    function generateGridWithSelectedValues() {

        if (!$rootScope.loadedSavedReport) {
            vm.grid.dataSource._filter = null;
            vm.grid.dataSource._sort = null;
        }

        populateCriteriaModel();
        vm.hideExportOptionsFromSubmit = true;

        // Add back the sorting and filtering options if we are running the report for the first time
        if (!$rootScope.loadedSavedReport) {
            vm.grid.setOptions({
                filterable: {
                    mode: "row"
                },
                sortable: true
            });
        }

        SavedReportSelectorService.queryGridWithOptions($rootScope.loadedSavedReport, vm.grid, true);
    }


    vm.filterManagerGridOptions = CustomReportServices.setfilterManagerGridOptions();

    vm.filterDropdownOptions = helperService.getFilterDropdownOptions();

    function applySelectedFilter(selectedFilter) {
        const currentOptions = vm.grid.getOptions();

        if (selectedFilter && selectedFilter.Filters && selectedFilter.Filters.length > 0) {
            currentOptions.dataSource.filter = helperService.convertFiltersForGrid(_, selectedFilter.Filters);
            try {
                vm.grid.dataSource._filter = currentOptions.dataSource.filter;
                vm.grid.dataSource.read();
            }
            catch (err) {
                vm.gridDataSource.filter({});
            }
        }
    }

    function deleteFilter(id) {
        CustomReportServices.deleteFilterFilterManager(vm, id);
    };

    function clearAllFilters() {
        vm.gridDataSource.filter({});
    };

    function onReadGrid() {
        vm.gridDataSource.read();
    }

    function setLayout() {
        if (!$rootScope.loadedSavedReport) {
            CustomReportServices.loadSavedGridLayout(vm, null);
        }
    }

    // --- FOR ADVANCED FILTERING ---
    async function getReportMetadata() {
        let response = await reportsDataService.getReportMetadata(vm.reportId);
        vm.reportMetadata = response.data._embedded.reportData;
        vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');
        vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
        return vm.reportMetadata;
    }
}
    // ---
