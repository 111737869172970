import identityManagerConfigTabController from "./identityManagerConfigTab.controller";


/* identityManagerConfigTab.component.js */

/**
 * @desc component for configuring settings for Identity Manager
 * @example <identity-manager-config-tab></identity-manager-config-tab>
 */

const identityManagerConfigTab = {
    bindings: {
        currentConfig: '<',
        originalConfig: '<',
        products: '<',
        tabSelected: '<',
        updateConfiguration: '&'
    },
    controller: identityManagerConfigTabController,
    controllerAs: 'imcvm',
    templateUrl: '/App/Components/configuration/identityManagerConfigTab/identityManagerConfigTab.tmpl.html'
};

export default identityManagerConfigTab;