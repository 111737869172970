import controller from "./home.controller";

/* home.component.js */

/**
 * @desc component home
 * @example <home></home>
 */

const home = {
    controller: controller,
    controllerAs: 'hvm',
    templateUrl: '/App/Components/home/home.tmpl.html'
};

export default home;