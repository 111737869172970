import importDataChangesModalController from "./importDataChangesModal.controller";

export default /*@ngInject*/ function ($uibModal) {
    const vm = this;
    vm.openImportModal = openImportModal;

    async function openImportModal() {
        let importModal = $uibModal.open({
            templateUrl: '/App/Shared/Components/DataChangesImport/importDataChangesModal.tmpl.html',
            controller: importDataChangesModalController,
            controllerAs: 'idcmvm',
            backdrop: 'static',
            resolve: {
                reportId: () => vm.reportId
            }
        });

        try {
            await importModal.result;
        } catch {
            return;
        };
    };
}