import manageDataActionController from "./manageDataAction.controller";

/* manageDataAction.component.js */

/**
 * @desc component for each action within manage data feature
 * @example <manage-data-action></manage-data-action>
 */

const manageDataAction = {
    bindings: {
        product: '<',
        action: '<'
    },
    controller: manageDataActionController,
    controllerAs: 'mdavm',
    templateUrl: '/App/Components/ManageDataV2/Action/manageDataAction.tmpl.html'
};

export default manageDataAction;