import editProductNameModalController from "../editProductNameModal.controller";

export default /*@ngInject*/ function ($uibModal, ViewProductImportPageService) {
    const vm = this;

    vm.$onInit = init;

    vm.ViewProductImportPageService = ViewProductImportPageService;
    vm.updateNSRoleDropdown = updateNSRoleDropdown;
    vm.getImportStatusClass = getImportStatusClass;
    vm.openEditProductName = openEditProductName;

    function init() {
        vm.uploadOnlyProducts = ["OREPM", "SAPB1", "Zuo", "CNC"];
    }

    function updateNSRoleDropdown(configChanged) {
        vm.nsRolesChanged = configChanged;
    }

    function getImportStatusClass(status) {
        const classes = {
            "Completed": "toast-success",
            "InProgress": "toast-loading",
            "Error": "toast-error"
        }

        return classes[status];
    }

    function openEditProductName() {
        $uibModal.open({
            templateUrl: '/App/Shared/Partials/EditProductNameModal.html',
            controller: editProductNameModalController,
            controllerAs: 'epnmcvm',
            backdrop: 'static',
            resolve: {
                product: () => vm.product
            }
        });
    }
}