import scheduleReportController from "./scheduleReport.controller";

/* scheduleReport.component.js */

/**
 * @desc component for scheduling reports
 * @example <schedule-report></schedule-report>
 */

const scheduleReport = {
   bindings: {
      resolve: '<',
      modalInstance: '<'
   },
    controller: scheduleReportController,
    controllerAs: 'srvm',
   templateUrl: '/App/Shared/Components/ScheduleReport/scheduleReport.tmpl.html'
};

export default scheduleReport;