/* outstandingApprovals.component.js */

/**
 * @desc component to display Outstanding Approvals
 * @example <outstanding-approvals></outstanding-approvals>
 */

const outstandingApprovals = {
    templateUrl: '/App/Components/outstandingApprovals/outstandingApprovals.tmpl.html'
};

export default outstandingApprovals;