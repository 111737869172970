import supervisorAssignmentsController from "./supervisorAssignments.controller";

/* supervisorAssignments.component.js */

/**
 * @desc component for viewing supervisor assignments
 * @example <supervisor-assignments></supervisor-assignments>
 */

const supervisorAssignments = {
    controller: supervisorAssignmentsController,
    controllerAs: 'savm',
    templateUrl: '/App/Components/SupervisorAssignments/supervisorAssignments.html'
};

export default supervisorAssignments;