import incidentManagementController from "./incidentManagement.controller";

/* incidentManagement.component.js */

/**
 * @desc component for Oracle EBS Transaction Monitoring Incident Management
 * @example <transaction-monitoring-incident-management></transaction-monitoring-incident-management>
 */

const transactionMonitoringIncidentManagement = {
    controller: incidentManagementController,
    controllerAs: 'imvm',
    templateUrl: '/App/Components/TransactionMonitoring/Incidents/incidentManagement.tmpl.html'
};

export default transactionMonitoringIncidentManagement;