import { standardGridPageSize, customAutoCompleteFilter } from "../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $filter, helperService, AuditTrailEntityDataService, selectedTemplate) {

    const vm = this;
    vm.numberOfTablesValid = false;
    vm.addingTable = false;
    vm.selectedTemplate = selectedTemplate;

    vm.validateNumberOfTables = validateNumberOfTables;
    vm.addTable = addTable;
    vm.cancel = cancel;

    function validateNumberOfTables() {
        vm.numberOfTablesValid = false;

        if (vm.selectedTablesDataSource.data().length > 0) {
            vm.numberOfTablesValid = true;
        }
    }

    //Save Template
    function addTable(table) {
        let newTable = null;
        let promises = [];
        vm.addingTable = true;

        if (!vm.selectedTemplate.TrackedTableList) {
            vm.selectedTemplate.TrackedTableList = [];
        }

        AuditTrailEntityDataService.getAXFieldsByTableId(table.Id).then(function (response) {
            table.TrackedFields = response.data;

            //Remove just added table from available list
            vm.availableTablesDataSource.data().forEach(function (row, i) {
                if (row) {
                    if (row.Id === table.Id) {
                        vm.availableTablesDataSource.data().splice(i, 1);
                    }
                }
            });

            newTable = AuditTrailEntityDataService.convertToTrackedTable(table);
            vm.selectedTemplate.TrackedTableList.push(newTable);
            vm.addingTable = false;
        }, function errorCallback(response) {
            vm.addingTable = false;
            helperService.showErrorMessage(response.data);
        });

    }

    function cancel() {
        $uibModalInstance.close();
    }

    vm.availableTablesGridColumns = [
        { field: "Label", title: "Label", filterable: customAutoCompleteFilter },
        { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
        { template: "<a ng-disabled='nattmvm.addingTable === true' ng-click='nattmvm.addTable(dataItem)'><i class='fa fa-plus pull-right text-success'></i></a>", title: " ", width: 20, filterable: { multi: true } }
    ];

    vm.availableTablesDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: async function (options) {
                try {
                    let response = await AuditTrailEntityDataService.getAllAXTables();
                    let allTables = response.data;

                    if (vm.selectedTemplate.TrackedTableList.length > 0) {
                        allTables.forEach(function (table) {
                            if (vm.selectedTemplate.TrackedTableList.some(function (allTable) { return allTable.Id === table.Id; })) {
                                let index = allTables.findIndex(newTable => newTable.Id === table.Id);
                                allTables.splice(index, 1);
                            }
                        });
                    }
                    allTables = $filter('orderBy')(response.data, 'Name');
                    options.success(allTables);
                } catch {
                    options.error();
                }
            }
        }

    });


    vm.availableTablesGridOptions = helperService.setMainGridOptions(null, 300);
    vm.availableTablesGridOptions.columns = vm.availableTablesGridColumns;
    vm.availableTablesGridOptions.selectable = false;

    vm.selectedTablesGridColumns = [
        { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
        { template: "<a class=\"k-button\" ng-click=\"nattmvm.deleteSelectedTable(#=Id#)\">Remove</a>", "title": "Remove", width: 100 }];

    vm.selectedTablesGridOptions = helperService.setMainGridOptions(null, 300);
    vm.selectedTablesGridOptions.columns = vm.selectedTablesGridColumns;
    vm.selectedTablesGridOptions.autoBind = true;
    vm.selectedTablesGridOptions.selectable = false;

    vm.selectedTablesDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize
    });


}