export default /*@ngInject*/ function ($http, errorsDataService) {
    var service = {
        getMitigations,
        addMitigation,
        modifyMitigation,
        removeMitigation
    };

    return service;

    async function getMitigations() {
        try {
            return await $http.get(`${apiUrl}api/arm/mitigations`);
        } catch (err) {
            showError(err);
        }
    }

    async function addMitigation(data) {
        try {
            return await $http.post(`${apiUrl}api/arm/mitigations`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function modifyMitigation(mitigationId, data) {
        try {
            return await $http.put(`${apiUrl}api/arm/mitigations/${mitigationId}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function removeMitigation(mitigationId) {
        try {
            return await $http.delete(`${apiUrl}api/arm/mitigations/${mitigationId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }
}