import angular from 'angular';
import applyDefaultMitigations from '../Components/applyDefaultMitigations/applyDefaultMitigations.component';
import assignRisks from '../Components/assignRisks/assignRisks.component';
import controlLibrary from '../Components/controlLibrary/controlLibrary.component';
import maintainBusinessCycles from '../Components/maintainBusinessCycles/maintainBusinessCycles.component';
import maintainRiskRulesetController from '../Components/maintainRiskRuleset/maintainRiskRuleset.controller';
import maintainMitigations from '../Components/maintainMitigations/maintainMitigations.component';
import riskLevels from '../Components/maintainRiskLevels/riskLevels.component';
import maintainStatuses from '../Components/maintainStatuses/maintainStatuses.component';
import ManageArchivesViewModel from '../Components/ManageArchives/ManageArchivesViewModel';
import startNewAudit from '../Components/startNewAudit/startNewAudit.component';
import ApplyAutoMitigationViewModel from '../Components/Oracle/ApplyAutoMitigation/ApplyAutoMitigationViewModel';
import transferRulesetController from '../Components/transferRuleset/transferRuleset.controller';
import peoplesoftAutoMitigations from '../Components/Peoplesoft/autoMitigations/autoMitigations.component';
import autoMitigations from '../Components/AutoMitigations/autoMitigations.component';
import manageConditions from '../Components/maintainRiskRuleset/objects/manageConditions/manageConditions.component';
import riskLevelsGrid from '../Components/maintainRiskLevels/riskLevelsGrid/riskLevelsGrid.component';
import maintainBusinessProcessController from '../Components/MaintainBusinessProcess/maintainBusinessProcess.controller';

export default angular
    .module('app.accessRiskMonitor.setup', [])
    .component("applyDefaultMitigations", applyDefaultMitigations)
    .component("assignRisks", assignRisks)
    .component("controlLibrary", controlLibrary)
    .component("maintainBusinessCycles", maintainBusinessCycles)
    .controller("maintainBusinessProcessController", maintainBusinessProcessController)
    .controller("maintainRiskRulesetController", maintainRiskRulesetController)
    .component("maintainMitigations", maintainMitigations)
    .component('riskLevelsGrid', riskLevelsGrid)
    .component('riskLevels', riskLevels)
    .component("maintainStatuses", maintainStatuses)
    .controller("manageArchivesViewModel", ManageArchivesViewModel)
    .component("startNewAudit", startNewAudit)
    .controller("applyAutoMitigationViewModel", ApplyAutoMitigationViewModel)
    .controller("transferRulesetController", transferRulesetController)
    .component("peoplesoftAutoMitigations", peoplesoftAutoMitigations)
    .component('autoMitigations', autoMitigations)
    .component('manageConditions', manageConditions)
    .name;