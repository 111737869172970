export default /*@ngInject*/ function ($http, FileSaver, helperService) {
    const service = {
        downloadReportExport,
        downloadReportExportFromNotification
    };

    return service;

    function downloadReportExport(item) {
        $http.get(apiUrl + 'api/core/exports/download/' + item.Id, { responseType: "blob" }).then(function successCallback(response) {
            if (item.Type === 'PDF') {
                FileSaver.saveAs(response.data, item.Name + '.pdf');
            } else if (item.Type === 'Excel') {
                FileSaver.saveAs(response.data, item.Name + '.xlsx');
            } else if (item.Type === 'Csv') {
                FileSaver.saveAs(response.data, item.Name + '.csv');
            }

            if (item.Status === 'ReadyForDownload') {
                item.Status = 'Downloaded';
            }

        }, function errorCallback(response) {
            helperService.showErrorMessage(response.data);
        });
    }

    function downloadReportExportFromNotification(item) {
        $http.get(apiUrl + 'api/core/exports/download/' + item.ExportId, { responseType: "blob" }).then(function successCallback(response) {
            FileSaver.saveAs(response.data, item.ExportName);
        }, function errorCallback(response) {
            if (response.status === 404) {
                helperService.showErrorMessage("Export no longer exists");
            } else {
                helperService.showErrorMessage(response.data);
            }
        });
    }


}