import { customAutoCompleteFilter, standardGridPageSize } from "../../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($filter, $uibModalInstance, salesforceService, helperService, requestUserId, type) {

    var vm = this;

    vm.typeDisplay = type.charAt(0).toUpperCase() + type.slice(1)
    vm.searchComplete = searchComplete;
    vm.searchKeyDown = searchKeyDown;
    vm.changeUserRowSelected = changeUserRowSelected;
    vm.selectUser = selectUser;
    vm.dismiss = dismiss;

    activate();

    function activate() {
        // TODO: Verify column names and fields
        let columns = [
            { field: "Name", title: "Name", filterable: false },
            { field: "Username", title: "Username", filterable: false }
        ];

        vm.usersGridOptions = helperService.setMainGridOptions(columns, 400);
        vm.usersGridOptions.autoBind = false;
        vm.usersGridOptions.pageable = {
            alwaysVisible: false
        }

        vm.usersGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let { data } = await salesforceService.getUsers(vm.userSearchString, "name");

                    data.map(user => {
                        user.Name = `${user.FirstName} ${user.LastName}`
                    });

                    // Remove request user from list as they cannot be selected 
                    data = data.filter(user => user.Id !== requestUserId);

                    data = $filter('orderBy')(data, 'FirstName');
                    options.success(data);
                }
            }
        });
    }

    function searchComplete() {
        if (vm.userSearchString === '' || !vm.userSearchString) {
            let data = vm.usersGridDataSource.data();

            data.forEach(item => {
                vm.usersGridDataSource.remove(item);
            });
        } else {
            vm.usersGridDataSource.read();
        }
    }

    function searchKeyDown () {
        vm.searchUserSelected = null;
    }

    function changeUserRowSelected(kendoEvent) {
        var grid = kendoEvent.sender;

        vm.selectedUser = grid.dataItem(grid.select());
    }

    function selectUser() {
        $uibModalInstance.close(vm.selectedUser);
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }
}