import riskLevelsController from "./riskLevels.controller";

/* riskLevels.component.js */

/**
 * @desc component to display risk levels view
 * @example <risk-levels></risk-levels>
 */

const riskLevels = {
    controller: riskLevelsController,
    controllerAs: 'rlvm',
    templateUrl: '/App/Components/maintainRiskLevels/riskLevels.tmpl.html'
};

export default riskLevels;