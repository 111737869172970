import maintainUserGroupsController from "./maintainUserGroups.controller";

/* maintainUserGroups.component.js */

/**
 * @desc component for maintaining audit trail user groups
 * @example <maintain-user-groups</maintain-user-groups>
 */

const maintainUserGroups = {
    controller: maintainUserGroupsController,
    controllerAs: 'mugvm',
    templateUrl: '/App/Components/NetSuiteAuditTrail/MaintainUserGroups/maintainUserGroups.tmpl.html'
};

export default maintainUserGroups;