import ZendeskTicketModalController from '../../../Components/DataChanges/Zendesk/ZendeskTicketModalController';
import ServiceNowTicketModalController from '../../../Components/DataChanges/ServiceNow/ServiceNowTicketModalController';
import JiraTicketModalController from '../../../Components/DataChanges/Jira/JiraTicketModalController';
import customTicketModalController from '../../../Components/DataChanges/customTicket/customTicketModalController';
import FreshServiceTicketModalController from '../../../Components/DataChanges/FreshService/FreshServiceTicketModalController';

export default /*@ngInject*/ function ($scope, $uibModal, $http, helperService) {

    const vm = this;

    vm.$onInit = onInit;

    function onInit() {
        vm.dataItem = vm.params;

        $scope.lastClickedDataItemRecord = vm.dataItem;
        $scope.lastClickedDataItemRecord.auditLogTicket = false;

        if (vm.selectedProduct) {
            $scope.lastClickedDataItemRecord.ProductId = vm.selectedProduct.ID;
            $scope.lastClickedDataItemRecord.ProductType = vm.selectedProduct.Type;
        } else {
            $scope.lastClickedDataItemRecord.auditLogTicket = true;
        }
    }
    
    vm.openJiraTicketModal = openJiraTicketModal;
    vm.openServiceNowTicketModal = openServiceNowTicketModal;
    vm.openZendeskTicketModal = openZendeskTicketModal;
    vm.openCustomTicketModal = openCustomTicketModal;
    vm.openFreshServiceTicketModal = openFreshServiceTicketModal;
    vm.removeTicketLinkByTicketId = removeTicketLinkByTicketId;

    async function openJiraTicketModal() {
        const modal = $uibModal.open({
            templateUrl: 'App/Components/DataChanges/Jira/JiraTicketModal.html',
            controller: JiraTicketModalController,
            controllerAs: 'jtmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                lastClickedDataItemRecord: () =>  $scope.lastClickedDataItemRecord
            }
        });

        try {
            await modal.result;
            vm.onDelete();
        } catch {
            return;
        }
    }

    async function openFreshServiceTicketModal() {
        const modal = $uibModal.open({
            templateUrl: 'App/Components/DataChanges/FreshService/FreshServiceTicketModal.html',
            controller: FreshServiceTicketModalController,
            controllerAs: 'fstmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                lastClickedDataItemRecord: () => $scope.lastClickedDataItemRecord
            }
        });

        try {
            await modal.result;
            vm.onDelete();
        } catch {
            return;
        }
    }

    async function openServiceNowTicketModal() {
        const modal = $uibModal.open({
            templateUrl: 'App/Components/DataChanges/ServiceNow/ServiceNowTicketModal.html',
            controller: ServiceNowTicketModalController,
            controllerAs: 'sntmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                lastClickedDataItemRecord: () =>  $scope.lastClickedDataItemRecord
            }
        });

        try {
            await modal.result;
            vm.onDelete();
        } catch {
            return;
        }
    }

    async function openZendeskTicketModal() {
        const modal = $uibModal.open({
            templateUrl: 'App/Components/DataChanges/Zendesk/ZendeskTicketModal.html',
            controller: ZendeskTicketModalController,
            controllerAs: 'ztmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                lastClickedDataItemRecord: () =>  $scope.lastClickedDataItemRecord
            }
        });

        try {
            await modal.result;
            vm.onDelete();
        } catch {
            return;
        }
    }

    async function openCustomTicketModal() {
        const modal = $uibModal.open({
            templateUrl: 'App/Components/DataChanges/customTicket/customTicketModal.html',
            controller: customTicketModalController,
            controllerAs: 'ctmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                lastClickedDataItemRecord: () => $scope.lastClickedDataItemRecord
            }
        });

        try {
            await modal.result;
            vm.onDelete();
        } catch {
            return;
        }
    }

    async function removeTicketLinkByTicketId() {
        try { 
            if ($scope.lastClickedDataItemRecord.auditLogTicket) {
                const putData = {
                    "AuditId": $scope.lastClickedDataItemRecord.AuditId,
                    "UserId": $scope.lastClickedDataItemRecord.UserId,
                    "ObjectName": $scope.lastClickedDataItemRecord.ObjectName
                }

                await $http.put(`${apiUrl}api/core/auditlogs/disassociateticket`, putData);
            } else {
                await $http.delete(apiUrl + "api/audittrail/tickets/" + vm.dataItem.AtHeaderTicketId);
            }
            vm.onDelete();
        } catch (response) {
            helperService.showErrorMessage(response.data);
        }
    }
}