import roleMultiselectDropdownController from "./roleMultiselectDropdown.controller";

/* roleMultiselectDropdown.component.js */

/**
 * @desc component for selecting multiple roles from a paginated list of all security roles
 * @example <role-multiselect-dropdown></role-multiselect-dropdown>
 */

const roleMultiselectDropdown = {
    bindings: {
        altText: '@',
        allRolesOption: '<',
        selectedProduct: '<',
        roleDataSource: '=',
        roleSelectedIds: '=',
        runSavedReport: '&',
        onUpdateRoles: '&',
        toggleIntraResponsibility: '&'
    },
    controller: roleMultiselectDropdownController,
    controllerAs: 'rmdvm',
    templateUrl: '/App/Shared/Components/roleMultiselectDropdown/roleMultiselectDropdown.tmpl.html'
};

export default roleMultiselectDropdown;