import { customAutoCompleteFilter, standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";
import riskAnalysisUserRisksMitigationModalController from "./riskAnalysisUserRisksMitigationModal.controller";

export default /*@ngInject*/ function ($scope, helperService, $rootScope, $uibModal) {
    //Show Summary screen by default

    var vm = $scope.vm;

    vm.requestRiskMitigation = requestRiskMitigation;

    $scope.showSummary = true;
    $scope.showUserRiskDetails = false;
    $scope.showBusinessProcessDetails = false;

    //Watch for role change and update navigation and user security object lists
    $scope.$on('broadcast-im-wizardstepchange', function (event, args) {
        if (args != null && args.toStep === 'Risk Analysis') {
            $scope.showSummary = true;
        }
        $scope.showUserRiskDetails = false;
        $scope.showBusinessProcessDetails = false;
    });

    $scope.showBPSummary = function () {

        var hasBP = false;
        //Check to see if any of the selected applications require the Business Process section on Risk Analysis
        if ($scope.vm.availableApplications != null) {

            $scope.vm.availableApplications.forEach(function (app) {
                if (app.selected) {
                    var product = $rootScope.availableProductsForUser.find(function (product) {
                        if (app.Name === 'Universal') {
                            return product.Name === app.ProductName;
                        } else {
                            return product.Name === app.Name;
                        }
                    });
                    if (product != null) {
                        hasBP = true;
                    }
                }
            });
        }
        return hasBP;
    };

    $scope.toggleScreen = function (screen) {

        $scope.showSummary = false;
        $scope.showUserRiskDetails = false;
        $scope.showBusinessProcessDetails = false;

        if (screen == 'UserRiskDetails') {
            $scope.showUserRiskDetails = true;
        }
        if (screen == 'BusinessProcessDetails') {
            $scope.showBusinessProcessDetails = true;
        }
        if (screen == 'Summary') {
            $scope.showSummary = true;
        }
    };

    var columnsFromVMUserRisks = [
        { field: "Comparison", width: 100, template: '<span set-style="#:Comparison == \'Added\'? \'D9EAD3\': Comparison == \'Deleted\'? \'F2DEDE\': Comparison == \'Existing\'? \'FCF8E3\': null#">{{dataItem.Comparison}}</span>' },
        { field: "RequestMitigation", title: "Request Mitigation", template: "<a ng-click='vm.requestRiskMitigation(dataItem)'>{{dataItem.RequestMitigation}}</a>", width: 180, filterable: customAutoCompleteFilter },
        { field: "CompanyName", title: "Company", width: 180, filterable: customAutoCompleteFilter },
        { field: "RiskId", title: "Risk ID", width: 120, filterable: customAutoCompleteFilter },
        { field: "RiskName", title: "Risk Name", width: 190, filterable: customAutoCompleteFilter },
        { field: "BusinessCycle", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter },
        { field: "RiskLevel", title: "Risk Level", width: 110, filterable: customAutoCompleteFilter },
        { field: "RiskTypeName", title: "Risk Type", width: 110, filterable: customAutoCompleteFilter },
        { field: "Policy", title: "Policy", width: 110, filterable: customAutoCompleteFilter },
        { field: "RiskDescription", title: "Risk Description", width: 190, filterable: customAutoCompleteFilter },
        { field: "Mitigation", title: "Mitigation", width: 190, filterable: customAutoCompleteFilter },
        { field: "MitigationControls", title: "Mitigation Controls", width: 190, filterable: customAutoCompleteFilter },
        { field: "MitigationStatus", title: "Mitigation Status", width: 130, filterable: customAutoCompleteFilter },
        { field: "MitigationNotes", title: "Mitigation Notes", width: 190, filterable: customAutoCompleteFilter },
        { field: "ProductID", title: "ProductID", width: 150, filterable: customAutoCompleteFilter, hidden: true },
        { field: "SecurityRoleID", title: "Security Role ID", width: 150, filterable: customAutoCompleteFilter, hidden: true },
        { field: "SecurityObjectTypeID", title: "Security ObjectType ID", width: 150, filterable: customAutoCompleteFilter, hidden: true },
        { field: "SecurityObjectID", title: "Security Object ID", width: 150, filterable: customAutoCompleteFilter, hidden: true },
        { field: "AccessTypeID", title: "Access Type ID", width: 150, filterable: customAutoCompleteFilter, hidden: true }
    ];

    var columnsFromVMBusinessProcess = [
        { field: "CompanyName", title: "Company", width: 190, filterable: customAutoCompleteFilter },
        { field: "ProductName", title: "Product", width: 150, filterable: customAutoCompleteFilter },
        { field: "SecurityObjectAltName", title: "Security Object", width: 180, filterable: customAutoCompleteFilter, hidden: $scope.disableObjectLevelRiskAnalysis },
        { field: "SecurityObjectTypeName", title: "Security Object Type", width: 180, filterable: customAutoCompleteFilter, hidden: $scope.disableObjectLevelRiskAnalysis },
        { field: "ObjectComparison", width: 125, groupHeaderTemplate: "#= value #", template: '<span set-style="#:ObjectComparison == \'Added\'? \'D9EAD3\': ObjectComparison == \'Deleted\'? \'F2DEDE\': ObjectComparison == \'Existing\'? \'FCF8E3\': null#">{{dataItem.ObjectComparison}}</span>', hidden: $scope.disableObjectLevelRiskAnalysis },
        { field: "AccessLevelName", title: "Access Level", width: 150, filterable: customAutoCompleteFilter, hidden: $scope.disableObjectLevelRiskAnalysis },
        { field: "AccessTypeName", title: "Access Type", width: 150, filterable: customAutoCompleteFilter, hidden: $scope.disableObjectLevelRiskAnalysis },
        { field: "BusinessProcessGroupName", title: "Group", width: 180, filterable: customAutoCompleteFilter, groupHeaderTemplate: "#= value #" },
        { field: "GroupComparison", width: 125, template: '<span set-style="#:GroupComparison == \'Added\'? \'D9EAD3\': GroupComparison == \'Deleted\'? \'F2DEDE\': GroupComparison == \'Existing\'? \'FCF8E3\': null#">{{dataItem.GroupComparison}}</span>' }
    ];

    $scope.userRisksGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        data: $scope.UserRisksDataSource,
        sort: { field: "Comparison", dir: "asc" },
        schema: {
            model: {
                fields: {
                    RiskId: { type: "number" }
                }
            }
        }
    });

    $scope.userRisksGridOptions = helperService.setMainGridOptions(columnsFromVMUserRisks, 500);
    $scope.userRisksGridOptions.autoBind = true;
    if (!$scope.disableObjectLevelRiskAnalysis) {
        $scope.userRisksGridOptions.detailTemplate = kendo.template($("#risksTemplate").html());
    }
    $scope.userRisksGridOptions.columnMenu = true;

    $scope.businessProcessGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        data: $scope.BusinessProcessDataSource,
        group: [{ field: "BusinessProcessGroupName", dir: "asc" }, { field: "ObjectComparison", dir: "asc" }]
    });

    $scope.businessProcessGridOptions = helperService.setMainGridOptions(columnsFromVMBusinessProcess, 500);
    $scope.businessProcessGridOptions.autoBind = true;

    vm.expandDetails = function () {

        $uibModal.open({
            templateUrl: '/App/Shared/Partials/RequestDetailsModal.html',
            controller: riskDetailsModalController,
            backdrop: 'static',
            scope: $scope,
            resolve: {
                disableObjectData: () => $scope.disableObjectLevelRiskAnalysis
            }
        });
    };

    function openRequestMitigationModal(dataItem, dataGrid) {
        $uibModal.open({
            templateUrl: '/App/Components/RequestManagement/WizardSteps/Universal/riskAnalysisUserRisksMitigationModal.html',
            controller: riskAnalysisUserRisksMitigationModalController,
            backdrop: 'static',
            size: 'lg',
            scope: $scope,
            resolve: {
                row: () => dataItem,
                grid: () => dataGrid
            }
        });
    }

    function requestRiskMitigation(dataItem) {
        $scope.riskToMitigate = angular.copy(dataItem);
        openRequestMitigationModal(dataItem, $scope.UserRisksDataSource);
    }

}

function riskDetailsModalController($scope, $uibModalInstance, helperService, disableObjectData) {

    var vm = $scope.vm;

    var RisksColumns = [
        { field: "CompanyName", title: "Company", width: 190, filterable: customAutoCompleteFilter },
        { field: "RiskId", title: "Risk ID", width: 120, filterable: customAutoCompleteFilter, hidden: true },
        { field: "RiskName", title: "Risk Name", width: 190, filterable: customAutoCompleteFilter, groupHeaderTemplate: "#=  value #" },
        { field: "BusinessCycle", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter },
        { field: "RiskLevel", title: "Risk Level", width: 110, filterable: customAutoCompleteFilter },
        { field: "Policy", title: "Policy", width: 110, filterable: customAutoCompleteFilter },
        { field: "RiskDescription", title: "Risk Description", width: 190, filterable: customAutoCompleteFilter },
        { field: "RiskTypeName", title: "Risk Type", width: 190, filterable: customAutoCompleteFilter },
        { field: "Mitigation", title: "Mitigation", width: 190, filterable: customAutoCompleteFilter },
        { field: "MitigationStatus", title: "Mitigation Status", width: 130, filterable: customAutoCompleteFilter },
        { field: "MitigationNotes", title: "Mitigation Notes", width: 190, filterable: customAutoCompleteFilter },
        { field: "Comparison", width: 100, template: '<span set-style="#:Comparison == \'Added\'? \'D9EAD3\': Comparison == \'Deleted\'? \'F2DEDE\': Comparison == \'Existing\'? \'FCF8E3\': null#">{{dataItem.Comparison}}</span>' },
        { field: "ProductID", title: "ProductID", width: 150, filterable: customAutoCompleteFilter, hidden: true },
        { field: "SecurityRoleID", title: "Security Role ID", width: 150, filterable: customAutoCompleteFilter, hidden: true },
        { field: "SecurityObjectTypeID", title: "Security ObjectType ID", width: 150, filterable: customAutoCompleteFilter, hidden: true },
        { field: "SecurityObjectID", title: "Security Object ID", width: 150, filterable: customAutoCompleteFilter, hidden: true },
        { field: "AccessTypeID", title: "Access Type ID", width: 150, filterable: customAutoCompleteFilter, hidden: true }
    ];

    vm.RisksOptions = helperService.setMainGridOptions(RisksColumns, null);
    if (!disableObjectData) {
        vm.RisksOptions.detailTemplate = kendo.template($("#risksTemplate").html());
    }
    vm.RisksOptions.selectable = false;
    vm.RisksOptions.columnMenu = true;
    vm.RisksOptions.filterable = {
        mode: "row",
        operators: {
            string: {
                contains: "Contains",
                doesnotcontain: "Does not contain",
                eq: "Equal to",
                neq: "Not equal to"
            }
        }
    };

    vm.RisksDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        data: $scope.UserRisksDataSource,
        schema: {
            model: {
                fields: {
                    RiskId: { type: "number" }
                }
            }
        },
        pageable: false
    });

    vm.cancel = function () {
        $uibModalInstance.close();
    };

}