import Controller from "./connectionImport.controller";

/* connectionImport.component.js */

/**
 * @desc component for importing product connection data.
 * @example <connection-import></connection-import>
 */

const ConnectionImport = {
    bindings: {
        product: '<',
        originalConfig: "=",
        setRadioSelected: "<",
        nsRolesNeedsUpdate: '<',
        onNsRolesUpdate: '&'
    },
    controller: Controller,
    controllerAs: 'civm',
    templateUrl: 'App/Components/Connections/Connection/Import/connectionImport.tmpl.html'
};

export default ConnectionImport;