import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, approvalGroupsDataService) {
    const vm = this;

    vm.importApprovalGroups = importApprovalGroups;
    vm.cancel = cancel;

    async function importApprovalGroups() {
        let fd = new FormData();

        vm.savePending = new createSpinner();
        fd.append(vm.files.files[0].name, vm.files.files[0].file);

        try {
            await approvalGroupsDataService.importApprovalGroups(fd);
            await helperService.successfulSaveButton(vm.savePending);
            close();
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function close() {
        $uibModalInstance.close();
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}