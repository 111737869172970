/* userFieldMappings.component.js */

/**
 * @desc component for viewing, creating, editing, and removing mappings for auto generation of Cross Platform Users
 * @example <user-field-mappings></user-field-mappings>
 */

const userFieldMappings = {
    controller: userFieldMappingsController,
    controllerAs: 'ufmvm',
    template: `<div class="ibox custom-ibox">
    <div class="ibox-title full-span-ibox flex-between flex-vcenter">
        <div>
            <h5>
                User Field Mappings
            </h5>
        </div>
        <div ng-if="$root.UserSecurityList['6400']" class="pull-right">
            <button ng-click="ufmvm.generateAssignments()" class="btn btn-secondary">
                Generate
            </button>
        </div>
    </div>
    <h4 class="m-l-sm">Fastpath Primary System</h4>
    <div class="list-item">
        <div class="k-flex-auto flex-vcenter">
            <div title="{{ufmvm.primarySystem}}" class="contain-background-icon" ng-class="ufmvm.helperService.setProductImageClass(ufmvm.primarySystem)"></div>
            <h4 class="m-l-md">{{ufmvm.primarySystemProductName}}</h4>
        </div>
    </div>
    <div ng-switch="ufmvm.autoMapXplatEnabled">
        <div ng-switch-when="1">
            <h4 class="m-l-sm">System User Mappings</h4>
            <div class="system-user-mappings-scrollable">
                <div ng-repeat="product in ufmvm.productsDataSource track by product.ID">
                    <system-user-product-mappings product="product"></system-user-product-mappings>
                </div>
            </div>
        </div>
        <div ng-switch-when="0">
            <div class="alert alert-warning" role="alert">
                Enable Auto Map Cross Platform Users After Import to view and edit Product System User Mapping fields
            </div>
        </div>
    </div>
</div>`
};

/*@ngInject*/ function userFieldMappingsController(crossPlatformUsersDataService, helperService, dataService) {

    const vm = this;

    vm.generateAssignments = generateAssignments;
    vm.helperService = helperService;

    vm.$onInit = activate;

    async function activate() {
        vm.primarySystem = await helperService.getConfigurationValue('PrimarySystem');
        vm.autoMapXplatEnabled = await helperService.getConfigurationValue('AutoMapCrossPlatformUsersAfterImport');
        getProducts();
    }

    async function generateAssignments() {
        vm.generatingAssignments = true;
        await crossPlatformUsersDataService.generateCrossPlatformAutoMapping();
        vm.generatingAssignments = false;
    }

    async function getProducts() {
        let { data } = await dataService.getProducts();
        vm.productsDataSource = data;
        vm.primarySystemProductName = vm.productsDataSource.find(product => product.Type === vm.primarySystem).Name;
    }
}

export default userFieldMappings;