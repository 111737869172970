export default /*@ngInject*/ function ($uibModalInstance, authenticationService, notificationsDataService, type, products) {

    const vm = this;

    vm.type = type;
    vm.products = products;
    vm.availableUsers = [];
    vm.subscribedUsers = [];

    vm.setupUsers = setupUsers;
    vm.createSubscription = createSubscription;
    vm.removeSubscription = removeSubscription;
    vm.cancel = cancel;

    activate();

    async function activate() {
        await getUsers();
        await getSubscriptions();
        if (!vm.type.HasProductFilter) {
            setupUsers();
        }
        else if (vm.type.HasProductFilter && vm.products.length === 1) {
            vm.productId = vm.products[0].ID;
            setupUsers();
        }
    }

    async function getUsers() {
        vm.currentUser = await authenticationService.getUser();
        const res = await notificationsDataService.getUsers();
        res.data = res.data.filter(user => user.Active);
        vm.users = res.data.filter(u => u.UserID !== vm.currentUser.userId);
    }

    async function getSubscriptions() {
        const res = await notificationsDataService.getSubscriptions();
        vm.subscribed = res.data.filter(s => s.UserId !== vm.currentUser.userId);
    }

    function setupUsers() {

        let subscribed = [];

        subscribed = vm.subscribed.filter(su => su.NotificationTypeId === vm.type.Id);

        if (vm.type.HasProductFilter && subscribed.length > 0) {
            vm.subscribedUsers = subscribed.filter(su => su.ProductId === vm.productId);
        } else if (!vm.type.HasProductFilter && subscribed.length > 0) {
            vm.subscribedUsers = [...subscribed];
        }

        vm.availableUsers = [...vm.users];

        if (subscribed.length > 0) {
            vm.subscribedUsers.forEach(su => {
                let match = vm.availableUsers.find(au => au.UserID === su.UserId);
                if (match) vm.availableUsers.remove(match);
            });
        } else {
            vm.subscribedUsers = [];
        }
    }

    async function createSubscription(user) {

        vm.loading = true;

        const data = {
            NotificationTypeId: vm.type.Id,
            UserId: user.UserID,
            ProductId: vm.productId ? vm.productId : null
        };

        try {
            await notificationsDataService.createSubscription(data);
            await getSubscriptions();
            setupUsers();
            vm.loading = false;
        } catch (_) {
            vm.loading = false;
        }
    }

    async function removeSubscription(subscription) {

        vm.loading = true;

        try {
            await notificationsDataService.removeSubscription(subscription.Id);
            await getSubscriptions();
            setupUsers();
            vm.loading = false;
        } catch (_) {
            vm.loading = false;
        }
    }

    function cancel() {
        $uibModalInstance.close();
    }

}
