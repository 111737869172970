import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (helperService, $uibModalInstance, controlManagementDataService) {

    const vm = this;

    vm.saveGlobalConditions = saveGlobalConditions;
    vm.cancel = cancel;


    async function activate() {
        vm.globalConditions = [];
        await getGlobalConditions();
    }

    activate();

    async function getGlobalConditions() {
        let response = await controlManagementDataService.getGlobalParameters();
        vm.globalConditions = response.data;
    }

    async function saveGlobalConditions() {
        vm.savingConditions = new createSpinner();

        try {
            vm.globalConditions.forEach(globalCondition => {
                let putData = {
                    "Id": globalCondition.Id,
                    "Value": globalCondition.GlobalValue
                };

                controlManagementDataService.updateGlobalParameter(globalCondition.Id, putData);
            });

            await helperService.successfulSaveButton(vm.savingConditions);
            cancel();
        } catch {
            vm.savingConditions.loadingValue = false;
        }
    }

    function cancel() {
        $uibModalInstance.close();
    }

}