import { customDatePickerFilter, customAutoCompleteFilter, standardGridPageSize, customBooleanFilter } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";

export default /*@ngInject*/ function ($scope, $rootScope, SavedReportSelectorService, $filter, CustomReportServices, objectIdDataService, $location, helperService, reportsService, reportsDataService, requestHistoryDataService) {
    const vm = this,
        timeout = 408;

    vm.runSavedReport = runSavedReport;
    vm.generateGridWithSelectedValues = generateGridWithSelectedValues;
    vm.deselectSelectedStatus = deselectSelectedStatus;
    vm.viewDetails = viewDetails;
    vm.openStartDate = openStartDate;
    vm.openEndDate = openEndDate;
    vm.applySelectedFilter = applySelectedFilter;
    vm.clearAllFilters = clearAllFilters;
    vm.onReadGrid = onReadGrid;
    vm.onApplyFilter = onApplyFilter;
    vm.populateCriteriaModel = populateCriteriaModel;

    vm.startDatePopup = {
        opened: false
    }

    vm.endDatePopup = {
        opened: false
    };

    vm.dateCriteria = [
        {
            DataType: "DateTime",
            FieldName: "Date",
            IsNullable: false
        }
    ];

    vm.hasDetailedVersion = true;
    vm.statusSelectedIds = [];

    //Start Date and End Date picker settings
    vm.startDateOptions = {
        dateDisabled: disabled,
        showWeeks: false,
        formatYear: 'yy',
        startingDay: 1
    };

    vm.endDateOptions = {
        showWeeks: false,
        formatYear: 'yy',
        maxDate: new Date(),
        startingDay: 1
    };

    // Set up Grid
    vm.mainGridOptions = helperService.setMainGridOptions(null, 600);
    vm.mainGridOptions.autoBind = false;

    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    vm.filterCriteria.dateQuery = [];
    // ---

    $scope.$on('kendoWidgetCreated', function () {
        if (vm.grid) {
            vm.gridReady = true;
            runSavedReport();
        }
    });

    activate();

    async function activate() {
        if ($location.path().includes('Detailed')) {
            vm.isDetailed = true;
            vm.urlString = apiUrl + 'api/identitymanager/requests/history/detailed';
            vm.requestedDate = { field: "RequestDate", dir: "desc" }
        } else {
            vm.isDetailed = false;
            vm.urlString = apiUrl + 'api/identitymanager/requests/history';
            vm.requestedDate = { field: "RequestedDate", dir: "desc" }
        }

        await setObjectId();

        createRequestHistoryGrid()
        await getStatuses();
        today();
        thirtyDaysAgo();

        await getReportMetadata();

        vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
        if ($rootScope.loadedSavedReport) {
            runSavedReport();
        }
    }

    async function setObjectId() {
        const objectId = await objectIdDataService.getObjectID();
        let securityObject = helperService.getSecurityObjectDetailsFromObjectId(objectId);

        vm.ObjectId = objectId;
        vm.reportName = securityObject.Name;
        vm.reportId = securityObject.ReportID;
        vm.filtersDataSource = CustomReportServices.setfiltersDataSource(vm.ObjectId);
    }

    function runSavedReport() {
        if (vm.gridReady && $rootScope.loadedSavedReport && vm.statusDataSource && vm.filterCriteria.fields) {
            $scope.$evalAsync(() => {
                vm.filterCriteria.query = reportsService.getSavedReportFilters();
                vm.filterCriteria.dateQuery = reportsService.getSavedReportDateFilters();
                generateGridWithSelectedValues();
                vm.filterCriteria.needsUpdate = true;
            });
        } else if (vm.gridReady && !$rootScope.loadedSavedReport && vm.statusDataSource && vm.filterCriteria.fields) {
            // We want the necessary report details available to be able to use the report toolbar without running the report
            populateCriteriaModel();
        }
    };

    async function getStatuses() {
        let statuses = await requestHistoryDataService.getStatuses();

        statuses = $filter('orderBy')(statuses.data, 'StatusName');

        vm.allStatusesDropdownObject = { "StatusName": "All Statuses", "Id": 'AllObjectsInMultiSelect' };
        statuses.unshift(vm.allStatusesDropdownObject);

        vm.statusDataSource = statuses;
        vm.statusSelectedIds = [statuses.first()];
        vm.statusSelectedIds = SavedReportSelectorService.checkSavedReportForStatus($rootScope.loadedSavedReport, statuses, vm.statusSelectedIds);
    }

    function deselectSelectedStatus() {
        vm.statusSelectedIds = helperService.deselectAllObjectsIfOtherIsClicked(vm.statusSelectedIds, vm.allStatusesDropdownObject);
        populateCriteriaModel();
    };

    function populateCriteriaModel() {
        // do not continue if the proper criteria are not loaded
        if (!vm.startDate || !vm.endDate || vm.statusSelectedIds === undefined) {
            return;
        }

        let criteria = [];

        vm.savedStartDate = helperService.setDateToBeginningOfDay(vm.startDate);
        vm.savedEndDate = helperService.setDateToEndOfDay(vm.endDate);

        vm.statuses = helperService.returnNullIfAllIsFound(vm.statusSelectedIds.map(el => el.Id));

        criteria.push(CustomReportServices.CreateCriteria("StartDate", 'StartDate', vm.savedStartDate, vm.savedStartDate));
        criteria.push(CustomReportServices.CreateCriteria("EndDate", 'EndDate', vm.savedEndDate, vm.savedEndDate));
        vm.statusSelectedIds.forEach((row, i) => criteria.push(CustomReportServices.CreateCriteria("Statuses", 'Status', row.StatusName, row.Id)));

        vm.reportDetailsModel = criteria;

        // if tabs are on, save report info for the tab
        if ($rootScope.tabs.length > 0 && vm.reportName) {
            reportsService.saveTabReportInfo(vm);
        }
    };

    function generateGridWithSelectedValues() {
        if (!$rootScope.loadedSavedReport) {
            vm.grid.dataSource._filter = null;
            vm.grid.dataSource._sort = null;
        }

        if (vm.startDate <= vm.endDate) {
            populateCriteriaModel();
            vm.hideExportOptionsFromSubmit = true;

            // Add back the sorting and filtering options if we are running the report for the first time
            if (!$rootScope.loadedSavedReport) {
                vm.grid.setOptions({
                    filterable: {
                        mode: "row"
                    },
                    sortable: true
                });
            }

            SavedReportSelectorService.queryGridWithOptions($rootScope.loadedSavedReport, vm.grid, true);

        } else {
            helperService.showErrorMessage("Start Date must be before or equal to End Date");
        }
    };

    function createRequestHistoryGrid() {
        if (vm.isDetailed === true) {
            vm.mainGridColumns = [
                { field: "RequestDate", title: "Request Date", template: "{{ dataItem.RequestDate | date: 'short'}} ", filterable: customDatePickerFilter, width: 100 },
                { field: "Requestor", title: "Requestor", filterable: customAutoCompleteFilter, width: 100 },
                { field: "RequestAction", title: "Request Action", filterable: customAutoCompleteFilter, width: 100 },               
                { field: "AffectedUser", title: "Affected User", filterable: customAutoCompleteFilter, width: 100 },
                { field: "AffectedUserEmail", title: "Affected User Email", filterable: customAutoCompleteFilter, width: 125 },
                { field: "TicketId", title: "Ticket ID", filterable: customAutoCompleteFilter, width: 100 },
                { field: "StatusID", title: "Status ID", filterable: customAutoCompleteFilter, width: 100 },
                { field: "RequestStatus", title: "Request Status", filterable: customAutoCompleteFilter, width: 100 },
                { field: "RequestUserId", title: "Request User ID", filterable: customAutoCompleteFilter, width: 100 },
                { field: "RequestUserName", title: "Request User Name", filterable: customAutoCompleteFilter, width: 100 },
                { field: "RequestUserEventType", title: "Request User Event Type", filterable: customAutoCompleteFilter, width: 100 },
                { field: "RequestUserProductName", title: "Request User Product Name", filterable: customAutoCompleteFilter, width: 100 },
                { field: "UserStartDate", title: "User Start Date", template: "{{ dataItem.UserStartDate | date: 'short'}} ", filterable: customDatePickerFilter, width: 100 },
                { field: "UserEndDate", title: "User End Date", template: "{{ dataItem.UserEndDate | date: 'short'}} ", filterable: customDatePickerFilter, width: 100 },
                { field: "RequestUserStatus", title: "Request User Status", filterable: customAutoCompleteFilter, width: 100 },
                { field: "RequestUserReviewedOn", title: "Request User Reviewed On", template: "{{ dataItem.RequestUserReviewedOn | date: 'short'}} ", filterable: customDatePickerFilter, width: 100 },
                { field: "RequestUserReviewedByName", title: "Request User Reviewed By", filterable: customAutoCompleteFilter, width: 100 },
                { field: "RequestUserIsApproved", title: "Request User Is Approved", filterable: customBooleanFilter, width: 125 },
                { field: "RequestAccessType", title: "Request Access Type", filterable: customAutoCompleteFilter, width: 100 },
                { field: "RequestAccessUserId", title: "Request Access User ID", filterable: customAutoCompleteFilter, width: 100 },
                { field: "Access", title: "Access", filterable: customAutoCompleteFilter, width: 100 },
                { field: "RequestAccessEventType", title: "Request Access Event Type", filterable: customAutoCompleteFilter, width: 100 },
                { field: "RequestAccessProductName", title: "Request Access Product Name", filterable: customAutoCompleteFilter, width: 100 },
                { field: "AccessStartDate", title: "Access Start Date", template: "{{ dataItem.AccessStartDate | date: 'short'}} ", filterable: customDatePickerFilter, width: 100 },
                { field: "AccessEndDate", title: "Access End Date", template: "{{ dataItem.AccessEndDate | date: 'short'}} ", filterable: customDatePickerFilter, width: 100 },
                { field: "RequestAccessStatus", title: "Request Access Status", filterable: customAutoCompleteFilter, width: 100 },
                { field: "RequestAccessReviewedOn", title: "Request Access Reviewed On", template: "{{ dataItem.RequestAccessReviewedOn | date: 'short'}} ", filterable: customDatePickerFilter, width: 100 },
                { field: "RequestAccessReviewedByName", title: "Request Access Reviewed By", filterable: customAutoCompleteFilter, width: 100 },
                { field: "RequestAccessIsApproved", title: "Request Access Is Approved", filterable: customBooleanFilter, width: 125 },
                { field: "ApprovalNotes", title: "Approval Notes", filterable: customAutoCompleteFilter, width: 100 },
                { field: "RequestorNotes", title: "Requestor Notes", filterable: customAutoCompleteFilter, width: 100 },
                { field: "RequestorEmail", title: "Requestor Email", filterable: customAutoCompleteFilter, width: 100 },
                { field: "CancelledBy", title: "Cancelled By", filterable: customAutoCompleteFilter, width: 100 },
                { field: "CancelledComments", title: "Cancelled Comments", filterable: customAutoCompleteFilter, width: 350 },
                { template: '<a ng-click="rhvm.viewDetails(dataItem)">Details</a>', title: "", width: 100 }
            ];
        } else {
            vm.mainGridColumns = [
                { field: "RequestedDate", title: "Request Date", template: "{{ dataItem.RequestedDate | date: 'short'}} ", filterable: customDatePickerFilter, width: 100 },
                { field: "RequestorName", title: "Requestor", filterable: customAutoCompleteFilter, width: 100 },
                { field: "RequestAction", title: "Request Action", filterable: customAutoCompleteFilter, width: 100 },                
                { field: "AffectedObjectUserName", title: "Affected User", filterable: customAutoCompleteFilter, width: 100 },
                { field: "AffectedUserEmail", title: "Affected User Email", filterable: customAutoCompleteFilter, width: 100 },
                { field: "TicketId", title: "Ticket ID", filterable: customAutoCompleteFilter, width: 100 },
                { field: "Status", title: "Status", filterable: customAutoCompleteFilter, width: 100 },
                { template: '<a ng-click="rhvm.viewDetails(dataItem)">Details</a>', title: "", width: 100 }
            ];
        }

        vm.gridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: angular.extend(halSchemaKendoGrid, {
                model: {
                    fields: {
                        RequestedDate: { type: "date" },
                        RequestDate: { type: "date" },
                        UserStartDate: { type: "date" },
                        UserEndDate: { type: "date" },
                        AccessStartDate: { type: "date" },
                        AccessEndDate: { type: "date" },
                        RequestUserIsApproved: { type: "boolean" },
                        RequestAccessIsApproved: { type: "boolean" },
                        RequestUserReviewedOn: { type: "date" },
                        RequestAccessReviewedOn: { type: "date" },
                    }
                }
            }),
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            sort: vm.requestedDate,
            transport: {
                read: async options => {
                    delete $rootScope.loadedSavedReport;
                    let payLoad = {
                        StartDate: vm.savedStartDate,
                        EndDate: vm.savedEndDate,
                        Statuses: vm.statuses
                    };

                    vm.sortsAndFilters = helperService.prepareSortsAndFilters(options);
                    payLoad['QueryInput'] = vm.sortsAndFilters;

                    // --- FOR ADVANCED FILTERING ---
                    if (vm.filterCriteria.query) {
                        payLoad['QueryInput'].Filters = payLoad['QueryInput'].Filters.concat(vm.filterCriteria.query);
                        payLoad['QueryInput'].DateFilters = vm.filterCriteria.dateQuery;
                    }
                    // ---

                    // if tabs are on, save report info for the tab
                    if ($rootScope.tabs.length > 0) {
                        reportsService.saveTabReportInfo(vm);
                    }

                    try {
                        let { data } = await requestHistoryDataService.getRequests(vm.urlString, payLoad, { params: { 'pagesize': options.data.pageSize, 'page': options.data.page } }),
                            reportData = data._embedded.reportData;

                        reportData.forEach(row => {
                            if (row.AffectedObject) {
                                const AffectedObject = JSON.parse(row.AffectedObject);
                                if (AffectedObject) {
                                    row["AffectedObjectUserName"] = AffectedObject.Name;
                                }
                            }
                        });

                        options.success(data);
                        vm.enableGrid = true;
                    } catch (error) {
                        if (error.status !== timeout) {
                            helperService.showErrorMessage(error.data);
                        }
                    }
                }
            }
        });

        setLayout();
    }

    // Don't allow filtering and sorting yet because they will force the report to run
    if (!$rootScope.loadedSavedReport) {
        vm.mainGridOptions.sortable = false;

        vm.mainGridOptions.filterable = false;
    }

    function setLayout() {
        if (!$rootScope.loadedSavedReport) {
            CustomReportServices.loadSavedGridLayout(vm, null);
        }
    }

    function viewDetails(dataItem) {
        const data = JSON.stringify(vm.gridDataSource.data());
        if (data) {
            localStorage.setItem("requestHistoryReportData", data);
        }
        $location.path('/IdentityManager/RequestDetails').search({ RequestId: dataItem.RequestId, Version: dataItem.RequestVersion });
    }

    //Set Datetime for start and end date time pickers
    function today() {
        vm.endDate = new Date();
        vm.endDate = SavedReportSelectorService.checkSavedReportForDate($rootScope.loadedSavedReport, "EndDate", vm.endDate);
    }

    function thirtyDaysAgo() {
        let newDate = new Date();

        newDate.setDate(newDate.getDate() - 30);
        vm.startDate = newDate;
        vm.startDate = SavedReportSelectorService.checkSavedReportForDate($rootScope.loadedSavedReport, "StartDate", vm.startDate);
    };

    function disabled(data) {
        let date = data.date,
            mode = data.mode;

        return mode === 'day' && date > vm.endDate;
    }

    function openStartDate() {
        vm.startDatePopup.opened = true;
    }

    function openEndDate() {
        vm.endDatePopup.opened = true;
    }

    function applySelectedFilter() {
        helperService.applyFilterCatchError(vm);
    }

    // apply filters for Detailed report toolbar
    function onApplyFilter(selectedFilter) {
        const currentOptions = vm.grid.getOptions();

        if (selectedFilter && selectedFilter.Filters && selectedFilter.Filters.length > 0) {
            currentOptions.dataSource.filter = helperService.convertFiltersForGrid(_, selectedFilter.Filters);
            try {
                vm.grid.dataSource._filter = currentOptions.dataSource.filter;
                vm.grid.dataSource.read();
            }
            catch (err) {
                vm.gridDataSource.filter({});
            }
        }
    }

    function clearAllFilters() {
        vm.gridDataSource.filter({});
    }

    function onReadGrid() {
        vm.gridDataSource.read();
    }

    // --- FOR ADVANCED FILTERING ---
    async function getReportMetadata() {
        let data = await reportsDataService.getReportMetadata(vm.reportId);

        vm.reportMetadata = data.data._embedded.reportData;
        vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');
        vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);

        return vm.reportMetadata;
    }
}
