export default /*@ngInject*/ function ($rootScope, Hub, signalRCommonService, archiveToastr, ViewProductImportPageService) {
    createHub();

    async function createHub() {
        var hub = new Hub('tenantHub', {

            listeners: {
                'send': function (response) {
                    var notifyObject = {
                        correlationId: response.CorrelationId,
                        type: response.Type,
                        message: response.Message,
                        title: response.Title,
                        status: response.Status,
                        response: response,
                        options: {}
                    };

                    if (response.Status === 'Success') {
                        switch (response.Type) {
                            case 'ProductImport':
                                notifyObject.options = {
                                    allowHtml: true,
                                    onTap: function (e) {
                                        ViewProductImportPageService.viewImportReport(response.ProductType);
                                    }
                                };
                                notifyObject.message = `${response.Message} <br/> <a>Click for Details</a>`;
                                break;
                            default:
                                break;
                        }
                        archiveToastr.notifyArchive(notifyObject);
                        $rootScope.$broadcast(response.Type);

                    } else if (response.Type === 'ProductImport' && response.Title === 'In Progress') {
                        archiveToastr.notifyArchive(notifyObject);
                        $rootScope.$broadcast('productImportInProgress', response);
                    } else {
                        archiveToastr.notifyArchive(notifyObject);
                        $rootScope.$broadcast(response.Type);
                    }

                    $rootScope.$evalAsync();
                }
            },
            queryParams: await signalRCommonService.populateQueryParams(),
            rootPath: apiUrl + 'signalr',
            useSharedConnection: false,
            stateChanged: function (state) {
                signalRCommonService.stateChanged(state, hub, 'Tenant');
            },
            errorHandler: function (error) {
                console.log(error.message, 'Error');
            }
        });

    }

    return {};
}