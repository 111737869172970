export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getUserProfileByUserMasterId,
        getUserProfileByUserIdAndProductId,
        getUserProfileAccess,
        getWorkdayProfileInfo
    };

    return service;

    async function getUserProfileByUserMasterId(userMasterId) {
       try {
         return await $http.get(`${apiUrl}api/universal/usermaster/${userMasterId}/profile`);
       } catch(err) {
          showError(err);
       }
    }

    async function getUserProfileByUserIdAndProductId(userId, productId) {
        try {
            return await $http.get(`${apiUrl}api/universal/usermaster/user/${userId}/product/${productId}/profile`);
        } catch (err) {
            showError(err);
        }
    }

    async function getUserProfileAccess(userId, productId) {
        try {
            return await $http.get(`${apiUrl}api/universal/usermaster/user/${userId}/product/${productId}/profile/access`);
        } catch (err) {
            showError(err);
        }
    }

    async function getWorkdayProfileInfo(userId) {
        try {
            const response = await $http.get(`${apiUrl}api/universal/wd/user/${userId}/profile`);
            return response.data;
        } catch (err) {
            showError(err);
        }
    }

    function showError(err) {
      errorsDataService.catch(err);
    }

}