/* groupItemOwnersList.component.js */

import groupItemOwnersListController from "./groupItemOwnersList.controller";

/**
 * @desc component for viewing and maintaining list of ownership group item users or approval groups
 * @example <ownership-group-item-owners-list><ownership-group-item-owners-list>
 */

const groupItemOwnersList = {
    bindings: {
        selectedOwnershipGroup: '<',
        selectedGroupItem: '<'
    },
    controller: groupItemOwnersListController,
    controllerAs: 'giolvm',
    templateUrl: '/App/Components/OwnershipGroups/groupItemOwnersList/groupItemOwnersList.tmpl.html'
};

export default groupItemOwnersList;