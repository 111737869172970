export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getPasswordCriteria,
        addPasswordCriteria,
        updatePasswordCriteria
    };

    return service;

    async function getPasswordCriteria(productId) {
        try {
            return await $http.get(`${apiUrl}api/identitymanager/products/${productId}/passwordcriteria`);
        } catch (error) {
            if (error.status !== 404) {
                showError(error);
            }
            sendError(error);
        }
    }

    async function addPasswordCriteria(data) {
        try {
            return await $http.post(`${apiUrl}api/identitymanager/products/${data.ProductId}/passwordcriteria`, data);
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    async function updatePasswordCriteria(data) {
        try {
            return await $http.put(`${apiUrl}api/identitymanager/products/${data.ProductId}/passwordcriteria`, data);
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}