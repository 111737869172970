import swal from "sweetalert2";
import createSpinner from "../../Shared/Functions/createSpinner";
import approvalGroupModalController from "./ApprovalGroupModal/approvalGroupModal.controller";
import levelModalController from "./LevelModal/levelModal.controller";
import approversModalController from "./ApproversModal/approversModal.controller";
import approvalGroupImportModalController from "./approvalGroupImportModal.controller";


export default /*@ngInject*/ function ($uibModal, approvalGroupsDataService, $filter, helperService, FileSaver) {

    const vm = this;
    vm.selectedApprovers = [];
    vm.openGroupModal = openGroupModal;
    vm.openApproversModal = openApproversModal;
    vm.deleteGroup = deleteGroup;
    vm.deleteGroupLevel = deleteGroupLevel;
    vm.selectedGroupChanged = selectedGroupChanged;
    vm.selectedLevelChanged = selectedLevelChanged;
    vm.openLevelModal = openLevelModal;
    vm.removeApprover = removeApprover;
    vm.exportApprovalGroups = exportApprovalGroups;
    vm.openApprovalGroupImportModal = openApprovalGroupImportModal;

    vm.approvalGroupMenuOptions = [
        {
            text: 'Edit',
            click: item => {
                vm.openGroupModal(item.group);
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: item => {
                vm.deleteGroup(item.group);
            }
        }
    ];

    vm.approvalGroupLevelMenuOptions = [
        {
            text: 'Edit',
            click: item => {
                vm.openLevelModal(item.level, true);
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: item => {
                vm.deleteGroupLevel(item.level);
            },
            displayed: function (modelValue) {
                return vm.levelsDataSource.length === modelValue.level.Level && vm.levelsDataSource.length > 1;
            }
        }
    ];

    activate();

    function activate() {
        getGroups();
    }

    async function getGroups() {
        vm.gettingGroupsInProgress = true;
        if (vm.selectedLevel) vm.selectedLevel = null;
        if (vm.selectedGroup) vm.selectedGroup = null;

        let response = await approvalGroupsDataService.getApprovalGroups();
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.groupsDataSource = response.data;
        vm.gettingGroupsInProgress = false;
    }

    async function getLevels() {
        vm.gettingLevelsInProgress = true;
        if (vm.selectedApprovers.length > 0) vm.selectedApprovers = [];

        let { data } = await approvalGroupsDataService.getApprovalGroupLevels(vm.selectedGroup.Id);
        vm.levelsDataSource = $filter('orderBy')(data, 'Level');
        // Need to track approvers that are already in a level
        data.forEach(level => {
            vm.selectedApprovers = vm.selectedApprovers.concat(level.Approvers);
        });
        vm.gettingLevelsInProgress = false;
    }

    function getApprovers(levelId) {
        vm.approversDataSource = vm.levelsDataSource.find(level => level.Id === levelId).Approvers;
        vm.approversDataSource = $filter('orderBy')(vm.approversDataSource, 'Name');
    }

    function sendNewGroupToTop(newGroupId) {
        vm.selectedGroup = vm.groupsDataSource.find(group => group.Id === newGroupId);
        vm.groupsDataSource = vm.groupsDataSource.filter(group => group.Id !== newGroupId);
        vm.groupsDataSource.unshift(vm.selectedGroup);
    }

    async function openGroupModal(group) {
        let groupModal = $uibModal.open({
            templateUrl: '/App/Components/ApprovalGroups/ApprovalGroupModal/approvalGroupModal.html',
            controller: approvalGroupModalController,
            controllerAs: 'agmvm',
            backdrop: 'static',
            resolve: {
                group: () => group || null
            }
        });

        try {
            let newGroupId = await groupModal.result;
            await getGroups();
            if (newGroupId) {
                await selectedGroupChanged(newGroupId);
                await sendNewGroupToTop(newGroupId);
            } else if (vm.selectedGroup) {
                await selectedGroupChanged(vm.selectedGroup.Id);
            }
        } catch (dismiss) { return; }
    }

    async function openLevelModal(level, fromEdit) {
        let levelsModal = $uibModal.open({
            templateUrl: '/App/Components/ApprovalGroups/LevelModal/levelModal.html',
            controller: levelModalController,
            controllerAs: 'lmvm',
            backdrop: 'static',
            resolve: {
                level: () => level || null,
                group: () => vm.selectedGroup,
                selectedApprovers: () => vm.selectedApprovers || [],
                showWarning: () => vm.levelsDataSource.length,
                fromEdit: () => fromEdit
            }
        });

        try {
            let newLevelId = await levelsModal.result;
            await getLevels();
            if (newLevelId) {
                await selectedLevelChanged(newLevelId);
            } else if (vm.selectedLevel) {
                await selectedLevelChanged(vm.selectedLevel.Id);
            }
        } catch (dismiss) { return; }
    }

    async function openApproversModal() {
        let approversModal = $uibModal.open({
            templateUrl: '/App/Components/ApprovalGroups/ApproversModal/approversModal.html',
            controller: approversModalController,
            controllerAs: 'amvm',
            backdrop: 'static',
            resolve: {
                level: () => vm.selectedLevel,
                group: () => vm.selectedGroup,
                selectedApprovers: () => vm.selectedApprovers || []
            }
        });

        try {
            await approversModal.result;
            const selectedLevelId = vm.selectedLevel.Id;
            clearLevels();
            await getLevels();
            await selectedLevelChanged(selectedLevelId);
        } catch (dismiss) { return; }
    }

    async function deleteGroup(group) {
        try {
            await swal(helperService.areYouSureParams('Delete Group', 'Are you sure you want to delete group ' + group.Name + '?', 'Delete'));
            await approvalGroupsDataService.removeApprovalGroup(group.Id);
            getGroups();
        } catch (dismiss) {
            return;
        }
    }

    async function deleteGroupLevel(level) {
        try {
            await swal(helperService.areYouSureParams('Delete Level', 'Are you sure you want to delete Level ' + level.Level + '?', 'Delete'));
            await approvalGroupsDataService.removeApprovalGroupLevel(vm.selectedGroup.Id, level.Id);
            vm.selectedLevel = null;
            await getLevels();
        } catch (dismiss) {
            return;
        }
    }

    async function removeApprover(approver) {
        const selectedLevelId = vm.selectedLevel.Id;
        await approvalGroupsDataService.removeApproverFromApprovalGroupLevel(vm.selectedGroup.Id, vm.selectedLevel.Id, approver.UserId);
        clearLevels();
        await getLevels();
        await selectedLevelChanged(selectedLevelId);
    }

    async function openApprovalGroupImportModal() {
        let approvalGroupModal = $uibModal.open({
            templateUrl: '/App/Components/ApprovalGroups/approvalGroupImportModal.tmpl.html',
            controller: approvalGroupImportModalController,
            controllerAs: 'agmvm',
            backdrop: 'static'
        });

        try {
            await approvalGroupModal.result;
            activate();
        } catch { return; }
    }

    async function exportApprovalGroups() {
        vm.exportUpdatePending = new createSpinner();
        try {
            const response = await approvalGroupsDataService.exportApprovalGroups();
            await helperService.successfulSaveButton(vm.exportUpdatePending);
            FileSaver.saveAs(response.data, 'ApprovalGroups.xlsx');
        } catch (err) {
            vm.exportUpdatePending.loadingValue = false;
        }
    }

    function selectedGroupChanged(selectedGroupId) {
        if (vm.selectedGroup && vm.selectedGroup.Id === selectedGroupId) {
            return;
        } else {
            if (vm.selectedApprovers.length > 0) vm.selectedApprovers = [];
            clearLevels();
            vm.selectedGroup = vm.groupsDataSource.find(group => group.Id === selectedGroupId);
            getLevels();
        }
    }

    function selectedLevelChanged(selectedLevelId) {
        if (vm.selectedLevel && vm.selectedLevel.Id === selectedLevelId) {
            return;
        } else {
            vm.selectedLevel = vm.levelsDataSource.find(level => level.Id === selectedLevelId);
            getApprovers(selectedLevelId);
        }
    }

    function clearLevels() {
        vm.selectedLevel = null;
        vm.levelsDataSource = null;
        vm.approversDataSource = null;
    }
}



