import maintainTemplatesController from "./maintainTemplates.controller";

/* maintainTemplates.component.js */

/**
 * @desc component for maintaining audit trail templates
 * @example <maintain-templates</maintain-templates>
 */

const maintainTemplates = {
    controller: maintainTemplatesController,
    controllerAs: 'mtvm',
    templateUrl: '/App/Components/AuditTrail/MaintainTemplates/maintainTemplates.tmpl.html'
};

export default maintainTemplates;