import snapshotsListController from "./snapshotsList.controller";

/* snapshotsList.component.js */

/**
 * @desc component for viewing a list of audit trail snapshots
 * @example <snapshots-list></snapshots-list>
 */

const snapshotsList = {
    bindings: {
        items: '<',
        onUpdate: '&'

    },
    controller: snapshotsListController,
    controllerAs: 'slvm',
    templateUrl: '/App/Components/AuditTrail/MaintainSnapshots/SnapshotsList/snapshotsList.tmpl.html'
};

export default snapshotsList;