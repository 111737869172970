import manageDataController from "./manageData.controller";

/* manageData.component.js */

/**
 * @desc component for managing audit trail data
 * @example <manage-data></manage-data>
 */

const manageData = {
    controller: manageDataController,
    controllerAs: 'mdvm',
    templateUrl: '/App/Components/ManageDataV2/manageData.html'
};

export default manageData;