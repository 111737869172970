import backButtonController from "./backButton.controller";

/* backButton.component.js */

/**
 * @desc component for displaying a back button that will call a bound function
 * @example <back-button></back-button>
 */

const backButton = {
    bindings: {
        buttonClick: '&',
        breadcrumbText: '<'
    },
    controller: backButtonController,
    controllerAs: 'bbvm',
    templateUrl: '/App/Shared/Components/backButton/backButton.tmpl.html'
};

export default backButton;