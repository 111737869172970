import createSpinner from "../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter } from "../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($scope, $rootScope, $uibModal, $route, $http, helperService) {

    var newVm = $scope.newVm = {};
    newVm.selectedListOption = 'Roles';
    newVm.securityModelUsers = [];

    if ($rootScope.simulationToEdit) {
        newVm.newSimulationName = $rootScope.simulationToEdit.Name;
        newVm.simulationId = $rootScope.simulationToEdit.Id;
        delete $rootScope.simulationToEdit;

        $http.get(apiUrl + '/api/arm/ns/risks/' + $scope.newVm.simulationId).then(function successCallback(response) {
            response.data.Roles.forEach(function (row) {
                row.Permissions.forEach(function (permission) { delete permission['AccessLevelName']; });
                row.Users.forEach(function (user) {
                    user.RoleId = row.Id;
                    if (!newVm.securityModelUsers.some(u => u.Name === user.Name && u.Id === user.Id)) {
                        newVm.securityModelUsers.push(user);
                    }
                });
            });
            newVm.currentSecurityModel = response.data;
        });

    } else {
        $scope.loadingNewSimulation = true;

        $http.get(apiUrl + '/api/arm/ns/risks').then(function successCallback(response) {

            response.data.Roles.forEach(function (row) {
                row.Permissions.forEach(function (permission) { delete permission['AccessLevelName']; });
                row.Users.forEach(function (user) {
                    user.RoleId = row.Id;
                    if (!newVm.securityModelUsers.some(u => u.Name === user.Name && u.Id === user.Id)) {
                        newVm.securityModelUsers.push(user);
                    }
                });
            });

            newVm.currentSecurityModel = response.data;
            $scope.loadingNewSimulation = false;
        });
    }

    $scope.listOptionSelectionChanged = function () {
        if ($scope.selectedNewUser) { $scope.selectedNewUser = null; }
        if ($scope.selectedNewRole) { $scope.selectedNewRole = null; }
    };

    $scope.changeNewRoleSelected = function (dataItem) {
        if ($scope.selectedNewUser) { $scope.selectedNewUser = null; }
        $scope.selectedNewRole = dataItem;
    };

    $scope.changeNewUserSelected = function (dataItem) {
        if ($scope.selectedNewRole) { $scope.selectedNewRole = null; }
        let selectedUser = Object.assign({}, dataItem);
        selectedUser.Roles = newVm.currentSecurityModel.Roles.filter(role => role.Users.some(user => user.Id === dataItem.Id));
        $scope.selectedNewUser = selectedUser;
    };

    $scope.addRoleToNewSimulation = function () {
        $uibModal.open({
            templateUrl: '/App/Components/NetSuiteSecurityDesigner/SimulationNewAddRole.html',
            controller: viewSimulationUserRisks,
            backdrop: 'static',
            scope: $scope,
            resolve: {
                perspective: () => newVm.selectedListOption === 'Roles' ? 'Role' : 'User'
            }
        });
    };

    $scope.addPermissionsToSelectedRole = function () {
        $uibModal.open({
            templateUrl: '/App/Components/NetSuiteSecurityDesigner/AddPropertiesToRolesModal.html',
            controller: addPermissionsToSelectedRoleModalController,
            backdrop: 'static',
            scope: $scope
        });
    };

    $scope.addSubsidiariesToSelectedRole = function () {
        $uibModal.open({
            templateUrl: '/App/Components/NetSuiteSecurityDesigner/AddPropertiesToRolesModal.html',
            controller: addSubsidiariesToSelectedRoleModalController,
            backdrop: 'static',
            scope: $scope
        });
    };

    $scope.addUsersToSelectedRole = function () {
        $uibModal.open({
            templateUrl: '/App/Components/NetSuiteSecurityDesigner/AddPropertiesToRolesModal.html',
            controller: addUsersToSelectedRoleModalController,
            backdrop: 'static',
            scope: $scope,
            size: 'lg'
        });
    };

    $scope.addRolesToSelectedUser = function () {
        $uibModal.open({
            templateUrl: '/App/Components/NetSuiteSecurityDesigner/AddRolesToUserModal.html',
            controller: addRolesToSelectedUserModalController,
            backdrop: 'static',
            scope: $scope
        });
    };

    $scope.selectedNewRoleFilter = "Subsidiaries";

    $scope.permissionLevels = [{ Name: 'View', Id: 2 }, { Name: 'Create', Id: 3 }, { Name: 'Edit', Id: 4 }, { Name: 'Full', Id: 5 }];

    $http.get(apiUrl + '/api/arm/ns/risks/subsidiaries/').then(function successCallback(response) {
        newVm.subsidiaries = response.data;
    });
    $http.get(apiUrl + '/api/arm/ns/risks/users/').then(function successCallback(response) {
        newVm.users = response.data;
    });
    $http.get(apiUrl + '/api/arm/ns/risks/permissions/').then(function successCallback(response) {
        newVm.permissions = response.data;
    });

    $scope.removeUserFromRole = function (dataItem) {
        $scope.selectedNewRole.Users.remove(function (el) {
            return el.Id === dataItem.Id;
        });
    };

    $scope.removeRoleFromUser = function (role, user) {
        const currentRole = newVm.currentSecurityModel.Roles.find(function (r) { return r.Id === role.Id; });
        currentRole.Users.remove(function (u) {
            return u.Id === user.Id;
        });
        $scope.selectedNewUser.Roles.remove(function (r) {
            return r.Id === role.Id;
        });
    };

    $scope.removeSubsidiaryFromRole = function (dataItem) {
        $scope.selectedNewRole.Subsidiaries.remove(function (el) {
            return el.Id === dataItem.Id;
        });
    };

    $scope.removePermissionFromRole = function (dataItem) {
        $scope.selectedNewRole.Permissions.remove(function (el) {
            return el.Name === dataItem.Name;
        });
    };

    $scope.removeRole = function (dataItem) {
        $scope.newVm.currentSecurityModel.Roles.remove(function (el) {
            return el.Name === dataItem.Name;
        });
        if ($scope.selectedNewRole) {
            if ($scope.selectedNewRole.Name === dataItem.Name) {
                $scope.selectedNewRole = null;
            }
        }
    };

    $scope.removeUser = function (dataItem) {
        $scope.newVm.securityModelUsers.remove(function (el) {
            return el.Name === dataItem.Name;
        });
        $scope.newVm.currentSecurityModel.Roles.forEach(role => {
            role.Users = role.Users.filter(user => user.Id !== dataItem.Id);
        });
        if ($scope.selectedNewUser) {
            if ($scope.selectedNewUser.Name === dataItem.Name) {
                $scope.selectedNewUser = null;
            }
        }
    };

    $scope.saveSimulation = function () {
        $scope.postSimulationInProgress = new createSpinner();

        var modifiedSecurityModel = angular.copy(newVm.currentSecurityModel);

        //If Id is populated it will edit otherwise create a new one
        var postBody = {
            "Id": newVm.simulationId,
            "Name": $scope.newVm.newSimulationName,
            "Roles": modifiedSecurityModel.Roles
        };

        $http.post(apiUrl + 'api/arm/ns/risks/', postBody).then(async function (response) {
            $scope.killModal = true;
            await helperService.successfulSaveButton($scope.postSimulationInProgress).then(function (result) {
                $route.reload();
            });

        }, function errorCallback(response) {
            $scope.postSimulationInProgress.loadingValue = false;
            helperService.showErrorMessage(response.data);
        });
    };


}

function viewSimulationUserRisks($scope, $uibModalInstance, helperService, perspective) {

    $scope.perspective = perspective;
    if (perspective === 'Role') {
        $scope.role = {};
    } else {
        $scope.user = {};
    }

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

    $scope.addRole = async function () {
        $scope.saving = new createSpinner();
        const role = {
            "Id": 0,
            "Name": $scope.role.name,
            "Subsidiaries": [
            ],
            "Users": [],
            "Permissions": [],
            "New": true
        };

        const nameAlreadyExists = $scope.newVm.currentSecurityModel.Roles.filter(e => e.Name.toLowerCase() === $scope.role.name.toLowerCase())[0];

        if (nameAlreadyExists) {
            $scope.saving.loadingValue = false;
            helperService.showErrorMessage('A role with this name already exists');
        } else {
            $scope.newVm.currentSecurityModel.Roles.unshift(role);
            $scope.changeNewRoleSelected(role);
            await helperService.successfulSaveButton($scope.saving);
            $scope.cancel();
        }
    };

    $scope.addUser = async function () {
        $scope.saving = new createSpinner();
        const user = {
            "DefaultSubsidiaryId": $scope.user.defaultSubsidiary.Id,
            "DefaultSubsidiaryName": $scope.user.defaultSubsidiary.Name,
            "Email": $scope.user.email,
            "Name": $scope.user.name,
            "New": true,
            "Id": Math.max.apply(Math, $scope.newVm.securityModelUsers.map(function (e) { return e.Id; })) + 1
        };

        const nameAlreadyExists = $scope.newVm.securityModelUsers.filter(e => e.Name.toLowerCase() === $scope.user.name.toLowerCase())[0];
        const emailAlreadyExists = $scope.newVm.securityModelUsers.filter(e => e.Email.toLowerCase() === $scope.user.email.toLowerCase())[0];

        if (nameAlreadyExists) {
            $scope.saving.loadingValue = false;
            helperService.showErrorMessage('A user with this name already exists');
        } else if (emailAlreadyExists) {
            $scope.saving.loadingValue = false;
            helperService.showErrorMessage('A user with this email already exists');
        } else {
            $scope.newVm.securityModelUsers.unshift(user);
            $scope.changeNewUserSelected(user);
            await helperService.successfulSaveButton($scope.saving);
            $scope.cancel();
        }
    };


}

function addPermissionsToSelectedRoleModalController($scope, $uibModalInstance, helperService, $filter) {

    $scope.modalName = "Permissions";

    var columns = [
        { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
        { template: "<a ng-click='addItemToRole(dataItem)'><i class='fa fa-plus pull-right text-success'></i></a>", title: " ", width: 20 }
    ];

    $scope.gridOptions = helperService.setMainGridOptions(columns, null);
    $scope.gridOptions.selectable = false;

    $scope.gridDataSource = new kendo.data.DataSource({
        pageSize: 1000,
        transport: {
            read: function (options) {
                var available = angular.copy($scope.newVm.permissions);

                $scope.selectedNewRole.Permissions.forEach(function (row) {
                    available.remove(function (el) {
                        return el.Name === row.Name;
                    });
                });
                available = $filter('orderBy')(available, 'Name');
                options.success(available);
            }
        }
    });

    $scope.addItemToRole = function (dataItem) {
        $scope.selectedNewRole.Permissions.push(dataItem);
        $scope.gridDataSource.read();
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };


}

function addSubsidiariesToSelectedRoleModalController($scope, $uibModalInstance, helperService, $filter) {

    $scope.modalName = "Subsidiaries";

    var columns = [
        { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
        { template: "<a ng-click='addItemToRole(dataItem)'><i class='fa fa-plus pull-right text-success'></i></a>", title: " ", width: 20 }
    ];

    $scope.gridOptions = helperService.setMainGridOptions(columns, null);
    $scope.gridOptions.selectable = false;

    $scope.gridDataSource = new kendo.data.DataSource({
        pageSize: 1000,
        transport: {
            read: function (options) {

                var available = angular.copy($scope.newVm.subsidiaries);

                $scope.selectedNewRole.Subsidiaries.forEach(function (row) {
                    available.remove(function (el) {
                        return el.Id === row.Id;
                    });
                });
                available = $filter('orderBy')(available, 'Name');
                options.success(available);

            }
        }
    });

    $scope.addItemToRole = function (dataItem) {
        $scope.selectedNewRole.Subsidiaries.push(dataItem);
        $scope.gridDataSource.read();
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };


}

function addUsersToSelectedRoleModalController($scope, $uibModalInstance, helperService, $filter) {

    $scope.modalName = "Users";

    var columns = [
        { title: "Internal ID", field: "Id", filterable: customAutoCompleteFilter },
        { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
        { title: "Email", field: "Email", filterable: customAutoCompleteFilter },
        { title: "Default Subsidiary", field: "DefaultSubsidiaryName", filterable: customAutoCompleteFilter },
        { template: "<a ng-click='addItemToRole(dataItem)'><i class='fa fa-plus pull-right text-success'></i></a>", title: " ", width: 20 }
    ];

    $scope.gridOptions = helperService.setMainGridOptions(columns, null);
    $scope.gridOptions.selectable = false;

    $scope.gridDataSource = new kendo.data.DataSource({
        pageSize: 1000,
        transport: {
            read: function (options) {

                var available = angular.copy($scope.newVm.users);

                $scope.selectedNewRole.Users.forEach(function (row) {
                    available.remove(function (el) {
                        return el.Id === row.Id;
                    });
                });
                available = $filter('orderBy')(available, 'Name');
                options.success(available);
            }
        }
    });

    $scope.addItemToRole = function (dataItem) {
        $scope.selectedNewRole.Users.push(dataItem);
        $scope.gridDataSource.read();
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };


}

function addRolesToSelectedUserModalController($scope, $uibModalInstance, helperService, $filter) {

    var columns = [
        { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
        { template: "<a ng-click='addItemToUser(dataItem)'><i class='fa fa-plus pull-right text-success'></i></a>", title: " ", width: 20 }
    ];

    $scope.gridOptions = helperService.setMainGridOptions(columns, null);
    $scope.gridOptions.selectable = false;

    $scope.gridDataSource = new kendo.data.DataSource({
        pageSize: 1000,
        transport: {
            read: function (options) {

                var available = angular.copy($scope.newVm.currentSecurityModel.Roles);

                $scope.selectedNewUser.Roles.forEach(function (row) {
                    available.remove(function (el) {
                        return el.Id === row.Id;
                    });
                });
                available = $filter('orderBy')(available, 'Name');
                options.success(available);
            }
        }
    });

    $scope.addItemToUser = function (dataItem) {
        dataItem = dataItem.toJSON();
        $scope.selectedNewUser.Roles.push(dataItem);
        $scope.gridDataSource.read();
        let roleToModify = $scope.newVm.currentSecurityModel.Roles.find(function (role) {
            return role.Id === dataItem.Id;
        });
        const userToAdd = Object.assign({}, $scope.selectedNewUser);
        delete userToAdd.Roles;
        roleToModify.Users.push(userToAdd);
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };


}