import angular from 'angular';
import companyMappingDataServiceFactory from '../Components/CompanyMapping/companyMappingDataService.factory';
import companyTemplatesFactory from '../Components/CompanyTemplates/companyTemplates.factory';
import crossPlatformUsersDataServiceFactory from '../Components/CrossPlatformUsers/crossPlatformUsersDataService.factory';
import excludedRolesDataServiceFactory from '../Components/ExcludedRoles/excludedRolesDataService.factory';
import excludedUsersDataServiceFactory from '../Components/ExcludedUsers/excludedUsersDataService.factory';
import maintainSystemRolesDataServiceFactory from '../Components/maintainSystemRoles/maintainSystemRolesDataService.factory';
import maintainSystemUsersDataServiceFactory from '../Components/maintainSystemUsers/maintainSystemUsersDataService.factory';
import notificationsDataServiceFactory from '../Components/Notifications/notificationsDataService.factory';
import roleTemplatesFactory from '../Components/RoleTemplates/roleTemplates.factory';


export default angular
    .module('app.systemConfiguration.factories', [])
    .factory('companyTemplatesData', companyTemplatesFactory)
    .factory('notificationsDataService', notificationsDataServiceFactory)
    .factory('maintainSystemRolesDataService', maintainSystemRolesDataServiceFactory)
    .factory('roleTemplatesData', roleTemplatesFactory)
    .factory('companyMappingDataService', companyMappingDataServiceFactory)
    .factory('crossPlatformUsersDataService', crossPlatformUsersDataServiceFactory)
    .factory('excludedUsersDataService', excludedUsersDataServiceFactory)
    .factory('excludedRolesDataService', excludedRolesDataServiceFactory)
    .factory('maintainSystemUsersDataService', maintainSystemUsersDataServiceFactory)
    .name;