import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, ownershipGroup, isImport, helperService, ownershipGroupsDataService) {
    const vm = this;

    vm.isImport = isImport;

    vm.saveOwnershipGroup = saveOwnershipGroup;
    vm.cancel = cancel;

    vm.ownershipGroup = { ...ownershipGroup };

    activate();

    function activate() {
        getOwnershipGroupTypes();
    }

    async function saveOwnershipGroup() {
        vm.successText = 'Saved';
        vm.savePending = new createSpinner();
        if (vm.ownershipGroup.Id) {
            editGroup();
        } else if (isImport) {
            importOwnershipGroups();
        } else {
            createGroup();
        }
    }

    async function createGroup() {
        let data = { Name: vm.ownershipGroup.Name, TypeId: vm.ownershipGroup.Type.Id };
        try {
            let response = await ownershipGroupsDataService.createGroup(data);
            await helperService.successfulSaveButton(vm.savePending);
            close(response.data);
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    async function editGroup() {
        let data = { Name: vm.ownershipGroup.Name, GroupId: vm.ownershipGroup.Id };
        delete data.Id;
        try {
            await ownershipGroupsDataService.updateGroup(data);
            await helperService.successfulSaveButton(vm.savePending);
            close();
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    async function importOwnershipGroups() {
        vm.successText = 'Import Started';
        let fd = new FormData();
        fd.append(vm.files.files[0].name, vm.files.files[0].file);

        try {
            await ownershipGroupsDataService.importOwnershipGroups(fd);
            await helperService.successfulSaveButton(vm.savePending);
            close();
        } catch {
            vm.savePending.loadingValue = false;
        }
    }
    async function getOwnershipGroupTypes() {
        let response = await ownershipGroupsDataService.getOwnershipGroupTypes();
        vm.typesDataSource = response.data;
        if (vm.typesDataSource.length === 1) {
            vm.ownershipGroup.Type = vm.typesDataSource[0].Name;
        }
    }

    function close(roleId) {
        $uibModalInstance.close(roleId);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}