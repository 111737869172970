import angular from 'angular';
import netsuiteDataChangesDataServiceFactory from '../Components/DataChangesNetSuite/netsuiteDataChangesDataService.factory';
import allChangesDataServiceFactory from '../Components/NetSuiteAuditTrail/AllChanges/allChangesDataService.factory';
import optionSetsDataServiceFactory from '../Components/NetSuiteAuditTrail/MaintainOptionSets/optionSetsDataService.factory';
import userGroupsDataServiceFactory from '../Components/NetSuiteAuditTrail/MaintainUserGroups/userGroupsDataService.factory';
import documentationDataServiceFactory from '../Components/NetSuiteAuditTrail/ManageDocumentation/documentationDataService.factory';


export default angular
    .module('app.auditTrail.netSuite.factories', [])
    .factory('allChangesDataService', allChangesDataServiceFactory)
    .factory('optionSetsDataService', optionSetsDataServiceFactory)
    .factory('userGroupsDataService', userGroupsDataServiceFactory)
    .factory('nsAtDocumentationService', documentationDataServiceFactory)
    .factory('netsuiteDataChangesDataService', netsuiteDataChangesDataServiceFactory)
    .name;