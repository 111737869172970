import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, roleTemplatesData, helperService, roleTemplates, selectedTemplate) {

    const vm = this;

    vm.selectedTemplate = selectedTemplate;
    vm.cancel = cancel;
    vm.templateNamesMatch = templateNamesMatch;
    vm.submitForm = submitForm;

    if (selectedTemplate) {
        vm.selectedTemplate = selectedTemplate;
        vm.templateName = selectedTemplate.Name;
    }

    async function createRoleTemplate() {
        vm.loading = new createSpinner();
        let postData = { Name: vm.templateName };

        try {
            let response = await roleTemplatesData.createRoleTemplate(postData);
            let newTemplateId = response.data;
            await helperService.successfulSaveButton(vm.loading);
            vm.loading.loadingValue = false;
            $uibModalInstance.close(newTemplateId);
        } catch {
            vm.loading.loadingValue = false;
        }
    }

    function templateNamesMatch() {
        let match = roleTemplates.some(template => template.Name.toLowerCase() === vm.templateName.toLowerCase());
        if (match) {
            vm.roleTemplateForm.templateName.$setValidity('templateNameMatch', false);
        } else {
            vm.roleTemplateForm.templateName.$setValidity('templateNameMatch', true);
        }
    }

    async function renameRoleTemplate() {
        vm.loading = new createSpinner();

        let putData = {
            Name: vm.templateName,
            Id: vm.selectedTemplate.Id
        };

        try {
            await roleTemplatesData.renameRoleTemplate(putData.Id, putData);
            await helperService.successfulSaveButton(vm.loading);
            $uibModalInstance.close();
        } catch {
            vm.loading.loadingValue = false;
        }
    }

    function submitForm() {
        if (selectedTemplate) {
            renameRoleTemplate();

        } else {
            createRoleTemplate();
        }
    }


    function cancel() {
        $uibModalInstance.dismiss();
    }

}