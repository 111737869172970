import createSpinner from "../../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (reviewAssignmentsDataService, $uibModalInstance, helperService) {
    const vm = this;
    vm.cancel = cancel;
    vm.dismiss = dismiss;
    vm.disableButton = false;
    vm.importSchedules = importSchedules;

    function cancel() { $uibModalInstance.close(); }
    function dismiss() { $uibModalInstance.dismiss(); }

    // Import Schedules
    async function importSchedules(file) {
        vm.importPending = new createSpinner();
        vm.disableButton = true;
        let fd = new FormData();
        fd.append(file[0].name, file[0].file);
        try {
            await reviewAssignmentsDataService.importReviews(fd);
            await helperService.successfulSaveButton(vm.importPending);
            cancel();
        } catch {
            vm.importPending.loadingValue = false;
            vm.disableButton = false;
        }
    }
}