export default /*@ngInject*/ function () {
    const vm = this;

    vm.selectedScheduleChanged = selectedScheduleChanged;
    vm.reviewAssignmentsUpdated = reviewAssignmentsUpdated;
    vm.selectedOwnershipGroupChanged = selectedOwnershipGroupChanged;

    function selectedScheduleChanged(schedule) {
        vm.selectedInstanceAssignment = null;
        vm.selectedOwnershipGroup = null;
        vm.selectedSchedule = schedule;
    }

    function selectedOwnershipGroupChanged(ownershipGroup) {
        vm.selectedOwnershipGroup = ownershipGroup;
    }

    function reviewAssignmentsUpdated(updateList) {
        vm.updateAssignmentsList = updateList;
    }
}