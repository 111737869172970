import angular from 'angular';
import buildRoleBPPostBodyFactory from '../Components/roleBusinessProcess/buildRoleBPPostBody.factory';
import risksDataServiceFactory from '../APICalls/risksDataService.factory';
import buildUserBPPostBodyFactory from '../Components/userBusinessProcess/buildUserBPPostBody.factory';
import BuildUserRisksPostBody from '../Components/userRisk/BuildUserRisksPostBody';
import responsibilityRiskPostBody from '../Components/responsibilityRisk/responsibilityRiskPostBody';
import controlLibraryDataServiceFactory from '../Components/controlLibrary/controlLibraryDataService.factory';
import businessCyclesDataServiceFactory from '../Components/maintainBusinessCycles/businessCyclesDataService.factory';
import mitigationsDataServiceFactory from '../Components/maintainMitigations/mitigationsDataService.factory';
import riskLevelsFactory from '../Components/maintainRiskLevels/riskLevels.factory';
import statusDataServiceFactory from '../Components/maintainStatuses/statusDataService.factory';
import AutoMitigationDataService from '../APICalls/AutoMitigationDataService';
import peoplesoftAutoMitigationsDataService from '../Components/Peoplesoft/autoMitigations/autoMitigationsDataService.factory';
import autoMitigationsDataServiceFactory from '../Components/AutoMitigations/autoMitigationsDataService.factory';
import businessProcessDataServiceFactory from '../Components/MaintainBusinessProcess/businessProcessDataService.factory';
import riskRulesetDataServiceFactory from '../Components/maintainRiskRuleset/riskRulesetDataService.factory';
import riskRulesetMapperService from '../Components/maintainRiskRuleset/objects/riskRulesetMapper.service';
import assignRisksDataServiceFactory from '../Components/assignRisks/assignRisksDataService.factory';
import BuildRoleAccessPostBody from '../Components/roleAccess/BuildRoleAccessPostBody';
import BuildUserAccessPostBody from '../Components/userAccess/BuildUserAccessPostBody';
import BuildResponsibilityAccessPostBody from '../Components/responsibilityAccess/responsibilityAccessPostBody';
import maintainProfilesDataServiceFactory from '../Components/maintainProfiles/maintainProfilesDataService.factory';

export default angular
    .module('app.accessRiskMonitor.factories', [])
    .factory('buildRoleBPPostBody', buildRoleBPPostBodyFactory)
    .factory('risksDataService', risksDataServiceFactory)
    .factory('businessProcessDataService', businessProcessDataServiceFactory)
    .factory('buildUserBPPostBody', buildUserBPPostBodyFactory)
    .factory('BuildUserRisksPostBody', BuildUserRisksPostBody)
    .factory('buildResponsibilityRiskPostBody', responsibilityRiskPostBody)
    .factory('controlLibraryDataService', controlLibraryDataServiceFactory)
    .factory('businessCyclesDataService', businessCyclesDataServiceFactory)
    .factory('riskRulesetService', riskRulesetDataServiceFactory)
    .factory('riskRulesetMapper', riskRulesetMapperService)
    .factory('mitigationsDataService', mitigationsDataServiceFactory)
    .factory('riskLevelsData', riskLevelsFactory)
    .factory('statusDataService', statusDataServiceFactory)
    .factory('AutoMitigationDataService', AutoMitigationDataService)
    .factory('peoplesoftAutoMitigationsDataService', peoplesoftAutoMitigationsDataService)
    .factory('autoMitigationsDataService', autoMitigationsDataServiceFactory)
    .factory('assignRisksDataService', assignRisksDataServiceFactory)
    .factory('BuildRoleAccessPostBody', BuildRoleAccessPostBody)
    .factory('BuildUserAccessPostBody', BuildUserAccessPostBody)
    .factory('BuildResponsibilityAccessPostBody', BuildResponsibilityAccessPostBody)
    .factory('maintainProfilesDataService', maintainProfilesDataServiceFactory)
    .name;