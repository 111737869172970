import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, approvalRulesDataService, $filter, helperService) {

    const vm = this;
    vm.updatedPriorities = [];

    vm.priorityChanged = priorityChanged;
    vm.updateRuleTypes = updateRuleTypes;
    vm.dismiss = dismiss;

    activate();

    function activate() {
        getApprovalRuleTypes();
    }

    async function getApprovalRuleTypes() {
        let response = await approvalRulesDataService.getApprovalRuleTypes();
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.approvalRuleTypesDataSource = response.data;
    }

    function priorityChanged(ruleType) {
        if (ruleType.Priority > 0) {
            const ruleTypeToUpdate = {
                Id: ruleType.Id,
                Priority: ruleType.Priority
            };
            const ruleTypeIndex = vm.updatedPriorities.findIndex(e => e.Id === ruleType.Id);
            if (ruleTypeIndex < 0) {
                vm.updatedPriorities.push(ruleTypeToUpdate);
            } else {
                vm.updatedPriorities[ruleTypeIndex] = ruleTypeToUpdate;
            }
        }
    }

    async function updateRuleTypes() {
        vm.updateInProgress = new createSpinner();
        const ruleTypes = vm.updatedPriorities;
        try {
            for (const ruleType of ruleTypes) {
                await approvalRulesDataService.updateApprovalRuleType(ruleType.Id, ruleType);
            }
            await helperService.successfulSaveButton(vm.updateInProgress);
            cancel();
        } catch {
            vm.updateInProgress.loadingValue = false;
        }
    }

    function cancel() {
        $uibModalInstance.close();
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }

}