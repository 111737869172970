export default /*@ngInject*/ function ($scope) {

    //If $scope variable doesnt exist in child it looks in parent

    $scope.validateNumberOfNetSuiteRoles = validateNumberOfNetSuiteRoles;

    function validateNumberOfNetSuiteRoles() {
        $scope.vm.numberOfNetSuiteRolesValid = null;
        if ($scope.vm.wizardMode === 'Modify') {
            $scope.vm.numberOfNetSuiteRolesValid = true;
        } else {
            if ($scope.vm.ListOfSelectedNetSuiteRoles.length > 0) {
                $scope.vm.numberOfNetSuiteRolesValid = true;
            }
        }
    }

    $scope.addRoleToList = function (role) {
        $scope.vm.ListOfAvailableNetSuiteRoles.forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfAvailableNetSuiteRoles.splice(i, 1);
                $scope.vm.ListOfSelectedNetSuiteRoles.push(row);
            }
        });
        $scope.vm.IsRisksReviewed = null;
        validateNumberOfNetSuiteRoles();
    };

    $scope.deleteSelectedRole = function (role) {
        $scope.vm.ListOfSelectedNetSuiteRoles.forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfSelectedNetSuiteRoles.splice(i, 1);
                if (role.IsAssignable) {
                    $scope.vm.ListOfAvailableNetSuiteRoles.push(row);
                }
            }
        });
        $scope.vm.IsRisksReviewed = null;
        validateNumberOfNetSuiteRoles();
    };

    $scope.setSelectedRoleTemplates = function (selectedRoleTemplates) {
        $scope.vm.selectedRoleTemplates = selectedRoleTemplates;
    };
}