export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getCurrentSharedUsers,
        getExports,
        getExportsSharedWithUser,
        updateSharedUsers,
        deleteExport,
        saveNameChange
    };

    return service;

    async function getCurrentSharedUsers(exportId) {
        try {
            return await await $http.get(`${apiUrl}api/core/exports/${exportId}/sharedusers`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getExports(userId) {
        try {
            return await $http.get(`${apiUrl}api/core/exports/users/${userId}`)
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getExportsSharedWithUser(userId) {
        try {
            return await $http.get(`${apiUrl}api/core/exports/sharedexports/users/${userId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateSharedUsers(postData, exportId) {
        try {
            return await $http.post(`${apiUrl}api/core/exports/${exportId}/share`, postData);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteExport(itemId) {
        try {
            return await $http.delete(`${apiUrl}api/core/exports/${itemId}`)
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function saveNameChange(itemId, postItem) {
        try {
            return await $http.put(`${apiUrl}api/core/exports/${itemId}`, postItem);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }


    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}