import angular from 'angular';
import roleBusinessProcessController from '../Components/roleBusinessProcess/roleBusinessProcess.controller';
import roleRisksController from '../Components/roleRisks/roleRisks.controller';
import userBusinessProcessController from '../Components/userBusinessProcess/userBusinessProcess.controller';
import userRiskController from '../Components/userRisk/userRisk.controller';
import accessRiskMonitorNetSuiteModule from './armNetSuite.module';
import accessRiskMonitorFactoriesModule from './accessRiskMonitor.factories.module';
import D365ArmQuantificationController from '../Components/D365ArmQuantification/D365ArmQuantification.controller';
import responsibilityRiskController from '../Components/responsibilityRisk/responsibilityRisk.controller';
import accessRiskMonitorSAPModule from './armSAP.module';
import accessRiskMonitorSetupModule from './accessRiskMonitor.setup.module';
import roleAccessController from '../Components/roleAccess/roleAccess.controller';
import userAccessController from '../Components/userAccess/userAccess.controller';
import responsibilityAccessController from '../Components/responsibilityAccess/responsibilityAccess.controller';

export default angular
    .module('app.accessRiskMonitor', [accessRiskMonitorFactoriesModule, accessRiskMonitorNetSuiteModule, accessRiskMonitorSAPModule, accessRiskMonitorSetupModule])
    .controller("roleBusinessProcessController", roleBusinessProcessController)
    .controller("roleRisksController", roleRisksController)
    .controller("userBusinessProcessController", userBusinessProcessController)
    .controller("userRiskController", userRiskController)
    .controller("D365ArmQuantificationController", D365ArmQuantificationController)
    .controller("responsibilityRiskController", responsibilityRiskController)
    .controller("roleAccessController", roleAccessController)
    .controller("userAccessController", userAccessController)
    .controller("responsibilityAccessController", responsibilityAccessController)
    .name;