import simulationsListController from "./simulationsList.controller";

/* simulationsList.component.js */

/**
 * @desc component for viewing and maintaining list of sap security designer simulations
 * @example <sap-sd-simulations-list></sap-sd-simulations-list>
 */

const sapSdSimulationsList = {
    bindings: {
        selectedSimulation: '<',
        updateSimulationsList: '<',
        onUpdateSimulationsList: '&',
        onSelectedSimulationChanged: '&',
        onViewAnalysisClicked: '&'
    },
    controller: simulationsListController,
    controllerAs: 'ssdslvm',
    templateUrl: '/App/Components/SAPSecurityDesigner/simulationsList/simulationsList.tmpl.html'
};

export default sapSdSimulationsList;