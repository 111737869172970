import createSpinner from "../../../Functions/createSpinner";
import { customAutoCompleteFilter, standardGridPageSize } from "../../../GlobalVariables/filterVariables";

export default /*@ngInject*/ function (helperService, accessDetailsProfileDataService) {
    const vm = this;
    vm.helperService = helperService;

    vm.accessCollapsed = true;
    vm.businessProcessProductCollapsed = true;

    vm.$onInit = activate;

    async function activate() {
        vm.data = await getBpGridData();
        setupGrid();
    }

    function setupGrid() {
        let businessProcessProductProfileGridColumns;
        if (vm.data.length) {
            let accessDetailFields = Object.keys(vm.data[0]);
            businessProcessProductProfileGridColumns = accessDetailFields.map(field => {
                return {
                    field: field,
                    title: helperService.headerStringTransformer(field),
                    width: 120,
                    filterable: customAutoCompleteFilter
                }
            });
        } else {
            businessProcessProductProfileGridColumns = null;
        }

        vm.businessProcessProductProfileGridOptions = helperService.setMainGridOptions(businessProcessProductProfileGridColumns, 400);
        vm.businessProcessProductProfileGridOptions.autoBind = true;

        vm.businessProcessProductProfileGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            noRecords: {
                template: "<div class='no-data-to-display-message'>No items to display</div>"
            },
            transport: {
                read: async options => {
                    try {
                        options.success(vm.data);
                    } catch (err) {
                        return;
                    }
                }
            }
        });
    }

    async function getBpGridData() {
        vm.loading = new createSpinner();
        const postData = {
            "UserMasterId": vm.businessProcess.UserMasterId,
            "BusinessProcessId": vm.businessProcess.BusinessProcessId,
            "CompanyId": vm.businessProcess.CompanyId
        };

        try {
            vm.loading.loadingValue = true;
            let response;

            if (vm.businessProcess.SecurityObjectIdentifier) {
                response = await accessDetailsProfileDataService.getObjectLevelBusinessProcessDetails(vm.businessProcess, postData);
            } else {
                response = await accessDetailsProfileDataService.getBusinessProcessDetails(vm.businessProcess, postData);
            }

            vm.loading.loadingValue = false;

            return response.data.reduce((acc, curr) => {
                return acc.concat(curr.AccessDetails)
            }, []);
        } catch {
            vm.loading.loadingValue = false;
        }
    }
}