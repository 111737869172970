import controller from "./securityAssignment.controller";

/* securityAssignment.component.js */

/**
 * @desc component for viewing and maintaining system roles
 * @example <security-assignment></security-assignment>
 */

const securityAssignment = {
    controller: controller,
    controllerAs: 'savm',
    templateUrl: '/App/Components/securityAssignment/securityAssignment.tmpl.html'
};

export default securityAssignment;