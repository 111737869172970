export default /*@ngInject*/ function ($http) {

    this.getAllRoles = function (productId) {
        try {
            return $http({
                method: 'GET',
                url: `${apiUrl}api/identitymanager/uv/roles/product/${productId}`
            });
        }
        catch (ex) {
            throw 'Error loading GP Roles';
        }
    };

    this.getAllCompanies = function (productId) {
        try {
            return $http({
                method: 'GET',
                url: `${apiUrl}api/identitymanager/uv/companies/product/${productId}`
            });
        }
        catch (ex) {
            throw 'Error loading GP Companies';
        }
    };

}