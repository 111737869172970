import templateDetailsController from "./templateDetails.controller";


/* templateDetails.component.js */

/**
 * @desc component for Template Tracking Details
 * @example <template-details></template-details>
 */

const templateDetails = {
    controller: templateDetailsController,
    controllerAs: 'tdvm',
    templateUrl: '/App/Components/TemplateTracking/TemplateTrackingDetails/templateDetails.tmpl.html'
};

export default templateDetails;