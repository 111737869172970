import { customAutoCompleteFilterEqualToOnly, customAutoCompleteFilter, customDatePickerFilter } from "../../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($scope, $uibModalInstance, helperService, $http, linkedTicketsArray) {

    var modalVm = $scope.modalVm = {};
    var statusTemplate = "<span class='review-status-capitalized'>{{dataItem.Status}}</span>";

    var columnsFromVM = [
        { field: "Status", title: "Status", template: statusTemplate, filterable: false },
        { field: "Id", title: "Id", filterable: customAutoCompleteFilterEqualToOnly },
        { field: "Subject", title: "Subject", filterable: customAutoCompleteFilter },
        { field: "Description", title: "Description", template: "{{ dataItem.Description }}", filterable: customAutoCompleteFilter }, // use template to handle possible issues with characters in description field
        { field: "CreatedDate", title: "Date Created", template: "{{ dataItem.CreatedDate | date:'short'}}", filterable: customDatePickerFilter },
        {
            title: ' ', width: 90, template: `<span ng-disabled="modalVm.ticketLinked" ng-if='!dataItem.isLinked' class='btn btn-sm btn-secondary fixed-btn-width' ng-click='modalVm.createFreshServiceLink(dataItem)'>Link</span>
                                              <span ng-if='dataItem.isLinked' class='btn btn-sm btn-secondary fixed-btn-width'>Unlink</span>`
        }
    ];

    $scope.$evalAsync(function () {
        modalVm.gridOptions = helperService.setMainGridOptions(columnsFromVM, 400);
        modalVm.gridOptions.selectable = false;
        modalVm.gridOptions.pageable = false;
        modalVm.gridOptions.filterable = {
            mode: 'row',
            operators: {
                string: {
                    contains: "Contains"
                }
            }
        };
    });

    modalVm.createFreshServiceLink = function (dataItem) {

        let linkedFreshServiceTicket = {};

        linkedFreshServiceTicket.TicketId = dataItem.Id;
        linkedFreshServiceTicket.TicketProvider = 'Freshservice';

        linkedTicketsArray.push(linkedFreshServiceTicket);

        dataItem.isLinked = true;
        modalVm.ticketLinked = true;
        $scope.$parent.freshServiceTicketLinked = true;

        $uibModalInstance.close();
    };

    modalVm.gridDataSource = new kendo.data.DataSource({
        serverFiltering: true,
        schema: {
            model: {
                fields: {
                    CreatedDate: { type: "date" }
                }
            }
        },
        transport: {
            read: function (options) {
                var sortsAndFilters = helperService.prepareSortsAndFilters(options);

                var postBody = {
                    "Criteria": sortsAndFilters.Filters
                };


                $http.post(apiUrl + 'api/core/tickets/freshservice/search/', postBody).then(function (response) {
                    modalVm.TotalTicketCount = response.data.Count;
                    options.success(response.data.Tickets);
                }, function (response) {
                    options.error();
                });
            }
        }
    });

    $scope.cancel = function () { $uibModalInstance.close(); };

}