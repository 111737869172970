import controller from "./customProductConnectionSettings.controller";

/* customProductConnectionSettings.component.js */

/**
 * @desc component for configuring custom product connection settings.
 * @example <custom-product-connection-settings></custom-product-connection-settings>
 */

const customProductConnectionSettings = {
    bindings: {
        product: '<',
        currentConfig: '=',
        originalConfig: '<',
        configUpdate: '<',
        allFpAuditConfigs: '<'
    },
    controller: controller,
    controllerAs: 'cpcsvm',
    templateUrl: '/App/Components/Connections/CustomProductConnection/Settings/customProductConnectionSettings.tmpl.html'
};

export default customProductConnectionSettings;