import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (helperService, manageDataDataService) {
    const vm = this;

    vm.updateProductConfiguration = updateProductConfiguration;
    vm.configValueChange = configValueChange;
    vm.productConfig = {};

    vm.$onInit = activate;

    async function activate() {
        vm.productImageClass = helperService.setProductImageClass(vm.product.Type);
        await setProductConfiguration();
        vm.configValuesUnchanged = true;
    }

    async function setProductConfiguration() {
        let { data } = await manageDataDataService.getConfigurationByProductId(vm.product.ID);

        data.forEach(item => {
            vm.productConfig[item.Name] = item.Value;
        });

        vm.unchangedProductConfig = { ...vm.productConfig };
    }

    function configValueChange() {
        vm.configValuesUnchanged = angular.equals(vm.unchangedProductConfig, vm.productConfig);
    }

    async function updateProductConfiguration() {
        let putData = {
            "Configurations": [],
            "ProductId": vm.product.ID
        },
        configList = [{
            Name: "SAP_S4CatalogValidation",
            Encrypted: false
        }];

        vm.updatePending = new createSpinner();

        configList.forEach(item => {
            if (vm.productConfig[item.Name] !== vm.unchangedProductConfig[item.Name]) {
                putData.Configurations.push({
                    "Name": item.Name,
                    "Value": vm.productConfig[item.Name]
                });
            }
        });

        try {
            await manageDataDataService.setProductConfiguration(vm.product.ID, putData);
            vm.unchangedProductConfig = { ...vm.productConfig };
            configValueChange();
            await helperService.successfulSaveButton(vm.updatePending);
        } catch {
            vm.updatePending.loadingValue = false
        }
    }
}