export default /*@ngInject*/ function ($scope, configurationDataService) {

    activate();

    async function activate() {
        await setRoleDescConfigValue();
        await setOrgHierarchiesConfigValue();
    }

    async function setRoleDescConfigValue() {
        let response = await configurationDataService.getProductConfigurationValueByName('ax7', 'IM_ShowRoleDescription');
        $scope.roleDesc = response.data.Value === '1';
    }

    async function setOrgHierarchiesConfigValue() {
        let response = await configurationDataService.getProductConfigurationValueByName('ax7', 'IM_EnableOrgHierarchies');
        $scope.vm.orgHierarchiesEnabled = response.data.Value === '1';
    }

    //If $scope variable doesnt exist in child it looks in parent

    var RoleCompany = function (params) {
        this.RoleId = params.RoleId;
        this.CompanyId = params.CompanyId;
        this.RoleName = params.RoleName;
        this.CompanyName = params.CompanyName;
        this.AllCompanies = params.AllCompanies;
    };

    $scope.AllCompanies = false;
    $scope.validateNumberOfAXRoles = validateNumberOfAXRoles;

    function validateNumberOfAXRoles() {
        $scope.vm.numberOfAXRolesValid = null;
        if ($scope.vm.ListOfSelectedAX7Roles.length > 0) {
            $scope.vm.numberOfAXRolesValid = true;
        }
        if ($scope.vm.ListOfExcludedAX7Roles.length > 0) {
            $scope.vm.numberOfAXRolesValid = true;
        }
    }

    $scope.addRoleToList = function (role) {
        $scope.vm.ListOfAvailableAX7Roles.forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfAvailableAX7Roles.splice(i, 1);
                $scope.vm.ListOfSelectedAX7Roles.push(role);
            }
        });
        $scope.vm.IsRisksReviewed = null;
        validateNumberOfAXRoles();
    };

    $scope.deleteSelectedRole = function (role) {
        $scope.vm.ListOfSelectedAX7Roles.forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfSelectedAX7Roles.splice(i, 1);
                if (role.IsAssignable) {
                    $scope.vm.ListOfAvailableAX7Roles.push(row);
                }
            }
        });

        $scope.vm.IsRisksReviewed = null;
        validateNumberOfAXRoles();
    };

    $scope.deleteExcludedRole = function (role) {
        $scope.vm.ListOfExcludedAX7Roles.forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfExcludedAX7Roles.splice(i, 1);
                $scope.vm.ListOfAvailableAX7Roles.push(row);
            }
        });

        $scope.vm.IsRisksReviewed = null;
        validateNumberOfAXRoles();
    };

    $scope.addRoleCompany = function (company, selected) {
        $scope.vm.ListOfSelectedAX7Roles.forEach(function (row, i) {
            if (row.Id === $scope.selectedRole.Id) {

                if (!row.CompanyId || selected === false) {
                    $scope.vm.AX7RoleCompanyList.splice(i, 1);
                }
                if (selected === true) {
                    $scope.vm.AX7RoleCompanyList.push(
                        new RoleCompany({
                            RoleId: row.RoleId,
                            CompanyId: company.CompanyId,
                            RoleName: row.RoleName,
                            CompanyName: company.CompanyName,
                            AllCompanies: false
                        }));
                }

            }
        });
    };

    $scope.updateSelectedRoleCompanies = function (selectedRole, prop, value, applyToAll) {
        if (applyToAll) {
            $scope.vm.ListOfSelectedAX7Roles.forEach(role => {
                role[prop] = value;
            })
        } else {
            selectedRole[prop] = value;
        }
    };

    $scope.setSelectedRoleTemplates = function (selectedRoleTemplates) {
        $scope.vm.selectedRoleTemplates = selectedRoleTemplates;
    };

    $scope.selectedRole = null;

}