import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (manageDataDataService, helperService, $uibModalInstance, systemFields, product, selectedFieldId) {

    const vm = this;

    vm.cancel = cancel;
    vm.configValueChange = configValueChange;
    vm.updateProductConfiguration = updateProductConfiguration;

    vm.systemFields = systemFields;
    vm.selectedFieldId = selectedFieldId;
    vm.originalFieldId = selectedFieldId;
    vm.product = product;

    function configValueChange() {
        vm.valueChanged = vm.originalFieldId !== vm.selectedFieldId;
    }

    async function updateProductConfiguration() {
        let putData = {
            Configurations: [{
                Name: 'AutoMappingSystemFieldId',
                Value: vm.selectedFieldId
            }],
            ProductId: vm.product.ID
        }

        vm.updatePending = new createSpinner();

        try {
            await manageDataDataService.setProductConfiguration(vm.product.ID, putData);
            configValueChange();
            await helperService.successfulSaveButton(vm.updatePending);
            close(vm.selectedFieldId);
        } catch {
            vm.updatePending.loadingValue = false
        }
    }

    function close(fieldId) {
        $uibModalInstance.close(fieldId);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }

}