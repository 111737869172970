export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getIncidentLogsByControlId,
        getStatuses, 
        updateIncident
    };

    return service;

    async function getIncidentLogsByControlId(controlId) {
        try {
            return await $http.get(`${apiUrl}api/transactionmonitoring/or/controls/${controlId}/imports`);
        } catch (err) {
            showError(err);
            sendError(err);
        };
    }

    async function getStatuses() {
        try {
            return await $http.get(`${apiUrl}api/transactionmonitoring/or/statuses`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateIncident(incidentId, putData) {
        try {
            return await $http.put(`${apiUrl}api/transactionmonitoring/or/incidents/${incidentId}`, putData);
        } catch (err) {
            showError(err);
            sendError(err);
        };
    }

    // Error Handling

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}