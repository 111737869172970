/* userProfile.component.js */

import userProfileController from "./userProfile.controller";

/**
 * @desc component for viewing user profile information
 * @example <user-profile></user-profile>
 */

const userProfile = {
    bindings: {
        userMasterId: '<',
        userId: '<',
        userDisplay: '<',
        productId: '<'
    },
    controller: userProfileController,
    controllerAs: 'upvm',
    templateUrl: '/App/Shared/Components/userProfile/userProfile.tmpl.html'
};

export default userProfile;