import riskLevelsGridController from "./riskLevelsGrid.controller";

/* riskLevelsGrid.component.js */

/**
 * @desc component for displaying and selecting risk levels in a grid
 * @example <risk-levels-grid></risk-levels-grid>
 */

const riskLevelsGrid = {
    controller: riskLevelsGridController,
    controllerAs: 'rlgvm',
    templateUrl: '/App/Components/maintainRiskLevels/riskLevelsGrid/riskLevelsGrid.tmpl.html'
};

export default riskLevelsGrid;