import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, requesterGroupsDataService) {

    const vm = this;
    vm.cancel = cancel;
    vm.dismiss = dismiss;
    vm.disableButton = false;
    vm.importRequesterGroups = importRequesterGroups;

    function cancel() { $uibModalInstance.close(); }
    function dismiss() { $uibModalInstance.dismiss(); }

    // Import Requester Groups
    async function importRequesterGroups(file) {
        vm.importPending = new createSpinner();
        vm.disableButton = true;
        let fd = new FormData();
        fd.append(file[0].name, file[0].file);
        try {
            await requesterGroupsDataService.importRequesterGroups(fd);
            await helperService.successfulSaveButton(vm.importPending);
            cancel();
        } catch {
            vm.importPending.loadingValue = false;
            vm.disableButton = false;
        }
    }
}