export default /*@ngInject*/ function ($http, errorsDataService) {
    const factory = {
        getCompanies,
        getUsers,
        getRulesets,
        getRiskTypes,
        getRisks,
        getAllRisks
    };

    return factory;

    async function getCompanies() {
        try {
            return await $http.get(`${apiUrl}api/universal/companymaster`);
        } catch (err) {
            showError(err);
        }
    }

    async function getUsers(data, params) {
        try {
            return await $http({
                method: 'POST',
                url: `${apiUrl}api/universal/usermaster`,
                data: data,
                params: params
            });
        } catch (err) {
            showError(err);
        }
    }

    async function getRulesets() {
        try {
            return await $http.get(`${apiUrl}api/arm/riskrulesets`);
        } catch (err) {
            showError(err);
        }
    }

    async function getRiskTypes() {
        try {
            return await $http.get(`${apiUrl}api/arm/risks/types`);
        } catch (err) {
            showError(err);
        }
    }


    async function getRisks(rulesetId) {
        try {
            return await $http.get(`${apiUrl}api/arm/risks/ruleset/${rulesetId}`);
        } catch (err) {
            showError(err);
        }
    }

    async function getAllRisks() {
        try {
            return await $http.get(`${apiUrl}api/arm/risks`);
        } catch (err) {
            showError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}
