import createSpinner from '../Functions/createSpinner';
import ReportSchedulerModalController from './ReportSchedulerModalController';

export /*@ngInject*/ function reportToolbarController($scope, $rootScope, $uibModal, authenticationService, SavedReportSelectorService, $http, $q, helperService, applicationInsightsService, configurationDataService) {

    $scope.helperService = helperService;
    $scope.shouldBeOpen = true;
    $scope.config = {};

    configurationDataService.getConfigurationValues().then(response => {
        response.data.forEach(row => {
            $scope.config[row.Name] = row.Value;
        });
    });

    $scope.openManageSavedLayoutsModal = function () {
        $uibModal.open({
            templateUrl: 'App/Shared/Partials/ManageSavedLayoutsModal.html',
            controller: manageSavedLayoutsModalController,
            backdrop: 'static',
            scope: $scope
        });
    };

    function countShowingColumns(array) {
        var filteredArray = array.filter(col => !col.hidden);
        return filteredArray.length;
    }

    $scope.hideShowColumn = function (columns, column) {

        if (column.isLast != true) {
            //If there are two columns left visible and one is being removed set the last column to isLast which prevents that column from being removed and changes the look
            if (countShowingColumns(columns) === 2 && !column.hidden) {
                var lastColumn = columns.find(col => !col.hidden && col.field !== column.field);
                if (lastColumn) {
                    lastColumn.isLast = true;
                }
            } else if (!column.isLast) {
                //Reset the isLast value to false if there is more than one column left
                columns.forEach(function (col) {
                    col.isLast = false;
                });
            }


            //Don't change the last column
            var index = columns.indexOf(column);

            if (column.hidden === true) {
                $scope.grid.showColumn(index);
            } else {
                $scope.grid.hideColumn(index);
                for (var i = 0; i < $scope.grid.columns.length; i++) {
                    //Remove width on columns in places so auto fit can resize them
                    $scope.grid.columns[i].width = null;
                    if ($scope.grid.columns[i].hidden == false) {
                        $scope.grid.autoFitColumn(i + 1);
                        //6 is an arbitrary number for the possible columns visible on a given page
                        //When setting auto fit on a report with a large number of columns it can slow down the UI
                        //6 could be set to the number of columns currently viewable on the screen if that information can be accessed
                        if (i > 6) {
                            break;
                        }
                    }
                }
            }
        }
    };

    $scope.openSaveReportModal = async function () {
        $scope.disableToolbarButtons = true;
        $scope.FilterAppliedToGrid = $scope.grid.getOptions().dataSource.filter;
        if ($scope.populateCriteriaModel) {
            $scope.populateCriteriaModel();
        }

        const currentUser = await authenticationService.getUser();
        $http({
            method: 'GET',
            url: `${apiUrl}api/core/savedreports/users/${currentUser.userId}`
        }).then(function (response) {
            $scope.disableToolbarButtons = false;

            var raw = response.data,
                item,
                i;

            $scope.CurrentSavedReports = [];

            for (i = response.data.length - 1; i >= 0; i--) {
                item = raw[i];

                if (item.ReportDefinition.ReportId == $scope.reportId) {
                    $scope.CurrentSavedReports.push(item);
                }

            }

            if ($scope.CurrentSavedReports.length == 0) {
                $scope.showOverwrite = false;
            }
            else {
                $scope.showOverwrite = true;
            }

            $uibModal.open({
                templateUrl: 'App/Shared/Partials/SaveReportModal.html',
                controller: SaveReportModalController,
                backdrop: 'static',
                scope: $scope
            });

        }, function errorCallback(response) {
            $scope.disableToolbarButtons = false;
        });

    };

    $scope.openScheduleReportModal = async function () {
        $scope.disableToolbarButtons = true;
        const currentUser = await authenticationService.getUser();

        $http({
            method: 'GET',
            url: `${apiUrl}api/core/savedreports/users/${currentUser.userId}`
        }).then(function (response) {
            $scope.disableToolbarButtons = false;

            var raw = response.data,
                item,
                i;

            $scope.CurrentSavedReports = [];
            $scope.isScheduleEnabled = false;

            for (i = response.data.length - 1; i >= 0; i--) {
                item = raw[i];

                if (item.ReportDefinition.ReportId == $scope.reportId) {
                    $scope.CurrentSavedReports.push(item);
                }
            }

            if ($scope.CurrentSavedReports.length == 0) {
                $scope.showScheduleReport = false;
            }
            else {
                $scope.showScheduleReport = true;
            }

            $uibModal.open({
                templateUrl: 'App/Shared/Partials/ScheduleReportModal.html',
                controller: scheduleReportModalToolbarController,
                backdrop: 'static',
                scope: $scope
            });

        }, function errorCallback(response) {
            $scope.disableToolbarButtons = false;
        });

    };

    $scope.findCurrentSavedReportByObjectID = async function (objectID) {
        var deferred = $q.defer();
        const currentUser = await authenticationService.getUser();

        $http({
            method: 'GET',
            url: `${apiUrl}api/core/savedreports/users/${currentUser.userId}`
        }).then(function (response) {
            var raw = response.data,
                item,
                i,
                currentSavedReports = [];

            for (i = response.data.length - 1; i >= 0; i--) {
                item = raw[i];

                if (item.ReportDefinition.ObjectId == objectID && item.detailed == $scope.isDetailed) {
                    currentSavedReports.push(item);
                }

            }

            deferred.resolve(currentSavedReports);

            return deferred.promise;
        });

    };

    $scope.openSignReportModal = function () {
        if ($scope.populateCriteriaModel) $scope.populateCriteriaModel();
        $uibModal.open({
            component: 'signReport',
            backdrop: 'static',
            resolve: {
                archiveReport: () => $scope.config['ArchiveSignedReports'] === '1',
                objectId: () => $scope.ObjectId,
                sortsAndFilters: () => $scope.sortsAndFilters,
                report: () => {
                    return {
                        id: $scope.reportId,
                        name: $scope.reportName,
                        definition: $scope.bundleReportDefinition($scope.grid.getOptions(), $scope.reportDetailsModel)
                    }
                }
            }
        });
    };

    $scope.openSaveFilterModal = function () {
        $scope.FilterAppliedToGrid = $scope.grid.getOptions().dataSource.filter;

        if ($scope.FilterAppliedToGrid?.filters) {
            $scope.filtersDataSource.read();

            $uibModal.open({
                templateUrl: 'App/Shared/Partials/SaveFilterModal.html',
                controller: SaveFilterModalController,
                backdrop: 'static',
                scope: $scope
            });

        } else {
            helperService.showErrorMessage('There is no filter to save');
        }

    };

    $scope.openFilterManagerModal = function () {

        $scope.filtersDataSource.read();
        $uibModal.open({
            templateUrl: 'App/Shared/Partials/FilterManagerModal.html',
            controller: FilterManagerModalController,
            backdrop: 'static',
            scope: $scope
        });
    };

    $scope.bundleReportDefinition = function (gridOptions, reportDetailsModel) {
        var reportDefinition = { 'Filters': null, 'Sortings': null, 'Columns': null, 'Criteria': null };
        reportDefinition.Sortings = helperService.halPrepareSortArray(gridOptions.dataSource.sort);
        reportDefinition.Columns = helperService.cleanReportColumns(gridOptions.columns);

        var filters = helperService.halPrepareFilterArray(gridOptions.dataSource.filter);
        // Needed for saving report until report toolbar is refactored
        if ($scope.$parent.$parent.drvm) $scope.vm.filterCriteria = $scope.$parent.$parent.drvm.filterCriteria;
        if ($scope.vm.filterCriteria) {
            if ($scope.vm.filterCriteria.query) {
                let standardFilters = $scope.vm.filterCriteria.query.filter(filter => {
                    return !filter.CriteriaValue;
                });
                filters = filters.concat(standardFilters);

                var criteriaToAdd = $scope.vm.filterCriteria.query.filter(filter => {
                    return filter.CriteriaValue;
                });
            }
            if ($scope.vm.filterCriteria.dateQuery) {
                reportDefinition.DateFilters = $scope.vm.filterCriteria.dateQuery;
            }
            if ($scope.sortsAndFilters) {
                if ($scope.sortsAndFilters.DateFilters.length > 0) {
                    reportDefinition.DateFilters = $scope.sortsAndFilters.DateFilters.slice();
                }
                if ($scope.dateCriteria && $scope.sortsAndFilters.Filters.some(filter => filter.FieldName === $scope.dateCriteria[0].FieldName)) {
                    const missingDateRangeFilters = $scope.sortsAndFilters.Filters.filter(filter => filter.FieldName === $scope.dateCriteria[0].FieldName);
                    filters = filters.concat(missingDateRangeFilters);
                }
            }
        }

        reportDefinition.Filters = filters;
        reportDefinition.Criteria = SavedReportSelectorService.cleanReportCriteria(reportDetailsModel, criteriaToAdd);

        return reportDefinition;
    };

    $scope.bundleReportDefinitionCriteriaAndColumnsOnly = function (gridOptions, reportDetailsModel) {
        var reportDefinition = { 'Filters': null, 'Sortings': null, 'Columns': null, 'Criteria': null },
            filtersToAdd = [],
            criteriaToAdd = [];

        reportDefinition.Columns = helperService.cleanReportColumns(gridOptions.columns);

        if ($scope.vm.filterCriteria && $scope.vm.filterCriteria.query) {
            filtersToAdd = $scope.vm.filterCriteria.query.filter(filter => {
                return !filter.CriteriaValue;
            });

            criteriaToAdd = $scope.vm.filterCriteria.query.filter(filter => {
                return filter.CriteriaValue;
            });
        }

        if ($scope.sortsAndFilters) {
            if ($scope.sortsAndFilters.DateFilters) {
                reportDefinition.DateFilters = $scope.sortsAndFilters.DateFilters.slice();
            }
            if ($scope.dateCriteria && $scope.sortsAndFilters.Filters.some(filter => filter.FieldName === $scope.dateCriteria[0].FieldName)) {
                const missingDateRangeFilters = $scope.sortsAndFilters.Filters.filter(filter => filter.FieldName === $scope.dateCriteria[0].FieldName);
                filtersToAdd = filtersToAdd.concat(missingDateRangeFilters);
            }
        }

        reportDefinition.Filters = filtersToAdd;
        reportDefinition.Criteria = SavedReportSelectorService.cleanReportCriteria(reportDetailsModel, criteriaToAdd);
        return reportDefinition;
    };

    $scope.createExport = function (exportType, criteriaAndColumnsOnly) {
        if ($rootScope.tabs.length > 0) {
            let currentTab = $rootScope.tabs.find(tab => tab.id === $rootScope.currentSelectedTab.id);
            exportReport(currentTab.reportExportDetails, exportType, criteriaAndColumnsOnly);
        } else {
            exportReport($scope, exportType, criteriaAndColumnsOnly);
        }
    };

    function exportReport(reportInfo, exportType, criteriaAndColumnsOnly) {

        var exportName = reportInfo.reportName;

        applicationInsightsService.trackEvent('Export', { Name: reportInfo.reportName, ObjectId: reportInfo.ObjectId, Type: exportType });

        var reportDefinition = null;

        if (criteriaAndColumnsOnly) {
            reportDefinition = $scope.bundleReportDefinitionCriteriaAndColumnsOnly(reportInfo.grid.getOptions(), reportInfo.reportDetailsModel);
        } else {
            reportDefinition = $scope.bundleReportDefinition(reportInfo.grid.getOptions(), reportInfo.reportDetailsModel);
        }

        var postData = {
            "Name": exportName,
            "Format": exportType,
            "PreferredTimeZone": $rootScope.applicationTimeZone,
            "ReportDefinition": {
                "ReportId": reportInfo.reportId,
                "Columns": reportDefinition.Columns,
                "Sortings": reportDefinition.Sortings,
                "Criteria": reportInfo.sortsAndFilters != null && reportInfo.sortsAndFilters.Criteria != null ? reportDefinition.Criteria.concat(reportInfo.sortsAndFilters.Criteria) : reportDefinition.Criteria,
                "Filters": reportDefinition.Filters,
                "DateFilters": reportDefinition.DateFilters
            }
        };

        $http.post(apiUrl + 'api/core/exports', postData).then(function (response) {

            var fullExportName;
            if (exportType === 'Excel') {
                fullExportName = exportName + '.xlsx';
            } else if (exportType === 'PDF') {
                fullExportName = exportName + '.pdf';
            } else if (exportType === 'Csv') {
                fullExportName = exportName + '.csv';
            }

            response.data = { ExportId: response.data, ExportName: fullExportName };

        }, function (response) {
            helperService.showErrorMessage(response.data);
        });
    }

    $scope.exportToExcel = function (refreshModel) {
        if ($scope.populateCriteriaModel) $scope.populateCriteriaModel();
        if (refreshModel) {
            $scope.grid.dataSource.data([]);
            $scope.createExport('Excel', true);
        } else {
            var serverLength = $scope.grid.dataSource._total;
            if (serverLength > 999999) {
                helperService.showErrorMessage('An export must be under 1,000,000 rows');
            } else {
                $scope.createExport('Excel', false);
            }
        }
    };

    $scope.exportToPDF = function (refreshModel) {
        if ($scope.populateCriteriaModel) $scope.populateCriteriaModel();
        if (refreshModel) {
            $scope.grid.dataSource.data([]);
            $scope.createExport('PDF', true);
        } else {
            $scope.createExport('PDF', false);
        }
    };

    $scope.exportToCSV = function (refreshModel) {
        if ($scope.populateCriteriaModel) $scope.populateCriteriaModel();
        if (refreshModel) {
            $scope.grid.dataSource.data([]);
            $scope.createExport('Csv', true);
        } else {
            $scope.createExport('Csv', false);
        }
    };

    $scope.exportToCSVForUpdate = function (refreshModel) {
        if ($scope.populateCriteriaModel) $scope.populateCriteriaModel();
        if (refreshModel) {
            $scope.grid.dataSource.data([]);
            $scope.createExport('CsvUpdate', true);
        } else {
            $scope.createExport('CsvUpdate', false);
        }
    };

    $scope.newReport = function () {
        $scope.showOverwrite = false;
    };

    $scope.launchSavedReportFromScheduleReport = function () {
        $scope.showScheduleReport = false;
    };

    $scope.changeView = function (moduleName, viewName, type) {
        helperService.changeView(moduleName, viewName, type);
    };

}

function SaveReportModalController($scope, $uibModalInstance, applicationInsightsService, $http, helperService) {

    //Save Report
    $scope.saveReport = function (name, description) {

        $scope.pleaseWait = new createSpinner();

        applicationInsightsService.trackEvent('SaveReport', { Name: $scope.reportName, ObjectId: $scope.ObjectId });

        var reportDefinition = $scope.bundleReportDefinition($scope.grid.getOptions(), $scope.reportDetailsModel),
            postData = {
                "Description": description,
                "Name": name,
                "ReportDefinition": {
                    "ReportId": $scope.reportId,
                    "Columns": reportDefinition.Columns,
                    "Sortings": reportDefinition.Sortings,
                    "Criteria": $scope.sortsAndFilters != null && $scope.sortsAndFilters.Criteria != null ? reportDefinition.Criteria.concat($scope.sortsAndFilters.Criteria) : reportDefinition.Criteria,
                    "Filters": reportDefinition.Filters,
                    "DateFilters": reportDefinition.DateFilters
                }
            };

        $http.post(apiUrl + 'api/core/savedreports', postData).then(async function (response) {
            $scope.killModal = true;
            await helperService.successfulSaveButton($scope.pleaseWait).then(function (result) {
                $uibModalInstance.close();
            });

        }, function (response) {
            helperService.showErrorMessage(response.data);
            $scope.pleaseWait.loadingValue = false;
        });

    };

    $scope.overwriteReport = function (selectedReport) {

        $scope.pleaseWait = new createSpinner();

        var reportDefinition = $scope.bundleReportDefinition($scope.grid.getOptions(), $scope.reportDetailsModel),
            postData = {
                "Description": selectedReport.Description,
                "Name": selectedReport.Name,
                "ReportDefinition": {
                    "ReportId": $scope.ReportId,
                    "Columns": reportDefinition.Columns,
                    "Sortings": reportDefinition.Sortings,
                    "Criteria": $scope.sortsAndFilters != null && $scope.sortsAndFilters.Criteria != null ? reportDefinition.Criteria.concat($scope.sortsAndFilters.Criteria) : reportDefinition.Criteria,
                    "Filters": reportDefinition.Filters,
                    "DateFilters": reportDefinition.DateFilters
                }
            };

        postData.Id = selectedReport.Id;
        $http.put(apiUrl + 'api/core/savedreports/' + selectedReport.Id, postData).then(async function (response) {
            $scope.killModal = true;
            await helperService.successfulSaveButton($scope.pleaseWait).then(function (result) {
                $uibModalInstance.close();
            });

        }, function (response) {
            helperService.showErrorMessage(response.data);
            $scope.pleaseWait.loadingValue = false;
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };
}

function scheduleReportModalToolbarController($scope, $uibModalInstance, $uibModal, $rootScope, dataService, $http, helperService) {

    //Schedule Report
    $scope.scheduleReport = function (scheduleReportId) {
        $uibModalInstance.close();

        if (scheduleReportId) {
            $rootScope.savedReportToSchedule = scheduleReportId;
            $uibModal.open({
                templateUrl: '/App/Shared/Controllers/ReportSchedulerModal.html',
                controller: ReportSchedulerModalController,
                backdrop: 'static',
                size: 'md'
            });
        }
    };

    $scope.saveReportToSchedule = function (name, description) {
        var reportDefinition = $scope.bundleReportDefinition($scope.grid.getOptions(), $scope.reportDetailsModel),
            postData = {
                "Description": description,
                "Name": name,
                "ReportDefinition": {
                    "ReportId": $scope.reportId,
                    "Columns": reportDefinition.Columns,
                    "Sortings": reportDefinition.Sortings,
                    "Criteria": reportDefinition.Criteria = $scope.sortsAndFilters != null && $scope.sortsAndFilters.Criteria != null ? reportDefinition.Criteria.concat($scope.sortsAndFilters.Criteria) : reportDefinition.Criteria,
                    "Filters": reportDefinition.Filters,
                    "DateFilters": reportDefinition.DateFilters
                }
            };
        $http.post(apiUrl + 'api/core/savedreports', postData).then(function (response) {

            $uibModalInstance.close();

            dataService.getSavedReportsByID(response.data).then(function (response) {
                $rootScope.savedReportToSchedule = response.data.Id;
                $uibModal.open({
                    templateUrl: '/App/Shared/Controllers/ReportSchedulerModal.html',
                    controller: ReportSchedulerModalController,
                    backdrop: 'static',
                    size: 'md'
                });
            });

        }, function (response) {
            helperService.showErrorMessage(response.data);
        });

    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };
}

function SaveFilterModalController($scope, $uibModalInstance, applicationInsightsService, $http, helperService) {
    $scope.form = {};
    $scope.form.filterType = "0";

    $scope.saveFilter = function () {
        if ($scope.userForm.$valid) {
            $scope.pleaseWait = new createSpinner();
            let FilterList = helperService.halPrepareFilterArray($scope.grid.getOptions().dataSource.filter);
            let filterSaveObject = {
                Name: $scope.user.name,
                Description: !$scope.user.description ? null : $scope.user.description,
                Filters: FilterList,
                Type: $scope.form.filterType,
                ReportId: $scope.ObjectId
            };

            applicationInsightsService.trackEvent('SaveFilter', { Name: $scope.reportName, ObjectId: $scope.ObjectId });
            

            $http.post(apiUrl + 'api/core/userreportfilters', filterSaveObject)
                .then(async function (response) {
                    $scope.killModal = true;
                    await helperService.successfulSaveButton($scope.pleaseWait).then(function (result) {
                        $uibModalInstance.close();
                        $scope.filtersDataSource.read();
                    });
                }, function (response) {
                    $scope.pleaseWait.loadingValue = false;
                    helperService.showErrorMessage(response.data);
                });
        }
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };
}

function FilterManagerModalController($scope, $uibModalInstance) {
    $scope.cancel = function () {
        $uibModalInstance.close();
    };
}

function manageSavedLayoutsModalController($scope, $uibModalInstance, $http, helperService) {

    $scope.cancel = function () {
        $uibModalInstance.close();
    }; // end done

    $scope.manageSavedLayoutsDataSource = new kendo.data.DataSource({
        transport: {

            read: function (options) {
                $http.get(apiUrl + 'api/core/reportlayouts').then(function (response) {
                    options.success(response.data);
                });
            }
        }
    });

    var columnsForGridOptions = [
        { field: "LayoutName", title: "Report Name", filterable: false },
        { field: "ProductID", title: "Product", template: '<span>{{dataItem.ProductID | convertProductIdToProductName}}</span>', filterable: false },
        { template: `<a ng-click\="deleteLayout(#=ID#)\"><i class="glyphicon glyphicon-trash text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 90 }
    ];

    $scope.manageSavedLayoutsGridOptions = helperService.setNonPageableGridOptions(columnsForGridOptions);

    $scope.deleteLayout = function (id) {
        var rows = $scope.manageSavedLayoutsDataSource.data();

        rows.forEach(function (row) {
            if (row.ID.toString() === id.toString()) {

                var url = apiUrl + 'api/core/reportlayouts/' + row.ID;
                $http.delete(url).
                    then(function (response) {
                        rows.remove(row);
                    }, function (response) {
                        helperService.showErrorMessage(response.data);
                    });
            }
        });
    };


}