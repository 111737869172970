import roleContentReviewModalController from "./roleContentReviewModal.controller";

export default /*@ngInject*/ function ($uibModal) {

    const vm = this;

    vm.$onInit, vm.$onChanges = activate;

    vm.openReviewModal = openReviewModal;


    function activate() {
        if (!vm.securityRole.items) { return; }
        vm.reviewItemCategories = vm.securityRole.items.reduce((categories, item) => {
            let existingIndex = categories.findIndex(category => category.Name === item.RoleContentType);
            if (existingIndex === -1) {
                categories.push({ Name: item.RoleContentType, Items: [item] });
            } else {
                categories[existingIndex].Items.push(item);
            }
            return categories;
        }, []);
        getCounts(vm.reviewItemCategories);
    }

    function getCounts(array) {
        array.forEach(item => {            
            item.ApprovedCount = item.Items.filter(i => i.StatusId === 1).length;
            item.RejectedCount = item.Items.filter(i => i.StatusId === 2).length;
            item.NotReviewedCount = item.Items.filter(i => !i.StatusId).length;
            item.ReviewedByOthersCount = item.Items.filter(i => i.MultiApprovalStatus === 'Complete').length;
        });
    }

    async function openReviewModal(category) {
        let reviewModal = $uibModal.open({
            templateUrl: '/App/Components/Certifications/MyReviews/SecurityRoleContentItemsList/roleContentReviewModal.tmpl.html',
            controller: roleContentReviewModalController,
            controllerAs: 'rcrmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                category: () => category,
                configValues: () => vm.configValues,
                reviewStatus: () => vm.reviewStatus,
                requireNotes: () => vm.requireNotes
            }
        });

        try {
            await reviewModal.result;
            vm.updateReviewCounts();
        } catch {
            return;
        }
    }
}