export default /*@ngInject*/ function ($filter, $uibModalInstance, roleTemplatesData, errorsDataService, productId, requestAction, objectTypeName) {

    var vm = this;
    vm.cancel = cancel;
    vm.close = close;
    vm.productId = productId;
    vm.convertRoles = convertRoles;
    vm.requestAction = requestAction;
    vm.objectTypeName = objectTypeName;

    function activate() {
        getRoleTemplates();
    }

    activate();

    function getRoleTemplates() {
        vm.loading = true;
        roleTemplatesData.getRoleTemplates()
            .then(response => {
                response.data = $filter('orderBy')(response.data, 'Name');
                vm.roleTemplates = response.data;
                vm.loading = false;
            })
            .catch(error => {
                errorsDataService.catch(error);
                vm.loading = false;
            });
    }

    function convertRoles() {
        return { "selectedRoleTemplates": vm.selectedRoleTemplates, "keepCurrent": vm.keepCurrentRoles };
    }

    function cancel() { $uibModalInstance.dismiss(); }

    function close(roles) { $uibModalInstance.close(roles); }
}