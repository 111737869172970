export default /*@ngInject*/ function ($scope) {

    if (!$scope.vm.ListOfStoredUPRoles) $scope.vm.ListOfStoredUPRoles = [];

    var RoleCompany = function (params) {
        this.RoleId = params.RoleId;
        this.CompanyId = params.CompanyId;
        this.RoleName = params.RoleName;
        this.CompanyName = params.CompanyName;
        this.AllCompanies = params.AllCompanies;
    };

    $scope.AllCompanies = false;
    $scope.validateNumberOfUPRoles = validateNumberOfUPRoles;

    function validateNumberOfUPRoles(productName) {
        if (!$scope.vm.numberOfUPRolesValid) {
            $scope.vm.numberOfUPRolesValid = [];
        }
        $scope.vm.numberOfUPRolesValid[productName] = null;
        if ($scope.vm.ListOfSelectedUPRoles[productName].length > 0) {
            $scope.vm.numberOfUPRolesValid[productName] = true;
        }
    }

    $scope.addRoleToList = function (role, productName) {
        $scope.vm.ListOfAvailableUPRoles[productName].forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfAvailableUPRoles[productName].splice(i, 1);
                $scope.vm.ListOfSelectedUPRoles[productName].push(row);
            }
        });
        $scope.vm.IsRisksReviewed = null;
        validateNumberOfUPRoles(productName)
    };

    $scope.deleteSelectedRole = function (role, productName) {
        $scope.vm.ListOfSelectedUPRoles[productName].forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfSelectedUPRoles[productName].splice(i, 1);
                if (role.IsAssignable) {
                    $scope.vm.ListOfAvailableUPRoles[productName].push(row);
                }
            }
        });
        $scope.vm.IsRisksReviewed = null;
        validateNumberOfUPRoles(productName);
    };

    $scope.updateSelectedRoleCompanies = function (productName, selectedRole, prop, value) {
        selectedRole[prop] = value;
        if (selectedRole[prop].length === $scope.vm.ListOfUPCompanies[productName].length) {
            selectedRole[prop] = null;
        }
    };

    $scope.setSelectedRoleTemplates = function (selectedRoleTemplates) {
        $scope.vm.selectedRoleTemplates = selectedRoleTemplates;
    };
}