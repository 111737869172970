export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getFastpathUsers,
        getUserCompanies,
        getUserCompanyMappings,
        getUserMappings,
        createCompanyMap,
        createProductMap,
        disableCompanyRestrictions,
        disableProductRestrictions
    };

    return service;

    // GET Calls
    async function getFastpathUsers() {
        try {
            return await $http.get(apiUrl + 'api/core/users');
        } catch(err) {
            showError(err);
        }
    }

    async function getUserCompanies() {
        try {
            return await $http.get(apiUrl + 'api/universal/companymaster');
        } catch(err) {
            showError(err);
            sendError(err);
        }
    }

    async function getUserCompanyMappings(userId) {
        try {
            return await $http.get(apiUrl + `api/universal/companymaster/usermaster/${userId}`);
        } catch(err) {
            showError(err);
            sendError(err);
        }
    }

    async function getUserMappings(userId) {
        try {
            return await $http.get(`${apiUrl}api/universal/usermapping/usermaster/${userId}`);
        } catch {
            showError(err);
            sendError(err);
        }
    }

    // POST calls
    async function createCompanyMap(mapObject) {
        try {
            return await $http.post(apiUrl + 'api/universal/fastpathusercompanymappings', mapObject);
        } catch(err) {
            showError(err);
            sendError(err);
        }
    }

    async function createProductMap(mapObject) {
        try {
            return await $http.post(apiUrl + 'api/universal/fastpathuserproductmappings', mapObject);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    // PUT calls
    async function disableCompanyRestrictions(userId) {
        try {
            return await $http.put(apiUrl + `api/universal/fastpathusercompanymappings/${userId}/disable`);
        } catch(err) {
            showError(err);
            sendError(err);
        }
    }

    async function disableProductRestrictions(userId) {
        try {
            return await $http.put(apiUrl + `api/universal/fastpathuserproductmappings/${userId}/disable`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(err) { errorsDataService.catch(err); }
    function sendError(err) { throw err; }

}