import angular from 'angular';
import certificationsAllReviewController from '../Components/Certifications/AllReviews/certificationsAllReview.controller';
import accessCertificationsFactoriesModule from './accessCertifications.factories.module';
import myReviewsController from '../Components/Certifications/MyReviews/myReviews.controller';
import reviewSchedules from '../Components/Certifications/reviewSchedules/reviewSchedules.component';
import securityRoleContentItemsList from '../Components/Certifications/MyReviews/SecurityRoleContentItemsList/securityRoleContentItemsList.component';
import linkTicketsDropdown from '../Components/Certifications/LinkTickets/linkTicketsDropdown.component';
import reviewScheduleInstancesList from '../Components/Certifications/reviewSchedules/reviewScheduleInstancesList/reviewScheduleInstancesList.component';
import scheduleInstanceAssignmentsList from '../Components/Certifications/reviewSchedules/scheduleInstanceAssignmentsList/instanceAssignmentsList.component';
import routingCriteriaInstancesList from '../Components/Certifications/reviewSchedules/routingCriteriaInstancesList/routingCriteriaInstancesList.component';
import notificationTeams from '../Components/Certifications/notificationTeams/notificationTeams.component';
import roleSecurityProfile from '../Shared/Components/roleSecurityProfile/roleSecurityProfile.component';

export default angular
    .module('app.accessCertifications', [accessCertificationsFactoriesModule])
    .controller("certificationsAllReviewController", certificationsAllReviewController)
    .controller("MyReviewsController", myReviewsController)
    .component('securityRoleContentItemsList', securityRoleContentItemsList)
    .component('linkTicketsDropdown', linkTicketsDropdown)
    .component('reviewSchedules', reviewSchedules)
    .component('reviewScheduleInstancesList', reviewScheduleInstancesList)
    .component('reviewScheduleInstanceAssignmentsList', scheduleInstanceAssignmentsList)
    .component('reviewScheduleRoutingCriteriaInstancesList', routingCriteriaInstancesList)
    .component('notificationTeams', notificationTeams)
    .component('roleSecurityProfile', roleSecurityProfile )
    .name;