export default /*@ngInject*/ function ($http, errorsDataService, dataService, authenticationService) {
    const service = {
        getOpenRequests,
        checkForCancelOrEditAllRequests,
        cancelRequest
    };

    return service;

    async function getOpenRequests() {
        try {
            return await $http.get(apiUrl + 'api/identitymanager/requests/open');
        } catch (err) {
            showError(err);
        }
    }

    async function checkForCancelOrEditAllRequests(cancelOrEditAllRequests) {
        const user = await authenticationService.getUser(),
            { data } = await dataService.getClaims(user.userId);

        return data.some(data => data.Value === cancelOrEditAllRequests);
    }

    async function cancelRequest(request, data) {
        try {
            return await $http.put(apiUrl + 'api/identitymanager/requests/' + request.Id + '/version/' + request.Version + '/cancel', data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}