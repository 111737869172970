/* securityRoleContentItemsList.component.js */

import securityRoleContentItemsListController from "./securityRoleContentItemsList.controller";

/**
 * @desc component for displaying and reviewing items for a Security Role Content Review Type
 * @example <security-role-content-items-list-view></<security-role-content-items-list-view>
 */

const securityRoleContentItemsList = {
    bindings: {
        securityRole: '<',
        configValues: '<',
        updateReviewCounts: '&',
        reviewStatus: '<',
        requireNotes: '<'
    },
    controller: securityRoleContentItemsListController,
    controllerAs: 'srcilvm',
    templateUrl: '/App/Components/Certifications/MyReviews/SecurityRoleContentItemsList/securityRoleContentItemsList.tmpl.html'
};

export default securityRoleContentItemsList;