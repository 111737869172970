import controller from "./items.controller";

/* items.component.js */

/**
 * @desc component for viewing and maintaining list of sap security designer role items
 * @example <sap-sd-simulations-role-items-list></sap-sd-simulations-role-items-list>
 */

const sapSdSimulationsRoleItemsList = {
    bindings: {
        simulation: '<',
        onUpdateSimulationsList: '&',
        selectedRole: '<',
        onItemAdded: '&'
    },
    controller: controller,
    controllerAs: 'ivm',
    templateUrl: '/App/Components/SAPSecurityDesigner/itemsList/items.tmpl.html'
};

export default sapSdSimulationsRoleItemsList;