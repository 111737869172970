import compositeRoleRolesListController from "./compositeRoleRolesList.controller";

/* compositeRoleRolesList.component.js */

/**
 * @desc component for viewing and maintaining list of sap security designer composite role roles
 * @example <sap-sd-simulation-composite-role-roles-list></sap-sd-simulation-composite-role-roles-list>
 */

const sapSdSimulationsCompositeRoleRolesList = {
    bindings: {
        simulation: '<',
        onUpdateSimulationsList: '&',
        selectedCompositeRole: '<',
    },
    controller: compositeRoleRolesListController,
    controllerAs: 'crrlvm',
    templateUrl: '/App/Components/SAPSecurityDesigner/compositeRoleRolesList/compositeRoleRolesList.tmpl.html'
};

export default sapSdSimulationsCompositeRoleRolesList;