import fieldValuesListController from "./fieldValuesList.controller";

/* fieldValuesList.component.js */

/**
 * @desc component for viewing and maintaining list of sap security designer simulation field values
 * @example <sap-sd-simulation-field-values-list></sap-sd-simulation-field-values-list>
 */

const sapSdSimulationFieldValuesList = {
    require: {
        simulationsCtrl: '^sapSdSimulations'
    },
    bindings: {
        simulation: '<',
        selectedRole: '<',
        selectedObject: '<',
        field: '<'
    },
    controller: fieldValuesListController,
    controllerAs: 'fvlvm',
    templateUrl: '/App/Components/SAPSecurityDesigner/simFieldValuesList/fieldValuesList.tmpl.html'
};

export default sapSdSimulationFieldValuesList;