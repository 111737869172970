import azureADUserLookupController from "./azureADUserLookup.controller";

/* azureADUserLookup.component.js */

/**
 * @desc component for displaying Azure AD User Lookup
 * @example <azure-ad-user-lookup></azure-ad-user-lookup>
 */

const azureAdUserLookup = {
    bindings: {
        userObject: '<',
        onUserUpdated: '&'
    },
    controller: azureADUserLookupController,
    controllerAs: 'aadulm',
    templateUrl: '/App/Components/RequestManagement/AzureADUserLookup/azureADUserLookup.tmpl.html'
};

export default azureAdUserLookup;