import swal from "sweetalert2";
import createSpinner from "../../../../Shared/Functions/createSpinner";
import scheduleModalController from "./scheduleModal/scheduleModal.controller";
import importSchedulesModalController from "./importSchedulesModal/importSchedulesModal.controller";
import scheduleInstanceModalController from "./scheduleInstanceModal/scheduleInstanceModal.controller";

export default /*@ngInject*/ function (reviewSchedulesDataService, reviewAssignmentsDataService, helperService, $uibModal, $filter, FileSaver) {
    const vm = this;

    vm.openScheduleModal = openScheduleModal;
    vm.openScheduleInstanceModal = openScheduleInstanceModal;
    vm.openImportReviewSchedulesModal = openImportReviewSchedulesModal;
    vm.deleteScheduleInstance = deleteScheduleInstance;
    vm.deleteInstanceSchedule = deleteInstanceSchedule;
    vm.selectedReviewScheduleChanged = selectedReviewScheduleChanged;
    vm.runSchedule = runSchedule;
    vm.exportReviews = exportReviews;

    vm.reviewScheduleMenuOptions = [
        {
            text: 'Clone',
            click: item => {
                vm.openScheduleInstanceModal(item.reviewSchedule, true);
            }
        },
        {
            text: 'Edit',
            click: item => {
                vm.openScheduleInstanceModal(item.reviewSchedule, false);
            }
        },
        {
            text: 'Full Review...',
            children: _ => {
                return [
                    {
                        text: 'Schedule',
                        click: item => {
                            vm.openScheduleModal(item.reviewSchedule, "Full");
                        },
                        displayed: item => {
                            return !item.reviewSchedule.FullSchedule;
                        }
                    },
                    {
                        text: 'Edit',
                        click: item => {
                            vm.openScheduleModal(item.reviewSchedule, "Full");
                        },
                        displayed: item => {
                            return item.reviewSchedule.FullSchedule;
                        }
                    },
                    {
                        text: 'Run Now',
                        click: item => {
                            vm.runSchedule(item.reviewSchedule.FullSchedule.Id);
                            swal("", `The ${item.reviewSchedule.Name} review has been initiated. Reviewers will be notified of their review items soon.`, 'success');
                        },
                        displayed: item => {
                            return item.reviewSchedule.FullSchedule;
                        }
                    },
                    {
                        text: '<span class="text-danger">Delete</span>',
                        click: item => {
                            vm.deleteInstanceSchedule(item.reviewSchedule.FullSchedule.Id);
                        },
                        displayed: item => {
                            return item.reviewSchedule.FullSchedule;
                        }
                    }
                ];
            }
        },
        {
            text: 'Rolling Review...',
            children: _ => {
                return [
                    {
                        text: 'Schedule',
                        click: item => {
                            vm.openScheduleModal(item.reviewSchedule, "Rolling");
                        },
                        displayed: item => {
                            return !item.reviewSchedule.RollingSchedule;
                        }
                    },
                    {
                        text: 'Edit',
                        click: item => {
                            vm.openScheduleModal(item.reviewSchedule, "Rolling");
                        },
                        displayed: item => {
                            return item.reviewSchedule.RollingSchedule;
                        }
                    },
                    {
                        text: 'Run Now',
                        click: item => {
                            vm.runSchedule(item.reviewSchedule.RollingSchedule.Id);
                            swal("", `The ${item.reviewSchedule.Name} review has been initiated. Reviewers will be notified of their review items soon.`, 'success');
                        },
                        displayed: item => {
                            return item.reviewSchedule.RollingSchedule;
                        }
                    },
                    {
                        text: '<span class="text-danger">Delete</span>',
                        click: item => {
                            vm.deleteInstanceSchedule(item.reviewSchedule.RollingSchedule.Id);
                        },
                        displayed: item => {
                            return item.reviewSchedule.RollingSchedule;
                        }
                    }
                ];
            },
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: item => {
                vm.deleteScheduleInstance(item.reviewSchedule);
            }
        }
    ]

    vm.$onInit = getScheduleInstances;

    async function getScheduleInstances() {
        vm.loadingReviewSchedules = true;
        let response = await reviewSchedulesDataService.getInstances();
        vm.reviewSchedules = response.data.reduce((schedulesArray, schedule) => {
            schedule.FullSchedule = schedule.Schedules.find(s => s.FrequencyType === 'Full');
            schedule.RollingSchedule = schedule.Schedules.find(s => s.FrequencyType === 'Rolling');
            return schedulesArray;
        }, []);
        vm.reviewSchedules = $filter('orderBy')(response.data, 'Name');
        vm.loadingReviewSchedules = false;
    }

    async function openScheduleModal(scheduleInstance, frequencyType) {
        let scheduleData,
            existingSchedule = frequencyType === 'Full' ? scheduleInstance.FullSchedule : scheduleInstance.RollingSchedule;
        if (existingSchedule) {
            scheduleData = {
                selectedSchedule: existingSchedule,
                scheduleType: 'Edit',
                selectedReviewInstance: scheduleInstance
            }
        } else {
            scheduleData = {
                selectedSchedule: null,
                scheduleType: 'Create',
                selectedReviewInstance: scheduleInstance,
                selectedFrequencyType: frequencyType
            };
        }
        let scheduleModal = $uibModal.open({
            templateUrl: '/App/Components/Certifications/reviewSchedules/reviewScheduleInstancesList/scheduleModal/scheduleModal.html',
            controller: scheduleModalController,
            controllerAs: 'smvm',
            backdrop: 'static',
            resolve: {
                scheduleData: () => scheduleData
            }
        });

        try {
            await scheduleModal.result;
            await getScheduleInstances();
        } catch (dismiss) { return; }
    }

    async function openScheduleInstanceModal(schedule, isClone) {
        let scheduleInstanceModal = $uibModal.open({
            templateUrl: '/App/Components/Certifications/reviewSchedules/reviewScheduleInstancesList/scheduleInstanceModal/scheduleInstanceModal.tmpl.html',
            controller: scheduleInstanceModalController,
            controllerAs: 'simvm',
            backdrop: 'static',
            resolve: {
                schedule: () => schedule || null,
                isClone: () => isClone
            }
        });

        try {
            let newInstanceId = await scheduleInstanceModal.result;
            await getScheduleInstances();
            if (newInstanceId) {
                sendNewScheduleInstanceToTop(newInstanceId);
                selectedReviewScheduleChanged(vm.reviewSchedules.find(schedule => schedule.Id === newInstanceId));
            }
        } catch (dismiss) { return; }
    }

    async function openImportReviewSchedulesModal() {
        let importReviewSchedulesModal = $uibModal.open({
            templateUrl: '/App/Components/Certifications/reviewSchedules/reviewScheduleInstancesList/importSchedulesModal/importSchedulesModal.tmpl.html',
            controller: importSchedulesModalController,
            controllerAs: 'ismvm',
            backdrop: 'static'
        });

        try {
            await importReviewSchedulesModal.result;
            getScheduleInstances();
        } catch {
            return;
        }
    }

    async function selectedReviewScheduleChanged(schedule) {
        vm.selectedScheduleInstance = schedule;
        vm.onSelectSchedule({ schedule });
    }

    function sendNewScheduleInstanceToTop(newInstanceId) {
        vm.selectedScheduleInstance = vm.reviewSchedules.find(schedule => schedule.Id === newInstanceId);
        vm.reviewSchedules = vm.reviewSchedules.filter(schedule => schedule.Id !== newInstanceId);
        vm.reviewSchedules.unshift(vm.selectedScheduleInstance);
    }

    async function deleteScheduleInstance(scheduleInstance) {
        await swal(helperService.areYouSureParams('Delete Review Schedule', `Are you sure you want to delete Review Schedule ${scheduleInstance.Name}?`, 'Delete'));
        await reviewSchedulesDataService.deleteInstance(scheduleInstance.Id);
        if (vm.selectedScheduleInstance && vm.selectedScheduleInstance.Id === scheduleInstance.Id) {
            selectedReviewScheduleChanged(null);
        }
        getScheduleInstances();
    }

    async function deleteInstanceSchedule(instanceScheduleId) {
        vm.processingReviewSchedules = true;
        try {
            await reviewSchedulesDataService.deleteInstanceSchedule(instanceScheduleId);
            vm.processingReviewSchedules = false;
        } catch {
            vm.processingReviewSchedules = false;
        }
        
        getScheduleInstances();
    }

    async function runSchedule(id) {
        vm.processingReviewSchedules = true;
        try {
            await reviewSchedulesDataService.runSchedule(id);
            vm.processingReviewSchedules = false;
        } catch {
            vm.processingReviewSchedules = false;
        }
    }

    async function exportReviews() {
        vm.exportUpdatePending = new createSpinner();

        let response = await reviewAssignmentsDataService.exportReviews();
        FileSaver.saveAs(response.data, 'ReviewSchedules.xlsx');
        vm.exportUpdatePending.loadingValue = false;
    }
}