export default /*@ngInject*/ function ($filter) {
    const vm = this,
        baseUrl = 'api/core/charts/';

    vm.createGenericTableChart = createGenericTableChart;
    vm.createGenericTile = createGenericTile;
    vm.createGenericDoughnutChart = createGenericDoughnutChart;
    vm.createGenericColumnChart = createGenericColumnChart;
    vm.createGenericStackedColumnChart = createGenericStackedColumnChart;
    vm.setPropertiesForChart = setPropertiesForChart;
    vm.setPropertiesForStackedChart = setPropertiesForStackedChart;
    vm.setTileChartValues = setTileChartValues;
    vm.createSavedReportStub = createSavedReportStub;

    function createGenericTableChart(item) {
        return {
            ObjectId: item.ObjectID,
            CardType: 'table',
            APIUrl: baseUrl + item.Id + '/data',
            DataSource: {
                reportObjectId: item.ObjectID,
                reportField: item.LabelColumn,
                data: []
            }
        };
    }

    function createGenericTile (item) {
        return {
            ObjectId: item.ObjectID,
            CardType: 'tile',
            APIUrl: baseUrl + item.Id + '/data',
            DataSource: {
                reportObjectId: item.ObjectID,
                reportField: item.LabelColumn,
                data: []
            }
        };
    }

    function createGenericDoughnutChart (item) {
        return {
            ObjectId: item.ObjectID,
            CardType: item.ChartType,
            APIUrl: baseUrl + item.Id + '/data',
            ToggleOnWhenFullScreen: ["showlegend"],
            DataSource: {
                reportObjectId: item.ObjectID,
                reportField: item.LabelColumn,
                chart: {
                    "showvalues": "0",
                    "showlabels": "0",
                    "showlegend": "1",
                    "doughnutRadius": "100",
                    "plottooltext": "$Label: $Value ($percentValue)",
                    "theme": "ocean"
                },
                data: []
            }
        };
    }

    function createGenericColumnChart (item) {
        return {
            ObjectId: item.ObjectID,
            CardType: item.ChartType,
            APIUrl: baseUrl + item.Id + '/data',
            ToggleOnWhenFullScreen: ["showvalues", "showlabels"],
            DataSource: {
                reportObjectId: item.ObjectID,
                reportField: item.LabelColumn,
                chart: {
                    "showvalues": "1",
                    "showlabels": "1",
                    "showlegend": "0",
                    "showborder": "0",
                    "theme": "ocean"
                },
                data: []
            }
        };
    }

    function createGenericStackedColumnChart (item) {
        return {
            ObjectId: item.ObjectID,
            CardType: item.ChartType,
            APIUrl: baseUrl + item.Id + '/stackeddata',
            DataSource: {
                reportObjectId: item.ObjectID,
                reportCategory: item.CategoryColumn,
                reportSeries: item.SeriesColumn,
                chart: {
                    "showsum": "0",
                    "theme": "ocean",
                    "showborder": "0",
                    "drawcrossline": "1"
                },
                categories: [],
                dataset: []
            }
        };
    }

    function setPropertiesForChart (data) {
        data.forEach(function (row) {
            switch (row.Label) {
                case 'Ignore':
                    row.color = "878787";
                    break;
                case 'High':
                    row.color = "EC7063";
                    break;
                case 'Medium':
                    row.color = "FFAD5D";
                    break;
                case 'Low':
                    row.color = "558DBF";
                    break;
                case 'Overdue':
                case 'Incomplete':
                    row.color = "E74C3C";
                    break;
                case 'Not Started':
                    row.color = "696969";
                    break;
                case 'In Progress':
                    row.color = "FF9934";
                    break;
                case 'Completed':
                    row.color = "008A00";
                    break;
            }
            row.link = "j-";
        });

        return data;
    }

    function setPropertiesForStackedChart (data) {
        data.DataSet.forEach(function (row) {
            switch (row.SeriesName) {
                case 'Overdue':
                case 'Incomplete':
                    row.color = "E74C3C";
                    break;
                case 'Not Started':
                    row.color = "696969";
                    break;
                case 'In Progress':
                    row.color = "FF9934";
                    break;
                case 'Completed':
                    row.color = "008A00";
                    break;
            }
            row.link = "j-";
        });
    }

    function setTileChartValues (chart, data) {
        var itemObject = $filter('filter')(data, { Label: "!Total" })[0];
        chart.ItemCount = itemObject.Value;
        chart.ItemLabel = itemObject.Label;
        chart.TotalCount = $filter('filter')(data, { Label: "Total" })[0].Value;
        chart.Percent = Math.round(chart.ItemCount / chart.TotalCount * 100, 2);

        return chart;
    }

    function createSavedReportStub(filters, objectId) {

        var filtersArray = [];

        filters.forEach(function (row) {
            var filterModel = {
                "FieldName": row.Label,
                "Operator": "Equals",
                "Value": row.Value
            };
            filtersArray.push(filterModel);
        });

        var loadedSavedReport = {
            "ReportDefinition": {
                "Columns": [],
                "Criteria": [],
                "Filters": filtersArray,
                "ObjectId": objectId,
                "Sortings": []
            }
        };
        return loadedSavedReport;
    }
}