import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, rolesDataService) {

    const vm = this;

    vm.cancel = cancel;
    vm.createRole = createRole;


    function cancel() {
        $uibModalInstance.dismiss();
    }

    async function createRole() {
        vm.saving = new createSpinner();
        let data = {
            "Name": vm.newRoleName
        };

        try {
            let response = await rolesDataService.addRole(data);
            await helperService.successfulSaveButton(vm.saving);
            $uibModalInstance.close(response.data);
        } catch {
            vm.saving.loadingValue = false;
        }

    }

}