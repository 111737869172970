import createSpinner from "../../Shared/Functions/createSpinner";
import IMRequestJiraTicketModalController from "./WizardSteps/Universal/linkTickets/IMRequestJiraTicketModal.controller";
import IMRequestServiceNowTicketModalController from "./WizardSteps/Universal/linkTickets/ServiceNow/IMRequestServiceNowTicketModal.controller";
import IMRequestZendeskTicketModalController from "./WizardSteps/Universal/linkTickets/IMRequestZendeskTicketModal.controller";
import IMRequestCustomTicketModalController from "./WizardSteps/Universal/linkTickets/IMRequestCustomTicketModal.controller";
import { ax7_application_id, sap_application_id, netsuite_application_id, ax_application_id, oracle_application_id, oracleCloud_application_id, gp_application_id, nav_application_id, d365s_application_id, universalProduct_application_id, aad_application_id, ps_application_id, salesforce_application_id } from "../../Shared/GlobalVariables/applicationIdVariables";
import IMRequestFreshServiceTicketModalController from "./WizardSteps/Universal/linkTickets/IMRequestFreshServiceTicketModal.controller";

export default /*@ngInject*/ function ($scope, $uibModal, RequestManagementShowStepsService, RequestManagementStepDefinitions, RequestManagementWizardController, RequestManagementLoadDataService, RequestManagementBuildRequestService, WizardHandler, $rootScope, objectIdDataService, $http, $q, $location, helperService, controlLibraryDataService, FileSaver, userRolesDataService) {

    //Page initialization
    var vm = $scope.vm = {};
    $scope.vmh = helperService;
    $scope.helperService = helperService;
    $scope.updatePending = {};
    var locationPath = $location.path().split("/");
    vm.wizardMode = locationPath[4];
    vm.availableApplications = [];
    vm.existingFilesDataSource = [];
    var RiskLevelId = 0;
    var RiskLevelIdNewOnly = 0;
    var selectedExistingUser = null;
    $scope.vm.nsMaxDate = new Date(2900, 0, 1, 0, 0, 0);
    $scope.vm.nsMinDate = new Date();

    $scope.requireTickets = false;
    helperService.getConfigurationValue('IM_RequireTicketAssociation').then(function (data) {
        $scope.requireTickets = data === "1" ? true : false;
    });

    //Common Functions - duplicate functions on each pathon
    objectIdDataService.getObjectID().then(function (objectId) {
        $scope.ObjectId = objectId;

        var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;

    });

    activate();

    function activate() {
        securityCheck();
    }

    function securityCheck() {
        let security = JSON.parse(localStorage.getItem('UserSecurityObjects'));
        if (security.find(o => o.ObjectID === 11404)) return;
        else $location.path('/403');
    }

    async function calculateExpirationDate() {
        let numberOfDaysToAdd = Number(vm.config.find(configuration => configuration.Name === 'IM_DefaultExpirationDays').Value);

        if (numberOfDaysToAdd) {
            vm.ExpirationDate = new Date();
            vm.ExpirationDate.setDate(vm.ExpirationDate.getDate() + numberOfDaysToAdd);
        }
    }

    async function getAdditionalRequestDetails() {
        const response = await $http.get(`${apiUrl}api/identitymanager/requests/${$scope.requestToEdit.Id}/version/${$scope.requestToEdit.Version}`);
        vm.existingFilesDataSource = response.data.Attachments;
        $scope.vm.ExpirationDate = response.data.ExpirationDate ? new Date(response.data.ExpirationDate) : null;
    }

    $scope.getApplicationName = function (applicationId) {
        return $scope.vmh.getApplicationName(applicationId, vm.availableApplications);
    };

    $scope.getProductName = function (productId) {
        return $scope.vmh.getProductName(productId, vm.availableApplications);
    };
    //--End Common Page Functions


    $scope.resetUserValues = function () {
        $scope.requestUserList = [];
        $scope.vm.effectiveDateList = [];

        vm.RiskLevelId = 0;
        vm.RiskLevelIdNewOnly = 0;

        var selectedExistingUser = null;
        var editRequestExistingUser = {};

        //Universal Product Access variables
        $scope.vm.ListOfSelectedUPRoles = [];
        $scope.vm.ListOfAvailableUPRoles = [];
        $scope.vm.ListOfUPCompanies = [];

        //SAP Access variables
        vm.ListOfSelectedSAPSingleRoles = [];
        vm.ListOfAvailableSAPSingleRoles = [];
        vm.ListOfSelectedSAPCompositeRoles = [];
        vm.ListOfAvailableSAPCompositeRoles = [];

        //AAD Access variables
        vm.ListOfSelectedAADRoles = [];
        vm.ListOfAvailableAADRoles = [];
        vm.ListOfSelectedAADGroups = [];
        vm.ListOfAvailableAADGroups = [];
        vm.ListOfSelectedAADLicenses = [];
        vm.ListOfAvailableAADLicenses = [];

        //AX7 Access variables
        vm.ListOfSelectedAX7Roles = [];
        vm.ListOfExcludedAX7Roles = [];
        vm.ListOfAvailableAX7Roles = [];
        vm.ListOfAX7Companies = [];
        vm.AX7RoleCompanyList = [];

        //AX2012 Access variables
        vm.ListOfSelectedAX2012Roles = [];
        vm.ListOfExcludedAX2012Roles = [];
        vm.ListOfAvailableAX2012Roles = [];
        vm.ListOfAX2012Companies = [];
        vm.AX2012RoleCompanyList = [];
        vm.ListOfAX2012Partitions = [];

        //NetSuite Access varibles
        vm.ListOfSelectedNetSuiteRoles = [];
        vm.ListOfAvailableNetSuiteRoles = [];
        vm.ListOfSelectedNetSuitePermissions = [];
        vm.ListOfAvailableNetSuitePermissions = [];
        vm.existingGlobalPermissions = [];

        //Oracle Responsibility variables
        vm.ListOfAvailableOracleResponsibilities = [];
        vm.ListOfSelectedOracleResponsibilities = [];

        // Oracle Cloud roles variables
        vm.ListOfAvailableOracleCloudRoles = [];
        vm.ListOfSelectedOracleCloudRoles = [];
        vm.ListOfStoredOracleCloudRoles = [];

        // PeopleSoft roles variables
        vm.ListOfAvailablePSRoles = [];
        vm.ListOfSelectedPSRoles = [];

        //NAV Access varibles
        vm.ListOfSelectedNAVRoles = [];
        vm.ListOfAvailableNAVRoles = [];
        vm.ListOfAvailableNAVPermissionSets = [];
        vm.ListOfSelectedNAVPermissionSets = [];
        vm.ListOfSelectedNAVUserGroups = [];
        vm.ListOfAvailableNAVUserGroups = [];

        //GP Access varibles
        vm.ListOfSelectedGPRoles = [];
        vm.ListOfAvailableGPRoles = [];
        vm.ListOfStoredGPRoles = [];
        vm.ListOfSelectedGPCompanies = [];
        vm.ListOfAvailableGPCompanies = [];

        //D365S Access varibles
        vm.ListOfSelectedD365SRoles = [];
        vm.ListOfAvailableD365SRoles = [];
        vm.ListOfSelectedD365STeams = [];
        vm.ListOfAvailableD365STeams = [];

        //Salesforce Access Variables
        vm.ListOfAvailableSalesforcePermissionSets = [];
        vm.ListOfSelectedSalesforcePermissionSets = [];

        $scope.vm.User = [];
    };

    $scope.resetUserValues();


    //--Select Application Functions
    vm.appSelectedChange = function (application) {

        if (!application.selected && $scope.vm.UserChanges) {
            var index = $scope.vm.UserChanges.findIndex(change => change.ProductId === application.ProductID);
            if (index > -1) {
                $scope.vm.UserChanges.splice(index, 1);
            }
        }

        $scope.atLeastOneAppSelected();
        $scope.loadERPSpecificSteps(application);
    };

    $scope.loadERPSpecificSteps = function (application) {
        switch (application.ProductType) {
            case 'OR':
                RequestManagementShowStepsService.setOracleStepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'SAP':
                RequestManagementShowStepsService.setSAPStepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'AAD':
                RequestManagementShowStepsService.setAADStepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'NS':
                RequestManagementShowStepsService.setNetSuiteStepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'AX7':
                RequestManagementShowStepsService.setAX7StepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'AX':
                RequestManagementShowStepsService.setAX2012StepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'GP':
                RequestManagementShowStepsService.setGPStepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'NAV':
                RequestManagementShowStepsService.setNAVStepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'D365S':
                RequestManagementShowStepsService.setD365SStepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'PS':
                RequestManagementShowStepsService.setPSStepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'SF':
                RequestManagementShowStepsService.setSalesforceStepsToVisible(vm.availableApplications, $scope.steps);
                break;
            default:
                RequestManagementShowStepsService.setCustomStepsToVisible(vm.availableApplications, $scope.steps, application);
                break;
        }
    };

    $scope.atLeastOneAppSelected = function () {
        vm.validAppsSelected = RequestManagementWizardController.atLeastOneAppSelected(vm.availableApplications, vm.wizardMode);
    };

    $scope.vm.applicationTerminationEffectiveDate = function (application, value) {
        if (application.Id == universalProduct_application_id) {
            $scope.vm.User.UPUser[application.ProductName].StartDate = new Date(value);
        }
        if (application.Id == sap_application_id) {
            $scope.vm.User.SAPUser[application.ProductName].StartDate = new Date(value);
        }
        if (application.Id == netsuite_application_id) {
            $scope.vm.User.NetSuiteUser.StartDate = new Date(value);
        }
        if (application.Id == aad_application_id) {
            $scope.vm.User.AadUser.StartDate = new Date(value);
        }
        if (application.Id == ax7_application_id) {
            $scope.vm.User.AX7User.StartDate = new Date(value);
        }
        if (application.Id == ax_application_id) {
            $scope.vm.User.AX2012User.StartDate = new Date(value);
        }
        if (application.Id == oracle_application_id) {
            $scope.vm.User.OracleUser.StartDate = new Date(value);
        }
        if (application.Id == oracleCloud_application_id) {
            $scope.vm.User.OracleCloudUser.StartDate = new Date(value);
        }
        if (application.Id == gp_application_id) {
            $scope.vm.User.GPUser.StartDate = new Date(value);
        }
        if (application.Id == nav_application_id) {
            $scope.vm.User.NAVUser.StartDate = new Date(value);
        }
        if (application.Id == d365s_application_id) {
            $scope.vm.User.D365SUser.StartDate = new Date(value);
        }
        if (application.Id == ps_application_id) {
            $scope.vm.User.PSUser.StartDate = new Date(value);
        }
        if (application.Id === salesforce_application_id) {
            $scope.vm.User.SalesforceUser.StartDate = new Date(value);
        }
    };
    //--End Select Application events


    //Wizard Navigation
    $scope.steps = RequestManagementStepDefinitions.getListOfSteps(vm.wizardMode, vm.alternatePath, $rootScope.requestToEdit);

    $scope.stepDisabled = function (title) {
        return RequestManagementWizardController.stepDisabled(title, WizardHandler.wizard(), $scope);
    };

    $scope.setParentStepToActive = function (parent) {
        return RequestManagementWizardController.setParentStepToActive(parent, WizardHandler.wizard(), $scope.steps);
    };

    $scope.goToNextVisiblePage = function () {
        var step = RequestManagementWizardController.goToNextVisiblePage($scope.steps, WizardHandler.wizard());
        $scope.setActivePage(step.title);
    };

    $scope.goToPreviousVisiblePage = function () {
        var step = RequestManagementWizardController.goToPreviousVisiblePage($scope.steps, WizardHandler.wizard());
        $scope.setActivePage(step.title);
    };

    $scope.setCurrentStepToActive = function () {
        return RequestManagementWizardController.getCurrentStepsTitle(WizardHandler.wizard());
    };

    $scope.setActivePage = function (stepTitle) {
        var wizardInstance = WizardHandler.wizard();
        var currentStepNumber = wizardInstance.currentStepNumber();
        var enabledSteps = wizardInstance.getEnabledSteps();
        var step = enabledSteps[currentStepNumber - 1];
        if (step.canexit == true) {
            $scope.buildRequest(step.title, stepTitle);
        }
        $scope.helpText = null;
        wizardInstance.goTo(stepTitle);
    };
    //---End of Wizard Navigation

    // Attachments logic

    if ($scope.requestToEdit) {
        getAdditionalRequestDetails();
    }

    $scope.removeFile = async function (file) {
        vm.fileActionPending = true;

        try {
            await controlLibraryDataService.deleteAttachment(file.Identifier);

            const index = vm.existingFilesDataSource.findIndex(remainingFile => remainingFile.Identifier === file.Identifier);
            vm.existingFilesDataSource.splice(index, 1);
            $scope.fileActionPending = false;
        } catch {
            $scope.fileActionPending = false;
        }
    };

    $scope.downloadFile = async function (file) {
        vm.fileActionPending = true;

        try {
            const response = await controlLibraryDataService.downloadAttachment(file.Identifier);
            FileSaver.saveAs(response.data, file.FileName);
            $scope.fileActionPending = false;
        } catch {
            $scope.fileActionPending = false;
        }
    };

    $scope.uploadFiles = async function (files, requestId) {
        let fd = new FormData();

        files.forEach(function (file, i) {
            fd.append(files[i].file.name, files[i].file);
        });

        fd.append("DataType", "ImRequest");
        fd.append("DataTypeId", requestId);

        await controlLibraryDataService.addAttachments(fd);
    };

    // Link Ticket logic ========================

    if (!$scope.linkedTickets) $scope.linkedTickets = [];

    $scope.openIMRequestJiraTicketModal = function () {

        $uibModal.open({
            templateUrl: 'App/Components/RequestManagement/WizardSteps/Universal/linkTickets/IMRequestJiraTicketModal.html',
            controller: IMRequestJiraTicketModalController,
            backdrop: 'static',
            size: 'lg',
            scope: $scope,
            resolve: {
                linkedTicketsArray: () => $scope.linkedTickets
            }
        });
    };

    $scope.openIMRequestFreshServiceTicketModal = function () {

        $uibModal.open({
            templateUrl: 'App/Components/RequestManagement/WizardSteps/Universal/linkTickets/IMRequestFreshServiceTicketModal.html',
            controller: IMRequestFreshServiceTicketModalController,
            backdrop: 'static',
            size: 'lg',
            scope: $scope,
            resolve: {
                linkedTicketsArray: () => $scope.linkedTickets
            }
        });
    };

    $scope.openIMRequestServiceNowTicketModal = function () {

        $uibModal.open({
            templateUrl: 'App/Components/RequestManagement/WizardSteps/Universal/linkTickets/ServiceNow/IMRequestServiceNowTicketModal.html',
            controller: IMRequestServiceNowTicketModalController,
            backdrop: 'static',
            size: 'lg',
            scope: $scope,
            resolve: {
                linkedTicketsArray: () => $scope.linkedTickets
            }
        });
    };

    $scope.openIMRequestZendeskTicketModal = function () {

        $uibModal.open({
            templateUrl: 'App/Components/RequestManagement/WizardSteps/Universal/linkTickets/IMRequestZendeskTicketModal.html',
            controller: IMRequestZendeskTicketModalController,
            backdrop: 'static',
            size: 'lg',
            scope: $scope,
            resolve: {
                linkedTicketsArray: () => $scope.linkedTickets
            }
        });
    };

    $scope.openIMRequestCustomTicketModal = function () {

        $uibModal.open({
            templateUrl: 'App/Components/RequestManagement/WizardSteps/Universal/linkTickets/IMRequestCustomTicketModal.html',
            controller: IMRequestCustomTicketModalController,
            backdrop: 'static',
            size: 'lg',
            scope: $scope,
            resolve: {
                linkedTicketsArray: () => $scope.linkedTickets
            }
        });
    };

    $scope.removeIMRequestTicketLink = function (providerString) {
        $scope.linkedTickets = $scope.linkedTickets.filter(ticket => ticket.TicketProvider !== providerString);

        if (providerString === 'Jira') $scope.jiraTicketLinked = false;
        else if (providerString === 'ServiceNow') $scope.serviceNowTicketLinked = false;
        else if (providerString === 'Zendesk') $scope.zendeskTicketLinked = false;
        else if (providerString === 'Custom') $scope.customTicketLinked = false;
    };


    $scope.buildRequest = async function (fromStep, toStep) {

        $scope.$broadcast('broadcast-im-wizardstepchange', { fromStep: fromStep, toStep: toStep });

        if (toStep == "Select Applications" && fromStep == "Search Existing Users") {
            $scope.selectApplicationsHelpText = 'The following is a list of applications available to this user. Select the application(s) you wish to terminate and enter the corresponding effective date (if applicable)';

            //Load data for an existing user
            if ($rootScope.IMSelectedExistingUser != null && $rootScope.IMSelectedExistingUser.HasERPDataLoaded === false) {
                $scope.resetUserValues();

                $scope.showRMLoadingIcon = true;
                $scope.vm.wizardClass = "wizard-nav-container disabledDiv";

                var promises = [];
                RequestManagementLoadDataService.loadUserMasterERPData($scope, $rootScope.IMSelectedExistingUser, $scope.vm.availableApplications).then(function () {

                    vm.availableApplications.forEach(function (application) {
                        if (application.selected) {

                            if (application.Id == netsuite_application_id) {
                                var nsUser = null;
                                var subsidiaryId = null;

                                if ($scope.vm.User.NetSuiteUser != null) {
                                    nsUser = $scope.vm.User.NetSuiteUser;
                                    subsidiaryId = $scope.vm.User.NetSuiteUser.Subsidiary.Id;
                                }
                            }

                            //Reverse the selected so user must toggle on the tile instead of toggle it off
                            application.selected = !application.selected;
                        }
                    });

                    $q.all(promises).then(function () {
                        //Check if an application is selected and load ERP steps
                        $scope.atLeastOneAppSelected();
                        $scope.vm.availableApplications.forEach(application => $scope.loadERPSpecificSteps(application));
                        $rootScope.IMSelectedExistingUser.HasERPDataLoaded = true;
                        $scope.showRMLoadingIcon = false;
                        $scope.vm.wizardClass = "wizard-nav-container";
                    }, function errorCallback(response) {
                        $scope.showRMLoadingIcon = false;
                        $scope.vm.wizardClass = "wizard-nav-container";
                        helperService.showErrorMessage(response);
                    });
                }, function errorCallback(response) {
                    $scope.showRMLoadingIcon = false;
                    $scope.vm.wizardClass = "wizard-nav-container";
                    helperService.showErrorMessage(response);
                }
                );


            }
        }
        vm.builtRequest = RequestManagementBuildRequestService.buildRequest(vm, false);

        if (toStep == "Summary") {

            $scope.loadingIcon = true;
            $scope.minimumExpirationDate = new Date();
            if (await helperService.getConfigurationValue('IM_DefaultExpirationEnabled') === '1') {
                calculateExpirationDate();
            }

            function checkSelected(product) {
                if (Array.isArray(product)) {
                    Object.keys(product).forEach(key => {
                        const currentObject = product[key];
                        if ($scope.vm.availableApplications.find(app => app.ProductID === currentObject.ProductId)?.selected) {
                            currentObject.Action = 'Delete';
                            changeList.push(currentObject);
                        }
                    });
                } else {
                    if ($scope.vm.availableApplications.find(app => app.ProductID === product.ProductId)?.selected) {
                        product.Action = 'Delete';
                        changeList.push(product);
                    }
                }
            };

            var changeList = [];
            if ($scope.vm.User.NetSuiteUser != null) {
                checkSelected($scope.vm.User.NetSuiteUser);
            }
            if ($scope.vm.User.AadUser != null) {
                checkSelected($scope.vm.User.AadUser);
            }
            if ($scope.vm.User.AX7User != null) {
                checkSelected($scope.vm.User.AX7User);
            }
            if ($scope.vm.User.AX2012User != null) {
                checkSelected($scope.vm.User.AX2012User);
            }
            if ($scope.vm.User.UPUser != null) {
                checkSelected($scope.vm.User.UPUser);
            }
            if ($scope.vm.User.SAPUser != null) {
                checkSelected($scope.vm.User.SAPUser);
            }
            if ($scope.vm.User.OracleUser != null) {
                checkSelected($scope.vm.User.OracleUser);
            }
            if ($scope.vm.User.OracleCloudUser != null) {
                checkSelected($scope.vm.User.OracleCloudUser);
            }
            if ($scope.vm.User.GPUser != null) {
                checkSelected($scope.vm.User.GPUser);
            }
            if ($scope.vm.User.NAVUser != null) {
                checkSelected($scope.vm.User.NAVUser);
            }
            if ($scope.vm.User.D365SUser != null) {
                checkSelected($scope.vm.User.D365SUser);
            }
            if ($scope.vm.User.PSUser != null) {
                checkSelected($scope.vm.User.PSUser);
            }

            if ($scope.vm.User.SalesforceUser != null) {
                checkSelected($scope.vm.User.SalesforceUser);
            }

            vm.UserChanges = changeList;

            let terminatedProducts = vm.UserChanges.map(uc => uc.ProductId);

            let { data: userRoles } = await userRolesDataService.getRolesByUserMasterId($scope.vm.User.UserMaster.Id || $scope.vm.User.UserMaster.ID);
            userRoles = userRoles.filter(ur => terminatedProducts.filter(p => p == ur.ProductID).length);
            $scope.vm.currentAccess = userRoles.reduce((accessAccumulator, currentRole) => {
                const role = {
                    Product: $scope.getProductName(currentRole.ProductID),
                    AccessType: currentRole.TypeID,
                    Access: currentRole.Name
                };
                if (currentRole.Companies.length) {
                    $scope.vm.hasCompany = true;
                    let roleCompanies = [];
                    currentRole.Companies.forEach(company => {
                        roleCompanies.push({ ...role, Company: company.Name });
                    })
                    return accessAccumulator.concat(roleCompanies);
                }
                else {
                    accessAccumulator.push(role);
                    return accessAccumulator;
                }
            }, []);

            $scope.loadingIcon = false;
        }

    };

    $http.get(apiUrl + 'api/identitymanager/applications').then(function (response) {

        response.data.forEach(function (row, i) {
            row.selected = false;
        });

        $scope.vm.availableApplications = response.data;

        $scope.atLeastOneAppSelected();

        //If the user is modifying an open request load the data after the available applications have populated
        if ($rootScope.requestToEdit) {
            $scope.LoadOpenRequestData($scope);
        }
    });

    //-------------------------------
    $scope.LoadOpenRequestData = function () {
        if ($rootScope.requestToEdit) {
            $scope.requestToEdit = $rootScope.requestToEdit;

            vm.requestToEdit = $scope.requestToEdit;

            delete $rootScope.requestToEdit;
            $scope.reportDetailsComplete = false;

            //Get Request data based on request Id and version from the database
            $http.get(apiUrl + 'api/identitymanager/requests/edit/' + $scope.requestToEdit.Id + '/version/' + $scope.requestToEdit.Version).then(function (response) {

                var requestData = response.data;

                $scope.linkedTickets = response.data.Tickets;

                $scope.jiraTicketLinked = $scope.linkedTickets.some(ticket => ticket.TicketProvider === 'Jira');
                $scope.zendeskTicketLinked = $scope.linkedTickets.some(ticket => ticket.TicketProvider === 'Zendesk');
                $scope.customTicketLinked = $scope.linkedTickets.some(ticket => ticket.TicketProvider === 'Custom');

                $http.get(apiUrl + 'api/identitymanager/applications').then(function (response) {

                    var applications = response.data;

                    applications.forEach(function (row, i) {
                        row.selected = false;
                    });

                    //Hide any applications that the user doesn't have access to
                    var nsApp = applications.find(function (app) {
                        return app.Id == netsuite_application_id;
                    });
                    if (!userHasApplication(requestData.Applications, netsuite_application_id) && nsApp != null) {
                        nsApp.Visible = false;
                    }

                    //SAP application setup
                    var sapApp = applications.find(function (app) {
                        return app.Id == sap_application_id;
                    });
                    if (!userHasApplication(requestData.Applications, sap_application_id) && sapApp != null) {
                        sapApp.Visible = false;
                    }

                    //AAD application setup
                    var aadApp = applications.find(function (app) {
                        return app.Id == aad_application_id;
                    });
                    if (!userHasApplication(requestData.Applications, aad_application_id) && aadApp != null) {
                        aadApp.Visible = false;
                    }

                    //AX application setup
                    var axApp = applications.find(function (app) {
                        return app.Id == ax_application_id;
                    });
                    if (!userHasApplication(requestData.Applications, ax_application_id) && axApp != null) {
                        axApp.Visible = false;
                    }

                    //EBS application setup
                    var oracleApp = applications.find(function (app) {
                        return app.Id == oracle_application_id;
                    });
                    if (!userHasApplication(requestData.Applications, oracle_application_id) && oracleApp != null) {
                        oracleApp.Visible = false;
                    }

                    //Oracle Cloud application setup
                    var oracleCloudApp = applications.find(function (app) {
                        return app.Id == oracleCloud_application_id;
                    });
                    if (!userHasApplication(requestData.Applications, oracleCloud_application_id) && oracleCloudApp != null) {
                        oracleCloudApp.Visible = false;
                    }

                    //GP application setup
                    var gpApp = applications.find(function (app) {
                        return app.Id == gp_application_id;
                    });
                    if (!userHasApplication(requestData.Applications, gp_application_id) && gpApp != null) {
                        gpApp.Visible = false;
                    }

                    //NAV application setup
                    var navApp = applications.find(function (app) {
                        return app.Id == nav_application_id;
                    });
                    if (!userHasApplication(requestData.Applications, nav_application_id) && navApp != null) {
                        navApp.Visible = false;
                    }

                    //D365S application setup
                    var d365sApp = applications.find(function (app) {
                        return app.Id == d365s_application_id;
                    });
                    if (!userHasApplication(requestData.Applications, d365s_application_id) && d365sApp != null) {
                        d365sApp.Visible = false;
                    }

                    //PS application setup
                    var psApp = applications.find(function (app) {
                        return app.Id == ps_application_id;
                    });

                    if (!userHasApplication(requestData.Applications, ps_application_id) && psApp != null) {
                        psApp.Visible = false;
                    }

                    var salesforceApp = applications.find(app => app.Id == salesforce_application_id);

                    if (!userHasApplication(requestData.Applications, salesforce_application_id) && salesforceApp != null) {
                        salesforceApp.Visible = false;
                    }

                    $scope.vm.availableApplications = applications;


                    //Load End date and selected values for open request
                    var user = {};
                    $scope.vm.User = {};

                    $scope.vm.User.UserMaster = requestData.GeneralUser;

                    //NetSuite application setup
                    var nsApp = $scope.vm.availableApplications.find(function (app) {
                        return app.Id == netsuite_application_id;
                    });
                    if (requestData.NetSuiteUser != null) {
                        $scope.vm.User.NetSuiteUser = requestData.NetSuiteUser;
                        nsApp.selected = true;
                        nsApp.StartDate = new Date(requestData.NetSuiteUser.StartDate);
                    }

                    //Universal application setup
                    var uvApps = $scope.vm.availableApplications.filter(function (app) {
                        return app.Id == universalProduct_application_id;
                    });
                    if (requestData.UvUser != null) {
                        $scope.vm.User.UPUser.forEach((user, i) => {
                            user = requestData.UvUser[i];
                            uvApps.find(app => app.ProductId === user.ProductID)[selected = true][StartDate = new Date(requestData.UvUser[i].StartDate)];
                        });
                    }

                    //SAP application setup
                    var sapApps = $scope.vm.availableApplications.filter(app => app.Id == sap_application_id);

                    if (requestData.SapUser != null) {
                        $scope.vm.User.SAPUser = $scope.vm.User.SAPUser || {};

                        sapApps.forEach(sapApp => {
                            let selectedApp = requestData.SapUser.find(userApp => userApp.ProductId === sapApp.ProductID);

                            if (selectedApp) {
                                sapApp.selected = true;
                                sapApp.Visible = true;
                            } else {
                                sapApp.Visible = false;
                            }

                            sapApp.ProductId = sapApp.ProductID;
                            $scope.vm.User.SAPUser[sapApp.ProductName] = Object.merge(sapApp, selectedApp);
                        });
                    }

                    //AAD application setup
                    var aadApp = $scope.vm.availableApplications.find(function (app) {
                        return app.Id == aad_application_id;
                    });
                    if (requestData.AadUser != null) {
                        $scope.vm.User.AadUser = requestData.AadUser;
                        aadApp.selected = true;
                        aadApp.StartDate = new Date(requestData.AadUser.StartDate);
                    }

                    //AX7 application setup
                    var ax7App = $scope.vm.availableApplications.find(function (app) {
                        return app.Id == ax7_application_id;
                    });
                    if (requestData.Ax7User != null) {
                        $scope.vm.User.AX7User = requestData.Ax7User;
                        ax7App.selected = true;
                        ax7App.StartDate = new Date(requestData.Ax7User.StartDate);
                    }

                    //AX2012 application setup
                    var ax2012App = $scope.vm.availableApplications.find(function (app) {
                        return app.Id == ax_application_id;
                    });
                    if (requestData.AxUser != null) {
                        $scope.vm.User.AX2012User = requestData.AxUser;

                        ax2012App.selected = true;
                        ax2012App.StartDate = new Date(requestData.AxUser.StartDate);
                    }

                    //EBS application setup
                    var oracleApp = $scope.vm.availableApplications.find(function (app) {
                        return app.Id == oracle_application_id;
                    });
                    if (requestData.EbsUser != null) {
                        $scope.vm.User.OracleUser = requestData.EbsUser;
                        oracleApp.selected = true;
                        oracleApp.StartDate = new Date(requestData.EbsUser.StartDate);
                    }

                    //Oracle Cloud application setup
                    var oracleCloudApp = $scope.vm.availableApplications.find(function (app) {
                        return app.Id == oracleCloud_application_id;
                    });
                    if (requestData.OrfcUser != null) {
                        $scope.vm.User.OracleCloudUser = requestData.OrfcUser;
                        oracleApp.selected = true;
                        oracleCloudApp.StartDate = new Date(requestData.OrfcUser.StartDate);
                    }

                    //GP application setup
                    var gpApp = $scope.vm.availableApplications.find(function (app) {
                        return app.Id == gp_application_id;
                    });
                    if (requestData.GPUser != null) {
                        $scope.vm.User.GPUser = requestData.GPUser;

                        gpApp.selected = true;
                        gpApp.StartDate = new Date(requestData.GPUser.StartDate);
                    }

                    //NAV application setup
                    var navApp = $scope.vm.availableApplications.find(function (app) {
                        return app.Id == nav_application_id;
                    });
                    if (requestData.NavUser != null) {
                        $scope.vm.User.NAVUser = requestData.NavUser;
                        navApp.selected = true;
                        navApp.StartDate = new Date(requestData.NavUser.StartDate);
                    }

                    //D365S application setup
                    var d365sApp = $scope.vm.availableApplications.find(function (app) {
                        return app.Id == d365s_application_id;
                    });
                    if (requestData.D365SUser != null) {
                        $scope.vm.User.D365SUser = requestData.D365SUser;
                        d365sApp.selected = true;
                        d365sApp.StartDate = new Date(requestData.D365SUser.StartDate);
                    }

                    //PS application setup
                    var psApp = $scope.vm.availableApplications.find(function (app) {
                        return app.Id == ps_application_id;
                    });
                    if (requestData.PsUserRequest != null) {
                        $scope.vm.User.PSUser = requestData.PsUserRequest;
                        psApp.selected = true;
                        psApp.StartDate = new Date(requestData.PsUserRequest.StartDate);
                    }

                    var salesforceApp = $scope.vm.availableApplications.find(app => app.Id == salesforce_application_id);

                    if (requestData.SfUserRequest != null) {
                        $scope.vm.User.SalesforceUser = requestData.SfUserRequest;
                        salesforceApp.selected = true;
                        salesforceApp.StartDate = new Date(requestData.SfUserRequest.StartDate);
                    }

                    $scope.atLeastOneAppSelected();
                    $scope.reportDetailsComplete = true;
                    $scope.stopRequestManagementSpinner = true;
                    vm.validAppsSelected = true;
                });

            }, function (response) {
                $scope.reportDetailsComplete = true;
                helperService.showErrorMessage(response.data);
            });
        }
    };

    $scope.createNewRequest = function (request) {
        $scope.updatePending = new createSpinner();

        $http.post(apiUrl + 'api/identitymanager/requests', request).then(async function (response) {

            if (vm.attachments.flow.files.length > 0) {
                const requestId = response.data;
                $scope.uploadFiles(vm.attachments.flow.files, requestId);
            }
            helperService.showConfirmationMessage("Success", "Your request has been submitted for approval.");
            $location.path('/IdentityManager/Place/RequestManagement').search({});
        },
            function errorCallback(response) {
                $scope.updatePending.loadingValue = false;
                helperService.showErrorMessage(response.data);
            });
    };

    $scope.updateExistingRequest = function (request) {
        $scope.updatePending = new createSpinner();

        $http.put(apiUrl + 'api/identitymanager/requests/' + $scope.requestToEdit.Id + '/version/' + $scope.requestToEdit.Version, request).then(function (response) {

            if (vm.attachments.flow.files.length > 0) {
                $scope.uploadFiles(vm.attachments.flow.files, $scope.requestToEdit.Id);
            }

            helperService.showConfirmationMessage("Success", "Your request has been updated.");
            $location.path('/IdentityManager/Place/RequestManagement').search({});
        }, function errorCallback(response) {
            $scope.updatePending.loadingValue = false;
            helperService.showErrorMessage(response.data);
        });
    };

    $scope.finishedWizard = function () {

        $scope.updatePending = new createSpinner();
        vm.builtRequest = RequestManagementBuildRequestService.buildRequest(vm, true);
        vm.builtRequest.Tickets = $scope.linkedTickets;
        vm.builtRequest.ExpirationDate = $scope.vm.ExpirationDate ? new Date($scope.vm.ExpirationDate).toUTCString() : null;

        //Update request if there is currently an open request
        if ($scope.requestToEdit) {
            $scope.updateExistingRequest(vm.builtRequest);
        } else {
            $scope.createNewRequest(vm.builtRequest);
        }
    };

    var userHasApplication = function (applications, appId) {
        var hasApp = false;

        var app = applications.find(function (app) {
            return app.Value === appId || app._value === appId;
        });
        if (app != null) {
            hasApp = true;
        }

        return hasApp;
    };
}