import productConfigurationController from "./productConfiguration.controller";

/* productConfiguration.component.js */

/**
 * @desc component for configuring product specific settings for Identity Manager
 * @example <identity-manager-product-configuration></identity-manager-product-configuration>
 */

const identityManagerProductConfiguration = {
    bindings: {
        product: '<',
        configuration: '<',
        refreshCoreConfig: '&'
    },
    controller: productConfigurationController ,
    controllerAs: 'pcvm',
    templateUrl: '/App/Components/configuration/identityManagerConfigTab/productConfiguration/productConfiguration.tmpl.html'
};

export default identityManagerProductConfiguration;