import { customAutoCompleteFilter, standardGridPageSize, customDatePickerFilter } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";
import NSQuantificationViewDetailsModalController from "./NSQuantificationViewDetailsModalController";

export default /*@ngInject*/ function ($scope, $rootScope, KPIGeneratorService, ViewProductImportPageService, objectIdDataService, $http, helperService, $uibModal) {

    var vm = $scope.vm = {};
    var initialLoad = true;

    objectIdDataService.getObjectID().then(objectId => {
        $scope.ObjectId = objectId;

        var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;

    });

    $http.get(apiUrl + 'api/core/charts')
        .then(response => {
            $scope.charts = response.data;
            $scope.chartType = $scope.getChartType();
        });

    if (!$rootScope.NSQuantDetailViewOptions) {
        $rootScope.NSQuantDetailViewOptions = {
            viewBy: "byUser",
            riskType: "actual"
        };
    }

    $scope.NSQuantValues = {
        byUserActual: {
            chartId: "11329697-D17B-43D9-8260-F6761D05AA32",
            reportId: 16221,
            detailId: 16217
        },
        byUserPotential: {
            chartId: "84AC0CBC-C2AB-442F-98D6-ECCC871B1D08",
            reportId: 16222,
            detailId: 16215
        },
        byRoleActual: {
            chartId: "DDE440F5-0542-4566-8838-B244657C2957",
            reportId: 16219,
            detailId: 16218
        },
        byRolePotential: {
            chartId: "1B65B9C0-EE5F-4347-BF09-49D78746B7D3",
            reportId: 16220,
            detailId: 16216
        },
        byRiskActual: {
            chartId: "A7BA97A3-4011-4017-A802-FDBE432A57FC",
            reportId: 16221,
            detailId: 16217
        },
        byRiskPotential: {
            chartId: "B3A5EB46-1D18-4A2F-85AD-7CA43F20DC90",
            reportId: 16222,
            detailId: 16215
        }
    };

    // Helper functions

    $scope.getViewOptions = _ => {
        return $rootScope.NSQuantDetailViewOptions.viewBy + $scope.convertUpperCase($rootScope.NSQuantDetailViewOptions.riskType);
    };

    $scope.getChartId = _ => {
        return $scope.NSQuantValues[$scope.getViewOptions()].chartId;
    };

    $scope.getReportObjectId = _ => {
        return $scope.NSQuantValues[$scope.getViewOptions()].reportId;
    };

    $scope.getDetailObjectId = _ => {
        return $scope.NSQuantValues[$scope.getViewOptions()].detailId;
    };

    $scope.convertTitleCase = string => {
        return string.replace(/^[a-z]|[A-Z]/g, (v, i) => {
            return i === 0 ? v.toUpperCase() : " " + v.toUpperCase();
        });
    };

    $scope.convertUpperCase = string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    $scope.getChartType = _ => {
        var selectedChartId = $scope.getChartId().toLowerCase().toString();
        var selectedChart = $scope.charts.find(chart => chart.Id === selectedChartId);

        return selectedChart.ChartType;
    };

    // Chart

    $scope.getChart = _ => {
        $scope.chartLoading = true;
        $scope.selectedItem = null;
        $scope.showDetailsGrid = false;
        $scope.chartDataSource = {
            chart: {
                caption: $scope.convertUpperCase($rootScope.NSQuantDetailViewOptions.riskType + " " + $scope.convertTitleCase($rootScope.NSQuantDetailViewOptions.viewBy)),
                theme: "ocean",
                valueFontSize: "11"
            },
            data: []
        };

        $http({
            method: 'GET',
            url: apiUrl + 'api/core/charts/' + $scope.getChartId() + '/data'
        }).then(response => {
            $scope.chartDataSource.data = response.data;
            $scope.chartLoading = false;
        });
    };

    $scope.getChart();

    // Chart sub summary

    function highlightSelectedItem(ev, props) {
        var dataSource = ev.sender.args.dataSource.data;
        for (var i = 0; i < dataSource.length; i++) {
            if (i === props.index) {
                dataSource[i].color = "#4F7E98";
            } else {
                delete dataSource[i].color;
            }
        }
        ev.sender.args.dataSource.data = dataSource;
        ev.sender.setJSONData(ev.sender.args.dataSource);
    }

    $scope.fusionChartEvents = {
        dataPlotClick: (ev, props) => {
            var row = ev.sender.args.dataSource.data[props.index];
            $scope.selectItem(row, $rootScope.NSQuantDetailViewOptions.viewBy);
            highlightSelectedItem(ev, props);
        }
    };

    $scope.createPartialSavedReportAndNavigate = (filters, objectId) => {
        $rootScope.loadedSavedReport = KPIGeneratorService.createSavedReportStub(filters, objectId);
        ViewProductImportPageService.launchReportByObjectId(objectId);
    };

    $scope.selectItem = (row, type) => {
        $scope.selectedItem = row;
        $scope.showDetailsGrid = false;
        $scope.selectedChartLoading = true;
        var data = { Filters: [], Sorts: [], DateFilters: [] };
        var filter = {};
        $scope.filterFieldName = "RiskName";

        if ($rootScope.NSQuantDetailViewOptions.viewBy === "byRole") {
            filter = { FieldName: "RoleName", Operator: "Contains", Value: row.Label };
            data.Filters.push(filter);
        } else if ($rootScope.NSQuantDetailViewOptions.viewBy === "byUser") {
            filter = { FieldName: "UserName", Operator: "Contains", Value: row.Label };
            data.Filters.push(filter);
        } else if ($rootScope.NSQuantDetailViewOptions.viewBy === "byRisk") {
            $scope.filterFieldName = "UserName";
            filter = { FieldName: "RiskName", Operator: "Contains", Value: row.Label };
            data.Filters.push(filter);
        }

        $http.post(apiUrl + 'api/core/reports/execute/' + $scope.getReportObjectId(), data).then(response => {
            $scope.selectedItemData = response.data._embedded.reportData;
            $scope.selectedChartLoading = false;
        }).catch(response => {
            $scope.selectedChartLoading = false;
            helperService.showErrorMessage(response.data);
        });
    };

    $scope.getDetails = row => {
        $scope.showDetailsGrid = true;
        $scope.selectedRow = row;
        var viewOptions = $scope.getViewOptions();
        $scope.detailsGridColumns = [];

        function getColumns() {

            if (viewOptions === 'byUserActual' || viewOptions === 'byRiskActual') {
                $scope.detailsGridColumns = [
                    { field: "UserInternalID", title: "User Internal ID", filterable: customAutoCompleteFilter },
                    { field: "UserName", title: "User Name", filterable: customAutoCompleteFilter },
                    { field: "TransactionInternalID", title: "Transaction Internal ID", filterable: customAutoCompleteFilter },
                    { field: "TransactionRecordName", title: "Transaction", template: "<a href='{{dataItem.TransactionRecordUrl}}' target='_blank'>{{dataItem.TransactionRecordName}}</a>", filterable: customAutoCompleteFilter },
                    { field: "TransactionCurrency", title: "Transaction Currency", filterable: customAutoCompleteFilter },
                    { field: "TransactionValue", title: "Transaction Value", filterable: customAutoCompleteFilter },
                    { field: "LastTransactionModifiedDate", title: "Transaction Last Modified", template: "{{dataItem.LastTransactionModifiedDate | date:'short'}}", filterable: customDatePickerFilter },
                    { field: "MasterDataInternalID", title: "Master Data Internal ID", filterable: customAutoCompleteFilter },
                    { field: "MasterDataRecordName", title: "Master Data", template: "<a href='{{dataItem.MasterDataRecordUrl}}' target='_blank'>{{dataItem.MasterDataRecordName}}</a>", filterable: customAutoCompleteFilter },
                    { field: "RiskId", title: "Risk ID", filterable: customAutoCompleteFilter },
                    { field: "RiskName", title: "Risk Name", filterable: customAutoCompleteFilter },
                    { field: "RiskDescription", title: "Risk Description", filterable: customAutoCompleteFilter },
                    { field: "RiskLevel", title: "Risk Level", filterable: customAutoCompleteFilter }
                ];

            } else if (viewOptions === 'byUserPotential' || viewOptions === 'byRiskPotential') {
                $scope.detailsGridColumns = [
                    { field: "UserInternalID", title: "User Internal ID", filterable: customAutoCompleteFilter },
                    { field: "UserName", title: "User Name", filterable: customAutoCompleteFilter },
                    { field: "TransactionInternalID", title: "Transaction Internal ID", filterable: customAutoCompleteFilter },
                    { field: "TransactionRecordName", title: "Transaction", template: "<a href='{{dataItem.TransactionRecordUrl}}' target='_blank'>{{dataItem.TransactionRecordName}}</a>", filterable: customAutoCompleteFilter },
                    { field: "TransactionCurrency", title: "Transaction Currency", filterable: customAutoCompleteFilter },
                    { field: "TransactionValue", title: "Transaction Value", filterable: customAutoCompleteFilter },
                    { field: "LastTransactionModifiedDate", title: "Transaction Last Modified", template: "{{dataItem.LastTransactionModifiedDate | date:'short'}}", filterable: customDatePickerFilter },
                    { field: "RiskId", title: "Risk ID", filterable: customAutoCompleteFilter },
                    { field: "RiskName", title: "Risk Name", filterable: customAutoCompleteFilter },
                    { field: "RiskDescription", title: "Risk Description", filterable: customAutoCompleteFilter },
                    { field: "RiskLevel", title: "Risk Level", filterable: customAutoCompleteFilter }
                ];

            } else if (viewOptions === 'byRoleActual') {
                $scope.detailsGridColumns = [
                    { field: "RoleID", title: "Role Internal ID", filterable: customAutoCompleteFilter },
                    { field: "RoleName", title: "Role Name", filterable: customAutoCompleteFilter },
                    { field: "TransactionInternalID", title: "Transaction Internal ID", filterable: customAutoCompleteFilter },
                    { field: "TransactionRecordName", title: "Transaction", template: "<a href='{{dataItem.TransactionRecordUrl}}' target='_blank'>{{dataItem.TransactionRecordName}}</a>", filterable: customAutoCompleteFilter },
                    { field: "TransactionCurrency", title: "Transaction Currency", filterable: customAutoCompleteFilter },
                    { field: "TransactionValue", title: "Transaction Value", filterable: customAutoCompleteFilter },
                    { field: "LastTransactionModifiedDate", title: "Transaction Last Modified", template: "{{dataItem.LastTransactionModifiedDate | date:'short'}}", filterable: customDatePickerFilter },
                    { field: "MasterDataInternalID", title: "Master Data Internal ID", filterable: customAutoCompleteFilter },
                    { field: "MasterDataRecordName", title: "Master Data", template: "<a href='{{dataItem.MasterDataRecordUrl}}' target='_blank'>{{dataItem.MasterDataRecordName}}</a>", filterable: customAutoCompleteFilter },
                    { field: "RiskId", title: "Risk ID", filterable: customAutoCompleteFilter },
                    { field: "RiskName", title: "Risk Name", filterable: customAutoCompleteFilter },
                    { field: "RiskDescription", title: "Risk Description", filterable: customAutoCompleteFilter },
                    { field: "RiskLevel", title: "Risk Level", filterable: customAutoCompleteFilter }
                ];
            } else if (viewOptions === 'byRolePotential') {
                $scope.detailsGridColumns = [
                    { field: "RoleID", title: "Role Internal ID", filterable: customAutoCompleteFilter },
                    { field: "RoleName", title: "Role Name", filterable: customAutoCompleteFilter },
                    { field: "TransactionInternalID", title: "Transaction Internal ID", filterable: customAutoCompleteFilter },
                    { field: "TransactionRecordName", title: "Transaction", template: "<a href='{{dataItem.TransactionRecordUrl}}' target='_blank'>{{dataItem.TransactionRecordName}}</a>", filterable: customAutoCompleteFilter },
                    { field: "TransactionCurrency", title: "Transaction Currency", filterable: customAutoCompleteFilter },
                    { field: "TransactionValue", title: "Transaction Value", filterable: customAutoCompleteFilter },
                    { field: "Policy", title: "Policy", width: 90, filterable: customAutoCompleteFilter },
                    { field: "LastTransactionModifiedDate", title: "Transaction Last Modified", template: "{{dataItem.LastTransactionModifiedDate | date:'short'}}", filterable: customDatePickerFilter },
                    { field: "RiskId", title: "Risk ID", filterable: customAutoCompleteFilter },
                    { field: "RiskName", title: "Risk Name", filterable: customAutoCompleteFilter },
                    { field: "RiskDescription", title: "Risk Description", filterable: customAutoCompleteFilter },
                    { field: "RiskLevel", title: "Risk Level", filterable: customAutoCompleteFilter }
                ];
            }

            var detailsColumn = { field: "Details", title: "Details", template: "<a ng-click='viewDetails(dataItem)'>View Details</a>", filterable: customAutoCompleteFilter };
            $scope.detailsGridColumns.unshift(detailsColumn);

        }

        function setGridOptions() {
            $scope.detailsGridOptions = helperService.setMainGridOptions($scope.detailsGridColumns, 400);
            $scope.detailsGridOptions.selectable = false;
        }

        getColumns();
        setGridOptions();
        if (!initialLoad) {
            $scope.detailsGridDataSource.page(1);
        }
    };

    $scope.detailsGridDataSource = new kendo.data.DataSource({

        pageSize: standardGridPageSize,
        schema: angular.extend(halSchemaKendoGrid, {
            model: {
                fields: {
                    LastTransactionModifiedDate: { type: "date" }
                }
            }
        }),
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {

            read: options => {
                var selectedFilter = {};

                $scope.sortsAndFilters = helperService.prepareSortsAndFilters(options);
                $scope.sortsAndFilters.Filters.push({ FieldName: "RiskName", Operator: "Contains", Value: $scope.selectedRow.RiskName });

                if ($rootScope.NSQuantDetailViewOptions.viewBy === "byRole") {
                    selectedFilter = { FieldName: "RoleName", Operator: "Contains", Value: $scope.selectedRow.RoleName };
                    $scope.sortsAndFilters.Filters.push(selectedFilter);
                } else if ($rootScope.NSQuantDetailViewOptions.viewBy === "byUser" || $rootScope.NSQuantDetailViewOptions.viewBy === "byRisk") {
                    selectedFilter = { FieldName: "UserName", Operator: "Contains", Value: $scope.selectedRow.UserName };
                    $scope.sortsAndFilters.Filters.push(selectedFilter);
                }

                $http({
                    method: 'POST',
                    url: apiUrl + 'api/core/reports/execute/' + $scope.getDetailObjectId(),
                    data: $scope.sortsAndFilters,
                    params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                }).then(response => {
                    options.success(response.data);
                    $scope.enableGrid = true;
                }).catch(response => options.error());
            }
        },
        requestEnd: function() {
            initialLoad = false;
        }
    });

    $scope.viewDetails = dataItem => {
        $scope.report = angular.copy(dataItem);
        $uibModal.open({
            templateUrl: '/App/Components/NetSuiteArmQuantification/ViewDetailsModal.html',
            controller: NSQuantificationViewDetailsModalController,
            backdrop: 'static',
            size: 'lg',
            scope: $scope
        });
    };
}