import swal from "sweetalert2";
import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, controlLibraryDataService, activeControlPlugins) {

    const vm = this;
    vm.cancel = cancel;
    vm.importControlLibrary = importControlLibrary;
    vm.importControlsFromPluginAPI = importControlsFromPluginAPI;

    vm.disableButton = false;
    vm.auditBoardActive = activeControlPlugins.some(plugin => plugin === 'AuditBoard');
    vm.workivaActive = activeControlPlugins.some(plugin => plugin === 'Workiva');
    vm.importType = "InsertNewOnly";

    function cancel() { $uibModalInstance.close(); }

    function successCallback(response) {
        vm.disableButton = false;
        vm.cancel();
    }

    // Import control functions

   async function importControlLibrary(file) {
        vm.pendingImport = new createSpinner();
        vm.pendingImport.loadingValue = true;
        let fd = new FormData();
        fd.append(file[0].name, file[0].file);
        fd.append("ImportType", vm.importType);

        vm.disableButton = true;

       try {
           let response = await controlLibraryDataService.importControlLibrary(fd);
            successCallback(response);
            vm.pendingImport.loadingValue = false;
        } catch {
           vm.pendingImport.loadingValue = false;
           vm.disableButton = false;
        }
    }

    async function importControlsFromPluginAPI(plugin) {
        vm.disableButton = true;
        try {
            await swal(helperService.areYouSureParams(`Import via ${plugin} API`, `Are you sure you want to import controls via ${plugin} API?`, 'Import'));
            let response = await controlLibraryDataService.importControlsFromPluginAPI(plugin);
            successCallback(response);
        } catch {
            vm.disableButton = false;
        }
    }

}