export default /*@ngInject*/ function (risk, businessProcess, dataService, accessDetailsProfileDataService, $uibModalInstance) {

    const vm = this;

    vm.dismiss = dismiss;
    vm.risk = risk;
    vm.businessProcess = businessProcess;

    activate();

    async function activate() {
        if (risk) {
            vm.products = await getRiskDetailProducts();
        } else {
            vm.products = await getBpDetailProducts();
        }
    }

    async function getRiskDetailProducts() {
        let { data } = await accessDetailsProfileDataService.getRiskDetails(risk.RiskId);
        // Need to filter out AD and AAD as they aren't currently supported for risk detail
        return data.filter(product => product.Type !== 'AD');
    }

    async function getBpDetailProducts() {
        let { data } = await dataService.getProducts();
        let product = data.find(p => p.ID === businessProcess.ProductId);
        return product;
    }

    function dismiss() {
        $uibModalInstance.close();
    }
}