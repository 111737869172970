export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getUsers,
        getPermissionSets,
        getCountries,
        getUserLicenses,
        getProfiles,
        getRoles
    };

    return service;

    //GET Requests
    async function getUsers(search, field) {
        try {
            return await $http.get(`${apiUrl}api/identitymanager/sf/users?search=${search}&field=${field}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getPermissionSets() {
        try {
            return await $http.get(`${apiUrl}api/identitymanager/sf/permission-sets`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getCountries() {
        try {
            return await $http.get(`${apiUrl}api/identitymanager/sf/countries`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getUserLicenses(params) {
        try {
            return await $http.get(`${apiUrl}api/identitymanager/sf/profiles/user-licenses`, {params: params});
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getProfiles() {
        try {
            return await $http.get(`${apiUrl}api/identitymanager/sf/profiles`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getRoles() {
        try {
            return await $http.get(`${apiUrl}api/identitymanager/sf/profiles/user-roles`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}