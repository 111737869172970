import controller from "./plugin.controller";

/* plugin.component.js */

/**
 * @desc component for a plugin
 * @example <plugin></plugin>
 */

const plugin = {
    bindings: {
        plugin: '<',
        config: '<',
        origConfig: '<',
        radioSelected: '<',
        updatePending: '<'
    },
    controller: controller,
    controllerAs: 'pvm',
    templateUrl: '/App/Components/Plugins/plugin/plugin.tmpl.html'
};

export default plugin;