import currentSnapshotController from "./currentSnapshot.controller";


/* currentSnapshot.component.js */

/**
 * @desc component for Current Snapshot in NetSuite
 * @example <current-snapshot></current-snapshot>
 */

const currentSnapshot = {
    controller: currentSnapshotController,
    controllerAs: 'csvm',
    templateUrl: '/App/Components/CurrentSnapshot/currentSnapshot.tmpl.html'
};

export default currentSnapshot;