import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, $filter, ownershipGroup, helperService, ownershipGroupOwnerDataService, ownershipGroupItemsDataService) {
    const vm = this;

    vm.saveProductOwner = saveProductOwner;
    vm.selectedOwnerTypeChanged = selectedOwnerTypeChanged;
    vm.cancel = cancel;

    vm.ownershipGroup = { ...ownershipGroup };

    activate();

    async function activate() {
        await getProducts();
        await getOwners();

        vm.selectedOwnerType = 'User';
        selectedOwnerTypeChanged();
    }

    async function getProducts() {
        vm.productsDataSourceLoading = true;
        let { data } = await ownershipGroupItemsDataService.getAllItems(ownershipGroup);
        vm.productsDataSource = data;
        vm.productsDataSourceLoading = false;
    }

    async function getOwners() {
        vm.ownersDataSourceLoading = true;
        let { data } = await ownershipGroupOwnerDataService.getAvailableOwners(ownershipGroup.Id, ownershipGroup.GroupType);
        data = $filter('orderBy')(data, 'Name');
        vm.availableOwnersDataSource = data;
        vm.ownersDataSourceLoading = false;
    }

    async function saveProductOwner() {
        vm.savePending = new createSpinner();
        let newOwnerId;
        let productIds = vm.selectedProducts.map(product => product.ProductId);

        let postData = {
            GroupId: ownershipGroup.Id,
            Owners: [vm.selectedOwner],
            ProductIds: productIds
        };

        try {
            const { data } = await ownershipGroupOwnerDataService.addOwnerToGroup(ownershipGroup.Id, ownershipGroup.GroupType, postData);
            newOwnerId = data;
            await helperService.successfulSaveButton(vm.savePending);
            close(newOwnerId);
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function selectedOwnerTypeChanged() {
        vm.selectedOwners = null;
        vm.filteredOwnersDataSource = vm.availableOwnersDataSource.filter(owner => owner.TypeName === vm.selectedOwnerType);
    }

    function close(newOwnerId) {
        $uibModalInstance.close(newOwnerId);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}