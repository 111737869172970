import swal from "sweetalert2";
import simulationModalController from "./simulationModal.controller";

export default /*@ngInject*/ function ($scope, sapSdSimulationDataService, sapSdSimAnalysisDataService, helperService, $uibModal, $filter) {

    const vm = this;

    vm.radioFilter = radioFilter;
    vm.changeSelectedSimulation = changeSelectedSimulation;
    vm.analyzeSimulation = analyzeSimulation;
    vm.publishSimulation = publishSimulation;
    vm.deleteSimulation = deleteSimulation;
    vm.viewRiskAnalysis = viewRiskAnalysis;

    vm.menuOptions = [
        {
            text: 'Run Analysis',
            click: item => {
                vm.analyzeSimulation(item.simulation);
            },
            displayed: item => {
                return item.simulation.Status !== 'Analyzing' && item.simulation.Status !== 'Deploying' && item.simulation.Status !== 'Deployed' && item.simulation.Status !== 'ReadyToDeploy';
            }
        },
        {
            text: 'View Analysis',
            click: item => {
                vm.viewRiskAnalysis(item.simulation);
            },
            displayed: item => {
                return item.simulation.Status !== 'New' && item.simulation.Status !== 'Analyzing';
            }
        },
        {
            text: 'Publish',
            click: item => {
                vm.publishSimulation(item.simulation);
            },
            displayed: item => {
                return item.simulation.Status === 'ReadyToDeploy'
            }
        },
        {
            text: 'Edit',
            click: item => {
                vm.openSimulationModal(item.simulation);
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: item => {
                vm.deleteSimulation(item.simulation);
            },
            displayed: item => {
                return item.simulation.Status !== 'Deployed';
            }
        }
    ];

    vm.simulationsRadioFilter = 'Active';

    vm.openSimulationModal = openSimulationModal;

    vm.$onInit = activate;
    vm.$onChanges = onChanges;

    //Watch for risk analysis completion and update simulations list to clear any needs analysis labels
    $scope.$on('RiskAnalysis', (event, args) => {
        getSimulations();
    });

    //Watch for publish success and update simulations list to reflect proper deployed status
    $scope.$on('PublishSecurityDesignerChanges', async (event, args) => {
        await getSimulations();
        changeSelectedSimulation(null);
    });

    function activate() {
        getSimulations()
    }

    function onChanges(changes) {
        if (changes['updateSimulationsList'] && changes['updateSimulationsList'].currentValue === true) {
            getSimulations();
            vm.updateSimulationsList = false;
            const updateList = vm.updateSimulationsList;
            vm.onUpdateSimulationsList({ updateList });
        }
    }

    function radioFilter(simulation) {
        if (vm.simulationsRadioFilter === 'Active') {
            return simulation.Status !== 'Deployed';
        } else {
            return simulation.Status === 'Deployed';
        }
    }

    async function getSimulations(newSimulationId) {
        vm.simulationsLoading = true;
        let response = await sapSdSimulationDataService.getSimulations();
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.simulations = response.data;
        if (newSimulationId) {
            const newestSimIndex = vm.simulations.findIndex(simulation => simulation.Id === newSimulationId);
            const newestSim = vm.simulations.find(simulation => simulation.Id === newSimulationId);
            vm.simulations.splice(newestSimIndex, 1);
            vm.simulations.unshift(newestSim);
        }
        vm.simulationsLoading = false;
    }

    function changeSelectedSimulation(simulation) {
        vm.onSelectedSimulationChanged({ simulation });
    }

    async function openSimulationModal(simulation) {
        let simulationModal = $uibModal.open({
            templateUrl: '/App/Components/SAPSecurityDesigner/simulationsList/simulationModal.tmpl.html',
            controller: simulationModalController,
            controllerAs: 'smvm',
            backdrop: 'static',
            resolve: {
                simulation: () => simulation || null
            }
        });

        try {
            let newSimulationId = await simulationModal.result;
            await getSimulations(newSimulationId);
            if (newSimulationId) {
                // We know a new simulation was created so we need to select it
                changeSelectedSimulation(vm.simulations.find(simulation => simulation.Id === newSimulationId));
            }
        } catch { return; }
    }

    async function analyzeSimulation(simulation) {
        let data = {
            SimulationId: simulation.Id
        }
        try {
            await swal(helperService.areYouSureParams(`Run Analysis`, `Are you sure you want to run the Simulation Analysis for Simulation ${simulation.Name}?`, `Yes`, 'No'))
            await sapSdSimAnalysisDataService.analyzeSimulation(data);
            simulation.Status = 'Analyzing';
        } catch {
            return;
        }
    }

    async function publishSimulation(simulation) {
        let data = {
            SimulationId: simulation.Id,
        }
        try {
            await swal(helperService.areYouSureParams(`Publish`, `Are you sure you want to Publish Simulation ${simulation.Name}?`, `Yes`, 'No'))
            await sapSdSimulationDataService.publishSimulation(data);
        } catch {
            return;
        }
    }

    async function deleteSimulation(simulation) {
        vm.simulationsLoading = true;
        try {
            await swal(helperService.areYouSureParams(`Delete Simulation`, `Are you sure you want to delete Simulation ${simulation.Name}?`, `Yes`, 'No'))
            await sapSdSimulationDataService.deleteSimulation(simulation.Id);
            changeSelectedSimulation(null);
            getSimulations();
        } catch {
            vm.simulationsLoading = false;
            return;
        }
    }

    async function viewRiskAnalysis(simulation) {
        vm.onViewAnalysisClicked({ simulation });
    }
}