import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, maintainSystemRolesDataService, helperService) {

    const vm = this;

    vm.removeFile = removeFile;
    vm.importSystemRoles = importSystemRoles;
    vm.cancel = cancel;


    async function removeFile(file) {
        vm.fileActionPending = true;

        try {
            await controlLibraryDataService.deleteAttachment(file.Identifier);

            const index = vm.existingFilesDataSource.findIndex(remainingFile => remainingFile.Identifier === file.Identifier);
            vm.existingFilesDataSource.splice(index, 1);
            vm.fileActionPending = false;
        } catch {
            vm.fileActionPending = false;
        }
    }

    async function importSystemRoles(file) {
        let fd = new FormData();
        vm.saving = new createSpinner();

        fd.append(file[0].name, file[0].file)

        await maintainSystemRolesDataService.importSystemRoles(fd);
        await helperService.successfulSaveButton(vm.saving);
        $uibModalInstance.close();
    }

    function cancel() { $uibModalInstance.close(); }
}