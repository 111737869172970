import { customAutoCompleteFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import swal from "sweetalert2";

export default /*@ngInject*/ function (AuditTrailEntityDataService, $filter, $location, helperService) {

    const vm = this;

    vm.haveTemplatesChanged = haveTemplatesChanged;
    vm.viewDetails = viewDetails;
    vm.enableTrackingTemplates = enableTrackingTemplates;
    
    vm.helperService = helperService;

    vm.updatePending = false;

    vm.mainGridColumns = [
        { field: "IsEnabled", title: "Track", template: "<label class='switch'><input type='checkbox' ng-model='dataItem.IsEnabled'><i></i></label>", width: 55, filterable: false },
        { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
        { field: "IsCustom", template: "<span ng-if='dataItem.IsCustom' class='label label-custom-template'>Custom</span><span ng-if='!dataItem.IsCustom' class='label label-out-of-box-template'>Out of Box</span>", "title": "Template Type", filterable: false, width: 100 },
        { template: "<button type=\"button\" class=\"k-button\" ng-click=\"attvm.viewDetails(dataItem)\">Details</button>", "title": "Details", width: 100 }
    ];

    vm.mainGridOptions = helperService.setMainGridOptions(null, 600);
    vm.mainGridOptions.autoBind = true;
    vm.mainGridOptions.dataSource = vm.gridDataSource;
    vm.mainGridOptions.selectable = false;

    vm.gridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: async function (options) {
                try {
                    let response = await AuditTrailEntityDataService.getAllTemplates();
                    response.data = $filter('orderBy')(response.data, 'Name');
                    options.success(response.data);
                    vm.origTemplates = vm.gridDataSource._data.slice();
                    vm.origTemplates = vm.origTemplates.filter(template => template.IsEnabled === true);
                } catch {
                    options.error();
                }
            }
        }
    });

    function viewDetails(dataItem) {
        $location.path('/ChangeTracking/Setup/TrackingTemplateDetails').search({ TemplateId: dataItem.Id });
    };

    //Function for Apply button to check if Any of the Templates Track toggle was changed
    function haveTemplatesChanged() {
        if (vm.origTemplates) {
            const newTemplates = vm.gridDataSource._data.filter(template => template.IsEnabled === true);

            return JSON.stringify(vm.origTemplates) === JSON.stringify(newTemplates);
        }
    }

    async function enableTrackingTemplates() {
        try {
            await swal(helperService.areYouSureParams('Apply templates to ' + helperService.productNameLookUp('AX7'), 'Are you sure you want to proceed? This will apply the selected templates in to ' + helperService.productNameLookUp('AX7') + '.', 'Apply'));
            vm.updatePending = true;
            let rows = vm.gridDataSource.data();
            //Get all the enabled templates then add them to a list with just their Ids
            let selectedTemplateList = rows.filter(function (template) { return template.IsEnabled === true; });
            let selectedTemplateIds = [];

            selectedTemplateList.forEach(function (template) {
                selectedTemplateIds.push(template.Id);
            });

            let postData = {
                "TemplateIds": selectedTemplateIds,
                "ProductId": "AX7"
            };

            //Pass list of Ids to apply template API call
            await AuditTrailEntityDataService.applyTemplates(postData);
            vm.updatePending = false;
        } catch (error) {
            vm.updatePending = false;
        }
    }

}