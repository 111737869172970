import createSpinner from "../../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($scope, $rootScope, helperService, $http) {

    const vm = this;

    vm.scheduleTMImportStatusChanged = scheduleTMImportStatusChanged;
    vm.updateTMScheduleImport = updateTMScheduleImport;
    vm.updateProductScheduleStatus = updateProductScheduleStatus;

    activate();

    async function activate() {
        vm.frequencies = ['Daily', 'Weekly', 'Monthly'];
        vm.daysInWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        vm.daysInMonth = helperService.getDayOfMonthDropdownOptions();
        vm.startTimeForScheduleTMImport = new Date();

        try {
            await getTMImportSchedule();
        } catch {
            return;
        }
    }

    async function getTMImportSchedule() {
        try {
            let response = await $http.get(apiUrl + 'api/transactionmonitoring/or/import/schedule');
            vm.importSchedule = response.data;
            vm.selectedFreqForScheduleTMImport = response.data.Frequency;
            vm.selectedDayOfWeekForScheduleTMImport = response.data.DayOfWeek;
            vm.selectedDayOfMonthForScheduleTMImport = response.data.DayOfMonth ? { Value: response.data.DayOfMonth } : null;
            vm.startTimeForScheduleTMImport = new Date(response.data.NextExecutionTime);
            vm.ScheduleTMImportIsOn = true;
            $scope.productImportScheduled = true;
        } catch {
            vm.importSchedule = null;
            vm.ScheduleTMImportIsOn = false;
            $scope.productImportScheduled = false;
        }
    }

    function scheduleTMImportStatusChanged(product) {
        if (vm.ScheduleTMImportIsOn == false && vm.importSchedule) {
            updateTMScheduleImport(false, product);
        }
    }

    function updateTMScheduleImport(isOn, product) {
        updateTMSchedule(isOn, vm.selectedFreqForScheduleTMImport, vm.selectedDayOfWeekForScheduleTMImport, vm.selectedDayOfMonthForScheduleTMImport, vm.startTimeForScheduleTMImport, apiUrl + 'api/transactionmonitoring/or/import/schedule', apiUrl + 'api/transactionmonitoring/or/import/schedule/disable', vm.importSchedule);
        $scope.productImportScheduled = isOn;
    }

    function updateProductScheduleStatus() {
        $scope.productImportScheduled = false;
    }

    async function updateTMSchedule(scheduleIsOn, frequency, dayOfWeek, dayOfMonth, startTime, scheduleUrl, disableUrl, downloadedSchedule) {
        vm.updateInProgress = new createSpinner();

        if (scheduleIsOn) {

            var dayOfMonthRequest;
            var dayOfWeekRequest;

            if (frequency === 'Daily') {
                dayOfMonthRequest = [];
                dayOfWeekRequest = [];
            } if (frequency === 'Weekly') {
                dayOfMonthRequest = [];
                dayOfWeekRequest = [dayOfWeek];
            } if (frequency === 'Monthly') {
                dayOfMonthRequest = [dayOfMonth.Value];
                dayOfWeekRequest = [];
            }

            let adjustedStartTime = new Date();
            adjustedStartTime.setHours(startTime.getHours());
            adjustedStartTime.setMinutes(startTime.getMinutes());
            adjustedStartTime = new Date(adjustedStartTime.toUTCString());
            adjustedStartTime = adjustedStartTime.toISOString();

            const putData = {
                "Schedule": {
                    "DayOfMonth": dayOfMonthRequest[0],
                    "DayOfWeek": dayOfWeekRequest[0],
                    "Frequency": frequency,
                    "StartTime": adjustedStartTime,
                    "TimeZone": $rootScope.applicationTimeZone
                }
            };

            try {
                await $http.put(scheduleUrl, putData);
                getTMImportSchedule();
                helperService.successfulSaveButton(vm.updateInProgress);
                vm.updateInProgress.loadingValue = false;

            } catch (err) {
                helperService.showErrorMessage(err.data);
                vm.updateInProgress.loadingValue = false;
            }
        } if (scheduleIsOn == false) {
            if (downloadedSchedule) {
                try {
                    await $http.put(disableUrl);
                    getTMImportSchedule();
                    vm.updateInProgress.loadingValue = false;
                } catch (err) {
                    helperService.showErrorMessage(err.data);
                    vm.updateInProgress.loadingValue = false;
                }
            }
        }
    }
}
