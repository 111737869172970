import groupOwnersListController from "./groupOwnersList.controller";

/* groupOwnersList.component.js */

/**
 * @desc component for viewing and maintaining list of ownership group owners
 * @example <ownership-group-owners-list><ownership-group-owners-list>
 */

const groupOwnersList = {
    bindings: {
        selectedOwnershipGroup: '<',
        selectedGroupItemType: '<',
        onGroupItemTypeChange: '&',
        onSelectedOwnerChange: '&'
    },
    controller: groupOwnersListController,
    controllerAs: 'golvm',
    templateUrl: '/App/Components/OwnershipGroups/ownershipGroupOwnersList/groupOwnersList.tmpl.html'
};

export default groupOwnersList;