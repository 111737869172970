/* myDownloads.component.js */

import myDownloadsController from "./myDownloads.controller";

/**
 * @desc component to display My Downloads
 * @example <my-downloads></my-downloads>
 */

const myDownloads = {
    controller: myDownloadsController,
    controllerAs: 'mdvm',
    templateUrl: '/App/Components/myDownloads/myDownloads.tmpl.html'
};

export default myDownloads;