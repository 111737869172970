import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, $http, helperService, errorsDataService, request) {

    const vm = this;

    vm.submitRequestAction = submitRequestAction;
    vm.cancel = cancel;

    async function submitRequestAction() {

        vm.updatePending = new createSpinner();
        let accessItems = request.RequestAccesses.filter(access => access.Action && access.Action !== 'Delegated');
        let userItems = request.RequestUsers.filter(user => user.Action && user.Action !== 'Delegated');

        accessItems = accessItems.map(item => {
            let newItem = {
                RequestAccessId: item.Id,
                Approved: item.Action === 'Approve' ? true : false
            };
            return newItem;
        });

        userItems = userItems.map(item => {
            let newItem = {
                RequestUserId: item.Id,
                Approved: item.Action === 'Approve' ? true : false
            };
            return newItem;
        });

        let postData = {
            "RequestId": request.Id,
            "Version": request.Version,
            "Notes": vm.approveDeny.note,
            "AccessItems": accessItems,
            "UserItems": userItems
        };

        try {
            let response = await $http.post(apiUrl + 'api/identitymanager/requests/' + request.Id + '/version/' + request.Version + '/reviews', postData);
            await helperService.successfulSaveButton(vm.updatePending);
            $uibModalInstance.close(response.status);
        } catch (err) {
            vm.updatePending.loadingValue = false;
            errorsDataService.catch(err);
        }
    }

    function cancel() { $uibModalInstance.dismiss(); }
}