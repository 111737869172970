import { standardGridPageSize, customAutoCompleteFilter, customBooleanFilter } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";

export default /*@ngInject*/ function ($scope, $rootScope, $filter, SavedReportSelectorService, dataService, BuildRoleAccessPostBody, objectIdDataService, CustomReportServices, $http, $location, helperService, reportsDataService, reportsService) {

    $scope.$on('kendoWidgetCreated', function () {
        if ($scope.grid) {
            $scope.gridReady = true;
            $scope.runSavedReport();
        }
    });

    $scope.helperService = helperService;

    var vm = $scope.vm = {};

    vm.allRolesOption = true;
    vm.updateRoleDataSource = updateRoleDataSource;
    vm.objectSearchField = $rootScope.UserPreferences.ObjectSearchField;

    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    // ---

    objectIdDataService.getObjectID().then(function (objectId) {
        $scope.ObjectId = objectId;

        $scope.filtersDataSource = CustomReportServices.setfiltersDataSource($scope.ObjectId);

        var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;

        dataService.getProducts().then(function (response) {
            response.data = response.data.filter(p => p.UserHasAccess === true);
            vm.productsDataSource = response.data;
            let defaultProduct = vm.productsDataSource.find(obj => obj.Name === $rootScope.UserPreferences.PreferredProduct);
            let defaultProductSelected = vm.productsDataSource.indexOf(defaultProduct);
            if ($scope.isDetailed) {
                vm.productsDataSource = vm.productsDataSource.filter(product => product.Type === "AX" || product.Type === "AX5" || product.Type === "AX7" || product.Type === "GP" || product.Type === "ORFC" || product.Type === "SAP");
                let defaultProductExists = vm.productsDataSource.some(obj => obj.Name === $rootScope.UserPreferences.PreferredProduct);
                if (defaultProductExists) {
                    defaultProductSelected = vm.productsDataSource.indexOf(defaultProduct);
                    if ($rootScope.UserPreferences.PreferredProduct !== null) {
                        vm.selectedProduct = vm.productsDataSource[defaultProductSelected];
                    } else {

                        vm.selectedProduct = vm.productsDataSource[0];
                    }
                } else {
                    vm.selectedProduct = vm.productsDataSource[0];
                }
            } else {
                if ($rootScope.UserPreferences.PreferredProduct !== null) {
                    vm.selectedProduct = vm.productsDataSource[defaultProductSelected];
                } else {
                    vm.selectedProduct = vm.productsDataSource[0];
                }
            }

            vm.selectedProduct = SavedReportSelectorService.checkSavedReportForSingleProduct($rootScope.loadedSavedReport, response.data, vm.selectedProduct);

            if ($scope.isDetailed) {
                $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProduct.Type).ReportID;
            } else {
                $scope.reportId = securityObject.ReportID;
            }

            getObjectTypes();
            vm.setColumns();
            $scope.runSavedReport();

            // --- FOR ADVANCED FILTERING ---
            getReportMetadata().then(_ => {
                if ($rootScope.loadedSavedReport) {
                    $scope.runSavedReport();
                }
            });
        });
        // ---
    });

    if ($location.path().has('Detailed')) {
        $scope.isDetailed = true;
    } else {
        $scope.isDetailed = false;
    }

    vm.roleSelectedIds = [];

    $scope.runSavedReport = function () {
        if ($scope.gridReady && $rootScope.loadedSavedReport && vm.objectTypeDataSource && vm.productsDataSource && vm.roleDataSource && vm.objectDataSource && vm.filterCriteria.fields) {
            vm.filterCriteria.query = reportsService.getSavedReportFilters();
            vm.filterCriteria.needsUpdate = true;
            $scope.generateGridWithSelectedValues();
        } else if ($scope.gridReady && !$rootScope.loadedSavedReport && vm.objectTypeDataSource && vm.productsDataSource && vm.roleDataSource && vm.objectDataSource && vm.filterCriteria.fields) {
            // We want the necessary report details available to be able to use the report toolbar without running the report
            $scope.populateCriteriaModel();
        }
    };

    $scope.mainGridOptions = helperService.setMainGridOptions(null, 600);
    $scope.mainGridOptions.autoBind = false;
    $scope.filterManagerGridOptions = CustomReportServices.setfilterManagerGridOptions();

    $scope.filterDropdownOptions = helperService.getFilterDropdownOptions();

    $scope.applySelectedFilter = function () {
        helperService.applyFilterCatchError($scope);
    };

    $scope.gridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        schema: angular.extend(halSchemaKendoGrid, {
            model: {
                fields: {
                    PermissionOverride: { type: "boolean" },
                    IsAssigned: { type: "boolean" }
                }
            }
        }),
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
            read: function (options) {
                delete $rootScope.loadedSavedReport;
                let data = BuildRoleAccessPostBody.generateBody($scope.ObjectId, vm.selectedProductId, $scope.roles, vm.selectedObject, vm.selectedObjectType, $scope.isDetailed);

                data['QueryInput'] = helperService.prepareSortsAndFilters(options);

                // --- FOR ADVANCED FILTERING ---
                if (vm.filterCriteria.query) {
                    data['QueryInput'].Filters = data['QueryInput'].Filters.concat(vm.filterCriteria.query);
                }
                // ---

                // if tabs are on, save report info for the tab
                if ($rootScope.tabs.length > 0) {
                    reportsService.saveTabReportInfo($scope);
                }

                $http({
                    method: 'POST',
                    url: $scope.urlString,
                    data: data,
                    params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                }).then(function (response) {
                    options.success(response.data);
                    vm.enableGrid = true;

                    if ($scope.isDetailed) {
                        var securityObject = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProduct.Type);

                        $scope.reportId = securityObject.ReportID;
                    }

                }).catch(response => {
                    const timeout = 408;

                    if (response.status !== timeout) {
                        helperService.showErrorMessage(response.data);
                    }
                });
            }
        }
    });

    $scope.populateCriteriaModel = function () {
        // do not continue if the proper criteria are not loaded
        if (!vm.selectedProduct || vm.selectedObjectType === undefined || vm.selectedObject === undefined || vm.roleSelectedIds === undefined) {
            return;
        }
        $scope.product = vm.selectedProduct;
        vm.selectedProductId = [];
        $scope.securityObject = JSON.parse(JSON.stringify(vm.selectedObject));
        $scope.securityObjectType = vm.selectedObjectType;
        $scope.roles = vm.roleSelectedIds;//.map(function (el) { return el.ID; });

        CustomReportServices.convertCriteriaValueToNull($scope.securityObject, "Identifier");

        var criteria = [];
        criteria.push(CustomReportServices.CreateCriteria("SecurityObjectType", 'SecurityObjectType', $scope.securityObjectType.Name, { "Id": vm.selectedObjectType.Identifier }));
        criteria.push(CustomReportServices.CreateCriteria("SecurityObject", 'SecurityObject', $scope.securityObject.AltName, { "Id": $scope.securityObject.Identifier }));

        vm.roleSelectedIds.forEach(function (row, i) {
            criteria.push(CustomReportServices.CreateCriteria("SecurityRoleIdentifier", 'Role', row.Name, row.Identifier));
        });

        criteria.push(CustomReportServices.CreateCriteria("ProductId", 'Product', $scope.product.Name, $scope.product.ID));

        $scope.reportDetailsModel = criteria;

        if ($scope.isDetailed === true) {
            vm.selectedProductId.push($scope.product.ID);
            let productString = vm.selectedProduct.Type === 'SAP' ? 'SAP' : vm.selectedProduct.ID;
            $scope.urlString = apiUrl + 'api/arm/' + productString + '/places/roleaccess';
        } else {
            vm.selectedProductId.push($scope.product.ID);
            $scope.urlString = apiUrl + 'api/arm/places/roleaccess';
        }

        // if tabs are on, save report info for the tab
        if ($rootScope.tabs.length > 0) {
            reportsService.saveTabReportInfo($scope);
        }

    };

    $scope.generateGridWithSelectedValues = function () {

        if (!$rootScope.loadedSavedReport) {
            $scope.grid.dataSource._filter = null;
            $scope.grid.dataSource._sort = null;
        }

        $scope.populateCriteriaModel();
        vm.hideExportOptionsFromSubmit = true;

        // Add back the sorting and filtering options if we are running the report for the first time
        if (!$rootScope.loadedSavedReport) {
            $scope.grid.setOptions({
                filterable: {
                    mode: "row"
                },
                sortable: true
            });
        }

        SavedReportSelectorService.queryGridWithOptions($rootScope.loadedSavedReport, $scope.grid, true);
    };

    vm.setColumns = function () {

        if ($scope.isDetailed === true) {
            if (vm.selectedProduct.Type === 'AX7') {
                $scope.mainGridColumns = [
                    { field: "RoleName", title: "Role", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RoleSystemName", title: "Role System Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SubRoleName", title: "Sub Role", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SubRoleSystemName", title: "Sub Role System Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "DutyName", title: "Duty Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "DutySystemName", title: "Duty System Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "PrivilegeName", title: "Privilege Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "PrivilegeSystemName", title: "Privilege System Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "ObjectName", title: "Object Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "ObjectLabel", title: "Object Label", width: 120, filterable: customAutoCompleteFilter },
                    { field: "ObjectType", title: "Object Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "AccessType", title: "Access Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "MenuPath", title: "Menu Path", width: 120, filterable: customAutoCompleteFilter },
                    { field: "License", title: "License", width: 120, filterable: customAutoCompleteFilter },
                    { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
                ];
            } else if (vm.selectedProduct.Type === 'AX') {
                $scope.mainGridColumns = [
                    { field: "RoleName", title: "Role", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RolePhysicalName", title: "Role Physical Name", width: 120, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "SubRoleName", title: "Sub Role Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SubRolePhysicalName ", title: "Sub Role Physical Name", width: 120, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "DutyName", title: "Duty", width: 120, filterable: customAutoCompleteFilter },
                    { field: "DutyPhysicalName", title: "Duty Physical Name", width: 120, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "PrivilegeName", title: "Privilege", width: 120, filterable: customAutoCompleteFilter },
                    { field: "PrivilegePhysicalName", title: "Privilege Physical Name", width: 120, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "SecurityObjectAltName", title: "Object Label", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectName", title: "Object Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectHelpText", title: "Object Help Text", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectTypeName", title: "Object Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "AccessTypeName", title: "Access Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "LicenseType", title: "License Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "PermissionOverride", title: "Permission Override", width: 120, filterable: customBooleanFilter },
                    { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
                ];
            } else if (vm.selectedProduct.Type === 'AX5') {
                $scope.mainGridColumns = [
                    { field: "RoleId", title: "Group ID", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RoleName", title: "Group Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "DomainId", title: "Domain", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectName", title: "Object Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectAltName", title: "Object Label", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectTypeName", title: "Object Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "AccessTypeName", title: "Access Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
                ];
            } else if (vm.selectedProduct.Type === 'GP') {
                $scope.mainGridColumns = [
                    { field: "RoleId", title: "Role ID", width: 40, filterable: customAutoCompleteFilter },
                    { field: "RoleName", title: "Role", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectName", title: "Security Object", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectAltName", title: "Security Object Alt Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "Series", title: "Series", width: 120, filterable: customAutoCompleteFilter },
                    { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
                ];
            } else if (vm.selectedProduct.Type === 'SAP') {
                $scope.mainGridColumns = [
                    { field: "RoleId", title: "Role ID", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RoleName", title: "Role", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectId", title: "Security Object ID", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectName", title: "Security Object", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectTypeName", title: "Security Object Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "Authorization", title: "Authorization", width: 120, filterable: customAutoCompleteFilter },
                    { field: "Field", title: "Field", width: 120, filterable: customAutoCompleteFilter },
                    { field: "TechnicalLow", title: "Technical Low", width: 130, filterable: customAutoCompleteFilter },
                    { field: "Low", title: "Low", width: 130, filterable: customAutoCompleteFilter },
                    { field: "TechnicalHigh", title: "Technical High", width: 130, filterable: customAutoCompleteFilter },
                    { field: "High", title: "High", width: 130, filterable: customAutoCompleteFilter },
                    { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
                ];
            } else if (vm.selectedProduct.Type === 'ORFC') {
                $scope.mainGridColumns = [
                    { field: "RoleName", title: "Role Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RolePhysicalName", title: "Role Physical Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RoleId", title: "Role Id", width: 120, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "SubRoleName", title: "Sub Role Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SubRolePhysicalName", title: "Sub Role Physical Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectAltName", title: "Object Label", width: 120, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectName", title: "Object Name", width: 120, filterable: customAutoCompleteFilter },
                    { field: "PrivilegeDescription", title: "Privilege Description", width: 120, filterable: customAutoCompleteFilter },
                    { field: "Path", title: "Path", width: 120, filterable: customAutoCompleteFilter },
                    { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
                ];
            }
        } else {
            $scope.mainGridColumns = [
                { field: "RoleId", title: "Role ID", width: 40, filterable: customAutoCompleteFilter },
                { field: "RoleName", title: "Role Name", width: 120, filterable: customAutoCompleteFilter },
                { field: "RoleType", title: "Role Type", width: 120, filterable: customAutoCompleteFilter },
                { field: "SecurityObjectId", title: "Object ID", width: 120, filterable: customAutoCompleteFilter },
                { field: "SecurityObjectName", title: "Object Name", width: 120, filterable: customAutoCompleteFilter },
                { field: "SecurityObjectAltName", title: "Object Label", width: 120, filterable: customAutoCompleteFilter },
                { field: "SecurityObjectTypeName", title: "Object Type", width: 120, filterable: customAutoCompleteFilter },
                { field: "AccessTypeName", title: "Access Type", width: 120, filterable: customAutoCompleteFilter },
                { field: "AccessLevelName", title: "Access Level", width: 120, filterable: customAutoCompleteFilter },
                { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
            ];
        }

        // Don't allow filtering and sorting yet because they will force the report to run
        if (!$rootScope.loadedSavedReport) {
            $scope.mainGridOptions.sortable = false;

            $scope.mainGridOptions.filterable = false;
        }

        vm.setLayout();
    };

    vm.setLayout = function () {
        if (!$rootScope.loadedSavedReport) {
            CustomReportServices.loadSavedGridLayout($scope, null);
        }
    };

    $scope.deleteFilter = function (id) {
        CustomReportServices.deleteFilterFilterManager($scope, id);
    };

    $scope.clearAllFilters = function () {
        $scope.gridDataSource.filter({});
    };

    $scope.saveReportLayoutButton = function () {
        CustomReportServices.saveGridLayout($scope, null);
    };

    $scope.productDropdownChange = function () {
        if ($scope.isDetailed) {
            $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProduct.Type).ReportID;
            getReportMetadata();
        }

        vm.roleSelectedIds = [];
        getObjectTypes();
        vm.setColumns();
    };

    function getObjectTypes() {
        vm.objectTypesDropdownLoading = true;

        $http({
            method: 'GET',
            url: apiUrl + 'api/universal/securityobjecttypes/producttype',
            params: { 'productType': vm.selectedProduct.Type }
        }).then(function (response) {
            vm.objectTypesDropdownLoading = false;
            response.data.unshift({ "Name": "All Object Types", "ID": null });

            vm.selectedObjectType = response.data.first();
            vm.objectTypeDataSource = $filter('orderBy')(response.data, 'Name');
            vm.selectedObjectType = SavedReportSelectorService.checkSavedReportForObjectTypes($rootScope.loadedSavedReport, response.data, vm.selectedObjectType);
            $scope.objectTypeDropdownChange();
            $scope.runSavedReport();
        });
    }

    $scope.objectTypeDropdownChange = function () {
        $scope.kendoObjectDropdownDataSource._data = [];
        $scope.kendoObjectDropdownDataSource.read();
    };

    $scope.kendoObjectDropdownDataSource = new kendo.data.DataSource({
        schema: {
            data: "_embedded.reportData",
            total: "TotalItems"
        },
        pageSize: standardGridPageSize,
        serverPaging: true,
        serverFiltering: true,
        transport: {
            read: function (options) {

                if (vm.selectedObjectType && vm.selectedObjectType.Identifier !== '') {
                    vm.objectsDropdownLoading = true;

                    let data = helperService.prepareSortsAndFilters(options);
                    if (data.Filters.length > 0) {
                        if (vm.objectSearchField === 'AltName') {
                            data.Filters[0].FieldName = "SecurityObjectAltName";
                        } else {
                            data.Filters[0].FieldName = "SecurityObjectName";
                        }
                    }

                    if (vm.selectedProduct.Type === 'SAP') {
                        data.ProductIds = [vm.selectedProduct.ID]
                    }

                    var urlString = null;
                    var params = null;
                    params = {
                        "productType": vm.selectedProduct.Type,
                        "pagesize": options.data.pageSize,
                        "page": options.data.page
                    };
                    if (vm.selectedObjectType.Identifier) {
                        urlString = apiUrl + 'api/universal/securityobjects/producttype/' + vm.selectedObjectType.ProductType + '/type/' + vm.selectedObjectType.ID;
                    } else {
                        urlString = apiUrl + 'api/universal/securityobjects';
                    }

                    $http({
                        method: 'POST',
                        url: urlString,
                        data: data,
                        params: params
                    }).then(function (response) {
                        let data = response.data._embedded.reportData;

                        if (vm.objectSearchField === 'AltName') {
                            data = fillAltNames(data);
                        }

                        if ($scope.kendoObjectDropdownDataSource._data.length === 0) {

                            // objects are being loaded for the first time
                            addAllObjectsOption();

                            if ($rootScope.loadedSavedReport) {
                                SavedReportSelectorService.getSavedReportSelectedObject($rootScope.loadedSavedReport).then(response => {
                                    if (response) {
                                        data.unshift(response);
                                    }
                                    success(data);
                                });
                            } else {
                                success(data);
                            }
                        } else if (options.data.page === 1) {
                            if (!options.data.filter || options.data.filter.filters.length === 0 || options.data.filter.filters[0].value === "") {
                                addAllObjectsOption();
                            }
                            if (vm.selectedObject.Identifier !== "-1" && !data.some(object => object.Identifier === vm.selectedObject.Identifier)) {
                                // selected object is not in the dataset we need to add it and make sure it stays selected
                                data.unshift(vm.selectedObject);
                                vm.selectedObject = data[0];
                            }
                            options.success(response.data);
                            $scope.populateCriteriaModel();
                        } else {
                            options.success(response.data);
                            $scope.populateCriteriaModel();
                        }

                        function addAllObjectsOption() {
                            let AllObjects = {};
                            if (vm.objectSearchField === 'AltName') {
                                AllObjects = { "AltName": "All Objects", "Id": null, "Identifier": "-1" };
                            } else {
                                AllObjects = { "Name": "All Objects", "Id": null, "Identifier": "-1" };
                            }
                            data.unshift(AllObjects);
                        }

                        function success(data) {
                            vm.objectDataSource = data;
                            vm.selectedObject = data[0];
                            vm.objectsDropdownLoading = false;
                            $scope.runSavedReport();
                            options.success(response.data);
                            $scope.populateCriteriaModel();
                        }

                    });
                } else {
                    options.error();
                }
            }
        }
    });

    if (vm.objectSearchField === 'AltName') {
        vm.dropdownTemplate = '<strong>{{dataItem.AltName}}</strong> <span ng-if="dataItem.Name"> | {{dataItem.Name}}</span> <span ng-if="dataItem.SecurityObjectTypeName"> | {{dataItem.SecurityObjectTypeName}} </span> <span class="badge light-gray-badge-background">{{dataItem.Id}}</span>';
        vm.dataTextField = 'AltName';
    } else {
        vm.dropdownTemplate = '<strong>{{dataItem.Name}}</strong> <span ng-if="dataItem.AltName"> | {{dataItem.AltName}}</span> <span ng-if="dataItem.SecurityObjectTypeName"> | {{dataItem.SecurityObjectTypeName}} </span> <span class="badge light-gray-badge-background">{{dataItem.Id}}</span>';
        vm.dataTextField = 'Name';
    }

    $scope.objectDropdownOptions = {
        template: vm.dropdownTemplate,
        filter: "contains",
        autoBind: false,
        minLength: 3,
        virtual: {
            itemHeight: 26,
            mapValueTo: "dataItem",
            valueMapper: function (options) {
                options.success(helperService.valueMapperFunction(options, $scope.vm.objectDataSource, 'Identifier'));
            }
        },
        open: function () {
            let filters = this.dataSource._filter;
            if (filters) {
                this.dataSource.filter({});
            }
        },
        change: function () {
            $scope.populateCriteriaModel();
        },
        height: 325
    };

    function updateRoleDataSource(roleDataSource, roleSelectedIds) {
        vm.roleDataSource = roleDataSource;
        vm.roleSelectedIds = roleSelectedIds;
        $scope.populateCriteriaModel();
    }

    // --- FOR ADVANCED FILTERING ---
    function getReportMetadata() {
        return reportsDataService.getReportMetadata($scope.reportId)
            .then(data => {

                vm.reportMetadata = data.data._embedded.reportData;
                vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');
                vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
                return vm.reportMetadata;
            });
    }
    // ---
}

function fillAltNames(data) {
    return data.map(object => {
        if (!object.AltName) {
            object.AltName = object.Name;
            object.Name = ""
        }

        return object;
    });
}