import createSpinner from "../../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (reviewSchedulesDataService, reviewAssignmentsDataService, $uibModalInstance, schedule, isClone, helperService) {
    const vm = this;
    vm.isClone = isClone;

    vm.saveSchedule = saveSchedule;
    vm.dismiss = dismiss;

    vm.schedule = { ...schedule };

    activate();

    function activate() {
        if (isClone) {
            vm.scheduleInstanceId = vm.schedule.Id;
            vm.schedule = {};
        } else {
            getReviewTypes();
        }
    }

    async function getReviewTypes() {
        const response = await reviewAssignmentsDataService.getReviewTypes();
        vm.scheduleTypesDataSource = response.data;
    }

    async function saveSchedule() {
        vm.saveInProgress = new createSpinner();
        let scheduleInstance = {
            Name: vm.schedule.Name,
            Description: vm.schedule.Description,
            ReviewType: vm.schedule.ReviewType,
            IncludeSupervisorReviews: vm.schedule.IncludeSupervisorReviews,
            IncludeCompanyInReviews: vm.schedule.IncludeCompanyInReviews
        }
        if (vm.schedule.Id && !isClone) {
            updateScheduleInstance(scheduleInstance);
        } else if (isClone) {
            cloneScheduleInstance(scheduleInstance);
        }
        else {
            createScheduleInstance(scheduleInstance);
        }
    }

    async function createScheduleInstance(scheduleInstance) {
        try {
            const response = await reviewSchedulesDataService.createInstance(scheduleInstance)
            await helperService.successfulSaveButton(vm.saveInProgress);
            close(response.data);
        } catch {
            vm.saveInProgress.loadingValue = false;
        }
        
    }

    async function cloneScheduleInstance(scheduleInstance) {
        scheduleInstance.ReviewInstanceId = vm.scheduleInstanceId;

        try {
            const response = await reviewSchedulesDataService.cloneInstance(scheduleInstance)
            await helperService.successfulSaveButton(vm.saveInProgress);
            close(response.data);
        } catch {
            vm.saveInProgress.loadingValue = false;
        }

    }

    async function updateScheduleInstance(scheduleInstance) {
        scheduleInstance.Id = vm.schedule.Id;
        scheduleInstance.ReviewType = schedule.ReviewType;
        try {
            await reviewSchedulesDataService.updateInstance(scheduleInstance)
            await helperService.successfulSaveButton(vm.saveInProgress);
            close();
        } catch {
            vm.saveInProgress.loadingValue = false;
        }
    }

    function close(newInstanceId) {
        $uibModalInstance.close(newInstanceId);
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }
}