export default /*@ngInject*/ function ($http, errorsDataService) {
    const factory = {
        getOwnershipGroups,
        getOwnershipGroupTypes,
        exportOwnershipGroups,
        createGroup,
        addItemsToGroupObject,
        addOwnersToGroupObject,
        updateGroup,
        importOwnershipGroups,
        deleteGroup
    };

    return factory;

    async function getOwnershipGroups() {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups`);
        } catch (err) {
            showError(err);
        }
    }

    async function getOwnershipGroupTypes() {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/types`);
        } catch (err) {
            showError(err);
        }
    }

    async function exportOwnershipGroups() {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/export`, { responseType: "blob" });
        } catch (err) {
            showError(err);
        }
    }

    async function createGroup(data) {
        try {
            return await $http.post(`${apiUrl}api/universal/ownershipgroups`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addItemsToGroupObject(ownershipGroup, groupOwner, data) {
        try {
            return await $http.put(`${apiUrl}api/universal/ownershipgroups/${ownershipGroup.Id}/type/${ownershipGroup.GroupType}/owners/${groupOwner.Id}/additems`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addOwnersToGroupObject(groupId, type, itemId, data) {
        try {
            return await $http.put(`${apiUrl}api/universal/ownershipgroups/${groupId}/type/${type}/items/${itemId}/addowners`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateGroup(data) {
        try {
            return await $http.put(`${apiUrl}api/universal/ownershipgroups/${data.GroupId}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function importOwnershipGroups(data) {
        try {
            return await $http.put(`${apiUrl}api/universal/ownershipgroups/import`, data, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }
   

    async function deleteGroup(groupId) {
        try {
            return await $http.delete(`${apiUrl}api/universal/ownershipgroups/${groupId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(error) {
        errorsDataService.catch(error);
    }

    function sendError(error) {
        throw error;
    }

}