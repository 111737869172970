export default /*@ngInject*/ function ($scope, manageDataDataService, $http) {
    var orfcUserMinDate = new Date();
    orfcUserMinDate.setHours(0, 0, 0, 0);

    $scope.vm.selectUser = selectUser;

    setFieldConfigurationValues();

    if (!$scope.vm.User.OracleCloudUser) {
        $scope.vm.User.OracleCloudUser = {};
    }
    $scope.vm.User.OracleCloudUser.ProductType = "ORFC";

    $scope.vm.orfcUserMinDate = orfcUserMinDate;
    if (!$scope.vm.User.OracleCloudUser) {
        $scope.vm.User.OracleCloudUser = {};
    }


    $scope.orfcUserFromDateChanged = function () {
        var date;
        if ($scope.vm.User.OracleCloudUser.StartDate === '') {
            date = new Date();
        } else {
            date = new Date($scope.vm.User.OracleCloudUser.StartDate);
        }

        $scope.vm.orfcUserMinDate = date;
        $scope.vm.orfcMinDate = date;
        $scope.vm.fromMinDate = date;
        if ($scope.vm.User.OracleCloudUser == null) {
            $scope.vm.User.OracleCloudUser = {};
        }
    };

    $scope.orfcUserToDateChanged = function () {
        $scope.vm.orfcUserMinDate = new Date($scope.vm.User.OracleCloudUser.EndDate);
        $scope.vm.orfcMinDate = new Date($scope.vm.User.OracleCloudUser.EndDate);
        $scope.vm.fromMinDate = new Date($scope.vm.User.OracleCloudUser.StartDate);
        if ($scope.vm.User.OracleCloudUser == null) {
            $scope.vm.User.OracleCloudUser = {};
        }
    };

    async function setFieldConfigurationValues() {
        $scope.vm.showADUserLookup = $scope.vm.config.find(config => config.Name === 'AD_Active').Value === '1';
        $scope.vm.showAADUserLookup = $scope.vm.config.find(config => config.Name === 'AAD_Active').Value === '1';
        let response = await manageDataDataService.getConfigurationByProductId('ORFC');
        $scope.vm.disableADUserFields = response.data.find(config => config.Name === 'IM_DisableADUserFields').Value === '1' && $scope.vm.showADUserLookup;
        $scope.vm.disableAADUserFields = response.data.find(config => config.Name === 'IM_DisableAADUserFields').Value === '1' && $scope.vm.showAADUserLookup;
    }

    function selectUser(user) {
        $scope.vm.User.OracleCloudUser = user;
        if (user.UserType !== 'Guest') {
            setProvider();
        }
    }

    async function setProvider() {
        const { data } = await $http.get(`${apiUrl}api/universal/products/ORFC/configuration/im_provider`);
        $scope.vm.User.OracleCloudUser.NetworkDomain = data.Value;
    }
}