import Controller from "./connectionSchedule.controller";

/* connectionSchedule.component.js */

/**
 * @desc component for managing product connection schedules
 * @example <connection-schedule></connection-schedule>
 */

const connectionSchedule = {
    bindings: {
        product: '<',
    },
    controller: Controller,
    controllerAs: 'csvm',
    templateUrl: '/App/Components/Connections/Connection/Schedule/connectionSchedule.tmpl.html'
};

export default connectionSchedule;