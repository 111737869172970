import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, businessProcessDataService, businessProcess, riskRuleset) {
    const vm = this;

    vm.saveBusinessProcess = saveBusinessProcess;
    vm.close = close;
    vm.cancel = cancel;

    activate();

    function activate() {
        if (businessProcess) {
            vm.businessProcess = {
                Name: businessProcess.Name,
                Description: businessProcess.Description,
                Id: businessProcess.Id,
                RulesetId: businessProcess.RulesetId
            }
        } else {
            vm.businessProcess = {
                RulesetId: riskRuleset.RulesetId
            };
        }
    }

    function saveBusinessProcess() {
        vm.bpSaveInProgress = new createSpinner();
        if (vm.businessProcess.Id) {
            updateBusinessProcess();
        } else {
            addBusinessProcess();
        }
    }

    async function addBusinessProcess() {
        try {
            let response = await businessProcessDataService.createBusinessProcess(vm.businessProcess);
            await helperService.successfulSaveButton(vm.bpSaveInProgress);
            close(response.data);
        } catch {
            vm.bpSaveInProgress.loadingValue = false;
        }
    }

    async function updateBusinessProcess() {
        try {
            await businessProcessDataService.updateBusinessProcess(vm.businessProcess);
            await helperService.successfulSaveButton(vm.bpSaveInProgress);
            close();
        } catch {
            vm.bpSaveInProgress.loadingValue = false;
        }
    }

    function close(bpId) {
        $uibModalInstance.close(bpId);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }

}