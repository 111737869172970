/* ownershipGroupAssignments.component.js */

/**
 * @desc component for displaying assignments for a given ownership group
 * @example <ownership-group-assignments></ownership-group-assignments>
 */

const ownershipGroupAssignments = {
    bindings: {
        group: '<'
    },
    controller: ownershipGroupAssignmentsController,
    controllerAs: 'ogavm',
    templateUrl: '/App/Shared/Components/ownershipGroupAssignments/ownershipGroupAssignments.tmpl.html'
};

/*@ngInject*/ function ownershipGroupAssignmentsController(ownershipGroupItemsDataService, ownershipGroupOwnerDataService) {
    const vm = this;
    vm.changeSelectedItem = changeSelectedItem;

    vm.$onChanges = activate;

    async function activate() {
        vm.selectedItem = null;
        vm.owners = [];
        if (vm.group.Id) {
            vm.items = await getItems();
        }
    }

    async function getItems() {
        vm.itemsLoading = true;
        const { data } = await ownershipGroupItemsDataService.getGroupItems(vm.group.Id, vm.group.GroupType);
        vm.itemsLoading = false;
        return data;
    }

    async function getItemOwners(item) {
        vm.ownersLoading = true;
        const { data } = await ownershipGroupOwnerDataService.getGroupItemOwners(vm.group, item);
        vm.ownersLoading = false;
        return data;
    }

    async function changeSelectedItem(item) {
        vm.selectedItem = item;
        vm.owners = await getItemOwners(item);
    }
}

export default ownershipGroupAssignments;