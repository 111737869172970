export default /*@ngInject*/ function ($http, errorsDataService) {
    const factory = {
        getAssignedRisks,
        assignRiskByUser,
        assignByRisk,
        removeAssignedRiskByUser
    };

    return factory;

    async function getAssignedRisks() {
        try {
            return await $http.get(`${apiUrl}api/arm/assignrisks/user`);
        } catch (err) {
            showError(err);
        }
    }

    async function assignRiskByUser(data) {
        try {
            return await $http.put(`${apiUrl}api/arm/assignrisks/user`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function assignByRisk(data) {
        try {
            return await $http.put(`${apiUrl}api/arm/assignrisks/risk`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function removeAssignedRiskByUser(userMasterId) {
        try {
            return await $http.delete(`${apiUrl}api/arm/assignrisks/user/${userMasterId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}
