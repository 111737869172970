import manageConditionsController from "./manageConditions.controller";

/* manageConditions.component.js */

/**
 * @desc component for managing conditions in Maintain Risk Ruleset
 * @example <manage-conditions></manage-conditions>
 */

const manageConditions = {
    bindings: {
        resolve: '<',
        modalInstance: '<'
    },
    controller: manageConditionsController,
    controllerAs: 'mcvm',
    templateUrl: '/App/Components/maintainRiskRuleset/objects/manageConditions/manageConditions.tmpl.html'
};

export default manageConditions;