import manageSavedLayoutsController from "./manageSavedLayouts.controller";

/* manageSavedLayouts.component.js */

/**
 * @desc component for managing saved layouts
 * @example <manage-saved-layouts></manage-saved-layouts>
 */

const manageSavedLayouts = {
   bindings: {
      resolve: '<',
      modalInstance: '<',
   },
    controller: manageSavedLayoutsController,
   controllerAs: 'mslvm',
   templateUrl: '/App/Shared/Components/ManageSavedLayouts/manageSavedLayouts.tmpl.html'
};

export default manageSavedLayouts;