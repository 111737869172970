export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getGeneratedReports,
        createGeneratedReport,
        createAx7GeneratedReport,
        deleteReport
    };

    return service;

    async function getGeneratedReports() {
        try {
            return await $http.get(`${apiUrl}api/audittrail/reports`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function createGeneratedReport(data) {
        try {
            return await $http.post(`${apiUrl}api/audittrail/reports`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function createAx7GeneratedReport(data) {
        try {
            return await $http.post(`${apiUrl}api/audittrail/ax7/reports`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteReport(reportId) {
        try {
            await $http.delete(`${apiUrl}api/audittrail/reports/${reportId}`);
        } catch (err) {
            showError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }

}