export default /*@ngInject*/ function ($q, RequestManagementAXService) {

    this.loadAX7 = function ($scope) {
        var axDfrd = $.Deferred();

        loadAX7Permissions($scope).done(function () {
            axDfrd.resolve();
        });

        return $.when(axDfrd).done(function () {
        }).promise();
    };

    this.loadAX7LoadPermisssions = function ($scope) {
        return loadAX7Permissions($scope);
    };
    //Load the AX permissions, after that completes and if there is an existing AX user load their permissions
    function loadAX7Permissions($scope) {
        var permissionsDfrd = $.Deferred(),
            promises = [],
            axRoles = [],
            pendingRoles = $scope.vm.User?.AX7User?.PendingRoleCompanies || [],
            pendingUserGroups = $scope.vm.User?.AX7User?.PendingUserGroups || [];

        $scope.vm.ListOfSelectedAX7Roles = [];
        $scope.vm.ListOfAvailableAX7Roles = [];
        $scope.vm.ListOfSelectedAX7UserGroups = [];
        $scope.vm.ListOfAvailableAX7UserGroups = [];

        if ($scope.vm.User.AX7User != null && $scope.vm.User.AX7User.UserId != null) {

            //If any axRoles are selected in the request set the axRoles valid property to true below
            $scope.vm.numberOfAXRolesValid = false;

            axRoles = $scope.vm.User.AX7User.RoleCompanies || [];

            if (axRoles?.length > 0) {
                $scope.vm.numberOfAXRolesValid = true;
            }
        }

        //Load Available axRoles then process existing security
        promises.push(RequestManagementAXService.getAllAX7Roles().then(function (response) {
            $scope.vm.ListOfAvailableAX7Roles = response.data.filter(applyPendingByKey.bind(null, "Role", pendingRoles));

            // we need to pre-select the system user role
            var systemUserRole = $scope.vm.ListOfAvailableAX7Roles.find(role => role.Id === 'SystemUser');

            if ($scope.vm.wizardMode === 'New' && !$scope.defaultRoleTemplate) {

                $scope.vm.ListOfAvailableAX7Roles.forEach((row, i) => {
                    if (row.Id == systemUserRole.Id) {
                        $scope.vm.ListOfAvailableAX7Roles.splice(i, 1);
                        $scope.vm.ListOfSelectedAX7Roles.push(systemUserRole);
                    }
                });
            } else if ($scope.vm.wizardMode === "New" && $scope.defaultRoleTemplate) {
                let defaultRoles = $scope.defaultRoleTemplate.Roles.filter(role => role.TypeId === "Role" && role.ProductType === "AX7");

                $scope.vm.ListOfSelectedAX7Roles = [...defaultRoles];
                $scope.vm.ListOfAvailableAX7Roles = $scope.vm.ListOfAvailableAX7Roles.filter(availableRole => {
                    let selectedRole = $scope.vm.ListOfSelectedAX7Roles.find(selectedRole => selectedRole.Id === availableRole.Id);

                    if (selectedRole) {
                        selectedRole = Object.merge(selectedRole, availableRole);
                    }

                    return !selectedRole;
                });

                $scope.vm.ListOfSelectedAX7Roles.push(systemUserRole);
            }

            if ($scope.vm.ListOfSelectedAX7Roles.length) {
                $scope.vm.numberOfAXRolesValid = true;
            }

        }, function errorCallback(response) {
            permissionsDfrd.reject();
        }));

        promises.push(RequestManagementAXService.getAllAX7Companies().then(function (response) {
            $scope.vm.ListOfAX7Companies = response.data;

            if ($scope.vm.axCompanyDropdownDataSource) {
                $scope.vm.axCompanyDropdownDataSource.read();
            }
        }, function errorCallback(response) {
            permissionsDfrd.reject();
        }));

        //Load Available axUserGroups then process existing security
        promises.push(RequestManagementAXService.getAllAX7UserGroups().then(function (response) {
            $scope.vm.ListOfAvailableAX7UserGroups = response.data.map(applyPending.bind(null, pendingUserGroups));
        }, function errorCallback(response) {
            permissionsDfrd.reject();
        }));

        $q.all(promises).then(function () {

            //Load AX user roles
            if ($scope.vm.User.AX7User.RoleCompanies != null) {
                $scope.vm.User.AX7User.RoleCompanies.forEach(roleCompany => {

                    var axRole = null;
                    var selectedAXRole = null;
                    var selectedCompany = null;

                    //Get the role information from the available axRoles list
                    axRole = $scope.vm.ListOfAvailableAX7Roles.find(object => object.Id.toUpperCase() === roleCompany.Role.Id.toUpperCase());

                    //Check if the company Id has been set for the current role company
                    if (roleCompany.Company != null) {
                        selectedCompany = $scope.vm.ListOfAX7Companies.find(object => object.CompanyId.toUpperCase() === roleCompany.Company.CompanyId.toUpperCase());
                    }


                    //Check if the role already exists in the selected list
                    selectedAXRole = $scope.vm.ListOfSelectedAX7Roles.find(object => object.Id.toUpperCase() === roleCompany.Role.Id.toUpperCase());
                    //If the role already exists add the current company information to the company list
                    if (selectedAXRole != null && selectedCompany != null) {
                        if (selectedAXRole.Companies == null) {
                            selectedAXRole.Companies = [];
                        }
                        if (selectedAXRole.Companies.none(company => company.CompanyId.toUpperCase() === selectedCompany.CompanyId.toUpperCase())) {
                            selectedAXRole.Companies.push(selectedCompany);
                        }
                    } else {
                        if (axRole != null) {
                            $scope.vm.ListOfSelectedAX7Roles.unshift({
                                Id: axRole.Id,
                                Name: axRole.Name,
                                IsAssignable: axRole.IsAssignable,
                                Description: axRole.Description,
                                Identifier: axRole.Identifier,
                                Companies: selectedCompany == null ? null : [selectedCompany],
                                Pending: pendingRoles.find(pendingRole => pendingRole.Role.Id.toUpperCase() === axRole.Id.toUpperCase() && axRole.Id !== "SystemUser")
                            });

                            $scope.vm.ListOfAvailableAX7Roles.forEach(function (row, j) {
                                if (row.Id.toUpperCase() === axRole.Id.toUpperCase()) {
                                    $scope.vm.ListOfAvailableAX7Roles.splice(j, 1);
                                }
                            });
                        }
                    }

                    axRole = null;
                    selectedCompany = null;

                    $scope.vm.numberOfAXRolesValid = true;
                });
            }
            $scope.vm.ListOfAvailableAX7Roles = $scope.vm.ListOfAvailableAX7Roles.filter(role => role.IsAssignable);

            //Load AX user groups
            if ($scope.vm.User.AX7User.UserGroups != null) {
                $scope.vm.User.AX7User.UserGroups.forEach(userGroup => {

                    var axUserGroup = null;
                    var selectedAXUserGroup = null;

                    //Get the role information from the available axRoles list
                    axUserGroup = $scope.vm.ListOfAvailableAX7UserGroups.find(object => object.Id.toUpperCase() === userGroup.Id.toUpperCase());


                    //Check if the role already exists in the selected list
                    selectedAXUserGroup = $scope.vm.ListOfSelectedAX7UserGroups.find(object => object.Id.toUpperCase() === userGroup.Id.toUpperCase());
                    if (axUserGroup != null) {
                        $scope.vm.ListOfSelectedAX7UserGroups.unshift({
                            Id: axUserGroup.Id, Name: axUserGroup.Name,
                            Pending: pendingUserGroups.find(pendingGroup => pendingGroup.Id.toUpperCase() === axUserGroup.Id.toUpperCase())
                        });

                        $scope.vm.ListOfAvailableAX7UserGroups.forEach(function (row, j) {
                            if (row.Id.toUpperCase() === axUserGroup.Id.toUpperCase()) {
                                $scope.vm.ListOfAvailableAX7UserGroups.splice(j, 1);
                            }
                        });
                    }
                    axUserGroup = null;
                });
            }

            $scope.axUserLoaded = true;
            permissionsDfrd.resolve();
        });

        return $.when(permissionsDfrd).done(function () {
        }).promise();

    }

    this.loadAX2012 = function (scope) {
        var axDfrd = $.Deferred();

        loadAX2012Permissions(scope).done(function () {
            axDfrd.resolve();
        });

        return $.when(axDfrd).done(function () {
        }).promise();
    };

    this.loadAX2012LoadPermisssions = function ($scope) {
        return loadAX2012Permissions($scope);
    };
    //Load the AX permissions, after that completes and if there is an existing AX user load their permissions
    function loadAX2012Permissions($scope) {
        let permissionsDfrd = $.Deferred(),
            promises = [],
            axRoles = [],
            pendingRoles = $scope.vm.User?.AX2012User?.PendingRoleCompanies || [],
            pendingUserGroups = $scope.vm.User?.AX2012User?.PendingUserGroups || [];

        $scope.vm.ListOfSelectedAX2012Roles = [];
        $scope.vm.ListOfAvailableAX2012Roles = [];
        $scope.vm.ListOfSelectedAX2012UserGroups = [];
        $scope.vm.ListOfAvailableAX2012UserGroups = [];

        if ($scope.vm.User.AX2012User != null && $scope.vm.User.AX2012User.UserId != null) {

            //If any axRoles are selected in the request set the axRoles valid property to true below
            $scope.vm.numberOfAXRolesValid = false;

            axRoles = $scope.vm.User.AX2012User.RoleCompanies;

            if (axRoles.length > 0) {
                $scope.vm.numberOfAXRolesValid = true;
            }
        }

        //Load Available axRoles then process existing security
        promises.push(RequestManagementAXService.getAllAX2012Roles().then(function (response) {
            $scope.vm.ListOfAvailableAX2012Roles = response.data.map(applyPendingByKey.bind(null, "Role", pendingRoles));
            let systemUserRole = $scope.vm.ListOfAvailableAX2012Roles.find(role => role.Name === 'SystemUser');

            if ($scope.vm.wizardMode === 'New' && !$scope.defaultRoleTemplate) {
                // we need to pre-select the system user role

                $scope.vm.ListOfAvailableAX2012Roles.forEach((row, i) => {
                    if (row.Id === systemUserRole.Id) {
                        $scope.vm.ListOfAvailableAX2012Roles.splice(i, 1);
                        $scope.vm.ListOfSelectedAX2012Roles.push(systemUserRole);
                    }
                });
            } else if ($scope.vm.wizardMode === "New" && $scope.defaultRoleTemplate) {
                let defaultRoles = $scope.defaultRoleTemplate.Roles.filter(role => role.TypeId === "Role" && role.ProductType === "AX");

                $scope.vm.ListOfSelectedAX2012Roles = [...defaultRoles];
                $scope.vm.ListOfAvailableAX2012Roles = $scope.vm.ListOfAvailableAX2012Roles.filter(availableRole => {
                    let selectedRole = $scope.vm.ListOfSelectedAX2012Roles.find(selectedRole => selectedRole.Id === availableRole.Id);

                    if (selectedRole) {
                        selectedRole = Object.merge(selectedRole, availableRole);
                    }

                    return !selectedRole;
                });
                $scope.vm.ListOfSelectedAX2012Roles.push(systemUserRole);
            }

            if ($scope.vm.ListOfSelectedAX2012Roles.length) {
                $scope.vm.numberOfAXRolesValid = true;
            }

        }, function errorCallback(response) {
            permissionsDfrd.reject();
        }));

        promises.push(RequestManagementAXService.getAllAX2012Partitions().then(function (response) {
            $scope.vm.ListOfAX2012Partitions = response.data;
            $scope.vm.User.AX2012User.Partition = $scope.vm.ListOfAX2012Partitions.find(p => p.Key === 'initial');

        }, function errorCallback(response) {
            permissionsDfrd.reject();
        }));

        //Load Available axUserGroups then process existing security
        promises.push(RequestManagementAXService.getAllAX2012UserGroups().then(function (response) {
            $scope.vm.ListOfAvailableAX2012UserGroups = response.data.map(applyPending.bind(null, pendingUserGroups));
        }, function errorCallback(response) {
            permissionsDfrd.reject();
        }));

        $q.all(promises).then(async function () {

            // load AX Companies
            let axCompanies = await RequestManagementAXService.getAllAX2012Companies($scope.vm.User.AX2012User.Partition.Key);

            $scope.vm.ListOfAX2012Companies = axCompanies.data;

            if ($scope.vm.axCompanyDropdownDataSource) {
                $scope.vm.axCompanyDropdownDataSource.read();
            }
            //Load AX user roles
            if ($scope.vm.User.AX2012User.RoleCompanies != null) {
                $scope.vm.User.AX2012User.RoleCompanies.forEach(function (roleCompany) {

                    var axRole = null;
                    var selectedAXRole = null;
                    var selectedCompany = null;

                    //Get the role information from the available axRoles list
                    axRole = $scope.vm.ListOfAvailableAX2012Roles.find(function (object) {
                        return object.Id.toUpperCase() === roleCompany.Role.Id.toUpperCase();
                    });
                    //Check if the company Id has been set for the current role company
                    if (roleCompany.Company != null) {
                        selectedCompany = $scope.vm.ListOfAX2012Companies.find(object => object.CompanyId.toUpperCase() === roleCompany.Company.CompanyId.toUpperCase());
                    }

                    //Check if the role already exists in the selected list
                    selectedAXRole = $scope.vm.ListOfSelectedAX2012Roles.find(object => object.Id.toUpperCase() === roleCompany.Role.Id.toUpperCase());
                    //If the role already exists add the current company information to the company list
                    if (selectedAXRole != null && selectedCompany != null) {
                        if (selectedAXRole.Companies == null) {
                            selectedAXRole.Companies = [];
                        }
                        if (selectedAXRole.Companies.none(company => company.CompanyId.toUpperCase() === selectedCompany.CompanyId.toUpperCase())) {
                            selectedAXRole.Companies.push(selectedCompany);
                        }
                    }
                    else {
                        if (axRole != null) {
                            $scope.vm.ListOfSelectedAX2012Roles.unshift({
                                Id: axRole.Id,
                                Name: axRole.Name,
                                IsAssignable: axRole.IsAssignable,
                                Description: axRole.Description,
                                Identifier: axRole.Identifier,
                                Companies: selectedCompany == null ? null : [selectedCompany],
                                Pending: pendingRoles.some(pendingRole => pendingRole.Role.Id.toUpperCase() === axRole.Id.toUpperCase() && axRole.Name !== "SystemUser")
                            });


                            $scope.vm.ListOfAvailableAX2012Roles.forEach(function (row, j) {
                                if (row.Id.toUpperCase() === axRole.Id.toUpperCase()) {
                                    $scope.vm.ListOfAvailableAX2012Roles.splice(j, 1);
                                }
                            });
                        }
                    }

                    axRole = null;
                    selectedCompany = null;

                    $scope.vm.numberOfAXRolesValid = true;
                });
            }
            $scope.vm.ListOfAvailableAX2012Roles = $scope.vm.ListOfAvailableAX2012Roles.filter(role => role.IsAssignable);

            //Load AX user groups
            if ($scope.vm.User.AX2012User.UserGroups != null) {
                $scope.vm.User.AX2012User.UserGroups.forEach(userGroup => {
                    var ax2012UserGroup = null;

                    //Get the role information from the available axRoles list
                    ax2012UserGroup = $scope.vm.ListOfAvailableAX2012UserGroups.find(object => object.Id.toUpperCase() === userGroup.Id.toUpperCase());

                    if (ax2012UserGroup != null) {
                        $scope.vm.ListOfSelectedAX2012UserGroups.unshift({
                            Id: ax2012UserGroup.Id,
                            Name: ax2012UserGroup.Name,
                            Pending: pendingUserGroups.some(pendingUserGroup => pendingUserGroup.Id === ax2012UserGroup.Id)
                        });

                        $scope.vm.ListOfAvailableAX2012UserGroups.forEach(function (row, j) {
                            if (row.Id.toUpperCase() === ax2012UserGroup.Id.toUpperCase()) {
                                $scope.vm.ListOfAvailableAX2012UserGroups.splice(j, 1);
                            }
                        });
                    }
                    ax2012UserGroup = null;
                });
            }

            $scope.axUserLoaded = true;
            permissionsDfrd.resolve();
        });

        return $.when(permissionsDfrd).done(function () {
        }).promise();
    }
}

function applyPendingByKey(key, pendingItems, item) {
    if (pendingItems.length) {
        item.Pending = pendingItems.some(pendingItem => pendingItem[key].Id.toUpperCase() === item.Id.toUpperCase());
    }

    return item;
}

function applyPending(pendingItems, item) {
    if (pendingItems.length) {
        item.Pending = pendingItems.some(pendingItem => pendingItem.Id.toUpperCase() === item.Id.toUpperCase());
    }

    return item;
}