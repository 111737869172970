export default /*@ngInject*/ function ($http, errorsDataService) {
    const factory = {
        importAutoMitigationRuleset,
        exportAutoMitigationRuleset
    };

    return factory;

    function importAutoMitigationRuleset(data) {
        return $http.put(apiUrl + 'api/assure/or/exclusions/import', data, {
            transformRequest: angular.identity,
            headers: { 'Content-Type': undefined }
        })
            .then(workComplete)
            .catch(response => errorsDataService.catch(response));
    }

    function exportAutoMitigationRuleset() {
        return $http.get(apiUrl + 'api/assure/or/exclusions/export', { responseType: "blob" })
            .then(workComplete)
            .catch(response => errorsDataService.catch(response));
    }

    function workComplete(response) {
        return response;
    }

}
