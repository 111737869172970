export default /*@ngInject*/ function ($scope, $filter, RequestManagementD365SService, helperService) {

    var vm = this;
    vm.licenseTypeDisabled = true;
    vm.getLicenseTypes = getLicenseTypes;
    vm.businessUnitDropdownChange = businessUnitDropdownChange;
    $scope.vm.selectADUser = selectADUser;
    if (!$scope.vm.User.D365SUser) {
        $scope.vm.User.D365SUser = {};
    }
    $scope.vm.User.D365SUser.ProductType = 'D365S';

    $scope.$on('im-request-d365s-getlicenseTypes', (event, args) => getLicenseTypes());

    function activate() {
        vm.businessUnitsDropdownLoading = true;
        vm.usersDropdownLoading = true;
        vm.territoryDropdownLoading = true;
        vm.siteDropdownLoading = true;
        vm.incomingMailDropdownLoading = true;
        vm.outgoingMailDropdownLoading = true;
        vm.accessModeDropdownLoading = true;

        RequestManagementD365SService.getAllUsers().then(response => {
            response.data = $filter('orderBy')(response.data, 'FullName');
            vm.D365SUserDataSource = response.data;
            vm.usersDropdownLoading = false;
        });

        RequestManagementD365SService.getAllBusinessUnits().then(response => {
            vm.D365SBusinessUnitDataSource = response.data;
            vm.businessUnitsDropdownLoading = false;
        });

        RequestManagementD365SService.getAllTerritories().then(response => {
            vm.D365STerritoryDataSource = response.data;
            vm.territoryDropdownLoading = false;
        });

        RequestManagementD365SService.getAllSites().then(response => {
            vm.D365SSiteDataSource = response.data;
            vm.siteDropdownLoading = false;
        });

        RequestManagementD365SService.getAllIncomingMailMethods().then(response => {
            vm.D365SIncomingMailMethodsDataSource = response.data.lstOptions;
            vm.incomingMailDropdownLoading = false;
        });

        RequestManagementD365SService.getAllOutgoingMailMethods().then(response => {
            vm.D365SOutgoingMailMethodsDataSource = response.data.lstOptions;
            vm.outgoingMailDropdownLoading = false;
        });

        RequestManagementD365SService.getAllAccessModes().then(response => {
            response.data.lstOptions = $filter('orderBy')(response.data.lstOptions, 'Label');
            vm.D365SAccessModeDataSource = response.data.lstOptions;
            vm.accessModeDropdownLoading = false;
        });

    }

    //activate();

    function getLicenseTypes() {
        if ($scope.vm.User.D365SUser.AccessMode !== null) {
            vm.licenseTypeDropdownLoading = true;
            RequestManagementD365SService.getLicenseTypesByAccessModeId($scope.vm.User.D365SUser.AccessMode).then(response => {
                response.data.lstOptions = $filter('orderBy')(response.data.lstOptions, 'Label');
                vm.D365SLicenseTypeDataSource = response.data.lstOptions;
                vm.licenseTypeDropdownLoading = false;
                vm.licenseTypeDisabled = false;
            }).catch(response => {
                helperService.showErrorMessage(response.data);
                vm.licenseTypeDropdownLoading = false;
            });
        } else {
            vm.licenseTypeDisabled = true;
            vm.D365SLicenseTypeDataSource = null;
        }
    }

    function businessUnitDropdownChange() {
        $scope.vm.ListOfSelectedD365SRoles = [];
        $scope.vm.ListOfSelectedD365STeams = [];

        RequestManagementD365SService.getRolesByBusinessUnitId($scope.vm.User.D365SUser.BusinessUnitId).then(response => {
            response.data = response.data.filter(role => role.IsAssignable);
            $scope.vm.ListOfAvailableD365SRoles = response.data;
        });

        RequestManagementD365SService.getTeamsByBusinessUnitId($scope.vm.User.D365SUser.BusinessUnitId).then(response => {
            $scope.vm.ListOfAvailableD365STeams = response.data;
        });
    }

    function selectADUser(user) {
        $scope.vm.User.D365SUser = user;
    }
}