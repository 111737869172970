import sapAuthFieldGroupsController from "./sapAuthFieldGroups.controller";

/* sapAuthFieldGroups.component.js */

/**
 * @desc component for viewing and managing SAP Auth Field Groups
 * @example <sap-auth-field-groups></sap-auth-field-groups>
 */

const sapAuthFieldGroups = {
    bindings: {
        object: '<',
        isCustomRuleset: '<',
        onGroupUpdated: '&'
    },
    controller: sapAuthFieldGroupsController,
    controllerAs: 'safgvm',
    templateUrl: '/App/Components/MaintainBusinessProcess/SAPAuthFieldGroups/sapAuthFieldGroups.tmpl.html'
};

export default sapAuthFieldGroups;