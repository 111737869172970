import { customAutoCompleteFilter, customDatePickerFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($rootScope, $scope, $filter, SavedReportSelectorService, objectIdDataService, CustomReportServices, $http, $timeout, helperService, reportsService, reportsDataService) {

    const vm = this;

    vm.reportDetailsModel = [];

    vm.onApplyFilter = onApplyFilter;
    vm.onReadGrid = onReadGrid;
    vm.onClearFilters = onClearFilters;
    vm.populateCriteriaModel = populateCriteriaModel;
    vm.generateGridWithDynamicValues = generateGridWithDynamicValues;

    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    vm.filterCriteria.dateQuery = [];
    // ---

    vm.hideExportOptionsFromSubmit = false;

    vm.filterManagerGridOptions = CustomReportServices.setfilterManagerGridOptions();
    vm.filterDropdownOptions = helperService.getFilterDropdownOptions();

    $scope.$on('kendoWidgetCreated', function () {
        if (vm.grid) {
            vm.gridReady = true;
            runSavedReport();
        }
    });

    activate();

    async function activate() {
        await getObjectId();

        // --- Grid Initialization ---
        await setGridLayout();
        // ---

        // --- FOR ADVANCED FILTERING ---
        await getReportMetadata();
        vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);

        if ($rootScope.loadedSavedReport) {
            runSavedReport();
        }
        // ---

        // -- AUTO RUN REPORT ---
        if ($rootScope.UserPreferences.AutoRunDashboardReports === '1') {
            await generateGridWithDynamicValues();
        }
        // ---
    }

    async function getObjectId() {
        let objectId = await objectIdDataService.getObjectID();
        let securityObject = helperService.getSecurityObjectDetailsFromObjectId(objectId);

        vm.ObjectId = objectId;
        vm.reportName = securityObject.Name;
        vm.reportId = securityObject.ReportID;

        vm.filtersDataSource = CustomReportServices.setfiltersDataSource(vm.ObjectId);
    }

    async function setGridLayout() {
        const rowSettingsTemplate = `<row-settings-template params="dataItem" selected-product="null" on-delete="alvm.onReadGrid()"></row-settings-template>`;

        const ticketTemplate = `<span ng-if='dataItem.TicketProvider && dataItem.TicketProvider !== "Custom"'>{{ dataItem.TicketProvider }} &nbsp; <a href='{{dataItem.TicketUrl}}' target='_blank'>{{dataItem.TicketId}}</a></span>
                          <span ng-if='dataItem.TicketProvider && dataItem.TicketProvider === "Custom"'>{{ dataItem.TicketProvider }} &nbsp; <span>{{dataItem.TicketId}}</span></span>`;

        try {
            let savedLayoutColumns = await CustomReportServices.loadSavedDynamicGridLayout(vm)

            vm.auditLogGridOptions = helperService.setMainGridOptions(savedLayoutColumns, 600);
            vm.auditLogGridOptions.autoBind = false;
            await createGridDataSource();
        } catch {
            const auditLogGridColumns = [
                { title: "", template: rowSettingsTemplate, attributes: { "class": "no-padding overflow-visible" }, minResizableWidth: 22, width: 22 },
                { field: "TicketDisplayName", title: "Ticket", template: ticketTemplate, filterable: false, sortable: false },
                { field: "ObjectName", title: "Report Name", filterable: customAutoCompleteFilter },
                { field: "ChangeType", title: "Change Type", filterable: customAutoCompleteFilter },
                { field: "ChangeDate", title: "Change Date", template: "{{ dataItem.ChangeDate | date:'short'}}", filterable: customDatePickerFilter },
                { field: "BeforeObject", title: "Before", encoded: false, filterable: customAutoCompleteFilter },
                { field: "AfterObject", title: "After", encoded: false, filterable: customAutoCompleteFilter },
                { field: "UserName", title: "User", filterable: customAutoCompleteFilter },
                { field: "TicketId", title: "Ticket Id", hidden: true, filterable: customAutoCompleteFilter },
                { field: "AuditId", title: "Audit Id", hidden: true, filterable: customAutoCompleteFilter },
                { field: "TicketUrl", title: "Ticket Url", hidden: true, filterable: customAutoCompleteFilter },
                { field: "TicketProvider", title: "Ticket Provider", hidden: true, filterable: customAutoCompleteFilter }
            ];

            vm.auditLogGridOptions = helperService.setMainGridOptions(auditLogGridColumns, 600);
            vm.auditLogGridOptions.selectable = false;
            vm.auditLogGridOptions.autoBind = false;
            await createGridDataSource();
        }
    }

    function createGridDataSource() {
        vm.auditLogGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: {
                model: {
                    fields: {
                        ChangeDate: { type: "date" }
                    }
                }
            },
            transport: {
                read: async function (options) {
                    delete $rootScope.loadedSavedReport;

                    // if tabs are on, save report info for the tab
                    if ($rootScope.tabs.length > 0) {
                        reportsService.saveTabReportInfo(vm);
                    }

                    let data = {};

                    data = helperService.prepareSortsAndFilters(options);

                    // --- FOR ADVANCED FILTERING ---
                    if (vm.filterCriteria.query) {
                        data.Filters = data.Filters.concat(vm.filterCriteria.query);
                        data.DateFilters = vm.filterCriteria.dateQuery;
                    }
                    // ---

                    try {
                        let response = await $http({
                            method: 'POST',
                            url: apiUrl + 'api/core/auditlogs',
                            data: data,
                            params: {
                                startDate: vm.startDate,
                                endDate: vm.endDate
                            }
                        });
                        options.success(response.data);
                        vm.enableGrid = true;
                    } catch (err) {
                        options.error(err);
                    }
                }
            }
        });
    }


    function generateGridWithDynamicValues() {
        if (!$rootScope.loadedSavedReport) {
            vm.grid.dataSource._filter = null;
            vm.grid.dataSource._sort = null;
        }

        vm.hideExportOptionsFromSubmit = true;

        // Add back the sorting and filtering options if we are running the report for the first time
        if (!$rootScope.loadedSavedReport) {
            vm.grid.setOptions({
                filterable: {
                    mode: "row"
                },
                sortable: true
            });
        }

        vm.auditLogGridDataSource.read();

        $timeout(async _ => {
            await SavedReportSelectorService.queryAuditLogsGridWithOptions($rootScope.loadedSavedReport, vm.grid, true);
        }, 500);
    }

    async function runSavedReport() {
        if (vm.gridReady && $rootScope.loadedSavedReport && vm.filterCriteria.fields) {
            vm.filterCriteria.query = reportsService.getSavedReportFilters();
            vm.filterCriteria.needsUpdate = true;
            await generateGridWithSelectedValues();
        } else if (vm.gridReady && !$rootScope.loadedSavedReport && vm.filterCriteria.fields) {
            populateCriteriaModel();
        }
    }

    function populateCriteriaModel() {
        // if tabs are on, save report info for the tab
        if ($rootScope.tabs.length > 0) {
            reportsService.saveTabReportInfo(vm);
        }

        vm.reportDetailsModel = [];
    }

    function onApplyFilter(selectedFilter) {
        const currentOptions = vm.grid.getOptions();

        if (selectedFilter && selectedFilter.Filters && selectedFilter.Filters.length > 0) {
            currentOptions.dataSource.filter = helperService.convertFiltersForGrid({}, selectedFilter.Filters);
            try {
                vm.grid.dataSource._filter = currentOptions.dataSource.filter;
                vm.grid.dataSource.read();
            }
            catch (err) {
                vm.auditLogGridDataSource.filter({});
            }
        }
    }

    function onReadGrid() {
        vm.auditLogGridDataSource.read();
    }

    function onClearFilters() {
        vm.auditLogGridDataSource.filter({});
    }

    // --- FOR ADVANCED FILTERING ---
    async function getReportMetadata() {
        let response = await reportsDataService.getReportMetadata(vm.reportId);

        vm.reportMetadata = response.data._embedded.reportData;
        vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');

        return vm.reportMetadata;
    }

}
