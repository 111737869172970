import setupChangesController from "./setupChanges.controller";

/* setupChanges.component.js */


/**
 * @desc component for setup changes report
 * @example <setup-changes></setup-changes>
 */

const setupChanges = {
    controller: setupChangesController,
    controllerAs: 'scvm',
    templateUrl: '/App/Components/setupChanges/setupChanges.tmpl.html'
}

export default setupChanges;