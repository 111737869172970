import { standardGridPageSize, customDatePickerNotNullableFilter, customAutoCompleteFilter } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";

export default /*@ngInject*/ function ($rootScope, $scope, SavedReportSelectorService, dataService, objectIdDataService, CustomReportServices, $filter, $http, helperService, reportsDataService, reportsService) {

    const vm = this;

    vm.$onInit = onInit;

    vm.runSavedReport = runSavedReport;
    vm.productDropdownChange = productDropdownChange;
    vm.thirtyDaysAgo = thirtyDaysAgo;
    vm.openStartDate = openStartDate;
    vm.today = today;
    vm.openEndDate = openEndDate;
    vm.populateCriteriaModel = populateCriteriaModel;
    vm.generateGridWithSelectedValues = generateGridWithSelectedValues;
    vm.setColumns = setColumns;
    vm.setLayout = setLayout;
    vm.onApplyFilter = onApplyFilter;
    vm.onReadGrid = onReadGrid;
    vm.onClearFilters = onClearFilters;
    vm.setDates = setDates;
    vm.getDateFilters = getDateFilters;
    vm.getPeriodId = getPeriodId;

    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    // ---

    async function onInit() {
        vm.RelativeDateTypeDataSource = CustomReportServices.getRelativeDateTypes();
        vm.PeriodTypeDataSource = CustomReportServices.getPeriodTypes();
        vm.RelativeDateType = 2;
        vm.PeriodType = 0;

        vm.dateCriteria = [
            {
                "DataType": "DateTime",
                "FieldName": "Date",
                "IsNullable": false
            }
        ];

        vm.mainGridOptions = helperService.setMainGridOptions(null, 600);
        vm.mainGridOptions.autoBind = false;

        vm.startDateOptions = {
            dateDisabled: disabled,
            showWeeks: false,
            formatYear: 'yy',
            startingDay: 1
        };

        vm.startDatePopup = {
            opened: false
        };

        vm.endDateOptions = {
            showWeeks: false,
            formatYear: 'yy',
            maxDate: new Date(),
            startingDay: 1
        };

        vm.endDatePopup = {
            opened: false
        };

        await getObjectId();
        await getProducts();
        thirtyDaysAgo();
        setDates();
        today();
    }

    $scope.$on('kendoWidgetCreated', function () {
        if (vm.grid) {
            vm.gridReady = true;
            runSavedReport();
        }
    });

    async function getObjectId() {
        const objectId = await objectIdDataService.getObjectID();
        // Needed for report toolbar controller functions
        vm.ObjectId = objectId;
        vm.reportName = helperService.getSecurityObjectDetailsFromObjectId(vm.ObjectId).Name;
        vm.filtersDataSource = CustomReportServices.setfiltersDataSource(vm.ObjectId);
    }

    async function getProducts() {
        const response = await dataService.getProducts();
        response.data = response.data.filter(product => product.Type === "ORFC");
        vm.productsDataSource = response.data;
        vm.selectedProduct = response.data[0];
        vm.selectedProduct = SavedReportSelectorService.checkSavedReportForSingleProduct($rootScope.loadedSavedReport, response.data, vm.selectedProduct);
        vm.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType(vm.ObjectId, vm.selectedProduct.Type).ReportID;
        productDropdownChange();
    }

    async function runSavedReport() {
        if (vm.gridReady && $rootScope.loadedSavedReport && vm.productsDataSource && vm.filterCriteria.fields) {
            vm.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType(vm.ObjectId, vm.selectedProduct.Type).ReportID;
            await getReportMetadata();

            const dateFieldName = 'Date';
            // --- FOR ADVANCED FILTERING ---
            // Convert date filters to criteria
            if ($rootScope.loadedSavedReport && $rootScope.loadedSavedReport.ReportDefinition.DateFilters.length > 0) {
                const criteriaDateFilterIndex = $rootScope.loadedSavedReport.ReportDefinition.DateFilters.findIndex(filter => filter.FieldName === dateFieldName);
                if (criteriaDateFilterIndex > -1) {
                    loadDateFilterAsCriteria($rootScope.loadedSavedReport.ReportDefinition.DateFilters[criteriaDateFilterIndex]);
                    $rootScope.loadedSavedReport.ReportDefinition.DateFilters.splice(criteriaDateFilterIndex, 1);
                }
            } else if ($rootScope.loadedSavedReport && $rootScope.loadedSavedReport.ReportDefinition.Filters.length > 0) {
                const dateRangeFilters = $rootScope.loadedSavedReport.ReportDefinition.Filters.filter(filter => filter.FieldName === dateFieldName);
                if (dateRangeFilters.length > 0) {
                    $rootScope.loadedSavedReport.ReportDefinition.Filters = $rootScope.loadedSavedReport.ReportDefinition.Filters.filter(filter => filter.FieldName !== dateFieldName);
                    dateRangeFilters.forEach(filter => {
                        loadDateFilterAsCriteria(filter);
                    });
                }
            }

            vm.filterCriteria.query = reportsService.getSavedReportFilters();
            vm.filterCriteria.dateQuery = reportsService.getSavedReportDateFilters();
            vm.filterCriteria.needsUpdate = true;
            // ---
            generateGridWithSelectedValues();
        } else if (vm.gridReady && !$rootScope.loadedSavedReport && vm.productsDataSource && vm.filterCriteria.fields) {
            // We want the necessary report details available to be able to use the report toolbar without running the report
            populateCriteriaModel();
        }
    }

    function loadDateFilterAsCriteria(DateFilter) {
        if (DateFilter.Operator === 'GreaterThanOrEqual' || DateFilter.Operator === 'LessThanOrEqual') {
            if (!$rootScope.loadedSavedReport.ReportDefinition.Criteria[0]) {
                $rootScope.loadedSavedReport.ReportDefinition.Criteria = [{
                    "CriteriaType": "Date",
                    "CriteriaValue": DateFilter.FieldName,
                    Filters: []
                }];
                $rootScope.loadedSavedReport.ReportDefinition.Criteria[0].Filters.push(DateFilter);
            } else {
                $rootScope.loadedSavedReport.ReportDefinition.Criteria[0].Filters.push(DateFilter);
            }
        } else {
            $rootScope.loadedSavedReport.ReportDefinition.Criteria = [{
                "CriteriaType": "Date",
                "CriteriaValue": DateFilter.FieldName,
                Filters: []
            }];
            $rootScope.loadedSavedReport.ReportDefinition.Criteria[0].DateFilter = DateFilter;
        }
    }

    function setupFilters() {
        if (!vm.sortsAndFilters) {
            vm.sortsAndFilters = {};
        }
        if (!vm.sortsAndFilters.Filters) {
            vm.sortsAndFilters.Filters = [];
        }
        if (!vm.sortsAndFilters.DateFilter) {
            vm.sortsAndFilters.DateFilters = [];
        }

        const dateFilters = getDateFilters();

        if (dateFilters.Filters && dateFilters.Filters.length > 0) {
            vm.sortsAndFilters.Filters = vm.sortsAndFilters.Filters.filter(f => f.FieldName != dateFilters.Filters[0].FieldName);
            vm.sortsAndFilters.Filters = vm.sortsAndFilters.Filters.concat(dateFilters.Filters);
        }
        if (dateFilters.RelativeFilter) {
            vm.sortsAndFilters.Filters = vm.sortsAndFilters.Filters.filter(f => f.FieldName != dateFilters.RelativeFilter.FieldName);
            vm.sortsAndFilters.DateFilters.push(dateFilters.RelativeFilter);
        }
    }

    async function productDropdownChange() {
        setColumns();
        vm.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType(vm.ObjectId, vm.selectedProduct.Type).ReportID;
        await getReportMetadata();
        populateCriteriaModel();
        if ($rootScope.loadedSavedReport) {
            runSavedReport();
            loadSavedReportCriteria();
        }
    }

    vm.gridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        schema: angular.extend(halSchemaKendoGrid, {
            model: {
                fields: {
                    ModifiedDate: { type: "date" },
                    ChangeDate: { type: "date" },
                    TimeStamp: { type: "date" },
                    ModifiedOn: { type: "date" },
                    Date: { type: "date" }
                }
            }
        }),
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
            read: options => {
                delete $rootScope.loadedSavedReport;

                const data = {};

                vm.sortsAndFilters = helperService.prepareSortsAndFilters(options);
                setupFilters();

                data['QueryInput'] = vm.sortsAndFilters;

                // --- FOR ADVANCED FILTERING ---
                if (vm.filterCriteria.query) {
                    data['QueryInput'].Filters = data['QueryInput'].Filters.concat(vm.filterCriteria.query);
                }
                // ---

                // if tabs are on, save report info for the tab
                if ($rootScope.tabs.length > 0) {
                    reportsService.saveTabReportInfo(vm);
                }

                $http({
                    method: 'POST',
                    url: vm.urlString,
                    data: data,
                    params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                }).then(function (response) {
                    vm.enableGrid = true;
                    vm.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType(vm.ObjectId, vm.selectedProduct.Type).ReportID;
                    options.success(response.data);
                }, function errorCallback(response) {
                    helperService.showErrorMessage(response.data);
                    options.error();
                });
            }
        }
    });

    function loadSavedReportCriteria() {
        if ($rootScope.loadedSavedReport && $rootScope.loadedSavedReport.ReportDefinition.Criteria) {
            $rootScope.loadedSavedReport.ReportDefinition.Criteria.forEach(function (criteria) {
                if (criteria.DateFilter) {
                    switch (criteria.DateFilter.DateType) {
                        case 'Previous':
                            vm.RelativeDateType = 0;
                            vm.PeriodType = getPeriodId(criteria.DateFilter.Period);
                            break;

                        case 'ToDate':
                            vm.RelativeDateType = 1;
                            vm.PeriodType = getPeriodId(criteria.DateFilter.Period);
                            break;

                        case 'LastNumberOf':
                            vm.RelativeDateType = 2;
                            vm.PeriodType = getPeriodId(criteria.DateFilter.Period);
                            vm.LastNumberOf = criteria.DateFilter.Value;
                            break;

                    }
                }
                if (criteria.Filters.length > 0) {
                    criteria.Filters.forEach(function (filter) {
                        switch (filter.Operator) {
                            case 'GreaterThanOrEqual':
                                vm.RelativeDateType = 4;
                                vm.startDate = new Date(filter.Value);
                                break;

                            case 'LessThanOrEqual':
                                vm.RelativeDateType = 4;
                                vm.endDate = new Date(filter.Value);
                                break;
                        }
                    });
                }
            });

        }

        if ($rootScope.loadedSavedReport && $rootScope.loadedSavedReport.ReportDefinition.DateFilters.length > 0) {
            const dateFilter = $rootScope.loadedSavedReport.ReportDefinition.DateFilters[0];
            switch (dateFilter.DateType) {
                case 'Previous':
                    vm.RelativeDateType = 0;
                    vm.PeriodType = getPeriodId(dateFilter.Period);
                    break;

                case 'ToDate':
                    vm.RelativeDateType = 1;
                    vm.PeriodType = getPeriodId(dateFilter.Period);
                    break;

                case 'LastNumberOf':
                    vm.RelativeDateType = 2;
                    vm.PeriodType = getPeriodId(dateFilter.Period);
                    vm.LastNumberOf = dateFilter.Value;
                    break;

            }
        }
    }

    //Start Date picker settings
    function thirtyDaysAgo() {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() - 30);
        vm.startDate = newDate;
        vm.startDate = SavedReportSelectorService.checkSavedReportForDate($rootScope.loadedSavedReport, "StartDate", vm.startDate);
    }

    function disabled(data) {
        const date = data.date,
            mode = data.mode;
        return mode === 'day' && date > vm.endDate;
    }

    function openStartDate() {
        vm.startDatePopup.opened = true;
    }

    //End Date picker settings
    function today() {
        vm.endDate = new Date();
        vm.endDate = SavedReportSelectorService.checkSavedReportForDate($rootScope.loadedSavedReport, "EndDate", vm.endDate);
    }

    function openEndDate() {
        vm.endDatePopup.opened = true;
    }

    function populateCriteriaModel() {
        // do not continue if the proper criteria are not loaded
        if (!vm.selectedProduct) {
            return;
        }
        const criteria = [];

        vm.product = vm.selectedProduct.ID;
        vm.productType = vm.selectedProduct.Type;

        criteria.push(CustomReportServices.CreateCriteria("ProductId", 'Product', vm.selectedProduct.Name, vm.selectedProduct.ID));

        setupFilters();

        vm.urlString = apiUrl + 'api/audittrail/' + vm.productType + '/datachanges';

        vm.reportDetailsModel = criteria;

        // if tabs are on, save report info for the tab
        if ($rootScope.tabs.length > 0) {
            reportsService.saveTabReportInfo(vm);
        }
    }

    function generateGridWithSelectedValues() {

        if (!$rootScope.loadedSavedReport) {
            vm.grid.dataSource._filter = null;
            vm.grid.dataSource._sort = null;
        }

        if (vm.startDate <= vm.endDate) {
            populateCriteriaModel();
            vm.hideExportOptionsFromSubmit = true;

            // Add back the sorting and filtering options if we are running the report for the first time
            if (!$rootScope.loadedSavedReport) {
                vm.grid.setOptions({
                    filterable: {
                        mode: "row"
                    },
                    sortable: true
                });
            }

            SavedReportSelectorService.queryGridWithOptions($rootScope.loadedSavedReport, vm.grid, true);
        } else {
            helperService.showErrorMessage("Start Date must be before or equal to End Date");
        }
    }

    function setColumns() {
        if (vm.selectedProduct) {

            const rowSettingsTemplate = `<row-settings-template params="dataItem" selected-product="ocdcvm.selectedProduct" on-delete="ocdcvm.onReadGrid()"></row-settings-template>`;

            const ticketTemplate = `
            <span ng-if='dataItem.TicketProvider && dataItem.TicketProvider !== "Custom"'>
                {{ dataItem.TicketProvider }} &nbsp;
                <a href='{{dataItem.TicketUrl}}' target='_blank'>{{dataItem.TicketId}}</a>
            </span>
            <span ng-if='dataItem.TicketProvider && dataItem.TicketProvider === "Custom"'>
                {{ dataItem.TicketProvider }} &nbsp;
                <span>{{dataItem.TicketId}}</span>
            </span>`;

            vm.mainGridColumns = [
                { title: "", template: rowSettingsTemplate, attributes: { "class": "no-padding overflow-visible" }, minResizableWidth: 22, width: 22 },
                { field: "TicketDisplayName", title: "Ticket", template: ticketTemplate, filterable: false, sortable: false },
                { field: "Date", title: "Date", template: "{{ dataItem.Date | date:'short'}}", filterable: customDatePickerNotNullableFilter },
                { field: "Product", title: "Product", filterable: customAutoCompleteFilter },
                { field: "BusinessObject", title: "Business Object", filterable: customAutoCompleteFilter },
                { field: "Report", title: "Report", filterable: customAutoCompleteFilter },
                { field: "Description", title: "Description", filterable: customAutoCompleteFilter },
                { field: "EventType", title: "Event Type", filterable: customAutoCompleteFilter },
                { field: "Attribute", title: "Attribute", filterable: customAutoCompleteFilter },
                { field: "OldValue", title: "Old Value", filterable: customAutoCompleteFilter },
                { field: "NewValue", title: "New Value", filterable: customAutoCompleteFilter },
                { field: "UserId", title: "User ID", filterable: customAutoCompleteFilter },
                { field: "UserName", title: "User Name", filterable: customAutoCompleteFilter },
                { field: "Impersonator", title: "Impersonator", filterable: customAutoCompleteFilter },
                { field: "DataSource", title: "Data Source", filterable: customAutoCompleteFilter }
            ];
        }

        // Don't allow filtering and sorting yet because they will force the report to run
        if (!$rootScope.loadedSavedReport) {
            vm.mainGridOptions.sortable = false;

            vm.mainGridOptions.filterable = false;
        }



        setLayout();
    }

    function setLayout() {

        let productId;
        if (vm.selectedProduct) {
            productId = vm.selectedProduct.ID;
        }

        if (!$rootScope.loadedSavedReport) {
            CustomReportServices.loadSavedGridLayout(vm, productId);
        }
    }

    function onApplyFilter(selectedFilter) {
        const currentOptions = vm.grid.getOptions();

        if (selectedFilter && selectedFilter.Filters && selectedFilter.Filters.length > 0) {
            currentOptions.dataSource.filter = helperService.convertFiltersForGrid(_, selectedFilter.Filters);
            try {
                vm.grid.dataSource._filter = currentOptions.dataSource.filter;
                vm.grid.dataSource.read();
            }
            catch (err) {
                vm.gridDataSource.filter({});
            }
        }
    }

    function onReadGrid() {
        vm.gridDataSource.read();
    }

    function onClearFilters() {
        vm.gridDataSource.filter({});
    }

    //Date picker settings
    function setDates() {
        const newDate = new Date();
        newDate.setDate(newDate.getDate() - $rootScope.UserPreferences.DefaultDaysForAuditTrailReports);
        vm.LastNumberOf = $rootScope.UserPreferences.DefaultDaysForAuditTrailReports;

        vm.startDate = newDate;
        vm.endDate = new Date();
    }

    function getDateFilters() {
        const dateCriteria = vm.dateCriteria[0];
        let returnObject = {};
        dateCriteria.FieldName = "Date";

        if (dateCriteria) {
            const dateTimeFieldName = dateCriteria.FieldName;
            const criteria = { CriteriaType: 'Date', CriteriaValue: dateTimeFieldName, Filters: [], DateFilter: null };
            returnObject = {
                Criteria: {},
                Filters: [],
                RelativeFilter: null
            };

            const startDate = helperService.setDateToBeginningOfDay(vm.startDate);
            const endDate = helperService.setDateToEndOfDay(vm.endDate);

            if (vm.RelativeDateType === 4) {
                const startDateRow = {
                    "FieldName": dateTimeFieldName,
                    "Operator": 'GreaterThanOrEqual',
                    "Value": startDate
                };
                const endDateRow = {
                    "FieldName": dateTimeFieldName,
                    "Operator": 'LessThanOrEqual',
                    "Value": endDate
                };

                returnObject.Filters.push(startDateRow);
                returnObject.Filters.push(endDateRow);

                criteria.Filters.push(startDateRow);
                criteria.Filters.push(endDateRow);

                returnObject.Criteria = criteria;

            }
            if (vm.RelativeDateType !== 4) {
                const relativeDate = {
                    "DateType": vm.RelativeDateType,
                    "FieldName": dateTimeFieldName,
                    "Period": vm.PeriodType,
                    "Value": vm.RelativeDateType === 2 ? vm.LastNumberOf : null
                };

                returnObject.RelativeFilter = relativeDate;
                criteria.DateFilter = relativeDate;
                returnObject.Criteria = criteria;
            }
        }

        return returnObject;
    }

    function getPeriodId(name) {
        if (name) {
            return vm.PeriodTypeDataSource.find(function (type) {
                return type.Name === name;
            }).Id;
        }
    }

    // --- FOR ADVANCED FILTERING ---
    function getReportMetadata() {
        return reportsDataService.getDataChangesReportMetadata(vm.selectedProduct.Type.toLowerCase())
            .then(data => {
                vm.reportMetadata = data.data._embedded.reportData;
                vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');
                vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
                return vm.reportMetadata;
            });
    }
    // ---

}