import Packery from 'packery';
import Draggabilly from 'draggabilly';

export function onEnter() {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if (event.which === 13) {
                scope.$apply(function () {
                    scope.$eval(attrs.onEnter, { 'event': event });
                });
                event.preventDefault();
            }
        });
    };
}

export function complexPassword() {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$parsers.unshift(function (password) {
                var hasUpperCase = /[A-Z]/.test(password);
                var hasLowerCase = /[a-z]/.test(password);
                var hasNumbers = /\d/.test(password);
                var hasNonalphas = /\W/.test(password);
                var characterGroupCount = hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas;

                if (password.length === 0) {
                    ctrl.$setValidity('complexity', true);
                    return password;
                } else {
                    if (password.length >= 10 && characterGroupCount >= 3) {
                        ctrl.$setValidity('complexity', true);
                        return password;
                    }
                    else {
                        ctrl.$setValidity('complexity', false);
                        return password;
                    }
                }

            });
        }
    };
}

export function pwdReqCapitalLetter() {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$parsers.unshift(function (password) {
                let requireCapitalLetter = scope.$eval(attrs.pwdReqCapitalLetter);
                if (requireCapitalLetter) {
                    if (/[A-Z]/.test(password)) {
                        ctrl.$setValidity('passwordRequireCapitalLetter', true);
                        return password;
                    } else {
                        ctrl.$setValidity('passwordRequireCapitalLetter', false);
                        return password;
                    }
                } else {
                    return password;
                }
            });
        }
    };
}

export function pwdReqNumber() {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$parsers.unshift(function (password) {
                let requireNumber = scope.$eval(attrs.pwdReqNumber);
                if (requireNumber) {
                    if (/[0-9]/.test(password)) {
                        ctrl.$setValidity('passwordRequireNumber', true);
                        return password;
                    } else {
                        ctrl.$setValidity('passwordRequireNumber', false);
                        return password;
                    }
                } else {
                    return password;
                }
            });
        }
    };
}

export function pwdReqSymbol() {
    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$parsers.unshift(function (password) {
                let symbols = scope.$eval(attrs.pwdReqSymbol);
                if (symbols && symbols.length > 0) {
                    symbols = '[' + symbols + ']';
                    let symbolRegex = new RegExp(symbols, 'g');
                    let hasSymbol = symbolRegex.test(password);
                    if (hasSymbol) {
                        ctrl.$setValidity('passwordRequireSymbol', true);
                        return password;
                    } else {
                        ctrl.$setValidity('passwordRequireSymbol', false);
                        return password;
                    }
                } else {
                    return password;
                }
            });
        }
    };
}

export function errSrc() {
    return {
        link: function (scope, element, attrs) {
            element.bind('error', function () {
                if (attrs.src != attrs.errSrc) {
                    attrs.$set('src', attrs.errSrc);
                }
            });
        }
    };
}

export function pwCheck() {
    return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ctrl) {
            var firstPassword = '#' + attrs.pwCheck;
            elem.add(firstPassword).on('keyup', function () {
                scope.$apply(function () {
                    // console.info(elem.val() === $(firstPassword).val());
                    ctrl.$setValidity('pwmatch', elem.val() === $(firstPassword).val());
                });
            });
        }
    };
}

export function setStyle() {
    return function (scope, element, attrs) {
        element.parent().css('backgroundColor', '#' + attrs.setStyle);
        element.parent().css('color', '#333');

    };
}

export function numbersOnly() {
    return {
        require: 'ngModel',
        link: function (scope, element, attr, ngModelCtrl) {
            function fromUser(text) {
                if (text) {
                    var transformedInput = text.replace(/[^0-9]/g, '');

                    if (transformedInput !== text) {
                        ngModelCtrl.$setViewValue(transformedInput);
                        ngModelCtrl.$render();
                    }
                    return transformedInput;
                }
                return undefined;
            }
            ngModelCtrl.$parsers.push(fromUser);
        }
    };
}

export /*@ngInject*/ function globalEvents($rootScope) {
    return function (scope, element, attrs) {
        element.bind('click', function (e) {

            if (angular.element('#right-sidebar').hasClass("ng-hide") == false) {
                $rootScope.rightSidebar = false;
            }

            if (angular.element('#right-sidebar-notifications').hasClass("ng-hide") == false) {
                $rootScope.rightNotificationsSidebar = false;
            }

            if (angular.element('#dashboard-card-list').hasClass("ng-hide") == false) {
                $rootScope.showCardList = false;
            }

            $rootScope.$evalAsync();

        });
    };
}

export function stopProp() {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            if (attr && attr.stopProp) {
                element.bind(attr.stopProp, function (e) {
                    e.stopPropagation();
                });
            }
        }
    };
}

export function showTemp() {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            if (attr && attr.stopProp) {
                element.bind(attr.stopProp, function (e) {
                    e.stopPropagation();
                });
            }
        }
    };
}

export /*@ngInject*/ function focusMe($timeout, $parse) {
    return {
        link: function (scope, element, attrs) {
            var model = $parse(attrs.focusMe);
            scope.$watch(model, function (value) {
                if (value === true) {
                    $timeout(function () {
                        element[0].focus();
                    });
                }
            });
            element.bind('blur', function () {
                if (model != null && model.assign != null) {
                    scope.$apply(model.assign(scope, false));
                }
            });
        }
    };
}

export function inlineSaver() {
    return {
        restrict: 'AE',
        scope: {
            model: '=ngModel',
            customtext: '@successtext',
            customcolor: '@customcolor'
        },
        template: [
            '<span ng-if="model.loadingValue || model.savedValue" class="btn saved-message">',
            '<span ng-if="model.savedValue" class="fadeout">{{customtext}} <span class="icons8-checked"></span></span>',
            '<span ng-if="model.loadingValue && customcolor" class="sm-spinner-contain"><span us-spinner spinner-theme="{{customcolor}}"></span></span>',
            '<span ng-if="model.loadingValue && !customcolor" class="sm-spinner-contain"><span us-spinner spinner-theme="smallDarkGray"></span></span>',
            '</span>'
        ].join('')
    };
}

export /*@ngInject*/ function component($compile, $parse) {
    return {
        restrict: 'E',
        scope: {
            name: '@name'
        },
        link: function (scope, element) {
            const name = scope.name;
            const args = $parse(element.attr("args"))(scope);
            const replace = $parse(element.attr("replace"))(scope);
            let argsStr = " ";
            angular.forEach(args, function (value, key, obj) {
                argsStr += key + '="' + value + '" ';
            });
            const elem = "<" + name + argsStr + "></" + name + ">";
            const component = $compile(elem)(scope)[0];
            if (replace) {
                element.replaceWith(component);
            } else {
                element.append(component);
            }
        }
    };
}

export /*@ngInject*/ function packery($rootScope, $timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            //console.log("link called on", element[0]);

            function broadcastCardChange() {
                var itemElems = $rootScope.packery.getItemElements();
                var cardPositionArray = [];
                $(itemElems).each(function (i, itemElem) {
                    cardPositionArray.push({ cardId: itemElem.id, cardIndex: i })
                });
                $rootScope.$broadcast('packery-card-changed-position', cardPositionArray);
            }

            if ($rootScope.packery === undefined || $rootScope.packery === null) {
                scope.element = element;
                $rootScope.packery = new Packery(element[0].parentElement, {
                    isResizeBound: true,
                    itemSelector: '.item',
                    columnWidth: 250,
                    gutter: 10
                });
                $rootScope.packery.bindResize();
                var draggable1 = new Draggabilly(element[0], {
                    handle: '.handle'
                });
                $rootScope.packery.bindDraggabillyEvents(draggable1);

                draggable1.on('dragEnd', function (instance, event, pointer) {
                    broadcastCardChange();
                    $timeout(function () {
                        $rootScope.packery.layout();
                    }, 100);
                });

                var orderItems = function () {
                    //var itemElems = $rootScope.packery.getItemElements();
                    //$(itemElems).each(function (i, itemElem) {
                    //    $(itemElem).text(i + 1);
                    //});
                };

                $rootScope.packery.on('layoutComplete', orderItems);
                $rootScope.packery.on('dragItemPositioned', orderItems);

            } else {
                var draggable2 = new Draggabilly(element[0], {
                    handle: '.handle'
                });
                $rootScope.packery.bindDraggabillyEvents(draggable2);

                draggable2.on('dragEnd', function (instance, event, pointer) {
                    broadcastCardChange();
                    $timeout(function () {
                        $rootScope.packery.layout();
                    }, 100);
                });

            }
            $timeout(function () {
                $rootScope.packery.reloadItems();
                $rootScope.packery.layout();
            }, 50);
        }
    };

}

