import controller from "./schedules.controller";

/* schedules.component.js */

/**
 * @desc component for report schedules
 * @example <schedules></schedules>
 */

const schedules = {
    controller: controller,
    controllerAs: 'svm',
    templateUrl: '/App/Components/Schedules/schedules.tmpl.html'
};

export default schedules;