import npsQuestionModalController from "../Controllers/npsQuestionModal.controller";

export default /*@ngInject*/ function ($rootScope, Hub, signalRCommonService, archiveToastr, ExportReportService, $location, $window, $route, $uibModal) {
    createHub();

    async function createHub() {
        var hub = new Hub('userHub', {

            listeners: {
                'send': function (response) {
                    var notifyObject = {
                        correlationId: response.CorrelationId,
                        type: response.Type,
                        message: response.Message,
                        title: response.Title,
                        status: response.Status,
                        response: response,
                        options: {}
                    };
                    if (response.Status === 'Success') {
                        switch (response.Type) {
                            case 'ExportReport':
                            case 'ExportRequest':
                            case 'ExportTaskRecording':
                            case 'ExportSimulation':
                            case 'ExportSupervisorAssignments':
                            case 'ExportCertificationReview':
                            case 'ExportDataChangesForUpdate':
                            case 'ExportAllChangesForUpdate':
                            case 'ExportRejectedCertificationReview':
                            case 'ImportSupervisorAssignments':
                            case 'ExportNsSecurityModel':
                            case 'ExportUserMappings':
                            case 'ImportUserMappings':
                            case 'AutoMapCrossPlatformUsers':
                                notifyObject.options = {
                                    allowHtml: true,
                                    onTap: function (e) {
                                        ExportReportService.downloadReportExportFromNotification(response);
                                    }
                                };
                                notifyObject.message = `${response.Message} <br/> <a>Click to Download</a>`;
                                break;
                            case 'RiskAnalysis':
                                if (response.ProductType !== 'SAP') {
                                    notifyObject.options = {
                                        allowHtml: true,
                                        onTap: function (e) {
                                            $location.path('/SecurityDesigner/Place/Dynamics365forFinanceandOperationsSecurityModels').search('');
                                        }
                                    };
                                    notifyObject.message = `${response.Message} <br/> <a>Click for Details</a>`;
                                }
                                break;
                            case 'ImportReviewAssignments':
                                notifyObject.options = {
                                    allowHtml: true,
                                    onTap: function (e) {
                                        if ($location.path() === '/AccessCertifications/Setup/ReviewSchedules') {
                                            $route.reload();
                                        } else {
                                            $location.path('/AccessCertifications/Setup/ReviewSchedules');
                                        }
                                    }
                                };
                                notifyObject.message = `${response.Message} Review Schedule data has been updated. <br/> <a>Click to view the latest Review Schedules Data</a>`;
                                break;
                            case 'DelegateReviewItems':
                                notifyObject.options = {
                                    allowHtml: true
                                };
                                notifyObject.message = `${response.Message} Review items have been updated. <br/> See My Reviews or All Reviews to view the latest Review Data`;
                                break;
                            case 'RequestReview':
                                notifyObject.options = {
                                    allowHtml: true,
                                    onTap: function (e) {
                                        // we need to check to see if user is still viewing the request details and if so do a reload instead of a page navigate
                                        const locationPath = $location.path(), locationSearchParams = $location.search();
                                        if (locationPath.includes(`/IdentityManager/RequestDetails`) && locationSearchParams.RequestId == response.RequestId && locationSearchParams.Version === response.RequestVersion) {
                                            $route.reload();
                                        } else {
                                            $location.path('/IdentityManager/RequestDetails').search({ RequestId: response.RequestId, Version: response.RequestVersion });
                                        }
                                    }
                                };
                                notifyObject.message = `${response.Message} Request Details have been updated. <br/> <a>Click to view the latest Request Details</a>`;
                                break;
                            default:
                                break;
                        }
                        archiveToastr.notifyArchive(notifyObject);
                        $rootScope.$broadcast(response.Type);
                    } else if (response.Status === 'Error') {
                        switch (response.Type) {
                            case 'ImportSupervisorAssignments':
                            case 'ImportUserMappings':
                            case 'ImportAuditTrailUpdate':
                                if (response.Message.includes("Check log")) {
                                    notifyObject.options = {
                                        allowHtml: true,
                                        onTap: function (e) {
                                            $location.path('/Setup/Report/JobLogs/2205').search('');
                                        }
                                    };
                                    notifyObject.message = `${response.Message} <br/> <a>Click for Details</a>`;
                                } else {
                                    notifyObject.options = {
                                        allowHtml: true,
                                        onTap: function (e) {
                                            ExportReportService.downloadReportExportFromNotification(response);
                                        }
                                    };
                                    notifyObject.message = `${response.Message} <br/> <a>Click to Download</a>`;
                                }
                                break;
                            case 'ExportNsSecurityModel':
                                notifyObject.options = {
                                    allowHtml: true,
                                    onTap: function (e) {
                                        $location.path('/Downloads').search('');
                                    }
                                };
                                notifyObject.message = `${response.Message} <br/> <a>Click for Details</a>`;
                                break;
                            default:
                                break;
                        }
                        archiveToastr.notifyArchive(notifyObject);
                        $rootScope.$broadcast(response.Type);
                    } else if (response.Status === 'Warning') {
                        switch (response.Type) {
                            case 'ImportRiskRuleset':
                                notifyObject.options = {
                                    allowHtml: true,
                                    onTap: function (e) {
                                        ExportReportService.downloadReportExportFromNotification(response);
                                    }
                                };
                                notifyObject.message = `${response.Message} <br/> <a>Click to Download</a>`;
                                break;
                        }
                        archiveToastr.notifyArchive(notifyObject);
                        $rootScope.$broadcast(response.Type);
                    } else if (response.ReleaseNotesUrl) {
                        notifyObject.options = {
                            allowHtml: true,
                            onTap: function (e) {
                                $window.open(response.ReleaseNotesUrl);
                            }
                        }
                        archiveToastr.notifyArchive(notifyObject);
                    } else if (response.Type === 'NpsQuestion') {
                        let openModal = $uibModal.open({
                            templateUrl: '/App/Shared/Partials/npsQuestionModal.tmpl.html',
                            controller: npsQuestionModalController,
                            controllerAs: 'nqmvm',
                            backdrop: 'static',
                            resolve: {
                                response: () => response
                            }
                        });
                    } else {
                        archiveToastr.notifyArchive(notifyObject);
                    }

                    $rootScope.$evalAsync();
                }
            },
            queryParams: await signalRCommonService.populateQueryParams(),
            rootPath: apiUrl + 'signalr',
            useSharedConnection: false,
            stateChanged: function (state) {
                signalRCommonService.stateChanged(state, hub, 'User');
            },
            errorHandler: function (error) {
                console.log(error.message, 'Error');
            }
        });

    }

    return {};
}