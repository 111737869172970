import azureADUserLookupSearchController from "./azureADUserLookupSearch.controller";

export default /*@ngInject*/ function ($scope, $uibModal) {
    const vm = this;

    vm.onClick = onClick;

    async function onClick() {
        let modal = $uibModal.open({
            templateUrl: '/App/Components/RequestManagement/AzureADUserLookup/azureADUserLookupSearch.html',
            controller: azureADUserLookupSearchController,
            controllerAs: 'aadulsvm',
            backdrop: 'static',
            size: 'lg',
            scope: $scope,
            resolve: {
                userObject: () => vm.userObject // bound to parent controller
            }
        });

        try {
            const user = await modal.result;
            vm.onUserUpdated({ user });
        }
        catch { return; }
    }
}