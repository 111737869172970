export const configurationOptions = {
    productOptions: {
        default: [
            { Name: "RiskManageType", Encrypted: false },
            { Name: "ARM_StandardMitigations", Encrypted: false },
            { Name: "ApplyDefaultMitigationsAfterImport", Encrypted: false },
            { Name: "ResetDefaultMitigationsBeforeApply", Encrypted: false },
            { Name: "DefaultMitigationsOverwriteCurrent", Encrypted: false },
            { Name: "ResetAutoMitigationsBeforeApply", Encrypted: false },
            { Name: "AutoExcludeDisabledUsersAfterImport", Encrypted: false }
        ],
        PS: [{ Name: "PS_ApplyAutoMitigationsAfterImport", Encrypted: false }],
        ORFC: [{ Name: "ORFC_ApplyAutoMitigationsAfterImport", Encrypted: false }],
        OR: [
            { Name: "OR_ApplyAutoMitigationsAfterImport", Encrypted: false },
            { Name: "OR_AutoMitigationsOverwrite", Encrypted: false }
        ],
        SAP: []
    },
    systemConfigurations: [
        { Name: "CreateFPUsersForAutoGenerateSupervisorAssignments", Encrypted: false },
        { Name: "AutoGenerateAllSupervisors", Encrypted: false },
        { Name: "IdentityProviderIdForAutoGenerateSupervisorAssignments", Encrypted: false },
        { Name: "AutoGenerateSupervisorAssignmentsAfterImport", Encrypted: false },
        { Name: "SAP_BuildRoleTemplatesForCompositeRoles", Encrypted: false },
        { Name: "HcmUserFieldForNewFastpathUserLoginEmail", Encrypted: false },
        { Name: "HcmUserFieldForNewFastpathUserEmail", Encrypted: false },
        { Name: "AutogeneratedUserRoles", Encrypted: false },
        { Name: "AutoMapCrossPlatformUsersAfterImport", Encrypted: false },
        { Name: "AutoMapFastpathToSystemUsers", Encrypted: false }
    ],
    companyInfo: [
        { Name: "CompanyName", Encrypted: false },
        { Name: "SystemTimeZone", Encrypted: false },
        { Name: "PrimarySystem", Encrypted: false },
        { Name: "EmailNewFpAdUsers", Encrypted: false }
    ],
    configNames: [
        'Cert_SnapshotTime',
        'Cert_DaysToComplete',
        'Cert_AllowSelectAll',
        'Cert_IncludeDisabledUsers',
        'Cert_BusinessProcessObjects',
        'Cert_RequireNotesOnRejectedItems',
        'Cert_ReviewAllRisks',
        'Cert_SelfReview',
        'Cert_UserDisplayField',
        'Cert_EnableNewReviewReminderEmails',
        'Cert_EnableBeforeDueDayReminderEmails',
        'Cert_DaysBeforeDueDate',
        'Cert_EnableDueDayReminderEmails',
        'Cert_EnableOverdueReminderEmails',
        'Cert_SendOverdueReminderEmailsEveryday',
        'Cert_EnableEmailsForCompletedIndividualReviews',
        'Cert_RoleContentBusinessProcess',
        'Cert_RoleContentBusinessProcessObjects',
        'Cert_RoleContentRisk',
        'Cert_ReviewAllRoleContentRisks',
        'Cert_RoleContentRoleAccess',
        'Cert_ReviewRoleContentAccessTypesAndLevels'
    ],
    accessCertifications: [
        { Name: "Cert_SnapshotTime", Encrypted: false },
        { Name: "Cert_DaysToComplete", Encrypted: false },
        { Name: "Cert_AllowSelectAll", Encrypted: false },
        { Name: "Cert_IncludeDisabledUsers", Encrypted: false},
        { Name: "Cert_BusinessProcessObjects", Encrypted: false },
        { Name: "Cert_RequireNotesOnRejectedItems", Encrypted: false },
        { Name: "Cert_ReviewAllRisks", Encrypted: false },
        { Name: "Cert_UserDisplayField", Encrypted: false },
        { Name: "Cert_SelfReview", Encrypted: false },
        { Name: "Cert_EnableNewReviewReminderEmails", Encrypted: false },
        { Name: "Cert_EnableBeforeDueDayReminderEmails", Encrypted: false },
        { Name: "Cert_DaysBeforeDueDate", Encrypted: false },
        { Name: "Cert_EnableOverdueReminderEmails", Encrypted: false },
        { Name: "Cert_EnableDueDayReminderEmails", Encrypted: false },
        { Name: "Cert_SendOverdueReminderEmailsEveryday", Encrypted: false },
        { Name: "Cert_EnableEmailsForCompletedIndividualReviews", Encrypted: false },
        { Name: "Cert_RoleContentBusinessProcess", Encrypted: false },
        { Name: "Cert_RoleContentBusinessProcessObjects", Encrypted: false },
        { Name: "Cert_RoleContentRisk", Encrypted: false },
        { Name: "Cert_ReviewAllRoleContentRisks", Encrypted: false },
        { Name: "Cert_RoleContentRoleAccess", Encrypted: false },
        { Name: "Cert_ReviewRoleContentAccessTypesAndLevels", Encrypted: false },
    ]
};