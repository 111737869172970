import { standardGridPageSize, customAutoCompleteFilter, customDatePickerFilter } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";

export default /*@ngInject*/ function ($scope, $rootScope, SavedReportSelectorService, BuildUserAccessPostBody, $filter, dataService, objectIdDataService, CustomReportServices, $http, $location, helperService, reportsDataService, reportsService) {

    $scope.$on('kendoWidgetCreated', function () {
        if ($scope.grid) {
            $scope.gridReady = true;
            $scope.runSavedReport();
        }
    });

    var vm = $scope.vm = {};

    $scope.helperService = helperService;

    $scope.CustomReportServices = CustomReportServices;

    vm.selectedCompanies = [];
    vm.selectedProducts = [];
    vm.objectSearchField = $rootScope.UserPreferences.ObjectSearchField;

    vm.updateUserDataSource = updateUserDataSource;

    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    // ---

    $scope.hasDetailedVersion = true;

    objectIdDataService.getObjectID().then(function (objectId) {
        $scope.ObjectId = objectId;

        $scope.filtersDataSource = CustomReportServices.setfiltersDataSource($scope.ObjectId);

        var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;
        $scope.reportId = securityObject.ReportID;


        dataService.getProducts().then(function (response) {
            response.data = response.data.filter(p => p.UserHasAccess === true);
            vm.productsDataSource = response.data;
            let defaultProduct = vm.productsDataSource.find(obj => obj.Name === $rootScope.UserPreferences.PreferredProduct);
            let defaultProductSelected = vm.productsDataSource.indexOf(defaultProduct);
            if ($scope.isDetailed) {
                if ($rootScope.UserPreferences.PreferredProduct !== null) {
                    vm.selectedProducts = [vm.productsDataSource[defaultProductSelected]];
                } else {
                    vm.selectedProducts = [vm.productsDataSource[0]];
                }
            } else {
                if ($rootScope.UserPreferences.PreferredProduct !== null) {
                    vm.selectedProducts = [vm.productsDataSource[defaultProductSelected]];
                } else {
                    vm.selectedProducts = angular.copy(vm.productsDataSource);
                }
            }

            vm.selectedProducts = SavedReportSelectorService.checkSavedReportForProducts($rootScope.loadedSavedReport, response.data, vm.selectedProducts);

            if ($scope.isDetailed) {
                if (vm.selectedProducts[0].IsCustom) {
                    $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, "customProduct").ReportID;
                } else {
                    $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProducts[0].Type).ReportID;
                }
            } else {
                $scope.reportId = securityObject.ReportID;
            }

            getObjectTypes();
            vm.setColumns();
            $scope.runSavedReport();

            // --- FOR ADVANCED FILTERING ---
            getReportMetadata().then(_ => {
                if ($rootScope.loadedSavedReport) {
                    $scope.runSavedReport();
                }
            });
            // ---
        });
    });

    if ($location.path().has('Detailed')) {
        $scope.isDetailed = true;
    } else {
        $scope.isDetailed = false;
    }

    $scope.runSavedReport = function () {
        if ($scope.gridReady && $rootScope.loadedSavedReport && vm.productsDataSource && vm.companyDataSource && vm.userDataSource && vm.objectTypeDataSource && vm.objectDataSource && vm.filterCriteria.fields) {
            // --- FOR ADVANCED FILTERING ---
            vm.filterCriteria.query = reportsService.getSavedReportFilters();
            vm.filterCriteria.needsUpdate = true;
            // ---

            $scope.generateGridWithSelectedValues();
        } else if ($scope.gridReady && !$rootScope.loadedSavedReport && vm.productsDataSource && vm.companyDataSource && vm.userDataSource && vm.objectTypeDataSource && vm.objectDataSource && vm.filterCriteria.fields) {
            // We want the necessary report details available to be able to use the report toolbar without running the report
            $scope.populateCriteriaModel();
        }
    };

    $scope.changeView = function () {
        if ($scope.isDetailed === true) {
            $location.path('/AccessControl/Place/UserAccess').search('');
        } else {
            $location.path('/AccessControl/Place/UserAccessDetailed').search('');
        }
    };

    $scope.mainGridOptions = helperService.setMainGridOptions(null, 600);
    $scope.mainGridOptions.autoBind = false;

    $scope.filterManagerGridOptions = CustomReportServices.setfilterManagerGridOptions();

    $scope.dynamicSideGridOptions = helperService.setNonPageableGridOptions([]);

    $scope.filterDropdownOptions = helperService.getFilterDropdownOptions();

    $scope.applySelectedFilter = function () {
        helperService.applyFilterCatchError($scope);
    };

    $scope.gridDataSource = new kendo.data.DataSource({

        pageSize: standardGridPageSize,
        schema: angular.extend(halSchemaKendoGrid, {
            model: {
                fields: {
                    LastRun: { type: "date" },
                    UseCount: { type: "number" }
                }
            }
        }),
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {

            read: function (options) {
                delete $rootScope.loadedSavedReport;
                var data = BuildUserAccessPostBody.generateBody($scope.products, $scope.ObjectId, $scope.securityObjectType, vm.selectedUserIds, $scope.companies, $scope.securityObject);
                data['QueryInput'] = helperService.prepareSortsAndFilters(options);

                // --- FOR ADVANCED FILTERING ---
                if (vm.filterCriteria.query) {
                    data['QueryInput'].Filters = data['QueryInput'].Filters.concat(vm.filterCriteria.query);
                }
                // ---
                // if tabs are on, save report info for the tab
                if ($rootScope.tabs.length > 0) {
                    reportsService.saveTabReportInfo($scope);
                }

                $http({
                    method: 'POST',
                    url: $scope.urlString,
                    data: data,
                    params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                }).then(function (response) {

                    var data = response.data._embedded.reportData;

                    options.success(response.data);
                    vm.enableGrid = true;

                    if ($scope.isDetailed) {
                        let securityObject;
                        if (vm.selectedProducts[0].IsCustom) {
                            // the security object for custom products has null for product type so we can't send the real product type
                            securityObject = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, "customProduct");
                        } else {
                            securityObject = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProducts[0].Type);
                        }
                        $scope.reportId = securityObject.ReportID;
                    }

                    if (data.length > 0) {
                        $scope.dynamicSideGridDataSource = null;
                    }

                }).catch(response => {
                    const timeout = 408;

                    if (response.status !== timeout) {
                        helperService.showErrorMessage(response.data);
                    }
                });
            }
        }
    });

    function updateUserDataSource(selectedUsers, selectedUserIds, userDataSource) {
        vm.selectedUserIds = selectedUserIds;
        vm.users = selectedUsers;
        vm.userDataSource = userDataSource;
        $scope.populateCriteriaModel();
    }

    function continuePopulateCriteriaModel() {
        return vm.selectedProducts && vm.selectedUserIds && vm.selectedCompanies && vm.selectedObject && vm.selectedObjectType;
    }

    $scope.populateCriteriaModel = function () {
        // only continue if the proper criteria are loaded
        if (continuePopulateCriteriaModel()) {

            var criteria = [];

            $scope.products = vm.selectedProducts;
            $scope.users = vm.users?.length ? vm.users : [{ "Name": "All Users", "ID": null }];
            $scope.companies = vm.selectedCompanies;
            $scope.securityObject = JSON.parse(JSON.stringify(vm.selectedObject));

            CustomReportServices.convertCriteriaValueToNull($scope.securityObject, "Identifier");

            $scope.securityObjectType = vm.selectedObjectType;

            criteria.push(CustomReportServices.CreateCriteria("SecurityObjectType", 'SecurityObjectType', $scope.securityObjectType.Name, { "Id": vm.selectedObjectType.Identifier }));
            criteria.push(CustomReportServices.CreateCriteria("SecurityObject", 'SecurityObject', $scope.securityObject.AltName, { "Id": $scope.securityObject.Identifier }));

            $scope.users.forEach(function (user) {
                criteria.push(CustomReportServices.CreateCriteria("UserMasterId", 'User', user.Name, user.ID));
            });

            $scope.products.forEach(function (row) {
                criteria.push(CustomReportServices.CreateCriteria("ProductId", 'Product', row.Name, row.ID));
            });

            $scope.companies.forEach(function (row) {
                criteria.push(CustomReportServices.CreateCriteria("CompanyMasterId", 'Company', row.Name, row.ID));
            });

            $scope.reportDetailsModel = criteria;

            if (vm.selectedProducts.length === 1 && $scope.isDetailed === true) {
                let productString = vm.selectedProducts[0].Type === 'SAP' ? 'SAP' : vm.selectedProducts[0].ID;
                $scope.urlString = apiUrl + 'api/arm/' + productString + '/places/useraccess';
            } else {
                $scope.urlString = apiUrl + 'api/arm/places/useraccess';
            }

            // if tabs are on, save report info for the tab
            if ($rootScope.tabs.length > 0) {
                reportsService.saveTabReportInfo($scope);
            }
        };

        $scope.generateGridWithSelectedValues = function () {

            if (!$rootScope.loadedSavedReport) {
                $scope.grid.dataSource._filter = null;
                $scope.grid.dataSource._sort = null;
            }

            $scope.populateCriteriaModel();
            vm.hideExportOptionsFromSubmit = true;

            // Add back the sorting and filtering options if we are running the report for the first time
            if (!$rootScope.loadedSavedReport) {
                $scope.grid.setOptions({
                    filterable: {
                        mode: "row"
                    },
                    sortable: true
                });
            }

            SavedReportSelectorService.queryGridWithOptions($rootScope.loadedSavedReport, $scope.grid, true);
        }

    };

    function getCompanies() {
        $http({
            method: 'GET',
            url: apiUrl + 'api/universal/companymaster'
        }).then(function (response) {
            response.data = $filter('orderBy')(response.data, 'Name');
            vm.allCompaniesDropdownObject = { "Name": "All Companies", "ID": "AllObjectsInMultiSelect" };
            response.data = response.data.filter(c => c.UserHasAccess === true);
            response.data.unshift(vm.allCompaniesDropdownObject);
            vm.companyDataSource = response.data;
            let defaultCompany = response.data.find(obj => obj.Name === $rootScope.UserPreferences.PreferredCompany);
            let defaultCompanySelected = vm.companyDataSource.indexOf(defaultCompany);
            if ($rootScope.UserPreferences.PreferredCompany !== null) {
                vm.selectedCompanies = [vm.companyDataSource[defaultCompanySelected]];
            } else {
                vm.selectedCompanies = [vm.allCompaniesDropdownObject];
            }
            vm.selectedCompanies = SavedReportSelectorService.checkSavedReportForCompanies($rootScope.loadedSavedReport, response.data, vm.selectedCompanies);
            $scope.companyDropdownChange();
            $scope.runSavedReport();
        });
    }
    getCompanies();

    function getObjectTypes() {
        if (vm.selectedProducts) {
            vm.objectTypesDropdownLoading = true;
            var products = vm.selectedProducts.map(function (el) {
                return el.Type;
            });


            $http({
                method: 'GET',
                url: apiUrl + 'api/universal/securityobjecttypes/producttype',
                params: { 'productType': products }
            }).then(function (response) {
                vm.objectTypesDropdownLoading = false;
                vm.allObjectTypesDropdownObject = { "Name": "All Object Types", "ID": null };

                response.data.unshift(vm.allObjectTypesDropdownObject);

                vm.objectTypeDataSource = response.data;
                vm.objectTypeDataSource = $filter('orderBy')(response.data, 'Name');
                vm.selectedObjectType = response.data[0];
                vm.selectedObjectType = SavedReportSelectorService.checkSavedReportForObjectTypes($rootScope.loadedSavedReport, response.data, vm.selectedObjectType);
                $scope.objectTypeDropdownChange();
                $scope.runSavedReport();
            });
        }
    }

    $scope.companyDropdownChange = function (companies) {
        if (companies) {
            vm.selectedCompanies = helperService.deselectAllObjectsIfOtherIsClicked(companies, vm.allCompaniesDropdownObject);
        } else {
            vm.selectedCompanies = helperService.deselectAllObjectsIfOtherIsClicked(vm.selectedCompanies, vm.allCompaniesDropdownObject);
        }
        $scope.populateCriteriaModel();
    };

    $scope.objectTypeDropdownChange = function () {
        $scope.kendoObjectDropdownDataSource._data = [];
        $scope.kendoObjectDropdownDataSource.read();
        $scope.populateCriteriaModel();
    };

    $scope.kendoObjectDropdownDataSource = new kendo.data.DataSource({
        schema: {
            data: "_embedded.reportData",
            total: "TotalItems"
        },
        pageSize: standardGridPageSize,
        serverPaging: true,
        serverFiltering: true,
        transport: {
            read: function (options) {

                if (vm.selectedObjectType && vm.selectedObjectType.Identifier !== '') {
                    vm.objectsDropdownLoading = true;

                    let data = helperService.prepareSortsAndFilters(options);
                    if (data.Filters.length > 0) {
                        if (vm.objectSearchField === 'AltName') {
                            data.Filters[0].FieldName = "SecurityObjectAltName";
                        } else {
                            data.Filters[0].FieldName = "SecurityObjectName";
                        }
                    }

                    var urlString = null;
                    var params = null;
                    params = {
                        "productType": vm.selectedProducts.map(function (el) { return el.Type; }),
                        "pagesize": options.data.pageSize,
                        "page": options.data.page
                    };
                    if (vm.selectedObjectType.Identifier) {
                        urlString = apiUrl + 'api/universal/securityobjects/producttype/' + vm.selectedObjectType.ProductType + '/type/' + vm.selectedObjectType.ID;
                    } else {
                        urlString = apiUrl + 'api/universal/securityobjects';
                    }
                    $http({
                        method: 'POST',
                        url: urlString,
                        params: params,
                        data: data
                    }).then(function (response) {
                        let data = response.data._embedded.reportData;

                        if (vm.objectSearchField === 'AltName') {
                            data = fillAltNames(data);
                        }

                        if ($scope.kendoObjectDropdownDataSource._data.length === 0) {
                            // objects are being loaded for the first time
                            addAllObjectsOption();

                            if ($rootScope.loadedSavedReport) {
                                SavedReportSelectorService.getSavedReportSelectedObject($rootScope.loadedSavedReport).then(response => {
                                    if (response) {
                                        data.unshift(response);
                                    }
                                    success(data);
                                });
                            } else {
                                success(data);
                            }
                        } else if (options.data.page === 1) {
                            if (!options.data.filter || options.data.filter.filters.length === 0 || options.data.filter.filters[0].value === "") {
                                addAllObjectsOption();
                            }
                            if (vm.selectedObject.Identifier !== "-1" && !data.some(object => object.Identifier === vm.selectedObject.Identifier)) {
                                // selected object is not in the dataset we need to add it and make sure it stays selected
                                data.unshift(vm.selectedObject);
                                vm.selectedObject = data[0];
                            }
                            options.success(response.data);
                        } else {
                            options.success(response.data);
                        }

                        function addAllObjectsOption() {
                            let AllObjects = {};
                            if (vm.objectSearchField === 'AltName') {
                                AllObjects = { "AltName": "All Objects", "Id": null, "Identifier": "-1" };
                            } else {
                                AllObjects = { "Name": "All Objects", "Id": null, "Identifier": "-1" };
                            }
                            data.unshift(AllObjects);
                        }

                        function success(data) {
                            vm.objectDataSource = data;
                            vm.selectedObject = data[0];
                            vm.objectsDropdownLoading = false;
                            $scope.runSavedReport();
                            options.success(response.data);
                        }

                    });
                } else {
                    options.error();
                }
            }
        }
    });

    if (vm.objectSearchField === 'AltName') {
        vm.dropdownTemplate = '<strong>{{dataItem.AltName}}</strong> <span ng-if="dataItem.Name"> | {{dataItem.Name}}</span> <span ng-if="dataItem.SecurityObjectTypeName"> | {{dataItem.SecurityObjectTypeName}}</span> <span class="badge light-gray-badge-background">{{dataItem.Id}}</span>';
        vm.dataTextField = 'AltName';
    } else {
        vm.dropdownTemplate = '<strong>{{dataItem.Name}}</strong> <span ng-if="dataItem.AltName"> | {{dataItem.AltName}}</span> <span ng-if="dataItem.SecurityObjectTypeName"> | {{dataItem.SecurityObjectTypeName}} </span> <span class="badge light-gray-badge-background">{{dataItem.Id}}</span>';
        vm.dataTextField = 'Name';
    }

    $scope.objectDropdownOptions = {
        template: vm.dropdownTemplate,
        filter: "contains",
        autoBind: false,
        minLength: 3,
        virtual: {
            itemHeight: 26,
            mapValueTo: "dataItem",
            valueMapper: function (options) {
                options.success(helperService.valueMapperFunction(options, $scope.vm.objectDataSource, 'Identifier'));
            }
        },
        open: function () {
            let filters = this.dataSource._filter;
            if (filters) {
                this.dataSource.filter({});
            }
        },
        height: 325
    };

    vm.setColumns = function () {
        if (vm.selectedProducts) {

            if (vm.selectedProducts.length === 1 && $scope.isDetailed === true) {
                if (vm.selectedProducts[0].Type === 'OR') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "UserDescription", title: "User Description", width: 160, filterable: customAutoCompleteFilter },
                        { field: "UserMenuName", title: "User Menu Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MenuName", title: "Menu Name", width: 200, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "UserSubMenuName", title: "User Sub Menu Name", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SubMenuName", title: "Sub Menu Name", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SecurityObjectAltName", title: "User Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Operating Unit", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyId", title: "Company Id", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Prompt", title: "Prompt", width: 200, filterable: customAutoCompleteFilter },
                        { field: "Path", title: "Path", width: 180, filterable: customAutoCompleteFilter }

                    ];

                    if (!helperService.convertConfigValueToTrueFalse('OR_RBACEnabled')) {
                        $scope.mainGridColumns.splice(3, 0, { field: "Role", title: "Role", width: 180, filterable: customAutoCompleteFilter }, { field: "RoleCode", title: "Role Code", width: 180, filterable: customAutoCompleteFilter }, { field: "RoleType", title: "Role Type", width: 180, filterable: customAutoCompleteFilter });
                    } else {
                        $scope.mainGridColumns.splice(3, 0, { field: "Responsibility", title: "Responsibility", width: 180, filterable: customAutoCompleteFilter }, { field: "ResponsibilityID", title: "Responsibility ID", width: 180, filterable: customAutoCompleteFilter }, { field: "ResponsibilityKey", title: "Responsibility Key", width: 180, filterable: customAutoCompleteFilter });
                    }

                } else if (vm.selectedProducts[0].Type === 'INT') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "ApplicationName", title: "Application Name", width: 180, filterable: customAutoCompleteFilter, hidden: !helperService.convertConfigValueToTrueFalse("INT_RoleBasedSecurity") },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter, hidden: helperService.convertConfigValueToTrueFalse("INT_RoleBasedSecurity") },
                        { field: "Module", title: "Module", width: 150, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AdministrationLevel", title: "Administration Level ", width: 180, filterable: customAutoCompleteFilter },
                        { field: "ActiveStatus", title: "Active Status ", width: 130, filterable: customAutoCompleteFilter }
                    ];

                } else if (vm.selectedProducts[0].Type === 'NS') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "UserType", title: "User Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Subsidiary", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'NAV') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "FullName", title: "Full Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleId", title: "Role ID", width: 100, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessLevelName", title: "Access Level", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'D365Bc') {
                    $scope.mainGridColumns = [
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "UserDescription", title: "User Description", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleId", title: "Role ID", width: 100, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessLevelName", title: "Access Level", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'Zuo' || vm.selectedProducts[0].IsCustom) {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "UserDescription", title: "User Description", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleId", title: "Role ID", width: 100, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessLevelName", title: "Access Level", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'CPA') {
                    $scope.mainGridColumns = [
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "UserDescription", title: "User Description", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleId", title: "Role ID", width: 100, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter }
                    ];

                } else if (vm.selectedProducts[0].Type === 'AX7') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoleSystemName", title: "Role System Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SubRoleName", title: "Sub Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SubRoleSystemName", title: "Sub Role System Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DutyName", title: "Duty Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "DutySystemName", title: "Duty System Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "PrivilegeName", title: "Privilege Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "PrivilegeSystemName", title: "Privilege System Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessLevelName", title: "Access Level", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "LicenseType", title: "License Type", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'AX') {
                    $scope.mainGridColumns = [
                        { field: "NetworkAlias", title: "Network Alias", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RolePhysicalName", title: "Role Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SubRoleName", title: "Sub Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SubRolePhysicalName", title: "Sub Role Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DutyName", title: "Duty Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "DutyPhysicalName", title: "Duty Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "PrivilegeName", title: "Privilege Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "PrivilegePhysicalName", title: "Privilege Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "EntryPointName", title: "Entry Point Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "EntryPointPhysicalName", title: "Entry Point Physical Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "EntryPointTypeName", title: "Entry Point Type", width: 200, filterable: customAutoCompleteFilter },
                        { field: "EntryPointAccessName", title: "Entry Point Access", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectHelpText", title: "Security Object Help Text", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'SAP') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "UserGroup", title: "User Group", width: 160, filterable: customAutoCompleteFilter },
                        { field: "UserType", title: "User Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Type", title: "Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Name", title: "Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Authorization", title: "Authorization", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Field", title: "Field", width: 130, filterable: customAutoCompleteFilter },
                        { field: "TechnicalLow", title: "Technical Low", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Low", title: "Low", width: 130, filterable: customAutoCompleteFilter },
                        { field: "TechnicalHigh", title: "Technical High", width: 130, filterable: customAutoCompleteFilter },
                        { field: "High", title: "High", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UseCount", title: "Use Count", width: 130, filterable: customAutoCompleteFilter },
                        { field: "LastRun", title: "Last Run Date", template: "{{ dataItem.LastRun | date:'short'}}", width: 200, filterable: customDatePickerFilter },
                        { field: "BusinessUnit", title: "Business Unit", width: 200, filterable: customAutoCompleteFilter },
                        { field: "Department", title: "Department", width: 200, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 200, filterable: customAutoCompleteFilter },
                        { field: "Location", title: "Location", width: 200, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'SAPB1') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AuthorizationSource", title: "Authorization Source", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'SF') {
                    $scope.mainGridColumns = [
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "UserFullName", title: "User Full Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Module", title: "Module", width: 150, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AssignedByType", title: "Access by Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AssignedByName", title: "Access By", width: 130, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'GP') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "TaskName", title: "Task", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Series", title: "Series", width: 160, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Alt Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter, hidden: true }
                    ];
                } else if (vm.selectedProducts[0].Type === 'AC') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'PS') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessLevelName", title: "Access Level", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "RoleName", title: "Role", width: 190, filterable: customAutoCompleteFilter },
                        { field: "PermissionListId", title: "Permission List", width: 160, filterable: customAutoCompleteFilter },
                        { field: "MenuName", title: "Menu", width: 200, filterable: customAutoCompleteFilter },
                        { field: "BarName", title: "Bar Name", width: 100, filterable: customAutoCompleteFilter },
                        { field: "ComponentName", title: "Component", width: 190, filterable: customAutoCompleteFilter },
                        { field: "MarketName", title: "Market", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'JDE') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "Application", title: "Application", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Version", title: "Version", width: 110, filterable: customAutoCompleteFilter },
                        { field: "AccessSource", title: "Access Source", width: 110, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessLevelName", title: "Access Level", width: 80, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'ORFC') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RolePhysicalName", title: "Role Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SubRoleName", title: "Sub Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SubRolePhysicalName", title: "Sub Role Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Business Unit", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RolePath", title: "Role Path", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'AX5') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "GroupId", title: "AX Group", width: 180, filterable: customAutoCompleteFilter },
                        { field: "DomainId", title: "AX Domain", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'SL') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "AccessSource", title: "Access Source", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter }
                    ];
                }
                else if (vm.selectedProducts[0].Type === 'D365S') {
                    $scope.mainGridColumns = [
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "TeamName", title: "Team Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "PrivilegeName", title: "Privilege", width: 160, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessLevelName", title: "Access Level", width: 130, filterable: customAutoCompleteFilter }
                    ];
                }
                else if (vm.selectedProducts[0].Type === 'AD') {
                    $scope.mainGridColumns = [
                        { field: "UserId", title: "User Id", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SubGroupName", title: "Sub-Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Path", title: "Path", width: 180, filterable: customAutoCompleteFilter }
                    ];
                }
                else if (vm.selectedProducts[0].Type === 'AAD') {
                    $scope.mainGridColumns = [
                        { field: "UserId", title: "User Id", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SubGroupName", title: "Sub-Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Path", title: "Path", width: 180, filterable: customAutoCompleteFilter }
                    ];
                }

                else if (vm.selectedProducts[0].Type === 'WD') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User Id", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserDescription", title: "User Description", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityGroup", title: "Security Group", width: 180, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcessType", title: "Business Process Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcessDefinition", title: "Business Process Definition", width: 180, filterable: customAutoCompleteFilter },
                        { field: "StepOrder", title: "Step Order", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoutingRestrictions", title: "Routing Restrictions", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Condition", title: "Condition", width: 180, filterable: customAutoCompleteFilter }

                    ];
                } else if (vm.selectedProducts[0].Type === 'OREPM') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User Id", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Enabled", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Service", title: "Service", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GrantedThroughGroup", title: "Granted Through Group", width: 180, filterable: customAutoCompleteFilter }
                    ];
                }
            } else {
                $scope.mainGridColumns = [
                    { field: "ProductName", title: "Product", width: 180, filterable: customAutoCompleteFilter },
                    { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                    { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                    { field: "UserDescription", title: "User Description", width: 160, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                    { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                    { field: "AccessLevelName", title: "Access Level", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                    { field: "Company", title: "Company", width: 180, filterable: customAutoCompleteFilter },
                    { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter }
                ];
            }

            // Don't allow filtering and sorting yet because they will force the report to run
            if (!$rootScope.loadedSavedReport) {
                $scope.mainGridOptions.sortable = false;

                $scope.mainGridOptions.filterable = false;
            }

            vm.setLayout();
        }
    };

    vm.setLayout = function () {

        var productId;
        if ($scope.isDetailed === true && vm.selectedProducts.length === 1) {
            productId = vm.selectedProducts[0].ID;
        }

        if (!$rootScope.loadedSavedReport) {
            CustomReportServices.loadSavedGridLayout($scope, productId);
        }

    };

    $scope.productDropdownChange = function () {
        if ($scope.isDetailed) {
            if (vm.selectedProducts && vm.selectedProducts.length > 1) {
                vm.selectedProducts.remove(vm.selectedProducts[0]);
            }
            if (vm.selectedProducts[0].IsCustom) {
                $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, "customProduct").ReportID;
            } else {
                $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProducts[0].Type).ReportID;
            }
            getReportMetadata();
        }
        getObjectTypes();
        vm.setColumns();
        $scope.populateCriteriaModel();
    };

    $scope.changeRowSelected = function (kendoEvent) {
        $scope.sideGridIsNew = false;

        $scope.dynamicSideGridOptions = helperService.setNonPageableGridOptions([]);
        $scope.sideGridIsNew = true;
        $scope.sideGridLoading = true;
        $scope.dynamicSideGridDataSource = [];
        var grid = kendoEvent.sender;
        CustomReportServices.getATChangeCountForRow($scope, grid);
        CustomReportServices.getAccessDetailsForRow($scope, grid).then(response => {
            if (response.data.length > 0) {
                $scope.dynamicSideGridColumns = Object.keys(response.data[0]).map(f => {
                    var column = {};
                    column.field = f;
                    column.title = helperService.headerStringTransformer(f);
                    return column;
                });
                $scope.dynamicSideGridDataSource = new kendo.data.DataSource({
                    data: response.data
                });
            }
        });
        $scope.sideGridLoading = false;

    };

    $scope.deleteFilter = function (id) {
        CustomReportServices.deleteFilterFilterManager($scope, id);
    };

    $scope.splitHeaderText = function () {
        helperService.splitHeaderText(angular);
    };

    $scope.clearAllFilters = function () {
        $scope.gridDataSource.filter({
        });
    };

    $scope.saveReportLayoutButton = function () {
        var productId;
        if ($scope.isDetailed === true && vm.selectedProducts.length === 1) {
            productId = vm.selectedProducts[0].ID;
        }
        CustomReportServices.saveGridLayout($scope, productId);
    };

    // --- FOR ADVANCED FILTERING ---
    function getReportMetadata() {
        return reportsDataService.getReportMetadata($scope.reportId)
            .then(data => {

                vm.reportMetadata = data.data._embedded.reportData;
                if (vm.selectedProducts.some(product => product.Type === 'OR')) {
                    if (helperService.convertConfigValueToTrueFalse('OR_RBACEnabled')) {
                        vm.reportMetadata.Columns = vm.reportMetadata.Columns.filter(column => column.FieldName !== "Role" && column.FieldName !== "RoleCode" && column.FieldName !== "RoleType");
                    } else {
                        vm.reportMetadata.Columns = vm.reportMetadata.Columns.filter(column => column.FieldName !== "Responsibility" && column.FieldName !== "ResponsibilityID" && column.FieldName !== "ResponsibilityKey");
                    }
                }
                vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');
                vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
                return vm.reportMetadata;
            });
    }
    // ---
}

function fillAltNames(data) {
    return data.map(object => {
        if (!object.AltName) {
            object.AltName = object.Name;
            object.Name = ""
        }

        return object;
    });
}