import createSpinner from "../../../../Shared/Functions/createSpinner";
import { customDatePickerFilter, customAutoCompleteFilter, standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../../../Shared/kendo.module";

export default /*@ngInject*/ function ($uibModalInstance, helperService, nsAtDocumentationService, $http, $filter, reportsService, templatesDataService, userGroupsDataService, reportsDataService, SavedReportSelectorService, documentation) {

    const vm = this;
    vm.documentation = documentation;
    vm.helperService = helperService;
    vm.cancel = cancel;
    vm.generateGridWithSelectedValues = generateGridWithSelectedValues;
    vm.deselectSelectedTemplates = deselectSelectedTemplates;
    vm.deselectSelectedUserGroups = deselectSelectedUserGroups;
    vm.linkChangesToDocumentation = linkChangesToDocumentation;
    vm.unlinkChangeFromDocumentation = unlinkChangeFromDocumentation;
    vm.changesChangeRowSelected = changesChangeRowSelected;
    vm.gridView = "LinkedChanges";
    vm.showChangesGrid = false;
    vm.changesToLink = [];

    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    // ---

    function activate() {
        configureLinkedChangesGrid();
        configureChangesGrid();
    }

    activate();

    function cancel() { $uibModalInstance.dismiss(); }

    function configureLinkedChangesGrid() {
        //-----LINKED CHANGES-----

        const linkedChangesGridColumns = [
            { template: `<a ng-click="cmvm.unlinkChangeFromDocumentation(dataItem)"><i class="glyphicon glyphicon-trash text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 100 },      
            { field: "DetailDate", title: "Date", template: "{{ dataItem.DetailDate | date:'short'}}", width: 150, filterable: customDatePickerFilter },
            { field: "DetailObjectName", title: "Object Name", width: 160, filterable: customAutoCompleteFilter },
            { field: "DetailSubObjectName", title: "Sub Object Name", width: 160, filterable: customAutoCompleteFilter },
            { field: "DetailFieldName", title: "Field Name", width: 160, filterable: customAutoCompleteFilter },
            { field: "DetailRecordId", title: "Record Id", width: 190, filterable: customAutoCompleteFilter },
            { field: "DetailRecordName", title: "Record Name", width: 180, filterable: customAutoCompleteFilter },
            { field: "DetailUserId", title: "User Id", width: 190, filterable: customAutoCompleteFilter },
            { field: "DetailUserName", title: "User Name", width: 190, filterable: customAutoCompleteFilter },
            { field: "DetailRoleId", title: "Role Id", width: 160, filterable: customAutoCompleteFilter },
            { field: "DetailRoleName", title: "Role Name", width: 160, filterable: customAutoCompleteFilter },
            { field: "DetailContext", title: "Context", width: 160, filterable: customAutoCompleteFilter },
            { field: "DetailType", title: "Type", width: 160, filterable: customAutoCompleteFilter },
            { field: "DetailOldValue", title: "Old Value", width: 200, filterable: customAutoCompleteFilter },
            { field: "DetailNewValue", title: "New Value", width: 200, filterable: customAutoCompleteFilter }

        ];

        vm.linkedChangesGridOptions = helperService.setMainGridOptions(linkedChangesGridColumns, 400);
        vm.linkedChangesGridOptions.noRecords = {
            template: "<div class='no-data-to-display-message'>No Linked Changes exist for this Documentation</div>"
        };

        vm.linkedChangesGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: angular.extend(halSchemaKendoGrid, {
                model: {
                    fields: {
                        Date: { type: "date" }
                    }
                }
            }),
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: async options => {
                    let linkedChangesData = helperService.prepareSortsAndFilters(options);
                    const params = { 'pagesize': options.data.pageSize, 'page': options.data.page };
                    try {
                        let response = await nsAtDocumentationService.getDocumentationLinkedChangesList(documentation.Id, linkedChangesData, params)
                        options.success(response);
                    } catch (err) {
                        options.error(err);
                    }
                }
            }
        });
        //-----LINKED CHANGES-----
    }

    async function getTemplates() {
        vm.templateDropdownIsLoading = true;

        try {
            let response = await templatesDataService.getTemplates('NS');
            response.data = $filter('orderBy')(response.data, 'Name');
            const All = { "Name": "All Templates", "Id": 'AllObjectsInMultiSelect' };
            response.data.unshift(All);
            vm.templatesDataSource = response.data;
            vm.selectedTemplates = [All];
            vm.templateDropdownIsLoading = false;
        } catch {
            vm.templateDropdownIsLoading = false;
        }
    }

    async function getUserGroups() {
        vm.userGroupDropdownIsLoading = true;
        try {
            let response = await userGroupsDataService.getUserGroups();
            response.data = $filter('orderBy')(response.data, 'Name');
            const AllUserGroups = { "Name": "All User Groups", "Id": 'AllObjectsInMultiSelect' };
            response.data.unshift(AllUserGroups);
            vm.userGroupsDataSource = response.data;
            vm.selectedUserGroups = [AllUserGroups];
            vm.userGroupDropdownIsLoading = false;
        } catch {
            vm.userGroupDropdownIsLoading = false;
        }
    }

    // --- FOR ADVANCED FILTERING ---
    async function getReportMetadata() {
        let response = await reportsDataService.getReportMetadata(vm.reportId)

        vm.reportMetadata = response.data._embedded.reportData;
        vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');

        return vm.reportMetadata;
    }
    // ---



    async function configureChangesGrid() {
        // Have to hardcode object Id for All Changes report until we find a better way to get it
        vm.reportId = '220923C6-5810-4ECD-8A81-F40D07917388';

        // --- FOR ADVANCED FILTERING ---
        await getReportMetadata();
        vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
        // ---

        getTemplates();
        getUserGroups();

        vm.changesGridColumns = [
            { field: "Date", title: "Date", template: "{{ dataItem.Date | date:'short'}}", width: 160, filterable: customDatePickerFilter },
            { field: "ObjectName", title: "Object", width: 160, filterable: customAutoCompleteFilter },
            { field: "SubObjectName", title: "Sub Object", width: 160, filterable: customAutoCompleteFilter },
            { field: "FieldName", title: "Field", width: 160, filterable: customAutoCompleteFilter },
            { field: "RecordId", title: "Record Id", width: 160, filterable: customAutoCompleteFilter },
            { field: "RecordName", title: "Record Name", width: 160, filterable: customAutoCompleteFilter },
            { field: "UserId", title: "User Id", width: 160, filterable: customAutoCompleteFilter },
            { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
            { field: "RoleId", title: "Role Id", width: 160, filterable: customAutoCompleteFilter },
            { field: "RoleName", title: "Role Name", width: 160, filterable: customAutoCompleteFilter },
            { field: "Context", title: "Context", width: 160, filterable: customAutoCompleteFilter },
            { field: "Type", title: "Type", width: 160, filterable: customAutoCompleteFilter },
            { field: "OldValue", title: "Old Value", width: 160, filterable: customAutoCompleteFilter },
            { field: "NewValue", title: "New Value", width: 160, filterable: customAutoCompleteFilter },
            { field: "ChangeId", title: "ChangeId", hidden: true, filterable: customAutoCompleteFilter },
            { field: "ObjectId", title: "ObjectId", hidden: true, filterable: customAutoCompleteFilter },
            { field: "FieldId", title: "FieldId", hidden: true, filterable: customAutoCompleteFilter },
            { field: "FieldApiId", title: "FieldApiId", hidden: true, filterable: customAutoCompleteFilter }
        ];

        vm.changesGridOptions = helperService.setMainGridOptions(vm.changesGridColumns, 400);
        vm.changesGridOptions.persistSelection = true;
        vm.changesGridOptions.selectable = "multiple, row";
        vm.changesGridOptions.autoBind = false;

        vm.changesGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: angular.extend(halSchemaKendoGrid, {
                model: {
                    id: "ChangeId",
                    fields: {
                        Date: { type: "date" }
                    }
                }
            }),
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: async options => {
                    let templates = helperService.returnNullIfAllIsFound(vm.selectedTemplates.map(function (el) { return el.Id; }));
                    let userGroups = helperService.returnNullIfAllIsFound(vm.selectedUserGroups.map(function (el) { return el.Id; }));

                    let data = {
                        'TemplateIds': templates,
                        'UserGroupIds': userGroups,
                        'DocumentationId': documentation.Id
                    };
                    data['QueryInput'] = helperService.prepareSortsAndFilters(options);

                    // --- FOR ADVANCED FILTERING ---
                    if (vm.filterCriteria.query) {
                        data['QueryInput'].Filters = data['QueryInput'].Filters.concat(vm.filterCriteria.query);
                        data['QueryInput'].DateFilters = vm.filterCriteria.dateQuery;
                    }
                    // ---

                    try {
                        let response = await $http({
                            method: 'POST',
                            url: apiUrl + 'api/audittrail/ns/v2/places/allchanges',
                            data: data,
                            params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                        });
                        options.success(response.data);
                    } catch (err) {
                        options.error(err);
                    }
                }
            }
        });

    }

    function generateGridWithSelectedValues() {
        vm.showChangesGrid = true;
        SavedReportSelectorService.queryGridWithOptions(null, vm.grid, true);
    }

    function deselectSelectedTemplates() {

        if (vm.selectedTemplates && vm.selectedTemplates[vm.selectedTemplates.length - 1].Id !== 'AllObjectsInMultiSelect') {
            vm.selectedTemplates.forEach(function (row, i) {
                if (row.Id === 'AllObjectsInMultiSelect') {
                    vm.selectedTemplates.splice(i, 1);
                }
            });
        } else {
            vm.selectedTemplates = [{ "Name": "All Template Types", "Id": 'AllObjectsInMultiSelect' }];
        }
    }

    function deselectSelectedUserGroups() {

        if (vm.selectedUserGroups && vm.selectedUserGroups[vm.selectedUserGroups.length - 1].Id !== 'AllObjectsInMultiSelect') {
            vm.selectedUserGroups.forEach(function (row, i) {
                if (row.Id === 'AllObjectsInMultiSelect') {
                    vm.selectedUserGroups.splice(i, 1);
                }
            });
        } else {
            vm.selectedUserGroups = [{ "Name": "All User Groups", "Id": 'AllObjectsInMultiSelect' }];
        }
    }

    async function linkChangesToDocumentation() {
        vm.actionInProgress = new createSpinner();

        try {
            await nsAtDocumentationService.linkChangeToDocumentation(documentation.Id, vm.changesToLink);
            await helperService.successfulSaveButton(vm.actionInProgress);
            vm.linkedChangesGridDataSource.read();
            vm.changesGridDataSource.read();
        } catch {
            vm.actionInProgress.loadingValue = false;
        }
    }

    async function unlinkChangeFromDocumentation(dataItem) {
        vm.actionInProgress = true;

        try {
            await nsAtDocumentationService.deleteChangeDocumentationLink(documentation.Id, dataItem.NsAtDocumentationDetailId);
            vm.linkedChangesGridDataSource.read();
            if (vm.changesGridDataSource._data.length > 0) {
                vm.changesGridDataSource.read();
            }
            vm.actionInProgress = false;
        } catch {
            vm.actionInProgress = false;
        }
    }

    function changesChangeRowSelected(kendoEvent) {
        vm.changesToLink = [];

        let grid = kendoEvent.sender;
        //stupid jQuery because of Kendo
        grid.select().each(function () {
            vm.changesToLink.push(grid.dataItem(this));
        });

        vm.changesToLink = vm.changesToLink.map(change => change.DetailId);
    }


}