import azureADUserController from "./azureADUser.controller";

/* azureADUser.component.js */

/**
 * @desc component for displaying Azure AD Username search and input fields
 * @example <azure-ad-user-search-link></azure-ad-user-search-link>
 */

const azureAdUserSearchLink = {
    bindings: {
        userObject: '<',
        onUserUpdated: '&'
    },
    controller: azureADUserController,
    controllerAs: 'aaduvm',
    templateUrl: '/App/Components/RequestManagement/AzureADUserLink/azureADUser.tmpl.html'
};

export default azureAdUserSearchLink;