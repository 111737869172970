import { standardGridPageSize } from "../../GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($rootScope, helperService, $http) {

    const vm = this;

    vm.$onInit = onInit;
    vm.$onChanges = onChanges;

    vm.intraResponsibilityCheck = intraResponsibilityCheck;

    async function onInit() {
        await setupRoleMultiselectDropdown();
        if ($rootScope.loadedSavedReport) {
            savedReportHandler();
        } else {
            if (vm.allRolesOption) {
                intraResponsibilityHandler();
            }
        }
    }

    async function onChanges(state) {
        if (vm.kendoRoleDropdownDataSource) {
            await vm.kendoRoleDropdownDataSource.read();
        }
        if (!state.selectedProduct.isFirstChange()) {
            productChangeHandler();
        }
    }

    function setupRoleMultiselectDropdown() {
        vm.kendoRoleDropdownDataSource = new kendo.data.DataSource({
            schema: {
                data: "_embedded.reportData",
                total: "TotalItems"
            },
            pageSize: standardGridPageSize,
            serverPaging: true,
            serverFiltering: true,
            serverOperation: false,
            transport: {
                read: async (options) => {
                    if (vm.selectedProduct) {
                        vm.rolesDropdownLoading = true;

                        const query = helperService.prepareSortsAndFilters(options);
                        if (query.Filters.length > 0) {
                            query.Filters[0].FieldName = "SecurityRoleName";
                        }

                        if (vm.altText && vm.altText.includes('Responsibility')) {
                            query.Filters.push({
                                "FieldName": "SecurityRoleTypeID",
                                "Operator": "equals",
                                "Value": "Responsibility"
                            });
                        }

                        if (vm.selectedProduct.length > 0) {
                            vm.selectedProduct.forEach(product => {
                                query.Filters.push({
                                    "FieldName": "ProductID",
                                    "Operator": "equals",
                                    "Value": product.ID
                                });
                            });
                        }

                        let urlString;
                        const params = {
                            "productType": vm.selectedProduct.Type,
                            "pagesize": options.data.pageSize,
                            "page": options.data.page,
                            "isExcluded": false,
                            "includeUnassigned": $rootScope.UserPreferences.IncludeOnlyUserAssignedRolesInPlacesRoleDropdowns === "1" ? false : true
                        };
                        if (vm.selectedProduct.ID) {
                            urlString = `${apiUrl}api/universal/securityroles/product/${vm.selectedProduct.ID}`;
                        } else {
                            urlString = `${apiUrl}api/universal/securityroles`;
                        }
                        const response = await $http({
                            method: 'POST',
                            url: urlString,
                            data: query,
                            params: params
                        });

                        options.success(response.data);
                    }
                }
            }
        });

        vm.roleDropdownOptions = {
            template: `<span>{{dataItem.Name}}</span>
       <span ng-if="dataItem.RolePhysicalName"> | {{dataItem.RolePhysicalName}}</span>
       <span ng-if="rmdvm.selectedProduct.length > 1"> | {{dataItem.ProductName}}</span>
       <span class="badge light-gray-badge-background">{{dataItem.ProductId}}</span>`,
            filter: "contains",
            autoBind: false,
            autoClose: false,
            placeholder: setPlaceholder(),
            popup: { origin: "bottom left" },
            virtual: {
                itemHeight: 26,
                mapValueTo: 'dataItem',
                valueMapper: async (options) => {
                    const roles = await helperService.roleMapperFunction(options.value);
                    options.success(roles);
                }
            },
            height: 325
        };
    }

    function setPlaceholder() {
        if (vm.altText) {
            if (vm.allRolesOption) {
                return `All Responsibilities`;
            } else {
                return `-- Select Responsibilities --`;
            }
        } else {
            if (vm.allRolesOption) {
                return `All Roles`;
            } else {
                return `-- Select Roles --`
            }
        }
    }

    function savedReportHandler() {
        if ($rootScope.loadedSavedReport) {
            const savedRoles = $rootScope.loadedSavedReport.ReportDefinition.Criteria.filter(criteria => criteria.CriteriaType === 'Role' || criteria.CriteriaType === 'Responsibility');

            const roles = [];

            if (savedRoles.length > 0) {
                savedRoles.forEach(role => {
                    roles.push({
                        Identifier: role.Filters[0].Value,
                        Name: role.CriteriaValue
                    });
                });
                vm.roleSelectedIds = [...roles];
            }
            if (roles.length === 0 || roles[0].Identifier === -1) {
                intraResponsibilityHandler();
            }
            vm.roleDataSource = vm.kendoRoleDropdown.dataSource.data();
            const roleDataSource = vm.roleDataSource.toJSON();
            const roleSelectedIds = vm.roleSelectedIds;
            vm.onUpdateRoles({ roleDataSource, roleSelectedIds });
            vm.rolesDropdownLoading = false;
            vm.runSavedReport();
        }
    }

    function productChangeHandler() {
        if (vm.allRolesOption) {
            intraResponsibilityHandler();
        } else {
            vm.roleSelectedIds = [];
        }
    }

    function intraResponsibilityHandler() {
        if (vm.altText === 'Responsibility Risks') {
            const selection = vm.roleSelectedIds;
            vm.toggleIntraResponsibility({ selection });
        }
    }

    function intraResponsibilityCheck(e) {
        if (vm.altText === 'Responsibility Risks') {
            const selectionArray = e.sender.value();
            const selection = selectionArray[0];
            vm.toggleIntraResponsibility({ selection });
        }
    }



}