import createSpinner from "../../../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter, standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";


export default /*@ngInject*/ function ($uibModalInstance, $filter, ownershipGroup, riskCriteriaItem, modalType, riskLevelsData, businessCyclesDataService, risksDataService, helperService, ownershipGroupItemsDataService, riskRulesetService, ownershipGroupOwnerDataService) {

    const vm = this;

    vm.saveRisk = saveRisk;
    vm.selectedOwnerTypeChanged = selectedOwnerTypeChanged;
    vm.updateRiskInfo = updateRiskInfo;
    vm.resetDropdowns = resetDropdowns;
    vm.cancel = cancel;

    vm.ownershipGroup = { ...ownershipGroup };
    vm.modalType = modalType;

    vm.selectedBusinessCycles = [];
    vm.selectedRiskLevels = [];

    activate();

    async function activate() {
        await getRulesets();
        await getOwners();

        await getRiskTypes();
        await getBusinessCycles();
        await getRiskLevels();

        vm.selectedOwnerType = 'User';
        if (modalType === 'edit') {
            vm.riskCreationType = 'Risk Criteria';
            vm.riskCriteriaName = riskCriteriaItem.Name;
            setupRiskCriteriaGrid();
        } else {
            vm.riskCreationType = 'Risk';
            vm.selectedRiskType = 0;
        }
        selectedOwnerTypeChanged();
    }

    async function getRulesets() {
        let { data } = await riskRulesetService.getRiskRulesetList();
        vm.rulesets = data;

        vm.defaultRulesetId = vm.rulesets.find(ruleset => ruleset.IsDefault).RulesetId;
        if (modalType === 'edit') {
            vm.selectedRulesetId = riskCriteriaItem.RulesetId;
        } else {
            vm.selectedRulesetId = vm.defaultRulesetId;
            await setupRiskDropdown();
            await setupRiskCriteriaGrid();
        }

    }

    async function getRiskTypes() {
        let typeId;
        let { data } = await risksDataService.getRiskTypes();
        vm.riskTypes = data;

        if (modalType === 'edit') {
            if (riskCriteriaItem.RiskCriteria.RiskType === 'SOD') {
                typeId = 1;
            } else if (riskCriteriaItem.RiskCriteria.RiskType === 'SA') {
                typeId = 2;
            } else {
                typeId = 0;
            }
            vm.selectedRiskType = typeId;
        }
    }

    async function getBusinessCycles() {
        let { data } = await businessCyclesDataService.getBusinessCycles();
        vm.businessCyclesDataSource = data;

        if (modalType === 'edit') {
            vm.businessCycleIds = riskCriteriaItem.RiskCriteria.BusinessCycleIds.length ? riskCriteriaItem.RiskCriteria.BusinessCycleIds : [];
            vm.selectedBusinessCycles = riskCriteriaItem.RiskCriteria.BusinessCycleIds.map(id => vm.businessCyclesDataSource.find(cycle => cycle.ID === id));
        }
    }

    async function getRiskLevels() {
        vm.riskLevelsDataSourceLoading = true;
        let { data } = await riskLevelsData.getRiskLevels();
        vm.riskLevelsDataSource = data;
        vm.riskLevelsDataSourceLoading = false;

        if (modalType === 'edit') {
            vm.riskLevelIds = riskCriteriaItem.RiskCriteria.RiskLevelIds.length ? riskCriteriaItem.RiskCriteria.RiskLevelIds : [];
            vm.selectedRiskLevels = riskCriteriaItem.RiskCriteria.RiskLevelIds.map(id => vm.riskLevelsDataSource.find(level => level.Id === id));
        }
    }

    async function setupRiskDropdown() {

        vm.riskDropdownOptions = {
            filter: "contains",
            minLength: 3,
            virtual: {
                itemHeight: 26
            },
            height: 325,
            optionLabel: '-- Select a Risk --'
        };

        vm.riskDropdownDataSource = new kendo.data.DataSource({

            pageSize: standardGridPageSize,
            transport: {
                read: async (options) => {
                    const { data } = await ownershipGroupItemsDataService.getAvailableRisks(ownershipGroup.Id, vm.selectedRulesetId);

                    if (vm.selectedRiskType === 1) {
                        vm.riskDataSource = data.filter(risk => risk.RiskType === 'SOD');
                    } else if (vm.selectedRiskType === 2) {
                        vm.riskDataSource = data.filter(risk => risk.RiskType === 'SA');
                    } else {
                        vm.riskDataSource = data;
                    }

                    options.success(vm.riskDataSource);
                }
            }
        });

        return vm.riskDropdownDataSource;
    }

    function setupRiskCriteriaGrid() {
        vm.riskCriteriaGridColumns = [
            { field: "RiskName", title: "Risk", width: 120, filterable: customAutoCompleteFilter },
            { field: "RiskDescription", title: "Risk Description", width: 120, filterable: customAutoCompleteFilter }
        ];

        vm.riskCriteriaGridOptions = helperService.setMainGridOptions(vm.riskCriteriaGridColumns, 400);

        vm.riskCriteriaGridDataSource = new kendo.data.DataSource({
            schema: {
                data: "_embedded.reportData",
                total: "TotalItems"
            },
            pageSize: standardGridPageSize,
            serverPaging: true,
            serverFiltering: true,
            noRecords: {
                template: "<div class='no-data-to-display-message'>No items to display</div>"
            },
            transport: {
                read: async options => {
                    try {
                        let sortsAndFilters = await helperService.prepareSortsAndFilters(options);

                        if (vm.selectedRulesetId) {
                            sortsAndFilters.Filters.push({ FieldName: "RulesetId", Operator: "Equals", Value: vm.selectedRulesetId });
                        }

                        if (vm.selectedRiskType) {
                            sortsAndFilters.Filters.push({ FieldName: "RiskTypeId", Operator: "Equals", Value: vm.selectedRiskType });
                        }

                        if (vm.selectedRiskLevels) {
                            vm.riskLevelIds.forEach(levelId => {
                                sortsAndFilters.Filters.push({ FieldName: "RiskLevelId", Operator: "Equals", Value: levelId });
                            });                      
                        }

                        if (vm.selectedBusinessCycles) {
                            vm.businessCycleIds.forEach(cycleId => {
                                sortsAndFilters.Filters.push({ FieldName: "BusinessCycleId", Operator: "Equals", Value: cycleId });
                            });
                        }

                        const { page, pageSize } = options.data,
                            { data } = await ownershipGroupItemsDataService.getRisksByRiskCriteria(ownershipGroup.Id, sortsAndFilters, page, pageSize);

                        options.success(data);
                    } catch (err) {
                        options.error();
                    }
                }
            }
        });
    }

    function resetDropdowns() {
        vm.selectedOwners = null;
        vm.selectedRisk = null;
        vm.selectedRiskType = 0;
        vm.selectedRulesetId = vm.defaultRulesetId;
        vm.selectedRiskLevels = [];
        vm.selectedBusinessCycles = [];
        vm.riskCriteriaName = null;
    }

    function updateRiskInfo() {
        if (vm.riskCreationType === 'Risk') {
            vm.selectedRisk = null;
            vm.riskDropdown.value([]);
            vm.riskDropdownDataSource.read();
        } else {
            vm.riskLevelIds = vm.selectedRiskLevels.map(level => level.Id);
            vm.businessCycleIds = vm.selectedBusinessCycles.map(cycle => cycle.ID);
            if (vm.riskCriteriaGridDataSource) {
                vm.riskCriteriaGridDataSource.read();
            }
        }
    }

    async function getOwners() {
        vm.ownersDataSourceLoading = true;
        let response = await ownershipGroupOwnerDataService.getAllOwners(ownershipGroup.Id);
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.availableOwnersDataSource = response.data;
        vm.ownersDataSourceLoading = false;
        return vm.availableOwnersDataSource;
    }

    async function saveRisk() {
        vm.savePending = new createSpinner();
        let data,
            riskId;

        if (modalType === 'edit') {
            data = {
                ItemId: riskCriteriaItem.Id,
                RiskCriteriaName: vm.riskCriteriaName,
                RiskType: vm.selectedRiskType,
                RulesetId: vm.selectedRulesetId,
                RiskLevelIds: vm.riskLevelIds,
                BusinessCycleIds: vm.businessCycleIds
            };
        } else {
            data = {
                GroupId: ownershipGroup.Id,
                RiskCriteriaName: vm.riskCriteriaName,
                Owners: vm.selectedOwners,
                RiskIds: !vm.selectedRisk ? [] : [vm.selectedRisk],
                RiskType: vm.selectedRiskType,
                RulesetId: vm.selectedRulesetId,
                RiskLevelIds: vm.riskLevelIds,
                BusinessCycleIds: vm.businessCycleIds
            };
        }
        try {
            let response
            if (modalType === 'edit') {
                response = await ownershipGroupItemsDataService.updateRiskCriteria(ownershipGroup.Id, riskCriteriaItem.Id, data);              
            } else {
                response = await ownershipGroupItemsDataService.addItemToGroup(data, ownershipGroup.Id, ownershipGroup.GroupType);
            }
            riskId = response.data;
            await helperService.successfulSaveButton(vm.savePending);
            close(riskId);
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function selectedOwnerTypeChanged() {
        vm.selectedOwners = null;
        vm.filteredOwnersDataSource = vm.availableOwnersDataSource.filter(owner => owner.TypeName === vm.selectedOwnerType);
    }

    function close(bpId) {
        $uibModalInstance.close(bpId);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}