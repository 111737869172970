import FastpathUserListController from "./fastpathUserList.controller"; 

/* fastpathUserList.component.js */

/**
 * @desc component for displaying and selecting fastpath users in a list
 * @example <fastpath-user-list></fastpath-user-list>
 */

var fastpathUserList = {
    controller: FastpathUserListController,
    controllerAs: 'fulvm',
    templateUrl: '/App/Components/userRestrictions/fastpathUserList/fastpathUserList.tmpl.html'
};

export default fastpathUserList;