import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, salesforceAtDataService, helperService) {
    const vm = this;
    vm.cancel = cancel;
    vm.importObjects = importObjects;

    function cancel() {
        $uibModalInstance.close();
    }

    async function importObjects(file) {
        vm.pendingImport = new createSpinner();

        let fd = new FormData();
        fd.append(file[0].name, file[0].file);

        try {
            await salesforceAtDataService.importObjectData(fd);
            await helperService.successfulSaveButton(vm.pendingImport);
            cancel();
        } catch {
            vm.pendingImport.loadingValue = false;
            vm.disableButton = false;
        }
    }
}