export default /*@ngInject*/ function ($http, errorsDataService) {
    var service = {
        getAutoMitigations,
        getObjects,
        getObjectsByUrl,
        addAutoMitigation,
        modifyMitigation,
        removeAutoMitigation,
        applyAutoMitigations
    };

    return service;

    async function getAutoMitigations() {
        try {
            return await $http.get(apiUrl + 'api/assure/ps/automitigations');
        } catch (error) {
            showError(error);
        }
    }

    async function getObjects(body, params) {
        try {
            return await $http.post(apiUrl + 'api/universal/securityobjects/producttype/ps/type/Page', body, { params: params });
        } catch (error) {
            showError(error);
        }
    }

    async function getObjectsByUrl(url) {
        try {
            return await $http.get(url);
        } catch (error) {
            showError(error);
        }
    }

    async function addAutoMitigation(data) {
        try {
            return await $http.post(apiUrl + 'api/assure/ps/automitigations', data);
        } catch (error) {
            showError(error);
        }
    }

    async function modifyMitigation(autoMitigationId, data) {
        try {
            return await $http.put(apiUrl + 'api/assure/ps/automitigations/' + autoMitigationId, data);
        } catch (error) {
            showError(error);
        }
    }

    async function removeAutoMitigation(autoMitigationId) {
        try {
            return await $http.delete(apiUrl + 'api/assure/ps/automitigations/' + autoMitigationId);
        } catch (error) {
            showError(error);
        }
    }

    async function applyAutoMitigations() {
        try {
            return await $http.put(apiUrl + 'api/arm/usermitigations/product/PS/applyautomitigations');
        } catch (error) {
            showError(error);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}