export default /*@ngInject*/ function CriteriaSubmitButtonDropdownController(helperService, applicationInsightsService, $http, SavedReportSelectorService, $scope, $rootScope) {

    const vm = this;

    vm.exportToExcel = exportToExcel;
    vm.exportToPDF = exportToPDF;
    vm.exportToCSV = exportToCSV;

    let parentScope = $scope.$parent;

    vm.$onInit = activate;

    function activate() {
        if (vm.exportPropertiesContainer) {
            parentScope = vm.exportPropertiesContainer;
            parentScope.vm = {};
            parentScope.vm.filterCriteria = parentScope.filterCriteria;
        }
    }

    function exportToExcel(refreshModel) {
        parentScope.populateCriteriaModel();
        if (refreshModel) {
            parentScope.grid.dataSource.data([]);
            createExport('Excel', true);
        } else {
            var serverLength = parentScope.grid.dataSource._total;
            if (serverLength > 999999) {
                helperService.showErrorMessage('An export must be under 1,000,000 rows');
            } else {
                createExport('Excel', false);
            }
        }
    }

    function exportToPDF(refreshModel) {
        parentScope.populateCriteriaModel();
        if (refreshModel) {
            parentScope.grid.dataSource.data([]);
            createExport('PDF', true);
        } else {
            var serverLength = parentScope.grid.dataSource._total;
            createExport('PDF', false);
        }
    }

    function exportToCSV(refreshModel) {
        parentScope.populateCriteriaModel();
        if (refreshModel) {
            parentScope.grid.dataSource.data([]);
            createExport('Csv', true);
        } else {
            var serverLength = parentScope.grid.dataSource._total;
            createExport('Csv', false);
        }
    }

    function createExport(exportType, criteriaAndColumnsOnly) {
        if ($rootScope.tabs.length > 0) {
            let currentTab = $rootScope.tabs.find(tab => tab.id === $rootScope.currentSelectedTab.id);
            exportReport(currentTab.reportExportDetails, exportType, criteriaAndColumnsOnly);
        } else {
            exportReport(parentScope, exportType, criteriaAndColumnsOnly);
        }
    }

    function exportReport(reportInfo, exportType, criteriaAndColumnsOnly) {

        var exportName = reportInfo.reportName;

        applicationInsightsService.trackEvent('Export', { Name: reportInfo.reportName, ObjectId: reportInfo.ObjectId, Type: exportType });

        var reportDefinition = null;

        if (criteriaAndColumnsOnly) {
            reportDefinition = bundleReportDefinitionCriteriaAndColumnsOnly(reportInfo.grid.getOptions(), reportInfo.reportDetailsModel);
        } else {
            reportDefinition = bundleReportDefinition(reportInfo.grid.getOptions(), reportInfo.reportDetailsModel);
        }

        var postData = {
            "Name": exportName,
            "Format": exportType,
            "PreferredTimeZone": $rootScope.applicationTimeZone,
            "ReportDefinition": {
                "ReportId": reportInfo.reportId,
                "Columns": reportDefinition.Columns,
                "Sortings": reportDefinition.Sortings,
                "Criteria": reportInfo.sortsAndFilters != null && reportInfo.sortsAndFilters.Criteria != null ? reportDefinition.Criteria.concat(reportInfo.sortsAndFilters.Criteria) : reportDefinition.Criteria,
                "Filters": reportDefinition.Filters,
                "DateFilters": reportDefinition.DateFilters
            }
        };

        $http.post(apiUrl + 'api/core/exports', postData).then(function (response) {

            var fullExportName;
            if (exportType === 'Excel') {
                fullExportName = exportName + '.xlsx';
            } else if (exportType === 'PDF') {
                fullExportName = exportName + '.pdf';
            } else if (exportType === 'Csv') {
                fullExportName = exportName + '.csv';
            }

            response.data = { ExportId: response.data, ExportName: fullExportName };

        }, function (response) {
            helperService.showErrorMessage(response.data);
        });

    }

    function bundleReportDefinition(gridOptions, reportDetailsModel) {
        var reportDefinition = { 'Filters': null, 'Sortings': null, 'Columns': null, 'Criteria': null };
        reportDefinition.Sortings = helperService.halPrepareSortArray(gridOptions.dataSource.sort);
        reportDefinition.Columns = helperService.cleanReportColumns(gridOptions.columns);

        var filters = helperService.halPrepareFilterArray(gridOptions.dataSource.filter);
        // Needed for saving report until report toolbar is refactored
        if (parentScope.$parent.$parent.drvm) parentScope.vm.filterCriteria = parentScope.$parent.$parent.drvm.filterCriteria;
        if (parentScope.vm.filterCriteria) {
            if (parentScope.vm.filterCriteria.query) {
                let standardFilters = parentScope.vm.filterCriteria.query.filter(filter => {
                    return !filter.CriteriaValue;
                });
                filters = filters.concat(standardFilters);

                var criteriaToAdd = parentScope.vm.filterCriteria.query.filter(filter => {
                    return filter.CriteriaValue;
                });
            }
            if (parentScope.vm.filterCriteria.dateQuery) {
                reportDefinition.DateFilters = parentScope.vm.filterCriteria.dateQuery;
            }
            if (parentScope.sortsAndFilters) {
                if (parentScope.sortsAndFilters.DateFilters) {
                    reportDefinition.DateFilters = parentScope.sortsAndFilters.DateFilters.slice();
                }
                if (parentScope.dateCriteria && parentScope.sortsAndFilters.Filters.some(filter => filter.FieldName === parentScope.dateCriteria[0].FieldName)) {
                    const missingDateRangeFilters = parentScope.sortsAndFilters.Filters.filter(filter => filter.FieldName === parentScope.dateCriteria[0].FieldName);
                    filters = filters.concat(missingDateRangeFilters);
                }
            }
        }

        reportDefinition.Filters = filters;
        reportDefinition.Criteria = SavedReportSelectorService.cleanReportCriteria(reportDetailsModel, criteriaToAdd);

        return reportDefinition;
    }

    function bundleReportDefinitionCriteriaAndColumnsOnly(gridOptions, reportDetailsModel) {
        var reportDefinition = { 'Filters': null, 'Sortings': null, 'Columns': null, 'Criteria': null };
        reportDefinition.Columns = helperService.cleanReportColumns(gridOptions.columns);
        var filtersToAdd = [];
        var criteriaToAdd = [];

        if (parentScope.vm.filterCriteria && parentScope.vm.filterCriteria.query) {
            filtersToAdd = parentScope.vm.filterCriteria.query.filter(filter => {
                return !filter.CriteriaValue;
            });

            criteriaToAdd = parentScope.vm.filterCriteria.query.filter(filter => {
                return filter.CriteriaValue;
            });
        }

        if (parentScope.sortsAndFilters) {
            if (parentScope.sortsAndFilters.DateFilters) {
                reportDefinition.DateFilters = parentScope.sortsAndFilters.DateFilters.slice();
            }
            if (parentScope.dateCriteria && parentScope.sortsAndFilters.Filters.some(filter => filter.FieldName === parentScope.dateCriteria[0].FieldName)) {
                const missingDateRangeFilters = parentScope.sortsAndFilters.Filters.filter(filter => filter.FieldName === parentScope.dateCriteria[0].FieldName);
                filtersToAdd = filtersToAdd.concat(missingDateRangeFilters);
            }
        }

        reportDefinition.Filters = filtersToAdd;
        reportDefinition.Criteria = SavedReportSelectorService.cleanReportCriteria(reportDetailsModel, criteriaToAdd);
        return reportDefinition;
    }
}