import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($rootScope, helperService, manageDataDataService, configurationDataService, RequestManagementSAPService, ownershipGroupsDataService) {
    const vm = this,
        editRestrictedADProducts = ['NS', 'ORFC', 'OR', 'SAP', 'PS', 'SF'],
        editRestrictedAADProducts = ['AX7', 'NS', 'ORFC', 'OR', 'SAP', 'PS', 'SF'];

    vm.helperService = helperService;
    vm.configValueChanged = configValueChanged;
    vm.updateConfigValues = updateConfigValues;
    vm.updateSAPCollectionSchedule = updateSAPCollectionSchedule;

    vm.showProductSettings = false;

    vm.scheduleCollectionIsOn = null;
    vm.originalCollectionStatus = null;
    vm.configValuesDiffer = false;

    vm.$onInit = activate;

    async function activate() {
        vm.processingActionsDataSource = getProcessingActions();
        if (vm.configuration.IM_AutomaticDelegationDaysThreshold) {
            vm.automaticDelegations = true;
        }
        vm.configuration = { ...vm.configuration };
        vm.unchangedConfig = { ...vm.configuration };
        getProductConfiguration();
        if (vm.product.ProductType === 'SAP') {
            getSAPCollectionSchedule();
            vm.roleOwnershipGroupsDataSource = await getOwnershipGroups();
        }
        vm.showRestrictEditOfADFieldsConfiguration = () => editRestrictedADProducts.includes(vm.product.ProductType) && vm.configuration['AD_Active'] === "1";
        vm.showRestrictEditOfAADFieldsConfiguration = () => editRestrictedAADProducts.includes(vm.product.ProductType) && vm.configuration['AAD_Active'] === "1";
    }

    async function getProductConfiguration() {
        let { data } = await manageDataDataService.getConfigurationByProductId(vm.product.ProductID);
        vm.productConfiguration = data.reduce((acc, curr) => {
            acc[curr.Name] = curr.Value;
            return acc;
        }, {});

        vm.unchangedProductConfig = { ...vm.productConfiguration };

        if (vm.productConfiguration[vm.product.ProductType + '_ImDisableOnTerminate']) {
            vm.disableOnTerminateConfigName = vm.product.ProductType + '_ImDisableOnTerminate';
        } else {
            vm.disableOnTerminateConfigName = null;
        }
        if (vm.productConfiguration['IM_ShowRoleDescription']) {
            vm.showRoleDescriptionConfigName = 'IM_ShowRoleDescription';
        } else {
            vm.showRoleDescriptionConfigName = null;
        }
        if (vm.productConfiguration.IM_CollectUsage_DaysToCheck) {
            vm.product.SAP_IM_CollectUsage_DaysToCheck = parseInt(vm.productConfiguration.IM_CollectUsage_DaysToCheck);
        }

        setShowProductSettings();
    }

    async function getOwnershipGroups() {
        vm.roleOwnershipGroupsLoading = true;
        const { data } = await ownershipGroupsDataService.getOwnershipGroups();
        data.forEach(group => group.Id = group.Id.toString());
        vm.roleOwnershipGroupsLoading = false;
        return data;
    }

    function setShowProductSettings() {
        let hasProductType = ["AX7", "SAP", "NS"].some(type => type === vm.product.ProductType),
            showCreateRequests = vm.productConfiguration.IM_CreateRequestsFromCertReviews !== undefined,
            showImProvider = vm.productConfiguration.IM_Provider !== undefined,
            hasRestrictEdit = ["SF", "PS"].some(type => type === vm.product.ProductType) && (vm.configuration['AD_Active'] === "1" || vm.configuration['AAD_Active'] === "1");

        vm.showProductSettings = hasProductType || vm.disableOnTerminateConfigName || vm.showRoleDescriptionConfigName || showCreateRequests || showImProvider || hasRestrictEdit;
    }

    function getProcessingActions() {
        return [
            {
                Name: 'Set to Inactive',
                Id: '0'
            },
            {
                Name: 'Remove Access',
                Id: '1'
            },
            {
                Name: 'Set to Inactive & Remove Access',
                Id: '2'
            }
        ];
    }

    function configValueChanged() {
        vm.configValuesDiffer = JSON.stringify(vm.unchangedConfig) !== JSON.stringify(vm.configuration) || JSON.stringify(vm.unchangedProductConfig) !== JSON.stringify(vm.productConfiguration) || vm.scheduleCollectionIsOn !== vm.originalCollectionStatus || vm.scheduleCollectionIsOn && (JSON.stringify(vm.originalCollectionStartTime) !== JSON.stringify(vm.scheduleCollectionStartTime) || vm.product.SAP_IM_CollectUsage_DaysToCheck.toString() !== vm.productConfiguration.IM_CollectUsage_DaysToCheck);
    }

    async function updateConfigValues() {
        vm.updateProductConfigPending = new createSpinner();

        await makeUpdateCalls();
        vm.configValuesDiffer = false;
        await helperService.successfulSaveButton(vm.updateProductConfigPending);
    }

    function makeUpdateCalls() {
        // decide which call(s) to make
        if (vm.product.ProductType === 'SAP') {
            if (vm.originalCollectionStatus !== vm.scheduleCollectionIsOn || JSON.stringify(vm.originalCollectionStartTime) !== JSON.stringify(vm.scheduleCollectionStartTime)) {
                updateSAPCollectionSchedule();
            }
            if (vm.product.SAP_IM_CollectUsage_DaysToCheck.toString() !== vm.productConfiguration.IM_CollectUsage_DaysToCheck) {
                vm.productConfiguration.IM_CollectUsage_DaysToCheck = vm.product.SAP_IM_CollectUsage_DaysToCheck.toString();
            }
        }
        if (JSON.stringify(vm.unchangedConfig) !== JSON.stringify(vm.configuration)) {
            // we need to make the core config change call
            updateConfigurationSetting(vm.configuration, vm.unchangedConfig);
        }
        if (JSON.stringify(vm.unchangedProductConfig) !== JSON.stringify(vm.productConfiguration)) {
            // we need to make the product config change call
            updateProductConfiguration(vm.productConfiguration, vm.unchangedProductConfig)
        }
    }

    async function updateConfigurationSetting(value, origVal) {

        let putData = {
            "Configurations": []
        };

        let list = [
            { Name: "NS_ImRequireExistingEmployee", Encrypted: false },
            { Name: "NS_ImTerminateOption", Encrypted: false },
            { Name: "NS_ImTerminateSetReleaseDate", Encrypted: false },
            { Name: "MaxFirefighterAccessPeriod", Encrypted: false },
            { Name: "EmergencyAccessApproversGroup", Encrypted: false },
            { Name: "IM_RestrictRoleRequestsEA", Encrypted: false }
        ];

        list.forEach(item => {
            if (value[item.Name] !== origVal[item.Name]) {
                putData.Configurations.push({
                    "Name": item.Name,
                    "Value": value[item.Name]
                });
            }
        });

        try {
            await configurationDataService.updateConfigurationValues(putData);
            vm.unchangedConfig = { ...vm.configuration };
            const updatedConfiguration = { ...vm.configuration };
            vm.refreshCoreConfig({ updatedConfiguration });
        } catch {
            vm.updateProductConfigPending.loadingValue = false;
        }
    }

    async function updateProductConfiguration(value, origVal) {
        let putData = {
            "Configurations": [],
            "ProductId": vm.product.ProductID
        };

        let list = [
            { Name: "AAD_ImDisableOnTerminate", Encrypted: false },
            { Name: "AX_ImDisableOnTerminate", Encrypted: false },
            { Name: "AX7_ImDisableOnTerminate", Encrypted: false },
            { Name: "IM_RemoveRoleLastCompanyRemoved", Encrypted: false },
            { Name: "IM_ShowRoleDescription", Encrypted: false },
            { Name: "NAV_ImDisableOnTerminate", Encrypted: false },
            { Name: "GP_ImDisableOnTerminate", Encrypted: false },
            { Name: "ORFC_ImDisableOnTerminate", Encrypted: false },
            { Name: "SAP_ImDisableOnTerminate", Encrypted: false },
            { Name: "IM_Provider", Encrypted: false },
            { Name: "IM_CreateRequestsFromCertReviews", Encrypted: false },
            { Name: "IM_CollectUsage_DaysToCheck", Encrypted: false }, 
            { Name: "IM_EnableOrgHierarchies", Encrypted: false },
            { Name: "IM_EnableUserGroups", Encrypted: false },
            { Name: "IM_DisableAADUserFields", Encrypted: false },
            { Name: "IM_DisableADUserFields", Encrypted: false }
        ];

        list.forEach(item => {
            if (value[item.Name] !== origVal[item.Name]) {
                putData.Configurations.push({
                    "Name": item.Name,
                    "Value": value[item.Name]
                });
            }
        });

        try {
            await manageDataDataService.setProductConfiguration(vm.product.ProductID, putData);
            vm.unchangedProductConfig = { ...vm.productConfiguration };
        } catch {
            vm.updateProductConfigPending.loadingValue = false;
        }
    }

    async function getSAPCollectionSchedule() {
        try {
            let response = await RequestManagementSAPService.getCollectionUsageSchedule(vm.product.ProductID)
            vm.collectionSchedule = response.data;
            vm.savedCollectionStartTime = new Date(response.data.StartTime);
            vm.scheduleCollectionStartTime = vm.savedCollectionStartTime;
            vm.originalCollectionStartTime = new Date(response.data.StartTime);
            vm.equal = true;
            vm.scheduleCollectionIsOn = true;
            vm.originalCollectionStatus = true;
        } catch (err) {
            vm.collectionSchedule = null;
            vm.scheduleCollectionStartTime = new Date();
            vm.scheduleCollectionIsOn = false;
            vm.originalCollectionStatus = false;
        }
    }

    async function updateSAPCollectionSchedule() {
        if (vm.scheduleCollectionIsOn) {
            vm.collectionScheduleUpdateInProgress = new createSpinner();
            const postData = {
                "StartTime": vm.scheduleCollectionStartTime.toUTCString(),
                "TimeZone": $rootScope.applicationTimeZone,
                "ProductId": vm.product.ProductID
            };

            try {
                await RequestManagementSAPService.setCollectionUsageSchedule(vm.product.ProductID, postData);
                await getSAPCollectionSchedule();
                helperService.successfulSaveButton(vm.collectionScheduleUpdateInProgress);
            } catch (err) {
                vm.collectionScheduleUpdateInProgress.loadingValue = false;
            }

        } else {
            if (vm.collectionSchedule) {
                try {
                    await RequestManagementSAPService.deleteCollectionUsageSchedule(vm.product.ProductID);
                    await getSAPCollectionSchedule();
                } catch (err) {
                    vm.collectionScheduleUpdateInProgress.loadingValue = false;
                }
            }
        }
    }
}