/* classesList.component.js */

import classesListController from "./classesList.controller";

/**
 * @desc component for displaying and creating Classes assigned to Workday Templates
 * @example <workday-classes-list></workday-classes-list>
 */

const classesList = {
    controller: classesListController,
    controllerAs: 'clvm',
    templateUrl: '/App/Components/workdayMaintainTemplates/classesList/classesList.tmpl.html',
    bindings: {
        selectedTemplate: '<'
    }
};

export default classesList;