import { standardGridPageSize, customAutoCompleteFilter } from "../../GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $filter, helperService, userRolesDataService, dataService, productId, requestAction, excludeRoles, objectTypeName) {

    var vm = this;
    vm.cancel = cancel;
    vm.close = close;
    vm.changeUserRowSelected = changeUserRowSelected;
    vm.productId = productId;
    vm.requestAction = requestAction;
    vm.excludeRoles = excludeRoles;
    vm.objectTypeName = objectTypeName;

    function activate() {
        configureUsersGrid();
    }

    activate();

    function cancel() { $uibModalInstance.dismiss(); }

    function close(selectedUser) { $uibModalInstance.close(selectedUser); }

    function configureUsersGrid() {
        const usersGridColumns = [
            { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
            { title: "Id", field: "ID", filterable: customAutoCompleteFilter },
            { title: "Description", field: "Description", filterable: customAutoCompleteFilter }
        ];

        vm.usersGridOptions = helperService.setMainGridOptions(usersGridColumns, null);

        vm.usersGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let response;

                    // check for product - get master roles list if no product
                    if (vm.productId) {
                        response = await userRolesDataService.getUsers(vm.productId)
                    } else {
                        response = await dataService.getUserMaster();
                    }

                    response.data = $filter('orderBy')(response.data, 'Name');
                    options.success(response.data);
                }
            }
        });
    }

    function changeUserRowSelected(kendoEvent) {
        var grid = kendoEvent.sender;
        vm.selectedUser = grid.dataItem(grid.select());
        if (vm.keepCurrentRoles) {
            vm.selectedUser.keepCurrent = vm.keepCurrentRoles;
        }
    }
}