/* maintainStatuses.component.js */

import maintainStatusesController from "./maintainStatuses.controller";

/**
 * @desc component to display Maintain Statuses
 * @example <maintain-statuses></maintain-statuses>
 */

const maintainStatuses = {
    controller: maintainStatusesController,
    controllerAs: 'msvm',
    templateUrl: '/App/Components/maintainStatuses/maintainStatuses.html'
};

export default maintainStatuses;