import swal from "sweetalert2";
import selectUserModalController from "./selectUserModal.controller";

export default /*@ngInject*/ function ($uibModal, userRolesDataService, helperService) {

    var vm = this;
    vm.$onInit = activate;

    vm.openUserSelectionModal = openUserSelectionModal;

    function activate() {
        if (!vm.productId) {
            vm.productId = vm.stringProductId;
        }
        if (vm.altText) {
            vm.objectTypeName = vm.altText;
        } else {
            vm.objectTypeName = "Roles";
        }
    }

    async function openUserSelectionModal() {
        let userSelectionModal = $uibModal.open({
            templateUrl: '/App/Shared/Components/selectRolesByUser/selectUserModal.html',
            controller: selectUserModalController,
            controllerAs: 'sumvm',
            backdrop: 'static',
            resolve: {
                productId: () => vm.productId,
                requestAction: () => vm.requestAction,
                excludeRoles: () => vm.excludeRoles,
                objectTypeName: () => vm.objectTypeName
            }
        });

        try {
            const user = await userSelectionModal.result;
            await getRoles(user);
        } catch (err) {
            return;
        }
    }

    async function getRoles(user) {
        // get roles based on user, set vm.roles to response
        let response, unavailableRoles = [];
        if (vm.productId) {
            response = await userRolesDataService.getRolesByUserId(vm.productId, user.ID, vm.excludeRoles);
        } else {
            response = await userRolesDataService.getRolesByUserMasterId(user.ID, vm.excludeRoles);
        }
        if (vm.availableRoles && vm.availableRoles.length > 0) {
            if (!user.keepCurrent && vm.roles.length > 0) {
                if (vm.roles[0].Role) {
                    vm.roles = vm.roles.map(role => role.Role);
                }
                removeCurrentRoles(vm.roles);
            }
            if (vm.productId !== 'PS') {
                // we can't do anything with roles that don't have the Id field so we will remove them for the purposes of this component
                vm.availableRoles = vm.availableRoles.filter(role => role.Id);
            }
            // need to select roles from available roles list
            response.data.forEach(role => {
                // toUpperCase to match casing of string Ids
                // checking if this role is already selected for user
                if (!isRoleSelected(vm.roles, role)) {
                    let availableRoleIndex = getAvailableRoleIndex(role, vm.availableRoles), availableRole = getAvailableRole(role, vm.availableRoles);

                    if (availableRoleIndex > -1) {
                        if (role.ProductType === "GP") {
                            if (!vm.storedRoles) { vm.storedRoles = []; }
                            let roleCompanies = role.Companies.map(company => {
                                let roleCompany = {
                                    Id: company.ID,
                                    Name: company.Name
                                };
                                return roleCompany;
                            });
                            roleCompanies.forEach(roleCompany => {
                                if (!isRoleSelected(vm.roles, availableRole) && roleCompany.Id === vm.selectedCompany.Id.toString()) {
                                    availableRole = { Role: availableRole, Company: roleCompany };
                                    vm.storedRoles.unshift(availableRole);
                                    addRole(availableRole, availableRoleIndex);
                                }
                            });
                        } else if ((role.Companies.length > 0) && vm.companies !== undefined) {
                            availableRole.Companies = [];
                            if ((role.ProductType === 'AX' || role.ProductType === 'AX7') && role.Companies.length === vm.companies.length) {
                                availableRole.Companies = null;
                            } else {
                                role.Companies.forEach(rc => {
                                    let company = undefined;
                                    if (role.ProductType === "NAV") {
                                        company = vm.companies.find(co => co.Id === rc.ID).Id;
                                    } else {
                                        company = vm.companies.find(co => co.CompanyId === rc.ID);
                                    }
                                    if (company) {
                                        availableRole.Companies.unshift(company);
                                    }
                                });
                            }
                            addRole(availableRole, availableRoleIndex);
                            if (vm.validateRoles) {
                                vm.validateRoles();
                            }
                        } else {
                            addRole(availableRole, availableRoleIndex);
                            // Call validate roles function from roles controller to enable the next button
                            if (vm.validateRoles) {
                                vm.validateRoles();
                            }
                        }
                    } else {
                            unavailableRoles.push(role);
                    }
                }
            });

            //we only support copying Permission Sets for SF, not Profile so exclude those from the Unavailable list
            if (unavailableRoles.length && vm.productId === 'SF') {
                unavailableRoles = unavailableRoles.filter(role => role.TypeID === 'Permission Set');
            }

            if (unavailableRoles.length) {
                unavailableRoles = unavailableRoles.map(unavailableRole => unavailableRole.Name).join(", ");
                let title = `${vm.altText ? vm.altText : 'Roles'} not added`, html = `<div>The following ${vm.altText ? vm.altText : 'Roles'} could not be added</div>
                <strong>${unavailableRoles}</strong>`;

                if (vm.roles.length) {
                    title = `Some ` + title;
                }

                swal({ title: title, html: html, type: 'warning', showCancelButton: false, confirmButtonText: 'OK' })
            }
        } else {
            vm.roles = response.data;
            if (vm.toggleIntraResponsibility) {
                // Need to ensure intra responsibility toggle is set correctly
                const selection = vm.roles;
                vm.toggleIntraResponsibility({ selection });
            }
        }
    }

    function isRoleSelected(selectedRoles, role) {
        if (selectedRoles.length === 0) {
            return false;
        }

        if (selectedRoles[0].Role) {
            if (role.Role) {
                return selectedRoles.some(r => r.Role.Identifier === role.Role.Identifier);
            } else {
                return selectedRoles.some(r => r.Role.Identifier === role.Identifier);
            }
        } else {
            return selectedRoles.some(r => r.Identifier === role.Identifier);
        }   
    }

    function removeCurrentRoles(roles) {
        let newRoles = roles.slice();
        newRoles.forEach(role => {
            let existingRoleIndex = roles.findIndex(er => er.Identifier === role.Identifier);
            roles.splice(existingRoleIndex, 1);
            if (role.Role) {
                vm.availableRoles.unshift(role.Role);
            } else {
                vm.availableRoles.unshift(role);
            }
            if (vm.storedRoles && vm.storedRoles.length > 0) {
                let storedRoleIndex = vm.storedRoles.findIndex(sr => {
                    return sr.Role.Identifier === role.Identifier && sr.Company.Id.toString() === vm.selectedCompany.Id.toString();
                });
                vm.storedRoles.splice(storedRoleIndex, 1);
            }
        });
    }

    function getAvailableRoleIndex(role, availableRoles) {
            return availableRoles.findIndex(ar => ar.Identifier === role.Identifier);
    }

    function getAvailableRole(role, availableRoles) {
            return availableRoles.find(ar => ar.Identifier=== role.Identifier);
    }

    function addRole(role, roleIndex) {
        vm.roles.unshift(role);
        vm.availableRoles.splice(roleIndex, 1);
    }

}
