import createSpinner from "../../../../Shared/Functions/createSpinner";
import { standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $filter, ownershipGroup, helperService, ownershipGroupItemsDataService, riskRulesetService, ownershipGroupOwnerDataService) {
    const vm = this;

    vm.saveBusinessProcessOwner = saveBusinessProcessOwner;
    vm.selectedOwnerTypeChanged = selectedOwnerTypeChanged;
    vm.setupBusinessProcessDropdown = setupBusinessProcessDropdown;
    vm.changeRuleset = changeRuleset;
    vm.cancel = cancel;

    vm.ownershipGroup = { ...ownershipGroup };

    activate();

    async function activate() {
        await getRulesets();
        await getOwners();

        vm.selectedOwnerType = 'User';
        selectedOwnerTypeChanged();
    }

    async function getRulesets() {
        let { data } = await riskRulesetService.getRiskRulesetList();
        vm.rulesets = data;

        vm.selectedRuleset = vm.rulesets.find(ruleset => ruleset.IsDefault).RulesetId;
        setupBusinessProcessDropdown();
    }

    function changeRuleset() {
        vm.selectedBusinessProcesses = null;
        vm.businessProcessDropdownDataSource.read();
    }

    async function setupBusinessProcessDropdown() {

        vm.businessProcessDropdownOptions = {
            filter: "contains",
            autoClose: false,
            virtual: {
                itemHeight: 26,
                mapValueTo: 'dataItem',
                valueMapper: function (options) {
                    options.success(helperService.multiValueMapperFunction(options, vm.businessProcessDataSource, 'BusinessProcessId'));
                }
            },
            height: 325
        };

        vm.businessProcessDropdownDataSource = new kendo.data.DataSource({

            pageSize: standardGridPageSize,
            transport: {
                read: async (options) => {
                    const { data } = await ownershipGroupItemsDataService.getAllBusinessProcesses(ownershipGroup.Id, vm.selectedRuleset);
                    vm.businessProcessDropdown.setOptions({ placeholder: `-- Select Business Processes --` });
                    vm.businessProcessDataSource = data;
                    options.success(data);
                }
            }
        });

        return vm.businessProcessDropdownDataSource;
    }

    async function getOwners() {
        vm.ownersDataSourceLoading = true;
        let { data } = await ownershipGroupOwnerDataService.getAvailableOwners(ownershipGroup.Id, ownershipGroup.GroupType);
        data = $filter('orderBy')(data, 'Name');
        vm.availableOwnersDataSource = data;
        vm.ownersDataSourceLoading = false;
    }

    async function saveBusinessProcessOwner() {
        vm.savePending = new createSpinner();
        let newOwnerId;
        let postData = {
            GroupId: ownershipGroup.Id,
            Owners: [vm.selectedOwner],
            BusinessProcessIds: vm.selectedBusinessProcesses
        };

        try {
            let { data } = await ownershipGroupOwnerDataService.addOwnerToGroup(ownershipGroup.Id, ownershipGroup.GroupType, postData);
            newOwnerId = data;
            await helperService.successfulSaveButton(vm.savePending);
            close(newOwnerId);
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function selectedOwnerTypeChanged() {
        vm.selectedOwners = null;
        vm.filteredOwnersDataSource = vm.availableOwnersDataSource.filter(owner => owner.TypeName === vm.selectedOwnerType);
    }

    function close(newOwnerId) {
        $uibModalInstance.close(newOwnerId);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}