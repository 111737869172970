import swal from "sweetalert2";
import compositeRoleModalController from "./compositeRoleModal.controller";

export default /*@ngInject*/ function (sapSdSimCompositeRoleDataService, helperService, $uibModal, $filter) {

    const vm = this;

    vm.changeSelectedCompositeRole = changeSelectedCompositeRole;
    vm.openCompositeRoleModal = openCompositeRoleModal;
    vm.deleteCompositeRole = deleteCompositeRole;
    vm.changeRoleType = changeRoleType;

    vm.menuOptions = [
        {
            text: 'Edit',
            click: item => {
                vm.openCompositeRoleModal('EditExisting', item.compositeRole);
            },
            displayed: function (modelValue) {
                return modelValue.compositeRole.IsCustom;
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: item => {
                vm.deleteCompositeRole(item.compositeRole);
            }
        }
    ];


    vm.$onChanges = activate;

    async function activate() {
        vm.selectedCompositeRole = null;
        getCompositeRoles();
    }

    async function getCompositeRoles() {
        vm.compositeRolesLoading = true;
        const params = {
            isModified: true
        };
        let response = await sapSdSimCompositeRoleDataService.getSimulationCompositeRoles(vm.simulation.Id, params);
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.compositeRoles = response.data;
        vm.compositeRolesLoading = false;
    }

    async function changeSelectedCompositeRole(compositeRole) {
        vm.selectedCompositeRole = compositeRole;
        vm.onSelectedCompositeRoleChange({ compositeRole });
    }

    async function openCompositeRoleModal(actionType, compositeRole) {
        let compositeRoleModal = $uibModal.open({
            templateUrl: '/App/Components/SAPSecurityDesigner/simulationCompositeRolesList/compositeRoleModal.tmpl.html',
            controller: compositeRoleModalController,
            controllerAs: 'crmvm',
            backdrop: 'static',
            resolve: {
                simulation: () => vm.simulation,
                actionType: () => actionType,
                compositeRole: () => compositeRole
            }
        });

        try {
            await compositeRoleModal.result;
            refreshSimulations();
            await getCompositeRoles();
        } catch { return; }
    }

    async function deleteCompositeRole(compositeRole) {
        try {
            await swal(helperService.areYouSureParams(`Delete Composite Role`, `Are you sure you want to delete composite role ${compositeRole.Name}?`, `Yes`, 'No'));
            vm.compositeRolesLoading = true;
            await sapSdSimCompositeRoleDataService.deleteCompositeRole(vm.simulation.Id, compositeRole.Id);
            changeSelectedCompositeRole(null);
            refreshSimulations();
            getCompositeRoles();
        } catch {
            return;
        }
    }

    function changeRoleType() {
        let roleType = vm.selectedRoleType;
        vm.onRoleTypeChange({ roleType });
    }

    function refreshSimulations() {
        const updateList = true;
        vm.onUpdateSimulationsList({ updateList });
    }
}