import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($scope, $rootScope, $http, helperService) {

    $scope.init = function (productRoute, actionRoute) {
        if (productRoute) {
            $scope.productRoute = productRoute;

            if (productRoute === 'sap') {
                productRoute = 'sap/sap';
            }

            $scope.getScheduleUrl = apiUrl + 'api/audittrail/' + productRoute + '/' + actionRoute + '/schedule';
            $scope.putScheduleUrl = apiUrl + 'api/audittrail/' + productRoute + '/' + actionRoute + '/schedule';
            $scope.deleteScheduleUrl = apiUrl + 'api/audittrail/' + productRoute + '/' + actionRoute + '/schedule/disable';
        } else {
            $scope.getScheduleUrl = apiUrl + 'api/audittrail/auditdata/' + actionRoute + '/schedule';
            $scope.putScheduleUrl = apiUrl + 'api/audittrail/auditdata/' + actionRoute + '/schedule';
            $scope.deleteScheduleUrl = apiUrl + 'api/audittrail/auditdata/' + actionRoute + '/schedule/disable';
        }

        vm.getImportSchedule();
    };

    var vm = $scope.vm = {};

    vm.frequencies = ['Daily', 'Weekly', 'Monthly'];

    vm.daysInWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    vm.daysInMonth = helperService.getDayOfMonthDropdownOptions();

    vm.startTimeForScheduleImport = new Date();

    vm.getImportSchedule = function () {
        $http.get($scope.getScheduleUrl).then(function (response) {
            vm.importSchedule = response.data;
            vm.selectedFreqForScheduleImport = response.data.Frequency;
            vm.selectedDayOfWeekForScheduleImport = response.data.DayOfWeek;
            vm.selectedDayOfMonthForScheduleImport = { Value: response.data.DayOfMonth };
            vm.startTimeForScheduleImport = new Date(response.data.StartTime);
            vm.ScheduleImportIsOn = true;
        }, function () {
            vm.importSchedule = null;
            vm.ScheduleImportIsOn = false;
        });
    };

    vm.scheduleImportStatusChanged = function () {
        if (!vm.ScheduleImportIsOn && vm.importSchedule) {
            vm.updateScheduleImport(false);
        }
    };

    vm.updateScheduleImport = function (isOn) {
        vm.updateSchedule(isOn, vm.selectedFreqForScheduleImport, vm.selectedDayOfWeekForScheduleImport, vm.selectedDayOfMonthForScheduleImport, vm.startTimeForScheduleImport, $scope.putScheduleUrl, $scope.deleteScheduleUrl, vm.importSchedule);
    };

    vm.updateSchedule = function (scheduleIsOn, frequency, dayOfWeek, dayOfMonth, startTime, scheduleUrl, disableUrl, downloadedSchedule) {


        vm.updateInProgress = new createSpinner();

        if (scheduleIsOn) {

            var dayOfMonthRequest;
            var dayOfWeekRequest;

            if (frequency === 'Daily') {
                dayOfMonthRequest = [];
                dayOfWeekRequest = [];
            } if (frequency === 'Weekly') {
                dayOfMonthRequest = [];
                dayOfWeekRequest = [dayOfWeek];
            } if (frequency === 'Monthly') {
                dayOfMonthRequest = [dayOfMonth.Value];
                dayOfWeekRequest = [];
            }

            var putData = {
                "Schedule": {
                    "DayOfMonth": dayOfMonthRequest[0],
                    "DayOfWeek": dayOfWeekRequest[0],
                    "Frequency": frequency,
                    "StartTime": startTime.toUTCString(),
                    "TimeZone": $rootScope.applicationTimeZone
                },
                "ProductId": $scope.productRoute
            };

            $http.put(scheduleUrl, putData).then(function (response) {
                vm.getImportSchedule();
                helperService.successfulSaveButton(vm.updateInProgress);
            }, function (response) {
                helperService.showErrorMessage(response.data);
                vm.updateInProgress.loadingValue = false;
            });
        } if (!scheduleIsOn) {
            if (downloadedSchedule) {
                $http.put(disableUrl, {}).then(function (response) {
                    vm.getImportSchedule();
                    vm.updateInProgress.loadingValue = false;
                }, function (response) {
                    helperService.showErrorMessage(response.data);
                    vm.updateInProgress.loadingValue = false;
                });
            }
        }
    };

}
