import domainModalController from "./domainModal.controller";

export default /*@ngInject*/ function ($http, $uibModal, helperService) {

    const vm = this;

    vm.$onInit = activate;

    vm.openDomainModal = openDomainModal;
    vm.deleteDomain = deleteDomain;

    function activate() {
        getDomains();
    }

    async function getDomains() {
        let response = await $http.get(`${apiUrl}/api/plugins/activedirectory/domains`);
        vm.domainsDataSource = response.data;
    }

    async function openDomainModal(domain) {
        const domainModal = $uibModal.open({
            templateUrl: '/App/Components/Connections/activeDirectoryDomains/domainModal.html',
            controller: domainModalController,
            controllerAs: 'dmvm',
            backdrop: 'static',
            resolve: {
                domain: () => domain || {}
            }
        });

        try {
            await domainModal.result;
            getDomains();
        } catch {
            return;
        }
    }

    async function deleteDomain(domainId) {
        try {
            await $http.delete(`${apiUrl}/api/plugins/activedirectory/domains/${domainId}`);
            getDomains();
        } catch (error) {
            helperService.showErrorMessage(error.data);
        }
    }

}