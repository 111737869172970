export default function () {
    //Convert User and Role start/end dates to Date type
    this.convertRequestDates = function (request) {
        convertRequestDatesHelper(request);
    };
    //Helper function that finds all Start/End Dates in the request and converts them to a Date type
    function convertRequestDatesHelper(obj) {
        for (var property in obj) {
            if (obj.hasOwnProperty(property)) {
                if (typeof obj[property] === "object") {
                    convertRequestDatesHelper(obj[property]);
                }
                else {
                    if (property === "StartDate" && obj[property] != null) {
                        obj[property] = new Date(obj[property]);
                    }
                    if (property === "EndDate" && obj[property] != null) {
                        obj[property] = new Date(obj[property]);
                    }
                }
            }
        }
    }

    //Convert User and Role dates to local time
    this.convertDatesToLocal = function (request) {
        convertDatesToLocalHelper(request);
    };
    //Helper function that loops thru request and finds all Start/End dates and convert them to local time
    function convertDatesToLocalHelper(obj) {
        for (var property in obj) {
            if (obj.hasOwnProperty(property)) {
                if (typeof obj[property] === "object")
                { convertDatesToLocalHelper(obj[property]); }
                else {
                    if (property === "StartDate" && obj[property] != null) {
                        obj[property] = convertTolocalISOTime(obj[property]);
                    }
                    if (property === "EndDate" && obj[property] != null) {
                        obj[property] = convertTolocalISOTime(obj[property]);
                    }
                }
            }
        }
    }

    //Helper function to convert UTC dates to local date time
    var convertTolocalISOTime = function (date) {
        var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        var localISOTime = (new Date(new Date(date) - tzoffset)).toISOString().slice(0, -1);

        return localISOTime;
    };
}