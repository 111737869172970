import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, reviewItem, notesRequired, allowCancel, helperService) {
    const vm = this;
    
    vm.reviewItem = reviewItem ? { ...reviewItem } : null;
    vm.notesRequired = notesRequired;
    vm.allowCancel = allowCancel;

    vm.saveNotes = saveNotes;
    vm.dismiss = dismiss;

    function dismiss() {
        $uibModalInstance.dismiss();
    }

    async function saveNotes() {
        vm.savingNotes = new createSpinner();

        await helperService.successfulSaveButton(vm.savingNotes);
        $uibModalInstance.close(vm.reviewItem.Notes);
    }

}