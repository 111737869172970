import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (sapAuthFieldGroupsDataService, $uibModalInstance, group, helperService, isEditingValues, objectId) {
    const vm = this;

    vm.dismiss = dismiss;
    vm.createFieldValue = createFieldValue;
    vm.deleteFieldValue = deleteFieldValue;
    vm.groupFormSubmit = groupFormSubmit;

    vm.group = { ...group };
    vm.isEditingValues = isEditingValues;
    vm.objectId = objectId;

    function dismiss() {
        $uibModalInstance.dismiss();
    }

    async function createFieldValue(newField) {        
        vm.fieldProcessing = true;
        let data = newField;
        newField.ProcessObjectId = vm.group.ProcessObjectId;
        newField.AuthFieldGroupId = vm.group.Id;
        try {
            let response = await sapAuthFieldGroupsDataService.createFieldValue(data);
            newField.Id = response.data;
            vm.group.AuthFieldValues.push(newField);
            vm.newField = null;
            vm.fieldProcessing = false;
        } catch (error) {
            vm.fieldProcessing = false;
        }
    }

    async function deleteFieldValue(field) {
        vm.fieldProcessing = true;
        try {
            await sapAuthFieldGroupsDataService.deleteFieldValue(field.Id);
            const fieldIndex = vm.group.AuthFieldValues.findIndex(value => value.Id === field.Id);
            vm.group.AuthFieldValues.splice(fieldIndex, 1);
            vm.fieldProcessing = false;
        } catch {
            vm.fieldProcessing = false;
        }
    }

    async function updateGroupInfo() {
        let data = { ...vm.group };
        delete data.AuthFieldValues;
        delete data.$$hashKey;
        try {
            await sapAuthFieldGroupsDataService.updateGroup(data);
            await helperService.successfulSaveButton(vm.saving);
            $uibModalInstance.close();
        } catch {
            vm.saving.loadingValue = false;
        }
    }

    async function createGroup() {
        let data = { ...vm.group };
        data.ProcessObjectId = vm.objectId;
        try {
            await sapAuthFieldGroupsDataService.createGroup(data);
            await helperService.successfulSaveButton(vm.saving);
            $uibModalInstance.close();
        } catch {
            vm.saving.loadingValue = false;
        }
    }

    function groupFormSubmit() {
        vm.saving = new createSpinner();
        if (vm.group.Id) {
            updateGroupInfo();
        } else {
            createGroup();
        }
    }
}