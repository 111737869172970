export default /*@ngInject*/ function (authenticationService, notificationsDataService, dataService, $filter) {

    const vm = this;

    vm.type = null;
    vm.notificationTypes = [];
    vm.allSubscriptions = [];
    vm.mySubscriptions = [];

    vm.getSubscriptions = getSubscriptions;
    vm.getNotificationTypes = getNotificationTypes;
    vm.setupToggleState = setupToggleState;

    vm.$onInit = activate();

    async function activate() {

        vm.loading = true;

        try {
            await getProducts();
            await getSubscriptions();
            await getNotificationTypes();
            setupToggleState();
            vm.loading = false;
        } catch (_) {
            vm.loading = false;
        }
    }

    // Pass to my notifications component
    async function getNotificationTypes() {
        const res = await notificationsDataService.getNotificationTypes();
        res.data = $filter('orderBy')(res.data, 'Name');
        vm.notificationTypes = res.data;
    }

    // Pass to modal controller and component
    async function getSubscriptions() {
        const currentUser = await authenticationService.getUser();
        const res = await notificationsDataService.getSubscriptions();
        vm.allSubscriptions = res.data.filter(s => s.UserId !== currentUser.userId);
        vm.mySubscriptions = res.data.filter(s => s.UserId === currentUser.userId);
    }

    async function getProducts() {
        const res = await dataService.getProducts();
        vm.products = res.data;
    }

    function setupToggleState() {
        vm.notificationTypes.forEach(type => {
            if (type.HasProductFilter) {
                vm.products.forEach(product => {
                    type[product.ID] = { Notifications: null };
                    if (vm.mySubscriptions.length > 0 && vm.mySubscriptions.some(subscription => subscription.ProductId === product.ID && subscription.NotificationTypeId === type.Id)) {
                        type[product.ID].Notifications = true;
                    } else {
                        type[product.ID].Notifications = false;
                    }
                });
            } else {
                type.Notifications = null;
                if (vm.mySubscriptions.length > 0 && vm.mySubscriptions.some(subscription => subscription.NotificationTypeId === type.Id)) {
                    type.Notifications = true;
                } else {
                    type.Notifications = false;
                }
            }
        });
    }

}