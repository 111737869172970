import swal from "sweetalert2";
import groupOwnerRoleModalController from "./groupOwnerRoleModal/groupOwnerRoleModal.controller";
import groupOwnerProductModalController from "./groupOwnerProductModal/groupOwnerProductModal.controller";
import groupOwnerBusinessProcessModalController from "./groupOwnerBusinessProcessModal/groupOwnerBusinessProcessModal.controller";
import groupOwnerRiskModalController from "./groupOwnerRiskModal/groupOwnerRiskModal.controller";
import groupOwnerCompaniesModalController from "./groupOwnerCompaniesModal/groupOwnerCompaniesModal.controller";


export default /*@ngInject*/ function (ownershipGroupOwnerDataService, helperService, $uibModal, $filter) {

    const vm = this;

    vm.changeSelectedOwner = changeSelectedOwner;
    vm.openGroupOwnerItemModal = openGroupOwnerItemModal;
    vm.removeOwnerFromGroup = removeOwnerFromGroup;
    vm.changeGroupItemType = changeGroupItemType;

    vm.$onChanges = activate;

    async function activate() {
        vm.selectedOwner = null;
        await getOwners();
        vm.ownersRadioFilter = null;
    }

    async function getOwners() {
        vm.ownersLoading = true;
        let response = await ownershipGroupOwnerDataService.getGroupOwners(vm.selectedOwnershipGroup.Id, vm.selectedOwnershipGroup.GroupType);
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.owners = response.data;
        vm.ownersLoading = false;
        return vm.owners;
    }

    async function changeSelectedOwner(owner) {
        vm.selectedOwner = owner;
        vm.onSelectedOwnerChange({ owner });
    }

    async function removeOwnerFromGroup(owner) {
        try {
            await ownershipGroupOwnerDataService.removeOwnerFromOwnershipGroup(vm.selectedOwnershipGroup.Id, vm.selectedOwnershipGroup.GroupType, owner.Id);
            if (owner.Id === vm.selectedOwner.Id) {
                vm.selectedOwner = null;
                changeSelectedOwner(null);
            }
            getOwners();
        } catch {
            return;
        }
    }

    function changeGroupItemType() {
        let groupItemType = vm.selectedGroupItemType;
        vm.onGroupItemTypeChange({ groupItemType });
    }

    function openGroupOwnerItemModal() {
        switch (vm.selectedOwnershipGroup.GroupType) {
            case 'role':
                openGroupOwnerRoleModal();
                break;
            case 'product':
                openGroupOwnerProductModal();
                break;
            case 'businessprocess':
                openGroupOwnerBusinessProcessModal();
                break;
            case 'risk':
                openGroupOwnerRiskModal();
                break;
            case 'company':
                openGroupOwnerCompaniesModal();
                break;
            default:
                break;
        }
    }

    // Group Owner Item Modals
    async function openGroupOwnerRoleModal() {
        let ownerModal = $uibModal.open({
            templateUrl: '/App/Components/OwnershipGroups/ownershipGroupOwnersList/groupOwnerRoleModal/groupOwnerRoleModal.tmpl.html',
            controller: groupOwnerRoleModalController,
            controllerAs: 'gormvm',
            backdrop: 'static',
            resolve: {
                ownershipGroup: () => vm.selectedOwnershipGroup,
                existingOwners: () => vm.owners
            }
        });

        try {
            let ownerId = await ownerModal.result;
            await getOwners();
            if (ownerId) {
                changeSelectedOwner(vm.owners.find(r => r.Id === ownerId));
            }
        } catch { return; }
    }

    async function openGroupOwnerProductModal() {
        let ownerProductModal = $uibModal.open({
            templateUrl: '/App/Components/OwnershipGroups/ownershipGroupOwnersList/groupOwnerProductModal/groupOwnerProductModal.tmpl.html',
            controller: groupOwnerProductModalController,
            controllerAs: 'gopmvm',
            backdrop: 'static',
            resolve: {
                ownershipGroup: () => vm.selectedOwnershipGroup
            }
        });

        try {
            let ownerId = await ownerProductModal.result;
            await getOwners();
            if (ownerId) {
                changeSelectedOwner(vm.owners.find(r => r.Id === ownerId));
            }
        } catch { return; }
    }

    async function openGroupOwnerBusinessProcessModal() {
        let ownerModal = $uibModal.open({
            templateUrl: '/App/Components/OwnershipGroups/ownershipGroupOwnersList/groupOwnerBusinessProcessModal/groupOwnerBusinessProcessModal.tmpl.html',
            controller: groupOwnerBusinessProcessModalController,
            controllerAs: 'gobpmvm',
            backdrop: 'static',
            resolve: {
                ownershipGroup: () => vm.selectedOwnershipGroup
            }
        });

        try {
            let ownerId = await ownerModal.result;
            await getOwners();
            if (ownerId) {
                changeSelectedOwner(vm.owners.find(r => r.Id === ownerId));
            }
        } catch { return; }
    }


    async function openGroupOwnerRiskModal() {
        let ownerModal = $uibModal.open({
            templateUrl: '/App/Components/OwnershipGroups/ownershipGroupOwnersList/groupOwnerRiskModal/groupOwnerRiskModal.tmpl.html',
            controller: groupOwnerRiskModalController,
            controllerAs: 'gormvm',
            backdrop: 'static',
            resolve: {
                ownershipGroup: () => vm.selectedOwnershipGroup,
                existingOwners: () => vm.owners
            }
        });

        try {
            let ownerId = await ownerModal.result;
            await getOwners();
            if (ownerId) {
                changeSelectedOwner(vm.owners.find(r => r.Id === ownerId));
            }
        } catch { return; }
    }

    async function openGroupOwnerCompaniesModal() {
        let ownerCompanyModal = $uibModal.open({
            templateUrl: '/App/Components/OwnershipGroups/ownershipGroupOwnersList/groupOwnerCompaniesModal/groupOwnerCompaniesModal.tmpl.html',
            controller: groupOwnerCompaniesModalController,
            controllerAs: 'gocmvm',
            backdrop: 'static',
            resolve: {
                ownershipGroup: () => vm.selectedOwnershipGroup,
                existingOwners: () => vm.owners
            }
        });

        try {
            let ownerId = await ownerCompanyModal.result;
            await getOwners();
            if (ownerId) {
                changeSelectedOwner(vm.owners.find(owner => owner.Id === ownerId));
            }
        } catch { return; }
    }

}




