export default /*@ngInject*/ function ($scope, RequestManagementOracleService, existingUserCheckService, errorsDataService, manageDataDataService, $http) {

    $scope.vm.PasswordExpirationOptions = RequestManagementOracleService.getPasswordExpirationTypes();

    //General User
    $scope.vm.userFromDateObject = null;
    $scope.vm.userToDateObject = null;
    $scope.vm.userMinDate = new Date();
    if (!$scope.vm.User.OracleUser) {
        $scope.vm.User.OracleUser = {};
    }
    $scope.vm.selectUser = selectUser;
    $scope.vm.User.OracleUser.ProductType = "OR";
    setFieldConfigurationValues();
    var userMinDate = new Date();
    userMinDate.setHours(0, 0, 0, 0);

    $scope.vm.userMinDate = userMinDate;

    $scope.userFromDateChanged = function () {
        var date;
        if ($scope.vm.User.OracleUser.StartDate == '') {
            date = new Date();
        } else {
            date = new Date($scope.vm.User.OracleUser.StartDate);
        }

        $scope.vm.userMinDate = date;
        $scope.vm.minDate = date;
        $scope.vm.fromMinDate = date;
        if ($scope.vm.User.OracleUser == null) {
            $scope.vm.User.OracleUser = {};
        }
    };

    $scope.userToDateChanged = function () {
        $scope.vm.userMinDate = new Date($scope.vm.User.OracleUser.EndDate);
        $scope.vm.minDate = new Date($scope.vm.User.OracleUser.EndDate);
        $scope.vm.fromMinDate = new Date($scope.vm.User.OracleUser.StartDate);
        if ($scope.vm.User.OracleUser == null) {
            $scope.vm.User.OracleUser = {};
        }
    };

    async function existingUserCheck(username) {
        try {
            const res = await existingUserCheckService.checkOR(username);
            if (res.data === true) {
                $scope.wizardForm.ORusername.$setValidity("ORusername", false);
                $scope.vm.userExists = true;
            }
            else {
                $scope.wizardForm.ORusername.$setValidity("ORusername", true);
                $scope.vm.userExists = false;
            }
        } catch (err) {
            errorsDataService.catch(err);
        }
    };

    $scope.$watch("vm.User.OracleUser.Name", () => {
        if ($scope.vm.User.OracleUser.Name && $scope.vm.wizardMode === 'New') {
            existingUserCheck($scope.vm.User.OracleUser.Name)
        }
    });

    async function setFieldConfigurationValues() {
        $scope.vm.showADUserLookup = $scope.vm.config.find(config => config.Name === 'AD_Active').Value === '1';
        $scope.vm.showAADUserLookup = $scope.vm.config.find(config => config.Name === 'AAD_Active').Value === '1';
        let response = await manageDataDataService.getConfigurationByProductId('OR');
        $scope.vm.disableADUserFields = response.data.find(config => config.Name === 'IM_DisableADUserFields').Value === '1' && $scope.vm.showADUserLookup;
        $scope.vm.disableAADUserFields = response.data.find(config => config.Name === 'IM_DisableAADUserFields').Value === '1' && $scope.vm.showAADUserLookup;
    }

    function selectUser(user) {
        $scope.vm.User.OracleUser = user;
        if (user.UserType !== 'Guest') {
            setProvider();
        }
    }

    async function setProvider() {
        const { data } = await $http.get(`${apiUrl}api/universal/products/NS/configuration/im_provider`);
        $scope.vm.User.OracleUser.NetworkDomain = data.Value;
    }
}