import userProfileModalController from "./userProfileModal.controller";

export default /*@ngInject*/ function ($uibModal) {

    const vm = this;

    vm.openUserProfileModal = openUserProfileModal;

    function openUserProfileModal() {
        $uibModal.open({
            templateUrl: '/App/Shared/Components/userProfile/userProfileModal.tmpl.html',
            controller: userProfileModalController,
            controllerAs: 'upmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                userMasterId: () => vm.userMasterId,
                userId: () => vm.userId,
                productId: () => vm.productId
            }
        });
    }
}