import migrationDetailsGridController from "./migrationDetailsGrid.controller";

/* migrationDetailsGrid.component.js */

/**
 * @desc component for viewing review assignments
 * @example <ax-security-designer-migration-details-grid></ax-security-designer-migration-details-grid>
 */

const axSecurityDesignerMigrationDetailsGrid = {
    bindings: {
        migration: '<'
    },
    controller: migrationDetailsGridController,
    controllerAs: 'mdgvm',
    templateUrl: '/App/Components/AXSecurityDesigner/SecurityMigration/MigrationDetails/migrationDetailsGrid.html'
};

export default axSecurityDesignerMigrationDetailsGrid;