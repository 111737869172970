import angular from 'angular';
import 'jquery';
import 'signalr';
import 'angular-signalr-hub';
import 'angular-toastr';

// services imports
import archiveToastr from './Services/SignalRArchiveService';
import signalRCommonService from './Services/SignalRCommonService';

// factory imports
import signalRUserFactory from './Factories/signalRUser.factory';
import signalRTenantFactory from './Factories/signalRTenant.factory';

export default angular
    .module('app.shared.signalr', ['toastr', 'SignalR'])
    .factory('signalRUserFactory', signalRUserFactory)
    .factory('signalRTenantFactory', signalRTenantFactory)
    .service('archiveToastr', archiveToastr)
    .service('signalRCommonService', signalRCommonService)
    .name;