 import snapshotChangesController from "./snapshotChanges.controller";

/* snaphsotChanges.component.js */

/**
 * @desc component for Snapshot Changes in NetSuite
 * @example <snapshot-changes></snapshot-changes>
 */

const snapshotChanges = {
    controller: snapshotChangesController,
    controllerAs: 'scvm',
    templateUrl: '/App/Components/SnapshotChanges/snapshotChanges.tmpl.html'
};

export default snapshotChanges;