import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, connectionsDataService, helperService, product) {
    const vm = this;

    vm.product = Object.assign({}, product);
    vm.updateProductName = updateProductName;
    vm.cancel = cancel;
    vm.dismiss = dismiss;
    vm.updateInProgress = false;

    function cancel() {
        $uibModalInstance.close();
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }

    async function updateProductName() {
        vm.updateInProgress = new createSpinner();

        try {
            await connectionsDataService.updateProductName(vm.product);

            await helperService.successfulSaveButton(vm.updateInProgress);
            product.Name = vm.product.Name;
            dismiss();
        } catch (response) {
            vm.updateInProgress.loadingValue = false;
        }
    }
}
