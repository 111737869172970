export default /*@ngInject*/ function ($q, RequestManagementNAVService) {

    var self = this;

    this.loadNAV = function ($scope, openRequest) {
        //Load Data for Products after search row is selected
        var navDfrd = $.Deferred();

        self.loadNAVData($scope);

        try {
            self.loadPermissions($scope, openRequest).then(function () {
                navDfrd.resolve();
            }, function errorCallback(response) {
                navDfrd.reject(response);
            });
        }
        catch (e) {
            navDfrd.reject(e);
        }

        return $.when(navDfrd).done(function () {
        }).promise();

    };

    this.loadPermissions = function ($scope) {
        let permissionsDfrd = $.Deferred(),
            promises = [],
            navPermissionSets = [],
            navUserGroups = [],
            pendingPermissionSets = $scope.vm.User?.NAVUser?.PendingPermissionSets || [],
            pendingUserGroups = $scope.vm.User?.NAVUser?.PendingUserGroups || [];

        $scope.vm.ListOfSelectedNAVPermissionSets = [];
        $scope.vm.ListOfAvailableNAVPermissionSets = [];
        $scope.vm.ListOfSelectedNAVUserGroups = [];
        $scope.vm.ListOfAvailableNAVUserGroups = [];

        if ($scope.vm.User.NAVUser != null) {
            navPermissionSets = $scope.vm.User.NAVUser.PermissionSets;
            navUserGroups = $scope.vm.User.NAVUser.UserGroups;
        }

        //Load Available NAV Roles then process existing security
        promises.push(
            RequestManagementNAVService.getAllPermissionSets().then(response => {
                $scope.vm.ListOfAvailableNAVPermissionSets = response.data.filter(applyPending.bind(null, pendingPermissionSets));
            })
        );

        promises.push(RequestManagementNAVService.getAllCompanies().then(response => {
            $scope.vm.ListOfNAVCompanies = response.data;
        }));

        promises.push(RequestManagementNAVService.getAllProfiles().then(response => {
            $scope.vm.NAVProfileDataSource = response.data;
        }));

        promises.push(RequestManagementNAVService.getAllUserGroups().then(response => {
            $scope.vm.ListOfAvailableNAVUserGroups = response.data.filter(applyPending.bind(null, pendingUserGroups));
        }));

        $q.all(promises).then(function () {
            if ($scope.defaultRoleTemplate && $scope.vm.wizardMode === "New" && !$scope.vm.requestToEdit) {
                let defaultPermissionSets = $scope.defaultRoleTemplate.Roles.filter(role => role.TypeId === "Permission Set" && role.ProductType === "NAV");

                navPermissionSets = [...defaultPermissionSets];
            }

            if (navPermissionSets != null && $scope.vm.ListOfSelectedNAVPermissionSets != null) {
                let permissionSetArray = [];

                navPermissionSets.reduce(consolidatePermissionSets, permissionSetArray);

                function consolidatePermissionSets(permissions, item) {
                    let permission = permissions.find(p => p.Id === item.Id);

                    if (permission) {
                        permission.Companies.push({
                            Name: item.Company
                        });
                        permission.CompanyNames.push(item.Company);
                    } else {
                        item = {
                            Id: item.Id,
                            Name: item.Name,
                            Companies: [{
                                Name: item.Company
                            }],
                            CompanyNames: [item.Company]
                        }
                        permissions.push(item);
                    }

                    return permissions;
                }

                permissionSetArray.forEach(function (role) {
                    if ($scope.vm.ListOfSelectedNAVPermissionSets.none(selectedRole => selectedRole.Id == role.Id)) {

                        $scope.vm.ListOfSelectedNAVPermissionSets.unshift({
                            Id: role.Id,
                            Name: role.Name,
                            IsAssignable: role.IsAssignable,
                            Identifier: role.Identifier,
                            Companies: role.Companies,
                            CompanyNames: role.CompanyNames,
                            Pending: pendingPermissionSets.some(pendingPermissionSet => pendingPermissionSet.Id === role.Id)
                        });

                        $scope.vm.ListOfAvailableNAVPermissionSets.forEach((row, j) => {
                            if (row.Id == role.Id) {
                                $scope.vm.ListOfAvailableNAVPermissionSets.splice(j, 1);
                            }
                        });
                    }
                });
            }

            if (navUserGroups != null && $scope.vm.ListOfSelectedNAVUserGroups != null) {
                //Group all the companies for each Permission Set under one single permission set
                let userGroupArray = [];

                navUserGroups.reduce(consolidateUserGroups, userGroupArray);

                function consolidateUserGroups(userGroups, item) {
                    let userGroup = userGroups.find(group => group.Id === item.Id);

                    if (userGroup) {
                        userGroup.Companies.push({
                            Name: item.Company
                        });
                        userGroup.CompanyNames.push(item.Company);
                    } else {
                        item = {
                            Id: item.Id,
                            Name: item.Name,
                            PermissionSets: item.PermissionSets,
                            Companies: [{
                                Name: item.Company
                            }],
                            CompanyNames: [item.Company]
                        }
                        userGroups.push(item);
                    }

                    return userGroups;
                }

                userGroupArray.forEach(function (userGroup) {
                    if ($scope.vm.ListOfSelectedNAVUserGroups.none(selectedUserGroup => selectedUserGroup.Id === userGroup.Id)) {

                        $scope.vm.ListOfSelectedNAVUserGroups.unshift({
                            Id: userGroup.Id,
                            Name: userGroup.Name,
                            Companies: userGroup.Companies,
                            CompanyNames: userGroup.CompanyNames,
                            Pending: pendingUserGroups.some(pendingUserGroup => pendingUserGroup.Id === userGroup.Id)
                        });

                        $scope.vm.ListOfAvailableNAVUserGroups.forEach((row, j) => {
                            if (row.Id === userGroup.Id) {
                                $scope.vm.ListOfAvailableNAVUserGroups.splice(j, 1);
                            }
                        });
                    }
                });
            }

            $scope.vm.ListOfAvailableNAVPermissionSets = $scope.vm.ListOfAvailableNAVPermissionSets.filter(permissionSet => permissionSet.IsAssignable);
            $scope.navUserLoaded = true;
            permissionsDfrd.resolve();
        });

        return $.when(permissionsDfrd).done(function () {
        }).promise();

    };

    this.loadNAVData = function ($scope) {
        if ($scope.vm.User.NAVUser == null) {
            $scope.vm.User.NAVUser = {};
            $scope.vm.User.NAVUser.State = '0';
        }
    };
}

function applyPending(pendingItems, item) {
    if (pendingItems.length) {
        item.Pending = pendingItems.some(pendingItem => pendingItem.Id === item.Id);
    }

    return item;
}