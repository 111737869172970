import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($scope, $http, helperService) {

    var manageDataVm = $scope.manageDataVm = {};

    manageDataVm.restoreData = function () {
        manageDataVm.dataProcessIsRunning = new createSpinner();
        $http.put(apiUrl + 'api/audittrail/auditdata/restore', { StartDate: $scope.vm.restoreStartDate.toUTCString(), EndDate: $scope.vm.restoreEndDate.toUTCString(), RecordTypes: [] }).then(function (response) {
            manageDataVm.dataProcessIsRunning.loadingValue = false;
        }, function errorCallback(response) {
            helperService.showErrorMessage(response.data);
            manageDataVm.dataProcessIsRunning.loadingValue = false;
        });
    };
}
