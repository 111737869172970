export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getStatuses,
        addStatus,
        modifyStatus,
        removeStatus
    };

    return service;

    async function getStatuses() {
        try {
            return await $http.get(`${apiUrl}api/arm/statuses`);
        } catch (error) {
            showError(error);
        }
    }

    async function addStatus(data) {
        try {
            return await $http.post(`${apiUrl}api/arm/statuses`, data);
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    async function modifyStatus(businessCycleId, data) {
        try {
            return await $http.put(`${apiUrl}api/arm/statuses/${businessCycleId}`, data);
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    async function removeStatus(businessCycleId) {
        try {
            return await $http.delete(`${apiUrl}api/arm/statuses/${businessCycleId}`);
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}