export default /*@ngInject*/ function  () {

    this.getWhiteListedRoutes = function () {
        return [
            "/",
            "/SIGNREPORT",
            "/DOWNLOADS",
            "/INBOX",
            "/SECURITYDESIGNER/DYNAMICS365FORFINANCEANDOPERATIONSSIMULATIONDETAILS",
            "/IDENTITYMANAGER/REQUESTDETAILS",
            "/IDENTITYMANAGER/REVIEW",
            "/CHANGETRACKING/SETUP/TRACKINGTEMPLATEDETAILS",
            "/SECURITYDESIGNER/DYNAMICS365FORFINANCEANDOPERATIONSTASKRECORDINGMANAGER"
        ];
    };
}