export default /*@ngInject*/ function ($http, $filter, errorsDataService, $uibModalInstance, item) {

    const vm = this;

    vm.cancel = cancel;

    function activate() {
        getAffectedObjects();
    }

    activate();

    function getAffectedObjects() {
        vm.loadingAffectedObjects = true;
        $http.get(apiUrl + 'api/securitydesigner/ax7/affectedobjects/systemName/' + item.SystemName + '/securityLayerType/' + item.securityLayerType).then(response => {
            vm.loadingAffectedObjects = false;
            if (response.data.length === 0) {
                vm.noAffectedObjects = true;
            } else {
                response.data = $filter('orderBy')(response.data, 'Name');
                response.data = $filter('orderBy')(response.data, 'Type');
                vm.affectedObjects = response.data;
            }
        }).catch(response => {
            vm.loadingAffectedObjects = false;
            errorsDataService.catch(response);
        });
    }

    function cancel() {
        $uibModalInstance.close();
    }

}