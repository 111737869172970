import createSpinner from "../../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($scope, $uibModalInstance, linkedTicketsArray, helperService) {

    var modalVm = $scope.modalVm = {};

    modalVm.linkCustomTicket = async function () {
        modalVm.saving = new createSpinner();
        let linkedCustomTicket = {};

        linkedCustomTicket.TicketId = modalVm.customTicketId;
        linkedCustomTicket.TicketProvider = 'Custom';

        linkedTicketsArray.push(linkedCustomTicket);

        $scope.$parent.customTicketLinked = true;
        await helperService.successfulSaveButton(modalVm.saving);
        $uibModalInstance.close();
    };

    $scope.cancel = function () { $uibModalInstance.close(); };
}