import angular from 'angular';
import ownershipGroups from '../Components/OwnershipGroups/ownershipGroups.component';
import ownershipGroupsList from '../Components/OwnershipGroups/ownershipGroupsList/ownershipGroupsList.component';
import ownershipGroupsDataServiceFactory from '../Components/OwnershipGroups/ownershipGroupsDataService.factory';
import ownershipGroupOwnerDataServiceFactory from '../Components/OwnershipGroups/ownershipGroupOwnersList/ownershipGroupOwnerDataService.factory';
import groupOwnersList from '../Components/OwnershipGroups/ownershipGroupOwnersList/groupOwnersList.component';
import groupItemsList from '../Components/OwnershipGroups/ownershipGroupItemsList/groupItemsList.component';
import ownershipGroupItemsDataServiceFactory from '../Components/OwnershipGroups/ownershipGroupItemsList/ownershipGroupItemsDataService.factory';
import groupItemOwnersList from '../Components/OwnershipGroups/groupItemOwnersList/groupItemOwnersList.component';
import groupOwnerItemsList from '../Components/OwnershipGroups/groupOwnerItemsList/groupOwnerItemsList.component';

export default angular
    .module('app.systemConfiguration.ownershipGroups', [])
    .component('ownershipGroups', ownershipGroups)
    .component('ownershipGroupsList', ownershipGroupsList)
    .factory('ownershipGroupsDataService', ownershipGroupsDataServiceFactory)
    .component('ownershipGroupItemsList', groupItemsList)
    .factory('ownershipGroupItemsDataService', ownershipGroupItemsDataServiceFactory)
    .factory('ownershipGroupOwnerDataService', ownershipGroupOwnerDataServiceFactory)
    .component('ownershipGroupOwnersList', groupOwnersList)
    .component('ownershipGroupItemsOwnersList', groupItemOwnersList)
    .component('ownershipGroupOwnerItemsList', groupOwnerItemsList)
    .name;