import swal from "sweetalert2";
import migrationModalController from "./migrationModal.controller";
import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (securityMigrationDataService, helperService, $uibModal, FileSaver) {
    const vm = this;

    vm.selectMigration = selectMigration;
    vm.openMigrationModal = openMigrationModal;

    vm.$onInit = activate;

    vm.menuOptions = [
        {
            text: 'Edit',
            click: item => {
                openMigrationModal(item.securityMigration);
            }
        },
        {
            text: 'Export to Code',
            click: item => {
                exportMigration(item.securityMigration, 0);
            }
        },
        {
            text: 'Export to UI',
            click: item => {
                exportMigration(item.securityMigration, 1);
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: item => {
                deleteMigration(item.securityMigration);
            }
        }
    ];

    function activate() {
        getMigrations();
    }

    async function getMigrations() {
        let response = await securityMigrationDataService.getSecurityMigrations();
        vm.securityMigrationsDataSource = response.data;
        if (vm.selectedMigration) {
            selectMigration(vm.selectedMigration);
        }
    }

    function selectMigration(selectedMigration) {
        vm.selectedMigration = selectedMigration;
        vm.selectedMigrationChanged({ selectedMigration });
    }

    async function deleteMigration(migration) {
        try {
            await swal(helperService.areYouSureParams(
                `Delete Migration`,
                `Are you sure you want to delete the ${migration.Name} Security Migration?`,
                `Yes`,
                `No`
            ));
            await securityMigrationDataService.deleteSecurityMigration(migration.Id);
            getMigrations();
            if (vm.selectedMigration.Id === migration.Id) {
                vm.selectMigration(null);
            }
        } catch {
            return;
        }
    }

    async function openMigrationModal(migration) {
        const migrationModal = $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/SecurityMigration/MigrationsList/migrationModal.html',
            controller: migrationModalController,
            controllerAs: 'mmvm',
            backdrop: 'static',
            resolve: {
                migration: () => migration || null
            }
        });

        try {
            await migrationModal.result;
            await getMigrations();
        } catch {
            return;
        }
    }

    async function exportMigration(migration, exportType) {
        migration.exportUpdatePending = new createSpinner();
        try {
            let response = await securityMigrationDataService.exportMigration(migration.Id, exportType);
            FileSaver.saveAs(response.data, exportType === 1 ? `${helperService.removeSpaces(migration.Name)}.xml` : `${helperService.removeSpaces(migration.Name)}.zip`);
            migration.exportUpdatePending.loadingValue = false;
        } catch {
            migration.exportUpdatePending.loadingValue = false;
        }
    }
}