import angular from 'angular';
import requesterGroups from '../Components/RequesterGroups/requesterGroups.component';
import requesterGroupsList from '../Components/RequesterGroups/requesterGroupsList/requesterGroupsList.component';
import requesterGroupsDataServiceFactory from '../Components/RequesterGroups/requesterGroupsDataService.factory';
import requesterGroupUsersList from '../Components/RequesterGroups/requesterGroupUsersList/requesterGroupUsersList.component';

export default angular
    .module('app.systemConfiguration.requesterGroups', [])
    .component('requesterGroups', requesterGroups)
    .component('requesterGroupsList', requesterGroupsList)
    .factory('requesterGroupsDataService', requesterGroupsDataServiceFactory)
    .component('requesterGroupUsersList', requesterGroupUsersList)
    .name;