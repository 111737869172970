/* instanceAssignmentsList.component.js */

import instanceAssignmentsListController from "./instanceAssignmentsList.controller";

/**
 * @desc component for viewing and managing access certifications review schedule instance assignments
 * @example <review-schedule-instance-assignments-list></review-schedule-instance-assignments-list>
 */

const scheduleInstanceAssignmentsList = {
    bindings: {
        scheduleInstance: '<',
        onSelectOwnershipGroup: '&'
    },
    controller: instanceAssignmentsListController,
    controllerAs: 'ialvm',
    templateUrl: '/App/Components/Certifications/reviewSchedules/scheduleInstanceAssignmentsList/instanceAssignmentsList.tmpl.html'
};

export default scheduleInstanceAssignmentsList;