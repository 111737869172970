import selectRoleTemplateModalController from "./selectRoleTemplateModal.controller";

export default /*@ngInject*/ function ($uibModal, $attrs, roleTemplatesData) {

    var vm = this;
    vm.$onInit = activate;

    vm.openModal = openModal;

    async function activate() {
        await checkForRoleTemplates();
        if (vm.roleTemplates.length === 0) {
            return;
        }

        if (!vm.productId) {
            vm.productId = vm.stringProductId;
        }

        if (vm.altText) {
            vm.objectTypeName = vm.altText;
        } else {
            vm.objectTypeName = "Roles";
        }
    }

    async function checkForRoleTemplates() {
        let response = await roleTemplatesData.getRoleTemplates();
        vm.roleTemplates = response.data;
    }

    function openModal() {
        let modal = $uibModal.open({
            templateUrl: '/App/Shared/Components/selectRolesByRoleTemplate/selectRoleTemplateModal.html',
            controller: selectRoleTemplateModalController,
            controllerAs: 'srtvm',
            backdrop: 'static',
            resolve: {
                productId: () => vm.productId,
                requestAction: () => vm.requestAction,
                objectTypeName: () => vm.objectTypeName
            }
        });

        modal.result
            .then(data => { selectTemplateRoles(data); })
            .catch(_ => { return; });
    }

    function selectTemplateRoles(data) {
        const selectedRoleTemplates = data.selectedRoleTemplates;
        let roles = {};
        roles.list = [];
        roles.keepCurrent = data.keepCurrent;
        if (selectedRoleTemplates.length > 0) {
            selectedRoleTemplates.forEach(roleTemplate => {
                roles.list = roles.list.concat(roleTemplate.Roles);
            });
            roles.list = roles.list.unique();
        }

        // get roles based on role template, set vm.roles to response
        if (vm.availableRoles) {
            if (vm.productId !== 'PS') {
                // we can't do anything with roles that don't have the Id field so we will remove them for the purposes of this component
                vm.availableRoles = vm.availableRoles.filter(role => role.Id);
            }
            if (!roles.keepCurrent && vm.roles.length > 0) {
                removeCurrentRoles(vm.roles);
            }
            // need to select roles from available roles list
            roles.list.forEach(role => {
                if (!isRoleSelected(vm.roles, role) && role.ProductId === vm.productId) {
                    let availableRoleIndex = getAvailableRoleIndex(role, vm.availableRoles);
                    let availableRole = getAvailableRole(role, vm.availableRoles);

                    if (availableRoleIndex > -1) {
                        if (role.ProductType === "GP") {
                            availableRole = { Role: availableRole, Company: vm.selectedCompany };

                            if (!vm.storedRoles) vm.storedRoles = [];

                            vm.storedRoles.unshift(availableRole);
                            addRole(availableRole, availableRoleIndex);
                        } else {
                            addRole(availableRole, availableRoleIndex);
                            // Call validate roles function from roles controller to enable the next button
                            if ($attrs.validateRoles) vm.validateRoles();
                        }
                    }
                }
            });
        } else {
            vm.roles = roles.list;
            if (vm.toggleIntraResponsibility) {
                // Need to ensure intra responsibility toggle is set correctly
                const selection = vm.roles;
                vm.toggleIntraResponsibility({ selection });
            }
        }
        vm.setSelectedRoleTemplates({ selectedRoleTemplates });
    }

    function isRoleSelected(selectedRoles, role) {
        if (selectedRoles.length === 0) {
            return false;
        }
        if (selectedRoles[0].Role) {
            if (role.Role) {
                return selectedRoles.some(r => r.Role.Id.toUpperCase() === role.Role.Id);
            } else {
                return selectedRoles.some(r => r.Role.Id.toUpperCase() === role.Id);
            }
        }
        if (typeof selectedRoles[0].Id === "string") {
            return selectedRoles.some(r => r.Id.toUpperCase() === role.Id.toUpperCase());
        } else {
            return vm.productId === 'PS' ? selectedRoles.some(ar => ar.Name === role.Name) : selectedRoles.some(r => r.Id.toString() === role.Id);
        }
    }

    function removeCurrentRoles(roles) {
        let newRoles = roles.slice();
        if (vm.productId === 'GP') {
            newRoles.forEach(role => {
                if (role.Role.Name === "SystemUser" || role.Role.Id === "SystemUser") {
                    return;
                } else {
                    let existingRoleIndex = roles.findIndex(er => er.Role.Id === role.Role.Id);
                    roles.splice(existingRoleIndex, 1);
                    vm.availableRoles.unshift(role.Role);
                    if (vm.storedRoles && vm.storedRoles.length > 0) {
                        let storedRoleIndex = vm.storedRoles.findIndex(sr => {
                            return sr.Role.Id === role.Role.Id && sr.Company.Id === vm.selectedCompany.Id;
                        });
                        vm.storedRoles.splice(storedRoleIndex, 1);
                    }
                }
            });
        } else {
            newRoles.forEach(role => {
                if (role.Name === "SystemUser" || role.Id === "SystemUser") {
                    return;
                } else {
                    let existingRoleIndex = roles.findIndex(er => er.Id === role.Id);
                    roles.splice(existingRoleIndex, 1);
                    vm.availableRoles.unshift(role);
                }
            });
        }
    }

    function getAvailableRoleIndex(role, availableRoles) {
        if (role.ProductType === "OR") {
            role.Id = role.Id.substr(role.Id.indexOf("|") + 1);
        }
        if (typeof availableRoles[0].Id === "string") {
            return availableRoles.findIndex(ar => ar.Id.toUpperCase() === role.Id.toUpperCase());
        } else {
            return vm.productId === 'PS' ? availableRoles.findIndex(ar => ar.Name === role.Name) : availableRoles.findIndex(ar => ar.Id.toString() === role.Id);
        }
    }

    function getAvailableRole(role, availableRoles) {
        if (typeof availableRoles[0].Id === "string") {
            return availableRoles.find(ar => ar.Id.toUpperCase() === role.Id.toUpperCase());
        } else {
            return vm.productId === 'PS' ? availableRoles.find(ar => ar.Name === role.Name) : availableRoles.find(ar => ar.Id.toString() === role.Id);
        }
    }

    function addRole(role, roleIndex) {
        vm.roles.unshift(role);
        vm.availableRoles.splice(roleIndex, 1);
    }

}
