import countryCodes from "../../../../Shared/GlobalVariables/countryCodes";
import managerSearchController from "./ManagerSearch/managerSearch.controller";

export default /*@ngInject*/ function ($scope, $http, $uibModal, helperService, existingUserCheckService) {

    $scope.openManagerSearchModal = openManagerSearchModal;

    //General User
    $scope.vm.adUserFromDateObject = null;
    $scope.vm.adUserToDateObject = null;
    $scope.vm.adUserMaxDate = new Date();
    var adUserMinDate = new Date();
    adUserMinDate.setHours(0, 0, 0, 0);

    $scope.vm.adUserMinDate = adUserMinDate;

    if (!$scope.vm.User.AadUser) {
        $scope.vm.User.AadUser = {};
    }
    if ($scope.vm.User.AadUser.AccountEnabled === undefined) {
        $scope.vm.User.AadUser.AccountEnabled = true;
    }
    $scope.vm.User.AadUser.ProductType = "AAD";
    $scope.vm.User.AadUser.Enabled = true;
    $scope.vm.ListOfCountryCodes = countryCodes;

    $scope.vm.existingUserCheck = existingUserCheck;

    $scope.adUserFromDateChanged = function () {
        var date;
        if ($scope.vm.User.AadUser.StartDate == '') {
            date = new Date();
        } else {
            date = new Date($scope.vm.User.AadUser.StartDate);
        }

        $scope.vm.adUserMinDate = date;
        $scope.vm.fromMinDate = date;
        if ($scope.vm.User.AadUser == null) {
            $scope.vm.User.AadUser = {};
        }
    };

    $scope.adUserToDateChanged = function () {
        $scope.vm.adUserMaxDate = new Date($scope.vm.User.AadUser.EndDate);
        if ($scope.vm.User.AadUser == null) {
            $scope.vm.User.AadUser = {};
        }
    };

    activate();

    function activate() {
        if ($scope.vm.wizardMode === 'New') {
            getDomains();
        }
    }

    async function existingUserCheck() {
        if ($scope.vm.wizardMode === 'New' && $scope.vm.User.AadUser.Domain) {
            try {
                let response = await existingUserCheckService.checkAAD($scope.vm.User.AadUser.UserPrincipalName);
                if (response.data.UserPrincipalName === $scope.vm.User.AadUser.UserPrincipalName + '@' + $scope.vm.User.AadUser.Domain) {
                    $scope.vm.aadUserExists = true;
                    $scope.wizardForm.AADUserName.$setValidity("AADUserName", false);
                }
            } catch (error) {
                if (error.status === 404) {
                    $scope.vm.aadUserExists = false;
                }
            }
        }
    }

    async function getDomains() {
        try {
            let response = await $http.get(`${apiUrl}api/identitymanager/aad/domains`);
            $scope.vm.ListOfAADDomains = response.data;
        } catch (error) {
            helperService.showErrorMessage(error.data);
        }
    }

    async function openManagerSearchModal() {
        let managerSearchModal = $uibModal.open({
            templateUrl: '/App/Components/RequestManagement/WizardSteps/AAD/ManagerSearch/managerSearch.tmpl.html',
            controller: managerSearchController,
            controllerAs: 'msmvm',
            backdrop: 'static',
            resolve: {
                requestUserId: () => $scope.vm.User.AadUser.Id
            }
        });

        try {
            let selectedManager = await managerSearchModal.result;
            $scope.vm.User.AadUser.Manager = selectedManager;
        } catch { return; }
    }
}