import angular from 'angular';
import appSharedModule from './Shared/shared.module';
import accessRiskMonitorModule from './AccessRiskMonitor/accessRiskMonitor.module';
import accessCertificationsModule from './AccessCertifications/accessCertifications.module';
import auditTrailModule from './AuditTrail/auditTrail.module';
import securityDesignerModule from './SecurityDesigner/securityDesigner.module';
import identityManagerModule from './IdentityManager/identityManager.module';
import reportManagementModule from './ReportManagement/reportManagement.module';
import transactionMonitoringModule from './TransactionMonitoring/transactionMonitoring.module';
import systemConfigurationModule from './SystemConfiguration/systemConfiguration.module';
import integrationsModule from './Integrations/integrations.module';
import securitySetupModule from './SecuritySetup/securitySetup.module';

export default angular
    .module('app', [
        appSharedModule,
        accessRiskMonitorModule,
        accessCertificationsModule,
        auditTrailModule,
        securityDesignerModule,
        identityManagerModule,
        reportManagementModule,
        transactionMonitoringModule,
        systemConfigurationModule,
        integrationsModule,
        securitySetupModule
    ])
    // constants
    .constant('config', {
        appName: 'Fastpath Stratus',
        appVersion: 1.0
    });