/* transactionMonitoringSchedule.component.js */

import transactionMonitoringScheduleController from "./transactionMonitoringSchedule.controller";

/**
 * @desc component to schedule Transaction Monitoring import
 * @example <transaction-monitoring-schedule></transaction-monitoring-schedule>
 */

const transactionMonitoringSchedule = {
    controller: transactionMonitoringScheduleController,
    controllerAs: 'tmsvm',
    templateUrl: '/App/Components/Connections/Connection/Schedule/TransactionMonitoringSchedule/transactionMonitoringSchedule.tmpl.html'
};

export default transactionMonitoringSchedule;