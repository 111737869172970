import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, $http, helperService, errorsDataService, requestData) {

    const vm = this;
    vm.requestData = requestData;
    vm.submitCancelNotes = submitCancelNotes;
    vm.cancel = cancel;

    async function submitCancelNotes() {

        vm.savePending = new createSpinner();

        try {
            await $http.put(`${apiUrl}api/identitymanager/requests/${requestData.RequestId}/version/${requestData.Version}/cancel`, vm.requestData);
            await helperService.successfulSaveButton(vm.savePending);
            $uibModalInstance.close();
        } catch (err) {
            vm.savePending.loadingValue = false;
            errorsDataService.catch(err);
        }
    }

    function cancel() { $uibModalInstance.dismiss(); }
}