import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, $filter, $timeout, $location, authenticationService, dataService, helperService, myReviewsDataService, delegationItems, reviewHeaderId, reviewType) {

    const vm = this;
    vm.userDropdownLoading = false;
    vm.helperService = helperService;
    vm.cancel = cancel;
    vm.reviewDelegation = reviewDelegation;
    vm.delegationItems = delegationItems.map(item => { return { ...item } });
    vm.selectAllChanged = selectAllChanged;
    vm.itemSelectionChanged = itemSelectionChanged;
    vm.checkAllSelection = checkAllSelection;
    vm.filterItems = filterItems;
    vm.itemsFilter = null;

    vm.selectedDelegationItems = [];

    activate();

    function activate() {
        getUsers();
        vm.delegationItems = $filter('orderBy')(vm.delegationItems, 'UserName');
    }

    async function getUsers() {
        vm.userDropdownLoading = true;
        const currentUser = await authenticationService.getUser();
        let response = await dataService.getUsers();
        if (!$location.path().includes('AllReviews')) {
            // we only want to filter out the current user if not coming from All Reviews
            response.data = response.data.filter(user => user.UserID !== currentUser.userId && user.UserID);
        }
        response.data = $filter('orderBy')(response.data, 'UserLogonName');
        response.data = response.data.filter(user => user.Active && user.RestrictDelegateAccessAC === false);
        vm.usersDataSource = response.data;
        vm.userDropdownLoading = false;
    }

    async function reviewDelegation() {
        let data = {
            ReviewHeaderId: reviewHeaderId,
            ReviewItemIds: vm.selectedDelegationItems.map(item => item.ItemId),
            DelegatedUserId: vm.DelegatedUserId,
            Notes: vm.delegationNotes
        };

        try {
            vm.delegationPending = new createSpinner();
            await myReviewsDataService.delegateReviewItems(reviewHeaderId, reviewType, data);
            await helperService.successfulSaveButton(vm.delegationPending);
            $uibModalInstance.close();
        } catch (err) {
            vm.delegationPending.loadingValue = false;
        }
    }

    function selectAllChanged() {
        if (vm.allSelected) {
            vm.filteredDelegationItems.forEach(item => item.selected = true);
            vm.selectedDelegationItems = [ ...vm.selectedDelegationItems, ...vm.filteredDelegationItems ];
        } else {
            // deselect the items currently being viewed
            vm.delegationItems.forEach(item => {
                if (vm.filteredDelegationItems.some(filteredItem => filteredItem.ItemId === item.ItemId)) {
                    item.selected = false
                }
            });
            // update our selected array to only included currently selected items
            vm.selectedDelegationItems = vm.delegationItems.filter(item => item.selected);
        }

        // remove duplicate items
        vm.selectedDelegationItems = vm.selectedDelegationItems.filter((item, index, items) => {
            return index === items.findIndex(itemToFind => itemToFind.ItemId === item.ItemId)
        });

        checkAllSelection();
    }

    function itemSelectionChanged(item) {
        if (item.selected) {
            vm.selectedDelegationItems.push(item);
        } else {
            const itemIndex = vm.selectedDelegationItems.findIndex(selectedItem => selectedItem.ItemId === item.ItemId);
            vm.selectedDelegationItems.splice(itemIndex, 1);
        }
        checkAllSelection();
    }

    function checkAllSelection() {
        $timeout(_ => {
            vm.allSelected = vm.filteredDelegationItems.every(item => item.selected);
        }, 1);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }

    function filterItems(item) {
        const keysToSearch = ['UserName', 'RiskName', 'RiskTypeName', 'RulesetName', 'BusinessProcessName', 'CompanyName', 'ProductName', 'SecurityRoleName', 'SecurityRoleTypeName', 'SecurityObjectAltName', 'SecurityObjectTypeName'];
        let foundItem = false;

        if (vm.itemsFilter && vm.itemsFilter !== "") {
            keysToSearch.forEach((keyField) => {
                if (item[keyField] && item[keyField].toLowerCase().includes(vm.itemsFilter.toLowerCase())) {
                    foundItem = true;
                }
            });
        } else {
            foundItem = true;
        }    

        return foundItem;
    };

}