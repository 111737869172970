import companyModalController from "../CompanyModal/companyModal.controller";
import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModal, $filter, companyTemplatesData) {

    const vm = this;

    vm.openCompanyModal = openCompanyModal;
    vm.deleteCompany = deleteCompany;

    async function getCompanyTemplates(preselectedId) {
        let { data } = await companyTemplatesData.getCompanyTemplates();
        if (data.length > 0) {
            data.forEach(template => {
                template.Companies = template.Companies.filter(company => company.CompanyMaster.UserHasAccess);
                template.Companies.forEach(templateCompany => templateCompany.CompanyIds = templateCompany.Companies.map(object => object.ID));
            });

            data = $filter('orderBy')(data, 'Name');
            if (preselectedId) {
                const companyTemplate = data.find(t => t.Id === preselectedId);
                data = data.filter(t => t.Id !== preselectedId);
                data.unshift(companyTemplate);
                changeSelectedCompanyTemplate(companyTemplate);
            }
        }
        vm.companyTemplates = data;
    }

    async function deleteCompany(company) {
        vm.deletePending = true;
        try {
            await companyTemplatesData.deleteCompany(vm.template.Id, company.ID);
            const companyIndex = vm.template.Companies.findIndex(c => c.CompanyMaster.ID === company.ID);
            vm.template.Companies.splice(companyIndex, 1);
        } catch {
            return;
        }
        vm.deletePending = false;
    }

    async function openCompanyModal() {
        const companyTemplateModal = $uibModal.open({
            templateUrl: '/App/Components/CompanyTemplates/CompanyModal/companyModal.html',
            controller: companyModalController,
            controllerAs: 'cmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                template: () => vm.template
            }
        });
        try {
            const newTemplateId = await companyTemplateModal.result;
            getCompanyTemplates(newTemplateId);
        } catch {
            return;
        }
    }

}
