export default /*@ngInject*/ function ($scope, $window, authenticationService) {

    var vm = $scope.vm = {};
    vm.currentYear = new Date();

    $scope.LogIn = function () {
        logInViaOkta();
    };

    function logInViaOkta() {
        authenticationService.login();
    }
}