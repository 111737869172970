/* templatesList.component.js */

import templatesListController from "./templatesList.controller";

/**
 * @desc component for displaying, creating and editing Templates List
 * @example <workday-templates-list></workday-templates-list>
 */

const templatesList = {
    controller: templatesListController,
    controllerAs: 'tlvm',
    templateUrl: '/App/Components/workdayMaintainTemplates/templatesList/templatesList.tmpl.html',
    bindings: {
        onSelectedTemplateChanged: '&'
    }
};

export default templatesList;