import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (dataService, $http, helperService) {
    const vm = this;

    vm.startNewAudit = startNewAudit;

    vm.show = false;
    vm.showReApplyAutoMitigations = false;

    vm.audit = {
        Name: '',
        ReapplyAutoMitigations: true,
        ReapplyDefaultMitigations: true
    };

    activate();

    function activate() {
        showReapply();
    }

    function clear() {
        vm.audit.Name = '';
        vm.audit.ReapplyAutoMitigations = true;
        vm.audit.ReapplyDefaultMitigations = true;
    }

    //Check if we should show Reapply Auto Mitigations toggle
    async function showReapply() {
        let productTypes = await dataService.getProductTypes();

        vm.showReApplyAutoMitigations = productTypes.data.some(p => p.Type === 'OR' || p.Type === 'PS' || p.Type === 'ORFC');
    }



    //Start New Audit
    async function startNewAudit() {
        vm.updatePending = new createSpinner();

        try {
            await $http.put(`${apiUrl}api/assure/archive/startnewaudit`, vm.audit);
            helperService.successfulSaveButton(vm.updatePending);
            clear();
        } catch (error) {
            vm.updatePending.loadingValue = false;
            helperService.showErrorMessage(error.data);
        }
    }

}
