import createSpinner from "../../../../Shared/Functions/createSpinner";
import { standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $filter, ownershipGroup, existingOwners, helperService, ownershipGroupOwnerDataService, ownershipGroupItemsDataService) {
    const vm = this;

    vm.saveRoleOwner = saveRoleOwner;
    vm.selectedRoleTypeChanged = selectedRoleTypeChanged;
    vm.selectedOwnerTypeChanged = selectedOwnerTypeChanged;
    vm.cancel = cancel;

    vm.ownershipGroup = { ...ownershipGroup };

    activate();

    async function activate() {
        vm.selectedRoleType = 'Role';
        setupRoleDropdown();
        await getOwners();
        vm.selectedOwnerType = 'User';
        selectedOwnerTypeChanged();
    }

    async function getOwners() {
        vm.ownersDataSourceLoading = true;
        let response = await ownershipGroupOwnerDataService.getAvailableOwners(ownershipGroup.Id, ownershipGroup.GroupType);
        response.data = $filter('orderBy')(response.data, 'Name');
        response.data = response.data.filter(masterOwner => !existingOwners.some(owner => owner.Id === masterOwner.Id));
        vm.availableOwnersDataSource = response.data;
        vm.ownersDataSourceLoading = false;
    }

    function setupRoleDropdown() {
        vm.rolesDropdownOptions = {
            filter: "contains",
            autoClose: false,
            virtual: {
                itemHeight: 26,
                mapValueTo: 'dataItem',
                valueMapper: function (options) {
                    options.success(helperService.multiValueMapperFunction(options, vm.rolesDataSource, 'Identifier'));
                }
            },
            height: 325
        };


        vm.roleDropdownDataSource = new kendo.data.DataSource({

            pageSize: standardGridPageSize,
            transport: {
                read: async (options) => {
                    let { data } = await ownershipGroupItemsDataService.getAllItems(ownershipGroup);
                    data = data.filter(role => role.Type === vm.selectedRoleType);
                    data = $filter('orderBy')(data, 'Name');
                    vm.rolesDropdown.setOptions({ placeholder: `-- Select ${$filter('insertSpaceBetweenCapitalWords')(vm.selectedRoleType)}s --` });
                    vm.rolesDataSource = data;
                    options.success(data);
                }
            }
        });
    }

    async function saveRoleOwner() {
        vm.savePending = new createSpinner();
        let newOwnerId;
        let owners = [];

        owners.push(vm.selectedOwner);

        let data = {
            GroupId: ownershipGroup.Id,
            Owners: owners,
            Items: vm.selectedRoles
        };

        try {
            let response = await ownershipGroupOwnerDataService.addOwnerToGroup(ownershipGroup.Id, ownershipGroup.GroupType, data);
            newOwnerId = response.data;
            await helperService.successfulSaveButton(vm.savePending);
            close(newOwnerId);
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function selectedRoleTypeChanged() {
        vm.rolesDropdown.value([]);
        vm.selectedRoles = null;
        vm.roleDropdownDataSource.read();
    }

    function selectedOwnerTypeChanged() {
        vm.selectedOwner = null;
        vm.filteredOwnersDataSource = vm.availableOwnersDataSource.filter(owner => owner.TypeName === vm.selectedOwnerType);
    }

    function close(ownerId) {
        $uibModalInstance.close(ownerId);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}