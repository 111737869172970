import { customAutoCompleteFilter, standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";
import importObjectModalController from "./importObjectModal.controller.js";

export default /*@ngInject*/ function ( $filter, helperService, $uibModal, salesforceAtDataService, FileSaver, $scope) {
    const vm = this;
    vm.importMetadata = importMetadata;
    vm.saveObjectChange = saveObjectChange;
    vm.exportData = exportData;
    vm.importData = importData;
    vm.$onInit = activate;

    $scope.$on('ImportAuditTrailTrackingObjects', (event, args) => vm.mainGridDataSource.read());
        

    function activate() {
        setupObjectTrackingGrid();
    }

    function setupObjectTrackingGrid() {
        const trackingTemplate = `<label class="switch"><input type="checkbox" ng-true-value="true" ng-false-value="false" class="ng-pristine ng-untouched ng-valid ng-not-empty" ng-model="dataItem.Enabled" ng-change="motgvm.saveObjectChange(dataItem)"/><i></i></label>`;

        const mainGridColumns = [
            { field: "Track", title: "Track", width: 50, filterable: false, template: trackingTemplate },
            { field: "Name", title: "Name", width: 120, filterable: customAutoCompleteFilter },
            { field: "Label", title: "Label", width: 120, filterable: customAutoCompleteFilter },
            { field: "ObjectType", title: "Object Type", width: 120, filterable: customAutoCompleteFilter },
            { field: "Description", title: "Description", width: 250, filterable: customAutoCompleteFilter },
        ];
        vm.mainGridOptions = helperService.setMainGridOptions(mainGridColumns, 600);
        vm.mainGridOptions.selectable = false;

        vm.mainGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async function (options) {
                    try {
                        let { data } = await salesforceAtDataService.getObjectsToTrack();
                        data = $filter('orderBy')(data, 'Name');  
                        vm.totalObjects = data;
                        options.success(data);
                    } catch {
                        options.error();
                    }
                }
            }
        });
    }

    async function importMetadata() {
        vm.importMetadataPending = true;
        await salesforceAtDataService.importObjectTrackingMetadata();
        vm.mainGridDataSource.read();
        vm.importMetadataPending = false;
    }

    async function saveObjectChange(dataItem) {
        await salesforceAtDataService.saveObjectDataChanges(dataItem);
        helperService.setConfigurationValue('SF_AT_LastTrackingFieldUpdateDate', new Date());
    }

    function exportData() {
        vm.exportPending = true;

        salesforceAtDataService.exportObjectData()
            .then(response => {
                FileSaver.saveAs(response.data, 'ObjectTrackingData.xlsx');
                vm.exportPending = false;
            }).catch(response => {
                helperService.showErrorMessage(response.data);
                vm.exportPending = false;
            });
    }

    async function importData() {
        $uibModal.open({
            templateUrl: '/App/Components/salesForceAuditTrail/maintainObjectTracking/maintainObjectTrackingGrid/importObjectModal.html',
            controller: importObjectModalController,
            controllerAs: 'iomvm',
            backdrop: 'static',
        });
    }
}