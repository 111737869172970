import controller from "./configuration.controller";

/* configuration.component.js */

/**
 * @desc component for system configuration
 * @example <configuration></configuration>
 */

const configuration = {
    controller: controller,
    controllerAs: 'cvm',
    templateUrl: '/App/Components/configuration/configuration.tmpl.html'
};

export default configuration;