export default /*@ngInject*/ function ($http, errorsDataService) {
    const factory = {
        getGroupItems,
        getEmergencyAccessGroupRoles,
        getAvailableItems,
        getAvailableBusinessProcesses,
        getAvailableOwnerBusinessProcesses,
        getAllBusinessProcesses,
        getAvailableOwnerItems,
        getAllItems,
        getAvailableRisks,
        getAvailableRisksByOwner,
        getAllRisksForOwner,
        getRisksByRiskCriteria,
        updateRiskCriteria,
        getGroupOwnerItems,
        addItemToGroup,
        removeItemFromOwnershipGroup,
        removeOwnerFromOwnershipGroupItem,
        removeItemFromOwnershipGroupOwner
    };

    return factory;

    async function getGroupItems(groupId, type) {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/${groupId}/type/${type}/items`);
        } catch (err) {
            showError(err);
        }
    }

    async function getEmergencyAccessGroupRoles(groupId) {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/${groupId}/type/role/emergencyaccess`);
        } catch (err) {
            showError(err);
        }
    }

    async function getAvailableItems(ownershipGroup) {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/${ownershipGroup.Id}/type/${ownershipGroup.GroupType}/availableitems`);
        } catch (err) {
            showError(err);
        }
    }

    async function getAvailableBusinessProcesses(groupId, rulesetId) {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/${groupId}/type/businessprocess/ruleset/${rulesetId}/availableitems`);
        } catch (err) {
            showError(err);
        }
    }

    async function getAvailableOwnerBusinessProcesses(groupId, ownerId, rulesetId) {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/${groupId}/type/businessprocess/owners/${ownerId}/ruleset/${rulesetId}/availableitems`);
        } catch (err) {
            showError(err);
        }
    }

    async function getAllBusinessProcesses(groupId, rulesetId) {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/${groupId}/type/businessprocess/ruleset/${rulesetId}/allitems`);
        } catch (err) {
            showError(err);
        }
    }

    async function getAvailableOwnerItems(ownershipGroup, ownerId) {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/${ownershipGroup.Id}/type/${ownershipGroup.GroupType}/owners/${ownerId}/availableitems`);
        } catch (err) {
            showError(err);
        }
    }

    async function getAllItems(ownershipGroup) {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/${ownershipGroup.Id}/type/${ownershipGroup.GroupType}/allitems`);
        } catch (err) {
            showError(err);
        }
    }

    async function getAvailableRisks(groupId, rulesetId) {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/${groupId}/type/risk/ruleset/${rulesetId}/availableitems`);
        } catch (err) {
            showError(err);
        }
    }

    async function getAvailableRisksByOwner(groupId, ownerId, rulesetId) {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/${groupId}/type/risk/owners/${ownerId}/ruleset/${rulesetId}/availableitems`);
        } catch (err) {
            showError(err);
        }
    }

    async function getAllRisksForOwner(groupId, rulesetId) {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/${groupId}/type/risk/ruleset/${rulesetId}/allitems`);
        } catch (err) {
            showError(err);
        }
    }

    async function getRisksByRiskCriteria(groupId, data, page, pageSize) {
        try {
            return await $http({
                method: 'POST',
                url: `${apiUrl}api/universal/ownershipgroups/${groupId}/type/risk/risks`,
                data: data,
                params: { 'pagesize': pageSize, 'page': page }
            });
        } catch (err) {
            showError(err);
        }
    }

    async function updateRiskCriteria(groupId, itemId, putData) {
        try {
            return await $http.put(`${apiUrl}api/universal/ownershipgroups/${groupId}/type/risk/item/${itemId}`, putData);
        } catch (err) {
            showError(err);
        }
    }

    async function getGroupOwnerItems(ownershipGroup, groupOwner) {
        try {
            return await $http.get(`${apiUrl}api/universal/ownershipgroups/${ownershipGroup.Id}/type/${ownershipGroup.GroupType}/owners/${groupOwner.Id}/items`);
        } catch (err) {
            showError(err);
        }
    }

    async function addItemToGroup(data, groupId, type) {
        try {
            return await $http.post(`${apiUrl}api/universal/ownershipgroups/${groupId}/type/${type}/item`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function removeItemFromOwnershipGroup(groupId, type, itemId) {
        try {
            return await $http.delete(`${apiUrl}api/universal/ownershipgroups/${groupId}/type/${type}/items/${itemId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function removeOwnerFromOwnershipGroupItem(ownershipGroup, groupItem, putBody) {
        try {
            return await $http.put(`${apiUrl}api/universal/ownershipgroups/${ownershipGroup.Id}/type/${ownershipGroup.GroupType}/items/${groupItem.Id}/deleteowners`, putBody);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function removeItemFromOwnershipGroupOwner(ownershipGroup, groupOwner, putBody) {
        try {
            return await $http.put(`${apiUrl}api/universal/ownershipgroups/${ownershipGroup.Id}/type/${ownershipGroup.GroupType}/owners/${groupOwner.Id}/deleteitems`, putBody);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(error) {
        errorsDataService.catch(error);
    }

    function sendError(error) {
        throw error;
    }

}