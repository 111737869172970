export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getCompanyTemplates,
        createCompanyTemplate,
        renameCompanyTemplate,
        deleteCompanyTemplate,
        addCompany,
        deleteCompany
    };

    return service;

    async function getCompanyTemplates() {
        try {
            return await $http.get(`${apiUrl}/api/universal/companytemplates`);
        } catch (error) {
            showError(error);
        }
    }

    async function createCompanyTemplate(postData) {
        try {
            return await $http.post(`${apiUrl}/api/universal/companytemplates`, postData);
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    async function renameCompanyTemplate(templateId, putData) {
        try {
            return await $http.put(`${apiUrl}/api/universal/companytemplates/${templateId}`, putData);
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    async function deleteCompanyTemplate(templateId) {
        try {
            return await $http.delete(`${apiUrl}/api/universal/companytemplates/${templateId}`);
        } catch (error) {
            showError(error);
        }
    }

    async function addCompany(templateId, postData) {
        try {
            return await $http.post(`${apiUrl}/api/universal/companytemplates/${templateId}/companies`, postData);
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    async function deleteCompany(templateId, companyMasterId) {
        try {
            return await $http.delete(`${apiUrl}/api/universal/companytemplates/${templateId}/companies/${companyMasterId}`);
        } catch (error) {
            showError(error);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}