export default /*@ngInject*/ function ($http, errorsDataService) {
    const factory = {
        getSimulations,
        getRoles,
        getSimulation,
        getSimulationItemsByType,
        getSimulationChanges,
        getSimulationAuthObjects,
        getIntegrationSettings,
        createSimulation,
        analyzeSimulation,
        publishSimulation,
        updateSimulation,
        updateIntegrationSettings,
        deleteSimulation
    };

    return factory;

    async function getSimulations() {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/simulation`);
        } catch (err) {
            showError(err);
        }
    }

    async function getRoles() {
        try {
            return await $http.get(`${apiUrl}api/assure/sap/roles`);
        } catch (err) {
            showError(err);
        }
    }

    async function getSimulation(simulationId) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/simulation/${simulationId}`);
        } catch (err) {
            showError(err);
        }
    }

    async function getSimulationItemsByType(simulationId, type) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/${type}`);
        } catch (err) {
            showError(err);
        }
    }

    async function getSimulationChanges(simulationId) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/changes`);
        } catch (err) {
            showError(err);
        }
    }

    async function getIntegrationSettings(productId) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/sap/connections/${productId}/integration`);
        } catch (err) {
            showError(err);
        }
    }

    async function getSimulationAuthObjects(simulationId) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/authorizationobjects`);
        } catch (err) {
            showError(err);
        }
    }

    async function createSimulation(data) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/simulation`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function analyzeSimulation(data) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/simulation/${data.SimulationId}/analyze`, data);
        } catch (err) {
            showError(err);
        }
    }

    async function publishSimulation(data) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/simulation/${data.SimulationId}/publish`, data);
        } catch (err) {
            showError(err);
        }
    }

    async function updateSimulation(data) {
        try {
            return await $http.put(`${apiUrl}api/securitydesigner/simulation/${data.Id}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateIntegrationSettings(productId, data) {
        try {
            return await $http.put(`${apiUrl}api/securitydesigner/sap/connections/${productId}/integration`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteSimulation(simulationId) {
        try {
            return await $http.delete(`${apiUrl}api/securitydesigner/simulation/${simulationId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(error) {
        errorsDataService.catch(error);
    }

    function sendError(error) {
        throw error;
    }

}