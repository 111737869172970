import createSpinner from "../../../../Shared/Functions/createSpinner";
import { standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";


export default /*@ngInject*/ function ($uibModalInstance, $filter, ownershipGroup, existingRoles, helperService, ownershipGroupItemsDataService, ownershipGroupOwnerDataService) {
    const vm = this;

    vm.saveRole = saveRole;
    vm.selectedRoleTypeChanged = selectedRoleTypeChanged;
    vm.selectedOwnerTypeChanged = selectedOwnerTypeChanged;
    vm.cancel = cancel;

    vm.ownershipGroup = { ...ownershipGroup };

    activate();

    async function activate() {
        vm.selectedRoleType = 'Role';
        setupRoleDropdown();
        await getOwners();
        vm.selectedOwnerType = 'User';
        selectedOwnerTypeChanged();
    }

    async function setupRoleDropdown() {

        vm.roleDropdownOptions = {
            filter: "contains",
            minLength: 3,
            virtual: {
                itemHeight: 26
            },
            height: 325,
            optionLabel: '-- Select a Role --'
        };


        vm.roleDropdownDataSource = new kendo.data.DataSource({

            pageSize: standardGridPageSize,
            transport: {
                read: async (options) => {
                    let { data } = await ownershipGroupItemsDataService.getAvailableItems(ownershipGroup);
                    vm.rolesDataSource = data.filter(masterRole => !existingRoles.some(role => role.Identifier === masterRole.Identifier) && masterRole.Type === vm.selectedRoleType);
                    options.success(vm.rolesDataSource);
                }
            }
        });

        return vm.roleDropdownDataSource;
    }

    async function getOwners() {
        vm.ownersDataSourceLoading = true;
        let response = await ownershipGroupOwnerDataService.getAllOwners(ownershipGroup.Id);
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.availableOwnersDataSource = response.data;
        vm.ownersDataSourceLoading = false;
        return vm.availableOwnersDataSource;
    }

    async function saveRole() {
        vm.savePending = new createSpinner();
        let newRoleId;
        let items = [];
        items.push(vm.selectedRole);
        let data = {
            GroupId: ownershipGroup.Id,
            Owners: vm.selectedOwners,
            Items: items
        };

        try {

            data.SecurityRoleIdentifier = vm.selectedRole.SecurityRoleIdentifier;
            let response = await ownershipGroupItemsDataService.addItemToGroup(data, ownershipGroup.Id, ownershipGroup.GroupType);
            newRoleId = response.data;
            await helperService.successfulSaveButton(vm.savePending);
            close(newRoleId);
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function selectedRoleTypeChanged() {
        vm.roleDropdown.value([]);
        vm.selectedRole = null;
        vm.roleDropdown.setOptions({ optionLabel: `-- Select a ${$filter('insertSpaceBetweenCapitalWords')(vm.selectedRoleType)} --` });
        vm.roleDropdownDataSource.read();
    }

    function selectedOwnerTypeChanged() {
        vm.selectedOwners = null;
        vm.filteredOwnersDataSource = vm.availableOwnersDataSource.filter(owner => owner.TypeName === vm.selectedOwnerType);
    }

    function close(roleId) {
        $uibModalInstance.close(roleId);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}