import userRisksMitigationModalController from "./userRisksMitigationModal.controller";
import { standardGridPageSize, customAutoCompleteFilter, customBooleanFilter, customDatePickerFilter } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";

export default /*@ngInject*/ function ($scope, $rootScope, $uibModal, SavedReportSelectorService, CustomReportServices, BuildUserRisksPostBody, dataService, objectIdDataService, configurationDataService, $filter, $http, $location, helperService, risksDataService, reportsDataService, reportsService) {

    $scope.$on('kendoWidgetCreated', function () {
        if ($scope.grid) {
            $scope.gridReady = true;
            runSavedReport();
        }
    });

    $scope.helperService = helperService;
    $scope.populateCriteriaModel = populateCriteriaModel;

    var vm = $scope.vm = {};

    vm.selectedCompanies = [];
    vm.riskSelectedIds = [];
    vm.selectedProducts = [];
    vm.dynamicSideGridDataSourceArray = [];
    vm.generateGridWithSelectedValues = generateGridWithSelectedValues;
    vm.productDropdownChange = productDropdownChange;
    vm.companyDropdownChange = companyDropdownChange;
    vm.rulesetDropdownChange = rulesetDropdownChange;
    vm.changeRowSelected = changeRowSelected;
    vm.updateUserDataSource = updateUserDataSource;
    vm.runSavedReport = runSavedReport;

    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    // ---

    activate();

    function activate() {

        objectIdDataService.getObjectID().then(function (objectId) {
            $scope.ObjectId = objectId;

            $scope.filtersDataSource = CustomReportServices.setfiltersDataSource($scope.ObjectId);

            var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
            $scope.pageInfo = securityObject;
            $scope.pageDescription = securityObject.HelpLink;
            $scope.reportName = securityObject.Name;
            $scope.reportId = securityObject.ReportID;

            dataService.getProducts().then(async response => {
                response.data = response.data.filter(p => p.UserHasAccess === true);
                vm.productsDataSource = response.data;
                let defaultProduct = vm.productsDataSource.find(obj => obj.Name === $rootScope.UserPreferences.PreferredProduct);
                let defaultProductSelected = vm.productsDataSource.indexOf(defaultProduct);
                if (vm.isDetailed || vm.isRoleDetail) {
                    if ($rootScope.UserPreferences.PreferredProduct !== null) {
                        vm.selectedProducts = [vm.productsDataSource[defaultProductSelected]];
                    } else {
                        vm.productsDataSource = vm.productsDataSource.filter(p => p.Type !== 'AD')
                        vm.selectedProducts = [vm.productsDataSource[0]];
                    }
                } else {
                    if ($rootScope.UserPreferences.PreferredProduct !== null) {
                        vm.selectedProducts = [vm.productsDataSource[defaultProductSelected]];
                    } else {
                        vm.selectedProducts = angular.copy(vm.productsDataSource);
                    }
                    runSavedReport();
                }

                vm.selectedProducts = SavedReportSelectorService.checkSavedReportForProducts($rootScope.loadedSavedReport, response.data, vm.selectedProducts);

                if (vm.selectedProducts[0].Type === 'SAP') {
                    let { data: s4Enabled } = await configurationDataService.getProductConfigurationValueByName('SAP', 'SAP_ErpType');
                    vm.s4Enabled = s4Enabled.Value === 'S4';
                }

                if (vm.isDetailed || (vm.isRoleDetail && vm.selectedProducts[0].Type === 'SAP')) {
                    if (vm.selectedProducts[0].IsCustom) {
                        $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, "customProduct").ReportID;
                    } else {
                        $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProducts[0].Type).ReportID;
                    }

                } else {
                    $scope.reportId = securityObject.ReportID;
                }

                vm.setColumns();
                runSavedReport();

                // --- FOR ADVANCED FILTERING ---
                getReportMetadata().then(_ => {
                    if ($rootScope.loadedSavedReport) {
                        runSavedReport();
                    }
                });
                // ---
            });

        });

        getCompanies();
        getRulesets()
            .then(_ => {
                return getRisks();
            });
    }

    if ($location.path().has('Detailed')) {
        vm.isDetailed = true;
    } else {
        vm.isDetailed = false;
    }

    if ($location.path().has('RoleDetail')) {
        vm.isRoleDetail = true;
    } else {
        vm.isRoleDetail = false;
    }

    function runSavedReport() {
        if ($scope.gridReady && $rootScope.loadedSavedReport && vm.productsDataSource && vm.companyDataSource && vm.userDataSource && vm.riskDataSource && vm.rulesetDataSource && vm.filterCriteria.fields) {
            vm.filterCriteria.query = reportsService.getSavedReportFilters();
            vm.generateGridWithSelectedValues();
            vm.filterCriteria.needsUpdate = true;
        } else if ($scope.gridReady && !$rootScope.loadedSavedReport && vm.productsDataSource && vm.companyDataSource && vm.userDataSource && vm.riskDataSource && vm.rulesetDataSource && vm.filterCriteria.fields) {
            // We want the necessary report details available to be able to use the report toolbar without running the report
            $scope.populateCriteriaModel();
        }
    }

    // Used in App\Shared\Partials\PageHeader.html(26)
    $scope.changeView = function () {
        if (vm.isDetailed === true) {
            $location.path('/AccessControl/Place/UserRisk').search('');
        } else {
            $location.path('/AccessControl/Place/UserRiskDetailed').search('');
        }
    };

    // Used in App\Shared\Partials\FilterManagerModal.html(10)
    $scope.filterManagerGridOptions = CustomReportServices.setfilterManagerGridOptions();

    vm.dynamicSideGridOptions = helperService.setNonPageableGridOptions([]);

    // Used in App\Shared\Partials\ReportToolbar.html(8)
    $scope.filterDropdownOptions = helperService.getFilterDropdownOptions();

    // Used in App\Shared\Partials\ReportToolbar.html(10)
    $scope.applySelectedFilter = function () {
        helperService.applyFilterCatchError($scope);
    };


    vm.mainGridOptions = helperService.setMainGridOptions(null, 600);
    vm.mainGridOptions.autoBind = false;

    $scope.openModal = function () {
        $uibModal.open({
            templateUrl: '/App/Components/userRisk/userRisksMitigationModal.html',
            controller: userRisksMitigationModalController,
            backdrop: 'static',
            size: 'lg',
            scope: $scope
        });
    };

    $scope.mitigateRisk = function (dataItem) {
        $scope.riskToMitigate = angular.copy(dataItem);
        $scope.openModal();
    };

    vm.deselectSelectedRisk = function () {
        vm.riskSelectedIds = helperService.deselectAllObjectsIfOtherIsClicked(vm.riskSelectedIds, vm.allRisksDropdownObject);
    };

    $scope.gridDataSource = new kendo.data.DataSource({

        pageSize: standardGridPageSize,
        schema: angular.extend(halSchemaKendoGrid, {
            model: {
                fields: {
                    DefaultMitigated: { type: "boolean" },
                    DefaultMitigationCondition: { type: "boolean" },
                    AutoMitigated: { type: "boolean" },
                    RiskId: { type: "number" },
                    LastRun: { type: "date" },
                    UseCount: { type: "number" },
                    UserLastUpdatedDate: { type: "date" }
                }
            }
        }),
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {

            read: function (options) {
                delete $rootScope.loadedSavedReport;
                var data = BuildUserRisksPostBody.generateBody($scope.ObjectId, vm.selectedUserIds, $scope.companies, $scope.risks, $scope.products, vm.selectedRuleset.RulesetId);
                data['QueryInput'] = helperService.prepareSortsAndFilters(options);

                // --- FOR ADVANCED FILTERING ---
                if (vm.filterCriteria.query) {
                    data['QueryInput'].Filters = data['QueryInput'].Filters.concat(vm.filterCriteria.query);
                }
                // ---

                // if tabs are on, save report info for the tab
                if ($rootScope.tabs.length > 0) {
                    reportsService.saveTabReportInfo($scope);
                }

                $http({
                    method: 'POST',
                    url: $scope.urlString,
                    data: data,
                    params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                }).then(function (response) {
                    var data = response.data._embedded.reportData;

                    data = $filter('orderBy')(data, 'UserName');
                    options.success(response.data);
                    vm.enableGrid = true;

                    if (vm.isDetailed) {
                        let securityObject;
                        if (vm.selectedProducts[0].IsCustom) {
                            // the security object for custom products has null for product type so we can't send the real product type
                            securityObject = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, "customProduct");
                        } else {
                            securityObject = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProducts[0].Type);
                        }
                        $scope.reportId = securityObject.ReportID;
                    }

                    if (data.length > 0) {
                        vm.dynamicSideGridDataSourceArray = [];
                    }

                }).catch(response => {
                    const timeout = 408;

                    if (response.status !== timeout) {
                        helperService.showErrorMessage(response.data);
                    }
                });
            }
        }
    });

    function updateUserDataSource(selectedUsers, selectedUserIds, userDataSource) {
        vm.users = selectedUsers;
        vm.selectedUserIds = selectedUserIds;
        vm.userDataSource = userDataSource;
        populateCriteriaModel();
    }

    function continuePopulateCriteriaModel() {
        return vm.selectedProducts && vm.selectedUserIds && vm.selectedCompanies && vm.riskSelectedIds;
    }

    function populateCriteriaModel() {
        // only continue if the proper criteria are loaded
        if (continuePopulateCriteriaModel()) {

            var criteria = [];

            $scope.products = vm.selectedProducts;
            $scope.users = vm.users?.length ? vm.users : [{ "Name": "All Users", "ID": null }];
            $scope.companies = vm.selectedCompanies;
            $scope.risks = vm.riskSelectedIds;

            $scope.products.forEach(function (row, i) {
                criteria.push(CustomReportServices.CreateCriteria("ProductId", 'Product', row.Name, row.ID));
            });

            $scope.users.forEach(function (user) {
                criteria.push(CustomReportServices.CreateCriteria("UserMasterId", 'User', user.Name, user.ID));
            });

            criteria.push(CustomReportServices.CreateCriteria("RulesetId", 'Ruleset', vm.selectedRuleset.Name, vm.selectedRuleset.RulesetId));

            $scope.companies.forEach(function (row) {
                criteria.push(CustomReportServices.CreateCriteria("CompanyMasterId", 'Company', row.Name, row.ID));
            });

            $scope.risks.forEach(function (row, i) {
                criteria.push(CustomReportServices.CreateCriteria("RiskId", 'Risk', row.Name, row.Id));
            });

            if (vm.isRoleDetail) {
                if (vm.selectedProducts[0].Type === 'SAP') {
                    $scope.urlString = `${apiUrl}api/arm/sap/places/userriskroledetail`;
                } else {
                    $scope.urlString = `${apiUrl}api/arm/places/userriskroledetail`;
                }
            } else if (vm.selectedProducts.length === 1) {
                setSingleProductRoute()
            } else {
                setMultiProductRoute();
            }

            function setSingleProductRoute() {
                if (vm.isDetailed) {
                    let productString = vm.selectedProducts[0].Type === 'SAP' ? 'SAP' : vm.selectedProducts[0].ID;
                    $scope.urlString = `${apiUrl}api/arm/${productString}/places/userrisk`;
                } else {
                    $scope.urlString = `${apiUrl}api/arm/places/userrisk`;
                }
            }

            function setMultiProductRoute() {
                $scope.urlString = `${apiUrl}api/arm/places/userrisk`;
            }

            $scope.reportDetailsModel = criteria;

            // if tabs are on, save report info for the tab
            if ($rootScope.tabs.length > 0) {
                reportsService.saveTabReportInfo($scope);
            }
        }

    }

    function generateGridWithSelectedValues() {

        if (!$rootScope.loadedSavedReport) {
            $scope.grid.dataSource._filter = null;
            $scope.grid.dataSource._sort = null;
        }

        populateCriteriaModel();
        vm.hideExportOptionsFromSubmit = true;

        // Add back the sorting and filtering options if we are running the report for the first time
        if (!$rootScope.loadedSavedReport) {
            $scope.grid.setOptions({
                filterable: {
                    mode: "row"
                },
                sortable: true
            });
        }

        SavedReportSelectorService.queryGridWithOptions($rootScope.loadedSavedReport, $scope.grid, true);
    }

    vm.setColumns = function () {
        if (vm.selectedProducts) {

            if (vm.selectedProducts.length === 1 && vm.isDetailed === true) {
                if (vm.selectedProducts[0].Type === 'OR') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "UserDescription", title: "User Description", width: 160, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 160, filterable: customAutoCompleteFilter },
                        { field: "RiskName", title: "Risk Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "BusinessCycle", title: "Business Cycle", width: 180, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcess", title: "Business Process", width: 130, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "Responsibility", title: "Responsibility", width: 180, filterable: customAutoCompleteFilter },
                        { field: "ResponsibilityKey", title: "Responsibility Key", width: 180, filterable: customAutoCompleteFilter },
                        { field: "ResponsibilityID", title: "Responsibility ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserMenuName", title: "User Menu Name", width: 130, filterable: customAutoCompleteFilter },
                        { field: "MenuName", title: "Menu Name", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "UserSubMenuName", title: "User Sub Menu Name", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SubMenuName", title: "Sub Menu Name", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SecurityObjectProductName", title: "Security Object Product", width: 80, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "User Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 200, filterable: customAutoCompleteFilter },
                        { field: "FunctionDescription", title: "Object Description", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 90, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 130, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 190, filterable: customAutoCompleteFilter },
                        { field: "AutoMitigated", title: "Auto Mitigated", width: 180, filterable: customBooleanFilter },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "Prompt", title: "Prompt", width: 200, filterable: customAutoCompleteFilter },
                        { field: "Path", title: "Path", width: 200, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 190, filterable: customAutoCompleteFilter }
                    ];

                    if (!helperService.convertConfigValueToTrueFalse('OR_RBACEnabled')) {
                        $scope.mainGridColumns.splice(14, 0, { field: "Role", title: "Role", width: 180, filterable: customAutoCompleteFilter }, { field: "RoleCode", title: "Role Code", width: 180, filterable: customAutoCompleteFilter });
                        $scope.mainGridColumns.splice(17, 0, { field: "AssignmentType", title: "Assignment Type", width: 180, filterable: customAutoCompleteFilter })
                    }

                } else if (vm.selectedProducts[0].Type === 'INT') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "RiskName", title: "Risk Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "BusinessCycle", title: "Business Cycle", width: 180, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectProductName", title: "Security Object Product", width: 80, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Security Object Label", width: 190, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Security Object Type", width: 190, filterable: customAutoCompleteFilter },
                        { field: "ApplicationName", title: "Application Name", width: 180, filterable: customAutoCompleteFilter, hidden: !helperService.convertConfigValueToTrueFalse("INT_RoleBasedSecurity") },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter, hidden: helperService.convertConfigValueToTrueFalse("INT_RoleBasedSecurity") },
                        { field: "AssignedUser", title: "Assigned User", width: 190, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 90, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description", width: 320, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 190, filterable: customAutoCompleteFilter },
                        { field: "AccessType", title: "Access Type", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 130, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 190, filterable: customAutoCompleteFilter }
                    ];

                } else if (vm.selectedProducts[0].Type === 'NS') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 150, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "UserType", title: "User Type", width: 100, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 100, filterable: customAutoCompleteFilter },
                        { field: "RiskName", title: "Risk Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "BusinessCycle", title: "Business Cycle ", width: 150, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 90, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description ", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 130, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 190, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcess", title: "Business Process", width: 190, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectProductName", title: "Security Object Product", width: 80, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Security Object", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Security Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "AccessType", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Role", title: "Role", width: 130, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'NAV') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 150, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "FullName", title: "Full Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 100, filterable: customAutoCompleteFilter },
                        { field: "RiskName", title: "Risk Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "BusinessCycle", title: "Business Cycle ", width: 150, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "BusinessProcess", title: "Business Process", width: 190, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "RoleId", title: "Role ID", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectProductName", title: "Security Object Product", width: 80, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Security Object", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Security Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessType", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessLevel", title: "Access Level", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 90, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description ", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 130, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 190, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'D365Bc') {
                    $scope.mainGridColumns = [
                        { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "UserDescription", title: "User Description", width: 150, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 100, filterable: customAutoCompleteFilter },
                        { field: "RiskName", title: "Risk Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "BusinessCycle", title: "Business Cycle ", width: 150, filterable: customAutoCompleteFilter },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "BusinessProcess", title: "Business Process", width: 190, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "RoleId", title: "Role ID", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectProductName", title: "Security Object Product", width: 80, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Security Object", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Security Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessType", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessLevel", title: "Access Level", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 90, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description ", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 130, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 190, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'Zuo') {
                    $scope.mainGridColumns = [
                        { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "UserDescription", title: "User Description", width: 150, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 150, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 100, filterable: customAutoCompleteFilter },
                        { field: "RiskName", title: "Risk Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "BusinessCycle", title: "Business Cycle ", width: 150, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "BusinessProcess", title: "Business Process", width: 190, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "RoleId", title: "Role ID", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectProductName", title: "Security Object Product", width: 80, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Security Object", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Security Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessType", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessLevel", title: "Access Level", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 90, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description ", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 130, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 190, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'CPA') {
                    $scope.mainGridColumns = [
                        { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "UserDescription", title: "User Description", width: 150, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 100, filterable: customAutoCompleteFilter },
                        { field: "RiskName", title: "Risk Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "BusinessCycle", title: "Business Cycle ", width: 150, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "BusinessProcess", title: "Business Process", width: 190, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "RoleId", title: "Role ID", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectProductName", title: "Security Object Product", width: 80, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Security Object", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Security Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessType", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 90, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description ", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 130, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 190, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessType", title: "Access Type", width: 190, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'AX7') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 150, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "RiskName", title: "Risk Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description", width: 180, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcess", title: "Business Process", width: 130, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectProductName", title: "Security Object Product", width: 80, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectID", title: "Security Object ID", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SecurityObjectName", title: "Security Object", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Security Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleSystemName", title: "Role System Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SubRoleName", title: "Sub Role", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SubRoleSystemName", title: "Sub Role System Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DutyName", title: "Duty", width: 130, filterable: customAutoCompleteFilter },
                        { field: "DutySystemName", title: "Duty System Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "PrivilegeName", title: "Privilege", width: 130, filterable: customAutoCompleteFilter },
                        { field: "PrivilegeSystemName", title: "Privilege System Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Policy", title: "Policy", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessCycle", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "RiskLevel", title: "Risk Level", width: 200, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 90, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 320, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter }

                    ];
                } else if (vm.selectedProducts[0].Type === 'SAP') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 150, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "UserGroup", title: "User Group", width: 150, filterable: customAutoCompleteFilter },
                        { field: "UserType", title: "User Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskName", title: "Risk Name", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcess", title: "Business Process", width: 130, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "Role", title: "Role", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompositeRole", title: "Composite Role", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectProductName", title: "Security Object Product", width: 80, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Label", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Authorization", title: "Authorization", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Field", title: "Field", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RulesetLow", title: "Ruleset Low", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RulesetHigh", title: "Ruleset High", width: 130, filterable: customAutoCompleteFilter },
                        { field: "TechnicalLow", title: "Technical Low", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Low", title: "Low", width: 130, filterable: customAutoCompleteFilter },
                        { field: "TechnicalHigh", title: "Technical High", width: 130, filterable: customAutoCompleteFilter },
                        { field: "High", title: "High", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description", width: 320, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 320, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessCycle", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "AutoMitigated", title: "Auto Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter },
                        { field: "UseCount", title: "Use Count", width: 130, filterable: customAutoCompleteFilter },
                        { field: "LastRun", title: "Last Run Date", template: "{{ dataItem.LastRun | date:'short'}}", width: 200, filterable: customDatePickerFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'SAPB1') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 150, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "RiskName", title: "Risk Name", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description", width: 320, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 190, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessCycle", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "Mitigation", title: "Mitigation", width: 90, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 200, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcess", title: "Business Process", width: 150, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectProductName", title: "Security Object Product", width: 80, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AuthorizationSource", title: "Authorization Source", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'SF') {
                    $scope.mainGridColumns = [
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserID", title: "User ID", width: 150, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskName", title: "Risk Name", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description", width: 320, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter },
                        { field: "BusinessCycle", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "Mitigation", title: "Mitigation", width: 130, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 190, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectProductName", title: "Security Object Product", width: 80, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectProductModule", title: "Module", width: 150, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcess", title: "Business Process", width: 150, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AssignedByType", title: "Access by Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AssignedBy", title: "Access By", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter }

                    ];
                } else if (vm.selectedProducts[0].Type === 'GP') {
                    $scope.mainGridColumns = [
                        { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskName", title: "Risk Name", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "BusinessCycle", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "BusinessProcess", title: "Business Process", width: 130, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role", width: 130, filterable: customAutoCompleteFilter },
                        { field: "TaskId", title: "Task ID", width: 130, filterable: customAutoCompleteFilter },
                        { field: "TaskName", title: "Task", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Series", title: "Series", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Alt Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description", width: 320, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 190, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 130, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessType", title: "Access Type", width: 190, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'AX') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 150, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "RiskName", title: "Risk Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcess", title: "Business Process", width: 130, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RolePhysicalName", title: "Role Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SubRoleName", title: "Sub Role", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SubRolePhysicalName", title: "Sub Role Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DutyName", title: "Duty", width: 130, filterable: customAutoCompleteFilter },
                        { field: "DutyPhysicalName", title: "Duty Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "PrivilegeName", title: "Privilege", width: 130, filterable: customAutoCompleteFilter },
                        { field: "PrivilegePhysicalName", title: "Privilege Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "EntryPointName", title: "Entry Point Name", width: 130, filterable: customAutoCompleteFilter },
                        { field: "EntryPointID", title: "Entry Point Physical Name", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "EntryPointTypeName", title: "Entry Point Type Name", width: 130, filterable: customAutoCompleteFilter },
                        { field: "EntryPointAccess", title: "Entry Point Access", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectProductName", title: "Security Object Product", width: 80, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Security Object Alt Name", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Security Object", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Security Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessType", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessCycle", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "RiskLevel", title: "Risk Level", width: 200, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 90, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 320, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'AC') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "RiskName", title: "Risk Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "BusinessCycle", title: "Business Cycle", width: 180, filterable: customAutoCompleteFilter },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "BusinessProcess", title: "Business Process", width: 150, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectProductName", title: "Security Object Product", width: 80, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Security Object Label", width: 190, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Security Object Type", width: 190, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 190, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 90, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description", width: 320, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 190, filterable: customAutoCompleteFilter },
                        { field: "AccessType", title: "Access Type", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 130, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 190, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'PS') {
                    $scope.mainGridColumns = [
                        { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "RiskName", title: "Risk Name", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description", width: 320, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessCycle", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "Mitigation", title: "Mitigation", width: 90, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 320, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", filterable: customAutoCompleteFilter, width: 90 },
                        { field: "SecurityObjectProductName", title: "Security Object Product", width: 80, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Security Object Label", width: 190, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Security Object Name", width: 190, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Security Object Type", width: 190, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessLevelName", title: "Access Level", width: 130, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcess", title: "Business Process", width: 140, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role", width: 190, filterable: customAutoCompleteFilter },
                        { field: "PermissionListId", title: "Permission List", width: 160, filterable: customAutoCompleteFilter },
                        { field: "MenuName", title: "Menu", width: 200, filterable: customAutoCompleteFilter },
                        { field: "BarName", title: "Bar Name", width: 100, filterable: customAutoCompleteFilter },
                        { field: "ComponentName", title: "Component", width: 190, filterable: customAutoCompleteFilter },
                        { field: "MarketName", title: "Market", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'JDE') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 150, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "RiskName", title: "Risk Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "BusinessCycle", title: "Business Cycle", width: 180, filterable: customAutoCompleteFilter },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "RiskDescription", title: "Risk Description", width: 320, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 190, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcess", title: "Business Process", width: 140, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Security Object Label", width: 190, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Security Object Name", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Application", title: "Application", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Version", title: "Version", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessSource", title: "Access Source", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 90, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Security Object Type", width: 140, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 90, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 130, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 190, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'ORFC') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 150, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "UserDescription", title: "User Description", width: 150, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "RiskName", title: "Risk Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description", width: 320, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "BusinessCycle", title: "Business Cycle", width: 180, filterable: customAutoCompleteFilter },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "DefaultMitigationCondition", title: "Default Mitigation Condition", width: 180, filterable: customBooleanFilter, hidden: true },
                        { field: "BusinessProcess", title: "Business Process", width: 140, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RolePhysicalName", title: "Role Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SubRoleName", title: "Sub Role Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SubRolePhysicalName", title: "Sub Role Physical Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Security Object Type", width: 140, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 130, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 190, filterable: customAutoCompleteFilter },
                        { field: "AutoMitigated", title: "Auto Mitigated", width: 180, filterable: customBooleanFilter },
                        { field: "Policy", title: "Policy", width: 90, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserLastUpdatedBy", title: "User Last Updated By", width: 120, filterable: customAutoCompleteFilter },
                        { field: "UserLastUpdatedDate", title: "User Last Updated Date", template: "{{ dataItem.UserLastUpdatedDate | date:'short'}}", width: 200, filterable: customDatePickerFilter },
                        { field: "RolePath", title: "Role Path", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'AX5') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "RiskName", title: "Risk Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description", width: 320, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "BusinessCycle", title: "Business Cycle", width: 180, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 190, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcess", title: "Business Process", width: 140, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "GroupId", title: "AX Group", width: 180, filterable: customAutoCompleteFilter },
                        { field: "DomainId", title: "AX Domain", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 90, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 130, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 190, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'AAD') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "RiskName", title: "Risk Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description", width: 320, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 190, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcess", title: "Business Process", width: 140, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "Role", title: "Role", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Group", title: "Entra ID Group", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SubGroup", title: "Entra ID SubGroup", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'SL') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "RiskName", title: "Risk Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description", width: 320, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "BusinessCycle", title: "Business Cycle", width: 180, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 190, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcess", title: "Business Process", width: 140, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "AccessSource", title: "Access Source", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 90, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 130, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 190, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'WD') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: "User ID", width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "UserDescription", title: "User Description", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RulesetName", title: "Ruleset Name", width: 160, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 320, filterable: customAutoCompleteFilter },
                        { field: "RiskName", title: "Risk Name", width: 140, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "BusinessCycle", title: "Business Cycle", width: 180, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "RiskLevel", title: "Risk Level", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectProductName", title: "Object Product Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Label", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AccessType", title: "Access Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcess", title: "Business Process", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "SecurityGroup", title: "Security Group", width: 180, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcessType", title: "Business Process Type", width: 180, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcessDefinition", title: "Business Process Definition", width: 180, filterable: customAutoCompleteFilter },
                        { field: "StepOrder", title: "Step Order", width: 180, filterable: customAutoCompleteFilter },
                        { field: "RoutingRestrictions", title: "Routing Restrictions", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Condition", title: "Condition", width: 180, filterable: customAutoCompleteFilter },
                        { field: "CostCenter", title: "Cost Center", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Country", title: "Country", width: 180, filterable: customAutoCompleteFilter },
                        { field: "JobProfile", title: "Job Profile", width: 180, filterable: customAutoCompleteFilter },
                        { field: "Manager", title: "Manager", width: 180, filterable: customAutoCompleteFilter },
                        { field: "WorkerStatus", title: "Worker Status", width: 180, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'D365S') {
                    $scope.mainGridColumns = [
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: "User Status", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: "Risk ID", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskName", title: "Risk Name", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "BusinessCycle", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessProcess", title: "Business Process", width: 130, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "RoleName", title: "Role", width: 130, filterable: customAutoCompleteFilter },
                        { field: "TeamName", title: "Team Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "PrivilegeName", title: "Privilege", width: 130, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: "Object Alt Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: "Object Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "AccessType", title: "Access Type", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description", width: 320, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 130, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 190, filterable: customAutoCompleteFilter }
                    ];
                } else if (vm.selectedProducts[0].Type === 'OREPM') {
                    $scope.mainGridColumns = [
                        { field: "UserID", title: 'User Id', width: 190, filterable: customAutoCompleteFilter },
                        { field: "UserName", title: 'User Name', width: 190, filterable: customAutoCompleteFilter },
                        { field: "RiskId", title: 'Risk Id', width: 190, filterable: customAutoCompleteFilter },
                        { field: "RiskName", title: 'Risk Name', width: 190, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: 'Description', width: 190, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: 'Risk', width: 190, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: 'Policy', width: 190, filterable: customAutoCompleteFilter },
                        { field: "BusinessCycle", title: 'Business Cycle', width: 190, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "DefaultMitigated", title: 'Default Mitigated', width: 190, filterable: customAutoCompleteFilter },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", width: 190, filterable: customAutoCompleteFilter },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: 'Assigned User', width: 190, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: 'Mitigation', width: 190, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: 'Mitigation Controls', width: 190, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: 'Mitigation Notes', width: 190, filterable: customAutoCompleteFilter },
                        { field: "BusinessProcess", title: 'Business Process', width: 190, filterable: customAutoCompleteFilter },
                        { field: "GroupName", title: 'Group Name', width: 190, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: 'Group Type', width: 190, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectProductName", title: 'Object Product', width: 190, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: 'Object Label', width: 190, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: 'Object Name', width: 190, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: 'Object Type', width: 190, filterable: customAutoCompleteFilter },
                        { field: "Service", title: 'Service', width: 190, filterable: customAutoCompleteFilter },
                        { field: "GrantedThroughGroup", title: 'Granted Through Group', width: 190, filterable: customAutoCompleteFilter },
                        { field: "Enabled", title: 'User Enabled', width: 190, filterable: customAutoCompleteFilter },
                    ];
                } else if (vm.selectedProducts[0].IsCustom === true) {
                    $scope.mainGridColumns = [
                        { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                        { field: "UserDescription", title: "User Description", width: 320, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "RiskId", title: "Risk ID", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "RiskName", title: "Risk Name", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RiskDescription", title: "Risk Description", width: 320, filterable: customAutoCompleteFilter },
                        { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                        { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                        { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessCycle", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessProcess", title: "Business Process", width: 130, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                        { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                        { field: "RoleType", title: "Role Type", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "Role", title: "Role", width: 130, filterable: customAutoCompleteFilter },
                        { field: "RoleId", title: "Role Id", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                        { field: "SecurityObjectProductName", title: 'Object Product', width: 190, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectAltName", title: 'Object Label', width: 190, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectName", title: 'Object Name', width: 190, filterable: customAutoCompleteFilter },
                        { field: "SecurityObjectTypeName", title: 'Object Type', width: 190, filterable: customAutoCompleteFilter },
                        { field: "AccessTypeName", title: 'Access Type', width: 190, filterable: customAutoCompleteFilter },
                        { field: "AccessLevelName", title: 'Access Level', width: 190, filterable: customAutoCompleteFilter },
                        { field: "AutoMitigated", title: "Auto Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                        { field: "DefaultMitigationCondition", title: "Default Mitigation Condition", width: 180, filterable: customBooleanFilter, hidden: true },
                        { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", filterable: customAutoCompleteFilter, width: 190 },
                        { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                        { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                        { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter },
                        { field: "Mitigation", title: "Mitigation", width: 130, filterable: customAutoCompleteFilter },
                        { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                        { field: "MitigationNotes", title: "Mitigation Notes", width: 130, filterable: customAutoCompleteFilter }
                    ];
                }
            } else if (vm.isRoleDetail) {
                $scope.mainGridColumns = [
                    { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                    { field: "UserDescription", title: "User Description", width: 320, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "RiskId", title: "Risk ID", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "RiskName", title: "Risk Name", width: 130, filterable: customAutoCompleteFilter },
                    { field: "RiskDescription", title: "Risk Description", width: 320, filterable: customAutoCompleteFilter },
                    { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                    { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                    { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "BusinessCycle", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "BusinessProcess", title: "Business Process", width: 130, filterable: customAutoCompleteFilter },
                    { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "GroupName", title: "Group Name", width: 180, filterable: customAutoCompleteFilter },
                    { field: "GroupType", title: "Group Type", width: 120, filterable: customAutoCompleteFilter },
                    { field: "RoleType", title: "Role Type", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "Role", title: "Role", width: 130, filterable: customAutoCompleteFilter },
                    { field: "RoleId", title: "Role Id", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "AutoMitigated", title: "Auto Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                    { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                    { field: "DefaultMitigationCondition", title: "Default Mitigation Condition", width: 180, filterable: customBooleanFilter, hidden: true },
                    { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", filterable: customAutoCompleteFilter, width: 190 },
                    { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                    { field: "CompanyID", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                    { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter },
                    { field: "Mitigation", title: "Mitigation", width: 130, filterable: customAutoCompleteFilter },
                    { field: "MitigationControls", title: "Mitigation Controls", width: 180, filterable: customAutoCompleteFilter },
                    { field: "MitigationNotes", title: "Mitigation Notes", width: 130, filterable: customAutoCompleteFilter }
                ];
                if (vm.selectedProducts[0].Type === 'SAP') {
                    let roleIndex = $scope.mainGridColumns.findIndex(column => column.field === 'Role');
                    $scope.mainGridColumns.splice(roleIndex + 1, 0, { field: "RoleDescription", title: "Role Description", width: 130, filterable: customAutoCompleteFilter }, { field: "CompositeRole", title: "Composite Role", width: 130, filterable: customAutoCompleteFilter }, { field: "CompositeRoleDescription", title: "Composite Role Description", width: 320, filterable: customAutoCompleteFilter });
                    if (vm.s4Enabled) {
                        $scope.mainGridColumns.splice(roleIndex + 4, 0, { field: "Catalog", title: "Catalog", width: 130, filterable: customAutoCompleteFilter }, { field: "CatalogDescription", title: "Catalog Description", width: 130, filterable: customAutoCompleteFilter })
                    }

                    let mitigationNotesIndex = $scope.mainGridColumns.findIndex(column => column.field === 'MitigationNotes');
                    $scope.mainGridColumns.splice(mitigationNotesIndex + 1, 0,
                        { field: "BusinessUnit", title: "Business Unit", width: 200, filterable: customAutoCompleteFilter },
                        { field: "Department", title: "Department", width: 200, filterable: customAutoCompleteFilter },
                        { field: "JobTitle", title: "Job Title", width: 200, filterable: customAutoCompleteFilter },
                        { field: "Location", title: "Location", width: 200, filterable: customAutoCompleteFilter }
                    );
                }
            } else {
                $scope.mainGridColumns = [
                    { field: "UserName", title: "User Name", width: 150, filterable: customAutoCompleteFilter },
                    { field: "UserDescription", title: "User Description", width: 320, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "RiskId", title: "Risk ID", width: 180, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "RiskName", title: "Risk Name", width: 130, filterable: customAutoCompleteFilter },
                    { field: "RiskDescription", title: "Risk Description", width: 320, filterable: customAutoCompleteFilter },
                    { field: "RiskLevel", title: "Risk Level", width: 90, filterable: customAutoCompleteFilter },
                    { field: "RiskTypeName", title: "Risk Type", width: 160, filterable: customAutoCompleteFilter },
                    { field: "Policy", title: "Policy", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "BusinessCycle", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "JobTitle", title: "Job Title", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "Department", title: "Department", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "Location", title: "Location", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "BusinessUnit", title: "Business Unit", width: 130, filterable: customAutoCompleteFilter, hidden: true },
                    { field: "AutoMitigated", title: "Auto Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                    { field: "DefaultMitigated", title: "Default Mitigated", width: 130, filterable: customBooleanFilter, hidden: true },
                    { field: "Status", title: "Status", template: "<span ng-if=\"!$root.UserSecurityList['10310']\">{{dataItem.Status}}</span><a ng-if=\"$root.UserSecurityList['10310']\" ng-click='mitigateRisk(dataItem)'>{{dataItem.Status}}</a>", filterable: customAutoCompleteFilter, width: 190 },
                    { field: "Company", title: "Company", width: 130, filterable: customAutoCompleteFilter },
                    { field: "CompanyIds", title: "Company ID", width: 180, filterable: customAutoCompleteFilter },
                    { field: "AssignedUser", title: "Assigned User", width: 130, filterable: customAutoCompleteFilter }
                ];
            }

            // Don't allow filtering and sorting yet because they will force the report to run
            if (!$rootScope.loadedSavedReport) {
                vm.mainGridOptions.sortable = false;

                vm.mainGridOptions.filterable = false;
            }

            setLayout();
        }
    };

    function setLayout() {

        var productId;
        if (vm.isDetailed === true && vm.selectedProducts.length === 1) {
            productId = vm.selectedProducts[0].ID;
        }

        if (!$rootScope.loadedSavedReport) {
            CustomReportServices.loadSavedGridLayout($scope, productId);
        }
    }

    function productDropdownChange() {
        if (vm.isDetailed) {
            if (vm.selectedProducts && vm.selectedProducts.length > 1) {
                vm.selectedProducts.remove(vm.selectedProducts[0]);
            }
        }
        if (vm.isRoleDetail) {
            if (vm.selectedProducts && vm.selectedProducts.length > 1) {
                // check to see if all products have same product Type
                if (vm.selectedProducts.some(product => product.Type !== vm.selectedProducts[0].Type)) {
                    // if the last added product doesn't match type, we need to replace the array with the new product
                    vm.selectedProducts = [vm.selectedProducts[vm.selectedProducts.length - 1]];
                }
            }
        }
        if (vm.isDetailed || vm.isRoleDetail) {
            if (vm.selectedProducts) {
                if ((vm.selectedProducts[0].Type === 'SAP' && vm.isRoleDetail) || vm.isDetailed) {
                    if (vm.selectedProducts[0].IsCustom) {
                        $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, "customProduct").ReportID;
                    } else {
                        $scope.reportId = helperService.getSecurityObjectDetailsFromObjectIdAndProductType($scope.ObjectId, vm.selectedProducts[0].Type).ReportID
                    }
                } else {
                    $scope.reportId = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId).ReportID;
                }
                getReportMetadata();
            }
        }
        vm.setColumns();
    }

    function getCompanies() {
        return risksDataService.getCompanies()
            .then(response => {
                response.data = $filter('orderBy')(response.data, 'Name');
                vm.allCompaniesDropdownObject = { "Name": "All Companies", "ID": "AllObjectsInMultiSelect" };
                response.data = response.data.filter(c => c.UserHasAccess === true);
                response.data.unshift(vm.allCompaniesDropdownObject);
                vm.companyDataSource = response.data;
                let defaultCompany = response.data.find(obj => obj.Name === $rootScope.UserPreferences.PreferredCompany);
                let defaultCompanySelected = vm.companyDataSource.indexOf(defaultCompany);
                if ($rootScope.UserPreferences.PreferredCompany !== null) {
                    vm.selectedCompanies = [vm.companyDataSource[defaultCompanySelected]];
                } else {
                    vm.selectedCompanies = [vm.allCompaniesDropdownObject];
                }
                vm.selectedCompanies = SavedReportSelectorService.checkSavedReportForCompanies($rootScope.loadedSavedReport, response.data, vm.selectedCompanies);
                vm.companyDropdownChange();
                runSavedReport();
            });
    }

    function getRulesets() {
        return risksDataService.getRulesets()
            .then(response => {
                response.data = $filter('filter')(response.data, { IsEnabled: true });
                vm.rulesetDataSource = response.data;
                vm.rulesetDataSource = $filter('orderBy')(response.data, 'Name');
                vm.selectedRuleset = SavedReportSelectorService.checkSavedReportForRuleset($rootScope.loadedSavedReport, response.data, vm.rulesetDataSource);
                runSavedReport();
            });
    }

    function getRisks() {
        return risksDataService.getRisks(vm.selectedRuleset.RulesetId)
            .then(response => {
                response.data = $filter('filter')(response.data, { Enabled: true });
                response.data = $filter('orderBy')(response.data, 'Name');
                vm.allRisksDropdownObject = { "Name": "All Risks", "Id": 'AllObjectsInMultiSelect' };
                response.data.unshift(vm.allRisksDropdownObject);
                vm.riskDataSource = response.data;
                vm.riskSelectedIds = [vm.allRisksDropdownObject];
                vm.riskSelectedIds = SavedReportSelectorService.checkSavedReportForRisks($rootScope.loadedSavedReport, response.data, vm.riskSelectedIds);
                runSavedReport();
            });
    }

    function companyDropdownChange(companies) {
        if (companies) {
            vm.selectedCompanies = helperService.deselectAllObjectsIfOtherIsClicked(companies, vm.allCompaniesDropdownObject);
        } else {
            vm.selectedCompanies = helperService.deselectAllObjectsIfOtherIsClicked(vm.selectedCompanies, vm.allCompaniesDropdownObject);
        }
    }

    function rulesetDropdownChange() {
        getRisks();
        if (vm.filterCriteria.query && vm.filterCriteria.query.some(criteria => criteria.CriteriaDisplayName.includes("Business Process"))) {
            vm.filterCriteria.query = vm.filterCriteria.query.filter(criteria => {
                if (criteria.CriteriaDisplayName) {
                    return !criteria.CriteriaDisplayName.includes("Business Process");
                } else {
                    return criteria;
                }
            });
            vm.filterCriteria.needsUpdate = true;
        }
    }

    function changeRowSelected(kendoEvent) {
        vm.sideGridIsNew = false;
        vm.sideGridLoading = true;
        vm.dynamicSideGridDataSourceArray = [];
        vm.dynamicSideGridDataSource = [];

        vm.dynamicSideGridOptions = helperService.setNonPageableGridOptions([]);
        vm.sideGridIsNew = true;
        var grid = kendoEvent.sender;
        $scope.sideGridHeader = grid.dataItem(grid.select()).ProductName;
        CustomReportServices.getATChangeCountForRow($scope, grid);
        CustomReportServices.getAccessDetailsForRowUserRisks(vm, grid).then(response => {
            if (response.data.length > 0) {
                CustomReportServices.getAccessDetailsForEachProduct(response.data, grid).then(productAccessDetailsResponse => {
                    vm.accessDetailsForEachProduct = productAccessDetailsResponse;
                    vm.accessDetailsForEachProduct = vm.accessDetailsForEachProduct.filter(row => row.DataSource.length > 0);
                    createDataSources();
                });
            }

            function createDataSources() {

                vm.accessDetailsForEachProduct.forEach((row, i) => {
                    let dynamicSideGridColumns = Object.keys(row.DataSource[0]).map(f => {
                        var column = {};
                        column.field = f;
                        column.title = helperService.headerStringTransformer(f);
                        return column;
                    });
                    vm.dynamicSideGridDataSource = new kendo.data.DataSource({
                        data: row.DataSource
                    });
                    vm.dynamicSideGridDataSourceArray.push({ 'Columns': dynamicSideGridColumns, 'Product': row.Product, 'DataSource': vm.dynamicSideGridDataSource });
                });
                vm.sideGridLoading = false;
            }
        });
    }

    // Used in App\Shared\Services\reportService.js(16)
    $scope.deleteFilter = function (id) {
        CustomReportServices.deleteFilterFilterManager($scope, id);
    };

    // Used in App\Shared\Partials\ReportToolbar.html(85)
    $scope.clearAllFilters = function () {
        $scope.gridDataSource.filter({});
    };

    // Used in App\Shared\Partials\ReportToolbar.html(48)
    $scope.saveReportLayoutButton = function () {
        var productId;
        if (vm.isDetailed === true && vm.selectedProducts.length === 1) {
            productId = vm.selectedProducts[0].ID;
        }
        CustomReportServices.saveGridLayout($scope, productId);
    };

    // --- FOR ADVANCED FILTERING ---
    function getReportMetadata() {
        return reportsDataService.getReportMetadata($scope.reportId)
            .then(data => {
                vm.reportMetadata = data.data._embedded.reportData;
                if (vm.selectedProducts.some(product => product.Type === 'OR')) {
                    if (helperService.convertConfigValueToTrueFalse('OR_RBACEnabled')) {
                        vm.reportMetadata.Columns = vm.reportMetadata.Columns.filter(column => column.FieldName !== "Role" && column.FieldName !== "RoleCode" && column.FieldName !== "RoleType");
                    } else {
                        vm.reportMetadata.Columns = vm.reportMetadata.Columns.filter(column => column.FieldName !== "Responsibility" && column.FieldName !== "ResponsibilityID" && column.FieldName !== "ResponsibilityKey");
                    }
                }
                vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');
                vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
                return vm.reportMetadata;
            });
    }
    // ---

}