import $ from 'jquery';

export default /*@ngInject*/ function ($http, $location, $rootScope, whiteListService) {

    var infoFactory = {
        getObjectID: async function () {
            let promises = [];

            if (localStorage.getItem("UserSecurityObjects") == null) {
                promises.push(infoFactory.loadUserSecurityObjects($rootScope));
            }
            if (localStorage.getItem("NavigationObjects") == null) {
                promises.push(infoFactory.loadNavigationObjects());
            }

            await Promise.all(promises);

            return infoFactory.getObjectIDfromRoute($location.path());;
        },
        getObjectIDfromRoute: function (route) {
            var temp = route.substr(1);
            var routeArray = temp.split("/");

            var userSecurityList = angular.fromJson(localStorage.getItem("UserSecurityObjects"));

            var typeName = "";

            if (routeArray[1] != null) {
                if (routeArray[1].toUpperCase() == "SETUP") {
                    typeName = "Dialog";
                } else {
                    typeName = routeArray[1];
                }
            }

            var parentID = infoFactory.getParentIDFromName(routeArray[0]);
            var name = routeArray[2];

            var userSecurityObject = null;

            if (userSecurityList) {
                userSecurityObject = userSecurityList.find(function (object) {
                    return object.ParentID == parentID && object.TypeName.toUpperCase() == typeName.toUpperCase() && object.Name.replace(/\s/g, '').toUpperCase() == name.toUpperCase();
                });
            }

            if (userSecurityObject != null) {
                return userSecurityObject.ObjectID;
            } else {

                var whiteList = whiteListService.getWhiteListedRoutes();

                var containedInWhiteList = whiteList.find(function (item) {
                    return item == route.toUpperCase();
                }) != null;

                if (!containedInWhiteList) {
                    $location.path('/403');
                }
            }

        },
        loadNavigationObjects: function () {
            //Load the navigation objects into local storage for use in routestartchange
            return $http.get(apiUrl + 'api/core/usersecurity/navigation')
                .then(function (response) {
                    var navigationList = response.data;

                    localStorage.setItem("NavigationObjects", angular.toJson(navigationList));
                    return response.data;
                });
        },
        getParentIDFromName: function (ParentName) {
            var navigationObjectList;

            navigationObjectList = angular.fromJson(localStorage.getItem("NavigationObjects"));
            var foundObject = navigationObjectList.find(function (object) {
                return object.Name.replace(/ /g, '').toUpperCase() == ParentName.toUpperCase();
            });

            if (foundObject != null){
                return foundObject.ObjectID;
            }
        },
        loadUserSecurityObjects: function ($rootScope) {
            //Load the navigation objects into local storage for use in routestartchange
            return $http.get(apiUrl + 'api/core/usersecurity').then(function (response) {
                $rootScope.UserSecurityList = [];
                response.data.forEach(function (row) {
                    $rootScope.UserSecurityList[row.ObjectID] = true;
                });

                localStorage.setItem("UserSecurityObjects", angular.toJson(response.data));

                return response.data;
            });
        }
    };

    return infoFactory;
}
