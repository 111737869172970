export default function () {
    const service = {
        mapBusinessProcesses,
        mapSecurityObjects
    };

    return service;

    function Object(Id, RiskId, ProductType, ProductName, BusinessProcessId, BusinessProcessName, BusinessProcessDescription, SecurityObjectTypeId, SecurityObjectTypeName, SecurityObjectModuleName) {
        this.Id = Id;
        this.RiskId = RiskId;
        this.ProductType = ProductType;
        this.ProductName = ProductName;
        this.BusinessProcessId = BusinessProcessId;
        this.BusinessProcessName = BusinessProcessName;
        this.BusinessProcessDescription = BusinessProcessDescription;
        this.SecurityObjectTypeId = SecurityObjectTypeId;
        this.SecurityObjectTypeName = SecurityObjectTypeName;
        this.SecurityObjectModuleName = SecurityObjectModuleName;

    }

    function mapBusinessProcesses(data) {

        let newArray = [];
        data.forEach(function (row) {
            var item = new Object(null, null, null, null, row.Id.toString(), row.Name, row.Description, row.Type, 'Business Process', null);
            newArray.push(item);
        });

        return newArray;
    }

    function mapSecurityObjects(data) {

        let newArray = [];
        data.forEach(function (row) {
            var item = new Object(null, null, row.ProductType, row.ProductName, row.Id.toString(), row.Name, row.AltName, row.SecurityObjectTypeId, row.SecurityObjectTypeName, row.ModuleName);
            newArray.push(item);
        });

        return newArray;
    }
}