import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (helperService) {

    const vm = this;

    vm.submitReportManagement = submitReportManagement;
    vm.configValuesHaveChanged = configValuesHaveChanged;

    vm.$onInit = activate;

    async function activate () {
        vm.config = vm.currentConfig;
        vm.origConfig = vm.originalConfig;
    }

    async function submitReportManagement () {
        vm.updateSettingsPending = new createSpinner();

        let list = [
            { Name: "ArchiveSignedReports", Encrypted: false },
            { Name: "RM_EnableNewReportSignatureReminderEmails", Encrypted: false },
            { Name: "RM_EnableBeforeDueDayReportSignatureReminderEmails", Encrypted: false },
            { Name: "RM_DaysToSignReport", Encrypted: false },
            { Name: "RM_EnableDueDayReportSignatureReminderEmails", Encrypted: false },
            { Name: "RM_EnableOverdueReportSignatureReminderEmails", Encrypted: false },
            { Name: "RM_SendOverdueReportSignatureReminderEmailsEveryday", Encrypted: false },
            { Name: "RM_ReportSignatureDaysBeforeDueDate", Encrypted: false }
        ];

        try {
            let value = vm.config;
            let origVal = vm.origConfig;
            await vm.updateConfiguration({ list, value, origVal });
            await helperService.successfulSaveButton(vm.updateSettingsPending);
            activate();
        } catch {
            vm.updateSettingsPending.loadingValue = false;
        }
    }

    function configValuesHaveChanged (configArray) {
        return helperService.returnTrueIfValuesDiffer(configArray, vm.origConfig, vm.config);
    }
}