export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getControl,
        getListOfControls,
        updateControl,
        updateControlConditions,
        scheduleControl,
        disableControlSchedule,
        onDemandControl,
        getGlobalParameters,
        updateGlobalParameter
    };

    return service;

    // Controls

    async function getControl(controlId) {
        try {
            return await $http.get(`${apiUrl}api/transactionmonitoring/or/controls/${controlId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        };
    }

    async function getListOfControls() {
        try {
            return await $http.get(`${apiUrl}api/transactionmonitoring/or/controls`);
        } catch (err) {
            showError(err);
            sendError(err);
        };
    }

    async function updateControl(controlId, putData) {
        try {
            return await $http.put(`${apiUrl}api/transactionmonitoring/or/controls/${controlId}`, putData)
        } catch (err) {
            showError(err);
            sendError(err);
        };
    }

    async function updateControlConditions(controlId, conditionId, putData) {
        try {
            return await $http.put(`${apiUrl}api/transactionmonitoring/or/controls/${controlId}/conditions/${conditionId}`, putData)
        } catch (err) {
            showError(err);
            sendError(err);
        };
    }

    // Control Schedule

    async function scheduleControl(controlId, putData) {
        try {
            return await $http.put(`${apiUrl}api/transactionmonitoring/or/controls/${controlId}/schedule`, putData)
        } catch (err) {
            showError(err);
            sendError(err);
        };
    }

    async function disableControlSchedule(controlId, putData) {
        try {
            return await $http.put(`${apiUrl}api/transactionmonitoring/or/controls/${controlId}/schedule/disable`, putData)
        } catch (err) {
            showError(err);
            sendError(err);
        };
    }

    // Send control for processing

    async function onDemandControl(controlId) {
        try {
            return await $http.put(`${apiUrl}api/transactionmonitoring/or/controls/${controlId}/run`)
        } catch (err) {
            showError(err);
            sendError(err);
        };
    }

    // Global Parameters

    async function getGlobalParameters() {
        try {
            return await $http.get(`${apiUrl}api/transactionmonitoring/or/controls/conditiontypes`)
        } catch (err) {
            showError(err);
            sendError();
        };
    }

    async function updateGlobalParameter(conditionTypeId, putData) {
        try {
            return await $http.put(`${apiUrl}api/transactionmonitoring/or/controls/conditiontypes/${conditionTypeId}`, putData)
        } catch (err) {
            showError(err);
            sendError(err);
        };
    }

    // Error Handling

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}