import angular from 'angular';
import connectionsDataService from '../Components/Connections/connectionsDataService.factory';
import connections from '../Components/Connections/connections.component';
import connection from '../Components/Connections/Connection/connection.component';
import connectionImport from '../Components/Connections/Connection/Import/connectionImport.component';
import connectionSchedule from '../Components/Connections/Connection/Schedule/connectionSchedule.component';
import connectionSettings from '../Components/Connections/Connection/Settings/connectionSettings.component';
import sapConnection from '../Components/Connections/SapConnection/sapConnection.component';
import sapConnectionSettings from '../Components/Connections/SapConnection/Settings/sapConnectionSettings.component';
import customProductConnection from '../Components/Connections/CustomProductConnection/customProductConnection.component';
import customProductConnectionSettings from '../Components/Connections/CustomProductConnection/Settings/customProductConnectionSettings.component';
import transactionMonitoringSchedule from '../Components/Connections/Connection/Schedule/TransactionMonitoringSchedule/transactionMonitoringSchedule.component';
import editProductNameModalController from '../Components/Connections/editProductNameModal.controller';
import partialHybridConnectionsInformation from '../Components/Connections/PartialHybridInformation/partialHybridConnectionsInformation.component';
import activeDirectoryDomains from '../Components/Connections/activeDirectoryDomains/activeDirectoryDomains.component';

export default angular
    .module('app.integrations.productConnections', [])
    .factory('connectionsDataService', connectionsDataService)
    .component("connections", connections)
    .component("connection", connection)
    .component("connectionImport", connectionImport)
    .component("connectionSchedule", connectionSchedule)
    .component("connectionSettings", connectionSettings)
    .component("sapConnection", sapConnection)
    .component("sapConnectionSettings", sapConnectionSettings)
    .component("customProductConnection", customProductConnection)
    .component("customProductConnectionSettings", customProductConnectionSettings)
    .controller("editProductNameModalController", editProductNameModalController)
    .component('transactionMonitoringSchedule', transactionMonitoringSchedule)
    .component('partialHybridConnectionsInformation', partialHybridConnectionsInformation)
    .component('activeDirectoryDomains', activeDirectoryDomains)
    .name;