/* maintainMitigations.component.js */

import maintainMitigationsController from "./maintainMitigations.controller";

/**
 * @desc component to display Maintain Mitigations
 * @example <maintain-mitigations></maintain-mitigations>
 */

const maintainMitigations = {
    controller: maintainMitigationsController,
    controllerAs: 'mvm',
    templateUrl: '/App/Components/maintainMitigations/maintainMitigations.html'
};

export default maintainMitigations;