import reportFilterManagerController from "./reportFilterManager.controller";

/* reportFilterMananger.component.js */

/**
 * @desc component for managing saved report filters
 * @example <report-filter-manager></report-filter-manager>
 */

const reportFilterManager = {
   bindings: {
      resolve: '<',
      modalInstance: '<'
   },
    controller: reportFilterManagerController,
    controllerAs: 'rfmvm',
   templateUrl: '/App/Shared/Components/ReportFilterManager/reportFilterManager.tmpl.html'
};

export default reportFilterManager;