export default /*@ngInject*/ function (salesforceService) {
    this.loadSalesforce = async function ($scope) {
        let { data } = await salesforceService.getPermissionSets(),
            pendingPermissions = $scope.vm.User?.SalesforceUser?.PendingPermissionSets || [],
            licenseId = $scope.vm.User?.SalesforceUser?.Profile?.UserLicenseId,
            pendingLicenseId = $scope.vm.User?.SalesforceUser?.PendingProfile?.UserLicenseId,
            permission;

        $scope.vm.assignedSalesforcePermissionSets = [];
        $scope.vm.assignedSalesforceLicenseId = licenseId;
        $scope.vm.assignedSalesforceEmail = $scope.vm.User?.SalesforceUser?.Email;
        $scope.vm.showSalesforceLicenseChangeMessage = false;
        $scope.vm.salesforceLicensePending = pendingLicenseId && licenseId !== pendingLicenseId;
        // Needa master list to handle when User License Changes
        $scope.vm.ListOfAllSalesforcePermissionSets = data.filter(applyPending.bind(null, pendingPermissions));
        $scope.vm.ListOfAvailableSalesforcePermissionSets = data.filter(applyPending.bind(null, pendingPermissions));

        if ($scope.vm.User.SalesforceUser != null && $scope.vm.User.SalesforceUser.PermissionSets != null) {
            $scope.vm.User.SalesforceUser.PermissionSets.forEach(permissionSet => {
                permission = $scope.vm.ListOfAvailableSalesforcePermissionSets.find(existingPermission => existingPermission.Id === permissionSet.Id);

                if (permission) {
                    let selectedPermission = {
                        Id: permission.Id,
                        Name: permission.Name,
                        Label: permission.Label,
                        IsAssignable: permission.IsAssignable,
                        Pending: pendingPermissions.find(pendingPermission => pendingPermission.Id === permission.Id)
                    };

                    $scope.vm.ListOfSelectedSalesforcePermissionSets.unshift(selectedPermission);

                    $scope.vm.assignedSalesforcePermissionSets.unshift(selectedPermission);

                    $scope.vm.ListOfAvailableSalesforcePermissionSets = $scope.vm.ListOfAvailableSalesforcePermissionSets.filter(availablePermission => availablePermission.Id !== permission.Id);
                }

                $scope.vm.numberOfSalesforcePermissionSetsValid = true;
            });

        }

        $scope.vm.ListOfAvailableSalesforcePermissionSets = $scope.vm.ListOfAvailableSalesforcePermissionSets.filter(permissionSet => permissionSet.IsAssignable);

        $scope.vm.ListOfAllSalesforcePermissionSets = angular.copy($scope.vm.ListOfAvailableSalesforcePermissionSets);
        $scope.salesforceUserLoaded = true;
    };
}

function applyPending(pendingItems, item) {
    if (pendingItems.length) {
        item.Pending = pendingItems.some(pendingItem => pendingItem.Id === item.Id);
    }

    return item;
}