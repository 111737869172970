import notificationsController from "./notifications.controller";

/* notifications.component.js */

/**
 * @desc component for displaying and subscribing to notifications
 * @example <notifications></notifications>
 */

const notifications = {
    controller: notificationsController,
    controllerAs: 'nvm',
    templateUrl: '/App/Components/Notifications/notifications.tmpl.html'
};

export default notifications;