/* maintainBusinessCycles.component.js */

import maintainBusinessCyclesController from "./maintainBusinessCycles.controller";

/**
 * @desc component to maintain Business Cycles
 * @example <maintain-business-cycles></maintain-business-cycles>
 */

const maintainBusinessCycles = {
    controller: maintainBusinessCyclesController,
    controllerAs: 'bcvm',
    templateUrl: '/App/Components/maintainBusinessCycles/maintainBusinessCycles.html'
};

export default maintainBusinessCycles;