import { customAutoCompleteFilter, customDatePickerFilter, customDatePickerNotNullableFilter, customBooleanFilter, customNotStringEmptyFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";

export default /*@ngInject*/ function ($scope, $rootScope, $filter, CustomReportServices, reportsDataService, reportsService, ExportReportService, $http, $location, helperService, schedulesDataService, FileSaver) {

    const vm = this;
    $scope.vm = {};

    const splitItUp = $location.path().split("/");
    vm.ObjectId = splitItUp[4];
    vm.generateGridWithDynamicValues = generateGridWithDynamicValues;

    const securityObject = helperService.getSecurityObjectDetailsFromObjectId(vm.ObjectId);
    vm.reportId = securityObject.ReportID;

    vm.RelativeDateTypeDataSource = CustomReportServices.getRelativeDateTypes();
    vm.PeriodTypeDataSource = CustomReportServices.getPeriodTypes();
    vm.openStartDate = openStartDate;
    vm.openEndDate = openEndDate;
    vm.downloadArchivedReport = downloadArchivedReport;

    vm.hasCriteria = false;
    vm.dateCriteria = [];
    vm.RelativeDateType = 2;
    vm.PeriodType = 0;
    vm.startDateOptions = {
        dateDisabled: startDateDisabled,
        showWeeks: false,
        formatYear: 'yy',
        startingDay: 1
    };
    vm.endDateOptions = {
        dateDisabled: endDateDisabled,
        showWeeks: false,
        formatYear: 'yy',
        startingDay: 1
    };
    vm.endDatePopup = {
        opened: false
    };
    vm.startDatePopup = {
        opened: false
    };

    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    vm.filterCriteria.dateQuery = [];
    // ---

    // scoped items - TODO: Refactor services that require these in $scope
    $scope.ObjectId = vm.ObjectId;
    $scope.reportName = securityObject.Name;
    //Add unique data- value for each tabbed grid.
    $scope.gridID = $scope.ObjectId + $scope.$id;
    $scope.reportId = vm.reportId;
    vm.grid = $scope.grid;
    $scope.reportDetailsModel = [];
    $scope.filtersDataSource = CustomReportServices.setfiltersDataSource(vm.ObjectId);
    $scope.filterManagerGridOptions = CustomReportServices.setfilterManagerGridOptions();
    $scope.filterDropdownOptions = helperService.getFilterDropdownOptions();

    $scope.deleteFilter = deleteFilter;
    $scope.clearAllFilters = clearAllFilters;
    $scope.saveReportLayoutButton = saveReportLayoutButton;
    $scope.applySelectedFilter = applySelectedFilter;
    $scope.populateCriteriaModel = populateCriteriaModel;


    activate();

    async function activate() {
        setDates();
        try {
            let response = await reportsDataService.getReportMetadata(vm.reportId);
            vm.reportMetadata = response.data._embedded.reportData;
            vm.reportMetadata.Columns = setupColumns(vm.reportMetadata.Columns);
            setupCriteria(vm.reportMetadata);
            setupAdvancedFiltering(vm.reportMetadata.Columns);
            if ($rootScope.UserPreferences.AutoRunDashboardReports === '1') {
                kendo.ui.progress($("#dynamicGrid"), true);
            }
            createDataSource(vm.reportMetadata.Columns);
            loadSavedGridLayout();
        } catch (err) {
            return;
        }
    }

    function setupColumns(metadataColumns) {
        vm.columnDefinition = [];
        metadataColumns = $filter('orderBy')(vm.reportMetadata.Columns, 'Ordinal');
        metadataColumns = metadataColumns.filter(d => !d.FieldName.includes('_hidden_'));

        metadataColumns.forEach(function (column) {
            if (column.DataType === 'DateTime') {
                column.DataType = 'dateTime';
            } else if (column.DataType === 'Date') {
                column.DataType = 'date';
            } else if (column.DataType === 'Time') {
                column.DataType = 'time';
            } else if (column.DataType === 'Boolean') {
                column.DataType = 'boolean';
            } else if (column.DataType === 'String') {
                column.DataType = 'string';
            } else if (column.DataType === 'Number') {
                column.DataType = 'number';
            }
        });

        metadataColumns.forEach(function (column) {
            let columnSample = { "field": column.FieldName, "title": !column.DisplayName ? helperService.headerStringTransformer(column.FieldName) : helperService.headerStringTransformer(column.DisplayName), filterable: customAutoCompleteFilter, ordinal: column.Ordinal };

            if (column.DataType === 'dateTime' || column.DataType === 'date' || column.DataType === 'time') {
                columnSample.filterable = column.IsNullable ? customDatePickerFilter : customDatePickerNotNullableFilter;
                columnSample.template = column.DataType === 'date' ? "<span>{{dataItem." + column.FieldName + " | date:'M/d/yy'}}</span>" : "<span>{{dataItem." + column.FieldName + " | date:'short'}}</span>";
            }
            if (column.DataType === 'boolean') {
                columnSample.filterable = customBooleanFilter;
            }
            if (column.DataType === 'number') {
                columnSample.filterable = customAutoCompleteFilter;
            }
            if (column.DataType === 'string') {
                // prevents kendo from parsing any code found in the string
                columnSample.template = "<span>{{dataItem." + column.FieldName + "}}</span>";
            }
            if (column.DataType !== 'string' && column.DataType !== 'boolean' && column.IsNullable) {
                columnSample.filterable = customNotStringEmptyFilter;
            }

            if (column.DisplayName.startsWith('_download_')) {
                columnSample.filterable = false;
                columnSample.title = helperService.headerStringTransformer(column.FieldName).slice(10);
                columnSample.template = `
                    <a ng-if="dataItem._download_ArchivedReport" ng-click="drvm.downloadArchivedReport(dataItem)">
                        <i class="glyphicon glyphicon-download-alt"></i>
                        Download
                    </a>
                `;
            }
            if (column.DisplayName.startsWith('_includes_')) {
                columnSample.filterable = false;
                columnSample.title = 'Attachment(s)';
                columnSample.template = `<attachments-download ng-if='dataItem._includes_Attachments' case-id = 'dataItem._hidden_CaseID'></attachments-download>`; 
            }

            vm.columnDefinition.push(columnSample);
        });

        return metadataColumns;
    }

    function setupCriteria(metadata) {
        if (metadata.DateCriteria.length > 0) {
            vm.hasCriteria = true;
            vm.dateCriteria = metadata.DateCriteria;
        }
    }

    function setupAdvancedFiltering(metadataColumns) {
        metadataColumns.forEach(column => {
            column.DisplayName = helperService.headerStringTransformer(column.DisplayName);
        });
        metadataColumns = metadataColumns.filter(d => !d.FieldName.includes('_download_') && !d.DisplayName.includes('_includes_Attachments'));
        vm.filterCriteria.fields = $filter('orderBy')(reportsService.getFields(metadataColumns), 'fieldName');
    }

    async function loadSavedGridLayout() {
        try {
            let savedLayoutColumns = await CustomReportServices.loadSavedDynamicGridLayout(vm);
            vm.mainGridOptions = helperService.setMainGridOptions(savedLayoutColumns, 600);
            executeGrid();
        } catch (_) {
            vm.mainGridOptions = helperService.setMainGridOptions(vm.columnDefinition, 600);
            executeGrid();
        }
    }

    function executeGrid() {
        const userPreferences = $rootScope.UserPreferences,
            autorun = userPreferences.AutoRunDashboardReports === "1",
            tabbedReports = userPreferences.ShowReportTabs === "1";

        vm.mainGridOptions.pageable = { 'refresh': true };
        if (!$rootScope.loadedSavedReport) {
            if (autorun && !tabbedReports) {
                vm.mainGridOptions.autoBind = false;
                vm.hideExportOptionsFromSubmit = true;
                vm.gridDataSource.read();
            } else if (autorun && tabbedReports) {
                vm.hideExportOptionsFromSubmit = true;
            } else {
                vm.mainGridOptions.autoBind = false;
                // Don't allow filtering and sorting yet because they will force the report to run
                if (!$rootScope.loadedSavedReport) {
                    vm.mainGridOptions.columns.forEach(column => {
                        column.sortable = false;
                    });
                    vm.mainGridOptions.filterable = false;
                }
            }
        } else {
            vm.mainGridOptions.autoBind = false;
            // Need to set columns based on saved report
            vm.mainGridOptions.columns.forEach(column => {
                if ($rootScope.loadedSavedReport.ReportDefinition.Columns.length > 0) {
                    let matchedColumn = $rootScope.loadedSavedReport.ReportDefinition.Columns.find(savedColumn => savedColumn.FieldName === column.field);
                    if (!matchedColumn) {
                        column.hidden = true;
                    } else {
                        if (matchedColumn.Ordinal !== column.ordinal) {
                            column.ordinal = matchedColumn.Ordinal;
                        }
                    }
                }
            });
            vm.mainGridOptions.columns = $filter('orderBy')(vm.mainGridOptions.columns, 'ordinal');
            // --- FOR ADVANCED FILTERING ---
            // Convert date filters to criteria
            if (vm.dateCriteria && vm.dateCriteria.length > 0) {
                if ($rootScope.loadedSavedReport.ReportDefinition.DateFilters.length > 0) {
                    const criteriaDateFilterIndex = $rootScope.loadedSavedReport.ReportDefinition.DateFilters.findIndex(filter => filter.FieldName === vm.dateCriteria[0].FieldName);
                    if (criteriaDateFilterIndex > -1) {
                        loadDateFilterAsCriteria($rootScope.loadedSavedReport.ReportDefinition.DateFilters[criteriaDateFilterIndex]);
                        $rootScope.loadedSavedReport.ReportDefinition.DateFilters.splice(criteriaDateFilterIndex, 1);
                    }
                } else if ($rootScope.loadedSavedReport.ReportDefinition.Filters.length > 0) {
                    const dateRangeFilters = $rootScope.loadedSavedReport.ReportDefinition.Filters.filter(filter => filter.FieldName === vm.dateCriteria[0].FieldName);
                    if (dateRangeFilters.length > 0) {
                        $rootScope.loadedSavedReport.ReportDefinition.Filters = $rootScope.loadedSavedReport.ReportDefinition.Filters.filter(filter => filter.FieldName !== vm.dateCriteria[0].FieldName);
                        dateRangeFilters.forEach(filter => {
                            loadDateFilterAsCriteria(filter);
                        });
                    }
                }
            }

            vm.filterCriteria.query = reportsService.getSavedReportFilters();

            if ($rootScope.loadedSavedReport && $rootScope.loadedSavedReport.ReportDefinition.Criteria) {
                $rootScope.loadedSavedReport.ReportDefinition.Criteria.forEach(function (criteria) {
                    if (criteria.DateFilter) {
                        switch (criteria.DateFilter.DateType) {
                            case 'Previous':
                                vm.RelativeDateType = 0;
                                vm.PeriodType = getPeriodId(criteria.DateFilter.Period);
                                break;

                            case 'ToDate':
                                vm.RelativeDateType = 1;
                                vm.PeriodType = getPeriodId(criteria.DateFilter.Period);
                                break;

                            case 'LastNumberOf':
                                vm.RelativeDateType = 2;
                                vm.PeriodType = getPeriodId(criteria.DateFilter.Period);
                                vm.LastNumberOf = criteria.DateFilter.Value;
                                break;

                        }
                    }
                    if (criteria.Filters.length > 0) {
                        criteria.Filters.forEach(function (filter) {
                            switch (filter.Operator) {
                                case 'GreaterThanOrEqual':
                                    vm.RelativeDateType = 4;
                                    vm.startDate = new Date(filter.Value);
                                    break;

                                case 'LessThanOrEqual':
                                    vm.RelativeDateType = 4;
                                    vm.endDate = new Date(filter.Value);
                                    break;
                            }
                        });
                    }
                });
            }

            vm.filterCriteria.dateQuery = reportsService.getSavedReportDateFilters();
            vm.filterCriteria.needsUpdate = true;
            // ---
            vm.hideExportOptionsFromSubmit = true;
            vm.gridDataSource.read();
        }
    }

    function determineTypeOfField(columnsWithType) {
        var fields = {};
        columnsWithType.forEach(function (column) {
            if (column.DataType === 'date' || column.DataType === 'time' || column.DataType === 'dateTime') {
                fields[column.FieldName] = { type: 'date' };
            } else {
                fields[column.FieldName] = { type: column.DataType };
            }
        });
        return fields;
    }

    function createDataSource(columnsWithType) {
        vm.gridDataSource = new kendo.data.DataSource({

            pageSize: standardGridPageSize,
            schema: angular.extend(halSchemaKendoGrid, {
                model: {
                    fields: determineTypeOfField(columnsWithType)
                },
                parse: function (response) {
                    // Ensure 'date' fields ignore time of date string values
                    if (dateColumnExists() && response?._embedded.reportData && response?._embedded.reportData.length) {
                        response._embedded.reportData.forEach(record => {
                            for (let key in record) {
                                if (isDateColumn(key) && record[key]) {
                                    record[key] = helperService.convertUTCDateTimeStringToDateString(record[key]);
                                }
                            }
                        });
                    }

                    return response;
                }
            }),
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: async options => {                   
                    vm.sortsAndFilters = helperService.prepareSortsAndFilters(options);

                    setSortsAndFilters();

                    $scope.sortsAndFilters = vm.sortsAndFilters;

                    try {
                        let response = await $http({
                            method: 'POST',
                            url: apiUrl + 'api/core/reports/' + vm.reportId + '/execute/',
                            data: vm.sortsAndFilters,
                            params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                        });
                        options.success(response.data);
                        kendo.ui.progress($("#dynamicGrid"), false);
                        vm.enableGrid = true;
                        $scope.vm.enableGrid = true;
                        if ($rootScope.tabs.length > 0) {
                            reportsService.saveTabReportInfo($scope);
                        }
                        delete $rootScope.loadedSavedReport;
                    } catch (response) {
                        const timeout = 408;

                        if (response.status !== timeout) {
                            helperService.showErrorMessage(response.data);
                        }

                        kendo.ui.progress($("#dynamicGrid"), false);
                    }
                }
            }
        });
    }

    function setSortsAndFilters() {
        // --- FOR ADVANCED FILTERING ---
        if (vm.filterCriteria.query) {
            vm.sortsAndFilters.Filters = vm.sortsAndFilters.Filters.concat(vm.filterCriteria.query);
        }
        if (vm.filterCriteria.dateQuery) {
            vm.sortsAndFilters.DateFilters = vm.filterCriteria.dateQuery.slice();
        }
        // ---

        if (!vm.sortsAndFilters) {
            vm.sortsAndFilters = {};
        }
        if (!vm.sortsAndFilters.Filters) {
            vm.sortsAndFilters.Filters = [];
        }
        if (!vm.sortsAndFilters.DateFilters) {
            vm.sortsAndFilters.DateFilters = [];
        }

        if (vm.hasCriteria) {
            let dateFilters = getDateFilters();
            if (dateFilters.Filters && dateFilters.Filters.length > 0) {
                vm.sortsAndFilters.Filters = vm.sortsAndFilters.Filters.concat(dateFilters.Filters);
            }
            if (dateFilters.RelativeFilter) {
                vm.sortsAndFilters.DateFilters.push(dateFilters.RelativeFilter);
            }
        }
    }

    function deleteFilter(id) {
        CustomReportServices.deleteFilterFilterManager($scope, id);
    }

    function clearAllFilters() {
        vm.gridDataSource.filter({});
    }

    function saveReportLayoutButton() {
        vm.grid = $scope.grid;
        CustomReportServices.saveGridLayout(vm, null);
    }

    function applySelectedFilter() {
        helperService.applyFilterCatchError($scope);
    }

    function generateGridWithDynamicValues() {
        vm.hideExportOptionsFromSubmit = true;
        // Add back the sorting and filtering options if we are running the report for the first time
        if (!$rootScope.loadedSavedReport) {
            vm.mainGridOptions.columns.forEach(column => {
                column.sortable = true;
            });
        }
        $scope.grid.setOptions({ "filterable": { mode: "row" } });
        vm.gridDataSource.read();
    }


    //Date picker settings
    function setDates() {
        var newDate = new Date();
        newDate.setDate(newDate.getDate() - $rootScope.UserPreferences.DefaultDaysForAuditTrailReports);
        vm.LastNumberOf = $rootScope.UserPreferences.DefaultDaysForAuditTrailReports;

        vm.startDate = newDate;
        vm.endDate = new Date();
    }

    function startDateDisabled(data) {
        var date = data.date,
            mode = data.mode;
        return mode === 'day' && date > vm.endDate;
    }

    function endDateDisabled(data) {
        var date = data.date,
            mode = data.mode;
        return mode === 'day' && date < vm.startDate;
    }

    function openEndDate() {
        vm.endDatePopup.opened = true;
    }

    function openStartDate() {
        vm.startDatePopup.opened = true;
    }


    function getDateFilters() {
        var dateCriteria = vm.dateCriteria[0];

        if (dateCriteria) {
            var dateTimeFieldName = dateCriteria.FieldName;
            var criteria = { CriteriaType: 'Date', CriteriaValue: dateTimeFieldName, Filters: [], DateFilter: null };
            var dateFilters = {
                Criteria: {},
                Filters: [],
                RelativeFilter: null
            };

            var startDate = helperService.setDateToBeginningOfDay(vm.startDate);
            var endDate = helperService.setDateToEndOfDay(vm.endDate);

            if (vm.RelativeDateType === 4) {
                var startDateRow = {
                    "FieldName": dateTimeFieldName,
                    "Operator": 'GreaterThanOrEqual',
                    "Value": startDate
                };
                var endDateRow = {
                    "FieldName": dateTimeFieldName,
                    "Operator": 'LessThanOrEqual',
                    "Value": endDate
                };

                dateFilters.Filters.push(startDateRow);
                dateFilters.Filters.push(endDateRow);

                criteria.Filters.push(startDateRow);
                criteria.Filters.push(endDateRow);

                dateFilters.Criteria = criteria;

            }
            if (vm.RelativeDateType !== 4) {
                var relativeDate = {
                    "DateType": vm.RelativeDateType,
                    "FieldName": dateTimeFieldName,
                    "Period": vm.PeriodType,
                    "Value": vm.RelativeDateType === 2 ? vm.LastNumberOf : null
                };

                dateFilters.RelativeFilter = relativeDate;
                criteria.DateFilter = relativeDate;
                dateFilters.Criteria = criteria;
            }

            return dateFilters;
        }

        return null;
    }

    function loadDateFilterAsCriteria(DateFilter) {
        if (DateFilter.Operator === 'GreaterThanOrEqual' || DateFilter.Operator === 'LessThanOrEqual') {
            if (!$rootScope.loadedSavedReport.ReportDefinition.Criteria[0]) {
                $rootScope.loadedSavedReport.ReportDefinition.Criteria = [{
                    "CriteriaType": "Date",
                    "CriteriaValue": DateFilter.FieldName,
                    Filters: []
                }];
                $rootScope.loadedSavedReport.ReportDefinition.Criteria[0].Filters.push(DateFilter);
            } else {
                $rootScope.loadedSavedReport.ReportDefinition.Criteria[0].Filters.push(DateFilter);
            }
        } else {
            $rootScope.loadedSavedReport.ReportDefinition.Criteria = [{
                "CriteriaType": "Date",
                "CriteriaValue": DateFilter.FieldName,
                Filters: []
            }];
            $rootScope.loadedSavedReport.ReportDefinition.Criteria[0].DateFilter = DateFilter;
        }
    }

    function getPeriodId(name) {
        if (name) {
            return CustomReportServices.getPeriodTypes().find(function (type) {
                return type.Name === name;
            }).Id;
        }
    }

    function populateCriteriaModel() {
        $scope.vm.filterCriteria = vm.filterCriteria;
        $scope.sortsAndFilters = {};
        $scope.sortsAndFilters.Filters = [];
        $scope.sortsAndFilters.DateFilters = [];

        let dateFilters = getDateFilters();

        if (dateFilters) {
            $scope.sortsAndFilters.Criteria = dateFilters.Criteria;
            $scope.sortsAndFilters.Filters = $scope.sortsAndFilters.Filters.concat(dateFilters.Filters);
            if (dateFilters.RelativeFilter && !dateFilters.Criteria) {
                $scope.sortsAndFilters.DateFilters.push(dateFilters.RelativeFilter);
            } else {
                $scope.sortsAndFilters.DateFilters = [];
            }
        }

        // if tabs are on, save report info for the tab
        if ($rootScope.tabs.length > 0) {
            reportsService.saveTabReportInfo($scope);
        }
    }

    async function downloadArchivedReport(dataItem) {
        if (dataItem.ReportExecutionDate) {
            await downloadExecutionFile(dataItem);
        } else {
            downloadReportExport(dataItem);
        }
    }

    function downloadReportExport(dataItem) {
        ExportReportService.downloadReportExport({
            Id: dataItem._download_ArchivedReport,
            Name: 'Archived Report',
            Type: 'Excel'
        });
    }

    async function downloadExecutionFile(dataItem) {
        try {
            const executionString = dataItem._download_ArchivedReport;
            const executionArray = executionString.split('?');
            const executionId = executionArray[0];
            const unformattedFileType = executionArray[1];
            const formattedFileType = unformattedFileType.split('=')[1];

            const res = await schedulesDataService.downloadExecutionFile(formattedFileType, executionId);

            let fileExtension = formattedFileType.toLowerCase();

            if (fileExtension === 'excel') {
                fileExtension = 'xlsx';
            }
            FileSaver.saveAs(res.data, `Archived Report.${fileExtension}`);

        } catch (_) {
            return;
        }
    }

    function dateColumnExists() {
        return vm.reportMetadata.Columns.some(column => column.DataType.toLowerCase() === 'date');
    }

    function isDateColumn(key) {
       return vm.reportMetadata.Columns.some(column => column.FieldName === key && column.DataType.toLowerCase() === 'date')
    }
}