// Intercepting HTTP calls with AngularJS.

import swal from "sweetalert2";

export default /*@ngInject*/ function ($provide, $httpProvider) {

    $provide.factory('SecurityHttpInterceptor', function ($q, $location, authenticationService) {
        return {
            'responseError': async function (response) {
                // Replaces Data object with flat message

                function isObject(val) {
                    return val instanceof Object;
                }

                if (isObject(response.data)) {
                    response.data = response.data.Message;
                }

                switch (response.status) {
                    case 403:
                        let authenticated = await authenticationService.isAuthenticated();
                        if (authenticated) {
                            $location.path('/403');
                        } else {
                            document.location.href = appUrl + "login";
                        }
                        break;
                    case 408:
                        swal('Timeout Error', 'The report has exceeded the timeout limit. Please refine your criteria and run the report again.', 'warning');
                        break;
                    default:
                        break;
                }

                return $q.reject(response);
            },
            'response': function (response) {

                if (response.data._embedded) {

                    var data = response.data._embedded;

                    for (var prop in data) {
                        break;
                    }

                    data.reportData = data[prop];
                    delete data[prop];

                }

                return response;
            }
        };
    });

    // Add the interceptor to the $httpProvider.
    $httpProvider.interceptors.push('SecurityHttpInterceptor');

}
