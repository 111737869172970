import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, controlLibraryDataService, review, helperService, FileSaver) {

    const vm = this;

    vm.removeFile = removeFile;
    vm.downloadFile = downloadFile;
    vm.uploadFiles = uploadFiles;
    vm.review = review;
    vm.cancel = cancel;

    vm.existingFilesDataSource = [];
    vm.review.Attachments.forEach(attachment => vm.existingFilesDataSource.push(attachment));


    async function removeFile(file) {
        vm.fileActionPending = true;

        try {
            await controlLibraryDataService.deleteAttachment(file.Identifier);

            const index = vm.existingFilesDataSource.findIndex(remainingFile => remainingFile.Identifier === file.Identifier);
            vm.existingFilesDataSource.splice(index, 1);
            vm.fileActionPending = false;
        } catch {
            vm.fileActionPending = false;
        }
    }

    async function downloadFile(file) {
        vm.fileActionPending = true;

        try {
            const response = await controlLibraryDataService.downloadAttachment(file.Identifier);
            FileSaver.saveAs(response.data, file.FileName);
            vm.fileActionPending = false;
        } catch {
            vm.fileActionPending = false;
        }
    }

    async function uploadFiles(files) {
        let fd = new FormData();
        vm.saving = new createSpinner();

        files.forEach(function (file, i) {
            fd.append(files[i].file.name, files[i].file);
        });

        fd.append("DataType", "CertReview");
        fd.append("DataTypeId", vm.review.HeaderId);

        await controlLibraryDataService.addAttachments(fd);
        await helperService.successfulSaveButton(vm.saving);
        $uibModalInstance.close();      
    }

    function cancel() { $uibModalInstance.close(); }
}