import maintainTemplateController from "./maintainTemplate.controller";

/* mantainTemplate.component.js */

/**
 * @desc component for Maintain Tracking Templates
 * @example <maintain-template></maintain-template>
 */

const maintainTemplate = {
    controller: maintainTemplateController,
    controllerAs: 'mtvm',
    templateUrl: '/App/Components/TemplateTracking/maintainTemplate.tmpl.html'
};

export default maintainTemplate;