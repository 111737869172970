import createSpinner from "../../../../Shared/Functions/createSpinner";
import { standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";


export default /*@ngInject*/ function ($uibModalInstance, $filter, ownershipGroup, existingCompanies, helperService, ownershipGroupItemsDataService, ownershipGroupOwnerDataService) {
    const vm = this;

    vm.saveCompany = saveCompany;
    vm.selectedCompanyTypeChanged = selectedCompanyTypeChanged;
    vm.selectedOwnerTypeChanged = selectedOwnerTypeChanged;
    vm.cancel = cancel;

    vm.ownershipGroup = { ...ownershipGroup };

    activate();

    async function activate() {
        vm.selectedCompanyType = 'Company';
        setupCompanyDropdown();
        await setOwners();
        vm.selectedOwnerType = 'User';
        selectedOwnerTypeChanged();
    }

    async function setupCompanyDropdown() {

        vm.companyDropdownOptions = {
            filter: "contains",
            minLength: 3,
            virtual: {
                itemHeight: 26
            },
            height: 325,
            optionLabel: '-- Select a Company --'
        };


        vm.companyDropdownDataSource = new kendo.data.DataSource({

            pageSize: standardGridPageSize,
            transport: {
                read: async (options) => {
                    let { data } = await ownershipGroupItemsDataService.getAvailableItems(ownershipGroup);
                    vm.companiesDataSource = data.filter(masterCompany => !existingCompanies.some(company => company.CompanyId === masterCompany.CompanyId) && masterCompany.Type === vm.selectedCompanyType);
                    options.success(vm.companiesDataSource);
                }
            }
        });

        return vm.companyDropdownDataSource;
    }

    async function setOwners() {
        vm.ownersDataSourceLoading = true;
        let { data } = await ownershipGroupOwnerDataService.getAllOwners(ownershipGroup.Id);
        data = $filter('orderBy')(data, 'Name');
        vm.availableOwnersDataSource = data;
        vm.ownersDataSourceLoading = false;
    }

    async function saveCompany() {
        vm.savePending = new createSpinner();
        const data = {
            GroupId: ownershipGroup.Id,
            Owners: vm.selectedOwners,
            Items: [vm.selectedCompany],
            SecurityCompanyIdentifier: vm.selectedCompany.SecurityCompanyIdentifier
        };

        try {
         
            let { data: newCompanyId } = await ownershipGroupItemsDataService.addItemToGroup(data, ownershipGroup.Id, ownershipGroup.GroupType);
            await helperService.successfulSaveButton(vm.savePending);
            close(newCompanyId);
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function selectedCompanyTypeChanged() {
        vm.companyDropdown.value([]);
        vm.selectedCompany = null;
        vm.companyDropdown.setOptions({ optionLabel: `-- Select a ${$filter('insertSpaceBetweenCapitalWords')(vm.selectedCompanyType)} --` });
        vm.companyDropdownDataSource.read();
    }

    function selectedOwnerTypeChanged() {
        vm.selectedOwners = null;
        vm.filteredOwnersDataSource = vm.availableOwnersDataSource.filter(owner => owner.TypeName === vm.selectedOwnerType);
    }

    function close(companyId) {
        $uibModalInstance.close(companyId);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}