import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($http, errorsDataService, $location, $uibModal, schedulesDataService, FileSaver) {

    const vm = this;

    vm.completeReview = completeReview;
    vm.openSignReportModal = openSignReportModal;
    vm.downloadReport = downloadReport;
    vm.reviewRequest = reviewRequest;

    activate();

    async function activate() {
        await getInbox();
        getLists();
    }

    async function getInbox() {
        try {
            let response = await $http.get(`${apiUrl}api/core/inbox`);
            vm.inboxDataSource = response.data;
        } catch (err) {
            errorsDataService.catch(err);
        }
    }

    function getLists() {
        vm.reviewsList = vm.inboxDataSource.filter(item => item.TaskType === 'AccessCertificationsReview');
        vm.requestList = vm.inboxDataSource.filter(item => item.TaskType === 'IdentityManagerApprovalRequest');
        vm.reportSignaturesList = vm.inboxDataSource.filter(item => item.TaskType === 'ReportSignature');
    }

    function reviewRequest(request) {
        $location.path('/IdentityManager/RequestDetails').search({ RequestId: request.RequestId, Version: request.Version });
    }

    function completeReview(instanceId) {
        $location.path('/AccessCertifications/Place/MyReviews').search({
            InstanceId: instanceId
        });
    }

    async function downloadReport(fileFormat, report) {
        vm.updateInProgress = new createSpinner();
        try {
            vm.updateInProgress.loadingValue = true;
             let fileExtension;
             switch (fileFormat) {
                 case 'Excel':
                     fileExtension = '.xlsx';
                     break;
                 case 'PDF':
                     fileExtension = '.pdf';
                     break;
                 case 'Csv':
                     fileExtension = '.csv';
                     break;
             }
            const response = await schedulesDataService.downloadExecutionFile(fileFormat, report.ExecutionId);
            FileSaver.saveAs(response.data, report.BaseReportName + fileExtension);
            vm.updateInProgress.loadingValue = false;
        } catch (err) {
            errorsDataService.catch(err);
            vm.updateInProgress.loadingValue = false;
        }
    }

    async function openSignReportModal(savedReport) {
        let signReportModal = $uibModal.open({
            component: 'signReport',
            backdrop: 'static',
            resolve: {
                report: () => {
                    return {
                        executionId: savedReport.ExecutionId,
                        savedReportId: savedReport.SavedReportId,
                        name: savedReport.BaseReportName
                    }
                }
            }
        });
        try {
            await signReportModal.result;
            activate();
        }
        catch {
            return;
        }
    }
   
}