import aDUserController from "./aDUser.controller";

/* aDUser.component.js */

/**
 * @desc component for displaying AD Username search and input fields
 * @example <ad-user-search-link></ad-user-search-link>
 */

const adUserSearchLink = {
    bindings: {
        userObject: '='
    },
    controller: aDUserController,
    controllerAs: 'aduvm',
    templateUrl: '/App/Components/RequestManagement/ADUserLink/aDUser.tmpl.html'
};

export default adUserSearchLink;