import roleSecurityProfileModalController from "./roleSecurityProfileModal/roleSecurityProfileModal.controller";

export default /*@ngInject*/ function ($uibModal) {

    const vm = this;

    vm.openRoleSecurityProfile = openRoleSecurityProfile;

    async function openRoleSecurityProfile(role) {
        $uibModal.open({
            templateUrl: '/App/Shared/Components/roleSecurityProfile/roleSecurityProfileModal/roleSecurityProfileModal.tmpl.html',
            controller: roleSecurityProfileModalController,
            controllerAs: 'rspmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                role: () => vm.role
            }
        });

    }
}