import groupRoleModalController from "../ownershipGroupItemsList/groupRoleModal/groupRoleModal.controller";
import groupCompanyModalController from "../ownershipGroupItemsList/groupCompanyModal/groupCompanyModal.controller";
import groupProductModalController from "../ownershipGroupItemsList/groupProductModal/groupProductModal.controller";
import groupBusinessProcessModalController from "../ownershipGroupItemsList/groupBusinessProcessModal/groupBusinessProcessModal.controller";
import groupRiskModalController from "./groupRiskModal/groupRiskModal.controller";

export default /*@ngInject*/ function (ownershipGroupItemsDataService, $uibModal) {

    const vm = this;

    vm.changeSelectedItem = changeSelectedItem;
    vm.openGroupItemModal = openGroupItemModal;
    vm.removeItemFromGroup = removeItemFromGroup;
    vm.changeGroupItemType = changeGroupItemType;

    vm.$onChanges = activate;

    async function activate() {
        vm.selectedItem = null;
        await getItems();
        vm.itemsRadioFilter = null;

        vm.selectedGroupItemType = 'item';
    }

    async function getItems() {
        vm.itemsLoading = true;
        const { data } = await ownershipGroupItemsDataService.getGroupItems(vm.selectedOwnershipGroup.Id, vm.selectedOwnershipGroup.GroupType);
        vm.items = data;
        vm.itemsLoading = false;
        return vm.items;
    }

    async function changeSelectedItem(item) {
        vm.selectedItem = item;
        vm.onSelectedItemChange({ item });
    }

    function openGroupItemModal(item, modalType) {
        switch (vm.selectedOwnershipGroup.GroupType) {
            case 'role':
                openGroupRoleModal();
                break;
            case 'company':
                openGroupCompanyModal();
                break;
            case 'product':
                openGroupProductModal();
                break;
            case 'businessprocess':
                openGroupBusinessProcessModal();
                break;
            case 'risk':
                openGroupRiskModal(item, modalType);
                break;
            default:
                break;
        }
    }

    async function removeItemFromGroup(item) {
        try {
            await ownershipGroupItemsDataService.removeItemFromOwnershipGroup(vm.selectedOwnershipGroup.Id, vm.selectedOwnershipGroup.GroupType, item.Id);
            if (item.Id === vm.selectedItem.Id) {
                vm.selectedItem = null;
                changeSelectedItem(null);
            }
            getItems();
        } catch {
            return;
        }
    }

    function changeGroupItemType() {
        let groupItemType = vm.selectedGroupItemType;
        vm.onGroupItemTypeChange({ groupItemType });
    }

    // Group Item Modals
    async function openGroupRoleModal() {
        let roleModal = $uibModal.open({
            templateUrl: '/App/Components/OwnershipGroups/ownershipGroupItemsList/groupRoleModal/groupRoleModal.tmpl.html',
            controller: groupRoleModalController,
            controllerAs: 'grmvm',
            backdrop: 'static',
            resolve: {
                ownershipGroup: () => vm.selectedOwnershipGroup,
                existingRoles: () => vm.items
            }
        });

        try {
            let roleId = await roleModal.result;
            await getItems();
            if (roleId) {
                changeSelectedItem(vm.roles.find(r => r.Id === roleId));
            }
        } catch { return; }
    }

    async function openGroupCompanyModal() {
        let companyModal = $uibModal.open({
            templateUrl: '/App/Components/OwnershipGroups/ownershipGroupItemsList/groupCompanyModal/groupCompanyModal.tmpl.html',
            controller: groupCompanyModalController,
            controllerAs: 'gcmvm',
            backdrop: 'static',
            resolve: {
                ownershipGroup: () => vm.selectedOwnershipGroup,
                existingCompanies: () => vm.items
            }
        });

        try {
            let companyId = await companyModal.result;
            await getItems();
            if (companyId) {
                changeSelectedItem(vm.items.find(item => item.Id === companyId));
            }
        } catch { return; }
    }

    async function openGroupProductModal() {
        let productModal = $uibModal.open({
            templateUrl: '/App/Components/OwnershipGroups/ownershipGroupItemsList/groupProductModal/groupProductModal.tmpl.html',
            controller: groupProductModalController,
            controllerAs: 'gpmvm',
            backdrop: 'static',
            resolve: {
                ownershipGroup: () => vm.selectedOwnershipGroup,
                existingProducts: () => vm.items
            }
        });

        try {
            let productId = await productModal.result;
            await getItems();
            if (productId) {
                changeSelectedItem(vm.items.find(p => p.Id === productId));
            }
        } catch { return; }
    }

    async function openGroupBusinessProcessModal() {
        let businessProcessModal = $uibModal.open({
            templateUrl: '/App/Components/OwnershipGroups/ownershipGroupItemsList/groupBusinessProcessModal/groupBusinessProcessModal.tmpl.html',
            controller: groupBusinessProcessModalController,
            controllerAs: 'gbpmvm',
            backdrop: 'static',
            resolve: {
                ownershipGroup: () => vm.selectedOwnershipGroup,
                existingBusinessProcesses: () => vm.items
            }
        });

        try {
            let bpId = await businessProcessModal.result;
            await getItems();
            if (bpId) {
                changeSelectedItem(vm.items.find(bp => bp.Id === bpId));
            }
        } catch { return; }
    }

    async function openGroupRiskModal(item, modalType) {
        let riskModal = $uibModal.open({
            templateUrl: '/App/Components/OwnershipGroups/ownershipGroupItemsList/groupRiskModal/groupRiskModal.tmpl.html',
            controller: groupRiskModalController,
            controllerAs: 'grmvm',
            backdrop: 'static',
            resolve: {
                ownershipGroup: () => vm.selectedOwnershipGroup,
                existingRisks: () => vm.items,
                riskCriteriaItem: () => item,
                modalType: () => modalType,
            }
        });

        try {
            let riskId = await riskModal.result;
            await getItems();
            if (riskId) {
                changeSelectedItem(vm.items.find(risk => risk.Id === riskId));
            }
        } catch { return; }
    }
}