import swal from "sweetalert2";
import selectUserMappingModalController from "./selectUserMappingModal.controller";

export default /*@ngInject*/ function ($rootScope, $location, $uibModal, RequestManagementLoadDataService, errorsDataService, $http) {

    const vm = this;

    activate();

    function activate() {
        securePaths();
    }

    vm.selectWizardMode = selectWizardMode;
    vm.executeAlternateWizardPath = executeAlternateWizardPath;

    function selectWizardMode(wizardMode) {
        $location.path('/IdentityManager/Place/RequestManagement/' + wizardMode);
    }

    async function securePaths() {
        let security = JSON.parse(localStorage.getItem('UserSecurityObjects'));
        let products = await $http.get(apiUrl + 'api/identitymanager/applications');

        vm.showNew = security.find(o => o.ObjectID === 11402);
        vm.showModify = security.find(o => o.ObjectID === 11403);
        vm.showTerminate = security.find(o => o.ObjectID === 11404);
        vm.showSelfService = security.find(o => o.ObjectID === 11405);
        vm.showEmergencyAccess = security.find(o => o.ObjectID === 11406) && products.data.some(p => p.ProductType === 'SAP');

    }

    async function executeAlternateWizardPath(wizardMode, requestType) {
        try {
            $rootScope.IMSelectedExistingUser = await RequestManagementLoadDataService.getSelfServiceMapping();
            let userMappingArray = $rootScope.IMSelectedExistingUser.length > 1 ? $rootScope.IMSelectedExistingUser : null;

            $rootScope.IMSelectedExistingUser.forEach(user => {
                if (user.PendingRequest) {
                    user.HasERPDataLoaded = false;
                }
            });

            if ($rootScope.IMSelectedExistingUser.length > 1) {
                try {
                    await openSelectUserMappingModal(userMappingArray);
                } catch (error) {
                    throw error;
                }
            } else {
                $rootScope.IMSelectedExistingUser = $rootScope.IMSelectedExistingUser[0];
            }

            switch (requestType) {
                case 'SelfService':
                    $location.path('/IdentityManager/Place/RequestManagement/' + wizardMode).search({ selfservice: 'true' });
                    break;
                case 'EmergencyAccess':
                    $location.path('/IdentityManager/Place/RequestManagement/' + wizardMode).search({ emergencyaccess: 'true' });
                    break;
            }
        } catch (error) {
            if (error.status === 404) {
                // Custom error message for non mapped system users trying to create a Self-Service or Emergency Access Request
                let message =
                    `<div>
                    Your Fastpath user is not mapped to a system user so you cannot start any ${requestType} requests.
                </div>
                <div class="m-t-sm">
                    Your Fastpath user will need to be mapped to a system user on the Maintain System Users page in the System Configuration module.
                </div>`;
                swal({ title: 'Error', html: message, type: 'warning' });
            } else {
                errorsDataService.catch(error);
            }
        }
    }

    async function openSelectUserMappingModal(options) {
        let selectUserMappingModal = $uibModal.open({
            templateUrl: '/App/Components/RequestManagement/selectUserMappingModal.html',
            controller: selectUserMappingModalController,
            controllerAs: 'summvm',
            backdrop: 'static',
            resolve: {
                userMappingOptions: () => options
            }
        });

        try {
            $rootScope.IMSelectedExistingUser = await selectUserMappingModal.result;
        } catch (error) { throw error; }
    }

}