export default /*@ngInject*/ function ($http) {

    //PeopleSoft HTTP Calls
    this.getAllPSRoles = function () {
        return $http({
            method: 'GET',
            url: `${apiUrl}api/identitymanager/ps/roles`
        });
    };

    this.getPSPermissionLists = function () {
        return $http({
            method: 'GET',
            url: `${apiUrl}api/identitymanager/ps/permissionlists`
        });
    };

    this.getPSEmailTypes = function () {
        return [
            {
                DisplayValue: "Work",
                Value: 'WORK'
            },
            {
                DisplayValue: "Home",
                Value: 'HOME'
            },
            {
                DisplayValue: "Other",
                Value: 'OTH'
            },
            {
                DisplayValue: "Business",
                Value: 'BUS'
            },
            {
                DisplayValue: "Blackberry",
                Value: 'BB'
            },
        ];
    }
}