/* outstandingApprovalsGrid.component.js */

import outstandingApprovalsGridController from "./outstandingApprovalsGrid.controller";

/**
 * @desc component to display Outstanding Approvals Grid
 * @example <outstanding-approvals-grid></outstanding-approvals-grid>
 */

const outstandingApprovalsGrid = {
    controller: outstandingApprovalsGridController,
    controllerAs: 'oagvm',
    templateUrl: '/App/Components/outstandingApprovals/outstandingApprovalsGrid.tmpl.html'
};

export default outstandingApprovalsGrid;