export default /*@ngInject*/ function ($q, $filter, RequestManagementD365SService) {

    var self = this;

    this.loadD365S = function ($scope, openRequest) {
        //Load Data for Products after search row is selected
        var d365sDfrd = $q.defer();

        try {
            self.loadPermissions($scope, openRequest).then(function () {
                d365sDfrd.resolve();
            }, function errorCallback(response) {
                d365sDfrd.reject(response);
            });
        }
        catch (e) {
            d365sDfrd.reject(e);
        }

        return $.when(d365sDfrd).done(function () {
        }).promise();

    };

    this.loadPermissions = function ($scope, openRequest) {
        let permissionsDfrd = $.Deferred(),
            promises = [],
            d365SRoles = [],
            d365STeams = [],
            pendingRoles = $scope.vm.User?.D365SUser?.PendingRoles || [],
            pendingTeams = $scope.vm.User?.D365SUser?.PendingTeams || [];

        $scope.vm.businessUnitsDropdownLoading = true;
        $scope.vm.usersDropdownLoading = true;
        $scope.vm.territoryDropdownLoading = true;
        $scope.vm.siteDropdownLoading = true;
        $scope.vm.incomingMailDropdownLoading = true;
        $scope.vm.outgoingMailDropdownLoading = true;
        $scope.vm.accessModeDropdownLoading = true;

        if ($scope.vm.User.D365SUser != null) {

            d365SRoles = $scope.vm.User.D365SUser.Roles;
            d365STeams = $scope.vm.User.D365SUser.Teams;

            //We can only get roles if there is a business unit selected
            if ($scope.vm.User.D365SUser.BusinessUnitId) {
                promises.push(
                    RequestManagementD365SService.getRolesByBusinessUnitId($scope.vm.User.D365SUser.BusinessUnitId).then(function (response) {
                        $scope.vm.ListOfAvailableD365SRoles = response.data.map(applyPendingByKey.bind(null, "Role", pendingRoles));
                    })
                );
                promises.push(RequestManagementD365SService.getTeamsByBusinessUnitId($scope.vm.User.D365SUser.BusinessUnitId).then(function (response) {
                    $scope.vm.ListOfAvailableD365STeams = response.data.map(applyPendingByKey.bind(null, "Team", pendingTeams));
                }));
            }
        }

        promises.push(RequestManagementD365SService.getAllUsers().then(response => {
            response.data = $filter('orderBy')(response.data, 'FullName');
            $scope.vm.D365SUserDataSource = response.data;
            $scope.vm.usersDropdownLoading = false;
        }));

        promises.push(RequestManagementD365SService.getAllBusinessUnits().then(response => {
            $scope.vm.D365SBusinessUnitDataSource = response.data;
            $scope.vm.businessUnitsDropdownLoading = false;
        }));

        promises.push(RequestManagementD365SService.getAllTerritories().then(response => {
            $scope.vm.D365STerritoryDataSource = response.data;
            $scope.vm.territoryDropdownLoading = false;
        }));

        promises.push(RequestManagementD365SService.getAllSites().then(response => {
            $scope.vm.D365SSiteDataSource = response.data;
            $scope.vm.siteDropdownLoading = false;
        }));

        promises.push(RequestManagementD365SService.getAllIncomingMailMethods().then(response => {
            $scope.vm.D365SIncomingMailMethodsDataSource = response.data.lstOptions;
            $scope.vm.incomingMailDropdownLoading = false;
        }));

        promises.push(RequestManagementD365SService.getAllOutgoingMailMethods().then(response => {
            $scope.vm.D365SOutgoingMailMethodsDataSource = response.data.lstOptions;
            $scope.vm.outgoingMailDropdownLoading = false;
        }));

        promises.push(RequestManagementD365SService.getAllAccessModes().then(response => {
            response.data.lstOptions = $filter('orderBy')(response.data.lstOptions, 'Label');
            $scope.vm.D365SAccessModeDataSource = response.data.lstOptions;
            $scope.vm.accessModeDropdownLoading = false;
        }));

        $q.all(promises).then(function () {
            if ($scope.vm.User === null) {
                $scope.vm.User = {};
            }
            if ($scope.vm.User.D365SUser === null) {
                $scope.vm.User.D365SUser = {};
            }

            //Modify Available and Selected company lists to refect users current security
            if (d365SRoles != null && $scope.vm.ListOfAvailableD365SRoles != null) {
                d365SRoles.forEach(role => {
                    if ($scope.vm.ListOfSelectedD365SRoles.none(selectedRole => { return selectedRole.Id === role.Role.Id; })) {

                        $scope.vm.ListOfSelectedD365SRoles.unshift({
                            Id: role.Role.Id,
                            Name: role.Role.Name,
                            IsAssignable: role.Role.IsAssignable,
                            Identifier: role.Role.Identifier,
                            Pending: pendingRoles.find(pendingRole => pendingRole.id === role.Role.Id)
                        });

                        $scope.vm.ListOfAvailableD365SRoles.forEach((row, j) => {
                            if (row.Id === role.Role.Id) {
                                $scope.vm.ListOfAvailableD365SRoles.splice(j, 1);
                            }
                        });
                    }
                });
            }

            if (d365STeams != null && $scope.vm.ListOfAvailableD365STeams != null) {
                d365STeams.forEach(team => {
                    if ($scope.vm.ListOfSelectedD365STeams.none(selectedTeam => { return selectedTeam.Id === team.Team.Id; })) {

                        $scope.vm.ListOfSelectedD365STeams.unshift({
                            Id: team.Team.Id,
                            Name: team.Team.Name,
                            Pending: pendingTeams.find(pendingTeam => pendingTeam.id === team.Team.Id)
                        });

                        $scope.vm.ListOfAvailableD365STeams.forEach((row, j) => {
                            if (row.Id === team.Team.Id) {
                                $scope.vm.ListOfAvailableD365STeams.splice(j, 1);
                            }
                        });
                    }
                });
            }

            $scope.vm.ListOfAvailableD365SRoles = $scope.vm.ListOfAvailableD365SRoles.filter(role => role.IsAssignable);
            $scope.d365sUserLoaded = true;
            permissionsDfrd.resolve();

        });

        return $.when(permissionsDfrd).done(function () {
        }).promise();

    };
}

function applyPendingByKey(key, pendingItems, item) {
     if (pendingItems.length) {
        item.Pending = pendingItems.some(pendingItem => pendingItem[key].Id === item.Id);
     }

    return item;
}