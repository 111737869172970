import { standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($http, helperService, autoMitigationsDataService, FileSaver, mitigation, product, $uibModalInstance) {

    const vm = this;

    vm.needsSelectedExcludedObjectType = true;
    vm.needsSelectedFromObjectType = true;
    vm.removedFiles = [];

    vm.$onInit = onInit;

    vm.saveAutoMitigation = saveAutoMitigation;
    vm.updateAutoMitigation = updateAutoMitigation;
    vm.excludeTypeDropdownChange = excludeTypeDropdownChange;
    vm.fromTypeDropdownChange = fromTypeDropdownChange;
    vm.remove = remove;
    vm.downloadFile = downloadFile;

    vm.product = product;

    vm.dismiss = dismiss;
    vm.close = close;

    function onInit() {
        if (mitigation.uid) {
            vm.action = 'Edit';
            vm.isCreate = false;
            vm.mitigation = {
                description: mitigation.Description,
                selectedExcludeType: {},
                excludeObject: {},
                selectedFromType: {},
                fromObject: {}
            };
            vm.mitigation.selectedExcludeType.name = mitigation.ExcludeObjectType;
            vm.mitigation.excludeObject.Name = mitigation.ExcludeObjectName;
            vm.mitigation.selectedFromType.name = mitigation.FromObjectType;
            vm.mitigation.ruleId = mitigation.Id;
            vm.mitigation.fromObject.Name = mitigation.FromObjectName;
            vm.mitigation.Enabled = mitigation.Enabled;
            if (mitigation.Attachments.length > 0) {
                vm.existingFilesDataSource = [];
                mitigation.Attachments.forEach(attachment => vm.existingFilesDataSource.push(attachment));
            }
        } else {
            vm.action = 'Create';
            vm.isCreate = true;
            vm.mitigation = mitigation;
            vm.mitigation.Enabled = true;
        }

        vm.excludeDropdownOptions = {
            dataSource: [
                { id: 1, name: "Privileges" }
            ],
            dataTextField: "name",
            dataValueField: "id",
            optionLabel: "-- Select an item --"
        };

        vm.fromDropdownOptions = {
            dataSource: [
                { id: 2, name: "Roles" }
            ],
            dataTextField: "name",
            dataValueField: "id",
            optionLabel: "-- Select an item --"
        };

        vm.excludeObjectDropdownOptions = {
            template: `
                <strong>{{dataItem.AltName}}</strong>
                <span ng-if="dataItem.Name"> | {{dataItem.Name}}</span>
                <span ng-if="dataItem.SecurityObjectTypeName"> | {{dataItem.SecurityObjectTypeName}} </span>
            `,
            filter: "contains",
            autoBind: false,
            minLength: 3,
            virtual: {
                itemHeight: 26
            },
            height: 325
        };

        vm.fromObjectDropdownOptions = {
            template: `
                <span>
                    <strong>{{ dataItem.Name }}</strong>
                    <span> | {{ dataItem.Code }}</span>
                </span>
            `,
            filter: "contains",
            autoBind: false,
            minLength: 3,
            virtual: {
                itemHeight: 26
            },
            height: 325
        };

        vm.fromFunctionDropdownOptions = {
            dataTextField: "name",
            dataValueField: "id"
        };
    }

    //Save Auto Mitigation
    async function saveAutoMitigation() {
        vm.savingAutoMitigation = new createSpinner();
        const data = {
            ExcludeObjectId: vm.mitigation.excludeObject.Id,
            ExcludeObjectType: vm.mitigation.selectedExcludeType.id,
            FromObjectId: vm.mitigation.fromObject.Code,
            FromObjectType: vm.mitigation.selectedFromType.id,
            Enabled: vm.mitigation.Enabled,
            Description: vm.mitigation.description
        };
        try {
            let response = await autoMitigationsDataService.addAutoMitigation(vm.product, data);
            await helperService.successfulSaveButton(vm.savingAutoMitigation);
            if (vm.attachments.flow.files.length > 0) {
                await uploadNewFiles(vm.attachments.flow.files, response.data);
            }
            vm.close();
        } catch {
            vm.savingAutoMitigation.loadingValue = false;
        }
    }

    //Update Auto Mitigation
    async function updateAutoMitigation() {
        vm.savingAutoMitigation = new createSpinner();
        const data = {
            ExclusionId: vm.mitigation.ruleId,
            Enabled: vm.mitigation.Enabled,
            Description: vm.mitigation.description
        };
        try {
            await autoMitigationsDataService.modifyMitigation(vm.product, data.ExclusionId, data);
            await helperService.successfulSaveButton(vm.savingAutoMitigation);
            if (vm.removedFiles.length > 0) {
                vm.removedFiles.forEach(file => removeFile(file));
            }
            if (vm.attachments.flow.files.length > 0) {
                await uploadNewFiles(vm.attachments.flow.files, data.ExclusionId);
            }
            vm.close();
        } catch {
            vm.savingAutoMitigation.loadingValue = false;
        }
    }


    function excludeTypeDropdownChange() {
        if (vm.mitigation.selectedExcludeType.id) {
            if (vm.mitigation.selectedExcludeType.id === 1) {
                vm.mitigation.excludeObjectDataSource = new kendo.data.DataSource({
                    schema: {
                        data: "_embedded.reportData",
                        total: "TotalItems"
                    },
                    pageSize: standardGridPageSize,
                    serverPaging: true,
                    serverFiltering: true,
                    transport: {
                        read: async function (options) {
                            let data = helperService.prepareSortsAndFilters(options);
                            if (data.Filters.length > 0) {
                                data.Filters[0].FieldName = "SecurityObjectAltName";
                            }
                            const params = {
                                "pagesize": options.data.pageSize,
                                "page": options.data.page
                            };

                            let response = await $http({
                                method: 'POST',
                                url: apiUrl + 'api/assure/orfc/privileges',
                                data: data,
                                params: params
                            });
                            if (vm.mitigation.excludeObjectDataSource._data.length === 0) {
                                vm.needsSelectedExcludedObjectType = false;
                                vm.excludeObjectDropdown.setOptions({ optionLabel: '-- Select an item --' });
                            }
                            options.success(response.data);
                        }
                    }
                });

                vm.mitigation.excludeObjectDataSource.read();
            }

            else if (!vm.mitigation.selectedExcludeType.id) {
                vm.needsSelectedExcludedObjectType = true;
                vm.mitigation.excludeObjectDataSource = [];
            }
        }
    }

    function fromTypeDropdownChange() {
        if (vm.mitigation.selectedFromType.id) {
            vm.mitigation.fromObjectDataSource = new kendo.data.DataSource({
                schema: {
                    data: "_embedded.reportData",
                    total: "TotalItems"
                },
                pageSize: standardGridPageSize,
                serverPaging: true,
                serverFiltering: true,
                transport: {
                    read: async function (options) {
                        let data = helperService.prepareSortsAndFilters(options);
                        if (data.Filters.length > 0) {
                            data.Filters[0].FieldName = "Name";
                        }
                        const params = {
                            "pagesize": options.data.pageSize,
                            "page": options.data.page
                        };
                        let response = await $http({
                            method: 'POST',
                            url: apiUrl + 'api/assure/orfc/roles',
                            data: data,
                            params: params
                        });
                        if (vm.mitigation.fromObjectDataSource._data.length === 0) {
                            vm.needsSelectedFromObjectType = false;
                            vm.fromObjectDropdown.setOptions({ optionLabel: '-- Select an item --' });
                        }
                        options.success(response.data);
                    }
                }
            });
            vm.mitigation.fromObjectDataSource.read();
        }

        else if (!vm.mitigation.selectedFromType.id) {
            vm.needsSelectedFromObjectType = true;
            vm.mitigation.fromObjectDataSource = [];
        }
    }

    function errorCallBack(error) {
        helperService.showErrorMessage(error);
    }

    function remove(identifier) {
        vm.removedFiles.push(identifier);
        var index = vm.existingFilesDataSource.findIndex(remainingFile => remainingFile.Identifier === identifier);
        vm.existingFilesDataSource.splice(index, 1);
    }

    async function removeFile(identifier) {
        vm.fileActionPending = true;

        try {
            await $http.delete(apiUrl + 'api/core/attachments/' + identifier);
            vm.fileActionPending = false;
        } catch (err) {
            errorCallBack(err.data);
            vm.fileActionPending = false;
        }
    }

    async function downloadFile(file) {
        vm.fileActionPending = true;

        try {
            let response = await $http.get(apiUrl + 'api/core/attachments/' + file.Identifier + '/download', { responseType: "blob" });
            FileSaver.saveAs(response.data, file.FileName);
            vm.fileActionPending = false;
        } catch (err) {
            errorCallBack(err.data);
            vm.fileActionPending = false;
        }
    }

    async function uploadNewFiles(files, autoMitigationId) {
        let fd = new FormData();
        files.forEach(function (file, i) {
            fd.append(files[i].file.name, files[i].file);
        });

        fd.append("DataType", "OracleCloudAutoMitigationRule");
        fd.append("DataTypeId", autoMitigationId);

        try {
            await $http.put(apiUrl + 'api/core/attachments/', fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
            vm.close();
        } catch (err) {
            errorCallBack(err.data);
        }
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }

    function close() {
        $uibModalInstance.close();
    }
}