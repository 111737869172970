import createSpinner from "../../../../Shared/Functions/createSpinner";
import { standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $filter, ownershipGroup, existingOwners, helperService, ownershipGroupOwnerDataService, ownershipGroupItemsDataService) {
    const vm = this;

    vm.saveCompanyOwner = saveCompanyOwner;
    vm.selectedCompanyTypeChanged = selectedCompanyTypeChanged;
    vm.selectedOwnerTypeChanged = selectedOwnerTypeChanged;
    vm.cancel = cancel;

    vm.ownershipGroup = { ...ownershipGroup };

    activate();

    async function activate() {
        vm.selectedCompanyType = 'Company';
        setupCompanyDropdown();
        await setOwners();
        vm.selectedOwnerType = 'User';
        selectedOwnerTypeChanged();
    }

    async function setOwners() {
        vm.ownersDataSourceLoading = true;
        let { data } = await ownershipGroupOwnerDataService.getAvailableOwners(ownershipGroup.Id, ownershipGroup.GroupType);
        data = $filter('orderBy')(data, 'Name');
        data = data.filter(masterOwner => !existingOwners.some(owner => owner.Id === masterOwner.Id));
        vm.availableOwnersDataSource = data;
        vm.ownersDataSourceLoading = false;
    }

    function setupCompanyDropdown() {
        vm.companiesDropdownOptions = {
            filter: "contains",
            autoClose: false,
            virtual: {
                itemHeight: 26,
                mapValueTo: 'dataItem',
                valueMapper: function (options) {
                    options.success(helperService.multiValueMapperFunction(options, vm.companiesDataSource, 'Id'));
                }
            },
            height: 325
        };


        vm.companyDropdownDataSource = new kendo.data.DataSource({

            pageSize: standardGridPageSize,
            transport: {
                read: async (options) => {
                    let { data } = await ownershipGroupItemsDataService.getAllItems(ownershipGroup);
                    data = data.filter(company => company.Type === vm.selectedCompanyType);
                    data = $filter('orderBy')(data, 'Name');
                    vm.companiesDropdown.setOptions({ placeholder: `-- Select one or more ${$filter('insertSpaceBetweenCapitalWords')(vm.selectedCompanyType)}s --` });
                    vm.companiesDataSource = data;
                    options.success(data);
                }
            }
        });
    }

    async function saveCompanyOwner() {
        const data = {
            GroupId: ownershipGroup.Id,
            Owners: [vm.selectedOwner],
            Items: vm.selectedCompanies
        };

        vm.savePending = new createSpinner();

        try {
            let { data: newOwnerId } = await ownershipGroupOwnerDataService.addOwnerToGroup(ownershipGroup.Id, ownershipGroup.GroupType, data);           
            await helperService.successfulSaveButton(vm.savePending);
            close(newOwnerId);
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function selectedCompanyTypeChanged() {
        vm.companiesDropdown.value([]);
        vm.selectedCompanies = null;
        vm.companyDropdownDataSource.read();
    }

    function selectedOwnerTypeChanged() {
        vm.selectedOwner = null;
        vm.filteredOwnersDataSource = vm.availableOwnersDataSource.filter(owner => owner.TypeName === vm.selectedOwnerType);
    }

    function close(ownerId) {
        $uibModalInstance.close(ownerId);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}