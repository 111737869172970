import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (user, usersDataService, helperService, $uibModalInstance) {
    const vm = this;
    vm.user = { ...user };
    vm.cancel = cancel;
    vm.saveUser = saveUser;


    async function saveUser() {
        vm.savingUser = createSpinner();
        const data = {
            UserId: vm.user.UserID,
            Name: vm.user.UserLogonName,
            Active: vm.user.Active,
            Email: vm.user.Email
        }
        try {
            await usersDataService.updateUser(vm.user.UserID, data);
            await helperService.successfulSaveButton(vm.savingUser);
            $uibModalInstance.close();
        } catch (err) {
            vm.savingUser.loadingValue = false;
            return;
        }
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }

}