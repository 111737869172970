import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $filter, simulation, role, selectedAuthObjects, helperService, sapSdSimulationDataService, sapSdSimRoleDataService) {
    const vm = this;

    vm.close = close;
    vm.addAuthObjectToSimulationRole = addAuthObjectToSimulationRole;
    selectedAuthObjects = [...selectedAuthObjects];

    activate();

    function activate() {
        setupAvailableAuthObjectsGrid();
    }

    function setupAvailableAuthObjectsGrid() {
        let availableAuthObjectsGridColumns = [
            { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
            { template: "<a ng-disabled='raomvm.addingAuthObjectsToRole' ng-click='raomvm.addAuthObjectToSimulationRole(dataItem)'><i class='fa fa-plus pull-right text-success'></i></a>", title: " ", width: 20 }
        ];

        vm.availableAuthObjectsGridOptions = helperService.setMainGridOptions(availableAuthObjectsGridColumns, null);

        vm.availableAuthObjectsGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let response = await sapSdSimulationDataService.getSimulationAuthObjects(simulation.Id);
                    response.data = response.data.filter(role => {
                        return !selectedAuthObjects.some(selectedAuthObject => selectedAuthObject.Name === role.Name);
                    });
                    response.data = $filter('orderBy')(response.data, "Name");
                    options.success(response.data);
                }
            }
        });
    }

    async function addAuthObjectToSimulationRole(authObject) {
        vm.addingAuthObjectsToRole = true;
        kendo.ui.progress(vm.availableAuthObjectsGrid.element, true);
        let data = {
            SimulationId: simulation.Id,
            RoleId: role.Id,
            Name: authObject.Name,
        }
        try {
            await sapSdSimRoleDataService.addAuthObjectToSimulationRole(data);
            let processedAuthObject = JSON.parse(JSON.stringify(authObject));
            selectedAuthObjects.push(processedAuthObject);
            kendo.ui.progress(vm.availableAuthObjectsGrid.element, false);
            vm.availableAuthObjectsGridDataSource.read();
            vm.addingAuthObjectsToRole = false;
        } catch {
            vm.addingAuthObjectsToRole = false;
            kendo.ui.progress(vm.availableAuthObjectsGrid.element, false);
        }
    }

    function close() {
        $uibModalInstance.close();
    }
}