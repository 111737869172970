import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ImportSupervisorModalController($uibModalInstance, supervisorAssignmentsDataService) {

    const vm = this;

    vm.importSupervisorAssignments = importSupervisorAssignments;
    vm.cancel = cancel;

    async function importSupervisorAssignments(files) {

        let fd = new FormData();
        files.forEach(file => {
            fd.append(file.file.name, file.file);
        });

        if (files.length > 0) {
            vm.disableButton = true;
            vm.importUpdatePending = new createSpinner();

            try {
                await supervisorAssignmentsDataService.importSupervisorAssignments(fd);
                await helperService.successfulSaveButton(vm.importUpdatePending);
                $uibModalInstance.close();
            } catch {
                vm.disableButton = false;
                vm.importUpdatePending.loadingValue = false;
            }
        }
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}