export default /*@ngInject*/ function ($http, helperService) {

    const vm = this;

    vm.$onInit = onInit;

    vm.deleteLayout = deleteLayout;
    vm.cancel = cancel;

    function onInit() {
        vm.manageSavedLayoutsDataSource = new kendo.data.DataSource({
            transport: {

                read: function (options) {
                    $http.get(apiUrl + 'api/core/reportlayouts').then(function (response) {
                        options.success(response.data);
                    });
                }
            }
        });

        const columnsForGridOptions = [
            { field: "LayoutName", title: "Report Name", filterable: false },
            { field: "ProductID", title: "Product", template: '<span>{{dataItem.ProductID | convertProductIdToProductName}}</span>', filterable: false },
            { template: `<a ng-click="mslvm.deleteLayout(#=ID#)"><i class="glyphicon glyphicon-trash text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 90 }
        ];

        vm.manageSavedLayoutsGridOptions = helperService.setNonPageableGridOptions(columnsForGridOptions);
    }


    function deleteLayout(id) {
        var rows = vm.manageSavedLayoutsDataSource.data();

        rows.forEach(function (row) {
            if (row.ID.toString() === id.toString()) {

                var url = apiUrl + 'api/core/reportlayouts/' + row.ID;
                $http.delete(url).
                    then(function (response) {
                        rows.remove(row);
                    }, function (response) {
                        helperService.showErrorMessage(response.data);
                    });
            }
        });
    }

    function cancel() {
        vm.modalInstance.close();
    }
}