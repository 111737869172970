 export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getSnapshots,
        getDistinctRecordType,
        getRecordType,
        updateRecordType,
        updateSnapshot,
        updateReportCurrentByDate
    };

    return service;

    async function getSnapshots(productType) {
        try {
            return await $http.get(`${apiUrl}api/audittrail/${productType}/reports`);
        } catch (err) {
            showError(err);
        }
    }

    async function getRecordType() {
        try {
            return await $http.get(`${apiUrl}api/audittrail/ns/snapshot/recordtype`)
        } catch (err) {
            showError(err);
        }
    }

    async function getDistinctRecordType(selectedRecordType) {
        try {
            return await $http.get(`${apiUrl}api/audittrail/ns/snapshot/header/distinctbyrecordtype/${selectedRecordType}`);
        } catch (err) {
            showError(err);
        }
     }

     async function updateRecordType(data, rowId) {
         try {
            return await $http({
                 method: 'PUT',
                 url: `${apiUrl}/api/audittrail/ns/snapshot/recordtype/` + rowId,
                 data: data
             });
         } catch (err) {
             showError(err)
             sendError(err);
         }

     }

    async function updateSnapshot(data) {
        try {
           return await $http.put(`${apiUrl}api/audittrail/orfc/reports/${data.Id}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateReportCurrentByDate(data, params) {
        try {
            return await $http({
                method: 'POST',
                url: `${apiUrl}api/audittrail/ns/snapshot/report/currentbydate`,
                data: data,
                params: params
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }



    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }

}