export default function () {

    //If App is selected the child steps are set to true else false
    function toggleVisibleAppSteps (steps, stepIsVisible, parentStep) {
        steps.forEach(step => {
            if (step.parent === parentStep) {
                step.visible = stepIsVisible;
            }
        });
    }

    this.createCustomSteps = function (application, steps) {
        let customSteps = [];
        const baseProductName = steps.find(step => step.parent !== application.ProductName && step.parent === application.Name).parent;
        const firstBaseIndex = steps.findIndex(step => step.parent !== application.ProductName && step.parent === application.Name);
        const customStepBase = steps.filter(step => step.parent !== application.ProductName && step.parent === application.Name);

        customStepBase.forEach(step => {
            let newCustomStep = Object.assign({}, step);
            if (application.Name === 'Universal') {
                newCustomStep.title = application.ProductName + ' ' + newCustomStep.title;
                newCustomStep.productId = application.ProductID;
            } else {
                newCustomStep.title = step.title.replace(application.Name, application.ProductName);
            }
            newCustomStep.parent = application.ProductName;
            newCustomStep.isCustom = true;
            customSteps.push(newCustomStep);
        });

        // See where the steps need to go order-wise
        if (baseProductName < application.ProductName) {
            // Custom Product Name is after parent product steps alpha so they follow them
            steps.splice(firstBaseIndex + customStepBase.length, 0, ...customSteps);
        } else {
            // Custom Product Name is before parent product steps alpha so they precede them
            steps.splice(firstBaseIndex, 0, ...customSteps);
        }

        return steps;
    };

    function checkIfApplicationIsSelectedAndMatches (availableApplications, steps, applicationName) {
        var appSelected = false;

        availableApplications.forEach(application => {
            if (application.ProductName === applicationName) {
                toggleVisibleAppSteps(steps, application.selected, applicationName);
                appSelected = application.selected;
            } else {
                toggleVisibleAppSteps(steps, application.selected, application.ProductName);
            }
        });

        return appSelected;
    };

    //NetSuite Selected
    this.setNetSuiteStepsToVisible = function (availableApplications, steps) {
        return checkIfApplicationIsSelectedAndMatches(availableApplications, steps, 'NetSuite');
    };

    //SAP Selected
    this.setSAPStepsToVisible = function (availableApplications, steps) {
        return checkIfApplicationIsSelectedAndMatches(availableApplications, steps, 'SAP');
    };

    //AAD Selected
    this.setAADStepsToVisible = function (availableApplications, steps) {
        return checkIfApplicationIsSelectedAndMatches(availableApplications, steps, 'Azure Active Directory');
    };

    //AX7 Selected
    this.setAX7StepsToVisible = function (availableApplications, steps) {
        return checkIfApplicationIsSelectedAndMatches(availableApplications, steps, 'Dynamics 365 for Finance and Operations');
    };

    //AX2012 Selected
    this.setAX2012StepsToVisible = function (availableApplications, steps) {
        return checkIfApplicationIsSelectedAndMatches(availableApplications, steps, 'Dynamics AX');
    };

    //Oracle Selected
    this.setOracleStepsToVisible = function (availableApplications, steps) {
        return checkIfApplicationIsSelectedAndMatches(availableApplications, steps, 'Oracle EBS');
    };

    //Oracle Cloud Selected
    this.setOracleCloudStepsToVisible = function (availableApplications, steps) {
        return checkIfApplicationIsSelectedAndMatches(availableApplications, steps, 'Oracle Cloud');
    };

    //GP Selected
    this.setGPStepsToVisible = function (availableApplications, steps) {
        return checkIfApplicationIsSelectedAndMatches(availableApplications, steps, 'Dynamics GP');
    };

    //NAV Selected
    this.setNAVStepsToVisible = function (availableApplications, steps) {
        return checkIfApplicationIsSelectedAndMatches(availableApplications, steps, 'Dynamics NAV');
    };

    //D365S Selected
    this.setD365SStepsToVisible = function (availableApplications, steps) {
        return checkIfApplicationIsSelectedAndMatches(availableApplications, steps, 'Dynamics 365 Customer Engagement');
    };

    //PeopleSoft Selected
    this.setPSStepsToVisible = function (availableApplications, steps) {
        return checkIfApplicationIsSelectedAndMatches(availableApplications, steps, 'PeopleSoft');
    };

    //Salesforce Selected
    this.setSalesforceStepsToVisible = function (availableApplications, steps) {
        return checkIfApplicationIsSelectedAndMatches(availableApplications, steps, 'Salesforce');
    };

    //Custom Product Selected
    this.setCustomStepsToVisible = function (availableApplications, steps, application) {
        return checkIfApplicationIsSelectedAndMatches(availableApplications, steps, application.ProductName);
    };
}