import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, optionSetsDataService, item, optionSet, helperService) {

    var vm = this;
    vm.cancel = cancel;
    vm.submitValueForm = submitValueForm;
    vm.hasExistingValueChanged = hasExistingValueChanged;

    vm.item = item;
    vm.value = {};

    activate();

    function activate() {
        if (vm.item.Id) {
            vm.value = { ...vm.item };
            vm.origValue = { ...vm.value };
        } else {
            vm.value.Active = true;
        }
    }

    function cancel() { $uibModalInstance.dismiss(); }

    function submitValueForm() {
        if (item.Id) {
            editValue(vm.value);
        } else {
            addNewValue(vm.value);
        }
    }

    async function addNewValue(value) {
        vm.updatePending = new createSpinner();
        const  newValue = {
            'OptionSetId': optionSet.Id,
            'Name': value.Name,
            'Active': value.Active
        };

        try {
            await optionSetsDataService.addOptionSetValue(optionSet.Id, newValue);
            await helperService.successfulSaveButton(vm.updatePending);
            close();
        } catch {
            vm.updatePending.loadingValue = false;
        }
    }

    async function editValue(value) {
        vm.updatePending = new createSpinner();
        const  editedValue = {
            'OptionSetValueId': item.Id,
            'Name': value.Name,
            'Active': value.Active
        };

        try {
            await optionSetsDataService.editOptionSetValue(optionSet.Id, editedValue);
            await helperService.successfulSaveButton(vm.updatePending);
            close();
        } catch {
            vm.updatePending.loadingValue = false;
        }
    }

    function close() {
        $uibModalInstance.close();
    }

    function hasExistingValueChanged() {
        if (vm.origValue && JSON.stringify(vm.origValue) === JSON.stringify(vm.value)) {
            return false;
        } else {
            // Either creating a new value or user has modified one of the fields
            return true;
        }
    }
}