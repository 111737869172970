import instanceAssignmentModalController from "./instanceAssignmentModal.controller";
import routingCriteriaModalController from "./routingCriteriaModal/routingCriteriaModal.controller";

export default /*@ngInject*/ function (reviewSchedulesDataService, reviewAssignmentsDataService, $uibModal) {
    const vm = this;

    vm.openInstanceAssignmentModal = openInstanceAssignmentModal;
    vm.removeInstanceAssignment = removeInstanceAssignment;
    vm.openGroupAssignmentsModal = openGroupAssignmentsModal;
    vm.openDefineRoutingCriteriaModal = openDefineRoutingCriteriaModal;
    vm.selectedOwnershipGroupChanged = selectedOwnershipGroupChanged;

    vm.reviewOwnershipGroupsOptions = [
        {
            text: 'Define Routing Crtieria',
            click: item => {
                vm.openDefineRoutingCriteriaModal(item.instanceAssignment);
            },
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: item => {
                vm.selectedOwnershipGroupChanged(null);
                vm.removeInstanceAssignment(item.instanceAssignment.Id);
            }
        }
    ]

    vm.$onChanges = activate;

    function activate() {
        vm.ownershipGroupTypeName = vm.scheduleInstance.OwnershipGroupTypeName;
        vm.reviewType = vm.scheduleInstance.ReviewType;
        vm.routingCriteriaEnabled = vm.reviewType === 'SecurityRole' || vm.reviewType === 'Risk' || vm.reviewType === 'BusinessProcess' ? true : false;
        setInstanceAssignments();
    }

    async function setInstanceAssignments() {
        vm.loadingAssignments = true;
        let { data } = await reviewSchedulesDataService.getInstanceAssignments(vm.scheduleInstance.Id);
        vm.instanceAssignmentsDisplayList = data;
        vm.loadingAssignments = false;
    }

    async function openDefineRoutingCriteriaModal(instanceAssignment) {

        let routingCriteriaModal = $uibModal.open({
            templateUrl: '/App/Components/Certifications/reviewSchedules/scheduleInstanceAssignmentsList/routingCriteriaModal/routingCriteriaModal.html',
            controller: routingCriteriaModalController,
            controllerAs: 'rcmvm',
            backdrop: 'static',
            resolve: {
                existingSchedules: () => vm.instanceAssignmentsDisplayList || null,
                reviewAssignmentId: () => instanceAssignment.Id,
                ownershipGroupTypeName: () => vm.ownershipGroupTypeName
            }
        });

        try {
            let modifiedAssignment = await routingCriteriaModal.result;
            if (modifiedAssignment) {
                instanceAssignment = { ...instanceAssignment };
                selectedOwnershipGroupChanged(instanceAssignment);
            }
        } catch (dismiss) { return; }
    }

    async function openInstanceAssignmentModal() {
        let instanceAssignmentModal = $uibModal.open({
            templateUrl: '/App/Components/Certifications/reviewSchedules/scheduleInstanceAssignmentsList/instanceAssignmentModal.tmpl.html',
            controller: instanceAssignmentModalController,
            controllerAs: 'iamvm',
            backdrop: 'static',
            resolve: {
                existingSchedules: () => vm.instanceAssignmentsDisplayList || null,
                reviewInstance: () => vm.scheduleInstance,
                ownershipGroupTypeName: () => vm.ownershipGroupTypeName,
            }
        });

        try {
            await instanceAssignmentModal.result;
            await setInstanceAssignments();
        } catch (dismiss) { return; }
    }

    async function openGroupAssignmentsModal(group) {
        $uibModal.open({
            template: `
            <div>
                <div class="modal-header">
                    <h3 class="pull-left">{{ogamvm.ownershipGroup.Name}} Group Assignments</h3>
                    <div class="pull-right">
                        <a class="btn btn-xs btn-modalClose" ng-click="ogamvm.dismiss()"><i class="fa fa-times"></i></a>
                    </div>
                </div>
                <div class="modal-body">
                    <ownership-group-assignments group="ogamvm.ownershipGroup"></ownership-group-assignments>
                </div>
            </div>`,
            controller: /*@ngInject*/ function (ownershipGroup, $uibModalInstance) {
                const vm = this;

                vm.ownershipGroup = ownershipGroup;

                vm.dismiss = dismiss;

                function dismiss() {
                    $uibModalInstance.close();
                }

            },
            controllerAs: 'ogamvm',
            backdrop: 'static',
            resolve: {
                ownershipGroup: () => {
                    return { GroupType: group.OwnershipGroupType, Id: group.OwnershipGroupId, Name: group.OwnershipGroupName, TypeName: group.ReviewTypeName };
                }
            }
        });
    }

    async function removeInstanceAssignment(assignmentId) {
        vm.processingAssignments = true;
        try {
            await reviewAssignmentsDataService.deleteReview(assignmentId);
            vm.processingAssignments = false;
            await setInstanceAssignments();
        } catch {
            vm.processingAssignments = false;
        }
    }

    async function selectedOwnershipGroupChanged(ownershipGroup) {
        vm.onSelectOwnershipGroup({ ownershipGroup });
    }
}