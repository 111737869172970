import ownershipGroupsListController from "./ownershipGroupsList.controller";

/* ownershipGroupsList.component.js */

/**
 * @desc component for viewing and maintaining list of ownership groups
 * @example <ownership-groups-list><ownership-groups-list>
 */

const ownershipGroupsList = {
    bindings: {
        onSelectedOwnershipGroupChanged: '&',
    },
    controller: ownershipGroupsListController,
    controllerAs: 'oglvm',
    templateUrl: '/App/Components/OwnershipGroups/ownershipGroupsList/ownershipGroupsList.tmpl.html'
};

export default ownershipGroupsList;