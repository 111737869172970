import swal from "sweetalert2";

export default /*@ngInject*/ function ($scope, helperService) {

    $scope.applyCompanyRestrictionsToAllSelectedRoles = applyCompanyRestrictionsToAllSelectedRoles;

    //If $scope variable doesnt exist in child it looks in parent

    var RoleCompany = function (params) {
        this.RoleId = params.RoleId;
        this.CompanyId = params.CompanyId;
        this.RoleName = params.RoleName;
        this.CompanyName = params.CompanyName;
        this.AllCompanies = params.AllCompanies;
    };

    $scope.AllCompanies = false;

    $scope.addRoleToList = function (role) {
        $scope.vm.ListOfAvailableAX2012Roles.forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfAvailableAX2012Roles.splice(i, 1);
                $scope.vm.ListOfSelectedAX2012Roles.push(role);
            }
        });
        $scope.vm.IsRisksReviewed = null;
    };

    $scope.deleteSelectedRole = function (role) {
        $scope.vm.ListOfSelectedAX2012Roles.forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfSelectedAX2012Roles.splice(i, 1);
                if (role.IsAssignable) {
                    $scope.vm.ListOfAvailableAX2012Roles.push(row);
                }
            }
        });

        $scope.vm.IsRisksReviewed = null;
    };

    $scope.deleteExcludedRole = function (role) {
        $scope.vm.ListOfExcludedAX2012Roles.forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfExcludedAX2012Roles.splice(i, 1);
                $scope.vm.ListOfAvailableAX2012Roles.push(row);
            }
        });

        $scope.vm.IsRisksReviewed = null;
    };

    $scope.addRoleCompany = function (company, selected) {
        $scope.vm.ListOfSelectedAX2012Roles.forEach(function (row, i) {
            if (row.Id === $scope.selectedRole.Id) {

                if (!row.CompanyId || selected === false) {
                    $scope.vm.AXRoleCompanyList.splice(i, 1);
                }
                if (selected === true) {
                    $scope.vm.AXRoleCompanyList.push(
                        new RoleCompany({
                            RoleId: row.RoleId,
                            CompanyId: company.CompanyId,
                            RoleName: row.RoleName,
                            CompanyName: company.CompanyName,
                            AllCompanies: false
                        }));
                }

            }
        });
    };

    $scope.updateSelectedRoleCompanies = function (selectedRole, prop, value, applyToAll) {
        if (applyToAll) {
            $scope.vm.ListOfSelectedAX2012Roles.forEach(role => {
                role[prop] = value;
            })
        } else {
            selectedRole[prop] = value;
        }
    };


    async function applyCompanyRestrictionsToAllSelectedRoles(selectedRole) {
        try {
            await swal(helperService.areYouSureParams('Apply Company Restrictions to all Selected Roles', `Are you sure you want to apply the Company Restrictions for Role ${selectedRole.Name} to all currently Selected Roles?`, 'Apply'));
            $scope.vm.ListOfSelectedAX2012Roles.forEach(role => {
                role['Companies'] = selectedRole.Companies;
            });
        } catch {
            return;
        }
    }

    $scope.selectedRole = null;

    $scope.setSelectedRoleTemplates = function (selectedRoleTemplates) {
        $scope.vm.selectedRoleTemplates = selectedRoleTemplates;
    };
}