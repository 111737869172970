export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        allChangesReport,
        ticketsReport,
        updateChangeRecord
    };

    return service;

    async function allChangesReport(data, params) {
        try {
            return await $http({
                method: 'POST',
                url: apiUrl + 'api/audittrail/ns/v2/places/allchanges',
                data: data,
                params: params
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function ticketsReport(ticketProvider, data) {
        try {
            return await $http.post(apiUrl + 'api/core/tickets/' + ticketProvider + '/search/', data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateChangeRecord(record) {
        try {
            return await $http.put(apiUrl + 'api/audittrail/ns/v2/detail/' + record.ChangeId, record);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}
