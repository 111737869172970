import companyTemplatesListController from "./companyTemplatesList.controller";

const companyTemplatesList = {
    bindings: {
        template: '<',
        onSelectTemplate: '&'
    },
    controller: companyTemplatesListController,
    controllerAs: 'ctlvm',
    templateUrl: '/App/Components/CompanyTemplates/CompanyTemplatesList/companyTemplatesList.tmpl.html'
};

export default companyTemplatesList;