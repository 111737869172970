import { standardGridPageSize, customAutoCompleteFilter, customBooleanFilter } from "../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";

export default /*@ngInject*/ function ($scope, $rootScope, $filter, SavedReportSelectorService, BuildResponsibilityAccessPostBody, objectIdDataService, CustomReportServices, $http, $location, helperService, reportsDataService, reportsService) {

    $scope.$on('kendoWidgetCreated', function () {
        if ($scope.grid) {
            $scope.gridReady = true;
            $scope.runSavedReport();
        }
    });

    $scope.helperService = helperService;

    var vm = $scope.vm = {};
    vm.allRolesOption = true;
    vm.selectedResponsibilities = [];
    vm.updateRoleDataSource = updateRoleDataSource;

    // --- FOR ADVANCED FILTERING ---
    vm.filterCriteria = {};
    vm.filterCriteria.query = [];
    // ---

    $scope.hasDetailedVersion = true;
    objectIdDataService.getObjectID().then(function (objectId) {
        $scope.ObjectId = objectId;

        $scope.filtersDataSource = CustomReportServices.setfiltersDataSource($scope.ObjectId);

        var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;
        $scope.reportId = securityObject.ReportID;

        vm.selectedProduct = {};
        vm.selectedProduct.ID = $scope.pageInfo.ProductType;

        // --- FOR ADVANCED FILTERING ---
        getReportMetadata().then(_ => {
            vm.filterCriteria.fields = reportsService.getFields(vm.reportMetadata.Columns);
            if ($rootScope.loadedSavedReport) {
                $scope.runSavedReport();
            }
        });
        // ---
    });
    if ($location.path().has('Detailed')) {
        $scope.isDetailed = true;
    } else {
        $scope.isDetailed = false;
    }

    $scope.runSavedReport = function () {
        if ($scope.gridReady && $rootScope.loadedSavedReport && $scope.vm.responsibilityDataSource && vm.objectTypeDataSource && $scope.vm.objectDataSource && vm.filterCriteria.fields) {
            // --- FOR ADVANCED FILTERING ---
            vm.filterCriteria.query = reportsService.getSavedReportFilters();
            vm.filterCriteria.needsUpdate = true;
            // ---
            $scope.generateGridWithSelectedValues();
        } else if ($scope.gridReady && !$rootScope.loadedSavedReport && $scope.vm.responsibilityDataSource && vm.objectTypeDataSource && $scope.vm.objectDataSource && vm.filterCriteria.fields) {
            // We want the necessary report details available to be able to use the report toolbar without running the report
            $scope.populateCriteriaModel();
        }
    };

    $scope.changeView = function () {
        if ($scope.isDetailed === true) {
            $location.path('/AccessControl/Place/ResponsibilityAccess').search('');
        } else {
            $location.path('/AccessControl/Place/ResponsibilityAccessDetailed').search('');
        }
    };


    $scope.mainGridOptions = helperService.setMainGridOptions(null, 600);
    $scope.mainGridOptions.autoBind = false;


    $scope.filterManagerGridOptions = CustomReportServices.setfilterManagerGridOptions();

    $scope.filterDropdownOptions = helperService.getFilterDropdownOptions();

    vm.objectTypesDropdownLoading = true;
    $http({
        method: 'GET',
        url: apiUrl + 'api/universal/securityobjecttypes/producttype/',
        params: { productType: 'OR' }
    }).then(function (response) {
        vm.objectTypesDropdownLoading = false;

        vm.objectTypeDataSource = response.data;
        vm.objectTypeDataSource = $filter('orderBy')(response.data, 'Name');
        vm.selectedObjectType = SavedReportSelectorService.checkSavedReportForObjectTypes($rootScope.loadedSavedReport, response.data, vm.selectedObjectType);
        $scope.objectTypeDropdownChange();
        $scope.runSavedReport();
    });

    $scope.objectTypeDropdownChange = function () {
        $scope.kendoObjectDropdownDataSource._data = [];
        $scope.kendoObjectDropdownDataSource.read();
    };

    $scope.kendoObjectDropdownDataSource = new kendo.data.DataSource({
        schema: halSchemaKendoGrid,
        pageSize: standardGridPageSize,
        serverPaging: true,
        serverFiltering: true,
        transport: {
            read: function (options) {

                if (vm.selectedObjectType && vm.selectedObjectType.Identifier !== '') {
                    vm.objectsDropdownLoading = true;

                    let data = helperService.prepareSortsAndFilters(options);
                    if (data.Filters.length > 0) {
                        if ($rootScope.UserPreferences.ObjectSearchField === 'AltName') {
                            data.Filters[0].FieldName = "SecurityObjectAltName";
                        } else {
                            data.Filters[0].FieldName = "SecurityObjectName";
                        }
                    }

                    $http({
                        method: 'POST',
                        url: apiUrl + 'api/universal/securityobjects/producttype/OR/type/' + vm.selectedObjectType.ID,
                        data: data,
                        params: { "pagesize": options.data.pageSize, "page": options.data.page }
                    }).then(function (response) {
                        let data = response.data._embedded.reportData;

                        if ($scope.kendoObjectDropdownDataSource._data.length === 0) {
                            // objects are being loaded for the first time
                            addAllObjectsOption();

                            if ($rootScope.loadedSavedReport) {
                                SavedReportSelectorService.getSavedReportSelectedObject($rootScope.loadedSavedReport).then(response => {
                                    if (response) {
                                        data.unshift(response);
                                    }
                                    success(data);
                                });
                            } else {
                                success(data);
                            }
                        } else if (options.data.page === 1) {
                            if (!options.data.filter || options.data.filter.filters.length === 0 || options.data.filter.filters[0].value === "") {
                                addAllObjectsOption();
                            }
                            if (vm.selectedObject.Identifier !== "-1" && !data.some(object => object.Identifier === vm.selectedObject.Identifier)) {
                                // selected object is not in the dataset we need to add it and make sure it stays selected
                                data.unshift(vm.selectedObject);
                                vm.selectedObject = data[0];
                            }
                            options.success(response.data);
                        } else {
                            options.success(response.data);
                        }

                        function addAllObjectsOption() {
                            let AllObjects = {};
                            if ($rootScope.UserPreferences.ObjectSearchField === 'AltName') {
                                AllObjects = { "AltName": "All Objects", "Id": null, "Identifier": "-1" };
                            } else {
                                AllObjects = { "Name": "All Objects", "Id": null, "Identifier": "-1" };
                            }
                            data.unshift(AllObjects);
                        }

                        function success(data) {
                            vm.objectDataSource = data;
                            vm.selectedObject = data[0];
                            vm.objectsDropdownLoading = false;
                            $scope.runSavedReport();
                            options.success(response.data);
                            $scope.populateCriteriaModel();
                        }

                    });
                } else {
                    options.error();
                }
            }
        }
    });

    if ($rootScope.UserPreferences.ObjectSearchField === 'AltName') {
        vm.dropdownTemplate = '<strong>{{dataItem.AltName}}</strong>      <span ng-if="dataItem.Name"> | {{dataItem.Name}}</span>          <span ng-if="dataItem.SecurityObjectTypeName"> | {{dataItem.SecurityObjectTypeName}} </span>         <span class="badge light-gray-badge-background">{{dataItem.ProductId}}</span>';
        vm.dataTextField = 'AltName';
    } else {
        vm.dropdownTemplate = '<strong>{{dataItem.Name}}</strong>      <span ng-if="dataItem.AltName"> | {{dataItem.AltName}}</span>          <span ng-if="dataItem.SecurityObjectTypeName"> | {{dataItem.SecurityObjectTypeName}} </span>         <span class="badge light-gray-badge-background">{{dataItem.ProductId}}</span>';
        vm.dataTextField = 'Name';
    }

    $scope.objectDropdownOptions = {
        template: vm.dropdownTemplate,
        filter: "contains",
        autoBind: false,
        minLength: 3,
        virtual: {
            itemHeight: 26,
            mapValueTo: "dataItem",
            valueMapper: function (options) {
                options.success(helperService.valueMapperFunction(options, vm.objectDataSource, 'Identifier'));
            }
        },
        open: function () {
            let filters = this.dataSource._filter;
            if (filters) {
                this.dataSource.filter({});
            }
        },
        change: function () {
            $scope.populateCriteriaModel();
        },
        height: 325
    };

    $scope.applySelectedFilter = function () {
        helperService.applyFilterCatchError($scope);
    };

    $scope.gridDataSource = new kendo.data.DataSource({

        pageSize: standardGridPageSize,
        schema: angular.extend(halSchemaKendoGrid, {
            model: {
                fields: {
                    IsAssigned: { type: "boolean" }
                }
            }
        }),
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        transport: {
            read: function (options) {
                delete $rootScope.loadedSavedReport;
                var data = BuildResponsibilityAccessPostBody.generateBody($scope.ObjectId, vm.selectedResponsibilities, vm.selectedObjectType, vm.selectedObject);
                data['QueryInput'] = helperService.prepareSortsAndFilters(options);

                // --- FOR ADVANCED FILTERING ---
                if (vm.filterCriteria.query) {
                    data['QueryInput'].Filters = data['QueryInput'].Filters.concat(vm.filterCriteria.query);
                }
                // ---

                // if tabs are on, save report info for the tab
                if ($rootScope.tabs.length > 0) {
                    reportsService.saveTabReportInfo($scope);
                }

                $http({
                    method: 'POST',
                    url: $scope.urlString,
                    data: data,
                    params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                }).then(function (response) {
                    options.success(response.data);
                    vm.enableGrid = true;

                }).catch(response => {
                    const timeout = 408;

                    if (response.status !== timeout) {
                        helperService.showErrorMessage(response.data);
                    }
                });
            }
        }

    });

    $scope.populateCriteriaModel = function () {
        // do not continue if the proper criteria are not loaded
        if (!$scope.vm.selectedObject || !$scope.vm.selectedObjectType || $scope.vm.selectedResponsibilities === undefined) {
            return;
        }
        var criteria = [];

        $scope.responsibilities = [];
        $scope.securityObject = JSON.parse(JSON.stringify($scope.vm.selectedObject));
        $scope.securityObjectType = $scope.vm.selectedObjectType;

        CustomReportServices.convertCriteriaValueToNull($scope.securityObject, "Identifier");

        $scope.vm.selectedResponsibilities.forEach(resp => {
            criteria.push(CustomReportServices.CreateCriteria("SecurityRoleIdentifier", 'Role', resp.Name, resp.Identifier));
            $scope.responsibilities.push(resp.ID);
        });

        criteria.push(CustomReportServices.CreateCriteria("SecurityObjectType", 'SecurityObjectType', $scope.securityObjectType.Name, { "Id": vm.selectedObjectType.Identifier }));
        criteria.push(CustomReportServices.CreateCriteria("SecurityObject", 'SecurityObject', $scope.securityObject.AltName, { "Id": $scope.securityObject.Identifier }));

        $scope.reportDetailsModel = criteria;

        if ($scope.vm.selectedObject.Id === "") {
            $scope.securityObject = {};
        }

        if ($scope.isDetailed === true) {
            $scope.urlString = apiUrl + 'api/arm/or/places/responsibilityaccess/detailed';
        } else if ($scope.isDetailed === false) {
            $scope.urlString = apiUrl + 'api/arm/or/places/responsibilityaccess';
        }

        // if tabs are on, save report info for the tab
        if ($rootScope.tabs.length > 0) {
            reportsService.saveTabReportInfo($scope);
        }
    };

    $scope.generateGridWithSelectedValues = function () {

        if (!$rootScope.loadedSavedReport) {
            $scope.grid.dataSource._filter = null;
            $scope.grid.dataSource._sort = null;
        }

        $scope.populateCriteriaModel();
        vm.hideExportOptionsFromSubmit = true;

        // Add back the sorting and filtering options if we are running the report for the first time
        if (!$rootScope.loadedSavedReport) {
            $scope.grid.setOptions({
                filterable: {
                    mode: "row"
                },
                sortable: true
            });
        }

        SavedReportSelectorService.queryGridWithOptions($rootScope.loadedSavedReport, $scope.grid, true);
    };

    if ($scope.isDetailed === false) {
        $scope.mainGridColumns = [
            { field: "ResponsibilityName", title: "Responsibility", width: 190, filterable: customAutoCompleteFilter },
            { field: "ResponsibilityID", title: "Responsibility ID", width: 190, filterable: customAutoCompleteFilter, hidden: true },
            { field: "ResponsibilityKey", title: "Responsibility Key", width: 190, filterable: customAutoCompleteFilter, hidden: true },
            { field: "SecurityObjectAltName", title: "User Object Name", width: 160, filterable: customAutoCompleteFilter },
            { field: "SecurityObjectName", title: "Object Name", width: 180, filterable: customAutoCompleteFilter },
            { field: "SecurityObjectTypeName", title: "Object Type", width: 180, filterable: customAutoCompleteFilter },
            { field: "Description", title: "Object Description", width: 200, filterable: customAutoCompleteFilter },
            { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
        ];

    } else if ($scope.isDetailed === true) {
        $scope.mainGridColumns = [
            { field: "ResponsibilityName", title: "Responsibility", width: 190, filterable: customAutoCompleteFilter },
            { field: "ResponsibilityID", title: "Responsibility ID", width: 190, filterable: customAutoCompleteFilter, hidden: true },
            { field: "ResponsibilityKey", title: "Responsibility Key", width: 190, filterable: customAutoCompleteFilter, hidden: true },
            { field: "UserMenuName", title: "User Menu Name", width: 160, filterable: customAutoCompleteFilter },
            { field: "MenuName", title: "Menu Name", width: 180, filterable: customAutoCompleteFilter, hidden: true },
            { field: "UserSubMenuName", title: "User Sub Menu Name", width: 200, filterable: customAutoCompleteFilter, hidden: true },
            { field: "SubMenuName", title: "Sub Menu Name", width: 200, filterable: customAutoCompleteFilter },
            { field: "SecurityObjectAltName", title: "User Object Name", width: 200, filterable: customAutoCompleteFilter },
            { field: "SecurityObjectName", title: "Object Name", width: 200, filterable: customAutoCompleteFilter },
            { field: "SecurityObjectTypeName", title: "Object Type", width: 200, filterable: customAutoCompleteFilter },
            { field: "Description", title: "Object Description", width: 200, filterable: customAutoCompleteFilter },
            { field: "Prompt", title: "Prompt", width: 200, filterable: customAutoCompleteFilter },
            { field: "Path", title: "Path", width: 200, filterable: customAutoCompleteFilter },
            { field: "IsAssigned", title: "Is Assigned", width: 120, filterable: customBooleanFilter }
        ];
    }

    // Don't allow filtering and sorting yet because they will force the report to run
    if (!$rootScope.loadedSavedReport) {
        $scope.mainGridOptions.sortable = false;

        $scope.mainGridOptions.filterable = false;
    }

    if (!$rootScope.loadedSavedReport) {
        CustomReportServices.loadSavedGridLayout($scope, null);
    }


    $scope.deleteFilter = function (id) {
        CustomReportServices.deleteFilterFilterManager($scope, id);
    };

    $scope.clearAllFilters = function () {
        $scope.gridDataSource.filter({});
    };

    $scope.saveReportLayoutButton = function () {
        CustomReportServices.saveGridLayout($scope, null);
    };

    function updateRoleDataSource(roleDataSource, roleSelectedIds) {
        $scope.vm.responsibilityDataSource = roleDataSource;
        vm.responsibilities = roleSelectedIds;
        $scope.populateCriteriaModel();
    }

    // --- FOR ADVANCED FILTERING ---
    function getReportMetadata() {
        return reportsDataService.getReportMetadata($scope.reportId)
            .then(data => {

                vm.reportMetadata = data.data._embedded.reportData;
                vm.reportMetadata.Columns = $filter('orderBy')(vm.reportMetadata.Columns, 'DisplayName');

                return vm.reportMetadata;
            });
    }
    // ---
}
