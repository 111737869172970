import AX7PersonController from "./AX7Person.controller";

/* ax7Person.component.js */

/**
 * @desc component for displaying AX7 Person search and input fields
 * @example <ax-7-person-search-link></ax-7-person-search-link>
 */

const ax7PersonSearchLink = {
    bindings: {
        onUpdatePersonName: '&',
        axPartitionKey: '<',
        axVersion: '<'
    },
    controller: AX7PersonController,
    controllerAs: 'axpvm',
    templateUrl: '/App/Components/RequestManagement/AX7Person/ax7Person.tmpl.html'
};

export default ax7PersonSearchLink;