import swal from "sweetalert2";
import createSpinner from "../../../Shared/Functions/createSpinner";
import requesterGroupModalController from "./requesterGroupModal.controller";
import importRequesterGroupsController from "../importRequesterGroups.controller";

export default /*@ngInject*/ function ($scope, requesterGroupsDataService, helperService, $uibModal, $filter, FileSaver) {

    const vm = this;

    $scope.$on('ImportRequesterGroups', _ => activate());

    vm.changeSelectedRequesterGroup = changeSelectedRequesterGroup;
    vm.deleteRequesterGroup = deleteRequesterGroup;
    vm.openRequesterGroupModal = openRequesterGroupModal;
    vm.exportRequesterGroups = exportRequesterGroups;
    vm.openImportModal = openImportModal;

    vm.menuOptions = [
        {
            text: 'Edit',
            click: item => {
                vm.openRequesterGroupModal(item.requesterGroup);
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: item => {
                vm.deleteRequesterGroup(item.requesterGroup);
            }
        }
    ];

    vm.$onInit = activate;

    async function activate() {
        getRequesterGroups();
    }

    async function getRequesterGroups() {
        vm.requesterGroupsLoading = true;
        let response = await requesterGroupsDataService.getRequesterGroups();
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.requesterGroups = response.data;
        vm.requesterGroupsLoading = false;
    }

    function changeSelectedRequesterGroup(requesterGroup) {
        vm.onSelectedRequesterGroupChanged({ requesterGroup });
    }

    async function openRequesterGroupModal(requesterGroup) {
        let requesterGroupModal = $uibModal.open({
            templateUrl: '/App/Components/RequesterGroups/requesterGroupsList/requesterGroupModal.tmpl.html',
            controller: requesterGroupModalController,
            controllerAs: 'rgmvm',
            backdrop: 'static',
            resolve: {
                requesterGroup: () => requesterGroup || null
            }
        });

        try {
            let newRequesterGroupId = await requesterGroupModal.result;
            await getRequesterGroups();
            if (newRequesterGroupId && newRequesterGroupId !== "") {
                // We know a new requester group was created so we need to select it
                changeSelectedRequesterGroup(vm.requesterGroups.find(group => group.Id === newRequesterGroupId));
            }
        } catch { return; }
    }

    async function deleteRequesterGroup(requesterGroup) {
        try {
            await swal(helperService.areYouSureParams(`Delete Requester Group`, `Are you sure you want to delete Requester Group ${requesterGroup.Name}?`, `Yes`, 'No'))
            await requesterGroupsDataService.deleteGroup(requesterGroup.Id);
            if (requesterGroup.Id === vm.selectedRequesterGroup?.Id) {
                changeSelectedRequesterGroup(null);
            }
            getRequesterGroups();
        } catch {
            return;
        }
    }

    async function exportRequesterGroups() {
        vm.exportUpdatePending = new createSpinner();
        try {
            let response = await requesterGroupsDataService.exportRequesterGroups();
            helperService.successfulSaveButton(vm.exportUpdatePending);
            FileSaver.saveAs(response.data, 'RequesterGroups.xlsx');
            vm.exportUpdatePending.loadingValue = false;
        } catch {
            vm.exportUpdatePending.loadingValue = false;
        }
    }

    async function openImportModal() {
        let importModal = $uibModal.open({
            templateUrl: '/App/Components/RequesterGroups/importRequesterGroups.html',
            controller: importRequesterGroupsController,
            controllerAs: 'irgvm',
            backdrop: 'static',
        });
        try {
            await importModal.result;
        } catch {
            return;
        }
    }
}