import createSpinner from "../../../../Shared/Functions/createSpinner";
import { standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $filter, ownershipGroup, helperService, ownershipGroupItemsDataService, riskRulesetService, ownershipGroupOwnerDataService) {
    const vm = this;

    vm.saveBusinessProcess = saveBusinessProcess;
    vm.selectedOwnerTypeChanged = selectedOwnerTypeChanged;
    vm.setupBusinessProcessDropdown = setupBusinessProcessDropdown;
    vm.changeRuleset = changeRuleset;
    vm.cancel = cancel;

    vm.ownershipGroup = { ...ownershipGroup };

    activate();

    async function activate() {
        await getRulesets();
        await getOwners();

        vm.selectedOwnerType = 'User';
        selectedOwnerTypeChanged();
    }

    async function getRulesets() {
        let { data } = await riskRulesetService.getRiskRulesetList();
        vm.rulesets = data;

        vm.selectedRuleset = vm.rulesets.find(ruleset => ruleset.IsDefault).RulesetId;
        setupBusinessProcessDropdown();
    }

    function changeRuleset() {
        vm.selectedBusinessProcess = null;
        vm.businessProcessDropdownDataSource.read();
    }

    async function setupBusinessProcessDropdown() {

        vm.businessProcessDropdownOptions = {
            filter: "contains",
            autoClose: false,
            virtual: {
                itemHeight: 26
            },
            height: 325,
            optionLabel: '-- Select a Business Process --'
        };

        vm.businessProcessDropdownDataSource = new kendo.data.DataSource({

            pageSize: standardGridPageSize,
            transport: {
                read: async (options) => {
                    const { data } = await ownershipGroupItemsDataService.getAvailableBusinessProcesses(ownershipGroup.Id, vm.selectedRuleset);
                    vm.bpDataSource = data;
                    options.success(vm.bpDataSource);
                }
            }
        });

        return vm.businessProcessDropdownDataSource;
    }

    async function getOwners() {
        vm.ownersDataSourceLoading = true;
        let response = await ownershipGroupOwnerDataService.getAllOwners(ownershipGroup.Id);
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.availableOwnersDataSource = response.data;
        vm.ownersDataSourceLoading = false;
        return vm.availableOwnersDataSource;
    }

    async function saveBusinessProcess() {
        vm.savePending = new createSpinner();

        let newBusinessProcessId;
        let data = {
            GroupId: ownershipGroup.Id,
            Owners: vm.selectedOwners,
            BusinessProcessIds: [vm.selectedBusinessProcess.BusinessProcessId]
        };

        try {
            let response = await ownershipGroupItemsDataService.addItemToGroup(data, ownershipGroup.Id, ownershipGroup.GroupType);
            newBusinessProcessId = response.data;
            await helperService.successfulSaveButton(vm.savePending);
            close(newBusinessProcessId);
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function selectedOwnerTypeChanged() {
        vm.selectedOwners = null;
        vm.filteredOwnersDataSource = vm.availableOwnersDataSource.filter(owner => owner.TypeName === vm.selectedOwnerType);
    }

    function close(bpId) {
        $uibModalInstance.close(bpId);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}