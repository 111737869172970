import swal from "sweetalert2";
import { standardGridPageSize, customAutoCompleteFilter, customBooleanFilter } from "../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($scope, $rootScope, $uibModal, $filter, objectIdDataService, $http, helperService) {

    $scope.helperService = helperService;

    objectIdDataService.getObjectID().then(function (objectId) {
        $scope.ObjectId = objectId;

        var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;

    });

    var vm = $scope.vm = {};

    $scope.selectedDetailView = 'User';
    $scope.simulationViewToShow = 1;

    $scope.saveAsExcel = function (simulation) {

        var postData = {
            "NsRsHeaderId": simulation.Id
        };

        $http.post(apiUrl + '/api/arm/ns/risks/export', postData).then(function successCallback(response) {

            response.data = { ExportId: response.data };

        }, function errorCallback(response) {
            helperService.showErrorMessage(response.data);
        });
    };

    $scope.menuOptions = [
        {
            text: 'Verify',
            click: function ($itemScope, $event, modelValue) {
                var simulation = modelValue;
                $scope.deploySimulation(simulation);
            },
            enabled: function ($itemScope, $event, modelValue) {
                var simulation = modelValue;
                var result = simulation.StatusName === 'Ready' ? true : false;
                return result;
            }
        },
        {
            text: 'Export to Excel',
            click: function ($itemScope, $event, modelValue) {
                var simulation = modelValue;
                $scope.saveAsExcel(simulation);
            },
            enabled: function ($itemScope, $event, modelValue) {
                var simulation = modelValue;
                var result = simulation.StatusName === 'Ready' ? true : false;
                return result;
            }
        },
        {
            text: 'Reprocess',
            click: function ($itemScope, $event, modelValue) {
                var simulation = modelValue;
                $scope.reprocessSimulation(simulation);
            },
            enabled: function ($itemScope, $event, modelValue) {
                var simulation = modelValue;
                var result = simulation.StatusName === 'Error' || simulation.StatusName === 'Ready' ? true : false;
                return result;
            }
        },
        null,
        {
            text: 'Edit',
            click: function ($itemScope, $event, modelValue) {
                var simulation = modelValue;
                $scope.editSimulation(simulation);
            },
            enabled: function ($itemScope, $event, modelValue) {
                var simulation = modelValue;
                var result = simulation.StatusName === 'Ready' ? true : false;
                return result;
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: function ($itemScope, $event, modelValue) {
                var simulation = modelValue;
                $scope.deleteSimulation(simulation);
            }
        }
    ];

    $scope.toggleNewSimulation = function (value) {
        $scope.simulationViewToShow = value;
    };

    $scope.editSimulation = function (simulation) {
        $rootScope.simulationToEdit = angular.copy(simulation);
        $scope.simulationViewToShow = 2;
    };

    $scope.deploySimulation = function (simulation) {
        $rootScope.simulationToDeploy = angular.copy(simulation);
        $scope.simulationViewToShow = 3;
    };

    $scope.changeSort = function (sortClicked) {
        $scope.selectedSort = { field: sortClicked.Id, reverse: sortClicked.reverse };
    };

    $scope.selectedSort = { field: "Name", reverse: false };

    $scope.availableToSorts = [
        { Name: "Name", Id: "Name", reverse: false },
        { Name: "Created Date", Id: "DateCreated", reverse: true },
        //{ Name: "Deployment Date", Id: 3, reverse: true },
        { Name: "Status", Id: "StatusName", reverse: false },
        { Name: "User", Id: "CreatedByName", reverse: false }
    ];

    $scope.reprocessSimulation = function (simulation) {

        simulation.StatusId = 2;
        simulation.StatusName = "Analyzing";
        $scope.selectedSimulation = null;

        var putBody = { "NsRsHeaderId": simulation.Id };

        $http.put(apiUrl + 'api/arm/ns/risks/reprocess', putBody).then(function (response) {
            return;
        }, function errorCallback(response) {
            helperService.showErrorMessage(response.data);
        });

    };

    $scope.deleteSimulation = function (simulation) {
        swal(helperService.areYouSureParams('Delete Security Model', 'Are you sure you want to delete this Security Model?', 'Delete')).then(function () {
            $http.delete(apiUrl + 'api/arm/ns/risks/' + simulation.Id).then(function (response) {
                return;
            }, function errorCallback(response) {
                helperService.showErrorMessage(response.data);
            });

            vm.listOfSimulations.remove(function (el) { return el.Id === simulation.Id; });
            $scope.selectedSimulation = null;
        }, function (dismiss) {
            return;
        });
    };

    $scope.selectSimulationIf = function (simulation) {
        if ($scope.selectedSimulation === simulation) {
            return true;
        } else {
            return false;
        }
    };

    $scope.determineLabelClass = function (ActionName) {
        if (ActionName === 'Modify') {
            return 'label-modify';
        } else if (ActionName === 'Add') {
            return 'label-add';
        } else if (ActionName === 'Remove') {
            return 'label-remove';
        }

    };

    $scope.determineStatusLabelClass = function (ActionName) {
        if (ActionName === 'Ready') {
            return 'label-sim-ready';
        } else if (ActionName === 'Pending Analysis') {
            return 'label-sim-analyzing';
        } else if (ActionName === 'Draft') {
            return 'label-sim-draft';
        } else if (ActionName === 'Analyzing') {
            return 'label-sim-analyzing';
        } else if (ActionName === 'Deployed') {
            return 'label-sim-deployed';
        } else if (ActionName === 'Error') {
            return 'label-remove';
        }
    };

    $scope.changeSimulationSatusTo = function (simulation, statusId) {
        var postData = {
            "NsRsHeaderId": simulation.Id,
            "StatusId": statusId
        };
        $http.put(apiUrl + '/api/arm/ns/risks/status', postData).then(function successCallback(response) {
            $scope.getSimulations();
        }, function errorCallback(response) {
            helperService.showErrorMessage(response.data);
        });
    };

    $scope.changeRoleSelected = function (dataItem) {
        $scope.selectedRole = dataItem;
    };

    function populateSelectedSimulation(dataItem) {
        $scope.selectedRole = null;
        $scope.loadingSelectedSimulation = true;
        $scope.selectedSimulation = dataItem;

        $http.get(apiUrl + '/api/arm/ns/risks/' + dataItem.Id).then(function successCallback(response) {
            vm.riskSnapshot = response.data;
        });

        $http.get(apiUrl + '/api/arm/ns/risks/results/' + dataItem.Id).then(function successCallback(response) {
            vm.riskResults = response.data;
            $scope.loadingSelectedSimulation = false;
        }, function (response) {
            $scope.loadingSelectedSimulation = false;
        });
    }

    $scope.changeRowSelected = function (dataItem) {
        if (!$scope.selectedSimulation) {
            populateSelectedSimulation(dataItem);
        } else if (dataItem.Id !== $scope.selectedSimulation.Id || dataItem.StatusId !== $scope.selectedSimulation.StatusId) {
            populateSelectedSimulation(dataItem);
        }
    };

    $scope.selectedRoleFilter = "Subsidiaries";

    vm.selectedSimulationView = "Results";


    $scope.getSimulations = function () {
        $scope.simulationsAreLoading = true;
        $http.get(apiUrl + 'api/arm/ns/risks/list').then(function successCallback(response) {
            response.data = $filter('orderBy')(response.data, 'Name');
            vm.listOfSimulations = response.data;
            $scope.simulationsAreLoading = false;
        }, function (response) {
            $scope.simulationsAreLoading = false;
        });
    };

    $scope.getSimulations();

    $scope.viewSimulationRoleRisks = function () {

        $uibModal.open({
            templateUrl: '/App/Components/NetSuiteSecurityDesigner/SimulationRisksModal.html',
            controller: viewSimulationRoleRisksController,
            backdrop: 'static',
            size: 'lg',
            scope: $scope
        });
    };

    $scope.viewSimulationUserRisks = function () {

        $uibModal.open({
            templateUrl: '/App/Components/NetSuiteSecurityDesigner/SimulationRisksModal.html',
            controller: viewSimulationUserRisksController,
            backdrop: 'static',
            size: 'lg',
            scope: $scope
        });
    };

}

function viewSimulationRoleRisksController($scope, $uibModalInstance, $http, helperService, $filter) {

    $scope.modalName = 'Role Risks';

    $scope.gridIsLoading = true;

    $http.get(apiUrl + 'api/arm/ns/risks/results/' + $scope.vm.riskResults.Id + '/rolerisk').then(function successCallback(response) {
        $scope.gridIsLoading = false;
        response.data = $filter('orderBy')(response.data, 'RoleName');
        $scope.roleRisksResults = response.data;
        $scope.risksGridDataSource.read();
    }, function (response) {
        $scope.gridIsLoading = false;
        helperService.showErrorMessage(response.data);
    });

    $scope.risksGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        schema: {
            model: {
                fields: {
                    DefaultMitigation: { type: "boolean" }
                }
            }
        },
        transport: {
            read: function (options) {
                options.success($scope.roleRisksResults);
            }
        }
    });

    var columns = [
        { title: "Action", field: "ActionName", filterable: customAutoCompleteFilter },
        { title: "Role", field: "RoleName", filterable: customAutoCompleteFilter },
        { title: "Risk Name", field: "RiskName", filterable: customAutoCompleteFilter },
        { title: "Risk Description", field: "RiskDescription", filterable: customAutoCompleteFilter },
        { title: "Risk Level", field: "RiskLevelName", filterable: customAutoCompleteFilter },
        { title: "Business Cycle", field: "BusinessCycleName", filterable: customAutoCompleteFilter },
        { title: "Policy", field: "Policy", filterable: customAutoCompleteFilter },
        { title: "Default Mitigation", field: "DefaultMitigation", filterable: customBooleanFilter }
    ];

    $scope.risksGridOptions = helperService.setMainGridOptions(columns, null);
    $scope.risksGridOptions.autoBind = false;
    $scope.risksGridOptions.selectable = false;

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}

function viewSimulationUserRisksController($scope, $uibModalInstance, $http, helperService, $filter) {

    $scope.modalName = 'User Risks';

    $scope.gridIsLoading = true;

    $http.get(apiUrl + 'api/arm/ns/risks/results/' + $scope.vm.riskResults.Id + '/userrisk').then(function successCallback(response) {
        $scope.gridIsLoading = false;
        response.data = $filter('orderBy')(response.data, 'RiskName');
        $scope.roleRisksResults = response.data;
        $scope.risksGridDataSource.read();
    }, function (response) {
        $scope.gridIsLoading = false;
        helperService.showErrorMessage(response.data);
    });

    $scope.risksGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        schema: {
            model: {
                fields: {
                    DefaultMitigation: { type: "boolean" }
                }
            }
        },
        transport: {
            read: function (options) {
                options.success($scope.roleRisksResults);
            }
        }
    });

    var columns = [
        { title: "Action", field: "ActionName", filterable: customAutoCompleteFilter },
        { title: "Risk Name", field: "RiskName", filterable: customAutoCompleteFilter },
        { title: "Risk Description", field: "RiskDescription", filterable: customAutoCompleteFilter },
        { title: "User Name", field: "UserName", filterable: customAutoCompleteFilter },
        { title: "Company Name", field: "CompanyName", filterable: customAutoCompleteFilter },
        { title: "Risk Level", field: "RiskLevelName", filterable: customAutoCompleteFilter },
        { title: "Business Cycle", field: "BusinessCycleName", filterable: customAutoCompleteFilter },
        { title: "Policy", field: "Policy", filterable: customAutoCompleteFilter },
        { title: "Default Mitigation", field: "DefaultMitigation", filterable: customBooleanFilter }
    ];

    $scope.risksGridOptions = helperService.setMainGridOptions(columns, null);
    $scope.risksGridOptions.autoBind = false;
    $scope.risksGridOptions.selectable = false;

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}