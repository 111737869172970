import maintainSnapshotsController from "./maintainSnapshots.controller";

/* maintainSnapshots.component.js */

/**
 * @desc component for maintaining audit trail snapshots
 * @example <maintain-snapshots</maintain-snapshots>
 */

const maintainSnapshots = {
    controller: maintainSnapshotsController,
    controllerAs: 'msvm',
    templateUrl: '/App/Components/AuditTrail/MaintainSnapshots/maintainSnapshots.tmpl.html'
};

export default maintainSnapshots;