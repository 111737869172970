import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, $filter, nsAtDocumentationService, helperService, documentation) {
    const vm = this;
    vm.documentation = angular.copy(documentation);

    vm.submitDocumentationForm = submitDocumentationForm;
    vm.openDate = openDate;
    vm.cancel = cancel;
    vm.hasExistingValueChanged = hasExistingValueChanged;

    vm.datePopup = {
        opened: false
    };

    vm.dateOptions = {
        showWeeks: false,
        formatYear: 'yy',
        startingDay: 1
    };

    function activate() {
        getTypes();
        getAuthorizedByOptions();

        if (documentation.Id) {
            vm.origDocumentation = angular.copy(documentation);
        }
    }

    activate();

    async function getTypes() {
        let response = await nsAtDocumentationService.getDocumentationTypes();
        response = $filter('orderBy')(response, 'Name');
        response = response.filter(t => t.Active);
        vm.typesDataSource = response;

        if (documentation.Id) {
            vm.documentation.Type = vm.typesDataSource.find(type => {
                return type.Name === documentation.Type;
            });
        }
    }

    async function getAuthorizedByOptions() {
        let response = await nsAtDocumentationService.getDocumentationAuthorizedByOptions();
        response = $filter('orderBy')(response, 'Name');
        response = response.filter(o => o.Active);
        vm.authorizedByOptionsDataSource = response;

        if (documentation.Id) {
            vm.documentation.AuthorizedBy = vm.authorizedByOptionsDataSource.find(auth => {
                return auth.Name === documentation.AuthorizedBy;
            });
        }
    }

    function submitDocumentationForm() {
        if (documentation.Id) {
            editDocumentation();
        } else {
            addNewDocumentation();
        }
    }

    async function addNewDocumentation() {
        vm.savingDocumentation = new createSpinner();
        const newDocumentation = {
            'Date': vm.documentation.Date,
            'Name': vm.documentation.Name,
            'TypeId': vm.documentation.Type.Id,
            'AuthorizedById': vm.documentation.AuthorizedBy ? vm.documentation.AuthorizedBy.Id : '',
            'Notes': vm.documentation.Notes
        };

        try {
            await nsAtDocumentationService.createDocumentation(newDocumentation);
            await helperService.successfulSaveButton(vm.savingDocumentation);
            $uibModalInstance.close();
        } catch {
            return;
        }
    }

    async function editDocumentation() {
        vm.savingDocumentation = new createSpinner();
        const updatedDocumentation = {
            'Id': vm.documentation.Id,
            'Date': vm.documentation.Date,
            'Name': vm.documentation.Name,
            'TypeId': vm.documentation.Type.Id,
            'AuthorizedById': vm.documentation.AuthorizedBy ? vm.documentation.AuthorizedBy.Id : '',
            'Notes': vm.documentation.Notes
        };

        try {
            await nsAtDocumentationService.updateDocumentation(updatedDocumentation);
            await helperService.successfulSaveButton(vm.savingDocumentation);
            $uibModalInstance.close();
        } catch {
            return;
        }
    }

    function hasExistingValueChanged() {
        if (vm.origDocumentation) {
            var currentDocumentation = angular.copy(vm.documentation);
            currentDocumentation.Type = vm.documentation.Type.Name;
            if (vm.documentation.AuthorizedBy && vm.documentation.AuthorizedBy.Name) {
                currentDocumentation.AuthorizedBy = vm.documentation.AuthorizedBy.Name;
            } else {
                currentDocumentation.AuthorizedBy = null;
            }

            return !angular.equals(vm.origDocumentation, currentDocumentation);
        } else {
            return true;
        }
    }

    function openDate() {
        vm.datePopup.opened = true;
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}