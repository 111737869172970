export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getRiskLevels,
        createRiskLevel,
        renameRiskLevel,
        deleteRiskLevel
    };

    return service;

    async function getRiskLevels() {
        try {
            return await $http.get(`${apiUrl}api/arm/risklevels`);
        } catch (err) {
            showError(err);
        }
    }

    async function createRiskLevel(postData) {
        try {
            return await $http.post(`${apiUrl}api/arm/risklevels`, postData);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function renameRiskLevel(putData) {
        try {
            return await $http.put(`${apiUrl}api/arm/risklevels/${putData.Id}`, putData);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteRiskLevel(riskLevelId) {
        try {
            return await $http.delete(`${apiUrl}api/arm/risklevels/${riskLevelId}`);
        } catch (err) {
            showError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}