import swal from "sweetalert2";
import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (allowLocalAccounts, $filter, $uibModalInstance, $http, helperService, configurationDataService, usersDataService, errorsDataService) {
    const vm = this;

    vm.user = {};
    vm.helperService = helperService;

    vm.cancel = cancel;
    vm.addNewUser = addNewUser;

    activate();

    function activate() {
        vm.needsProvider = null;
        getIdentityProviders();
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }

    async function getIdentityProviders() {
        let response = await configurationDataService.getIdentityProviders();
        response.data = $filter('orderBy')(response.data, 'Name');
        if (allowLocalAccounts !== "1") {
            response.data = response.data.filter(provider => provider.Protocol !== 'Local');
        }
        vm.providersList = response.data;
    }

    async function addNewUser() {
        vm.disableButton = new createSpinner();

        if (vm.needsProvider === null) {
            // we need to check the email for a matching existing account
            try {
                const accountExists = await checkEmail(vm.user.loginEmail);
                if (accountExists.data === true) {
                    const existingUser = await getAccount(vm.user.loginEmail);
                    vm.user.email = existingUser.data.EmailAddress;
                    const existingProvider = vm.providersList.find(provider => provider.Name === existingUser.data.IdentityProvider)
                    if (existingProvider) {
                        vm.user.type = existingProvider.Id;
                    } else {
                        showInvalidIdpMessage(existingUser.data.IdentityProvider);
                        vm.needsProvider = null;
                        vm.disableButton.loadingValue = false;
                        return;
                    }
                    vm.needsProvider = false;
                }
                else {
                    vm.needsProvider = true;
                    vm.user.email = vm.user.loginEmail;
                    vm.disableButton.loadingValue = false;
                    return;
                }

            } catch (error) {
                errorsDataService.catch(error);
                vm.disableButton.loadingValue = false;
                return;
            } 
        }

        const data = {
            FirstName: vm.user.firstName,
            LastName: vm.user.lastName,
            Email: vm.user.email,
            IdentityProviderId: vm.user.type,
            IdentityUserId: vm.user.loginEmail
        };

        try {
            const response = await usersDataService.createUser(data);
            await helperService.successfulSaveButton(vm.disableButton);
            $uibModalInstance.close(response.data);
        } catch (error) {
            if (error.status === 400 && error.data.includes('already exists')) {
                vm.needsProvider = null;
            }
            vm.disableButton.loadingValue = false;
        }
    };

    async function showInvalidIdpMessage(identityProvider) {
        await swal({
            title: "Identity Provider Not Found",
            text: `The Identity Provider ${identityProvider} has not been associated with this tenant. Please contact support to add the ${identityProvider} Identity Provider to this tenant.`,
            type: "warning",
            showCancelButton: false,
            confirmButtonText: "Close",
            reverseButtons: false,
            showCloseButton: true
        });
    }

    async function checkEmail(email) {
        return await $http.get(`${apiUrl}api/auth/accounts/search`, { params: { 'email': email } });
    }

    async function getAccount(email) {
        return await $http.get(`${apiUrl}api/auth/accounts`, { params: { 'email': email } });
    }

}