import createSpinner from "../Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, errorsDataService, response, $http) {

    const vm = this;

    vm.response = response;
    vm.saveResponse = saveResponse;
    vm.dismiss = dismiss;
    vm.enableSave = enableSave;

    vm.questionChoicesList = [];

    vm.questionChoicesList = response.Choices;


    vm.disableSave = true;

    function enableSave() {
        if (vm.answer) {
            vm.disableSave = false;
        }
    }

    async function saveResponse() {
        vm.saving = new createSpinner();

        let postBody = {
            "QuestionId": response.QuestionId,
            "Answer": vm.answer
        };

        vm.saving.loadingValue = true;
        vm.disableSave = true;

        try {
            await $http.post(`${apiUrl}/api/core/npsquestions/${response.QuestionId}/answer`, postBody);
            vm.saving.loadingValue = false;
            cancel();
        } catch (err) {
            vm.saving.loadingValue = false;
            cancel();
            showError(err);
            sendError(err);
        }
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }

    function cancel() {
        $uibModalInstance.close();
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}