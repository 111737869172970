import { Modal } from "bootstrap";
import createSpinner from "../Functions/createSpinner";

export default /*@ngInject*/ function ($scope, $rootScope, $uibModalInstance, authenticationService, helperService, $http, $filter, $route) {

    var modalVm = $scope.modalVm = {};

    modalVm.validDate = validDate;

    modalVm.frequencies = ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly'];

    modalVm.daysInWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    modalVm.daysInMonth = helperService.getDayOfMonthDropdownOptions();

    $scope.dropdownTypeOptions = [
        { text: "CSV", value: "Csv" },
        { text: "Excel", value: "Excel" },
        { text: "PDF", value: "PDF" }
    ];

    activate();

    function populateModels() {

        if ($rootScope.scheduleToEdit) {
            //Edit Schedule
            modalVm.modalInEditMode = true;
            var scheduleToEdit = $rootScope.scheduleToEdit;
            delete $rootScope.scheduleToEdit;

            $http.get(apiUrl + 'api/core/reportschedules/' + scheduleToEdit).then(function successCallback(response) {
                var data = angular.copy(response.data);
                modalVm.scheduleId = data.ID;
                modalVm.requireSignature = data.RequireSignature;
                modalVm.description = data.Description;
                $http.get(apiUrl + 'api/core/savedreports/' + data.SavedReportID).then(function (reportResponse) {
                    var savedReportData = [];
                    savedReportData.push(reportResponse.data);

                    modalVm.savedReportsDataSource = savedReportData;
                    modalVm.selectedSavedReport = modalVm.savedReportsDataSource[0];
                    modalVm.selectedFreq = data.Frequency;
                    modalVm.zeroRowsCheckbox = data.SendReportWithZeroRows;
                    modalVm.selectedDayOfWeek = modalVm.daysInWeek.find(function (days) { return days === data.DayOfWeek; });

                    modalVm.selectedDayOfMonth = modalVm.daysInMonth.find(function (days) { return days.Value === data.DayOfMonth; });

                    modalVm.selectedExcelUsers = [];
                    modalVm.selectedPDFUsers = [];
                    modalVm.selectedCSVUsers = [];


                    if (data.StartTime) {
                        modalVm.startDateString = new Date(data.StartTime);
                    }

                    if (data.EndTime) {
                        modalVm.endDateString = new Date(data.EndTime);
                    }

                    data.Outputs.forEach(function (row) {
                        if (row.Format === 'Excel') {
                            const matchedUser = modalVm.availableUsers.find(user => user.UserID === row.UserID);
                            if (matchedUser) {
                                modalVm.selectedExcelUsers.push(matchedUser);
                            }
                        }
                        if (row.Format === 'PDF') {
                            const matchedUser = modalVm.availableUsers.find(user => user.UserID === row.UserID);
                            if (matchedUser) {
                                modalVm.selectedPDFUsers.push(matchedUser);
                            }
                        }
                        if (row.Format === 'Csv') {
                            const matchedUser = modalVm.availableUsers.find(user => user.UserID === row.UserID);
                            if (matchedUser) {
                                modalVm.selectedCSVUsers.push(matchedUser);
                            }
                        }
                    });
                })
            });

        } else if ($rootScope.savedReportToSchedule) {
            //Add Schedule to pre-selected Saved Report
            modalVm.modalHasPreSelectedSavedReport = true;
            var savedReportToSchedule = $rootScope.savedReportToSchedule;
            delete $rootScope.savedReportToSchedule;

            modalVm.selectedSavedReport = modalVm.savedReportsDataSource.filter(e => e.Id === savedReportToSchedule)[0];
            modalVm.requireSignature = false;
            modalVm.startDateString = new Date();
            modalVm.endDatePicker = null;
            modalVm.zeroRowsCheckbox = false;
            modalVm.selectedDayOfWeek = null;
            modalVm.selectedDayOfMonth = null;
            modalVm.selectedPDFUsers = [];
            modalVm.selectedExcelUsers = [];
            modalVm.selectedCSVUsers = [];
            modalVm.description = null;

        } else {
            //New Schedule
            modalVm.requireSignature = false;
            modalVm.startDateString = new Date();
            modalVm.endDatePicker = null;
            modalVm.zeroRowsCheckbox = false;
            modalVm.selectedDayOfWeek = null;
            modalVm.selectedDayOfMonth = null;
            modalVm.selectedPDFUsers = [];
            modalVm.selectedExcelUsers = [];
            modalVm.selectedCSVUsers = [];
            modalVm.description = null;
        }
    }

    async function activate() {
        let response = await $http.get(apiUrl + 'api/core/users');
        response.data = $filter('orderBy')(response.data, 'UserLogonName');
        response.data = $filter('filter')(response.data, { Active: true });
        modalVm.availableUsers = response.data;

        if ($rootScope.scheduleToEdit) {
            populateModels();
        }
        else {
            const currentUser = await authenticationService.getUser();
            //Populate Saved Report dropdown list
            $http.get(`${apiUrl}api/core/savedreports/users/${currentUser.userId}`).then(function (response) {
                response.data = $filter('orderBy')(response.data, 'Name');
                modalVm.savedReportsDataSource = response.data;
                populateModels();
            });
        }
    }

    $scope.cleanCriteriaForSchedule = function () {
        var criteria = {};
        criteria.outputs = [];

        modalVm.selectedExcelUsers.forEach(function (row) {
            criteria.outputs.push({
                "DeliveryType": "Email",
                "Format": "Excel",
                "ScheduleID": null,
                "UserID": row.UserID
            });
        });

        modalVm.selectedPDFUsers.forEach(function (row) {
            criteria.outputs.push({
                "DeliveryType": "Email",
                "Format": "PDF",
                "ScheduleID": null,
                "UserID": row.UserID
            });
        });

        modalVm.selectedCSVUsers.forEach(function (row) {
            criteria.outputs.push({
                "DeliveryType": "Email",
                "Format": "Csv",
                "ScheduleID": null,
                "UserID": row.UserID
            });
        });

        criteria.DayOfMonths = [];
        criteria.Days = [];
        criteria.Months = [];

        if (modalVm.selectedDayOfMonth && modalVm.selectedFreq === 'Monthly') {
            criteria.DayOfMonths = [modalVm.selectedDayOfMonth.Value];
        }
        if (modalVm.selectedDayOfWeek && modalVm.selectedFreq === 'Weekly') {
            criteria.Days = [modalVm.selectedDayOfWeek];
        }

        return criteria;
    };

    $scope.scheduleReport = function () {
        var criteria = $scope.cleanCriteriaForSchedule();

        const postData = {
            "SavedReportID": modalVm.selectedSavedReport.Id,
            "DayOfMonth": criteria.DayOfMonths[0],
            "DayOfWeek": criteria.Days[0],
            "Frequency": modalVm.selectedFreq,
            "StartTime": modalVm.startDateString,
            "EndTime": modalVm.endDateString,
            "TimeZone": $rootScope.applicationTimeZone,
            "RequireSignature": modalVm.requireSignature,
            "SendReportWithZeroRows": modalVm.zeroRowsCheckbox,
            "Outputs": criteria.outputs,
            "Description": modalVm.description
        };

        modalVm.disableButton = new createSpinner();

        $http.post(apiUrl + 'api/core/reportschedules', postData).then(async function (response) {
            modalVm.killModal = true;
            await helperService.successfulSaveButton(modalVm.disableButton).then(function (result) {
                if (!modalVm.modalHasPreSelectedSavedReport) {
                    $route.reload();
                }
                $uibModalInstance.close();
            });

        }, function (response) {
            modalVm.disableButton.loadingValue = false;
            helperService.showErrorMessage(response.data);
        });
    };

    $scope.updateScheduleReport = function () {
        var criteria = $scope.cleanCriteriaForSchedule();

        const putData = {
            "ID": modalVm.scheduleId,
            "Outputs": criteria.outputs,
            "DayOfMonth": criteria.DayOfMonths[0],
            "DayOfWeek": criteria.Days[0],
            "Frequency": modalVm.selectedFreq,
            "StartTime": modalVm.startDateString,
            "EndTime": modalVm.endDateString,
            "TimeZone": $rootScope.applicationTimeZone, 
            "SendReportWithZeroRows": modalVm.zeroRowsCheckbox,
            "RequireSignature": modalVm.requireSignature,
            "Description": modalVm.description
        };

        modalVm.disableButton = new createSpinner();

        $http.put(apiUrl + 'api/core/reportschedules/' + modalVm.scheduleId, putData).then(async function (response) {
            modalVm.killModal = true;
            await helperService.successfulSaveButton(modalVm.disableButton).then(function (result) {
                $uibModalInstance.close();
                $route.reload();
            });

        }, function (response) {
            modalVm.disableButton.loadingValue = false;
            helperService.showErrorMessage(response.data);
        });
    };

    function validDate(date) {
        if (angular.isDate(date)) return true;
        else return false;
    }

    modalVm.cancel = function () {
        $uibModalInstance.close();
    };

}