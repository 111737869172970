export default /*@ngInject*/ function (helperService) {
    const factory = {
        generateBody
    };

    return factory;

    function generateBody(companies, ruleset, businessProcesses, objectId, products, securityObject, users, reportId) {

        var companiesSelected = helperService.returnNullIfAllIsFound(companies.map(function (el) { return el.ID; }));
        var businessProcessesSelected = helperService.returnNullIfAllIsFound(businessProcesses.map(function (el) { return el.Id; }));

        if (businessProcessesSelected) {
            businessProcessesSelected = businessProcesses.map(bp => bp.Id);
        }

        var data = {
            "CompanyMasterIds": companiesSelected,
            "RulesetId": ruleset.RulesetId,
            "BusinessProcessIds": businessProcessesSelected,
            "ObjectId": objectId,
            "ProductIds": products.map(function (el) { return el.ID; }),
            "SecurityObjects": [securityObject.Identifier],
            "UserMasterIds": users.length ? users : null,
            "ReportId": reportId
        };

        if (securityObject.Id === null) {
            data.SecurityObjects = null;
        }

        return data;
    }

}