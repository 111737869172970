export default /*@ngInject*/ function ($q, RequestManagementAADService) {

    this.loadAAD = function ($scope) {
        var adDfrd = $.Deferred();

        loadAADPermissions($scope).done(function () {
            adDfrd.resolve();
        });

        return $.when(adDfrd).done(function () {
        }).promise();
    };

    this.loadAADLoadPermisssions = function ($scope) {
        return loadAADPermissions($scope);
    };
    //Load the AAD permissions, after that completes and if there is an existing AAD user load their permissions
    function loadAADPermissions($scope) {
        let permissionsDfrd = $.Deferred(),
            promises = [],
            aadRoles = [],
            aadGroups = [],
            aadLicenses = [],
            pendingRoles = $scope.vm.User?.AadUser?.PendingUserRoles || [],
            pendingGroups = $scope.vm.User?.AadUser?.PendingUserGroups || [],
            pendingLicenses = $scope.vm.User?.AadUser?.PendingLicenses || [];

        $scope.vm.ListOfSelectedAADRoles = [];
        $scope.vm.ListOfAvailableAADRoles = [];
        $scope.vm.ListOfSelectedAADGroups = [];
        $scope.vm.ListOfAvailableAADGroups = [];
        $scope.vm.ListOfSelectedAADLicenses = [];
        $scope.vm.ListOfAvailableAADLicenses = [];

        if ($scope.vm.User.AadUser != null) {
            aadRoles = $scope.vm.User.AadUser.UserRoles || [];
            aadGroups = $scope.vm.User.AadUser.UserGroups || [];
            aadLicenses = $scope.vm.User.AadUser.AssignedLicenses || [];
        }

        //Load Available aadRoles then process existing security
        promises.push(RequestManagementAADService.getAllAADRoles().then(function (response) {
            $scope.vm.ListOfAvailableAADRoles = response.data.map(applyPendingByKey.bind(null, pendingRoles, "Id"));
        }, function errorCallback(response) {
            permissionsDfrd.reject();
        }));

        promises.push(RequestManagementAADService.getAllAADGroups().then(function (response) {
            $scope.vm.ListOfAvailableAADGroups = response.data.map(applyPendingByKey.bind(null, pendingGroups, "Id"));
        }, function errorCallback(response) {
            permissionsDfrd.reject();
        }));

        promises.push(RequestManagementAADService.getAllAADLicenses().then(function (response) {
            $scope.vm.ListOfAvailableAADLicenses = response.data.map(applyPendingByKey.bind(null, pendingLicenses, "SkuId"));
        }, function errorCallback(response) {
            permissionsDfrd.reject();
        }));

        $q.all(promises).then(function () {
            if ($scope.vm.User === null) {
                $scope.vm.User = {};
            }
            if ($scope.vm.User.AadUser === null) {
                $scope.vm.User.AadUser = {};
            }

            //Modify Available and Selected roles lists to reflect users current security
            if (aadRoles != null && $scope.vm.ListOfAvailableAADRoles != null) {
                aadRoles.forEach(role => {
                    if ($scope.vm.ListOfSelectedAADRoles.none(selectedRole => selectedRole.Id === role.Id)) {

                        role.Pending = pendingRoles.some(pendingRole => pendingRole.Id === role.Id);

                        $scope.vm.ListOfSelectedAADRoles.unshift(role);

                        $scope.vm.ListOfAvailableAADRoles.forEach((row, j) => {
                            if (row.Id === role.Id) {
                                $scope.vm.ListOfAvailableAADRoles.splice(j, 1);
                            }
                        });
                    }
                });
            }

            //Modify Available and Selected group lists to reflect users current security
            if (aadGroups != null && $scope.vm.ListOfAvailableAADGroups != null) {
                if ($scope.defaultRoleTemplate && $scope.vm.wizardMode === "New" && !$scope.vm.requestToEdit) {
                    let defaultRoles = $scope.defaultRoleTemplate.Roles.filter(role => role.TypeId === "Group" && role.ProductType === "AAD");

                    $scope.vm.ListOfSelectedAADGroups = [...defaultRoles];
                    $scope.vm.ListOfAvailableAADGroups = $scope.vm.ListOfAvailableAADGroups.filter(availableGroup => {
                        let selectedGroup = $scope.vm.ListOfSelectedAADGroups.find(selectedGroup => selectedGroup.Id === availableGroup.Id);

                        if (selectedGroup) {
                            selectedGroup = Object.merge(selectedGroup, availableGroup);
                        }

                        return !selectedGroup;
                    });
                } else {
                    aadGroups.forEach(group => {
                        if ($scope.vm.ListOfSelectedAADGroups.none(selectedGroup => selectedGroup.Id === group.Id)) {

                            group.Pending = pendingGroups.some(pendingGroup => pendingGroup.Id === group.Id);

                            $scope.vm.ListOfSelectedAADGroups.unshift(group);

                            $scope.vm.ListOfAvailableAADGroups.forEach((row, j) => {
                                if (row.Id === group.Id) {
                                    $scope.vm.ListOfAvailableAADGroups.splice(j, 1);
                                }
                            });
                        }
                    });
                }
            }

            //Modify Available and Selected license lists to refect users current security
            if (aadLicenses != null && $scope.vm.ListOfAvailableAADLicenses != null) {
                aadLicenses.forEach(license => {
                    if ($scope.vm.ListOfSelectedAADLicenses.none(selectedLicense => selectedLicense.SkuId === license.SkuId)) {

                        license.Pending = pendingLicenses.some(pendingLicense => pendingLicense.SkuId === license.SkuId);

                        $scope.vm.ListOfSelectedAADLicenses.unshift(license);

                        $scope.vm.ListOfAvailableAADLicenses.forEach((row, j) => {
                            if (row.SkuId === license.SkuId) {
                                $scope.vm.ListOfAvailableAADLicenses.splice(j, 1);
                            }
                        });
                    }
                });
            }

            $scope.aadUserLoaded = true;
            permissionsDfrd.resolve();

        });

        return $.when(permissionsDfrd).done(function () {
        }).promise();
    }
}

function applyPendingByKey(pendingItems, key, item) {
    if (pendingItems.length) {
        item.Pending = pendingItems.find(pendingItem => pendingItem[key] === item[key]);
    }

    return item;
}