export default /*@ngInject*/ function ($scope) {

    //If $scope variable doesnt exist in child it looks in parent

    $scope.addRoleToList = function (role) {
        $scope.vm.ListOfAvailableOracleRoles.forEach(function (row, i) {
            if (row.Name === role.Name) {
                $scope.vm.ListOfAvailableOracleRoles.splice(i, 1);
                $scope.vm.ListOfSelectedOracleRoles.push(row);
            }
        });
        $scope.vm.IsRisksReviewed = null;
        $scope.vm.validateNumberOfOracleResponsibilities();
    };

    $scope.deleteSelectedRole = function (role) {
        $scope.vm.ListOfSelectedOracleRoles.forEach(function (row, i) {
            if (row.Name === role.Name) {
                $scope.vm.ListOfSelectedOracleRoles.splice(i, 1);
                if (role.IsAssignable) {
                    $scope.vm.ListOfAvailableOracleRoles.push(row);
                }
            }
        });
        $scope.vm.IsRisksReviewed = null;
        $scope.vm.validateNumberOfOracleResponsibilities();
    };
}