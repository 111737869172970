export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getRoleById,
        addAssignments,
        removeAssignments
    };

    return service;

    async function getRoleById(roleId) {
        try {
            return await $http.get(`${apiUrl}api/core/users/role/${roleId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addAssignments(data) {
        try {
            return await $http.post(`${apiUrl}api/core/userroles`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function removeAssignments(data) {
        try {
            return await $http.delete(`${apiUrl}api/core/userroles`, { data: data, headers: { 'Content-Type': 'application/json;charset=utf-8' } });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }
}