import { customAutoCompleteFilter, customBooleanFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../../Shared/kendo.module";

export default /*@ngInject*/ function (helperService, $http, dataService, reportsDataService) {

    const vm = this;
    vm.$onInit = activate;
    vm.selectUser = selectUser;
    vm.helperService = helperService;
    

    async function activate() {
        // making metadata call so report is up to date
        await reportsDataService.getReportMetadata(reportId);
        setupExpansionGrid();
    }

    
    const reportId = 'e5f3c3b7-2b49-4192-bddb-17ff914682d6';

    async function setupExpansionGrid() {
        vm.expansionGridColumns = [
            { field: "FastpathUserName", title: "Fastpath User Name", template: `<user-profile user-display="dataItem.FastpathUserName" user-master-id="dataItem._hidden_UserMasterID"></user-profile>`, filterable: customAutoCompleteFilter, width: 125 },
            { field: "FastpathEmail", title: "Fastpath Email", filterable: customAutoCompleteFilter, width: 100 },
            { field: "UserCrossPlatformMapping", title: "Cross Platform User", filterable: customBooleanFilter, width: 100 }
        ];

        let {data: availableProducts } = await dataService.getProducts();

        availableProducts.forEach(product => {
            const productName = helperService.removeSpaces(product.Name)
            vm.expansionGridColumns = vm.expansionGridColumns.concat([
                { field: productName, title: product.Name, width: 75, },
                { field: productName + "UserId", title: product.Name + " ID", width: 100, },
                { field: productName + "UserName", title: product.Name + " User Name", width: 130, },
                { field: productName + "Email", title: product.Name + " Email", width: 125, }
            ]);
        });


        // Table data setup
        const fields = { UserCrossPlatformMapping: { type: "boolean" } };
        availableProducts.forEach(product => {
            fields[helperService.removeSpaces(product.Name)] = { type: "boolean" }
        });
       

        vm.ExistingUsersGridOptions = helperService.setMainGridOptions(vm.expansionGridColumns, 350);
        vm.ExistingUsersGridOptions.selectable = true;
        vm.ExistingUsersGridOptions.persistSelection = true;
        vm.ExistingUsersGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            schema: angular.extend(halSchemaKendoGrid, {
                model: {
                    id: '_hidden_UserMasterID',
                    fields: fields
                }
            }),
            transport: {
                read: async options => {
                    const params = {
                        "pagesize": options.data.pageSize,
                        "page": options.data.page
                    }, sortsAndFilters = helperService.prepareSortsAndFilters(options);
                    try {
                        let { data } = await $http.post(`${apiUrl}api/core/reports/${reportId}/execute`, sortsAndFilters, { params: params });
                        options.success(data);
                    } catch (error) {
                        options.error();
                        helperService.showErrorMessage(error.data);
                    }
                }
            }
        });
    }

    function selectUser(kendoEvent) {
        let grid = kendoEvent.sender;
        let linkedUser = grid.dataItem(grid.select());
        vm.linkedUserChange({ linkedUser });
    }
}