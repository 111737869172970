import angular from 'angular';

import '@progress/kendo-ui/js/kendo.dropdownlist';
import '@progress/kendo-ui/js/kendo.grid';
import '@progress/kendo-ui/js/kendo.filtercell';
import '@progress/kendo-ui/js/kendo.multiselect';
import '@progress/kendo-ui/js/kendo.datetimepicker';
import '@progress/kendo-ui/js/kendo.tooltip';
import '@progress/kendo-ui/js/kendo.angular';


export const halSchemaKendoGrid = { data: "_embedded.reportData", total: "TotalItems" };

export default angular
    .module('app.shared.kendo', [
        'kendo.directives'
    ])
    .name;