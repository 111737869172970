export default /*@ngInject*/ function ($scope) {

    $scope.addTeamToList = addTeamToList;
    $scope.deleteSelectedTeam = deleteSelectedTeam;

    function addTeamToList(team) {
        $scope.vm.ListOfAvailableD365STeams.forEach(function (row, i) {
            if (row.Id === team.Id) {
                $scope.vm.ListOfAvailableD365STeams.splice(i, 1);
                $scope.vm.ListOfSelectedD365STeams.push(row);
            }
        });
        $scope.vm.IsRisksReviewed = null;
        validateNumberOfD365STeams();
    }

    function deleteSelectedTeam(team) {
        $scope.vm.ListOfSelectedD365STeams.forEach(function (row, i) {
            if (row.Id === team.Id) {
                $scope.vm.ListOfSelectedD365STeams.splice(i, 1);
                $scope.vm.ListOfAvailableD365STeams.push(row);
            }
        });
        $scope.vm.IsRisksReviewed = null;
        validateNumberOfD365STeams();
    }

    function validateNumberOfD365STeams() {
        $scope.vm.numberOf365STeamsValid = null;
        if ($scope.vm.ListOfSelectedD365STeams.length > 0) {
            $scope.vm.numberOf365STeamsValid = true;
        }
    }
}