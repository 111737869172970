import { standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($rootScope, $filter, $uibModalInstance, helperService, azureADUserObjectValueMapperService, existingUserSearchService, userObject) {

    const vm = this;

    vm.title = 'Active Directory User Search';
    vm.filterUsersToggle = true;
    vm.selectedUser = null;
    vm.adTypeConfigValue = $rootScope.ConfigurationValues.AX7_UseAzureAD === '1' || $rootScope.ConfigurationValues.D365S_UseAzureAD === '1' ? true : false;

    vm.doneSearchingUsers = doneSearchingUsers;
    vm.changeExistingUserRowSelected = changeExistingUserRowSelected;
    vm.selectUser = selectUser;
    vm.filterUsers = filterUsers;
    vm.cancel = cancel;

    if (!userObject) {
        userObject = {};
    }

    function getExistingUserGridColumns() {
        if (vm.adTypeConfigValue) {
            return [
                { field: "DisplayName", title: "User Name", filterable: false },
                { field: "GivenName", title: "Given Name", filterable: false },
                { field: "Mail", title: "Email", filterable: false },
                { field: "JobTitle", title: "Job Title", filterable: false },
                { field: "UserPrincipalName", title: "User Principal Name", filterable: false }
            ];
        } else {
            return [
                { field: "DisplayName", title: "User Name", filterable: false },
                { field: "Mail", title: "Email", filterable: false }
            ];
        }
    }

    function doneSearchingUsers() {

        $rootScope.IMSelectedExistingUser = null;

        if (vm.ExistingWindowsUserSearchString === '' || vm.ExistingWindowsUserSearchString === null) {

            var raw = vm.ExistingusersGridDataSource.data();
            var length = raw.length;

            let item, i;
            for (i = length - 1; i >= 0; i--) {

                item = raw[i];
                vm.ExistingusersGridDataSource.remove(item);

            }
        } else {
            vm.ExistingusersGridDataSource.read();
        }
    }

    vm.ExistingUsersGridOptions = helperService.setMainGridOptions(getExistingUserGridColumns(), 350);
    vm.ExistingUsersGridOptions.autoBind = false;

    vm.ExistingusersGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: async (options) => {
                let response;
                try {
                    if (vm.adTypeConfigValue) {
                        response = await existingUserSearchService.getFilteredAzureADUsers(vm.ExistingWindowsUserSearchString, vm.filterUsersToggle);
                    } else {
                        response = await existingUserSearchService.getFilteredADUsers(vm.ExistingWindowsUserSearchString, vm.filterUsersToggle);
                    }
                    response.data = $filter('orderBy')(response.data, 'DisplayName');
                    options.success(response.data);
                } catch (err) {
                    options.error();
                }
            }
        }
    });

    function changeExistingUserRowSelected(kendoEvent) {
        let grid = kendoEvent.sender;
        vm.selectedUser = grid.dataItem(grid.select());
    }

    function selectUser() {
        vm.selectedUser.ProductType = userObject.ProductType; // Add productType for value mapper to map properly
        if (vm.selectedUser) {
            userObject = Object.assign(userObject, vm.selectedUser);
            userObject = azureADUserObjectValueMapperService.valueMap(userObject);
        }
        if (vm.selectedUser.UserType === 'Guest') {
            let emailFromPrincipalName = vm.selectedUser.UserPrincipalName.split("#EXT#")[0];
            const underscoreIndex = emailFromPrincipalName.lastIndexOf("_");
            userObject.Email = emailFromPrincipalName.substr(0, underscoreIndex) + '@' + emailFromPrincipalName.substr(underscoreIndex + 1);
            userObject.NetworkDomain = `https://sts.windows.net/${userObject.Email.slice(userObject.Email.indexOf('@')).slice(1)}`;
        }
        vm.ExistingWindowsUserSearchString = null;
        $uibModalInstance.close(userObject);
    }

    function filterUsers() {
        if (vm.ExistingusersGridDataSource._data.length > 0) {
            vm.ExistingusersGridDataSource.read();
        }
    }

    function cancel() { $uibModalInstance.dismiss(); }

}