import createSpinner from "../../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $filter, simulation, actionType, role, existingRoles, helperService, sapSdSimRoleDataService) {
    const vm = this;

    vm.actionType = actionType;

    vm.changeRowSelected = changeRowSelected;
    vm.saveRole = saveRole;
    vm.cancel = cancel;

    vm.role = { ...role };

    activate();

    function activate() {
        if (actionType !== 'Add') {
            setupAvailableRolesGrid();
        }
    }

    function setupAvailableRolesGrid() {
        let availableRolesGridColumns = [
            { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
        ];

        vm.availableRolesGridOptions = helperService.setMainGridOptions(availableRolesGridColumns, null);

        vm.availableRolesGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let params;
                    if (vm.actionType === 'Edit') {
                        params = {
                            IsEditable: true
                        };
                    }
                    let response = await sapSdSimRoleDataService.getSimulationRoles(simulation.Id, params);
                    if (vm.actionType === 'Edit') {
                        response.data = response.data.filter(masterRole => !existingRoles.some(role => role.Id == masterRole.Id));
                    }
                    response.data = $filter('orderBy')(response.data, "Name");
                    options.success(response.data);
                }
            }
        });
    }

    function changeRowSelected(dataItem) {
        vm.selectedRole = dataItem.toJSON();
    }

    async function saveRole() {
        vm.savePending = new createSpinner();
        switch (vm.actionType) {
            case 'Add':
                addRole();
                break;
            case 'Edit':
                editRole();
                break;
            case 'EditExisting':
                updateExistingCustomRole();
                break;
            case 'Copy':
                copyRole();
                break;
            default:
                break;
        }
    }

    async function addRole() {
        let data = vm.role;
        data.SimulationId = simulation.Id;

        try {
            let response = await sapSdSimRoleDataService.addRoleToSimulation(data);
            await helperService.successfulSaveButton(vm.savePending);
            close(response.data);
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    async function updateExistingCustomRole() {
        let data = {
            "SimulationId": simulation.Id,
            "RoleId": vm.role.Id,
            "Name": vm.role.Name,
            "Description": vm.role.Description,
            "LongDescription": vm.role.LongDescription
        };

        try {
            await sapSdSimRoleDataService.updateSimulationRole(data);
            await helperService.successfulSaveButton(vm.savePending);
            close();
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    async function editRole() {
        let data = {
            SimulationId: simulation.Id,
            Name: vm.selectedRole.Name
        };

        try {
            let response = await sapSdSimRoleDataService.editRoleToSimulation(data);
            await helperService.successfulSaveButton(vm.savePending);
            close(response.data);
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    async function copyRole() {
        let data = {
            SimulationId: simulation.Id,
            ExistingRoleName: vm.selectedRole.Name,
            NewRoleName: vm.role.Name
        };

        try {
            let response = await sapSdSimRoleDataService.copyRoleToSimulation(data);
            await helperService.successfulSaveButton(vm.savePending);
            close(response.data);
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function close(roleId) {
        $uibModalInstance.close(roleId);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}