import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, $filter, simulation, helperService, sapSdSimulationDataService, dataService) {
    const vm = this;

    vm.saveSimulation = saveSimulation;
    vm.cancel = cancel;

    vm.simulation = { ...simulation };

    activate();

    function activate() {
        getProducts();
    }

    async function getProducts() {
        let response = await dataService.getProducts();
        response.data = $filter('orderBy')(response.data, 'Name');
        response.data = response.data.filter(product => product.Type === 'SAP');
        if (response.data.length === 1) {
            vm.selectedProduct = response.data[0];
        } else {
            vm.availableProducts = response.data;
        }
    }

    async function saveSimulation() {
        vm.savePending = new createSpinner();
        let data = {
            Name: vm.simulation.Name,
            IsPublic: vm.simulation.IsPublic
        };

        if (!vm.simulation.Id) {
            data['ProductId'] = vm.selectedProduct.ID;
            createSimulation(data);
        } else {
            data['Id'] = vm.simulation.Id;
            data.IsPublic = vm.simulation.IsPublic;
            updateSimulation(data);
        }
    }

    async function createSimulation(data) {
        try {            
            let response = await sapSdSimulationDataService.createSimulation(data);
            await helperService.successfulSaveButton(vm.savePending);
            close(response.data);
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    async function updateSimulation(data) {
        try {
            await sapSdSimulationDataService.updateSimulation(data);
            await helperService.successfulSaveButton(vm.savePending);
            close();
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function close(simulationId) {
        $uibModalInstance.close(simulationId);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}