import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, mainGrid, helperService, disableObjectData) {

    const vm = this;

    vm.cancel = cancel;

    var RisksColumns = [
        { field: "Comparison", width: 100, template: '<span set-style="#:Comparison == \'Added\'? \'D9EAD3\': Comparison == \'Deleted\'? \'F2DEDE\': Comparison == \'Existing\'? \'FCF8E3\': null#">{{dataItem.Comparison}}</span>' },
        { field: "RequestMitigation", title: "Request Mitigation", template: "<span ng-if='!vm.requestAllowed'>{{dataItem.RequestMitigation}}</span><a ng-if='vm.requestAllowed' ng-click='vm.requestRiskMitigation(dataItem)'>{{dataItem.RequestMitigation}}</a>", width: 180, filterable: customAutoCompleteFilter },
        { field: "CompanyName", title: "Company", width: 190, filterable: customAutoCompleteFilter },
        { field: "RiskId", title: "Risk ID", width: 120, filterable: customAutoCompleteFilter, hidden: true },
        { field: "RiskName", title: "Risk Name", width: 190, filterable: customAutoCompleteFilter, groupHeaderTemplate: "#=  value #" },
        { field: "RiskTypeName", title: "Risk Type", width: 190, filterable: customAutoCompleteFilter },
        { field: "BusinessCycle", title: "Business Cycle", width: 130, filterable: customAutoCompleteFilter },
        { field: "RiskLevel", title: "Risk Level", width: 110, filterable: customAutoCompleteFilter },
        { field: "Policy", title: "Policy", width: 110, filterable: customAutoCompleteFilter },
        { field: "RiskDescription", title: "Risk Description", width: 190, filterable: customAutoCompleteFilter },
        { field: "Mitigation", title: "Current Mitigation", width: 190, filterable: customAutoCompleteFilter },
        { field: "MitigationStatus", title: "Current Mitigation Status", width: 130, filterable: customAutoCompleteFilter },
        { field: "RequestMitigationStatusName", title: "Request Mitigation Status", width: 130, filterable: customAutoCompleteFilter },
        { field: "MitigationNotes", title: "Current Mitigation Notes", width: 190, filterable: customAutoCompleteFilter },
        { field: "RequestMitigationNotes", title: "Request Mitigation Notes", width: 190, filterable: customAutoCompleteFilter },
        { field: "MitigationControls", title: "Current Mitigation Controls", width: 190, filterable: customAutoCompleteFilter },
        { field: "RequestMitigationControlNames", title: "Request Mitigation Controls", width: 190, filterable: customAutoCompleteFilter },
        { field: "ProductID", title: "ProductID", width: 150, filterable: customAutoCompleteFilter, hidden: true },
        { field: "SecurityRoleID", title: "Security Role ID", width: 150, filterable: customAutoCompleteFilter, hidden: true },
        { field: "SecurityObjectTypeID", title: "Security ObjectType ID", width: 150, filterable: customAutoCompleteFilter, hidden: true },
        { field: "SecurityObjectID", title: "Security Object ID", width: 150, filterable: customAutoCompleteFilter, hidden: true },
        { field: "AccessTypeID", title: "Access Type ID", width: 150, filterable: customAutoCompleteFilter, hidden: true }
    ];

    vm.RisksOptions = helperService.setMainGridOptions(RisksColumns, null);
    if (!disableObjectData) {
        vm.RisksOptions.detailTemplate = kendo.template($("#risksTemplate").html());
    }
    vm.RisksOptions.selectable = false;
    vm.RisksOptions.columnMenu = true;
    vm.RisksOptions.filterable = {
        mode: "row",
        operators: {
            string: {
                contains: "Contains",
                doesnotcontain: "Does not contain",
                eq: "Equal to",
                neq: "Not equal to"
            }
        }
    };

    vm.RisksDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        data: mainGrid,
        schema: {
            model: {
                fields: {
                    RiskId: { type: "number" }
                }
            }
        },
        pageable: false
    });

    function cancel() { $uibModalInstance.close(); }

}