export default /*@ngInject*/ function (RequestManagementOracleCloudService) {
    //Load Oracle Cloud roles, if an Oracle Cloud user exists move their roles into the selected list
    this.loadOracleCloud = function ($scope) {
        var orDfrd = $.Deferred();

        $scope.vm.ListOfAvailableOracleCloudRolesIsLoading = true;

        RequestManagementOracleCloudService.getOracleCloudRoles().then(response => {
            let pendingRoles = $scope.vm.User?.OracleCloudUser?.PendingRoles || [];

            $scope.vm.ListOfAvailableOracleCloudRoles = [];
            $scope.vm.ListOfSelectedOracleCloudRoles = [];

            $scope.vm.ListOfAvailableOracleCloudRoles = response.data.filter(applyPending.bind(null, pendingRoles));

            $scope.vm.ListOfAvailableOracleCloudRolesIsLoading = false;

            if ($scope.defaultRoleTemplate && $scope.vm.wizardMode === "New" && !$scope.vm.requestToEdit) {
                let defaultRoles = $scope.defaultRoleTemplate.Roles.filter(role => role.TypeId === "Role" && role.ProductType === "ORFC");

                $scope.vm.User.OracleCloudUser.Roles = [...defaultRoles];
            }

            if ($scope.vm.User.OracleCloudUser != null && $scope.vm.User.OracleCloudUser.Roles != null) {
                $scope.vm.User.OracleCloudUser.Roles.forEach(role => {
                    var oracleCloudRole = null;
                    if ($scope.vm.ListOfAvailableOracleCloudRoles != null) {
                        //Get role information from the available roles list
                        oracleCloudRole = $scope.vm.ListOfAvailableOracleCloudRoles.find(object => object.RoleID === role.RoleID);
                        // Add already selected Roles
                        if (oracleCloudRole != null) {
                            $scope.vm.ListOfSelectedOracleCloudRoles.unshift({
                                RoleID: oracleCloudRole.RoleID,
                                Id: oracleCloudRole.Id,
                                IsAssignable: oracleCloudRole.IsAssignable,
                                Identifier: oracleCloudRole.Identifier,
                                RoleName: oracleCloudRole.RoleName,
                                DisplayName: oracleCloudRole.DisplayName,
                                Description: oracleCloudRole.Description,
                                ProductId: oracleCloudRole.ProductId,
                                Pending: pendingRoles.find(pendingRole => pendingRole.RoleID === oracleCloudRole.RoleID)
                            });
                            // Remove them from Available list
                            $scope.vm.ListOfAvailableOracleCloudRoles.forEach(function (row, j) {
                                if (row.RoleID == oracleCloudRole.RoleID) {
                                    $scope.vm.ListOfAvailableOracleCloudRoles.splice(j, 1);
                                }
                            });
                        }

                        oracleCloudRole = null;

                        $scope.vm.numberOfOracleCloudRolesValid = true;
                    }
                });

                $scope.oracleCloudUserLoaded = true;
            }

            $scope.vm.ListOfAvailableOracleCloudRoles = $scope.vm.ListOfAvailableOracleCloudRoles.filter(role => role.IsAssignable);
            orDfrd.resolve();
        });

        return $.when(orDfrd).done(function () {
        }).promise();
    };
}

function applyPending(pendingItems, item) {
    if (pendingItems.length) {
        item.Pending = pendingItems.some(pendingItem => pendingItem.RoleID === item.RoleID);
    }

    return item;
}