import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, taskRecordingDataService, productType) {

    var vm = this;
    vm.productType = productType;

    vm.cancel = cancel;
    vm.importRecording = importRecording;
    vm.filterName = filterName;

    async function importRecording(files) {


        var fd = new FormData();

        if (files.length === 1) {
            fd.append("Name", vm.updatedFileName);
            fd.append("TaskRecordings", files[0].file);
        } else if (files.length > 1) {
            files.forEach(f => {
                // Name is taken care of on the back-end
                fd.append("Name", " ");
                fd.append("TaskRecordings", f.file);
            });
        }

        vm.disableSubmit = true;
        vm.pleaseWait = new createSpinner();

        try {
            let response = await taskRecordingDataService.importTaskRecording(productType, fd);
            vm.killModal = true;
            vm.pleaseWait.loadingValue = false;
            let newTaskRecordingId = response.data;
            await helperService.successfulSaveButton(vm.pleaseWait);
            $uibModalInstance.close(newTaskRecordingId);
        } catch {
            vm.disableSubmit = false;
            vm.pleaseWait.loadingValue = false;
        }

    }

    function filterName(files) {
        // Split the file name at the . and take the first chunk
        vm.updatedFileName = files[0].file.name.split('.')[0];
    }

    function cancel() { $uibModalInstance.dismiss(); }

}