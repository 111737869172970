export default /*@ngInject*/ function ($q, RequestManagementUPService) {
    const vm = this;

    this.loadUP = function ($scope, application) {
        //Load Data for Products after search row is selected
        var upDfrd = $.Deferred();

        try {
            vm.loadPermissions($scope, application).then(function () {
                upDfrd.resolve();
            }, function errorCallback(response) {
                upDfrd.reject(response);
            });
        }
        catch (e) {
            upDfrd.reject(e);
        }

        return $.when(upDfrd).done(function () {
        }).promise();

    };

    this.loadPermissions = function ($scope, application) {
        var permissionsDfrd = $.Deferred(),
            promises = [],
            upRoles = [],
            pendingRoles = $scope.vm.User?.UPUser?.[application.ProductName]?.PendingRoles || [];

        $scope.vm.ListOfSelectedUPRoles[application.ProductName] = [];
        $scope.vm.ListOfAvailableUPRoles[application.ProductName] = [];

        if ($scope.vm.User?.UPUser?.[application.ProductName].ProductName === application.ProductName) {
            //If any upRoles are selected in the request set the upRoles valid property to true below
            if (!$scope.vm.numberOfUPRolesValid) {
                $scope.vm.numberOfUPRolesValid = [];
                $scope.vm.numberOfUPRolesValid[application.ProductName] = null;
            }
            $scope.vm.numberOfUPRolesValid[application.ProductName] = null;
            upRoles = $scope.vm.User.UPUser[application.ProductName].Roles;

            if (upRoles.length > 0) {
                $scope.vm.numberOfUPRolesValid[application.ProductName] = true;
            }
        }

        //Load Available upRoles then process existing security
        promises.push(
            RequestManagementUPService.getAllRoles(application.ProductID).then(function (response) {
                $scope.vm.ListOfAvailableUPRoles[application.ProductName] = response.data.filter(applyPending.bind(null, pendingRoles));
                $scope.vm.ListOfSelectedUPRoles[application.ProductName] = [];
            })
        );

        promises.push(RequestManagementUPService.getAllCompanies(application.ProductID).then(function (response) {
            $scope.vm.ListOfUPCompanies[application.ProductName] = response.data;
        }, function errorCallback(response) {
            permissionsDfrd.reject();
        }));

        $q.all(promises).then(function () {
            if ($scope.defaultRoleTemplate && $scope.vm.wizardMode === "New" && !$scope.vm.requestToEdit) {
                let defaultRoles = $scope.defaultRoleTemplate.Roles.filter(role => role.TypeId === "Role" && role.ProductName === application.ProductName);

                upRoles = [...defaultRoles];

                if (upRoles.length) {
                    $scope.vm.numberOfUPRolesValid = {
                        [application.ProductName]: true
                    };
                }

                $scope.vm.User.UPUser = {
                    [application.ProductName]: {
                        Roles: upRoles.map(role => {
                            role.Role = role;

                            return role;
                        })
                    }
                }
            }
            if (upRoles.length > 0 && $scope.vm.User.UPUser[application.ProductName] && $scope.vm.User.UPUser[application.ProductName].Roles) {
                $scope.vm.User.UPUser[application.ProductName].Roles.forEach(role => {
                    let upRole = null,
                        selectedUpRole = null,
                        selectedCompany = null;

                    if ($scope.vm.ListOfAvailableUPRoles[application.ProductName]) {
                        //Get the role information from the available upRoles list
                        upRole = $scope.vm.ListOfAvailableUPRoles[application.ProductName].find(object => object.Id === role.Role.Id);

                        //Check if the company Id has been set for the current role company
                        if (role.Company != null) {
                            selectedCompany = $scope.vm.ListOfUPCompanies[application.ProductName].find(object => object.CompanyId.toUpperCase() === role.Company.Id.toUpperCase());
                        }

                        //Check if the role already exists in the selected list
                        selectedUpRole = $scope.vm.ListOfSelectedUPRoles[application.ProductName].find(object => object.Id.toUpperCase() === role.Role.Id.toUpperCase());
                        //If the role already exists add the current company information to the company list
                        if (selectedUpRole != null && selectedCompany != null) {
                            if (selectedUpRole.Companies == null) {
                                selectedUpRole.Companies = [];
                            }
                            if (selectedUpRole.Companies.none(company => company.CompanyId === selectedCompany.CompanyId)) {
                                selectedUpRole.Companies.push(selectedCompany);
                            }
                        }

                        if (upRole) {
                            $scope.vm.ListOfSelectedUPRoles[application.ProductName].unshift({
                                Id: upRole.Id,
                                Name: upRole.Name,
                                IsAssignable: upRole.IsAssignable,
                                Identifier: upRole.Identifier,
                                Companies: selectedCompany === null ? null : [selectedCompany],
                                Pending: upRole.Pending
                            });

                            $scope.vm.ListOfAvailableUPRoles[application.ProductName].forEach(function (row, j) {
                                if (row.Id === upRole.Id) {
                                    $scope.vm.ListOfAvailableUPRoles[application.ProductName].splice(j, 1);
                                }
                            });
                        }

                        upRole = null;
                        $scope.vm.numberOfUPRolesValid[application.ProductName] = true;
                    }
                });
            }

            $scope.vm.ListOfAvailableUPRoles[application.ProductName] = $scope.vm.ListOfAvailableUPRoles[application.ProductName].filter(role => role.IsAssignable);
            $scope.upUserLoaded = true;
            permissionsDfrd.resolve();
        });

        return $.when(permissionsDfrd).done(function () {
        }).promise();
    };
}

function applyPending(pendingItems, item) {
    if (pendingItems.length) {
        item.Pending = pendingItems.some(pendingItem => pendingItem.Id === item.Id);
    }
    return item;
}