export default /*@ngInject*/ function (RequestManagementOracleService) {
    //Load Oracle responsibilities, if an Oracle user exists move their roles into the selected list
    this.loadOracle = async function ($scope) {
        var orDfrd = $.Deferred();

        $scope.vm.ORRBACEnabled = JSON.parse(localStorage.getItem('ConfigurationValues'))['OR_RBACEnabled'] === '1' ? true : false;

        RequestManagementOracleService.getOracleResponsibilities().then(function (response) {
            let pendingResponsibilities = $scope.vm.User?.OracleUser?.PendingResponsibilities || [];

            $scope.vm.ListOfAvailableOracleResponsibilities = [];
            $scope.vm.ListOfSelectedOracleResponsibilities = [];

            $scope.vm.ListOfAvailableOracleResponsibilities = response.data.filter(applyPending.bind(null, pendingResponsibilities));

            if ($scope.defaultRoleTemplate && $scope.vm.wizardMode === "New") {
                let defaultResponsibilities = $scope.defaultRoleTemplate.Roles.filter(role => role.TypeId === "Responsibility" && role.ProductType === "OR");

                $scope.vm.User.OracleUser = {
                    Responsibilities: [...defaultResponsibilities]
                }
            }

            if ($scope.vm.User.OracleUser != null && $scope.vm.User.OracleUser.Responsibilities != null) {
                $scope.vm.User.OracleUser.Responsibilities.forEach(responsibility => {
                    var oracleResponsibility = null;
                    if ($scope.vm.ListOfAvailableOracleResponsibilities != null) {
                        //Get the responsibility information from the available oracleResponsibilities list
                        oracleResponsibility = $scope.vm.ListOfAvailableOracleResponsibilities.find(object => object.Id === responsibility.Id && object.ApplicationId === responsibility.ApplicationId);

                        if (oracleResponsibility != null) {
                            $scope.vm.ListOfSelectedOracleResponsibilities.unshift({
                                Id: oracleResponsibility.Id,
                                Name: oracleResponsibility.Name,
                                IsAssignable: oracleResponsibility.IsAssignable,
                                Identifier: oracleResponsibility.Identifier,
                                ApplicationId: oracleResponsibility.ApplicationId,
                                ApplicationShortName: oracleResponsibility.ApplicationShortName,
                                Key: oracleResponsibility.Key,
                                Pending: pendingResponsibilities.find(pendingResponsibility => pendingResponsibility.Id === oracleResponsibility.Id)
                            });

                            $scope.vm.ListOfAvailableOracleResponsibilities.forEach(function (row, j) {
                                if (row.Id == oracleResponsibility.Id) {
                                    $scope.vm.ListOfAvailableOracleResponsibilities.splice(j, 1);
                                }
                            });
                        }

                        oracleResponsibility = null;

                        $scope.vm.numberOfOracleResponsibilitiesValid = true;
                    }
                });

                $scope.oracleUserLoaded = true;
            }

            $scope.vm.ListOfAvailableOracleResponsibilities = $scope.vm.ListOfAvailableOracleResponsibilities.filter(responsibility => responsibility.IsAssignable);
            orDfrd.resolve();
        });

        if ($scope.vm.ORRBACEnabled) {
            RequestManagementOracleService.getOracleRoles().then(function (response) {
                let pendingRoles = $scope.vm.User?.OracleUser?.PendingRoles || [];

                $scope.vm.ListOfAvailableOracleRoles = response.data.filter(applyPendingByName.bind(null, pendingRoles));

                if ($scope.vm.User.OracleUser != null && $scope.vm.User.OracleUser.Roles != null) {
                    $scope.vm.User.OracleUser.Roles.forEach(function (role) {
                        var oracleRole = null;
                        if ($scope.vm.ListOfAvailableOracleRoles != null) {
                            //Get the role information from the available oracleRoles list
                            oracleRole = $scope.vm.ListOfAvailableOracleRoles.find(object => object.Name === role.Name);

                            if (oracleRole) {
                                $scope.vm.ListOfSelectedOracleRoles.unshift({
                                    DisplayName: oracleRole.DisplayName,
                                    IsAssignable: oracleRole.IsAssignable,
                                    Name: oracleRole.Name,
                                    ProductId: oracleRole.ProductId,
                                    Pending: pendingRoles.find(pendingRole => pendingRole.Name === oracleRole.Name)
                                });

                                $scope.vm.ListOfAvailableOracleRoles.forEach(function (row, j) {
                                    if (row.Name === oracleRole.Name) {
                                        $scope.vm.ListOfAvailableOracleRoles.splice(j, 1);
                                    }
                                });
                            }

                            oracleRole = null;

                            $scope.vm.numberOfOracleResponsibilitiesValid = true;
                        }
                    });

                    $scope.oracleUserLoaded = true;
                }

                $scope.vm.ListOfAvailableOracleRoles = $scope.vm.ListOfAvailableOracleRoles.filter(role => role.IsAssignable);
                orDfrd.resolve();
            });
        }

        return $.when(orDfrd).done(function () {
        }).promise();

    };
}

function applyPending(pendingItems, item) {
    if (pendingItems.length) {
        item.Pending = pendingItems.some(pendingItem => pendingItem.Id === item.Id);
    }

    return item;
}

function applyPendingByName(pendingItems, item) {
    if (pendingItems.length) {
        item.Pending = pendingItems.some(pendingItem => pendingItem.Name === item.Name);
    }

    return item;
}