export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getConfigurationByProductId,
        getNetSuiteAuditTrailVersion,
        setProductConfiguration,
        dataActionCall
    };

    return service;

    //GET Requests
    async function getConfigurationByProductId(productId) {
        try {
            return await $http.get(`${apiUrl}api/universal/products/${productId}/configuration`);
        } catch {
            showError(err);
        }
    }

    async function getNetSuiteAuditTrailVersion() {
        try {
            return await $http.get(`${apiUrl}api/core/configuration/NS_AT2_Version`);
        } catch {
            showError(err);
        }
    }

    //PUT Requests
    async function setProductConfiguration(productId, data) {
        try {
            return await $http.put(`${apiUrl}api/universal/products/${productId}/configuration`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function dataActionCall(action, data) {
        if (action === 'import') {
            try {
                return await $http.put(`${apiUrl}api/audittrail/sf/object-tracking/import-metadata`);
            } catch (err) {
                showError(err);
                sendError(err);
            }
        } else {
            try {
                return await $http.put(`${apiUrl}api/audittrail/auditdata/` + action, data);
            } catch (err) {
                showError(err);
                sendError(err);
            }
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}