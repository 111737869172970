import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, businessCyclesDataService, businessCycle) {

    const vm = this;

    vm.dismiss = dismiss;
    vm.saveBusinessCycle = saveBusinessCycle;

    function activate() {
        vm.shouldBeOpen = true;

        if (businessCycle.ID) {
            vm.businessCycle = Object.assign({}, businessCycle.toJSON());
        }
    }

    activate();

    function dismiss() {
        $uibModalInstance.dismiss();
    }

    function close() {
        $uibModalInstance.close();
    }

    //Save BusinessCycle
    function saveBusinessCycle() {
        vm.saving = new createSpinner();
        if (vm.businessCycle.ID) {
            modifyBusinessCycle(vm.businessCycle.ID, vm.businessCycle);
        } else {
            addBusinessCycle(vm.businessCycle);
        }
    }

    async function modifyBusinessCycle(businessCycleId, businessCycle) {
        try {
            await businessCyclesDataService.modifyBusinessCycle(businessCycleId, businessCycle);
            await helperService.successfulSaveButton(vm.saving);
            close();
        }
        catch {
            vm.saving.loadingValue = false;
        }
    }

    async function addBusinessCycle(businessCycle) {
        try {
            businessCycle.ID = '';
            await businessCyclesDataService.addBusinessCycle(businessCycle);
            await helperService.successfulSaveButton(vm.saving);
            close();
        }
        catch {
            vm.saving.loadingValue = false;
        }
    }
}