import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, $filter, dataService, manageDataDataService, configurationDataService, requesterGroupsDataService, approvalGroupsDataService, riskLevelsData, $http, helperService, approvalRulesDataService, supervisorRuleDisabled, allCompaniesRuleDisabled, companyTemplatesData, ownershipGroupsDataService, modalType, approvalRule) {

    const vm = this;

    vm.supervisorRuleDisabled = supervisorRuleDisabled;
    vm.allCompaniesRuleDisabled = allCompaniesRuleDisabled;

    vm.helperService = helperService;
    vm.dismiss = dismiss;
    vm.saveApprovalRule = saveApprovalRule;
    vm.ruleTypeDropdownChange = ruleTypeDropdownChange;
    vm.productDropdownChange = productDropdownChange;
    vm.enforceCompanyApprovalRulesChange = enforceCompanyApprovalRulesChange;
    vm.userGroupDropdownChange = userGroupDropdownChange;
    vm.dropdownChange = dropdownChange;
    vm.modalType = modalType;
    vm.updateAutoApproveUser = updateAutoApproveUser;    

    vm.updateInProgress = false;
    vm.configUpdated = false;
    vm.dropdownsLoaded = false;
    if (modalType === 'Edit') {
        vm.disableSaveButton = true;
    } else {
        vm.disableSaveButton = false;
    }
    vm.approversList = [];
    vm.availableProducts = [];


    activate();

    async function activate() {        
        vm.ruleTypesLoading = true;
        vm.approversLoading = true;
        vm.usersLoading = true;
        vm.hideApproverSection = false;
        vm.saveEnforceApprovalRulesConfig = false;
        await getRuleTypes();
        selectInitialRuleType();
        if (vm.availableProducts.some(prod => prod.ID === 'AX7')) {
            let companyApprovalRuleConfig = await configurationDataService.getProductConfigurationValueByName('ax7', 'AX7_ImEnforceCompanyApprovalRules');
            vm.AX7_ImEnforceCompanyApprovalRules = companyApprovalRuleConfig.data.Value;        
        }
        setEnforceApprovalRulesToggle();
        await getUsers();
        await getApprovalGroups();
        getRiskLevels();
        getApplications();
        getCompanyTemplates();
        if (vm.selectedRuleType !== 7 && vm.selectedRuleType !== 2) {
            filterApproverDropdown();
        }
        vm.dropdownsLoaded = true;
    }

    function setEnforceApprovalRulesToggle() {     
        //define toggle default value to False when adding new rule
        if (modalType === 'Edit') {
            vm.enforceCompanyApprovalRules = vm.AX7_ImEnforceCompanyApprovalRules;
        } else {
            vm.enforceCompanyApprovalRules = '0';
        }
    }

    function updateAutoApproveUser() {
        if (modalType === 'Edit') {
            vm.disableSaveButton = false;
            if (!vm.autoApproveUser && !vm.selectedApprover) {
                vm.selectedApprover = vm.approverUserGroupDataSource[0];
            }
        }
    }

    async function selectInitialRuleType() {
        vm.isUser = true;
        vm.isRole = false;
        vm.isRiskTolerance = false;
        vm.isApplication = false;
        vm.isGroup = false;
        vm.isRequest = false;
        vm.isCompany = false;
        vm.isCompanyTemplate = false;
        vm.requestApplicationDataSource = [
            {
                Name: 'New',
                Id: 1
            },
            {
                Name: 'Modify',
                Id: 2
            },
            {
                Name: 'Terminate',
                Id: 3
            }
        ];
        vm.selectedRequest = vm.requestApplicationDataSource[0];

        if (modalType === 'Edit') {
            vm.isUser = false;
            switch (approvalRule.TypeId) {
                case 1:
                    vm.isUser = true;
                    break;
                case 2:
                    vm.isRole = true;
                    await getRoleOwnershipGroups();
                    break;
                case 3:
                    vm.isRiskTolerance = true;
                    vm.riskToleranceLoading = true;
                    break;
                case 4:
                    vm.isApplication = true;
                    vm.applicationLoading = true;
                    break;
                case 6:
                    vm.isRequest = true;
                    vm.selectedRequest = vm.requestApplicationDataSource.find(requestAction => requestAction.Name === approvalRule.RequesterName);
                    break;
                case 8:
                    vm.isCompany = true;
                    vm.companiesDropdownLoading = true;
                    await getProducts();
                    break;
                case 9:
                    vm.isCompanyTemplate = true;
                    vm.companyTemplatesLoading = true;
                    break;
                default:
                    break;
            }
        }
    }

    async function getRuleTypes() {
        let response = await approvalRulesDataService.getApprovalRuleTypes();
        if (vm.supervisorRuleDisabled) {
            response.data = response.data.filter(ruleType => ruleType.Name !== 'Supervisor');
        }

        let showCompanyType = false;
        await getProducts();

        if (vm.availableProducts.some(prod => prod.ID === 'AX' || prod.ID === 'GP' || prod.ID === 'D365S' || prod.ID === 'AX7' || prod.ID === 'NAV')) {
            showCompanyType = true;
        }

        if (!showCompanyType) {
            response.data = response.data.filter(ruleType => ruleType.Name !== 'Company');
        }

        response.data = $filter('orderBy')(response.data, 'Name');
        vm.ruleTypeDataSource = response.data;
        if (modalType === 'Edit') {
            vm.selectedRuleType = approvalRule.TypeId;
            vm.autoApproveUser = approvalRule.AutoApproveUser;
        } else {
            vm.selectedRuleType = 1;
        }
        vm.ruleTypesLoading = false;
        return vm.ruleTypeDataSource;
    }

    async function getUsers() {
        vm.userGroupDataSource = [];
        const users = await dataService.getUsers();
        vm.usersDataSource = $filter('filter')(users.data, { Active: true });
        vm.usersDataSource.forEach(user => {
            vm.userGroupDataSource.push({ Name: user.UserLogonName, Id: user.UserID, Type: 'User' });
        });

        const requesterGroupResponse = await requesterGroupsDataService.getRequesterGroups();
        requesterGroupResponse.data.forEach(group => {
            vm.userGroupDataSource.push({ Name: group.Name, Id: group.Id, Type: 'Group' });
        });

        if (vm.userGroupDataSource.length > 0 && modalType === 'Edit' && approvalRule.RequesterType === 'User') {
            vm.selectedUser = vm.userGroupDataSource.find(user => user.Name === approvalRule.RequesterName);
        } else if (vm.userGroupDataSource.length > 0) {
            vm.selectedUser = vm.userGroupDataSource[0];
        }
        vm.usersLoading = false;

        return vm.userGroupDataSource;
    }

    async function getApprovalGroups() {
        const approvalGroups = await approvalGroupsDataService.getApprovalGroups();
        approvalGroups.data.forEach(group => {
            vm.approversList.push({ Name: group.Name, Id: group.Id, Type: 'Group' });
        });
        vm.usersDataSource.forEach(user => {
            vm.approversList.push({ Name: user.UserLogonName, Id: user.UserID, Type: 'User' });
        });
        vm.approversList = $filter('orderBy')(vm.approversList, 'Name');
        vm.approversLoading = false;
        return vm.approversList;
    }

    async function getRiskLevels() {
        vm.riskToleranceLoading = true;
        let response = await riskLevelsData.getRiskLevels();
        vm.riskDataSource = response.data;
        if (vm.riskDataSource.length > 0 && modalType === 'Edit' && approvalRule.RequesterType === 'Risk Tolerance') {
            vm.selectedRiskTolerance = vm.riskDataSource.find(riskLevel => riskLevel.Name === approvalRule.RequesterName);
        } else if (vm.riskDataSource.length > 0) {
            vm.selectedRiskTolerance = vm.riskDataSource[0];
        }
        vm.riskToleranceLoading = false;
    }

    async function getApplications() {
        vm.applicationLoading = true;
        let response = await $http.get(apiUrl + 'api/identitymanager/applications');
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.applicationDataSource = response.data;
        if (vm.applicationDataSource.length > 0 && modalType === 'Edit' && approvalRule.RequesterType === 'Application') {
            vm.selectedApplication = vm.applicationDataSource.find(application => application.Name === approvalRule.RequesterName);
        } else if (vm.applicationDataSource && vm.applicationDataSource.length > 0) {
            vm.selectedApplication = vm.applicationDataSource[0];
        }
        vm.applicationLoading = false;
    }

    async function getCompanyTemplates() {
        vm.companyTemplatesLoading = true;
        let response = await companyTemplatesData.getCompanyTemplates();
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.companyTemplateDataSource = response.data;



        if (vm.companyTemplateDataSource.length === 0) {
            vm.companyTemplateDataSource.unshift({ "Companies": null, "Id": null, "Name": "-- No Company Templates Available --" });
            vm.selectedCompanyTemplate = vm.companyTemplateDataSource[0];
        } else if (vm.companyTemplateDataSource.length > 0 && modalType === 'Edit' && approvalRule.RequesterType === 'Company Template') {
            vm.selectedCompanyTemplate = vm.companyTemplateDataSource.find(companyTemplate => companyTemplate.Id === approvalRule.RequesterId);
        } else {
            vm.selectedCompanyTemplate = vm.companyTemplateDataSource[0];
        }

        vm.companyTemplatesLoading = false;

    }

    async function getProducts() {
        vm.productsLoading = true;
        let response = await $http.get(`${apiUrl}api/core/modules/identitymanager/products`);
        vm.availableProducts = response.data;
        if (vm.isCompany) {
            vm.productsDataSource = vm.availableProducts.filter(product => product.ID === 'AX' || product.ID === 'AX7' || product.ID === 'NAV' || product.ID === 'D365S' || product.ID === 'GP');
        }

        vm.productsDataSource = response.data;
        if (response.data.length > 0 && modalType === 'Edit' && approvalRule.ProductId) {
            vm.selectedProduct = vm.productsDataSource.find(product => product.ID === approvalRule.ProductId);
        } else if (response.data.length > 0) {
            vm.selectedProduct = response.data[0];
        }
        vm.productsLoading = false;
        if (vm.isCompany) {
            loadCompanies();
        }
        return vm.availableProducts;
    }

    function cancel() {
        $uibModalInstance.close();
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }

    //Functions

    function saveApprovalRule() {
        let postBody = {};

        switch (vm.selectedRuleType) {
            case 1:
                postBody.RequesterId = vm.selectedUser.Id;
                postBody.RequesterType = vm.selectedUser.Type;
                postBody.RequesterName = vm.selectedUser.Name;
                break;
            case 2:
                postBody.RequesterId = vm.selectedOwnershipGroup.Id;
                postBody.RequesterType = 'RoleOwnershipGroup';
                break;
            case 3:
                postBody.RequesterId = vm.selectedRiskTolerance.Id;
                postBody.RequesterName = vm.selectedRiskTolerance.Name;
                postBody.RequesterType = 'RiskTolerance';
                break;
            case 4:
                postBody.RequesterId = vm.selectedApplication.Id;
                postBody.RequesterName = vm.selectedApplication.Name;
                postBody.RequesterType = 'Application';
                break;
            case 5:
                break;
            case 6:
                postBody.RequesterId = vm.selectedRequest.Id;
                postBody.RequesterName = vm.selectedRequest.Name;
                postBody.AutoApproveUser = vm.autoApproveUser;
                postBody.RequesterType = 'RequestAction';
                break;
            case 7:
                break;
            case 8:
                postBody.ConfigUpdated = false;

                if (vm.selectedCompany.ID === '-1') {
                    vm.saveEnforceApprovalRulesConfig = true;
                }

                if (vm.configUpdated) {
                    postBody.ConfigUpdated = true;
                }

                postBody.RequesterId = vm.selectedCompany.ID;
                postBody.RequesterName = vm.selectedCompany.Name;
                postBody.ProductId = vm.selectedCompany.ProductID;
                postBody.RequesterType = 'Company';
                break;
            case 9:
                postBody.RequesterId = vm.selectedCompanyTemplate.Id;
                postBody.RequesterName = vm.selectedCompanyTemplate.Name;
                postBody.RequesterType = 'CompanyTemplate';
                break;
            default:
                postBody.RequesterId = vm.selectedCompanyTemplate.Id;
                postBody.RequesterName = vm.selectedCompanyTemplate.Name;
                postBody.RequesterType = 'CompanyTemplate';
                break;
        }

        postBody.TypeId = vm.selectedRuleType;

        if (vm.selectedRuleType !== 7 && vm.selectedRuleType !== 2 && !vm.autoApproveUser) {
            postBody.ApproverId = vm.enforceCompanyApprovalRules === '1' ? null : vm.selectedApprover.Id;
            postBody.ApproverType = vm.enforceCompanyApprovalRules === '1' ? null : vm.selectedApprover.Type;
            postBody.ApproverName = vm.enforceCompanyApprovalRules === '1' ? null : vm.selectedApprover.Name;
        }

        if (modalType === 'Edit') {
            postBody.ApprovalRuleId = approvalRule.Id;
        }

        addRule(postBody);
    }

    async function addRule(data) {
        vm.updateInProgress = new createSpinner();
        try {
            if (vm.saveEnforceApprovalRulesConfig) {
                let configData = {
                    "Configurations": [{
                        "Name": 'AX7_ImEnforceCompanyApprovalRules',
                        "Value": vm.enforceCompanyApprovalRules
                    }],
                    "ProductId": data.ProductId
                };

                await manageDataDataService.setProductConfiguration(data.ProductId, configData);
            }

            if (modalType === 'Edit') {
                await approvalRulesDataService.updateApprovalRule(approvalRule.Id, data);
            } else {
                await approvalRulesDataService.addApprovalRule(data);
            }

            await helperService.successfulSaveButton(vm.updateInProgress);
            cancel();
        } catch {
            vm.updateInProgress.loadingValue = false;
        }
    }

    function userGroupDropdownChange() {
        vm.disableSaveButton = false;
        filterApproverDropdown();
    }

    function dropdownChange() {
        vm.disableSaveButton = false;
    }

    async function ruleTypeDropdownChange() {
        vm.isUser = false;
        vm.isRole = false;
        vm.isRiskTolerance = false;
        vm.isApplication = false;
        vm.isRequest = false;
        vm.isCompany = false;
        vm.isCompanyTemplate = false;

        switch (vm.selectedRuleType) {
            case 1:
                vm.isUser = true;
                break;
            case 2:
                vm.isRole = true;
                await getRoleOwnershipGroups();
                break;
            case 3:
                vm.isRiskTolerance = true;
                break;
            case 4:
                vm.isApplication = true;
                break;
            case 6:
                vm.isRequest = true;
                break;
            case 8:
                vm.isCompany = true;
                await getProducts();
                break;
            case 9:
                vm.isCompanyTemplate = true;
                break;
            default:
                break;
        }

        filterApproverDropdown();
    }

    function productDropdownChange() {
        vm.disableSaveButton = false;
        if (vm.isCompany) {
            loadCompanies();
        }
    }

    function enforceCompanyApprovalRulesChange(toggled) {
        if (vm.selectedCompany.Name === 'All Companies' && vm.enforceCompanyApprovalRules === '1') {
            vm.hideApproverSection = true;
        } else {
            vm.hideApproverSection = false;
        }

        if (toggled) {
            vm.updateConfig = true;
        }
        vm.disableSaveButton = false;
    }

    async function getRoleOwnershipGroups() {
        vm.ownershipGroupsDropdownLoading = true;
        let { data } = await ownershipGroupsDataService.getOwnershipGroups();
        vm.roleOwnershipGroupsDataSource = data.filter(group => group.GroupType === 'role');

         if (vm.roleOwnershipGroupsDataSource.length === 0) {
            vm.roleOwnershipGroupsDataSource.unshift({ "GroupType": "Role", "Name": "-- No Ownership Groups Available --", "Id": null });
            vm.selectedOwnershipGroup = vm.roleOwnershipGroupsDataSource[0];
        } else if (vm.roleOwnershipGroupsDataSource.length > 0 && modalType === 'Edit' && approvalRule.RequesterType === 'Role Ownership Group') {
             vm.selectedOwnershipGroup = vm.roleOwnershipGroupsDataSource.find(ownershipGroup => ownershipGroup.Id.toString() === approvalRule.RequesterId);
        } else {
            vm.selectedOwnershipGroup = vm.roleOwnershipGroupsDataSource[0];
        }

        vm.ownershipGroupsDropdownLoading = false;
    }


    async function loadCompanies() {
        vm.companiesDropdownLoading = true;
        let response;
        if (vm.selectedProduct.Type === 'AX7') {
            let allCompaniesOption = {
                Name: 'All Companies',
                ID: '-1',
                Type: 'All',
                ProductID: vm.selectedProduct.ID
            };

            // we need an alternate call for D365FO companies so we can include Org Hierarchies
            response = await $http.get(`${apiUrl}api/identitymanager/ax7/companies`);
            response.data = response.data.map(company => {
                return {
                    Name: company.CompanyName,
                    ID: company.CompanyId,
                    Type: company.CompanyType,
                    ProductID: vm.selectedProduct.ID
                }
            });

            if (!vm.allCompaniesRuleDisabled || (modalType === 'Edit' && approvalRule.RequesterId === '-1')) {
                response.data.push(allCompaniesOption);
            }
        } else {
            response = await dataService.getCompaniesByProductId(vm.selectedProduct.ID);
        }
        vm.companiesDataSource = response.data;
        vm.companiesDataSource = $filter('orderBy')(vm.companiesDataSource, 'Name');
        if (modalType === 'Edit' && approvalRule.TypeName === 'Company') {
               vm.selectedCompany = vm.companiesDataSource.find(company => company.ID === approvalRule.RequesterId);
        } else {
            vm.selectedCompany = vm.companiesDataSource[0];
        }

        enforceCompanyApprovalRulesChange(false);

        vm.companiesDropdownLoading = false;
    }

    //If user is the selected rule type remove the selected user from the approver list
    function filterApproverDropdown() {
        if (vm.selectedRuleType === 1) {
            vm.approverUserGroupDataSource = vm.approversList.filter(approver => approver.Id !== vm.selectedUser.Id);
            //Remove the Default Approver group from the approver list
            vm.approverUserGroupDataSource = vm.approverUserGroupDataSource.filter(userGroup => userGroup.Id !== 'da812a09-e9e3-46bf-9c8f-32d267b12440');

            if (vm.approverUserGroupDataSource.length > 0 && (!vm.selectedApprover || vm.selectedApprover.Id === vm.selectedUser.Id) && modalType === 'Edit' && approvalRule) {
                vm.selectedApprover = vm.approverUserGroupDataSource.find(approver => approver.Id === approvalRule.ApproverId);
            } else if (vm.approverUserGroupDataSource.length > 0 && (!vm.selectedApprover || vm.selectedApprover.Id === vm.selectedUser.Id)) {
                vm.selectedApprover = vm.approverUserGroupDataSource[0];
            }
        }
        else {
            //Remove the Default Approver group from the approver list
            vm.approverUserGroupDataSource = vm.approversList.filter(approver => approver.Id !== 'da812a09-e9e3-46bf-9c8f-32d267b12440');

            if (vm.approverUserGroupDataSource.length > 0 && (!vm.selectedApprover || vm.selectedApprover.Id === vm.selectedUser.Id) && modalType === 'Edit' && approvalRule) {
                vm.selectedApprover = vm.approverUserGroupDataSource.find(approver => approver.Id === approvalRule.ApproverId);

                if (!vm.selectedApprover && vm.isCompany) {
                    vm.selectedApprover = vm.approverUserGroupDataSource[0];
                }
            }
        }
    }
}