import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($scope, AXSecurityDesignerService, errorsDataService, $http, $location, helperService) {

    var vm = $scope.vm = {};
    var sim = $scope.sim = {};

    $scope.helperService = helperService;
    $scope.isCollapsed = true;
    $scope.pageParams = $location.search();
    $scope.selectedDetailView = null;

    vm.exportSimulationAnalysis = exportSimulationAnalysis;

    var statusColumn = { title: "Status", field: "Status", width: 125, filterable: customAutoCompleteFilter, template: '<span set-style="#:Status == \'Added\'? \'D9EAD3\': Status == \'Removed\'? \'F2DEDE\': Status == \'Exists\'? \'FCF8E3\': null#">{{dataItem.Status}}</span>' };

    $scope.changeSelectedView = function (view) {
        $scope.selectedDetailView = view;
    };

    function getRoleLicenses() {

        $scope.roleLicenses_dataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: function (options) {
                    $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + $scope.pageParams.SimulationId + '/analysis/roleLicenses').then(function (response) {
                        options.success(response.data);
                    }, function errorCallback(response) {
                        options.error();
                    });

                }
            }
        });

        var columns = [
            { title: "Role ID", field: "RoleId", filterable: customAutoCompleteFilter },
            { title: "Role Name", field: "RoleName", filterable: customAutoCompleteFilter },
            { title: "Current License", field: "CurrentLicense", filterable: customAutoCompleteFilter },
            { title: "New License", field: "SimulationLicense", filterable: customAutoCompleteFilter }
        ];

        $scope.roleLicenses_gridOptions = helperService.setMainGridOptions(columns, null);
        $scope.roleLicenses_gridOptions.selectable = false;

    }

    function getUserLicenses() {

        $scope.userLicenses_dataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: function (options) {
                    $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + $scope.pageParams.SimulationId + '/analysis/userLicenses').then(function (response) {
                        options.success(response.data);
                    }, function errorCallback(response) {
                        options.error();
                    });

                }
            }
        });

        var columns = [
            { title: "User ID", field: "UserId", filterable: customAutoCompleteFilter },
            { title: "User Name", field: "UserName", filterable: customAutoCompleteFilter },
            { title: "Current License", field: "CurrentLicense", filterable: customAutoCompleteFilter },
            { title: "New License", field: "SimulationLicense", filterable: customAutoCompleteFilter }
        ];

        $scope.userLicenses_gridOptions = helperService.setMainGridOptions(columns, null);
        $scope.userLicenses_gridOptions.selectable = false;

    }

    function getRoleRisks() {

        $scope.roleRisks_dataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: function (options) {
                    $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + $scope.pageParams.SimulationId + '/analysis/rolerisks').then(function (response) {
                        options.success(response.data);
                    }, function errorCallback(response) {
                        options.error();
                    });

                }
            }
        });

        var columns = [
            { title: "Role Name", field: "RoleName", filterable: customAutoCompleteFilter },
            { title: "Risk ID", field: "RiskId", filterable: customAutoCompleteFilter },
            { title: "Risk Name", field: "RiskName", filterable: customAutoCompleteFilter },
            { title: "Risk Type", field: "RiskType", filterable: customAutoCompleteFilter }
        ];
        columns.push(statusColumn);

        $scope.roleRisks_gridOptions = helperService.setMainGridOptions(columns, null);
        $scope.roleRisks_gridOptions.selectable = false;

    }

    function getRoleBusinessProcess() {

        $scope.roleBusinessProcess_dataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: function (options) {
                    $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + $scope.pageParams.SimulationId + '/analysis/rolebpaccess').then(function (response) {
                        options.success(response.data);
                    }, function errorCallback(response) {
                        options.error();
                    });

                }
            }
        });

        var columns = [
            { title: "Role Name", field: "RoleName", filterable: customAutoCompleteFilter },
            { title: "Object Name", field: "BusinessProcessName", filterable: customAutoCompleteFilter }
        ];
        columns.push(statusColumn);

        $scope.roleBusinessProcess_gridOptions = helperService.setMainGridOptions(columns, null);
        $scope.roleBusinessProcess_gridOptions.selectable = false;

    }

    function getUserRisks() {

        $scope.userRisks_dataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: function (options) {
                    $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + $scope.pageParams.SimulationId + '/analysis/userrisks').then(function (response) {
                        options.success(response.data);
                    }, function errorCallback(response) {
                        options.error();
                    });

                }
            }
        });

        var columns = [
            { title: "User Name", field: "UserName", filterable: customAutoCompleteFilter },
            { title: "Company Name", field: "CompanyName", filterable: customAutoCompleteFilter },
            { title: "Risk ID", field: "RiskId", filterable: customAutoCompleteFilter },
            { title: "Risk Name", field: "RiskName", filterable: customAutoCompleteFilter },
            { title: "Risk Type", field: "RiskType", filterable: customAutoCompleteFilter }
        ];

        columns.push(statusColumn);

        $scope.userRisks_gridOptions = helperService.setMainGridOptions(columns, null);
        $scope.userRisks_gridOptions.selectable = false;

    }

    function getUserBusinssProcess() {

        $scope.userBusinessProcess_dataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: function (options) {
                    $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + $scope.pageParams.SimulationId + '/analysis/userbpaccess').then(function (response) {
                        options.success(response.data);
                    }, function errorCallback(response) {
                        options.error();
                    });

                }
            }
        });

        var columns = [
            { title: "User Name", field: "UserName", filterable: customAutoCompleteFilter },
            { title: "Company Name", field: "CompanyName", filterable: customAutoCompleteFilter },
            { title: "Object Name", field: "BusinessProcessName", filterable: customAutoCompleteFilter }
        ];

        columns.push(statusColumn);

        $scope.userBusinessProcess_gridOptions = helperService.setMainGridOptions(columns, null);
        $scope.userBusinessProcess_gridOptions.selectable = false;

    }

    vm.statisticsLoading = true;

    function getStatistics() {

        $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + $scope.pageParams.SimulationId + '/analysis/statistics').then(function (response) {
            vm.statisticsLoading = false;
            vm.selectedSimulationStatistics = response.data;
        }, function errorCallback(response) {
            vm.statisticsLoading = false;
        });

    }

    vm.loadingChanges = true;
    if ($scope.pageParams.SimulationId) {
        $http.get(apiUrl + 'api/securitydesigner/simulation/' + $scope.pageParams.SimulationId).then(function (response) {
            vm.selectedSimulation = response.data;
            if (vm.selectedSimulation.ProductId === 'AX7') {
                vm.selectedSimulation.ProductName = 'Dynamics 365 for Finance and Operations';
            }
            vm.selectedSimulation.toggleInfo = false;

            getStatistics();

            getRoleLicenses();
            getUserLicenses();

            getRoleRisks();
            getRoleBusinessProcess();

            getUserRisks();
            getUserBusinssProcess();

            $http.get(apiUrl + 'api/securitydesigner/simulation/' + $scope.pageParams.SimulationId + '/changes').then(function (response) {

                AXSecurityDesignerService.changeActionVerbiage(response.data);

                sim.SimulationChanges = response.data;
                vm.loadingChanges = false;
            }, function errorCallback(response) {
                vm.loadingChanges = false;
            });

        }, function errorCallback(response) {
            vm.loadingChanges = false;
        });
    }

    $scope.determineLabelClass = function (ActionName) {
        return AXSecurityDesignerService.determineLabelClass(ActionName);
    };

    function exportSimulationAnalysis() {
        vm.pleaseWait = new createSpinner();
        AXSecurityDesignerService.exportSimulationAnalysis(vm.selectedSimulation).then(response => {
            helperService.successfulSaveButton(vm.pleaseWait);
        }).catch(response => {
            vm.pleaseWait.loadingValue = false;
            errorsDataService.catch(response);
        });
    }

}
