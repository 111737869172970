export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getUserStatistics,
        getCompanyStatistics,
        getDashboards,
        getCards,
        getCharts,
        getChartByCard,
        createDashboard,
        deleteDashboard,
        getDashboard,
        createCard
    };

    return service;

    //GET Requests
    async function getUserStatistics() {
        try {
            return await $http.get(apiUrl + 'api/universal/users/statistics/');
        } catch (error) {
            sendError(error);
        }
    }

    async function getCompanyStatistics() {
        try {
            return await $http.get(apiUrl + 'api/universal/companymapping/statistics/');
        } catch (error) {
            sendError(error);
        }
    }

    async function getDashboards() {
        try {
            return await $http.get(apiUrl + 'api/core/dashboards');
        } catch (error) {
            sendError(error);
        }
    }

    async function getCards() {
        try {
            return await $http.get(apiUrl + 'api/core/cards');
        } catch (error) {
            sendError(error);
        }
    }

    async function getCharts() {
        try {
            return await $http.get(apiUrl + 'api/core/charts');
        } catch (error) {
            sendError(error);
        }
    }

    async function getChartByCard(cardUrl) {
        try {
            return await $http.get(apiUrl + cardUrl);
        } catch (error) {
            sendError(error);
        }
    }

    async function createDashboard(data) {
        try {
            return await $http.post(apiUrl + 'api/core/dashboards', data);
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    async function deleteDashboard(id) {
        try {
            return await $http.delete(apiUrl + 'api/core/dashboards/' + id);
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    async function getDashboard(data) {
        try {
            return await $http.put(apiUrl + 'api/core/userpreferences/DefaultDashboard', data);
        } catch (error) {
            showError(error);
            sendError(error);
        }

    }

    async function createCard(data) {
        try {
            return await $http.post(apiUrl + 'api/core/cards', data);
        } catch (error) {
            showError(error);
            sendError(error);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }
}