import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($scope, $http, helperService) {

    var manageDataVm = $scope.manageDataVm = {};

    manageDataVm.collectAuditTrailDataApiCall = function () {

        var selectedProduct = $scope.parentVm.radioSelected;
        var product = null;

        if (selectedProduct === 'AX5_CollectDataChanges') {
            product = 'ax5';
        } else if (selectedProduct === 'NS_CollectDataChanges') {
            product = 'ns';
        } else if (selectedProduct === 'SAP_CollectDataChanges') {
            product = 'sap';
        } else if (selectedProduct === 'GP_CollectDataChanges') {
            product = 'gp';
        } else if (selectedProduct === 'NAV_CollectDataChanges') {
            product = 'nav';
        } else if (selectedProduct === 'AX_CollectDataChanges') {
            product = 'ax';
        } else if (selectedProduct === 'AX7_CollectDataChanges') {
            product = 'ax7';
        } else if (selectedProduct === 'SL_CollectDataChanges') {
            product = 'sl';
        }

        manageDataVm.dataProcessIsRunning = new createSpinner();
        var url = apiUrl + 'api/audittrail/' + product + '/auditdata/import';
        if (product === 'sap') {
            url = apiUrl + 'api/audittrail/sap/SAP/auditdata/import';
        } else if (product === 'ax7') {
            url = apiUrl + 'api/audittrail/ax7/auditdata/move';
        }
        $http.put(url, {}).then(function (response) {
            //helperService.showConfirmationMessage("Attention", "This process has been initiated.");
            manageDataVm.dataProcessIsRunning.loadingValue = false;
        }, function errorCallback(response) {
            helperService.showErrorMessage(response.data);
            manageDataVm.dataProcessIsRunning.loadingValue = false;
        });
    };
}
