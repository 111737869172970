import { standardGridPageSize } from "../../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($filter, $http, $uibModalInstance, helperService, requestUserId) {

    var vm = this;

    vm.searchComplete = searchComplete;
    vm.searchKeyDown = searchKeyDown;
    vm.changeExistingUserRowSelected = changeExistingUserRowSelected;
    vm.selectUser = selectUser;
    vm.dismiss = dismiss;

    activate();

    function activate() {
        const existingUserGridColumns = [
            { field: "DisplayName", title: "Name", filterable: false },
            { field: "UserPrincipalName", title: "User Principal Name", filterable: false }
        ];
        vm.existingUsersGridOptions = helperService.setMainGridOptions(existingUserGridColumns, 400);
        vm.existingUsersGridOptions.autoBind = false;
        vm.existingUsersGridOptions.pageable = {
            alwaysVisible: false
        }

        vm.existingUsersGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    try {
                        let response = await $http({
                            method: 'GET',
                            url: apiUrl + 'api/identitymanager/aad/users/search',
                            params: {
                                criteria: vm.existingUserSearchString,
                                //includeInactive: false
                            }
                        });
                        // Remove request user from list as they cannot be selected as manager
                        const requestUserIndex = response.data.findIndex(user => user.Id === requestUserId);
                        if (requestUserIndex > -1) {
                            response.data.splice(requestUserIndex, 1);
                        }
                        response.data = $filter('orderBy')(response.data, 'Name');
                        options.success(response.data);
                    } catch (error) {
                        options.error();
                        helperService.showErrorMessage(error.data);
                    }
                }
            }
        });
    }

    function searchComplete() {
        if (vm.existingUserSearchString === '' || !vm.existingUserSearchString) {
            var raw = vm.existingUsersGridDataSource.data();
            var length = raw.length;

            var item, i;
            for (i = length - 1; i >= 0; i--) {
                item = raw[i];
                vm.existingUsersGridDataSource.remove(item);
            }
        } else {
            vm.existingUsersGridDataSource.read();
        }
    }

    function searchKeyDown () {
        vm.SearchExistingUserSelected = null;
    }

    function changeExistingUserRowSelected(kendoEvent) {
        var grid = kendoEvent.sender;

        vm.selectedManager = grid.dataItem(grid.select());
    }

    function selectUser() {
        $uibModalInstance.close(vm.selectedManager);
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }
}