export default /*@ngInject*/ function (helperService) {
    const factory = {
        generateBody
    };

    return factory;

    function generateBody (ObjectId, users, selectedCompanies, riskSelectedIds, selectedProducts, ruleset) {

        var risksSelected = helperService.returnNullIfAllIsFound(riskSelectedIds.map(function (el) { return el.Id; }));
        var companiesSelected = helperService.returnNullIfAllIsFound(selectedCompanies.map(function (el) { return el.ID; }));
        var products = selectedProducts.map(function (el) { return el.ID; });


        var data = {
            "ProductIds": products,
            "ObjectId": ObjectId,
            "UserMasterIds": users.length ? users : null,
            "CompanyMasterIds": companiesSelected,
            "RiskIds": risksSelected,
            "RulesetId": ruleset
        };

        return data;
    }

}