export default /*@ngInject*/ function dataService($http, $injector) {
    const service = {
        getNavigation: getNavigation,
        getProducts: getProducts,
        getProductTypes: getProductTypes,
        getFavorites: getFavorites,
        getRoles: getRoles,
        getSecurityObjects: getSecurityObjects,
        getSavedReports: getSavedReports,
        getSavedReportsByUser: getSavedReportsByUser,
        getSavedReportsByID: getSavedReportsByID,
        getUsers: getUsers,
        getUserMaster: getUserMaster,
        getClaims: getClaims,
        getCompanyMasters: getCompanyMasters,
        getCompaniesByProductId: getCompaniesByProductId
    };

    return service;

    function getNavigation() {
        return $http.get(apiUrl + 'api/core/usersecurity/navigation')
            .then(workComplete)
            .catch(workFailed);
    }

    function getProducts() {
        return $http.get(apiUrl + 'api/universal/products')
            .then(workComplete)
            .catch(workFailed);
    }

    function getProductTypes() {
        return $http.get(apiUrl + 'api/universal/producttypes')
            .then(workComplete)
            .catch(workFailed);
    }

    function getFavorites() {
        return $http.get(apiUrl + 'api/core/favorites')
            .then(workComplete)
            .catch(workFailed);
    }

    function getRoles() {
        return $http.get(apiUrl + 'api/core/role')
            .then(workComplete)
            .catch(workFailed);
    }

    function getSecurityObjects() {
        return $http.get(apiUrl + 'api/universal/securityobjects')
            .then(workComplete)
            .catch(workFailed);
    }

    function getSavedReports() {
        return $http.get(apiUrl + 'api/core/savedreports')
            .then(workComplete)
            .catch(workFailed);
    }

    function getSavedReportsByUser(userId) {
        return $http.get(apiUrl + 'api/core/savedreports/users/' + userId)
            .then(workComplete)
            .catch(workFailed);
    }

    function getSavedReportsByID(id) {
        return $http.get(apiUrl + 'api/core/savedreports/' + id)
            .then(workComplete)
            .catch(workFailed);
    }

    function getUsers() {
        return $http.get(apiUrl + 'api/core/users')
            .then(workComplete)
            .catch(workFailed);
    }

    function getUserMaster() {
        return $http.get(apiUrl + 'api/universal/usermaster')
            .then(workComplete)
            .catch(workFailed);
    }

    function getClaims(UserID) {
        return $http.get(apiUrl + 'api/core/claims/' + UserID)
            .then(workComplete)
            .catch(workFailed);
    }

    function getCompanyMasters() {
        return $http.get(apiUrl + 'api/universal/companymaster')
            .then(workComplete)
            .catch(workFailed);
    }

    function workComplete(response) {
        return response;
    }

    function workFailed(response) {
        $injector.get('errorsDataService').catch(response);
    }

    function getCompaniesByProductId(productId) {
        return $http.get(apiUrl + 'api/universal/companies/product/' + productId)
            .then(workComplete)
            .catch(workFailed);
    }
}
