import reportManagementController from "./reportManagement.controller";


/* reportManagement.component.js */

/**
 * @desc component for configuring settings for Report management
 * @example <report-management></report-management>
 */

const reportManagement = {
    bindings: {
        currentConfig: '<',
        originalConfig: '<',
        updateConfiguration: '&'
    },
    controller: reportManagementController,
    controllerAs: 'rmvm',
    templateUrl: '/App/Components/configuration/reportManagement/reportManagement.html'
};

export default reportManagement;