import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (helperService, manageDataDataService) {

    var vm = this;

    vm.executeAction = executeAction;
    vm.openStartDate = openStartDate;
    vm.openEndDate = openEndDate;

    vm.newDate = new Date();

    // Date picker settings
    vm.archiveStartDatePopup = {
        opened: false
    };
    vm.purgeStartDatePopup = {
        opened: false
    };
    vm.restoreStartDatePopup = {
        opened: false
    };

    vm.singleDateOptions = {
        showWeeks: false,
        formatYear: 'yy',
        startingDay: 1,
        maxDate: vm.newDate
    };

    vm.startDateOptions = {
        dateDisabled: disabled,
        showWeeks: false,
        formatYear: 'yy',
        startingDay: 1
    };

    vm.endDateOptions = {
        showWeeks: false,
        formatYear: 'yy',
        maxDate: vm.newDate,
        startingDay: 1
    };

    vm.startDatePopup = {
        opened: false
    };

    vm.endDatePopup = {
        opened: false
    };


    function activate() {
        helperService.getConfigurationValue('DefaultMitigationsOverwriteCurrent').then(data => {
            vm.DefaultMitigationsOverwriteCurrent = data;
        });

        ninetyDaysAgo();
        thirtyDaysAgo();
        today();
    }

    activate();

    function ninetyDaysAgo() {
        var ninetyDaysAgo = new Date();
        ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
        vm.archiveSingleDate = ninetyDaysAgo;
        vm.purgeSingleDate = ninetyDaysAgo;
    }

    function thirtyDaysAgo() {
        var thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        vm.restoreStartDate = thirtyDaysAgo;
    }

    function disabled(data) {
        var date = data.date,
            mode = data.mode;
        return mode === 'day' && date > vm.restoreEndDate;
    }

    function openStartDate() {
        vm.startDatePopup.opened = true;
    }

    //End Date picker settings
    function today() {
        vm.restoreEndDate = vm.newDate;
    }

    function openEndDate() {
        vm.endDatePopup.opened = true;
    }

    function executeAction() {

        vm.dataChangesProcessIsRunning = new createSpinner();

        let data = {};
        data.ProductId = vm.product.ID;

        switch (vm.action.name) {
            case 'Collect':
                data.ImportType = vm.action.type;
                break;
            case 'Archive':
                data.Date = vm.archiveSingleDate.toISOString();
                break;
            case 'Purge':
                data.Date = vm.purgeSingleDate.toISOString();
                break;
            case 'Restore':
                data.StartDate = vm.restoreStartDate.toISOString();
                data.EndDate = vm.restoreEndDate.toISOString();
                break;
        }

        manageDataDataService.dataActionCall(vm.action.name.toLowerCase(), data)
            .then(dataActionCallComplete)
            .catch(dataActionCallFailed);

        function dataActionCallComplete() {
            vm.dataChangesProcessIsRunning.loadingValue = false;
        }

        function dataActionCallFailed() {
            vm.dataChangesProcessIsRunning.loadingValue = false;
        }
    }
}
