import securityMigrationController from "./securityMigration.controller";

/* axSecurityDesignerSecurityMigration.component.js */

/**
 * @desc component for viewing review assignments
 * @example <ax-security-designer-security-migration></ax-security-designer-security-migration>
 */

const axSecurityDesignerSecurityMigration = {
    controller: securityMigrationController,
    controllerAs: 'smvm',
    templateUrl: '/App/Components/AXSecurityDesigner/SecurityMigration/securityMigration.html'
};

export default axSecurityDesignerSecurityMigration;