import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (modelValue, $uibModalInstance, helperService, myDownloadsDataService) {

    const vm = this;

    vm.dismiss = dismiss;
    vm.saveDownload = saveDownload;
    vm.editItem = modelValue.Name;

    async function saveDownload() {
        vm.saving = new createSpinner();
        const data = { 'Id': modelValue.Id, 'Name': vm.editItem };

        try {
            await myDownloadsDataService.saveNameChange(modelValue.Id, data);
            await helperService.successfulSaveButton(vm.saving);
            cancel();
        } catch {
            vm.saving.loadingValue = false;
        }
    }

    function cancel() {
        $uibModalInstance.close();
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }
}