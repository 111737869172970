import { OktaAuth } from '@okta/okta-auth-js';

export default /*@ngInject*/ function ($window, $location) {
    const service = {
        getAuthClient,
        getUser,
        isAuthenticated,
        login,
        handleAuthentication,
        logOut
    };

    return service;

    let authClient;

    function getAuthClient() {
        if (!authClient) {
            authClient = new OktaAuth({
                clientId: clientId,
                redirectUri: `${appUrl}callback`,
                issuer: issuer,
                postLogoutRedirectUri: appUrl + 'login'
            });
            // Triggered when a token has expired
            authClient.tokenManager.on('expired', async function (key, expiredToken) {
                console.log('Token with key', key, ' has expired:');
                console.log(expiredToken);
            });
            // Triggered when a token has been renewed
            authClient.tokenManager.on('renewed', function (key, newToken, oldToken) {
                console.log('Token with key', key, 'has been renewed');
                console.log('Old token:', oldToken);
                console.log('New token:', newToken);
            });
            // Triggered when an OAuthError is returned via the API (typically during token renew)
            authClient.tokenManager.on('error', function (err) {
                console.log('TokenManager error:', err);
                // err.name
                // err.message
                // err.errorCode
                // err.errorSummary
                // err.tokenKey
                // err.accessToken
            });
        }
        return authClient;
    }

    async function getUser() {
        let authClient = getAuthClient();
        const oktaIdToken = await authClient.tokenManager.get('idToken');
        if (oktaIdToken) {
            const decodedToken = await authClient.token.decode(oktaIdToken.idToken);
            const userInfo = decodedToken.payload;
            let isUserAuthenticated = await isAuthenticated();

            return Object.freeze({
                email: userInfo.email,
                fullName: userInfo.name,
                userId: userInfo.fastpathuserid,
                isAuthenticated: isUserAuthenticated
            });
        }
        else {
            login();
        }
    }

    async function isAuthenticated() {
        // Checks if there is a current accessToken in the TokenManger.
        let authClient = getAuthClient();
        let accessToken = await authClient.tokenManager.get('accessToken');
        return accessToken;
    }

    function login(originalUrl) {
        sessionStorage.setItem('okta-app-url', originalUrl || $location.url())

        let authClient = getAuthClient();
        authClient.token.getWithRedirect({
            responseType: 'id_token',
            scopes: [
                'openid',
                'email',
                'profile'
            ]
        });
    }

    async function handleAuthentication() {
        let authClient = getAuthClient();
        const response = await authClient.token.parseFromUrl();
        if (response.tokens.idToken) {
            authClient.tokenManager.add('idToken', response.tokens.idToken);
        }
        if (response.tokens.accessToken) {
            authClient.tokenManager.add('accessToken', response.tokens.accessToken);
        }
        // Retrieve the saved URL and navigate back
        let url = localStorage.getItem('locationBeforeRedirect');
        if (!url) { url = "" };
        $window.open(appUrl + url, '_self');
    }

    function logOut() {
        let authClient = getAuthClient();
        authClient.signOut();
        localStorage.clear();
    }
}