/* maintainUserGroups.component.js */

import manageDocumentationController from "./manageDocumentation.controller";

/**
 * @desc component for managing NetSuite Audit Trail documentation
 * @example <manage-documentation></manage-documentation>
 */

const manageDocumentation = {
    controller: manageDocumentationController,
    controllerAs: 'mdvm',
    templateUrl: '/App/Components/NetSuiteAuditTrail/ManageDocumentation/manageDocumentation.tmpl.html'
};

export default manageDocumentation;