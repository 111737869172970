export default /*@ngInject*/ function (review, $uibModalInstance) {
    const vm = this;

    vm.dismiss = dismiss;

    vm.linkedTickets = review.Tickets;

    function dismiss() {
        $uibModalInstance.dismiss();
    }
            
}