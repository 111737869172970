export default /*@ngInject*/ function ($http, errorsDataService, authenticationService, dataService) {
    var service = {
        getReviews,
        getReviewersByReviewId,
        checkForDelegateAllReviews,
        refreshReviewSheduleExecution,
        refreshReview,
        downloadRejectedItemValidation,
        sendEmailNotifications,
        exportReview,
        deleteReview,
        reprocessImRequests
    };

    return service;

    async function getReviews() {
        try {
            return await $http.get(`${apiUrl}api/certifications/reviewscheduleexecutions`);
        } catch (err) {
            showError(err);
        }
    }

    async function getReviewersByReviewId(reviewId) {
        try {
            return await $http.get(`${apiUrl}api/certifications/reviewscheduleexecutions/${reviewId}/reviews`);
        } catch (err) {
            showError(err);
        }
    }

    async function downloadRejectedItemValidation(id) {
        try {
            return await $http.post(`${apiUrl}api/certifications/reviews/${id}/items/validation/export`);
        } catch (err) {
            showError(err);
        }
    }

    async function sendEmailNotifications(id, data) {
        try {
            return await $http.post(`${apiUrl}api/certifications/reviewscheduleexecutions/${id}/sendnotifications`, data);
        } catch (err) {
            showError(err);
        }
    }

    async function checkForDelegateAllReviews(delegateAllReviews) {
        const user = await authenticationService.getUser();
        let response = await dataService.getClaims(user.userId);
        let data = response.data;

        return await data.some(data => data.Value === delegateAllReviews);
    }

    async function refreshReviewSheduleExecution(reviewId) {
        try {
            await $http.post(`${apiUrl}api/certifications/reviewscheduleexecutions/${reviewId}/refresh`);
        } catch (err) {
            showError(err);
        }
    }

    async function refreshReview(reviewId) {
        try {
            await $http.post(`${apiUrl}/api/certifications/reviews/${reviewId}/refresh`);
        } catch (err) {
            showError(err);
        }
    }

    async function deleteReview(reviewId) {
        try {
            return await $http.delete(`${apiUrl}api/certifications/reviewscheduleexecutions/${reviewId}`);
        } catch (err) {
            showError(err);
        }
    }

    async function exportReview(reviewId, data) {
        try {
            return await $http.post(`${apiUrl}api/certifications/reviewscheduleexecutions/${reviewId}/export`, data)
        } catch (err) {
            showError(err);
        }
    }

    async function reprocessImRequests(reviewId) {
        try {
            return await $http.put(`${apiUrl}api/certifications/reviews/${reviewId}/reprocessim`)
        } catch (err) {
            showError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

}