export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getApprovalGroups,
        getApprovalGroupLevels,
        updateGroup,
        updateGroupLevel,
        addApprovalGroup,
        addApprovalGroupLevel,
        addApproverToApprovalGroupLevel,
        removeApprovalGroup,
        removeApprovalGroupLevel,
        removeApproverFromApprovalGroupLevel,
        exportApprovalGroups,
        importApprovalGroups
    };

    return service;

    //GET Requests
    async function getApprovalGroups() {
        try {
            return await $http.get(`${apiUrl}api/universal/approvalgroups`);
        } catch (err) {
            showError(err);
        }
    }

    async function getApprovalGroupLevels(groupId) {
        try {
            return await $http.get(`${apiUrl}api/universal/approvalgroups/${groupId}/levels`);
        } catch (err) {
            showError(err);
        }
    }

    //PUT Requests
    async function updateGroup(groupId, data) {
        try {
            return await $http.put(`${apiUrl}api/universal/approvalgroups/${groupId}`, data);
        } catch (err) {
            showError(err);
        }
    }

    async function updateGroupLevel(groupId, levelId, data) {
        try {
            return await $http.put(`${apiUrl}api/universal/approvalgroups/${groupId}/levels/${levelId}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    //POST Requests
    async function addApprovalGroup(data) {
        try {
            return await $http.post(`${apiUrl}api/universal/approvalgroups`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addApprovalGroupLevel(groupId, data) {
        try {
            return await $http.post(`${apiUrl}api/universal/approvalgroups/${groupId}/levels`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addApproverToApprovalGroupLevel(groupId, levelId, data) {
        try {
            return await $http.post(`${apiUrl}api/universal/approvalgroups/${groupId}/levels/${levelId}/approvers`, data);
        } catch (err) {
            showError(err);
        }
    }

    //DELETE Requests
    async function removeApprovalGroup(groupId) {
        try {
            return await $http.delete(`${apiUrl}api/universal/approvalgroups/${groupId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function removeApprovalGroupLevel(groupId, levelId) {
        try {
            return await $http.delete(`${apiUrl}api/universal/approvalgroups/${groupId}/levels/${levelId}`);
        } catch (err) {
            showError(err);
        }
    }

    async function removeApproverFromApprovalGroupLevel(groupId, levelId, userId) {
        try {
            return await $http.delete(`${apiUrl}api/universal/approvalgroups/${groupId}/levels/${levelId}/approvers/${userId}`);
        } catch (err) {
            showError(err);
        }
    }

    async function exportApprovalGroups() {
        try {
            return await $http.get(`${apiUrl}api/universal/approvalgroups/export`, { responseType: "blob" });
        } catch (err) {
            showError(err);
        }
    }

    async function importApprovalGroups(data) {
        try {
            return await $http.put(`${apiUrl}api/universal/approvalgroups/import`, data, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}
