import tableFieldsModalController from "./tableFieldsModal.controller";

/* tableFieldsModal.component.js */

/**
 * @desc component for managing table fields for Security Designer privileges
 * @example <table-fields-modal></table-fields-modal>
 */

const tableFieldsModal = {
    bindings: {
        resolve: '<',
        modalInstance: '<'
    },
    controller: tableFieldsModalController,
    controllerAs: 'tfmvm',
    templateUrl: '/App/Shared/Components/TableFieldsModal/tableFieldsModal.tmpl.html'
};

export default tableFieldsModal;
