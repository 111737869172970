import searchController from "./searchModal/search.controller";

export default /*@ngInject*/ function ($scope, $filter, $uibModal, salesforceService, errorsDataService, manageDataDataService, $http) {

    const salesforceUserMinDate = new Date();

    salesforceUserMinDate.setHours(0, 0, 0, 0);

    if (!$scope.vm.User.SalesforceUser) {
        $scope.vm.User.SalesforceUser = {};
    }

    $scope.vm.User.SalesforceUser.ProductType = "SF";
    $scope.vm.openSalesforceSearchModal = openSearchModal;
    $scope.vm.existingSalesforceUserCheck = existingUserCheck;
    $scope.userFromDateChanged = userFromDateChanged;
    $scope.userToDateChanged = userToDateChanged;
    $scope.vm.salesforceUserMinDate = salesforceUserMinDate;
    $scope.vm.selectUser = selectUser;
    $scope.vm.User.SalesforceUser.ProfileId = $scope.vm.User.SalesforceUser?.ProfileId || "";
    $scope.vm.handleSalesforceCountrySelection = handleCountrySelection;
    $scope.vm.clearSalesforceInput = clearInput;
    $scope.vm.selectedManager = "";
    $scope.vm.selectedApprover = "";
    $scope.vm.salesforceEmailChangeMessage = "To ensure system security, the owner of the new email address must verify this change. When the request is submitted, an email will be sent to the specified address with directions on confirming this change."

    activate();

    async function activate() {
        setFieldConfigurationValues();
        let { data: countries } = await salesforceService.getCountries();
        $scope.vm.salesforceCountriesDatasource = $filter('orderBy')(countries, 'Name');
        $scope.vm.salesforceStatesDatasource = $filter('orderBy')(countries.first()?.States || [], 'Name');
        if ($scope.vm.User.SalesforceUser.Country) {
            handleCountrySelection();
        }
    }

    async function handleCountrySelection() {
        $scope.vm.salesforceStatesDatasource = $scope.vm.salesforceCountriesDatasource.find(country => country.Name === $scope.vm.User.SalesforceUser.Country).States;
    }

    async function existingUserCheck(username) {
        try {
            const { data } = await salesforceService.getUsers(username, "username"),
                currentId = $scope.vm.User?.SalesforceUser?.Id;

            if (data?.length && data?.first().Id !== currentId) {
                $scope.wizardForm.Username.$setValidity("Username", false);
                $scope.vm.salesforceUserExists = true;
            } else {
                $scope.wizardForm.Username.$setValidity("Username", true);
                $scope.vm.salesforceUserExists = false;
            }
        } catch (err) {
            errorsDataService.catch(err);
        }
    };

    $scope.$watch("vm.User.SalesforceUser.Username", () => {
        if ($scope.vm.User.SalesforceUser?.Username && $scope.vm.wizardMode === 'New') {
            existingUserCheck($scope.vm.User.SalesforceUser.Username)
        }
    });

    async function openSearchModal(type) {
        let searchModal = $uibModal.open({
            templateUrl: '/App/Components/RequestManagement/WizardSteps/Salesforce/searchModal/search.tmpl.html',
            controller: searchController,
            controllerAs: 'svm',
            backdrop: 'static',
            resolve: {
                requestUserId: () => $scope.vm.User.SalesforceUser.Id,
                type: () => type
            }
        });

        try {
            let result = await searchModal.result;

            if (type === "manager") {
                $scope.vm.User.SalesforceUser.ManagerId = result.Id;
                $scope.vm.selectedManager = result.Name;
            } else {
                $scope.vm.User.SalesforceUser.DelegateApproverId = result.Id;
                $scope.vm.selectedApprover = result.Name;
            }
            
        } catch { return; }
    }

    function clearInput(type) {
        if (type === "Approver") {
            $scope.vm.User.SalesforceUser.DelegateApproverId = null;
            $scope.vm.selectedApprover = "";
        } else {
            $scope.vm.User.SalesforceUser.ManagerId = null;
            $scope.vm.selectedManager = "";
        }
    }

    function userFromDateChanged () {
        var date;

        if ($scope.vm.User.SalesforceUser.StartDate === '') {
            date = new Date();
        } else {
            date = new Date($scope.vm.User.SalesforceUser.StartDate);
        }

        $scope.vm.salesforceUserMinDate = date;
        $scope.vm.fromMinDate = date;

        if ($scope.vm.User.SalesforceUser == null) {
            $scope.vm.User.SalesforceUser = {};
        }
    };

    function userToDateChanged() {
        $scope.vm.salesforceUserMaxDate = new Date($scope.vm.User.SalesforceUser.EndDate);
        $scope.vm.fromMaxDate = new Date($scope.vm.User.SalesforceUser.StartDate);
        if ($scope.vm.User.SalesforceUser == null) {
            $scope.vm.User.SalesforceUser = {};
        }
    };

    async function setFieldConfigurationValues() {
        $scope.vm.showADUserLookup = $scope.vm.config.find(config => config.Name === 'AD_Active').Value === '1';
        $scope.vm.showAADUserLookup = $scope.vm.config.find(config => config.Name === 'AAD_Active').Value === '1';
        let response = await manageDataDataService.getConfigurationByProductId('SF');
        $scope.vm.disableADUserFields = response.data.find(config => config.Name === 'IM_DisableADUserFields').Value === '1' && $scope.vm.showADUserLookup;
        $scope.vm.disableAADUserFields = response.data.find(config => config.Name === 'IM_DisableAADUserFields').Value === '1' && $scope.vm.showAADUserLookup;
    }

    function selectUser(user) {
        $scope.vm.User.SalesforceUser = user;
        if (user.UserType !== 'Guest') {
            setProvider();
        }
    }

    async function setProvider() {
        const { data } = await $http.get(`${apiUrl}api/universal/products/ORFC/configuration/im_provider`);
        $scope.vm.User.SalesforceUser.NetworkDomain = data.Value;
    }
}