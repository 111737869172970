import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($scope, $http, helperService) {

    var manageDataVm = $scope.manageDataVm = {};

    manageDataVm.archiveData = function () {
        manageDataVm.dataProcessIsRunning = new createSpinner();
        $http.put(apiUrl + 'api/audittrail/auditdata/archive', { Date: $scope.vm.singleDate, RecordTypes: [] }).then(function (response) {
            //helperService.showConfirmationMessage("Attention", "This process has been initiated.");
            manageDataVm.dataProcessIsRunning.loadingValue = false;
        }, function errorCallback(response) {
            helperService.showErrorMessage(response.data);
            manageDataVm.dataProcessIsRunning.loadingValue = false;
        });
    };

    $scope.helperService.getConfigurationValue('DefaultMitigationsOverwriteCurrent').then(function (data) {
        vm.DefaultMitigationsOverwriteCurrent = data;
    });
}
