import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, securityMigrationDataService, helperService, migrationDetail, migrationHeaderId) {
    const vm = this;

    vm.migrationDetail = { ...migrationDetail };
    vm.saveMigrationDetail = saveMigrationDetail;
    vm.cancel = cancel;

    async function saveMigrationDetail() {
        vm.migrationDetailsSavePending = new createSpinner();
        const data = {
            Id: vm.migrationDetail.Id,
            Name: vm.migrationDetail.Name,
            Label: vm.migrationDetail.Label,
            Description: vm.migrationDetail.Description,
            Enabled: vm.migrationDetail.Enabled
        };
        try {
            await securityMigrationDataService.updateSecurityMigrationDetails(migrationHeaderId, data);
            await helperService.successfulSaveButton(vm.migrationDetailsSavePending);
            $uibModalInstance.close();
        } catch {
            vm.migrationDetailsSavePending.loadingValue = false;
            return;
        }
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}