import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (autoMitigationsDataService, product, $uibModalInstance, AutoMitigationDataService, helperService) {

    const vm = this;

    vm.product = product;

    vm.shouldBeOpen = true;
    vm.cancel = cancel;
    vm.importRuleset = importRuleset;
    vm.dismiss = dismiss;

    function importRuleset(file) {
        vm.importInProgress = new createSpinner();

        let fd = new FormData();
        fd.append(file[0].name, file[0].file);

        if (vm.product) {
            importDynamicProductAutoMitigationRuleset(vm.product, fd);
        } else {
            importOracleAutoMitigationRuleset(fd);
        }
    }

    async function importDynamicProductAutoMitigationRuleset(product, file) {
        try {
            await autoMitigationsDataService.importAutoMitigationRuleset(product, file);
            await helperService.successfulSaveButton(vm.importInProgress);
            cancel();
        } catch {
            vm.importInProgress.loadingValue = false;
        }
    }

    async function importOracleAutoMitigationRuleset(file) {
        try {
            await AutoMitigationDataService.importAutoMitigationRuleset(file);
            await helperService.successfulSaveButton(vm.importInProgress);
            cancel();
        } catch {
            vm.importInProgress.loadingValue = false;
        }
    }

    function cancel() {
        $uibModalInstance.close();
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }
}