import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, $http, helperService, domain) {

    const vm = this;
    vm.cancel = cancel;
    vm.saveDomain = saveDomain;

    vm.domain = { ...domain };

    // Common functions
    function cancel() { $uibModalInstance.dismiss(); }

    async function saveDomain() {
        vm.savePending = new createSpinner();
        if (vm.domain.Id) {
            try {
                await $http.put(`${apiUrl}/api/plugins/activedirectory/domains/${vm.domain.Id}`, vm.domain);
                await helperService.successfulSaveButton(vm.savePending);
                $uibModalInstance.close();
            } catch (error) {
                helperService.showErrorMessage(error.data);
                vm.savePending.loadingValue = false;
            }
        } else {
            try {
                await $http.post(`${apiUrl}/api/plugins/activedirectory/domains`, vm.domain);
                await helperService.successfulSaveButton(vm.savePending);
                $uibModalInstance.close();
            } catch (error) {
                helperService.showErrorMessage(error.data);
                vm.savePending.loadingValue = false;
            }
        }
    }

}