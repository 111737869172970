import riskEligibilityController from "./riskEligibility.controller";

/* riskEligibility.component.js */

/**
 * @desc component for configuring product risk eligibility in Identity Manager
 * @example <arm-product-risk-eligibility></arm-product-risk-eligibility>
 */

const armProductRiskEligibility = {
    bindings: {
        product: '<'
    },
    controller: riskEligibilityController,
    controllerAs: 'revm',
    templateUrl: '/App/Components/Configuration/riskEligibility/riskEligibility.tmpl.html'
};

export default armProductRiskEligibility;