import createSpinner from "../../Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, $http, errorsDataService, helperService, reportId) {
    const vm = this;
    vm.cancel = cancel;
    vm.disableButton = false;
    vm.importDataChangesReport = importDataChangesReport;
    vm.reportId = reportId;

    function cancel() { $uibModalInstance.dismiss(); }

    // Import control functions
    async function importDataChangesReport(file) {
        vm.importUpdatePending = new createSpinner();
        vm.disableButton = true;
        let fd = new FormData();
        fd.append(file[0].name, file[0].file);
        try {
            let routeString;
            if (vm.reportId === 'b280b98b-f119-4b3a-8d2d-fccef20e9195') {
                routeString = 'api/audittrail/ns/places/datachanges/import';
            } else {
                routeString = 'api/audittrail/ns/v2/places/allchanges/import'
            }
            await $http.put(apiUrl + routeString, fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
            await helperService.successfulSaveButton(vm.importUpdatePending);
            $uibModalInstance.close();
        } catch (err) {
            vm.importUpdatePending.loadingValue = false;
            vm.disableButton = false;
            showError(err);
            sendError(err);
        };
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }
}