import createSpinner from "../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import editImportUserModalController from "./editImportUsersModal/editImportUserModal.controller";
import systemUserMappingModalController from "./systemUserMappingModal/systemUserMappingModal.controller";

export default /*@ngInject*/ function (maintainSystemUsersDataService, helperService, $uibModal, $filter, $scope, FileSaver) {

    const vm = this;

    vm.openEditImportModal = openEditImportModal;
    vm.openSystemUserMappingModal = openSystemUserMappingModal;
    vm.exportSystemUsers = exportSystemUsers;
    vm.generateAssignments = generateAssignments;

    $scope.$on('AutoMapFastpathToSystemUsers', (event, args) => vm.systemUsersGridDataSource.read());

    configureSystemUsersGrid();

    async function configureSystemUsersGrid() {
        const systemUsersGridColumns = [
            { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
            { field: "Email", title: "Email", filterable: customAutoCompleteFilter },
            { field: "Department", title: "Department", filterable: customAutoCompleteFilter },
            { field: "JobTitle", title: "Job Title", filterable: customAutoCompleteFilter },
            { field: "Location", title: "Location", filterable: customAutoCompleteFilter },
            { field: "BusinessUnit", title: "Business Unit", filterable: customAutoCompleteFilter },
            { field: "PrimarySystemUserId", title: "Primary System User ID", filterable: customAutoCompleteFilter },
            { field: "PrimarySystemProduct", title: "Primary System Product", filterable: customAutoCompleteFilter },
            { template: `<a ng-click="msugvm.openEditImportModal(dataItem, 'Edit')" ng-disabled="msugvm.disabled"><i class="glyphicon glyphicon-edit text-warning m-r-xs"></i>Edit</a>`, "title": "Edit", width: 90 },
            { field: "FpUserMappingStatus", title: "Mapping Status", filterable: customAutoCompleteFilter },
            { field: "FpUserName", title: "Mapped to", filterable: customAutoCompleteFilter },
            { template: `<a ng-click="msugvm.openSystemUserMappingModal(dataItem)" ng-disabled="msugvm.disabled">System User Mapping</a>`, "title": "System User Mapping", width: 150 }
        ];

        vm.systemUsersGridOptions = helperService.setMainGridOptions(systemUsersGridColumns, 600);
        vm.systemUsersGridOptions.autoBind = false;

        vm.systemUsersGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    try {
                        let { data } = await maintainSystemUsersDataService.getMasterUsers();
                        data = $filter('orderBy')(data, 'Name');
                        options.success(data);
                    } catch (err) {
                        options.error(err);
                    }
                }
            }
        });

        await vm.systemUsersGridDataSource.read();
    }

    async function openEditImportModal(user, modalType) {

        const editImportModal = $uibModal.open({
            templateUrl: '/App/Components/maintainSystemUsers/editImportUsersModal/editImportUsersModal.tmpl.html',
            controller: editImportUserModalController,
            controllerAs: 'eiumvm',
            backdrop: 'static',
            resolve: {
                user: () => user,
                modalType: () => modalType
            }
        });
        try {
            await editImportModal.result;
            vm.systemUsersGridDataSource.read();
        } catch {
            return;
        }
    }

    async function openSystemUserMappingModal(user) {

        const mappingModal = $uibModal.open({
            templateUrl: '/App/Components/maintainSystemUsers/systemUserMappingModal/systemUserMappingModal.html',
            controller: systemUserMappingModalController,
            controllerAs: 'summvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                user: () => user
            }
        });
        try {
            await mappingModal.result;
            vm.systemUsersGridDataSource.read();
        } catch {
            return;
        }
    }

    async function exportSystemUsers() {
        vm.exportUpdatePending = new createSpinner();
        try {
            let { data } = await maintainSystemUsersDataService.exportSystemUsers();
            await helperService.successfulSaveButton(vm.exportUpdatePending);
            FileSaver.saveAs(data, 'UserIdentities.xlsx');
        } catch {
            vm.exportUpdatePending.loadingValue = false;
        }
    }

    async function generateAssignments() {
        vm.generatingAssignments = true;
        await maintainSystemUsersDataService.generateAssignments();
        vm.generatingAssignments = false;
    }
}