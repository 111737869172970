/* systemConfigProductConfiguration.component.js */

import systemConfigProductConfigurationController from "./systemConfigProductConfiguration.controller";

/**
 * @desc component for configuring product specific settings for System Configuration
 * @example <system-config-product-configuration></system-config-product-configuration>
 */

const systemConfigProductConfiguration = {
    bindings: {
        products: '<',
        autoMapConfig: '<'
    },
    controller: systemConfigProductConfigurationController,
    controllerAs: 'scpcvm',
    templateUrl: '/App/Components/Configuration/systemConfigProductConfiguration/systemConfigProductConfiguration.tmpl.html'
};

export default systemConfigProductConfiguration;