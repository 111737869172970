export default /*@ngInject*/ function ($http, errorsDataService) {
    var factory = {
        getAllRoles,
        exportExcludedRoles,
        importExcludedRoles,
        updateExcludedRole
    };

    return factory;

    async function getAllRoles() {
        try {
            return await $http.get(`${apiUrl}/api/universal/securityroles`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function exportExcludedRoles() {
        try {
            return await $http.get(`${apiUrl}/api/universal/securityroles/exclude/export`, { responseType: "blob" });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function importExcludedRoles(fd) {
        try {
            return await $http.put(`${apiUrl}/api/universal/securityroles/exclude/import`, fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateExcludedRole(data) {
        try {
            return await $http.put(`${apiUrl}api/universal/securityroles/${data.SecurityRoleIdentifier}/exclude`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

}