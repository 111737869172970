export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getCompanyStatistics,
        getCompanyMapping,
        getCompanyAutoMap,
        createAutoMap,
        createMapping,
        createBulkMapping,
        deleteCompanyMaster,
        deleteProductMapping
    };

    return service;

    async function getCompanyStatistics() {
        try {
            return await $http.get(`${apiUrl}api/universal/companymapping/statistics/`);
        } catch (err) {
            showError(err);
        }
    }

    async function getCompanyMapping() {
        try {
            return await $http.get(`${apiUrl}api/universal/companymaster/companymapping`);
        } catch (err) {
            showError(err);
        }
    }

    async function getCompanyAutoMap() {
        try {
            return await $http.get(`${apiUrl}api/universal/automap/company`);
        } catch (err) {
            showError(err);
        }
    }

    async function createAutoMap(data) {
        try {
            return await $http.post(`${apiUrl}api/universal/automap/company`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function createMapping(data) {
        try {
            return await $http.post(`${apiUrl}api/universal/companymapping/`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function createBulkMapping(data) {
        try {
            return await $http.post(`${apiUrl}api/universal/automap/company/bulkmap`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteCompanyMaster(companyId) {
        try {
            return await $http.delete(`${apiUrl}api/universal/companymaster/${companyId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteProductMapping(companyMapping, companyId) {
        try {
            return await $http.delete(`${apiUrl}api/universal/companymapping/companymaster/${companyMapping.CompanyMasterID}/product/${companyMapping.ProductID}/company/${companyId}/`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}