import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($scope, $uibModalInstance, $filter, $timeout, helperService, $http, row, grid, statusDataService) {

    const vm = this;

    $scope.originalMitigation = $scope.riskToMitigate.MitigationID;
    $scope.originalStatus = $scope.riskToMitigate.StatusID;
    $scope.originalMitigationNotes = $scope.riskToMitigate.MitigationNotes;

    function activate() {

        getItemPillLabels();
        getStandardMitigations();
        getMitigations();
        setupAdditionalMitigationsObject();
        getAdditionalCompaniesDataSource();

        if (row.RequestMitigation === 'Edit') {
            populateModalFieldsWithEditedMitigationRequestData();
        } else {
            setupDefaultModalFields();
        }
    }

    activate();

    function getStandardMitigations() {
        $http.get(apiUrl + 'api/core/configuration/ARM_StandardMitigations').then(function (response) {
            $scope.standardMitigations = response.data;
        });
    }

    function getControlLibrary() {
        $scope.controlsLoading = true;
        return $http.get(apiUrl + 'api/arm/controls').then(function (response) {
            $scope.controlLibrary = response.data;
            $scope.controlsLoading = false;
        });
    }

    function setupControls() {
        $scope.selectedControls = new Array();
        $scope.originalControls = new Array();
        return;
    }

    function getStatuses() {
        statusDataService.getStatuses().then(function (response) {
            response.data = $filter('filter')(response.data, { CanCurrentUserAccess: true });
            response.data = $filter('orderBy')(response.data, 'Name');
            $scope.statuses = response.data;
        });
    }

    function populateModalFieldsWithEdits() {

        $scope.UserMitigations.some(mitigation => {

            if (row.RiskId === mitigation.RiskId && row.CompanyMasterID === mitigation.CompanyMasterId) {

                if (mitigation.Controls && mitigation.Controls.length > 0) {
                    $scope.selectedControls = new Array();
                    mitigation.Controls.forEach(control => {
                        $scope.controlLibrary.forEach(option => {
                            if (option.Identifier === control) {
                                $scope.selectedControls.push(option);
                            }
                        });
                    });
                    $scope.controlsLoading = false;
                }

                if (mitigation.MitigationId && mitigation.MitigationId !== null) {
                    $scope.riskToMitigate.MitigationID = mitigation.MitigationId;
                }
                $scope.riskToMitigate.StatusID = mitigation.StatusId;
                $scope.riskToMitigate.MitigationNotes = mitigation.MitigationNotes;
                // Request Mitigation in additional companies is not populated.
            }
        });
    }

    function setupDefaultModalFields() {
        getControlLibrary().then(_ => setupControls()).then(_ => getStatuses());
    }

    function populateModalFieldsWithEditedMitigationRequestData() {
        getControlLibrary().then(_ => setupControls()).then(_ => getStatuses()).then(_ => populateModalFieldsWithEdits());
    }

    function getAdditionalCompaniesDataSource() {

        $scope.mitigateAddtionalDataSource = [];

        grid.forEach(item => {
            if (item.RiskId === row.RiskId && item.CompanyName !== row.CompanyName) {
                $scope.mitigateAddtionalDataSource.push(item);
            }
        });
    }

    function getItemPillLabels() {
        $http.get(apiUrl + 'api/arm/risks/' + $scope.riskToMitigate.RiskId).then(function (response) {
            $scope.riskObjects = response.data;
        });
    }

    function getMitigations() {
        $http.get(apiUrl + 'api/arm/mitigations').then(function (response) {
            response.data = $filter('orderBy')(response.data, 'Name');
            $scope.mitigations = response.data;
        });
    }

    function setupAdditionalMitigationsObject() {
        $scope.mitigatedRisk = {
            selectedMitigateAddtional: '',
            companyIds: ''
        };
    }

    $scope.requestRiskMitigation = function (event) {


        let userMitigation = {
            "RequestMitigationId": $scope.riskToMitigate.RequestMitigationId,
            "CompanyMasterId": $scope.riskToMitigate.CompanyMasterID,
            "RiskId": $scope.riskToMitigate.RiskId,
            "MitigationId": $scope.riskToMitigate.MitigationID,
            "MitigationNotes": $scope.riskToMitigate.MitigationNotes,
            "StatusId": $scope.riskToMitigate.StatusID,
            "Controls": $scope.selectedControls.map(x => x.Identifier)
        };

        if ($scope.riskToMitigate.MitigationID) {
            userMitigation.Controls = null;
        } else if ($scope.selectedControls) {
            userMitigation.MitigationId = null;
        }

        $scope.riskToMitigate.updateInProgress = new createSpinner();

        $scope.UserMitigations.forEach(mitigation => {
            // Remove previous mitigation request for this risk.
            if (row.RequestMitigation === 'Edit' && mitigation.RiskId === userMitigation.RiskId && mitigation.CompanyMasterId === userMitigation.CompanyMasterId) {
                $scope.UserMitigations.remove(mitigation);
            }
        });

        grid.forEach(item => {
            // Create status for new request.
            if (item.RiskId === userMitigation.RiskId && item.CompanyMasterID === userMitigation.CompanyMasterId) {
                item.RequestMitigation = 'Edit';
            }
        });

        // Push new request to UserMitigations array.
        $scope.UserMitigations.push(userMitigation);

        //Remove $timeout when additional companies are mitigated.
        $timeout(function () {
            $scope.killModal = true;
            helperService.successfulSaveButton($scope.riskToMitigate.updateInProgress).then(function (result) {
                $scope.riskToMitigate.updateInProgress.loadingValue = false;
                $scope.mitigateInAdditionalCompanies();
                $uibModalInstance.close();
                $scope.$parent.userRisksGridDataSource.read();
            });
        }, 1000);
    }, function errorCallback(response) {
        $scope.riskToMitigate.updateInProgress.loadingValue = false;
        helperService.showErrorMessage(response.data);
    };

    $scope.mitigateInAdditionalCompanies = function () {
        if ($scope.mitigatedRisk !== null && $scope.mitigatedRisk.additionalCompanies !== null) {
            if ($scope.mitigatedRisk.additionalCompanies && $scope.mitigatedRisk.additionalCompanies.length > 0) {
                mitigateRisk();
            }
        }
        function mitigateRisk() {
            $scope.mitigatedRisk.additionalCompanies.forEach(company => {

                let additionalUserMitigation = {
                    "RequestMitigationId": $scope.riskToMitigate.RequestMitigationId,
                    "CompanyMasterId": company.CompanyMasterID,
                    "RiskId": $scope.riskToMitigate.RiskId,
                    "MitigationId": $scope.riskToMitigate.MitigationID,
                    "MitigationNotes": $scope.riskToMitigate.MitigationNotes,
                    "StatusId": $scope.riskToMitigate.StatusID,
                    "Controls": $scope.selectedControls.map(x => x.Identifier)
                };

                if ($scope.riskToMitigate.MitigationID) {
                    additionalUserMitigation.Controls = null;
                } else if ($scope.selectedControls) {
                    additionalUserMitigation.MitigationId = null;
                }

                grid.forEach(item => {
                    $scope.UserMitigations.forEach(mitigation => {
                        // Remove previous mitigation request for this risk.
                        if (item.RequestMitigation === 'Edit' && mitigation.RiskId === additionalUserMitigation.RiskId && mitigation.CompanyMasterId === additionalUserMitigation.CompanyMasterId) {
                            $scope.UserMitigations.remove(mitigation);
                        }
                    });
                    // Create status for new request.
                    if (item.RiskId === additionalUserMitigation.RiskId && item.CompanyMasterID === additionalUserMitigation.CompanyMasterId) {
                        item.RequestMitigation = 'Edit';
                    }
                });
                // Push new request to UserMitigations array.
                $scope.UserMitigations.push(additionalUserMitigation);
            });
        }
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}
