import NotificationTypesListController from "./notificationTypesList.controller";

/* notificationTypesList.component.js */

/**
 * @desc component for displaying and selecting notification types
 * @example <notification-types-list></notification-types-list>
 */

const notificationTypesList = {
    bindings: {
        getSubscriptions: '<',
        getNotificationTypes: '<',
        allSubscriptions: '<',
        mySubscriptions: '<',
        notificationTypes: '<',
        setupToggleState: '<',
        products: '<',
        loading: '<'
    },
    controller: NotificationTypesListController,
    controllerAs: 'ntlvm',
    templateUrl: '/App/Components/Notifications/notificationTypesList/notificationTypesList.tmpl.html'
};

export default notificationTypesList;