import signReportController from "./signReport.controller";

/* signReport.component.js */

/**
 * @desc component for signing reports
 * @example <sign-report></sign-report>
 */

const signReport = {
   bindings: {
      resolve: '<',
      modalInstance: '<'
   },
   controller: signReportController,
   controllerAs: 'srvm',
   templateUrl: '/App/Shared/Components/SignReport/signReport.tmpl.html'
};

export default signReport;