import swal from "sweetalert2";
import requesterGroupUserModalController from "./requesterGroupUserModal.controller";

export default /*@ngInject*/ function (requesterGroupsDataService, helperService, $uibModal) {

    const vm = this;

    vm.removeUserFromRequesterGroup = removeUserFromRequesterGroup;
    vm.openUserModal = openUserModal;

    vm.$onChanges = activate;

    async function activate() {
        getUsers()
    }

    async function getUsers() {
        vm.usersLoading = true;
        const response = await requesterGroupsDataService.getRequesterGroupUsers(vm.requesterGroup.Id);
        vm.users = response.data;
        vm.usersLoading = false;
    }

    async function openUserModal() {
        let requesterGroupModal = $uibModal.open({
            templateUrl: '/App/Components/RequesterGroups/requesterGroupUsersList/requesterGroupUserModal.tmpl.html',
            controller: requesterGroupUserModalController,
            controllerAs: 'rgumvm',
            backdrop: 'static',
            resolve: {
                selectedUsers: () => vm.users,
                requesterGroup: () => vm.requesterGroup
            }
        });

        try {
            await requesterGroupModal.result;
            await getUsers();
        } catch { return; }
    }

    async function removeUserFromRequesterGroup(user) {
        try {
            await swal(helperService.areYouSureParams(`Remove User`, `Are you sure you want to remove User ${user.UserName} from Requester Group ${vm.requesterGroup.Name}?`, `Yes`, 'No'))
            await requesterGroupsDataService.removeUserFromGroup(vm.requesterGroup.Id, user.Id);
            getUsers();
        } catch {
            return;
        }
    }
}