import { customAutoCompleteFilter, customBooleanFilter, standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";
import notesModalController from "../notesModal/notesModal.controller";

export default /*@ngInject*/ function (category, configValues, reviewStatus, requireNotes, $uibModalInstance, $uibModal, $timeout, myReviewsDataService) {
    const vm = this;
    vm.category = category;
    vm.configValues = configValues;
    vm.reviewStatus = reviewStatus;
    vm.requireNotes = requireNotes;

    vm.filterCheckAllStatus = filterCheckAllStatus;
    vm.checkAllStatus = checkAllStatus;
    vm.changeItemStatus = changeItemStatus;
    vm.updateAllItemStatuses = updateAllItemStatuses;
    vm.openNotesModal = openNotesModal;
    vm.close = close;

    activate();

    function activate() {
        vm.securityRoleName = category.Items[0].SecurityRoleName;
        vm.categoryType = category.Items[0].RoleContentType;
        setupFilterTypes();
        vm.itemsRadioFilter = 'All';
        vm.allItemStatus = checkAllStatus(vm.filteredReviewItems);
    }

    function setupFilterTypes() {
        switch (vm.categoryType) {
            case 'BusinessProcess':
                vm.reviewitemsSortKey = ['BusinessProcessName'];
                vm.reviewItemsFilterKey = ['BusinessProcessName', 'RulesetName', 'SecurityRoleName', 'SecurityObjectAltName', 'SecurityObjectName', 'SecurityObjectTypeName', 'Notes'];
                break;
            case 'Risk':
                vm.reviewitemsSortKey = ['RiskName'];
                vm.reviewItemsFilterKey = ['RiskName', 'RulesetName', 'SecurityRoleName', 'Notes'];
                break;
            case 'RoleAccess':
                vm.reviewitemsSortKey = ['SecurityObjectAltName'];
                vm.reviewItemsFilterKey = ['SecurityObjectAltName', 'SecurityObjectName', 'Notes'];
                break;
        }
    }

    function filterCheckAllStatus() {
        $timeout(_ => {
            vm.allItemStatus = checkAllStatus(vm.filteredReviewItems);
            if (vm.filteredReviewItems.length === 0) {
                vm.hideSelectAll = true;
            } else {
                vm.hideSelectAll = false;
            }
        }, 1);
    }

    function checkAllStatus(reviewItems) {
        if (!reviewItems) {
            reviewItems = vm.category.Items;
        }
        if (reviewItems.length > 0) {
            if (reviewItems.every(item => item.StatusId === 1)) {
                return '1';
            } else if (reviewItems.every(item => item.StatusId === 2)) {
                return '2';
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    async function changeItemStatus(item) {
        if (requireNotes && item.StatusId === 2) {
            let itemNotesModal = $uibModal.open({
                templateUrl: 'App/Components/Certifications/MyReviews/notesModal/notesModal.tmpl.html',
                controller: notesModalController,
                controllerAs: 'nmvm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    reviewItem: () => item,
                    notesRequired: () => requireNotes,
                    allowCancel: () => false
                }
            });

            try {
                let itemNotes = await itemNotesModal.result;
                item.Notes = itemNotes;
            } catch {
                return;
            }
        }
        const types = category.Items.slice();
        types.remove(item);

        updateItemStatus(item);
    }

    async function updateItemStatus(item) {
        vm.processingItemStatus = true;

        if (!item.StatusId && item.MultiApprovalStatus === 'Complete') {
            item.MultiApprovalStatus = 'Pending';
        }

        let putBody = {
            "ReviewItems": []
        };

        putBody.ReviewItems.push({
            "HeaderId": item.HeaderId,
            "ItemId": item.ItemId,
            "Status": item.StatusId,
            "Notes": item.Notes
        });

        await myReviewsDataService.updateItemStatus(item.HeaderId, 'SecurityRoleContent', putBody);
        vm.processingItemStatus = false;
        vm.allItemStatus = checkAllStatus(vm.filteredReviewItems);
    }

    async function updateAllItemStatuses(newValue) {
        vm.processingItemStatus = true;

        let putBody = {
            "ReviewItems": []
        };

        let items = vm.filteredReviewItems;

        if (items.every(item => item.StatusId === newValue)) {
            newValue = null;
        }

        // if they require notes for rejected items we need to capture notes and apply them to all the rejected items
        if (newValue === 2 && requireNotes) {
            let itemNotesModal = $uibModal.open({
                templateUrl: 'App/Components/Certifications/MyReviews/notesModal/notesModal.tmpl.html',
                controller: notesModalController,
                controllerAs: 'nmvm',
                backdrop: 'static',
                keyboard: false,
                resolve: {
                    reviewItem: () => null,
                    notesRequired: () => requireNotes,
                    allowCancel: () => false
                }
            });

            try {
                var itemNotes = await itemNotesModal.result;
            } catch {
                return;
            }
        }

        items.forEach(item => {
            if (item.MultiApprovalStatus !== 'Complete') {
                item.StatusId = newValue;
                if (itemNotes) {
                    item.Notes = itemNotes;
                }
                putBody.ReviewItems.push({
                    "HeaderId": item.HeaderId,
                    "ItemId": item.ItemId,
                    "Status": item.StatusId,
                    "Notes": item.Notes
                });
            }
        });

        await myReviewsDataService.updateItemStatus(putBody.ReviewItems[0].HeaderId, 'SecurityRoleContent', putBody);
        vm.allItemStatus = checkAllStatus(vm.filteredReviewItems);
        vm.processingItemStatus = false;
    }

    async function openNotesModal(item) {
        let itemNotesModal = $uibModal.open({
            templateUrl: 'App/Components/Certifications/MyReviews/notesModal/notesModal.tmpl.html',
            controller: notesModalController,
            controllerAs: 'nmvm',
            backdrop: 'static',
            resolve: {
                reviewItem: () => item,
                notesRequired: () => requireNotes,
                allowCancel: () => true
            }
        });

        try {
            let itemNotes = await itemNotesModal.result;
            item.Notes = itemNotes;
            updateItemStatus(item);
        } catch {
            return;
        }
    }

    function close() {
        $uibModalInstance.close();
    }
}