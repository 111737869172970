export default /*@ngInject*/ function ($log) {
    return async function (exception) {
        var localTenant = localStorage.getItem("selectedTenant");

        if (localTenant) {
            localTenant = JSON.parse(localTenant);
        } else {
            localTenant = {};
        }

        rg4js('send', {
            error: exception,
            tags: [localTenant.Name, localTenant.ID]
        });

        $log.error(exception);
    };
}