export default /*@ngInject*/ function (templatesDataService) {

    const vm = this;
    vm.updateTemplate = updateTemplate;

    vm.$onInit = getTemplates;

    async function getTemplates() {
        let response = await templatesDataService.getTemplates('ORFC');
        vm.templatesDataSource = response.data;
    }

    async function updateTemplate(template) {
        const data = {
            ProductId: template.Id,
            Enabled: template.Enabled
        };
        try {
            await templatesDataService.updateTemplate(data);
        } catch (err) {
            if (template.Enabled) {
                template.Enabled = false;
            } else {
                template.Enabled = true;
            }
        }
    }
}