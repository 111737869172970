import editRoleOrgHierarchiesController from "./editRoleOrgHierarchies.controller";


/* editRoleOrgHierarchies.component.js */

/**
 * @desc component for selecting org hierarchies to associate to a role for an IM request
 * @example <im-role-org-hierarchies></im-role-org-hierarchies>
 */

const imRoleOrgHierarchies = {
    bindings: {
        selectedRole: '<',
        onUpdateOrgHierarchies: '&'
    },
    controller: editRoleOrgHierarchiesController,
    controllerAs: 'erohvm',
    templateUrl: '/App/Shared/Components/imRoleOrgHierarchies/editRoleOrgHierarchies.tmpl.html'
};

export default imRoleOrgHierarchies;