/* notificationTeams.component.js */

import notificationTeamsController from "./notificationTeams.controller";

/**
 * @desc component to view and manage the Audit and Security teams and their members
 * @example <notification-teams></notification-teams>
 */

const notificationTeams = {
    controller: notificationTeamsController,
    controllerAs: 'ntvm',
    templateUrl: '/App/Components/Certifications/notificationTeams/notificationTeams.tmpl.html'
};

export default notificationTeams;