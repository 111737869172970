export default /*@ngInject*/ function ($scope) {

    //If $scope variable doesnt exist in child it looks in parent
    $scope.validateNumberOfSAPRoleObjects = validateNumberOfSAPRoleObjects;

    $scope.addRoleObjectToList = function (roleObject, productName) {
        $scope.vm.ListOfAvailableSAPRoleObjects[productName].forEach(function (row, i) {
            if (row.Id === roleObject.Id) {
                $scope.vm.ListOfAvailableSAPRoleObjects[productName].splice(i, 1);
                $scope.vm.ListOfSelectedSAPRoleObjects[productName].push(row);
            }
        });
        $scope.vm.IsRisksReviewed = null;
        validateNumberOfSAPRoleObjects(productName);
    };

    $scope.deleteSelectedRoleObject = function (roleObject, productName) {
        $scope.vm.ListOfSelectedSAPRoleObjects[productName].forEach(function (row, i) {
            if (row.Id === roleObject.Id) {
                $scope.vm.ListOfSelectedSAPRoleObjects[productName].splice(i, 1);
                $scope.vm.ListOfAvailableSAPRoleObjects[productName].push(row);
            }
        });
        $scope.vm.IsRisksReviewed = null;
        validateNumberOfSAPRoleObjects(productName);
    };

    function validateNumberOfSAPRoleObjects(productName) {
        if (!$scope.vm.numberOfSAPRoleObjectsValid) {
            $scope.vm.numberOfSAPRoleObjectsValid = [];
        }
        $scope.vm.numberOfSAPRoleObjectsValid[productName] = null;
        if ($scope.vm.ListOfSelectedSAPRoleObjects[productName].length > 0) {
            $scope.vm.numberOfSAPRoleObjectsValid[productName] = true;
        }
    }
}