export default function () {
    const vm = this;
    
    vm.overrideById = overrideById;

    function overrideById (row) {
        switch (row.Id) {
            case "162d34b9-8bf3-4216-8789-14a15ad9f790":
                row.Icon = "icons8-user-menu-male";
                break;
            case "8bbeb9d7-9a8c-4cc5-ae49-8140094933a0":
                row.Icon = "icons8-business-building";
                break;
            case "d64548f3-75bb-4b0e-8182-c6aaa34d7021":
                row.Icon = "icons8-warning-shield";
                break;
            case "b39d354f-bfda-4478-8a2c-cbce21f6f30b":
                row.Icon = "icons8-info";
                break;
            case "41fa31b1-7599-465b-944b-b59e83ce9983":
                row.Icon = "icons8-play-2";
                break;
            case "f84d3aa4-e22d-4550-99af-4d35c48f1294":
                row.Icon = "icons8-star";
                break;
        }
    }
}