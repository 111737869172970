import angular from 'angular';
import auditTrailNetSuiteFactoriesModule from './auditTrailNetSuite.factories.module';
import maintainTemplatesController from '../Components/NetSuiteAuditTrail/MaintainTemplates/maintainTemplates.controller';
import netsuiteDataChangesConfiguration from '../Components/DataChangesConfiguration/netsuiteDataChangesConfiguration.component';
import maintainOptionSets from '../Components/NetSuiteAuditTrail/MaintainOptionSets/maintainOptionSets.component';
import snapshotChanges from '../Components/SnapshotChanges/snapshotChanges.component';
import maintainUserGroups from '../Components/NetSuiteAuditTrail/MaintainUserGroups/maintainUserGroups.component';
import snapshotComparison from '../Components/SnapshotComparison/snapshotComparison.component';
import snapshotConfiguration from '../Components/SnapshotConfiguration/snapshotConfiguration.component';
import manageDocumentation from '../Components/NetSuiteAuditTrail/ManageDocumentation/manageDocumentation.component';
import allChanges from '../Components/NetSuiteAuditTrail/AllChanges/allChanges.component';
import netsuiteDataChanges from '../Components/DataChangesNetSuite/netsuiteDataChanges.component';

export default angular
    .module('app.auditTrail.netSuite', [auditTrailNetSuiteFactoriesModule])
    .controller("maintainTemplatesController", maintainTemplatesController)
    .component("snapshotConfiguration", snapshotConfiguration)
    .component("netsuiteDataChangesConfiguration", netsuiteDataChangesConfiguration)
    .component("maintainOptionSets", maintainOptionSets)
    .component('snapshotChanges', snapshotChanges)
    .component('maintainUserGroups', maintainUserGroups)
    .component('snapshotComparison', snapshotComparison)
    .component('manageDocumentation', manageDocumentation)
    .component('allChanges', allChanges)
    .component('netsuiteDataChanges', netsuiteDataChanges)
    .name;