export default /*@ngInject*/ function ($http, errorsDataService) {
    const factory = {
        getRisks,
        getBusinessProcesses,
        getRiskRulesetList,
        downloadRulesetTemplate,
        cloneRiskRuleset,
        importRiskRuleset,
        setDefaultRiskRuleset,
        updateRiskRuleset,
        addNewRiskRuleset,
        deleteRiskRuleset,
        addNewRisk,
        updateRisk,
        copyObjectsToRuleset,
        addNewRiskObject
    };

    return factory;


    // GET Requests
    async function getRisks(rulesetId) {
        try {
            return await $http.get(`${apiUrl}api/arm/risks/ruleset/${rulesetId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getBusinessProcesses(rulesetId) {
        try {
            return await $http.get(`${apiUrl}api/arm/businessprocesses/ruleset/${rulesetId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getRiskRulesetList() {
        try {
            return await $http.get(`${apiUrl}api/arm/riskrulesets`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function downloadRulesetTemplate(product) {
        try {
            return await $http.get(`${apiUrl}api/arm/riskrulesets/download`, { responseType: "blob", params: {productType: product}});
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    // POST Requests
    async function cloneRiskRuleset(data) {
        try {
            return await $http.post(`${apiUrl}api/arm/riskrulesets/clone`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addNewRiskRuleset(data) {
        try {
            return await $http.post(`${apiUrl}api/arm/riskrulesets`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addNewRisk(data) {
        try {
            return await $http.post(`${apiUrl}api/arm/risks`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addNewRiskObject(riskId, data) {
        try {
            return await $http.post(`${apiUrl}api/arm/risks/${riskId}/objects`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function copyObjectsToRuleset(objectType, data) {
        try {
            return await $http.post(`${apiUrl}api/arm/riskrulesets/copy-${objectType}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    // PUT Requests
    async function importRiskRuleset(data) {
        try {
            return await $http.put(`${apiUrl}api/arm/risks/import`, data, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function setDefaultRiskRuleset(rulesetId, data) {
        try {
            return await $http.put(`${apiUrl}api/arm/riskrulesets/default/${rulesetId}`, data);
        } catch (err) {
            showError(err);
        }
    }

    async function updateRiskRuleset(rulesetId, data) {
        try {
            return await $http.put(`${apiUrl}api/arm/riskrulesets/${rulesetId}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateRisk(riskId, data) {
        try {
            return await $http.put(`${apiUrl}api/arm/risks/${riskId}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    // DELETE Requests
    async function deleteRiskRuleset(rulesetId) {
        try {
            return await $http.delete(`${apiUrl}api/arm/riskrulesets/${rulesetId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }
}