import auditLogsController from "./auditLogs.controller";

/* auditLogs.component.js */

/**
 * @desc component for viewing audit logs
 * @example <audit-logs></audit-logs>
 */

const auditLogs = {
    controller: auditLogsController,
    controllerAs: 'alvm',
    templateUrl: '/App/Components/AuditLogs/auditLogs.tmpl.html'
};

export default auditLogs;