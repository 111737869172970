import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, securityMigrationDataService, helperService, migration) {
    const vm = this;

    vm.migration = { ...migration };
    vm.saveMigration = saveMigration;
    vm.cancel = cancel;

    async function saveMigration() {
        vm.migrationSavePending = new createSpinner();
        try {
            if (vm.migration.Id) {
                const data = {
                    Id: vm.migration.Id,
                    Name: vm.migration.Name
                };
                await securityMigrationDataService.updateSecurityMigration(data);
                await helperService.successfulSaveButton(vm.migrationSavePending);
            } else {
                let fd = new FormData();
                const fileObject = vm.file.flow.files[0];
                const fileName = fileObject.name;
                const file = fileObject.file;
                fd.append('SecurityFile', file);
                fd.append('Name', vm.migration.Name);
                await securityMigrationDataService.addSecurityMigration(fd);
                await helperService.successfulSaveButton(vm.migrationSavePending);
            }
            $uibModalInstance.close();
        } catch {
            vm.migrationSavePending.loadingValue = false;
            return;
        }


    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}