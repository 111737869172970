export default /*@ngInject*/ function ($http, errorsDataService) {
    const factory = {
        getSimulationAnalysisStats,
        getRiskAnalysisReportStatistics,
        exportRiskAnalysisReportDetails,
        analyzeSimulation
    };

    return factory;

    async function getSimulationAnalysisStats(simulationId) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/analysis/statistics`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getRiskAnalysisReportStatistics(simulationId, type) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/analysis/${type}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function exportRiskAnalysisReportDetails(simulationId, type) {
        try {
            const res = await $http.get(`${apiUrl}api/securityDesigner/sap/simulations/${simulationId}/analysis/${type}/export`, { responseType: 'blob' });
            return res;
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function analyzeSimulation(data) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/simulation/${data.SimulationId}/analyze`, data);
        } catch (err) {
            showError(err);
        }
    }

    function showError(error) {
        errorsDataService.catch(error);
    }

    function sendError(error) {
        throw error;
    }

}