import userGroupModalController from "./userGroupModal.controller";
import userModalController from "./UserModal/userModal.controller";

export default /*@ngInject*/ function ($scope, $filter, $uibModal, helperService, userGroupsDataService, objectIdDataService) {

    var vm = this;

    vm.openUserGroupModal = openUserGroupModal;
    vm.changeUserGroupSelected = changeUserGroupSelected;
    vm.deleteUserGroup = deleteUserGroup;
    vm.openUserModal = openUserModal;
    vm.removeUserGroupUser = removeUserGroupUser;

    function activate() {
        getUserGroups(null);
    }

    activate();

    async function openUserGroupModal(userGroup) {

        let userGroupModal = $uibModal.open({
            templateUrl: '/App/Components/NetSuiteAuditTrail/MaintainUserGroups/userGroupModal.html',
            controller: userGroupModalController,
            controllerAs: 'ugmvm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                userGroup: () => userGroup || {}
            }
        });

        try {
            let response = await userGroupModal.result;
            getUserGroups(response);
        } catch {
            return;
        }
    }

    async function openUserModal() {

        let userModal = $uibModal.open({
            templateUrl: '/App/Components/NetSuiteAuditTrail/MaintainUserGroups/UserModal/userModal.html',
            controller: userModalController,
            controllerAs: 'umvm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                userGroup: () => vm.selectedUserGroup,
                usersDataSource: () => vm.usersDataSource
            }
        });

        try {
            await userModal.result;
            changeUserGroupSelected(vm.selectedUserGroup);
        } catch {
            return;
        }
    }

    vm.userGroupMenuOptions = [
        {
            text: 'Edit',
            click: function ($itemScope, $event, modelValue) {
                var item = modelValue;
                vm.openUserGroupModal(item);
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: function ($itemScope, $event, modelValue) {
                var item = modelValue;
                vm.deleteUserGroup(item);
            }
        }
    ];

    async function getUserGroups(preselect) {
        let response = await userGroupsDataService.getUserGroups();
        response.data = $filter('orderBy')(response.data, 'UserGroupUserName');
        vm.userGroupsDataSource = response.data;
        if (preselect) {
            response.data.forEach(row => {
                if (row.Id === preselect) {
                    vm.selectedUserGroup = row;
                    vm.changeUserGroupSelected(vm.selectedUserGroup);
                }
            });

        }

    }

    async function changeUserGroupSelected(userGroup) {
        vm.selectedUserGroup = userGroup;
        vm.changeUserGroupInProgress = true;
        vm.usersDataSource = null;

        if (vm.selectedUserGroup) {
            let response = await userGroupsDataService.getUsersByUserGroupId(vm.selectedUserGroup.Id);
            response.data = $filter('orderBy')(response.data, 'Name');
            vm.usersDataSource = response.data;
            vm.changeUserGroupInProgress = false;
        } else {
            vm.changeUserGroupInProgress = false;
        }
    }

    async function deleteUserGroup(userGroup) {
        if (vm.selectedUserGroup === userGroup) {
            vm.selectedUserGroup = null;
        }
        await userGroupsDataService.deleteUserGroup(userGroup.Id);
        getUserGroups(null);
    }

    async function removeUserGroupUser(userGroupId, userGroupUserId) {
        await userGroupsDataService.removeUserGroupUser(userGroupId, userGroupUserId);
        changeUserGroupSelected(vm.selectedUserGroup);
    }

}