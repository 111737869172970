import companyTemplateCompaniesListController from "./companyTemplateCompaniesList.controller";

const companyTemplateCompaniesList = {
    bindings: {
        template: '<',
        onUpdateCompanies: '&'
    },
    controller: companyTemplateCompaniesListController,
    controllerAs: 'ctclvm',
    templateUrl: '/App/Components/CompanyTemplates/AssociatedCompaniesList/companyTemplateCompaniesList.tmpl.html'
};

export default companyTemplateCompaniesList;