import { standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($scope, $uibModalInstance, data, helperService) {

    var vm = $scope.vm = {};

    //Search Existing Users
    var getDuplicateGridColumns = function () {
        return [
            { field: "FirstName", title: "First Name", filterable: false },
            { field: "LastName", title: "Last Name", filterable: false },
            { field: "Email", title: "Email", filterable: false }
        ];
    };

    vm.DuplicateUserGridOptions = helperService.setMainGridOptions(getDuplicateGridColumns(), 350);
    vm.DuplicateUserGridOptions.autoBind = true;

    $scope.DuplicateNetSuiteUserGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        data: data.NetSuiteUsers
    });

    vm.changeSelectedUser = function (kendoEvent) {

        var grid = kendoEvent.sender;
        var selectedExistingUser = grid.dataItem(grid.select());

        var User = {};
        User.Applications = [];
        User.UserMaster = data.UserMasterRecord;

        User.NetSuiteUser = selectedExistingUser;
        $uibModalInstance.close(User);
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };
}