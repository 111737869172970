import { customAutoCompleteFilter, customDatePickerFilter, customYesNoBooleanFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($filter, $http, $location, helperService) {

    const vm = this;

    vm.helperService = helperService;

    vm.saveConfiguration = saveConfiguration;
    vm.viewDetails = viewDetails;

    async function activate() {
        vm.origValue = await helperService.getConfigurationValue('IM_ReminderThreshold');
        vm.IM_ReminderThreshold = vm.origValue;

        createOutstandingApprovalsGridDataSource();

        const columnsFromVM = [
            { field: "RequestorName", title: "Requestor Name", filterable: customAutoCompleteFilter },
            { field: "GeneralUser.Name", title: "Affected User", filterable: customAutoCompleteFilter },
            { field: "GeneralUser.Email", title: "Affected User Email", filterable: customAutoCompleteFilter },
            { field: "AffectedType", title: "Affected Type", filterable: customAutoCompleteFilter, hidden: true },
            { field: "TicketIds", title: "Ticket ID", filterable: customAutoCompleteFilter, width: 100 },
            { field: "RequestedDate", title: "Request Date", template: "{{dataItem.RequestedDate | date:'short'}}", filterable: customDatePickerFilter },
            { field: "ExpirationDate", title: "Expiration Date", template: "{{dataItem.ExpirationDate | date:'short'}}", filterable: customDatePickerFilter },
            { field: "Action", template: "{{ dataItem.Action | insertSpaceBetweenCapitalWords }}", filterable: customAutoCompleteFilter },
            { field: "Status", template: "<span class='label' ng-class='oagvm.helperService.setStatusCssClass(dataItem.Status)'>{{dataItem.Status | insertSpaceBetweenCapitalWords}}</span>", filterable: customAutoCompleteFilter },            { field: "CanIReview", title: "Requires Your Review", template: "{{dataItem.CanIReview | booleanToYesNo}}", filterable: customYesNoBooleanFilter },
            { template: "<a ng-click=\"oagvm.viewDetails(dataItem)\">Details</a>", "title": "Details", width: 100 }
        ];

        vm.mainGridOptions = helperService.setMainGridOptions(columnsFromVM, 600);
    }

    activate();

    function saveConfiguration() {
        vm.origValue = vm.IM_ReminderThreshold;
        helperService.setConfigurationValueClosePopup('IM_ReminderThreshold', vm.IM_ReminderThreshold, vm);
    }

    function createOutstandingApprovalsGridDataSource() {
        vm.gridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    try {
                        let { data } = await $http.get(`${apiUrl}api/identitymanager/requests/outstanding`);
                        data = $filter('orderBy')(data, 'RequestedDate', true);

                        data.forEach(row => {
                            if (row.AffectedObject) {
                                let AffectedObject = JSON.parse(row.AffectedObject);
                                if (AffectedObject) {
                                    row["AffectedObjectUserName"] = AffectedObject.Name;
                                }
                            }
                        });
                        options.success(data);
                    } catch {
                        options.error();
                    }
                }
            },
            schema: {
                model: {
                    fields: {
                        RequestedDate: { type: "date" },
                        ExpirationDate: { type: "date" },
                        CanIReview: { type: "boolean" },
                    }
                }
            }
        });
    }

    function viewDetails(dataItem) {
        $location.path('/IdentityManager/RequestDetails').search({ RequestId: dataItem.Id, Version: dataItem.Version });
    }
}
