import swal from "sweetalert2";
import axSecurityDesignerAffectedObjectsModalController from "../axSecurityDesignerAffectedObjectsModal.controller";
import NewAXSecurityLayerSecurityDesignerController from "../NewAXSecurityLayerSecurityDesignerController";
import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($scope, $uibModal, $filter, AXSecurityDesignerService, objectIdDataService, $http, helperService) {

    $scope.helperService = helperService;

    objectIdDataService.getObjectID().then(function (objectId) {
        $scope.ObjectId = objectId;

        var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;

    });

    var vm = $scope.vm = {};
    vm.disableFilter = true;
    $scope.currentPage = 1;
    $scope.itemsPerPage = 4;

    $scope.searchChanged = function () {
        var filteredResults = $filter('filterResults')(vm.securityDesignerRolesTree, vm.searchBox, ['Name', 'SystemName']);
        if (!vm.disableFilter) {
            filteredResults = $filter('filter')(filteredResults, { IsModified: true });
        }
        $scope.filteredCount = filteredResults.length;
    };

    vm.deleteRole = function (role) {

        if (role.IsCustom) {
            $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + vm.selectedSimulation.Id + '/securitylayer/roles').then(function (response) {
                var securityLayer = response.data.filter(e => e.Name.toLowerCase() === role.Name.toLowerCase())[0];
                $http.delete(apiUrl + 'api/securitydesigner/ax7/simulation/securitylayer/' + securityLayer.Id).then(function (response) {
                    vm.simulationSelected();
                }, function errorCallback(response) {
                    return;
                });
            }, function errorCallback(response) {
                return;
            });
        } else {

            var message = "Are you sure you want to remove this role?";

            swal(helperService.areYouSureParams('Remove Role', message, 'Remove Role')).then(function () {
                var postBody = {
                    "SimulationId": vm.selectedSimulation.Id,
                    "SystemName": role.SystemName,
                    "Type": "Role"
                };

                $http.post(apiUrl + 'api/securitydesigner/ax7/simulation/securitylayer/removals', postBody).then(function (response) {
                    role.Action = "Remove";
                    role.IsModified = true;
                    $scope.determineModifiedArrays();
                }, function errorCallback(response) {
                    helperService.showErrorMessage(response.data);
                });
            }, function (dismiss) {
                return;
            });
        }
    };

    vm.openAffectedObjectsModal = function (selectedRole) {

        selectedRole.securityLayerType = 1;

        $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/axSecurityDesignerAffectedObjectsModal.html',
            controller: axSecurityDesignerAffectedObjectsModalController,
            controllerAs: 'vm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                item: () => selectedRole
            }
        });
    };

    vm.openCloneRoleModal = function (privilege) {
        $scope.selectedSecurityObject = privilege;
        $scope.securityObjectType = 'Role';

        $uibModal.open({
            templateUrl: '/App/Shared/Partials/CloneSecurityModal.html',
            controller: cloneRoleModal,
            backdrop: 'static',
            scope: $scope
        });
    };


    $scope.openAXNewSecurityLayerSecurityDesignerModal = function () {
        $scope.securityLayerType = 'Role';
        $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/NewAXSecurityLayerSecurityDesigner.html',
            controller: NewAXSecurityLayerSecurityDesignerController,
            backdrop: 'static',
            scope: $scope,
            resolve: {
                productType: () => $scope.productType || false
            }
        });
    };

    $http.get(apiUrl + 'api/securitydesigner/simulation').then(function (response) {
        response.data.remove(function (n) { return n['Status'] === 'Deployed'; });
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.securityModels = response.data;

        var securityModelInLocalStorage = localStorage.getItem("LastSelectedAX7SecurityModel");
        var selected = vm.securityModels.find(e => e.Id.toString() === securityModelInLocalStorage);
        if (securityModelInLocalStorage && selected) {
            vm.selectedSimulation = selected;
        } else {
            vm.selectedSimulation = response.data[0];
        }
        vm.simulationSelected();
    }, function errorCallback(response) {
        return;
    });

    $scope.determineModifiedArrays = function () {
        vm.securityDesignerRolesTree.forEach(function (row) {
            var dutiesModifiedResultOfRemove = row.Duties.filter(e => e.IsModified === true && e.Action === 'Remove').length;
            var privilegesModifiedResultOfRemove = row.Privileges.filter(e => e.IsModified === true && e.Action === 'Remove').length;
            var subRolesModifiedResultOfRemove = row.SubRoles.filter(e => e.IsModified === true && e.Action === 'Remove').length;

            row.DutiesModified = row.Duties.filter(e => e.IsModified === true).length;
            row.PrivilegesModified = row.Privileges.filter(e => e.IsModified === true).length;
            row.SubRolesModified = row.SubRoles.filter(e => e.IsModified === true).length;
            row.TablesModified = row.Tables.filter(e => e.IsModified === true).length;

            row.GrantedDuties = row.Duties.filter(e => e.Action === "Grant").length;
            row.GrantedPrivileges = row.Privileges.filter(e => e.Action === "Grant").length;
            row.GrantedSubRoles = row.SubRoles.filter(e => e.Action === "Grant").length;
            row.GrantedTables = row.Tables.filter(e => e.Read !== "Unset" || e.Create !== "Unset" || e.Update !== "Unset" || e.Delete !== "Unset").length;

            if (dutiesModifiedResultOfRemove === row.DutiesModified && privilegesModifiedResultOfRemove === row.PrivilegesModified && subRolesModifiedResultOfRemove === row.SubRolesModified) {
                row.ModifiedResultOfAllRemoves = true;
            } else {
                row.ModifiedResultOfAllRemoves = false;
            }

            if (row.DutiesModified > 0 || row.PrivilegesModified > 0 || row.SubRolesModified > 0 || row.TablesModified > 0 || row.IsCustom || row.Action === "Remove") {
                row.IsModified = true;
            } else {
                row.IsModified = false;
            }
        });
        vm.numberModified = vm.securityDesignerRolesTree.filter(e => e.IsModified === true).length;
    };

    vm.simulationSelected = function () {
        vm.disableFilter = true;
        vm.searchBox = '';
        if (vm.selectedSimulation) {

            if (vm.selectedSimulation.Status === "Analyzing" || vm.selectedSimulation.Status === "Locked") {
                $scope.pageIsReadOnly = true;
            } else {
                $scope.pageIsReadOnly = false;
            }

            localStorage.setItem("LastSelectedAX7SecurityModel", vm.selectedSimulation.Id);
            vm.rolesLoading = true;
            $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + vm.selectedSimulation.Id + '/roleaccess').then(function (response) {
                vm.rolesLoading = false;
                AXSecurityDesignerService.populateSystemNameIfCustom(response.data);
                vm.securityDesignerRolesTree = response.data;
                $scope.determineModifiedArrays();

                $scope.filteredCount = response.data.length;

            }, function errorCallback(response) {
                vm.rolesLoading = false;
            });

            $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + vm.selectedSimulation.Id + '/roles').then(function (response) {
                response.data.forEach(function (row) { row.SystemName = row.SystemName || row.Name; });
                vm.securityDesignerRoles = response.data;
            }, function errorCallback(response) {
                return;
            });

            $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + vm.selectedSimulation.Id + '/duties').then(function (response) {
                response.data.forEach(function (row) { row.SystemName = row.SystemName || row.Name; });
                vm.securityDesignerDuties = response.data;
            }, function errorCallback(response) {
                return;
            });

            $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + vm.selectedSimulation.Id + '/privileges').then(function (response) {
                response.data.forEach(function (row) { row.SystemName = row.SystemName || row.Name; });
                vm.securityDesignerPrivileges = response.data;
            }, function errorCallback(response) {
                return;
            });

            $http.get(apiUrl + 'api/securitydesigner/ax7/tables').then(function (response) {
                vm.securityDesignerTables = response.data;
            }, function errorCallback(response) {
                return;
            });
        }
    };

    vm.undoChangesToRole = function (role) {
        swal(helperService.areYouSureParams('Undo Changes', 'Are you sure you want to undo this change?', 'Undo')).then(function () {

            var putBody = {
                "SimulationId": vm.selectedSimulation.Id,
                "ObjectId": role.SystemName,
                "ObjectType": "Role"
            };

            $http.put(apiUrl + 'api/securitydesigner/ax7/simulation/' + vm.selectedSimulation.Id + '/revert', putBody).then(function (response) {
                vm.simulationSelected();
            }, function errorCallback(response) {
                helperService.showErrorMessage(response.data);
            });

        }, function (dismiss) {
            return;
        });
    };

    vm.undoRemoveRole = function (role) {
        swal(helperService.areYouSureParams('Add Role Back', 'Are you sure you want to add this role back?', 'Add Role')).then(function () {

            $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + vm.selectedSimulation.Id + '/securitylayer/removals').then(function (response) {
                var securityLayer = response.data.filter(e => e.SystemName.toLowerCase() === role.SystemName.toLowerCase())[0];
                $http.delete(apiUrl + 'api/securitydesigner/ax7/simulation/securitylayer/removals/' + securityLayer.Id).then(function (response) {
                    vm.simulationSelected();
                }, function errorCallback(response) {
                    return;
                });
            }, function errorCallback(response) {
                return;
            });

        }, function (dismiss) {
            return;
        });
    };

    vm.openPrivilegesModal = function (item) {
        $scope.objectOpenInModal = item;
        var currentPrivileges = item.Privileges;

        var columns = [
            { title: "Active", field: "SelectedCheckbox", template: `<label ng-disabled='pageIsReadOnly' ng-show="dataItem.Action !== 'Remove'" class='switch'><input type='checkbox' ng-model='dataItem.SelectedCheckbox' ng-change='modalvm.selectPrivilege(dataItem)'><i></i></label>`, width: 55, filterable: false },
            { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
            { title: "System Name", field: "SystemName", template: "<span ng-if='!dataItem.IsCustom'>{{dataItem.SystemName}}</span>", filterable: customAutoCompleteFilter },
            { title: "Description", field: "Description", filterable: customAutoCompleteFilter },
            { title: "License", field: "License", filterable: customAutoCompleteFilter }
        ];

        if (!item.IsCustom) {
            columns.push({ title: " ", field: "IsModified", template: `<a ng-disabled='pageIsReadOnly' ng-click='deleteModificationToItem(dataItem)' ng-if="dataItem.IsModified && dataItem.Action !== 'Remove'" class='icons8-undo-2 pull-right'></a>`, width: 50, filterable: false });
        }

        vm.manageSecurityLayerGridOptions = helperService.setMainGridOptions(columns, null);
        vm.manageSecurityLayerGridOptions.selectable = false;

        vm.manageSecurityLayerGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            sort: { field: "Name", dir: "asc" },
            transport: {
                read: function (options) {
                    vm.selectedSecurityDesignerPrivileges = vm.securityDesignerPrivileges.slice();

                    vm.selectedSecurityDesignerPrivileges.forEach(function (row) {
                        row.SelectedCheckbox = false;
                        row.IsModified = false;
                    });

                    vm.selectedSecurityDesignerPrivileges.forEach(function (row) {

                        for (var i = 0; i < currentPrivileges.length; i++) {
                            if (row.SystemName === currentPrivileges[i].SystemName) {
                                row.IsModified = currentPrivileges[i].IsModified;
                                row.Action = currentPrivileges[i].Action;
                                if (currentPrivileges[i].Action === "Grant") {
                                    row.SelectedCheckbox = true;
                                }
                                break;
                            }
                        }
                    });

                    vm.selectedSecurityDesignerPrivileges = $filter('orderBy')(vm.selectedSecurityDesignerPrivileges, 'Name');
                    options.success(vm.selectedSecurityDesignerPrivileges);
                }
            }
        });

        $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/Roles/ManageRoleAXSecurityDesigner.html',
            controller: AddAXPrivilegesToRoleSecurityDesigner,
            backdrop: 'static',
            size: 'lg',
            scope: $scope
        });
    };

    vm.openDutiesModal = function (item) {
        $scope.objectOpenInModal = item;
        var currentDuties = item.Duties;

        var columns = [
            { title: "Active", field: "SelectedCheckbox", template: `<label ng-disabled='pageIsReadOnly' ng-show="dataItem.Action !== 'Remove'" class='switch'><input type='checkbox' ng-model='dataItem.SelectedCheckbox' ng-change='modalvm.selectDuty(dataItem)'><i></i></label>`, width: 55, filterable: false },
            { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
            { title: "System Name", field: "SystemName", template: "<span ng-if='!dataItem.IsCustom'>{{dataItem.SystemName}}</span>", filterable: customAutoCompleteFilter },
            { title: "Description", field: "Description", filterable: customAutoCompleteFilter },
            { title: "License", field: "License", filterable: customAutoCompleteFilter }
        ];

        if (!item.IsCustom) {
            columns.push({ title: " ", field: "IsModified", template: `<a ng-disabled='pageIsReadOnly' ng-click='deleteModificationToItem(dataItem)' ng-if="dataItem.IsModified && dataItem.Action !='Remove'" class='icons8-undo-2 pull-right'></a>`, width: 50, filterable: false });
        }

        vm.manageSecurityLayerGridOptions = helperService.setMainGridOptions(columns, null);
        vm.manageSecurityLayerGridOptions.selectable = false;

        vm.manageSecurityLayerGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            sort: { field: "Name", dir: "asc" },
            transport: {
                read: function (options) {
                    vm.selectedSecurityDesignerDuties = vm.securityDesignerDuties.slice();

                    vm.selectedSecurityDesignerDuties.forEach(function (row) {
                        row.SelectedCheckbox = false;
                        row.IsModified = false;
                    });

                    vm.selectedSecurityDesignerDuties.forEach(function (row) {

                        for (var i = 0; i < currentDuties.length; i++) {
                            if (row.SystemName === currentDuties[i].SystemName) {
                                row.IsModified = currentDuties[i].IsModified;
                                row.Action = currentDuties[i].Action;
                                if (currentDuties[i].Action === "Grant") {
                                    row.SelectedCheckbox = true;
                                }
                                break;
                            }
                        }
                    });

                    vm.selectedSecurityDesignerDuties = $filter('orderBy')(vm.selectedSecurityDesignerDuties, 'Name');
                    options.success(vm.selectedSecurityDesignerDuties);
                }
            }
        });

        $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/Roles/ManageRoleAXSecurityDesigner.html',
            controller: AddAXDutiesToRoleSecurityDesigner,
            backdrop: 'static',
            size: 'lg',
            scope: $scope
        });
    };

    vm.openTablesModal = function (item) {
        $scope.objectOpenInModal = item;

        AXSecurityDesignerService.createTablesModalDataSource(item, vm);

        $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/Roles/ManageRoleAXSecurityDesigner.html',
            controller: AddAXTablesToRoleSecurityDesigner,
            backdrop: 'static',
            size: 'lg',
            scope: $scope
        });
    };

    vm.openSubRolesModal = function (item) {
        $scope.objectOpenInModal = item;
        var currentSubRoles = item.SubRoles;

        var columns = [
            { title: "Active", field: "SelectedCheckbox", template: `<label ng-disabled='pageIsReadOnly' ng-show="dataItem.Action !== 'Remove'" class='switch'><input type='checkbox' ng-model='dataItem.SelectedCheckbox' ng-change='modalvm.selectSubRole(dataItem)'><i></i></label>`, width: 55, filterable: false },
            { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
            { title: "System Name", field: "SystemName", template: "<span ng-if='!dataItem.IsCustom'>{{dataItem.SystemName}}</span>", filterable: customAutoCompleteFilter },
            { title: "Description", field: "Description", filterable: customAutoCompleteFilter },
            { title: "License", field: "License", filterable: customAutoCompleteFilter }
        ];

        if (!item.IsCustom) {
            columns.push({ title: " ", field: "IsModified", template: `<a ng-disabled='pageIsReadOnly' ng-click='deleteModificationToItem(dataItem)' ng-if="dataItem.IsModified && dataItem.Action !== 'Remove'" class='icons8-undo-2 pull-right'></a>`, width: 50, filterable: false });
        }

        vm.manageSecurityLayerGridOptions = helperService.setMainGridOptions(columns, null);
        vm.manageSecurityLayerGridOptions.selectable = false;

        vm.manageSecurityLayerGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            sort: { field: "Name", dir: "asc" },
            transport: {
                read: function (options) {
                    vm.selectedSecurityDesignerRoles = vm.securityDesignerRoles.slice();
                    vm.selectedSecurityDesignerRoles.forEach(function (row, i) {
                        row.SelectedCheckbox = false;
                        row.IsModified = false;
                        if (item.Name === row.Name) {
                            vm.selectedSecurityDesignerRoles.remove(row);
                        }
                    });

                    vm.selectedSecurityDesignerRoles.forEach(function (row) {

                        for (var i = 0; i < currentSubRoles.length; i++) {
                            if (row.SystemName === currentSubRoles[i].SystemName) {
                                row.IsModified = currentSubRoles[i].IsModified;
                                row.Action = currentSubRoles[i].Action;
                                if (currentSubRoles[i].Action === "Grant") {
                                    row.SelectedCheckbox = true;
                                }
                                break;
                            }
                        }
                    });

                    vm.selectedSecurityDesignerRoles = $filter('orderBy')(vm.selectedSecurityDesignerRoles, 'Name');
                    options.success(vm.selectedSecurityDesignerRoles);
                }
            }
        });

        $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/Roles/ManageRoleAXSecurityDesigner.html',
            controller: AddAXSubRolesToRoleSecurityDesigner,
            backdrop: 'static',
            size: 'lg',
            scope: $scope
        });
    };

}

function AddAXDutiesToRoleSecurityDesigner($scope, $uibModalInstance, AXSecurityDesignerService) {

    $scope.modalvm = {};

    $scope.selectedTableFilter = "All";
    $scope.modalHeader = "Duties";

    $scope.modalvm.selectDuty = function (dataItem) {
        var parentItem = $scope.vm.securityDesignerRolesTree.filter(e => e.SystemName.toLowerCase() === $scope.objectOpenInModal.SystemName.toLowerCase())[0];

        AXSecurityDesignerService.SetInitialModifiedValueElseChangeModified(dataItem);

        parentItem.Duties.remove(function (n) { return n['SystemName'] === dataItem.SystemName; });
        var childItem = { "IsCustom": dataItem.IsCustom, "IsModified": dataItem.IsModified, "SystemName": dataItem.SystemName, "Name": dataItem.Name };

        if (dataItem.SelectedCheckbox === true) {
            childItem.Action = "Grant";
            parentItem.Duties.push(childItem);
            AXSecurityDesignerService.AssignChildToParent({ SimulationId: $scope.vm.selectedSimulation.Id, ChildItem: childItem, ChildType: 2, ParentItem: parentItem, ParentType: 1, Action: "Grant" });
        } else {
            childItem.Action = "Revoke";
            parentItem.Duties.push(childItem);
            AXSecurityDesignerService.AssignChildToParent({ SimulationId: $scope.vm.selectedSimulation.Id, ChildItem: childItem, ChildType: 2, ParentItem: parentItem, ParentType: 1, Action: "Revoke" });
        }

        $scope.determineModifiedArrays();
    };

    $scope.changeFilter = function () {
        AXSecurityDesignerService.changeFilter($scope.selectedTableFilter, $scope.vm.manageSecurityLayerGrid.dataSource, "SelectedCheckbox");
    };

    $scope.deleteModificationToItem = function (dataItem) {
        dataItem.SelectedCheckbox = !dataItem.SelectedCheckbox;
        $scope.modalvm.selectDuty(dataItem);
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}

function AddAXPrivilegesToRoleSecurityDesigner($scope, $uibModalInstance, AXSecurityDesignerService, $http, $route) {

    $scope.modalvm = {};

    $scope.selectedTableFilter = "All";
    $scope.modalHeader = "Privileges";

    $scope.modalvm.selectPrivilege = function (dataItem) {
        var parentItem = $scope.vm.securityDesignerRolesTree.filter(e => e.SystemName.toLowerCase() === $scope.objectOpenInModal.SystemName.toLowerCase())[0];

        AXSecurityDesignerService.SetInitialModifiedValueElseChangeModified(dataItem);

        parentItem.Privileges.remove(function (n) { return n['SystemName'] === dataItem.SystemName; });
        var childItem = { "IsCustom": dataItem.IsCustom, "IsModified": dataItem.IsModified, "SystemName": dataItem.SystemName, "Name": dataItem.Name };

        if (dataItem.SelectedCheckbox === true) {
            childItem.Action = "Grant";
            parentItem.Privileges.push(childItem);
            AXSecurityDesignerService.AssignChildToParent({ SimulationId: $scope.vm.selectedSimulation.Id, ChildItem: childItem, ChildType: 3, ParentItem: parentItem, ParentType: 1, Action: "Grant" });
        } else {
            childItem.Action = "Revoke";
            parentItem.Privileges.push(childItem);
            AXSecurityDesignerService.AssignChildToParent({ SimulationId: $scope.vm.selectedSimulation.Id, ChildItem: childItem, ChildType: 3, ParentItem: parentItem, ParentType: 1, Action: "Revoke" });
        }

        $scope.determineModifiedArrays();
    };

    $scope.changeFilter = function () {
        AXSecurityDesignerService.changeFilter($scope.selectedTableFilter, $scope.vm.manageSecurityLayerGrid.dataSource, "SelectedCheckbox");
    };

    $scope.deleteModificationToItem = function (dataItem) {
        dataItem.SelectedCheckbox = !dataItem.SelectedCheckbox;
        $scope.modalvm.selectPrivilege(dataItem);
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}

function AddAXTablesToRoleSecurityDesigner($scope, $uibModalInstance, AXSecurityDesignerService) {

    $scope.modalvm = {};

    $scope.selectedTableFilter = "All";
    $scope.modalHeader = "Tables";
    $scope.tableActions = ["Grant", "Deny", "Unset"];

    $scope.changeFilter = function () {
        AXSecurityDesignerService.changeFilter($scope.selectedTableFilter, $scope.vm.manageSecurityLayerGrid.dataSource, "ContainsAssigningValue");
    };

    $scope.actionDropdownChange = async function (action, dataItem) {
        kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, true);
        dataItem.IsModified = true;

        var parentItem = $scope.vm.securityDesignerRolesTree.filter(e => e.SystemName.toLowerCase() === $scope.objectOpenInModal.SystemName.toLowerCase())[0];

        var childItem = {
            "Create": dataItem.Create,
            "Delete": dataItem.Delete,
            "Invoke": dataItem.Invoke,
            "IsModified": true,
            "OldCreate": dataItem.OldCreate,
            "OldDelete": dataItem.OldDelete,
            "OldInvoke": dataItem.OldInvoke,
            "OldRead": dataItem.OldRead,
            "OldUpdate": dataItem.OldUpdate,
            "Read": dataItem.Read,
            "SystemName": dataItem.Name,
            "Update": dataItem.Update,
            "Type": 44
        };

        var parentItemTable = parentItem.Tables.filter(e => e.SystemName.toLowerCase() === dataItem.Name.toLowerCase())[0];
        parentItem.Tables.remove(parentItemTable);

        if (dataItem.Create !== 'Unset' || dataItem.Delete !== 'Unset' || dataItem.Read !== 'Unset' || dataItem.Update !== 'Unset') {
            dataItem.ContainsAssigningValue = true;
        } else {
            dataItem.ContainsAssigningValue = false;
        }

        parentItem.Tables.push(childItem);

        let { data } = await AXSecurityDesignerService.AssignChildObjectToParent({ SimulationId: $scope.vm.selectedSimulation.Id, ChildItem: childItem, ChildType: 44, ParentItem: parentItem, ParentType: 1 });
        // use the returned object to update the permissions dropdowns
        childItem.Create = data[0].Create;
        childItem.Update = data[0].Update;
        childItem.Read = data[0].Read;
        childItem.Delete = data[0].Delete
        $scope.vm.manageSecurityLayerGridDataSource.read();
        $scope.determineModifiedArrays();

        kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, false);
    };

    $scope.deleteModificationToObject = function (dataItem) {
        kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, true);
        AXSecurityDesignerService.DeleteModificationToObject(dataItem, $scope.vm.selectedSimulation, $scope.objectOpenInModal, "Tables").then(function () {
            $scope.determineModifiedArrays();
            kendo.ui.progress($scope.vm.manageSecurityLayerGrid.element, false);
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}

function AddAXSubRolesToRoleSecurityDesigner($scope, $uibModalInstance, AXSecurityDesignerService) {

    $scope.modalvm = {};

    $scope.selectedTableFilter = "All";
    $scope.modalHeader = "Sub-Roles";

    $scope.modalvm.selectSubRole = function (dataItem) {
        var parentItem = $scope.vm.securityDesignerRolesTree.filter(e => e.SystemName.toLowerCase() === $scope.objectOpenInModal.SystemName.toLowerCase())[0];

        AXSecurityDesignerService.SetInitialModifiedValueElseChangeModified(dataItem);

        parentItem.SubRoles.remove(function (n) { return n['SystemName'] === dataItem.SystemName; });
        var childItem = { "IsCustom": dataItem.IsCustom, "IsModified": dataItem.IsModified, "SystemName": dataItem.SystemName, "Name": dataItem.Name };

        if (dataItem.SelectedCheckbox === true) {
            childItem.Action = "Grant";
            parentItem.SubRoles.push(childItem);
            AXSecurityDesignerService.AssignChildToParent({ SimulationId: $scope.vm.selectedSimulation.Id, ChildItem: childItem, ChildType: 1, ParentItem: parentItem, ParentType: 1, Action: "Grant" });
        } else {
            childItem.Action = "Revoke";
            parentItem.SubRoles.push(childItem);
            AXSecurityDesignerService.AssignChildToParent({ SimulationId: $scope.vm.selectedSimulation.Id, ChildItem: childItem, ChildType: 1, ParentItem: parentItem, ParentType: 1, Action: "Revoke" });
        }

        $scope.determineModifiedArrays();
    };

    $scope.changeFilter = function () {
        AXSecurityDesignerService.changeFilter($scope.selectedTableFilter, $scope.vm.manageSecurityLayerGrid.dataSource, "SelectedCheckbox");
    };

    $scope.deleteModificationToItem = function (dataItem) {
        dataItem.SelectedCheckbox = !dataItem.SelectedCheckbox;
        $scope.modalvm.selectSubRole(dataItem);
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}

function cloneRoleModal($scope, helperService, $uibModalInstance, $http) {

    $scope.modalvm = {};
    $scope.modalvm.securityObjectType = $scope.securityObjectType;

    $scope.clone = function (name, description) {
        var obj = $scope.selectedSecurityObject;

        if (obj.pleaseWait === null) {
            obj.pleaseWait = {};
        }
        obj.pleaseWait = new createSpinner();
        $scope.pleaseWait = new createSpinner();

        if (description === null) {
            description = "";
        }

        var postData = {
            "SimulationId": $scope.vm.selectedSimulation.Id,
            "SystemName": obj.SystemName,
            "Name": name,
            "Description": description,
            "Type": "Role"
        };

        $http.post(apiUrl + 'api/securitydesigner/ax7/simulation/' + $scope.vm.selectedSimulation.Id + '/clone', postData).then(function (response) {
            $scope.vm.simulationSelected();
            $uibModalInstance.close();
        }, function errorCallback(response) {
            obj.pleaseWait.loadingValue = false;
            $scope.pleaseWait.loadingValue = false;
            helperService.showErrorMessage(response.data);
        });
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}