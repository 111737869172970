import manageDataConfigurationController from "./manageDataConfiguration.controller";

/* manageDataConfiguration.component.js */

/**
 * @desc component for each configuration within manage data feature
 * @example <manage-data-configuration></manage-data-configuration>
 */

const manageDataConfiguration = {
    bindings: {
        product: '=',
        action: '<'
    },
    controller: manageDataConfigurationController,
    controllerAs: 'mdcvm',
    templateUrl: '/App/Components/ManageDataV2/Configuration/manageDataConfiguration.tmpl.html'
};

export default manageDataConfiguration;