export default /*@ngInject*/ function (authenticationService) {
    startSetup();

    async function startSetup() {
        const authenticated = await authenticationService.isAuthenticated();
        let currentUser;

        if (authenticated) {
            currentUser = await authenticationService.getUser();
        }

        if (currentUser) {
            setup(currentUser);
        }
    }

    function setup(user) {
        let localTenant = localStorage.getItem("selectedTenant");

        ChurnZero.push(['setAppKey', churnZeroAppKey]);

        if (localTenant && user.email) {
            localTenant = JSON.parse(localTenant);
            ChurnZero.push(['setContact', localTenant.CustomerId, user.email]);
        }
    }
}
