import roleTemplatesController from "./roleTemplates.controller";

/* roleTemplates.component.js */

/**
 * @desc component for displaying role template associated components and modals
 * @example <role-templates></role-templates>
 */

var roleTemplates = {
    bindings: {
        template: '='
    },
    controller: roleTemplatesController,
    controllerAs: 'rtvm',
    templateUrl: '/App/Components/RoleTemplates/roleTemplates.tmpl.html'
};

export default roleTemplates;