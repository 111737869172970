import autoMitigationsController from "./autoMitigations.controller";

/* autoMitigations.component.js */

/**
 * @desc component for managing auto mitigations in ORFC and Peoplesoft
 * @example <auto-mitigations></auto-mitigations>
 */

const autoMitigations = {
   bindings: {
      product: '<'
   },
   controller: autoMitigationsController,
   controllerAs: 'amvm',
   templateUrl: '/App/Components/AutoMitigations/autoMitigations.tmpl.html'
};

export default autoMitigations;