import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($filter, $uibModalInstance, helperService, taskRecordingDataService, productType, taskRecording) {

    const vm = this;

    vm.cancel = cancel;
    vm.exportMenuItems = exportMenuItems;

    function activate() {
        getMenuItems();
    }

    activate();

    function getMenuItems() {
        taskRecordingDataService.getMenuItems(productType, taskRecording.Id).then(response => {
            vm.menuItemsList = response.data;
            vm.menuItemsList = $filter('orderBy')(vm.menuItemsList, 'ObjectLabel');
        });
    }

    function exportMenuItems() {
        vm.exportInProgress = new createSpinner();
        taskRecordingDataService.exportMenuItems(productType, taskRecording.Id)
            .then(response => {
                helperService.successfulSaveButton(vm.exportInProgress);
                vm.exportInProgress.loadingValue = false;
            }).catch(response => {
                vm.exportInProgress.loadingValue = false;
            });
    }

    function cancel() {
        $uibModalInstance.close();
    }

}