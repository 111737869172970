import { customAutoCompleteFilter, customBooleanYesNoFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import createSpinner from "../../Shared/Functions/createSpinner";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";
import importExcludedRolesController from "./importExcludedRoles.controller";

export default /*@ngInject*/ function ($scope, reportsService, helperService, $uibModal, FileSaver, dataService, excludedRolesDataService, maintainSystemRolesDataService) {

    const vm = this;

    vm.helperService = helperService;
    vm.exportExcludedRoles = exportExcludedRoles;
    vm.openImportModal = openImportModal;
    vm.excludeRole = excludeRole;
    vm.customProductNames = new Array;

    activate();

    function activate() {
        createExcludedRolesGrid();
    }

    $scope.$on('ImportExcludedRoles', (event, args) => vm.excludedRolesGridDataSource.read());

    async function createExcludedRolesGrid() {
        const excludedRolesColumns = [
            { title: "Excluded", field: "IsExcluded", template: "<label class='switch'><input type='checkbox' ng-model='dataItem.IsExcluded' ng-change='ervm.excludeRole(dataItem)'><i></i></label>", width: 130, filterable: customBooleanYesNoFilter },
            { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
            { field: "Description", title: "Description", filterable: customAutoCompleteFilter },
        ];

        const products = await dataService.getProducts();
        // if there are multiple products we need to show the products column
        if (products.data.length > 1) {
            excludedRolesColumns.push(
                { field: "ProductName", title: "Product", filterable: customAutoCompleteFilter }
            );
            // set up the list of custom products to filter those roles in the grid data source
            vm.customProductNames = products.data.reduce((newArray, product) => {
                if (product.IsCustom) {
                    newArray.push(product.Name);
                }
                return newArray;
            }, []);

        }

        vm.excludedRolesGridOptions = helperService.setMainGridOptions(excludedRolesColumns, 600);
        vm.excludedRolesGridOptions.autoBind = true;

        vm.excludedRolesGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: angular.extend(halSchemaKendoGrid, {
                model: {
                    fields: {
                        IsExcluded: { type: "boolean" }
                    }
                }
            }),
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            transport: {
                read: async options => {
                    let query = helperService.prepareSortsAndFilters(options);
                    // the api uses different field names for filtering and sorting security roles
                    query = reportsService.fixFieldNamesForApi(query, "SecurityRole");
                    // we don't currently support excluding custom product roles so we'll filter them
                    vm.customProductNames.forEach(customProduct => query.Filters.push({
                        FieldName: "ProductName",
                        Operator: "NotEquals",
                        Value: customProduct
                    }));
                    let params = { 'pagesize': options.data.pageSize, 'page': options.data.page };
                    try {
                        let response = await maintainSystemRolesDataService.getSystemRoles(query, params);
                        options.success(response.data);
                    } catch (err) {
                        options.error(err);
                    }
                }
            }
        });
    }

    async function excludeRole(dataItem) {
        const data = { SecurityRoleIdentifier: dataItem.Identifier, ShouldExclude: dataItem.IsExcluded };
        kendo.ui.progress(vm.excludedRolesGrid.element, true);
        try {
            await excludedRolesDataService.updateExcludedRole(data);
            kendo.ui.progress(vm.excludedRolesGrid.element, false);
        } catch {
            dataItem.Exclude = !dataItem.Exclude;
            kendo.ui.progress(vm.excludedRolesGrid.element, false);
        }
    }

    async function exportExcludedRoles() {
        vm.exportUpdatePending = new createSpinner();
        try {
            let response = await excludedRolesDataService.exportExcludedRoles();
            helperService.successfulSaveButton(vm.exportUpdatePending);
            FileSaver.saveAs(response.data, 'ExcludedRoles.xlsx');
            vm.exportUpdatePending.loadingValue = false;
        } catch {
            vm.exportUpdatePending.loadingValue = false;
        }
    }

    async function openImportModal() {
        let importModal = $uibModal.open({
            templateUrl: '/App/Components/ExcludedRoles/importExcludedRoles.html',
            controller: importExcludedRolesController,
            controllerAs: 'iervm',
            backdrop: 'static',
        });
        try {
            await importModal.result;
            return;
        } catch {
            return;
        }
    }

}
