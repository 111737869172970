import swal from "sweetalert2";
import NewAXSecurityModelModalController from "./NewAXSecurityModelModal.controller";
import PartialAXSecurityDesignerChangesModalController from "../PartialAXSecurityDesignerChangesModal.controller";

export default /*@ngInject*/ function ($scope, $uibModal, AXSecurityDesignerService, $filter, $location, helperService, errorsDataService) {

    $scope.itemsPerPage = 4;
    $scope.currentPage = 1;

    const vm = this;

    vm.filterSecurityModels = filterSecurityModels;
    vm.publishSimulation = publishSimulation;
    vm.deleteSimulation = deleteSimulation;
    vm.viewDetails = viewDetails;
    vm.createRiskAnalysis = createRiskAnalysis;
    vm.preselectSimulationNavigateToPage = preselectSimulationNavigateToPage;
    vm.changePublic = changePublic;
    vm.openAXNewSecurityModelModal = openAXNewSecurityModelModal;
    vm.openAXSecurityDesignerChanges = openAXSecurityDesignerChanges;
    vm.checkForSimulationModifications = checkForSimulationModifications;
    vm.sortSecurityModels = sortSecurityModels;

    activate();

    async function activate() {
        await getSimulations();
        await filterSecurityModels();
        await sortSecurityModels(vm.sortMethod);
        await checkForSimulationModifications();
    }

    $scope.$on('RiskAnalysis', () => activate());
    $scope.$on('PublishSecurityDesignerChanges', () => activate());

    function filterSecurityModels(num) {

        vm.activeModels = [];
        vm.deployedModels = [];

        vm.activeModels = vm.securityModels.filter(m => m.Status !== 'Deployed');
        vm.deployedModels = vm.securityModels.filter(m => m.Status === 'Deployed');

        if (num === 0) vm.filteredModels = vm.activeModels;
        else if (num === 1) vm.filteredModels = vm.deployedModels;

        else if (!num) {
            if (vm.activeModels.length > 0) {
                vm.toggle = 0;
                vm.filteredModels = vm.activeModels;
            } else {
                vm.toggle = 1;
                vm.filteredModels = vm.deployedModels;
            }
        }
        vm.filteredCount = vm.filteredModels.length;
    }

    async function publishSimulation(sim) {

        let postBody = { "SimulationId": sim.Id };

        try {
            await swal(helperService.areYouSureParams('Publish Security Model', 'Are you sure you want to publish this security model to your Dynamics 365 environment?', 'Publish'));
            sim.pleaseWait = true;
            AXSecurityDesignerService.publishSimulation(sim.Id, postBody);
        } catch(_) {
            return;
        }
    }

    function sortSecurityModels(num) {
        if (num === 1) {
            vm.filteredModels = $filter('orderBy')(vm.filteredModels, 'Name');
        } else if (num === 2) {
            vm.filteredModels = $filter('orderBy')(vm.filteredModels, '-Name');
        } else if (num === 3) {
            vm.filteredModels = $filter('orderBy')(vm.filteredModels, 'DateLastModified');
        } else if (num === 4) {
            vm.filteredModels = $filter('orderBy')(vm.filteredModels, '-DateLastModified');
        } else if (!num) {
            vm.filteredModels = $filter('orderBy')(vm.filteredModels, 'Name');
        }
    }

    function viewDetails(dataItem) {
        $location.path('/SecurityDesigner/Dynamics365forFinanceandOperationsSimulationDetails').search({
            SimulationId: dataItem.Id
        });
    }

    function createRiskAnalysis(dataItem) {

        dataItem.Status = "Analyzing";
        let postBody = { SimulationId: dataItem.Id };

        AXSecurityDesignerService.createRiskAnalysis(dataItem.Id, postBody);
    }

    async function getSimulations() {
        try {
            let response = await AXSecurityDesignerService.getSimulations();
            response.data = $filter('orderBy')(response.data, 'Name');
            vm.securityModels = response.data;
            vm.securityModels = vm.securityModels.map(model => { model.toggleInfo = false; return model; });
            filterSecurityModels(vm.toggle);
        } catch(err) {
            errorsDataService.catch(err);
        }
    }

    async function checkForSimulationModifications() {
        for (const sim of vm.securityModels) {
            try{
                let response = await AXSecurityDesignerService.viewChanges(sim);
                //check for new roles, duties, or privileges created
                if (response.data.CreatedSecurityLayers.length > 0) {
                    response.data.CreatedSecurityLayers.forEach(creation => {

                        if (creation.Type === 'Role' && sim.Status !== 'Deployed') {
                            sim.modifiedRoles = true;
                        } else if (creation.Type === 'Duty' && sim.Status !== 'Deployed') {
                            sim.modifiedDuties = true;
                        } else if (creation.Type === 'Privilege' && sim.Status !== 'Deployed') {
                            sim.modifiedPrivileges = true;
                        } else {
                            return;
                        }
                    });
                }
                //check for toggling changes made
                if (response.data.ParentToChild.length > 0) {
                    response.data.ParentToChild.forEach(change => {

                        if (change.ParentObjectType === 'Role' && sim.Status !== 'Deployed') {
                            sim.modifiedRoles = true;
                        } else if (change.ParentObjectType === 'Duty' && sim.Status !== 'Deployed') {
                            sim.modifiedDuties = true;
                        } else if (change.ParentObjectType === 'Privilege' && sim.Status !== 'Deployed') {
                            sim.modifiedPrivileges = true;
                        } else {
                            return;
                        }
                    });
                }
                //check for dropdown modifying changes made
                if (response.data.ParentToObject.length > 0) {
                    response.data.ParentToObject.forEach(change => {

                        if (change.ParentObjectType === 'Role' && sim.Status !== 'Deployed') {
                            sim.modifiedRoles = true;
                        } else if (change.ParentObjectType === 'Duty' && sim.Status !== 'Deployed') {
                            sim.modifiedDuties = true;
                        } else if (change.ParentObjectType === 'Privilege' && sim.Status !== 'Deployed') {
                            sim.modifiedPrivileges = true;
                        } else {
                            return;
                        }
                    });
                }
                //check for any removed security layers
                if (response.data.RemovedSecurityLayers.length > 0) {
                    response.data.RemovedSecurityLayers.forEach(removal => {

                        if (removal.Type === 'Role' && sim.Status !== 'Deployed') {
                            sim.modifiedRoles = true;
                        } else if (removal.Type === 'Duty' && sim.Status !== 'Deployed') {
                            sim.modifiedDuties = true;
                        } else if (removal.Type === 'Privilege' && sim.Status !== 'Deployed') {
                            sim.modifiedPrivileges = true;
                        } else {
                            return;
                        }
                    });
                }
            } catch(_) {
                return;
            }
        }
    }

    function preselectSimulationNavigateToPage(simulation, page) {
        localStorage.setItem("LastSelectedAX7SecurityModel", simulation.Id);
        $location.path('/SecurityDesigner/Place/Dynamics365forFinanceandOperations' + page);
    }

    async function changePublic(simulation, boolValue) {

        simulation.IsPublic = boolValue;

        let putBody = {
            "Id": simulation.Id,
            "Name": simulation.Name,
            "IsPublic": boolValue
        };

        try {
            await AXSecurityDesignerService.changePublic(simulation.Id, putBody);
        } catch(_) {
            simulation.IsPublic = !boolValue;
        }
    }

    async function deleteSimulation(simulation) {      
        try {
            await swal(helperService.areYouSureParams('Delete Security Model', 'Are you sure you want to delete the ' + simulation.Name + ' security model?', 'Delete'));
            simulation.pleaseWait = true;
            await AXSecurityDesignerService.deleteSimulation(simulation.Id);
            simulation.pleaseWait = false;
            activate();
        } catch {
            simulation.pleaseWait = false;
        }
    }

    function openAXNewSecurityModelModal() {
        let modal = $uibModal.open({
            templateUrl: 'App/Components/AXSecurityDesigner/SecurityModels/NewAXSecurityModelModal.html',
            controller: NewAXSecurityModelModalController,
            backdrop: 'static',
            scope: $scope
        });
        modal.result
            .then(_ => { activate(); });
    }

    function openAXSecurityDesignerChanges(simulationModel) {
        $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/PartialAXSecurityDesignerChanges.html',
            controller: PartialAXSecurityDesignerChangesModalController,
            backdrop: 'static',
            scope: $scope,
            resolve: {
                sim: () => simulationModel
            },
            size: 'lg'
        });

    }

}
