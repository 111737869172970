import { customAutoCompleteFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import swal from "sweetalert2";
import { halSchemaKendoGrid } from "../../Shared/kendo.module";

export default /*@ngInject*/ function ($filter, errorsDataService, $http, helperService) {
    const vm = this,
        columns = [
            { field: "RiskName", title: "Risk Name", filterable: customAutoCompleteFilter },
            { field: "RulesetName", title: " Ruleset Name", filterable: customAutoCompleteFilter },
            { field: "RiskLevel", title: "Risk Level", filterable: customAutoCompleteFilter },
            { field: "RiskTypeName", title: "Risk Type", filterable: customAutoCompleteFilter },
            { field: "Mitigation", title: "Mitigation", filterable: customAutoCompleteFilter },
            { field: "Control", title: "Control", filterable: customAutoCompleteFilter },
            { field: "Status", title: "Status", filterable: customAutoCompleteFilter },
            { field: "MitigationNotes", title: "Mitigation Notes", filterable: customAutoCompleteFilter }
        ];

    vm.applyDefaultMitigations = applyDefaultMitigations;
    vm.helperService = helperService;
    vm.DefaultMitigationsOverwriteCurrent;
    vm.ApplyDefaultMitigationsAfterImport;

    activate();

    async function activate() {
        vm.DefaultMitigationsOverwriteCurrent = await vm.helperService.getConfigurationValue('DefaultMitigationsOverwriteCurrent');
        vm.ApplyDefaultMitigationsAfterImport = await vm.helperService.getConfigurationValue('ApplyDefaultMitigationsAfterImport');
        vm.mainGridOptions = helperService.setMainGridOptions(columns, 600);

        vm.gridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: halSchemaKendoGrid,
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            selectable: false,
            transport: {
                read: async options => {
                    let sortsAndFilters = helperService.prepareSortsAndFilters(options),
                        response;

                    try {
                        response = await $http({
                            method: 'POST',
                            url: apiUrl + 'api/core/reports/execute/10203',
                            data: sortsAndFilters,
                            params: { 'pagesize': options.data.pageSize, 'page': options.data.page }
                        });

                        $filter('filter')(response.data._embedded.reportData, { DefaultMitigation: 'True' });

                        options.success(response.data);
                        vm.enableGrid = true;
                    } catch (err) {
                        options.error();
                    }
                }
            }
        });
    }

    async function applyDefaultMitigations() {
        try {
            await swal(helperService.areYouSureParams('Apply Default Mitigations', 'Are you sure you want to apply Default Mitigations to all risks?', 'Apply'));
            await $http.put(`${apiUrl}api/arm/usermitigations/applydefaultmitigations`, {});
        } catch (error) {
            errorsDataService.catch(error);
        }
    }
}
