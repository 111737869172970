import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (user, source, $uibModalInstance, authenticationService, dataService, usersDataService, errorsDataService, helperService, $filter) {

    const vm = this;

    vm.source = source;

    vm.onSave = onSave;
    vm.cancel = cancel;
    vm.clearOOFHours = clearOOFHours;

    activate();

    async function activate() {
        let users = await dataService.getUsers();
        users.data = users.data.filter(user => user.Active);
        vm.users = $filter('orderBy')(users.data, 'UserLogonName');

        if (source === 'usersPage') {
            vm.user = Object.assign({}, user);
        } else {
            const currentUser = await authenticationService.getUser();
            vm.user = vm.users.find(u => u.UserID === currentUser.userId);
        }

        if (vm.user.SubstituteReviewer) {
            let substitute = vm.users.find(u => u.UserID === vm.user.SubstituteReviewer.SubstituteUserId);
            vm.substitute = substitute;
        }
        if (vm.user.OutOfOfficeHours) mapHours();
        vm.users = vm.users.filter(u => u.UserID !== vm.user.UserID);
    }

    async function addSubstitute() {

        const data = {
            UserId: vm.user.UserID,
            SubstituteReviewerId: vm.substitute.UserID
        };
        await usersDataService.addSubstitute(vm.user.UserID, data);
    }

    async function changeSubstitute() {

        const data = {
            UserId: vm.user.UserID,
            SubstituteReviewerId: vm.substitute.UserID
        };
        await usersDataService.changeSubstitute(vm.user.UserID, data);
    }

    async function addOOFHours() {

        let fromTimeMinutes = vm.fromTime.getMinutes();
        let fromTimeHours = vm.fromTime.getHours();

        vm.fromDate.setMinutes(fromTimeMinutes);
        vm.fromDate.setHours(fromTimeHours);

        let toTimeMinutes = vm.toTime.getMinutes();
        let toTimeHours = vm.toTime.getHours();

        vm.toDate.setMinutes(toTimeMinutes);
        vm.toDate.setHours(toTimeHours);

        const data = {
            UserId: vm.user.UserID,
            StartDate: vm.fromDate,
            EndDate: vm.toDate
        };
        await usersDataService.addOOFHours(vm.user.UserID, data);
    }

    function mapHours() {

        vm.fromTime = new Date(vm.user.OutOfOfficeHours.StartDate);
        vm.toTime = new Date(vm.user.OutOfOfficeHours.EndDate);
        vm.fromDate = new Date(vm.user.OutOfOfficeHours.StartDate);
        vm.toDate = new Date(vm.user.OutOfOfficeHours.EndDate);
    }

    async function onSave() {

        vm.save = new createSpinner();

        try {
            if (vm.user.SubstituteReviewer && vm.substitute) await changeSubstitute();
            else if (!vm.user.SubstituteReviewer && vm.substitute) await addSubstitute();
            else if (vm.user.SubstituteReviewer && !vm.substitute) await usersDataService.removeSubstitute(vm.user.UserID);

            if (vm.fromTime && vm.substitute) await addOOFHours(); // Can use any date/time model since they're all required
            else if (vm.user.OutOfOfficeHours && vm.substitute && !vm.fromTime) await usersDataService.removeOOFHours(vm.user.UserID);

            await helperService.successfulSaveButton(vm.save);
            cancel();
        } catch (err) {
            errorsDataService.catch(err);
            vm.save = null;
        }
    }

    function clearOOFHours() {
        vm.fromTime = null;
        vm.fromDate = null;
        vm.toTime = null;
        vm.toDate = null;
    }

    function cancel() {
        $uibModalInstance.close();
    }
}