export default /*@ngInject*/ function ($http) {

    //AX 7 HTTP Calls
    this.getAllAX7Roles = function () {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/ax7/roles'
        });
    };

    this.getAllAX7UserGroups = function () {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/ax7/usergroups'
        })
    };

    this.getAllAX7Companies = function () {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/ax7/companies'
        });
    };

    this.getAX7UserData = function (userId) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/ax7/users/' + userId + '/'
        });
    };

    this.getAllAX7UserRoleCompanies = function () {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/ax7/userrolecompanies'
        });
    };

    //AX 2012
    this.getAllAX2012Roles = function () {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/ax/roles'
        });
    };

    this.getAllAX2012UserGroups = function () {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/ax/usergroups'
        })
    };

    this.getAllAX2012Companies = function (partitionKey) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/ax/companies/partitions/' + partitionKey
        });
    };

    this.getAllAX2012Partitions = function () {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/ax/partitions'
        });
    };
}