export default /*@ngInject*/ function ($http, errorsDataService) {
    var service = {
        getTemplates,
        getObjects,
        getObjectsByTemplateId,
        getFieldsByObjectId,
        createTemplate,
        editTemplate,
        updateTemplate,
        cloneTemplate,
        deleteTemplate,
        addObjectToTemplate,
        addFieldToObject,
        removeObjectByTemplateIdAndObjectId,
        removeFieldByTemplateIdTemplateObjectIdAndTemplateFieldId
    };

    return service;

    async function getTemplates(productType) {
        switch (productType) {
            case 'NS':
                try {
                    return await $http.get(apiUrl + 'api/audittrail/ns/v2/templates');
                } catch (err) {
                    showError(err);
                }
                break;
            case 'ORFC':
                try {
                    return await $http.get(apiUrl + 'api/audittrail/orfc/products');
                } catch (err) {
                    showError(err);
                }
        }
    }

    function getObjects() {
        return $http.get(apiUrl + 'api/audittrail/ns/v2/objects')
            .then(getObjectsComplete)
            .catch(getObjectsFailed);

        function getObjectsComplete(response) {
            return response.data;
        }

        function getObjectsFailed(error) {
            errorsDataService.catch(error);
        }
    }

    function getObjectsByTemplateId(templateId) {
        return $http.get(apiUrl + 'api/audittrail/ns/v2/templates/' + templateId)
            .then(getObjectsByTemplateIdComplete)
            .catch(getObjectsByTemplateIdFailed);

        function getObjectsByTemplateIdComplete(response) {
            return response.data;
        }

        function getObjectsByTemplateIdFailed(error) {
            errorsDataService.catch(error);
        }
    }

    function getFieldsByObjectId(objectId) {
        return $http.get(apiUrl + 'api/audittrail/ns/v2/objects/' + objectId + '/fields')
            .then(getObjectsByTemplateIdComplete)
            .catch(getObjectsByTemplateIdFailed);

        function getObjectsByTemplateIdComplete(response) {
            return response.data;
        }

        function getObjectsByTemplateIdFailed(error) {
            errorsDataService.catch(error);
        }
    }

    function createTemplate(data) {
        return $http.post(apiUrl + 'api/audittrail/ns/v2/templates', data)
            .then(createTemplateComplete)
            .catch(createTemplateFailed);

        function createTemplateComplete(response) {
            return response.data;
        }

        function createTemplateFailed(error) {
            errorsDataService.catch(error);
        }
    }

    function editTemplate(templateId, data) {
        return $http.put(apiUrl + 'api/audittrail/ns/v2/templates/' + templateId, data)
            .then(editTemplateComplete)
            .catch(editTemplateFailed);

        function editTemplateComplete(response) {
            return response.data;
        }

        function editTemplateFailed(error) {
            errorsDataService.catch(error);
        }
    }

    async function updateTemplate(template) {
        try {
            return await $http.put(apiUrl + 'api/audittrail/orfc/products', template);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function cloneTemplate(templateId, data) {
        return $http.post(apiUrl + 'api/audittrail/ns/v2/templates/' + templateId + '/clone', data)
            .then(cloneTemplateComplete)
            .catch(cloneTemplateFailed);

        function cloneTemplateComplete(response) {
            return response.data;
        }

        function cloneTemplateFailed(error) {
            errorsDataService.catch(error);
            throw error;
        }
    }

    function deleteTemplate(templateId) {
        return $http.delete(apiUrl + 'api/audittrail/ns/v2/templates/' + templateId)
            .then(deleteTemplateComplete)
            .catch(deleteTemplateFailed);

        function deleteTemplateComplete(response) {
            return response.data;
        }

        function deleteTemplateFailed(error) {
            errorsDataService.catch(error);
        }
    }

    function addObjectToTemplate(templateId, data) {
        return $http.post(apiUrl + 'api/audittrail/ns/v2/templates/' + templateId + '/objects', data)
            .then(addObjectToTemplateComplete)
            .catch(addObjectToTemplateFailed);

        function addObjectToTemplateComplete(response) {
            return response.data;
        }

        function addObjectToTemplateFailed(error) {
            errorsDataService.catch(error);
        }
    }

    function addFieldToObject(templateId, templateObjectId, data) {
        return $http.post(apiUrl + 'api/audittrail/ns/v2/templates/' + templateId + '/objects/' + templateObjectId + '/fields', data)
            .then(addFieldToObjectComplete)
            .catch(addFieldToObjectFailed);

        function addFieldToObjectComplete(response) {
            return response.data;
        }

        function addFieldToObjectFailed(error) {
            errorsDataService.catch(error);
            throw error;
        }
    }

    function removeObjectByTemplateIdAndObjectId(templateId, templateObjectId) {
        return $http.delete(apiUrl + 'api/audittrail/ns/v2/templates/' + templateId + '/objects/' + templateObjectId)
            .then(removeObjectByTemplateIdAndObjectIdComplete)
            .catch(removeObjectByTemplateIdAndObjectIdFailed);

        function removeObjectByTemplateIdAndObjectIdComplete(response) {
            return response.data;
        }

        function removeObjectByTemplateIdAndObjectIdFailed(error) {
            errorsDataService.catch(error);
        }
    }

    function removeFieldByTemplateIdTemplateObjectIdAndTemplateFieldId(templateId, templateObjectId, templateFieldId) {
        return $http.delete(apiUrl + 'api/audittrail/ns/v2/templates/' + templateId + '/objects/' + templateObjectId + '/fields/' + templateFieldId)
            .then(removeFieldByTemplateIdTemplateObjectIdAndTemplateFieldIdComplete)
            .catch(removeFieldByTemplateIdTemplateObjectIdAndTemplateFieldIdFailed);

        function removeFieldByTemplateIdTemplateObjectIdAndTemplateFieldIdComplete(response) {
            return response.data;
        }

        function removeFieldByTemplateIdTemplateObjectIdAndTemplateFieldIdFailed(error) {
            errorsDataService.catch(error);
        }
    }

    function showError(error) {
        errorsDataService.catch(error);
    }

    function sendError(error) {
        throw error;
    }

}