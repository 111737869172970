import { standardGridPageSize } from "../../GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($rootScope, helperService, risksDataService) {

    const vm = this;

    vm.$onInit = onInit;
    vm.$onChanges = onChanges;

    vm.changeUserSelection = changeUserSelection;

    function onInit() {
        vm.userDataSource = [];
        vm.selectedUserIds = [];
        vm.selectedUsers = [];
        vm.filter = "";

        if (!vm.selectedProduct.length) {
            vm.selectedProduct = $rootScope.availableProductsForUser;
        }

        setupUserMultiselectDropdown();
    }

    function setupUserMultiselectDropdown() {
        vm.kendoUserDropdownDataSource = new kendo.data.DataSource({
            schema: {
                data: "_embedded.reportData",
                total: "TotalItems"
            },
            pageSize: standardGridPageSize,
            serverPaging: true,
            serverFiltering: true,
            serverSorting: true,
            serverOperation: false,
            transport: {
                read: async options => {
                    try {
                        if (vm.selectedProduct) {
                            const params = {
                                pagesize: options.data.pageSize,
                                page: options.data.page,
                            },
                                productIds = vm.selectedProduct.map(product => product.ID),

                                postBody = {
                                    ProductIds: productIds,
                                    Excluded: false,
                                    UserNameFilter: vm.filter
                                },
                                { data } = await risksDataService.getUsers(postBody, params);

                            vm.userDataSource = data;

                            vm.selectedUserIds = vm.selectedUsers.map(user => user.ID);

                            vm.onUpdateUsers({
                                selectedUsers: vm.selectedUsers,
                                selectedUserIds: vm.selectedUserIds,
                                userDataSource: vm.userDataSource._embedded.reportData
                            });
                        }
                        options.success(vm.userDataSource);

                        if ($rootScope.loadedSavedReport) {
                            savedReportHandler();
                        }
                    } catch {
                        return;
                    }
                }
            }
        });

        vm.userDropdownOptions = {
            filter: "contains",
            autoBind: false,
            autoClose: false,
            placeholder: "All Users",
            popup: { origin: "bottom left" },
            virtual: {
                itemHeight: 26,
                mapValueTo: "dataItem",
                valueMapper: async options => {
                    const users = await helperService.userMapperFunction(options.value);
                    options.success(users);
                }
            },
            height: 325,
            filtering: item => {
                vm.filter = item?.filter ? item.filter.value : "";
            }
        };
    }

    async function onChanges() {
        vm.selectedUsers = [];

        if (vm.kendoUserDropdownDataSource) {
            await vm.kendoUserDropdownDataSource.read();
        }
    }

    function savedReportHandler() {
        if ($rootScope.loadedSavedReport) {
            const savedUsers = $rootScope.loadedSavedReport.ReportDefinition.Criteria.filter(criteria => criteria.CriteriaType === 'User');

            const users = [];

            if (savedUsers.length > 0) {
                savedUsers.forEach(user => {
                    users.push({
                        ID: user.Filters[0].Value,
                        Name: user.CriteriaValue
                    });
                });
                vm.selectedUsers = [...users];
            }

            vm.selectedUserIds = vm.selectedUsers.map(user => user.ID);
            vm.userDataSource = vm.kendoUserDropdownDataSource.data();
            const userDataSource = vm.userDataSource.toJSON();

            vm.onUpdateUsers({
                selectedUsers: vm.selectedUsers,
                selectedUserIds: vm.selectedUserIds,
                userDataSource: userDataSource
            });

            vm.runSavedReport();
        }
    }

    async function changeUserSelection() {
        vm.selectedUserIds = vm.selectedUsers.map(user => user.ID);

        vm.onUpdateUsers({
            selectedUsers: vm.selectedUsers,
            selectedUserIds: vm.selectedUserIds,
            userDataSource: vm.kendoUserDropdownDataSource._data
        });
    }
}