import snapshotConfigurationController from "./snapshotConfiguration.controller";


/* snapshotConfiguration.component.js */

/**
 * @desc component for Snapshot Configuration in NetSuite
 * @example <snapshot-configuration></snapshot-configuration>
 */

const snapshotConfiguration = {
    controller: snapshotConfigurationController,
    controllerAs: 'scvm',
    templateUrl: '/App/Components/SnapshotConfiguration/snapshotConfiguration.tmpl.html'
};

export default snapshotConfiguration;