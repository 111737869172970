import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, excludedRolesDataService) {

    const vm = this;
    vm.cancel = cancel;
    vm.dismiss = dismiss;
    vm.disableButton = false;
    vm.importExcludedRoles = importExcludedRoles;

    function cancel() { $uibModalInstance.close(); }
    function dismiss() { $uibModalInstance.dismiss(); }

    // Import Excluded Users
    async function importExcludedRoles(file) {
        vm.importPending = new createSpinner();
        vm.disableButton = true;
        let fd = new FormData();
        fd.append(file[0].name, file[0].file);
        try {
            await excludedRolesDataService.importExcludedRoles(fd);
            await helperService.successfulSaveButton(vm.importPending);
            cancel();
        } catch {
            vm.importPending.loadingValue = false;
            vm.disableButton = false;
        }
    }
}