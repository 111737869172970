export default /*@ngInject*/ function ($scope) {

    //If $scope variable doesnt exist in child it looks in parent
    $scope.vm.validateNumberOfOracleResponsibilities = validateNumberOfOracleResponsibilities;

    function validateNumberOfOracleResponsibilities() {
        $scope.vm.numberOfOracleResponsibilitiesValid = null;
        if ($scope.vm.ORRBACEnabled) {
            if ($scope.vm.ListOfSelectedOracleResponsibilities.length > 0 || $scope.vm.ListOfSelectedOracleRoles.length > 0) {
                $scope.vm.numberOfOracleResponsibilitiesValid = true;
            }
        }
        if ($scope.vm.ListOfSelectedOracleResponsibilities.length > 0) {
            $scope.vm.numberOfOracleResponsibilitiesValid = true;
        }
    }

    $scope.addResponsibilityToList = function (responsibility) {
        $scope.vm.ListOfAvailableOracleResponsibilities.forEach(function (row, i) {
            if (row.Id === responsibility.Id) {
                $scope.vm.ListOfAvailableOracleResponsibilities.splice(i, 1);
                $scope.vm.ListOfSelectedOracleResponsibilities.push(row);
            }
        });
        $scope.vm.IsRisksReviewed = null;
        validateNumberOfOracleResponsibilities();
    };

    $scope.deleteSelectedResponsibility = function (responsibility) {
        $scope.vm.ListOfSelectedOracleResponsibilities.forEach(function (row, i) {
            if (row.Id === responsibility.Id) {
                $scope.vm.ListOfSelectedOracleResponsibilities.splice(i, 1);
                if (responsibility.IsAssignable) {
                    $scope.vm.ListOfAvailableOracleResponsibilities.push(row);
                }
            }
        });
        $scope.vm.IsRisksReviewed = null;
        validateNumberOfOracleResponsibilities();
    };

    $scope.setSelectedRoleTemplates = function (selectedRoleTemplates) {
        $scope.vm.selectedRoleTemplates = selectedRoleTemplates;
    };
}