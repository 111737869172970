import AX7PersonSearchController from "./AX7PersonSearch.controller";

export default /*@ngInject*/ function ($uibModal) {

    const vm = this;

    vm.onClick = onClick;

    async function onClick() {

        let modal = $uibModal.open({
            templateUrl: '/App/Components/RequestManagement/AX7Person/ax7PersonSearch.html',
            controller: AX7PersonSearchController,
            controllerAs: 'axpsvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                partitionKey: () => vm.axPartitionKey,
                axVersion: () => vm.axVersion
            }

        });

        try {
            let personObject = await modal.result;
            vm.onUpdatePersonName({ personObject });
        }

        catch (_) { return; }
    }

}