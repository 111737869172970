export default /*@ngInject*/ function ($scope, authenticationService) {

    var vm = $scope.vm = {};
    vm.currentYear = new Date();

    activate();

    function activate() {
        authenticationService.handleAuthentication();
    }
}