import { customDatePickerFilter, customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import documentationModalController from "./documentationModal.controller";
import swal from "sweetalert2";
import changeTicketsModalController from "./ChangeTicketsModal/changeTicketsModal.controller";
import changesModalController from "./ChangesModal/changesModal.controller";

export default /*@ngInject*/ function ($scope, $filter, $uibModal, helperService, nsAtDocumentationService) {
    const vm = this;

    vm.openDocumentationModal = openDocumentationModal;
    vm.deleteDocumentation = deleteDocumentation;
    vm.openChangeTicketsModal = openChangeTicketsModal;
    vm.openChangesModal = openChangesModal;

    function activate() {
        setupDocumentationGrid();
    }

    activate();

    function setupDocumentationGrid() {
        const columnsFromVM =
            [
                { field: "Date", title: "Date", template: "{{ dataItem.Date | date:'shortDate'}}", filterable: customDatePickerFilter },
                { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
                { field: "Type", title: "Type", filterable: customAutoCompleteFilter },
                { field: "AuthorizedBy", title: "Authorized By", filterable: customAutoCompleteFilter },
                { field: "Notes", title: "Notes", filterable: customAutoCompleteFilter },
                { field: "CreatedBy", title: "Created By", filterable: customAutoCompleteFilter },
                { field: "Id", title: "Id", hidden: true },
                { template: `<a ng-click="mdvm.openChangesModal(dataItem)"><i class="m-r-xs"></i>Changes</a>`, "title": "Changes", width: 100 },            
                { template: `<a ng-click="mdvm.openChangeTicketsModal(dataItem)"><i class="m-r-xs"></i>Change Tickets</a>`, "title": "Change Tickets", width: 125 },
                { template: `<a ng-click="mdvm.openDocumentationModal(dataItem)"><i class="glyphicon glyphicon-edit text-warning m-r-xs"></i>Edit</a>`, "title": "Edit", width: 100 },
                { template: `<a ng-click="mdvm.deleteDocumentation(dataItem)"><i class="glyphicon glyphicon-trash text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 100 }

            ];

        vm.mainGridOptions = helperService.setMainGridOptions(columnsFromVM, 500);
        vm.mainGridOptions.selectable = false;

        vm.gridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: {
                model: {
                    fields: {
                        Date: { type: "date" }
                    }
                }
            },
            transport: {
                read: async options => {
                    let response = await nsAtDocumentationService.getDocumentation();
                    response = $filter('orderBy')(response, '-Date');
                    options.success(response);
                }
            }
        });
    }

    async function openDocumentationModal(documentation) {
        let documentationModal = $uibModal.open({
            templateUrl: '/App/Components/NetSuiteAuditTrail/ManageDocumentation/documentationModal.html',
            controller: documentationModalController,
            controllerAs: 'dmvm',
            backdrop: 'static',
            scope: $scope,
            size: 'lg',
            resolve: {
                documentation: () => documentation || {}
            }
        });


        try {
            await documentationModal.result;
            vm.gridDataSource.read();
        } catch {
            return;
        }
    }

    async function deleteDocumentation(documentation) {
        await swal(helperService.areYouSureParams('Delete Documentation', 'Are you sure you want to delete documentation ' + documentation.Name + '?', 'Delete'))
        try {
            await nsAtDocumentationService.deleteDocumentation(documentation.Id);
            vm.gridDataSource.read();
        } catch {
            return;
        }
    }

    function openChangeTicketsModal(documentation) {
        $uibModal.open({
            templateUrl: '/App/Components/NetSuiteAuditTrail/ManageDocumentation/ChangeTicketsModal/changeTicketsModal.html',
            controller: changeTicketsModalController,
            controllerAs: 'ctmvm',
            backdrop: 'static',
            scope: $scope,
            size: 'lg',
            resolve: {
                documentation: () => documentation || {}
            }
        });
    }

    function openChangesModal(documentation) {
        $uibModal.open({
            templateUrl: '/App/Components/NetSuiteAuditTrail/ManageDocumentation/ChangesModal/changesModal.html',
            controller: changesModalController,
            controllerAs: 'cmvm',
            backdrop: 'static',
            scope: $scope,
            size: 'lg',
            resolve: {
                documentation: () => documentation || {}
            }
        });
    }
}