
export default /*@ngInject*/ function ($uibModalInstance, ownershipGroupsDataService, ownershipGroupName, routingCriteriaData) {

    const vm = this;
    vm.dismiss = dismiss;
    activate();

    async function activate() {
        vm.ownershipGroupName = ownershipGroupName;
        vm.ownershipGroupId = routingCriteriaData.RoutingCriteriaOwnershipGroupId;
        vm.routingCriteriaOwnershipGroups = await getOwnershipGroups();
        vm.routingCriteriaOwnershipGroup = vm.routingCriteriaOwnershipGroups.find(ownershipGroup => ownershipGroup.Id === vm.ownershipGroupId);    
    }

    async function getOwnershipGroups() {
        let { data } = await ownershipGroupsDataService.getOwnershipGroups();
        return data.filter(group => group.TypeName === 'Company');
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }

}