import { standardGridPageSize, customAutoCompleteFilter } from "../../GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $filter, helperService, companyTemplatesData) {

    var vm = this;
    vm.cancel = cancel;
    vm.close = close;
    vm.changeTemplateRowSelected = changeTemplateRowSelected;

    activate();

    function activate() {
        configureTemplatesGrid();
    }

    function cancel() { $uibModalInstance.dismiss(); }

    function close(selectedTemplate) { $uibModalInstance.close(selectedTemplate.Companies); }

    function configureTemplatesGrid() {
        const templatesGridColumns = [
            { title: "Name", field: "Name", filterable: customAutoCompleteFilter }
        ];

        vm.templatesGridOptions = helperService.setMainGridOptions(templatesGridColumns, 400);

        vm.templatesGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let response = await companyTemplatesData.getCompanyTemplates();
                    response.data = $filter('orderBy')(response.data, 'Name');
                    options.success(response.data);
                }
            }
        });
    }

    function changeTemplateRowSelected(kendoEvent) {
        const grid = kendoEvent.sender;
        vm.selectedTemplate = grid.dataItem(grid.select()).toJSON();
    }
}