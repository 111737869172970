import angular from 'angular';
import rolesDataServiceFactory from '../Components/Roles/rolesDataService.factory';
import securityAssignmentDataServiceFactory from '../Components/securityAssignment/securityAssignmentDataService.factory';
import usersDataServiceFactory from '../Components/Users/usersDataService.factory';
import passwordCriteria from '../Components/Configuration/PasswordCriteria/passwordCriteria.component';
import passwordCriteriaDataServiceFactory from '../Components/Configuration/PasswordCriteria/passwordCriteriaDataService.factory';
import auditLogs from '../Components/AuditLogs/auditLogs.component';
import users from '../Components/Users/users.component';
import roles from '../Components/Roles/roles.component';
import securityAssignment from '../Components/securityAssignment/securityAssignment.component';

export default angular
    .module('app.securitySetup', [])
    .component('roles', roles)
    .factory('rolesDataService', rolesDataServiceFactory)
    .component('securityAssignment', securityAssignment)
    .factory('securityAssignmentDataService', securityAssignmentDataServiceFactory)
    .component('users', users)
    .factory('usersDataService', usersDataServiceFactory)
    .component('passwordCriteria', passwordCriteria)
    .factory('passwordCriteriaDataService', passwordCriteriaDataServiceFactory)
    .component('auditLogs', auditLogs)
    .name;