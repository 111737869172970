export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getAutoMitigations,
        addAutoMitigation,
        modifyMitigation,
        deleteAutoMitigation,
        applyAutoMitigations,
        importAutoMitigationRuleset,
        exportAutoMitigationRuleset
    };

    return service;

    async function getAutoMitigations(productType) {
        try {
            const res = await $http.get(`${apiUrl}api/assure/${productType}/objectexclusions`);
            return res;
        } catch (err) {
            showError(err);
        }
    }

    function addAutoMitigation(productType, body) {
        return $http.post(`${apiUrl}api/assure/${productType}/objectexclusions`, body)
            .then(addAutoMitigationComplete)
            .catch(addAutoMitigationFailed);

        function addAutoMitigationComplete(response) {
            return response;
        }

        function addAutoMitigationFailed(response) {
            errorsDataService.catch(response);
        }
    }

    function modifyMitigation(productType, mitigationId, body) {
        return $http.put(`${apiUrl}api/assure/${productType}/objectexclusions/${mitigationId}`, body)
            .then(modifyMitigationComplete)
            .catch(modifyMitigationFailed);

        function modifyMitigationComplete(response) {
            return response;
        }

        function modifyMitigationFailed(response) {
            errorsDataService.catch(response);
        }
    }

    async function importAutoMitigationRuleset(productType, body) {
        try {
           return await $http.put(`${apiUrl}api/assure/${productType}/objectexclusions/import`, body, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function exportAutoMitigationRuleset(productType) {
        try {
            const res = await $http.get(`${apiUrl}api/assure/${productType}/objectexclusions/export`, { responseType: 'blob' });
            return res;
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteAutoMitigation(productType, mitigationId) {
        try {
            await $http.delete(`${apiUrl}api/assure/${productType}/objectexclusions/${mitigationId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function applyAutoMitigations(productType) {
        try {
            await $http.put(`${apiUrl}api/arm/usermitigations/product/${productType}/applyautomitigations`);
        } catch (err) {
            showError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}