import addTeamMemberModalController from "./addTeamMemberModal.controller";

export default /*@ngInject*/ function ($scope, $filter, $uibModal, dataService, notificationTeamsDataService) {

    const vm = this;

    vm.teamsList = [];

    vm.selectTeam = selectTeam;
    vm.selectProduct = selectProduct;
    vm.openAddTeamMemberModal = openAddTeamMemberModal;
    vm.removeTeamMember = removeTeamMember;

    activate();

    function activate() {
        vm.teamsList = [{ Name: 'Security Team', TeamType: 'Security', urlString: 'security' },
        { Name: 'Audit Team', TeamType: 'Audit', urlString: 'audit' },
        { Name: 'Access Certification Administrators Team', TeamType: 'AccessCertificationAdministrators', urlString: 'access-certification-administrators' }];

        vm.teamsList = $filter('orderBy')(vm.teamsList, 'Name');
    }

    function selectTeam(team) {
        vm.selectedTeam = team;

        if (team.TeamType === 'Security') {
            getAssociatedProducts();
        } else {
            vm.selectedProduct = null;
            getTeamMembers(team.urlString);
        }
    }

    async function getTeamMembers(teamTypeUrlString) {
        vm.loadingTeamMembers = true;

        try {
            let { data } = await notificationTeamsDataService.getTeamMembers(teamTypeUrlString);
            vm.teamMembersList = $filter('orderBy')(data, 'UserLogonName');
            vm.loadingTeamMembers = false;
        } catch {
            vm.loadingTeamMembers = false;
        }
    }

    async function selectProduct(product) {
        vm.selectedProduct = product;
        vm.selectedTeamMember = null;
        vm.loadingSelectedProductTeamMembers = true;

        try {
            let { data } = await notificationTeamsDataService.getTeamMembersByProduct(product.ID);
            vm.teamMembersList = $filter('orderBy')(data, 'UserLogonName');
            vm.loadingSelectedProductTeamMembers = false;
        } catch {
            vm.loadingSelectedProductTeamMembers = false;
        }
    }

    async function getAssociatedProducts() {
        vm.loadingAssociatedProducts = true;

        try {
            let { data } = await dataService.getProducts();
            vm.associatedProductsList = $filter('orderBy')(data, 'Name');
            vm.loadingAssociatedProducts = false;
        } catch {
            vm.loadingAssociatedProducts = false;
        }
    }

    async function removeTeamMember(selectedTeamMember) {
        try {
            await notificationTeamsDataService.removeTeamMember(selectedTeamMember.Id);
            updateTeamMembersList();
        } catch {
            return;
        }
    }

    async function openAddTeamMemberModal() {
        let modal = $uibModal.open({
            templateUrl: '/App/Components/Certifications/notificationTeams/addTeamMemberModal.html',
            controller: addTeamMemberModalController,
            controllerAs: 'atmmvm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                teamMembersList: () => vm.teamMembersList,
                teamType: () => vm.selectedTeam.TeamType,
                selectedProduct: () => vm.selectedProduct
            }
        });

        try {
            await modal.result;
            updateTeamMembersList();
        } catch (cancel) {
            return;
        }
    }

    function updateTeamMembersList() {
        if (vm.selectedProduct) {
            selectProduct(vm.selectedProduct);
        } else {
            getTeamMembers(vm.selectedTeam.urlString)
        }
    }
}