export default /*@ngInject*/ function ($scope) {

    //If $scope variable doesnt exist in child it looks in parent

    $scope.addRoleToList = function (role) {
        $scope.vm.ListOfAvailableAADRoles.forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfAvailableAADRoles.splice(i, 1);
                $scope.vm.ListOfSelectedAADRoles.push(role);
            }
        });
        $scope.vm.IsRisksReviewed = null;
    };

    $scope.deleteSelectedRole = function (role) {
        $scope.vm.ListOfSelectedAADRoles.forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfSelectedAADRoles.splice(i, 1);
                $scope.vm.ListOfAvailableAADRoles.push(row);
            }
        });

        $scope.vm.IsRisksReviewed = null;
    };

    $scope.selectedRole = null;

}