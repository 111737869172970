export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getProfile,
        getWorkdayProfile,
        getSecurityRoleDetails,
        getAccess,
        getCatalog
    };

    return service;

    async function getProfile(identifier) {
       try {
         const res = await $http.get(`${apiUrl}api/universal/securityroles/${identifier}`);
         return res.data;
       } catch(err) {
          showError(err);
       }
    }

    async function getWorkdayProfile(securityRoleId) {
        try {
            const res = await $http.get(`${apiUrl}api/universal/wd/securityrole/${securityRoleId}`);
            return res.data;
        } catch (err) {
            showError(err);
        }
    }

    async function getSecurityRoleDetails(roleId, productId) {
        try {
            const res = await $http.get(`${apiUrl}api/universal/securityroles/${roleId}/product/${productId}`);
            return res.data;
        } catch (err) {
            showError(err);
        }
    }

    async function getAccess(identifier, data, page, pageSize) {
      try {
          const res = await $http({
              method: 'POST',
              url: `${apiUrl}api/universal/securityroles/${identifier}/access`,
              data: data,
              params: { 'pagesize': pageSize, 'page': page }
          });
         return res;
      } catch(err) {
         showError(err);
      }
    }

    async function getCatalog(reportId, data, page, pageSize) {
        try {
            return await $http({
                method: 'POST',
                url: `${apiUrl}api/core/reports/${reportId}/execute`,
                data: data,
                params: { 'pagesize': pageSize, 'page': page }
            });
        } catch (err) {
            showError(err);
        }
    }

    function showError(err) {
      errorsDataService.catch(err);
    }

}