export default /*@ngInject*/ function ($http) {

    //AX 7 HTTP Calls
    this.getAllAADRoles = function () {
        return $http({
            method: 'GET',
            url: `${apiUrl}api/identitymanager/aad/roles`
        });
    };

    this.getAllAADGroups = function () {
        return $http({
            method: 'GET',
            url: `${apiUrl}api/identitymanager/aad/groups`
        });
    };

    this.getAllAADLicenses = function () {
        return $http({
            method: 'GET',
            url: `${apiUrl}api/identitymanager/aad/licenses`
        });
    };

    this.getAADUserData = function (userId) {
        return $http({
            method: 'GET',
            url: `${apiUrl}api/identitymanager/aad/users/${userId}`
        });
    };
}