export default /*@ngInject*/ function (userMasterId, userId, productId, userProfileDataService, $uibModalInstance) {

    const vm = this;

    vm.dismiss = dismiss;
    activate();

    async function activate() {
        if (productId === 'WD') {
            vm.workDayProfile = await userProfileDataService.getWorkdayProfileInfo(userId);
        }
        vm.profile = await getUserProfile();
    }

    async function getUserProfile() {
        let response;
        if (userMasterId) {
            response = await userProfileDataService.getUserProfileByUserMasterId(userMasterId);
        } else {
            response = await userProfileDataService.getUserProfileByUserIdAndProductId(userId, productId);
        }

        return response.data;
    }

    function dismiss() {
        $uibModalInstance.close();
    }
}