export default function () {
    const service = {
        getReviewTypes
    };
    return service;

    function getReviewTypes() {
        return [
            { Name: "Risk", Id: "Risk", Background: "review-assignment_Risk", Icon: "icons8-risk" },
            { Name: "Security Role", Id: "SecurityRole", Background: "review-assignment_Roles", Icon: "icons8-user-avatar" },
            { Name: "Security Role Content", Id: "SecurityRoleContent", Background: "review-assignment_Roles", Icon: "icons8-user-menu-male" },
            { Name: "Business Process", Id: "BusinessProcess", Background: "review-assignment_BusinessProcess", Icon: "icons8-warning-shield" },
            { Name: "Product", Id: "Product", Background: "review-assignment_Products", Icon: "icons8-globe" },
            { Name: "Manager", Id: "Manager", Background: "review-assignment_Manager", Icon: "icons8-briefcase" }
        ];
    }
}