import createSpinner from "../../../Shared/Functions/createSpinner";
import { standardGridPageSize, customAutoCompleteFilter } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($scope, $filter, $uibModalInstance, selectedCompanyForManualMapping, selectedCompanyProductClicked, helperService, dataService, companyMappingDataService, $http) {
    const vm = this;

    vm.updateMapping = updateMapping;
    vm.changeRowSelected = changeRowSelected;
    vm.cancel = cancel;

    vm.selectedCompanyForManualMapping = selectedCompanyForManualMapping;
    vm.selectedCompanyProductClicked = selectedCompanyProductClicked;

    activate();

    function activate() {
        createGrid();
    }


    function createGrid() {
        let columnsFromVM = [
            { field: "Name", title: "Company", filterable: customAutoCompleteFilter }
        ];

        vm.manualMapGridOptions = helperService.setMainGridOptions(columnsFromVM, 400);
        vm.manualMapGridOptions.selectable = true;

        vm.manualMapDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let response = await dataService.getCompaniesByProductId(selectedCompanyProductClicked.ProductID);
                    response.data = $filter('orderBy')(response.data, 'Name');
                    options.success(response.data);
                }
            }
        });
    }


    async function updateMapping() {
        //Check to see if product exists in CompanyMappings
        let productAlreadyExists = selectedCompanyForManualMapping.CompanyMappings.some(e => e.ProductID === vm.selectedRow.ProductID);
        vm.pleaseWait = new createSpinner();
        if (productAlreadyExists) {
            await deleteMapping();
            postMapping(true);
        } else {
            postMapping();
        }
    }

    async function postMapping(callRemove) {
        let postBody = {
            "CompanyMasterID": selectedCompanyForManualMapping.ID,
            "ProductID": vm.selectedRow.ProductID,
            "CompanyID": vm.selectedRow.ID
        };
        try {          
            await companyMappingDataService.createMapping(postBody);
            await helperService.successfulSaveButton(vm.pleaseWait);
            $uibModalInstance.close(callRemove);
        } catch {
            vm.pleaseWait.loadingValue = false;
        }
        
    }

    async function deleteMapping() {
        let dataItem = selectedCompanyForManualMapping;
        let launchedFromProductID = vm.selectedRow.ProductID;

        let companyMapping = selectedCompanyForManualMapping.CompanyMappings.find(e => e.ProductID === launchedFromProductID);

        let companyId = encodeURIComponent(companyMapping.CompanyID);

        try {
            await companyMappingDataService.deleteMapping(companyMapping, companyId);

        } catch {
            return;
        }

        $http.delete(apiUrl + 'api/universal/companymapping/companymaster/' + companyMapping.CompanyMasterID + '/product/' + companyMapping.ProductID + '/company/' + CompanyID + '/').then(function (response) {
            $scope.removeMappingFromDataSource(dataItem, launchedFromProductID);
        }, function errorCallback(response) {
            helperService.showErrorMessage(response.data);
        });
    }

    function changeRowSelected(dataItem) {
        vm.selectedRow = dataItem;
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }

}