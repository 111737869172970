import { standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, existingUserSearchService, $rootScope, $filter, helperService, userObject, azureADUserObjectValueMapperService) {
    const vm = this;

    vm.title = 'Entra ID User Lookup';
    vm.filterUsersToggle = true;
    vm.selectedUser = null;

    vm.cancel = cancel;
    vm.doneSearchingUsers = doneSearchingUsers;
    vm.selectUser = selectUser;
    vm.filterUsers = filterUsers;
    vm.changeExistingUserRowSelected = changeExistingUserRowSelected;
   
    if (!userObject) {
        userObject = {};
    }

    const userGridColumns = [
        { field: "DisplayName", title: "Display Name", filterable: false },
        { field: "UserPrincipalName", title: "Email", filterable: false },
    ];

    function doneSearchingUsers() {

        $rootScope.IMSelectedExistingUser = null;

        if (vm.ExistingWindowsUserSearchString === '' || vm.ExistingWindowsUserSearchString === null) {
            vm.ExistingusersGridDataSource.data([]);
        } else {
            vm.ExistingusersGridDataSource.read();
        }
    }

    vm.ExistingUsersGridOptions = helperService.setMainGridOptions(userGridColumns, 350);
    vm.ExistingUsersGridOptions.autoBind = false;

    vm.ExistingusersGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: async (options) => {
                try {
                    if (vm.ExistingWindowsUserSearchString) {
                        let { data: existingUsers } = await existingUserSearchService.getFilteredAzureADUsers(vm.ExistingWindowsUserSearchString, vm.filterUsersToggle);
                        existingUsers = $filter('orderBy')(existingUsers, 'DisplayName');
                        options.success(existingUsers);
                    }  
                } catch (err) {
                    options.error();
                }
            }
        }
    });

    async function selectUser() {
        let emailMappingField, emailMapConfig = await helperService.getConfigurationValue('IM_AADFieldForEmailMapping');
        emailMappingField = emailMapConfig === '0' ? 'UserPrincipalName' : 'Mail'
        if (vm.selectedUser) {
            userObject = Object.assign(userObject, vm.selectedUser);
            userObject = azureADUserObjectValueMapperService.valueMap(userObject, emailMappingField);
        }
        if (vm.selectedUser.UserType === 'Guest') {
            let emailFromPrincipalName = vm.selectedUser.UserPrincipalName.split("#EXT#")[0];
            const underscoreIndex = emailFromPrincipalName.lastIndexOf("_");
            userObject.Email = emailFromPrincipalName.substr(0, underscoreIndex) + '@' + emailFromPrincipalName.substr(underscoreIndex + 1);
            userObject.NetworkDomain = `https://sts.windows.net/${userObject.Email.slice(userObject.Email.indexOf('@')).slice(1)}`;
        }
        vm.ExistingWindowsUserSearchString = null;
        $uibModalInstance.close(userObject);
    }

    function changeExistingUserRowSelected(kendoEvent) {
        let grid = kendoEvent.sender;
        vm.selectedUser = grid.dataItem(grid.select());
    }

    function filterUsers() {
        if (vm.ExistingusersGridDataSource._data.length > 0) {
            vm.ExistingusersGridDataSource.read();
        }
    }

    function cancel() { $uibModalInstance.dismiss(); }
}