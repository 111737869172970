export default /* @ngInject */ function ($translateProvider) {

    $translateProvider
        .translations('en', {

            SEARCH: 'Search for something...'

        })
        .translations('es', {

            SEARCH: 'Busca algo ...'
        });

    $translateProvider.preferredLanguage('en');
}