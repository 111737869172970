export default /*@ngInject*/ function ($scope) {

    //If $scope variable doesnt exist in child it looks in parent

        $scope.addCompanyToList = function (company) {
            $scope.vm.ListOfAvailableGPCompanies.forEach(function (row, i) {
                if (row.Id === company.Id) {
                    $scope.vm.ListOfAvailableGPCompanies.splice(i, 1);
                    $scope.vm.ListOfSelectedGPCompanies.push(row);
                }
            });
            $scope.vm.IsRisksReviewed = null;
        };

        $scope.deleteSelectedCompany = function (company) {
            $scope.vm.ListOfSelectedGPCompanies.forEach(function (row, i) {
                if (row.Id === company.Id) {
                    $scope.vm.ListOfSelectedGPCompanies.splice(i, 1);
                    $scope.vm.ListOfAvailableGPCompanies.push(row);

                    $scope.vm.ListOfStoredGPRoles = $scope.vm.ListOfStoredGPRoles.filter(selectedRole => selectedRole.Company.Id !== company.Id);
                }
            });
            $scope.vm.IsRisksReviewed = null;
        };
}