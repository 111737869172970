import swal from "sweetalert2";
import roleModalController from "./roleModal.controller";

export default /*@ngInject*/ function (sapSdSimRoleDataService, helperService, $uibModal, $filter) {

    const vm = this;

    vm.changeSelectedRole = changeSelectedRole;
    vm.openSimulationRoleModal = openSimulationRoleModal;
    vm.manageRoleAuthorizations = manageRoleAuthorizations;
    vm.manageOrganizationalLevels = manageOrganizationalLevels;
    vm.deleteSimulationRole = deleteSimulationRole;
    vm.changeRoleType = changeRoleType;

    vm.menuOptions = [
        {
            text: 'Define Organizational Levels',
            click: item => {
                vm.manageOrganizationalLevels(item.role);
            }
        },
        {
            text: 'Authorizations...',
            children: _ => {
                return [
                    {
                        text: 'Manage Authorizations',
                        click: item => {
                            vm.manageRoleAuthorizations(item.role);
                        }
                    },
                    {
                        text: 'Grant Full Authorization',
                        click: item => {
                            grantRoleFullAuthorization(item.role);
                        }
                    },
                ];
            },
        },
        {
            text: 'Edit',
            click: item => {
                vm.openSimulationRoleModal("EditExisting", item.role);
            },
            displayed: modelValue => {
                return modelValue.role.IsCustom;
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: item => {
                vm.deleteSimulationRole(item.role);
            }
        }
    ];

    vm.$onChanges = activate;

    async function activate() {
        vm.selectedRole = null;
        getRoles();
    }

    async function getRoles() {
        vm.rolesLoading = true;
        const params = {
            isModified: true
        };
        let response = await sapSdSimRoleDataService.getSimulationRoles(vm.simulation.Id, params);
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.roles = response.data;
        vm.rolesLoading = false;
    }

    async function changeSelectedRole(role) {
        vm.selectedRole = role;
        vm.onSelectedRoleChange({ role });
    }

    async function openSimulationRoleModal(actionType, role) {
        let roleModal = $uibModal.open({
            templateUrl: '/App/Components/SAPSecurityDesigner/simulationRolesList/roleModal.tmpl.html',
            controller: roleModalController,
            controllerAs: 'rmvm',
            backdrop: 'static',
            resolve: {
                simulation: () => vm.simulation,
                actionType: () => actionType,
                role: () => role,
                existingRoles: () => vm.roles
            }
        });

        try {
            let roleId = await roleModal.result;
            await getRoles();
            if (roleId) {
                changeSelectedRole(vm.roles.find(r => r.Id === roleId));
                refreshSimulations();
            }
        } catch { return; }
    }

    async function manageRoleAuthorizations(role) {
        vm.onManageAuthClicked({ role });
    }

    async function manageOrganizationalLevels(role) {
        vm.onDefineOrgLevelsClicked({ role });
    }

    async function grantRoleFullAuthorization(role) {
        await sapSdSimRoleDataService.grantRoleFullAuthorization(vm.simulation.Id, role.Id);
        refreshSimulations();
        getRoles();
    }

    async function deleteSimulationRole(role) {
        try {
            await swal(helperService.areYouSureParams(`Remove Role`, `Are you sure you want to remove role ${role.Name} from simulation ${vm.simulation.Name}?`, `Yes`, 'No'));
            vm.rolesLoading = true;
            await sapSdSimRoleDataService.removeRoleFromSimulation(vm.simulation.Id, role.Id);
            changeSelectedRole(null);
            refreshSimulations();
            getRoles();
        } catch {
            return;
        }
    }

    function changeRoleType() {
        let roleType = vm.selectedRoleType;
        vm.onRoleTypeChange({ roleType });
    }

    function refreshSimulations() {
        const updateList = true;
        vm.onUpdateSimulationsList({ updateList });
    }
}