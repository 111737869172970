import { standardGridPageSize, customAutoCompleteFilter } from "../../Shared/GlobalVariables/filterVariables";
import swal from "sweetalert2";
import createSpinner from "../../Shared/Functions/createSpinner";
import addTemplateModalController from "./addTemplateModal.controller";
import newAddTemplateTableModalController from "./newAddTemplateTableModal.controller"
import importTemplatesModalController from "./importTemplatesModal/importTemplatesModal.controller";

export default /*@ngInject*/ function ($scope, $uibModal, $http, objectIdDataService, AuditTrailEntityDataService, $filter, helperService, FileSaver) {

    const vm = this;

    vm.selectedTemplate = null;
    vm.selectedProduct = null;
    vm.stopTemplateDetailsSpinner = false;

    vm.atTableActionChanged = atTableActionChanged;
    vm.deleteItem = deleteItem;
    vm.cloneItem = cloneItem;
    vm.changeRowSelected = changeRowSelected;
    vm.removeSelectedTable = removeSelectedTable;
    vm.atFieldActionChanged = atFieldActionChanged;
    vm.checkAll = checkAll;
    vm.resetTemplate = resetTemplate;
    vm.openAddTemplateModal = openAddTemplateModal;
    vm.openAddTableModal = openAddTableModal;
    vm.submit = submit;
    vm.openImportModal = openImportModal;
    vm.exportTemplates = exportTemplates;


    //Save button is disabled by default
    vm.changeDetected = false;

    $scope.$on('ImportAuditTrailTemplates', function (event, data) {
        // refresh the Templates grid when import completes
        vm.gridDataSource.read();
    });

    vm.mainGridColumns = [
        { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
        { field: "IsCustom", template: "<span ng-if='dataItem.IsCustom' class='label label-custom-template'>Custom</span><span ng-if='!dataItem.IsCustom' class='label label-out-of-box-template'>Out of Box</span>", "title": "Template Type", filterable: false, width: 100 },
        { template: `<a ng-click="mtvm.deleteItem(#=Id#)" ng-hide="!dataItem.IsCustom"><i class="glyphicon glyphicon-trash text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 90 },
        { template: `<a ng-click="mtvm.cloneItem(dataItem)">Clone</a>`, "title": "Clone", width: 90 }];

    vm.mainGridOptions = helperService.setMainGridOptions(null, 600);
    vm.mainGridOptions.autoBind = true;
    vm.mainGridOptions.dataSource = vm.gridDataSource;

    vm.mainGridOptions.dataBound = function (e) {
        if (vm.selectedTemplate) {
            let data = e.sender._data;
            data.forEach(function (dataItem) {
                if (vm.selectedTemplate.Id === dataItem.Id) {
                    $('[data-uid=' + dataItem.uid + ']').addClass('k-state-selected');
                }
            });
        }
    };

    function atActionChanged(obj) {
        $(obj).toggleClass("atactive");
    }

    vm.gridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: function (options) {
                AuditTrailEntityDataService.getAllTemplates().then(function (response) {
                    vm.mapTemplateResults = null;

                    AuditTrailEntityDataService.mapTemplateHeaders(response.data).done(function (mapTemplateResponse) {
                        if (mapTemplateResponse) {
                            mapTemplateResponse = $filter('orderBy')(mapTemplateResponse, 'Name');

                            options.success(mapTemplateResponse);
                        }
                    });

                }, function errorCallback(response) {
                    options.error();
                });
            }
        }
    });

    async function deleteItem(id) {
        kendo.ui.progress(vm.grid.element, true);
        try {
            await AuditTrailEntityDataService.deleteTemplate(id);
            kendo.ui.progress(vm.grid.element, false);
            vm.gridDataSource.read();
        } catch (error) {
            helperService.showErrorMessage(error.data);
        }
        vm.selectedTemplate = null;
    }

    function cloneItem(dataItem) {
        vm.selectedTemplateToClone = dataItem;
        openAddTemplateModal(true);
    }

    vm.originalTemplateTrackedTables = null;

    async function changeRowSelected(dataItem) {
        vm.templateDetailsLoading = true;

        try {
            let response = await AuditTrailEntityDataService.mapTemplate(dataItem, true);
            vm.selectedTemplate = await response;

            if (vm.selectedTemplate.IsCustom) {
                //If it's a custom template copy it to the 'original' placeholder and reset the hasTemplateChanged function
                vm.originalTemplateTrackedTables = angular.copy(vm.selectedTemplate.TrackedTableList);
                hasTemplatedChanged();
            } else {
                vm.originalTemplateTrackedTables = null;
            }
            vm.templateDetailsLoading = false;
        } catch {
            vm.templateDetailsLoading = false;
        }
    }
    function atTableActionChanged(event, object, type) {
        return atActionChanged(event, object, null, type);
    }

    function removeSelectedTable(table) {
        vm.selectedTemplate.TrackedTableList.forEach(function (row, i) {
            if (row.Id === table.Id) {
                vm.selectedTemplate.TrackedTableList.splice(i, 1);
            }
        });

        hasTemplatedChanged();
    }

    //Changing a field needs acccess to the containing table to modify the action list
    function atFieldActionChanged(event, object, table, type) {
        return atActionChanged(event, object, table, type);
    }

    function atActionChanged(event, object, table, type) {
        if (vm.selectedTemplate.IsCustom === true) {
            if (object.Type === 'Table') {

                //All
                if (type === 1 && !object.TrackAll) {
                    object.TrackAll = true;
                    object.TrackInsert = true;
                    object.TrackUpdate = true;
                    object.TrackDelete = true;
                }
                else if (type === 1 && object.TrackAll === true) {
                    object.TrackAll = false;
                    object.TrackInsert = false;
                    object.TrackUpdate = false;
                    object.TrackDelete = false;
                }

                //Insert
                if (type === 2) {
                    object.TrackInsert = !object.TrackInsert;
                }

                //Update
                if (type === 3) {
                    object.TrackUpdate = !object.TrackUpdate;
                }

                //Delete
                if (type === 4) {
                    object.TrackDelete = !object.TrackDelete;
                }

                if (object.TrackInsert && object.TrackUpdate && object.TrackDelete) {
                    object.TrackAll = true;
                }
                else {
                    object.TrackAll = false;
                }

                object.TrackedFields.forEach(function (field) {

                    //All
                    if (type === 1 && !object.TrackAll) {
                        field.TrackAll = false;
                        field.TrackInsert = false;
                        field.TrackUpdate = false;
                        field.TrackDelete = false;
                    }
                    else if (type === 1 && object.TrackAll === true) {
                        field.TrackAll = true;
                        field.TrackInsert = true;
                        field.TrackUpdate = true;
                        field.TrackDelete = true;
                    }

                    //Insert
                    if (type === 2) {
                        field.TrackInsert = object.TrackInsert;
                    }

                    //Update
                    if (type === 3) {
                        field.TrackUpdate = object.TrackUpdate;
                    }

                    //Delete
                    if (type === 4) {
                        field.TrackDelete = object.TrackDelete;
                    }

                    if (object.TrackInsert && object.TrackUpdate && object.TrackDelete) {
                        field.TrackAll = true;
                    }
                    else {
                        field.TrackAll = false;
                    }


                });
            }

            if (object.Type === 'Field') {
                //All
                if (type === 1 && !object.TrackAll) {
                    object.TrackAll = true;
                    object.TrackInsert = true;
                    object.TrackUpdate = true;
                    object.TrackDelete = true;
                }
                else if (type === 1 && object.TrackAll === true) {
                    object.TrackAll = false;
                    object.TrackInsert = false;
                    object.TrackUpdate = false;
                    object.TrackDelete = false;
                }

                //Insert
                if (type === 2) {
                    object.TrackInsert = !object.TrackInsert;

                    if (object.TrackInsert === false) {
                    }
                }

                //Update
                if (type === 3) {
                    object.TrackUpdate = !object.TrackUpdate;

                    if (object.TrackUpdate === false) {
                    }
                }

                //Delete
                if (type === 4) {
                    object.TrackDelete = !object.TrackDelete;

                    if (object.TrackDelete === false) {
                    }
                }

                if (object.TrackInsert && object.TrackUpdate && object.TrackDelete) {
                    object.TrackAll = true;
                }
                else {
                    object.TrackAll = false;
                }

                if (table) {
                    if (table.TrackedFields.every(checkInsert) === true) {
                        table.TrackInsert = true;
                    }
                    else {
                        table.TrackInsert = false;
                    }

                    if (table.TrackedFields.every(checkUpdate) === true) {
                        table.TrackUpdate = true;
                    } else {
                        table.TrackUpdate = false;
                    }

                    if (table.TrackedFields.every(checkDelete) === true) {
                        table.TrackDelete = true;
                    } else {
                        table.TrackDelete = false;
                    }

                    if (table.TrackInsert === true && table.TrackUpdate === true && table.TrackDelete === true) {
                        table.TrackAll = true;
                    } else {
                        table.TrackAll = false;
                    }
                }

            }

            hasTemplatedChanged();
        }
    }

    //Check if the tracked fields list match, if they don't enable the save'
    function hasTemplatedChanged() {
        let isTheSame = true;
        let originalTable = null;
        let existingTable = null;

        //Check the template to see if any tables have tracked field changes. If a new table has been added it must have at least one tracked field to enable the Save button
        for (let i = 0; i < vm.selectedTemplate.TrackedTableList.length; i++) {
            if (vm.originalTemplateTrackedTables) {
                originalTable = vm.originalTemplateTrackedTables.find(function (orgTable) {
                    return orgTable.Id === vm.selectedTemplate.TrackedTableList[i].Id;
                });
            }
            if (originalTable) {
                isTheSame = angular.equals(originalTable.TrackedFields, vm.selectedTemplate.TrackedTableList[i].TrackedFields);
                if (isTheSame === false) {
                    break;
                }
            }
            else {
                let trackedField = false;
                for (let j = 0; j < vm.selectedTemplate.TrackedTableList[i].TrackedFields.length; j++) {
                    if (vm.selectedTemplate.TrackedTableList[i].TrackedFields[j].TrackInsert === true || vm.selectedTemplate.TrackedTableList[i].TrackedFields[j].TrackUpdate === true || vm.selectedTemplate.TrackedTableList[i].TrackedFields[j].TrackDelete === true) {
                        trackedField = true;
                        break;
                    }
                }

                if (trackedField === true) {
                    isTheSame = false;
                }
            }
        }

        //Check if any original tables were deleted from the template
        if (vm.originalTemplateTrackedTables) {
            for (let i = 0; i < vm.originalTemplateTrackedTables.length; i++) {
                existingTable = vm.selectedTemplate.TrackedTableList.find(function (table) {
                    return table.Id === vm.originalTemplateTrackedTables[i].Id;
                });

                if (existingTable === null || existingTable === undefined) {
                    isTheSame = false;
                }
            }
        }

        vm.changeDetected = !isTheSame;
    };

    function checkAll(field) {
        return field.TrackInsert === true && field.TrackUpdate === true && field.TrackDelete === true;
    }

    function checkInsert(field) {
        return field.TrackInsert === true;
    }

    function checkUpdate(field) {
        return field.TrackUpdate === true;
    }

    function checkDelete(field) {
        return field.TrackDelete === true;
    }

    objectIdDataService.getObjectID().then(function (objectId) {
        vm.ObjectId = objectId;

        let securityObject = helperService.getSecurityObjectDetailsFromObjectId(vm.ObjectId);
        vm.pageInfo = securityObject;
        vm.pageDescription = securityObject.HelpLink;
        vm.reportName = securityObject.Name;

    });

    function resetTemplate() {
        swal(helperService.areYouSureParams('Reset Template: ' + vm.selectedTemplate.Name, 'Are you sure you want to proceed? This will reset this template to the original settings provided by Fastpath. This cannot be undone.', 'Reset')).then(function () {
            ResetTemplate();
        }, function (dismiss) {
            return;
        });
    }

    function ResetTemplate() {
        if (vm.selectedTemplate) {
            AuditTrailEntityDataService.revertTemplate(vm.selectedTemplate.Id).then(function (response) {

                AuditTrailEntityDataService.mapTemplate(vm.selectedTemplate, true).done(function (response) {
                    vm.selectedTemplate = response;
                });

            }, function errorCallback(response) {
                helperService.showErrorMessage(response.data);
            });
        }
    }

    async function openAddTemplateModal(isClone) {
        let addTemplateModal = $uibModal.open({
            templateUrl: '/App/Components/TemplateTracking/addTemplateModal.tmpl.html',
            controller: addTemplateModalController,
            controllerAs: 'atmvm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                isClone: () => isClone,
                selectedTemplateToClone: () => vm.selectedTemplateToClone,
                selectedTemplate: () => vm.selectedTemplate,
                templateGridDataSource: () => vm.gridDataSource,
                changeRowSelected: () => vm.changeRowSelected
            }
        });

        try {
            let newTemplateId = await addTemplateModal.result, response = await $http.get(`${apiUrl}api/audittrail/template/${newTemplateId}`), mappedNewTemplate = await AuditTrailEntityDataService.mapTemplateHeader(response.data), grid = $("#maintainTemplatesGrid").data("kendoGrid");
            vm.gridDataSource.insert(0, mappedNewTemplate);
            changeRowSelected(response.data);
            grid.select("tr:eq(0)");
        } catch {
            return;
        }
    }

    function openAddTableModal() {

        $uibModal.open({
            templateUrl: '/App/Components/TemplateTracking/newAddTemplateTableModal.tmpl.html',
            controller: newAddTemplateTableModalController,
            controllerAs: 'nattmvm',
            backdrop: 'static',
            size: 'lg',
            scope: $scope,
            resolve: {
                selectedTemplate: () => vm.selectedTemplate
            }
        });
    }

    function submit() {
        if (vm.changeDetected) {
            let convertedTables = AuditTrailEntityDataService.convertFromTrackedTable(vm.selectedTemplate.Id, vm.selectedTemplate.TrackedTableList);

            //remove all the fields that aren't being tracked
            convertedTables.forEach(function (table) {
                table.Fields.forEach(function (field, i) {
                    if (field.TrackInsert === false && field.TrackUpdate === false && field.TrackDelete === false) {
                        table.Fields.splice(i, 1);
                    }
                });
            });

            let allTablesHaveFieldsToTrack = true;

            for (let i = 0; i < convertedTables.length; i++) {
                let numberOfValidFields = 0;

                convertedTables[i].Fields.forEach(function (field, i) {
                    if (field.TrackInsert === true || field.TrackUpdate === true || field.TrackDelete === true) {
                        numberOfValidFields += 1;
                    }
                });

                if (numberOfValidFields === 0) {
                    //This table will not be saved because it has no fields to track
                    allTablesHaveFieldsToTrack = false;
                    helperService.showErrorMessage("One or more of the tables are not tracking anything");
                    break;
                }
            }

            if (allTablesHaveFieldsToTrack === true) {
                vm.pleaseWait = new createSpinner();

                AuditTrailEntityDataService.updateTemplateTables(vm.selectedTemplate.Id, convertedTables).then(function (response) {
                    helperService.successfulSaveButton(vm.pleaseWait);
                }, function errorCallback(response) {
                    vm.pleaseWait.loadingValue = false;
                    helperService.showErrorMessage(response.data);
                });
            }
        }

        vm.changeDetected = false;
        vm.gridDataSource.read();
    }

    function openImportModal() {
        let importModal = $uibModal.open({
            templateUrl: '/App/Components/TemplateTracking/importTemplatesModal/importTemplatesModal.html',
            controller: importTemplatesModalController,
            controllerAs: 'itmvm',
            backdrop: 'static'
        });

        importModal.result
            .then(_ => { return; })
            .catch(_ => { return; });
    }

    function exportTemplates() {
        vm.exportUpdatePending = new createSpinner();

        AuditTrailEntityDataService.exportTemplates()
            .then(response => {
                helperService.successfulSaveButton(vm.exportUpdatePending)
                    .then(_ => {
                        const selectedTenant = JSON.parse(localStorage.getItem("selectedTenant"));
                        FileSaver.saveAs(response.data, `D365FO Audit Trail Template Export - ${selectedTenant.Name}.xlsx`);
                        vm.exportUpdatePending.loadingValue = false;
                    });
            }).catch(response => {
                helperService.showErrorMessage(response.data);
                vm.exportUpdatePending.loadingValue = false;
            });
    }
}

