import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, dataService, connectionsDataService, modules) {
    const vm = this;

    vm.$onInit = init;

    vm.productTypesDataSource = [];
    vm.createNewProduct = createNewProduct;
    vm.cancel = cancel;
    vm.updateInProgress = false;

    async function init() {
        const customProductType = {
            Type: 'Custom',
            Name: 'Custom',
            ModuleId: 10000
        },
        atProductType = {
            Type: 'AuditTrail',
            Name: 'Change Tracking',
            ModuleId: 3000
        };
        let response;

        try {
            response = await dataService.getProductTypes();

            if (response.data.some(prod => prod.Type === 'SAP')) {
                const obj = response.data.find(prod => prod.Type === 'SAP');
                obj.ModuleId = 10000;
                vm.productTypesDataSource.push(obj);
            }

            vm.productTypesDataSource.unshift(customProductType);

            if (modules.some(item => item.ObjectID === atProductType.ModuleId)) {
                vm.productTypesDataSource.unshift(atProductType);
            }

            vm.selectedProductType = customProductType;

        } catch (response) {
            helperService.showErrorMessage(response.data);
        }
    }

    async function createNewProduct() {
        const newProduct = {
            Name: vm.newProduct.Name,
            ID: '',
            Type: vm.selectedProductType.Type,
            ModuleId: vm.selectedProductType.ModuleId
        };

        vm.updateInProgress = new createSpinner();

        try {
            const { data: newId } = await connectionsDataService.createNewProduct(newProduct);

            await helperService.successfulSaveButton(vm.updateInProgress);
            vm.cancel(newId);
        } catch (response) {
            vm.updateInProgress.loadingValue = false;
        }
    }

    function cancel(newProduct) {
        $uibModalInstance.close(newProduct);
    }
}