import roleModalController from "../RoleModal/roleModal.controller";

export default /*@ngInject*/ function ($uibModal, roleTemplatesData) {

    const vm = this;

    vm.openRoleModal = openRoleModal;
    vm.deleteRole = deleteRole;

    async function deleteRole(role) {
        vm.deleteProcessing = true;
        try {
            await roleTemplatesData.deleteRole(vm.template.Id, role.Identifier);
            const removedRole = vm.template.Roles.findIndex(r => r.Identifier === role.Identifier);
            await vm.template.Roles.splice(removedRole, 1);
            vm.deleteProcessing = false;
        } catch {
            vm.deleteProcessing = false;
        }
    }

    async function openRoleModal() {
        $uibModal.open({
            templateUrl: '/App/Components/RoleTemplates/RoleModal/roleModal.html',
            controller: roleModalController,
            controllerAs: 'rmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                template: () => vm.template
            }
        });
    }

}
