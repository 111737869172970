export default /*@ngInject*/ function ($scope, $rootScope, signalRTenantFactory, signalRUserFactory, objectIdDataService, $http, dataService, $window, $location, helperService, authenticationService, whiteListService) {

    // This is the parent controller/viewmodel for 'angular.module('main')' and its $scope is accesible
    // down controllers set by the routing engine. This controller is bound to the Report.cshtml in the
    // Home view-folder.

    $scope.helperService = helperService;

    $rootScope.reloadConfigurationValues = function () {
        $http.get(apiUrl + 'api/core/configuration').then(function (response) {
            var configValues = {};
            response.data.forEach(function (row) {
                configValues[row.Name] = row.Value;
            });
            $rootScope.ConfigurationValues = configValues;
            var stringified = JSON.stringify(configValues);

            localStorage.setItem("ConfigurationValues", stringified);
        });
    };

    activate();

    function activate() {
        loadUserSecurityObjects();
        $rootScope.reloadConfigurationValues();
    }

    $scope.$on('reportLocationEvent', function (event, data) {
        $scope.ReportScriptLocation = data;
    });

    dataService.getProducts().then(function (response) {
        $rootScope.availableProductsForUser = response.data;
    });

    $scope.$on('userpreferences-change', function (event, args) {
        $scope.LoadUserPreferences();
    });

    $rootScope.currentHoveredCell = function (e) {
        let data = { cellData: e.target[0].innerText };
        let template = kendo.template("<span ng-non-bindable>#: cellData #</span>");
        let tooltip = template(data);
        return tooltip;
    };

    kendo.ui.Tooltip.fn._show = function (show) {
        return function (target) {
            const e = {
                sender: this,
                target: target,
                preventDefault: function () {
                    this.isDefaultPrevented = true;
                }
            };
            if (typeof this.options.beforeShow === "function") {
                this.options.beforeShow.call(this, e);
            }
            if (!e.isDefaultPrevented) {
                show.call(this, target);
            }
        };
    }(kendo.ui.Tooltip.fn._show);

    $rootScope.currentHoveredCellOptions = {
        beforeShow: function (e) {
            var innerText = e.target[0].innerText;
            var innerHTML = e.target[0].innerHTML;
            if (innerText === "" || innerText === null || innerHTML.has('<button') || innerHTML.has('<a')) {
                e.preventDefault();
            }
        },
        width: 220,
        filter: '.k-grid td',
        showAfter: 600,
        animation: {
            open: {
                effects: "fade:in",
                duration: 100
            }
        }
    };

    $scope.LoadUserPreferences = function () {
        return $http.get(apiUrl + 'api/core/userpreferences')
            .then(function (response) {
                $rootScope.UserPreferences = {};
                $rootScope.UserPreferenceList = response.data;
                response.data.forEach(function (row) {

                    $rootScope.UserPreferences[row.Type] = row.Value;

                    if (row.Value === "ShowReportTabs") {

                        if ($rootScope.tabs) {
                            if ($rootScope.tabs.length === 0 && $rootScope.IsReport === true) {
                                $window.location.reload();
                            }
                        }
                    }
                });
            });
    };

    $scope.LoadTabViewerData = function (current) {
        //Check if they have tabs turned on in user preferences and the current route is a Place or Report that should load in the tab viewer
        var showInTabs = true;
        if ($rootScope.UserPreferences != null && $rootScope.UserPreferences.ShowReportTabs == false) {
            showInTabs = false;
        }

        if (showInTabs && current.data != null && (current.data.IsPlaceReport == true || current.data.IsReport == true)) {
            $rootScope.IsReport = true;
        } else {
            $rootScope.IsReport = false;
        }

        //If the route is loading from outside the application or from helperService view/report change events
        //and it's a report that should load in the tab viewer add the report to the tabs array
        if (current.data != null && ($rootScope.tabChangedEventFired == null || $rootScope.tabChangedEventFired == false)) {

            if ($rootScope.IsReport) {
                let viewName;
                let content;
                let link;
                let isComponent;

                if (current.data.IsPlaceReport != null && current.data.IsPlaceReport == true) {
                    viewName = current.data.PlaceName;
                    content = current.data.ViewLocation;
                    link = current.originalPath;
                    isComponent = current.data.isComponent;
                } else {
                    // We need to get the unmodified report name
                    const splitItUp = $location.path().split("/");
                    const objectId = splitItUp[4];
                    const securityObject = helperService.getSecurityObjectDetailsFromObjectId(objectId);

                    viewName = securityObject.Name;
                    content = 'App/Components/DynamicReports/ReportViewer.html';
                    link = current.originalPath.replace(':objectID', current.params.objectID).replace(':reportName', current.params.reportName);
                }

                if ($rootScope.tabs == null) {
                    $rootScope.tabs = [];
                }

                let count = 0;
                let disablepin = false;

                angular.forEach($rootScope.tabs, function (tab) {
                    count += tab.pinned ? 1 : 0;
                });

                if (count >= Number(tabLimit) - 1) {
                    disablepin = true;
                }
                //If there are already four reports when the fifth one is added make the pin disabled by default
                $rootScope.tabs.unshift({ title: viewName, content: content, id: helperService.getTabIndex() + 1, link: link, pinned: false, disablepin: disablepin, isComponent: isComponent });
            }
        }

        $rootScope.tabChangedEventFired = false;
    };


    //Capture the route change and redirect to the login page if not authenticated


    //Load the security objects the user has access to
    async function loadUserSecurityObjects() {
        //Load the navigation objects into local storage for use in routestartchange
        const { data } = await $http.get(apiUrl + 'api/core/usersecurity');
        
        $rootScope.UserSecurityList = [];
        data.forEach(row => {
            $rootScope.UserSecurityList[row.ObjectID] = true;
        });

        localStorage.setItem("UserSecurityObjects", angular.toJson(data));

        return data
    };

    //Load Menu only if authenticated
    async function getNavigation() {
        let authenticated = await authenticationService.isAuthenticated();
        if (authenticated) {
            const { data } = dataService.getNavigation();

            $rootScope.UserNavigationList = [];
            data.forEach(row => {
                $rootScope.UserNavigationList[row.ObjectID] = true;
            });

            $scope.navLinks = data;
            localStorage.setItem("NavigationObjects", angular.toJson(data));
        }
    }

    //Navigate to the route if the user has access or send them to the error page
    $scope.navigateToRoute = function (objectID) {
        var targetPath = $location.path();
        var targetSearch = $location.search();
        var targetHash = $location.hash();

        trackModuleForChurnZero(targetPath);

        if (objectID != null) {
            $location.path(targetPath).search(targetSearch).hash(targetHash);
        } else {
            //If there is no objectID route to the error page unless the route is the home page
            //Excepts for routes with no object IDs - see whiteListService.js

            var whiteList = whiteListService.getWhiteListedRoutes();

            var containedInWhiteList = whiteList.find(function (item) {
                return item == targetPath.toUpperCase();
            }) != null;

            if (!containedInWhiteList) {
                $location.path('/403');
            } else {
                $location.path(targetPath).search(targetSearch).hash(targetHash);
            }
        }
    };

    //Get the parent ID from the name in the route
    $scope.getParentIDFromName = function (ParentName) {
        var navigationObjectList;

        navigationObjectList = angular.fromJson(localStorage.getItem("NavigationObjects"));
        var foundObject = navigationObjectList.find(function (object) {
            return object.Name.replace(/ /g, '').toUpperCase() == ParentName.toUpperCase();
        });

        if (foundObject != null) {
            return foundObject.ObjectID;
        }


    };

    //Watch for role change and update navigation and user security object lists
    $scope.$on('navigation-or-security-change', async function (event, args) {
        await loadUserSecurityObjects();
        await getNavigation();
    });

    $scope.$on('$routeChangeStart', async function (next, current) {
        //If it's a Place check if the PlaceIsReport route property is set to true
        //If it's a Report set IsReport to true
        //IsReport controls the visibility of the tab viewer on the Index page

        var dataPromise;

        //Previous URL used for loading places and reports in the tab viewer. determines if it's a new route or route.reload is called
        $rootScope.previousURL = $location.url();
        $rootScope.currentRoute = current;

        let authenticated = await authenticationService.isAuthenticated();
        if (authenticated) {
            if ($rootScope.UserPreferences == null) {

                dataPromise = $scope.LoadUserPreferences(current);
                dataPromise.then(function (result) {
                    $scope.LoadTabViewerData(current);
                });
            }
            else {
                $scope.LoadTabViewerData(current);
            }
        } else {
            localStorage.setItem("locationBeforeRedirect", $location.url());
            if (localStorage.getItem("selectedTenant")) {
                localStorage.setItem("tenantBeforeRedirect", localStorage.getItem("selectedTenant"));
            }
            document.location.href = appUrl + "login";
        }

        if ($location.path() != "/") {
            helperService.setFavoriteValue().then(function (result) {
                if (result === 1) {
                    $scope.favorited = { value: true };
                } else {
                    $scope.favorited = { value: false };
                }
            });
        }

    });

    $scope.$on("$locationChangeStart", function (event, next, current) {
        objectIdDataService.getObjectID().then(function (objectId) {

            var securityObject = helperService.getSecurityObjectDetailsFromObjectId(objectId);
            if (securityObject) {
                $window.document.title = securityObject.Name;
            } else {
                $window.document.title = "Fastpath Assure";
            }
            $scope.navigateToRoute(objectId);
        });
    });
}

function trackModuleForChurnZero(targetPath) {
    const regEx = /^([^\/]*\/){1}[^\/]*/,
        match = targetPath.match(regEx);

    if (match.length && match.first() !== "/") {
        ChurnZero.push(['setModule', match.first().substring(1)]);
    } else {
        ChurnZero.push(['setModule', 'Dashboard']);
    }
}

