import simulationsController from "./simulations.controller";

/* simulations.component.js */

/**
 * @desc component for viewing and maintaining sap security designer simulations
 * @example <sap-sd-simulations></sap-sd-simulations>
 */

const sapSdSimulations = {
    controller: simulationsController,
    controllerAs: 'ssdsvm',
    templateUrl: '/App/Components/SAPSecurityDesigner/simulations.tmpl.html'
};

export default sapSdSimulations;