import swal from "sweetalert2";
import createSpinner from "../../../Shared/Functions/createSpinner";
import importRoleTemplatesController from "../importRoleTemplates.controller";
import roleTemplateModalController from "../RoleTemplateModal/roleTemplateModal.controller";

export default /*@ngInject*/ function ($filter, $uibModal, $scope, roleTemplatesData, helperService, FileSaver) {

    const vm = this;

    vm.$onInit = () => getRoleTemplates();
    $scope.$on('ImportRoleTemplates', _ => getRoleTemplates());

    vm.openRoleTemplateModal = openRoleTemplateModal;
    vm.changeSelectedRoleTemplate = changeSelectedRoleTemplate;
    vm.openImportModal = openImportModal;
    vm.exportRoleTemplates = exportRoleTemplates;

    vm.menuOptions = [
        {
            text: 'Edit',
            click: item => {
                openRoleTemplateModal(vm.roleTemplates, item.template);
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: item => {
                deleteRoleTemplate(item.template);
            }
        }
    ];

    async function getRoleTemplates(preselectedId) {
        // get role templates on initialization, set vm.roleTemplates to response
        // move newly created template to the top of the list and select
        vm.roleTemplatesLoading = true;
        let response = await roleTemplatesData.getRoleTemplates();
        if (response.data.length > 0) {
            response.data = $filter('orderBy')(response.data, 'Name');
            if (preselectedId) {
                let roleTemplate = response.data.find(t => t.Id === preselectedId);
                response.data = response.data.filter(t => t.Id !== preselectedId);
                response.data.unshift(roleTemplate);
                changeSelectedRoleTemplate(roleTemplate);
            }
        }
        vm.roleTemplates = response.data;
        vm.roleTemplatesLoading = false;
    }

    function changeSelectedRoleTemplate(template) {
        vm.template = template;
    }

    async function deleteRoleTemplate(template) {
        vm.deletePending = true;
        try {
            await swal(helperService.areYouSureParamsWithHTML('Delete Role Template',
                `<div>Are you sure you want to delete
                <div><strong>${template.Name}</strong>?</div>
            </div>`, 'Yes', 'No'));
            await roleTemplatesData.deleteRoleTemplate(template.Id);
            if (vm.template && vm.template.Id === template.Id) {
                vm.template = null;
            }
            getRoleTemplates();
            vm.deletePending = false;
        } catch {
            vm.deletePending = false;
        }
    }

    async function openRoleTemplateModal(roleTemplates, selectedTemplate) {
        let roleTemplateModal = $uibModal.open({
            templateUrl: '/App/Components/RoleTemplates/RoleTemplateModal/roleTemplateModal.html',
            controller: roleTemplateModalController,
            controllerAs: 'rtmvm',
            backdrop: 'static',
            resolve: {
                roleTemplates: () => roleTemplates,
                selectedTemplate: () => selectedTemplate
            }
        });

        try {
            let newTemplateId = await roleTemplateModal.result;
            getRoleTemplates(newTemplateId);
        } catch {
            return;
        }
    }

    async function exportRoleTemplates() {
        vm.exportUpdatePending = new createSpinner();
        try {
            let response = await roleTemplatesData.exportRoleTemplates();
            helperService.successfulSaveButton(vm.exportUpdatePending);
            FileSaver.saveAs(response.data, 'RoleTemplates.xlsx');
            vm.exportUpdatePending.loadingValue = false;
        } catch {
            vm.exportUpdatePending.loadingValue = false;
        }
    }

    async function openImportModal() {
        let importModal = $uibModal.open({
            templateUrl: '/App/Components/RoleTemplates/importRoleTemplates.html',
            controller: importRoleTemplatesController,
            controllerAs: 'irtvm',
            backdrop: 'static',
        });
        try {
            await importModal.result;
        } catch {
            return;
        }
    }

}
