export default /*@ngInject*/ function ($scope) {

    //If $scope variable doesnt exist in child it looks in parent

    $scope.changeClass = function(userGroup){
        $scope.vm.ListOfAvailableNAVUserGroups.forEach(function (group) {
            group.isSelected = false;
        });
        userGroup.isSelected = true;
    };

    var RoleCompany = function (params) {
        this.RoleId = params.RoleId;
        this.CompanyId = params.CompanyId;
        this.RoleName = params.RoleName;
        this.CompanyName = params.CompanyName;
        this.AllCompanies = params.AllCompanies;
    };

    $scope.AllCompanies = false;

    $scope.handleNavGroupCompany = function (group) {
        group.Companies = group.CompanyNames.map(name => {
            return { Name: name };
        });
    };

    $scope.addRoleToList = function (role) {
        $scope.vm.ListOfAvailableNAVUserGroups.forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfAvailableNAVUserGroups.splice(i, 1);
                $scope.vm.ListOfSelectedNAVUserGroups.push(role);
            }
        });
        $scope.vm.IsRisksReviewed = null;
    };

    $scope.deleteSelectedRole = function (role) {
        $scope.vm.ListOfSelectedNAVUserGroups.forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfSelectedNAVUserGroups.splice(i, 1);
                $scope.vm.ListOfAvailableNAVUserGroups.push(row);
            }
        });

        $scope.vm.IsRisksReviewed = null;
    };

    $scope.addRoleCompany = function (company, selected) {
        $scope.vm.ListOfSelectedNAVUserGroups.forEach(function (row, i) {
            if (row.Id === $scope.selectedRole.Id) {

                if (row.CompanyId == null || selected == false) {
                    $scope.vm.NAVRoleCompanyList.splice(i, 1);
                }
                if (selected == true) {
                    $scope.vm.NAVRoleCompanyList.push(
                        new RoleCompany({
                            RoleId: row.RoleId,
                            CompanyId: company.CompanyId,
                            RoleName: row.RoleName,
                            CompanyName: company.CompanyName,
                            AllCompanies: false
                        }));
                }

            }
        });
    };

    $scope.selectedRole = null;

}