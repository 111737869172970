import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, userGroupsDataService, userGroup, helperService) {

    var vm = this;
    vm.cancel = cancel;
    vm.submitUserGroupForm = submitUserGroupForm;
    vm.hasExistingValueChanged = hasExistingValueChanged;

    vm.userGroup = angular.copy(userGroup);

    function activate() {
        if (userGroup.Id) {
            vm.origValue = { ...vm.userGroup };
        }
    }
    activate();

    function cancel() { $uibModalInstance.dismiss(); }

    function submitUserGroupForm(userGroup) {
        if (userGroup.Id) {
            editUserGroup();
        } else {
            addNewUserGroup();
        }
    }

    async function addNewUserGroup() {
        vm.updatePending = new createSpinner();

        const newUserGroup = {
            'Name': vm.userGroup.Name
        };

        try {
            let response = await userGroupsDataService.createUserGroup(newUserGroup);
            await helperService.successfulSaveButton(vm.updatePending);
            close(response);
        } catch {
            return;
            vm.updatePending.loadingValue = false;
        }
    }

    async function editUserGroup() {
        vm.updatePending = new createSpinner();

        const editedUserGroup = {
            'Id': vm.userGroup.Id,
            'Name': vm.userGroup.Name
        };

        try {
            await userGroupsDataService.editUserGroup(userGroup.Id, editedUserGroup);
            await helperService.successfulSaveButton(vm.updatePending);
            close(vm.userGroup.Id);
        } catch {
            return;
            vm.updatePending.loadingValue = false;
        }
    }

    function close(userGroupId) {
        $uibModalInstance.close(userGroupId);
    }

    function hasExistingValueChanged() {
        if (vm.origValue && angular.equals(vm.origValue, vm.userGroup)) {
            return false;
        } else {
            // Either creating a new value or user has modified one of the fields
            return true;
        }
    }
}