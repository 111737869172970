/* routingCriteriaInstancesList.component.js */

import routingCriteriaInstancesListController from "./routingCriteriaInstancesList.controller";

/**
 * @desc component for viewing and managing access certifications review schedule and assignment items
 * @example <review-schedule-instances-list></review-schedule-instances-list>
 */

const routingCriteriaInstancesList = {
    bindings: {
        ownershipGroup: '<'
    },
    controller: routingCriteriaInstancesListController,
    controllerAs: 'rcilvm',
    templateUrl: '/App/Components/Certifications/reviewSchedules/routingCriteriaInstancesList/routingCriteriaInstancesList.tmpl.html'
};

export default routingCriteriaInstancesList;