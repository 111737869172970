import controller from "./savedReports.controller";

/* savedReports.component.js */

/**
 * @desc component for saved reports
 * @example <saved-reports></saved-reports>
 */

const savedReports = {
    controller: controller,
    controllerAs: 'srvm',
    templateUrl: '/App/Components/SavedReports/savedReports.tmpl.html'
};

export default savedReports;