import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (availableProducts, $uibModalInstance, helperService, companyMappingDataService, $filter) {
    const vm = this;

    vm.cancel = cancel;
    vm.acceptAutoMap = acceptAutoMap;
    vm.removeProductFromAutoMap = removeProductFromAutoMap;

    activate();

    function activate() {
        createGridOptions();
        createDataSource();
    }

    function createGridOptions() {
        let columnsFromVM = [
            { field: "Name", title: "Company", filterable: customAutoCompleteFilter }
        ];

        let dynamicColumns = [];
        availableProducts.forEach(product => {
            let identifier = product.Identifier;
            let productId = product.ID;
            let productName = product.Name;

            let customTemplate =
                "<span><i ng-if='dataItem." + identifier + "' class='fa fa-circle new-mapping-dot'></i>{{dataItem." + identifier + "}}</span>" +
                "<a ng-if='dataItem." + identifier + "' class='icons8-delete-filled user-mapping-link-button text-danger pull-right' ng-click='amcmvm.removeProductFromAutoMap(dataItem, \"${'" + identifier + "'}\", \"${'" + productId + "'}\", \"${'" + productName + "'}\")'></a>";

            let templateRow = { field: identifier, title: productName, template: customTemplate, filterable: customAutoCompleteFilter };
            dynamicColumns.push(templateRow);
        });

        dynamicColumns = dynamicColumns.unique();

        dynamicColumns = $filter('orderBy')(dynamicColumns, 'field');
        columnsFromVM = columnsFromVM.concat(dynamicColumns);

        vm.autoMapResultsGridOptions = helperService.setMainGridOptions(columnsFromVM, 400);
    }


    function addProductSpecificVariables(data) {
        data.forEach(row => {
            availableProducts.forEach(product => {
                row[product.Identifier] = null;
            });

            row.CompanyMappings.forEach(function (mapping) {
                var identifier = 'id' + mapping.ProductName.replace(/ /g, '');
                row[identifier] = mapping.CompanyName;
            });
        });
        return data;
    }

    function createDataSource() {
        vm.autoMapResultsDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let response = await companyMappingDataService.getCompanyAutoMap();
                    response.data = addProductSpecificVariables(response.data);
                    response.data = $filter('orderBy')(response.data, 'Name');
                    options.success(response.data);
                }
            }
        });
    }

    function removeProductFromAutoMap(dataItem, identifier, launchedFromProductId, launchedFromProductName) {
        dataItem[identifier] = null;

        for (var i = 0; i < dataItem.CompanyMappings.length; i++) {
            if (dataItem.CompanyMappings[i].ProductID === launchedFromProductId) {
                dataItem.CompanyMappings.splice(i, 1);
                break;
            }
        }
        if (dataItem.CompanyMappings.length === 0) {
            vm.autoMapResultsDataSource.remove(dataItem);
        }
    };

    async function acceptAutoMap() {
        vm.pleaseWait = new createSpinner();

        let data = vm.autoMapResultsDataSource.data();
        let CompanyMasterRequests = [];

        data.forEach(row => {
            let record = {
                "ID": row.ID,
                "Name": row.Name,
                "CompanyMappings": row.CompanyMappings
            };
            CompanyMasterRequests.push(record);
        });

        let postBody = {
            "CompanyMasterRequests": CompanyMasterRequests
        };

        try {
            await companyMappingDataService.createAutoMap(postBody);
            await helperService.successfulSaveButton(vm.pleaseWait);
            $uibModalInstance.close();
        } catch {
            vm.pleaseWait.loadingValue = false;
        }
    };

    function cancel() {
        $uibModalInstance.dismiss();
    };

}