import oracleCloudDataChangesController from "./oracleCloudDataChanges.controller";

/* oracleCloudDataChanges.component.js */

/**
 * @desc component for the Oracle Cloud Data Changes page
 * @example <oracle-cloud-data-changes></oracle-cloud-data-changes>
 */

const oracleCloudDataChanges = {
    controller: oracleCloudDataChangesController,
    controllerAs: 'ocdcvm',
    templateUrl: '/App/Components/DataChangesOracleCloud/oracleCloudDataChanges.html'
};

export default oracleCloudDataChanges;