import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($route, $filter, $uibModalInstance, helperService, errorsDataService, taskRecordingDataService, AXSecurityDesignerService, dataObject, missingItemsArray) {

    const vm = this;

    vm.missingItemsArray = missingItemsArray;
    vm.showExistingSecurityModelView = showExistingSecurityModelView;
    vm.showNewSecurityModelView = showNewSecurityModelView;
    vm.addMissingMenuItemsToExistingSecurityModel = addMissingMenuItemsToExistingSecurityModel;
    vm.addMissingMenuItemsToNewSecurityModel = addMissingMenuItemsToNewSecurityModel;
    vm.cancel = cancel;

    function activate() {
        getSimulations();
    }

    activate();

    function getSimulations() {
        vm.gettingSimulations = true;
        AXSecurityDesignerService.getSimulations().then(response => {
            vm.gettingSimulations = false;
            response.data = $filter('orderBy')(response.data, 'Name');
            vm.availableSimulations = response.data;
        }).catch(response => {
            errorsDataService.catch(response);
        });
    }

    function showExistingSecurityModelView() {

        vm.editSecurityModel = true;
        vm.createSecurityModel = false;

        vm.newSecurityModelIconFaded = true;
        vm.existingSecurityModelIconFaded = false;
    }

    function showNewSecurityModelView() {

        vm.editSecurityModel = false;
        vm.createSecurityModel = true;

        vm.newSecurityModelIconFaded = false;
        vm.existingSecurityModelIconFaded = true;
    }

    function addMissingMenuItemsToExistingSecurityModel() {

        vm.editingSecurityModel = new createSpinner();

        let data = {
            SimulationId: vm.selectedSimulation.Id,
            TaskRecordingId: dataObject.TaskRecordingId,
            SecurityLayerName: dataObject.SecurityLayerName,
            LayerType: dataObject.LayerType,
            ObjectsToAdd: dataObject.ObjectsToAdd
        };

        taskRecordingDataService.addMissingMenuItems(vm.selectedSimulation.ProductId, data.SimulationId, data).then(async response => {
            vm.killModal = true;
            vm.editingSecurityModel.loadingValue = false;
            await helperService.successfulSaveButton(vm.editingSecurityModel).then(result => {
                $uibModalInstance.close();
                $route.reload();
            });
        }).catch(response => {
            vm.disableSubmit = false;
            vm.editingSecurityModel.loadingValue = false;
        });
    }

    function addMissingMenuItemsToNewSecurityModel() {

        vm.addingSecurityModel = new createSpinner();

        let newSecurityModelFormData = {
            "Name": vm.newSecurityModelName,
            "ProductId": "AX7"
        };

        taskRecordingDataService.createNewSecurityModel(newSecurityModelFormData).then(response => {

            vm.newSecurityModelId = response.data;

            vm.completeObject = {
                SimulationId: vm.newSecurityModelId,
                TaskRecordingId: dataObject.TaskRecordingId,
                SecurityLayerName: dataObject.SecurityLayerName,
                LayerType: dataObject.LayerType,
                ObjectsToAdd: dataObject.ObjectsToAdd
            };

        }).then(_ => {
            taskRecordingDataService.addMissingMenuItems(newSecurityModelFormData.ProductId, vm.newSecurityModelId, vm.completeObject).then(async response => {
                vm.killModal = true;
                vm.addingSecurityModel.loadingValue = false;
                await helperService.successfulSaveButton(vm.addingSecurityModel).then(result => {
                    $uibModalInstance.close();
                    $route.reload();
                });
            });
        }).catch(response => {
            errorsDataService.catch(response);
            vm.disableSubmit = false;
            vm.addingSecurityModel.loadingValue = false;
        });
    }

    function cancel() { $uibModalInstance.dismiss(); }

}