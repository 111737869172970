/* linkTicketsDropdown.component.js */

import linkTicketsDropdownController from "./linkTicketsDropdown.controller";

/**
 * @desc component for the Link Tickets dropdown
 * @example <link-tickets-dropdown></link-tickets-dropdown>
 */

const linkTicketsDropdown = {
    bindings: {
        tickets: '<',
        onUpdateTicket: '&',
        onRemoveTicket: '&'
    },
    controller: linkTicketsDropdownController,
    controllerAs: 'ltdvm',
    templateUrl: 'App/Components/Certifications/LinkTickets/linkTicketsDropdown.tmpl.html'
};

export default linkTicketsDropdown;