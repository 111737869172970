/* companyMapping.component.js */

import companyMappingController from "./companyMapping.controller";

/**
 * @desc component to view and manage company mappings
 * @example <company-mapping></company-mapping>
 */

const companyMapping = {
    controller: companyMappingController,
    controllerAs: 'cmvm',
    templateUrl: '/App/Components/CompanyMapping/companyMapping.html'
};

export default companyMapping;