import editRoleCompaniesController from "./editRoleCompanies.controller";


/* editRoleCompanies.component.js */

/**
 * @desc component for selecting companies to associate to a role for an IM request
 * @example <im-role-companies></im-role-companies>
 */

const imRoleCompanies = {
    bindings: {
        selectedRole: '<',
        onUpdateCompanies: '&',
        productId: '@',
        availableCompanies: '<',
        onApplyCompaniesToAllRoles: '&',
        showApplyAll: '<'
    },
    controller: editRoleCompaniesController,
    controllerAs: 'ercvm',
    templateUrl: '/App/Shared/Components/identityManagerRoleCompanies/editRoleCompanies.tmpl.html'
};

export default imRoleCompanies;