import createSpinner from "../../Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, objects, ruleset, riskRulesetService, helperService) {
    const vm = this;

    vm.close = close;
    vm.objects = objects;
    vm.ruleset = ruleset;
    vm.deleteObject = deleteObject;
    vm.copyObjects = copyObjects;

    function close() {
        $uibModalInstance.close();
    }


    activate();

    function activate() {
        getRiskRulesetsList();
        vm.objectType = vm.objects[0].RiskType ? 'risks' : 'bps';
    }

    // ----- Risk Ruleset -----
    async function getRiskRulesetsList() {
        vm.loadingRiskRulesetList = true;
        try {
            let { data: riskRulesets } = await riskRulesetService.getRiskRulesetList();
            const defaultRulesetId = 1;
            vm.riskRulesetDataSource = riskRulesets.filter(ruleset => ruleset.RulesetId !== vm.ruleset.RulesetId && ruleset.RulesetId !== defaultRulesetId);
            vm.loadingRiskRulesetList = false;
        } catch (error) {
            vm.loadingRiskRulesetList = false;
        }
    }

    function deleteObject(deletedObject) {
        vm.objects = vm.objects.filter(object => object.Id !== deletedObject.Id);
    }

    async function copyObjects() {
        vm.copyPending = new createSpinner();
        let data = {
                DestinationRulesetId: vm.destinationRuleset.RulesetId,
                SourceRulesetId: ruleset.RulesetId
            };
        if (vm.objectType === 'risks') {
            data.Risks = vm.objects.map(object => object.Id);
            data.IncludeBPs = vm.copyRiskBPs;
        }
        else {
            data.BusinessProcesses = vm.objects.map(object => {
                return object.BusinessProcessId ? object.BusinessProcessId : object.Id
            });
        }
        try {
            await riskRulesetService.copyObjectsToRuleset(vm.objectType, data);
            await helperService.successfulSaveButton(vm.copyPending);
            close();
        }
        catch {
            vm.copyPending.loadingValue = false;
        }
    }
}