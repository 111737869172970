import angular from 'angular';
import aDUserObjectValueMapperService from '../Components/RequestManagement/Services/aDUserObjectValueMapper.service';
import azureADUserObjectValueMapperService from '../Components/RequestManagement/Services/azureADUserObjectValueMapper.service';
import existingUserCheckService from '../Components/RequestManagement/Services/existingUserCheck.service';
import existingUserSearchService from '../Components/RequestManagement/Services/existingUserSearch.service';
export default angular
    .module('app.identityManager.requestManagement.factories', [])
    .factory('aDUserObjectValueMapperService', aDUserObjectValueMapperService)
    .factory('azureADUserObjectValueMapperService', azureADUserObjectValueMapperService)
    .factory('existingUserCheckService', existingUserCheckService)
    .factory('existingUserSearchService', existingUserSearchService)
    .name;