export default /*@ngInject*/ function ($http, $q, errorsDataService) {

    var Entity = function (params) {
        this.Id = params.Id;
        this.Name = params.Name;
        this.TrackedTableList = params.TrackedTableList;
        this.IsCustom = params.IsCustom;
        this.CreatedDate = params.CreatedDate;
        this.CreatedBy = params.CreatedBy;
        this.IsEnabled = params.IsEnabled;
        this.BaseTemplate = params.BaseTemplate;
    };

    var TrackedTable = function (params) {
        this.Id = params.Id;
        this.Name = params.Name;
        this.Label = params.Label;
        this.TrackedFields = params.TrackedFields;
        this.TrackAll = params.TrackAll;
        this.TrackInsert = params.TrackInsert,
            this.TrackUpdate = params.TrackUpdate;
        this.TrackDelete = params.TrackDelete;
        this.Type = 'Table';
    };

    var TrackedField = function (params) {
        this.Id = params.Id;
        this.Name = params.Name;
        this.Label = params.Label,
            this.TrackAll = params.TrackAll;
        this.TrackInsert = params.TrackInsert,
            this.TrackUpdate = params.TrackUpdate;
        this.TrackDelete = params.TrackDelete;
        this.Type = 'Field';
    };

    this.convertToTrackedTable = function (table) {
        var convertedFields = [];
        table.TrackedFields.forEach(function (field) {
            convertedFields.push(new TrackedField({ Id: field.Id, Name: field.Name, Label: field.Label, TrackAll: false, TrackDelete: false, TrackInsert: false, TrackUpdate: false }));
        });

        return new TrackedTable({
            Id: table.Id,
            Name: table.Name,
            Label: table.Label,
            TrackedFields: convertedFields,
            TrackAll: false,
            TrackInsert: false,
            TrackUpdate: false,
            TrackDelete: false,
            Type: 'Table'
        });
    };

    this.convertFromTrackedTable = function (templateId, tableList) {

        var convertedTables = [];
        var convertedFields = [];

        tableList.forEach(function (trackedTable) {
            convertedFields = new Array();
            trackedTable.TrackedFields.forEach(function (field) {
                convertedFields.push({
                    "FieldId": field.Id,
                    "TrackInsert": field.TrackInsert,
                    "TrackUpdate": field.TrackUpdate,
                    "TrackDelete": field.TrackDelete
                });
            });

            convertedTables.push({
                "TableId": trackedTable.Id,
                "Fields": convertedFields
            });
        });

        return convertedTables;
    };

    function checkAll(field) {
        return field.TrackInsert === true && field.TrackUpdate === true && field.TrackDelete === true;
    }

    function checkInsert(field) {
        return field.TrackInsert === true;
    }

    function checkUpdate(field) {
        return field.TrackUpdate === true;
    }

    function checkDelete(field) {
        return field.TrackDelete === true;
    }

    //Convert API tracked templates into UI Entities for use on UI side
    this.mapTemplate = function (template, includeUntrackedFields) {

        var templateDfrd = $.Deferred();

        var entityList = [];
        var trackedFieldList = [];
        var trackedTableList = [];
        var returnedTemplate = null;
        var promises = [];
        var selectedTemplate = null;

        getTrackingTemplate(template.Id).then(function (response) {
            returnedTemplate = response.data;
            trackedFieldList = new Array();
            trackedTableList = new Array();

            returnedTemplate.TrackedTables.forEach(function (templateTable) {

                promises.push(getAXFieldsByTableId(templateTable.TableId).then(function (fieldList) {
                    trackedFieldList = new Array();

                    fieldList.data.forEach(function (field) {
                        var foundField = templateTable.TrackedFields.find(function (trackedField) {
                            return trackedField.FieldId === field.Id;
                        });

                        if (foundField) {
                            trackedFieldList.push(new
                                TrackedField({
                                    Id: foundField.FieldId,
                                    Name: foundField.FieldName,
                                    Label: foundField.FieldLabel,
                                    TrackAll: foundField.TrackInsert === true && foundField.TrackUpdate === true && foundField.TrackDelete === true ? true : false,
                                    TrackInsert: foundField.TrackInsert,
                                    TrackUpdate: foundField.TrackUpdate,
                                    TrackDelete: foundField.TrackDelete
                                }));
                        }
                        else {
                            if (includeUntrackedFields) {
                                trackedFieldList.push(new
                                    TrackedField({
                                        Id: field.Id,
                                        Name: field.Name,
                                        Label: field.Label,
                                        TrackAll: false,
                                        TrackInsert: false,
                                        TrackUpdate: false,
                                        TrackDelete: false
                                    }));
                            }
                        }
                    });

                    trackedTableList.push(new TrackedTable({
                        Id: templateTable.TableId,
                        Name: templateTable.TableName,
                        Label: templateTable.TableLabel,
                        TrackedFields: trackedFieldList,
                        TrackAll: trackedFieldList.every(checkAll),
                        TrackInsert: trackedFieldList.every(checkInsert),
                        TrackUpdate: trackedFieldList.every(checkUpdate),
                        TrackDelete: trackedFieldList.every(checkDelete)
                    }));
                }));

            });

            $q.all(promises).then(function () {
                selectedTemplate = new Entity({ Id: template.Id, Name: template.Name, IsEnabled: template.IsEnabled, IsCustom: template.IsCustom, BaseTemplate: template.BaseTemplate, CreatedBy: template.CreatedBy, CreatedDate: template.CreatedDate, TrackedTableList: trackedTableList });

                templateDfrd.resolve(selectedTemplate);
            });
        });

        return $.when(templateDfrd).done(function () {
            return;
        }).promise();
    };

    this.mapTemplateHeaders = function (trackedTemplateList) {

        var templateDfrd = $.Deferred();

        var entityList = [];

        //Loop thru API Tracked Template list
        trackedTemplateList.forEach(function (template) {

            entityList.push(new Entity({ Id: template.Id, Name: template.Name, IsEnabled: template.IsEnabled, IsCustom: template.IsCustom, BaseTemplate: template.BaseTemplate, CreatedBy: template.CreatedBy, CreatedDate: template.CreatedDate }));
        });

        templateDfrd.resolve(entityList);

        return $.when(templateDfrd).done(function () {
            return;
        }).promise();
    };

    this.mapTemplateHeader = function (template) {

        var templateDfrd = $.Deferred();
        var convertedTemplate = null;

        convertedTemplate = new Entity({ Id: template.Id, Name: template.Name, IsEnabled: template.IsEnabled, IsCustom: template.IsCustom, BaseTemplate: template.BaseTemplate, CreatedBy: template.CreatedBy, CreatedDate: template.CreatedDate });

        templateDfrd.resolve(convertedTemplate);

        return $.when(templateDfrd).done(function () {
            return;
        }).promise();
    };

    this.getAllTemplates = function () {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/audittrail/template'
        });
    };

    function getTemplate(id) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/audittrail/template/' + id
        });
    }

    this.getTemplate = function (id) {
        return getTemplate(id);
    };

    this.getTrackingTemplate = function (id) {
        return getTrackingTemplate(id);
    };

    function getTrackingTemplate(id) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/audittrail/templatetracking/' + id
        });
    }

    this.createTemplate = function (name, productId) {
        return $http({
            method: 'POST',
            url: apiUrl + 'api/audittrail/template',
            data: {
                Name: name,
                ProductId: productId
            }
        });
    };

    this.updateTemplateTables = function (templateId, tables) {
        return $http({
            method: 'PUT',
            url: apiUrl + 'api/audittrail/templatetracking/' + templateId,
            data:
            {
                "TemplateId": templateId,
                "tables": tables
            }
        });
    };

    this.deleteTemplate = function (templateId) {

        return $http({
            method: 'DELETE',
            url: apiUrl + 'api/audittrail/template/' + templateId
        });
    };

    this.revertTemplate = function (templateId) {
        return $http({
            method: 'PUT',
            url: apiUrl + 'api/audittrail/template/' + templateId + '/revert',
            data: {
                TemplateId: templateId
            }
        });
    };

    this.applyTemplates = function (selectedTemplates) {
        $http({
            method: 'POST',
            url: apiUrl + 'api/audittrail/ax7/template/apply',
            data: selectedTemplates
        }).then(response => {
            return response;
        }).catch(response => {
            errorsDataService.catch(response);
            throw response.data;
        });
    };

    this.exportTemplates = function () {
        return $http.get(`${apiUrl}api/audittrail/template/ax7/export`, { responseType: 'blob' });
    };

    this.importTemplates = function (data) {
        return $http.put(`${apiUrl}api/audittrail/ax7/template/import`, data, {
            transformRequest: angular.identity,
            headers: { 'Content-Type': undefined }
        });
    };


    //AX AT Calls
    this.getAllAXTables = function () {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/audittrail/ax7/metadata/tables'
        });
    };

    function getAXFieldsByTableId(tableId) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/audittrail/ax7/metadata/table/' + tableId + '/fields'
        });
    }

    this.getAXFieldsByTableId = function (tableId) {
        return getAXFieldsByTableId(tableId);
    };

}
