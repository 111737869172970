export default /*@ngInject*/ function ($scope, configurationDataService) {

    getRoleDescConfigValue();

    async function getRoleDescConfigValue() {
        let response = await configurationDataService.getProductConfigurationValueByName('orfc', 'IM_ShowRoleDescription');
        $scope.roleDesc = response.data.Value;
    }

    //If $scope variable doesnt exist in child it looks in parent

    $scope.addRoleToList = function (role) {
        $scope.vm.ListOfAvailableOracleCloudRoles.forEach(function (row, i) {
            if (row.RoleID === role.RoleID) {
                $scope.vm.ListOfAvailableOracleCloudRoles.splice(i, 1);
                $scope.vm.ListOfStoredOracleCloudRoles.push({ Role: row });
                $scope.vm.ListOfSelectedOracleCloudRoles.push(role);
            }
        });
        $scope.vm.IsRisksReviewed = null;
    };

    $scope.deleteSelectedRole = function (role) {

        $scope.vm.ListOfSelectedOracleCloudRoles.forEach(function (row, i) {
            if (row.RoleID === role.RoleID) {
                $scope.vm.ListOfSelectedOracleCloudRoles.splice(i, 1);
                if (role.IsAssignable) {
                    $scope.vm.ListOfAvailableOracleCloudRoles.push(role);
                }
            }
        });
        $scope.vm.ListOfStoredOracleCloudRoles.forEach(function (row, j) {
            if (row.RoleID === role.RoleID) {
                $scope.vm.ListOfStoredOracleCloudRoles.splice(j, 1);
            }
        });

        $scope.vm.IsRisksReviewed = null;
    };

    $scope.setSelectedRoleTemplates = function (selectedRoleTemplates) {
        $scope.vm.selectedRoleTemplates = selectedRoleTemplates;
    };

}