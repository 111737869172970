import createSpinner from "../../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $filter, simulation, actionType, compositeRole, helperService, sapSdSimCompositeRoleDataService) {
    const vm = this;

    vm.actionType = actionType;

    vm.changeRowSelected = changeRowSelected;
    vm.saveCompositeRole = saveCompositeRole;
    vm.cancel = cancel;

    vm.compositeRole = { ...compositeRole };

    activate();

    function activate() {
        if (actionType !== 'Add') {
            setupAvailableCompositeRolesGrid();
        }
    }

    function setupAvailableCompositeRolesGrid() {
        let availableCompositeRolesGridColumns = [
            { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
        ];

        vm.availableRolesGridOptions = helperService.setMainGridOptions(availableCompositeRolesGridColumns, null);

        vm.availableRolesGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let params;
                    if (vm.actionType === 'Edit') {
                        params = {
                            IsEditable: true
                        };
                    }
                    let response = await sapSdSimCompositeRoleDataService.getSimulationCompositeRoles(simulation.Id, params);
                    response.data = $filter('orderBy')(response.data, "Name");
                    options.success(response.data);
                }
            }
        });
    }

    function changeRowSelected(dataItem) {
        vm.selectedCompositeRole = dataItem.toJSON();
    }

    async function saveCompositeRole() {
        vm.savePending = new createSpinner();
        switch (vm.actionType) {
            case 'Add':
                createCompositeRole();
                break;
            case 'Edit':
                editCompositeRole();
                break;
            case 'EditExisting':
                updateExistingCustomCompositeRole();
                break;
            case 'Copy':
                copyCompositeRole();
                break;
            default:
                break;
        }
    }

    async function createCompositeRole() {
        let data = vm.compositeRole;
        data.SimulationId = simulation.Id;

        try {
            await sapSdSimCompositeRoleDataService.addCompositeRoleToSimulation(data);
            await helperService.successfulSaveButton(vm.savePending);
            close();
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    async function editCompositeRole() {
        let data = {
            SimulationId: simulation.Id,
            Name: vm.selectedCompositeRole.Name
        };

        try {
            await sapSdSimCompositeRoleDataService.editCompositeRoleToSimulation(data);
            helperService.successfulSaveButton(vm.savePending);
            close();
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    async function updateExistingCustomCompositeRole() {
        let data = {
            SimulationId: simulation.Id,
            CompositeRoleId: vm.compositeRole.Id,
            Name: vm.compositeRole.Name,
            Description: vm.compositeRole.Description,
            LongDescription: vm.compositeRole.LongDescription
        };

        try {
            await sapSdSimCompositeRoleDataService.updateCompositeRole(data);
            helperService.successfulSaveButton(vm.savePending);
            close();
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    async function copyCompositeRole() {
        let data = {
            SimulationId: simulation.Id,
            ExistingCompositeRoleName: vm.selectedCompositeRole.Name,
            NewCompositeRoleName: vm.compositeRole.Name
        };

        try {
            await sapSdSimCompositeRoleDataService.copyCompositeRoleToSimulation(data);
            helperService.successfulSaveButton(vm.savePending);
            close();
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    async function updateCompositeRole() {
        vm.compositeRole['CompositeRoleId'] = vm.compositeRole.Id;
        delete vm.compositeRole.Id;
        try {
            await sapSdSimCompositeRoleDataService.updateCompositeRole(vm.compositeRole);
            helperService.successfulSaveButton(vm.savePending);
            close();
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function close() {
        $uibModalInstance.close();
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}