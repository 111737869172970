import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($scope, $uibModalInstance, $filter, helperService, $http, row, grid, request, statusDataService) {

    $scope.originalMitigation = $scope.riskToMitigate.MitigationID;
    $scope.originalStatus = $scope.riskToMitigate.StatusID;
    $scope.originalMitigationNotes = $scope.riskToMitigate.MitigationNotes;

    function activate() {

        getItemPillLabels();
        getStandardMitigations();
        getMitigations();
        setupAdditionalMitigationsObject();
        getAdditionalCompaniesDataSource();

        if (row.RequestMitigation === 'Edit') {
            populateModalFieldsWithEditedMitigationRequestData();
        } else {
            setupDefaultModalFields();
        }
    }

    activate();

    function getStandardMitigations() {
        $http.get(apiUrl + 'api/core/configuration/ARM_StandardMitigations').then(function (response) {
            $scope.standardMitigations = response.data;
        });
    }

    function getControlLibrary() {
        $scope.controlsLoading = true;
        return $http.get(apiUrl + 'api/arm/controls').then(function (response) {
            $scope.controlLibrary = response.data;
            $scope.controlsLoading = false;
        });
    }

    function setupControls() {
        $scope.selectedControls = new Array();
        $scope.originalControls = new Array();
        return;
    }

    function getStatuses() {
        statusDataService.getStatuses().then(function (response) {
            response.data = $filter('filter')(response.data, { CanCurrentUserAccess: true });
            response.data = $filter('orderBy')(response.data, 'Name');
            $scope.statuses = response.data;
        });
    }

    function populateModalFieldsWithEdits() {
        if (row.Controls) {
            row.Controls.forEach(control => {
                let controlToAdd = $scope.controlLibrary.find(c => c.Identifier === control);
                if (controlToAdd) $scope.selectedControls.push(controlToAdd);
            });
        }
        $scope.riskToMitigate.MitigationID = row.RequestMitigationId;
        $scope.riskToMitigate.StatusID = row.RequestMitigationStatusId;
        $scope.riskToMitigate.MitigationNotes = row.RequestMitigationNotes;
    }

    function setupDefaultModalFields() {
        getControlLibrary().then(_ => setupControls()).then(_ => getStatuses());
    }

    function populateModalFieldsWithEditedMitigationRequestData() {
        getControlLibrary().then(_ => setupControls()).then(_ => getStatuses()).then(_ => populateModalFieldsWithEdits());
    }

    function getAdditionalCompaniesDataSource() {

        $scope.mitigateAddtionalDataSource = [];

        grid.forEach(item => {
            if (item.RiskId === row.RiskId && item.CompanyName !== row.CompanyName) {
                $scope.mitigateAddtionalDataSource.push(item);
            }
        });
    }

    function getItemPillLabels() {
        $http.get(apiUrl + 'api/arm/risks/' + $scope.riskToMitigate.RiskId).then(function (response) {
            $scope.riskObjects = response.data;
        });
    }

    function getMitigations() {
        $http.get(apiUrl + 'api/arm/mitigations').then(function (response) {
            response.data = $filter('orderBy')(response.data, 'Name');
            $scope.mitigations = response.data;
        });
    }

    function setupAdditionalMitigationsObject() {
        $scope.mitigatedRisk = {
            selectedMitigateAddtional: '',
            companyIds: ''
        };
    }

    $scope.requestRiskMitigation = async function () {
        $scope.riskToMitigate.updateInProgress = new createSpinner();

        let userMitigation = {
            "RequestMitigationId": $scope.riskToMitigate.RequestMitigationIdentifier,
            "RequestId": request.Id,
            "Version": request.Version,
            "CompanyMasterId": $scope.riskToMitigate.CompanyMasterID,
            "RiskId": $scope.riskToMitigate.RiskId,
            "MitigationId": $scope.riskToMitigate.MitigationID,
            "MitigationNotes": $scope.riskToMitigate.MitigationNotes,
            "StatusId": $scope.riskToMitigate.StatusID,
            "Controls": $scope.selectedControls.map(x => x.Identifier)
        };

        if ($scope.riskToMitigate.MitigationID) {
            userMitigation.Controls = null;
        } else if ($scope.selectedControls) {
            userMitigation.MitigationId = null;
        }

        grid.forEach(item => {
            // Create status for new request.
            if (item.RiskId === userMitigation.RiskId && item.CompanyMasterID === userMitigation.CompanyMasterId) {
                item.RequestMitigation = 'Edit';
                item.RequestMitigationId = userMitigation.MitigationId;
                item.RequestMitigationNotes = userMitigation.MitigationNotes;
                item.RequestMitigationStatusId = userMitigation.StatusId;
                item.Controls = userMitigation.Controls;
            }
        });

        $scope.killModal = true;
        try {
            await $http.post(apiUrl + 'api/identityManager/requests/' + request.Id + '/version/' + request.Version + '/mitigations', userMitigation);
            await $scope.mitigateInAdditionalCompanies();
            await helperService.successfulSaveButton($scope.riskToMitigate.updateInProgress);
            $uibModalInstance.close(true);
            $scope.$parent.vm.RisksDataSource.read();
        } catch (error) {
            $scope.riskToMitigate.updateInProgress.loadingValue = false;
            helperService.showErrorMessage(error.data);
        }

    };

    $scope.mitigateInAdditionalCompanies = async function () {
        if ($scope.mitigatedRisk && $scope.mitigatedRisk.additionalCompanies) {
            if ($scope.mitigatedRisk.additionalCompanies && $scope.mitigatedRisk.additionalCompanies.length > 0) {
                const additionalCompanies = $scope.mitigatedRisk.additionalCompanies;

                try {
                    for (const company of additionalCompanies) {
                        let additionalUserMitigation = {
                            "RequestMitigationId": $scope.riskToMitigate.RequestMitigationId, "RequestId": request.Id,
                            "Version": request.Version,
                            "CompanyMasterId": company.CompanyMasterID,
                            "RiskId": $scope.riskToMitigate.RiskId,
                            "MitigationId": $scope.riskToMitigate.MitigationID,
                            "MitigationNotes": $scope.riskToMitigate.MitigationNotes,
                            "StatusId": $scope.riskToMitigate.StatusID,
                            "Controls": $scope.selectedControls.map(x => x.Identifier)
                        };

                        if ($scope.riskToMitigate.MitigationID) {
                            additionalUserMitigation.Controls = null;
                        } else if ($scope.selectedControls) {
                            additionalUserMitigation.MitigationId = null;
                        }

                        grid.forEach(item => {
                            // Create status for new request.
                            if (item.RiskId === additionalUserMitigation.RiskId && item.CompanyMasterID === additionalUserMitigation.CompanyMasterId) {
                                item.RequestMitigation = 'Edit';
                                item.RequestMitigationId = additionalUserMitigation.RequestMitigationId;
                                item.RequestMitigationNotes = additionalUserMitigation.MitigationNotes;
                                item.RequestMitigationStatusId = additionalUserMitigation.StatusId;
                                item.Controls = additionalUserMitigation.Controls;
                            }
                        });

                        await $http.post(apiUrl + 'api/identityManager/requests/' + request.Id + '/version/' + request.Version + '/mitigations', additionalUserMitigation);
                    }
                } catch (error) {
                    throw error;
                }
            }
        }
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}
