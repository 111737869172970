/* accessDetailsProfile.component.js */

import accessDetailsProfileController from "./accessDetailsProfile.controller";

/**
 * @desc component for viewing Access Details profile information
 * @example <access-details-profile></access-details-profile>
 */

const accessDetailsProfile = {
    bindings: {
        risk: '<',
        businessProcess: '<'
    },
    controller: accessDetailsProfileController,
    controllerAs: 'adpvm',
    templateUrl: '/App/Shared/Components/accessDetailsProfile/accessDetailsProfile.tmpl.html'
};

export default accessDetailsProfile;