import saveReportFilterController from "./saveReportFilter.controller";

/* saveReportFilter.component.js */

/**
 * @desc component for saving report filters
 * @example <save-report-filter></save-report-filter>
 */

const saveReportFilter = {
    bindings: {
        resolve: '<',
        modalInstance: '<'
    },
    controller: saveReportFilterController,
    controllerAs: 'srfvm',
    templateUrl: '/App/Shared/Components/SaveReportFilter/saveReportFilter.tmpl.html'
};

export default saveReportFilter;