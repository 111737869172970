import allSupportedProducts from "../../Shared/GlobalVariables/ProductLookUp";

export default /*@ngInject*/ function ($scope, objectIdDataService, $http, helperService) {

    objectIdDataService.getObjectID().then(function (objectId) {
        $scope.ObjectId = objectId;

        var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;

    });

    $scope.helperService = helperService;

    var vm = $scope.vm = {};
    var parentVm = $scope.parentVm = {};

    parentVm.radioSelected = '';

    $scope.helperService.getConfigurationValue('AT_ArchiveDays').then(function (data) {
        parentVm.origAT_ArchiveDays = data;
        parentVm.AT_ArchiveDays = data;
    });

    $scope.helperService.getConfigurationValue('AT_PurgeDays').then(function (data) {
        parentVm.origAT_PurgeDays = data;
        parentVm.AT_PurgeDays = data;
    });

    $http.get(apiUrl + 'api/core/configuration').then(function (response) {
        parentVm.configurationValues = {};
        response.data.forEach(function (product) {
            parentVm.configurationValues[product.Name] = product.Value;
        });
    });

    parentVm.checkImportFocus = function (product) {
        if (parentVm.radioSelected === null || parentVm.radioSelected === "") {
            return false;
        } else {
            if (parentVm.radioSelected.has(product)) {
                return false;
            } else {
                return true;
            }
        }
    };

    parentVm.ProductImportTiles = {};

    allSupportedProducts.forEach(function (product) {
        parentVm.ProductImportTiles[product.ID] = 'hide';
    });

    function determineProductVisability() {
        parentVm.availableProducts.forEach(function (availableProduct) {
            parentVm.ProductImportTiles[availableProduct.ID] = 'show';
        });
    }

    parentVm.ActionTiles = {};

    $http.get(apiUrl + '/api/core/modules/' + 3000 + '/products').then(function (response) {

        parentVm.availableProducts = response.data;
        determineProductVisability();

        response.data = response.data.filter(function (el) { return el.Type === 'NS' || el.Type === 'SAP' || el.Type === 'GP' || el.Type === 'NAV' || el.Type === 'AX' || el.Type === "AX5" || el.Type === "AX7" || el.Type === "SL" || el.IsCustom; });

        parentVm.ActionTiles['CollectDataChanges'] = 'show';
        parentVm.ActionTiles['ArchiveDataChanges'] = 'show';
        parentVm.ActionTiles['RestoreDataChanges'] = 'show';
        parentVm.ActionTiles['PurgeDataChanges'] = 'show';

        response.data.forEach(function (data) {
            if (data.ID === 'NS') {
                parentVm.ActionTiles['CollectSnapshot'] = 'show';
            } else if (data.ID === 'AX7') {
                parentVm.ActionTiles['CollectDataChanges'] = 'show';
                parentVm.ActionTiles['ArchiveDataChanges'] = 'hide';
                parentVm.ActionTiles['RestoreDataChanges'] = 'hide';
                parentVm.ActionTiles['PurgeDataChanges'] = 'hide';
            }
        });

        parentVm.availableProducts = {};
        response.data.forEach(function (product) {
            parentVm.availableProducts[product.ID] = 'show';
        });

    });

    var newDate = $scope.newDate = new Date();

    //Single Date picker settings
    $scope.singleDateOptions = {
        showWeeks: false,
        formatYear: 'yy',
        startingDay: 1,
        maxDate: newDate
    };

    $scope.ninetyDaysAgo = function () {
        var ninetyDaysAgo = new Date();
        ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);
        vm.singleDate = ninetyDaysAgo;
    };

    $scope.ninetyDaysAgo();

    //Start Date picker settings
    $scope.thirtyDaysAgo = function () {
        var thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
        vm.restoreStartDate = thirtyDaysAgo;
    };

    $scope.thirtyDaysAgo();

    $scope.startDateOptions = {
        dateDisabled: disabled,
        showWeeks: false,
        formatYear: 'yy',
        startingDay: 1
    };

    function disabled(data) {
        var date = data.date,
            mode = data.mode;
        return mode === 'day' && date > vm.restoreEndDate;
    }

    $scope.openStartDate = function () {
        $scope.startDatePopup.opened = true;
    };

    $scope.startDatePopup = {
        opened: false
    };

    //End Date picker settings
    $scope.today = function () {
        vm.restoreEndDate = newDate;
    };
    $scope.today();

    $scope.endDateOptions = {
        showWeeks: false,
        formatYear: 'yy',
        maxDate: newDate,
        startingDay: 1
    };

    $scope.openEndDate = function () {
        $scope.endDatePopup.opened = true;
    };

    $scope.endDatePopup = {
        opened: false
    };

}