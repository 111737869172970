import crossPlatformUsersController from "./crossPlatformUsers.controller";

/* crossPlatformUsers.component.js */
/**
 * @desc component for cross platform users
 * @example <cross-platform-users</cross-platform-users>
 */

const crossPlatformUsers = {
    controller: crossPlatformUsersController,
    controllerAs: 'cpuvm',
    templateUrl: '/App/Components/CrossPlatformUsers/crossPlatformUsers.tmpl.html'
};

export default crossPlatformUsers;