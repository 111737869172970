export default function () {

    const service = {
        valueMap
    };

    return service;

    function valueMap(userObject, mailMappingField) {

        let mappedObject = {};

        switch (userObject.ProductType) {
            case 'AX':
                mappedObject = {
                    NetworkDomain: userObject.Domain,
                    NetworkAlias: userObject.SamAccountName,
                    UserName: userObject.DisplayName,
                    DisplayUserId: userObject.SamAccountName.substring(0, 8),
                    SID: userObject.Sid
                };
                break;

            case 'GP':
                mappedObject = {
                    WindowsUserId: userObject.SamAccountName,
                    NetBiosName: userObject.NetBiosName,
                    ADUserId: userObject.ObjectGuid,
                    Id: userObject.SamAccountName,
                    UserName: userObject.DisplayName,
                    ProductType: userObject.ProductType
                };
                break;

            case 'NAV':
                mappedObject = {
                    WindowsUserName: userObject.NetBiosName + '\\' + userObject.SamAccountName,
                    UserName: userObject.NetBiosName + '\\' + userObject.SamAccountName,
                    FullName: userObject.DisplayName,
                    WindowsSID: userObject.Sid,
                    LicenseType: 0,
                    State: '0',
                    ProductType: userObject.ProductType
                };
                break;

            case 'ORFC':
                mappedObject = {
                    DisplayName: userObject.DisplayName,
                    Email: userObject[mailMappingField],
                    EndDate: userObject.AccountExpirationDate,
                    ProductType: userObject.ProductType,
                    FirstName: '',
                    LastName: ''
                };
                break;

            case 'SF':
                mappedObject = {
                    CompanyName: userObject.Company,
                    Department: userObject.Department,
                    Email: userObject[mailMappingField],
                    FirstName: '',
                    LastName: '',
                    ManagerId: '',
                    Moble: '',
                    Phone: '',
                    ProductType: userObject.ProductType,
                    Title: userObject.Title,
                    EndDate: userObject.AccountExpirationDate,
                    Street: '',
                    City: '',
                };
                break;

            case 'PS':
                mappedObject = {
                    PrimaryEmail: userObject[mailMappingField],
                    ProductType: userObject.ProductType,
                    EndDate: userObject.AccountExpirationDate
                };
                break;

            case 'NS':
                mappedObject = {
                    Email: userObject[mailMappingField],
                    ProductType: userObject.ProductType,
                    JobTitle: userObject.Title,
                    EndDate: userObject.AccountExpirationDate,
                    FirstName: '',
                    LastName: '',
                    Phone: '',
                    MobilePhone: '',
                };
                break;

            case 'OR':
                mappedObject = {
                    ProductType: userObject.ProductType,
                    Email: userObject[mailMappingField],
                    EndDate: userObject.AccountExpirationDate
                }
                break;

            case 'SAP':
                mappedObject = {
                    Email: userObject[mailMappingField],
                    EndDate: userObject.AccountExpirationDate,
                };
                break;

            default:
                mappedObject = userObject;
        }
        return mappedObject;
    }

}