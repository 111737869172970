export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getReport
    };

    return service;

    //GET Requests
    async function getReport(objectID) {
        try {
            return await $http.get(apiUrl + 'api/core/reports/execute/' + objectID);
        } catch (err) {
            errorsDataService.catch(err);
        }
    }
}
