export default function () {

    const service = {
        formatDate: formatDate
    };

    return service;

    // Connections -> schedule import -> ISO string date
    function formatDate(dateObject) {
        return dateObject.toISOString().replace(/\.[0-9]{3}/, '');
    }

}