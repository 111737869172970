export default /*@ngInject*/ function ($http, errorsDataService) {
    const factory = {
        createGroup,
        createFieldValue,
        updateGroup,
        deleteGroup,
        deleteFieldValue
    };

    return factory;

    // POST Requests
    async function createGroup(data) {
        try {
            return await $http.post(`${apiUrl}api/assure/sap/authfieldgroups`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function createFieldValue(data) {
        try {
            return await $http.post(`${apiUrl}api/assure/sap/authfieldvalues`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    // PUT Requests
    async function updateGroup(data) {
        try {
            return await $http.put(`${apiUrl}api/assure/sap/authfieldgroups/${data.Id}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    // DELETE Requests
    async function deleteGroup(groupId) {
        try {
            return await $http.delete(`${apiUrl}api/assure/sap/authfieldgroups/${groupId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteFieldValue(fieldId) {
        try {
            return await $http.delete(`${apiUrl}api/assure/sap/authfieldvalues/${fieldId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }
}