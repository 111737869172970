import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (passwordCriteriaDataService, helperService) {
    const vm = this;

    vm.helperService = helperService;
    vm.saveOrUpdatePasswordCriteria = saveOrUpdatePasswordCriteria;
    vm.criteriaValuesHaveChanged = criteriaValuesHaveChanged;
    vm.requireSpecialCharChanged = requireSpecialCharChanged;

    vm.$onInit = getCriteria;

    async function getCriteria() {
        vm.gettingCriteriaInProgress = true;

        try {
            const { data } = await passwordCriteriaDataService.getPasswordCriteria(vm.product.ID);

            vm.originalPasswordCriteria = data;
            vm.passwordCriteria = { ...vm.originalPasswordCriteria };

            if (data.SpecialChars) {
                vm.requireSpecialChar = true;
            }

        } catch (response) {
            if (response.status === 404) {
                vm.originalPasswordCriteria = {};
                vm.passwordCriteria = {};
            }
        }
        vm.gettingCriteriaInProgress = false;
    }

    async function saveOrUpdatePasswordCriteria() {
        vm.updatePasswordCriteriaPending = new createSpinner();

        let data = {
            ProductId: vm.product.ID,
            MinLength: vm.passwordCriteria.MinLength,
            MaxLength: vm.passwordCriteria.MaxLength,
            RequireCapitalLetter: vm.passwordCriteria.RequireCapitalLetter,
            RequireNumber: vm.passwordCriteria.RequireNumber,
            SpecialChars: vm.passwordCriteria.SpecialChars
        };

        if (Object.keys(vm.originalPasswordCriteria).length > 0) {
            try {
                await passwordCriteriaDataService.updatePasswordCriteria(data);
                vm.originalPasswordCriteria = { ...vm.passwordCriteria };
                await helperService.successfulSaveButton(vm.updatePasswordCriteriaPending);
            } catch {
                vm.updatePasswordCriteriaPending.loadingValue = false;
            }
        } else {
            try {
                await passwordCriteriaDataService.addPasswordCriteria(data);
                vm.originalPasswordCriteria = { ...vm.passwordCriteria };
                await helperService.successfulSaveButton(vm.updatePasswordCriteriaPending);
            } catch {
                vm.updatePasswordCriteriaPending.loadingValue = false;
            }
        }
    }

    function criteriaValuesHaveChanged(criteriaArray) {
        return helperService.returnTrueIfValuesDiffer(criteriaArray, vm.originalPasswordCriteria, vm.passwordCriteria);
    }

    function requireSpecialCharChanged() {
        if (vm.requireSpecialChar === false) {
            vm.passwordCriteria.SpecialChars = undefined;
        }
    }
}