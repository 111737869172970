import createSpinner from "../../Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, role, helperService, $http) {

    const vm = this;
    vm.role = { ...role };
    vm.cancel = cancel;
    vm.close = close;
    vm.addOrgHierarchyToList = addOrgHierarchyToList;
    vm.deleteselectedOrgHierarchy = deleteselectedOrgHierarchy;

    activate();

    async function activate() {
        vm.orgHierarchiesLoading = true;
        let availableOrgHierarchies = await getOrgHierarchies();
        vm.orgHierarchiesLoading = false;
        availableOrgHierarchies = mapOrgHierarchiesToCompanies(availableOrgHierarchies);
        if (vm.role.Companies) {
            let selectedOrgHierarchies = vm.role.Companies.filter(roleCompany => roleCompany.CompanyType === 'Organization');
            if (selectedOrgHierarchies.length) {
                availableOrgHierarchies = filterAvailableOrgHierarchies(availableOrgHierarchies, selectedOrgHierarchies);
            }
        } else {
            vm.role.Companies = [];
        }
        vm.availableOrgHierarchies = availableOrgHierarchies;
    }

    function mapOrgHierarchiesToCompanies(orgHierarchies) {
        return orgHierarchies.map(orgHierarchy => {
            return {
                CompanyId: orgHierarchy.Id,
                CompanyName: orgHierarchy.Name,
                CompanyType: 'Organization'
            };
        })
    }

    function filterAvailableOrgHierarchies(availableOrgHierarchies, selectedOrgHierarchies) {
            // Filter out the already selected org hierarchies from the available list
        return availableOrgHierarchies.filter(orgHierarchy => selectedOrgHierarchies.some(selectedOrgHierarchy => selectedOrgHierarchy.CompanyId !== orgHierarchy.CompanyId));
    }

    function addOrgHierarchyToList(orgHierarchy) {
        vm.role.Companies.push(orgHierarchy);
        const selectedOrgHierarchyIndex = vm.availableOrgHierarchies.findIndex(item => item.CompanyId === orgHierarchy.CompanyId);
        vm.availableOrgHierarchies.splice(selectedOrgHierarchyIndex, 1);
    }

    function deleteselectedOrgHierarchy(orgHierarchy) {
        vm.availableOrgHierarchies.push(orgHierarchy);
        const removedOrgHierarchyIndex = vm.role.Companies.findIndex(item => item.CompanyId === orgHierarchy.CompanyId);
        vm.role.Companies.splice(removedOrgHierarchyIndex, 1);
    }

    async function getOrgHierarchies() {
        let orgHierarchies = await $http.get(`${apiUrl}api/identitymanager/ax7/organizationalhierarchies`);
        return orgHierarchies.data;
    }

    function cancel() { $uibModalInstance.dismiss(); }

    async function close() {
        vm.saving = new createSpinner();
        let roleOrgHierarchyModalObject = {
            roleCompanies: vm.role.Companies
        };
        await helperService.successfulSaveButton(vm.saving);
        $uibModalInstance.close(roleOrgHierarchyModalObject);
    }
}