import assignRisksController from "./assignRisks.controller";

/* assignRisks.component.js */

/**
 * @desc component for assigning risks
 * @example <assign-risks></assign-risks>
 */

const assignRisks = {
    controller: assignRisksController,
    controllerAs: 'arvm',
    templateUrl: '/App/Components/assignRisks/assignRisks.tmpl.html'
};

export default assignRisks;