import churnZeroConfig from '../Config/churnZero.config';

export default /*@ngInject*/ function ($scope, $http, $filter, $timeout, $rootScope, $window, dataService, $location, helperService, configurationDataService, authenticationService) {

    $scope.navigateToMvcPage = function (path) {
        $window.open(appUrl + path, '_self');
    };
    $scope.$on('logoUpdated', getCompanyLogo);
    $scope.helperService = helperService;
    $scope.userInfo = {};

    activate();

    async function activate() {
        $scope.tenantID = await checkForSelectedTenant();
        await getUserTenants();
        $scope.getFavorites();
        await getNavigation();
        getCompanyLogo();
    }

    async function getCompanyLogo() {
        $scope.logoLoading = true;
        try {
            const { data } = await configurationDataService.getCompanyLogo();
            $scope.companyLogo = data;
            $scope.logoLoading = false;
        } catch {
            // if the call errors or returns null load default FP logo
            $scope.companyLogo = null;
            $scope.logoLoading = false;
        }
        
    }

    function checkForSelectedTenant() {
        var selectedTenantAsObject = angular.fromJson(localStorage.getItem("selectedTenant"));
        if (selectedTenantAsObject) {
            return selectedTenantAsObject.Name;
        } else {
            $window.open(appUrl + 'redirect', '_self');
        }
    }

    async function getUserTenants() {
        const currentUser = await authenticationService.getUser();
        $scope.userInfo = currentUser;
        if (currentUser) {
            let response = await $http({
                method: 'GET',
                url: apiUrl + `api/auth/tenants/user/${currentUser.userId}`
            });

            response.data = $filter('orderBy')(response.data, 'Name');
            $rootScope.availableTenantsForUser = response.data;
            resetSelectedTenant(response.data);
        }
    }

    $scope.backToReportViewer = function () {
        // Prevent dashboard charts from breaking;
        delete $rootScope.packery;

        if ($rootScope.tabs) {
            $rootScope.IsReport = true;

            if ($rootScope.currentSelectedTab != null) {
                $location.path($rootScope.currentSelectedTab.link).search('');
                $rootScope.tabChangedEventFired = true;
            }
        }

    };

    $scope.stringEndsInDetailed = function (string) {
        if (string.endsWith("Detailed")) {
            return true
        } else {
            return false
        }
    }

    $scope.stringEndsInRoleDetail = function (string) {
        if (string.endsWith("Role Detail")) {
            return true
        } else {
            return false
        }
    }

    $scope.showNavItem = function (navItem) {
        return navItem.length > 0;
    };

    $scope.emptySearchQuery = function () {
        $scope.navCtrl.query = "";
    };

    $scope.$on('handleFavoriteBroadcast', function () {
        $scope.getFavorites();
    });

    $scope.selectTenantFromNavigation = function (Tenant) {
        if (Tenant.LicensingOnly) {
            // If switching to a Licensing Only tenant we need to remove the selectedTenant from local storage to cause redirect
            localStorage.removeItem("selectedTenant");
            $window.location.href = appUrl;
        }
        else if (!Tenant.OnHold) {
            localStorage.setItem("selectedTenant", JSON.stringify(angular.copy(Tenant)));
            $window.location.href = appUrl;
        }
        else {
            swal('Attention:', 'The ' + Tenant.Name + ' environment is currently on hold. Please contact Fastpath Support for further assistance.', 'warning');
        }
    };

    $scope.getFavorites = function () {
        var selectedTenantInLocalStorage = localStorage.getItem("selectedTenant");
        if (selectedTenantInLocalStorage) {
            if (selectedTenantInLocalStorage != null) {
                dataService.getFavorites()
                    .then(function (response) {
                        response.data = $filter('orderBy')(response.data, 'Name');
                        $rootScope.allFavorites = response.data;
                    });
            }
        }
    };

    $rootScope.navigateToFavorite = function (favorite) {
        $location.path(favorite.Url.substr(1));
    };


    //Load Menu only if authenticated
    async function getNavigation() {
        let authenticated = await authenticationService.isAuthenticated();
        if (authenticated) {
            dataService.getNavigation().then(function (response) {

                $rootScope.UserNavigationList = [];
                response.data.forEach(function (row) {
                    $rootScope.UserNavigationList[row.ObjectID] = true;
                });

                $scope.navLinks = response.data;
                localStorage.setItem("NavigationObjects", angular.toJson(response.data));
            });
        }
    }

    $scope.$on('navigation-or-security-change', function (event, args) {
        getNavigation();
    });

    $scope.removeStaticHeight = function ($event) {
        $timeout(function () {
            $event.currentTarget.children.forceStyleOfMetisMenu.style.height = "";
        }, 400);
    };

    $scope.changeTypeClass = function (firstLevel, secondLevel, activeOrInValue) {

        firstLevel = $filter('removeSpaces')(firstLevel);

        var splitItUp = $location.path().split("/");
        var currentRoute = splitItUp[1];
        if (firstLevel === currentRoute) {
            if (splitItUp[2] === secondLevel) {
                return firstLevel = activeOrInValue;
            }
        }
    };

    $scope.changeCurrentPageClass = function (firstLevel, secondLevel, page, activeOrInValue) {

        firstLevel = $filter('removeSpaces')(firstLevel);
        page = $filter('removeSpaces')(page);

        var splitItUp = $location.path().split("/");
        var currentRoute = splitItUp[1];
        if (firstLevel === currentRoute) {
            if (splitItUp[2] === secondLevel) {
                if (splitItUp[3] === page) {
                    return firstLevel = activeOrInValue;
                }
            }
        }
    };

    $scope.changeItemClass = function (firstLevel, activeOrInValue) {

        firstLevel = $filter('removeSpaces')(firstLevel);

        var splitItUp = $location.path().split("/");
        var currentRoute = splitItUp[1];
        if (firstLevel === currentRoute) {
            return firstLevel = activeOrInValue;
        }
    };

    $scope.changeReportView = function (moduleName, reportName, objectID, type) {
        helperService.changeReportView(moduleName, reportName, objectID, type);
        $scope.emptySearchQuery();
    };

    $scope.changeView = function (moduleName, viewName, type) {
        helperService.changeView(moduleName, viewName, type);
        $scope.emptySearchQuery();

    };

    function resetSelectedTenant(tenants) {
        let currentTenant = JSON.parse(localStorage.getItem("selectedTenant"));

        if (currentTenant?.ID) {
            currentTenant = tenants.filter(tenant => tenant.ID === currentTenant.ID).first();
            localStorage.setItem("selectedTenant", JSON.stringify(currentTenant));
        }

        churnZeroConfig(authenticationService);
    }
}