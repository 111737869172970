import swal from "sweetalert2";
import addRolesModalController from "./addRolesModal.controller";

export default /*@ngInject*/ function (sapSdSimCompositeRoleDataService, helperService, $uibModal, $filter) {

    const vm = this;

    vm.openAddRoleModal = openAddRoleModal;
    vm.removeRoleFromCompositeRole = removeRoleFromCompositeRole;

    vm.$onChanges = activate;

    async function activate() {
        getCompositeRoleRoles();
    }

    async function getCompositeRoleRoles() {
        vm.rolesLoading = true;
        let response = await sapSdSimCompositeRoleDataService.getCompositeRoleRoles(vm.simulation.Id, vm.selectedCompositeRole.Id);
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.roles = response.data;
        vm.rolesLoading = false;
    }

    async function openAddRoleModal() {
        let addRoleModal = $uibModal.open({
            templateUrl: '/App/Components/SAPSecurityDesigner/compositeRoleRolesList/addRolesModal.tmpl.html',
            controller: addRolesModalController,
            controllerAs: 'armvm',
            backdrop: 'static',
            resolve: {
                simulation: () => vm.simulation,
                selectedCompositeRole: () => vm.selectedCompositeRole,
                selectedRoles: () => vm.roles
            }
        });

        try {
            await addRoleModal.result;
            refreshSimulations();
            await getCompositeRoleRoles();
        } catch { return; }
    }

    async function removeRoleFromCompositeRole(role) {
        try {
            await swal(helperService.areYouSureParams(`Remove Role from Composite Role`, `Are you sure you want to remove Role ${role.Name} from Composite Role ${vm.selectedCompositeRole.Name}?`, `Yes`, 'No'));
            vm.rolesLoading = true;
            await sapSdSimCompositeRoleDataService.removeRoleFromCompositeRole(vm.simulation.Id, vm.selectedCompositeRole.Id, role.Id);
            vm.rolesLoading = false;
            refreshSimulations();
            getCompositeRoleRoles();
        } catch {
            vm.rolesLoading = false;
        }
    }

    function refreshSimulations() {
        const updateList = true;
        vm.onUpdateSimulationsList({ updateList });
    }
}