import { customAutoCompleteFilter, customAutoCompleteFilterEqualToOnly, customDatePickerFilter } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($scope, $http, helperService) {

    const vm = this;

    vm.removeIMRequestTicketLink = removeIMRequestTicketLink;
    vm.openTicketTable = openTicketTable;
    vm.createLink = createLink;
    vm.showLinkTicketBody = false;

    vm.isJira = false;
    vm.isServiceNow = false;
    vm.isZendesk = false;
    vm.isCustom = false;
    vm.url = null;


    vm.linkedTickets = vm.tickets;

    async function openTicketTable(ticketType) {
        vm.showLinkTicketBody = true;
        switch (ticketType) {
            case 'Jira':
                vm.isJira = true;
                vm.url = 'api/core/tickets/jira/search/';
                vm.template = `<span ng-disabled="ltdvm.jiraTicketLinked" ng-if='!dataItem.isLinked' class='btn btn-sm btn-secondary fixed-btn-width' ng-click='ltdvm.createLink(dataItem)'>Link</span>`;
                vm.isFreshservice = false;
                vm.isServiceNow = false;
                vm.isZendesk = false;
                vm.isCustom = false;
                await updateTicketGrid();
                break;
            case 'Freshservice':
                vm.isFreshservice = true;
                vm.daysToSearch = '30';
                vm.url = 'api/core/tickets/freshservice/search/';
                vm.template = `<span ng-disabled="ltdvm.freshServiceTicketLinked" ng-if='!dataItem.isLinked' class='btn btn-sm btn-secondary fixed-btn-width' ng-click='ltdvm.createLink(dataItem)'>Link</span>`;
                vm.isJira = false;
                vm.isServiceNow = false;
                vm.isZendesk = false;
                vm.isCustom = false;
                await updateTicketGrid();
                break;
            case 'ServiceNow':
                vm.isServiceNow = true;
                vm.url = 'api/core/tickets/servicenow/search/';
                vm.template = `<span ng-disabled="ltdvm.serviceNowTicketLinked" ng-if='!dataItem.isLinked' class='btn btn-sm btn-secondary fixed-btn-width' ng-click='ltdvm.createLink(dataItem)'>Link</span>`;
                vm.isJira = false;
                vm.isFreshservice = false;
                vm.isZendesk = false;
                vm.isCustom = false;
                await updateTicketGrid();
                break;
            case 'Zendesk':
                vm.isZendesk = true;
                vm.url = 'api/core/tickets/zendesk/search/';
                vm.template = `<span ng-disabled="ltdvm.zendeskTicketLinked" ng-if='!dataItem.isLinked' class='btn btn-sm btn-secondary fixed-btn-width' ng-click='ltdvm.createLink(dataItem)'>Link</span>`;
                vm.isJira = false;
                vm.isFreshservice = false;
                vm.isServiceNow = false;
                vm.isCustom = false;
                await updateTicketGrid();
                break;
            case 'Custom':
                vm.isCustom = true;
                vm.url = null;
                vm.isJira = false;
                vm.isFreshservice = false;
                vm.isServiceNow = false;
                vm.isZendesk = false;
            default:
                return;

        }
    }

    //set columns as they are all the same 
    function updateTicketGrid() {
        let statusTemplate = "<span class='review-status-capitalized'>{{dataItem.Status}}</span>";

        let columnsFromVM = [
            { field: "Status", title: "Status", template: statusTemplate, filterable: false },
            { field: "Id", title: "Id", filterable: customAutoCompleteFilterEqualToOnly },
            { field: "Subject", title: "Subject", filterable: customAutoCompleteFilter },
            { field: "Description", title: "Description", template: "{{ dataItem.Description }}", filterable: customAutoCompleteFilter }, // use template to handle possible issues with characters in description field
            { field: "CreatedDate", title: "Date Created", template: "{{ dataItem.CreatedDate | date:'short'}}", filterable: customDatePickerFilter },
            { title: ' ', width: 90, template: vm.template }
        ];

        vm.gridOptions = helperService.setMainGridOptions(columnsFromVM, 400);
        vm.gridOptions.selectable = false;
        vm.gridOptions.pageable = false;
        vm.gridOptions.filterable = {
            mode: 'row',
            operators: {
                string: {
                    contains: "Contains"
                }
            }
        };

        vm.gridDataSource.read();
    }

    if (!vm.isCustom) {
        vm.gridDataSource = new kendo.data.DataSource({
            serverFiltering: true,
            schema: {
                model: {
                    fields: {
                        CreatedDate: { type: "date" }
                    }
                }
            },
            transport: {
                read: async function (options) {
                    let sortsAndFilters = helperService.prepareSortsAndFilters(options);

                    let postBody = {
                        "Criteria": sortsAndFilters.Filters
                    };

                    try {
                        let response = await $http.post(apiUrl + vm.url, postBody);
                        vm.TotalTicketCount = response.data.Count;
                        options.success(response.data.Tickets);
                    } catch {
                        options.error();
                    }
                }
            }
        });
    }

    function createLink(dataItem) {
        if (!vm.linkedTickets) vm.linkedTickets = [];
        let linkedTicket = {};

        if (vm.isJira) {
            linkedTicket.TicketId = dataItem.Id;
            linkedTicket.TicketProvider = 'Jira';
            vm.jiraTicketLinked = true;
        }
        else if (vm.isFreshservice) {
            linkedTicket.TicketId = dataItem.Id;
            linkedTicket.TicketProvider = 'Freshservice';
            vm.freshServiceTicketLinked = true;
        }
        else if (vm.isServiceNow) {
            linkedTicket.TicketId = dataItem.Id;
            linkedTicket.TicketProvider = 'ServiceNow';
            vm.serviceNowTicketLinked = true;
        }
        else if (vm.isZendesk) {
            linkedTicket.TicketId = dataItem.Id;
            linkedTicket.TicketProvider = 'Zendesk';
            vm.zendeskTicketLinked = true;
        } else if (vm.isCustom) {
            linkedTicket.TicketId = vm.customTicketId;
            linkedTicket.TicketProvider = 'Custom';
            vm.customTicketLinked = true;
        }

        vm.linkedTickets.push(linkedTicket);

        if (dataItem) {
            dataItem.isLinked = true;
        }

        vm.showLinkTicketBody = false;

        let ticket = linkedTicket;

        vm.onUpdateTicket({ ticket });
    };

    // Remove a ticket
    function removeIMRequestTicketLink(providerString) {
        vm.linkedTickets = vm.linkedTickets.filter(ticket => ticket.TicketProvider !== providerString);

        if (providerString === 'Jira') vm.jiraTicketLinked = false;
        else if (providerString === 'Freshservice') vm.freshServiceTicketLinked = false;
        else if (providerString === 'ServiceNow') vm.serviceNowTicketLinked = false;
        else if (providerString === 'Zendesk') vm.zendeskTicketLinked = false;
        else if (providerString === 'Custom') vm.customTicketLinked = false; vm.customTicketId = '';

        vm.onRemoveTicket({ providerString });
    }

}