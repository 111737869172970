import CriteriaSubmitButtonDropdownController from "./criteriaSubmitButtonDropdown.controller";

/* criteriaSubmitButtonDropdown.component.js */

/**
 * @desc component for displaying a criteria submit button with dropdown export options
 * @example <criteria-submit-button-dropdown></criteria-submit-button-dropdown>
 */

const criteriaSubmitButtonDropdown = {
    bindings: {
        formReference: '<',
        hideOptions: '<',
        exportPropertiesContainer: '<'
    },
    controller: CriteriaSubmitButtonDropdownController,
    controllerAs: 'csbdvm',
    templateUrl: '/App/Shared/Components/criteriaSubmitButtonDropdown/criteriaSubmitButtonDropdown.tmpl.html'
};

export default criteriaSubmitButtonDropdown;