import angular from 'angular';
import * as directives from './Directives/customDirectives';
import * as inspinia from './Functions/directives';
import whenReadyDirective from './Directives/whenReadyDirective';
import oraclePasswordDirective from './Directives/oracle-password.directive';
import navRepeatDirective from './Directives/navRepeat.directive';

export default angular
    .module('app.shared.directives', [])
    // directives
    .directive('navRepeatDirective', navRepeatDirective)
    .directive('onEnter', directives.onEnter)
    .directive('complexPassword', directives.complexPassword)
    .directive('pwdReqCapitalLetter', directives.pwdReqCapitalLetter)
    .directive('pwdReqNumber', directives.pwdReqNumber)
    .directive('pwdReqSymbol', directives.pwdReqSymbol)
    .directive('errSrc', directives.errSrc)
    .directive('pwCheck', directives.pwCheck)
    .directive('setStyle', directives.setStyle)
    .directive('numbersOnly', directives.numbersOnly)
    .directive('globalEvents', directives.globalEvents)
    .directive('stopProp', directives.stopProp)
    .directive('showTemp', directives.showTemp)
    .directive('focusMe', directives.focusMe)
    .directive('inlineSaver', directives.inlineSaver)
    .directive('packery', directives.packery)
    .directive('whenReady', whenReadyDirective)
    .directive('oraclePassword', oraclePasswordDirective)
    .directive('component', directives.component)
    // inspinia directives
    .directive('pageTitle', inspinia.pageTitle)
    .directive('sideNavigation', inspinia.sideNavigation)
    .directive('iboxTools', inspinia.iboxTools)
    .directive('minimalizaSidebar', inspinia.minimalizaSidebar)
    .directive('vectorMap', inspinia.vectorMap)
    .directive('sparkline', inspinia.sparkline)
    .directive('icheck', inspinia.icheck)
    .directive('ionRangeSlider', inspinia.ionRangeSlider)
    .directive('dropZone', inspinia.dropZone)
    .directive('responsiveVideo', inspinia.responsiveVideo)
    .directive('chatSlimScroll', inspinia.chatSlimScroll)
    .directive('customValid', inspinia.customValid)
    .directive('fullScroll', inspinia.fullScroll)
    .directive('closeOffCanvas', inspinia.closeOffCanvas)
    .directive('clockPicker', inspinia.clockPicker)
    .directive('landingScrollspy', inspinia.landingScrollspy)
    .directive('fitHeight', inspinia.fitHeight)
    .directive('iboxToolsFullScreen', inspinia.iboxToolsFullScreen)
    .directive('slimScroll', inspinia.slimScroll)
    .name;