import angular from 'angular';
import AXSecurityDesignerService from '../Components/AXSecurityDesigner/AXSecurityDesignerService';
import taskRecordingDataServiceFactory from '../APICalls/taskRecordingDataService.factory';
import AXSecurityDesignerPrivilegesDataServiceFactory from '../Components/AXSecurityDesigner/Privileges/AXSecurityDesignerPrivilegesDataService.factory';
import securityMigrationDataServiceFactory from '../Components/AXSecurityDesigner/SecurityMigration/securityMigrationDataService.factory';
import sapSdSimulationDataServiceFactory from '../Components/SAPSecurityDesigner/sapSdSimulationDataService.factory';
import sapSdSimCompositeRoleDataServiceFactory from '../Components/SAPSecurityDesigner/simulationCompositeRolesList/sapSdSimCompositeRoleDataService.factory';
import sapSdSimRoleDataServiceFactory from '../Components/SAPSecurityDesigner/simulationRolesList/sapSdSimRoleDataService.factory';
import sapSdSimRiskAnalysisDataServiceFactory from '../Components/SAPSecurityDesigner/simulationRiskAnalysis/sapSdSimRiskAnalysisDataService.factory';

export default angular
    .module('app.securityDesigner.factories', [])
    .factory('AXSecurityDesignerService', AXSecurityDesignerService)
    .factory('AXSecurityDesignerPrivilegesDataService', AXSecurityDesignerPrivilegesDataServiceFactory)
    .factory('taskRecordingDataService', taskRecordingDataServiceFactory)
    .factory('securityMigrationDataService', securityMigrationDataServiceFactory)
    .factory('sapSdSimulationDataService', sapSdSimulationDataServiceFactory)
    .factory('sapSdSimCompositeRoleDataService', sapSdSimCompositeRoleDataServiceFactory)
    .factory('sapSdSimRoleDataService', sapSdSimRoleDataServiceFactory)
    .factory('sapSdSimAnalysisDataService', sapSdSimRiskAnalysisDataServiceFactory)
    .name;