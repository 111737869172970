export default /*@ngInject*/ function BuildResponsibilityAccessPostBody(helperService) {
    const factory = {
        generateBody
    };

    return factory;

    function generateBody(ObjectId, responsibilities, securityObjectType, securityObject) {

        var selectedResponsibilities;

        var result = helperService.returnNullIfAllIsFound(responsibilities.map(resp => resp.ID));
        if (result) {
            selectedResponsibilities = responsibilities.map(resp => resp.Identifier);
        } else {
            selectedResponsibilities = null;
        }

        var data = {
            "ObjectId": ObjectId,
            "Roles": selectedResponsibilities,
            "SecurityObjects": [securityObject.Identifier],
            "SecurityObjectTypes": [securityObjectType.Identifier]
        };

        if (securityObject.Id === null) {
            data.SecurityObjects = null;
        }

        return data;
    }
}