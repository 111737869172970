/* myInbox.component.js */

import myInboxController from "./myInbox.controller";


/**
 * @desc component for managing / viewing inbox
 * @example <my-inbox></my-inbox>
 */

const myInbox = {

    controller: myInboxController,
    controllerAs: 'mivm',
    templateUrl: '/App/Components/MyInbox/myInboxView.html'
};

export default myInbox;