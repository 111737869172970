import roleTemplatesListController from "./roleTemplatesList.controller";

/* roleTemplatesList.component.js */

/**
 * @desc component for displaying and selecting role templates in a list
 * @example <role-templates-list></role-templates-list>
 */

const roleTemplatesList = {
    bindings: {
        template: '='
    },
    controller: roleTemplatesListController,
    controllerAs: 'rtlvm',
    templateUrl: '/App/Components/RoleTemplates/RoleTemplatesList/roleTemplatesList.tmpl.html'
};

export default roleTemplatesList;