import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (helperService, connectionsDataService, manageDataDataService, sapSdSimulationDataService) {
    var vm = this;

    vm.$onInit = init;

    vm.updateSAPSdConnectionSettings = updateSAPSdConnectionSettings;
    vm.updateConfig = updateConfig;
    vm.configValuesHaveChanged = configValuesHaveChanged;
    vm.productValuesHaveChanged = productValuesHaveChanged;
    vm.sdConfigValuesHaveChanged = sdConfigValuesHaveChanged;
    vm.submitProduct = submitProduct;
    vm.updateSapProduct = updateSapProduct;

    async function init() {
        const navigationList = JSON.parse(localStorage.getItem("NavigationObjects"));

        vm.showSAPSdConnectionSettings = false;
        vm.originalProduct = Object.assign({}, vm.product);
        vm.configNames = vm.product.Config.map(item => item.Name);

        await getConfigByProductValues();

        if (navigationList.some(navItem => navItem.ObjectID === 14000)) {
            vm.showSAPSdConnectionSettings = true;
            await getSAPSdConnectionSettings(vm.product.ID);
        }
    }

   async function submitProduct() {
        vm.updatePending = new createSpinner();

        await vm.updateSapProduct(vm.product);
        await updateConfig(vm.product.Config, vm.currentConfig, vm.originalConfig);
    };

    async function updateSapProduct(product) {
        var data = {
            "AppServerHost": product.AppServerHost,
            "SystemNumber": product.SystemNumber,
            "SystemID": product.SystemID,
            "User": product.User,
            "Password": product.Password,
            "Client": product.Client,
            "Language": product.Language,
            "PoolSize": product.PoolSize,
            "ProductId": product.ID
        };

        vm.updatePending = new createSpinner();

        //Update Connection
        if (product.ConnectionExists === true) {
            try {
                await connectionsDataService.updateSapProduct(product.ID, data)
                helperService.successfulSaveButton(vm.updatePending);
            } catch {
                vm.updatePending.loadingValue = false;
            }
        }
        else {
            try {
                await connectionsDataService.createSapProduct(product.ID, data);
                product.ConnectionExists = true;
                helperService.successfulSaveButton(vm.updatePending);
            } catch {
                vm.updatePending.loadingValue = false;
            }
        }
    };

    async function getConfigByProductValues() {
        let response = await manageDataDataService.getConfigurationByProductId(vm.product.ID),
            config = {};
        response.data.forEach(row => {
            config[row.Name] = row.Value;
        });

        vm.currentConfig = JSON.parse(JSON.stringify(config));
        vm.originalConfig = JSON.parse(JSON.stringify(config));
    }

    async function getSAPSdConnectionSettings(productId) {
        let response;
        
        try {
            response = await sapSdSimulationDataService.getIntegrationSettings(productId);
            vm.sapSDConfig = response.data;
            vm.origSapSDConfig = Object.assign({}, response.data);
        } catch {
            vm.sapSDConfig = {};
            vm.origSapSDConfig = {};
        }
    }

    async function updateSAPSdConnectionSettings() {
        vm.updateSdPending = new createSpinner();
        let data = { ...vm.sapSDConfig };

        data.SystemId = data.SystemID;
        data.ProductId = vm.product.ID;

        delete data.SystemID;

        try {
            await sapSdSimulationDataService.updateIntegrationSettings(vm.product.ID, data);
            helperService.successfulSaveButton(vm.updateSdPending);
        } catch {
            vm.updateSdPending.loadingValue = false;
        }
    }

    // update Configuration
    async function updateConfig(list, value, origVal) {
        let productPutData = {
            "Configurations": [],
            "ProductId": vm.product.ID
        };

        if (JSON.stringify(value) !== JSON.stringify(origVal)) {
            list.forEach(item => {
                if (!origVal || value[item.Name] !== origVal[item.Name]) {
                    productPutData.Configurations.push({
                        "Name": item.Name,
                        "Value": value[item.Name]
                    });
                }
            });
            vm.updatePending = new createSpinner();
            try {
                await manageDataDataService.setProductConfiguration(vm.product.ID, productPutData);
                helperService.successfulSaveButton(vm.updatePending);
                await getConfigByProductValues();
            } catch {
                vm.updatePending.loadingValue = false;
            }
        }
    }

    function configValuesHaveChanged() {
        return helperService.returnTrueIfValuesDiffer(vm.configNames, vm.originalConfig, vm.currentConfig);
    }

    function productValuesHaveChanged() {
        return !angular.equals(vm.originalProduct, vm.product);
    }

    function sdConfigValuesHaveChanged() {
        return !angular.equals(vm.sapSDConfig, vm.origSapSDConfig);
    }
}

