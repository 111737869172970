export default /*@ngInject*/ function ($scope) {

    //If $scope variable doesnt exist in child it looks in parent

    $scope.addRoleToList = function (role) {
        $scope.vm.ListOfAvailablePSRoles.forEach(function (row, i) {
            if (row.Name === role.Name) {
                $scope.vm.ListOfAvailablePSRoles.splice(i, 1);
                $scope.vm.ListOfSelectedPSRoles.push(role);
            }
        });
    };

    $scope.deleteSelectedRole = function (role) {
        $scope.vm.ListOfSelectedPSRoles.forEach(function (row, i) {
            if (row.Name === role.Name) {
                $scope.vm.ListOfSelectedPSRoles.splice(i, 1);
                if (role.IsAssignable) {
                    $scope.vm.ListOfAvailablePSRoles.push(row);
                }
            }
        });
    };

    $scope.selectedRole = null;

    $scope.setSelectedRoleTemplates = function (selectedRoleTemplates) {
        $scope.vm.selectedRoleTemplates = selectedRoleTemplates;
    };
}