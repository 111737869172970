import createSpinner from "../../Functions/createSpinner";

export default /*@ngInject*/ function (caseId, $uibModalInstance, controlLibraryDataService, FileSaver) {

    const vm = this;

    vm.dismiss = dismiss;
    vm.downloadAttachment = downloadAttachment;
    vm.getFileTypeImage = getFileTypeImage;

    activate();

    async function activate() {
        vm.attachmentsList = await getAttachmentsInformation(caseId);
    }

    async function getAttachmentsInformation(caseId) {
        vm.callPending = true;
        const { data: attachmentsList } = await controlLibraryDataService.getAttachmentsList(caseId);
        vm.callPending = false;
        return attachmentsList;
    }

    async function downloadAttachment(attachment) {
        let response;
        
        try {
            vm.downloadPending = new createSpinner();
            response = await controlLibraryDataService.downloadAttachment(attachment.Identifier);

            FileSaver.saveAs(response.data, attachment.FileName);
            vm.downloadPending.loadingValue = false;
        } catch {
            vm.downloadPending.loadingValue = false;
        }
    }

    function getFileTypeImage(fileType) {
        switch (fileType) {
            case 'xlsx': //Excel
                return 'excel-file-icon-16';
                break;
            case 'pdf': //PDF 
                return 'pdf-file-icon-16';
                break;
            case 'csv': //Csv
                return 'csv-file-icon-16';
                break;
            default: 
                return 'default-file-icon-64';
                break;
        }
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }
}