export default /*@ngInject*/ function ($scope) {

    //If $scope variable doesnt exist in child it looks in parent

    $scope.addUserGroupToList = addUserGroupToList;
    $scope.deleteSelectedUserGroup = deleteSelectedUserGroup;

    function addUserGroupToList(userGroup) {
        $scope.vm.ListOfAvailableAX7UserGroups.forEach((row, i) => {
            if (row.Id === userGroup.Id) {
                $scope.vm.ListOfAvailableAX7UserGroups.splice(i, 1);
                $scope.vm.ListOfSelectedAX7UserGroups.push(userGroup);
            }
        });
    };


    function deleteSelectedUserGroup(userGroup) {
        $scope.vm.ListOfSelectedAX7UserGroups.forEach((row, i) => {
            if (row.Id === userGroup.Id) {
                $scope.vm.ListOfSelectedAX7UserGroups.splice(i, 1);
                $scope.vm.ListOfAvailableAX7UserGroups.push(row);
            }
        });
    };

}