/* maintainObjectTrackingGrid.component.js */

import maintainObjectTrackingGridController from "./maintainObjectTrackingGrid.controller";

/**
 * @desc component to enable/disable tracking of objects
 * @example <maintain-object-tracking-grid></maintain-object-tracking-grid>
 */

const maintainObjectTrackingGrid = {
    controller: maintainObjectTrackingGridController,
    controllerAs: 'motgvm',
    templateUrl: '/App/Components/salesForceAuditTrail/maintainObjectTracking/maintainObjectTrackingGrid/maintainObjectTrackingGrid.tmpl.html'
};

export default maintainObjectTrackingGrid;  