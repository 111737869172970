export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getUsers,
        getRecordTypes,
        nsDataChangesReport
    };

    return service;

    async function getUsers() {
        try {
            return await $http.get(`${apiUrl}api/audittrail/ns/places/datachanges/users`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getRecordTypes() {
        try {
            return await $http.get(`${apiUrl}api/audittrail/recordtypes`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function nsDataChangesReport(data, params) {
        try {
            return await $http({
                method: 'POST',
                url: `${apiUrl}api/audittrail/ns/places/datachanges`,
                data: data,
                params: params
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}