import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, $http, supervisorAssignmentsDataService, mapping, availableProducts, allProducts, mappingType, helperService) {

    const vm = this;

    vm.productsDataSource = [...availableProducts];
    vm.mapping = { ...mapping };
    vm.mappingType = mappingType;

    vm.selectedProductChanged = selectedProductChanged;
    vm.saveAssignmentMapping = saveAssignmentMapping;
    vm.cancel = cancel;

    activate();

    function activate() {
        if (vm.mapping.Id && vm.mapping.ProductId !== null) {
            addCurrentProductToAvailableProducts();
        }
        getHcmMappingFields();
        setupSystemMappingField();
    }

    function addCurrentProductToAvailableProducts() {
        const productToAdd = allProducts.find(product => product.ID === vm.mapping.ProductId);
        vm.productsDataSource.unshift(productToAdd);
        vm.mapping.Product = vm.productsDataSource[0];
    }

    async function getHcmMappingFields() {
        vm.pluginMappingFieldDropdownLoading = true;
        await findHCMPlugin();
        try {
            let response = await supervisorAssignmentsDataService.getPluginMappingFields(vm.hcmPlugin.Id);
            vm.hcmMappingFieldsDataSource = response.data;
            vm.pluginMappingFieldDropdownLoading = false;
        } catch (_) {
            vm.pluginMappingFieldDropdownLoading = false;
        }
    }

    function setupSystemMappingField() {
        if (vm.mapping.ProductId === null || mappingType === 'Fastpath Supervisor') {
            getSystemMappingFields();
        } else {
            getProductMappingFields();
        }
    }

    async function findHCMPlugin() {
        let response = await $http.get(`${apiUrl}/api/core/plugins`);
        vm.hcmPlugin = response.data.find(plugin => plugin.HcmPlugin);
    }

    function selectedProductChanged() {
        if (vm.systemMappingFieldsDataSource) {
            vm.systemMappingFieldsDataSource = null;
        }
        getProductMappingFields();
    }

    async function getProductMappingFields() {
        vm.systemMappingFieldDropdownLoading = true;
        try {
            let productResponse = await supervisorAssignmentsDataService.getProductMappingFields(vm.mapping.Product.Type.toLowerCase());
            vm.systemMappingFieldsDataSource = productResponse.data;
            let universalReponse = await supervisorAssignmentsDataService.getUniversalMappingFields();
            vm.systemMappingFieldsDataSource = [...vm.systemMappingFieldsDataSource, ...universalReponse.data];
            vm.systemMappingFieldDropdownLoading = false;
        } catch (_) {
            vm.systemMappingFieldDropdownLoading = false;
        }
    }

    async function getSystemMappingFields() {
        vm.systemMappingFieldDropdownLoading = true;
        try {
            let response = await supervisorAssignmentsDataService.getInternalMappingFields();
            vm.systemMappingFieldsDataSource = response.data;
            vm.systemMappingFieldDropdownLoading = false;
        } catch (_) {
            vm.systemMappingFieldDropdownLoading = false;
        }
    }

    async function saveAssignmentMapping() {
        vm.saveMappingPending = new createSpinner();

        const data = {
            Id: vm.mapping.Id,
            PluginMappingFieldId: vm.mapping.PluginMappingField.Id,
            SystemMappingFieldId: vm.mapping.SystemMappingField.Id,
            ProductId: vm.mapping.Product ? vm.mapping.Product.ID : null
        };

        try {
            if (vm.mapping.Id) {
                await supervisorAssignmentsDataService.updateAutoSupervisorAssignmentMapping(vm.mapping.Id, data);
            } else {
                await supervisorAssignmentsDataService.createAutoSupervisorAssignmentMapping(data);
            }
            await helperService.successfulSaveButton(vm.saveMappingPending);
            close();
        } catch (error) {
            vm.saveMappingPending.loadingValue = false;
        }
    }

    function close() {
        $uibModalInstance.close();
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }

}