export default /*@ngInject*/ function (objectIdDataService, helperService, dataService, $location) {

    const vm = this;

    vm.helperService = helperService;
    vm.favorited = {};
    vm.helpObject = {
        domain: helpUrl,
        specificArticle: "hc/en-us/articles/",
        home: "hc/en-us"
    };

    vm.$onInit = () => activate();

    function activate() {
        objectIdDataService.getObjectID().then(objectId => {
            vm.ObjectId = objectId;

            const securityObject = helperService.getSecurityObjectDetailsFromObjectId(vm.ObjectId);
            vm.pageInfo = securityObject;
            vm.pageDescription = securityObject.HelpLink;
            vm.reportName = securityObject.Name;
        });

        getFavorites();
    }

    async function getFavorites() {
        let favorites = await dataService.getFavorites();
        let favorited = favorites.data.find(f => f.Url === $location.url());

        if (favorited) vm.favorited.value = true;
        else vm.favorited.value = false;
    }

}