/* maintainSystemUsersGrid.component.js */

import maintainSystemUsersGridController from "./maintainSystemUsersGrid.controller";

/**
 * @desc component to display grid to view, import and export System Users
 * @example <maintain-system-users-grid></maintain-system-users-grid>
 */

const maintainSystemUsersGrid = {
    controller: maintainSystemUsersGridController,
    controllerAs: 'msugvm',
    template: `<div class="row wrapper-content">
    <div class="col-lg-12">
        <div class="ibox custom-ibox">
            <div class="ibox-title full-span-ibox flex-between flex-vcenter">
                <div>
                    <h5>System Users</h5>
                </div>
                <div class="pull-right">
                    <span ng-if="!msugvm.exportUpdatePending.loadingValue">
                        <button ng-if="$root.UserSecurityList['6401']" ng-click="msugvm.generateAssignments()" class="btn btn-secondary">
                            Generate User Mappings
                        </button>
                        <button class="btn btn-secondary" ng-click="msugvm.openEditImportModal(null, 'Import')">
                            Import
                        </button>
                        <button class="btn btn-secondary" ng-click="msugvm.exportSystemUsers()">
                            Export
                        </button>
                    </span>
                    <span>
                        <inline-saver successtext="Export Complete" customcolor="smallWhite" ng-model="msugvm.exportUpdatePending"></inline-saver>
                    </span>
                </div>
            </div>
            <div class="ibox-content">
                <div kendo-grid="msugvm.systemUsersGrid"
                     k-options="msugvm.systemUsersGridOptions"
                     k-data-source="msugvm.systemUsersGridDataSource">
                </div>
            </div>
        </div>
    </div>
</div>`
};

export default maintainSystemUsersGrid;