import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, taskRecordingDataService, productType, taskRecording) {

    const vm = this;

    vm.oldName = taskRecording.Name;
    vm.name = taskRecording.Name;
    vm.cancel = cancel;
    vm.renameTaskRecording = renameTaskRecording;

    async function renameTaskRecording() {

        vm.pleaseWait = new createSpinner();

        let data = {
            "Id": taskRecording.Id,
            "Name": vm.name
        };

        try {
            await taskRecordingDataService.renameTaskRecording(productType, taskRecording.Id, data);
            vm.killModal = true;
            vm.pleaseWait.loadingValue = false;
            await helperService.successfulSaveButton(vm.pleaseWait);
            $uibModalInstance.close();
        } catch {
            vm.disableSubmit = false;
            vm.pleaseWait.loadingValue = false;
        }

    }


    function cancel() { $uibModalInstance.dismiss(); }

}