export default /*@ngInject*/ function (helperService) {

    const factory = {
        generateBody
    };

    return factory;

    function generateBody (ObjectId, responsibilities, risks, individual, ruleset) {

        var selectedResponsibilities;

        var result = helperService.returnNullIfAllIsFound(responsibilities.map(resp => resp.ID));
        if (result) {
            selectedResponsibilities = responsibilities.map(resp => resp.Identifier);
        } else {
            selectedResponsibilities = null;
        }

        var data = {
            "ObjectId": ObjectId,
            "RoleIds": selectedResponsibilities,
            "RiskIds": risks,
            "Individual": individual,
            "RulesetId": ruleset
        };

        return data;
    }

}