import IMRequestCustomTicketModalController from "./WizardSteps/Universal/linkTickets/IMRequestCustomTicketModal.controller";
import IMRequestZendeskTicketModalController from "./WizardSteps/Universal/linkTickets/IMRequestZendeskTicketModal.controller";
import IMRequestServiceNowTicketModalController from "./WizardSteps/Universal/linkTickets/ServiceNow/IMRequestServiceNowTicketModal.controller";
import IMRequestJiraTicketModalController from "./WizardSteps/Universal/linkTickets/IMRequestJiraTicketModal.controller";
import createSpinner from "../../Shared/Functions/createSpinner";
import { ax7_application_id, netsuite_application_id, sap_application_id, ax_application_id, oracle_application_id, oracleCloud_application_id, gp_application_id, nav_application_id, d365s_application_id, universalProduct_application_id, aad_application_id, ps_application_id, salesforce_application_id } from "../../Shared/GlobalVariables/applicationIdVariables";
import IMRequestFreshServiceTicketModalController from "./WizardSteps/Universal/linkTickets/IMRequestFreshServiceTicketModal.controller";
import swal from "sweetalert2";

export default /*@ngInject*/ function ($scope, $uibModal, $filter, RequestManagementShowStepsService, RequestManagementStepDefinitions, RequestManagementWizardController, RequestManagementUPLoadDataService, RequestManagementAXLoadDataService, RequestManagementAADLoadDataService, RequestManagementSAPLoadDataService, RequestManagementNetSuiteLoadDataService, RequestManagementOracleLoadDataService, RequestManagementNAVLoadDataService, RequestManagementGPLoadDataService, RequestManagementD365SLoadDataService, RequestManagementPSLoadDataService, RequestManagementBuildRequestService, RequestManagementEffectiveDateService, RequestManagementLoadDataService, controlLibraryDataService, FileSaver, WizardHandler, $rootScope, objectIdDataService, $http, $q, $location, helperService, RequestManagementOracleCloudLoadDataService, salesforceLoadData, passwordCriteriaDataService, manageDataDataService, configurationDataService) {

    //Page initialization
    var vm = $scope.vm = {},
        salesforceEmailMessage = "To ensure system security, the owner of the new email address must verify this change. When you click OK, an email will be sent to the specified address with directions on confirming this change.  Click Cancel if you do not want to make this change."

    $scope.vmh = helperService;
    $scope.helperService = helperService;
    $scope.updatePending = {};
    $scope.UserMitigations = [];
    vm.existingFilesDataSource = [];
    vm.AccessChanges = [];
    vm.hasCompany = false;
    $scope.hasAX = false;
    vm.RequestNotes = '';
    var locationPath = $location.path().split("/");
    vm.wizardMode = locationPath[4];

    $scope.requireTickets = false;
    $scope.disableObjectLevelRiskAnalysis = false;


    //Common Functions - duplicate functions on each path
    objectIdDataService.getObjectID().then(function (objectId) {
        $scope.ObjectId = objectId;

        var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;

    });

    activate();

    async function activate() {
        var { data: config } = await configurationDataService.getConfigurationValues()
        vm.config = config;
        $scope.requireTickets = config.some(item => item.Name === "IM_RequireTicketAssociation" && item?.Value === "1");
        vm.bypassRiskAnalysis = config.some(item => item.Name === "IM_BypassRiskAnalysis" && item?.Value === "1");
        $scope.disableObjectLevelRiskAnalysis = config.some(item => item.Name === "IM_DisableObjectLevelRiskAnalysis" && item?.Value === "1");
        vm.roleTemplatesOnlyEnabled = config.some(item => item.Name === "IM_RoleTemplatesOnlyEnabled" && item?.Value === "1");

        securityCheck();
        getApplications();
    }

    function securityCheck() {
        let security = JSON.parse(localStorage.getItem('UserSecurityObjects'));

        if ($location.url().includes('selfservice=true')) {
            if (security.find(o => o.ObjectID === 11405)) vm.alternatePath = 'SelfService';
            else $location.path('/403');
        } else if ($location.url().includes('emergencyaccess=true')) {
            if (security.find(o => o.ObjectID === 11406)) vm.alternatePath = 'EmergencyAccess';
        } else {
            if (security.find(o => o.ObjectID === 11403)) return;
            else $location.path('/403');
        }
    }

    async function calculateExpirationDate() {
        let numberOfDaysToAdd = Number(vm.config.find(configuration => configuration.Name === 'IM_DefaultExpirationDays').Value);

        if (numberOfDaysToAdd) {
            vm.ExpirationDate = new Date();
            vm.ExpirationDate.setDate(vm.ExpirationDate.getDate() + numberOfDaysToAdd);
        }
    }

    async function getAdditionalRequestDetails() {
        const response = await $http.get(`${apiUrl}api/identitymanager/requests/${$scope.requestToEdit.Id}/version/${$scope.requestToEdit.Version}`);
        vm.existingFilesDataSource = response.data.Attachments;
        $scope.vm.ExpirationDate = response.data.ExpirationDate ? new Date(response.data.ExpirationDate) : null;
    }

    $scope.getApplicationName = (applicationId) => $scope.vmh.getApplicationName(applicationId, vm.availableApplications);

    $scope.getProductName = (productId) => $scope.vmh.getProductName(productId, vm.availableApplications);
    //--End Common Page Functions

    async function getAX7UserGroupConfigValue() {
        let response = await manageDataDataService.getConfigurationByProductId('AX7');
        const ax7UserGroupConfigValue = response.data.find(config => config.Name === 'IM_EnableUserGroups');

        vm.EnableAX7UserGroups = ax7UserGroupConfigValue.Value === '1' ? true : false;
    }

    $scope.resetUserValues = function () {
        $scope.requestUserList = [];
        $scope.vm.effectiveDateList = [];

        vm.RiskLevelId = 0;
        vm.RiskLevelIdNewOnly = 0;

        //Universal Product Access variables
        $scope.vm.ListOfSelectedUPRoles = [];
        $scope.vm.ListOfAvailableUPRoles = [];
        $scope.vm.ListOfUPCompanies = [];

        //SAP Access variables
        $scope.vm.ListOfSelectedSAPSingleRoles = [];
        $scope.vm.ListOfAvailableSAPSingleRoles = [];
        $scope.vm.ListOfSelectedSAPRoleObjects = [];
        $scope.vm.ListOfAvailableSAPRoleObjects = [];

        $scope.vm.ListOfSelectedSAPCompositeRoles = [];
        $scope.vm.ListOfAvailableSAPCompositeRoles = [];

        //AAD Access variables
        vm.ListOfSelectedAADRoles = [];
        vm.ListOfAvailableAADRoles = [];
        vm.ListOfSelectedAADGroups = [];
        vm.ListOfAvailableAADGroups = [];
        vm.ListOfSelectedAADLicenses = [];
        vm.ListOfAvailableAADLicenses = [];

        //AX7 Access variables
        vm.ListOfSelectedAX7Roles = [];
        vm.ListOfExcludedAX7Roles = [];
        vm.ListOfAvailableAX7Roles = [];
        vm.ListOfAX7Companies = [];
        vm.AX7RoleCompanyList = [];
        vm.ListOfAvailableAX7UserGroups = [];
        vm.ListOfSelectedAX7UserGroups = [];

        //AX2012 Access variables
        vm.ListOfSelectedAX2012Roles = [];
        vm.ListOfExcludedAX2012Roles = [];
        vm.ListOfAvailableAX2012Roles = [];
        vm.ListOfAX2012Companies = [];
        vm.AX2012RoleCompanyList = [];
        vm.ListOfAX2012Partitions = [];
        vm.ListOfAvailableAX2012UserGroups = [];
        vm.ListOfSelectedAX2012UserGroups = [];

        //NetSuite Access varibles
        vm.ListOfSelectedNetSuiteRoles = [];
        vm.ListOfAvailableNetSuiteRoles = [];
        vm.ListOfSelectedNetSuitePermissions = [];
        vm.ListOfAvailableNetSuitePermissions = [];
        vm.existingGlobalPermissions = [];

        //Oracle Responsibility variables
        vm.ListOfAvailableOracleResponsibilities = [];
        vm.ListOfSelectedOracleResponsibilities = [];
        vm.ListOfAvailableOracleRoles = [];
        vm.ListOfSelectedOracleRoles = [];

        // Oracle Cloud roles variables
        vm.ListOfAvailableOracleCloudRoles = [];
        vm.ListOfSelectedOracleCloudRoles = [];
        vm.ListOfStoredOracleCloudRoles = [];

        // PeopleSoft roles variables
        vm.ListOfAvailablePSRoles = [];
        vm.ListOfSelectedPSRoles = [];

        //NAV Access varibles
        vm.ListOfSelectedNAVRoles = [];
        vm.ListOfAvailableNAVRoles = [];
        vm.ListOfAvailableNAVPermissionSets = [];
        vm.ListOfSelectedNAVPermissionSets = [];
        vm.ListOfSelectedNAVUserGroups = [];
        vm.ListOfAvailableNAVUserGroups = [];

        //GP Access varibles
        vm.ListOfSelectedGPRoles = [];
        vm.ListOfAvailableGPRoles = [];
        vm.ListOfStoredGPRoles = [];
        vm.ListOfSelectedGPCompanies = [];
        vm.ListOfAvailableGPCompanies = [];

        //D365S Access varibles
        vm.ListOfSelectedD365SRoles = [];
        vm.ListOfAvailableD365SRoles = [];
        vm.ListOfSelectedD365STeams = [];
        vm.ListOfAvailableD365STeams = [];

        //Salesforce Access Variables
        vm.ListOfAvailableSalesforcePermissionSets = [];
        vm.ListOfSelectedSalesforcePermissionSets = [];

        $scope.vm.User = {};
    };

    $scope.resetUserValues();

    function getApplications() {
        $http.get(apiUrl + 'api/identitymanager/applications').then(function (response) {

            response.data.forEach(row => {
                row.selected = false;
            });
            if (vm.alternatePath === 'EmergencyAccess') {
                response.data = response.data.filter(app => app.ProductType === 'SAP');
            }
            response.data = $filter('orderBy')(response.data, 'ProductName');
            $scope.vm.availableApplications = response.data;
            $scope.atLeastOneAppSelected();

            //If the user is modifying an open request load the data after the available applications have populated
            if ($rootScope.requestToEdit) {
                RequestManagementLoadDataService.loadOpenRequestData($scope);
            }

            //Check if custom apps exist
            var existingSteps = RequestManagementStepDefinitions.getListOfSteps(vm.wizardMode, vm.alternatePath, $scope.requestToEdit);
            $scope.vm.availableApplications.forEach(application => {
                $scope.vm.ListOfSelectedSAPSingleRoles[application.ProductName] = [];
                $scope.vm.ListOfSelectedSAPCompositeRoles[application.ProductName] = [];
                $scope.vm.ListOfSelectedUPRoles[application.ProductName] = [];
                if (!existingSteps.some(step => step.parent && step.parent === application.ProductName)) {
                    $scope.steps = RequestManagementShowStepsService.createCustomSteps(application, existingSteps);
                }
            });
            if (!$scope.steps) {
                $scope.steps = RequestManagementStepDefinitions.getListOfSteps(vm.wizardMode, vm.alternatePath, $scope.requestToEdit);
            }

            // Remove Risk Analysis Step if Configuration is enabled
            if ($scope.vm.bypassRiskAnalysis) {
                const riskAnalysisStepIndex = $scope.steps.findIndex(step => step.title === 'Risk Analysis');
                $scope.steps.splice(riskAnalysisStepIndex, 1);
            }

            if (vm.alternatePath) setupAlternatePath();
        });
    }

    async function setupAlternatePath() {
        await $scope.buildRequest(null, "Select Applications");
    }

    //Select Application Functions
    vm.appSelectedChange = async function (application) {
        if (application.selected) {
            if (application.Id === netsuite_application_id) {
                RequestManagementNetSuiteLoadDataService.loadNetSuite($scope, null, null, null);
            }

            if (application.Id === sap_application_id) {
                RequestManagementSAPLoadDataService.loadSAP($scope, application, null);
                //Load SAP Password Criteria
                passwordCriteriaDataService.getPasswordCriteria(application.ProductID).then(function (response) {
                    $scope.vm.sapPasswordCriteria = {};
                    $scope.vm.sapPasswordCriteria[application.ProductName] = response.data;
                });
            }

            if (application.Id == aad_application_id) {
                RequestManagementAADLoadDataService.loadAAD($scope, null);
                //Load AAD Password Criteria
                passwordCriteriaDataService.getPasswordCriteria(application.ProductID).then(function (response) {
                    $scope.vm.aadPasswordCriteria = {};
                    $scope.vm.aadPasswordCriteria = response.data;
                });


            }

            if (application.Id == ps_application_id) {
                RequestManagementPSLoadDataService.loadPS($scope, null);
                //Load PS Password Criteria
                passwordCriteriaDataService.getPasswordCriteria(application.ProductID).then(function (response) {
                    $scope.vm.psPasswordCriteria = {};
                    $scope.vm.psPasswordCriteria = response.data;
                }).catch(function (response) {
                    $scope.vm.psPasswordCriteria = {};
                });
            }

            if (application.Id === ax7_application_id) {
                RequestManagementAXLoadDataService.loadAX7($scope, null);
            }

            if (application.Id === ax_application_id) {
                RequestManagementAXLoadDataService.loadAX2012($scope, null);
            }

            if (application.Id === oracle_application_id) {
                await RequestManagementOracleLoadDataService.loadOracle($scope, null);
            }

            if (application.Id === oracleCloud_application_id) {
                RequestManagementOracleCloudLoadDataService.loadOracleCloud($scope, null);
            }

            if (application.Id === gp_application_id) {
                RequestManagementGPLoadDataService.loadGP($scope, null);
            }

            if (application.Id === nav_application_id) {
                RequestManagementNAVLoadDataService.loadNAV($scope, null);
            }

            if (application.Id === d365s_application_id) {
                RequestManagementD365SLoadDataService.loadD365S($scope, null);
            }

            if (application.Id === universalProduct_application_id) {
                RequestManagementUPLoadDataService.loadUP($scope, application, null);
            }

            if (application.Id === salesforce_application_id) {
                salesforceLoadData.loadSalesforce($scope);
            }
        }

        $scope.atLeastOneAppSelected();

        $scope.loadERPSpecificSteps(application);
    };

    $scope.loadERPSpecificSteps = async function (application) {
        switch (application.ProductType) {
            case 'OR':
                if (!vm.ORRBACEnabled) {
                    const rolesStepIndex = $scope.steps.findIndex(step => step.title === 'Oracle EBS Roles');
                    if (rolesStepIndex > -1) {
                        $scope.steps.splice(rolesStepIndex, 1);
                    }
                }
                RequestManagementShowStepsService.setOracleStepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'SAP':
                if (application.ProductName === 'SAP') {
                    RequestManagementShowStepsService.setSAPStepsToVisible(vm.availableApplications, $scope.steps);
                } else {
                    RequestManagementShowStepsService.setCustomStepsToVisible(vm.availableApplications, $scope.steps, application);
                }
                break;
            case 'NS':
                RequestManagementShowStepsService.setNetSuiteStepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'AAD':
                RequestManagementShowStepsService.setAADStepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'AX7':
                await getAX7UserGroupConfigValue();
                if (!vm.EnableAX7UserGroups) {
                    const userGroupsStepIndex = $scope.steps.findIndex(step => step.title === 'Dynamics 365 for Finance and Operations User Groups');
                    if (userGroupsStepIndex > -1) {
                        $scope.steps.splice(userGroupsStepIndex, 1);
                    }
                }
                RequestManagementShowStepsService.setAX7StepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'AX':
                RequestManagementShowStepsService.setAX2012StepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'GP':
                RequestManagementShowStepsService.setGPStepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'NAV':
                RequestManagementShowStepsService.setNAVStepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'D365S':
                RequestManagementShowStepsService.setD365SStepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'PS':
                RequestManagementShowStepsService.setPSStepsToVisible(vm.availableApplications, $scope.steps);
                break;
            case 'SF':
                if ($scope.vm?.User?.SalesforceUser.IsActive) {
                    RequestManagementShowStepsService.setSalesforceStepsToVisible(vm.availableApplications, $scope.steps);
                    application.inactiveUser = false;
                } else {
                    application.selected = false;
                    application.inactiveUser = true;
                }

                break;
            default:
                RequestManagementShowStepsService.setCustomStepsToVisible(vm.availableApplications, $scope.steps, application);
                break;
        }
    };

    $scope.atLeastOneAppSelected = function () {
        vm.validAppsSelected = RequestManagementWizardController.atLeastOneAppSelected(vm.availableApplications);
    };
    //--End Select Application events


    $scope.stepDisabled = function (title) {
        return RequestManagementWizardController.stepDisabled(title, WizardHandler.wizard(), $scope);
    };

    $scope.setParentStepToActive = function (parent) {
        return RequestManagementWizardController.setParentStepToActive(parent, WizardHandler.wizard(), $scope.steps);
    };

    $scope.goToNextVisiblePage = function () {
        var step = RequestManagementWizardController.goToNextVisiblePage($scope.steps, WizardHandler.wizard());
        $scope.setActivePage(step.title);
    };

    $scope.goToPreviousVisiblePage = function () {
        var step = RequestManagementWizardController.goToPreviousVisiblePage($scope.steps, WizardHandler.wizard());
        $scope.setActivePage(step.title);
    };

    $scope.setCurrentStepToActive = function () {
        return RequestManagementWizardController.getCurrentStepsTitle(WizardHandler.wizard());
    };

    $scope.setActivePage = function (stepTitle) {
        var wizardInstance = WizardHandler.wizard();
        var currentStepNumber = wizardInstance.currentStepNumber();
        var enabledSteps = wizardInstance.getEnabledSteps();
        var step = enabledSteps[currentStepNumber - 1];
        if (step.canexit === true) {
            $scope.buildRequest(step.title, stepTitle);
        }
        wizardInstance.goTo(stepTitle);
    };
    //---End of Wizard Navigation

    $scope.buildRequest = async function (fromStep, toStep) {

        $scope.$broadcast('broadcast-im-wizardstepchange', { fromStep: fromStep, toStep: toStep });

        if (toStep === "Select Applications") {

            $scope.selectApplicationsHelpText = 'The following is a list of applications available to this user. Select the application(s) you wish to modify';
            //Load data for an existing user
            if ($rootScope.IMSelectedExistingUser && !$rootScope.IMSelectedExistingUser.HasERPDataLoaded) {
                $scope.resetUserValues();

                $scope.showRMLoadingIcon = true;
                $scope.vm.wizardClass = "wizard-nav-container disabledDiv";

                var promises = [];
                RequestManagementLoadDataService.loadUserMasterERPData($scope, $rootScope.IMSelectedExistingUser, $scope.vm.availableApplications).then(function () {

                    vm.availableApplications.forEach(function (application) {
                        if (application.selected) {
                            if (application.Id === netsuite_application_id) {
                                var nsUser = null;
                                var subsidiaryId = null;

                                if ($scope.vm.User.NetSuiteUser) {
                                    nsUser = $scope.vm.User.NetSuiteUser;
                                    subsidiaryId = $scope.vm.User.NetSuiteUser.Subsidiary.Id;
                                }
                                promises.push(RequestManagementNetSuiteLoadDataService.loadNetSuite($scope, subsidiaryId, null));
                            }

                            if (application.Id === sap_application_id) {
                                promises.push(RequestManagementSAPLoadDataService.loadSAP($scope, application, null));
                            }

                            if (application.Id === universalProduct_application_id) {
                                promises.push(RequestManagementUPLoadDataService.loadUP($scope, application, null));
                            }

                            if (application.Id === aad_application_id) {
                                promises.push(RequestManagementAADLoadDataService.loadAAD($scope, null));
                            }

                            if (application.Id === ax7_application_id) {
                                promises.push(RequestManagementAXLoadDataService.loadAX7($scope, null));
                            }

                            if (application.Id === ax_application_id) {
                                promises.push(RequestManagementAXLoadDataService.loadAX2012($scope, null));
                            }

                            if (application.Id === oracle_application_id) {
                                promises.push(RequestManagementOracleLoadDataService.loadOracle($scope));
                            }

                            if (application.Id === oracleCloud_application_id) {
                                promises.push(RequestManagementOracleCloudLoadDataService.loadOracleCloud($scope));
                            }

                            if (application.Id === gp_application_id) {
                                promises.push(RequestManagementGPLoadDataService.loadGP($scope));
                            }

                            if (application.Id === nav_application_id) {
                                promises.push(RequestManagementNAVLoadDataService.loadNAV($scope));
                            }

                            if (application.Id === d365s_application_id) {
                                promises.push(RequestManagementD365SLoadDataService.loadD365S($scope));
                            }

                            if (application.Id === ps_application_id) {
                                promises.push(RequestManagementPSLoadDataService.loadPS($scope));
                            }

                            if (application.Id === salesforce_application_id) {
                                promises.push(salesforceLoadData.loadSalesforce($scope));
                            }

                            $scope.loadERPSpecificSteps(application);
                        }
                    });

                    $q.all(promises).then(function () {
                        //Check if an application is selected and load ERP steps
                        $scope.atLeastOneAppSelected();
                        $rootScope.IMSelectedExistingUser.HasERPDataLoaded = true;
                        $scope.showRMLoadingIcon = false;
                        $scope.vm.wizardClass = "wizard-nav-container";
                        if (fromStep === 'Search Existing Users' && $scope.vm.availableApplications.length === 1) {
                            $scope.goToNextVisiblePage();
                        }
                    }, function errorCallback(response) {
                        $scope.showRMLoadingIcon = false;
                        $scope.vm.wizardClass = "wizard-nav-container";
                        helperService.showErrorMessage(response);
                    });
                }, function errorCallback(response) {
                    $scope.showRMLoadingIcon = false;
                    $scope.vm.wizardClass = "wizard-nav-container";
                    helperService.showErrorMessage(response);
                }
                );

            }
        }
        vm.builtRequest = RequestManagementBuildRequestService.buildRequest(vm, false);

        if (toStep.includes('Emergency Access Usage')) {
            vm.availableApplications.forEach(function (application) {
                if (application.selected && application.ProductType === 'SAP' && (!vm.ListOfSelectedSAPRoleObjects[application.ProductName] || vm.ListOfSelectedSAPRoleObjects[application.ProductName].length === 0)) {
                    RequestManagementSAPLoadDataService.loadSAPRoleObjects($scope, application);
                }
            });
        }

        if (toStep === "Access Effective Dates" || toStep === "Summary") {

            $scope.loadingIcon = true;
            $scope.minimumExpirationDate = new Date();
            if (vm.config.find(config => config.Name === 'IM_DefaultExpirationEnabled').Value === '1') {
                calculateExpirationDate();
            }

            RequestManagementBuildRequestService.executeRequest(vm.builtRequest).then(function (response) {
                vm.ReturnedRequest = response.data;

                RequestManagementLoadDataService.loadRequestData(toStep, fromStep, vm, $scope, vm.ReturnedRequest).done(function () {
                    $scope.loadingIcon = false;
                });

                //Check if AX roles have been selected. Changes screens on Access Effective Dates and Summary page
                if (vm.ListOfSelectedAX7Roles && vm.ListOfSelectedAX7Roles.length > 0 || vm.ListOfSelectedAX2012Roles && vm.ListOfSelectedAX2012Roles.length > 0) {
                    $scope.hasAX = true;
                } else {
                    $scope.hasAX = false;
                }
            });

        }

        if (toStep === "Risk Analysis") {

            vm.buildReviewRisksDataSource(vm.builtRequest);
        }

    };

    $scope.loadEffectiveDateData = function (request) {
        vm.AccessChanges = RequestManagementLoadDataService.findAccessChanges(request, vm.AccessChanges);

        //Show the AX grid if the request contains AX
        if (vm.ListOfSelectedAX7Roles.length > 0 || vm.ListOfAvailableAX2012Roles.length > 0) {
            $scope.hasAX = true;
        } else {
            $scope.hasAX = false;
        }
    };
    vm.riskResults = null;

    vm.clearRiskAnalysisResults = function () {
        vm.riskResults = null;

        //Assign totals for summary grids
        $scope.ucExistingTotal = '';
        $scope.ucNewTotal = '';
        $scope.ucRemovedTotal = '';
        $scope.ucTotal = '';

        $scope.caGroupExistingTotal = '';
        $scope.caGroupNewTotal = '';
        $scope.caGroupRemovedTotal = '';
        $scope.caGroupTotal = '';

        $scope.caObjectExistingTotal = '';
        $scope.caObjectNewTotal = '';
        $scope.caObjectRemovedTotal = '';
        $scope.caObjectTotal = '';

        $scope.bpGroupExistingTotal = '';
        $scope.bpGroupNewTotal = '';
        $scope.bpGroupRemovedTotal = '';
        $scope.bpGroupTotal = '';

        $scope.bpObjectExistingTotal = '';
        $scope.bpObjectNewTotal = '';
        $scope.bpObjectRemovedTotal = '';
        $scope.bpObjectTotal = '';

        //Datasource for Risk Analysis User Risks grid
        $scope.UserRisksDataSource = [];
        $scope.BusinessProcessDataSource = [];
    };

    //Review Risks
    vm.buildReviewRisksDataSource = function (request) {
        $scope.showReviewRisksLoadingIcon = true;

        vm.clearRiskAnalysisResults();

        $http({
            method: 'POST',
            url: apiUrl + 'api/identitymanager/requests/riskanalysis/v2',
            data: request
        }).then(function (response) {

            vm.riskResults = response.data;


            $scope.showSummary = true;
            $scope.showUserRiskDetails = false;
            $scope.showBusinessProcessDetails = false;

            //Assign totals for summary grids
            $scope.ucExistingTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.UserRiskAnalysis.CountByComparison, 'Existing');
            $scope.ucNewTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.UserRiskAnalysis.CountByComparison, 'Added');
            $scope.ucRemovedTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.UserRiskAnalysis.CountByComparison, 'Deleted');
            $scope.ucTotal = $scope.ucExistingTotal + $scope.ucNewTotal;

            $scope.bpGroupExistingTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.BusinessProcessAnalysis.GroupCountByComparison, 'Existing');
            $scope.bpGroupNewTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.BusinessProcessAnalysis.GroupCountByComparison, 'Added');
            $scope.bpGroupRemovedTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.BusinessProcessAnalysis.GroupCountByComparison, 'Deleted');
            $scope.bpGroupTotal = $scope.bpGroupExistingTotal + $scope.bpGroupNewTotal;

            $scope.bpObjectExistingTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.BusinessProcessAnalysis.ObjectCountByComparison, 'Existing');
            $scope.bpObjectNewTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.BusinessProcessAnalysis.ObjectCountByComparison, 'Added');
            $scope.bpObjectRemovedTotal = RequestManagementLoadDataService.searchByKey(vm.riskResults.BusinessProcessAnalysis.ObjectCountByComparison, 'Deleted');
            $scope.bpObjectTotal = $scope.bpObjectExistingTotal + $scope.bpObjectNewTotal;

            //Datasource for Risk Analysis User Risks grid

            $scope.UserRisksDataSource = response.data.UserRisks;
            updateUserRisksDetailedViewGridIfMitigationRequestsExist();

            function updateUserRisksDetailedViewGridIfMitigationRequestsExist() {
                $scope.UserRisksDataSource.forEach(item => {
                    if ($scope.UserMitigations.length > 0) {
                        let match = $scope.UserMitigations.some(mitigation => {
                            return mitigation.RiskId === item.RiskId && mitigation.CompanyMasterId === item.CompanyMasterID;
                        });
                        if (match) {
                            item.RequestMitigation = 'Edit';
                        }
                        else if (!match && item.MitigationStatus !== 'New' && item.MitigationStatus) {
                            return;
                        }
                        else {
                            item.RequestMitigation = 'Request';
                        }
                    } else if (item.MitigationStatus === 'New' || !item.MitigationStatus) {
                        item.RequestMitigation = 'Request';
                    }
                    else {
                        return;
                    }
                });
            }

            $scope.BusinessProcessDataSource = response.data.BusinessProcesses;

            var addedList = response.data.UserRisks.filter(e => e.Comparison === 'Added');
            var addedPlusCurrentList = response.data.UserRisks.filter(e => e.Comparison === 'Existing' || e.Comparison === 'Added');

            if (addedPlusCurrentList.length > 0) {
                vm.RiskLevelId = Math.max.apply(Math, addedPlusCurrentList.map(function (o) { return o.RiskLevelId; }));
            } else {
                vm.RiskLevelId = 0;
            }

            if (addedList.length > 0) {
                vm.RiskLevelIdNewOnly = Math.max.apply(Math, addedList.map(function (o) { return o.RiskLevelId; }));
            } else {
                vm.RiskLevelIdNewOnly = 0;
            }

            vm.IsRisksReviewed = true;
            $scope.showReviewRisksLoadingIcon = false;
        }, function errorCallback(response) {
            $scope.showReviewRisksLoadingIcon = false;
        });
    };

    if ($scope.requestToEdit) {
        getAdditionalRequestDetails();
    }

    $scope.removeFile = async function (file) {
        vm.fileActionPending = true;

        try {
            await controlLibraryDataService.deleteAttachment(file.Identifier);

            const index = vm.existingFilesDataSource.findIndex(remainingFile => remainingFile.Identifier === file.Identifier);
            vm.existingFilesDataSource.splice(index, 1);
            $scope.fileActionPending = false;
        } catch {
            $scope.fileActionPending = false;
        }
    };

    $scope.downloadFile = async function (file) {
        vm.fileActionPending = true;

        try {
            const response = await controlLibraryDataService.downloadAttachment(file.Identifier);
            FileSaver.saveAs(response.data, file.FileName);
            $scope.fileActionPending = false;
        } catch {
            $scope.fileActionPending = false;
        }
    };

    $scope.uploadFiles = async function (files, requestId) {
        let fd = new FormData();

        files.forEach(function (file, i) {
            fd.append(files[i].file.name, files[i].file);
        });

        fd.append("DataType", "ImRequest");
        fd.append("DataTypeId", requestId);

        await controlLibraryDataService.addAttachments(fd);
    };

    // Link Ticket logic ========================

    if (!$scope.linkedTickets) $scope.linkedTickets = [];

    $scope.openIMRequestJiraTicketModal = function () {

        $uibModal.open({
            templateUrl: 'App/Components/RequestManagement/WizardSteps/Universal/linkTickets/IMRequestJiraTicketModal.html',
            controller: IMRequestJiraTicketModalController,
            backdrop: 'static',
            size: 'lg',
            scope: $scope,
            resolve: {
                linkedTicketsArray: () => $scope.linkedTickets
            }
        });
    };

    $scope.openIMRequestFreshServiceTicketModal = function () {

        $uibModal.open({
            templateUrl: 'App/Components/RequestManagement/WizardSteps/Universal/linkTickets/IMRequestFreshServiceTicketModal.html',
            controller: IMRequestFreshServiceTicketModalController,
            backdrop: 'static',
            size: 'lg',
            scope: $scope,
            resolve: {
                linkedTicketsArray: () => $scope.linkedTickets
            }
        });
    };

    $scope.openIMRequestServiceNowTicketModal = function () {

        $uibModal.open({
            templateUrl: 'App/Components/RequestManagement/WizardSteps/Universal/linkTickets/ServiceNow/IMRequestServiceNowTicketModal.html',
            controller: IMRequestServiceNowTicketModalController,
            backdrop: 'static',
            size: 'lg',
            scope: $scope,
            resolve: {
                linkedTicketsArray: () => $scope.linkedTickets
            }
        });
    };

    $scope.openIMRequestZendeskTicketModal = function () {

        $uibModal.open({
            templateUrl: 'App/Components/RequestManagement/WizardSteps/Universal/linkTickets/IMRequestZendeskTicketModal.html',
            controller: IMRequestZendeskTicketModalController,
            backdrop: 'static',
            size: 'lg',
            scope: $scope,
            resolve: {
                linkedTicketsArray: () => $scope.linkedTickets
            }
        });
    };

    $scope.openIMRequestCustomTicketModal = function () {

        $uibModal.open({
            templateUrl: 'App/Components/RequestManagement/WizardSteps/Universal/linkTickets/IMRequestCustomTicketModal.html',
            controller: IMRequestCustomTicketModalController,
            backdrop: 'static',
            size: 'lg',
            scope: $scope,
            resolve: {
                linkedTicketsArray: () => $scope.linkedTickets
            }
        });
    };

    $scope.removeIMRequestTicketLink = function (providerString) {
        $scope.linkedTickets = $scope.linkedTickets.filter(ticket => ticket.TicketProvider !== providerString);

        if (providerString === 'Jira') $scope.jiraTicketLinked = false;
        else if (providerString === 'ServiceNow') $scope.serviceNowTicketLinked = false;
        else if (providerString === 'Zendesk') $scope.zendeskTicketLinked = false;
        else if (providerString === 'Custom') $scope.customTicketLinked = false;
    };

    //Submit request functions
    $scope.finishedWizard = function () {

        // add selected role template names to request notes if restriction is on
        if (vm.selectedRoleTemplates?.length && vm.roleTemplatesOnlyEnabled) {
            if (!vm.RequestNotes.includes(' Selected Role Templates: ')) {
                vm.RequestNotes += " Selected Role Templates: "
            }
            vm.selectedRoleTemplates.forEach(template => {
                if (!vm.RequestNotes.includes(template.Name)) {
                    vm.RequestNotes += template.Name + " ";
                }
            });
        }

        $scope.updatePending = new createSpinner();
        var builtRequest = RequestManagementBuildRequestService.buildRequest(vm, true);
        //Make a copy of the request before the dates are changed so the actual data is not affected by converting dates
        var builtRequestCopy = angular.copy(builtRequest);
        //Convert request dates to Date before sending them to the DB
        RequestManagementLoadDataService.addEffectiveDates(builtRequestCopy, vm.AccessChanges);
        //Convert request dates to Date before sending them to the DB
        RequestManagementEffectiveDateService.convertRequestDates(builtRequestCopy);
        builtRequestCopy.UserMitigations = $scope.UserMitigations;
        builtRequestCopy.Tickets = $scope.linkedTickets;
        builtRequestCopy.ExpirationDate = $scope.vm.ExpirationDate ? new Date($scope.vm.ExpirationDate).toUTCString() : null;

        //Update request if there is currently an open request
        if ($scope.requestToEdit) {
            $scope.updateExistingRequest(builtRequestCopy);
        } else {
            $scope.alertUser(builtRequestCopy);
        }
    };

    $scope.alertUser = async function (request) {
        $scope.updatePending = new createSpinner();
        if (request.SfRequest?.User?.Email !== $scope.vm.assignedSalesforceEmail) {
            try {
                await swal(helperService.areYouSureParams("Warning", salesforceEmailMessage, "OK", "Cancel"));
                $scope.submitRequest(request);
            } catch {
                $scope.updatePending.loadingValue = false;
            }
        } else {
            $scope.submitRequest(request);
        }
    };

    $scope.submitRequest = async function (request) {
        try {
            let { data } = await $http.post(apiUrl + 'api/identitymanager/requests', request);

            $scope.updatePending.loadingValue = false;

            if (vm.attachments.flow.files.length > 0) {
                const requestId = data;
                $scope.uploadFiles(vm.attachments.flow.files, requestId);
            }
            helperService.showConfirmationMessage("Success", "Your request has been submitted for approval.");
            $location.path('/IdentityManager/Place/RequestManagement').search({});
        } catch (error) {
            $scope.updatePending.loadingValue = false;
            helperService.showErrorMessage(error.data);
        }
    }


    $scope.updateExistingRequest = function (request) {
        $scope.updatePending = new createSpinner();


        $http.put(apiUrl + 'api/identitymanager/requests/' + $scope.requestToEdit.Id + '/version/' + $scope.requestToEdit.Version, request).then(function (response) {

            if (vm.attachments?.flow?.files?.length > 0) {
                $scope.uploadFiles(vm.attachments.flow.files, $scope.requestToEdit.Id);
            }

            helperService.showConfirmationMessage("Success", "Your request has been updated.");
            $location.path('/IdentityManager/Place/RequestManagement').search({});
        }, function errorCallback(response) {
            $scope.updatePending.loadingValue = false;
            helperService.showErrorMessage(response.data);
        });
    };

}