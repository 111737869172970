import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, $filter, requesterGroup, helperService, requesterGroupsDataService) {
    const vm = this;

    vm.saveRequesterGroup = saveRequesterGroup;
    vm.cancel = cancel;

    vm.requesterGroup = { ...requesterGroup };

    async function saveRequesterGroup() {
        vm.savePending = new createSpinner();
        createOrUpdateGroup();
    }

    async function createOrUpdateGroup() {
        try {
            let response = vm.requesterGroup.Id ? await requesterGroupsDataService.updateGroup(vm.requesterGroup) : await requesterGroupsDataService.createGroup(vm.requesterGroup);
            await helperService.successfulSaveButton(vm.savePending);
            close(response.data);
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function close(roleId) {
        $uibModalInstance.close(roleId);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}