import swal from "sweetalert2";
import itemsModalController from "./itemsModal.controller";

export default /*@ngInject*/ function (sapSdSimRoleDataService, configurationDataService, helperService, $uibModal, $filter) {

    const vm = this;

    vm.openItemModal = openItemModal;
    vm.deleteItemFromRole = deleteItemFromRole;
    vm.changeType = changeType;
    vm.itemsLoading = true;

    vm.$onChanges = activate;

    async function activate() {
        let { data } = await configurationDataService.getProductConfigurationValueByName("SAP", "SAP_ErpType");

        vm.showCatalogsGroupsAndSpaces = data.Value === "S4";

        vm.selectedType = "tcodes";

        getRoleItemsByType(vm.selectedType);
    }

    async function getRoleItemsByType() {
        vm.itemsLoading = true;

        let { data } = await sapSdSimRoleDataService.getRoleItemsByType(vm.simulation.Id, vm.selectedRole.Id, vm.selectedType);

        data = $filter('orderBy')(data, 'Name');
        vm.items = data;
        vm.itemsLoading = false;
    }

    async function openItemModal() {
        let itemModal = $uibModal.open({
            templateUrl: '/App/Components/SAPSecurityDesigner/itemsList/itemsModal.tmpl.html',
            controller: itemsModalController,
            controllerAs: 'imvm',
            backdrop: 'static',
            resolve: {
                simulation: () => vm.simulation,
                role: () => vm.selectedRole,
                selectedItems: () => vm.items,
                selectedType: () => vm.selectedType
            }
        });

        try {
            const itemAdded = await itemModal.result,
                openOrgLevels = vm.selectedType === "tcodes" || vm.selectedType === "catalogs";

            refreshSimulations();
            await getRoleItemsByType(vm.selectedType);

            if (itemAdded && openOrgLevels) {
                vm.onItemAdded();
            }
        } catch { return; }
    }

    async function deleteItemFromRole(item) {
        try {
            await swal(helperService.areYouSureParams(`Remove Item from Role`, `Are you sure you want to remove Item ${item.Name} from Role ${vm.selectedRole.Name}?`, `Yes`, 'No'));
            vm.itemsLoading = true;

            await sapSdSimRoleDataService.removeItemFromRole(vm.simulation.Id, vm.selectedRole.Id, item.Id, vm.selectedType);

            vm.itemsLoading = false;
            refreshSimulations();
            await getRoleItemsByType(vm.selectedType);
        } catch {
            vm.itemsLoading = false;
        }
    }

    function changeType() {
        getRoleItemsByType(vm.selectedType);
    }

    function refreshSimulations() {
        const updateList = true;

        vm.onUpdateSimulationsList({ updateList });
    }
}