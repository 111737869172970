export default /*@ngInject*/ function ($http, errorsDataService) {


    this.getSAPUserTypes = function () {

        return [
            {
                Name: 'Dialog',
                Id: "A"
            },
            {
                Name: 'System',
                Id: "B"
            },
            {
                Name: 'Communications Data',
                Id: "C"
            },
            {
                Name: 'Reference (Logon not possible)',
                Id: "L"
            },
            {
                Name: 'Service',
                Id: "S"
            }
        ];
    };

    this.getSAPUserData = function (productId, userId) {
        if (userID != null) {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/sap/' + productId + '/users/' + userId
            });
        }
    };

    this.getAllRoles = function (productId) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/sap/' + productId + '/roles'
        });
    };

    this.getRolesByCompositeRole = function (data) {
        return $http({
            method: 'POST',
            url: apiUrl + 'api/identitymanager/sap/' + data.ProductId + '/roles/bycomposites',
            data: data
        })
            .catch(function (response) {
                errorsDataService.catch(response);
            });
    }

    this.getCollectionUsageSchedule = function (productId) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/sap/' + productId + '/collectusageschedules'
        });
    }

    this.setCollectionUsageSchedule = async (productId, data) => {
        try {
            return await $http.post(`${apiUrl}api/identitymanager/sap/${productId}/collectusageschedules`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    this.deleteCollectionUsageSchedule = async (productId) => {
        try {
            return await $http.delete(`${apiUrl}api/identitymanager/sap/${productId}/collectusageschedules`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

}