import swal from "sweetalert2";
import { customAutoCompleteFilter, customBooleanFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import { halSchemaKendoGrid } from "../../../Shared/kendo.module";
import importAutoMitigationRulesetModalController from "../../AutoMitigations/ImportAutoMitigationRulesetModal/importAutoMitigationRulesetModal.controller";
import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($scope, $filter, objectIdDataService, dialogs, $uibModal, $http, helperService, AutoMitigationDataService, FileSaver) {

    objectIdDataService.getObjectID().then(function (objectId) {
        $scope.ObjectId = objectId;

        var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;

    });

    $scope.helperService = helperService;

    $scope.$on('ImportExclusions', (event, args) => $scope.vm.systemGridDataSource.read());
    $scope.$on('ImportExclusions', (event, args) => $scope.vm.objectGridDataSource.read());

    var vm = $scope.vm = {};

    $scope.openImportAutoMitigationRulesetModal = function () {
        $uibModal.open({
            templateUrl: 'App/Components/AutoMitigations/ImportAutoMitigationRulesetModal/importAutoMitigationRulesetModal.tmpl.html',
            controller: importAutoMitigationRulesetModalController,
            controllerAs: 'iamrmvm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                product: () => null
            }
        });
    };

    $scope.exportAutoMitigationRuleset = function () {
        vm.rulesetExportPending = true;

        AutoMitigationDataService.exportAutoMitigationRuleset().then(response => {
            FileSaver.saveAs(response.data, 'AutoMitigationRuleset.xlsx');
            vm.rulesetExportPending = false;
        }).catch(response => {
            helperService.showErrorMessage(response.data);
            vm.rulesetExportPending = false;
        });
    };

    $scope.applyAutoMitigationConfirmation = function () {
        swal(helperService.areYouSureParams('Apply Auto Mitigation', 'This process will apply the defined Auto Mitigation Rules to the user risks that have not already been mitigated.', 'Apply')).then(function () {
            $http.put(apiUrl + 'api/arm/usermitigations/product/OR/applyautomitigations')
                .then(function (response) {
                    return;
                }, function (response) {
                    helperService.showErrorMessage(response.data);
                });
        }, function (dismiss) {
            return;
        });
    };

    //--System Auto Mitigation

    var systemColumnsFromVM =
        [
            { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
            { field: "Description", title: "Description", filterable: customAutoCompleteFilter },
            { field: "Type", title: "Type", filterable: customAutoCompleteFilter },
            { field: "Condition", title: "Condition", filterable: customAutoCompleteFilter },
            { field: "DisplayValue", title: "Display Value", filterable: customAutoCompleteFilter },
            { field: "Enabled", title: "Enabled", width: 125, filterable: customBooleanFilter },
            { template: `<a ng-click="editSystemAutoMitigation(dataItem)"><i class="glyphicon glyphicon-edit text-warning m-r-xs"></i>Edit</a>`, "title": "Edit", width: 90 },
            { template: `<a ng-click="deleteSystemAutoMitigation(dataItem)"><i class="glyphicon glyphicon-trash text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 90 }

        ];

    $scope.vm.systemExclusionGridOptions = helperService.setMainGridOptions(systemColumnsFromVM, 275);

    $scope.vm.systemGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        schema: {
            model: {
                fields: {
                    Enabled: { type: "boolean" }
                }
            }
        },
        transport: {
            read: function (options) {
                $http.get(apiUrl + 'api/assure/or/systemexclusions')
                    .then(function (response) {
                        response.data = $filter('orderBy')(response.data, 'Name');
                        options.success(response.data);
                    });
            }
        }
    });

    //Update AutoMitigation
    $scope.editSystemAutoMitigation = async function (dataItem) {
        $scope.vm.isCreate = false;
        $scope.vm.ModuleType = 'Edit';
        let { data } = await $http.get(`${apiUrl}api/assure/or/menus`);
        $scope.vm.autoMitigation = {
            Attachments: dataItem.Attachments,
            Id: 0,
            name: '',
            description: '',
            selectedType: { id: 0, name: '' },
            selectedCondition: { Id: 0, Name: '' },
            selectedValue: { Id: 0, Name: '' },
            value: '',
            excludedMenuItems: dataItem.ExcludedMenuItems,
            availableMenuItems: data
        };

        $scope.vm.autoMitigation.Id = dataItem.Id;

        $scope.vm.autoMitigation.name = dataItem.Name;
        $scope.vm.autoMitigation.description = dataItem.Description;

        $scope.vm.autoMitigation.selectedType.id = dataItem.TypeId;
        $scope.vm.autoMitigation.selectedType.name = dataItem.Type;

        $scope.vm.autoMitigation.selectedCondition.id = dataItem.ConditionId;
        $scope.vm.autoMitigation.selectedCondition.name = dataItem.Condition;

        if (dataItem.TechName !== '') {
            $scope.vm.autoMitigation.selectedValue.Id = dataItem.Value;
            $scope.vm.autoMitigation.selectedValue.Name = dataItem.TechName;
            $scope.vm.showValueDropdown = true;
        }
        else {
            $scope.vm.autoMitigation.Value = dataItem.Value;
            $scope.vm.showValueDropdown = false;
        }

        $scope.vm.autoMitigation.Enabled = dataItem.Enabled;

        var dlg = dialogs.create('/App/Components/Oracle/ApplyAutoMitigation/NewSystemAutoMitigationModal.html', newSystemAutoMitigationModalController, $scope.vm, { size: 'lg' });
    };

    //Delete AutoMitigation
    $scope.deleteSystemAutoMitigation = function (dataItem) {

        var id = dataItem.Id;

        $http({
            method: 'DELETE',
            url: apiUrl + 'api/assure/or/systemexclusions/' + id
        }).then(function successCallback(response) {

            //Remove item from datasource
            var datasourcedata = $scope.vm.systemGridDataSource.data();

            for (var i = 0; i < datasourcedata.length; i++) {
                if (datasourcedata[i].Id === id) {
                    $scope.vm.systemGridDataSource.remove(datasourcedata[i]);
                    break;
                }
            }

            resetNew();
        }, function errorCallback(response) {
            helperService.showErrorMessage(response.data);

        });

    };

    $scope.openNewSystemAutoMitigationModal = async function () {
        $scope.vm.isCreate = true;
        $scope.vm.ModuleType = 'Create';
        let { data } = await $http.get(`${apiUrl}api/assure/or/menus`);
        $scope.vm.availableMenuItems = data;
        $scope.vm.autoMitigation = {
            Id: 0,
            name: '',
            description: '',
            selectedType: { id: 0, name: '' },
            selectedCondition: { Id: 0, Name: '' },
            selectedValue: { Id: 0, Name: '' },
            value: '',
            Enabled: true,
            availableMenuItems: $scope.vm.availableMenuItems
        };

        $scope.vm.autoMitigation.Id = null;

        $scope.vm.autoMitigation.selectedType = null;

        $scope.vm.autoMitigation.selectedCondition = null;

        $scope.vm.autoMitigation.selectedValue = null;


        var dlg = dialogs.create('/App/Components/Oracle/ApplyAutoMitigation/NewSystemAutoMitigationModal.html', newSystemAutoMitigationModalController, $scope.vm, { size: 'lg' });

    };

    function resetNew() {
        $scope.autoMitigation.name = '';
    }

    //--End System Auto Mitigation

    //--Object Auto Mitigation

    var objectColumnsFromVM =
        [
            { field: "ExcludeObjectType", title: "Excluded Object Type", filterable: customAutoCompleteFilter },
            { field: "ExcludeObjectName", title: "Excluded Object", filterable: customAutoCompleteFilter },
            { field: "FromObjectType", title: "From Object Type", filterable: customAutoCompleteFilter },
            { field: "FromObjectName", title: "From Object", filterable: customAutoCompleteFilter },
            { field: "Description", title: "Description", filterable: customAutoCompleteFilter },
            { field: "Enabled", title: "Enabled", width: 125, filterable: customBooleanFilter },
            { template: `<a ng-click="editObjectAutoMitigation(dataItem)"><i class="glyphicon glyphicon-edit text-warning m-r-xs"></i>Edit</a>`, "title": "Edit", width: 90 },
            { template: `<a ng-click="deleteObjectAutoMitigation(dataItem)"><i class="glyphicon glyphicon-trash text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 90 }

        ];

    $scope.vm.objectExclusionGridOptions = helperService.setMainGridOptions(objectColumnsFromVM, 275);

    $scope.vm.objectGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        schema: {
            model: {
                fields: {
                    Enabled: { type: "boolean" }
                }
            }
        },
        transport: {
            read: function (options) {
                $http.get(apiUrl + 'api/assure/or/objectexclusions')
                    .then(function (response) {
                        response.data = $filter('orderBy')(response.data, 'Name');
                        options.success(response.data);
                    });
            }
        }
    });


    //Update AutoMitigation
    $scope.editObjectAutoMitigation = function (dataItem) {
        $scope.vm.isCreate = false;
        $scope.vm.ModuleType = 'Edit';

        $scope.vm.autoMitigation = {
            Attachments: dataItem.Attachments,
            Id: 0,
            description: '',
            selectedExcludeType: { id: 0, name: '' },
            selectedFromType: { id: 0, name: '' },
            excludeObject: { Id: 0, Name: '' },
            fromObject: { Id: 0, Name: '' }
        };

        $scope.vm.autoMitigation.Id = dataItem.Id;

        $scope.vm.autoMitigation.selectedExcludeType.id = dataItem.ExcludeObjectTypeId;
        $scope.vm.autoMitigation.selectedExcludeType.name = dataItem.ExcludeObjectType;

        $scope.vm.autoMitigation.selectedFromType.id = dataItem.FromObjectTypeId;
        $scope.vm.autoMitigation.selectedFromType.name = dataItem.FromObjectType;

        $scope.vm.autoMitigation.excludeObject.Id = dataItem.ExcludeObjectId;
        $scope.vm.autoMitigation.excludeObject.Name = dataItem.ExcludeObjectName;


        $scope.vm.autoMitigation.fromObject.Id = dataItem.FromObjectId;
        $scope.vm.autoMitigation.fromObject.Name = dataItem.FromObjectName;

        $scope.vm.autoMitigation.description = dataItem.Description;
        $scope.vm.autoMitigation.Enabled = dataItem.Enabled;

        var dlg = dialogs.create('/App/Components/Oracle/ApplyAutoMitigation/NewObjectAutoMitigationModal.html', newObjectAutoMitigationModalController, $scope.vm);

    };

    //Delete AutoMitigation
    $scope.deleteObjectAutoMitigation = function (dataItem) {

        var id = dataItem.Id;

        $http({
            method: 'DELETE',
            url: apiUrl + 'api/assure/or/objectexclusions/' + id
        }).then(function successCallback(response) {

            //Remove item from datasource
            var datasourcedata = $scope.vm.objectGridDataSource.data();

            for (var i = 0; i < datasourcedata.length; i++) {
                if (datasourcedata[i].Id === id) {
                    $scope.vm.objectGridDataSource.remove(datasourcedata[i]);
                    break;
                }
            }

            resetNew();
        }, function errorCallback(response) {
            helperService.showErrorMessage(response.data);

        });

    };

    $scope.openNewObjectAutoMitigationModal = function () {
        $scope.vm.isCreate = true;
        $scope.vm.ModuleType = 'Create';

        $scope.vm.autoMitigation = {
            Id: 0,
            Description: '',
            selectedExcludeType: { id: 0, name: '' },
            selectedFromType: { id: 0, name: '' },
            excludeObject: { Id: 0, Name: '' },
            fromObject: { Id: 0, Name: '' },
            Enabled: true
        };

        $scope.vm.autoMitigation.Id = null;

        $scope.vm.autoMitigation.selectedExcludeType = null;

        $scope.vm.autoMitigation.selectedFromType = null;

        $scope.vm.autoMitigation.excludeObject = null;

        $scope.vm.autoMitigation.fromObject = null;

        var dlg = dialogs.create('/App/Components/Oracle/ApplyAutoMitigation/NewObjectAutoMitigationModal.html', newObjectAutoMitigationModalController, $scope.vm);

    };

    function resetNew() {
        if ($scope.autoMitigation) {
            $scope.autoMitigation.name = '';
        }
    }
    //--End Object Auto Mitigation

}

/*@ngInject*/ function newObjectAutoMitigationModalController($scope, $uibModalInstance, $filter, data, $http, $route, helperService, FileSaver) {

    var vm = $scope.vm = data;

    $scope.removeFile = removeFile;
    $scope.downloadFile = downloadFile;

    if (!$scope.vm.isCreate) {
        if ($scope.vm.autoMitigation.Attachments.length > 0) {
            $scope.existingFilesDataSource = [];
            $scope.vm.autoMitigation.Attachments.forEach(attachment => $scope.existingFilesDataSource.push(attachment));
        }
    }

    $scope.attachments = {};

    $scope.init = function () {
        if ($scope.vm.autoMitigation) {
            $scope.vm.excludeTypeDropdownChange();
            $scope.vm.fromTypeDropdownChange();
        }
    };

    $scope.vm.excludeDropdownOptions = {
        dataSource: [
            { id: 1, name: "Function" },
            { id: 2, name: "Menu" },
            { id: 4, name: 'Concurrent Program' }
        ],
        dataTextField: "name",
        dataValueField: "id",
        optionLabel: "--Select an item--"
    };

    $scope.vm.fromDropdownOptions = {
        dataSource: $scope.vm.fromDropdownDataSource,
        dataTextField: "name",
        dataValueField: "id",
        optionLabel: "--Select an item--"
    };

    $scope.vm.excludeObjectDropdownOptions = {
        template: '<strong>{{dataItem.AltName}}</strong>      <span ng-if="dataItem.Name"> | {{dataItem.Name}}</span>          <span ng-if="dataItem.SecurityObjectTypeName"> | {{dataItem.SecurityObjectTypeName}} </span>         <span class="badge light-gray-badge-background">{{dataItem.ProductId}}</span>',
        filter: "contains",
        autoBind: false,
        minLength: 3,
        virtual: {
            itemHeight: 26
        },
        height: 325
    };

    $scope.vm.fromObjectDropdownOptions = {
        template: '<strong>{{dataItem.AltName}}</strong>      <span ng-if="dataItem.Name"> | {{dataItem.Name}}</span>          <span ng-if="dataItem.SecurityObjectTypeName"> | {{dataItem.SecurityObjectTypeName}} </span>         <span class="badge light-gray-badge-background">{{dataItem.ProductId}}</span>',
        filter: "contains",
        autoBind: false,
        virtual: {
            itemHeight: 26
        },
        height: 325
    };

    $scope.vm.fromDropdownDataSource = new kendo.data.DataSource({
        data: [{ id: 0, name: "-- Select Exclude Type --" }]
    });

    vm.fromFunctionDropdownDataSource = new kendo.data.DataSource({
        data: [{ id: 2, name: "Menu" },
        { id: 3, name: "Responsibility" }]
    });

    vm.fromMenuDropdownDataSource = new kendo.data.DataSource({
        data: [{ id: 2, name: "Menu" },
        { id: 3, name: "Responsibility" }]
    });

    vm.fromFunctionDropdownOptions = {
        dataSource: vm.fromFunctionDropdownDataSource,
        dataTextField: "name",
        dataValueField: "id"
    };

    vm.fromMenuDropdownOptions = {
        dataSource: vm.fromMenuDropdownDataSource,
        dataTextField: "name",
        dataValueField: "id"
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
        $route.reload();
    }; // end done

    //Save Auto Mitigation
    $scope.saveAutoMitigation = function (e, enabled, description, excludeObjectType, excludeObject, fromObjectType, fromObject) {

        $scope.savingAutoMitigation = new createSpinner();

        let fromObjectID;
        let fromObjectRespApp = null;
        let fromName;

        if (fromObject.Id) {
            fromObjectID = fromObject.Id;
        }

        if (fromObject.ID) {
            fromObjectID = fromObject.ID;
        }

        if (fromObject.Name) {
            fromName = fromObject.Name;
        }

        if (fromObjectType.name === 'Responsibility') {
            fromObjectRespApp = fromObject.ApplicationId;
        }

        var data = {
            Enabled: $scope.vm.autoMitigation.Enabled,
            Description: description,
            ExcludeObjectID: excludeObject.Id,
            ExcludeObjectTechName: excludeObject.Name,
            ExcludeObjectType: excludeObjectType.id,
            FromObjectID: fromObjectID,
            FromObjectTechName: fromName,
            FromObjectRespApp: fromObjectRespApp,
            FromObjectType: fromObjectType.id
        };

        $http({
            method: 'POST',
            url: apiUrl + 'api/assure/or/objectexclusions',
            data: data

        }).then(async function successCallback(response) {
            await helperService.successfulSaveButton($scope.savingAutoMitigation);
            if ($scope.attachments.flow.files.length) {
                uploadNewFiles($scope.attachments.flow.files, response.data);
                data.ID = response.data;
            } else {
                data.id = response.data;
                $route.reload();
                $scope.cancel();
            }

        }, function errorCallback(response) {
            helperService.showErrorMessage(response.data);
            $scope.savingAutoMitigation.loadingValue = false;
        });

    };

    //Save Auto Mitigation
    $scope.updateAutoMitigation = function (e, enabled, description, id) {

        $scope.savingAutoMitigation = new createSpinner();

        var data = {
            ExclusionId: id,
            Enabled: enabled,
            Description: description
        };

        $http({
            method: 'PUT',
            url: apiUrl + 'api/assure/or/objectexclusions/' + id,
            data: data

        }).then(async function successCallback(response) {
            await helperService.successfulSaveButton($scope.savingAutoMitigation);
            if ($scope.attachments.flow.files.length) {
                uploadNewFiles($scope.attachments.flow.files, id);
                data.ID = response.data;
            } else {
                data.id = response.data;
                $route.reload();
                $scope.cancel();
            }
        }, function errorCallback(response) {
            helperService.showErrorMessage(response.data);
            $scope.savingAutoMitigation.loadingValue = false;
        });

    };


    $scope.vm.excludeTypeDropdownChange = function () {
        if ($scope.vm.isCreate) {
            $scope.vm.autoMitigation.excludeObject = null;
        }

        if ($scope.vm.autoMitigation.selectedExcludeType) {
            //Reset from object type datasource
            vm.fromFunctionDropdownDataSource = new kendo.data.DataSource({
                data: [{ id: 2, name: "Menu" },
                { id: 3, name: "Responsibility" }]
            });
            //Function
            if ($scope.vm.autoMitigation.selectedExcludeType.id === 1) {

                $scope.vm.excludeObjectDropdown.options.dataValueField = "Identifier";
                $scope.vm.fromDropdown.setOptions(vm.fromFunctionDropdownOptions);
                $scope.vm.fromDropdown.setDataSource(vm.fromFunctionDropdownDataSource);

                $scope.vm.autoMitigation.excludeObjectDataSource = new kendo.data.DataSource({
                    schema: halSchemaKendoGrid,
                    pageSize: standardGridPageSize,
                    serverPaging: true,
                    serverFiltering: true,
                    transport: {
                        read: function (options) {
                            let data = helperService.prepareSortsAndFilters(options);
                            if (data.Filters.length > 0) {
                                data.Filters[0].FieldName = "SecurityObjectAltName";
                            }
                            let params = {
                                "pagesize": options.data.pageSize,
                                "page": options.data.page
                            };
                            $http({
                                method: 'POST',
                                url: apiUrl + 'api/universal/securityobjects/producttype/OR/type/FUNC',
                                data: data,
                                params: params
                            }).then(function (response) {
                                options.success(response.data);
                            });
                        }
                    }
                });


            }
            //Menu
            else if ($scope.vm.autoMitigation.selectedExcludeType.id === 2) {

                $scope.vm.excludeObjectDropdown.options.dataValueField = "Id";
                $scope.vm.fromDropdown.setOptions(vm.fromMenuDropdownOptions);
                $scope.vm.fromDropdown.setDataSource(vm.fromMenuDropdownDataSource);

                $scope.vm.autoMitigation.excludeObjectDataSource = new kendo.data.DataSource({
                    transport: {
                        read: function (options) {
                            $http({
                                method: 'GET',
                                url: apiUrl + 'api/assure/or/menus'
                            }).then(function (response) {
                                response.data = $filter('orderBy')(response.data, 'AltName');
                                options.success(response.data);
                            });
                        }
                    }
                });

            }
            //Concurrent Program
            else if ($scope.vm.autoMitigation.selectedExcludeType.id === 4) {

                $scope.vm.excludeObjectDropdown.options.dataValueField = "Identifier";
                $scope.vm.fromDropdown.setOptions(vm.fromFunctionDropdownOptions);
                vm.fromFunctionDropdownDataSource = new kendo.data.DataSource({
                    data: [
                        { id: 3, name: "Responsibility" }
                    ]
                });
                $scope.vm.fromDropdown.setDataSource(vm.fromFunctionDropdownDataSource);

                $scope.vm.autoMitigation.excludeObjectDataSource = new kendo.data.DataSource({
                    schema: halSchemaKendoGrid,
                    pageSize: standardGridPageSize,
                    serverPaging: true,
                    serverFiltering: true,
                    transport: {
                        read: function (options) {
                            let data = helperService.prepareSortsAndFilters(options);
                            if (data.Filters.length > 0) {
                                data.Filters[0].FieldName = "SecurityObjectAltName";
                            }
                            let params = {
                                "pagesize": options.data.pageSize,
                                "page": options.data.page
                            };
                            $http({
                                method: 'POST',
                                data: data,
                                params: params,
                                url: apiUrl + 'api/universal/securityobjects/producttype/OR/type/CP'
                            }).then(function (response) {
                                options.success(response.data);
                            });
                        }
                    }
                });

            }
            $scope.vm.autoMitigation.excludeObjectDataSource.read();

        }

        if ($scope.vm.autoMitigation.selectedFromType) {
            $scope.vm.fromDropdown.value($scope.vm.autoMitigation.selectedFromType.id);
        }
    };

    $scope.vm.fromTypeDropdownChange = function () {
        if ($scope.vm.isCreate) {
            $scope.vm.autoMitigation.fromObject = null;
        }

        //Function

        if ($scope.vm.autoMitigation.selectedFromType) {
            if ($scope.vm.autoMitigation.selectedFromType.id === 1) {

                $scope.vm.autoMitigation.fromObjectDataSource = new kendo.data.DataSource({
                    schema: halSchemaKendoGrid,
                    pageSize: standardGridPageSize,
                    serverPaging: true,
                    serverFiltering: true,
                    transport: {
                        read: function (options) {
                            let data = helperService.prepareSortsAndFilters(options);
                            if (data.Filters.length > 0) {
                                data.Filters[0].FieldName = "SecurityObjectAltName";
                            }
                            let params = {
                                "pagesize": options.data.pageSize,
                                "page": options.data.page
                            };
                            $http({
                                method: 'POST',
                                data: data,
                                params: params,
                                url: apiUrl + 'api/universal/securityobjects/producttype/OR/type/FUNC'
                            }).then(function (response) {
                                options.success(response.data);
                            });
                        }
                    }
                });
            }
            //Menu
            else if ($scope.vm.autoMitigation.selectedFromType.id === 2) {

                $scope.vm.autoMitigation.fromObjectDataSource = new kendo.data.DataSource({
                    transport: {
                        read: function (options) {
                            $http({
                                method: 'GET',
                                url: apiUrl + 'api/assure/or/menus'
                            }).then(function (response) {
                                response.data = $filter('orderBy')(response.data, 'AltName');
                                options.success(response.data);

                            });
                        }
                    }
                });
            }
            //Responsibility
            else if ($scope.vm.autoMitigation.selectedFromType.id === 3) {

                $scope.vm.autoMitigation.fromObjectDataSource = new kendo.data.DataSource({
                    pageSize: standardGridPageSize,
                    schema: halSchemaKendoGrid,
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    transport: {
                        read: function (options) {
                            let data = helperService.prepareSortsAndFilters(options);
                            if (data.Filters.length > 0) {
                                data.Filters[0].FieldName = "RESPONSIBILITY_NAME";
                            }
                            let params = { 'pagesize': options.data.pageSize, 'page': options.data.page };
                            $http({
                                method: 'POST',
                                url: apiUrl + 'api/assure/or/responsibilities',
                                data: data,
                                params: params
                            }).then(function (response) {
                                response.data._embedded.reportData.forEach(function (row, i) {
                                    row['AltName'] = row.Name;
                                    row['Name'] = row.Key;
                                });
                                options.success(response.data);
                            });
                        }
                    }
                });
            }

            $scope.vm.autoMitigation.fromObjectDataSource.read();
        }
    };

    function errorCallBack(error) {
        helperService.showErrorMessage(error);
    }

    function removeFile(file) {
        $scope.fileActionPending = true;

        $http.delete(apiUrl + 'api/core/attachments/' + file.Identifier)
            .then(response => {
                var index = $scope.existingFilesDataSource.findIndex(remainingFile => remainingFile.Identifier === file.Identifier);
                $scope.existingFilesDataSource.splice(index, 1);
                $scope.fileActionPending = false;
                $scope.vm.objectGridDataSource.read();
                $scope.vm.systemGridDataSource.read();
            }).catch(response => {
                errorCallBack(response.data);
                $scope.fileActionPending = false;
            });
    }

    function downloadFile(file) {
        $scope.fileActionPending = true;

        $http.get(apiUrl + 'api/core/attachments/' + file.Identifier + '/download', { responseType: "blob" })
            .then(response => {
                FileSaver.saveAs(response.data, file.FileName);
                $scope.fileActionPending = false;
            })
            .catch(response => {
                errorCallBack(response.data);
                $scope.fileActionPending = false;
            });
    }

    function uploadNewFiles(files, autoMitigationId) {
        var fd = new FormData();
        files.forEach(function (file, i) {
            fd.append(files[i].file.name, files[i].file);
        });

        fd.append("DataType", "EbsAutoMitigationRule");
        fd.append("DataTypeId", autoMitigationId);

        $http.put(apiUrl + 'api/core/attachments/', fd, {
            transformRequest: angular.identity,
            headers: { 'Content-Type': undefined }
        }).then(response => {
            $route.reload();
            $scope.cancel();
        }).catch(response => errorCallBack(response.data));
    }

}

/*@ngInject*/ function newSystemAutoMitigationModalController($scope, $uibModalInstance, $filter, data, $http, $route, helperService, FileSaver, errorsDataService) {

    $scope.vm = data;

    $scope.cancel = cancel;
    $scope.removeFile = removeFile;
    $scope.downloadFile = downloadFile;
    $scope.vm.addMenuToList = addMenuToList;
    $scope.vm.deleteSelectedMenu = deleteSelectedMenu;
    $scope.vm.addAllMenus = addAllMenus;
    $scope.vm.deleteAllSelectedMenus = deleteAllSelectedMenus;
    $scope.vm.allMenusStatus = 0;
    $scope.vm.listOfSelectedMenus = $scope.vm.autoMitigation.excludedMenuItems || [];
    $scope.vm.listOfAvailableMenus = $scope.vm.autoMitigation.availableMenuItems || [];
    $scope.vm.listOfAvailableMenus = $scope.vm.listOfAvailableMenus.filter(itemA => {
        return !$scope.vm.listOfSelectedMenus.some(itemB => itemA.Id === itemB.Id);
    });
    $scope.vm.listOfSelectedMenuIds = $scope.vm.autoMitigation.excludedMenuItems
        ? $scope.vm.autoMitigation.excludedMenuItems.map(item => item.Id)
        : []
        ;

    if (!$scope.vm.isCreate) {
        if ($scope.vm.autoMitigation.Attachments.length > 0) {
            $scope.existingFilesDataSource = [];
            $scope.vm.autoMitigation.Attachments.forEach(attachment => $scope.existingFilesDataSource.push(attachment));
        }
    }

    $scope.attachments = {};

    $scope.vm.exclusionTypeDropdownOptions = {
        dataSource: [
            { id: 1, name: "Function Name" },
            { id: 2, name: "Menu Name" },
            { id: 3, name: "Responsibility Name" },
            { id: 4, name: "User Name" },
            { id: 5, name: "Function Menu Prompt" },
            { id: 6, name: "Function Query Only" },
            { id: 7, name: "Concurrent Program Name" }

        ],
        dataTextField: "name",
        dataValueField: "id"
    };

    // if RBAC is enabled - add Role Name to the exclusion dropdown
    if (JSON.parse(localStorage.getItem("ConfigurationValues")).OR_RBACEnabled === '1') {
        $scope.vm.exclusionTypeDropdownOptions.dataSource.push({ id: 8, name: "Role Name" });
    }

    $scope.vm.searchDropdownOptions = {
        template: '<span ng-if="dataItem.Name"><strong>{{dataItem.Name}}</strong></span><span ng-if="dataItem.AltName"> | {{dataItem.AltName}}</span><span ng-if="dataItem.SecurityObjectTypeName"> | {{dataItem.SecurityObjectTypeName}} </span><span class="badge light-gray-badge-background">{{dataItem.ProductId}}</span><span ng-if="dataItem.DisplayName"> | {{dataItem.DisplayName}} </span>',
        filter: "contains",
        autoBind: false,
        virtual: {
            itemHeight: 26
        },
        dataTextField: "Name",
        dataValueField: "Id",
        height: 325
    };

    $scope.vm.exclusionConditionDropdownOptions = {
        dataSource: [
            { id: 0, name: "Equals" },
            { id: 1, name: "Contains" },
            { id: 2, name: "Is" }
        ],
        dataTextField: "name",
        dataValueField: "id",
        index: 0
    };

    $scope.vm.conditionIsDropdownDataSource = new kendo.data.DataSource({
        data: [{ id: 2, name: "Is" }]
    });

    $scope.vm.conditionNoIsDropdownDataSource = new kendo.data.DataSource({
        data: [{ id: 0, name: "Equals" },
        { id: 1, name: "Contains" }]
    });

    $scope.vm.conditionContainsDropdownOptions = {
        dataSource: $scope.vm.conditionIsDropdownDataSource,
        dataTextField: "name",
        dataValueField: "id",
        minLength: 3
    };

    $scope.vm.conditionNoContainsDropdownOptions = {
        dataSource: $scope.vm.conditionNoIsDropdownDataSource,
        dataTextField: "name",
        dataValueField: "id",
        minLength: 3
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
        $route.reload();
    }; // end done


    //Save Auto Mitigation
    $scope.saveAutoMitigation = async function (e, enabled, name, description, type, condition, textValue, comboBoxValue, excludedMenuIds) {

        $scope.savingAutoMitigation = new createSpinner();

        var objectRespApp;
        var objectName;
        var value;

        if (comboBoxValue) {
            value = comboBoxValue.Id ? comboBoxValue.Id : comboBoxValue.Name;
            objectName = comboBoxValue.Name;
            objectRespApp = comboBoxValue.ApplicationId;
        } else if (textValue !== '') {
            value = textValue;
        }

        var data = {

            Name: name,
            Description: description,
            Enabled: enabled,
            Type: type.id,
            Condition: condition.id,
            Value: value,
            TechName: objectName,
            RespApp: objectRespApp,
            ExcludedMenuIds: excludedMenuIds
        };

        try {
            let response = await $http({
                method: 'POST',
                url: apiUrl + 'api/assure/or/systemexclusions',
                data: data

            });
            await helperService.successfulSaveButton($scope.savingAutoMitigation);
            if ($scope.attachments.flow.files.length) {
                uploadNewFiles($scope.attachments.flow.files, response.data);
                data.ID = response.data;
            } else {
                data.ID = response.data;
                $route.reload();
                $scope.cancel();
            }
            //resetNew();
        } catch (response) {
            helperService.showErrorMessage(response.data);
            $scope.savingAutoMitigation.loadingValue = false;
        }

    };

    //Save Auto Mitigation
    $scope.updateAutoMitigation = async function (e, enabled, id, name, description, excludedMenuIds) {

        $scope.savingAutoMitigation = createSpinner();

        var data = {
            ExclusionId: id,
            Name: name,
            Description: description,
            Enabled: enabled,
            ExcludedMenuIds: excludedMenuIds
        };

        try {
            let response = await $http({
                method: 'PUT',
                url: apiUrl + 'api/assure/or/systemexclusions/' + id,
                data: data

            });
            await helperService.successfulSaveButton($scope.savingAutoMitigation);
            if ($scope.attachments.flow.files.length) {
                uploadNewFiles($scope.attachments.flow.files, id);
                data.ID = response.data;
            } else {
                data.id = response.data;
                $route.reload();
                $scope.cancel();
            }

        } catch (response) {
            helperService.showErrorMessage(response.data);
            $scope.savingAutoMitigation.loadingValue = false;
        }

    };


    //Product Dropdown

    $scope.vm.typeDropdownChange = function () {
        if ($scope.vm.isCreate) {
            $scope.vm.autoMitigation.selectedValue = null;
            $scope.vm.autoMitigation.selectedCondition = null;
        }

        //Function Menu Prompt Or Function Query Only
        if ($scope.vm.autoMitigation.selectedType.id === 5 || $scope.vm.autoMitigation.selectedType.id === 6) {
            $scope.vm.conditionDropdown.setOptions($scope.vm.conditionContainsDropdownOptions);
            $scope.vm.conditionDropdown.setDataSource($scope.vm.conditionIsDropdownDataSource);

            $scope.vm.showValueDropdown = false;
        }
        else {

            $scope.vm.conditionDropdown.setOptions($scope.vm.conditionNoContainsDropdownOptions);
            $scope.vm.conditionDropdown.setDataSource($scope.vm.conditionNoIsDropdownDataSource);

            $scope.vm.showValueDropdown = false;
        }

        $scope.vm.ChangeUpdateValueControl();
        $scope.vm.EnableUpdate();
    };

    $scope.vm.conditionDropdownChange = function () {
        if ($scope.vm.isCreate) {
            $scope.vm.autoMitigation.selectedValue = null;
        }
        $scope.vm.ChangeUpdateValueControl();
        $scope.vm.EnableUpdate();

    };

    $scope.vm.ChangeUpdateValueControl = async function () {
        $scope.vm.showValueDropdown = false;
        $scope.vm.disableValueTextBox = true;

        if (($scope.vm.autoMitigation.selectedValue || $scope.vm.autoMitigation.selectedType) && $scope.vm.autoMitigation.selectedCondition) {
            //Responsibility Name && Equals
            if ($scope.vm.autoMitigation.selectedType.id === 3 && $scope.vm.autoMitigation.selectedCondition.id === 0) {
                $scope.vm.showValueDropdown = true;

                $scope.vm.searchDropdownOptions.dataTextField = "Name";
                $scope.vm.searchDropdownOptions.dataValueField = "Id";

                $scope.vm.autoMitigation.searchObjectDataSource = new kendo.data.DataSource({
                    pageSize: standardGridPageSize,
                    schema: halSchemaKendoGrid,
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    transport: {
                        read: function (options) {
                            let data = helperService.prepareSortsAndFilters(options);
                            if (data.Filters.length > 0) {
                                data.Filters[0].FieldName = "RESPONSIBILITY_NAME";
                            }
                            let params = { 'pagesize': options.data.pageSize, 'page': options.data.page };
                            $http({
                                method: 'POST',
                                url: apiUrl + 'api/assure/or/responsibilities',
                                data: data,
                                params: params
                            }).then(function (response) {
                                response.data._embedded.reportData.forEach(function (row, i) {
                                    row['AltName'] = row.Name;
                                    row['Name'] = row.Key;
                                });
                                options.success(response.data);
                            });
                        }
                    }
                });
            }
            //Menu Name && Equals
            else if ($scope.vm.autoMitigation.selectedType.id === 2 && $scope.vm.autoMitigation.selectedCondition.id === 0) {
                $scope.vm.showValueDropdown = true;

                $scope.vm.searchDropdownOptions.dataTextField = "AltName";
                $scope.vm.searchDropdownOptions.dataValueField = "Id";

                $scope.vm.autoMitigation.searchObjectDataSource = new kendo.data.DataSource({
                    transport: {
                        read: function (options) {
                            $http({
                                method: 'GET',
                                url: apiUrl + 'api/assure/or/menus'
                            }).then(function (response) {
                                response.data = $filter('orderBy')(response.data, 'AltName');
                                options.success(response.data);
                            });
                        }
                    }
                });
            }
            //Function Name && Equals
            else if ($scope.vm.autoMitigation.selectedType.id === 1 && $scope.vm.autoMitigation.selectedCondition.id === 0) {
                $scope.vm.showValueDropdown = true;

                $scope.vm.searchDropdownOptions.dataTextField = "AltName";
                $scope.vm.searchDropdownOptions.dataValueField = "Identifier";

                $scope.vm.autoMitigation.searchObjectDataSource = new kendo.data.DataSource({
                    schema: halSchemaKendoGrid,
                    pageSize: standardGridPageSize,
                    serverPaging: true,
                    serverFiltering: true,
                    transport: {
                        read: function (options) {
                            let data = helperService.prepareSortsAndFilters(options);
                            if (data.Filters.length > 0) {
                                data.Filters[0].FieldName = "SecurityObjectAltName";
                            }
                            let params = {
                                "pagesize": options.data.pageSize,
                                "page": options.data.page
                            };
                            $http({
                                method: 'POST',
                                data: data,
                                params: params,
                                url: apiUrl + 'api/universal/securityobjects/producttype/OR/type/FUNC'
                            }).then(function (response) {
                                options.success(response.data);
                            });
                        }
                    }
                });
            }
            //Concurrent Program Name && Equals
            else if ($scope.vm.autoMitigation.selectedType.id === 7 && $scope.vm.autoMitigation.selectedCondition.id === 0) {
                $scope.vm.showValueDropdown = true;

                $scope.vm.searchDropdownOptions.dataTextField = "AltName";
                $scope.vm.searchDropdownOptions.dataValueField = "Identifier";

                $scope.vm.autoMitigation.searchObjectDataSource = new kendo.data.DataSource({
                    schema: halSchemaKendoGrid,
                    pageSize: standardGridPageSize,
                    serverPaging: true,
                    serverFiltering: true,
                    transport: {
                        read: function (options) {
                            let data = helperService.prepareSortsAndFilters(options);
                            if (data.Filters.length > 0) {
                                data.Filters[0].FieldName = "SecurityObjectAltName";
                            }
                            let params = {
                                "pagesize": options.data.pageSize,
                                "page": options.data.page
                            };
                            $http({
                                method: 'POST',
                                data: data,
                                params: params,
                                url: apiUrl + 'api/universal/securityobjects/producttype/OR/type/CP'
                            }).then(function (response) {
                                options.success(response.data);
                            });
                        }
                    }
                });
            }
            //Function Menu Prompt && Is
            else if ($scope.vm.autoMitigation.selectedType.id === 5 &&
                $scope.vm.autoMitigation.selectedCondition.id === 2) {
                $scope.vm.showValueDropdown = false;
                let response = await $http.get(`${apiUrl}api/assure/or/menus`);
                $scope.vm.listOfAvailableMenus = response.data;
                $scope.vm.autoMitigation.Value = "BLANK";
                $scope.vm.disableValueTextBox = true;

            }
            //Function Query Only && Is
            else if ($scope.vm.autoMitigation.selectedType.id === 6 &&
                $scope.vm.autoMitigation.selectedCondition.id === 2) {
                $scope.vm.showValueDropdown = false;

                $scope.vm.autoMitigation.Value = "TRUE";
                $scope.vm.disableValueTextBox = true;
            }
            //Role Name && Equals
            else if ($scope.vm.autoMitigation.selectedType.id === 8 && $scope.vm.autoMitigation.selectedCondition.id === 0) {
                $scope.vm.showValueDropdown = true;

                $scope.vm.searchDropdownOptions.dataTextField = "Name";
                $scope.vm.searchDropdownOptions.dataValueField = "Id";


                $scope.vm.autoMitigation.searchObjectDataSource = new kendo.data.DataSource({
                    transport: {
                        read: function (options) {
                            $http({
                                method: 'GET',
                                url: apiUrl + 'api/assure/or/roles'
                            }).then(function (response) {
                                response.data = $filter('orderBy')(response.data, 'Name');
                                options.success(response.data);
                            });
                        }
                    }
                });
            }
            else {
                $scope.vm.showValueDropdown = false;
                $scope.vm.disableValueTextBox = false;
            }
        }
        else {
            $scope.vm.showValueDropdown = false;
        }

    };

    $scope.vm.EnableUpdate = function () {
        if ($scope.vm.autoMitigation.selectedValue) {
            $scope.vm.autoMitigationvalue = $scope.vm.autoMitigation.selectedValue.Id;
        }

        if ($scope.vm.autoMitigation.Value) {
            $scope.vm.autoMitigationvalue = $scope.vm.autoMitigation.Value.Id;
        }

    };

    if (data.showValueDropdown) {
        $scope.vm.showValueDropdown = data.showValueDropdown;
        $scope.vm.ChangeUpdateValueControl();
        $scope.vm.EnableUpdate();
    }
    else {
        $scope.vm.showValueDropdown = false;
    }

    function errorCallBack(error) {
        helperService.showErrorMessage(error);
    }

    function removeFile(file) {
        $scope.fileActionPending = true;

        $http.delete(apiUrl + 'api/core/attachments/' + file.Identifier)
            .then(response => {
                var index = $scope.existingFilesDataSource.findIndex(remainingFile => remainingFile.Identifier === file.Identifier);
                $scope.existingFilesDataSource.splice(index, 1);
                $scope.fileActionPending = false;
                $scope.vm.objectGridDataSource.read();
                $scope.vm.systemGridDataSource.read();
            }).catch(response => {
                errorCallBack(response.data);
                $scope.fileActionPending = false;
            });
    }

    function downloadFile(file) {
        $scope.fileActionPending = true;

        $http.get(apiUrl + 'api/core/attachments/' + file.Identifier + '/download', { responseType: "blob" })
            .then(response => {
                FileSaver.saveAs(response.data, file.FileName);
                $scope.fileActionPending = false;
            })
            .catch(response => {
                errorCallBack(response.data);
                $scope.fileActionPending = false;
            });
    }

    function uploadNewFiles(files, autoMitigationId) {
        var fd = new FormData();
        files.forEach(function (file, i) {
            fd.append(files[i].file.name, files[i].file);
        });

        fd.append("DataType", "EbsAutoMitigationRule");
        fd.append("DataTypeId", autoMitigationId);

        $http.put(apiUrl + 'api/core/attachments/', fd, {
            transformRequest: angular.identity,
            headers: { 'Content-Type': undefined }
        }).then(response => {
            $route.reload();
            $scope.cancel();
        }).catch(response => errorCallBack(response.data));
    }

    function addMenuToList(menu) {
        $scope.vm.listOfAvailableMenus.forEach(function (row, i) {
            if (row.Id === menu.Id) {
                $scope.vm.listOfAvailableMenus.splice(i, 1);
                $scope.vm.listOfSelectedMenus.push(row);
                $scope.vm.listOfSelectedMenuIds.push(row.Id);
            }
        });
    }

    function deleteSelectedMenu(menu) {
        $scope.vm.listOfSelectedMenus.forEach(function (row, i) {
            if (row.Id === menu.Id) {
                $scope.vm.listOfSelectedMenus.splice(i, 1);
                $scope.vm.listOfSelectedMenuIds.splice(i, 1);
                $scope.vm.listOfAvailableMenus.push(row);
            }
        });
    }

    function addAllMenus() {
        let selectedIds = $scope.vm.listOfAvailableMenus.map(menu => menu.Id);
        $scope.vm.listOfSelectedMenuIds.push.apply($scope.vm.listOfSelectedMenuIds, selectedIds);
        $scope.vm.listOfSelectedMenus.push.apply($scope.vm.listOfSelectedMenus, $scope.vm.listOfAvailableMenus);
        $scope.vm.listOfAvailableMenus.length = 0;
        $scope.vm.allMenusStatus = 1;
    }

    function deleteAllSelectedMenus() {
        $scope.vm.listOfAvailableMenus.push.apply($scope.vm.listOfAvailableMenus, $scope.vm.listOfSelectedMenus);
        $scope.vm.listOfSelectedMenus = [];
        $scope.vm.listOfSelectedMenuIds.length = 0;
        $scope.vm.allMenusStatus = 2;
    }

    function cancel() {
        $uibModalInstance.close();;
    }
}