import { standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, userRestrictionsDataService, fastpathUser, $filter) {

    const vm = this;

    var inheritedCompanies = [];

    vm.fastpathUser = fastpathUser;
    vm.toggleCompany = toggleCompany;
    vm.handleCompanyMap = handleCompanyMap;
    vm.selectedCompanies = [];
    vm.cancel = cancel;

    activate();

    function activate() {
        // Add already mapped companies to selected companies array
        if (fastpathUser.ManualCompanyMappings.length > 0) vm.selectedCompanies = fastpathUser.ManualCompanyMappings.map(m => m.ID);
        if (fastpathUser.CompanyRestrictionType !== 'None') vm.enabled = true;

        // Auto-select user options and set up relevant grids
        if (fastpathUser.CompanyRestrictionType === 'UserMasterMapping') {
            vm.option = 0;
            setupInheritedCompaniesGrid();
            setupManualCompaniesGrid();
        }
        else if (fastpathUser.SystemUserMapping && fastpathUser.CompanyRestrictionType === 'ManualMapping') {
            vm.option = 1;
            setupInheritedCompaniesGrid();
            setupManualCompaniesGrid();
        }
        else if (fastpathUser.SystemUserMapping && fastpathUser.CompanyRestrictionType === 'None') {
            vm.option = 0;
            setupInheritedCompaniesGrid();
            setupManualCompaniesGrid();
        }
        else if (!fastpathUser.SystemUserMapping && fastpathUser.CompanyRestrictionType === 'ManualMapping') {
            vm.option = 1;
            setupManualCompaniesGrid();
        }
        else if (!fastpathUser.SystemUserMapping && fastpathUser.CompanyRestrictionType === 'None') {
            setupManualCompaniesGrid();
        }

    }

    function setupInheritedCompaniesGrid() {
        var inheritedCompanyGridColumns = [{ field: "Name", title: "Name" }];

        vm.inheritedCompanyGridOptions = helperService.setMainGridOptions(inheritedCompanyGridColumns, 300);

        vm.inheritedCompanyGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: function (options) {
                    userRestrictionsDataService.getUserCompanyMappings(vm.fastpathUser.SystemUserMapping.ID)
                        .then(res => {
                            res.data = $filter('orderBy')(res.data, 'Name');
                            options.success(res.data);
                            inheritedCompanies = res.data.map(c => { return c.ID; });
                        }).catch(_ => {
                            options.error();
                        });
                }
            }
        });
    }

    function setupManualCompaniesGrid() {
        var manualCompanyGridColumns = [
            {
                title: "Access",
                template: `<label class='switch'>
                            <input type='checkbox' ng-model='dataItem.companyAccess' ng-change='crmvm.toggleCompany(dataItem)'>
                            <i></i>
                        </label>`,
                width: 65, filterable: false
            },
            { field: "Name", title: "Name" }
        ];

        vm.manualCompanyGridOptions = helperService.setMainGridOptions(manualCompanyGridColumns, 300);

        vm.manualCompanyGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: function (options) {
                    userRestrictionsDataService.getUserCompanies()
                        .then(res => {

                            if (fastpathUser.ManualCompanyMappings.length > 0) {
                                fastpathUser.ManualCompanyMappings.forEach(m => {
                                    let match = res.data.find(c => c.ID === m.ID);
                                    match.companyAccess = true;
                                });
                            }
                            res.data = $filter('orderBy')(res.data, 'Name');
                            options.success(res.data);
                        }).catch(_ => {
                            options.error();
                        });
                }
            }
        });
    }

    function toggleCompany(dataItem) {

        switch (dataItem.companyAccess) {

            case true:
                vm.selectedCompanies.push(dataItem.ID);
                break;
            case false:
                vm.selectedCompanies.remove(dataItem.ID);
                break;
        }
    }

    function handleCompanyMap() {

        let map = {};
        map.FpUserId = fastpathUser.UserID;

        if (!vm.enabled) {
            disableCompanyRestrictions(map.FpUserId);
        }
        else if (vm.enabled && vm.option === 0) {
            map.CompanyMasterIds = inheritedCompanies;
            map.CompanyRestrictionType = 2;
            createCompanyMap(map);
        }
        else if (vm.enabled && vm.option === 1) {
            map.CompanyMasterIds = vm.selectedCompanies;
            map.CompanyRestrictionType = 1;
            createCompanyMap(map);
        }
    }

    async function createCompanyMap(map) {

        vm.save = new createSpinner();

        try {
            await userRestrictionsDataService.createCompanyMap(map);
            await helperService.successfulSaveButton(vm.save);
            $uibModalInstance.close();
        } catch {
            vm.save = null;
        }
    }

    async function disableCompanyRestrictions(userId) {

        vm.save = new createSpinner();

        try {
            await userRestrictionsDataService.disableCompanyRestrictions(userId);
            await helperService.successfulSaveButton(vm.save);
            $uibModalInstance.close();
        } catch {
            vm.save = null;
        }
    }

    function cancel() { $uibModalInstance.dismiss(); }

}