/* productProvisioning.component.js */

import productProvisioningController from "./productProvisioning.controller";

/**
 * @desc component for configuring product specific provisioning settings for Identity Manager
 * @example <identity-manager-product-provisioning></identity-manager-product-provisioning>
 */

const identityManagerProductProvisioning = {
    bindings: {
        product: '<'
    },
    controller: productProvisioningController,
    controllerAs: 'ppvm',
    templateUrl: '/App/Components/configuration/identityManagerConfigTab/productProvisioning/productProvisioning.tmpl.html'
};

export default identityManagerProductProvisioning;