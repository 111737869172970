export default /*@ngInject*/ function (sapSdSimRoleDataService, $filter) {

    const vm = this;

    vm.changeSelectedOrgLevel = changeSelectedOrgLevel;
    vm.grantFullAuthorization = grantFullAuthorization;

    vm.menuOptions = [
        {
            text: 'Grant full authorization',
            click: item => {
                vm.grantFullAuthorization(item.orgLevel);
            }
        }
    ];

    vm.$onChanges = activate;

    async function activate() {
        getRoleOrgLevels();
    }

    async function getRoleOrgLevels() {
        vm.orgLevelsLoading = true;
        let response = await sapSdSimRoleDataService.getRoleOrgLevels(vm.simulation.Id, vm.selectedRole.Id);
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.orgLevels = response.data;
        vm.orgLevelsLoading = false;
    }

    async function changeSelectedOrgLevel(orgLevel) {
        vm.selectedOrgLevel = orgLevel;
        vm.onSelectedOrgLevelChange({ orgLevel });
    }

    async function grantFullAuthorization(orgLevel) {
        vm.orgLevelsLoading = true;
        let data = {
            SimulationId: vm.simulation.Id,
            RoleId: vm.selectedRole.Id,
            FieldId: orgLevel.Id,
            Low: "*",
            High: null
        };

        try {
            await sapSdSimRoleDataService.addFieldValue(data);
            vm.orgLevelsLoading = false;
            // we have to clone the orgLevel object to ensure the field values component updates via $onChanges
            const updatedOrgLevel = { ...orgLevel };
            changeSelectedOrgLevel(updatedOrgLevel);
            vm.simulationsCtrl.updateList('orgLevels');
        } catch {
            vm.orgLevelsLoading = false;
        }
    }
}