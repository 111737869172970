import createSpinner from "../../../Shared/Functions/createSpinner";


export default /*@ngInject*/ function ($uibModalInstance, helperService, FileSaver, usersDataService) {

    const vm = this;
    vm.cancel = cancel;
    vm.dismiss = dismiss;
    vm.disableButton = false;
    vm.importUsers = importUsers;
    vm.downloadUsersTemplate = downloadUsersTemplate;

    function cancel() { $uibModalInstance.close(); }
    function dismiss() { $uibModalInstance.dismiss(); }

    // Import Users
    async function importUsers(file) {
        vm.importPending = new createSpinner;
        vm.disableButton = true;
        let fd = new FormData();
        fd.append(file[0].name, file[0].file);
        try {
            await usersDataService.importUsers(fd);
            await helperService.successfulSaveButton(vm.importPending);
            cancel();
        } catch {
            vm.importPending.loadingValue = false;
            vm.disableButton = false;
        }
    }

    // Export Users
    async function downloadUsersTemplate() {
        vm.downloadPending = new createSpinner();
        try {
            let response = await usersDataService.downloadUsersTemplate();
            await helperService.successfulSaveButton(vm.downloadPending);
            FileSaver.saveAs(response.data, 'UsersTemplate.xlsx');
            vm.downloadPending.loadingValue = false;
        } catch {
            vm.downloadPending.loadingValue = false;
        }
    }

}

