import templateModalController from "./templateModal.controller";
import objectModalController from "./ObjectModal/objectModal.controller";
import fieldModalController from "./FieldModal/fieldModal.controller";

export default /*@ngInject*/ function ($scope, $filter, $uibModal, templatesDataService) {

    var vm = this;

    vm.openTemplateModal = openTemplateModal;
    vm.changeTemplateSelected = changeTemplateSelected;
    vm.changeObjectSelected = changeObjectSelected;
    vm.deleteTemplate = deleteTemplate;
    vm.openObjectModal = openObjectModal;
    vm.openFieldModal = openFieldModal;
    vm.removeObject = removeObject;
    vm.removeField = removeField;

    function activate() {
        getTemplates(null);
    }

    activate();

    function openTemplateModal(template, isClone) {

        let templateModal = $uibModal.open({
            templateUrl: '/App/Components/NetSuiteAuditTrail/MaintainTemplates/templateModal.html',
            controller: templateModalController,
            controllerAs: 'tmvm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                template: () => template || {},
                isClone: () => isClone
            }
        });

        templateModal.result
            .then(response => {
                return getTemplates(response);
            })
            .catch(_ => {
                return;
            });
    }



    function openObjectModal() {
        let objectModal = $uibModal.open({
            templateUrl: '/App/Components/NetSuiteAuditTrail/MaintainTemplates/ObjectModal/objectModal.html',
            controller: objectModalController,
            controllerAs: 'omvm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                template: () => vm.selectedTemplate,
                objectsDataSource: () => vm.objectsDataSource
            }
        });

        objectModal.result
            .then(_ => {
                return getTemplates(vm.selectedTemplate.Id);
            })
            .catch(_ => {
                return;
            });
    }

    function openFieldModal() {

        let fieldModal = $uibModal.open({
            templateUrl: '/App/Components/NetSuiteAuditTrail/MaintainTemplates/FieldModal/fieldModal.html',
            controller: fieldModalController,
            controllerAs: 'fmvm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                template: () => vm.selectedTemplate,
                object: () => vm.selectedObject,
                fieldsDataSource: () => vm.fieldsDataSource
            }
        });

        fieldModal.result
            .then(_ => {
                return changeTemplateSelected(vm.selectedObject.ObjectId, vm.selectedTemplate);
            })
            .catch(_ => {
                return;
            });
    }

    vm.templateMenuOptions = [
        {
            text: 'Edit',
            click: function ($itemScope, $event, modelValue) {
                var item = modelValue;
                vm.openTemplateModal(item, false);
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: function ($itemScope, $event, modelValue) {
                var item = modelValue;
                vm.deleteTemplate(item);
            },
            displayed: function (modelValue) {
                return modelValue.template.Custom;
            }
        },
        {
            text: 'Clone',
            click: function ($itemScope, $event, modelValue) {
                var item = modelValue;
                vm.openTemplateModal(item, true);
            }
        }
    ];

    function getTemplates(preselect) {
        templatesDataService.getTemplates('NS').then(response => {
            response.data = $filter('orderBy')(response.data, 'Name');
            vm.templatesDataSource = response.data;
            if (preselect) {
                response.data.forEach(function (row, i) {
                    if (row.Id === preselect) {
                        vm.selectedTemplate = row;
                        vm.changeTemplateSelected(row.ObjectId, vm.selectedTemplate);
                    }
                });

            }
        });

    }

    function changeTemplateSelected(preselectObjectId, template) {
        vm.selectedTemplate = template;
        vm.changeTemplateInProgress = true;
        vm.objectsDataSource = null;

        if (vm.selectedTemplate) {
            templatesDataService.getObjectsByTemplateId(vm.selectedTemplate.Id).then(response => {
                response.TemplateObjects = $filter('orderBy')(response.TemplateObjects, 'ObjectName');
                vm.objectsDataSource = response.TemplateObjects;
                vm.changeTemplateInProgress = false;

                if (preselectObjectId) {
                    vm.selectedObject = vm.objectsDataSource.filter(e => e.ObjectId === preselectObjectId)[0];
                }
                else if (vm.objectsDataSource.length === 1) {
                    vm.selectedObject = vm.objectsDataSource[0];
                }
                else {
                    vm.selectedObject = null;
                }

                changeObjectSelected(vm.selectedObject);
            });
        } else {
            vm.changeTemplateInProgress = false;
        }
    }

    function changeObjectSelected(object) {
        vm.selectedObject = object;
        vm.changeObjectInProgress = true;
        vm.fieldsDataSource = null;

        if (vm.selectedObject) {
            vm.fieldsDataSource = $filter('orderBy')(vm.selectedObject.TemplateFields, 'FieldName');
            vm.changeObjectInProgress = false;
        } else {
            vm.changeObjectInProgress = false;
        }
    }

    function deleteTemplate(template) {
        if (vm.selectedTemplate = template) {
            vm.selectedTemplate = null;
        }
        return templatesDataService.deleteTemplate(template.Id)
            .then(_ => {
                return getTemplates(null);
            });
    }

    function removeObject(object) {
        return templatesDataService.removeObjectByTemplateIdAndObjectId(vm.selectedTemplate.Id, object.TemplateObjectId)
            .then(_ => {
                return changeTemplateSelected(vm.selectedObject.ObjectId, vm.selectedTemplate);
            });
    }

    function removeField(field) {
        return templatesDataService.removeFieldByTemplateIdTemplateObjectIdAndTemplateFieldId(vm.selectedTemplate.Id, field.TemplateObjectId, field.TemplateFieldId)
            .then(_ => {
                return changeTemplateSelected(vm.selectedObject.ObjectId, vm.selectedTemplate);
            });
    }

}