import createSpinner from "../../Functions/createSpinner";

export default /*@ngInject*/ function (applicationInsightsService, $http, helperService, controlLibraryDataService) {

    const vm = this;
    vm.uploadFiles = uploadFiles;

    vm.$onInit = function () {
        if (vm.resolve.objectId) {
            vm.archiveReport = vm.resolve.archiveReport;
            vm.objectId = vm.resolve.objectId;
            vm.sortsAndFilters = vm.resolve.sortsAndFilters;
        }
        vm.report = vm.resolve.report;
    };

    vm.date = new Date();
    vm.saveReportSignature = saveReportSignature;
    vm.cancel = cancel;

    function saveReportSignature() {
        if (vm.objectId) {
            signReport();
        }
        else {
            signReportFromInbox();
        }
    }

    async function signReportFromInbox() {
        const postItem = {
            ExecutionId: vm.report.executionId,
            SavedReportId: vm.report.savedReportId,
            Notes: vm.notes
        };

        vm.pleaseWait = new createSpinner();

        try {
            let { data } = await $http.post(`${apiUrl}api/core/cases/report/${vm.report.savedReportId}`, postItem);
            await helperService.successfulSaveButton(vm.pleaseWait);
            vm.pleaseWait.loadingValue = false;
            vm.modalInstance.close();
            if (vm.attachments.flow.files.length > 0) {
                const requestId = data;
                await uploadFiles(vm.attachments.flow.files, requestId);
            }
        } catch (error) {
            helperService.showErrorMessage(error.data);
            vm.pleaseWait.loadingValue = false;
        }

    }

    async function signReport() {
        const postItem = {
            "SavedReportId": null,
            "Name": vm.report.name,
            "ArchiveReport": vm.archiveReport,
            "Notes": vm.notes,
            "ReportDefinition": {
                "ReportId": vm.report.id,
                "Columns": vm.report.definition.Columns,
                "Sortings": vm.report.definition.Sortings,
                "Criteria": vm.report.definition.Criteria = vm.sortsAndFilters && vm.sortsAndFilters.Criteria ? vm.report.definition.Criteria.concat(vm.resolve.sortsAndFilters.Criteria) : vm.report.definition.Criteria,
                "Filters": vm.report.definition.Filters,
                "DateFilters": vm.report.definition.DateFilters
            }
        };

        applicationInsightsService.trackEvent('SignReport', { Name: vm.report.name, ObjectId: vm.objectId });

        vm.pleaseWait = new createSpinner();

        try {
            let { data } = await $http.post(apiUrl + 'api/core/cases', postItem);
            vm.killModal = true;
            await helperService.successfulSaveButton(vm.pleaseWait);
            vm.pleaseWait.loadingValue = false;
            vm.modalInstance.close();
            if (vm.attachments.flow.files.length > 0) {
                const requestId = data;
                await uploadFiles(vm.attachments.flow.files, requestId);
            }
        } catch (error) {
            helperService.showErrorMessage(error.data);
            vm.pleaseWait.loadingValue = false;
        }
    }

    async function uploadFiles(files, requestId) {
        let fd = new FormData();
        vm.saving = new createSpinner();

        files.forEach(function (file, i) {
            fd.append(files[i].file.name, files[i].file);
        });

        fd.append("DataType", "Signature");
        fd.append("DataTypeId", requestId);

        await controlLibraryDataService.addAttachments(fd);
        await helperService.successfulSaveButton(vm.saving);
    }

    function cancel() {
        vm.modalInstance.dismiss();
    }
}