import { standardGridPageSize, customAutoCompleteFilter, customBooleanFilter } from "../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($filter, helperService, dataService, risksDataService, assignRisksDataService) {

    const vm = this;

    vm.helperService = helperService;

    vm.assignByUserDropdownChange = assignByUserDropdownChange;
    vm.assignByRiskDropdownChange = assignByRiskDropdownChange;
    vm.removeAssignByUser = removeAssignByUser;
    vm.assignByUser = assignByUser;
    vm.assignByRisk = assignByRisk;

    //Arrays to hold the current list of assigned users and risks
    vm.AssignByUserList = [];
    vm.AssignByRiskList = [];
    vm.risk = {};
    vm.AssignedUser = {
        AssignedUser: '',
        Active: false,
        Domain: '',
        Email: '',
        IsSystemAccount: false,
        UserID: '',
        UserLogonName: ''
    };

    activate();

    async function activate() {
        vm.userDataSource = await getUsers();
        vm.riskManageType = await getRiskManageType();
        setupAssignByUserGrid();
        setupAssignByRiskGrid();
    }

    function getRiskManageType() {
        return helperService.getConfigurationValue('RiskManageType');
    }

    async function getUsers() {
        let response = await dataService.getUsers();
        response.data = response.data.filter(user => user.Active);
        response.data = $filter('orderBy')(response.data, 'UserLogonName');
        return response.data;
    }

    function setupAssignByUserGrid() {
        const userGridColumns = [
            { template: `<button class="btn label label-dimgrey" ng-show="dataItem.AssignedUserId !== arvm.risk.selectedAssignByUserUser.UserID && arvm.risk.selectedAssignByUserUser" ng-click="arvm.assignByUser(dataItem)">Assign</button>`, width: 65, minResizableWidth: 65, title: " " },
            { field: "User", title: "User Name", filterable: customAutoCompleteFilter },
            { field: "UserDescription", title: "User Description", filterable: customAutoCompleteFilter },
            { template: `<span>{{dataItem.AssignedUserName}}</span><a ng-if="dataItem.AssignedUserName" ng-click="arvm.removeAssignByUser(dataItem)" class="fa fa-lg fa-trash-o text-danger pull-right"></a>`, title: "Assigned User", width: 160, filterable: customAutoCompleteFilter }
        ];

        vm.userGridOptions = helperService.setMainGridOptions(userGridColumns, 600);
        vm.userGridOptions.selectable = false;

        vm.userGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    try {
                        let response = await assignRisksDataService.getAssignedRisks();
                        response.data.forEach(risk => risk.isAssigned = false);
                        response.data = $filter('orderBy')(response.data, 'User');
                        options.success(response.data);
                    } catch {
                        options.error();
                    }
                }
            }
        });
    }

    function setupAssignByRiskGrid() {
        const riskGridColumns = [
            { template: `<button class="btn label label-dimgrey" ng-show="dataItem.AssignedUser.UserID !== arvm.risk.selectedAssignByRiskUser.UserID && arvm.risk.selectedAssignByRiskUser" ng-click="arvm.assignByRisk(dataItem)">Assign</button>`, width: 65, minResizableWidth: 65, title: " " },
            { field: "RulesetName", title: "Ruleset", filterable: customAutoCompleteFilter },
            { field: "RulesetEnabled", title: "Ruleset Enabled", width: 140, filterable: customBooleanFilter },
            { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
            { field: "ID", title: "Id", filterable: customAutoCompleteFilter },
            { field: "RiskLevel.Name", title: "Risk", filterable: customAutoCompleteFilter },
            { field: "Enabled", title: "Risk Enabled", width: 140, filterable: customBooleanFilter },
            { template: `<span>{{dataItem.AssignedUser.UserLogonName}}<a ng-if="dataItem.AssignedUser.UserLogonName" ng-click="arvm.assignByRisk(dataItem, true)" class="fa fa-lg fa-trash-o text-danger pull-right"></a>`, title: "Assigned User", width: 160, filterable: customAutoCompleteFilter }
        ];

        vm.riskGridOptions = helperService.setMainGridOptions(riskGridColumns, 600);
        vm.riskGridOptions.selectable = false;

        vm.riskGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: {
                model: {
                    fields: {
                        RulesetEnabled: { type: "boolean" },
                        Enabled: { type: "boolean" }
                    }
                }
            },
            filter: [{ field: 'RulesetEnabled', operator: 'equal', value: true }, { field: 'Enabled', operator: 'equal', value: true }],
            transport: {
                read: async options => {
                    try {
                        let response = await risksDataService.getAllRisks();
                        response.data.forEach(risk => {
                            if (risk.AssignedUser === null) {
                                risk.AssignedUser = vm.AssignedUser;
                            }
                            risk.isAssigned = false;
                        });

                        response.data = $filter('orderBy')(response.data, 'Name');
                        options.success(response.data);
                    } catch {
                        options.error();
                    }
                }

            }
        });
    }

    function assignByUserDropdownChange() {
        let userGridDataSource = vm.userGridDataSource.data()
        userGridDataSource.forEach(user => {
            user.isAssigned = user.UserMasterID === vm.risk.selectedAssignByUserUser.UserID;
        });
    }

    function assignByRiskDropdownChange() {
        let riskGridDataSource = vm.riskGridDataSource.data()
        riskGridDataSource.forEach(risk => {
            if (risk.isAssigned) {
                risk.isAssigned = false;
            }
        });
    }

    async function removeAssignByUser(dataItem) {
        kendo.ui.progress(vm.userGrid.element, true);
        try {
            await assignRisksDataService.removeAssignedRiskByUser(dataItem.UserMasterID);
            dataItem.AssignedUserId = null;
            dataItem.AssignedUserName = null;
            dataItem.isAssigned = false;
            kendo.ui.progress(vm.userGrid.element, false);
        } catch {
            kendo.ui.progress(vm.userGrid.element, false);
        }
    }

    async function assignByUser(dataItem) {
        kendo.ui.progress(vm.userGrid.element, true);
        if (vm.risk.selectedAssignByUserUser && vm.risk.selectedAssignByUserUser !== '') {
            const data = {
                'AssignedUserId': vm.risk.selectedAssignByUserUser.UserID,
                'UserMasterId': dataItem.UserMasterID
            };

            try {
                await assignRisksDataService.assignRiskByUser(data);
                dataItem.AssignedUserId = data.AssignedUserId;
                dataItem.AssignedUserName = vm.risk.selectedAssignByUserUser.UserLogonName;
                kendo.ui.progress(vm.userGrid.element, false);
            } catch {
                kendo.ui.progress(vm.userGrid.element, false);
            }
        }
    }

    async function assignByRisk(dataItem, remove) {
        kendo.ui.progress(vm.riskGrid.element, true);
        const data = {
            'Id': dataItem.Id,
            'AssignedUserId': remove ? null : vm.risk.selectedAssignByRiskUser.UserID
        };

        try {
            await assignRisksDataService.assignByRisk(data);
            if (remove) {
                dataItem.AssignedUser = vm.AssignedUser;
                dataItem.isAssigned = false;
            } else {
                dataItem.AssignedUser = vm.risk.selectedAssignByRiskUser;
            }
            kendo.ui.progress(vm.riskGrid.element, false);
        } catch {
            kendo.ui.progress(vm.riskGrid.element, false);
        }
    }
}
