export default /*@ngInject*/ function ($http, errorsDataService) {
    const factory = {
        getBusinessProcesses,
        getBusinessProcessesByRiskRuleset,
        getBusinessProcessesObjectsByGroup,
        getBusinessProcessObjects,
        getBpGroups,
        getBpGroupObjects,
        getSapFioriApplications,
        getSapFioriAppServices,
        createBusinessProcess,
        createBusinessProcessGroup,
        addObjectToBpGroup,
        updateBusinessProcess,
        updateBusinessProcessGroup,
        deleteBusinessProcess,
        deleteBpGroup,
        removeBusinessProcessObjectsFromGroup
    };

    return factory;

    //GET Requests
    async function getBusinessProcesses() {
        try {
            return await $http.get(`${apiUrl}api/arm/businessprocesses`);
        } catch (err) {
            showError(err);
        }
    }

    async function getBusinessProcessesByRiskRuleset(riskRulesetId) {
        try {
            return await $http.get(`${apiUrl}api/arm/businessprocesses/ruleset/${riskRulesetId}`);
        } catch (err) {
            showError(err);
        }
    }

    async function getBusinessProcessesObjectsByGroup(id) {
        try {
            return await $http.get(`${apiUrl}api/arm/businessprocesses/${id}/objects`);
        } catch (err) {
            showError(err);
        }
    }

    async function getBusinessProcessObjects(id) {
        try {
            return await $http.get(`${apiUrl}api/arm/businessprocesses/${id}`);
        } catch (err) {
            showError(err);
        }
    }

    async function getBpGroups(bpId) {
        try {
            return await $http.get(`${apiUrl}api/arm/businessprocesses/${bpId}/groups`);
        } catch (err) {
            showError(err);
        }
    }

    async function getBpGroupObjects(bpId, bpGroupId) {
        try {
            return await $http.get(`${apiUrl}api/arm/businessprocesses/${bpId}/groups/${bpGroupId}/objects`);
        } catch (err) {
            showError(err);
        }
    }

    async function getSapFioriApplications(productId) {
        try {
            return await $http.get(`${apiUrl}api/sap/${productId}/fioriapplications`);
        } catch (err) {
            showError(err);
        }
    }

    async function getSapFioriAppServices(productId, appId) {
        try {
            return await $http.get(`${apiUrl}api/sap/${productId}/services/${appId}`);
        } catch (err) {
            showError(err);
        }
    }

    //POST Requests

    async function createBusinessProcess(data) {
        try {
            return await $http.post(`${apiUrl}api/arm/businessprocesses`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function createBusinessProcessGroup(data) {
        try {
            if (data.AppId) {
                return await $http.post(`${apiUrl}api/sap/${data.ProductId}/businessprocesses/${data.BusinessProcessId}/groups`, data);
            } else {
                return await $http.post(`${apiUrl}api/arm/businessprocesses/${data.BusinessProcessId}/groups`, data);
            }

        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addObjectToBpGroup(data) {
        try {
            return await $http.post(`${apiUrl}api/arm/businessprocesses/${data.BusinessProcessID}/objects`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    //PUT Requests
    async function updateBusinessProcess(data) {
        try {
            return await $http.put(`${apiUrl}api/arm/businessprocesses/${data.Id}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateBusinessProcessGroup(data) {
        try {
            return await $http.put(`${apiUrl}api/arm/businessprocesses/${data.BusinessProcessId}/groups/${data.ID}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }


    //DELETE Requests
    async function deleteBusinessProcess(id) {
        try {
            return await $http.delete(`${apiUrl}api/arm/businessprocesses/${id}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteBpGroup(bpId, bpGroupId) {
        try {
            return await $http.delete(`${apiUrl}api/arm/businessprocesses/${bpId}/groups/${bpGroupId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function removeBusinessProcessObjectsFromGroup(bpId, bpObjectId) {
        try {
            return await $http.delete(`${apiUrl}api/arm/businessprocesses/${bpId}/objects/${bpObjectId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }


    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }
}
