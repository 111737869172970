export default /*@ngInject*/ function (snapshotsDataService) {

    const vm = this;
    vm.updateSnapshot = updateSnapshot;

    vm.$onInit = getSnapshots;

    async function getSnapshots() {
        const response = await snapshotsDataService.getSnapshots('ORFC');
        vm.snapshotsDataSource = response.data;
    }

    async function updateSnapshot(snapshot) {

        const { Id, Enabled } = snapshot;
        const data = { Id, Enabled };

        try {
            await snapshotsDataService.updateSnapshot(data);
        } catch (err) {
            if (snapshot.Enabled) {
                snapshot.Enabled = false;
            } else {
                snapshot.Enabled = true;
            }
        }
    }
}