import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, $filter, helperService, errorsDataService, AuditTrailEntityDataService, dataService) {

    const vm = this;
    vm.cancel = cancel;
    vm.importTemplates = importTemplates;

    vm.importType = 0;

    function cancel() { $uibModalInstance.dismiss(); }
    function close() { $uibModalInstance.close(); }

    activate();

    async function activate() {
        vm.productsDataSource = await getProducts();
        vm.selectedProduct = vm.productsDataSource[0];
    }

    async function getProducts() {
        let response = await dataService.getProducts();
        response.data = $filter('orderBy')(response.data, 'Name');
        return response.data;
    }

    async function importTemplates(file) {
        vm.pendingImport = new createSpinner;
        let fd = new FormData();
        fd.append(file[0].name, file[0].file);
        fd.append("ImportType", vm.importType);

       try {
           await AuditTrailEntityDataService.importTemplates(fd);
           await helperService.successfulSaveButton(vm.pendingImport);
           close();
       } catch (error) {
           errorsDataService.catch(error);
           vm.pendingImport.loadingValue = false;
           vm.disableButton = false;
        }
    }

}