/* netsuiteDataChanges.component.js */

import netsuiteDataChangesController from "./netsuiteDataChanges.controller";

/**
 * @desc component to display data changes for NetSuite
 * @example <netsuite-data-changes></netsuite-data-changes>
 */

const netsuiteDataChanges = {
    controller: netsuiteDataChangesController,
    controllerAs: 'nsdcvm',
    templateUrl: '/App/Components/DataChangesNetSuite/netsuiteDataChanges.tmpl.html'
};

export default netsuiteDataChanges;