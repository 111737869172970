import simRiskAnalysisReportController from "./simRiskAnalysisReport.controller";

/* simRiskAnalysisReport.component.js */

/**
 * @desc component for viewing sap security designer simulation risk analysis statistics data in report format
 * @example <sap-sd-simulation-risk-analysis-report></sap-sd-simulation-risk-analysis-report>
 */

const sapSdSimulationRiskAnalysisReport = {
    bindings: {
        simulation: '<',
        selectedReportType: '<',
        reportData: '<'
    },
    controller: simRiskAnalysisReportController,
    controllerAs: 'srarvm',
    templateUrl: '/App/Components/SAPSecurityDesigner/simulationRiskAnalysis/simRiskAnalysisReport/simRiskAnalysisReport.tmpl.html'
};

export default sapSdSimulationRiskAnalysisReport;