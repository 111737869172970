export default /*@ngInject*/ function ($rootScope, $uibModal, SavedReportSelectorService, $http, $q, helperService, applicationInsightsService, authenticationService, CustomReportServices, objectIdDataService, errorsDataService, configurationDataService) {

    const vm = this;

    vm.$onInit = onInit;

    vm.openManageSavedLayoutsModal = openManageSavedLayoutsModal;
    vm.hideShowColumn = hideShowColumn;
    vm.openSaveReportModal = openSaveReportModal;
    vm.openScheduleReportModal = openScheduleReportModal;
    vm.findCurrentSavedReportByObjectID = findCurrentSavedReportByObjectID;
    vm.applyFilter = applyFilter;
    vm.openSignReportModal = openSignReportModal;
    vm.openSaveFilterModal = openSaveFilterModal;
    vm.openFilterManagerModal = openFilterManagerModal;
    vm.bundleReportDefinition = bundleReportDefinition;
    vm.bundleReportDefinitionCriteriaAndColumnsOnly = bundleReportDefinitionCriteriaAndColumnsOnly;
    vm.createExport = createExport;
    vm.changeView = changeView;

    vm.saveReportLayoutButton = saveReportLayoutButton;

    vm.exportToExcel = exportToExcel;
    vm.exportToPDF = exportToPDF;
    vm.exportToCSV = exportToCSV;
    vm.exportToCSVForUpdate = exportToCSVForUpdate;

    async function onInit() {
        vm.currentUser = await authenticationService.getUser();
        vm.objectId = await objectIdDataService.getObjectID();
        vm.report = helperService.getSecurityObjectDetailsFromObjectId(vm.objectId);
        checkForReportId();
        vm.filtersDataSource = CustomReportServices.setfiltersDataSource(vm.objectId);
        vm.filterDropdownOptions = helperService.getFilterDropdownOptions();
        vm.config = {};

        configurationDataService.getConfigurationValues().then(response => {
            response.data.forEach(row => {
                vm.config[row.Name] = row.Value;
            });
        });
    }

    function checkForReportId() {
        if (vm.reportId) {
            vm.report.ReportID = vm.reportId;
        }
    }

    function applyFilter(selectedFilter) {
        vm.onApplyFilter({ selectedFilter });
    }

    function saveReportLayoutButton() {
        const scope = {
            grid: vm.grid,
            ObjectId: vm.objectId
        };

        if (!vm.selectedProduct) {
            vm.selectedProduct = {
                ID: null
            };
        }

        CustomReportServices.saveGridLayout(scope, vm.selectedProduct.ID);
    }

    function openManageSavedLayoutsModal() {
        checkForReportId();

        $uibModal.open({
            component: 'manageSavedLayouts',
            resolve: {
                grid: () => vm.grid,
                report: () => vm.report,
                reportDetailsModel: () => vm.reportDetailsModel,
                objectId: () => vm.objectId,
                sortsAndFilters: () => vm.sortsAndFilters,
                bundleReportDefinition: () => bundleReportDefinition
            }
        });
    }

    function countShowingColumns(array) {
        var filteredArray = array.filter(e => !e.hidden);
        return filteredArray.length;
    }

    function hideShowColumn(columns, column) {

        if (!column.isLast) {
            //If there are two columns left visible and one is being removed set the last column to isLast which prevents that column from being removed and changes the look
            if (countShowingColumns(columns) === 2 && !column.hidden) {
                var lastColumn = columns.find(function (col) { return !col.hidden && col.field !== column.field; });
                if (lastColumn) {
                    lastColumn.isLast = true;
                }
            } else if (!column.isLast) {
                //Reset the isLast value to false if there is more than one column left
                columns.forEach(function (col) {
                    col.isLast = false;
                });
            }


            //Don't change the last column
            var index = columns.indexOf(column);

            if (column.hidden) {
                vm.grid.showColumn(index);
            } else {
                vm.grid.hideColumn(index);
                for (var i = 0; i < vm.grid.columns.length; i++) {
                    //Remove width on columns in places so auto fit can resize them
                    vm.grid.columns[i].width = null;
                    if (!vm.grid.columns[i].hidden) {
                        vm.grid.autoFitColumn(i + 1);
                        //6 is an arbitrary number for the possible columns visible on a given page
                        //When setting auto fit on a report with a large number of columns it can slow down the UI
                        //6 could be set to the number of columns currently viewable on the screen if that information can be accessed
                        if (i > 6) {
                            break;
                        }
                    }
                }
            }
        }
    }

    async function openSaveReportModal() {      
        checkForReportId();
        vm.populateCriteriaModel();
        try {
            const currentSavedReports = [];
            const res = await $http.get(`${apiUrl}api/core/savedreports/users/${vm.currentUser.userId}`);

            if (res.data.some(report => report.ReportDefinition.ReportId === vm.report.ReportID)) {

                const matches = res.data.filter(report => report.ReportDefinition.ReportId === vm.report.ReportID);
                matches.forEach(match => {
                    currentSavedReports.push(match);
                });
            }

            $uibModal.open({
                component: 'saveReport',
                resolve: {
                    grid: () => vm.grid,
                    report: () => vm.report,
                    reportDetailsModel: () => vm.reportDetailsModel,
                    objectId: () => vm.objectId,
                    sortsAndFilters: () => vm.sortsAndFilters,
                    bundleReportDefinition: () => bundleReportDefinition,
                    currentSavedReports: () => currentSavedReports
                }
            });
        } catch (err) {
            errorsDataService.catch(err);
        }
    }

    async function openScheduleReportModal() {
        checkForReportId();

        try {
            const currentSavedReports = [];
            const res = await $http.get(`${apiUrl}api/core/savedreports/users/${vm.currentUser.userId}`);

            if (res.data.some(report => report.ReportDefinition.ReportId === vm.report.ReportID)) {
                const matches = res.data.filter(report => report.ReportDefinition.ReportId === vm.report.ReportID);
                matches.forEach(match => {
                    currentSavedReports.push(match);
                });
            }

            $uibModal.open({
                component: 'scheduleReport',
                resolve: {
                    grid: () => vm.grid,
                    report: () => vm.report,
                    reportDetailsModel: () => vm.reportDetailsModel,
                    sortsAndFilters: () => vm.sortsAndFilters,
                    bundleReportDefinition: () => bundleReportDefinition,
                    currentSavedReports: () => currentSavedReports
                }
            });
        } catch (err) {
            errorsDataService.catch(err);
        }

    }

    function findCurrentSavedReportByObjectID(objectID) {
        var deferred = $q.defer();
        $http({
            method: 'GET',
            url: `${apiUrl}api/core/savedreports/users/${vm.currentUser.userId}`
        }).then(function (response) {

            var raw = response.data;
            var item, i;
            var currentSavedReports = [];

            for (i = response.data.length - 1; i >= 0; i--) {
                item = raw[i];

                if (item.ReportDefinition.ObjectId === objectID && item.detailed === vm.isDetailed) {
                    currentSavedReports.push(item);
                }
            }

            deferred.resolve(currentSavedReports);

            return deferred.promise;

        });

    }

    function openSignReportModal() {
        checkForReportId();
        vm.populateCriteriaModel();
        $uibModal.open({
            component: 'signReport',
            backdrop: 'static',
            resolve: {
                archiveReport: () => vm.config['ArchiveSignedReports'] === '1',
                objectId: () => vm.objectId,
                sortsAndFilters: () => vm.sortsAndFilters,
                report: () => {
                    return {
                        id: vm.report.ReportID,
                        name: vm.report.Name,
                        definition: vm.bundleReportDefinition(vm.grid.getOptions(), vm.reportDetailsModel)
                    }
                }
            }
        });
    }

    async function openSaveFilterModal() {
        const gridFilters = vm.grid.getOptions().dataSource.filter;
        if (gridFilters?.filters) {
            const modal = $uibModal.open({
                component: 'saveReportFilter',
                resolve: {
                    grid: () => vm.grid,
                    reportId: () => vm.reportId,
                    gridFilters: () => gridFilters,
                    objectId: () => vm.objectId,
                    filtersDataSource: () => vm.filtersDataSource
                }
            });
            try {
                await modal.result;
                onInit();
            } catch (_) {
                return;
            }
        } else {
            helperService.showErrorMessage('There is no filter to save.');
        }
    }

    function openFilterManagerModal() {
        $uibModal.open({
            component: 'reportFilterManager',
            resolve: {
                filtersDataSource: () => vm.filtersDataSource
            }
        });
    }

    function bundleReportDefinition(gridOptions, reportDetailsModel) {
        const reportDefinition = { 'Filters': null, 'Sortings': null, 'Columns': null, 'Criteria': null };
        reportDefinition.Sortings = helperService.halPrepareSortArray(gridOptions.dataSource.sort);
        reportDefinition.Columns = helperService.cleanReportColumns(gridOptions.columns);
        let filters = helperService.halPrepareFilterArray(gridOptions.dataSource.filter);
        // Needed for saving report until report toolbar is refactored
        if (vm.filterCriteria) {
            if (vm.filterCriteria.query) {
                const standardFilters = vm.filterCriteria.query.filter(filter => !filter.CriteriaValue || (filter.CriteriaValue && filter.FieldName === 'ProductId') || (filter.CriteriaValue && filter.FieldName === 'ReportId'));
                vm.criteriaToAdd = vm.filterCriteria.query.filter(filter => filter.CriteriaValue);
                filters = filters.concat(standardFilters);
            }
            if (vm.filterCriteria.dateQuery) {
                reportDefinition.DateFilters = vm.filterCriteria.dateQuery;
            }
            if (vm.sortsAndFilters) {
                if (vm.sortsAndFilters.DateFilters) {
                    reportDefinition.DateFilters = vm.sortsAndFilters.DateFilters.slice();
                }
                if (vm.dateCriteria && vm.sortsAndFilters.Filters.some(filter => filter.FieldName === vm.dateCriteria[0].FieldName)) {
                    const missingDateRangeFilters = vm.sortsAndFilters.Filters.filter(filter => filter.FieldName === vm.dateCriteria[0].FieldName);
                    filters = filters.concat(missingDateRangeFilters);
                }
                if (vm.sortsAndFilters.Filters.length > 0) {
                    filters = vm.sortsAndFilters.Filters;
                }
            }
        }

        reportDefinition.Filters = filters;
        reportDefinition.Criteria = SavedReportSelectorService.cleanReportCriteria(reportDetailsModel, vm.criteriaToAdd);

        return reportDefinition;
    }

    function bundleReportDefinitionCriteriaAndColumnsOnly(gridOptions, reportDetailsModel) {
        var reportDefinition = { 'Filters': null, 'Sortings': null, 'Columns': null, 'Criteria': null };
        reportDefinition.Columns = helperService.cleanReportColumns(gridOptions.columns);
        var filtersToAdd = [];
        var criteriaToAdd = [];

        if (vm.filterCriteria && vm.filterCriteria.query) {
            filtersToAdd = vm.filterCriteria.query.filter(filter => {
                return !filter.CriteriaValue;
            });

            criteriaToAdd = vm.filterCriteria.query.filter(filter => {
                return filter.CriteriaValue;
            });
        }

        if (vm.sortsAndFilters) {
            if (vm.sortsAndFilters.DateFilters) {
                reportDefinition.DateFilters = vm.sortsAndFilters.DateFilters.slice();
            }
            if (vm.dateCriteria && vm.sortsAndFilters.Filters.some(filter => filter.FieldName === vm.dateCriteria[0].FieldName)) {
                const missingDateRangeFilters = vm.sortsAndFilters.Filters.filter(filter => filter.FieldName === vm.dateCriteria[0].FieldName);
                filtersToAdd = filtersToAdd.concat(missingDateRangeFilters);
            }
        }

        reportDefinition.Filters = filtersToAdd;
        reportDefinition.Criteria = SavedReportSelectorService.cleanReportCriteria(reportDetailsModel, criteriaToAdd);
        return reportDefinition;
    }

    function createExport(exportType, criteriaAndColumnsOnly) {
        checkForReportId();
        if ($rootScope.tabs.length > 0) {
            const currentTab = $rootScope.tabs.find(tab => tab.id === $rootScope.currentSelectedTab.id);
            exportReport(currentTab.reportExportDetails, exportType, criteriaAndColumnsOnly);
        } else {
            exportReport(vm, exportType, criteriaAndColumnsOnly);
        }
    }

    function exportReport(reportInfo, exportType, criteriaAndColumnsOnly) {
        let exportName = null;

        if (reportInfo.reportName) {
            exportName = reportInfo.reportName;
        } else {
            exportName = reportInfo.report.Name;
        }

        applicationInsightsService.trackEvent('Export', { Name: exportName, ObjectId: reportInfo.objectId, Type: exportType });

        let reportDefinition = null;

        if (criteriaAndColumnsOnly) {
            reportDefinition = bundleReportDefinitionCriteriaAndColumnsOnly(reportInfo.grid.getOptions(), reportInfo.reportDetailsModel);
        } else {
            reportDefinition = bundleReportDefinition(reportInfo.grid.getOptions(), reportInfo.reportDetailsModel);
        }

        const postData = {
            "Name": exportName,
            "Format": exportType,
            "PreferredTimeZone": $rootScope.applicationTimeZone,
            "ReportDefinition": {
                "ReportId": reportInfo.reportId ? reportInfo.reportId : reportInfo.report.ReportID,
                "Columns": reportDefinition.Columns,
                "Sortings": reportDefinition.Sortings,
                "Criteria": reportInfo.sortsAndFilters && reportInfo.sortsAndFilters.Criteria ? reportDefinition.Criteria.concat(reportInfo.sortsAndFilters.Criteria) : reportDefinition.Criteria,
                "Filters": reportDefinition.Filters,
                "DateFilters": reportDefinition.DateFilters
            }
        };

        if (exportType === 'CsvUpdate') {
            let routeString;
            if (reportInfo.reportId === 'b280b98b-f119-4b3a-8d2d-fccef20e9195') {
                routeString = "api/audittrail/ns/places/datachanges/export";
            } else {
                routeString = "api/audittrail/ns/v2/places/allchanges/export";
            }

            $http.post(apiUrl + routeString, postData).then(function (response) {
            }, function (response) {
                helperService.showErrorMessage(response.data);
            });

        } else {
            $http.post(apiUrl + 'api/core/exports', postData).then(function (response) {

                let fullExportName;
                if (exportType === 'Excel') {
                    fullExportName = exportName + '.xlsx';
                } else if (exportType === 'PDF') {
                    fullExportName = exportName + '.pdf';
                } else if (exportType === 'Csv') {
                    fullExportName = exportName + '.csv';
                }

                response.data = { ExportId: response.data, ExportName: fullExportName };

            }, function (response) {
                helperService.showErrorMessage(response.data);
            });
        }

    }

    function exportToExcel(refreshModel) {
        vm.populateCriteriaModel();
        if (refreshModel) {
            vm.grid.dataSource.data([]);
            vm.createExport('Excel', true);
        } else {
            var serverLength = vm.grid.dataSource._total;
            if (serverLength > 999999) {
                helperService.showErrorMessage('An export must be under 1,000,000 rows');
            } else {
                vm.createExport('Excel', false);
            }
        }
    }

    function exportToPDF(refreshModel) {
        vm.populateCriteriaModel();
        if (refreshModel) {
            vm.grid.dataSource.data([]);
            vm.createExport('PDF', true);
        } else {
            vm.createExport('PDF', false);
        }
    }

    function exportToCSV(refreshModel) {
        vm.populateCriteriaModel();
        if (refreshModel) {
            vm.grid.dataSource.data([]);
            vm.createExport('Csv', true);
        } else {
            vm.createExport('Csv', false);
        }
    }

    function exportToCSVForUpdate(refreshModel) {
        if (vm.populateCriteriaModel) vm.populateCriteriaModel();
        if (refreshModel) {
            vm.grid.dataSource.data([]);
            vm.createExport('CsvUpdate', true);
        } else {
            vm.createExport('CsvUpdate', false);
        }
    };

    function changeView(moduleName, viewName, type) {
        helperService.changeView(moduleName, viewName, type);
    }

}