// Intercepting HTTP calls with AngularJS.

export default /*@ngInject*/ function ($provide, $httpProvider) {

    // Intercept http calls.
    $provide.factory('AuthInterceptor', function ($q, $window, authenticationService) {

        $httpProvider.defaults.withCredentials = true;

        return {
            // On request success
            request: async function (config) {
                let authClient = authenticationService.getAuthClient();
                let oktaAccess = await authClient.tokenManager.get('accessToken');
                if (oktaAccess) {
                    let expired = await authClient.tokenManager.hasExpired(oktaAccess);

                    if (expired) {
                        try {
                            oktaAccess = await authClient.tokenManager.renew('accessToken');
                            console.log('Token was expired and renewed key', oktaAccess);
                        }
                        catch {
                            authClient.tokenManager.clear();
                            document.location.href = appUrl + "login";
                        }
                    }
                }
                config.headers = config.headers || {};

                if (oktaAccess) {
                    // check endpoint mapping if provided
                    config.headers.Authorization = 'Bearer ' + oktaAccess.accessToken;
                    return config;
                }

                return config || $q.when(config)
            },

            // On request failure
            requestError: function (rejection) {
                // console.log(rejection); // Contains the data about the error on the request.

                // Return the promise rejection.
                return $q.reject(rejection);
            }
        };
    });

    // Add the interceptor to the $httpProvider.
    $httpProvider.interceptors.push('AuthInterceptor');

}