export default /*@ngInject*/ function ($http, errorsDataService) {
    const factory = {
        getSimulationRoles,
        getRoleItemsByType,
        getRoleAuthObjects,
        getAuthObjectFields,
        getFieldValues,
        getRoleOrgLevels,
        getFieldValueOptions,
        addRoleToSimulation,
        copyRoleToSimulation,
        editRoleToSimulation,
        addItemToSimulationRoleByType,
        addAuthObjectToSimulationRole,
        addFieldValue,
        grantRoleFullAuthorization,
        updateSimulationRole,
        removeRoleFromSimulation,
        removeItemFromRole,
        deleteFieldValue
    };

    return factory;

    async function getSimulationRoles(simulationId, params) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/roles`, {
                params: params
            });
        } catch (err) {
            showError(err);
        }
    }

    async function getRoleItemsByType(simulationId, roleId, type) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/roles/${roleId}/${type}`);
        } catch (err) {
            showError(err);
        }
    }

    async function getRoleAuthObjects(simulationId, roleId) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/roles/${roleId}/authorizationobjects`);
        } catch (err) {
            showError(err);
        }
    }

    async function getAuthObjectFields(simulationId, roleId, authObjectId) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/roles/${roleId}/authorizationobjects/${authObjectId}/fields`);
        } catch (err) {
            showError(err);
        }
    }

    async function getFieldValues(simulationId, roleId, objectId, fieldId) {
        let url;
        // if we have authorization object id we need to call the GET route for auth object field values - otherwise we are calling route for org level field values
        if (fieldId) {
            url = `${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/roles/${roleId}/authorizationobjects/${objectId}/fields/${fieldId}/values`;
        } else {
            url = `${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/roles/${roleId}/organizationlevel/${objectId}/values`;
        }

        try {
            return await $http.get(url);
        } catch (err) {
            showError(err);
        }
    }

    async function getRoleOrgLevels(simulationId, roleId) {
        try {
            return await $http.get(`${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/roles/${roleId}/organizationlevels`);
        } catch (err) {
            showError(err);
        }
    }

    async function getFieldValueOptions(simulationId, roleId, objectId, fieldId) {
        let url;
        // if we have authorization object id we need to call the GET route for auth object field values - otherwise we are calling route for org level field values
        if (fieldId) {
            url = `${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/roles/${roleId}/authorizationobjects/${objectId}/fields/${fieldId}/valueoptions`;
        } else {
            url = `${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/roles/${roleId}/organizationlevels/${objectId}/valueoptions`;
        }

        try {
            return await $http.get(url);
        } catch (err) {
            showError(err);
        }
    }

    async function addRoleToSimulation(data) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/sap/simulations/${data.SimulationId}/roles`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function copyRoleToSimulation(data) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/sap/simulations/${data.SimulationId}/roles/copy`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function editRoleToSimulation(data) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/sap/simulations/${data.SimulationId}/roles/edit`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addItemToSimulationRoleByType(data, type) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/sap/simulations/${data.SimulationId}/roles/${data.RoleId}/${type}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addAuthObjectToSimulationRole(data) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/sap/simulations/${data.SimulationId}/roles/${data.RoleId}/authorizationobjects`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addFieldValue(data) {
        let url;
        // if we have authorization object id we need to call the POST route for adding field value to auth object field - otherwise we are calling route for org level
        if (data.AuthorizationObjectId) {
            url = `${apiUrl}api/securitydesigner/sap/simulations/${data.SimulationId}/roles/${data.RoleId}/authorizationobjects/${data.AuthorizationObjectId}/fields/${data.FieldId}/values`;
        } else {
            url = `${apiUrl}api/securitydesigner/sap/simulations/${data.SimulationId}/roles/${data.RoleId}/organizationlevel/fields/${data.FieldId}/values`;
        }

        try {
            return await $http.post(url, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function grantRoleFullAuthorization(simulationId, roleId) {
        try {
            return await $http.post(`${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/roles/${roleId}/grantfullauthorization`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateSimulationRole(data) {
        try {
            return await $http.put(`${apiUrl}api/securitydesigner/sap/simulations/${data.SimulationId}/roles/${data.RoleId}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function removeRoleFromSimulation(simulationId, roleId) {
        try {
            return await $http.delete(`${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/roles/${roleId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function removeItemFromRole(simulationId, roleId, itemId, type) {
        try {
            return await $http.delete(`${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/roles/${roleId}/${type}/${itemId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteFieldValue(simulationId, roleId, objectId, fieldId, fieldValueId) {
        let url;
        // if we have authorization object id we need to call the GET route for auth object field values - otherwise we are calling route for org level field values
        if (fieldId) {
            url = `${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/roles/${roleId}/authorizationobjects/${objectId}/fields/${fieldId}/values/${fieldValueId}`;
        } else {
            url = `${apiUrl}api/securitydesigner/sap/simulations/${simulationId}/roles/${roleId}/organizationlevels/${objectId}/values/${fieldValueId}`;
        }

        try {
            return await $http.delete(url);
        } catch (err) {
            showError(err);
        }
    }

    function showError(error) {
        errorsDataService.catch(error);
    }

    function sendError(error) {
        throw error;
    }

}