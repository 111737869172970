import simRiskAnalysisController from "./simRiskAnalysis.controller";

/* simRiskAnalysis.component.js */

/**
 * @desc component for viewing sap security designer simulation risk analysis statistics
 * @example <sap-sd-simulation-risk-analysis></sap-sd-simulation-risk-analysis>
 */

const sapSdSimulationRiskAnalysis = {
    bindings: {
        simulation: '<'
    },
    controller: simRiskAnalysisController,
    controllerAs: 'sravm',
    templateUrl: '/App/Components/SAPSecurityDesigner/simulationRiskAnalysis/simRiskAnalysis.tmpl.html'
};

export default sapSdSimulationRiskAnalysis;