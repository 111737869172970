import connectionsController from "./connections.controller";

/* connections.component.js */

/**
 * @desc component for product connections
 * @example <connections></connections>
 */

const connections = {
    controller: connectionsController,
    controllerAs: 'cvm',
    templateUrl: '/App/Components/Connections/connections.tmpl.html'
};

export default connections;