import createSpinner from "../../../../Shared/Functions/createSpinner";
import { standardGridPageSize } from "../../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $http, $filter, existingOwners, ownershipGroup, helperService, riskRulesetService, risksDataService, ownershipGroupOwnerDataService, ownershipGroupItemsDataService) {
    const vm = this;

    vm.cancel = cancel;
    vm.saveRiskOwner = saveRiskOwner;
    vm.selectedOwnerTypeChanged = selectedOwnerTypeChanged;
    vm.selectedRiskInfoChanged = selectedRiskInfoChanged;

    activate();

    async function activate() {
        await getRulesets();
        await getOwners();
        
        getRiskTypes();

        vm.selectedRiskType = 0;
        vm.selectedOwnerType = 'User';

        selectedOwnerTypeChanged();
    }

    async function getOwners() {
        vm.ownersDataSourceLoading = true;
        let { data } = await ownershipGroupOwnerDataService.getAvailableOwners(ownershipGroup.Id, ownershipGroup.GroupType);
        data = $filter('orderBy')(data, 'Name');
        data = data.filter(masterOwner => !existingOwners.some(owner => owner.Id === masterOwner.Id));
        vm.availableOwnersDataSource = data;
        vm.ownersDataSourceLoading = false;
    }

    async function getRulesets() {
        let { data } = await riskRulesetService.getRiskRulesetList();
        vm.rulesets = data;

        vm.selectedRulesetId = vm.rulesets.find(ruleset => ruleset.IsDefault).RulesetId;
        setupRiskDropdown();
    }

    async function getRiskTypes() {
        let { data } = await risksDataService.getRiskTypes();
        vm.riskTypes = data;
    }

    async function setupRiskDropdown() {
        vm.riskDropdownOptions = {
            filter: "contains",
            autoClose: false,
            virtual: {
                itemHeight: 26,
                mapValueTo: 'dataItem',
                valueMapper: function (options) {
                    options.success(helperService.multiValueMapperFunction(options, vm.riskDataSource, 'RiskId'));
                }
            },
            height: 325
        };

        vm.kendoRiskDropdownDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async (options) => {
                    const { data } = await ownershipGroupItemsDataService.getAllRisksForOwner(ownershipGroup.Id, vm.selectedRulesetId);
                    vm.riskDropdown.setOptions({ placeholder: `-- Select Risks --` });


                    if (vm.selectedRiskType && vm.selectedRiskType === 1) {
                        vm.riskDataSource = data.filter(risk => risk.RiskType === 'SOD');
                    } else if (vm.selectedRiskType && vm.selectedRiskType === 2) {
                        vm.riskDataSource = data.filter(risk => risk.RiskType === 'SA');
                    } else {
                        vm.riskDataSource = data;
                    }

                    options.success(vm.riskDataSource);
                }
            }
        });
    }

    function selectedRiskInfoChanged() {
        vm.riskDropdown.value([]);
        vm.selectedRisks = null;
        vm.kendoRiskDropdownDataSource.read();
    }

    function selectedOwnerTypeChanged() {
        vm.selectedOwner = null;
        vm.filteredOwnersDataSource = vm.availableOwnersDataSource.filter(owner => owner.TypeName === vm.selectedOwnerType);
    }

    async function saveRiskOwner() {
        vm.savePending = new createSpinner();
        let newOwnerId;
        let owners = [];

        owners.push(vm.selectedOwner);

        let data = {
            GroupId: ownershipGroup.Id,
            RulesetId: vm.selectedRulesetId,
            RiskType: vm.selectedRiskType,
            Owners: owners,
            RiskIds: vm.selectedRisks
        };

        try {
            let response = await ownershipGroupOwnerDataService.addOwnerToGroup(ownershipGroup.Id, ownershipGroup.GroupType, data);
            newOwnerId = response.data;
            await helperService.successfulSaveButton(vm.savePending);
            close(newOwnerId);
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function close(ownerId) {
        $uibModalInstance.close(ownerId);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}