import createSpinner from "../../../Shared/Functions/createSpinner";
export default /*@ngInject*/ function ($uibModalInstance, helperService, approvalGroupsDataService, group) {

    const vm = this;
    vm.group = Object.assign({}, group);
    vm.dismiss = dismiss;
    vm.groupFormSubmit = groupFormSubmit;
    vm.shouldBeOpen = true;
    vm.updateInProgress = false;

    function cancel(newGroupId) {
        $uibModalInstance.close(newGroupId);
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }

    function groupFormSubmit() {
        vm.updateInProgress = new createSpinner();
        const data = vm.group;
        if (group) {
            updateGroup(data);
        } else {
            addNewGroup(data);
        }
    }

    async function addNewGroup(data) {
        try {
            let response = await approvalGroupsDataService.addApprovalGroup(data);
            await helperService.successfulSaveButton(vm.updateInProgress);
            cancel(response.data);
        } catch (err) {
            vm.updateInProgress.loadingValue = false;
            return;
        }
    }

    async function updateGroup(data) {
        try {
            await approvalGroupsDataService.updateGroup(vm.group.Id, data);
            await helperService.successfulSaveButton(vm.updateInProgress);
            cancel();
        } catch {
            vm.updateInProgress.loadingValue = false;
        }
    }
}