import createSpinner from "../../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function (helperService, $http, errorsDataService) {
    const vm = this;

    vm.updateCuaConnectionValues = updateCuaConnectionValues;

    vm.$onInit = activate;
    
    function activate() {
        getSapCuaConnections();
    }

    async function getSapCuaConnections() {
        try {
            let response = await $http.get(apiUrl + 'api/universal/products/sap/connections/cua');
            vm.origValues = response.data;
            vm.product = response.data;
        } catch (err) {
            errorsDataService.catch(err);
        }
    }

    async function updateCuaConnectionValues() {
        vm.saving = new createSpinner();
        let cuaPostData = {
            "AppServerHost": vm.product.AppServerHost,
            "Client": vm.product.Client,
            "CuaSystem": vm.product.CuaSystem,
            "IsCuaEnabled": vm.product.IsCuaEnabled,
            "Language": vm.product.Language,
            "Password": vm.product.Password,
            "PoolSize": vm.product.PoolSize,
            "SystemID": vm.product.SystemID,
            "SystemNumber": vm.product.SystemNumber,
            "User": vm.product.User
        };

        try {
           await $http.put(`${apiUrl}api/universal/products/sap/connections/cua`, cuaPostData);
           helperService.successfulSaveButton(vm.saving);
        } catch (err) {
            errorsDataService.catch(err);
            vm.saving.loadingValue = false;
        }
    }
}