import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, helperService, mitigationsDataService, mitigation) {
    const vm = this;

    vm.dismiss = dismiss;
    vm.saveMitigation = saveMitigation;

    function activate() {
        vm.shouldBeOpen = true;

        if (mitigation.ID) {
            vm.mitigation = Object.assign({}, mitigation.toJSON());
        }
    }

    activate();

    function dismiss() {
        $uibModalInstance.dismiss();
    }

    function close() {
        $uibModalInstance.close();
    }

    //Save Mitigation
    function saveMitigation() {
        vm.saving = new createSpinner();
        if (vm.mitigation.ID) {
            modifyMitigation(vm.mitigation.ID, vm.mitigation);
        } else {
            addMitigation(vm.mitigation);
        }
    }

    async function modifyMitigation(mitigationId, mitigation) {
        try {
            await mitigationsDataService.modifyMitigation(mitigationId, mitigation);
            await helperService.successfulSaveButton(vm.saving);
            close();
        } catch {
            vm.saving.loadingValue = false;
        }
    }

    async function addMitigation(mitigation) {
        mitigation.ID = '';
        try {
            await mitigationsDataService.addMitigation(mitigation);
            await helperService.successfulSaveButton(vm.saving);
            close();
        } catch {
            vm.saving.loadingValue = false;
        }
    }
}