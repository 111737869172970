import { customAutoCompleteFilter, standardGridPageSize } from '../../GlobalVariables/filterVariables';

export default /*@ngInject*/ function (helperService, $filter, AXSecurityDesignerPrivilegesDataService) {

    const vm = this;

    vm.collapsed = false;
    vm.selectedTab = 'All';

    vm.$onInit = onInit;

    vm.selectTable = selectTable;
    vm.toggleCollapse = toggleCollapse;
    vm.onAccessChange = onAccessChange;
    vm.deleteModification = deleteModification;
    vm.changeFilter = changeFilter;

    function onInit() {

        const tableColumns = [
            { field: "Name", title: "Table Name", filterable: customAutoCompleteFilter },
            { field: "Label", title: "Table Label", filterable: customAutoCompleteFilter },
        ];

        vm.fieldActions = ["Grant", "Deny", "Unset"];

        const fieldColumns = [
            {
                field: "Name",
                title: "Field Name",
                filterable: customAutoCompleteFilter
            },
            {
                field: "Label",
                title: "Field Label",
                filterable: customAutoCompleteFilter
            },
            {
                title: "Read",
                field: "Read",
                template:
                    `<select class='inline-grid-dropdown' ng-change="tfmvm.onAccessChange('Read', dataItem)" ng-model='dataItem.Read'>
                        <option ng-repeat='action in tfmvm.fieldActions'>{{action}}</option>
                     </select>`,
                filterable: customAutoCompleteFilter
            },
            {
                title: "Update",
                field: "Update",
                template:
                    `<select class='inline-grid-dropdown' ng-change="tfmvm.onAccessChange('Update', dataItem)" ng-model='dataItem.Update'>
                        <option ng-repeat='action in tfmvm.fieldActions'>{{action}}</option>
                     </select>`,
                filterable: customAutoCompleteFilter
            },
            // {
            //     title: "Create",
            //     field: "Create",
            //     template:
            //         `<select class='inline-grid-dropdown' ng-change="tfmvm.onAccessChange('Create', dataItem)" ng-model='dataItem.Create'>
            //             <option ng-repeat='action in tfmvm.fieldActions'>{{action}}</option>
            //          </select>`,
            //     filterable: customAutoCompleteFilter
            // },
            // {
            //     title: "Delete",
            //     field: "Delete",
            //     template:
            //         `<select class='inline-grid-dropdown' ng-change="tfmvm.onAccessChange('Delete', dataItem)" ng-model='dataItem.Delete'>
            //             <option ng-repeat='action in tfmvm.fieldActions'>{{action}}</option>
            //          </select>`,
            //     filterable: customAutoCompleteFilter
            // },
            {
                title: " ",
                field: "IsCustomized",
                template:
                    `<a
                        ng-click='tfmvm.deleteModification(tfmvm.resolve.simulationId, dataItem)'
                        ng-if='dataItem.IsCustomized && tfmvm.selectedTab !== "Assigned"'
                        class='icons8-undo-2 pull-right'>
                    </a>`,
                width: 30,
                filterable: false
            }
        ];

        vm.tablesGridOptions = helperService.setMainGridOptions(tableColumns, null);
        vm.fieldsGridOptions = helperService.setMainGridOptions(fieldColumns, null);
        vm.fieldsGridOptions.selectable = false;
    }

    vm.tablesGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: async options => {
                try {
                    vm.resolve.tables = $filter('orderBy')(vm.resolve.tables, 'Name');
                    options.success(vm.resolve.tables);
                } catch (err) {
                    options.error();
                }
            }
        }
    });

    vm.fieldsGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        transport: {
            read: async options => {
                try {
                    const fields = await AXSecurityDesignerPrivilegesDataService.getFields(vm.selectedTable.Name);
                    const access = await AXSecurityDesignerPrivilegesDataService.getFieldAccess(vm.resolve.simulationId, vm.resolve.privilege.SystemName, vm.selectedTable.Name);

                    vm.assignedFields = [];
                    vm.modifiedFields = [];

                    if (access.data.length === 0) {
                        fields.data.forEach(field => {
                            setFieldDefaults(field);
                        });
                    } else {
                        access.data.forEach(set => {
                            const match = fields.data.find(field => field.Id === set.SystemName);
                            if (match) {
                                copyFieldSettings(match, set);
                            }
                        });
                        vm.assignedFields = getAssignedFields(fields.data);
                        vm.modifiedFields = fields.data.filter(field => field.IsModified);

                        const others = fields.data.filter(field => !field.IsCustomized);
                        others.forEach(field => {
                            setFieldDefaults(field);
                        });
                    }

                    if (vm.selectedTab === 'Assigned') {
                        fields.data = vm.assignedFields;
                    } else if (vm.selectedTab === 'Modified') {
                        fields.data = vm.modifiedFields;
                    }

                    fields.data = $filter('orderBy')(fields.data, 'Name');
                    options.success(fields.data);
                } catch (err) {
                    options.error();
                }
            }
        }
    });

    function setFieldDefaults(field) {
        field.Read = 'Unset';
        field.Update = 'Unset';
        // field.Create = 'Unset';
        // field.Delete = 'Unset';
        field.IsCustomized = false;
    }

    function copyFieldSettings(field, set) {
        field.Read = set.Read;
        field.Update = set.Update;
        // field.Create = set.Create;
        // field.Delete = set.Delete;
        field.IsModified = set.IsModified;
        field.IsCustomized = true;
    }

    function getAssignedFields(array) {
        const assigned = [];

        if (array.length > 0) {
            array.forEach(field => {
                if (field.hasOwnProperty('Read')) {
                    const { Update, Read} = field; // Create, Delete
                    const actions = [ Update, Read ]; // Create, Delete
                    if (actions.some(action => action !== 'Unset')) {
                        assigned.push(field);
                    }
                }
            });
        }
        return assigned;
    }

    async function onAccessChange(action, field) {
        kendo.ui.progress(vm.fieldsGrid.element, true);
        await updateFieldAccess(vm.resolve.simulationId, action, field);
        vm.fieldsGridDataSource.read();
        kendo.ui.progress(vm.fieldsGrid.element, false);
    }

    async function updateFieldAccess(simulationId, action, field) {

        // if (action === 'Delete' && field.Delete === 'Grant') {
        //     field.Create = 'Grant';
        //     field.Update = 'Grant';
        //     field.Read = 'Grant';
        // } else if (action === 'Create' && field.Create === "Grant") {
        //     field.Update = 'Grant';
        //     field.Read = 'Grant';
        // }
        if (action === 'Update' && field.Update === "Grant") {
            field.Read = 'Grant';
        } else if (action === 'Read' && field.Read === 'Deny') {
            field.Update = 'Deny';
            // field.Update = 'Deny';
            // field.Delete = 'Deny';
        }
        // else if (action === 'Update' && field.Update === 'Deny') {
        //     field.Create = 'Deny';
        //     field.Delete = 'Deny';
        // }
        // else if (action === 'Create' && field.Create === 'Deny') {
        //     field.Delete = 'Deny';
        // }

        const body = {
            SimulationId: simulationId,
            ParentObjectAssociation: [
                {
                    ParentObjectId: vm.resolve.privilege.SystemName,
                    ParentObjectType: 'Privilege',
                    ParentIsCustom: vm.resolve.privilege.IsCustom,

                    ChildObjectId: field.Id,
                    ChildObjectType: 'TableField',

                    Read: field.Read,
                    Update: field.Update,
                    // Create: field.Create,
                    // Delete: field.Delete,
                }
            ]
        };
        await AXSecurityDesignerPrivilegesDataService.updateFieldAccess(simulationId, body);
    }

    async function deleteModification(simulationId, field) {
        try {
            await AXSecurityDesignerPrivilegesDataService.deleteModification(simulationId, field);
            vm.fieldsGridDataSource.read();
        } catch (err) {
            return;
        }
    }

    function changeFilter() {
        vm.fieldsGridDataSource.read();
    }

    function selectTable(dataItem) {
        vm.selectedTable = dataItem;
        vm.collapsed = true;
        vm.selectedTab = 'All';
        vm.fieldsGridDataSource.read();
    }

    function toggleCollapse() {
        vm.collapsed = !vm.collapsed;
    }

}