export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getApprovalRules,
        getApprovalRuleTypes,
        getRolesByProductId,
        removeApprovalRule,
        addApprovalRule,
        updateApprovalRule,
        updateApprovalRuleType
    };

    return service;

    //GET Requests
    async function getApprovalRules() {
        try {
            return await $http.get(apiUrl + 'api/identitymanager/approvalrules');
        } catch (err) {
            showError(err);
        }

    }

    async function getApprovalRuleTypes() {
        try {
            return await $http.get(apiUrl + 'api/identitymanager/approvalruletypes');
        } catch (err) {
            showError(err);
        }

    }

    async function getRolesByProductId(productId) {
        try {
            return await $http.get(apiUrl + 'api/identitymanager/' + productId + '/roles');
        } catch (err) {
            showError(err);
        }
    }

    //DELETE Requests
    async function removeApprovalRule(approvalRuleId) {
        try {
            return await $http.delete(apiUrl + 'api/identitymanager/approvalrules/' + approvalRuleId);
        } catch (err) {
            showError(err);
        }

    }

    //POST Requests
    async function addApprovalRule(data) {
        try {
            return await $http.post(apiUrl + 'api/identitymanager/approvalrules', data);
        } catch (err) {
            showError(err);
            sendError(err);
        }

    }

    //PUT Requests
    async function updateApprovalRule(ruleTypeId, data) {
        try {
            return await $http.put(apiUrl + 'api/identitymanager/approvalrules/' + ruleTypeId, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }

    }

    async function updateApprovalRuleType(ruleTypeId, data) {
        try {
            return await $http.put(apiUrl + 'api/identitymanager/approvalruletypes/' + ruleTypeId, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }

    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}
