import { customAutoCompleteFilter } from "../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($rootScope, dataService, $filter, helperService, securityAssignmentDataService) {
    const vm = this;

    vm.createUserDataSource = createUserDataSource;
    vm.helperService = helperService;
    vm.selectedRole = null;
    vm.roleChanged = roleChanged;
    vm.search;
    vm.searchChanged = searchChanged;
    vm.selectUsers = selectUsers;
    vm.selectAllUsers = selectAllUsers;
    vm.allSelectedCheckbox = false;

    function roleChanged(selectedRole) {
        if (selectedRole !== vm.selectedRole) {
            vm.selectedRole = selectedRole;
            vm.selectedModule = null;
        }

        vm.securityAssignmentUsersGridDataSource.read();
    };

   activate();

   async function activate () {
       let response = await dataService.getRoles();

       vm.search = "";

       response.data = $filter('orderBy')(response.data, 'Name');

       vm.rolesDataSource = response.data;
       vm.createUserDataSource();
    }

    var objectModelAssignUsersToRole = function (UserID, UserLogonName, Domain, Email, Active, SelectedUserCheckbox) {
        this.UserID = UserID,
        this.UserLogonName = UserLogonName,
        this.Domain = Domain;
        this.Email = Email,
        this.Active = Active,
        this.SelectedUserCheckbox = SelectedUserCheckbox;
    };

    function createUserDataSource () {
        vm.securityAssignmentUsersGridDataSource = new kendo.data.DataSource({
            transport: {
                read: async function (options) {
                    let response = await dataService.getUsers();
                            response.data = $filter('orderBy')(response.data, 'UserLogonName');
                            response.data = $filter('filter')(response.data, { Active: true });

                    var users = response.data.map(item => {
                        return new objectModelAssignUsersToRole(
                            item.UserID,
                            item.UserLogonName,
                            item.Domain,
                            item.Email,
                            item.Active,
                            false
                        );
                    });

                    response = await securityAssignmentDataService.getRoleById(vm.selectedRole.ID);

                    response.data.forEach(userRole => {
                        let foundUser = users.find(user => userRole.UserID === user.UserID);

                        if (foundUser) {
                            foundUser.SelectedUserCheckbox = true;
                        }
                    });

                    vm.allSelectedCheckbox = users.every(user => user.SelectedUserCheckbox);

                    options.success(users);
                }
            }
        });

    };

    vm.securityAssignmentShowUsersGridOptions = helperService.setNonPageableGridOptions([
        { headerTemplate: `<label class="switch" ng-if="savm.allSelectedCheckbox !== null"><input type="checkbox" ng-disabled="savm.selectionProcessing" ng-model="savm.allSelectedCheckbox" ng-change="savm.selectAllUsers()"><i></i></label>`, template: "<label class='switch'><input type='checkbox' ng-model='dataItem.SelectedUserCheckbox' ng-change='savm.selectUsers([dataItem])' ng-disabled='savm.selectionProcessing'><i></i></label>", width: 55, filterable: false },
        { field: "UserLogonName", title: "User Name", filterable: customAutoCompleteFilter },
        { field: "Email", filterable: customAutoCompleteFilter }
    ]);


    async function selectAllUsers() {
        let users = vm.securityAssignmentUsersGridDataSource._data.filter(user => user.SelectedUserCheckbox !== vm.allSelectedCheckbox);

        kendo.ui.progress(vm.assignmentsGrid.element, true);

        users = users.filter(user => !vm.search || user.UserLogonName.toLowerCase().includes(vm.search) || user.Email.toLowerCase().includes(vm.search));

        users.forEach(user => {
            if (user.SelectedUserCheckbox !== vm.allSelectedCheckbox) {
                user.SelectedUserCheckbox = vm.allSelectedCheckbox;
            }
        });

        await selectUsers(users);
    }

    async function selectUsers (users) {
        let checked = users?.[0]?.SelectedUserCheckbox,
            data = {
                UserRoles: []
            };

        vm.selectionProcessing = true;

        users.forEach(user => {
            data.UserRoles.push({
                roleId: vm.selectedRole.ID,
                userId: user.UserID
            });
        });

        if (users.length) {
            try {

                if (checked && users.length) {
                    await securityAssignmentDataService.addAssignments(data);
                } else if (users.length) {
                    await securityAssignmentDataService.removeAssignments(data);
                }

                $rootScope.$broadcast('navigation-or-security-change');
            } catch (err) {
                activate();
            }
        }

        checkAllSelected();
        kendo.ui.progress(vm.assignmentsGrid.element, false);
        vm.selectionProcessing = false;
    }

    function searchChanged() {
        let filter = {
            logic: "or",
            filters: [
                { field: "UserLogonName", operator: "contains", value: `${vm.search}` },
                { field: "Email", operator: "contains", value: `${vm.search}` }
            ]
        };

        if (vm.search) {
            vm.securityAssignmentUsersGridDataSource.filter(filter);
        } else {
            vm.securityAssignmentUsersGridDataSource.filter({});
        }

        checkAllSelected();
    }

    function checkAllSelected() {
        let view = vm.securityAssignmentUsersGridDataSource.view();

        vm.allSelectedCheckbox = view.length && view.every(user => user.SelectedUserCheckbox);
    }
}