import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($http, $uibModalInstance, $filter, authenticationService, errorsDataService, dataService, helperService, request, delegationItems, requestType) {

    const vm = this;
    vm.delegation = {};
    vm.userDropdownLoading = false;
    vm.helperService = helperService;
    vm.cancel = cancel;
    vm.requestDelegation = requestDelegation;

    activate();

    function activate() {
        getUsers();
    }

    async function getUsers() {
        vm.userDropdownLoading = true;
        const currentUser = await authenticationService.getUser();
        let response = await dataService.getUsers();
        response.data = response.data.filter(user => user.UserID !== currentUser.userId && user.UserID !== request.Requestor);
        response.data = $filter('orderBy')(response.data, 'UserLogonName');
        response.data = response.data.filter(user => user.Active && user.RestrictDelegateAccessIM === false);
        vm.usersDataSource = response.data;
        vm.userDropdownLoading = false;
    }

    async function requestDelegation() {
        vm.delegationPending = new createSpinner();
        let data = {
            RequestId: request.Id,
            Version: request.Version,
            DelegatedUserId: vm.delegation.selectedUser,
            Notes: vm.delegation.notes,
            RequestUserIds: requestType === 'User' ? delegationItems.map(user => user.Id) : null,
            RequestAccessIds: requestType === 'Access' ? delegationItems.map(access => access.Id) : null
        };

        try {          
            await $http.post(apiUrl + 'api/identitymanager/requests/' + request.Id + '/version/' + request.Version + '/delegations', data);
            await helperService.successfulSaveButton(vm.delegationPending);
            $uibModalInstance.close(delegationItems);
        } catch (err) {
            errorsDataService.catch(err);
            vm.delegationPending.loadingValue = false;
        }
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }

}