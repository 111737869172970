import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($uibModalInstance, $http, helperService, AuditTrailEntityDataService, isClone, selectedTemplateToClone, selectedTemplate, templateGridDataSource, changeRowSelected, authenticationService) {

    const vm = this;

    vm.template = {};
    vm.template.name = '';
    vm.selectedTemplateToClone = selectedTemplateToClone;
    vm.selectedTemplate = selectedTemplate;
    vm.gridDataSource = templateGridDataSource;
    vm.isClone = isClone;
    vm.cancel = cancel;
    vm.addTemplate = addTemplate;



    //Create or Clone a Template
    function addTemplate(e, templateName, selectedProduct) {
       vm.pleaseWait = new createSpinner();

        if (!isClone) {
            let input = { Name: templateName, ProductId: 'AX7', IsCustom: true };

            $http({
                method: 'POST',
                url: apiUrl + 'api/audittrail/template',
                data: input

            }).then(async function successCallback(response) {
                const currentUser = authenticationService.getUser();
                input.Id = response.data;
                input.BaseTemplate = 0;
                input.CreatedBy = currentUser.fullName;
                let date = new Date();
                input.CreatedDate = date.toISOString();

                vm.selectedTemplate = input;
                await helperService.successfulSaveButton(vm.pleaseWait);
                cancel(response.data);

            }, function errorCallback(response) {
                vm.pleaseWait.loadingValue = false;
                helperService.showErrorMessage(response.data);
            });
        } else {
            let input = vm.selectedTemplateToClone;

            if (input) {
                $http({
                    method: 'POST',
                    url: apiUrl + 'api/audittrail/template/' + input.Id + '/clone',
                    data: {
                        "TemplateId": input.Id,
                        "Name": vm.template.name
                    }
                }).then(function successCallback(response) {

                    $http({
                        method: 'GET',
                        url: apiUrl + 'api/audittrail/template/' + response.data
                    }).then(function (response) {

                        AuditTrailEntityDataService.mapTemplateHeader(response.data).done(async function (mapTemplateResponse) {
                            if (mapTemplateResponse) {
                                vm.selectedTemplate = response.data;
                                vm.gridDataSource.insert(0, response.data);
                                changeRowSelected(response.data);

                            }
                            vm.gridDataSource.read();
                            await helperService.successfulSaveButton(vm.pleaseWait);
                            cancel();
                        });

                    }, async function (response) {
                        await helperService.successfulSaveButton(vm.pleaseWait);
                        cancel();
                    });

                }, function errorCallback(response) {
                    vm.pleaseWait.loadingValue = false;
                    helperService.showErrorMessage(response.data);
                });
            }
        }
    }

    function cancel(value) {
        $uibModalInstance.close(value);
    }
}

