import swal from "sweetalert2";

export default /*@ngInject*/ function ($filter, $scope, salesforceService) {
    $scope.vm.salesforceProfileHasRoles = false;

    if (!$scope.vm.User.SalesforceUser) {
        $scope.vm.User.SalesforceUser = {};
    }

    $scope.vm.User.SalesforceUser.ProductType = "SF";
    $scope.vm.allowSalesforceRoleSelection = $scope.vm.User?.SalesforceUser?.Profile?.UserType === "Standard";
    $scope.vm.salesforceProfilesDisabled = $scope.vm.User?.SalesforceUser?.PendingProfile;
    $scope.vm.handleSalesforceLicenseSelection = handleLicenseSelection;
    $scope.vm.handleSalesforceProfileSelection = handleProfileSelection;
    $scope.vm.salesforceProfiles = [];
    $scope.vm.dropdownOptions = {
        template: '<span>{{dataItem.Name}}</span>',
        autoBind: true,
        dataTextField: "Name",
        dataValueField: "Id",
        height: 325
    };

    activate();

    function activate() {
        getUserLicenses();
        getRoles();
        getProfiles();

        if (!$scope.vm.User.SalesforceUser?.Profile?.Id) {
            $scope.wizardForm.$valid = false;
            $scope.wizardForm.$invalid = true;
        }
    }

    function getUserLicenses() {
        $scope.vm.salesforceUserLicensesDatasource = new kendo.data.DataSource({
            transport: {
                read: async function (options) {
                    const params = { 'currentLicenseId': $scope.vm.User?.SalesforceUser?.Profile?.UserLicenseId };
                    let { data } = await salesforceService.getUserLicenses(params);

                    options.success($filter('orderBy')(data, 'Name'));
                }
            }
        });
    }

    function getProfiles() {
        $scope.vm.salesforceProfilesDatasource = new kendo.data.DataSource({
            transport: {
                read: async function (options) {
                    let { data: profiles } = await salesforceService.getProfiles(),
                        profile;

                    profiles = $filter('orderBy')(profiles, 'Name');

                    // Filter profiles by selected User License Id
                    if (profiles && $scope.vm.User.SalesforceUser.Profile?.UserLicenseId) {
                        profiles = profiles.filter(systemProfile => systemProfile.UserLicenseId === $scope.vm.User.SalesforceUser.Profile.UserLicenseId);
                    }

                    // If only one profile found, set it as the user's profile
                    if (profiles.length === 1) {
                        profile = profiles.first();

                        $scope.vm.User.SalesforceUser.Profile = { ...profile };
                        $scope.vm.allowSalesforceRoleSelection = profile?.UserType === "Standard";
                    }

                    // Set form to invalid to prevent continuing if user is assigned unassignable profile
                    if ($scope.vm.User.SalesforceUser.Profile && !$scope.vm.User.SalesforceUser.Profile.IsAssignable) {
                        $scope.wizardForm.$valid = false;
                        $scope.wizardForm.$invalid = true;
                    }

                    // Filter out profiles that are not assignable
                    profiles = profiles.filter(profile => profile.IsAssignable);

                    if (!$scope.vm.allowSalesforceRoleSelection) {
                        $scope.vm.User.SalesforceUser.UserRoleId = null;
                    }

                    $scope.vm.salesforceProfiles = profiles;

                    options.success(profiles);

                    $scope.vm.ListOfAvailableSalesforcePermissionSets = filterPermissons($scope.vm.User.SalesforceUser.Profile?.UserLicenseId, $scope.vm.ListOfAllSalesforcePermissionSets);
                }
            }
        });
    }

    function getRoles() {
        $scope.vm.salesforceRolesDatasource = new kendo.data.DataSource({
            transport: {
                read: async function (options) {
                    let { data } = await salesforceService.getRoles();

                    options.success($filter('orderBy')(data, 'Name'));
                }
            }
        });
    }

    async function handleLicenseSelection(conflictsReviewed) {
        let userId = $scope.vm.User?.SalesforceUser?.Id,
            licenseId = $scope.vm.User?.SalesforceUser?.Profile?.UserLicenseId;

        $scope.vm.ListOfSelectedSalesforcePermissionSets = [];

        if (userId && licenseId !== $scope.vm.assignedSalesforceLicenseId) {
            //Alert user and set bit used to filter assigned permission sets so they are hidden
            alertUser();
        } else if (userId) {
            // If modify and original license id selected, set to original assigned permission sets to prevent inconsistencies
            $scope.vm.ListOfSelectedSalesforcePermissionSets = [...$scope.vm.assignedSalesforcePermissionSets];
            $scope.vm.salesforceHideAssignedPermissionSets = false;
        } else {
            $scope.vm.salesforceHideAssignedPermissionSets = false;
            $scope.vm.showSalesforceLicenseChangeMessage = false;
        }

        await $scope.vm.salesforceProfilesDatasource.read();
        $scope.vm.IsConflictsReviewed = conflictsReviewed;

        handleProfileSelection();

        $scope.vm.ListOfAvailableSalesforcePermissionSets = filterPermissons(licenseId, $scope.vm.ListOfAllSalesforcePermissionSets);
    }


    function alertUser() {
        let message = "Changing this user's license will also remove any permission sets and permission set licenses that are assigned to this user. A welcome email from the community will be sent to new community users, regardless of their preferences."

        if (!$scope.vm.showSalesforceLicenseChangeMessage) {
            swal("Warning", message, "warning");

            $scope.vm.showSalesforceLicenseChangeMessage = true;
            $scope.vm.salesforceHideAssignedPermissionSets = true;
        }
    }

    function handleProfileSelection() {
        let profile = $scope.vm.salesforceProfiles.find(profile => $scope.vm.User.SalesforceUser?.Profile.Id === profile.Id);

        if (profile?.Id) {
            profile.UserRoleId = null;
            $scope.vm.User.SalesforceUser.Profile = { ...profile };
            $scope.wizardForm.$valid = true;
            $scope.wizardForm.$invalid = false;
        } else {
            $scope.wizardForm.$valid = false;
            $scope.wizardForm.$invalid = true;
        }

        $scope.vm.allowSalesforceRoleSelection = profile?.UserType === "Standard";
        $scope.vm.IsConflictsReviewed = null;
    }
}

function filterPermissons(licenseId, permissions = []) {
    return permissions.filter(availablePermission => availablePermission.LicenseId === licenseId || !availablePermission.LicenseId);
}