export default /*@ngInject*/ function ($scope) {

    //If $scope variable doesnt exist in child it looks in parent

        $scope.addNSPermissionToList = function (permission) {

            $scope.vm.ListOfAvailableNetSuitePermissions.forEach(function (row, i) {
                if (row.Id === permission.Id) {
                    $scope.vm.ListOfAvailableNetSuitePermissions.splice(i, 1);
                    row.selectedLevel = null;
                    $scope.vm.ListOfSelectedNetSuitePermissions.push(row);
                }
            });
            $scope.vm.IsRisksReviewed = null;
        };

        $scope.deleteSelectedPermission = function (selectedPermission) {
            $scope.vm.ListOfSelectedNetSuitePermissions.forEach(function (row, i) {
                if (row.Id === selectedPermission.Id) {
                    $scope.vm.ListOfSelectedNetSuitePermissions.splice(i, 1);
                    $scope.vm.ListOfAvailableNetSuitePermissions.push(row);
                }
            });
            $scope.vm.IsRisksReviewed = null;
        };
    
}