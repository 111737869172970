export default function () {
    var _b = 'static'; // backdrop
    var _k = true; // keyboard
    var _w = 'dialogs-default'; // windowClass
    var _bdc = 'dialogs-backdrop-default'; // backdropClass
    var _copy = true; // controls use of angular.copy
    var _wTmpl = null; // window template
    var _wSize = 'md'; // large modal window default
    var _animation = true; // true/false to use animation

    var _fa = false; // fontawesome flag

    var _setOpts = function (opts) {
        var _opts = {};
        opts = opts || {};
        _opts.kb = angular.isDefined(opts.keyboard) ? !!opts.keyboard : _k; // values: true,false
        _opts.bd = angular.isDefined(opts.backdrop) ? opts.backdrop : _b; // values: 'static',true,false
        _opts.bdc = angular.isDefined(opts.backdropClass) ? opts.backdropClass : _bdc; // additional CSS class(es) to be added to the modal backdrop
        _opts.ws = angular.isDefined(opts.size) && (opts.size === 'sm' || opts.size === 'lg' || opts.size === 'md') ? opts.size : _wSize; // values: 'sm', 'lg', 'md'
        _opts.wc = angular.isDefined(opts.windowClass) ? opts.windowClass : _w; // additional CSS class(es) to be added to a modal window
        _opts.anim = angular.isDefined(opts.animation) ? !!opts.animation : _animation; // values: true,false
        return _opts;
    }; // end _setOpts


    this.$get = ['$uibModal', function ($uibModal) {

        return {

            create: function (url, ctrlr, data, opts, ctrlAs) {
                var copy = opts && angular.isDefined(opts.copy) ? opts.copy : _copy;
                opts = _setOpts(opts);

                return $uibModal.open({
                    templateUrl: url,
                    controller: ctrlr,
                    controllerAs: ctrlAs,
                    keyboard: opts.kb,
                    backdrop: opts.bd,
                    backdropClass: opts.bdc,
                    windowClass: opts.wc,
                    size: opts.ws,
                    animation: opts.anim,
                    resolve: {
                        data: function () {
                            if (copy) {
                                return angular.copy(data);
                            }
                            else {
                                return data;
                            }
                        }
                    }
                }); // end modal.open
            } // end create

        }; // end return

    }]; // end $get
} // end provider dialogs
//== Dialogs.Main Module =====================================================//

/**
 * Include this module 'dialogs.main' in your module's dependency list where you
 * intend to use it.  Then inject the 'dialogs' service in your controllers that
 * need it.
 */