export default /*@ngInject*/ function ($http, errorsDataService) {
    var factory = {
        getControls,
        deleteControl,
        addNewControl,
        editControl,
        exportControls,
        getMitigations,
        getProducts,
        getBusinessProcesses,
        deleteAttachment,
        downloadAttachment,
        getAttachmentsList,
        addAttachments,
        importControlLibrary,
        importControlsFromPluginAPI
    };

    return factory;

    async function getControls() {
        try {
            return await $http.get(`${apiUrl}api/arm/controls`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteControl(controlId) {
        try {
            return await $http.delete(`${apiUrl}api/arm/controls/${controlId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addNewControl(control) {
        try {
            return await $http.post(`${apiUrl}api/arm/controls`, control);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function editControl(control) {
        try {
            return await $http.put(`${apiUrl}api/arm/controls/${control.Identifier}`, control);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function exportControls() {
        try {
            return await $http.get(`${apiUrl}api/arm/controls/export`, { responseType: "blob" });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getMitigations() {
        try {
            return await $http.get(`${apiUrl}api/arm/mitigations`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getProducts() {
        try {
            return await $http.get(`${apiUrl}api/universal/products`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getBusinessProcesses() {
        try {
            return await $http.get(`${apiUrl}api/arm/businessprocesses`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteAttachment(identifier) {
        try {
            return await $http.delete(`${apiUrl}api/core/attachments/${identifier}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function downloadAttachment(identifier) {
        try {
            return await $http.get(`${apiUrl}api/core/attachments/${identifier}/download`, { responseType: "blob" });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getAttachmentsList(dataTypeIdentifier) {
        try {
            return await $http.get(`${apiUrl}api/core/cases/data-type/3/identifier/${dataTypeIdentifier}`); 
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function addAttachments(data) {
        try {
            return await $http.put(`${apiUrl}api/core/attachments/`, data, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function importControlLibrary(fd) {
        try {
            return await $http.put(`${apiUrl}api/arm/controls/import`, fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function importControlsFromPluginAPI(plugin) {
        try {
            return await $http.put(`${apiUrl}api/arm/controls/import/${plugin}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }
}