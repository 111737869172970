import editRoleCompaniesModalController from "./editRoleCompaniesModal.controller";

export default /*@ngInject*/ function ($uibModal) {

    const vm = this;

    vm.openEditRoleCompaniesModal = openEditRoleCompaniesModal;
    vm.applyCompaniesToAllSelectedRoles = applyCompaniesToAllSelectedRoles;

    async function openEditRoleCompaniesModal() {
        const editRoleCompaniesModal = $uibModal.open({
            templateUrl: '/App/Shared/Components/identityManagerRoleCompanies/editRoleCompaniesModal.html',
            controller: editRoleCompaniesModalController,
            controllerAs: 'ercmvm',
            backdrop: 'static',
            resolve: {
                role: () => vm.selectedRole ? JSON.parse(JSON.stringify(vm.selectedRole)) : null,
                productId: () => vm.productId,
                availableCompanies: () => vm.availableCompanies
            },
            size: 'lg'
        });

        try {
            const roleCompaniesObject = await editRoleCompaniesModal.result;
            updateRoleCompanies(vm.selectedRole, roleCompaniesObject);
        } catch (err) {
            return;
        }
    }

    function updateRoleCompanies(selectedRole, roleCompaniesObject) {
        // overwrite companies for role based on user input from modal
        if (roleCompaniesObject.roleCompanies.length === 0) {
            roleCompaniesObject.roleCompanies = null;
        }
        vm.onUpdateCompanies({ selectedRole: selectedRole, prop: 'Companies', value: roleCompaniesObject.roleCompanies, applyToAll: roleCompaniesObject.applyToAll });
    }

    function applyCompaniesToAllSelectedRoles() {
        vm.onApplyCompaniesToAllRoles();
    }

}
