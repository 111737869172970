import controller from "./customProductConnection.controller";

/* connection.component.js */

/**
 * @desc component for custom product connections
 * @example <custom-product-connection></custom-product-connection>
 */

const customProductConnection = {
    bindings: {
        product: '<',
        radioSelected: '<',
        setRadioSelected: '<',
        latestImportArray: '<',
        currentConfig: '<',
        originalConfig: '<',
        allFpAuditConfigs: '<'
    },
    controller: controller,
    controllerAs: 'cpcvm',
    templateUrl: '/App/Components/Connections/CustomProductConnection/customProductConnection.tmpl.html'
};

export default customProductConnection;