import { customAutoCompleteFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($http, $q, $filter, helperService, errorsDataService) {

    const factory = {
        populateSystemNameIfCustom,
        changeFilter,
        SetInitialModifiedValueElseChangeModified,
        determineLabelClass,
        DeleteModificationToObject,
        AssignChildToParent,
        AssignChildObjectToParent,
        setDataSourceDefaultsAndMapProperties,
        formRemoveSecurityLayerSentance,
        createTablesModalDataSource,
        changeActionVerbiage,
        getSimulations,
        viewChanges,
        exportSimulationChanges,
        exportSimulationAnalysis,
        createRiskAnalysis,
        publishSimulation,
        deleteSimulation,
        changePublic
    };

    return factory;

    function populateSystemNameIfCustom(data) {
        data.forEach(function (row) {
            if (row.IsCustom === true) {
                row.SystemName = row.Name;
            }
        });
    }

    function changeFilter(selectedFilter, dataSource, filterField) {
        if (selectedFilter === "Changed") {
            var selectedFilter = { field: filterField, operator: "equalto", value: true };
            dataSource.filter(selectedFilter);
        } else if (selectedFilter === "Modified") {
            var selectedFilter = { field: "IsModified", operator: "equalto", value: true };
            dataSource.filter(selectedFilter);
        } else {
            dataSource.filter({});
        }
    }

    function SetInitialModifiedValueElseChangeModified(dataItem) {
        if (typeof dataItem.InitialModifiedValue === 'undefined') {
            dataItem.InitialModifiedValue = dataItem.IsModified;
            dataItem.IsModified = !dataItem.IsModified;
        } else {
            dataItem.IsModified = !dataItem.IsModified;
        }
    }

    function determineLabelClass(ActionName) {
        if (ActionName === 'Modify') {
            return 'label-modify';
        } else if (ActionName === 'Add') {
            return 'label-add';
        } else if (ActionName === 'Remove' || ActionName === 'Delete') {
            return 'label-remove';
        }
    }

    function DeleteModificationToObject(dataItem, selectedSimulation, objectOpenInModal, securityLayerType) {

        var deferred = $q.defer();

        $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + selectedSimulation.Id + '/securityobjectassociations').then(function (response) {
            var modification = response.data.filter(e => e.ChildObjectId.toLowerCase() === dataItem.Name.toLowerCase() && e.ParentObjectId.toLowerCase() === objectOpenInModal.SystemName.toLowerCase())[0];

            if (modification) {
                $http.delete(apiUrl + 'api/securitydesigner/ax7/simulation/securityobjectassociations/' + modification.Id).then(function (response) {
                    var selectedSecurityLayer = objectOpenInModal[securityLayerType].filter(e => e.SystemName.toLowerCase() === dataItem.Name.toLowerCase())[0];

                    selectedSecurityLayer.Create = dataItem.Create = selectedSecurityLayer.OldCreate || 'Unset';
                    selectedSecurityLayer.Delete = dataItem.Delete = selectedSecurityLayer.OldDelete || 'Unset';
                    selectedSecurityLayer.Invoke = dataItem.Invoke = selectedSecurityLayer.OldInvoke || 'Unset';
                    selectedSecurityLayer.Read = dataItem.Read = selectedSecurityLayer.OldRead || 'Unset';
                    selectedSecurityLayer.Update = dataItem.Update = selectedSecurityLayer.OldUpdate || 'Unset';
                    selectedSecurityLayer.IsModified = dataItem.IsModified = false;

                    if (securityLayerType === "Tables" || securityLayerType === "MenuItems" || securityLayerType === "DataEntities") {
                        if (selectedSecurityLayer.OldCreate === 'Unset' && selectedSecurityLayer.OldDelete === 'Unset' && selectedSecurityLayer.OldInvoke === 'Unset' && selectedSecurityLayer.OldRead === 'Unset' && selectedSecurityLayer.OldUpdate === 'Unset') {
                            objectOpenInModal[securityLayerType].remove(selectedSecurityLayer);
                        } else if (selectedSecurityLayer.OldCreate || selectedSecurityLayer.OldDelete || selectedSecurityLayer.OldInvoke || selectedSecurityLayer.OldRead || selectedSecurityLayer.OldUpdate) {
                            //Do nothing
                        } else {
                            objectOpenInModal[securityLayerType].remove(selectedSecurityLayer);
                        }

                        if (dataItem.Create !== 'Unset' || dataItem.Delete !== 'Unset' || dataItem.Read !== 'Unset' || dataItem.Update !== 'Unset') {
                            dataItem.ContainsAssigningValue = true;
                        } else {
                            dataItem.ContainsAssigningValue = false;
                        }
                    } else if (securityLayerType === "ServiceOperations") {
                        if (selectedSecurityLayer.OldInvoke === 'Unset') {
                            objectOpenInModal[securityLayerType].remove(selectedSecurityLayer);
                        } else if (selectedSecurityLayer.OldInvoke) {
                            //Do nothing
                        } else {
                            objectOpenInModal[securityLayerType].remove(selectedSecurityLayer);
                        }

                        if (dataItem.Invoke !== 'Unset') {
                            dataItem.ContainsAssigningValue = true;
                        } else {
                            dataItem.ContainsAssigningValue = false;
                        }
                    }

                    deferred.resolve();

                }, function errorCallback(response) {
                    deferred.reject();
                });
            }
        }, function errorCallback(response) {
            deferred.reject();
        });

        return deferred.promise;
    }

    function AssignChildToParent({ SimulationId, ChildItem, ChildType, ParentItem, ParentType, Action }) {

        var postData = {
            "SimulationId": SimulationId,
            "ParentChildAssociation": [
                {
                    "ParentObjectId": ParentItem.SystemName || ParentItem.Name,
                    "ParentObjectType": ParentType,
                    "ParentIsCustom": ParentItem.IsCustom,

                    "ChildObjectId": ChildItem.SystemName || ChildItem.Name,
                    "ChildObjectType": ChildType,
                    "ChildIsCustom": ChildItem.IsCustom,

                    "Action": Action
                }
            ]
        };

        $http.post(apiUrl + 'api/securitydesigner/ax7/simulation/' + SimulationId + '/securitylayerassociations', postData).then(function successCallback(response) {
            return;
        }, function errorCallback(response) {
            helperService.showErrorMessage(response.data);
        });
    }

    async function AssignChildObjectToParent({ SimulationId, ChildItem, ChildType, ParentItem, ParentType }) {

        var postData = {
            "SimulationId": SimulationId,
            "ParentObjectAssociation": [
                {
                    "ParentObjectId": ParentItem.SystemName,
                    "ParentObjectType": ParentType,
                    "ParentIsCustom": ParentItem.IsCustom,

                    "ChildObjectId": ChildItem.SystemName,
                    "ChildObjectType": ChildType,

                    "OldRead": ChildItem.OldRead,
                    "OldUpdate": ChildItem.OldUpdate,
                    "OldCreate": ChildItem.OldCreate,
                    "OldDelete": ChildItem.OldDelete,
                    "OldInvoke": ChildItem.OldInvoke,

                    "Read": ChildItem.Read,
                    "Update": ChildItem.Update,
                    "Create": ChildItem.Create,
                    "Delete": ChildItem.Delete,
                    "Invoke": ChildItem.Invoke
                }
            ]
        };

        try {
            return await $http.post(`${apiUrl}api/securitydesigner/ax7/simulation/${SimulationId}/securityobjectassociations`, postData)
        } catch ({ data: error }) {
            helperService.showErrorMessage(error);
        }
    }

    function setDataSourceDefaultsAndMapProperties(array, currentItems, menuItems) {
        array.forEach(function (row) {
            row.Create = 'Unset';
            row.Delete = 'Unset';
            row.Read = 'Unset';
            row.Update = 'Unset';
            row.Invoke = 'Unset';
            row.IsModified = false;
        });

        array.forEach(function (row) {

            for (var i = 0; i < currentItems.length; i++) {
                if (menuItems) {
                    if (row.Name.toLowerCase() === currentItems[i].SystemName.toLowerCase() && row.Type === currentItems[i].Type) {
                        row.Create = currentItems[i].Create;
                        row.Delete = currentItems[i].Delete;
                        row.Invoke = currentItems[i].Invoke;
                        row.IsModified = currentItems[i].IsModified;
                        row.OldCreate = currentItems[i].OldCreate;
                        row.OldDelete = currentItems[i].OldDelete;
                        row.OldInvoke = currentItems[i].OldInvoke;
                        row.OldRead = currentItems[i].OldRead;
                        row.OldUpdate = currentItems[i].OldUpdate;
                        row.Read = currentItems[i].Read;
                        row.SystemName = currentItems[i].SystemName;
                        row.Update = currentItems[i].Update;
                        row.Type = currentItems[i].Type;
                        break;
                    }
                } else {
                    if (row.Name.toLowerCase() === currentItems[i].SystemName.toLowerCase()) {
                        row.Create = currentItems[i].Create;
                        row.Delete = currentItems[i].Delete;
                        row.Invoke = currentItems[i].Invoke;
                        row.IsModified = currentItems[i].IsModified;
                        row.OldCreate = currentItems[i].OldCreate;
                        row.OldDelete = currentItems[i].OldDelete;
                        row.OldInvoke = currentItems[i].OldInvoke;
                        row.OldRead = currentItems[i].OldRead;
                        row.OldUpdate = currentItems[i].OldUpdate;
                        row.Read = currentItems[i].Read;
                        row.SystemName = currentItems[i].SystemName;
                        row.Update = currentItems[i].Update;
                        row.Type = currentItems[i].Type;
                        break;
                    }
                }
            }
        });
    }

    function formRemoveSecurityLayerSentance(data, securityLayerType) {
        if (securityLayerType === 1) {
            var message = 'Are you sure you want to remove this role?';

            if (data.Roles.length > 0) {
                message = 'This role is a sub-role of the following roles: ';
                data.Roles.forEach(function (row) {
                    message = message + row + ', ';
                });
                message = message.slice(0, -2);
                message = message + '. Are you sure you want to remove this role?';
            }
        } else if (securityLayerType === 2) {
            var message = 'Are you sure you want to remove this duty?';

            if (data.Roles.length > 0) {
                message = 'This duty belongs to the following roles: ';
                data.Roles.forEach(function (row) {
                    message = message + row + ', ';
                });
                message = message.slice(0, -2);
                message = message + '. Are you sure you want to remove this duty?';
            }
        } else if (securityLayerType === 3) {
            var message = 'Are you sure you want to remove this privilege?';
            var dutiesMessage = '';
            var rolesMessage = '';

            if (data.Duties.length > 0) {
                dutiesMessage = 'This privilege belongs to the following duties: ';
                data.Duties.forEach(function (row) {
                    dutiesMessage = dutiesMessage + row + ', ';
                });
                dutiesMessage = dutiesMessage.slice(0, -2);
                dutiesMessage = dutiesMessage + '. ';
            }

            if (data.Roles.length > 0) {
                rolesMessage = 'This privilege belongs to the following roles: ';
                data.Roles.forEach(function (row) {
                    rolesMessage = rolesMessage + row + ', ';
                });
                rolesMessage = rolesMessage.slice(0, -2);
                rolesMessage = rolesMessage + '. ';
            }

            message = rolesMessage + dutiesMessage + 'Are you sure you want to remove this privilege?';

        }
        return message;
    }

    function createTablesModalDataSource(item, vm) {
        var currentItems = item.Tables;


        var columns = [
            { title: "Name", field: "Name", filterable: customAutoCompleteFilter, width: 200 },
            { title: "Label", field: "Label", filterable: customAutoCompleteFilter, width: 200 },
            { title: "Read", field: "Read", template: `<select ng-disabled='pageIsReadOnly' class='inline-grid-dropdown' ng-change="actionDropdownChange('Read', dataItem)" ng-model='dataItem.Read'><option ng-repeat='action in tableActions'>{{action}}</option></select>`, filterable: customAutoCompleteFilter },
            { title: "Update", field: "Update", template: `<select ng-disabled='pageIsReadOnly' class='inline-grid-dropdown' ng-change="actionDropdownChange('Update', dataItem)" ng-model='dataItem.Update'><option ng-repeat='action in tableActions'>{{action}}</option></select>`, filterable: customAutoCompleteFilter },
            { title: "Create", field: "Create", template: `<select ng-disabled='pageIsReadOnly' class='inline-grid-dropdown' ng-change="actionDropdownChange('Create', dataItem)" ng-model='dataItem.Create'><option ng-repeat='action in tableActions'>{{action}}</option></select>`, filterable: customAutoCompleteFilter },
            { title: "Delete", field: "Delete", template: `<select ng-disabled='pageIsReadOnly' class='inline-grid-dropdown' ng-change="actionDropdownChange('Delete', dataItem)" ng-model='dataItem.Delete'><option ng-repeat='action in tableActions'>{{action}}</option></select>`, filterable: customAutoCompleteFilter },
            { title: "ContainsAssigningValue", field: "ContainsAssigningValue", hidden: true }
        ];

        if (!item.IsCustom) {
            columns.push({ title: " ", field: "IsModified", template: "<a ng-disabled='pageIsReadOnly' ng-click='deleteModificationToObject(dataItem)' ng-if='dataItem.IsModified' class='icons8-undo-2 pull-right'></a>", width: 50, filterable: false });
        }

        vm.manageSecurityLayerGridOptions = helperService.setMainGridOptions(columns, null);
        vm.manageSecurityLayerGridOptions.selectable = false;

        vm.manageSecurityLayerGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            sort: { field: "Name", dir: "asc" },
            transport: {
                read: function (options) {
                    vm.selectedSecurityDesignerTables = vm.securityDesignerTables.slice();
                    setDataSourceDefaultsAndMapProperties(vm.selectedSecurityDesignerTables, currentItems);

                    vm.selectedSecurityDesignerTables.forEach(function (row) {
                        if (row.Create !== 'Unset' || row.Delete !== 'Unset' || row.Read !== 'Unset' || row.Update !== 'Unset') {
                            row.ContainsAssigningValue = true;
                        } else {
                            row.ContainsAssigningValue = false;
                        }
                    });

                    vm.selectedSecurityDesignerTables = $filter('orderBy')(vm.selectedSecurityDesignerTables, 'Name');
                    options.success(vm.selectedSecurityDesignerTables);
                }
            }
        });
    }

    function changeActionVerbiage(data) {
        function changeActionVerbiage(array) {
            array.forEach(function (row) {
                if (row.Action === "Grant") {
                    row.Action = "Add";
                }
                if (row.Action === "Revoke") {
                    row.Action = "Remove";
                }
            });
        }

        function manipulateSecurityLayers(array) {
            array.forEach(function (row) {
                if (row.Action === "Grant" && !row.IsCustom) {
                    row.Action = "Modify";
                }
                if (row.Action === "Grant") {
                    row.Action = "Add";
                }
                if (row.Action === "Remove") {
                    row.Action = "Delete";
                }
                if (row.Duties) {
                    changeActionVerbiage(row.Duties);
                }
                if (row.Privileges) {
                    changeActionVerbiage(row.Privileges);
                }
                if (row.SubRoles) {
                    changeActionVerbiage(row.SubRoles);
                }
            });
        }

        manipulateSecurityLayers(data.ParentToChild);
        manipulateSecurityLayers(data.ParentToChild);
        manipulateSecurityLayers(data.ParentToChild);
    }

    function getSimulations() {
        return $http.get(apiUrl + 'api/securitydesigner/simulation').then(response => {
            return response;
        }).catch(response => {
            errorsDataService.catch(response);
        });
    }

    function viewChanges(sim) {
        return $http.get(apiUrl + 'api/securitydesigner/simulation/' + sim.Id + '/changes').then(response => {
            return response;
        }).catch(response => {
            errorsDataService.catch(response);
        });
    }

    function exportSimulationChanges(sim) {

        let data = {
            "SimulationId": sim.Id
        };

        return $http.post(apiUrl + 'api/securitydesigner/simulation/' + sim.Id + '/changes/export', data).then(response => {
            return response;
        }).catch(response => {
            errorsDataService.catch(response);
        });
    }

    function exportSimulationAnalysis(sim) {

        let data = {
            "SimulationId": sim.Id
        };

        return $http.post(apiUrl + 'api/securitydesigner/simulation/' + sim.Id + '/analysis/export', data).then(response => {
            return response;
        }).catch(response => {
            errorsDataService.catch(response);
        });
    }

    async function createRiskAnalysis(Id, formData) {
        try {
            return await $http.post(apiUrl + `api/securitydesigner/simulation/${Id}/analyze`, formData);
        } catch (err) {
            errorsDataService.catch(err);
        }
    }

    async function publishSimulation(Id, formData) {
        try {
            return await $http.post(apiUrl + `api/securitydesigner/simulation/${Id}/publish`, formData);
        } catch (err) {
            errorsDataService.catch(err);
        }
    }

    async function deleteSimulation(Id) {
        try {
            return await $http.delete(apiUrl + `api/securitydesigner/simulation/${Id}`);
        } catch (err) {
            errorsDataService.catch(err);
        }
    }

    async function changePublic(Id, formData) {
        try {
            return await $http.put(apiUrl + `api/securitydesigner/simulation/${Id}`, formData);
        } catch (err) {
            errorsDataService.catch(err);
            throw err;
        }
    }

}