import roleOrgLevelsListController from "./roleOrgLevelsList.controller";

/* roleOrgLevelsList.component.js */

/**
 * @desc component for viewing and maintaining list of sap security designer simulation role authorization objects
 * @example <sap-sd-simulation-role-auth-objects-list></sap-sd-simulation-role-auth-objects-list>
 */

const sapSdSimulationRoleOrgLevelsList = {
    require: {
        simulationsCtrl: '^sapSdSimulations'
    },
    bindings: {
        simulation: '<',
        selectedRole: '<',
        onSelectedOrgLevelChange: '&'
    },
    controller: roleOrgLevelsListController,
    controllerAs: 'rollvm',
    templateUrl: '/App/Components/SAPSecurityDesigner/simRoleOrgLevelsList/roleOrgLevelsList.tmpl.html'
};

export default sapSdSimulationRoleOrgLevelsList;