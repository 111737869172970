import { customAutoCompleteFilter, customBooleanFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import optionSetValueModalController from "./optionSetValueModal.controller";

export default /*@ngInject*/ function ($scope, $filter, $uibModal, helperService, optionSetsDataService) {

    const vm = this;
    vm.openOptionSetValueModal = openOptionSetValueModal;
    vm.changeOptionSet = changeOptionSet;

    activate();

    async function activate() {
        await getOptionSets();
        createOptionSetsGrid();
    }

    async function getOptionSets() {
        let response = await optionSetsDataService.getOptionSets();
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.optionSetsDataSource = response.data;
        vm.selectedOptionSet = response.data[0];
    }

    function changeOptionSet() {
        vm.optionSetsGridDataSource.read();
    }

    async function openOptionSetValueModal(item) {
        let openModal = $uibModal.open({
            templateUrl: '/App/Components/NetSuiteAuditTrail/MaintainOptionSets/optionSetValueModal.html',
            controller: optionSetValueModalController,
            controllerAs: 'osvvm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                item: () => item || {},
                optionSet: () => vm.selectedOptionSet || {}
            }
        });

        try {
            await openModal.result;
            vm.optionSetsGridDataSource.read()
        } catch {
            return;
        }
    }

    function createOptionSetsGrid() {
        var optionSetsGridColumns =
            [
                { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
                { field: "Active", title: "Active", filterable: customBooleanFilter },
                { field: "Id", title: "Id", hidden: true },
                { template: `<a ng-click="osvm.openOptionSetValueModal(dataItem)"><i class="glyphicon glyphicon-edit text-warning m-r-xs"></i>Edit</a>`, "title": "Edit", width: 90 }
            ];

        vm.optionSetsGridOptions = helperService.setMainGridOptions(optionSetsGridColumns, 500);
        vm.optionSetsGridOptions.selectable = false;

        vm.optionSetsGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: {
                model: {
                    fields: {
                        Active: { type: "boolean" }
                    }
                }
            },
            transport: {
                read: async options => {
                    let response = await optionSetsDataService.getOptionSetValuesByOptionSet(vm.selectedOptionSet.Id);
                    response.data = $filter('orderBy')(response.data, 'Name');
                    options.success(response.data);
                }
            }
        });
    }


}