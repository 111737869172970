import createSpinner from "../../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function (helperService, workdayMaintainTemplatesDataService, $filter, $uibModalInstance, template, isClone) {

    const vm = this;

    vm.template = { ...template };
    vm.isClone = isClone;

    vm.updateSelectedClassIds = updateSelectedClassIds;
    vm.saveTemplate = saveTemplate;
    vm.cancel = cancel;

    activate();

    function activate() {
        vm.modalTitle = template?.Id && !isClone ? 'Edit' : template?.Id && isClone ? 'Clone' : 'Create';

        if (isClone) {
            setupCloneTemplateGrid();
        }

        if (!template?.Id || isClone) {
            vm.template.IsEnabled = true;
        }
    }

    async function setupCloneTemplateGrid() {
        let classesGridOptions = [
            { template: "<label class='switch'><input type='checkbox' ng-model='dataItem.isSelected' ng-change='tmvm.updateSelectedClassIds(dataItem)'><i></i></label>", width: 55, filterable: false },
            { field: "ClassName", title: "Class Name", filterable: customAutoCompleteFilter }
        ];

        vm.cloneTemplateClassesGridOptions = helperService.setNonPageableGridOptions(classesGridOptions, 300);
        vm.cloneTemplateClassesGridOptions.noRecords = {
            template: "<div class='no-data-to-display-message'>No Classes exist for this template</div>"
        };

        let { data } = await workdayMaintainTemplatesDataService.getSelectedClasses(vm.template.Id);

        vm.templateClasses = $filter('orderBy')(data, 'ClassName');

        vm.templateClasses = vm.templateClasses?.map(templateClass => {
            templateClass.isSelected = true;

            return templateClass;
        });

        vm.cloneTemplateClassesGridDataSource = new kendo.data.DataSource({
            transport: {
                read: options => {
                    options.success(vm.templateClasses);
                }
            }
        });
    }

    function updateSelectedClassIds(selectedClass) {
        vm.templateClasses = vm.templateClasses.map(existingClass => {
            if (existingClass.ClassId === selectedClass.ClassId) {
                existingClass.isSelected = selectedClass.isSelected;
            }

            return existingClass;
        });
    }

    function saveTemplate() {
        vm.pleaseWait = new createSpinner();
        switch (vm.modalTitle) {
            case 'Create':
                createTemplate();
                break;
            case 'Edit':
                editTemplate();
                break;
            case 'Clone':
                cloneTemplate();
                break;
            default:
                break;
        }
    }

    async function createTemplate() {
        try {
            const postData = {
                Name: vm.template.Name,
                IsEnabled: vm.template.IsEnabled,
                ProductId: 'WD'
            }, { data } = await workdayMaintainTemplatesDataService.createTemplate(postData);
            await helperService.successfulSaveButton(vm.pleaseWait);
            close(data);
        } catch {
            vm.pleaseWait.loadingValue = false;
        }
    }

    async function editTemplate() {
        try {
            const putData = {
                Id: vm.template.Id,
                Name: vm.template.Name,
                IsEnabled: vm.template.IsEnabled
            }
            await workdayMaintainTemplatesDataService.updateTemplate(putData);
            await helperService.successfulSaveButton(vm.pleaseWait);
            close();
        } catch {
            vm.pleaseWait.loadingValue = false;
        }
    }

    async function cloneTemplate() {
        try {
            let selectedTemplateClasses = vm.templateClasses.filter(selectedClass => selectedClass.isSelected);
            const selectedClassIds = selectedTemplateClasses.map(selectedTemplateClass => selectedTemplateClass.ClassId),
                postData = {
                    "Name": vm.template.Name,
                    "Active": vm.template.IsEnabled,
                    "ClonedTemplateId": vm.template.Id,
                    "ClassIds": selectedClassIds
                }, { data } = await workdayMaintainTemplatesDataService.cloneTemplate(postData);
            await helperService.successfulSaveButton(vm.pleaseWait);
            close(data);
        } catch {
            vm.pleaseWait.loadingValue = false;
        }
    }


    function cancel() { $uibModalInstance.dismiss(); }

    function close(id) { $uibModalInstance.close(id); }
}