import createSpinner from "../../../Shared/Functions/createSpinner";
import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, helperService, approvalGroupsDataService, dataService, $filter, group, level, selectedApprovers, showWarning, fromEdit) {

    const vm = this;
    vm.level = Object.assign({}, level);
    vm.group = Object.assign({}, group);
    vm.showWarning = showWarning;
    vm.fromEdit = fromEdit;
    vm.dismiss = dismiss;
    vm.levelFormSubmit = levelFormSubmit;
    vm.allApproversRequiredChanged = allApproversRequiredChanged;
    vm.approversSelectionChanged = approversSelectionChanged;
    vm.shouldBeOpen = true;

    activate();

    function activate() {
        if (!vm.level.Id) {
            vm.level.RequiresAllApprovers = false;
            configureApproversGrid();
        }
    }

    function cancel(newLevelId) {
        $uibModalInstance.close(newLevelId);
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }

    function levelFormSubmit() {
        vm.updateInProgress = new createSpinner();
        const data = vm.level;
        vm.level.ApprovalGroupId = vm.group.Id;
        if (vm.level.Id) {
            updateLevel(data);
        } else {
            addNewLevel(data);
        }
    }

    async function addNewLevel(data) {
        try {
            let response = await approvalGroupsDataService.addApprovalGroupLevel(vm.group.Id, data);
            await helperService.successfulSaveButton(vm.updateInProgress);
            cancel(response.data);
        } catch {
            vm.updateInProgress.loadingValue = false;
        }
    }

    async function updateLevel(data) {
        try {
            await approvalGroupsDataService.updateGroupLevel(vm.group.Id, vm.level.Id, data);
            await helperService.successfulSaveButton(vm.updateInProgress);
            cancel();
        } catch {
            vm.updateInProgress.loadingValue = false;
        }
    }

    function allApproversRequiredChanged() {
        if (vm.level.RequiresAllApprovers) {
            if (vm.level.ApprovalsRequired) vm.level.ApprovalsRequired = null;
        }
    }

    function approversSelectionChanged() {
        vm.level.ApproverIds = vm.approversGrid.selectedKeyNames();
    }

    function configureApproversGrid() {
        const approversGridColumns = [
            { selectable: true, width: 50 },
            { title: "Name", field: "UserLogonName", filterable: customAutoCompleteFilter },
            { title: "Email", field: "Email", filterable: customAutoCompleteFilter }
        ];

        vm.approversGridOptions = helperService.setMainGridOptions(approversGridColumns, null);
        vm.approversGridOptions.selectable = false;
        vm.approversGridOptions.persistSelection = true;
        vm.approversGridOptions.noRecords = {
            template: "<div class='no-data-to-display-message'>All Approvers Are Currently Assigned</div>"
        };

        vm.approversGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            schema: {
                model: {
                    id: "UserID"
                }
            },
            transport: {
                read: async options => {
                    try {
                        let response = await dataService.getUsers();
                        response.data = $filter('orderBy')(response.data, 'UserLogonName');
                        response.data = response.data.filter(obj => obj.Active && !selectedApprovers.some(o => o.UserId === obj.UserID));
                        options.success(response.data);
                    } catch (error) {
                        options.error(error);
                    }

                }
            }
        });
    }
}