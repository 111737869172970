import requesterGroupUsersListController from "./requesterGroupUsersList.controller";

/* requesterGroupUsersList.component.js */

/**
 * @desc component for viewing and maintaining list of requester group users
 * @example <requester-group-users-list><requester-group-users-list>
 */

const requesterGroupUsersList = {
    bindings: {
        requesterGroup: '<'
    },
    controller: requesterGroupUsersListController,
    controllerAs: 'rgulvm',
    templateUrl: '/App/Components/RequesterGroups/requesterGroupUsersList/requesterGroupUsersList.tmpl.html'
};

export default requesterGroupUsersList;