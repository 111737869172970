export default /*@ngInject*/ function (authenticationService) {
    setupRaygun();

    async function setupRaygun() {
        const authenticated = await authenticationService.isAuthenticated();
        let currentUser;

        rg4js('apiKey', raygunApiKey);
        rg4js('options', {
            enableCrashReporting: true,
            debugMode: true,
            disablePulse: true,
            excludedHostnames: ['localhost']
        });

        if (authenticated) {
            currentUser = await authenticationService.getUser();
        }

        if (currentUser) {
            setUser(currentUser);
        }
    }
}

function setUser (currentUser) {
    let localTenant,
        user = {
            identifier: currentUser.userId,
            fullName: currentUser.fullName,
            email: currentUser.email,
            isAnonymous: false
        };

    localTenant = localStorage.getItem("selectedTenant");

    if (localTenant) {
        localTenant = JSON.parse(localTenant);
        user.uuid = localTenant.ID;
    }

    rg4js('setUser', user);
}