export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getNotificationTypes,
        getSubscriptions,
        getUsers,
        createSubscription,
        removeSubscription,
        updateTypeDescription
    };

    return service;

    async function getNotificationTypes() {
        try {
            return await $http.get(`${apiUrl}api/core/notificationtypes`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getSubscriptions() {
        try {
            return await $http.get(`${apiUrl}api/core/notificationsubscriptions`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getUsers() {
        try {
            return await $http.get(`${apiUrl}api/core/users`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function createSubscription(data) {
        try {
            return await $http.post(`${apiUrl}api/core/notificationsubscriptions`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function removeSubscription(id) {
        try {
            return await $http.delete(`${apiUrl}api/core/notificationsubscriptions/${id}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateTypeDescription(id, data) {
        try {
            return await $http.put(`${apiUrl}api/core/notificationtypes/${id}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

    function sendError(err) {
        throw err;
    }
}