/**
 * @desc directive to validate oracle password requirements
 * @example <input type="password" oracle-password />
 */
export default function () {
    var directive = {
        link: link,
        require: 'ngModel'
    };
    return directive;

    function link(scope, elm, attrs, ctrl) {
        ctrl.$parsers.unshift(function (password) {
            var hasNumbers = /\d/.test(password);

            if (password.length === 0) {
                ctrl.$setValidity('complexity', true);
                return password;
            } else {
                if (password.length >= 8 && hasNumbers) {
                    ctrl.$setValidity('complexity', true);
                    return password;
                }
                else {
                    ctrl.$setValidity('complexity', false);
                    return undefined;
                }
            }

        });
    }
}