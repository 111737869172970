export default /*@ngInject*/ function ($http) {

    this.getNAVLicenseTypes = function () {

        return [
            {
                Name: 'Full User',
                Id: 0
            },
            {
                Name: 'Limited User',
                Id: 1
            },
            {
                Name: 'Device Only User',
                Id: 2
            },
            {
                Name: 'Windows Group',
                Id: 3
            },
            {
                Name: 'External User',
                Id: 4
            }
        ];
    };


    this.getExistingNAVUserSecurity = function (userId) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/nav/employees/userid/' + userId + '/'
        });
    };

    this.getAllPermissionSets = function () {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/nav/permissionsets'
            });
        }
        catch (ex) {
            throw 'Error loading NAV Permission Sets';
        }
    };

    this.getAllCompanies = function () {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/nav/companies'
            });
        }
        catch (ex) {
            throw 'Error loading NAV Companies';
        }
    };

    this.getAllProfiles = function () {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/nav/profiles'
            });
        }
        catch (ex) {
            throw 'Error loading NAV Profiles';
        }
    };

    this.getAllUserGroups = function () {
        try {
            return $http({
                method: 'GET',
                url: apiUrl + 'api/identitymanager/nav/usergroups'
            });
        }
        catch (ex) {
            throw 'Error loading NAV User Groups';
        }
    };

    this.getClassesBySubsidiary = function (subsidiaryId) {
        return $http({
            method: 'GET',
            url: apiUrl + 'api/identitymanager/nav/subsidiaries/' + subsidiaryId + '/classes'
        });
    };


}