import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function manageDataConfigurationController(manageDataDataService, helperService) {
    var vm = this;

    vm.updateConfigValue = updateConfigValue;
    vm.configValueChanged = configValueChanged;
    
    async function updateConfigValue() {
        vm.updateInProgress = new createSpinner();

        let data = {
            "Configurations": [],
            "ProductId": vm.product.ID
        };

        data.Configurations.push({
            "Name": vm.action.configValue,
            "Value": vm.product.configuration[vm.action.configValue]
        });

        await manageDataDataService.setProductConfiguration(vm.product.ID, data);
        helperService.successfulSaveButton(vm.updateInProgress);
    }

    function configValueChanged() {
        var configValue = vm.action.configValue;
        var changedConfigurations = vm.product.changedConfigurations;

        if (vm.product.configuration[configValue] !== vm.product.oldConfig[configValue]) {
            // we have a new value so let's check if this config value is in our array
            if (!changedConfigurations.includes(configValue)) {
                // ok let's track this change
                changedConfigurations.push(configValue);
            }
        } else {
            // the value was changed back to the original value so we need to remove it from the array
            var index = changedConfigurations.indexOf(configValue);
            changedConfigurations.splice(index, 1);
        }
    }
}
