import { ax7_application_id, oracle_application_id, oracleCloud_application_id, gp_application_id, nav_application_id, d365s_application_id, netsuite_application_id, sap_application_id, ax_application_id, universalProduct_application_id, aad_application_id, ps_application_id, salesforce_application_id } from "../../Shared/GlobalVariables/applicationIdVariables";

export default /*@ngInject*/ function ($http) {
    var self = this;
    //Build request for executeRequest function, isFinishRequest adds in any revoked roles from the available role lists
    this.buildRequest = function (vm, isFinishRequest) {

        var request = self.buildHeaderRequest(vm),
            isTerminate = vm.wizardMode === "Terminate";

        var globalPermissionsWithUnusedRemoved = [];
        //Removes all levels from object and populates it with 1 selected Level
        if (vm.ListOfSelectedNetSuitePermissions != null) {
            globalPermissionsWithUnusedRemoved = vm.ListOfSelectedNetSuitePermissions && vm.ListOfSelectedNetSuitePermissions.map(permission => ({ ...permission }));
        }

        if (globalPermissionsWithUnusedRemoved.length > 0) {
            globalPermissionsWithUnusedRemoved.forEach(function (row) {
                row.Levels = [row.selectedLevel];
                delete row.selectedLevel;
                delete row.Pending;
            });
        }

        var netSuiteRequest = {};
        var nsApplication = vm.availableApplications.find(function (application) {
            return application.Id == netsuite_application_id;
        });

        if (vm.User.NetSuiteUser != null && nsApplication != null && (nsApplication?.selected || vm.wizardMode === 'Terminate' && nsApplication?.selected == false)) {

            var nsRoles = vm.ListOfSelectedNetSuiteRoles && vm.ListOfSelectedNetSuiteRoles.map(role => ({ ...role })),
                nsPermissions = [];

            if (!isTerminate && isFinishRequest) {
                nsRoles = vm.ReturnedRequest?.NetSuiteRequest?.Roles.map(removePendingKey);
                nsPermissions = vm.ReturnedRequest?.NetSuiteRequest?.GlobalPermissions;
            } else if (!isTerminate && !isFinishRequest) {
                nsRoles = nsRoles && nsRoles.map(removePendingKey);
                nsPermissions = globalPermissionsWithUnusedRemoved;
            } else {
                nsRoles = [];
                nsPermissions = [];
            }

            netSuiteRequest = {
                NetSuiteRequest: {
                    User: {
                        Id: vm.User.NetSuiteUser.Id,
                        ProductId: vm.User.NetSuiteUser.ProductId,
                        FirstName: vm.User.NetSuiteUser.FirstName,
                        LastName: vm.User.NetSuiteUser.LastName,
                        Email: vm.User.NetSuiteUser.Email,
                        Subsidiary: vm.User.NetSuiteUser.Subsidiary,
                        Password: vm.User.NetSuiteUser.Password,
                        Department: vm.User.NetSuiteUser.Department == null || vm.User.NetSuiteUser.Department.Id == '' ? null : vm.User.NetSuiteUser.Department,
                        Class: vm.User.NetSuiteUser.Class == null || vm.User.NetSuiteUser.Class.Id == '' ? null : vm.User.NetSuiteUser.Class,
                        Location: vm.User.NetSuiteUser.Location == null || vm.User.NetSuiteUser.Location.Id == '' ? null : vm.User.NetSuiteUser.Location,
                        Phone: vm.User.NetSuiteUser.Phone,
                        MobilePhone: vm.User.NetSuiteUser.MobilePhone,
                        JobTitle: vm.User.NetSuiteUser.JobTitle,
                        Inactive: vm.User.NetSuiteUser.Inactive,
                        GiveAccess: vm.User.NetSuiteUser.GiveAccess,
                        StartDate: vm.User.NetSuiteUser.StartDate,
                        EndDate: vm.User.NetSuiteUser.EndDate,
                        IsSalesRep: vm.User.NetSuiteUser.IsSalesRep,
                        IsSupportRep: vm.User.NetSuiteUser.IsSupportRep,
                        Action: vm.User.NetSuiteUser.Action,
                        RequirePasswordChange: vm.User.NetSuiteUser.RequirePasswordChange,
                        SendNotificationEmail: vm.User.NetSuiteUser.SendNotificationEmail,
                        UserChanges: vm.User.NetSuiteUser.UserChanges
                    },
                    Roles: nsRoles,
                    GlobalPermissions: nsPermissions
                }
            };
        }
        else {
            netSuiteRequest = {
                NetSuiteRequest: null
            };
        }
        //Add NS request
        request = $.extend({}, request, netSuiteRequest);

        //SAP Request
        var sapApplication = vm.availableApplications.filter(function (application) {
            return application.Id == sap_application_id;
        });

        var sapRequest = {};
        if (vm.User.SAPUser) {
            sapRequest.SAPRequest = [];
        } else {
            sapRequest.SAPRequest = null;
        }
        sapApplication.forEach(application => {
            var productSapRequest = {};
            if (vm.User.SAPUser) {
                if (vm.User.SAPUser[application.ProductName] != null && application != null && application?.selected == true) {

                    function getRoles() {
                        if (vm.wizardMode !== 'Terminate') {
                            if (isFinishRequest) {
                                if (vm.ReturnedRequest.SAPRequest.some(request => request.ProductId === application.ProductID)) {
                                    return vm.ReturnedRequest.SAPRequest.find(request => request.ProductId === application.ProductID).Roles;
                                } else {
                                    return [];
                                }
                            } else {
                                let allRoles = vm.ListOfSelectedSAPSingleRoles[application.ProductName].concat(vm.ListOfSelectedSAPCompositeRoles[application.ProductName]);
                                return allRoles;
                            }
                        } else {
                            return [];
                        }
                    }

                    function findSAPUserIndex() {
                        return vm.ReturnedRequest.SAPRequest.findIndex(request => request.ProductId === application.ProductID);
                    }

                    function getSAPUserAction() {
                        let action = null;

                        if (isFinishRequest && vm.alternatePath !== 'EmergencyAccess') {
                            if (vm.ReturnedRequest) {
                                if (vm.ReturnedRequest.SAPRequest[findSAPUserIndex()].User) {
                                    action = vm.ReturnedRequest.SAPRequest[findSAPUserIndex()].User.Action;
                                }
                            } else {
                                action = vm.User.SAPUser[application.ProductName].Action;
                            }
                        }
                        return action;
                    }

                    let isEmergencyAccess = vm.alternatePath === "EmergencyAccess" ? true : false;
                    productSapRequest = {
                        "ProductId": application.ProductID,
                        "User": {
                            "ProductId": application.ProductID,
                            "Action": getSAPUserAction(),
                            "Status": vm.User.SAPUser[application.ProductName].Status,
                            "StartDate": vm.User.SAPUser[application.ProductName].StartDate,
                            "EndDate": vm.User.SAPUser[application.ProductName].EndDate,
                            "Active": vm.User.SAPUser[application.ProductName].Active,
                            "UserStartDate": vm.User.SAPUser[application.ProductName].UserStartDate,
                            "UserEndDate": vm.User.SAPUser[application.ProductName].UserEndDate,
                            "Email": vm.User.SAPUser[application.ProductName].Email,
                            "FirstName": vm.User.SAPUser[application.ProductName].FirstName,
                            "LastName": vm.User.SAPUser[application.ProductName].LastName,
                            "UserName": vm.User.SAPUser[application.ProductName].UserName,
                            "Street1": vm.User.SAPUser[application.ProductName].Street1,
                            "SNC": vm.User.SAPUser[application.ProductName].SNC,
                            "State": vm.User.SAPUser[application.ProductName].State,
                            "TimeZone": vm.User.SAPUser[application.ProductName].TimeZone,
                            "Title": vm.User.SAPUser[application.ProductName].Title,
                            "UserGroup": vm.User.SAPUser[application.ProductName].UserGroup,
                            "UserType": vm.User.SAPUser[application.ProductName].UserType === null ? null : vm.User.SAPUser[application.ProductName].UserType,
                            "AccountId": vm.User.SAPUser[application.ProductName].AccountId,
                            "City": vm.User.SAPUser[application.ProductName].City,
                            "Password": vm.User.SAPUser[application.ProductName].Password,
                            "UserChanges": vm.User.SAPUser[application.ProductName].UserChanges
                        },
                        "Roles": getRoles().map(removePendingKey),
                        "IsEmergencyAccess": isEmergencyAccess,
                        "EmergencyAccessObjects": isEmergencyAccess && vm.ListOfSelectedSAPRoleObjects[application.ProductName] ? vm.ListOfSelectedSAPRoleObjects[application.ProductName].map(o => o.Identifier) : []
                    };
                    sapRequest.SAPRequest.push(productSapRequest);
                }
            } else {
                sapRequest.SAPRequest = null;
            }
        });
        request = $.extend({}, request, sapRequest);

        //UP Request
        var universalApplication = vm.availableApplications.filter(function (application) {
            return application.Id == universalProduct_application_id;
        });

        var universalProductRequest = {};
        if (vm.User.UPUser) {
            universalProductRequest.UniversalRequest = [];
        } else {
            universalProductRequest.UniversalRequest = null;
        }
        universalApplication.forEach(application => {
            var productUpRequest = {};
            if (vm.User.UPUser) {
                if (vm.User.UPUser[application.ProductName] != null && application != null && application?.selected == true) {
                    let upRoles = [];

                    if (vm.ListOfSelectedUPRoles[application.ProductName] != null) {
                        vm.ListOfSelectedUPRoles[application.ProductName].forEach(role => {
                            if (role.Companies != null) {

                                role.Companies.forEach(company => {
                                    upRoles.push(
                                        {
                                            "UserId": vm.User.UPUser[application.ProductName].Id,
                                            "Action": role.Action,
                                            "StartDate": company?.StartDate,
                                            "EndDate": company?.EndDate,
                                            "Role": {
                                                "Id": role.Id,
                                                "Name": role.Name,
                                                "Description": role.Description,
                                                "Identifier": role.Identifier,
                                                "ProductId": application.ProductID,
                                                "ProductName": application.ProductName
                                            },
                                            "Company": {
                                                "Id": company?.CompanyId,
                                                "Name": company?.CompanyName,
                                                "ProductId": company?.ProductId
                                            }
                                        });
                                });
                            } else {
                                upRoles.push(
                                    {
                                        "UserId": vm.User.UPUser[application.ProductName].Id,
                                        "Action": role.Action,
                                        "StartDate": role.StartDate,
                                        "EndDate": role.EndDate,
                                        "Role": {
                                            "Id": role.Id,
                                            "Name": role.Name,
                                            "Description": role.Description,
                                            "Identifier": role.Identifier,
                                            "ProductId": application.ProductID,
                                            "ProductName": application.ProductName
                                        },
                                        "Company": null
                                    });
                            }
                        });
                    }

                    function findUPUserIndex() {
                        return vm.ReturnedRequest.UniversalRequest.findIndex(request => request.ProductId === application?.ProductID);
                    }

                    function getUPUserAction() {
                        let action = null;

                        if (isFinishRequest && vm.ReturnedRequest) {
                                if (vm.ReturnedRequest.UniversalRequest[findUPUserIndex()].User) {
                                    action = vm.ReturnedRequest.UniversalRequest[findUPUserIndex()].User.Action;
                                }  else {
                                    action = vm.User.UPUser[application.ProductName].Action;
                            }
                        }
                        return action;
                    }

                    if (!isTerminate && isFinishRequest) {
                        upRoles = vm.ReturnedRequest.UniversalRequest.find(request => request.ProductName === application?.ProductName).UserRoles;
                    } else if (isTerminate && !isFinishRequest) {
                        upRoles = [];
                    }

                    productUpRequest = {
                        "ProductId": application.ProductID,
                        "ProductName": application.ProductName,
                        "UserId": vm.User.UPUser[application.ProductName].Id,
                        "User": {
                            "ProductId": application.ProductID,
                            "Action": isTerminate ? "Delete" : getUPUserAction(),
                            "Id": vm.User.UPUser[application.ProductName].Id,
                            "Name": vm.User.UPUser[application.ProductName].Name,
                            "Description": vm.User.UPUser[application.ProductName].Description,
                            "Email": vm.User.UPUser[application.ProductName].Email,
                            "UserChanges": vm.User.UPUser[application.ProductName].UserChanges
                        },
                        "UserRoles": upRoles
                    };
                    universalProductRequest.UniversalRequest.push(productUpRequest);
                }
            } else {
                universalProductRequest.UniversalRequest = null;
            }
        });
        request = $.extend({}, request, universalProductRequest);

        //AAD Request
        var aadRequest = {};
        var aadApplication = vm.availableApplications.find(function (application) {
            return application.Id == aad_application_id;
        });

        if (vm.User.AadUser != null && vm.User.AadUser.UserPrincipalName != null && aadApplication?.selected == true) {
            let aadRoles = vm.ListOfSelectedAADRoles && vm.ListOfSelectedAADRoles.map(role => ({ ...role })),
                aadGroups = vm.ListOfSelectedAADGroups && vm.ListOfSelectedAADGroups.map(group => ({ ...group })),
                aadLicenses = vm.ListOfSelectedAADLicenses && vm.ListOfSelectedAADLicenses.map(license => ({ ...license }));

            if (!isTerminate && isFinishRequest) {
                aadRoles = vm.ReturnedRequest?.AadRequest?.Roles.map(removePendingKey);
                aadGroups = vm.ReturnedRequest?.AadRequest?.Groups.map(removePendingKey);
                aadLicenses = vm.ReturnedRequest?.AadRequest?.Licenses.map(removePendingKey);
            } else if (!isTerminate && !isFinishRequest) {
                aadRoles = aadRoles && aadRoles.map(removePendingKey);
                aadGroups = aadGroups && aadGroups.map(removePendingKey);
                aadLicenses = aadLicenses && aadLicenses.map(removePendingKey);
            } else {
                aadRoles = [];
                aadGroups = [];
                aadLicenses = [];
            }

            aadRequest = {
                AadRequest: {
                    User: {
                        AccountEnabled: vm.User.AadUser.AccountEnabled,
                        BusinessPhones: !vm.User.AadUser.BusinessPhones ? [] : Array.isArray(vm.User.AadUser.BusinessPhones) ? vm.User.AadUser.BusinessPhones : [vm.User.AadUser.BusinessPhones],
                        City: vm.User.AadUser.City,
                        Country: vm.User.AadUser.Country,
                        Department: vm.User.AadUser.Department,
                        DisplayName: vm.User.AadUser.DisplayName,
                        GivenName: vm.User.AadUser.GivenName,
                        Id: vm.User.AadUser.Id,
                        JobTitle: vm.User.AadUser.JobTitle,
                        Mail: vm.User.AadUser.Mail,
                        MobilePhone: vm.User.AadUser.MobilePhone,
                        OfficeLocation: vm.User.AadUser.OfficeLocation,
                        PostalCode: vm.User.AadUser.PostalCode,
                        ProductId: vm.User.AadUser.ProductId,
                        State: vm.User.AadUser.State,
                        StreetAddress: vm.User.AadUser.StreetAddress,
                        SurName: vm.User.AadUser.SurName,
                        UsageLocation: vm.User.AadUser.UsageLocation,
                        UserPrincipalName: vm.User.AadUser.Domain ? vm.User.AadUser.UserPrincipalName + '@' + vm.User.AadUser.Domain : vm.User.AadUser.UserPrincipalName,
                        UserType: "Member",
                        Manager: vm.User.AadUser.Manager,
                        StartDate: vm.User.AadUser.StartDate,
                        EndDate: vm.User.AadUser.EndDate,
                        Action: vm.User.AadUser.Action,
                        StartDate: vm.User.AadUser.StartDate,
                        EndDate: vm.User.AadUser.EndDate,
                        PersonUser: vm.User.AadUser.PersonUser,
                        PasswordProfile: vm.User.AadUser.PasswordProfile ? {
                            ForceChangePasswordNextSignIn: vm.User.AadUser.PasswordProfile.ForceChangePasswordNextSignIn,
                            Password: vm.User.AadUser.PasswordProfile.Password
                        } : null,
                        UserChanges: vm.User.AadUser.UserChanges
                    },
                    Groups: aadGroups,
                    Roles: aadRoles,
                    Licenses:aadLicenses
                }

            };
        }
        else {
            aadRequest = {
                AadRequest: null
            };
        }

        request = $.extend({}, request, aadRequest);

        //AX7 Request
        var ax7Request = {};
        var axApplication = vm.availableApplications.find(function (application) {
            return application.Id == ax7_application_id;
        });

        if (vm.User.AX7User != null && vm.User.AX7User.UserId != null && axApplication?.selected == true) {
            let ax7UserGroups = vm.ListOfSelectedAX7UserGroups && vm.ListOfSelectedAX7UserGroups.map(group => ({ ...group })),
                ax7RoleCompanies = [];

            if (vm.ListOfSelectedAX7Roles != null) {
                vm.ListOfSelectedAX7Roles.forEach(role => {
                    if (role.Companies != null) {

                        role.Companies.forEach(function (company) {
                            ax7RoleCompanies.push(
                                {
                                    UserId: vm.User.AX7User.UserId,
                                    Action: role.Action,
                                    StartDate: company.StartDate,
                                    EndDate: company.EndDate,
                                    Role: {
                                        Id: role.Id,
                                        Name: role.Name,
                                        Description: role.Description
                                    },
                                    Company: {
                                        CompanyId: company.CompanyId,
                                        CompanyName: company.CompanyName,
                                        CompanyType: company.CompanyType
                                    }
                                });
                        });
                    } else {
                        ax7RoleCompanies.push(
                            {
                                UserId: vm.User.AX7User.UserId,
                                Action: role.Action,
                                StartDate: role.StartDate,
                                EndDate: role.EndDate,
                                Role: {
                                    Id: role.Id,
                                    Name: role.Name,
                                    Description: role.Description
                                },
                                Company: null
                            });
                    }
                });
            }


            if (!isTerminate && isFinishRequest) {
                ax7UserGroups = vm.ReturnedRequest?.Ax7Request?.UserGroups.map(removePendingKey);
                ax7RoleCompanies = vm.ReturnedRequest?.Ax7Request?.RoleCompanies.map(removePendingKey);
            } else if (!isTerminate && !isFinishRequest) {
                ax7UserGroups = ax7UserGroups && ax7UserGroups.map(removePendingKey);
            } else {
                ax7UserGroups = [];
                ax7RoleCompanies = [];
            }

            ax7Request = {
                Ax7Request: {
                    User: {
                        ProductId: vm.User.AX7User.ProductId,
                        Company: vm.User.AX7User.Company == null ? null : vm.User.AX7User.Company,
                        Email: vm.User.AX7User.Email,
                        NetworkDomain: vm.User.AX7User.NetworkDomain,
                        UserId: vm.User.AX7User.UserId,
                        UserName: vm.User.AX7User.UserName,
                        Person: vm.User.AX7User.Person,
                        Enabled: vm.User.AX7User.Enabled,
                        StartDate: vm.User.AX7User.StartDate,
                        EndDate: vm.User.AX7User.EndDate,
                        Action: vm.User.AX7User.Action,
                        PersonUser: vm.User.AX7User.PersonUser,
                        UserChanges: vm.User.AX7User.UserChanges
                    },
                    RoleCompanies: ax7RoleCompanies,
                    UserGroups: ax7UserGroups,
                    //If wizard mode is Modify, current user security was pulled from initiate request - populate it here.  Otherwise, null.  API will ignore if null.
                    CurrentUser: vm.wizardMode === 'Modify' && !vm.requestToEdit ? vm.User.AX7User.CurrentAx7User : null
                }
            };

        }
        else {
            ax7Request = {
                Ax7Request: null
            };
        }

        request = $.extend({}, request, ax7Request);

        //AX2012 Request
        var ax2012Request = {};
        var axApplication = vm.availableApplications.find(function (application) {
            return application.Id == ax_application_id;
        });

        if (vm.User.AX2012User != null && axApplication != null && axApplication?.selected == true) {
            let ax2012UserGroups = vm.ListOfSelectedAX2012UserGroups && vm.ListOfSelectedAX2012UserGroups.map(group => ({ ...group })),
                ax2012RoleCompanies = [];

            if (vm.ListOfSelectedAX2012Roles != null) {
                vm.ListOfSelectedAX2012Roles.forEach(function (role) {
                    if (role.Companies != null && role?.Companies?.length > 0) {

                        role.Companies.forEach(function (company) {
                            ax2012RoleCompanies.push(
                                {
                                    UserId: vm.User.AX2012User.UserId,
                                    Action: role.Action,
                                    StartDate: company.StartDate,
                                    EndDate: company.EndDate,
                                    Role: {
                                        Id: role.Id,
                                        Name: role.Name,
                                        Description: role.Description
                                    },
                                    Company: {
                                        CompanyId: company.CompanyId,
                                        CompanyName: company.CompanyName
                                    }
                                });
                        });
                    } else {
                        ax2012RoleCompanies.push(
                            {
                                UserId: vm.User.AX2012User.UserId,
                                Action: role.Action,
                                StartDate: role.StartDate,
                                EndDate: role.EndDate,
                                Role: {
                                    Id: role.Id,
                                    Name: role.Name,
                                    Description: role.Description
                                },
                                Company: null
                            });
                    }
                });
            }

            if (!isTerminate && isFinishRequest) {
                ax2012UserGroups = vm.ReturnedRequest?.AxRequest?.UserGroups.map(removePendingKey);
                ax2012RoleCompanies = vm.ReturnedRequest?.AxRequest?.RoleCompanies.map(removePendingKey);
            } else if (!isTerminate && !isFinishRequest) {
                ax2012UserGroups = ax2012UserGroups && ax2012UserGroups.map(removePendingKey);
            } else {
                ax2012UserGroups = [];
                ax2012RoleCompanies = [];
            }

            ax2012Request = {
                AxRequest: {
                    User: {
                        ProductId: vm.User.AX2012User.ProductId,
                        Company: vm.User.AX2012User.Company == null ? null : vm.User.AX2012User.Company,
                        Email: vm.User.AX2012User.Email,
                        UserId: vm.wizardMode == 'Modify' || vm.wizardMode == 'Terminate' ? vm.User.AX2012User.UserId : vm.User.AX2012User.DisplayUserId,
                        UserName: vm.User.AX2012User.UserName,
                        Person: vm.User.AX2012User.Person,
                        Enabled: vm.User.AX2012User.Enabled,
                        StartDate: vm.User.AX2012User.StartDate,
                        EndDate: vm.User.AX2012User.EndDate,
                        Action: vm.User.AX2012User.Action,
                        Partition: vm.User.AX2012User.Partition == null ? null : vm.User.AX2012User.Partition.Id,
                        PartitionKey: vm.User.AX2012User.Partition == null ? null : vm.User.AX2012User.Partition.Key,
                        SID: vm.User.AX2012User.SID,
                        NetworkDomain: vm.User.AX2012User.NetworkDomain,
                        NetworkAlias: vm.User.AX2012User.NetworkAlias,
                        UserChanges: vm.User.AX2012User.UserChanges
                    },
                    RoleCompanies: ax2012RoleCompanies,
                    UserGroups: ax2012UserGroups,
                    //If wizard mode is Modify, current user security was pulled from initiate request - populate it here.  Otherwise, null.  API will ignore if null.
                    CurrentUser: vm.wizardMode === 'Modify' && !vm.requestToEdit ? vm.User.AX2012User.CurrentAX2012User : null
                }
            };
        }
        else {
            ax2012Request = {
                "AxRequest": null
            };
        }

        request = $.extend({}, request, ax2012Request);

        //ORACLE Request
        var oracleApplication = vm.availableApplications.find(function (application) {
            return application.Id == oracle_application_id;
        });

        var oracleRequest = {};

        if (vm.User.OracleUser != null && oracleApplication != null && oracleApplication?.selected == true) {
            let oracleResponsibilities = vm.ListOfSelectedOracleResponsibilities && vm.ListOfSelectedOracleResponsibilities.map(responsibility => ({ ...responsibility })),
                oracleRoles = vm.ListOfSelectedOracleRoles && vm.ListOfSelectedOracleRoles.map(role => ({ ...role }));

            if (!isTerminate && isFinishRequest) {
                oracleResponsibilities = vm.ReturnedRequest?.EbsRequest?.Responsibilities.map(removePendingKey);
                oracleRoles = vm.ReturnedRequest?.EbsRequest?.Roles.map(removePendingKey);
            } else if (!isTerminate && !isFinishRequest) {
                oracleResponsibilities = oracleResponsibilities && oracleResponsibilities.map(removePendingKey);
                oracleRoles = oracleRoles && oracleRoles.map(removePendingKey)
            } else {
                oracleResponsibilities = [];
                oracleRoles = [];
            }

            oracleRequest = {
                "EbsRequest": {
                    "User": {
                        "ProductId": vm.User.OracleUser.ProductId,
                        "Action": vm.User.OracleUser.Action,
                        "StartDate": vm.User.OracleUser.StartDate,
                        "EndDate": vm.User.OracleUser.EndDate,
                        "ID": vm.User.OracleUser.ID,
                        "Name": vm.User.OracleUser.Name,
                        "Description": vm.User.OracleUser.Description,
                        "EmployeeID": vm.User.OracleUser.EmployeeID,
                        "Email": vm.User.OracleUser.Email,
                        "Password": vm.User.OracleUser.Password,
                        "PasswordAccesses": vm.User.OracleUser.Expiration === 'None' || vm.User.OracleUser.Expiration === 'Days' ? null : vm.User.OracleUser.ExpirationValue,
                        "PasswordDays": vm.User.OracleUser.Expiration === 'None' || vm.User.OracleUser.Expiration === 'Accesses' ? null : vm.User.OracleUser.ExpirationValue,
                        "UserChanges": vm.User.OracleUser.UserChanges
                    },
                    "Responsibilities": oracleResponsibilities
                }
            };

            if (vm.ORRBACEnabled) {
                oracleRequest.EbsRequest.Roles = oracleRoles;
            }

        } else {
            oracleRequest = {
                "EbsRequest": null
            };
        }
        request = $.extend({}, request, oracleRequest);

        // Oracle Cloud Request
        var oracleCloudApplication = vm.availableApplications.find(function (application) {
            return application.Id == oracleCloud_application_id;
        });

        var oracleCloudRequest = {};

        if (vm.User.OracleCloudUser != null && oracleCloudApplication != null && oracleCloudApplication?.selected == true) {
            var oracleCloudRoles = vm.ListOfSelectedOracleCloudRoles && vm.ListOfSelectedOracleCloudRoles.map(role => ({ ...role }));

            if (!isTerminate && isFinishRequest) {
                oracleCloudRoles = vm.ReturnedRequest?.OrfcRequest?.Roles.map(removePendingKey);
               
            } else if (!isTerminate && !isFinishRequest) {
                oracleCloudRoles = oracleCloudRoles && oracleCloudRoles.map(removePendingKey);
            } else {
                oracleCloudRoles = [];
            }

            oracleCloudRequest = {
                "OrfcRequest": {
                    "User": {
                        "Action": vm.User.OracleCloudUser.Action,
                        "ProductId": vm.User.OracleCloudUser.ProductId,
                        "UserID": vm.User.OracleCloudUser.UserID,
                        "UserName": vm.User.OracleCloudUser.UserName,
                        "Password": vm.User.OracleCloudUser.Password,
                        "FirstName": vm.User.OracleCloudUser.FirstName,
                        "LastName": vm.User.OracleCloudUser.LastName,
                        "Email": vm.User.OracleCloudUser.Email,
                        "ExternalID": vm.User.OracleCloudUser.ExternalID,
                        "DisplayName": vm.User.OracleCloudUser.DisplayName,
                        "Active": vm.User.OracleCloudUser.Active,
                        "StartDate": vm.User.OracleCloudUser.StartDate,
                        "EndDate": vm.User.OracleCloudUser.EndDate,
                        "UserChanges": vm.User.OracleCloudUser.UserChanges
                    },
                    "Roles": oracleCloudRoles
                }
            };

        } else {
            oracleRequest = {
                "OrfcRequest": null
            };
        }
        request = $.extend({}, request, oracleCloudRequest);

        //GP Request
        var gpApplication = vm.availableApplications.find(function (application) {
            return application.Id == gp_application_id;
        });

        var gpRequest = {};
        if (vm.User.GPUser != null && gpApplication != null && gpApplication?.selected == true) {
            let gpRoles = [],
                gpCompanies = [];

            if (vm.ListOfStoredGPRoles != null) {
                vm.ListOfStoredGPRoles.forEach(function (roleCompany) {
                    //Find the company for each role, this is used to set the AltModProfile for each role
                    var foundCompany = vm.User.GPUser.Companies.find(company => company.Company.Id == roleCompany.Company.Id);

                    gpRoles.push({
                        "UserId": vm.User.GPUser.Id,
                        "Action": roleCompany.Action,
                        "Status": roleCompany.Status,
                        "StartDate": roleCompany.StartDate,
                        "EndDate": roleCompany.EndDate,
                        "AccessType": roleCompany.AccessType,
                        "Role": {
                            "Id": roleCompany.Role.Id,
                            "Name": roleCompany.Role.Id,
                            "Description": roleCompany.Role.Id
                        },
                        "Company": {
                            "Id": roleCompany.Company.Id,
                            "Name": roleCompany.Company.Name
                        },
                        "AltModProfile": foundCompany.AltModProfile.Id
                    });
                });
            }

            if (vm.ListOfSelectedGPCompanies != null) {
                vm.ListOfSelectedGPCompanies.forEach(company => {
                    gpCompanies.push({
                        "UserId": vm.User.GPUser.Id,
                        "Action": company.Action,
                        "Status": company.Status,
                        "AccessType": company.AccessType,
                        "StartDate": company.StartDate,
                        "EndDate": company.EndDate,
                        "Company": {
                            "Id": company.Id,
                            "Name": company.Name
                        }
                    }
                    );
                });
            }

            if (!isTerminate && isFinishRequest) {
                gpRoles = vm.ReturnedRequest?.GPRequest?.RoleCompanies.map(removePendingKeyByKey.bind(null, "Role"));
                gpCompanies = vm.ReturnedRequest?.GPRequest?.UserCompanies.map(removePendingKey);
            } else if (!isTerminate && !isFinishRequest) {
                gpCompanies = gpCompanies && gpCompanies.map(removePendingKey);
            } else {
                gpRoles = [];
                gpCompanies = [];
            }

            gpRequest = {
                "GPRequest": {
                    "User": {
                        "ProductId": vm.User.GPUser.ProductId,
                        "Action": vm.User.GPUser.Action,
                        "Status": vm.User.GPUser.Status,
                        "StartDate": vm.User.GPUser.StartDate,
                        "EndDate": vm.User.GPUser.EndDate,
                        "Id": vm.User.GPUser.Id,
                        "UserName": vm.User.GPUser.UserName,
                        "UserStatus": vm.User.GPUser.UserStatus,
                        "UserType": vm.User.GPUser.UserType,
                        "WCUser": vm.User.GPUser.WCUser,
                        "Company": vm.User.GPUser.Company,
                        "Class": vm.User.GPUser.DisplayClass == null ? null : vm.User.GPUser.DisplayClass.RowId,
                        "ClassName": vm.User.GPUser.DisplayClass == null ? null : vm.User.GPUser.DisplayClass.Name,
                        "LoginType": vm.User.GPUser.LoginType,
                        "PayrollViewForHR": vm.User.GPUser.PayrollViewForHR,
                        "WindowsUserId": vm.User.GPUser.WindowsUserId,
                        "NetBiosName": vm.User.GPUser.NetBiosName,
                        "ADUserId": vm.User.GPUser.ADUserId,
                        "UserChanges": vm.User.GPUser.UserChanges

                    },
                    "RoleCompanies": gpRoles,
                    "UserCompanies": gpCompanies
                }
            };

        } else {
            gpRequest = {
                "GpRequest": null
            };
        }
        request = $.extend({}, request, gpRequest);


        //NAV Request
        var navRequest = {};
        var navApplication = vm.availableApplications.find(function (application) {
            return application.Id == nav_application_id;
        });

        if (vm.User.NAVUser != null && vm.User.NAVUser.LicenseType != null && navApplication?.selected == true) {
            let navPermissions = [],
                navUserGroups = [];

            if (vm.ListOfSelectedNAVPermissionSets != null) {
                vm.ListOfSelectedNAVPermissionSets.forEach(permissionSet => {
                    if (permissionSet.Companies != null && permissionSet.Companies.length > 0) {

                        permissionSet.Companies.forEach(company => {
                            navPermissions.push({
                                "UserId": vm.User.NAVUser.UserId,
                                "Action": permissionSet.Action,
                                "Status": permissionSet.Status,
                                "StartDate": permissionSet.StartDate,
                                "EndDate": permissionSet.EndDate,
                                "AccessType": permissionSet.AccessType,
                                "Id": permissionSet.Id,
                                "Name": permissionSet.Name,
                                "Company": company.Name,
                                "StartDate": company.StartDate,
                                "EndDate": company.EndDate
                            });
                        });
                    } else {
                        navPermissions.push(
                            {
                                "UserId": vm.User.NAVUser.UserId,
                                "Action": permissionSet.Action,
                                "Status": permissionSet.Status,
                                "StartDate": permissionSet.StartDate,
                                "EndDate": permissionSet.EndDate,
                                "AccessType": permissionSet.AccessType,
                                "Id": permissionSet.Id,
                                "Name": permissionSet.Name,
                                "Company": null
                            });
                    }
                });
            }

            if (vm.ListOfSelectedNAVUserGroups != null) {
                vm.ListOfSelectedNAVUserGroups.forEach(group => {
                    if (group.Companies != null && group.Companies.length > 0) {
                        group.Companies.forEach(company => {
                            navUserGroups.push(
                                {
                                    "UserId": vm.User.NAVUser.UserId,
                                    "Action": group.Action,
                                    "Status": group.Status,
                                    "StartDate": group.StartDate,
                                    "EndDate": group.EndDate,
                                    "AccessType": group.AccessType,
                                    "Id": group.Id,
                                    "Name": group.Name,
                                    "PermissionSets": group.PermissionSets,
                                    "Company": company.Name
                                });
                        });
                    } else  {
                        navUserGroups.push(
                            {
                                "UserId": vm.User.NAVUser.UserId,
                                "Action": group.Action,
                                "Status": group.Status,
                                "StartDate": group.StartDate,
                                "EndDate": group.EndDate,
                                "AccessType": group.AccessType,
                                "Id": group.Id,
                                "Name": group.Name,
                                "PermissionSets": group.PermissionSets,
                                "Company": null
                            });
                    }
                });
            }

            if (!isTerminate && isFinishRequest) {
                navPermissions = vm.ReturnedRequest?.NavRequest?.PermissionSets.map(removePendingKey);
                navUserGroups = vm.ReturnedRequest?.NavRequest?.UserGroups.map(removePendingKey);
            } else if (isTerminate && !isFinishRequest) {
                navPermissions = [];
                navUserGroups = [];
            }

            navRequest = {
                "NavRequest": {
                    "User": {
                        "Action": vm.User.NAVUser.Action,
                        "ProductId": vm.User.NAVUser.ProductId,
                        "Status": vm.User.NAVUser.Status,
                        "StartDate": vm.User.NAVUser.StartDate,
                        "EndDate": vm.User.NAVUser.EndDate,
                        "UserSecurityId": vm.User.NAVUser.UserSecurityId,
                        "UserName": vm.User.NAVUser.UserName,
                        "FullName": vm.User.NAVUser.FullName,
                        "LicenseType": vm.User.NAVUser.LicenseType.Id,
                        "State": vm.User.NAVUser.State,
                        "ExpirySet": vm.User.NAVUser.ExpiryDate == new Date(-8640000000000000) ? false : true,
                        "ExpiryDate": vm.User.NAVUser.ExpiryDate,
                        "ContactEmail": vm.User.NAVUser.ContactEmail,
                        "WindowsUserName": vm.User.NAVUser.WindowsUserName,
                        "WindowsSID": vm.User.NAVUser.WindowsSID,
                        "Domain": vm.User.NAVUser.Domain,
                        "ProfileID": vm.User.NAVUser.ProfileID,
                        "LanguageID": vm.User.NAVUser.LanguageID,
                        "Company": vm.User.NAVUser.Company,
                        "Personalized": true,
                        "UserChanges": vm.User.NAVUser.UserChanges
                    },
                    "PermissionSets": navPermissions,
                    "UserGroups": navUserGroups
                }
            };
        }
        else {
            navRequest = {
                "NavRequest": null
            };
        }

        request = $.extend({}, request, navRequest);

        //D365S Request
        var d365SRequest = {};
        var d365SApplication = vm.availableApplications.find(function (application) {
            return application.Id == d365s_application_id;
        });

        if (vm.User.D365SUser != null && vm.User.D365SUser.DomainName != null && d365SApplication?.selected == true) {
            let d365sRoles = [],
                d365sTeams = [];

            if (vm.ListOfSelectedD365SRoles != null) {
                vm.ListOfSelectedD365SRoles.forEach(role => {
                    d365sRoles.push(
                        {
                            "UserId": vm.User.D365SUser.UserId,
                            "Action": role.Action,
                            "StartDate": role.StartDate,
                            "EndDate": role.EndDate,
                            "Role": {
                                "Id": role.Id,
                                "Name": role.Name,
                                "Description": role.Description
                            },
                            "Company": null
                        });
                });
            }

            if (vm.ListOfSelectedD365STeams != null) {
                vm.ListOfSelectedD365STeams.forEach(team => {
                    d365sTeams.push(
                        {
                            "UserId": vm.User.D365SUser.UserId,
                            "Action": team.Action,
                            "StartDate": team.StartDate,
                            "EndDate": team.EndDate,
                            "Team": {
                                "Id": team.Id,
                                "Name": team.Name,
                                "Description": team.Description
                            },
                            "Company": null
                        });
                });
            }

            if (!isTerminate && isFinishRequest) {
                d365sRoles = vm.ReturnedRequest.D365SRequest.UserRoles.map(removePendingKeyByKey.bind(null, "Role"));
                d365sTeams = vm.ReturnedRequest.D365SRequest.UserTeams.map(removePendingKey);
            } else if (isTerminate && !isFinishRequest) {
                d365sRoles = [];
                d365sTeams = [];
            }

            d365SRequest = {
                "D365SRequest": {
                    "User": {
                        "Action": vm.User.D365SUser.Action,
                        "Status": vm.User.D365SUser.Status,
                        "StartDate": vm.User.D365SUser.StartDate,
                        "EndDate": vm.User.D365SUser.EndDate,
                        "ProductId": vm.User.D365SUser.ProductId,
                        "Id": vm.User.D365SUser.Id,
                        "AccessMode": vm.User.D365SUser.AccessMode,
                        "BusinessUnitId": vm.User.D365SUser.BusinessUnitId,
                        "CalType": vm.User.D365SUser.CalType,
                        "DomainName": vm.User.D365SUser.DomainName,
                        "FirstName": vm.User.D365SUser.FirstName,
                        "LastName": vm.User.D365SUser.LastName,
                        "IncomingEmailDeliveryMethod": vm.User.D365SUser.IncomingEmailDeliveryMethod,
                        "InternalEmailAddress": vm.User.D365SUser.InternalEmailAddress,
                        "IsDisabled": vm.User.D365SUser.IsDisabled,
                        "HomePhone": vm.User.D365SUser.HomePhone,
                        "MobilePhone": vm.User.D365SUser.MobilePhone,
                        "OutgoingEmailDeliveryMethod": vm.User.D365SUser.OutgoingEmailDeliveryMethod,
                        "SiteId": vm.User.D365SUser.SiteId,
                        "TerritoryId": vm.User.D365SUser.TerritoryId,
                        "Title": vm.User.D365SUser.Title,
                        "ParentSystemUserId": vm.User.D365SUser.ParentSystemUserId,
                        "AzureADObjectId": vm.User.D365SUser.AzureADObjectId,
                        "UserChanges": vm.User.D365SUser.UserChanges
                    },
                    "UserRoles": d365sRoles,
                    "UserTeams": d365sTeams
                }
            };

        }
        else {
            d365SRequest = {
                "D365SRequest": null
            };
        }

        request = $.extend({}, request, d365SRequest);


        //Peoplesoft Request
        var psRequest = {};
        var psApplication = vm.availableApplications.find(function (application) {
            return application.Id == ps_application_id;
        });

        if (vm.User.PSUser != null && vm.User.PSUser.Id != null && psApplication?.selected == true) {

            let psRoles = vm.ListOfSelectedPSRoles;

            if (vm.User.PSUser.Password === "") {
                vm.User.PSUser.Password = null;
            }

            if (!isTerminate && isFinishRequest) {
                psRoles = vm.ReturnedRequest?.PsRequest?.Roles.map(removePendingKeyByKey.bind(null, "Name"));
            } else if (!isTerminate && !isFinishRequest) {
                psRoles = psRoles && psRoles.map(removePendingKey);
            } else {
                psRoles = [];
            }

            psRequest = {
                "PsRequest": {
                    "User": {
                        "Action": vm.User.PSUser.Action,
                        "Status": vm.User.PSUser.Status,
                        "StartDate": vm.User.PSUser.StartDate,
                        "EndDate": vm.User.PSUser.EndDate,
                        "ProductId": vm.User.PSUser.ProductId,
                        "Id": vm.User.PSUser.Id,
                        "Description": vm.User.PSUser.Description,
                        "Alias": vm.User.PSUser.Alias,
                        "PrimaryEmail": vm.User.PSUser.PrimaryEmail,
                        "EmailType": vm.User.PSUser.EmailType,
                        "Password": vm.User.PSUser.Password,
                        "AccountLocked": vm.User.PSUser.AccountLocked,
                        "NavigatorHomePermissionList": vm.User.PSUser.NavigatorHomePermissionList,
                        "ProcessProfilePermissionList": vm.User.PSUser.ProcessProfilePermissionList,
                        "PrimaryPermissionList": vm.User.PSUser.PrimaryPermissionList,
                        "RowSecurityPermissionList": vm.User.PSUser.RowSecurityPermissionList,
                        "UserChanges": vm.User.PSUser.UserChanges
                    },
                    "Roles": psRoles,
                }
            };

        }
        else {
            psRequest = {
                "PSRequest": null
            };
        }

        request = $.extend({}, request, psRequest);

        //Salesforce Request
        var salesforceRequest = {};
        var salesforceApplication = vm.availableApplications.find(application => application.Id == salesforce_application_id);

        if (vm.User.SalesforceUser != null && vm.User.SalesforceUser.Username && salesforceApplication?.selected == true) {
            
            let salesforceProfiles, salesforcePermissionSets = vm.ListOfSelectedSalesforcePermissionSets;

            if (!isTerminate && isFinishRequest) {
                salesforceProfiles = vm.ReturnedRequest?.SfRequest?.Profiles;
                salesforcePermissionSets = vm.ReturnedRequest?.SfRequest?.PermissionSets.map(removePendingKey);
            } else if (!isTerminate && !isFinishRequest) {
                salesforceProfiles = [vm.User.SalesforceUser.Profile];
                salesforcePermissionSets = salesforcePermissionSets && salesforcePermissionSets.map(removePendingKey);
            } else {
                salesforcePermissionSets = [];
            }

            salesforceRequest = {
                SfRequest: {
                    User: {
                        Action: vm.User.SalesforceUser.Action,
                        Status: vm.User.SalesforceUser.Status,
                        StartDate: vm.User.SalesforceUser.StartDate,
                        EndDate: vm.User.SalesforceUser.EndDate,
                        ProductId: vm.User.SalesforceUser.ProductId,
                        Id: vm.User.SalesforceUser.Id,
                        FirstName: vm.User.SalesforceUser.FirstName,
                        LastName: vm.User.SalesforceUser.LastName,
                        Alias: vm.User.SalesforceUser.Alias,
                        Email: vm.User.SalesforceUser.Email,
                        Username: vm.User.SalesforceUser.Username,
                        Nickname: vm.User.SalesforceUser.Nickname,
                        Title: vm.User.SalesforceUser.Title,
                        CompanyName: vm.User.SalesforceUser.CompanyName,
                        Department: vm.User.SalesforceUser.Department,
                        Division: vm.User.SalesforceUser.Division,
                        Phone: vm.User.SalesforceUser.Phone,
                        Extension: vm.User.SalesforceUser.Extension,
                        Fax: vm.User.SalesforceUser.Fax,
                        MobilePhone: vm.User.SalesforceUser.MobilePhone,
                        EmployeeNumber: vm.User.SalesforceUser.EmployeeNumber,
                        Country: vm.User.SalesforceUser.Country,
                        Street: vm.User.SalesforceUser.Street,
                        City: vm.User.SalesforceUser.City,
                        State: vm.User.SalesforceUser.State,
                        PostalCode: vm.User.SalesforceUser.PostalCode,
                        FederationIdentifier: vm.User.SalesforceUser.FederationIdentifier,
                        ManagerId: vm.User.SalesforceUser.ManagerId,
                        DelegateApproverId: vm.User.SalesforceUser.DelegateApproverId,
                        UserRoleId: vm.User.SalesforceUser.UserRoleId,
                        UserChanges: vm.User.SalesforceUser.UserChanges
                    },
                    Profiles: salesforceProfiles,
                    PermissionSets: salesforcePermissionSets
                }
            };

        }
        else {
            salesforceRequest = {
                SfRequest: null
            };
        }

        request = $.extend({}, request, salesforceRequest);

        return request;
    };

    this.buildHeaderRequest = function (vm) {

        var request = {
        };

        //Add inital settings for the requests
        if (vm.User.UserMaster != null) {
            request = $.extend({}, request, { "UserMasterId": vm.User.UserMaster.Id });
        }
        if (vm.requestToEdit != null) {
            request = $.extend({}, request, { "RequestId": vm.requestToEdit.Id });
            request = $.extend({}, request, { "Version": vm.requestToEdit.Version });
        }

        request = $.extend({}, request, { "Note": vm.RequestNotes });
        if (vm.RiskLevelId != null) {
            request = $.extend({}, request, { "RiskLevelId": vm.RiskLevelId });
        }
        if (vm.RiskLevelIdNewOnly != null) {
            request = $.extend({}, request, { "RiskLevelIdNewOnly": vm.RiskLevelIdNewOnly });
        }
        if (vm.bypassRiskAnalysis != null) {
            request = $.extend({}, request, { "RiskAnalysisBypassed": vm.bypassRiskAnalysis });
        }

        request = $.extend({}, request, { "Action": vm.wizardMode });
        if (vm.alternatePath === 'EmergencyAccess') {
            // Emergency Access uses modify wizard mode, so we have to intercept and change the request Action
            request.Action = 'EmergencyAccess';
        }
        request = $.extend({}, request, { "AffectedType": "User" });

        return request;
    };

    this.executeRequest = function (request) {
        return $http.post(apiUrl + 'api/identitymanager/requests/build', request);
    };
}

function removePendingKey(item) {
    item = { ...item }
    delete item.Pending;

    return item;
}

function removePendingKeyByKey(key, item) {
    item = { ...item }
    delete item[key].Pending;

    return item;
}