import passwordCriteriaController from "./passwordCriteria.controller";

/* passwordCriteria.component.js */

/**
 * @desc component for configuring password criteria
 * @example <password-criteria></password-criteria>
 */

const passwordCriteria = {
    bindings: {
        product: '<'
    },
    controller:  passwordCriteriaController,
    controllerAs: 'pcvm',
    templateUrl: '/App/Components/configuration/passwordCriteria/passwordCriteria.tmpl.html'
};

export default passwordCriteria;