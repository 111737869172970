export default /*@ngInject*/ function (helperService) {
    const factory = {
        generateBody
    };

    return factory;

    function generateBody(objectId, productIds, roles, securityObject, securityObjectType, isDetailed) {
        let selectedRoles,
            result = helperService.returnNullIfAllIsFound(roles.map(role => role.ID)),
            data = {
                "ObjectId": objectId,
                "SecurityObjectTypes": [securityObjectType.Identifier],
                "SecurityObjects": [securityObject.Identifier]
            };

        if (result) {
            selectedRoles = roles.map(role => role.Identifier);
        } else {
            selectedRoles = null;
        }

        data.ProductIds = productIds;
        data.Roles = selectedRoles;

        if (securityObjectType.ID === null) {
            data.SecurityObjectTypes = null;
        }
        if (securityObject.Id === null) {
            data.SecurityObjects = null;
        }

        return data;
    }
}