export default /*@ngInject*/ function ($scope, $rootScope, $timeout, $location, helperService) {

    var removedTab = false;
    var tabAdded = false;

    if ($rootScope.tabs == null) {
        $rootScope.tabs = [];
    }

    if ($rootScope.tabs != null) {
        $rootScope.$watch('tabs.length', function () {
            if ($rootScope.tabs.length > 1) {

                if ($rootScope.tabs.length >= Number(tabLimit) + 1) {
                    for (var i = $rootScope.tabs.length - 1; i > 0; i--) {
                        if ($rootScope.tabs[i].pinned == false) {
                            $rootScope.tabs.splice(i, 1);
                            break;
                        }
                    }
                }

                $timeout(function () {
                    if (!removedTab) {
                        $scope.active = helperService.getTabIndex();
                    }
                    removedTab = false;


                }, 1);


                //If there is more than one tab enable all the close buttons
                angular.forEach($rootScope.tabs, function (tab) {
                    tab.hideCloseButton = false;
                });
            }

            //If only one tab is left hide the close button
            if ($rootScope.tabs.length == 1) {
                $rootScope.tabs[0].hideCloseButton = true;
            }

            //Only one tab was present when new report was run, set current tab to first tab
            if ($rootScope.tabs.length == 2) {
                removedTab = false;
                $scope.setCurrentTab($rootScope.tabs[0]);
            }

        }, true);
    }


    $scope.tabChanged = function (tab) {
        if (tab.link != null) {
            $location.path(tab.link, false);
            $rootScope.tabChangedEventFired = true;
        }
    };

    $scope.setCurrentTab = function (tab) {
        if (tab.link != null) {
            $rootScope.currentSelectedTab = tab;
            //When removing current tab this changes the URL to the new current tab
            if (removedTab) {
                $location.path(tab.link).search('');
                $rootScope.tabChangedEventFired = true;
            }
        }
    };


    $scope.tabIndex = function () {
        $scope.active = $scope.active - 1;
    };

    $scope.removeTab = function (event, index, tab) {
        event.preventDefault();
        event.stopPropagation();

        if ($rootScope.tabs.length > 1) {

            removedTab = true;

            //Remove tab
            $rootScope.tabs.splice(index, 1);

            //If the current tab is being deleted then the active tab needs changed
            if (tab.id == $scope.active) {
                $scope.active = $scope.active;
            }

            setDisablePin(false);


        }

    };

    $scope.pinThisTab = function (event, index, tab) {
        event.preventDefault();
        event.stopPropagation();

        tab.pinned = !tab.pinned;


        //Move the pinned tab to the end of the
        var tabArrayIndex = $rootScope.tabs.findIndex($rootScope.tabs.find({ id: tab.id }));
        $rootScope.tabs.splice(tabArrayIndex, 1);

        var firstAvailableIndex = $rootScope.tabs.length;
        for (var i = 0; i < $rootScope.tabs.length; i++) {
            if ($rootScope.tabs[i].pinned) {
                firstAvailableIndex = i;
                break;
            }
        }

        $rootScope.tabs.splice(firstAvailableIndex, 0, tab);

        var count = 0;
        $rootScope.tabs.forEach(function (tab) {
            count += tab.pinned ? 1 : 0;
        });
        //Disable the fifth pin if four are already pinned to leave room for a new report
        if (count >= Number(tabLimit) - 1) {
            setDisablePin(true);
        }
        else {
            setDisablePin(false);
        }

        if (tab.id == $scope.active) {
            $scope.active = tab.id;
        }
    };

    var setDisablePin = function (value) {
        angular.forEach($rootScope.tabs, function (tab) {
            if (tab.pinned == false) {
                tab.disablepin = value;
            }
        });
    };

}
