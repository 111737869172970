export const customDatePickerFilter = {
    extra: false, //do not show extra filters
    operators: { // redefine the string operators
        date: {
            gt: "Is after",
            lt: "Is before",
            isempty: "Is empty",
            isnotempty: "Is not empty"
        }
    },
    cell: {
        template: function (e) {
            e.element.kendoDatePicker({
                format: "M/d/yy"
            });
        }
    }
};

export const customDatePickerNotNullableFilter = {
    extra: false, //do not show extra filters
    operators: { // redefine the string operators
        date: {
            gt: "Is after",
            lt: "Is before"
        }
    },
    cell: {
        template: function (e) {
            e.element.kendoDatePicker({
                format: "M/d/yy"
            });
        }
    }
};

export const customBooleanFilter = {
    messages: {
        isTrue: "True",
        isFalse: "False"
    }
};

export const customYesNoBooleanFilter = {
    messages: {
        isTrue: "Yes",
        isFalse: "No"
    }
};
export const customBooleanYesNoFilter = {
    messages: {
        isTrue: "Yes",
        isFalse: "No"
    }
};

export const customAutoCompleteFilter = {
    operators: {
        string: {
            contains: "Contains",
            doesnotcontain: "Does not contain",
            eq: "Equal to",
            neq: "Not equal to",
            startswith: 'Starts with',
            endswith: 'Ends with',
            isempty: 'Is empty',
            isnotempty: 'Is not empty'
        },
        number: {
            eq: "Equal to",
            neq: "Not equal to",
            gte: "Greater than or equal to",
            gt: "Greater than",
            lte: "Less than or equal to",
            lt: "Less than"
        }
    },
    cell: {
        template: function (e) {
            e.element.kendoAutoComplete({
                serverFiltering: false,
                valuePrimitive: true,
                noDataTemplate: ''
            });
        }
    }
};

export const customNotStringEmptyFilter = {
    extra: false, //do not show extra filters
    operators: {
        number: {
            eq: "Equal to",
            neq: "Not equal to",
            gte: "Greater than or equal to",
            gt: "Greater than",
            lte: "Less than or equal to",
            lt: "Less than",
            isempty: "Is empty",
            isnotempty: "Is not empty"
        },
        date: {
            gt: "Is after",
            lt: "Is before",
            isempty: "Is empty",
            isnotempty: "Is not empty"
        }
    }
};

export const customAutoCompleteFilterEqualToOnly = {
    operators: {
        string: {
            eq: "Equal to"
        }
    },
    cell: {
        template: function (e) {
            e.element.kendoAutoComplete({
                serverFiltering: false,
                valuePrimitive: true,
                noDataTemplate: ''
            });
        }
    }
};

export const standardGridPageSize = 50;