import angular from 'angular';
import supervisorAssignments from '../Components/SupervisorAssignments/supervisorAssignments.component';
import supervisorAssignmentsDataServiceFactory from '../Components/SupervisorAssignments/supervisorAssignmentsDataService.factory';
import autoMappingAssignments from '../Components/SupervisorAssignments/AutoMappingAssignments/autoMappingAssignments.component';

export default angular
    .module('app.integrations.supervisorAssignments', [])
    .component('supervisorAssignments', supervisorAssignments)
    .factory('supervisorAssignmentsDataService', supervisorAssignmentsDataServiceFactory)
    .component('autoMappingAssignments', autoMappingAssignments)
    .name;