import netsuiteDataChangesConfigurationController from "./netsuiteDataChangesConfiguration.controller";

/* netsuiteDataChangesConfiguration.component.js */

/**
 * @desc component for Data Changes Configuration in NetSuite
 * @example <data-changes-configuration></data-changes-configuration>
 */

const netsuiteDataChangesConfiguration = {
    controller: netsuiteDataChangesConfigurationController,
    controllerAs: 'dccvm',
    templateUrl: '/App/Components/DataChangesConfiguration/netsuiteDataChangesConfiguration.tmpl.html'
};

export default netsuiteDataChangesConfiguration;