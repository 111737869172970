/* userDetailsChanges.component.js */
import userDetailsChangesModalController from "./userDetailsChangesModal.controller"
/**
 * @desc component for viewing user profile information
 * @example <user-details-changes></user-details-changes>
 */

const userDetailsChanges = {
    bindings: {
        changes: '<'
    },
    controller: userDetailsChangesController,
    controllerAs: 'udcvm',
    template: `<div class="user-details-changes">
                    <a ng-click="udcvm.openUserDetailsChangesModal()">
                        <strong>View Changes</strong>
                    </a>
                </div>`
};

/*@ngInject*/ function userDetailsChangesController($uibModal) {

    const vm = this;

    vm.openUserDetailsChangesModal = openUserDetailsChangesModal;

    async function openUserDetailsChangesModal() {
        $uibModal.open({
            templateUrl: '/App/Shared/Components/userDetailsChanges/userDetailsChangesModal.tmpl.html',
            controller: userDetailsChangesModalController,
            controllerAs: 'udcmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                userChangesInformation: () => vm.changes
            }
        });
    }
}

export default userDetailsChanges;