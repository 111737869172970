import swal from 'sweetalert2';
import churnZeroConfig from '../Config/churnZero.config';
import createSpinner from '../Functions/createSpinner';

export default /*@ngInject*/ function ($scope, $http, $filter, $window, helperService, authenticationService, errorsDataService) {

    $scope.multipleCompanies = false;
    $scope.showCompanies = true;
    $scope.signLicenseAgreement = false;
    $scope.loadingTenants = false;

    $scope.appUrl = appUrl;

    activate();

    async function activate() {
        let authenticated = await authenticationService.isAuthenticated();
        if (!authenticated) {
            $window.open(appUrl + 'login', '_self');
        } else {
            $scope.getTenants();
        }
    }

    $scope.getTenants = async function () {

        $scope.loadingTenants = true;
        $scope.currentUser = await authenticationService.getUser();
        $http({
            method: 'GET',
            url: `${apiUrl}api/auth/tenants/user/${$scope.currentUser.userId}`
        }).then(function successCallback(response) {
            $scope.loadingTenants = false;

            response.data = $filter('orderBy')(response.data, 'Name');
            var onlyTenant = response.data[0];

            var tenantBeforeRedirect = localStorage.getItem("tenantBeforeRedirect");
            localStorage.removeItem("tenantBeforeRedirect");

            //Validates that tenant being preselected is in the response
            var tenantBeforeRedirectIsValid = response.data.find(function (object) {
                var parsedTenant = JSON.parse(tenantBeforeRedirect);
                if (parsedTenant) {
                    return object.ID == parsedTenant.ID;
                }
            });

            if (tenantBeforeRedirectIsValid) {
                $scope.selectTenant(JSON.parse(tenantBeforeRedirect));
            }

            // 0 Tenants
            else if (response.data.length === 0) {
                $scope.alertNoTenantsFound();
            }

            // 1 Tenant
            else if (response.data.length === 1) {
                $scope.selectTenant(onlyTenant);
            }
            // Multiple Tenants
            else {
                $scope.multipleCompanies = true;
                $scope.showCompanySelectionView(response);
            }
        }, function errorCallback(response) {
            $scope.loadingTenants = false;
            if (response.status === 404) {
                $scope.alertNoTenantsFound();
            } else if (response.status === 401) {
                $scope.alertUnauthorized();
            } else if (response.status === 500) {
                $scope.alertServerErrorHasOccured();
            }
        });
    };

    $scope.alertNoTenantsFound = function () {

        swal({ title: 'No tenant mapping:', text: 'You are not assigned access in any tenants. Please contact your Fastpath Assure administrator if you need access.', type: 'warning', showCancelButton: false }).then(function () {
            $window.open(appUrl + 'login', '_self');
        }, function (dismiss) {

        });

    };

    $scope.alertServerErrorHasOccured = function () {
        swal({ title: 'Internal Server Error', type: 'warning', showCancelButton: false }).then(function () {
            $window.open(appUrl + 'login', '_self');
        }, function (dismiss) {

        });
    };

    $scope.alertUnauthorized = function () {
        swal({ title: 'Unauthorized', type: 'warning', showCancelButton: false }).then(function () {
            $window.open(appUrl + 'login', '_self');
        }, function (dismiss) {

        });
    };

    $scope.showCompanySelectionView = function (response) {
        $scope.tenantsForUser = response.data;
    };

    $scope.selectTenant = function (Tenant) {
        if (Tenant.LicensingOnly) {
            $scope.licensingOnly = true;
            $scope.tenantIDForlicensingUpload = Tenant.ID;
            checkLatestImports();
            if ($scope.multipleCompanies) {
                $scope.multipleCompanies = false;
            }
            return;
        }

        if (Tenant.OnHold == false) {
            $scope.checkSignEula(Tenant);
        }
        else {
            swal('Attention:', 'The ' + Tenant.Name + ' environment is currently on hold. Please contact Fastpath Support for further assistance.', 'warning');
        }
    };

    $scope.checkSignEula = function (Tenant) {

        if (Tenant.EULASigned === true) {
            $scope.checklocalStorageAndRedirect(Tenant);
        } else {
            $scope.LicenseAgreementWindow(Tenant);
        }
    };

    $scope.LicenseAgreementWindow = function (Tenant) {
        $scope.showCompanies = false;
        $scope.signLicenseAgreement = true;
        $scope.tenantAgreeingToTerms = Tenant;
    };

    $scope.checklocalStorageAndRedirect = function (Tenant) {
        Tenant = angular.copy(Tenant);
        localStorage.setItem("selectedTenant", JSON.stringify(Tenant));

        var selectedTenantAsObject = angular.fromJson(localStorage.getItem("selectedTenant"));
        $scope.selectedTenantID = selectedTenantAsObject.Name;
        var locationBeforeRedirect = localStorage.getItem("locationBeforeRedirect");
        localStorage.removeItem("locationBeforeRedirect");


        if (locationBeforeRedirect != null) {
            $window.location.href = locationBeforeRedirect;
        }
        else {
            $window.location.href = appUrl;
        }

        churnZeroConfig(authenticationService);
    };

    $scope.DeclineLicenseAgreement = function () {
        document.location.href = appUrl + "login";
    };

    $scope.AcceptLicenseAgreement = async function () {
        var tenantAgreeingToTerms = angular.copy($scope.tenantAgreeingToTerms);
        tenantAgreeingToTerms.EULASigned = true;
        localStorage.setItem("selectedTenant", JSON.stringify(tenantAgreeingToTerms));
        var data = {
            "TenantId": tenantAgreeingToTerms.ID,
            "UserId": $scope.currentUser.userId
        };

        $http({
            method: 'POST',
            url: apiUrl + 'api/auth/tenants/signeula',
            headers: {
                'TenantId': tenantAgreeingToTerms.ID
            },
            data: data
        }).then(function successCallback(response) {
            $scope.checklocalStorageAndRedirect(tenantAgreeingToTerms);
        }, function errorCallback(response) {
            swal('Error', response.data, 'warning');
        });
    };

    $scope.importLicensingFile = async function (files) {
        const optInText = `Does your organization have a complete picture of its access risk? In addition to running an analysis of your current license information, 'Opt-In' for a free Segregation of Duties assessment . We will run an analysis on your F&O Security data, down to the lowest security level, and report the number of conflict risks associated with the access. Please 'Opt-In' below for your free Segregation of Duties analysis.`
        try {
            await swal({
                title: 'Opt In for Segregation of Duties Assessment?',
                type: 'question',
                showCancelButton: true,
                showConfirmButton: true,
                text: optInText,
                cancelButtonText: 'Decline',
                confirmButtonText: 'Opt-In'
            });
            processImport(files, true);
        } catch {
            processImport(files, false);
        }
        
    }

    async function processImport(files, sodOptin) {
        let formData = new FormData(),
            file = files.first().file;
        $scope.showLoading = new createSpinner();
        formData.append(file.name, file);
        formData.append('SodOptIn', sodOptin);

        try {
            await $http.put(`${apiUrl}api/universal/ax7/import/files/license`, formData, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined, 'TenantId': $scope.tenantIDForlicensingUpload }
            });
            await helperService.successfulSaveButton($scope.showLoading);
            files[0].cancel();
            swal('File Upload Complete', 'File was uploaded successfully. Analysis is in progress. Your results will be emailed to you when the Analysis is complete', 'success');
            $scope.importInProgress = true;
        } catch (error) {
            errorsDataService.catch(error);
            $scope.showLoading.loadingValue = false;
        }
    }

    async function checkLatestImports() {
        $scope.checkingLatest = true;
        try {
            let { data } = await $http.get(apiUrl + 'api/universal/imports/products/latest', {
                headers: { 'TenantId': $scope.tenantIDForlicensingUpload }
            });
            if (data.find(productImport => productImport.Status === 'InProgress')) {
                $scope.importInProgress = true;
            }
            $scope.checkingLatest = false;
        } catch (error) {
            $scope.checkingLatest = false;
            errorsDataService.catch(error);
        }
    }

}