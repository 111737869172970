export default /*@ngInject*/ function ($scope, $rootScope, $route, ViewProductImportPageService, $filter, dataService, authenticationService, KPIOverrideDefinitions, KPIGeneratorService, helperService, CustomReportServices, homeDataService) {

    const vm = this;

    vm.runReport = runReport;
    vm.savedReportsDataSource = [];
    vm.usersByProductStatistics = [];
    vm.companiesByProductStatistics = [];
    vm.listOfCards = [];
    vm.expandNewDashboardSection = expandNewDashboardSection;
    vm.cancelNewDashboard = expandNewDashboardSection;
    vm.createDashboardIsCollapsed = false;
    vm.focusNewDashboardName = false;
    vm.dashboardName = '';
    vm.launchReport = launchReport;
    vm.fusionChartEvents = {};
    vm.launchReportFromTable = launchReportFromTable;
    vm.launchReportFromTile = launchReportFromTile;
    vm.createPartialSavedReportAndNavigate = createPartialSavedReportAndNavigate;
    vm.postCardsToApi = postCardsToApi;
    vm.reloadPackery = reloadPackery;
    vm.cardChangedVisibility = cardChangedVisibility;
    vm.deleteDashboard = deleteDashboard;
    vm.createDashboard = createDashboard;
    vm.dashboardSelected = dashboardSelected;
    vm.setDashboardSettings = setDashboardSettings;
    vm.showLabels = showLabels;

    function runReport(savedReport) {
        CustomReportServices.loadSavedReport(savedReport);
    }

    vm.$onInit = init;

    async function init() {
        setEvents();
        setFusionChart();

        await setupDashboard();
        await setSavedReportsByUser();
        await setUsersByProductStatistics();
        await setCompaniesByProductStatistics();

        vm.reloadPackery();
        vm.postCardsToApi();
    }

    function setEvents() {
        $scope.$on('$routeChangeStart', () => {
            delete $rootScope.packery;

            if (vm.listOfCards) {
                vm.listOfCards.forEach(card => {
                    if (card.ChartId) {
                        var fusionChart = FusionCharts.items[card.ChartId];
                        if (fusionChart) {
                            fusionChart.dispose();
                        }
                    }
                });
            }
        });

        $rootScope.$on('packery-card-changed-position', (event, args) => {
            vm.listOfCards.forEach(row => {
                args.forEach(card => {
                    if (card.cardId === row.Id) {
                        row.Ordinal = card.cardIndex;
                    }
                });
            });

            vm.postCardsToApi();
            vm.reloadPackery();
        });
    }

    function setFusionChart() {
        vm.fusionChartEvents = {
            beforeRender: () => {
                $rootScope.packery.layout();
            },
            renderComplete: () => {
                $rootScope.packery.layout();
            },
            dataPlotClick: (ev, props) => {
                var objectId = ev.sender.args.dataSource.reportObjectId;

                if (ev.sender.args.type === 'stackedcolumn2d') {
                    let filters = [
                        { 'Label': ev.sender.args.dataSource.reportCategory, 'Value': props.categoryLabel },
                        { 'Label': ev.sender.args.dataSource.reportSeries, 'Value': props.datasetName }
                    ]
                    vm.createPartialSavedReportAndNavigate(filters, objectId);
                } else {
                    var field = ev.sender.args.dataSource.reportField;
                    var row = ev.sender.args.dataSource.data[props.index];

                    vm.createPartialSavedReportAndNavigate([{ 'Label': field, 'Value': row.Label }], objectId);
                }
            }
        };
    }

    async function setSavedReportsByUser() {
        const currentUser = await authenticationService.getUser();
        let { data } = await dataService.getSavedReportsByUser(currentUser.userId);

        vm.savedReportsDataSource = $filter('orderBy')(data, 'Name');
    }

    async function setUsersByProductStatistics() {
        const { data } = await homeDataService.getUserStatistics();

        vm.usersByProductStatistics = data;
    }

    async function setCompaniesByProductStatistics() {
        const { data } = await homeDataService.getCompanyStatistics();

        vm.companiesByProductStatistics = data;
    }

    async function setupDashboard() {
        let response = await homeDataService.getDashboards();
        
        response.data = $filter('orderBy')(response.data, 'Name');

        vm.listOfDashboards = response.data;

        var defaultDashboard = $rootScope.UserPreferences['DefaultDashboard'];

        var selectedDashboard = null;

        if (defaultDashboard) {
            selectedDashboard = vm.listOfDashboards.filter(e => e.Id.toLowerCase() === defaultDashboard.toLowerCase())[0];
        }

        if (selectedDashboard) {
            vm.selectedDashboard = vm.listOfDashboards.filter(e => e.Id === selectedDashboard.Id)[0];
        } else {
            vm.selectedDashboard = response.data[0];
        }

        response = await homeDataService.getCards();

        response.data = $filter('filter')(response.data, { HasAccess: 'true' });
        var listOfCards = response.data;

        listOfCards = $filter('orderBy')(listOfCards, 'Ordinal');


        //Card Defaults
        listOfCards.forEach(card => {
            card.Icon = 'icons8-combo-chart';
        });

        response = await homeDataService.getCharts();

        response.data.forEach(row => {

            var selectedCard = null;

            listOfCards.forEach(card => {
                if (card.ChartId === row.Id) {
                    selectedCard = card;
                }
            });

            if (selectedCard) {
                switch (row.ChartType) {
                    case 'table':
                        selectedCard.Chart = KPIGeneratorService.createGenericTableChart(row);
                        break;
                    case 'doughnut2d':
                        selectedCard.Chart = KPIGeneratorService.createGenericDoughnutChart(row);
                        break;
                    case 'column2d':
                        selectedCard.Chart = KPIGeneratorService.createGenericColumnChart(row);
                        break;
                    case 'tile':
                        selectedCard.Chart = KPIGeneratorService.createGenericTile(row);
                        break;
                    case 'stackedcolumn2d':
                        selectedCard.Chart = KPIGeneratorService.createGenericStackedColumnChart(row);
                        break;
                    default:
                        selectedCard.Chart = KPIGeneratorService.createGenericColumnChart(row);
                        break;
                }
            }

            listOfCards.forEach(card => {
                KPIOverrideDefinitions.overrideById(card);
            });
        });


        vm.listOfCards = listOfCards;
        vm.setDashboardSettings();

        vm.listOfCards.forEach(card => {

            if (card.Visible && card.Chart) {
                homeDataService.getChartByCard(card.Chart.APIUrl).then(response => {
                    if (card.Chart.CardType === 'tile') {
                        card.Chart = KPIGeneratorService.setTileChartValues(card.Chart, response.data);
                    }

                    if (card.Chart.CardType === 'stackedcolumn2d') {
                        card.Chart.DataSource.dataset = response.data.DataSet;
                        card.Chart.DataSource.categories = response.data.Categories;
                        response.data = KPIGeneratorService.setPropertiesForStackedChart(response.data);
                    }
                    else {
                        card.Chart.DataSource.data = response.data;
                        response.data = KPIGeneratorService.setPropertiesForChart(response.data);
                    }
                });
            }
        });
    }

    function expandNewDashboardSection() {
        vm.createDashboardIsCollapsed = !vm.createDashboardIsCollapsed;
        vm.focusNewDashboardName = true;
    }

    function launchReport(objectId) {
        ViewProductImportPageService.launchReportByObjectId(objectId);
    }

    function launchReportFromTable(label, card) {
        var field = card.Chart.DataSource.reportField;
        vm.createPartialSavedReportAndNavigate([{ 'Label': field, 'Value': label }], card.Chart.ObjectId);
    }

    function launchReportFromTile(card) {
        vm.createPartialSavedReportAndNavigate([], card.Chart.ObjectId);
    }

    function createPartialSavedReportAndNavigate(filters, objectId) {
        $rootScope.loadedSavedReport = KPIGeneratorService.createSavedReportStub(filters, objectId);
        ViewProductImportPageService.launchReportByObjectId(objectId);
    }

    async function postCardsToApi() {
        var postData = { 'Cards': [] };

        vm.listOfCards.forEach(card => {
            if (vm.selectedDashboard.Id === card.DashboardId) {

                var item = {
                    Id: card.Id,
                    Ordinal: card.Ordinal,
                    Visible: card.Visible,
                    Expanded: card.Expanded,
                    DashboardId: card.DashboardId
                };

                postData.Cards.push(item);
            }
        });
        if (postData.Cards.length > 0) {
            await homeDataService.createCard(postData);
        }
    }

    function reloadPackery() {
        if ($rootScope.packery) {
            $rootScope.packery.layout();
        }
    }

    function cardChangedVisibility(card) {
        if (card.Visible) {
            if (card.DashboardId === "00000000-0000-0000-0000-000000000000") {
                card.DashboardId = vm.selectedDashboard.Id;
            }

            homeDataService.getChartByCard(card.Chart.APIUrl).then(response => {
                if (card.Chart.CardType === 'tile') {
                    card.Chart = KPIGeneratorService.setTileChartValues(card.Chart, response.data);
                }
                if (card.Chart.CardType === 'stackedcolumn2d') {
                    card.Chart.DataSource.dataset = response.data.DataSet;
                    card.Chart.DataSource.categories = response.data.Categories;
                    response.data = KPIGeneratorService.setPropertiesForStackedChart(response.data);
                }
                else {
                    card.Chart.DataSource.data = response.data;
                    response.data = KPIGeneratorService.setPropertiesForChart(response.data);
                }

            });
        }
        vm.postCardsToApi();
        vm.reloadPackery();
    }

    async function deleteDashboard() {
        try {
            await homeDataService.deleteDashboard(vm.selectedDashboard.Id);

            $route.reload();
        } catch (error) {
            helperService.showErrorMessage(error.data);
        }
    }

    async function createDashboard() {
        let postData = {
            "Name": vm.dashboardName
        },
            response;

        try {
            response = await homeDataService.createDashboard(postData);

            vm.selectedDashboard = { "Id": response.data };
            dashboardSelected();
        } catch (error) {
            helperService.showErrorMessage(error.data);
        }
    }

    async function dashboardSelected() {
        let data = {
            Name: "DefaultDashboard",
            Value: vm.selectedDashboard.Id
        };

        try {
            await homeDataService.getDashboard(data)
            $rootScope.UserPreferences['DefaultDashboard'] = vm.selectedDashboard.Id;
            $route.reload();
        } catch {
            return;
        }
    }

    function setDashboardSettings() {
        vm.listOfCards.forEach(card => {
            if (!vm.selectedDashboard.Cards.some(c => c.Id === card.Id)) {
                card.Visible = false;
                card.Ordinal = 0;
                return;
            }
            vm.selectedDashboard.Cards.forEach(selectedCard => {
                if (card.Id === selectedCard.Id) {
                    card.Expanded = selectedCard.Expanded;
                    card.Ordinal = selectedCard.Ordinal;
                    card.Visible = selectedCard.Visible;
                    card.DashboardId = selectedCard.DashboardId;
                }
            });
        });
        vm.listOfCards = $filter('orderBy')(vm.listOfCards, 'Ordinal');
    }


    function showLabels(dataSource, showlabels, valuesToFlip) {
        if (showlabels) {
            valuesToFlip.forEach(row => {
                dataSource.chart[row] = "1";
            });
        } else {
            valuesToFlip.forEach(row => {
                dataSource.chart[row] = "0";
            });
        }
    }

}