export default /*@ngInject*/ function () {
    const vm = this;

    vm.changeSelectedOwnershipGroup = changeSelectedOwnershipGroup;    
    vm.changeSelectedItem = changeSelectedItem;
    vm.changeSelectedOwner = changeSelectedOwner;
    vm.changeOwnershipGroupObjectType = changeOwnershipGroupObjectType;

    vm.selectedGroupItemType = "item";

    function changeSelectedOwnershipGroup(ownershipGroup) {
        vm.selectedItem = null;
        vm.selectedOwner = null;
        vm.selectedOwnershipGroup = ownershipGroup;
    }

    function changeSelectedItem(item) {
        vm.selectedItem = item;
    }

    function changeSelectedOwner(owner) {
        vm.selectedOwner = owner;
    }

    function changeOwnershipGroupObjectType(groupItemType) {
        vm.selectedGroupItemType = groupItemType;
        switch (groupItemType) {
            case 'item':
                vm.selectedOwner = null;
                break;
            case 'owner':
                vm.selectedItem = null;
                break;
            default:
                vm.selectedOwner = null;
                vm.selectedItem = null;
        }
    }
}