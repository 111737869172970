export default /*@ngInject*/ function ($scope) {

    $scope.addCompositeRoleToList = function (compositeRole, productName) {
        $scope.vm.ListOfAvailableSAPCompositeRoles[productName].forEach(function (row, i) {
            if (row.Id === compositeRole.Id) {
                $scope.vm.ListOfAvailableSAPCompositeRoles[productName].splice(i, 1);
                $scope.vm.ListOfSelectedSAPCompositeRoles[productName].push(row);
            }
        });
        $scope.vm.IsRisksReviewed = null;
    };

    $scope.deleteSelectedCompositeRole = function (compositeRole, productName) {
        $scope.vm.ListOfSelectedSAPCompositeRoles[productName].forEach(function (row, i) {
            if (row.Id === compositeRole.Id) {
                $scope.vm.ListOfSelectedSAPCompositeRoles[productName].splice(i, 1);
                if (compositeRole.IsAssignable) {
                    $scope.vm.ListOfAvailableSAPCompositeRoles[productName].push(row);
                }
                if (compositeRole.IsEmergencyAccess) {
                    // Clear out selected TCodes
                    $scope.vm.ListOfAvailableSAPRoleObjects[productName] = null;
                    $scope.vm.ListOfSelectedSAPRoleObjects[productName] = null;
                }
            }
        });
        $scope.vm.IsRisksReviewed = null;
    };
}