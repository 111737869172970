import swal from "sweetalert2";
import axSecurityDesignerAffectedObjectsModalController from "../axSecurityDesignerAffectedObjectsModal.controller";
import NewAXSecurityLayerSecurityDesignerController from "../NewAXSecurityLayerSecurityDesignerController";
import { customAutoCompleteFilter, standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";
import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($scope, $uibModal, $filter, objectIdDataService, AXSecurityDesignerService, $http, helperService) {

    $scope.helperService = helperService;

    objectIdDataService.getObjectID().then(function (objectId) {
        $scope.ObjectId = objectId;

        var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;

    });

    var vm = $scope.vm = {};
    vm.disableFilter = true;
    $scope.currentPage = 1;
    $scope.itemsPerPage = 4;

    $scope.searchChanged = function () {
        var filteredResults = $filter('filterResults')(vm.securityDesignerDutiesTree, vm.searchBox, ['Name', 'SystemName']);
        if (!vm.disableFilter) {
            filteredResults = $filter('filter')(filteredResults, { IsModified: true });
        }
        $scope.filteredCount = filteredResults.length;
    };

    vm.deleteDuty = function (duty) {
        if (duty.IsCustom) {
            $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + vm.selectedSimulation.Id + '/securitylayer/duties').then(function (response) {
                var securityLayer = response.data.filter(e => e.Name.toLowerCase() === duty.Name.toLowerCase())[0];
                $http.delete(apiUrl + 'api/securitydesigner/ax7/simulation/securitylayer/' + securityLayer.Id).then(function (response) {
                    vm.simulationSelected();
                }, function errorCallback(response) {
                    return;
                });
            }, function errorCallback(response) {
                return;
            });
        } else {
            var message = "Are you sure you want to remove this duty?";
            swal(helperService.areYouSureParams('Remove Duty', message, 'Remove Duty')).then(function () {
                var postBody = {
                    "SimulationId": vm.selectedSimulation.Id,
                    "SystemName": duty.SystemName,
                    "Type": "Duty"
                };

                $http.post(apiUrl + 'api/securitydesigner/ax7/simulation/securitylayer/removals', postBody).then(function (response) {
                    duty.Action = "Remove";
                    duty.IsModified = true;
                    $scope.determineModifiedArrays();
                }, function errorCallback(response) {
                    helperService.showErrorMessage(response.data);
                });
            }, function (dismiss) {
                return;
            });
        }
    };

    vm.openCloneDutyModal = function (duty) {
        $scope.selectedSecurityObject = duty;
        $scope.securityObjectType = 'Duties';

        $uibModal.open({
            templateUrl: '/App/Shared/Partials/CloneSecurityModal.html',
            controller: cloneDutyModal,
            backdrop: 'static',
            scope: $scope
        });
    };

    vm.openAffectedObjectsModal = function (selectedDuty) {

        selectedDuty.securityLayerType = 2;

        $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/axSecurityDesignerAffectedObjectsModal.html',
            controller: axSecurityDesignerAffectedObjectsModalController,
            controllerAs: 'vm',
            backdrop: 'static',
            scope: $scope,
            resolve: {
                item: () => selectedDuty
            }
        });
    };

    vm.undoChangesToDuty = function (duty) {
        swal(helperService.areYouSureParams('Undo Changes', 'Are you sure you want to undo this change?', 'Undo')).then(function () {

            var putBody = {
                "SimulationId": vm.selectedSimulation.Id,
                "ObjectId": duty.SystemName,
                "ObjectType": "Duty"
            };

            $http.put(apiUrl + 'api/securitydesigner/ax7/simulation/' + vm.selectedSimulation.Id + '/revert', putBody).then(function (response) {
                vm.simulationSelected();
            }, function errorCallback(response) {
                helperService.showErrorMessage(response.data);
            });

        }, function (dismiss) {
            return;
        });
    };

    vm.undoRemoveDuty = function (duty) {
        swal(helperService.areYouSureParams('Add Duty Back', 'Are you sure you want to add this duty back?', 'Add Duty')).then(function () {

            $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + vm.selectedSimulation.Id + '/securitylayer/removals').then(function (response) {
                var securityLayer = response.data.filter(e => e.SystemName.toLowerCase() === duty.SystemName.toLowerCase())[0];
                $http.delete(apiUrl + 'api/securitydesigner/ax7/simulation/securitylayer/removals/' + securityLayer.Id).then(function (response) {
                    vm.simulationSelected();
                }, function errorCallback(response) {
                    return;
                });
            }, function errorCallback(response) {
                return;
            });

        }, function (dismiss) {
            return;
        });
    };

    $scope.openAXNewSecurityLayerSecurityDesignerModal = function () {
        $scope.securityLayerType = 'Duty';
        $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/NewAXSecurityLayerSecurityDesigner.html',
            controller: NewAXSecurityLayerSecurityDesignerController,
            backdrop: 'static',
            scope: $scope,
            resolve: {
                productType: () => $scope.productType || false
            }
        });
    };

    $http.get(apiUrl + 'api/securitydesigner/simulation').then(function (response) {
        response.data.remove(function (n) { return n['Status'] === 'Deployed'; });
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.securityModels = response.data;

        var securityModelInLocalStorage = localStorage.getItem("LastSelectedAX7SecurityModel");
        var selected = vm.securityModels.find(e => e.Id.toString() === securityModelInLocalStorage);

        if (securityModelInLocalStorage && selected) {
            vm.selectedSimulation = selected;
        } else {
            vm.selectedSimulation = response.data[0];
        }
        vm.simulationSelected();
    }, function errorCallback(response) {
        return;
    });

    $scope.determineModifiedArrays = function () {
        vm.securityDesignerDutiesTree.forEach(function (row) {
            var privilegesModifiedResultOfRemove = row.Privileges.filter(e => e.IsModified === true && e.Action === 'Remove').length;

            row.PrivilegesModified = row.Privileges.filter(e => e.IsModified === true).length;

            row.GrantedPrivileges = row.Privileges.filter(e => e.Action === "Grant").length;


            if (privilegesModifiedResultOfRemove === row.PrivilegesModified) {
                row.ModifiedResultOfAllRemoves = true;
            } else {
                row.ModifiedResultOfAllRemoves = false;
            }

            if (row.PrivilegesModified > 0 || row.IsCustom || row.Action === "Remove") {
                row.IsModified = true;
            } else {
                row.IsModified = false;
            }
        });
        vm.numberModified = vm.securityDesignerDutiesTree.filter(e => e.IsModified === true).length;
    };

    vm.simulationSelected = function () {
        vm.disableFilter = true;
        vm.searchBox = '';
        if (vm.selectedSimulation) {

            if (vm.selectedSimulation.Status === "Analyzing" || vm.selectedSimulation.Status === "Locked") {
                $scope.pageIsReadOnly = true;
            } else {
                $scope.pageIsReadOnly = false;
            }

            localStorage.setItem("LastSelectedAX7SecurityModel", vm.selectedSimulation.Id);
            vm.dutiesLoading = true;
            $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + vm.selectedSimulation.Id + '/dutyaccess').then(function (response) {
                vm.dutiesLoading = false;
                AXSecurityDesignerService.populateSystemNameIfCustom(response.data);
                vm.securityDesignerDutiesTree = response.data;
                $scope.determineModifiedArrays();

                $scope.filteredCount = response.data.length;

            }, function errorCallback(response) {
                vm.dutiesLoading = false;
            });

            $http.get(apiUrl + 'api/securitydesigner/ax7/simulation/' + vm.selectedSimulation.Id + '/privileges').then(function (response) {
                response.data.forEach(function (row) { row.SystemName = row.SystemName || row.Name; });
                vm.securityDesignerPrivileges = response.data;
            }, function errorCallback(response) {
                return;
            });
        }
    };

    vm.openPrivilegesModal = function (duty) {
        $scope.objectOpenInModal = duty;
        var currentPrivileges = duty.Privileges;

        var columns = [
            { title: "Active", field: "SelectedCheckbox", template: `<label ng-disabled='pageIsReadOnly' ng-show="dataItem.Action !='Remove'" class='switch'><input type='checkbox' ng-model='dataItem.SelectedCheckbox' ng-change='modalvm.selectPrivilege(dataItem)'><i></i></label>`, width: 55, filterable: false },
            { title: "Name", field: "Name", filterable: customAutoCompleteFilter },
            { title: "System Name", field: "SystemName", template: "<span ng-if='!dataItem.IsCustom'>{{dataItem.SystemName}}</span>", filterable: customAutoCompleteFilter },
            { title: "Description", field: "Description", filterable: customAutoCompleteFilter },
            { title: "License", field: "License", filterable: customAutoCompleteFilter }
        ];

        if (!duty.IsCustom) {
            columns.push({ title: " ", field: "IsModified", template: `<a ng-disabled='pageIsReadOnly' ng-click='deleteModificationToItem(dataItem)' ng-if="dataItem.IsModified && dataItem.Action !='Remove'" class='icons8-undo-2 pull-right'></a>`, width: 50, filterable: false });
        }

        vm.manageSecurityLayerGridOptions = helperService.setMainGridOptions(columns, null);
        vm.manageSecurityLayerGridOptions.selectable = false;

        vm.manageSecurityLayerGridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            sort: { field: "Name", dir: "asc" },
            transport: {
                read: function (options) {
                    vm.selectedSecurityDesignerPrivileges = vm.securityDesignerPrivileges.slice();

                    vm.selectedSecurityDesignerPrivileges.forEach(function (row) {
                        row.SelectedCheckbox = false;
                        row.IsModified = false;
                    });

                    vm.selectedSecurityDesignerPrivileges.forEach(function (row) {

                        for (var i = 0; i < currentPrivileges.length; i++) {
                            if (row.SystemName === currentPrivileges[i].SystemName) {
                                row.IsModified = currentPrivileges[i].IsModified;
                                row.Action = currentPrivileges[i].Action;
                                if (currentPrivileges[i].Action === "Grant") {
                                    row.SelectedCheckbox = true;
                                }
                                break;
                            }
                        }
                    });

                    vm.selectedSecurityDesignerPrivileges = $filter('orderBy')(vm.selectedSecurityDesignerPrivileges, 'Name');
                    options.success(vm.selectedSecurityDesignerPrivileges);
                }
            }
        });

        $uibModal.open({
            templateUrl: '/App/Components/AXSecurityDesigner/Roles/ManageRoleAXSecurityDesigner.html',
            controller: AddAXPrivilegesToDutySecurityDesigner,
            backdrop: 'static',
            size: 'lg',
            scope: $scope
        });
    };

}

function AddAXPrivilegesToDutySecurityDesigner($scope, $uibModalInstance, AXSecurityDesignerService) {

    $scope.modalvm = {};

    $scope.selectedTableFilter = "All";
    $scope.modalHeader = "Privileges";

    $scope.modalvm.selectPrivilege = function (dataItem) {
        var parentItem = $scope.vm.securityDesignerDutiesTree.filter(e => e.SystemName.toLowerCase() === $scope.objectOpenInModal.SystemName.toLowerCase())[0];

        AXSecurityDesignerService.SetInitialModifiedValueElseChangeModified(dataItem);

        parentItem.Privileges.remove(function (n) { return n['SystemName'] === dataItem.SystemName; });
        var childItem = { "IsCustom": dataItem.IsCustom, "IsModified": dataItem.IsModified, "SystemName": dataItem.SystemName, "Name": dataItem.Name };

        if (dataItem.SelectedCheckbox === true) {
            childItem.Action = "Grant";
            parentItem.Privileges.push(childItem);
            AXSecurityDesignerService.AssignChildToParent({ SimulationId: $scope.vm.selectedSimulation.Id, ChildItem: childItem, ChildType: 3, ParentItem: parentItem, ParentType: 2, Action: "Grant" });
        } else {
            childItem.Action = "Revoke";
            parentItem.Privileges.push(childItem);
            AXSecurityDesignerService.AssignChildToParent({ SimulationId: $scope.vm.selectedSimulation.Id, ChildItem: childItem, ChildType: 3, ParentItem: parentItem, ParentType: 2, Action: "Revoke" });
        }

        $scope.determineModifiedArrays();
    };

    $scope.changeFilter = function () {
        AXSecurityDesignerService.changeFilter($scope.selectedTableFilter, $scope.vm.manageSecurityLayerGrid.dataSource, "SelectedCheckbox");
    };

    $scope.deleteModificationToItem = function (dataItem) {
        dataItem.SelectedCheckbox = !dataItem.SelectedCheckbox;
        $scope.modalvm.selectPrivilege(dataItem);
    };

    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}

function cloneDutyModal($scope, helperService, $uibModalInstance, $http) {

    $scope.modalvm = {};
    $scope.modalvm.securityObjectType = $scope.securityObjectType;

    $scope.clone = function (name, description) {
        var obj = $scope.selectedSecurityObject;

        if (obj.pleaseWait === null) {
            obj.pleaseWait = {};
        }
        obj.pleaseWait = new createSpinner();
        $scope.pleaseWait = new createSpinner();

        if (description === null) {
            description = "";
        }

        var postData = {
            "SimulationId": $scope.vm.selectedSimulation.Id,
            "SystemName": obj.SystemName,
            "Name": name,
            "Description": description,
            "Type": "Duty"
        };

        $http.post(apiUrl + 'api/securitydesigner/ax7/simulation/' + $scope.vm.selectedSimulation.Id + '/clone', postData).then(function (response) {
            $scope.vm.simulationSelected();
            $uibModalInstance.close();
        }, function errorCallback(response) {
            obj.pleaseWait.loadingValue = false;
            $scope.pleaseWait.loadingValue = false;
            helperService.showErrorMessage(response.data);
        });
    };


    $scope.cancel = function () {
        $uibModalInstance.close();
    };

}



