export default /*@ngInject*/ function ($http, errorsDataService) {

    const service = {
        getTemplates,
        getSelectedClasses,
        getAllClasses,
        createTemplate,
        addClassToTemplate,
        cloneTemplate,
        updateTemplate,
        deleteTemplate,
        deleteClass
    };

    return service;

    // GET Calls
    async function getTemplates() {
        try {
            return await $http.get(`${apiUrl}api/audittrail/template`);
        } catch (err) {
            showError(err);
        }
    }

    async function getSelectedClasses(templateId) {
        try {
            return await $http.get(`${apiUrl}api/audittrail/wd/template/${templateId}/classes`);
        } catch (err) {
            showError(err);
        }
    }

    // POST calls
    async function createTemplate(postData) {
        try {
            return await $http.post(`${apiUrl}api/audittrail/template`, postData);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function getAllClasses(postData, params) {
        return await $http({
            method: 'POST',
            url: `${apiUrl}api/audittrail/wd/classes`,
            data: postData,
            params: params
        });
    }

    async function addClassToTemplate(data) {
        try {
            return await $http.post(`${apiUrl}api/audittrail/wd/template/class`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function cloneTemplate(data) {
        try {
            return await $http.post(`${apiUrl}api/audittrail/wd/template/clone`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    // PUT calls
    async function updateTemplate(data) {
        try {
            return await $http.put(`${apiUrl}api/audittrail/template/${data.Id}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    //DELETE calls
    async function deleteTemplate(templateId) {
        try {
            return await $http.delete(`${apiUrl}api/audittrail/wd/template/${templateId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }


    async function deleteClass(templateClassId) {
        try {
            return await $http.delete(`${apiUrl}api/audittrail/wd/template/classes/${templateClassId}`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    function showError(err) { errorsDataService.catch(err); }
    function sendError(err) { throw err; }

}