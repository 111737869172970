export default /*@ngInject*/ function($http, errorsDataService) {
    const service = {
        getSupervisorAssignments,
        getSupervisorAssignmentMappings,
        getProductMappingFields,
        getMappingFields,
        getPluginMappingFields,
        getInternalMappingFields,
        getUniversalMappingFields,
        createSupervisorAssignment,
        exportSupervisorAssignments,
        createAutoSupervisorAssignmentMapping,
        autoGenerateMappingsSupervisorAssignments,
        importSupervisorAssignments,
        updateAutoSupervisorAssignmentMapping,
        deleteSupervisorAssignment,
        deleteAutoSupervisorAssignmentMapping
    };

    return service;

    //GET Requests
    async function getSupervisorAssignments() {
        try {
            return await $http.get(apiUrl + 'api/universal/supervisorassignments');
        } catch (err) {
            showError(err);
        }
    }

    async function getSupervisorAssignmentMappings() {
        try {
            return await $http.get(apiUrl + 'api/universal/supervisorassignments/mappings');
        } catch (err) {
            showError(err);
        }
    }

    async function getMappingFields() {
        try {
            return await $http.get(`${apiUrl}api/universal/systemmappings/mappingfields`);
        } catch (err) {
            showError(err);
        }
    }

    async function getPluginMappingFields(pluginId) {
        try {
            return await $http.get(`${apiUrl}api/universal/pluginmapping/${pluginId}/mappingfields`);
        } catch (err) {
            showError(err);
        }
    }

    async function getProductMappingFields(productType) {
        try {
            return await $http.get(`${apiUrl}api/universal/systemmappings/mappingfields/${productType}`);
        } catch (err) {
            showError(err);
        }
    }

    async function getInternalMappingFields() {
        try {
            return await $http.get(`${apiUrl}api/universal/systemmappings/mappingfields/internal`);
        } catch (err) {
            showError(err);
        }
    }

    async function getUniversalMappingFields() {
        try {
            return await $http.get(`${apiUrl}api/universal/systemmappings/mappingfields/universal`);
        } catch (err) {
            showError(err);
        }
    }

    //POST Requests
    async function createSupervisorAssignment(data) {
        try {
            return await $http.post(apiUrl + 'api/universal/supervisorassignments', data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function exportSupervisorAssignments() {
        try {
            return await $http.post(apiUrl + 'api/universal/supervisorassignments/export');
        } catch (err) {
            showError(err);
        }
    }

    async function createAutoSupervisorAssignmentMapping(data) {
        try {
            return await $http.post(`${apiUrl}api/universal/supervisorassignments/mappings`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function autoGenerateMappingsSupervisorAssignments() {
        try {
            return await $http.post(`${apiUrl}api/universal/supervisorassignments/mappings/autogenerate`);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    //PUT Requests
    async function importSupervisorAssignments(formData) {
        try {
            return await $http.put(apiUrl + 'api/universal/supervisorassignments/import', formData, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            });
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function updateAutoSupervisorAssignmentMapping(mappingId, data) {
        try {
            return await $http.put(`${apiUrl}api/universal/supervisorassignments/mappings/${mappingId}`, data);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    //DELETE Requests
    async function deleteSupervisorAssignment(userId, supervisorId) {
        try {
            return await $http.delete(apiUrl + 'api/universal/supervisorassignments/userMasterId/' + userId + '/supervisorid/' + supervisorId);
        } catch (err) {
            showError(err);
            sendError(err);
        }
    }

    async function deleteAutoSupervisorAssignmentMapping(mappingId) {
        try {
            return await $http.delete(`${apiUrl}api/universal/supervisorassignments/mappings/${mappingId}`);
        } catch (err) {
            showError(err);
        }
    }

    function sendError(err) {
        throw err;
    }

    function showError(err) {
        errorsDataService.catch(err);
    }
}
