import notificationManagerModalController from "../NotificationManagerModal/notificationManagerModal.controller";

export default /*@ngInject*/ function (authenticationService, notificationsDataService, $rootScope, $uibModal) {

    const vm = this;

    vm.changeSelectedType = changeSelectedType;
    vm.toggleProductNotifications = toggleProductNotifications;
    vm.toggleTypeNotifications = toggleTypeNotifications;
    vm.updateTypeDescription = updateTypeDescription;

    vm.$onInit = activate();

    async function activate() {
        vm.currentUser = await authenticationService.getUser();
        setupMenuOptions();
    }

    function setupMenuOptions() {
        if ($rootScope.UserSecurityList['6301']) {
            vm.menuOptions = [
                {
                    text: 'Edit Description',
                    click: item => {
                        item.type.editedTypeDescription = item.type.Description;
                        toggleDescriptionEditor(item.type);
                    }
                },
                {
                    text: 'Manage Others',
                    click: item => {
                        openNotificationManagerModal(item.type);
                    }
                }
            ];
        }
    }

    function changeSelectedType(type) {
        vm.type = type;
    }

    function openNotificationManagerModal(type) {
        $uibModal.open({
            templateUrl: '/App/Components/Notifications/NotificationManagerModal/notificationManagerModal.html',
            controller: notificationManagerModalController,
            controllerAs: 'nmmvm',
            backdrop: 'static',
            resolve: {
                type: () => type,
                products: () => vm.products
            },
            size: 'lg'
        });
    }

    async function createSubscription(type, product) {

        
        const data = {
            NotificationTypeId: type.Id,
            UserId: vm.currentUser.userId,
            ProductId: product ? product.ID : null
        };

        return await notificationsDataService.createSubscription(data);
    }

    async function removeProductSubscription() {
        const subscriptionId = vm.mySubscriptions.find(sub => sub.UserId === vm.currentUser.userId && sub.NotificationTypeId === vm.type.Id && vm.type[sub.ProductId].Notifications === false).Id;

        return await notificationsDataService.removeSubscription(subscriptionId);
    }

    async function removeTypeSubscription() {
        const subscriptionId = vm.mySubscriptions.find(sub => sub.UserId === vm.currentUser.userId && sub.NotificationTypeId === vm.type.Id && vm.type.Notifications === false).Id;

        return await notificationsDataService.removeSubscription(subscriptionId);
    }

    async function toggleProductNotifications(type, product) {

        vm.toggling = true;

        try {
            if (type[product.ID].Notifications) {
                await createSubscription(type, product);
            } else {
                await removeProductSubscription();
            }
           vm.getSubscriptions();
            vm.toggling = false;
        } catch (_) {
            vm.toggling = false;
        }
    }

    async function updateTypeDescription(type) {

        const data = {
            Id: type.Id,
            Description: type.editedTypeDescription
        };

        try {
            await notificationsDataService.updateTypeDescription(type.Id, data);
            vm.getNotificationTypes();
        } catch (_) {
            return;
        }
    }

    async function toggleTypeNotifications(type) {

        vm.toggling = true;

        try {
            if (type.Notifications) {
                await createSubscription(type);
            } else {
                await removeTypeSubscription();
            }
            vm.getSubscriptions();
            vm.toggling = false;
        } catch (_) {
            vm.toggling = false;
        }
    }

    function toggleDescriptionEditor(type) {
        if (!type.editDescription) type.editDescription = true;
        else type.editDescription = false;
    }



}