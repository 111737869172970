import angular from 'angular';
import SAPCodeCheckerViewModel from '../Components/SAPCodeChecker/SAPCodeCheckerViewModel';
import SAPRiskQuantificationViewModel from '../Components/SAPRiskQuantification/SAPRiskQuantificationViewModel';
import sapAuthFieldGroups from '../Components/MaintainBusinessProcess/SAPAuthFieldGroups/sapAuthFieldGroups.component';
import sapAuthFieldGroupsDataServiceFactory from '../Components/MaintainBusinessProcess/SAPAuthFieldGroups/sapAuthFieldGroupsDataService.factory';
import maintainProfilesGrid from '../Components/maintainProfiles/maintainProfilesGrid/maintainProfilesGrid.component';
import maintainProfiles from '../Components/maintainProfiles/maintainProfiles.component';


export default angular
    .module('app.accessRiskMonitor.sap', [])
    .controller("sapCodeCheckerViewModel", SAPCodeCheckerViewModel)
    .controller("SAPRiskQuantificationViewModel", SAPRiskQuantificationViewModel)
    .component("sapAuthFieldGroups", sapAuthFieldGroups)
    .factory("sapAuthFieldGroupsDataService", sapAuthFieldGroupsDataServiceFactory)
    .component('maintainProfiles', maintainProfiles)
    .component('maintainProfilesGrid', maintainProfilesGrid)
    .name;