import controller from "./requestHistory.controller";

/* requestHistory.component.js */

/**
 * @desc component for identity manager request history
 * @example <request-history></request-history>
 */

const requestHistory = {
    controller: controller,
    controllerAs: 'rhvm',
    templateUrl: '/App/Components/requestHistory/requestHistory.tmpl.html'
};

export default requestHistory;