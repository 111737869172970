import { customAutoCompleteFilter } from "../../GlobalVariables/filterVariables";

export default /*@ngInject*/ function (CustomReportServices) {

    const vm = this;

    vm.$onInit = onInit;

    vm.deleteFilter = deleteFilter;
    vm.cancel = cancel;

    function onInit() {
        vm.filterManagerGridOptions = {
            height: 400,
            scrollable: { virtual: true },
            columns: [
                { field: "Name", title: "Name", filterable: customAutoCompleteFilter },
                { field: "Description", filterable: customAutoCompleteFilter },
                { field: "ReportName", title: "Report Name", filterable: customAutoCompleteFilter },
                { template: `<a ng-click="rfmvm.deleteFilter(#=Id#)"><i class="glyphicon glyphicon-trash text-danger m-r-xs"></i>Delete</a>`, "title": "Delete", width: 90 }
            ],
            sortable: true,
            resizable: true,
            reorderable: true,
            selectable: true,
            allowCopy: true,
            pageable: { numeric: false, previousNext: false, messages: { display: "Total: {2}" } }
        };
    }

    function deleteFilter(id) {
        CustomReportServices.deleteFilterFilterManager(vm.resolve, id);
    }

    function cancel() {
        vm.modalInstance.close();
    }

}