export default /*@ngInject*/ function($http, errorsDataService) {

   const service = {
      getTables,
      getFields,
      getFieldAccess,
      updateFieldAccess,
      deleteModification
   };

   return service;

   async function getTables() {
      try {
         const res = await $http.get(`${apiUrl}api/securitydesigner/ax7/tables`);
         return res;
      } catch (err) {
         showError(err);
         sendError(err);
      }
   }

   async function getFields(tableName) {
      try {
         const res = await $http.get(`${apiUrl}api/securitydesigner/ax7/table/${tableName}/fields`);
         return res;
      } catch (err) {
         showError(err);
         sendError(err);
      }
   }

   async function getFieldAccess(simulationId, privilegeName, tableName) {
      try {
         const res = await $http.get(`${apiUrl}api/securitydesigner/ax7/simulation/${simulationId}/privilegeaccess/${privilegeName}/table/${tableName}/fieldaccess`);
         return res;
      } catch (err) {
         showError(err);
         sendError(err);
      }
   }

   async function updateFieldAccess(simulationId, body) {
      try {
         await $http.post(`${apiUrl}api/securitydesigner/ax7/simulation/${simulationId}/securityobjectassociations`, body);
      } catch (err) {
         showError(err);
      }
  }

  async function deleteModification(simulationId, field) {
     try {
         const relationships = await $http.get(`${apiUrl}api/securitydesigner/ax7/simulation/${simulationId}/securityobjectassociations`);
         const modification = relationships.data.find(item => item.ChildObjectId.toLowerCase() === field.Id.toLowerCase());
         $http.delete(`${apiUrl}api/securitydesigner/ax7/simulation/securityobjectassociations/${modification.Id}`);
     } catch (err) {
         showError(err);
         sendError(err);
     }
  }

   function sendError(err) {
      throw err;
   }

   function showError(err) {
      errorsDataService.catch(err);
   }

}