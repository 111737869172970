import angular from 'angular';

// module imports
import ownershipGroupsModule from './ownershipGroups.module';
import requesterGroupsModule from './requesterGroups.module';
import supervisorAssignmentsModule from './supervisorAssignments.module';
import systemConfigurationFactoriesModule from './systemConfiguration.factories.module';
import systemConfigurationSetupModule from './systemConfiguration.setup.module';
import userRestrictionsModule from './userRestrictions.module';

// controller / component imports
import approvalGroups from '../Components/ApprovalGroups/approvalGroups.component';
import companyMapping from '../Components/CompanyMapping/companyMapping.component';
import companyTemplateCompaniesList from '../Components/CompanyTemplates/AssociatedCompaniesList/companyTemplateCompaniesList.component';
import companyTemplates from '../Components/CompanyTemplates/companyTemplates.component';
import companyTemplatesList from '../Components/CompanyTemplates/CompanyTemplatesList/companyTemplatesList.component';
import configuration from '../Components/configuration/configuration.component';
import reportManagement from '../Components/configuration/reportManagement/reportManagement.component';
import identityManagerCuaConnectionConfiguration from '../Components/configuration/identityManagerConfigTab/CuaConnectionConfiguration/cuaConnectionConfiguration.component';
import identityManagerConfigTab from '../Components/Configuration/identityManagerConfigTab/identityManagerConfigTab.component';
import identityManagerProductConfiguration from '../Components/configuration/identityManagerConfigTab/productConfiguration/productConfiguration.component';
import identityManagerProductProvisioning from '../Components/configuration/identityManagerConfigTab/ProductProvisioning/productProvisioning.component';
import crossPlatformUsers from '../Components/CrossPlatformUsers/crossPlatformUsers.component';
import maintainSystemRoles from '../Components/maintainSystemRoles/maintainSystemRoles.component';
import notifications from '../Components/Notifications/notifications.component';
import notificationTypesList from '../Components/Notifications/NotificationTypesList/notificationTypesList.component';
import roleTemplateRolesList from '../Components/RoleTemplates/AssociatedRolesList/roleTemplateRolesList.component';
import roleTemplates from '../Components/RoleTemplates/roleTemplates.component';
import roleTemplatesList from '../Components/RoleTemplates/RoleTemplatesList/roleTemplatesList.component';
import armProductRiskEligibility from '../Components/Configuration/riskEligibility/riskEligibility.component';
import armProductConfiguration from '../Components/Configuration/armProductConfiguration/armProductConfiguration.component';
import maintainSystemUsers from '../Components/maintainSystemUsers/maintainSystemUsers.component';
import maintainSystemUsersGrid from '../Components/maintainSystemUsers/maintainSystemUsersGrid.component';
import systemConfigProductConfiguration from '../Components/configuration/systemConfigProductConfiguration/systemConfigProductConfiguration.component';
import userFieldMappings from '../Components/CrossPlatformUsers/userFieldMappings/userFieldMappings.component';
import systemUserProductMappings from '../Components/CrossPlatformUsers/userFieldMappings/systemUserProductMappings.component';


export default angular
    .module('app.systemConfiguration', [ownershipGroupsModule, requesterGroupsModule, supervisorAssignmentsModule, userRestrictionsModule, systemConfigurationFactoriesModule, systemConfigurationSetupModule])
    .component('approvalGroups', approvalGroups)
    .component('companyTemplates', companyTemplates)
    .component('companyTemplatesList', companyTemplatesList)
    .component('companyTemplateCompaniesList', companyTemplateCompaniesList)
    .component('companyMapping', companyMapping) /*NEEDS RENAMED*/
    .component("crossPlatformUsers", crossPlatformUsers)
    .component("maintainSystemRoles", maintainSystemRoles)
    .component('notifications', notifications)
    .component('notificationTypesList', notificationTypesList)
    .component('roleTemplates', roleTemplates)
    .component('roleTemplatesList', roleTemplatesList)
    .component('roleTemplateRolesList', roleTemplateRolesList)
    .component("configuration", configuration)
    .component('reportManagement', reportManagement)
    .component('identityManagerConfigTab', identityManagerConfigTab)
    .component('identityManagerProductConfiguration', identityManagerProductConfiguration)
    .component('identityManagerProductProvisioning', identityManagerProductProvisioning)
    .component('identityManagerCuaConnectionConfiguration', identityManagerCuaConnectionConfiguration)
    .component('armProductRiskEligibility', armProductRiskEligibility)
    .component('armProductConfiguration', armProductConfiguration)
    .component('maintainSystemUsers', maintainSystemUsers)
    .component('maintainSystemUsersGrid', maintainSystemUsersGrid)
    .component('systemConfigProductConfiguration', systemConfigProductConfiguration)
    .component('userFieldMappings', userFieldMappings)
    .component('systemUserProductMappings', systemUserProductMappings)
    .name;