import companyTemplatesController from "./companyTemplates.controller";

var companyTemplates = {
    bindings: {
        template: '<'
    },
    controller: companyTemplatesController,
    controllerAs: 'ctvm',
    templateUrl: '/App/Components/CompanyTemplates/companyTemplates.tmpl.html'
};

export default companyTemplates;