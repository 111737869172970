export default /*@ngInject*/ function ($scope, FileSaver, objectIdDataService, $http, helperService) {

    objectIdDataService.getObjectID().then(function (objectId) {
        $scope.ObjectId = objectId;

        var securityObject = helperService.getSecurityObjectDetailsFromObjectId($scope.ObjectId);
        $scope.pageInfo = securityObject;
        $scope.pageDescription = securityObject.HelpLink;
        $scope.reportName = securityObject.Name;

    });

    $scope.helperService = helperService;

    var vm = $scope.vm = {};

    $scope.vm.showLoading = false;

    function enableDownloadButton(fileToDownload) {
        if (fileToDownload === "RiskRuleset") {
            $scope.riskRulesetDownloadInProgress = false;
        }
        if (fileToDownload === "AutoMitigationRuleset") {
            $scope.autoMitigationRulesetDownloadInProgress = false;
        }
    }

    $scope.exportFiles = function (fileToDownload) {

        function downloadFile(url, fileName) {
            $http.get(apiUrl + url, { responseType: "blob" })
            .then(function successCallback(response) {

                FileSaver.saveAs(response.data, fileName + '.xlsx');
                enableDownloadButton(fileToDownload);

            }, function errorCallback(response) {
                helperService.showErrorMessage(response.data);
                enableDownloadButton(fileToDownload);
            });
        }

        if (fileToDownload === "RiskRuleset") {
            var url = 'api/arm/risks/export';
            downloadFile(url, fileToDownload);
            $scope.riskRulesetDownloadInProgress = true;
        }
        if (fileToDownload === "AutoMitigationRuleset") {
            var url = 'api/assure/or/exclusions/export';
            downloadFile(url, fileToDownload);
            $scope.autoMitigationRulesetDownloadInProgress = true;
        }
    };

    function enableUploadButton(fileToUpload) {
        if (fileToUpload === "RiskRuleset") {
            $scope.riskRulesetUploadInProgress = false;
            vm.importRiskRulesetFlow.files = [];

        }
        if (fileToUpload === "AutoMitigationRuleset") {
            $scope.autoMitigationRulesetUploadInProgress = false;
            vm.importAutoMitigationRuleset.files = [];
        }
    }

    $scope.upload = function (files, fileToUpload) {

        function uploadFile(url) {
            $http.put(apiUrl + url, fd, {
                transformRequest: angular.identity,
                headers: { 'Content-Type': undefined }
            })
            .then(function successCallback(response) {
                enableUploadButton(fileToUpload);

            }, function errorCallback(response) {
                helperService.showErrorMessage(response.data);
                enableUploadButton(fileToUpload);
            });
        }

        var fd = new FormData();
        var fileName = files[0].file.name;
        var file = files[0].file;
        fd.append(fileName, file);

        if (fileToUpload === "RiskRuleset") {
            uploadFile('api/arm/risks/import');
            $scope.riskRulesetUploadInProgress = true;
        }
        if (fileToUpload === "AutoMitigationRuleset") {
            uploadFile('api/assure/or/exclusions/import');
            $scope.autoMitigationRulesetUploadInProgress = true;
        }

    };

}