import cuaConnectionConfigurationController from "./cuaConnectionConfiguration.controller";

/* cuaConnectionConfiguration.component.js */

/**
 * @desc component for configuring SAP CUA Connection settings for Identity Manager
 * @example <identity-manager-cua-connection-configuration></identity-manager-cua-connection-configuration>
 */

const identityManagerCuaConnectionConfiguration = {
    controller: cuaConnectionConfigurationController,
    controllerAs: 'cccvm',
    templateUrl: '/App/Components/configuration/identityManagerConfigTab/cuaConnectionConfiguration/cuaConnectionConfiguration.tmpl.html'
};

export default identityManagerCuaConnectionConfiguration;