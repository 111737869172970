import { customAutoCompleteFilter, customDatePickerFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($scope, $rootScope, $uibModalInstance, $http, helperService) {
    $scope.cancel = _ => {
        $uibModalInstance.close();
    };

    var report = $scope.report;

    var data = {
        "TransactionId": report.TransactionInternalID,
        "Date": report.LastTransactionModifiedDate,
        "MasterDataId": report.MasterDataID
    };
    var url = "";

    if ($rootScope.NSQuantDetailViewOptions.viewBy === "byUser" || $rootScope.NSQuantDetailViewOptions.viewBy === "byRisk") {
        data["UserId"] = report.UserInternalID;
        url = 'api/arm/ns/quantification/details/user';
    } else if ($rootScope.NSQuantDetailViewOptions.viewBy === "byRole") {
        data["RoleId"] = report.RoleID;
        url = 'api/arm/ns/quantification/details/role';
    }

    $scope.reportDetailsGridDataSource = new kendo.data.DataSource({
        pageSize: standardGridPageSize,
        schema: {
            model: {
                fields: {
                    Date: { type: "date" }
                }
            }
        },
        transport: {
            read: options => {
                $http({
                    method: 'POST',
                    url: apiUrl + url,
                    data: data
                }).then(response => {
                    options.success(response.data.TransactionChanges);
                });
            }
        }
    });

    getReportDetailsGridColumns();
    $scope.reportDetailsGridOptions = helperService.setMainGridOptions($scope.reportDetailsGridColumns, 400);
    $scope.reportDetailsGridOptions.selectable = false;

    function getReportDetailsGridColumns() {
        $scope.reportDetailIdentifier;
        if (report.RoleID) {
            $scope.reportDetailIdentifier = "User";
        } else {
            $scope.reportDetailIdentifier = "Role";
        }

        $scope.reportDetailsGridColumns = [
            { field: $scope.reportDetailIdentifier, title: $scope.reportDetailIdentifier, filterable: customAutoCompleteFilter },
            { field: "Date", title: "Date", template: "{{ dataItem.Date | date:'short'}}", filterable: customDatePickerFilter },
            { field: "Context", title: "Context", filterable: customAutoCompleteFilter },
            { field: "Type", title: "Type", filterable: customAutoCompleteFilter },
            { field: "Field", title: "Field", filterable: customAutoCompleteFilter },
            { field: "OldValue", title: "Old Value", filterable: customAutoCompleteFilter },
            { field: "NewValue", title: "New Value", filterable: customAutoCompleteFilter }
        ];
    }
}