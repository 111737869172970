import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($filter, helperService, snapshotsDataService) {

    const vm = this;

    vm.objectsAreEqual = objectsAreEqual;
    vm.submit = submit;

    async function getRecordTypes() {
        try {
            let response = await snapshotsDataService.getRecordType();
            response.data = $filter('orderBy')(response.data, 'DisplayName');
            vm.recordTypes = response.data;
            vm.originalRecordTypes = angular.copy(vm.recordTypes);
        } catch {
            return;
        }
    }

    getRecordTypes();

    function objectsAreEqual() {
        if (angular.equals(vm.recordTypes, vm.originalRecordTypes)) {
            return true;
        } else { return false; }
    }

    function submit() {
        let data;

        vm.pleaseWait = new createSpinner();

        vm.recordTypes.forEach(async function (row, i) {

            if (row.Enabled !== vm.originalRecordTypes[i].Enabled) {

                data = {
                    "Id": row.Id,
                    "Enabled": row.Enabled
                }

                try {
                    vm.originalRecordTypes[i].Enabled = row.Enabled;
                    await snapshotsDataService.updateRecordType(data, row.Id);
                    helperService.successfulSaveButton(vm.pleaseWait);
                } catch {
                    vm.pleaseWait.loadingValue = false;
                }
            }
        });
    }
}