import createSpinner from "../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($http, helperService, reportId, selectedReport, $uibModalInstance, savedReportsDataService) {

    const vm = this;

    vm.dismiss = dismiss;
    vm.close = close;
    vm.saveReport = saveReport;
    vm.reportName = selectedReport.Name;
    vm.reportDescription = selectedReport.Description;

    function saveReport() {

        vm.pleaseWait = new createSpinner();

        let putData = {
            "Id": selectedReport.Id,
            "Name": vm.reportName,
            "Description": vm.reportDescription,
            "ReportDefinition": {
                "ReportId": selectedReport.ReportDefinition.ReportId,
                "Columns": selectedReport.ReportDefinition.Columns,
                "Sortings": selectedReport.ReportDefinition.Sortings,
                "Criteria": selectedReport.ReportDefinition.Criteria,
                "Filters": selectedReport.ReportDefinition.Filters,
                "DateFilters": selectedReport.ReportDefinition.DateFilters
            }
        };

        savedReportsDataService.editSavedReport(reportId, putData).then(async function (response) {
            vm.killModal = true;
            await helperService.successfulSaveButton(vm.pleaseWait).then(function (result) {
                close();
            });

        }, function (response) {
            helperService.showErrorMessage(response.data);
            vm.pleaseWait.loadingValue = false;
        });

    }

    function close() {
        $uibModalInstance.close();
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }
}