import { customAutoCompleteFilter, customDatePickerFilter, standardGridPageSize } from "../../Shared/GlobalVariables/filterVariables";
import swal from "sweetalert2";
import cancellationNotesModalController from "../IdentityManagerRequestDetails/cancellationNotesModal/cancellationNotesModal.controller";

export default /*@ngInject*/ function ($rootScope, $filter, $uibModal, $location, authenticationService, helperService, openRequestsDataService) {

    const vm = this;

    vm.viewDetails = viewDetails;
    vm.editRequest = editRequest;
    vm.cancelRequest = cancelRequest;
    vm.setStatusCssClass = helperService.setStatusCssClass;

    activate();

    async function activate() {
        vm.currentUser = await authenticationService.getUser();

        const openRequestsGridColumns = [
            { field: "RequestorName", title: "Requestor Name", filterable: customAutoCompleteFilter },
            { field: "GeneralUser.Name", title: "Affected User", filterable: customAutoCompleteFilter },
            { field: "GeneralUser.Email", title: "Affected User Email", filterable: customAutoCompleteFilter },
            { field: "AffectedType", title: "Affected Type", filterable: customAutoCompleteFilter, hidden: true },
            { field: "TicketIds", title: "Ticket ID", filterable: customAutoCompleteFilter },
            { field: "RequestedDate", title: "Request Date", template: "{{ dataItem.RequestedDate | date:'short'}}", filterable: customDatePickerFilter },
            { field: "Action", title: "Action", template: "{{ dataItem.Action | insertSpaceBetweenCapitalWords }}", filterable: customAutoCompleteFilter },
            { field: "Status", template: "<span class='label' ng-class='orvm.setStatusCssClass(dataItem.Status)'>{{dataItem.Status | insertSpaceBetweenCapitalWords}}</span>", filterable: customAutoCompleteFilter },
            { template: `<a ng-click="orvm.viewDetails(dataItem)">Details</a>`, "title": "Details", width: 90 },
            { template: `<a ng-click="orvm.editRequest(dataItem)" ng-show="dataItem.isEditable"><i class="glyphicon glyphicon-edit text-warning m-r-xs"></i>Edit</a>`, title: "Edit", width: 90 },
            { template: `<a ng-click="orvm.cancelRequest(dataItem)" ng-show="dataItem.isCancellable">Cancel</a>`, "title": "Cancel", width: 90 }
        ];

        checkForCancelOrEditAllRequests();

        vm.openRequestsGridOptions = helperService.setMainGridOptions(openRequestsGridColumns, 600);
        vm.openRequestsGridOptions.selectable = false;

        vm.openRequestsGridDataSource = new kendo.data.DataSource({
            schema: {
                model: {
                    fields: {
                        RequestedDate: { type: "date" }
                    }
                }
            },
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    try {
                        let { data } = await openRequestsDataService.getOpenRequests();

                        data = $filter('orderBy')(data, 'RequestedDate', true);

                        data.forEach(row => {
                            if (row.AffectedObject) {
                                let AffectedObject = JSON.parse(row.AffectedObject);
                                if (AffectedObject) {
                                    row["AffectedObjectUserName"] = AffectedObject.Name;
                                }
                            }
                            row.isCancellable = setIsCancellableProp(row);
                            row.isEditable = setIsEditableProp(row);
                        });
                        options.success(data);
                    } catch {
                        options.error();
                    }
                }
            }
        });
    }

    function setIsCancellableProp(request) {
        return (vm.cancelAllRequests || request.Requestor === vm.currentUser.userId) && (request.Action !== 'EmergencyAccess' || (request.Action === 'EmergencyAccess' && request.Status === "Pending"));
    }

    function setIsEditableProp(request) {
        return (vm.currentUser.userId === request.Requestor || vm.editAllRequests === true) && request.Status === 'Pending' && request.Action !== 'EmergencyAccess';
    }

    function viewDetails(request) {
        $location.path('/IdentityManager/RequestDetails').search({ RequestId: request.Id, Version: request.Version });
    }

    async function checkForCancelOrEditAllRequests() {
        vm.cancelAllRequests = await openRequestsDataService.checkForCancelOrEditAllRequests("Cancel Other User's Requests");
        vm.editAllRequests = await openRequestsDataService.checkForCancelOrEditAllRequests('Edit All Requests');
    }

    function editRequest(request) {
        $rootScope.requestToEdit = { Id: request.Id, Version: request.Version };
        $location.path('/IdentityManager/Place/RequestManagement/' + request.Action).search({});
    }

    async function cancelRequest(request) {
        kendo.ui.progress(vm.openRequestsGrid.element, true);
        let data = {
            "RequestId": request.Id,
            "Version": request.Version
        };

        try {
            await swal(helperService.areYouSureParams('Cancel Request', 'Are you sure you want to cancel this request?', 'Yes', 'No'));
            const requireNotes = await helperService.getConfigurationValue('IM_RequireNoteOnCancelledRequests') === '1';
            if (requireNotes) {
                openCancellationNotesModal(data);
            } else {
                await openRequestsDataService.cancelRequest(request, data);
                kendo.ui.progress(vm.openRequestsGrid.element, false);
                vm.openRequestsGridDataSource.read();
            }
        } catch {
            kendo.ui.progress(vm.openRequestsGrid.element, false);
        }
    }

    async function openCancellationNotesModal(requestData) {
        let cancelNotesModal = $uibModal.open({
            templateUrl: '/App/Components/IdentityManagerRequestDetails/cancellationNotesModal/cancellationNotesModal.html',
            controller: cancellationNotesModalController,
            controllerAs: 'cnvm',
            backdrop: 'static',
            resolve: {
                requestData: () => requestData
            }
        });

        try {
            await cancelNotesModal.result;
            kendo.ui.progress(vm.openRequestsGrid.element, false);
            vm.openRequestsGridDataSource.read();
        } catch {
            return;
        }
    }
}
