export default /*@ngInject*/ function ($scope, RequestManagementSAPService, passwordCriteriaDataService, manageDataDataService) {
    $scope.vm.selectUser = selectUser;

    function getUserTypes() {
        $scope.vm.SAPUserTypeDataSource = RequestManagementSAPService.getSAPUserTypes();
    }

    getUserTypes();

    if (!$scope.vm.User.SAPUser) {
        $scope.vm.User.SAPUser = {};
    }

    //General User
    $scope.vm.sapUserFromDateObject = null;
    $scope.vm.sapUserToDateObject = null;
    $scope.vm.sapUserMaxDate = new Date();
    var sapUserMinDate = new Date();
    sapUserMinDate.setHours(0, 0, 0, 0);

    $scope.vm.sapUserMinDate = sapUserMinDate;
    setFieldConfigurationValues();

    $scope.sapUserFromDateChanged = function () {
        var date;
        if ($scope.vm.User.SAPUser.StartDate === '') {
            date = new Date();
        } else {
            date = new Date($scope.vm.User.SAPUser.StartDate);
        }

        $scope.vm.sapUserMinDate = date;
        $scope.vm.nsMinDate = date;
        $scope.vm.fromMinDate = date;
        if ($scope.vm.User.SAPUser == null) {
            $scope.vm.User.SAPUser = {};
        }
    };

    $scope.sapUserToDateChanged = function () {
        $scope.vm.sapUserMaxDate = new Date($scope.vm.User.SAPUser.EndDate);
        $scope.vm.nsMaxDate = new Date($scope.vm.User.SAPUser.EndDate);
        $scope.vm.fromMaxDate = new Date($scope.vm.User.SAPUser.StartDate);
        if ($scope.vm.User.SAPUser == null) {
            $scope.vm.User.SAPUser = {};
        }
    };

    async function setFieldConfigurationValues() {
        $scope.vm.showADUserLookup = $scope.vm.config.find(config => config.Name === 'AD_Active').Value === '1';
        $scope.vm.showAADUserLookup = $scope.vm.config.find(config => config.Name === 'AAD_Active').Value === '1';
        let response = await manageDataDataService.getConfigurationByProductId('SAP');
        $scope.vm.disableADUserFields = response.data.find(config => config.Name === 'IM_DisableADUserFields').Value === '1' && $scope.vm.showADUserLookup && $scope.vm.wizardMode === 'New';
        $scope.vm.disableAADUserFields = response.data.find(config => config.Name === 'IM_DisableAADUserFields').Value === '1' && $scope.vm.showAADUserLookup && $scope.vm.wizardMode === 'New';
    }

    function selectUser(user, product) {
        $scope.vm.User.SAPUser[product] = user;
    }
}