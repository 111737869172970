import roleTemplateRolesListController from "./roleTemplateRolesList.controller";

/* roleTemplateRolesList.component.js */

/**
 * @desc component for displaying and selecting roles associated with a role template
 * @example <role-template-roles-list></role-template-roles-list>
 */

const roleTemplateRolesList = {
    bindings: {
        template: '<'
    },
    controller: roleTemplateRolesListController,
    controllerAs: 'rtrlvm',
    templateUrl: '/App/Components/RoleTemplates/AssociatedRolesList/roleTemplateRolesList.tmpl.html'
};

export default roleTemplateRolesList;