import shareExportsModalController from "./shareExportsModal.controller";
import editDownloadModalController from "./editDownloadModal.controller"

export default /*@ngInject*/ function ($filter, authenticationService, ExportReportService, $uibModal, myDownloadsDataService) {

    const vm = this;

    vm.determineStatusLabelClass = determineStatusLabelClass;
    vm.setExportingOpacity = setExportingOpacity;
    vm.deleteExport = deleteExport;
    vm.downloadItemClicked = downloadItemClicked;
    vm.changeRadioFilter = changeRadioFilter;
    vm.exportsAreLoading = false;
    vm.showFilters = false;
    vm.menuOptions = [
        {
            text: 'Download',
            click: function ($itemScope, $event, modelValue) {
                downloadItem(modelValue);
            },
            displayed: function ($itemScope, $event, modelValue) {
                return (modelValue.Status === 'ReadyForDownload' || modelValue.Status === 'Downloaded');
            }
        },
        {
            text: 'Edit',
            click: function ($itemScope, $event, modelValue) {
                openEditDownloadModal(modelValue);
            },
            displayed: function (modelValue) {
                return (modelValue.exportItem.Owner.UserLogonName === vm.userName);
            },
            hasTopDivider: true
        },
        {
            text: 'Share Export',
            click: function (modelValue) {
                openShareExportModal(modelValue.exportItem);
            },
            displayed: function (modelValue) {
                return (modelValue.exportItem.Owner.UserLogonName === vm.userName);
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: function ($itemScope, $event, modelValue) {
                deleteExport(modelValue);
            },
            displayed: function (modelValue) {
                return (modelValue.exportItem.Owner.UserLogonName === vm.userName);
            }
        }
    ];

    activate();

    async function activate() {
        await getExports();
        getExportsSharedWithUser();
        vm.downloadsRadioFilter = 1;
    }

    async function getExports() {
        vm.exportsAreLoading = true;

        try {
            vm.currentUser = await authenticationService.getUser();
            vm.userName = vm.currentUser.fullName;
            let { data } = await myDownloadsDataService.getExports(vm.currentUser.userId);
            data = $filter('orderBy')(data, 'CreatedOn', 'desc');

            vm.listOfExports = data;
            vm.myDownloads = [...vm.listOfExports];

            vm.exportsAreLoading = false;
        } catch {
            vm.exportsAreLoading = false;
        }
    }

    async function getExportsSharedWithUser() {
        vm.exportsAreLoading = true;
        vm.listOfSharedExports = [];

        try {
            let { data } = await myDownloadsDataService.getExportsSharedWithUser(vm.currentUser.userId);
            data = $filter('orderBy')(data, 'CreatedOn', 'desc');

            vm.listOfSharedExports = data;

            // Only show filter bar if there are downloads shared with user
            if (vm.listOfSharedExports.length > 0) {
                vm.showFilters = true;
            } else {
                vm.showFilters = false;
            }
            vm.exportsAreLoading = false;
        } catch {
            vm.exportsAreLoading = false;
        }
    }

    function determineStatusLabelClass(ActionName) {
        if (ActionName === 'Excel') {
            return 'excel-file-icon';
        } else if (ActionName === 'PDF') {
            return 'pdf-file-icon';
        } else if (ActionName === 'Csv') {
            return 'csv-file-icon';
        }
    }

    function setExportingOpacity(status) {
        if (status === 'Exporting') {
            return 'file-exporting-opacity';
        }
    }

    async function deleteExport(item) {
        try {
            await myDownloadsDataService.deleteExport(item.Id);
            vm.listOfExports.remove(function (el) { return el.Id === item.Id; });
        } catch {
            return;
        }
    }

    function downloadItemClicked(item) {
        downloadItem(item);
    }

    function downloadItem(item) {
        ExportReportService.downloadReportExport(item);
    }

    async function openShareExportModal(modelValue) {
        let shareExportModal = await $uibModal.open({
            templateUrl: '/App/Components/myDownloads/shareExportsModal.tmpl.html',
            controller: shareExportsModalController,
            controllerAs: 'semvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                modelValue: () => modelValue
            }
        });

        try {
            await shareExportModal.result;
            getExports();
            getExportsSharedWithUser();
        } catch {
            return;
        }
    }

    async function openEditDownloadModal(modelValue) {
        let editDownloadModal = await $uibModal.open({
            templateUrl: '/App/Components/myDownloads/editDownloadModal.tmpl.html',
            controller: editDownloadModalController,
            controllerAs: 'edmvm',
            backdrop: 'static',
            resolve: {
                modelValue: () => modelValue
            }
        });

        try {
            await editDownloadModal.result;
            getExports();
            getExportsSharedWithUser();
        } catch {
            return;
        }
    }

    function changeRadioFilter(type) {
        switch (type) {
            case 'My Downloads':
                vm.listOfExports = vm.myDownloads;
                break;
            case 'Shared With Me':
                //When case type is 'Shared With Me', push each object to new array and assign proper values so Object Names align with 'vm.listOfExports' list - this ensures that the html works/displays cohesively
                vm.listOfExports = vm.listOfSharedExports.reduce((newList, row) => {
                    newList.push({
                        Id: row.ExportId,
                        Name: row.ExportName,
                        Status: row.ExportStatus,
                        CreatedOn: row.CreatedOn,
                        Type: row.ExportType,
                        Owner: {
                            UserLogonName: row.OwnerName
                        }
                    });
                    return newList;
                }, []);
                break;
            default:
                break;
        }
    }
}