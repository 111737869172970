import controller from "./autoMitigations.controller";

/* autoMitigations.component.js */

/**
 * @desc component for peoplesoft auto mitigations
 * @example <peoplesoft-auto-mitigations></peoplesoft-auto-mitigations>
 */

const autoMitigations = {
    controller: controller,
    controllerAs: 'amvm',
    templateUrl: '/App/Components/Peoplesoft/autoMitigations/autoMitigations.tmpl.html'
};

export default autoMitigations;