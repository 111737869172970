export default /*@ngInject*/ function (helperService) {
    const factory = {
        generateBody
    };

    return factory;

    function generateBody(selectedProducts, reportId, securityObjectType, users, selectedCompanies, securityObject) {

        var companiesSelected = helperService.returnNullIfAllIsFound(selectedCompanies.map(function (el) { return el.ID; }));
        var products = selectedProducts.map(function (el) { return el.ID; });

        var data = {
            "ProductIds": selectedProducts.map(function (el) { return el.ID; }),
            "ObjectId": reportId,
            "SecurityObjectTypes": [securityObjectType.Identifier],
            "UserMasterIds": users.length ? users : null,
            "CompanyMasterIds": companiesSelected,
            "SecurityObjects": [securityObject.Identifier]
        };

        if (securityObjectType.ID === null) {
            data.SecurityObjectTypes = null;
        }

        if (securityObject.Id === null) {
            data.SecurityObjects = null;
        }

        return data;
    }

}