import createSpinner from "../../../Shared/Functions/createSpinner";

export default /*@ngInject*/ function ($filter, $uibModalInstance, dataService, userRestrictionsDataService, helperService, companyMappingDataService, availableProducts) {

    const vm = this;

    vm.allCompaniesForAllProducts = [];
    vm.selectedMasterCompanies = [];

    vm.bulkMap = bulkMap;
    vm.cancel = cancel;

    activate();

    function activate() {
        getAllCompanies();
        getMasterCompanies();
    }

    function getAllCompanies() {
        availableProducts.forEach(async product => {
            let { data } = await dataService.getCompaniesByProductId(product.ID);
            data = $filter('orderBy')(data, 'Name');
            vm.allCompaniesForAllProducts = vm.allCompaniesForAllProducts.concat(data);
            vm.allCompaniesForAllProducts = $filter('orderBy')(vm.allCompaniesForAllProducts, 'ProductID');
        });

    }

    async function getMasterCompanies() {
        let { data } = await userRestrictionsDataService.getUserCompanies();
        data = $filter('orderBy')(data, 'Name');
        vm.masterCompanies = data;
    }

    async function bulkMap() {
        vm.pleaseWait = new createSpinner();

        let record = {
            "ProductID": vm.selectedProductCompany.ProductID,
            "CompanyID": vm.selectedProductCompany.ID,
            "CompanyMasterIDs": vm.selectedMasterCompanies.map(el => el.ID)
        };

        try {
            await companyMappingDataService.createBulkMapping(record);
            await helperService.successfulSaveButton(vm.pleaseWait);
            $uibModalInstance.close();
        } catch {
            vm.pleaseWait.loadingValue = false;
        }
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }

}