import createSpinner from "../../../Shared/Functions/createSpinner";
import { configurationOptions } from "../configOptions";

export default /*@ngInject*/ function (helperService, $filter, manageDataDataService, supervisorAssignmentsDataService) {
    const vm = this;

    vm.updateProductConfiguration = updateProductConfiguration;
    vm.productConfigValueChange = productConfigValueChange;
    vm.updatedProducts = [];

    vm.$onInit = activate;

    async function activate() {
        vm.unChangedAutoMapConfig = { ...vm.autoMapConfig };
        vm.products = await getNewProductProps(vm.products);
        vm.propsLoading = false;
    }

    function getNewProductProps(products) {
        vm.propsLoading = true;
        const promises = products.map(async product => {
            return {
                ...product,
                systemFields: await getSystemFields(product),
                logo: helperService.setProductImageClass(product.Type),
                configuration: await getProductConfiguration(product),
                unChangedConfiguration: await getProductConfiguration(product),
                configValuesUnchanged: true
            }
        });
        return Promise.all(promises);
    }

    async function getSystemFields(product) {
        const { data: universalFields } = await supervisorAssignmentsDataService.getUniversalMappingFields(),
            { data: productSpecificFields } = await supervisorAssignmentsDataService.getProductMappingFields(product.Type);

        return $filter('orderBy')(universalFields.concat(productSpecificFields), 'DisplayName');
    }

    async function getProductConfiguration(product) {
        const configuration = {},
            { data } = await manageDataDataService.getConfigurationByProductId(product.ID);

        data.forEach(item => {
            configuration[item.Name] = item.Value;
        });

        return configuration;
    }

    function productConfigValueChange(product) {
        product.configValuesUnchanged = angular.equals(product.unChangedConfiguration, product.configuration);
        vm.updatedProducts = vm.products.filter(app => !app.configValuesUnchanged);
    }

    async function updateProductConfiguration() {
        vm.updatePending = new createSpinner();

        if (vm.unChangedAutoMapConfig[0] !== vm.autoMapConfig) {
            await helperService.setConfigurationValue('AutoMapCrossPlatformUsersAfterImport', vm.autoMapConfig);
        }

        vm.updatedProducts.forEach(async product => {
            let putData = {
                "Configurations": [],
                "ProductId": product.ID
            };

            if (product.configuration.AutoMappingSystemFieldId !== product.unChangedConfiguration.AutoMappingSystemFieldId) {
                putData.Configurations.push({
                    "Name": 'AutoMappingSystemFieldId',
                    "Value": product.configuration.AutoMappingSystemFieldId
                });
            }
            await manageDataDataService.setProductConfiguration(product.ID, putData);
            product.unChangedConfiguration = { ...product.configuration };
            productConfigValueChange(product);
        });

        await helperService.successfulSaveButton(vm.updatePending);
        vm.xplatConfigForm.$setPristine();
    }
}