export default /*@ngInject*/ function ($http, $filter) {

    //Product Multiselect
    this.checkSavedReportForProducts = function (savedReport, data, selected) {
        if (savedReport && savedReport.ReportDefinition.Criteria.length > 0) {

            var selectedDefinitions = savedReport.ReportDefinition.Criteria.filter(function (n) { return n.CriteriaType === "Product"; });

            var emptyArray = [];

            selectedDefinitions.forEach(function (definition, i) {
                definition.Filters.forEach(function (row, i) {
                    emptyArray.push(data.filter(e => e.ID === row.Value)[0]);
                });
            });

            if (emptyArray.length > 0) {
                return emptyArray;
            }
        } else {
            return selected;
        }
    };

    //Single Product
    this.checkSavedReportForSingleProduct = function (savedReport, data, selected) {
        if (savedReport) {
            var selectedDefinitions = savedReport.ReportDefinition.Criteria.filter(function (n) { return n.CriteriaType === "Product"; });
            if (selectedDefinitions.length > 0) {
                return data.filter(e => e.ID === selectedDefinitions[0].Filters[0].Value)[0];
            } else {
                return selected;
            }
        } else {
            return selected;
        }
    };

    this.checkSavedReportForDate = function (savedReport, criteriaType, selected) {
        if (savedReport) {
            var selectedDefinitions = savedReport.ReportDefinition.Criteria.filter(function (n) { return n.CriteriaType === criteriaType; });

            if (selectedDefinitions && selectedDefinitions.length > 0) {
                var date = new Date(selectedDefinitions[0].CriteriaValue);
                return date;
            }
            else {
                return selected;
            }

        } else {
            return selected;
        }
    };

    this.checkSavedReportForRoles = function (savedReport, data, selected) {
        if (savedReport) {
            var selectedDefinitions = savedReport.ReportDefinition.Criteria.filter(function (n) { return n.CriteriaType === "Role"; });

            var emptyArray = [];

            selectedDefinitions.forEach(function (definition, i) {
                definition.Filters.forEach(function (row, i) {
                    emptyArray.push(data.filter(e => e.ID === row.Value)[0]);
                });
            });
            if (emptyArray.length > 0) {
                return emptyArray;
            } else {
                return selected;
            }
        } else {
            return selected;
        }
    };

    this.getSavedReportRoles = function (savedReport) {

        const savedReportRoles = savedReport.ReportDefinition.Criteria.filter(c => c.CriteriaType === 'Role');
        const savedReportProduct = savedReport.ReportDefinition.Criteria.filter(c => c.CriteriaType === 'Product');

        let fullRoles = [];

        async function asyncForEach(array, callback) {
            for (let index = 0; index < array.length; index++) {
                await callback(array[index], index, array);
            }
        }
        const getFullRoles = async () => {
            await asyncForEach(savedReportRoles, async (role) => {
                try {
                    let res = await $http.get(`${apiUrl}api/universal/securityroles/${role.Filters[0].Value}/product/${savedReportProduct[0].Filters[0].Value}`);
                    fullRoles.push(res.data);
                } catch (err) {
                    throw err;
                }
            });
            const sortedFullRoles = fullRoles.sort((a, b) => a.Name - b.Name);
            return sortedFullRoles;
        };

        const roles = getFullRoles();
        return roles;
    };

    this.checkSavedReportForRisks = function (savedReport, data, selected) {
        if (savedReport) {
            var selectedDefinitions = savedReport.ReportDefinition.Criteria.filter(function (n) { return n.CriteriaType === "Risk"; });

            var emptyArray = [];

            selectedDefinitions.forEach(function (definition, i) {
                definition.Filters.forEach(function (row, i) {
                    emptyArray.push(data.filter(e => e.Id === row.Value)[0]);
                });
            });

            if (emptyArray.length > 0) {
                return emptyArray;
            } else {
                return selected;
            }
        } else {
            return selected;
        }
    };

    this.checkSavedReportForCompanies = function (savedReport, data, selected) {
        if (savedReport) {
            var selectedDefinitions = savedReport.ReportDefinition.Criteria.filter(function (n) { return n.CriteriaType === "Company"; });

            var emptyArray = [];

            selectedDefinitions.forEach(function (definition, i) {
                definition.Filters.forEach(function (row, i) {
                    emptyArray.push(data.filter(e => e.ID === row.Value)[0]);
                });
            });

            if (emptyArray.length > 0) {
                return emptyArray;
            } else {
                return selected;
            }
        } else {
            return selected;
        }
    };

    this.checkSavedReportForObjectTypes = function (savedReport, data, selected) {
        if (savedReport) {
            var selectedDefinitions = savedReport.ReportDefinition.Criteria.filter(function (n) { return n.CriteriaType === "SecurityObjectType"; });
            var selectedValue;

            selectedDefinitions.forEach(function (definition, i) {
                var Id = definition.Filters.find(function (n) { return n.FieldName === 'SecurityObjectTypeIdentifier'; });
                selectedValue = data.filter(e => e.Identifier === Id.Value)[0];
            });

            if (selectedValue) {
                return selectedValue;
            } else {
                return data[0];
            }
        } else {
            return selected;
        }
    };

    this.checkSavedReportForObjects = function (savedReport, data, selected) {
        if (savedReport) {
            var selectedDefinitions = savedReport.ReportDefinition.Criteria.filter(function (n) { return n.CriteriaType === "SecurityObject"; });
            var selectedValue;

            selectedDefinitions.forEach(function (definition, i) {
                var Id = definition.Filters.find(function (n) { return n.FieldName === 'SecurityObjectIdentifier'; });
                selectedValue = data.filter(e => e.Identifier === Id.Value)[0];
            });
            if (selectedValue) {
                return selectedValue;
            } else {
                return selected;
            }
        } else {
            return selected;
        }
    };

    this.getSavedReportSelectedObject = function (savedReport) {
        var selectedDefinitions = savedReport.ReportDefinition.Criteria.filter(function (n) { return n.CriteriaType === "SecurityObject"; });
        var selectedValueIdentifier;

        selectedDefinitions.forEach(definition => {
            var Id = definition.Filters.find(n => n.FieldName === 'SecurityObjectIdentifier');
            selectedValueIdentifier = Id.Value;
        });
        if (selectedValueIdentifier) {
            return $http.get(apiUrl + 'api/universal/securityobjects/' + selectedValueIdentifier)
                .then(response => {
                    return response.data;
                });
        } else {
            return Promise.resolve();
        }
    };

    this.checkSavedReportForUsers = function (savedReport, data, selected) {
        if (savedReport) {
            var selectedDefinitions = savedReport.ReportDefinition.Criteria.filter(function (n) { return n.CriteriaType === "User"; });
            var selectedValue;
            var emptyArray = [];
            selectedDefinitions.forEach(function (definition, i) {

                var UserMasterId = definition.Filters.find(function (n) { return n.FieldName === "UserMasterId"; });
                if (UserMasterId) {
                    selectedValue = data.filter(e => e.ID === UserMasterId.Value)[0];
                }
                else {
                    definition.Filters.forEach(function (row, i) {
                        emptyArray.push(data.filter(e => e.InternalId === row.Value)[0]);
                    });
                }

            });
            if (emptyArray.length > 0) {
                return emptyArray;
            } else if (selectedValue) {
                return selectedValue;
            } else {
                return selected;
            }
        } else {
            return selected;
        }
    };

    this.checkSavedReportForBusinessProcesses = function (savedReport, data, selected) {
        if (savedReport) {
            var selectedDefinitions = savedReport.ReportDefinition.Criteria.filter(function (n) { return n.CriteriaType === "BusinessProcessId"; });
            var emptyArray = [];
            selectedDefinitions.forEach(function (definition, i) {
                definition.Filters.forEach(function (row, i) {
                    emptyArray.push(data.filter(e => e.Id === row.Value)[0]);
                });
            });
            if (emptyArray.length > 0) {
                return emptyArray;
            } else {
                return selected;
            }
        } else {
            return selected;
        }
    };

    this.checkSavedReportForRuleset = function (savedReport, data, selected) {
        if (savedReport) {
            var selectedDefinitions = savedReport.ReportDefinition.Criteria.filter(function (n) { return n.CriteriaType === "Ruleset"; });

            if (selectedDefinitions.length === 1) {
                var selectedValue = data.filter(e => e.RulesetId === selectedDefinitions[0].Filters[0].Value)[0];
            }

            if (selectedValue) {
                return selectedValue;
            }
            else {
                return selected.filter(e => e.IsDefault === true)[0];
            }

        } else {
            if (selected.length > 0) {
                return selected.filter(e => e.IsDefault === true)[0];
            }
        }
    };

    this.checkSavedReportForResponsibilities = function (savedReport, data, selected) {
        if (savedReport) {
            var selectedDefinitions = savedReport.ReportDefinition.Criteria.filter(function (n) { return n.CriteriaType === "Responsibility"; });

            var emptyArray = [];

            selectedDefinitions.forEach(function (definition, i) {
                definition.Filters.forEach(function (row, i) {
                    emptyArray.push(data.filter(e => e.ID === row.Value)[0]);
                });
            });
            if (emptyArray.length > 0) {
                return emptyArray;
            } else {
                return selected;
            }
        } else {
            return selected;
        }
    };

    this.checkSavedReportForRecordTypeMultiSelect = function (savedReport, data, selected) {
        if (savedReport) {
            var selectedDefinitions = savedReport.ReportDefinition.Criteria.filter(function (n) { return n.CriteriaType === "RecordType" || n.CriteriaType === "TemplateId" || n.CriteriaType === "UserGroupId"; });

            let emptyArray = [];

            selectedDefinitions.forEach(function (definition, i) {
                definition.Filters.forEach(function (row, i) {
                    const matchingItem = data.find(e => e.Id === row.Value);
                    if (matchingItem) {
                        emptyArray.push(matchingItem);
                    }
                });
            });

            if (emptyArray.length > 0) {
                return emptyArray;
            } else {
                return selected;
            }
        } else {
            return selected;
        }
    };

    this.checkSavedReportForRecordType = function (savedReport, data, selected) {
        if (savedReport) {
            var selectedDefinitions = savedReport.ReportDefinition.Criteria.filter(function (n) { return n.CriteriaType === "RecordType"; });
            return data.filter(e => e.Id === selectedDefinitions[0].Filters[0].Value)[0];

        } else {
            return selected;
        }
    };

    this.checkSavedReportForRecord = function (savedReport, data, selected) {
        if (savedReport) {
            var selectedDefinitions = savedReport.ReportDefinition.Criteria.filter(function (n) { return n.CriteriaType === "Record"; });

            if (selectedDefinitions.length === 1) {
                return data.filter(e => e.RecordId === selectedDefinitions[0].Filters[0].Value)[0];
            } else {
                return selected;
            }

        } else {
            return selected;
        }
    };

    this.checkSavedReportForStatus = function (savedReport, data, selected) {
        if (savedReport) {
            var selectedDefinitions = savedReport.ReportDefinition.Criteria.filter(function (n) { return n.CriteriaType === "Status"; });

            var emptyArray = [];

            selectedDefinitions.forEach(function (definition, i) {
                definition.Filters.forEach(function (row, i) {
                    emptyArray.push(data.filter(e => e.ID === row.Value)[0]);
                });
            });

            if (emptyArray.length > 0) {
                return emptyArray;
            } else {
                return selected;
            }
        } else {
            return selected;
        }
    };

    this.checkSavedReportForIntraResponsibility = function (savedReport, selected) {
        if (savedReport) {
            var selectedDefinition = savedReport.ReportDefinition.Criteria.find(function (n) { return n.CriteriaType === "IntraResponsibilityOnly"; });
            return selectedDefinition ? selectedDefinition.Filters[0].Value : false;
        } else {
            return selected;
        }
    };

    this.orderAndRemoveColumns = function (currentColumns, newColumns) {

        var newFields = newColumns.map(function (el) { return el.FieldName; });
        currentColumns.forEach(function (column, i) {
            var columnIsContained = newFields.indexOf(column.field);
            column.ordinal = columnIsContained;



            if (column.title !== "" && column.title !== "Edit") {

                delete column['headerAttributes'];
                delete column['footerAttributes'];
                delete column['attributes'];

                if (columnIsContained === -1) {
                    //field does not exist
                    column.hidden = true;
                } else if (columnIsContained > -1) {
                    //field is contained in newColumns
                    column.hidden = false;
                }
            } else {
                //column does not have a title so it is ignored by this function
            }
        });

        currentColumns = $filter('orderBy')(currentColumns, 'ordinal');
        return currentColumns;
    };

    this.convertSortingsForGrid = function (currentSort, newSort) {
        var convertedNewSorts = [];

        newSort.forEach(function (column, i) {
            var convertedFormat = {

                "field": column.FieldName,
                "dir": column.SortDirection.toLowerCase(),
                "compare": null
            };
            convertedNewSorts.push(convertedFormat);
        });

        return convertedNewSorts;
    };


    this.queryGridWithOptions = function (loadedSavedReport, grid, manuallyCallReady) {
        if (loadedSavedReport) {

            var newOptions = angular.copy(loadedSavedReport.ReportDefinition);
            var currentOptions = grid.getOptions();

            if (newOptions.Columns.length > 0) {
                currentOptions.columns = this.orderAndRemoveColumns(currentOptions.columns, newOptions.Columns);
            }

            if (newOptions.Sortings.length > 0) {
                currentOptions.dataSource.sort = this.convertSortingsForGrid(currentOptions.dataSource.sort, newOptions.Sortings);
            }

            grid.setOptions({ "columns": currentOptions.columns });
            grid.dataSource._filter = null;
            grid.dataSource._sort = currentOptions.dataSource.sort;
            grid.dataSource.read();

        } else if (manuallyCallReady) {
            grid.dataSource.page(1);
        }
        //Set all columns that are not hidden to false
        grid.columns.forEach(function (col) {
            if (col.hidden === null) {
                col.hidden = false;
            }
        });
    };

    this.queryAuditLogsGridWithOptions = function (loadedSavedReport, grid, manuallyCallReady) {
        if (loadedSavedReport) {

            var newOptions = angular.copy(loadedSavedReport.ReportDefinition);
            var currentOptions = grid.getOptions();

            if (newOptions.Columns.length > 0) {
                currentOptions.columns = this.orderAndRemoveColumns(currentOptions.columns, newOptions.Columns);
            }

            if (newOptions.Sortings.length > 0) {
                currentOptions.dataSource.sort = this.convertSortingsForGrid(currentOptions.dataSource.sort, newOptions.Sortings);
            }

            grid.setOptions({ "columns": currentOptions.columns });

            if (newOptions.Filters.length === 1) {
                const filter = newOptions.Filters[0];
                grid.dataSource._filter = {
                    field: filter.FieldName,
                    operator: filter.Operator,
                    value: filter.Value
                }
            } else if (newOptions.Filters.length > 1) {
                grid.dataSource._filter = {};
                grid.dataSource._filter.filters = [];
                newOptions.Filters.forEach(filter => {
                    let object = {
                        field: filter.FieldName,
                        operator: filter.Operator,
                        value: filter.Value
                    };
                    grid.dataSource._filter.filters.push(object);
                })
            }

            grid.dataSource._sort = currentOptions.dataSource.sort;
            grid.dataSource.read();

        } else if (manuallyCallReady) {
            grid.dataSource.page(1);
        }
        //Set all columns that are not hidden to false
        grid.columns.forEach(function (col) {
            if (col.hidden === null) {
                col.hidden = false;
            }
        });
    };

    this.cleanReportCriteria = function (criteriaMultiple, criteriaToAdd) {
        var criteria = [];

        //If criteria exists, clean it
        if (criteriaMultiple) {
            criteriaMultiple.forEach(function (row) {
                if (row.ParameterName === "SecurityObjectType") {
                    if (row.Value) {
                        if (row.Value.Id) {
                            var singleCriteria = {
                                "CriteriaType": row.DisplayName,
                                "CriteriaValue": row.DisplayValue,
                                "Filters": [
                                    {
                                        "FieldName": 'SecurityObjectTypeIdentifier',
                                        "Operator": "Equals",
                                        "Value": row.Value.Id
                                    }
                                ]
                            };
                            criteria.push(singleCriteria);
                        }
                    }
                } else if (row.ParameterName === "SecurityObject") {
                    if (row.Value) {
                        if (row.Value.Id) {
                            var singleCriteria = {
                                "CriteriaType": row.DisplayName,
                                "CriteriaValue": row.DisplayValue,
                                "Filters": [
                                    {
                                        "FieldName": 'SecurityObjectIdentifier',
                                        "Operator": "Equals",
                                        "Value": row.Value.Id
                                    }
                                ]
                            };
                            criteria.push(singleCriteria);
                        }
                    }
                } else {
                    if (row.Value && row.Value !== 'AllObjectsInMultiSelect' && row.Value !== -1) {
                        var singleCriteria = {
                            "CriteriaType": row.DisplayName,
                            "CriteriaValue": row.DisplayValue,

                            "Filters": [
                                {
                                    "FieldName": row.ParameterName,
                                    "Operator": "Equals",
                                    "Value": row.Value
                                }
                            ]
                        };
                        criteria.push(singleCriteria);
                    }
                }
            });
        }
        //If criteria exists, clean it
        if (criteriaToAdd) {
            criteriaToAdd.forEach(function (row) {
                var singleCriteria = {
                    "CriteriaDisplayName": row.CriteriaDisplayName,
                    "CriteriaValue": row.CriteriaValue,

                    "Filters": [
                        {
                            "FieldName": row.FieldName,
                            "Operator": row.Operator,
                            "Value": row.Value
                        }
                    ]
                };
                criteria.push(singleCriteria);
            });
        }
        return criteria;
    };

}