export default /*@ngInject*/ function ($scope, configurationDataService) {

    getRoleDescConfigValue();

    async function getRoleDescConfigValue() {
        let response = await configurationDataService.getProductConfigurationValueByName('gp', 'IM_ShowRoleDescription');
        $scope.vm.roleDesc = response.data.Value;
    }

    if (!$scope.vm.ListOfStoredGPRoles) $scope.vm.ListOfStoredGPRoles = [];

    $scope.addRoleToList = function (role) {
        $scope.vm.ListOfAvailableGPRoles.forEach(function (row, i) {
            if (row.Id === role.Id) {
                $scope.vm.ListOfAvailableGPRoles.splice(i, 1);
                if (!$scope.vm.ListOfStoredGPRoles) $scope.vm.ListOfStoredGPRoles = [];
                $scope.vm.ListOfStoredGPRoles.push({ Role: row, Company: $scope.vm.selectedCompany });
                $scope.vm.ListOfSelectedGPRoles.push({ Role: row, Company: $scope.vm.selectedCompany });
            }
        });
        $scope.vm.IsRisksReviewed = null;
    };

    $scope.deleteSelectedRole = function (role) {
        $scope.vm.ListOfSelectedGPRoles.forEach(function (row, i) {
            if (row.Role.Id === role.Role.Id && row.Company.Id === role.Company.Id) {

                $scope.vm.ListOfSelectedGPRoles.splice(i, 1);
                //This company is for display purposes on the Summary page
                row.Role.Company = row.Company;
                if (role.Role.IsAssignable) {
                    $scope.vm.ListOfAvailableGPRoles.push(row.Role);
                }
            }
        });
        $scope.vm.ListOfStoredGPRoles.forEach(function (row, i) {
            if (row.Role.Id === role.Role.Id && row.Company.Id === role.Company.Id) {
                $scope.vm.ListOfStoredGPRoles.splice(i, 1);
            }
        });


        $scope.vm.IsRisksReviewed = null;
    };

    $scope.addNewCompany = function () {
        var newCompany = angular.copy($scope.vm.selectedCompany);
        $scope.vm.User.GPUser.Companies.push({ AltModProfile: $scope.vm.currentAltModProfile, Company: newCompany });
    };

    $scope.companyDropdownChange = function () {
        $scope.refreshLists();
    };

    $scope.profileDropdownChange = function () {
        //Set the AltModProfile on the company
        if (!$scope.vm.User.GPUser.Companies) {
            $scope.vm.User.GPUser.Companies = [];
        }

        var foundCompany = $scope.vm.User.GPUser.Companies.find(function (company) {
            return company.Company.Id === $scope.vm.selectedCompany.Id;
        });

        if (foundCompany) {
            foundCompany.AltModProfile = $scope.vm.currentAltModProfile;
        } else {
            $scope.addNewCompany();
        }
    };

    $scope.refreshLists = function () {
        //Sets the AltModProfile for the selected company if there is an existing user
        if ($scope.vm.selectedCompany && $scope.vm.User.GPUser && $scope.vm.User.GPUser.Companies) {
            let foundCompany = $scope.vm.User.GPUser.Companies.find(company => company?.Company?.Id === $scope.vm.selectedCompany.Id);

            if (foundCompany) {
                if (foundCompany.AltModProfile !== "") {
                    var altModProfileId = foundCompany.AltModProfile.Id ? foundCompany.AltModProfile.Id : foundCompany.AltModProfile;
                    $scope.vm.currentAltModProfile = $scope.vm.GPAltModProfileDataSource.find(profile => profile.Id === altModProfileId);
                }
                else {
                    foundCompany.AltModProfile = $scope.vm.GPAltModProfileDataSource[0];
                    $scope.vm.currentAltModProfile = $scope.vm.GPAltModProfileDataSource[0];
                }
            } else {
                $scope.vm.currentAltModProfile = $scope.vm.GPAltModProfileDataSource[0];
                $scope.addNewCompany();
            }
        } else {
            if (!$scope.vm.User.GPUser.Companies) {
                $scope.vm.User.GPUser.Companies = [];
            }
            $scope.vm.currentAltModProfile = $scope.vm.GPAltModProfileDataSource[0];
            $scope.addNewCompany();
        }

        if (!$scope.vm.selectedCompany) {
            $scope.vm.ListOfAvailableGPRoles = [];
            $scope.vm.currentAltModProfile = null;
        }

        $scope.vm.ListOfSelectedGPRoles = [];

        //Load the Roles for the selected company
        if ($scope.vm.selectedCompany) {
            $scope.vm.ListOfAvailableGPRoles = angular.copy($scope.vm.ListOfAllAvailableGPRoles);
            if ($scope.defaultRoleTemplate && $scope.vm.wizardMode === "New" && !$scope.vm.requestToEdit) { 
                let defaultRoles = $scope.defaultRoleTemplate.Roles.filter(role => role.TypeId === "Role" && role.ProductType === "GP");

                defaultRoles.forEach(role => {
                    $scope.addRoleToList(role);
                });
            } else if ($scope.vm.ListOfStoredGPRoles) {
                $scope.vm.ListOfStoredGPRoles.forEach(roleCompany => {
                    if (roleCompany.Company.Id.toString() === $scope.vm.selectedCompany.Id.toString()) {

                        roleCompany.Pending = $scope.vm.PendingGpRoleCompanies.some(pendingRole => pendingRole.Role.Id === roleCompany.Role.Id && pendingRole.Company.Id === roleCompany.Company.Id);

                        $scope.vm.ListOfSelectedGPRoles.push(roleCompany);

                        $scope.vm.ListOfAvailableGPRoles.forEach(function (row, i) {
                            if (row.Id === roleCompany.Role.Id) {
                                $scope.vm.ListOfAvailableGPRoles.splice(i, 1);
                            }
                        });
                    }
                });
            }

            // Remove Pending Roles by Company Here
            $scope.vm.ListOfAvailableGPRoles = $scope.vm.ListOfAvailableGPRoles.map(availableItem => {
                availableItem.Pending = $scope.vm?.PendingGpRoleCompanies?.find(byCompanyAndRole.bind(null, $scope.vm.selectedCompany, availableItem));

                return availableItem;
            });
        }
    };

    $scope.setSelectedRoleTemplates = function (selectedRoleTemplates) {
        $scope.vm.selectedRoleTemplates = selectedRoleTemplates;
    };
}

function byCompanyAndRole(selectedCompany, availableItem, pendingItem) {
    return availableItem.Id === pendingItem.Role.Id && selectedCompany.Id === pendingItem.Company.Id;
}