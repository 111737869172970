import angular from 'angular';
import AXSecurityDesignerDutiesViewModel from '../Components/AXSecurityDesigner/Duties/AXSecurityDesignerDutiesViewModel';
import AXSecurityDesignerPrivilegesViewModel from '../Components/AXSecurityDesigner/Privileges/AXSecurityDesignerPrivilegesViewModel';
import AXSecurityDesignerRolesViewModel from '../Components/AXSecurityDesigner/Roles/AXSecurityDesignerRolesViewModel';
import AXSecurityDesignerSecurityModelsViewModel from '../Components/AXSecurityDesigner/SecurityModels/AXSecurityDesignerSecurityModelsViewModel';
import AXSecurityDesignerSimulationDetailsViewModel from '../Components/AXSecurityDesigner/SimulationDetails/AXSecurityDesignerSimulationDetailsViewModel';
import taskRecordingManagerController from '../Components/AXSecurityDesigner/taskRecordingManager/taskRecordingManager.controller';
import securityDesignerFactoriesModule from './securityDesigner.factories.module';
import securityDesignerComponentsModule from './securityDesigner.components.module';

export default angular
    .module('app.securityDesigner', [securityDesignerFactoriesModule, securityDesignerComponentsModule])
    .controller("AXSecurityDesignerDutiesViewModel", AXSecurityDesignerDutiesViewModel)
    .controller("AXSecurityDesignerPrivilegesViewModel", AXSecurityDesignerPrivilegesViewModel)
    .controller("AXSecurityDesignerRolesViewModel", AXSecurityDesignerRolesViewModel)
    .controller("AXSecurityDesignerSecurityModelsViewModel", AXSecurityDesignerSecurityModelsViewModel)
    .controller("AXSecurityDesignerSimulationDetailsViewModel", AXSecurityDesignerSimulationDetailsViewModel)
    .controller("taskRecordingManagerController", taskRecordingManagerController)
    .name;