import createSpinner from "../../../Shared/Functions/createSpinner";
import { standardGridPageSize } from "../../../Shared/GlobalVariables/filterVariables";

export default /*@ngInject*/ function ($uibModalInstance, $filter, ownershipGroup, groupOwner, helperService, riskRulesetService, risksDataService, ownershipGroupsDataService, ownershipGroupItemsDataService) {
    const vm = this;

    vm.selectedRoleTypeChanged = selectedRoleTypeChanged;
    vm.selectedCompanyTypeChanged = selectedCompanyTypeChanged;
    vm.updateDropdowns = updateDropdowns;
    vm.saveItem = saveItem;
    vm.cancel = cancel;

    vm.ownershipGroup = { ...ownershipGroup };
    vm.groupOwner = groupOwner;

    activate();

    async function activate() {
        await setupModal();
    }

    async function setupModal() {
        switch (vm.ownershipGroup.GroupType) {
            case 'role':
                vm.selectedRoleType = 'Role';
                setupRoleDropdown();
                break;
            case 'company':
                vm.selectedCompanyType = 'Company';
                setupCompanyDropdown();
                break;
            case 'product':
                setProducts();
                break;
            case 'businessprocess':
                await setRulesets();
                setupBusinessProcessDropdown();
                break;
            case 'risk':
                vm.selectedRiskType = null;
                await setRulesets();
                getRiskTypes();
                setupRiskDropdown();
                break;
            default:
                break;
        }
    }

    async function setRulesets() {
        let { data } = await riskRulesetService.getRiskRulesetList();
        vm.rulesets = data;

        vm.selectedRulesetId = vm.rulesets.find(ruleset => ruleset.IsDefault).RulesetId;

    }

    // Role
    function setupRoleDropdown() {

        vm.rolesDropdownOptions = {
            filter: "contains",
            autoClose: false,
            virtual: {
                itemHeight: 26,
                mapValueTo: 'dataItem',
                valueMapper: function (options) {
                    options.success(helperService.multiValueMapperFunction(options, vm.rolesDataSource, 'Identifier'));
                }
            },
            height: 325,
            placeholder: `-- Select Roles --`
        };

        vm.rolesDropdownDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async (options) => {
                    vm.rolesDropdownLoading = true;
                    let { data } = await ownershipGroupItemsDataService.getAvailableOwnerItems(ownershipGroup, groupOwner.Id);
                    data = data.filter(role => role.Type === vm.selectedRoleType);
                    vm.rolesDataSource = data;
                    options.success(data);
                    vm.rolesDropdownLoading = false;
                }
            }
        });
    }

    function selectedRoleTypeChanged() {
        vm.rolesDropdown.value([]);
        vm.rolesDropdown.setOptions({ placeholder: `-- Select ${$filter('insertSpaceBetweenCapitalWords')(vm.selectedRoleType)}s --` });
        vm.rolesDropdownDataSource.read();
    }

    // Company
    function setupCompanyDropdown() {

        vm.companiesDropdownOptions = {
            filter: "contains",
            autoClose: false,
            virtual: {
                itemHeight: 26,
                mapValueTo: 'dataItem',
                valueMapper: function (options) {
                    options.success(helperService.multiValueMapperFunction(options, vm.companiesDataSource, 'Id'));
                }
            },
            height: 325,
            placeholder: `-- Select Companies --`
        };

        vm.companiesDropdownDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async (options) => {
                    vm.companiesDropdownLoading = true;
                    let { data } = await ownershipGroupItemsDataService.getAvailableOwnerItems(ownershipGroup, groupOwner.Id);
                    data = data.filter(company => company.Type === vm.selectedCompanyType);
                    vm.companiesDataSource = data;
                    options.success(data);
                    vm.companiesDropdownLoading = false;
                }
            }
        });
    }

    function selectedCompanyTypeChanged() {
        vm.companiesDropdown.value([]);
        vm.companiesDropdown.setOptions({ placeholder: `-- Select one or more ${$filter('insertSpaceBetweenCapitalWords')(vm.selectedCompanyType)}s --` });
        vm.companiesDropdownDataSource.read();
    }

    // Business Process
    async function setupBusinessProcessDropdown() {

        vm.businessProcessDropdownOptions = {
            filter: "contains",
            autoClose: false,
            virtual: {
                itemHeight: 26,
                mapValueTo: 'dataItem',
                valueMapper: function (options) {
                    options.success(helperService.multiValueMapperFunction(options, vm.businessProcessDataSource, 'BusinessProcessId'));
                }
            },
            height: 325
        };

        vm.businessProcessDropdownDataSource = new kendo.data.DataSource({

            pageSize: standardGridPageSize,
            transport: {
                read: async (options) => {
                    const { data } = await ownershipGroupItemsDataService.getAvailableOwnerBusinessProcesses(ownershipGroup.Id, groupOwner.Id, vm.selectedRulesetId);
                    vm.businessProcessDropdown.setOptions({ placeholder: `-- Select Business Processes --` });
                    vm.businessProcessDataSource = data;
                    options.success(data);
                }
            }
        });

        return vm.businessProcessDropdownDataSource;
    }

    // Product
    async function setProducts() {
        let { data } = await ownershipGroupItemsDataService.getAvailableOwnerItems(ownershipGroup, groupOwner.Id);
        vm.productsDataSource = data;
    }

    // Risk
    async function updateDropdowns(type) {
        vm.selectedItems = null;
        if (type === 'businessprocess') {
            vm.businessProcessDropdownDataSource.read();
        } else {
            vm.kendoRiskDropdownDataSource.read();
        }
    }

    async function getRiskTypes() {
        let { data } = await risksDataService.getRiskTypes();
        vm.riskTypes = data;
    }

    function setupRiskDropdown() {
        vm.riskDropdownOptions = {
            filter: "contains",
            autoClose: false,
            virtual: {
                itemHeight: 26,
                mapValueTo: 'dataItem',
                valueMapper: function (options) {
                    options.success(helperService.multiValueMapperFunction(options, vm.riskDataSource, 'RiskId'));
                }
            },
            height: 325
        };

        vm.kendoRiskDropdownDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async (options) => {
                    const { data } = await ownershipGroupItemsDataService.getAvailableRisksByOwner(ownershipGroup.Id, groupOwner.Id, vm.selectedRulesetId);
                    vm.riskDropdown.setOptions({ placeholder: `-- Select Risks --` });

                    if (vm.selectedRiskType && vm.selectedRiskType === 'Segregation Of Duties') {
                        vm.riskDataSource = data.filter(risk => risk.RiskType === 'SOD');
                    } else if (vm.selectedRiskType && vm.selectedRiskType === 'Sensitive Access') {
                        vm.riskDataSource = data.filter(risk => risk.RiskType === 'SA');
                    } else {
                        vm.riskDataSource = data;
                    }

                    options.success(vm.riskDataSource);
                }
            }
        });
    }
    //

    function definePutData() {
        switch (vm.ownershipGroup.GroupType) {
            case 'role':
                return { Items: vm.selectedItems };
            case 'company':
                return { Items: vm.selectedItems };
            case 'product':
                let productIds = vm.selectedItems.map(item => item.ProductId);
                return { ProductIds: productIds };
            case 'businessprocess':
                return { BusinessProcessIds: vm.selectedItems }
            case 'risk':
                return {
                    RulesetId: vm.selectedRulesetId,
                    RiskIds: vm.selectedItems
                }
                break;
            default:
                break;
        }
    }

    async function saveItem() {
        vm.savePending = new createSpinner();
        const data = await definePutData();

        try {
            let itemId = await ownershipGroupsDataService.addItemsToGroupObject(ownershipGroup, groupOwner, data);
            await helperService.successfulSaveButton(vm.savePending);
            close(itemId);
        } catch {
            vm.savePending.loadingValue = false;
        }
    }

    function close(itemId) {
        $uibModalInstance.close(itemId);
    }

    function cancel() {
        $uibModalInstance.dismiss();
    }
}