export default /*@ngInject*/ function ($http, errorsDataService) {
    const service = {
        getRiskDetails,
        getRiskProductProfile,
        getBusinessProcessDetails,
        getObjectLevelBusinessProcessDetails
    };

    return service;

    async function getRiskDetails(riskId) {
        try {
            return await $http.get(`${apiUrl}api/arm/risks/${riskId}/products`);
        } catch (err) {
            showError(err);
        }
    }

    async function getRiskProductProfile(productId, risk) {
        try {
            if (risk.Company === null) {
                return await $http.get(`${apiUrl}api/arm/${productId}/places/accessdetails/risk/${risk.RiskId}/user/${risk.UserMasterId}`);
            } else {
                return await $http.get(`${apiUrl}api/arm/${productId}/places/accessdetails/risk/${risk.RiskId}/company/${risk.CompanyMasterId}/user/${risk.UserMasterId}`);
            }
        } catch (err) {
            showError(err);
        }
    }

    async function getBusinessProcessDetails(businessProcess, postData) {
        try {
            return await $http.post(`${apiUrl}api/arm/businessprocesses/${businessProcess.BusinessProcessId}/usermaster/${businessProcess.UserMasterId}/accessdetails`, postData);
        } catch (err) {
            showError(err);
        }
    }

    async function getObjectLevelBusinessProcessDetails(businessProcess, postData) {
        try {
            return await $http.post(`${apiUrl}api/arm/businessprocesses/${businessProcess.BusinessProcessId}/usermaster/${businessProcess.UserMasterId}/securityobject/${businessProcess.SecurityObjectIdentifier}/securityobjecttype/${businessProcess.SecurityObjectTypeId}/accessdetails`, postData);
        } catch (err) {
            showError(err);
        } 
    }

    function showError(err) {
        errorsDataService.catch(err);
    }

}