import { standardGridPageSize, customAutoCompleteFilter } from "../../Shared/GlobalVariables/filterVariables";
import swal from "sweetalert2";
import BulkMapCompaniesModalController from "./bulkMapCompanies/bulkMapCompaniesModal.controller";
import UnmappedCompaniesForProductModalController from "./unmappedCompaniesForProduct/unmappedCompaniesForProductModal.controller";
import autoMapCompaniesModalController from "./autoMapCompanies/autoMapCompaniesModal.controller";
import manualMapCompaniesModalController from "./manualMapCompanies/manualMapCompaniesModal.controller";
import unmappedCompaniesForProductModalController from "./unmappedCompaniesForProduct/unmappedCompaniesForProductModal.controller";

export default /*@ngInject*/ function ($uibModal, $filter, helperService, companyMappingDataService, dataService) {

    const vm = this;

    vm.removeProductMapping = removeProductMapping;
    vm.deleteCompanyMaster = deleteCompanyMaster;
    vm.openAutoMapCompaniesModal = openAutoMapCompaniesModal;
    vm.openManualMapCompanyModal = openManualMapCompanyModal;
    vm.openBulkMapCompaniesModal = openBulkMapCompaniesModal;
    vm.openUnmappedCompaniesForProductModal = openUnmappedCompaniesForProductModal;

    activate();

    async function activate() {
        await setupProducts();
        createGridOptions();
        getAllCompanyStatistics();
        createGridDataSource();
    }

    async function setupProducts() {
        vm.availableProducts = [];
        let response = await dataService.getProducts();
        response.data.forEach(function (row) {
            vm.availableProducts.push({
                'ID': row.ID,
                'Name': row.Name,
                'Identifier': 'id' + row.Name.replace(/ /g, '')
            });
        });

    }

    async function getAllCompanyStatistics() {
        let response = await companyMappingDataService.getCompanyStatistics()
        vm.companyByProductStatistics = response.data;

        vm.totalUnmappedCompaniesCount = response.data.sum(function (row) {
            return row.UnMappedCompanyCount;
        });
    }


    function createGridDataSource() {
        vm.gridDataSource = new kendo.data.DataSource({
            pageSize: standardGridPageSize,
            transport: {
                read: async options => {
                    let response = await companyMappingDataService.getCompanyMapping();

                    response.data = $filter('orderBy')(response.data, 'Name');

                    response.data.forEach(function (row) {
                        row.CompanyMappings.forEach(function (mapping) {
                            var identifier = 'id' + mapping.ProductName.replace(/ /g, '');
                            row[identifier] = mapping.CompanyName;
                        });
                    });
                    options.success(response.data);
                }
            }
        });
    }

    async function deleteCompanyMaster(dataItem) {
        try {
            await swal(helperService.areYouSureParams('Delete Company', 'All data associated with this company will be lost. Are you sure you want to remove this company?', 'Delete'));
            kendo.ui.progress(vm.mainGrid.element, true);
            await companyMappingDataService.deleteCompanyMaster(encodeURIComponent(dataItem.ID));
            kendo.ui.progress(vm.mainGrid.element, false);
            vm.mainGrid.dataSource.read();
            getAllCompanyStatistics();
        } catch {
            return;
        }

    }

    function createGridOptions() {

        let columnsFromVM = [
            { field: "Name", title: "Company", template: "<span>{{dataItem.Name}}</span> <a class='icons8-delete-filled user-mapping-link-button text-danger pull-right' ng-click='cmvm.deleteCompanyMaster(dataItem)'></a>", filterable: customAutoCompleteFilter }
        ];

        let productColumns = [];

        vm.availableProducts.forEach(function (product) {
            let identifier = product.Identifier;
            let productID = product.ID;
            let productName = product.Name;

            let customTemplate =
                "<span>{{dataItem." + identifier + "}}</span> <a class='icons8-pencil-tip user-mapping-link-button pull-right' ng-click='cmvm.openManualMapCompanyModal(dataItem, \"${'" + productID + "'}\", \"${'" + productName + "'}\")'></a>" +
                "<a ng-if='dataItem." + identifier + "' class='icons8-delete-filled user-mapping-link-button text-danger pull-right' ng-click='cmvm.removeProductMapping(dataItem, \"${'" + identifier + "'}\", \"${'" + productID + "'}\", \"${'" + productName + "'}\")'></a>";

            let templateRow = { field: identifier, title: productName, template: customTemplate, filterable: customAutoCompleteFilter };
            productColumns.push(templateRow);
        });
        productColumns = $filter('orderBy')(productColumns, 'field');
        productColumns = productColumns.unique();

        columnsFromVM = columnsFromVM.concat(productColumns);

        vm.mainGridOptions = helperService.setMainGridOptions(columnsFromVM, 600);
    }

    async function removeProductMapping(dataItem, identifier, launchedFromProductId, launchedFromProductName) {
        try {
            await swal(helperService.areYouSureParams('Remove Mapping', 'Are you sure you want to remove this mapping to ' + launchedFromProductName + '?', 'Remove'));
            var companyMapping = dataItem.CompanyMappings.find(e => e.ProductID === launchedFromProductId);
            var companyId = encodeURIComponent(companyMapping.CompanyID);
            kendo.ui.progress(vm.mainGrid.element, true);
            await companyMappingDataService.deleteProductMapping(companyMapping, companyId);
            removeMappingFromDataSource(dataItem, identifier, launchedFromProductId);
            await getAllCompanyStatistics();
            kendo.ui.progress(vm.mainGrid.element, false);
        } catch {
            return;
        }

    };

    function removeMappingFromDataSource(dataItem, identifier, launchedFromProductId) {
        delete dataItem[identifier];

        for (var i = 0; i < dataItem.CompanyMappings.length; i++) {
            if (dataItem.CompanyMappings[i].ProductID === launchedFromProductId) {
                dataItem.CompanyMappings.splice(i, 1);
                break;
            }
        }
    };

    async function openAutoMapCompaniesModal() {
        let autoMapCompaniesModal = $uibModal.open({
            templateUrl: '/App/Components/CompanyMapping/autoMapCompanies/autoMapCompanyModal.html',
            controller: autoMapCompaniesModalController,
            controllerAs: 'amcmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                availableProducts: () => vm.availableProducts
            }
        });

        try {
            await autoMapCompaniesModal.result;
            vm.gridDataSource.read();
            getAllCompanyStatistics();
        } catch {
            return;
        }
    };

    async function openManualMapCompanyModal(dataItem, launchedFromProductId, launchedFromProductName) {
        let selectedCompanyProductClicked = { "ProductID": launchedFromProductId, "ProductName": launchedFromProductName };

        let manualMapCompanyModal = $uibModal.open({
            templateUrl: '/App/Components/CompanyMapping/manualMapCompanies/manualMapCompanyModal.html',
            controller: manualMapCompaniesModalController,
            controllerAs: 'mmcmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                selectedCompanyForManualMapping: () => dataItem,
                selectedCompanyProductClicked: () => selectedCompanyProductClicked
            }
        });

        try {
            await manualMapCompanyModal.result;
            vm.gridDataSource.read();
            getAllCompanyStatistics();
        } catch {
            return;
        }
    };

    async function openBulkMapCompaniesModal() {
        let bulkMapCompaniesModal = $uibModal.open({
            templateUrl: '/App/Components/CompanyMapping/bulkMapCompanies/bulkMapCompanyModal.html',
            controller: BulkMapCompaniesModalController,
            controllerAs: 'bmcmvm',
            backdrop: 'static',
            size: 'md',
            resolve: {
                availableProducts: () => vm.availableProducts,
            }
        });

        try {
            await bulkMapCompaniesModal.result;
            vm.gridDataSource.read();
            getAllCompanyStatistics();
        } catch {
            return;
        }
    }

    async function openUnmappedCompaniesForProductModal(launchedFromProductID, launchedFromProductName) {

        vm.selectedCompanyProductClicked = { "ProductID": launchedFromProductID, "ProductName": launchedFromProductName };

        $uibModal.open({
            templateUrl: '/App/Components/CompanyMapping/unmappedCompaniesForProduct/unmappedCompaniesForProductModal.html',
            controller: unmappedCompaniesForProductModalController,
            controllerAs: 'ucfpmvm',
            backdrop: 'static',
            size: 'lg',
            resolve: {
                selectedCompanyProduct: () => vm.selectedCompanyProductClicked
            }
        });
    };

}