export default /*@ngInject*/ function (helperService, $uibModalInstance, userChangesInformation, $scope) {

    const vm = this;

    vm.userChangesInformation = userChangesInformation;
    vm.helperService = helperService;
    vm.dismiss = dismiss;
    vm.oldValueExists = oldValueExists;

    activate();

    async function activate() {
        setUserChangesInfo();

    }

    function setUserChangesInfo() {
        if (userChangesInformation.Event === 'Delete') {
            vm.isDelete = true;
            vm.userProperties = Object.keys(userChangesInformation);
            vm.userProperties = vm.userProperties.reduce((formattedProperties, currentProperty) => {
                if (currentProperty !== "Application" && currentProperty !== "$$hashKey" && currentProperty !== "UserChanges") {
                    formattedProperties.push(currentProperty);
                    return formattedProperties;
                }
                else {
                    return formattedProperties;
                }
            }, []);
        }
        vm.productImage = userChangesInformation.ProductId;
        vm.userInformation = userChangesInformation.UserChanges;
    }

    function oldValueExists(userProperty) {
        if (userProperty.OldValue && userProperty.OldValue !== userProperty.NewValue || userProperty.OldValue) {
            $scope.oldValue = userProperty.OldValue;

        } else {
            $scope.oldValue = "<span class='text-muted'>Blank</span>";
        }

        return true;
    }

    function dismiss() {
        $uibModalInstance.dismiss();
    }
}