export default /*@ngInject*/ function () {

    const vm = this;

    vm.changeSelectedSimulation = changeSelectedSimulation;
    vm.changeSelectedRole = changeSelectedRole;
    vm.changeSelectedCompositeRole = changeSelectedCompositeRole;
    vm.changeSelectedAuthObject = changeSelectedAuthObject;
    vm.changeSelectedAuthObjectField = changeSelectedAuthObjectField;
    vm.changeSelectedOrgLevel = changeSelectedOrgLevel;
    vm.roleTypeChange = roleTypeChange;
    vm.backToSimulationList = backToSimulationList;
    vm.manageAuthorizations = manageAuthorizations;
    vm.defineOrgLevels = defineOrgLevels;
    vm.viewAnalysis = viewAnalysis;
    vm.simulationsListUpdated = simulationsListUpdated;
    vm.updateList = updateList;

    vm.roleType = 'singleRole';
    vm.manageAuthActive = false;
    vm.defineOrgLevelsActive = false;
    vm.backButtonText = 'Simulations';
    vm.updateSimulationsList = false;

    function changeSelectedSimulation(simulation) {
        if ((vm.selectedSimulation && vm.selectedSimulation.Id !== simulation?.Id) || !simulation) {
            vm.selectedRole = null;
            vm.selectedCompositeRole = null;
            vm.selectedSimulation = null;
        }
        vm.roleType = 'singleRole';
        vm.selectedSimulation = simulation;
    }

    function changeSelectedRole(role) {
        vm.selectedRole = role;
    }

    function changeSelectedCompositeRole(compositeRole) {
        vm.selectedCompositeRole = compositeRole;
    }

    function changeSelectedAuthObject(authObject) {
        vm.selectedAuthObjectField = null;
        vm.selectedAuthObject = authObject;
    }
    
    function changeSelectedAuthObjectField(field) {
        vm.selectedAuthObjectField = field;
    }

    function changeSelectedOrgLevel(orgLevel) {
        vm.selectedOrgLevel = orgLevel;
    }

    function roleTypeChange(type) {
        vm.selectedRole = null;
        vm.selectedCompositeRole = null;
        vm.roleType = type;
    }

    function backToSimulationList() {
        vm.manageAuthActive = false;
        vm.defineOrgLevelsActive = false;
        vm.viewAnalysisActive = false;
        // clear list selections
        vm.selectedAuthRole = null;
        vm.selectedAuthObject = null;
        vm.selectedAuthObjectField = null;
        vm.selectedOrgLevel = null;
        vm.selectedOrgLevelsRole = null;
        vm.selectedAnalysisSimulation = null;
        // refresh simulations list so statuses can be up to date
        vm.updateSimulationsList = true;
    }

    function manageAuthorizations(role) {
        vm.staticHeaderTitle = `SAP Security Designer Simulation Role Authorizations`;
        vm.staticHeaderDescription = `Manage Authorizations for Simulation Role ${role.Name}`;
        vm.selectedAuthRole = role;
        vm.manageAuthActive = true;
    }

    function defineOrgLevels(role) {
        vm.staticHeaderTitle = `SAP Security Designer Simulation Role Organizational Levels`;
        vm.staticHeaderDescription = `Define Organizational Levels for Simulation Role ${role.Name}`;
        vm.selectedOrgLevelsRole = role;
        vm.defineOrgLevelsActive = true;
    }

    function viewAnalysis(simulation) {
        vm.staticHeaderTitle = `SAP Security Designer Simulation Risk Analysis`;
        vm.staticHeaderDescription = `Risk Analysis for Simulation ${simulation.Name}`;
        vm.selectedAnalysisSimulation = simulation;
        vm.viewAnalysisActive = true;
    }

    function simulationsListUpdated(updateList) {
        vm.updateSimulationsList = updateList;
    }

    function updateList(listToUpdate) {
        switch (listToUpdate) {
            case 'authObjects':
                vm.selectedAuthRole = angular.copy(vm.selectedAuthRole);
                break;
            case 'objectFields':
                vm.selectedAuthObject = angular.copy(vm.selectedAuthObject);
                break;
            case 'orgLevels':
                vm.selectedOrgLevelsRole = angular.copy(vm.selectedOrgLevelsRole);
                break;
        }
    }
}