import swal from "sweetalert2";
import createSpinner from "../../../Shared/Functions/createSpinner";
import ownershipGroupModalController from "./ownershipGroupModal.controller";

export default /*@ngInject*/ function (ownershipGroupsDataService, helperService, $uibModal, $filter, FileSaver) {

    const vm = this;

    vm.changeSelectedOwnershipGroup = changeSelectedOwnershipGroup;
    vm.deleteOwnershipGroup = deleteOwnershipGroup;
    vm.openOwnershipGroupModal = openOwnershipGroupModal;
    vm.exportOwnershipGroups = exportOwnershipGroups;

    vm.menuOptions = [
        {
            text: 'Edit',
            click: item => {
                vm.openOwnershipGroupModal(item.ownershipGroup, false);
            }
        },
        {
            text: '<span class="text-danger">Delete</span>',
            click: item => {
                vm.deleteOwnershipGroup(item.ownershipGroup);
            }
        }
    ];

    vm.$onInit = activate;

    async function activate() {
        getOwnershipGroups();
    }

    async function getOwnershipGroups() {
        vm.ownershipGroupsLoading = true;
        let response = await ownershipGroupsDataService.getOwnershipGroups();
        response.data = $filter('orderBy')(response.data, 'Name');
        vm.ownershipGroups = response.data;
        vm.ownershipGroupsLoading = false;
    }

    function changeSelectedOwnershipGroup(ownershipGroup) {
        vm.selectedOwnershipGroup = ownershipGroup;
        vm.onSelectedOwnershipGroupChanged({ ownershipGroup });
    }

    async function openOwnershipGroupModal(ownershipGroup, isImport) {
        let ownershipGroupModal = $uibModal.open({
            templateUrl: '/App/Components/OwnershipGroups/ownershipGroupsList/ownershipGroupModal.tmpl.html',
            controller: ownershipGroupModalController,
            controllerAs: 'ogmvm',
            backdrop: 'static',
            resolve: {
                ownershipGroup: () => ownershipGroup || null,
                isImport: () => isImport || null
            }
        });

        try {
            let newOwnershipGroupId = await ownershipGroupModal.result;
            if (!isImport) {
                await getOwnershipGroups();
            }
            if (newOwnershipGroupId) {
                // We know a new ownership group was created so we need to select it
                changeSelectedOwnershipGroup(vm.ownershipGroups.find(group => group.Id === newOwnershipGroupId));
            }
        } catch { return; }
    }

    async function deleteOwnershipGroup(ownershipGroup) {
        try {
            await swal(helperService.areYouSureParams(`Delete Ownership Group`, `Are you sure you want to delete Ownership Group ${ownershipGroup.Name}?`, `Yes`, 'No'))
            await ownershipGroupsDataService.deleteGroup(ownershipGroup.Id);
            getOwnershipGroups();
            if (vm.selectedOwnershipGroup.Id === ownershipGroup.Id) {
                changeSelectedOwnershipGroup(null);
            }         
        } catch {
            return;
        }
    }

    async function exportOwnershipGroups() {
        vm.exportUpdatePending = new createSpinner();
        try {
            const response = await ownershipGroupsDataService.exportOwnershipGroups();
            await helperService.successfulSaveButton(vm.exportUpdatePending);
            FileSaver.saveAs(response.data, 'OwnershipGroups.xlsx');
        } catch {
            vm.exportUpdatePending.loadingValue = false;
        }
    }
}